import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 0.5rem;
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
`;

export const Block = styled(Col)`
  margin: 2rem auto;
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.shuttleGray};
  line-height: 1.31;
`;

export const Content = styled.p`
  &:first-child {
    margin-bottom: 1rem;
  }
`;

export const ButtonsContainer = styled.div`
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`;

export default Block;
