import React from 'react';
import PropTypes from 'prop-types';

import { FlexContainer } from './styled-components';

const Flex = ({
  children,
  justifyContent,
  alignItems,
  direction,
  full,
  bgColor,
  bgImage,
  padding,
  margin,
  highlighted,
  maxWidth,
  fullWidth,
  height,
  noShrink,
  theme,
  onClick,
  peopleTab
}) => (
  <FlexContainer
    justifyContent={justifyContent}
    alignItems={alignItems}
    direction={direction}
    full={full}
    bgColor={bgColor}
    bgImage={bgImage}
    padding={padding}
    margin={margin}
    highlighted={highlighted}
    maxWidth={maxWidth}
    fullWidth={fullWidth}
    height={height}
    noShrink={noShrink}
    theme={theme}
    onClick={onClick}
    peopleTab={peopleTab}
  >
    {children}
  </FlexContainer>
);

Flex.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  direction: PropTypes.string,
  full: PropTypes.bool,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highlighted: PropTypes.bool,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  height: PropTypes.string,
  noShrink: PropTypes.bool,
  theme: PropTypes.string,
  onClick: PropTypes.func
};

Flex.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  direction: 'row',
  full: false,
  bgColor: null,
  bgImage: null,
  padding: 0,
  margin: 0,
  highlighted: false,
  maxWidth: null,
  fullWidth: false,
  height: null,
  noShrink: false,
  theme: '',
  onClick: () => true
};

export default Flex;
