import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { ifElse, isEmpty, always, identity } from 'ramda';

import MultiSelectDropdown from '../../Reusable/MultiSelectDropdown';
import { allAvailableProvinces } from '../../../../constants';
import MaterialInput from '../../MaterialInput';
import { TitleBar } from '../InformationForm/styled-components';
import Button from '../../Button';
import LoaderWrapper from '../../LoaderWrapper';
import {
  Wrapper,
  Block,
  HelpText,
  IconButton,
  X,
  FieldRow,
  Counter,
  Label,
  New,
  Criteria,
  MultipleInputTheme
} from './styled-components';
import { ActualContentContainer } from '../styled-components';
import Paper from '../../../layouts/Paper';
import { ICONS } from '../../../../utils/styles';
import { required, hasAtLeastOneField, isNotValidated } from '../../../../utils/validations';
import { notNilNotEmpty } from '../../../../utils/helpers';
import Enhancer from '../../../containers/Event/Categories';
import Warning from './Warning';
import MultipleInput from '../../Reusable/MultipleInput';

const defaultTo = ifElse(isEmpty, always([{ name: '' }]), identity);

const getCategoryCounter = (categories, idx, key) =>
  !notNilNotEmpty(categories) &&
  !notNilNotEmpty(categories[idx]) &&
  !notNilNotEmpty(categories[idx][key])
    ? categories[idx][key].length
    : 0;

const checkIfValuesAreEqual = ({ initialValue, finalValue }) => {
  if (
    Array.isArray(initialValue) &&
    Array.isArray(finalValue) &&
    initialValue.length === finalValue.length
  ) {
    return finalValue.reduce((acc, value) => {
      if (!acc) {
        return acc;
      }
      return initialValue.includes(value);
    }, true);
  }
  if (initialValue === '' && Array.isArray(finalValue) && finalValue.length === 0) {
    return true;
  }
  return initialValue === finalValue;
};

export const Categories = ({
  categories,
  showModal,
  setModalVisibility,
  onRemove,
  onDelete,
  onSubmit,
  isFetching,
  teams,
  locations
}) => (
  <LoaderWrapper isFetching={isFetching}>
    <ActualContentContainer>
      <Form
        onSubmit={onSubmit}
        initialValues={{ categories: defaultTo(categories) }}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, mutators: { push }, pristine, invalid, values }) => {
          const sameFields = [];

          categories.map((category, i) => {
            const isNameEqual = checkIfValuesAreEqual({
              initialValue: category && category.name,
              finalValue: values.categories && values.categories[i] && values.categories[i].name
            });

            const isStatesEqual = checkIfValuesAreEqual({
              initialValue: category && category.states,
              finalValue: values.categories && values.categories[i] && values.categories[i].states
            });

            const isPostalCodesEqual = checkIfValuesAreEqual({
              initialValue: category && category.postal_codes,
              finalValue:
                values.categories && values.categories[i] && values.categories[i].postal_codes
            });

            const isTeamsEqual = checkIfValuesAreEqual({
              initialValue: category && category.teams,
              finalValue: values.categories && values.categories[i] && values.categories[i].teams
            });

            const isLocationsEqual = checkIfValuesAreEqual({
              initialValue: category && category.locations,
              finalValue:
                values.categories && values.categories[i] && values.categories[i].locations
            });

            const isSame =
              isNameEqual &&
              isStatesEqual &&
              isPostalCodesEqual &&
              isTeamsEqual &&
              isLocationsEqual;

            sameFields.push(isSame);

            return category;
          });

          const different = sameFields.filter(same => !same);
          const isValueChanged = !(
            different.length || values.categories.length > categories.length
          );

          return (
            <form onSubmit={handleSubmit}>
              <Paper>
                <Wrapper>
                  {showModal && (
                    <Warning onClose={() => setModalVisibility(false)} onSubmit={onDelete} />
                  )}
                  <Col xs={12}>
                    <TitleBar>
                      <Row between="xs">
                        <Col xs={8}>Categories</Col>
                        <Col xs={2}>
                          <IconButton onClick={() => push('categories', {})} type="button">
                            <New height={16} width={16} icon={ICONS.NEW} />
                            <Label>New Category</Label>
                          </IconButton>
                        </Col>
                      </Row>
                    </TitleBar>
                  </Col>
                  <Block xs={12}>
                    <HelpText>
                      Assignors, Officials, and Assessors assigned a category will be limited to the
                      games with the same category.
                    </HelpText>
                  </Block>
                  <Block xs={12}>
                    <FieldArray name="categories">
                      {({ fields }) =>
                        fields.map((category, index) => (
                          <Fragment key={category}>
                            <Row bottom="xs">
                              <Col xs={6}>
                                <Row start="xs" top="xs">
                                  <Col xs={11}>
                                    <Field
                                      name={`${category}.name`}
                                      component={MaterialInput}
                                      placeholder="Category"
                                      validate={required}
                                    />
                                  </Col>
                                  <Col xs={1}>
                                    {fields.length > 1 && (
                                      <IconButton
                                        onClick={onRemove({
                                          remove: fields.remove,
                                          index
                                        })}
                                        type="button"
                                      >
                                        <X
                                          height={16}
                                          width={16}
                                          icon={ICONS.CLOSE}
                                          color="black"
                                        />
                                      </IconButton>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={6}>
                                <Row center="xs">
                                  <Col xs={3}>
                                    <HelpText>
                                      Officials:
                                      <Counter>
                                        {getCategoryCounter(categories, index, 'officials')}
                                      </Counter>
                                    </HelpText>
                                  </Col>
                                  <Col xs={3}>
                                    <HelpText>
                                      Assessor:
                                      <Counter>
                                        {getCategoryCounter(categories, index, 'assessors')}
                                      </Counter>
                                    </HelpText>
                                  </Col>
                                  <Col xs={3}>
                                    <HelpText>
                                      Assignors:
                                      <Counter>
                                        {getCategoryCounter(categories, index, 'assignors')}
                                      </Counter>
                                    </HelpText>
                                  </Col>
                                  <Col xs={3}>
                                    <HelpText>
                                      Games:
                                      <Counter>
                                        {getCategoryCounter(categories, index, 'games')}
                                      </Counter>
                                    </HelpText>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Criteria style={{ paddingTop: '10px' }}>
                                Games should be added to this category based on the following
                                criteria:
                              </Criteria>
                            </Row>
                            {isNotValidated(fields.value[index]) && (
                              <Row>
                                <Criteria style={{ color: 'red' }}>
                                  * At least one of the below field is required
                                </Criteria>
                              </Row>
                            )}
                            <FieldRow>
                              <Field
                                name={`${category}.states`}
                                validate={hasAtLeastOneField}
                                render={({ input }) => (
                                  <MultiSelectDropdown
                                    options={allAvailableProvinces}
                                    input={input}
                                    placeholder="State/Province"
                                    theme={{ wrapper: `margin-left: 0.5rem; width: 13%` }}
                                    outsideClickStyle={{ width: '100%' }}
                                  />
                                )}
                              />
                            </FieldRow>
                            <FieldRow>
                              <Field
                                name={`${category}.postal_codes`}
                                validate={hasAtLeastOneField}
                                render={({ input }) => (
                                  <MultipleInput
                                    maxLength={7}
                                    isCount
                                    input={input}
                                    placeholder="Zip/Postal Code"
                                    theme={MultipleInputTheme}
                                  />
                                )}
                              />
                            </FieldRow>
                            {teams.length > 0 && (
                              <FieldRow>
                                <Field
                                  name={`${category}.teams`}
                                  validate={hasAtLeastOneField}
                                  render={({ input }) => (
                                    <MultiSelectDropdown
                                      options={teams.map(team => ({
                                        value: `${team.source.external_id}`,
                                        name: `${team.name} ${team.game_lvl}/${team.game_type}`
                                      }))}
                                      input={input}
                                      placeholder="Teams"
                                      theme={{
                                        wrapper: `margin-left: 0.5rem; width: 13%;`,
                                        options: `left:0px;width:200%; `,
                                        option: `padding: 0.7rem 0.7rem !important;display:inline;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;min-height:2.7rem`,
                                        checkIcon: `float:right;`
                                      }}
                                      outsideClickStyle={{ width: '100%' }}
                                      checkValueCount
                                    />
                                  )}
                                />
                              </FieldRow>
                            )}
                            {locations.length > 0 && (
                              <FieldRow>
                                <Field
                                  name={`${category}.locations`}
                                  validate={hasAtLeastOneField}
                                  render={({ input }) => (
                                    <MultiSelectDropdown
                                      options={locations.map(location => ({
                                        value: `${location.source.external_id}`,
                                        name: `${location.name} ${
                                          location.surface_name ? `, ${location.surface_name}` : ''
                                        } ${location.city ? `, ${location.city}` : ''} ${
                                          location.state ? `, ${location.state}` : ''
                                        }`
                                      }))}
                                      input={input}
                                      placeholder="Locations"
                                      theme={{
                                        wrapper: `margin-left: 0.5rem; width: 13%;`,
                                        options: `left:0px !important;width:600px; max-width:600px `,
                                        option: `padding: 0.7rem 0.7rem !important;display:inline;overflow:hidden;text-overflow:ellipsis;min-height:2.7rem !important`,
                                        checkIcon: `float:right;`
                                      }}
                                      outsideClickStyle={{ width: '100%' }}
                                      checkValueCount
                                    />
                                  )}
                                />
                              </FieldRow>
                            )}
                          </Fragment>
                        ))
                      }
                    </FieldArray>
                  </Block>
                  <Block xs={12}>
                    <Row end="xs">
                      <Col xs={2}>
                        <Button
                          label="Update"
                          primary
                          type="submit"
                          disabled={pristine || invalid || isValueChanged}
                        />
                      </Col>
                    </Row>
                  </Block>
                </Wrapper>
              </Paper>
            </form>
          );
        }}
      />
    </ActualContentContainer>
  </LoaderWrapper>
);

Categories.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      games: PropTypes.array,
      event_roles: PropTypes.array,
      id: PropTypes.string
    })
  ),
  teams: PropTypes.arrayOf(Object),
  locations: PropTypes.arrayOf(Object)
};

Categories.defaultProps = {
  categories: [
    {
      name: ''
    }
  ],
  teams: [
    {
      name: ''
    }
  ],
  locations: [
    {
      name: ''
    }
  ]
};

export default Enhancer(Categories);
