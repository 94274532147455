import { compose } from 'recompose';
import { connect } from 'react-redux';

import unconnected from './unconnected';
import { getFrom, getAdditional as getAdditionalParams } from '../../../core/Modals/selectors';
import { setShowModalFor as setShowModalForAction } from '../../../core/Modals';
import { FROM_NOTIFICATIONS } from '../../../core/Modals/constants';
import {
  getEvent as getNotificationEvent,
  getNotificationReportSettings
} from '../../../core/Notifications/selectors';
import {
  getCurrentGame,
  getGamesEventId,
  getCalendarGameLevels,
  getCalendarEventId,
  getCalendarEventSport,
  getCurrentGameEventReportSettings
} from '../../../core/Games/selectors';
import {
  createGameReport as createGameReportAction,
  editGameReport as editGameReportAction,
  fetchCurrentGameReports as fetchCurrentGameReportsAction,
  deleteGameReport as deleteGameReportAction,
  fetchGameReport as fetchGameReportAction
} from '../../../core/Games';
import { getCurrentReport, isReportsEventGroupAdmin } from '../../../core/Reports/selectors';
import { updateGameReport } from '../../../core/Reports';
import {
  isGamesEventAdmin,
  isReportsEventAdmin,
  selectUserId,
  isGamesEventAssignor,
  isGamesEventOfficial,
  isNotificationEventOfficial,
  isGamesEventAdminOrAssignor,
  isReportEventAssignor
} from '../../../core/Auth/selectors';
import {
  getEventGameLevels,
  getEventReportSettings,
  getEventSportsId
} from '../../../core/Events/selectors';

const mapStateToProps = state => ({
  game: getCurrentGame(state),
  from: getFrom(state),
  params: getAdditionalParams(state),
  report: getCurrentReport(state),
  reportSettings: (() => {
    const from = getFrom(state);
    switch (from) {
      case FROM_NOTIFICATIONS:
        return getNotificationReportSettings(state);
      default:
        return getEventReportSettings(state) || getCurrentGameEventReportSettings(state);
    }
  })(),
  isGamesEventAdmin: isGamesEventAdmin(state),
  isGamesEventAssignor: isGamesEventAssignor(state),
  isReportsEventAdmin: isReportsEventAdmin(state),
  isReportsEventAssignor: isReportEventAssignor(state),
  isReportsEventGroupAdmin: !!isReportsEventGroupAdmin()(state),
  isGamesEventAdminOrAssignor: isGamesEventAdminOrAssignor(state),
  isGamesEventOfficial:
    getFrom(state) === FROM_NOTIFICATIONS
      ? isNotificationEventOfficial(state)
      : isGamesEventOfficial(state),
  currentUserId: selectUserId(state),
  isFetching: state.games.current.isFetching,
  eventSportId:
    getFrom(state) === FROM_NOTIFICATIONS
      ? getNotificationEvent(state).sport_id
      : getEventSportsId(state) || getCalendarEventSport(state),
  gamesEventId:
    getFrom(state) === FROM_NOTIFICATIONS
      ? getNotificationEvent(state).id
      : getGamesEventId(state) || getCalendarEventId(state),
  gameLevels:
    getFrom(state) === FROM_NOTIFICATIONS
      ? getNotificationEvent(state).game_levels
      : getEventGameLevels(state),
  calendarGameLevel: getCalendarGameLevels(state)
});

const mapDispatchToProps = dispatch => ({
  createGameReport: payload => dispatch(createGameReportAction(payload)),
  editGameReport: payload => dispatch(editGameReportAction(payload)),
  setShowModalFor: payload => dispatch(setShowModalForAction(payload)),
  deleteGameReport: payload => dispatch(deleteGameReportAction(payload)),
  fetchCurrentGameReports: payload => dispatch(fetchCurrentGameReportsAction(payload)),
  fetchGameReport: payload => dispatch(fetchGameReportAction(payload)),
  updateReport: payload => dispatch(updateGameReport(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
