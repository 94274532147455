import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';
import { COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  box-sizing: border-box;
`;

export const InnerPageCol = styled(Col)`
  width: 100%;
  margin: 1rem 1.5rem 0 1.5rem;
`;

export const Header = InnerPageCol.extend`
  background-color: ${COLORS.altoGray};
  line-height: 2;
`;

export const SimpleLabel = styled.label`
  font-size: 9pt;
  color: ${COLORS.naturalGray};
`;

export const Input = styled.input``;

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  .desktop {
    display: flex;
  }
  .mobile {
    display: none;
  }
  .mobileMenu {
    z-index: 99;
    transform: translate3d(198px, 52px, 0px);
  }
  ${mediaQueries.md(`
  .desktop{display:none;}
  .mobile{display: flex;
    margin-top: 5px;}
  `)};
`;

export const ColumnedContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
`;

export const ActualContentContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const ProfileTitleWrapper = styled.div`
  min-height: 4rem;
  max-height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.white};
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.4%;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.47rem;
  box-shadow: ${({ title }) =>
    title === 'Certifications'
      ? '2px 3px 2px rgba(0, 0, 0, 0.1)'
      : '2px 3px 8px rgba(0, 0, 0, 0.4)'};
  box-sizing: border-box;
  position: relative;
  .exitViewButton {
    position: absolute;
    right: 50px;
  }

  ${mediaQueries.md(`
  .exitViewButton{
    position:absolute;
    right:30px;
    top:80px
      }
  `)};
`;

export const ProfileName = styled.span`
  font-size: ${pxToRem('17px')};
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.denimBlue};
  height: 100%;
  line-height: 3rem;
  padding-right: 1rem;
  text-transform: uppercase;
  ${mediaQueries.sm(`
  padding-left: 1rem;
  padding-right: 0rem;
  `)};
`;

export const Name = styled.span`
  color: ${COLORS.pureOrange};
  text-transform: none;
`;

export const Paper = styled.div`
  padding: ${pxToRem('40px')} 4% ${pxToRem('30px')};
  background-color: ${COLORS.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
`;

export const WhiteContentContainer = styled.div`
  background: ${COLORS.white};
  min-height: ${({ onBoarding, height }) => (onBoarding ? '70vh' : height)};
  overflow-y: auto;
  box-sizing: border-box;
  ${({ padding }) => padding && 'padding: 40px'};
  ${({ fetching }) =>
    fetching &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
    `};

}
`;

export const StyledDiv = styled.div`
  min-height: ${({ onBoarding }) => (onBoarding ? '75vh' : 'auto')};
  ${({ onBoarding }) => onBoarding && 'padding: 1.875rem; box-shadow: 0 0 8px rgb(0 0 0 / 30%);'};
  background: ${COLORS.white};
`;

export const H2 = styled.span`
  font-size: 20px;
  color: #0d194f;
  font-weight: 500;
  display: flex;
`;
