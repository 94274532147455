import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export const OnboadingBtns = styled.div`
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: ${({ margin }) => margin};
  display: flex;
  & .nextBtn {
    background: ${({ disabled }) =>
      disabled ? `${COLORS.gray} !important` : `${COLORS.denimBlue} !important`};
    color: ${({ disabled }) =>
      disabled ? `${COLORS.greyish} !important` : `${COLORS.white} !important`};
  }
  & .prevBtn {
    background: ${COLORS.denimBlue} !important;
    color: ${COLORS.white} !important;
  }
`;

export const ButtonWrapperRight = styled.div`
  display: flex;
`;
