import React from 'react';

import CreateAdmin from '../../CreateAdmin';
import CreateGroup from '../../CreateGroup';
import Enhancer from '../../../containers/Dashboards/GroupsAndSubscriptions';
import BannerUnsheduledNotifications from '../../BannerNotifications';
import { Wrapper, Container, InnerWrapper, FormTile, BillingWrapper } from './styled-components';
import GenerateReport from '../../GenerateReport';
import GroupDetails from '../../GroupDetails';
import EventBillingInfo from '../../EventBilling';

const Dashboard = props => (
  <div>
    <BannerUnsheduledNotifications
      setShowBannerForGamesUnscheduled={props.setShowBannerForGamesUnscheduled}
      showBannerForGamesUnscheduled={props.showBannerForGamesUnscheduled}
      notifications={props.notifications}
      bannerClose={props.bannerClose}
      bannerHeading="These games have been unscheduled and will affect the crews listed below. A game COPY in “Cancelled” status will appear in your event to manage assignments and/or create pay."
    />
    <Wrapper>
      <InnerWrapper xs={12} sm={12} md={6} lg={6}>
        <Container>
          <FormTile title="Create new users">
            <CreateAdmin {...props} />
          </FormTile>
        </Container>
      </InnerWrapper>
      <InnerWrapper xs={12} sm={12} md={6} lg={6}>
        <Container>
          <FormTile title="Create a new group">
            <CreateGroup {...props} />
          </FormTile>
        </Container>
      </InnerWrapper>
      <InnerWrapper xs={12} sm={12} md={6} lg={6}>
        <Container>
          <FormTile title="Financial Reports">
            <GenerateReport {...props} />
          </FormTile>
        </Container>
      </InnerWrapper>
      <InnerWrapper xs={12} sm={12} md={12} lg={12}>
        <BillingWrapper>
          <GroupDetails {...props} />
          <EventBillingInfo />
        </BillingWrapper>
      </InnerWrapper>
    </Wrapper>
  </div>
);

export default Enhancer(Dashboard);
