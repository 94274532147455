import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import MaterialDropdown from '../../MaterialDropDown';
import theme, {
  NotificationRow,
  Summary,
  OrgName,
  Chevron,
  ExpandedWrapper,
  Label,
  Value,
  Details,
  ActionItems,
  DropdownContainer
} from '../styled-components';
import { ICONS, COLORS } from '../../../../utils/styles';
import Enhancer from '../../../containers/NotificationsMenu/SubMenu/InvitationsList';

const NotificationExpanded = ({
  id,
  invitation,
  setSelectedEventId,
  selectedEventId,
  events,
  linkGamesToEvent,
  closeMenu
}) => (
  <ExpandedWrapper>
    <Details>
      <Label>Schedule</Label>
      <Value>{invitation.schedule_name}</Value>
      <Label>Level</Label>
      <Value>{invitation.schedule_level}</Value>
      <Label>Type</Label>
      <Value>{invitation.schedule_type}</Value>
    </Details>
    <ActionItems>
      <DropdownContainer>
        <MaterialDropdown
          placeholder="Add these games to (Select Event)"
          theme={theme}
          input={{ onChange: setSelectedEventId, value: selectedEventId }}
          options={events.map(event => ({ value: event.id, name: event.name }))}
        />
      </DropdownContainer>
      <Button
        label="Upload Games"
        primary
        width="84px"
        padding="5px"
        disabled={!selectedEventId}
        onClick={e => {
          closeMenu(e);
          linkGamesToEvent({
            eventId: selectedEventId,
            invitationId: invitation.id,
            notificationId: id
          });
        }}
      />
    </ActionItems>
  </ExpandedWrapper>
);

NotificationExpanded.propTypes = {
  id: PropTypes.string.isRequired,
  invitation: PropTypes.shape({
    schedule_name: PropTypes.string,
    schedule_level: PropTypes.string,
    schedule_type: PropTypes.string
  }).isRequired,
  selectedEventId: PropTypes.string,
  setSelectedEventId: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(Object),
  linkGamesToEvent: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired
};

NotificationExpanded.defaultProps = {
  events: [],
  selectedEventId: null
};

const SubMenu = ({
  notifications,
  expandedId,
  setExpandedId,
  selectedEventId,
  setSelectedEventId,
  events,
  linkGamesToEvent,
  closeMenu
}) => (
  <>
    {notifications.map((notification, i) => {
      const isExpanded = expandedId === notification.id;
      return (
        <Fragment key={notification.id}>
          <NotificationRow isExpanded={isExpanded} noBorder={i === notifications.length - 1}>
            <Summary>
              <OrgName>{notification.invitation.organization_name}</OrgName> has shared games with
              you.
            </Summary>
            <Chevron
              icon={isExpanded ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_RIGHT}
              width={15}
              height={10}
              color={isExpanded ? COLORS.denimBlue : COLORS.shuttleGray}
              onClick={() => {
                setSelectedEventId(null);
                setExpandedId(isExpanded ? null : notification.id);
              }}
              isExpanded={isExpanded}
            />
          </NotificationRow>
          {isExpanded && (
            <NotificationExpanded
              id={notification.id}
              selectedEventId={selectedEventId}
              setSelectedEventId={setSelectedEventId}
              invitation={notification.invitation}
              events={events}
              linkGamesToEvent={linkGamesToEvent}
              closeMenu={closeMenu}
            />
          )}
        </Fragment>
      );
    })}
  </>
);

SubMenu.propTypes = {
  notifications: PropTypes.arrayOf(Object),
  expandedId: PropTypes.string,
  setExpandedId: PropTypes.func.isRequired,
  selectedEventId: PropTypes.string,
  setSelectedEventId: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(Object),
  linkGamesToEvent: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired
};

SubMenu.defaultProps = {
  notifications: [],
  events: [],
  expandedId: null,
  selectedEventId: null
};
export default Enhancer(SubMenu);
