import React from 'react';
import PropTypes from 'prop-types';

import { Svg } from './styled-components';

const Circle = ({ width, height, cx, cy, r, strokeWidth, color, ...rest }) => (
  <Svg className="circular" height={height} width={width} color={color} {...rest}>
    <circle
      className="path"
      cx={cx}
      cy={cy}
      r={r}
      fill="none"
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
  </Svg>
);

Circle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  r: PropTypes.number,
  strokeWidth: PropTypes.number,
  color: PropTypes.string
};

Circle.defaultProps = {
  width: 50,
  height: 50,
  cx: 25,
  cy: 25.2,
  r: 19.9,
  strokeWidth: 6,
  color: ''
};

export default React.memo(Circle);
