import styled from 'styled-components';
import { HEIGHTS, COLORS, mediaQueries } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Icon from '../../Icon';

export const SearchIcon = styled(Icon)`
  align-items: center;
  margin: auto;
  padding-left: 0.5rem;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: ${HEIGHTS.userListFilterBar};
  ${mediaQueries.md(`{
    overflow:hidden;
  }`)}
`;

export const SearchField = styled.input`
  border: none;
  border-color: transparent;
  font-family: 'RobotoCondensed';
  background-color: ${COLORS.lightCyanGray};
  color: ${COLORS.mineShaft};
  padding-left: ${pxToRem('6px')};
  letter-spacing: -0.2px;
  font-size: ${pxToRem('13px')};
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: ${COLORS.greyChateau};
  }
  &::-moz-placeholder {
    color: ${COLORS.greyChateau};
  }
  &:-ms-input-placeholder {
    color: ${COLORS.greyChateau};
  }
  &:-moz-placeholder {
    color: ${COLORS.greyChateau};
  }
`;

const shouldSplit = ({ split }) => (split ? ` 1px solid ${COLORS.white}` : 'none');

export const SortByContainer = styled.div`
  display: flex;
  background-color: ${COLORS.lightCyanGray};
  height: 100%;
  align-items: center;
  border-left: ${shouldSplit};
  border-right: ${shouldSplit};
  width: ${({ width }) => width && `${200}rem`};
  flex-grow: 1;
`;

export const FilterByContainer = styled.div`
  position: relative;
  display: flex;
  background-color: ${COLORS.lightCyanGray};
  height: 100%;
  align-items: center;
  border-left: ${shouldSplit};
  border-right: ${shouldSplit};
  width: ${({ width }) => width && `${width}rem`};
  flex-grow: 1;
`;

export const Caption = styled.span`
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('13px')};
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  text-align: left;
  color: ${COLORS.mineShaft};
  letter-spacing: -0.2px;
  padding-left: 0.5rem;
  padding-top: ${pxToRem('1px')};
`;

export const SearchContainer = styled.div``;

export const FilterCaptionContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding-top: ${pxToRem('2px')};
`;

export const FilterCounterWrapper = styled.div`
  min-height: ${pxToRem('14px')};
  min-width: ${pxToRem('14px')};
  padding: ${pxToRem('2px')};
  margin-left: ${pxToRem('11px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${COLORS.denimBlue};
  margin-right: ${pxToRem('8px')};
`;

export const FilterCounter = styled.span`
  color: ${COLORS.white};
  font-size: ${pxToRem('9px')};
  font-family: sans-serif;
`;

export const FilterChevron = styled(Icon)`
  margin: ${pxToRem('4px')} 0 0 ${pxToRem('16px')};
`;
