import React from 'react';
import PropTypes from 'prop-types';

import GameSorter from './GameSorter';
import {
  Container,
  InnerContainer,
  NavItem,
  LabelsHeader,
  Options,
  ColumnHeader,
  LeftContent,
  CheckboxPlaceholder,
  SelectAllCheckbox
} from './styled-components';

const Subheader = ({
  sort: { onSort, value: sortingValue },
  sortingOptions,
  labels,
  handleSelectAll,
  checked,
  theme,
  checkbox
}) => (
  <Container>
    <InnerContainer>
      <Options>
        <LeftContent>
          {checkbox ? (
            <SelectAllCheckbox
              type="checkbox"
              onChange={e => handleSelectAll(e.target.checked)}
              checked={checked}
            />
          ) : (
            <CheckboxPlaceholder />
          )}
          <NavItem divider theme={theme.NavItem}>
            <GameSorter sortingOptions={sortingOptions} onSort={onSort} value={sortingValue} />
          </NavItem>
        </LeftContent>
      </Options>

      <LabelsHeader theme={theme.LabelsHeader}>
        {labels.map(({ label }, i) => (
          // Crew labels will not change within an event, no other unique value to use
          // eslint-disable-next-line react/no-array-index-key
          <ColumnHeader theme={theme.ColumnHeader} key={i}>
            {label}
          </ColumnHeader>
        ))}
      </LabelsHeader>
    </InnerContainer>
  </Container>
);

Subheader.propTypes = {
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  handleSelectAll: PropTypes.func,
  checked: PropTypes.bool,
  theme: PropTypes.shape({
    ColumnHeader: PropTypes.string,
    NavItem: PropTypes.string,
    LabelsHeader: PropTypes.string
  }),
  checkbox: PropTypes.bool,
  sortingOptions: PropTypes.arrayOf(Object)
};

Subheader.defaultProps = {
  checked: false,
  handleSelectAll: () => true,
  theme: {
    ColumnHeader: '',
    NavItem: '',
    LabelsHeader: ''
  },
  checkbox: true,
  sortingOptions: null
};

export default React.memo(Subheader);
