import React from 'react';
import { isNil } from 'ramda';
import { allAvailableProvinces } from '../../../../../constants';
import { normalizePhone, returnOnlyNumbers } from '../../../../../utils/parsers';
import MaterialInput from '../../../MaterialInput';
import {
  composeValidators,
  isValidAreaCode,
  minLength,
  mustBeEmail,
  required
} from '../../../../../utils/validations';
import { capitalize, sortListByKey } from '../../../../../utils/helpers';
import MaterialUiDropDown from '../../../../global-material-components/MaterialUiDropdown';
import MaterialUiTypehead from '../../../../global-material-components/MaterialUiTypehead';

const addNew = 'Add New';
const getBillingCustomerOption = billingCustomers => {
  const customers = [];
  const modifyCustomer =
    billingCustomers &&
    billingCustomers.length &&
    billingCustomers.map(billing_customer => ({
      value: billing_customer.id,
      name: `${capitalize(billing_customer.billing_name)}${
        !isNil(billing_customer.name) ? ` - ${billing_customer.name}` : ''
      }${!isNil(billing_customer.email) ? ` - ${billing_customer.email}` : ''} `
    }));
  let result = [];
  result = modifyCustomer && modifyCustomer.length ? customers.concat(modifyCustomer) : [];
  const events = result && result.length ? sortListByKey(result, 'name') : [];
  return events;
};

export default {
  getBillingFields: ({
    billingCustomers,
    setEventBillingModal,
    setUpdateEventBillingModal,
    mutators,
    isGamesEventGroupAdmin,
    billing,
    hasInvoice,
    initialValue,
    setSelectedBillingCustomer
  }) => {
    return [
      {
        field: {
          component: MaterialUiDropDown,
          value: initialValue,
          name: 'billing',
          helpText: 'Who should be invoiced for the game fees?*',
          options: getBillingCustomerOption(billingCustomers, billing),
          showError: true,
          validate: isGamesEventGroupAdmin ? required : '',
          fontSize: '12px',
          optionButton: addNew,
          defaultValue: '',
          onButtonClick: () => {
            setEventBillingModal(true);
            mutators.setBillingDropdown(billing);
          },
          onOptionClick: selectedValue => {
            const [payerInfo] = billingCustomers.filter(
              billingCustomer => billingCustomer.id === selectedValue
            );
            setSelectedBillingCustomer(payerInfo);
            setUpdateEventBillingModal(true);
          },
          disabled: !isGamesEventGroupAdmin || (initialValue && hasInvoice),
          theme: {
            listHeight: '200px'
          }
        }
      }
    ];
  },

  billingModalFields: ({ openModal, hasInvoice }) => [
    {
      field: {
        component: MaterialInput,
        name: 'billing_name',
        placeholder: 'Billing / Company Name*',
        showError: true,
        validate: openModal ? required : '',
        disabled: hasInvoice,
        theme: {
          input: `&:disabled{background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 26%, rgb(217 217 217) 28%, rgb(217 217 217) 100%)}`
        }
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'name',
        placeholder: 'Contact Name*',
        showError: true,
        validate: openModal ? required : ''
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'email',
        placeholder: 'Contact Email*',
        type: 'email',
        validate: openModal ? composeValidators(required, mustBeEmail) : ''
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'tax_id',
        alwaysShowError: true,
        placeholder: (
          <span>
            Tax Id(EIN / SSN)* <small>This is used for customer verification</small>
          </span>
        ),
        parse: returnOnlyNumbers,
        showError: true,
        validate: openModal
          ? composeValidators(required, minLength(9, 'Expected minimum 9 digits'))
          : '',
        maxLength: '9',
        margin: '0px 25px 0px 0px',
        disabled: hasInvoice,
        theme: {
          input: `&:disabled{background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 26%, rgb(217 217 217) 28%, rgb(217 217 217) 100%)}`
        }
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'phone_number',
        placeholder: 'Contact Phone',
        parse: normalizePhone,
        validate: openModal ? composeValidators(required, minLength(14), isValidAreaCode) : '',
        allowNull: true
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'address_1',
        placeholder: 'Address',
        parse: x => x,
        validate: openModal ? required : ''
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'address_2',
        placeholder: 'Address 2',
        parse: x => x
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'city',
        placeholder: 'City',
        parse: x => x,
        validate: openModal ? required : ''
      }
    },
    {
      field: {
        component: MaterialUiTypehead,
        name: 'state',
        placeholder: 'State/Province',
        options: allAvailableProvinces,
        parse: x => x,
        validate: openModal ? required : ''
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'postal_code',
        placeholder: 'Zip/Postal Code',
        maxLength: '7',
        parse: x => x,
        validate: openModal ? required : ''
      }
    }
  ]
};
