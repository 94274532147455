import styled from 'styled-components';
import { HEIGHTS, COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const EventFilterBarContainer = styled.div`
  display: flex;
`;

export const EventFilterBarInnerContainer = styled.div`
  flex-grow: 1;
  height: ${pxToRem(HEIGHTS.eventFilterBar)};
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  ${({ backgroundColor }) =>
    backgroundColor
      ? `background-color:${backgroundColor}`
      : `padding: 0 ${pxToRem('4px')} 0 ${pxToRem('34px')};
  background: ${COLORS.greenVogue};
  background: -moz-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${
    COLORS.greenVogue
  }), color-stop(100%, ${COLORS.bahamaBlue}));
  background: -webkit-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -o-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -ms-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: linear-gradient(to bottom, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${
    COLORS.greenVogue
  }', endColorstr='${COLORS.bahamaBlue}', GradientType=0);`};
`;

export const EventFilterBarSummaryHeader = styled.div`
  width: 44%;
  height: ${pxToRem('32px')};
  display: flex;
  box-sizing: border-box;
  background: ${COLORS.greenVogue};
  background: -moz-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${
    COLORS.greenVogue
  }), color-stop(100%, ${COLORS.bahamaBlue}));
  background: -webkit-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -o-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -ms-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: linear-gradient(to bottom, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${
    COLORS.greenVogue
  }', endColorstr='${COLORS.bahamaBlue}', GradientType=0);
`;

export const EventFilterBarItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const EventFilterBarLeftItemContainer = EventFilterBarItemContainer.extend`
  margin-left: ${pxToRem('50px')};
  position: relative;
`;

export const EventFilterBarRightItemContainer = EventFilterBarItemContainer.extend`
  margin-right: ${pxToRem('10px')};
`;

export const EventFilterBarItemInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EventFilterCounterWrapper = styled.div`
  min-height: ${pxToRem('12px')};
  min-width: ${pxToRem('12px')};
  padding: ${pxToRem('2px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${COLORS.denimBlue};
  margin-right: ${pxToRem('8px')};
`;

export const EventFilterCounter = styled.span`
  color: ${COLORS.white};
  font-size: ${pxToRem('9px')};
  font-family: sans-serif;
`;

export const EventFilterBarItem = styled.span`
  color: ${({ fontColor }) => fontColor || COLORS.white};
  font-size: ${pxToRem('13px')};
`;

export const EventFilterBarSummaryItem = styled.span`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.pattensBlue};
  font-size: ${pxToRem('12px')};
  text-align: center;
`;
