import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import { COLORS } from '../../../../../utils/styles';
import Icon from '../../../Icon';

import { pxToRem } from '../../../../../utils/helpers';

export const CenteredContentContainer = styled.div`
  width: 50%;
  justify-content: 'center';
  padding: 1.4rem 0.8rem;
  background: #fff;
`;

export const ContainerPadding = styled.div`
  padding: 0rem 0.4rem;
`;

export const InnerPageCol = styled(Col)`
  width: 100%;
`;

export const InnerFormCol = InnerPageCol.extend`
  margin-right: 0;
  padding: 0px 20px;
`;

export const TabsContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${COLORS.lightGray};
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: center;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ConfirmationIcon = styled(Icon)`
  position: relative;
  top: -1px;
  right: -10px;
`;

export const AccountText = styled.span`
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: ${({ color }) => color || `${COLORS.navyBlue}`}
  margin-left: ${({ marginL }) => marginL || 0}
  margin-top: ${({ marginT }) => marginT || 0}
  display: ${({ display }) => display || null}
`;

export const TextBorder = styled.div`
  margin-top: 1em;
`;

export const SectionsHeading = styled.div`
  margin-bottom: -0.8rem;
  color: black;
`;

export const PayoutText = styled.span`
  color: black;
`;

export const AddBtn = styled.button`
  font-size: 16px;
  padding: 5px 20px;
  border: 1px solid #ccc;
  background: ${COLORS.navyBlue};
  color: #fff;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  &:disabled {
    background: ${COLORS.veryLightGray};
    color: ${COLORS.lightGrey};
  }
`;
export const CarretBtnWrapper = styled.button`
  padding: 0px 10px;
  background: #fff;
  border: 0px;
`;

export const RowsMarginZero = styled(Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
`;

export const RowElementCenter = styled(Row)`
  justify-content: center;
  align-item: center;
  margin-left: 0px !important;
  margin-right: 0px !important;
`;

export const RowsWrapper = styled.div`
  margin-left: 0px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  border: 1px solid ${COLORS.veryLightGray};
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 0px;
`;

export const BatchTitle = styled.h3`
  color: ${COLORS.darkBlue};
  font-size: ${pxToRem('32px')};
  font-weight: normal;
  padding: 0;
  margin: 0;
`;

export const BatchNumber = styled.div`
  background: ${COLORS.veryLightGray};
  padding: ${pxToRem('3px')} ${pxToRem('10px')};
  border-radius: ${pxToRem('13px')};
  margin: ${pxToRem('10px')} 0px;
  font-size: ${pxToRem('13px')};
  color: ${COLORS.offBlack};
`;

export const TileTextWrapper = styled.div`
  font-size: ${({ size }) => size || pxToRem('11px')};
  letter-spacing: ${pxToRem('0.3px')};
  color: ${COLORS.greyChateau};
  margin: ${({ margin }) => margin || 0};
`;

export const TileText = styled.span`
  color: ${COLORS.offBlack};
`;

export const BatchTotal = styled.h3`
  color: ${COLORS.offBlack};
  font-size: ${({ size }) => size || pxToRem('24px')};
  padding: 0;
  margin: ${({ margin }) => margin || 0};
`;

export const BarDiv = styled.div`
  border-left: ${({ green }) =>
    green
      ? `${pxToRem('7px')} solid ${COLORS.forestGreen}`
      : `${pxToRem('7px')} solid ${COLORS.lightCyanGray}`};
  margin: ${({ margin }) => margin || 0};
  padding-left: ${pxToRem('10px')};
`;

export const NoBatchSubHeader = styled.p`
  margin: ${({ margin }) => margin || 0};
  padding: 0;
`;

export const ModalContainer = styled.div`
  font-size: 13px;
  padding: 20px;
`;

export const CardElementWrapper = styled.div`
  width: 360px;
  height: 580px;
  padding-top: ${props => (props.paddingT ? props.paddingT : '20px')};
  margin: 0 auto;
`;
export const CardWrapper = styled.div`
  padding: 20px;
`;

export const ACHWrapper = styled.div`
  height: 420px;
  width: 400px;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const SuccessRow = styled.div`
  text-align: center;
`;

export const BatchRow = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0;
  width: 430px;
`;

export const BatchAmount = styled.div`
  font-size: 24px;
  color: ${COLORS.denimBlue};
`;

export const Batch = styled.span`
  background-color: ${COLORS.athensGrayDarker};
  border-radius: 13px;
  padding: 3px 10px;
`;

export const FundTotal = styled.span`
  color: ${COLORS.denimBlue};
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
`;

export const FundTotalText = styled.span`
  color: ${COLORS.cobaltGrey};
  padding-left: 10px;
  position: relative;
  top: 3px;
`;

export const IconSuccess = styled(Icon)`
  margin: 20px 0 12px;
`;

export const FundStyle = {
  margin: '12px 0'
};

export const InputStyle = {
  fontSize: '13px',
  width: '430px',
  marginTop: '0',
  marginBottom: '16px',
  zIndex: 1
};

export const Overlay = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${props => (props.show ? 'block' : 'none')};
  &:before {
    content: '';
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

export const SpinnerMessage = styled.div`
  color: white;
`;

export const VerifyText = styled.div`
  color: black;
  padding-bottom: 1rem;
`;

export const StyleBackButton = styled.div`
  color: black;
  margin-right: 1rem;
`;

export const GhostStyle = {
  fontSize: '14px',
  fontWeight: '500',
  paddingRight: '40px'
};

export const GhostContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AddIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 9px;
`;

/* Span colors */
export const SpanColor = styled.span`
  color: #697386;
`;

export const SpanGrey = styled.span`
  color: #a3acb9;
`;

export const SpanBlack = styled.span`
  color: ${COLORS.black};
`;

export const SpanBlue = styled.span`
  color: ${COLORS.denimBlue};
`;

/* End Span colors */

export const SectionHeading = styled.h2`
  font-weight: 600;
  font-size: 18px;
  margin: 5px 0px 20px 0px;
`;
