import Dropdown from '../../../Reusable/Dropdown';
import MultiSelectDropdown from '../../../Reusable/MultiSelectDropdown';
import MultipleInput from '../../../Reusable/MultipleInput';
import theme from './styled-components';
import MinMax from '../../../Reusable/MinMax';
import { allAvailableProvinces, CORE_ROLES_DROPDOWN } from '../../../../../constants';

export default ({ categories }) => ({
  filters: [
    {
      component: MultipleInput,
      name: 'user.first_name',
      placeholder: 'First Name'
    },
    {
      component: MultipleInput,
      name: 'user.last_name',
      placeholder: 'Last Name'
    },
    {
      component: MultiSelectDropdown,
      name: 'role_id',
      placeholder: 'SO Role',
      options: CORE_ROLES_DROPDOWN.map(({ name, value }) => ({
        name,
        value
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MinMax,
      name: ['age_min', 'age_max'],
      placeholder: 'Age',
      theme: theme.multipleInput,
      hasCustomFields: true,
      convert: true,
      color: 'white',
      maxLength: 2
    },
    {
      component: Dropdown,
      name: 'user.gender',
      placeholder: 'Gender',
      options: [
        {
          name: 'Male',
          value: 'male'
        },
        {
          name: 'Female',
          value: 'female'
        }
      ],
      theme: theme.multiSelectDropdown
    },
    {
      component: MultiSelectDropdown,
      name: 'user.address_primary.state',
      placeholder: 'State/Province',
      options: allAvailableProvinces.map(({ name, value }) => ({
        name,
        value
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MultipleInput,
      name: 'user.address_primary.postal_code',
      placeholder: 'Zip Code',
      maxLength: 7,
      theme: theme.multipleInput
    },
    {
      component: MinMax,
      name: ['rank_min', 'rank_max'],
      placeholder: 'Rank',
      theme: theme.multipleInput,
      hasCustomFields: true,
      convert: true,
      color: 'white'
    },
    {
      component: MultiSelectDropdown,
      name: 'categories_filtered.category_id',
      placeholder: 'Category',
      options:
        categories &&
        categories.map(category => ({
          value: category.id,
          name: category.name
        })),
      noCount: true,
      theme: theme.multiSelectDropdown
    }
  ],
  allEventsFilter: [
    {
      component: MultipleInput,
      name: 'user.first_name',
      placeholder: 'First Name'
    },
    {
      component: MultipleInput,
      name: 'user.last_name',
      placeholder: 'Last Name'
    },
    {
      component: MultiSelectDropdown,
      name: 'role_id',
      placeholder: 'SO Role',
      options: CORE_ROLES_DROPDOWN.map(({ name, value }) => ({
        name,
        value
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MinMax,
      name: ['age_min', 'age_max'],
      placeholder: 'Age',
      theme: theme.multipleInput,
      hasCustomFields: true,
      convert: true,
      color: 'white',
      maxLength: 2
    },
    {
      component: Dropdown,
      name: 'user.gender',
      placeholder: 'Gender',
      options: [
        {
          name: 'Male',
          value: 'male'
        },
        {
          name: 'Female',
          value: 'female'
        }
      ],
      theme: theme.multiSelectDropdown
    },
    {
      component: MultiSelectDropdown,
      name: 'user.address_primary.state',
      placeholder: 'State/Province',
      options: allAvailableProvinces.map(({ name, value }) => ({
        name,
        value
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MultipleInput,
      name: 'user.address_primary.postal_code',
      placeholder: 'Zip Code',
      maxLength: 7,
      theme: theme.multipleInput
    }
  ]
});
