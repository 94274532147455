export default {
  options: [
    {
      name: 'name',
      label: 'Event Name'
    },
    {
      name: 'group_name',
      label: 'Group Name'
    },
    {
      name: 'area_state',
      label: 'State/Province'
    },
    {
      name: 'sport_name',
      label: 'Sport'
    }
  ]
};
