import React from 'react';
import { Dialog } from '../../../global-material-components';
import { MandateContent } from './styled-components';
import LoaderWrapper from '../../LoaderWrapper';
import SanitizeHTML from '../../SanitizeHtml';

const MandateDialog = ({ open, toggle, mandate }) => (
  <Dialog title="Terms and Conditions" onClose={() => toggle(false)} open={open} noCancel noPrimary>
    <LoaderWrapper isFetching={mandate.fetching}>
      <MandateContent>
        <SanitizeHTML>{mandate.data && mandate.data.doc_agreement.doc_text}</SanitizeHTML>
      </MandateContent>
    </LoaderWrapper>
  </Dialog>
);

export default MandateDialog;
