import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import {
  createUserAvailability,
  fetchUserAvailabilities,
  updateUserAvailability,
  deleteUserAvailability,
  setShowCalendar as setShowCalendarAction,
  fetchActiveUserInfo,
  fetchUserAvailabilityNote as fetchUserAvailabilityNoteAction,
  setUserAvailabilityNote
} from '../../../core/User';
import {
  getAvailabilityNote,
  getAvailabilityNoteisFetching,
  getUserAvailabilities
} from '../../../core/User/selectors';

import unconnected from './unconnected';
import { getActiveUser, getUser, isSuperAdmin } from '../../../core/Auth/selectors';

const mapStateToProps = (state, props) => ({
  availabilities: getUserAvailabilities(state),
  isFetching: state.user.isFetching,
  calendarIcon: state.user.calendarIcon,
  currentUserId: state.auth && state.auth.user && state.auth.user.id,
  userId: state.auth && state.user.activeUser && state.user.activeUser.id,
  paramId: props && props.match && props.match.params && props.match.params.userId,
  pathUrl: props && props.match && props.match.path,
  isFetchingPayee: state.auth && state.auth.isFetchingPayee,
  localTimezone: moment.tz.guess(),
  availabilityNote: getAvailabilityNote(state),
  availabilityNotefetching: getAvailabilityNoteisFetching(state),
  isSuperAdmin: isSuperAdmin(state),
  allowAdminUpdates: getActiveUser(state).allow_admin_updates,
  availabilityAccessPermissions: getUser(state).cross_edit_permissions.AVAILABILITY
});

const mapDispatchToProps = dispatch => ({
  onCreateUserAvailability: payload => dispatch(createUserAvailability(payload)),
  onUpdateUserAvailability: payload => dispatch(updateUserAvailability(payload)),
  fetchAvailabilities: payload => dispatch(fetchUserAvailabilities(payload)),
  deleteUserAvailability: payload => dispatch(deleteUserAvailability(payload)),
  setShowCalendarAction: payload => dispatch(setShowCalendarAction(payload)),
  fetchActiveUserInfo: payload => dispatch(fetchActiveUserInfo(payload)),
  fetchUserAvailabilityNote: payload => dispatch(fetchUserAvailabilityNoteAction(payload)),
  setUserAvailabilityNote: payload => dispatch(setUserAvailabilityNote(payload))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), unconnected);
