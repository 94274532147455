import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Col } from 'react-flexbox-grid';
import OutsideClickHandler from 'react-outside-click-handler';

import Icon from '../Icon';
import Button from '../Button';
import Enhancer from '../../containers/Filters/unconnected';

import {
  Wrapper,
  ExpandableWrapper,
  FieldWrapper,
  ButtonsRow,
  Count,
  Label,
  FilterOptionsContainer,
  NavOption,
  FieldWrapperTime,
  AvailabityLabel
} from './styled-components';
import { formConverter } from '../../../utils/helpers';
import { COLORS, ICONS } from '../../../utils/styles';

const AvailabityField = ['filter_date', 'start_time', 'end_time'];
const DEFAULT_TIMES = { START: '12:00 am', END: '11:59 pm' };

const Filter = ({ field, field: { component: Component } }) =>
  field && AvailabityField.includes(field.name) ? (
    <>
      {field.name === AvailabityField[0] && (
        <Col xs={12} md={2}>
          <FieldWrapper>
            <AvailabityLabel>Availability</AvailabityLabel>
          </FieldWrapper>
        </Col>
      )}

      {field.name === AvailabityField[0] ? (
        <Col xs={12} md={4}>
          <FieldWrapper>
            {field.hasCustomFields ? (
              <Component
                {...field}
                name={
                  Array.isArray(field.name)
                    ? field.name.map(fieldName => formConverter.convert(fieldName))
                    : formConverter.convert(field.name)
                }
              />
            ) : (
              <Field {...field} name={formConverter.convert(field.name)} withTags />
            )}
          </FieldWrapper>
        </Col>
      ) : (
        <Col xs={12} md={3}>
          <FieldWrapperTime>
            {field.hasCustomFields ? (
              <Component
                {...field}
                name={
                  Array.isArray(field.name)
                    ? field.name.map(fieldName => formConverter.convert(fieldName))
                    : formConverter.convert(field.name)
                }
              />
            ) : (
              <Field {...field} name={formConverter.convert(field.name)} withTags />
            )}
          </FieldWrapperTime>
        </Col>
      )}
    </>
  ) : (
    <Col xs={12} md={12} lg={!field.isDistanceFilter ? 6 : 12}>
      <FieldWrapper>
        {field.hasCustomFields ? (
          <Component
            {...field}
            name={
              Array.isArray(field.name)
                ? field.name.map(fieldName => formConverter.convert(fieldName))
                : formConverter.convert(field.name)
            }
          />
        ) : (
          <Field {...field} name={formConverter.convert(field.name)} withTags />
        )}
      </FieldWrapper>
    </Col>
  );

Filter.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
  }).isRequired
};

/**
 * Configurable filters component that takes paginationAware middleware filter props and a config array
 * @param {Object} filterData object with filters and values provided by paginationAware middleware
 * @param {Function} onClear clear action provided by paginationAware middleware
 * @param {Function} onSort used to call onSort('') to reset the sort list
 * @param {Array} config array of configurable filter fields to render
 * @param {boolean} sideList *optional* flag to set styles/layout to match sidelist, defaults to false
 */
const Filters = ({
  countFilters,
  filterData,
  onClear,
  isExpanded,
  toggleExpanded,
  onSubmit,
  sideList,
  config,
  filterUtils,
  onSort
}) => {
  // This allows the sort to be reset when the filters is cleared
  const clearButtonClickRef = useRef(false);
  useEffect(() => {
    if (onSort && clearButtonClickRef.current) {
      onSort('');
      clearButtonClickRef.current = false; // Reset the flag after running onSort
    }
  }, [onSort]);

  return (
    <Wrapper>
      <OutsideClickHandler
        onOutsideClick={() =>
          !document.getElementsByClassName('time-picker-bring-to-front').length &&
          isExpanded &&
          toggleExpanded(false)
        }
      >
        <Form
          onSubmit={onSubmit}
          initialValues={formConverter.convert(filterData)}
          mutators={{
            setStartEndTimes: ([start, end], state, utils) => {
              utils.changeValue(state, AvailabityField[1], () => start);
              utils.changeValue(state, AvailabityField[2], () => end);
            }
          }}
          render={({ handleSubmit, invalid, pristine, values, change, form: { mutators } }) => (
            <form onSubmit={handleSubmit}>
              <NavOption onClick={() => toggleExpanded(s => !s)} sideList={sideList}>
                <Count>
                  {filterUtils && filterUtils.getCount
                    ? filterUtils.getCount(values)
                    : countFilters({ values })}
                </Count>
                <Label sideList={sideList}>Filter</Label>
                <Icon
                  height={10}
                  width={10}
                  icon={ICONS.BOLD_CHEVRON_DOWN}
                  color={sideList ? COLORS.nightRider : COLORS.white}
                />
              </NavOption>
              {isExpanded && (
                <ExpandableWrapper sideList={sideList}>
                  <FilterOptionsContainer>
                    {config.map(
                      field =>
                        field && (
                          <Filter
                            key={field.name}
                            style={field.style}
                            field={(() => {
                              const updatedField = field;
                              if (
                                (!values.filter_date && updatedField.name === AvailabityField[1]) ||
                                (!values.start_time && updatedField.name === AvailabityField[2])
                              ) {
                                updatedField.disabled = true;
                              } else {
                                updatedField.disabled = false;
                                if (
                                  values.filter_date &&
                                  ((!values.start_time &&
                                    updatedField.name === AvailabityField[1]) ||
                                    (!values.start_time &&
                                      updatedField.name === AvailabityField[2]))
                                ) {
                                  mutators.setStartEndTimes(DEFAULT_TIMES.START, DEFAULT_TIMES.END);
                                } else if (
                                  !values.filter_date &&
                                  ((values.start_time &&
                                    updatedField.name === AvailabityField[1]) ||
                                    (values.start_time && updatedField.name === AvailabityField[2]))
                                ) {
                                  mutators.setStartEndTimes(null, null);
                                  updatedField.disabled = true;
                                }
                              }
                              return updatedField;
                            })()}
                          />
                        )
                    )}
                  </FilterOptionsContainer>
                  <ButtonsRow sideList={sideList}>
                    <Button
                      type="submit"
                      width="72px"
                      height="32px"
                      margin="0 8px 0"
                      label="Apply"
                      disabled={invalid || pristine}
                      primary
                      noShadow
                    />
                    <Button
                      width="84px"
                      height="32px"
                      margin="0 8px 0"
                      label="Clear All"
                      noShadow
                      onClick={() => {
                        Object.keys(values).forEach(key => change(key, null));
                        onClear();
                        // Set the flag after running onClear
                        clearButtonClickRef.current = true;
                        toggleExpanded(false);
                      }}
                    />
                  </ButtonsRow>
                </ExpandableWrapper>
              )}
            </form>
          )}
        />
      </OutsideClickHandler>
    </Wrapper>
  );
};

Filters.propTypes = {
  countFilters: PropTypes.func.isRequired,
  config: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  filterData: PropTypes.shape({}).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  sideList: PropTypes.bool
};

Filters.defaultProps = {
  sideList: false
};

export default Enhancer(React.memo(Filters));
