import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import IncidentReportsModal from '../../components/Games/Modals/IncidentReportsModal';
import GameReportModal from '../../components/Games/Modals/GameReportModal';
import CrewVerificationReportModal from '../../components/Games/Modals/CrewVerificationReportModal';
import { getShowModals } from '../../../core/Modals/selectors';
import { setShowModalFor as setShowModalForAction } from '../../../core/Modals';
import unconnected from './unconnected';

const mapStateToProps = state => ({
  showModals: getShowModals(state)
});

const mapDispatchToProps = dispatch => ({
  setShowModalFor: payload => dispatch(setShowModalForAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps({
    IncidentReportsModal,
    GameReportModal,
    CrewVerificationReportModal
  }),
  unconnected
);
