import React from 'react';
import { PrimaryButton } from '../../../global-material-components';
import { AccountText, ContainerPadding, RowWrapper } from './styled-components';
import ConfirmationPopup from '../../popupModals/ComfirmationPopup';

export default function TaxIdentification({
  account: accounts,
  addPayeeAccountAction,
  editPayeeAccountAction,
  userId,
  currentUserId
}) {
  const isLoggedInUser = currentUserId === userId;
  const [confirmationPopupAction, setConfirmationPopupAction] = React.useState(false);

  const handleConfirmationPopup = () => {
    setConfirmationPopupAction(true);
  };
  const accountExists = accounts && !!accounts.length;

  return (
    <div>
      {accountExists &&
        accounts.map(account =>
          account && account.id ? (
            <RowWrapper center="xs" key={account.id}>
              <ContainerPadding style={{ padding: '10px' }}>
                <AccountText color="#484848" marginL="0.8rem" marginT="0.8rem" display="block">
                  To Enable Payout To your Account, please ensure our payment partner has your
                  taxpayer ID on file.
                </AccountText>
                <PrimaryButton
                  texttransform="uppercase"
                  onClick={() =>
                    editPayeeAccountAction({
                      id: account.id,
                      userId
                    })
                  }
                  disabled={!isLoggedInUser}
                  style={{ marginBottom: '20px' }}
                >
                  Update Taxpayer ID
                </PrimaryButton>
                <AccountText color="#484848" marginL="0.8rem" marginT="0.8rem" display="block">
                  Tax Forms (when applicable) are available from our payment provider. See our Help
                  Center FAQs.
                </AccountText>
                <PrimaryButton
                  textTransform="uppercase"
                  onClick={() =>
                    editPayeeAccountAction({
                      id: account.id,
                      userId
                    })
                  }
                  disabled={!isLoggedInUser}
                >
                  View / Download 1099 Forms
                </PrimaryButton>
              </ContainerPadding>
            </RowWrapper>
          ) : null
        )}
      {!accountExists && (
        <RowWrapper center="xs">
          <ContainerPadding style={{ padding: '10px' }}>
            {confirmationPopupAction && (
              <ConfirmationPopup
                title="Confirmation"
                description="We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click Continue to set up your payment profile on Stripe."
                SubmitBtnText="Continue"
                confirmationPopupAction={confirmationPopupAction}
                setConfirmationPopupAction={setConfirmationPopupAction}
                primaryActionFun={addPayeeAccountAction}
              />
            )}
            <AccountText color="#484848" marginL="0.8rem" marginT="0.8rem" display="block">
              To Enable Payout To your Account, please ensure our payment partner has your taxpayer
              ID on file.
            </AccountText>
            <PrimaryButton
              texttransform="uppercase"
              onClick={() => handleConfirmationPopup()}
              disabled={!isLoggedInUser}
              style={{ marginBottom: '20px' }}
            >
              Update Taxpayer ID
            </PrimaryButton>
            <AccountText color="#484848" marginL="0.8rem" marginT="0.8rem" display="block">
              Tax Forms (when applicable) are available from our payment provider. See our Help
              Center FAQs.
            </AccountText>
            <PrimaryButton
              textTransform="uppercase"
              onClick={() => handleConfirmationPopup()}
              disabled={!isLoggedInUser}
            >
              View / Download 1099 Forms
            </PrimaryButton>
          </ContainerPadding>
        </RowWrapper>
      )}
    </div>
  );
}
