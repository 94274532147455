import { compose } from 'recompose';
import { connect } from 'react-redux';

import { createGroup as createGroupAction } from '../../../core/Auth';
import { getGroups, isSuperAdmin as isSuperAdminSelector } from '../../../core/Auth/selectors';

import unconnected from './unconnected';

const mapStateToProps = state => ({
  isSuperAdmin: isSuperAdminSelector(state),
  groups: getGroups(state),
  isFetching: state.auth.isFetching
});

const mapDispatchToProps = {
  createGroup: createGroupAction
};

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
