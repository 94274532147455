import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { HEIGHTS, COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  height: ${pxToRem(HEIGHTS.paginationBar)};
  border-top: 1px solid ${COLORS.pattensBlue};
  color: ${COLORS.shuttleGray};
  font-size: ${pxToRem('11px')};
  padding: ${pxToRem('10px')} 0 ${pxToRem('8px')} ${pxToRem('11px')};
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  bottom: 0px;
  background: #fff;
  ${mediaQueries.md(`{
   position:relative;

   background:linear-gradient(to top,#F4F8FB,#ffffff);
  }`)}
`;

export const PageNumberWrapper = styled(NavLink)`
  color: inherit;
  cursor: pointer;
  display: inline-block;
  padding: 0 0.25rem;
  font-weight: bold;
  font-family: ${({ light }) => (light ? 'RobotoCondensedLight' : 'Roboto')};
  letter-spacing: 0.8px;
  &.active {
    color: ${COLORS.denimBlue};
  }
`;

export const DefaultChevrons = styled.span`
  color: inherit;
  cursor: default;
  display: inline-block;
  padding: 0 0.25rem;
  font-family: 'RobotoCondensedLight';
  letter-spacing: 0.8px;
`;

export const ResultsText = styled.span`
  font-family: 'RobotoCondensedLight';
  letter-spacing: 0.4px;
  padding: 0 0.25rem;
  font-weight: bold;
`;
