import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TilesTitle,
  Grid,
  Tile,
  TitleRow,
  TitleText,
  ViewButton,
  Flex,
  Circle,
  InfoItem,
  ContentWrapper,
  KillLoaderWrapperStyleIssue
} from './styled-components';
import LoaderWrapper from '../../LoaderWrapper';
import Enhancer from '../../../containers/Payments/MonthTiles';
import { COLORS } from '../../../../utils/styles';
import { numToCurrency } from '../../../../utils/parsers';

const MonthTiles = ({ isFetching, monthTilesData, createMonthBatch }) => (
  <LoaderWrapper isFetching={isFetching}>
    <KillLoaderWrapperStyleIssue>
      {!!monthTilesData.length && (
        <ContentWrapper>
          <Grid margin="0px 0px 40px">
            <Flex justification="flex-start">
              <TilesTitle>Create pay batch by month</TilesTitle>
            </Flex>
            <div />
            <Flex justification="flex-end" alignment="center">
              <Circle blue /> <TitleText margin="0px 20px 0px 10px">Batched</TitleText>
              <Circle /> <TitleText margin="0px 0px 0px 10px">Total</TitleText>
            </Flex>
          </Grid>
          <Grid columns={2} gutter="25px">
            {monthTilesData.map(monthSummary => {
              const {
                month,
                id,
                games_batched,
                games_total,
                crew_batched,
                crew_total,
                pay_batched,
                pay_total
              } = monthSummary;
              const start_date = moment(month, 'MMMM, YYYY').format('YYYY-MM-DD');
              const end_date = moment(month, 'MMMM, YYYY').endOf('month').isAfter(moment())
                ? moment().format('YYYY-MM-DD')
                : moment(month, 'MMMM, YYYY').endOf('month').format('YYYY-MM-DD');
              const slash = (
                <TitleText color={COLORS.shuttleGray} margin="0px 3px">
                  /
                </TitleText>
              );
              return (
                <Tile
                  onClick={() => createMonthBatch({ data: { start_date, end_date } })}
                  key={`monthTile${id}`}
                  disabled={moment().isBefore(moment(month))}
                >
                  <TitleRow>
                    <TitleText margin="10px 0px">{month}</TitleText>
                    <ViewButton>View Month</ViewButton>
                  </TitleRow>
                  <Grid>
                    <InfoItem>
                      <TitleText size="13px">Games</TitleText>
                      <Flex>
                        <TitleText color={COLORS.denimBlue}>{games_batched}</TitleText>
                        {slash}
                        <TitleText color={COLORS.shuttleGray}>{games_total}</TitleText>
                      </Flex>
                    </InfoItem>
                    <InfoItem border>
                      <TitleText size="13px">Assignments</TitleText>
                      <Flex>
                        <TitleText color={COLORS.denimBlue}>{crew_batched}</TitleText>
                        {slash}
                        <TitleText color={COLORS.shuttleGray}>{crew_total}</TitleText>
                      </Flex>
                    </InfoItem>
                    <InfoItem>
                      <TitleText size="13px">Payments</TitleText>
                      <Flex>
                        <TitleText color={COLORS.denimBlue}>{numToCurrency(pay_batched)}</TitleText>
                        {slash}
                        <TitleText color={COLORS.shuttleGray}>{numToCurrency(pay_total)}</TitleText>
                      </Flex>
                    </InfoItem>
                  </Grid>
                </Tile>
              );
            })}
          </Grid>
        </ContentWrapper>
      )}
    </KillLoaderWrapperStyleIssue>
  </LoaderWrapper>
);

MonthTiles.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  monthTilesData: PropTypes.arrayOf(Object).isRequired,
  createMonthBatch: PropTypes.func.isRequired
};

export default Enhancer(MonthTiles);
