import { connect } from 'react-redux';
import { compose } from 'recompose';

import { deleteUserRole } from '../../../../../core/User';
import unconnected from './unconnected';

const mapDispatchToProps = dispatch => ({
  deleteUserRole: payload => dispatch(deleteUserRole(payload))
});

export default compose(connect(null, mapDispatchToProps), unconnected);
