import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import BatchCreation from './BatchCreation';
import Funding from './Funding';
import MonthTiles from './MonthTiles';
import Header from './Header';
import Enhancer from '../../containers/Payments';
import { PaymentsContainer, PaymentBodyWrapper } from './styled-components';
import { EmptyMessage } from '../StyledGames/styled-components';

import stripe from '../../../utils/stripe';
import { PAYMENTS_VIEW } from '../../../constants';

const Payments = ({
  events,
  storePaymentsEvent,
  paymentsEvent,
  submitBatch,
  activeBatch: batch,
  deleteActiveBatch,
  editActiveBatch,
  paymentsViewSelected,
  setPaymentsViewSelected,
  isFetching,
  eventBatches,
  viewBatch,
  addNotifications,
  isPaymentsEventGroupAdmin,
  location,
  isSuperAdmin,
  isOnlyFundingAdmin
}) => {
  return (
    <Elements stripe={stripe.getStripe()}>
      <PaymentsContainer>
        <Header
          storePaymentsEvent={storePaymentsEvent}
          paymentsEvent={paymentsEvent}
          paymentsViewSelected={paymentsViewSelected}
          setPaymentsViewSelected={setPaymentsViewSelected}
          isFundingAdmin={isOnlyFundingAdmin}
          isPaymentsEventGroupAdmin={isPaymentsEventGroupAdmin}
          isSuperAdmin={isSuperAdmin}
        />
        <PaymentBodyWrapper>
          {!!paymentsEvent &&
            (paymentsViewSelected === PAYMENTS_VIEW.GAME_PAY ? (
              <>
                <BatchCreation
                  name={paymentsEvent.name}
                  submitBatch={submitBatch}
                  batch={batch}
                  deleteActiveBatch={deleteActiveBatch}
                  editActiveBatch={editActiveBatch}
                />
                <MonthTiles />
              </>
            ) : (
              <Funding
                isFetching={isFetching}
                eventBatches={eventBatches}
                viewBatch={viewBatch}
                addNotifications={addNotifications}
                location={location}
                paymentsEvent={paymentsEvent}
              />
            ))}
        </PaymentBodyWrapper>
        {!events.length && <EmptyMessage>No events available</EmptyMessage>}
        {!!events.length && !paymentsEvent && <EmptyMessage>Please select an event</EmptyMessage>}
      </PaymentsContainer>
    </Elements>
  );
};

Payments.propTypes = {
  events: PropTypes.arrayOf(Object),
  paymentsEvent: PropTypes.shape({
    id: PropTypes.string
  }),
  storePaymentsEvent: PropTypes.func.isRequired,
  submitBatch: PropTypes.func.isRequired,
  activeBatch: PropTypes.objectOf(Object).isRequired,
  deleteActiveBatch: PropTypes.func.isRequired,
  editActiveBatch: PropTypes.func.isRequired,
  paymentsViewSelected: PropTypes.string.isRequired,
  setPaymentsViewSelected: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  eventBatches: PropTypes.arrayOf(Object),
  viewBatch: PropTypes.func.isRequired
};

Payments.defaultProps = {
  events: [],
  paymentsEvent: null,
  eventBatches: []
};

export default Enhancer(Payments);
