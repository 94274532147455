import { takeLatest, call, put, select, all, take, takeEvery } from 'redux-saga/effects';
import { compose, always, isEmpty } from 'ramda';
import moment from 'moment';

import { delay } from 'redux-saga';
import {
  FETCH_GAMES,
  setGames,
  setSelectedGamesEvent,
  setGamesEventCrewLabels,
  addGameAssignmentToGame,
  removeGameAssignmentFromGame,
  updateGameAssignmentToGame,
  setIsFetching,
  STORE_GAMES_EVENT,
  STORE_GAMES_EVENT_AND_REDIRECT,
  FETCH_GAMES_EVENT_CREW_LABELS,
  CREATE_GAME_ASSIGNMENT,
  DELETE_GAME_ASSIGNMENTS,
  UPDATE_GAMES,
  DELETE_BULK_GAMES,
  UPDATE_GAME_ASSIGNMENTS,
  CREATE_INCIDENT_REPORT,
  FETCH_CURRENT_INCIDENT_REPORTS,
  setCurrentIncidentReports,
  setCurrentIsFetching,
  addIncidentReport,
  EDIT_INCIDENT_REPORT,
  updateIncidentReport as updateIncidentReportAction,
  DELETE_INCIDENT_REPORT,
  removeIncidentReport,
  CREATE_GAME_REPORT,
  FETCH_CURRENT_GAME_REPORTS,
  FETCH_GAME_REPORT,
  setCurrentGameReports,
  setGameReport,
  addGameReport,
  EDIT_GAME_REPORT,
  updateGameReport as updateGameReportAction,
  DELETE_GAME_REPORT,
  removeGameReport,
  FETCH_CURRENT_GAME,
  setCurrentGame,
  SUBMIT_GAMES_IMPORT,
  SUBMIT_GAMES_UPDATE,
  setAssignmentFetching,
  CREATE_CREW_VERIFICATION_REPORT,
  FETCH_CURRENT_CREW_VERIFICATION_REPORTS,
  setCurrentCrewVerificationReports,
  addCrewVerificationReport,
  setCrewVerificationReports,
  SUBMIT_COPIED_GAME_ID,
  isCopiedGameIdCorrect,
  setFetchingCopiedGameId,
  FETCH_ASSIGNMENT_NOTE,
  setAssignmentNote,
  isfetchingAssignmentNote,
  setCurrentCrewVerificationNotes
} from './index';
import { setCurrentReport } from '../Reports';
import {
  addNotifications,
  ALLOW_IGNORE_RULES_CLEAR,
  setAssignmentWarnings
} from '../Notifications';
import {
  getLimit,
  getGamesEventId,
  getCurrentGame,
  getCalendarEventId,
  isGamesEventGroupAdmin,
  getPaginationPage
} from './selectors';
import Api from '../../utils/api-wrapper';
import { errorProgressDecorate, errorDecorate } from '../Progress/helper';
import { showProgress, hideProgress } from '../Progress';
import { dataWithPagination, mergeData } from '../../utils/parsers';
import { userIdxGetter, persistGamesEvent } from '../../utils/auth';
import {
  sInterpolator as routeBuilder,
  conditionallySetDefaultFilter,
  formatGameFilterStartDate,
  formatGameFilterEndDate
} from '../../utils/helpers';
import { history } from '../store';
import { GAMES } from '../paths';
import {
  fetchEventGameLevels,
  SET_GAME_LEVELS,
  addEventUserGameAssignment,
  removeEventUserGameAssignment
} from '../Events';
// import { addAssignment, removeAssignment } from '../Users';
import { getEventCategories, getEventGameLevels } from '../Events/selectors';
import {
  isGamesEventAdminOrAssignor,
  isReportsEventAdmin,
  selectUserId,
  isReportEventAssignor
} from '../Auth/selectors';
import { GAMES_STATUSES, GAME_ASSIGNMENT_STATUSES } from '../../constants';
import { UNASSIGNED_ASSIGNMENT_STATUS } from './constants';
import { applyAssignmentFilter } from '../../utils/assignmentValidations';
import {
  addAssignmentToSelfAssignGames,
  addAssignmentToUseSchedule,
  setFetching,
  SELFASSIGNGAMES
} from '../User';
// import { fetchEventUsers } from '../Events/sagas';
import { fetchEventUniqueTeams } from '../Teams';
import { fetchEventComplexs } from '../Locations';

const DEFAULT_SORT = 'date_location_time';
export function* processFetchGames({
  payload: {
    filters = {},
    byEvent = true,
    page,
    sort,
    toCSV,
    assignmentFilter,
    search,
    resolve = () => true
  }
}) {
  const eventId = yield select(getGamesEventId);
  if (!byEvent || eventId) {
    yield put(
      fetchEventGameLevels({
        eventId,
        filters: conditionallySetDefaultFilter(history, {}, 'payments', 'set_crew_pay', true)
      })
    );
    yield take(SET_GAME_LEVELS);
    yield put(fetchEventUniqueTeams({ eventId }));
    yield put(fetchEventComplexs({ eventId }));

    const isGroupAdmin = yield select(isGamesEventGroupAdmin({ params: { eventId } }));
    const gamesEventCategories = yield select(getEventCategories);
    const gamesEventGameLevels = yield select(getEventGameLevels);
    const gameCategoryFilters = filters['categories_filtered.category_id'] || [];
    const modifiedFilters = {
      ...conditionallySetDefaultFilter(
        history,
        filters,
        'payments',
        'game.end_date',
        moment().format('YYYY-MM-DD')
      ),
      ...conditionallySetDefaultFilter(
        history,
        filters,
        'payments',
        'game_level.schedule_id',
        gamesEventGameLevels.map(gl => gl.schedule_id)
      )
    };

    if (
      gameCategoryFilters.length === 0 &&
      !isGroupAdmin &&
      gamesEventCategories.length &&
      gamesEventCategories[0].user_categories.length
    ) {
      const allStates = gamesEventCategories.reduce(
        (fullArr, category) => [...fullArr, ...category.states],
        []
      );
      modifiedFilters['field.location.state'] = allStates.filter(
        (state, i) => allStates.indexOf(state) === i
      );
    } else if (gameCategoryFilters.length) {
      const allStates = gamesEventCategories
        .filter(({ id }) => gameCategoryFilters.includes(id))
        .reduce((fullArr, category) => [...fullArr, ...category.states], []);
      modifiedFilters['field.location.state'] = allStates.filter(
        (state, i) => allStates.indexOf(state) === i
      );
    }

    if (modifiedFilters && !modifiedFilters['game.status']) {
      modifiedFilters['game.status'] = Object.values(GAMES_STATUSES);
    }
    if (modifiedFilters && modifiedFilters['game.start_date']) {
      modifiedFilters['game.start_date'] = formatGameFilterStartDate(
        modifiedFilters['game.start_date']
      );
    }
    if (modifiedFilters && modifiedFilters['game.end_date']) {
      modifiedFilters['game.end_date'] = formatGameFilterEndDate(modifiedFilters['game.end_date']);
    }
    if (assignmentFilter && !UNASSIGNED_ASSIGNMENT_STATUS.includes(assignmentFilter)) {
      modifiedFilters['participants.status'] = assignmentFilter;
    }

    const limit = yield select(getLimit);
    const paginationPage = yield select(getPaginationPage);
    let pageValue = page;
    if (
      assignmentFilter &&
      UNASSIGNED_ASSIGNMENT_STATUS.includes(assignmentFilter) &&
      isEmpty(filters)
    ) {
      pageValue = paginationPage;
    }
    const response = yield call(Api.fetchGames, {
      eventId,
      filters: modifiedFilters,
      page: pageValue,
      limit,
      sortingValue: sort || DEFAULT_SORT,
      toCSV,
      search
    });
    if (response) {
      if (!toCSV) {
        if (assignmentFilter && UNASSIGNED_ASSIGNMENT_STATUS.includes(assignmentFilter)) {
          if (!isEmpty(response)) {
            response.data = applyAssignmentFilter(
              dataWithPagination(response).data,
              assignmentFilter
            );
            response.meta.total_count = response.data.length;
            yield put(setGames(response));
          } else {
            yield put(compose(setGames, dataWithPagination)(response));
          }
        } else {
          yield put(compose(setGames, dataWithPagination)(response));
        }
      } else {
        yield call(resolve, response);
      }
    }
  }
}

export function* fetchGames(params) {
  yield call(errorProgressDecorate, processFetchGames, params, setIsFetching);
}

export function* storeGamesEvent({ payload: event }) {
  const { usrIdx = userIdxGetter() } = event;
  yield call(persistGamesEvent, { event, usrIdx });
  yield put(setSelectedGamesEvent(event));
}

export function* storeGamesEventAndRedirect(params) {
  yield call(storeGamesEvent, params);
  const userIdx = yield call(userIdxGetter);
  history.push(routeBuilder({ userIdx }, GAMES));
}

export function* processFetchGamesEventCrewLabels() {
  const gamesEventId = yield select(getGamesEventId);
  const calendarGameEventId = yield select(getCalendarEventId);
  if (gamesEventId || calendarGameEventId) {
    const response = yield call(Api.fetchEventCrewLabels, {
      id: gamesEventId || calendarGameEventId
    });

    const parsedResponse = compose(
      values => values.labels.map((label, i) => ({ label, role_id: values.role_ids[i] })),
      mergeData
    )(response);

    yield put(setGamesEventCrewLabels(parsedResponse));
  }
}

export function* fetchGamesEventCrewLabels() {
  yield call(errorProgressDecorate, processFetchGamesEventCrewLabels);
}

export function* processCreateGameAssignment({
  payload: {
    gameId,
    crewLabelCol,
    eventRoleId,
    status = GAME_ASSIGNMENT_STATUSES.unpublished,
    external_start_date,
    external_end_date,
    timezone,
    external_event_id,
    external_location_id,
    game_status,
    game_published,
    userId,
    eventId,
    game_level_id,
    resolve,
    // type,
    ignore_rules,
    onLoadPage,
    reloadSelfAssignmentsForDay,
    selfAssignGamesRequestBody
  }
}) {
  try {
    if (status === GAME_ASSIGNMENT_STATUSES.accepted) {
      yield put(setFetching(SELFASSIGNGAMES, true));
    } else {
      yield put(setAssignmentFetching({ gameId, crewLabelCol }));
    }
    const assignmentResponse = yield call(Api.createGameAssignment, {
      id: gameId,
      data: {
        official_label_col: crewLabelCol,
        event_role_id: eventRoleId,
        status,
        game_status,
        game_published,
        external_start_date,
        external_end_date,
        timezone,
        external_event_id,
        external_location_id,
        game_level_id,
        ignore_rules,
        eventId
      }
    });

    if (reloadSelfAssignmentsForDay) {
      yield call(() => reloadSelfAssignmentsForDay(selfAssignGamesRequestBody));
    }
    if (status === GAME_ASSIGNMENT_STATUSES.accepted && assignmentResponse) {
      if (onLoadPage) yield call(onLoadPage);
      const parsedAssignment = mergeData(assignmentResponse);
      yield put(addAssignmentToSelfAssignGames({ assignment: parsedAssignment }));
      yield put(addAssignmentToUseSchedule({ assignment: parsedAssignment, eventRoleId, eventId }));
      yield put(
        addNotifications([{ type: 'success', message: 'Successfully created assignment' }])
      );
      yield put(setFetching(SELFASSIGNGAMES, false));
    }

    if (assignmentResponse && status !== GAME_ASSIGNMENT_STATUSES.accepted) {
      const parsedAssignment = mergeData(assignmentResponse);
      yield put(addGameAssignmentToGame({ assignment: parsedAssignment, gameId }));
      yield put(setAssignmentFetching({}));
      yield call(resolve, gameId);
      yield put(
        addNotifications([{ type: 'success', message: 'Successfully created assignment' }])
      );
      yield delay(0);
      yield put(addEventUserGameAssignment({ assignment: parsedAssignment, userId, eventRoleId }));
      yield put((() => ({ type: ALLOW_IGNORE_RULES_CLEAR }))());
    }
  } catch (e) {
    yield put(setAssignmentFetching({}));
    yield put(setFetching(SELFASSIGNGAMES, false));
    yield put(addNotifications(e.message));
  }
}

export function* createGameAssignment(params) {
  yield call(errorDecorate, processCreateGameAssignment, params);
}

export function* processDeleteGameAssignments({
  payload: { gameId, id, resolve, userId, eventRoleId, crewLabelCol, type, checkReport }
}) {
  yield put(setAssignmentFetching({ gameId, crewLabelCol }));
  const response = yield call(Api.deleteGameAssignments, {
    id,
    data: {
      userId,
      checkReport
    }
  });
  yield put(removeGameAssignmentFromGame({ assignmentId: id, gameId, userId }));
  yield put(setAssignmentFetching({}));
  yield call(resolve, gameId);
  yield put(addNotifications([{ type: 'success', message: 'Successfully removed assignment' }]));
  yield delay(0);
  yield put(removeEventUserGameAssignment({ userId, eventRoleId, assignmentId: id }));
  if (response && response.reportExist) {
    const {
      submitted_by: { first_name, last_name },
      game: { external_event_id }
    } = response.report;
    yield put(
      setAssignmentWarnings({
        assignmentWarnings: [
          {
            message: `${first_name} ${last_name} has submitted a incident report for Game ${external_event_id}. Removing the official does NOT delete the incident report.`,
            gameData: { id, gameId, resolve, userId, eventRoleId, crewLabelCol, type },
            warning: true,
            reportExist: response.reportExist
          }
        ]
      })
    );
  }
}

export function* submitGamesImport({ payload: { eventId, file, resolve } }) {
  try {
    const response = yield call(Api.submitGamesImport, { id: eventId, file });
    yield call(resolve, response);
  } catch (err) {
    yield call(resolve, err.message[0]);
  }
}

export function* processSubmitGamesUpdate({
  payload: { gameId, data, onLoadPage = always(undefined) }
}) {
  try {
    yield put(showProgress());
    yield call(Api.submitGamesUpdate, { gameId, data });
    yield put(addNotifications([{ type: 'success', message: 'Game updated successfully' }]));
    yield call(onLoadPage);
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(hideProgress());
  }
}

export function* submitGamesUpdate(params) {
  yield call(errorDecorate, processSubmitGamesUpdate, params);
}
export function* deleteGameAssignments(params) {
  yield call(errorDecorate, processDeleteGameAssignments, params);
}

export function* processUpdateGameAssignments({
  payload: {
    assignmentIds,
    onLoadPage,
    reloadSelfAssignmentsForDay,
    selfAssignGamesRequestBody,
    data,
    message,
    resolve = () => true
  }
}) {
  if (assignmentIds.length) {
    const assignmentResponse = yield all(
      assignmentIds.map(id => call(Api.updateAssignment, { id, data }))
    );

    if (reloadSelfAssignmentsForDay) {
      yield call(() => reloadSelfAssignmentsForDay(selfAssignGamesRequestBody));
    }

    const parsedAssignments = assignmentResponse.map(a => mergeData(a));
    yield put(updateGameAssignmentToGame({ assignments: parsedAssignments }));
    if (onLoadPage) yield call(onLoadPage);
    // const eventId = yield select(getGamesEventId);
    //  yield call(fetchEventUsers, { payload: { id: eventId } });
    yield call(resolve);
    yield put(addNotifications([{ type: 'success', message }]));
  }
}

export function* updateGameAssignments(params) {
  yield call(errorProgressDecorate, processUpdateGameAssignments, params, setIsFetching);
}

export function* processUpdateGames({ payload: { gameIds, onLoadPage, data, message } }) {
  yield all(gameIds.map(id => call(Api.updateGame, { id, data })));
  if (gameIds.length) {
    yield call(onLoadPage);
    yield put(addNotifications([{ type: 'success', message }]));
  }
}

export function* processDeleteBulkGames({ payload: { data, onLoadPage, resolve = () => true } }) {
  yield put(setIsFetching(false));
  const response = yield call(Api.deleteBulkGames, { data });
  if (response) {
    yield call(onLoadPage);
    yield call(resolve, response);
  }
}

export function* updateGames(params) {
  yield call(errorProgressDecorate, processUpdateGames, params, setIsFetching);
}

export function* deleteBulkGames(params) {
  yield call(errorProgressDecorate, processDeleteBulkGames, params, setIsFetching);
}

export function* processCreateIncidentReport({ payload: { id, data } }) {
  const external_team_id = data.external_team_id === 'N/A' ? null : data.external_team_id;
  const response = yield call(Api.createIncidentReport, {
    id,
    data: { ...data, external_team_id }
  });
  if (response) {
    yield put(addIncidentReport(mergeData(response)));
    yield put(
      addNotifications([{ type: 'success', message: 'Successfully created incident report' }])
    );
  }
}

export function* createIncidentReport(params) {
  yield call(errorProgressDecorate, processCreateIncidentReport, params, setCurrentIsFetching);
}

export function* processFetchCurrentIncidentReports({ payload: { filters } }) {
  const { id } = yield select(getCurrentGame);
  const response = yield call(Api.fetchIncidentReports, { id, filters });

  if (response) yield put(setCurrentIncidentReports(mergeData(response)));
}

export function* fetchCurrentIncidentReports(params) {
  yield call(
    errorProgressDecorate,
    processFetchCurrentIncidentReports,
    params,
    setCurrentIsFetching
  );
}

export function* processUpdateIncidentReport({
  payload: { id, data, message = 'Successfully updated incident report' }
}) {
  const external_team_id = data.external_team_id === 'N/A' ? null : data.external_team_id;
  const response = yield call(Api.updateIncidentReport, {
    id,
    data: { ...data, external_team_id }
  });
  if (response) {
    const merged = mergeData(response);
    yield put(updateIncidentReportAction(merged));
    yield put(setCurrentReport(merged));
    yield put(addNotifications([{ type: 'success', message }]));
  }
}

export function* updateIncidentReport(params) {
  yield call(errorProgressDecorate, processUpdateIncidentReport, params, setCurrentIsFetching);
}

export function* processDeleteIncidentReport({ payload: { id } }) {
  yield call(Api.deleteIncidentReport, { id });
  yield put(removeIncidentReport(id));
  yield put(
    addNotifications([{ type: 'success', message: 'Successfully deleted incident report' }])
  );
}

export function* deleteIncidentReport(params) {
  yield call(errorProgressDecorate, processDeleteIncidentReport, params, setCurrentIsFetching);
}

export function* processCreateCrewVerificationReport({ payload: { id, data } }) {
  const crewCheckedIn = Object.keys(data.values).map(key => data.values[key]);
  const body = { ...data, ...data.values };
  delete body.values;

  const response = yield call(Api.createCrewVerificationReport, {
    id,
    data: { ...body }
  });
  if (response) {
    yield put(addCrewVerificationReport(mergeData(response)));
    yield put(setCrewVerificationReports({ id, crewCheckedIn }));
    yield put(
      addNotifications([
        { type: 'success', message: 'Successfully Created Crew Verification Report' }
      ])
    );
  }
}

export function* createCrewVerificationReport(params) {
  yield call(
    errorProgressDecorate,
    processCreateCrewVerificationReport,
    params,
    setCurrentIsFetching
  );
}

export function* processFetchCurrentCrewVerificationReports({ payload: { filters } }) {
  const { id } = yield select(getCurrentGame);
  const { reports, notes } = yield all({
    reports: call(Api.fetchCrewVerificationReports, { id, filters }),
    notes: call(Api.fetchCrewVerificationNotes, { id })
  });
  if (reports) yield put(setCurrentCrewVerificationReports(reports));
  if (notes) yield put(setCurrentCrewVerificationNotes(notes));
}

export function* fetchCurrentCrewVerificationReports(params) {
  yield call(
    errorProgressDecorate,
    processFetchCurrentCrewVerificationReports,
    params,
    setCurrentIsFetching
  );
}

export function* processCreateGameReport({ payload: { id, data } }) {
  const external_team_id = data.external_team_id === 'N/A' ? null : data.external_team_id;
  const response = yield call(Api.createGameReport, {
    id,
    data: { ...data, external_team_id }
  });
  if (response) {
    if (data.attachments && data.attachments.length)
      yield call(Api.submitGameReportAttachment, {
        id: response.data.id,
        file: data.attachments
      });
    yield put(addGameReport(mergeData(response)));
    yield put(addNotifications([{ type: 'success', message: 'Successfully created Game report' }]));
    const { id: gameId } = yield select(getCurrentGame);
    const filters = {};
    if (!(isGamesEventAdminOrAssignor || isReportsEventAdmin || isReportEventAssignor))
      filters.user_id = yield select(selectUserId);
    const reportsResponse = yield call(Api.fetchGameReports, { id: gameId, filters });

    if (reportsResponse) yield put(setCurrentGameReports(mergeData(reportsResponse)));
  }
}
export function* createGameReport(params) {
  yield call(errorProgressDecorate, processCreateGameReport, params, setCurrentIsFetching);
}

export function* processFetchCurrentGameReports({ payload: { filters } }) {
  const { id } = yield select(getCurrentGame);
  const response = yield call(Api.fetchGameReports, { id, filters });

  if (response) yield put(setCurrentGameReports(mergeData(response)));
}

export function* fetchCurrentGameReports(params) {
  yield call(errorProgressDecorate, processFetchCurrentGameReports, params, setCurrentIsFetching);
}

export function* processFetchGameReport({ payload: { id } }) {
  const response = yield call(Api.fetchGameReport, { id });

  if (response) yield put(setGameReport(mergeData(response)));
}

export function* fetchGameReport(params) {
  yield call(errorProgressDecorate, processFetchGameReport, params, setCurrentIsFetching);
}

export function* processUpdateGameReport({
  payload: { id, event_id, data, successHandler, message = 'Successfully updated Game report' }
}) {
  const external_team_id = data.external_team_id === 'N/A' ? null : data.external_team_id;
  const newAttachments = data.attachments && data.attachments.filter(file => !file.id);
  let response = null;

  if (newAttachments && newAttachments.length) {
    const { report } = yield all({
      report: call(Api.updateGameReport, {
        id,
        data: { ...data, external_team_id, event_id }
      }),
      attachments: call(Api.submitGameReportAttachment, {
        id,
        file: newAttachments
      })
    });
    response = report;
  } else {
    response = yield call(Api.updateGameReport, {
      id,
      data: { ...data, external_team_id, event_id }
    });
  }

  if (response) {
    const merged = mergeData(response);
    yield put(updateGameReportAction(merged));
    yield put(setCurrentReport(merged));
    yield put(addNotifications([{ type: 'success', message }]));
    if (successHandler) yield put(successHandler(response));
    const { id: gameId } = yield select(getCurrentGame);
    const filters = {};
    if (!(isGamesEventAdminOrAssignor || isReportsEventAdmin || isReportEventAssignor))
      filters.user_id = yield select(selectUserId);
    const reportsResponse = yield call(Api.fetchGameReports, { id: gameId, filters });

    if (reportsResponse) yield put(setCurrentGameReports(mergeData(reportsResponse)));
  }
}

export function* updateGameReport(params) {
  yield call(errorProgressDecorate, processUpdateGameReport, params, setCurrentIsFetching);
}

export function* processDeleteGameReport({ payload: { id } }) {
  yield call(Api.deleteGameReport, { id });
  yield put(removeGameReport(id));
  yield put(addNotifications([{ type: 'success', message: 'Successfully deleted Game report' }]));
}

export function* deleteGameReport(params) {
  yield call(errorProgressDecorate, processDeleteGameReport, params, setCurrentIsFetching);
}

export function* processFetchCurrentGame({ payload: { id } }) {
  const response = yield call(Api.fetchGame, { id });
  if (response) yield put(setCurrentGame(mergeData(response)));
}

export function* fetchCurrentGame(params) {
  yield call(errorProgressDecorate, processFetchCurrentGame, params, setCurrentIsFetching);
}

export function* processSubmitCopiedGameId({
  payload: { gameId, data, onLoadPage = always(undefined) }
}) {
  try {
    yield put(showProgress());
    yield call(Api.submitCopiedGameId, { gameId, data });
    yield put(addNotifications([{ type: 'success', message: 'Game was copied successfully.' }]));
    yield put(isCopiedGameIdCorrect({ flag: false, display_id: '' }));
    yield call(onLoadPage);
  } catch (err) {
    yield put(
      isCopiedGameIdCorrect({ flag: true, display_id: err.message[0].message.split(':')[0] })
    );
    yield put(addNotifications(err.message));
  } finally {
    yield put(hideProgress());
  }
}

export function* submitCopiedGameId(params) {
  yield call(errorProgressDecorate, processSubmitCopiedGameId, params, setFetchingCopiedGameId);
}

export function* fetchAssignmentNote({ payload: { id } }) {
  try {
    yield put(isfetchingAssignmentNote());
    const result = yield call(Api.fetchAssignmentNote, { id });
    yield put(setAssignmentNote(result.note));
  } catch (err) {
    yield put(addNotifications(err.message));
  }
}

export const gamesSagas = [
  takeLatest(FETCH_GAMES, fetchGames),
  takeLatest(STORE_GAMES_EVENT, storeGamesEvent),
  takeLatest(STORE_GAMES_EVENT_AND_REDIRECT, storeGamesEventAndRedirect),
  takeLatest(FETCH_GAMES_EVENT_CREW_LABELS, fetchGamesEventCrewLabels),
  takeEvery(CREATE_GAME_ASSIGNMENT, createGameAssignment),
  takeEvery(DELETE_GAME_ASSIGNMENTS, deleteGameAssignments),
  takeLatest(UPDATE_GAME_ASSIGNMENTS, updateGameAssignments),
  takeLatest(UPDATE_GAMES, updateGames),
  takeLatest(DELETE_BULK_GAMES, deleteBulkGames),
  takeLatest(CREATE_INCIDENT_REPORT, createIncidentReport),
  takeLatest(FETCH_CURRENT_INCIDENT_REPORTS, fetchCurrentIncidentReports),
  takeLatest(EDIT_INCIDENT_REPORT, updateIncidentReport),
  takeLatest(DELETE_INCIDENT_REPORT, deleteIncidentReport),
  takeLatest(CREATE_GAME_REPORT, createGameReport),
  takeLatest(FETCH_CURRENT_GAME_REPORTS, fetchCurrentGameReports),
  takeLatest(FETCH_GAME_REPORT, fetchGameReport),
  takeLatest(EDIT_GAME_REPORT, updateGameReport),
  takeLatest(DELETE_GAME_REPORT, deleteGameReport),
  takeEvery(FETCH_CURRENT_GAME, fetchCurrentGame),
  takeLatest(SUBMIT_GAMES_IMPORT, submitGamesImport),
  takeLatest(SUBMIT_GAMES_UPDATE, submitGamesUpdate),
  takeLatest(CREATE_CREW_VERIFICATION_REPORT, createCrewVerificationReport),
  takeLatest(FETCH_CURRENT_CREW_VERIFICATION_REPORTS, fetchCurrentCrewVerificationReports),
  takeLatest(SUBMIT_COPIED_GAME_ID, submitCopiedGameId),
  takeLatest(FETCH_ASSIGNMENT_NOTE, fetchAssignmentNote)
];

export default gamesSagas;
