import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';

import { Wrapper, Title, PaleTitleBar } from './styled-components';

export { PaleTitleBar };

const TitleBar = ({ children, margin, customStyle, bgColor, height }) => (
  <Wrapper margin={margin} bgColor={bgColor} height={height}>
    <Col xs={12}>
      <Title customStyle={customStyle}>{children}</Title>
    </Col>
  </Wrapper>
);

TitleBar.propTypes = {
  children: PropTypes.string.isRequired
};

export default TitleBar;
