import { compose } from 'recompose';
import { connect } from 'react-redux';
import unconnected from './unconnected';
import {
  fetchWallet,
  fetchTransactionHistory as fetchTransactionHistoryAction
} from '../../../core/Payments';

import {
  getTransactionHistory,
  getUserWallet,
  getUserWalletTotal,
  getWalletIsFetching
} from '../../../core/Payments/selectors';
import { getPayerId } from '../../../core/Auth/selectors';

const mapStateToProps = state => ({
  wallet: getUserWallet(state),
  walletTotal: getUserWalletTotal(state),
  isFetching: getWalletIsFetching(state),
  payerId: getPayerId(state),
  limit: getTransactionHistory(state).meta.limit
});

const mapDispatchToProps = dispatch => ({
  getWallet: payload => dispatch(fetchWallet(payload)),
  fetchTransactionHistory: payload => dispatch(fetchTransactionHistoryAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
