import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { Wrapper, StyledIcon, NotificationNumber } from './styled-components';

const NotificationIcon = ({
  color,
  height,
  icon,
  notificationNumber,
  width,
  handleOnClick,
  canClickIfEmpty,
  addClassName,
  tooltipText
}) => (
  <Wrapper
    onClick={e => (!!notificationNumber || canClickIfEmpty) && handleOnClick(e)}
    notificationNumber={notificationNumber}
    canClickIfEmpty={canClickIfEmpty}
    className={addClassName}
  >
    <Tooltip title={tooltipText}>
      <StyledIcon width={width} height={height} icon={icon} color={color} />
    </Tooltip>
    {!!notificationNumber && <NotificationNumber>{notificationNumber}</NotificationNumber>}
  </Wrapper>
);

NotificationIcon.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  notificationNumber: PropTypes.number,
  width: PropTypes.number.isRequired,
  handleOnClick: PropTypes.func,
  canClickIfEmpty: PropTypes.bool,
  tooltipText: PropTypes.string
};

NotificationIcon.defaultProps = {
  notificationNumber: 0,
  handleOnClick: () => true,
  canClickIfEmpty: false,
  tooltipText: ''
};

export default NotificationIcon;
