import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import Flex from '../../../Flex';
import GamesFilter from './GamesFilter';
import Icon from '../../../Icon';
import theme, {
  EventWrapper,
  EventHeader,
  EventLabel,
  actionTheme,
  BatchActionItemContainer,
  BatchActionItem
} from './styled-components';
import { ICONS, COLORS, HEIGHTS } from '../../../../../utils/styles';
import ActionDropdown from '../../../ActionDropdown';
import { generateRefreshQuery } from '../../../../../utils/helpers';

const Header = ({
  eventName,
  gamelevels,
  filter,
  setSelectedGame,
  setAllGamesCheck,
  batchRange: { start_date, end_date },
  batchReviewDescription,
  reviewMode,
  downloadBatchPayReport,
  location,
  categories,
  isSuperAdmin,
  teams,
  complexes
}) => {
  const isLocked = gamelevels && !!gamelevels.find(l => l.locked);
  return (
    <Flex
      bgImage="linear-gradient(to top, #05579d, #167cd4)"
      height={HEIGHTS.eventNavigationBar}
      justifyContent="space-between"
      padding="0 1.5rem 0 0"
      fullWidth
      noShrink
    >
      <Flex>
        <EventWrapper>
          <EventHeader>
            <EventLabel>{eventName}</EventLabel>
          </EventHeader>
        </EventWrapper>
        <Flex theme={theme.dropdownContainer} height={HEIGHTS.eventNavigationBar}>
          <GamesFilter
            gamelevels={gamelevels}
            {...filter}
            setSelectedGame={setSelectedGame}
            setAllGamesCheck={setAllGamesCheck}
            batchRange={{ start_date, end_date }}
            categories={categories}
            teams={teams}
            complexes={complexes}
          />
        </Flex>
        {reviewMode ? (
          <Flex theme={theme.dateRangeWrapper} height={HEIGHTS.eventNavigationBar}>
            <span>{batchReviewDescription}</span>{' '}
            <Icon height={20} width={32} margin="0" icon={ICONS.BOX} color={COLORS.white} />
          </Flex>
        ) : (
          <Flex theme={theme.dateRangeWrapper} height={HEIGHTS.eventNavigationBar}>
            <span>{`${moment(start_date).format('MMM Do')} - ${moment(end_date).format(
              'MMM Do'
            )}`}</span>
          </Flex>
        )}
      </Flex>
      <BatchActionItemContainer>
        <BatchActionItem>
          <NavLink to={`${location.pathname}${generateRefreshQuery(location.search)}`}>
            <Icon height={18} width={18} icon={ICONS.REFRESH} color={COLORS.white} />
          </NavLink>
        </BatchActionItem>
        {reviewMode || !isLocked || isSuperAdmin ? (
          <BatchActionItem>
            <ActionDropdown
              theme={actionTheme}
              config={{
                options: [
                  {
                    label: 'Download Game List',
                    showOption: [],
                    clickableOption: [],
                    onClick: downloadBatchPayReport
                  }
                ]
              }}
            />
          </BatchActionItem>
        ) : (
          ''
        )}
      </BatchActionItemContainer>
    </Flex>
  );
};

Header.propTypes = {
  gamelevels: PropTypes.arrayOf(Object),
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  setSelectedGame: PropTypes.func.isRequired,
  setAllGamesCheck: PropTypes.func.isRequired,
  eventName: PropTypes.string,
  batchRange: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  }),
  batchReviewDescription: PropTypes.string.isRequired,
  reviewMode: PropTypes.bool.isRequired
};

Header.defaultProps = {
  gamelevels: [],
  eventName: '',
  batchRange: {
    start_date: '',
    end_date: ''
  }
};

export default Header;
