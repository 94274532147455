import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ContentWrapper,
  ButtonsContainer,
  ButtonContainer,
  LineBreak,
  NotificationContentWrapper
} from '../styled-components';
import { history } from '../../../../../core/store';
import { sInterpolator } from '../../../../../utils/helpers';
import { userIdxGetter } from '../../../../../utils/auth';
import { EVENT_GAME_LEVELS } from '../../../../../core/paths';

const GameImportNotificationModal = ({ eventId, onClose }) => (
  <Modal
    title="Finish setting up your games"
    size={{ md: 10 }}
    onClose={onClose}
    background
    leftPanel
  >
    <Row>
      <ContentWrapper>
        <Block xs={10} xsOffset={1} className="content">
          <NotificationContentWrapper>
            <Content first>
              For each Game Level & Type, you will need to:
              <LineBreak />
              - Set the amount of positions for Officials or Assessors
              <LineBreak />
              - Add Crew Labels (position titles)
              <LineBreak />- Enable Crew Pay, if processing payments via Stack Officials
            </Content>
            <Content first>
              Our Help Center has support articles and videos covering Event setup criteria.
            </Content>
            <Content>Click ‘Finish’ to complete setting up these games.</Content>
          </NotificationContentWrapper>
        </Block>
      </ContentWrapper>
      <Block xs={12}>
        <ButtonsContainer>
          <ButtonContainer>
            <Button
              label="Finish"
              onClick={() => {
                history.push(
                  sInterpolator({ userIdx: userIdxGetter(), eventId }, EVENT_GAME_LEVELS)
                );
              }}
              width="151px"
              height="32px"
              primary
            />
          </ButtonContainer>
        </ButtonsContainer>
      </Block>
    </Row>
  </Modal>
);

GameImportNotificationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired
};

GameImportNotificationModal.defaultProps = {};

export default GameImportNotificationModal;
