import { compose, pure, withStateHandlers, withProps, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileSaver from 'file-saver';
import { notNilNotEmpty } from '../../../utils/helpers';
import { formatDate } from '../../../utils/parsers';
import {
  createEvent,
  fetchEvents as fetchEventsAction,
  createEventAndRedirect,
  fetchGoverningBodies
} from '../../../core/Events';
import {
  isSuperAdmin,
  getAdminEvents,
  getAssignorEvents,
  getGroups,
  getUserGroups,
  isSelectedEventAdminOrAssignor,
  isAnyEventAssignorOrAssessorOrOfficial,
  isGroupAdmin,
  isAnyEventAdminOrFundingAdmin
} from '../../../core/Auth/selectors';
import { OWN_EVENTS } from '../../../core/Events/eventConstants';
import {
  getEvents,
  getTotalEventCount,
  getFilters,
  getSortingValue,
  getEventMeta,
  getGoverningBodies
} from '../../../core/Events/selectors';
import generateFields from '../../components/Events/EventsFilter/config';
import { fetchSports as fetchSportsAction } from '../../../core/Sports';
import { fetchRoles as fetchRolesAction } from '../../../core/Roles';
import { getSports } from '../../../core/Sports/selectors';
import { getRoles } from '../../../core/Roles/selectors';
import { fetchGroups } from '../../../core/Auth';
import { paginationAware, updateOnPageChange, filterConfigToNames } from '../PaginationAware';
import { storeGamesEventAndRedirect as storeGamesEventAndRedirectAction } from '../../../core/Games';
import { EVENTS } from '../../../core/paths';
import { unsetShowModalFor as unsetShowModalForAction } from '../../../core/Modals';
import { isGamesEventGroupAdmin } from '../../../core/Games/selectors';
import { getProgressVisibility } from '../../../core/Progress';

const mapStateToProps = state => ({
  eventList: getEvents(state),
  filters: getFilters(state),
  sortingValue: getSortingValue(state),
  sports: getSports(state),
  governingBodies: getGoverningBodies(state),
  adminEvents: getAdminEvents(state),
  assignorEvents: getAssignorEvents(state),
  isSuperAdmin: isSuperAdmin(state),
  roles: getRoles(state),
  totalCount: getTotalEventCount(state),
  eventMeta: getEventMeta(state),
  isFetching: state.events.isFetching,
  groups: getGroups(state),
  userGroups: getUserGroups(state),
  canEdit: isGamesEventGroupAdmin()(state) || isSelectedEventAdminOrAssignor(state),
  showProgress: getProgressVisibility(state),
  hasNoRole: !(
    isSuperAdmin(state) ||
    isAnyEventAdminOrFundingAdmin()(state) ||
    isAnyEventAssignorOrAssessorOrOfficial(state) ||
    isGroupAdmin(state)
  )
});

const mapDispatchToProps = {
  fetchEvents: fetchEventsAction,
  createNewEvent: createEvent,
  fetchSports: fetchSportsAction,
  createEventAndRedirect,
  fetchRoles: fetchRolesAction,
  storeGamesEventAndRedirect: storeGamesEventAndRedirectAction,
  unsetShowModalFor: unsetShowModalForAction,
  fetchGroups,
  fetchGoverningBodies
};

const LIMIT = 10;
export const PAGINATION_AWARE_PREFIX = 'ev';

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware(
    {
      to: EVENTS,
      fetcherQuote: 'fetchEvents',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'eventMeta',
      searchFilter: ['search'],
      filterNames: filterConfigToNames(generateFields({})),
      statusFilter: ['status']
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  withStateHandlers(
    {
      selectedNavigationItem: OWN_EVENTS,
      showNewEventModal: false,
      showFilterMenu: false
    },
    {
      setModalVisibility: ({ showNewEventModal }) => () => ({
        showNewEventModal: !showNewEventModal
      })
    }
  ),
  withProps({ limit: LIMIT }),
  withHandlers({
    onCreateNewEvent: ({
      createNewEvent,
      setModalVisibility,
      pagination: { onLoadPage }
    }) => event => {
      new Promise(resolve => createNewEvent({ event, resolve, onLoadPage })).then(() => {
        setModalVisibility(false);
      });
    },
    onCreateEventAndRedirect: ({
      createEventAndRedirect: createEventAndRedirectAction
    }) => event => {
      new Promise(resolve => createEventAndRedirectAction({ event, resolve })).then(() => {});
    },
    requestEventListCSV: ({ fetchEvents, filters, limit, sortingValue }) => () =>
      new Promise(resolve =>
        fetchEvents({ filters, limit, sortingValue, toCSV: true, resolve })
      ).then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' });
        FileSaver.saveAs(blob, `event_list_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
      })
  }),
  lifecycle({
    componentDidMount() {
      const { pagination } = this.props;

      if (!notNilNotEmpty(pagination)) pagination.onLoadPage();
      if (this.props.fetchSports) this.props.fetchSports();
      if (this.props.fetchRoles) this.props.fetchRoles();
      if (this.props.fetchGroups) this.props.fetchGroups();
      if (this.props.fetchGoverningBodies) this.props.fetchGoverningBodies();
    },
    componentWillUnmount() {
      const { unsetShowModalFor } = this.props;
      unsetShowModalFor({});
    }
  }),
  pure
);
