import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../utils/api-wrapper';
import {
  FETCH_USER_CERTIFICATIONS,
  ADD_USER_CERTIFICATIONS,
  UPDATE_USER_CERTIFICATIONS,
  fetchCertifications as fetchCertificationsAction,
  setCertificates,
  setIsFetching,
  setUssf,
  DELETE_USER_CERTIFICATIONS
} from './index';
import { addNotifications } from '../Notifications';

export function* fetchCertifications({ payload: { userId } }) {
  try {
    yield put(setIsFetching(true));
    const response = yield call(Api.fetchCertifications, { userId });
    if (response && response.data && response.data.ussf) {
      yield put(setUssf({ ...response.data.ussf }));
      yield put(setCertificates({ ...response.data.certs }));
    } else if (response && response.data && !response.data.ussf) {
      yield put(setUssf({ id: '', isValid: false, first_name: '', last_name: '' }));
      yield put(setCertificates({}));
    }
  } catch (err) {
    yield put(addNotifications(err.message));
    yield put(setUssf({ id: '', isValid: false }));
    yield put(setCertificates({}));
  } finally {
    yield put(setIsFetching(false));
  }
}

export function* addCertifications({
  payload: { userId, ussf_id: ussfId, ussf_first_name: ussfFirstName, ussf_last_name: ussfLastName }
}) {
  try {
    yield put(setIsFetching(true));
    const response = yield call(Api.addCertifications, {
      userId,
      ussfId,
      ussfFirstName,
      ussfLastName
    });
    if (response && response.data && response.data.ussf) {
      yield put(setUssf({ ...response.data.ussf }));
      yield put(setCertificates({ ...response.data.certs }));
      if (response.data.ussf.isValid)
        yield put(addNotifications([{ type: 'success', message: 'Successfuly added USSF ID.' }]));
    } else if (response && response.message) {
      yield put(
        addNotifications([{ type: response.type || 'success', message: response.message }])
      );
    }
  } catch (err) {
    yield put(addNotifications(err.message));
    yield put(setUssf({ id: '', isValid: false }));
    yield put(setCertificates({}));
  } finally {
    yield put(setIsFetching(false));
  }
}

export function* updateCertifications({
  payload: { userId, ussf_id: ussfId, ussf_first_name: ussfFirstName, ussf_last_name: ussfLastName }
}) {
  try {
    yield put(setIsFetching(true));
    const response = yield call(Api.updateCertifications, {
      userId,
      ussfId,
      ussfFirstName,
      ussfLastName
    });
    if (response && response.message) {
      yield put(
        addNotifications([{ type: response.type || 'success', message: response.message }])
      );
    }
    yield put(fetchCertificationsAction({ userId, ussf_id: ussfId }));
  } catch (err) {
    yield put(addNotifications(err.message));
    yield put(setUssf({ id: '', isValid: false }));
    yield put(setCertificates({}));
  } finally {
    yield put(setIsFetching(false));
  }
}

export function* deleteCertifications({ payload: { userId = undefined } }) {
  try {
    yield put(setIsFetching(true));
    const response = yield call(Api.deleteCertifications, { userId });
    if (response && response.message) {
      yield put(
        addNotifications([{ type: response.type || 'success', message: response.message }])
      );
      yield put(setUssf({ id: '', isValid: false, first_name: '', last_name: '' }));
      yield put(setCertificates({}));
    }
    yield put(fetchCertificationsAction({ userId }));
  } catch (err) {
    yield put(addNotifications(err.message));
    yield put(setUssf({ id: '', isValid: false }));
    yield put(setCertificates({}));
  } finally {
    yield put(setIsFetching(false));
  }
}

export const certSagas = [
  takeLatest(FETCH_USER_CERTIFICATIONS, fetchCertifications),
  takeLatest(ADD_USER_CERTIFICATIONS, addCertifications),
  takeLatest(UPDATE_USER_CERTIFICATIONS, updateCertifications),
  takeLatest(DELETE_USER_CERTIFICATIONS, deleteCertifications)
];

export default certSagas;
