import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import Modal from '@material-ui/core/Modal';
import Icon from '../../Icon';

import { COLORS } from '../../../../utils/styles';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #f4f8fb;
`;

export const TableRow = styled.div`
  margin: 0.9rem 0 0.9rem;
  height: 3rem;
  align-self: center;
  display: flex;
  width: 99%;
  padding: 0 15px 0 15px;
`;

export const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const GameLevelLabel = styled.div`
  height: 100%;
  width: 100%;

  font-weight: bold;
  background-color: ${COLORS.blackSqueeze};
  color: ${COLORS.denimBlue};

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b2b2b;
  align-self: center;
`;

export const GameLevelHeaderRow = styled.div`
  height: 3rem;
  font-weight: bold;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b2b2b;
  align-self: flex-end;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 0.8rem;
  padding-top: 0.5rem;
  //background-color: ${COLORS.blackSqueeze};
`;

export const RightButtonContainer = styled.div`
  width: 20%;
  align-items: baseline;
  padding: 10px;
`;

export const LeftButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: baseline;
  padding: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const CopyFrom = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
`;

export const DropDown = styled.div`
  width: 10%;
  padding: 1rem;
`;

export const LineSeparator = styled.hr`
  border: solid 1px #d8d8d8;
  background-color: #d8d8d8;
  margin: 7px;
`;

export const InfoIconBlue = styled(InfoIcon)`
  cursor: pointer;
  color: ${COLORS.denimBlue};
  position: relative;
  margin-left: 0.5rem;
`;

export const InfoModal = styled(Modal)`
  display: 'flex';
  alignitems: 'center';
  justifycontent: 'center';
`;

export const MouseOverPopoverWrapper = styled.div`
  width: 100%;
`;

export const ConfirmationIcon = styled(Icon)`
  position: relative;
  top: -1px;
  right: -10px;
  max-width: 5rem;
  display: inline-block;
`;
