import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { range } from 'ramda';
import moment from 'moment';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import { required, isBefore } from '../../../../../utils/validations';
import MaterialDropdown from '../../../MaterialDropDown';
import MaterialDatePicker from '../../../../controls/MaterialDatePicker';
import NumberInputSelector from '../../../NumberInputSelector';
import Modal from '../../../Modal';
import TextArea from '../../../TextArea';
import theme, {
  Wrapper,
  ContentWrapper,
  ButtonsContainer,
  ButtonContainer,
  StyledRow,
  StyledCol,
  Label,
  DayBox,
  DaysContainer,
  FlexStatic,
  DaysLabel,
  Note,
  LastRow,
  FlexedCol,
  Button
} from './styled-components';
import { ActionIcon } from '../../../Event/styled-components';
import { ICONS, COLORS } from '../../../../../utils/styles';
import { convertUtcToTimezone, pxToRem } from '../../../../../utils/helpers';
import FREQUENCIES, { AVAILABILITY_TYPES } from '../availabilityConstants';
import { AVAILABILITY_CALENDAR_TZ } from '../../../../../constants';
import LoaderWrapper from '../../../LoaderWrapper';

const DAYS = [
  { value: '0', label: 'Mo' },
  { value: '1', label: 'Tu' },
  { value: '2', label: 'We' },
  { value: '3', label: 'Th' },
  { value: '4', label: 'Fr' },
  { value: '5', label: 'Sa' },
  { value: '6', label: 'Su' }
];

const createTimeSlots = hour => [moment().hour(hour).minute(0), moment().hour(hour).minute(30)];

const TIMES = range(7, 24).reduce(
  (timeslots, time) => [...timeslots, ...createTimeSlots(time)],
  []
);

const generateInitialValuesFromClick = (startTime, endTime, timezone, availabilityNote, paramId) =>
  startTime
    ? {
        frequency: FREQUENCIES.never,
        interval: [false, false, false, false, false, false, false],
        date: startTime,
        from: moment(startTime).minute(0).format('h:mm A'),
        to: endTime
          ? moment(endTime).minute(0).format('h:mm A')
          : moment(startTime).add(1, 'h').minute(0).format('h:mm A'),
        timezone,
        availability_type: '',
        note: availabilityNote && availabilityNote.note,
        paramId
      }
    : {
        frequency: FREQUENCIES.never,
        interval: [false, false, false, false, false, false, false],
        timezone,
        note: availabilityNote && availabilityNote.note,
        paramId
      };

const configureExistingAvailability = (availability, availabilityNote, paramId) => ({
  date: moment(convertUtcToTimezone(availability.time_from, availability.timezone)).toDate(),
  from: moment(convertUtcToTimezone(availability.time_from, availability.timezone)).format(
    'h:mm A'
  ),
  to: moment(convertUtcToTimezone(availability.time_to, availability.timezone)).format('h:mm A'),
  frequency: availability.frequency,
  interval: availability.interval
    ? availability.interval.reduce(
        (result, day) => {
          const newResult = [...result];
          newResult[day] = true;
          return newResult;
        },
        [false, false, false, false, false, false, false]
      )
    : [false, false, false, false, false, false, false],
  count: availability.count && availability.count.toString(),
  timezone: availability.timezone,
  availability_type: availability.availability_type,
  note: availabilityNote && availabilityNote.note,
  paramId
});

const validateIncludesTrue = (day, days) =>
  day || days.includes(true) ? undefined : 'At least one day is required to repeat weekly';

const AddAvailabilityModal = ({
  onClose,
  onSubmit,
  startTime,
  endTime,
  availability,
  deleteUserAvailability,
  localTimezone,
  availabilityNotefetching,
  availabilityNote,
  paramId,
  inCrossEditMode
}) => (
  <Modal
    title="Set Availability"
    inCrossEditMode={inCrossEditMode}
    onClose={onClose}
    background
    overflow
  >
    <LoaderWrapper isFetching={availabilityNotefetching}>
      <Wrapper>
        <Form
          onSubmit={onSubmit}
          initialValues={
            availability
              ? configureExistingAvailability(availability, availabilityNote, paramId)
              : generateInitialValuesFromClick(
                  startTime,
                  endTime,
                  localTimezone,
                  availabilityNote,
                  paramId
                )
          }
          mutators={{
            setAvailabilityType: (args, state, utils) => {
              utils.changeValue(state, 'availability_type', () => args[0]);
            }
          }}
          render={({ handleSubmit, invalid, values, change, form: { mutators } }) => (
            <form>
              <ContentWrapper>
                {availability && (
                  <FlexedCol>
                    <ActionIcon
                      width={18}
                      height={22.5}
                      icon={ICONS.TRASH}
                      color={COLORS.denimBlue}
                      onClick={() => {
                        deleteUserAvailability({ id: availability.id, onClose });
                      }}
                    />
                  </FlexedCol>
                )}
                <StyledRow>
                  <StyledCol xs={6} lg={3}>
                    <Field
                      component={MaterialDatePicker}
                      theme={theme.datePicker}
                      name="date"
                      placeholder="Date (YYYY-MM-DD)"
                      validate={required}
                    />
                  </StyledCol>
                  <StyledCol xs={6} lg={3}>
                    <Field
                      component={MaterialDropdown}
                      theme={theme.dropdown}
                      name="from"
                      placeholder="From"
                      options={TIMES.slice(0, TIMES.length - 2).map(time => ({
                        name: time.format('h:mm A'),
                        value: time.format('h:mm A')
                      }))}
                      validate={required}
                    />
                  </StyledCol>
                  <StyledCol xs={6} lg={3}>
                    <Field
                      render={fieldProps => (
                        <MaterialDropdown
                          {...fieldProps}
                          showError={fieldProps.meta.error && fieldProps.meta.error !== 'Required'}
                        />
                      )}
                      theme={{
                        ...theme.dropdown,
                        dropableContainer: {
                          ...theme.dropdown.dropableContainer,
                          error: `margin-top: ${pxToRem('5px')};`
                        }
                      }}
                      name="to"
                      placeholder="To"
                      options={TIMES.slice(1, TIMES.length - 1).map(time => ({
                        name: time.format('h:mm A'),
                        value: time.format('h:mm A')
                      }))}
                      validate={(value, allValues) =>
                        isBefore(allValues.from, value) || required(value)
                      }
                    />
                  </StyledCol>
                  <StyledCol xs={6} lg={3}>
                    <Field
                      component={MaterialDropdown}
                      theme={theme.dropdown}
                      name="timezone"
                      placeholder="Timezone"
                      options={[
                        { name: 'Current time zone', value: localTimezone },
                        ...AVAILABILITY_CALENDAR_TZ.map(tz => ({
                          name: `${moment().tz(tz.code).format('z')} - ${tz.name}`,
                          value: tz.code
                        }))
                      ]}
                      validate={required}
                    />
                  </StyledCol>
                </StyledRow>
                <StyledRow className={values.frequency === FREQUENCIES.weekly ? '' : 'days-hidden'}>
                  <StyledCol xs={12} lg={3}>
                    <Field
                      component={MaterialDropdown}
                      theme={theme.dropdown}
                      name="frequency"
                      placeholder="Repeat"
                      options={[
                        { name: 'Never', value: FREQUENCIES.never },
                        { name: 'Daily', value: FREQUENCIES.daily },
                        { name: 'Weekly', value: FREQUENCIES.weekly },
                        { name: 'Monthly', value: FREQUENCIES.monthly }
                      ]}
                      validate={required}
                    />
                  </StyledCol>
                  <OnChange name="frequency">
                    {(value, prev) => {
                      if (value !== prev) {
                        DAYS.forEach(({ value: idx }) => change(`interval[${idx}]`, false));
                        change('count', null);
                      }
                    }}
                  </OnChange>

                  <DaysContainer
                    className={values.frequency === FREQUENCIES.weekly ? '' : 'hidden'}
                  >
                    <DaysLabel>Repeat On</DaysLabel>
                    {DAYS.map(({ value, label }) => (
                      <Field
                        key={value}
                        name={`interval[${value}]`}
                        type="checkbox"
                        validate={(fieldValue, allValues) =>
                          allValues.frequency === FREQUENCIES.weekly
                            ? validateIncludesTrue(fieldValue, allValues.interval)
                            : undefined
                        }
                        render={props => (
                          <DayBox
                            onClick={() =>
                              values.frequency === FREQUENCIES.weekly &&
                              props.input.onChange(!props.input.value)
                            }
                            selected={props.input.value}
                            disabled={values.frequency !== FREQUENCIES.weekly}
                          >
                            {label}
                          </DayBox>
                        )}
                      />
                    ))}
                  </DaysContainer>
                  <FlexStatic className={values.frequency !== FREQUENCIES.never ? '' : 'hidden'}>
                    <Label>Ends After</Label>
                    <Field
                      parse={value => (value !== '' ? (Number(value) - 1).toString() : '')}
                      format={value => (value !== '' ? (Number(value) + 1).toString() : '')}
                      render={({ input }) => (
                        <NumberInputSelector
                          trimLeadingZeros
                          theme={theme.numberInput}
                          {...input}
                          max={2}
                        />
                      )}
                      name="count"
                      validate={(value, allValues) =>
                        allValues.frequency === FREQUENCIES.never ? undefined : required(value)
                      }
                    />
                    <Label>Occurrences</Label>
                  </FlexStatic>
                </StyledRow>
                <StyledRow>
                  <StyledCol xs={12} lg={6}>
                    <Field
                      render={({ input }) => (
                        <TextArea
                          placeholder="Add Note"
                          resize="none"
                          helpText="Note"
                          helpTextStyle={{
                            color: COLORS.hawkesBlue,
                            fontSize: '11px',
                            paddingLeft: '10px',
                            marginBottom: '0.4rem'
                          }}
                          customStyle={{ outline: 'none' }}
                          containerStyle={{ textAlign: 'left' }}
                          input={{ ...input }}
                        />
                      )}
                      name="note"
                    />
                  </StyledCol>
                </StyledRow>
              </ContentWrapper>
              <LastRow>
                {availability && availability.id && values.frequency !== FREQUENCIES.never && (
                  <Note>
                    This availability time repeats. Editing this availability will change settings
                    for all dates within this sequence.
                  </Note>
                )}
                <ButtonsContainer>
                  <ButtonContainer>
                    <Button
                      label="I’m Available"
                      onClick={submitValues => {
                        mutators.setAvailabilityType(AVAILABILITY_TYPES.AVAILABLE);
                        handleSubmit(submitValues);
                        onClose();
                      }}
                      disabled={invalid}
                      color="#4E9F31"
                      border="1px solid #4E9F31"
                      background="rgba(78,159,49,0.3)"
                    >
                      <CheckCircleOutline width={10} height={10} />
                      <span>I’m Available</span>
                    </Button>
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button
                      label="I’m Busy"
                      onClick={submitValues => {
                        mutators.setAvailabilityType(AVAILABILITY_TYPES.UNAVAILABLE);
                        handleSubmit(submitValues);
                        onClose();
                      }}
                      disabled={invalid}
                      color="#BF2827"
                      border="1px solid #BF2827"
                      background="rgba(191,40,39,0.3)"
                    >
                      <CancelOutlined width={10} height={10} />
                      <span>I’m Busy</span>
                    </Button>
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button label="Cancel" onClick={onClose}>
                      <span>Cancel</span>
                    </Button>
                  </ButtonContainer>
                </ButtonsContainer>
              </LastRow>
            </form>
          )}
        />
      </Wrapper>
    </LoaderWrapper>
  </Modal>
);

AddAvailabilityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  availability: PropTypes.shape({
    id: PropTypes.string
  }),
  deleteUserAvailability: PropTypes.func.isRequired,
  paramId: PropTypes.string.isRequired,
  inCrossEditMode: PropTypes.bool.isRequired
};

AddAvailabilityModal.defaultProps = {
  startTime: null,
  endTime: null,
  availability: null
};

export default AddAvailabilityModal;
