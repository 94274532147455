import styled from 'styled-components';

import { COLORS } from '../../../../utils/styles';

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MinMaxWarning = styled.span`
  color: ${COLORS.errorRed};
`;

export const EmptyLine = styled.br``;

export default FlexRow;
