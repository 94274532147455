import { assoc } from 'ramda';
import { getSelectedRole } from '../../utils/auth';

// Actions
export const CREATE_ACCOUNT = 'AUTH/CREATE_ACCOUNT';
export const LOGIN = 'AUTH/LOGIN';
export const LOGOUT = 'AUTH/LOGOUT';
export const SET_REFERRER = 'AUTH/SET_REFERRER';
export const SET_USER_IDX = 'AUTH/SET_USER_IDX';
export const SET_USER = 'AUTH/SET_USER';
export const SET_ACTIVE_USER = 'AUTH/SET_ACTIVE_USER';
export const UPDATE_USER_PROFILE = 'AUTH/UPDATE_PROFILE';
export const CHANGE_PASSWORD = 'AUTH/CHANGE_PASSWORD';
export const CHANGE_EMAIL = 'AUTH/CHANGE_EMAIL';
export const RESEND_EMAIL = 'AUTH/RESEND_EMAIL';
export const SET_NO_ACCOUNT = 'AUTH/SET_NO_ACCOUNT';
export const SET_IS_FETCHING_FLAG = 'AUTH/SET_IS_FETCHING_FLAG';
export const UPDATE_USER_ADDRESS = 'AUTH/UPDATE_USER_ADDRESS';
export const DELETE_USER_ADDRESS = 'AUTH/DELETE_USER_ADDRESS';
export const ADD_USER_BANK_ACCOUNT = 'AUTH/ADD_USER_BANK_ACCOUNT';
export const EDIT_USER_BANK_ACCOUNT = 'AUTH/EDIT_USER_BANK_ACCOUNT';
export const DELETE_USER_BANK_ACCOUNT = 'AUTH/DELETE_USER_BANK_ACCOUNT';
export const GET_PAYEE_ACCOUNTS = 'AUTH/GET_PAYEE_ACCOUNTS';
export const SET_PAYEE_ACCOUNTS = 'AUTH/SET_PAYEE_ACCOUNTS';
export const ADD_PAYEE_ACCOUNT = 'AUTH/ADD_PAYEE_ACCOUNT';
export const EDIT_PAYEE_ACCOUNT = 'AUTH/EDIT_PAYEE_ACCOUNT';
export const DELETE_PAYEE_ACCOUNT = 'AUTH/DELETE_PAYEE_ACCOUNT';
export const SET_IS_FETCHING_FLAG_PAYEE = 'AUTH/SET_IS_FETCHING_FLAG_PAYEE';

// TODO: refactor master data
export const FETCH_TIMEZONES_LIST = 'TIMEZONE/FETCH_TIMEZONES_LIST';
export const SET_TIMEZONES_LIST = 'TIMEZONE/SET_TIMEZONES_LIST';

export const FETCH_GROUPS = 'PLATFORM/FETCH_GROUPS';
export const FETCH_GROUPS_REPORT = 'PLATFORM/FETCH_GROUPS_REPORT';
export const SET_GROUPS = 'PLATFORM/SET_GROUPS';
export const CREATE_GROUP = 'PLATFORM/CREATE_GROUP';
export const SET_NEW_GROUP = 'PLATFORM/SET_NEW_GROUP';
export const FETCH_GROUP_INFO = 'PLATFORM/FETCH_GROUP_INFO';
export const SET_GROUP_INFO = 'PLATFORM/SET_GROUP_INFO';
export const SET_GROUP_USERS = 'PLATFORM/SET_GROUP_USERS';
export const SET_GROUP_SUBSCRIPTIONS = 'PLATFORM/SET_GROUP_SUBSCRIPTIONS';
export const SET_IS_FETCHING_FLAG_GROUP = 'PLATFORM/SET_IS_FETCHING_FLAG_GROUP';
export const UPDATE_GROUP_INFO = 'PLATFORM/UPDATE_GROUP_INFO';

export const FETCH_EVENT_BILLING_STATEMENTS = 'PLATFORM/FETCH_EVENT_BILLING_STATEMENTS';
export const SET_PAST_EVENT_BILLING_STATEMENTS = 'PLATFORM/SET_PAST_EVENT_BILLING_STATEMENTS';
export const SET_DUE_DATE_EVENT_BILLING_STATEMENTS =
  'PLATFORM/SET_DUE_DATE_EVENT_BILLING_STATEMENTS';
export const SET_RECENT_EVENT_BILLING_STATEMENTS = 'PLATFORM/SET_RECENT_EVENT_BILLING_STATEMENTS';
export const SET_IS_FETCHING_FLAG_PAST_STATEMENT = 'PLATFORM/SET_IS_FETCHING_FLAG_PAST_STATEMENT';
export const SET_IS_FETCHING_FLAG_DUE_DATE_STATEMENT =
  'PLATFORM/SET_IS_FETCHING_FLAG_DUE_DATE_STATEMENT';
export const SET_IS_FETCHING_FLAG_RECENT_STATEMENT =
  'PLATFORM/SET_IS_FETCHING_FLAG_RECENT_STATEMENT';

export const SET_SELECTED_ROLE = 'NAVLINK/SET_SELECTED_ROLE';
export const STORE_SELECTED_ROLE = 'NAVLINK/STORE_SELECTED_ROLE';

// Reducer
const initialState = {
  user: {},
  activeUser: {},
  userIdx: 0,
  noAccount: false,
  isFetching: false,
  timezonesList: [],
  isFetchingPayee: true,
  groups: [],
  group: {
    isFetching: false,
    info: {},
    users: [],
    subscriptions: []
  },
  selectedRole: getSelectedRole({})
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DELETE_PAYEE_ACCOUNT:
      return {
        ...state,
        isFetching: true
      };
    case EDIT_PAYEE_ACCOUNT:
      return {
        ...state,
        isFetching: true
      };
    case ADD_PAYEE_ACCOUNT:
      return {
        ...state,
        isFetching: true
      };
    case GET_PAYEE_ACCOUNTS:
      return {
        ...state,
        isFetching: true
      };
    case SET_PAYEE_ACCOUNTS:
      return {
        ...state,
        user: { ...state.user, bankAccounts: action.payload },
        activeUser: { ...state.activeUser, bankAccounts: action.payload }
      };
    case DELETE_USER_ADDRESS:
      return {
        ...state,
        isFetching: true
      };
    case UPDATE_USER_ADDRESS:
      return {
        ...state,
        isFetching: true
      };
    case SET_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload }
      };
    case SET_USER_IDX:
      return assoc('userIdx', action.payload)(state);
    case SET_NO_ACCOUNT:
      return {
        ...state,
        noAccount: action.payload
      };
    case SET_IS_FETCHING_FLAG:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_IS_FETCHING_FLAG_PAYEE:
      return {
        ...state,
        isFetchingPayee: action.payload
      };
    case SET_TIMEZONES_LIST:
      return {
        ...state,
        timezonesList: action.payload
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.payload
      };
    case SET_PAST_EVENT_BILLING_STATEMENTS:
      return {
        ...state,
        pastEventBillingStatements: action.payload
      };
    case SET_DUE_DATE_EVENT_BILLING_STATEMENTS:
      return {
        ...state,
        dueDateEventBillingStatements: action.payload
      };
    case SET_RECENT_EVENT_BILLING_STATEMENTS:
      return {
        ...state,
        recentEventBillingStatements: action.payload
      };
    case SET_IS_FETCHING_FLAG_PAST_STATEMENT:
      return {
        ...state,
        isFetchingPastStatement: action.payload
      };
    case SET_IS_FETCHING_FLAG_DUE_DATE_STATEMENT:
      return {
        ...state,
        isFetchingDueDateStatement: action.payload
      };
    case SET_IS_FETCHING_FLAG_RECENT_STATEMENT:
      return {
        ...state,
        isFetchingRecentStatement: action.payload
      };
    case SET_NEW_GROUP: {
      const newGroup = action.payload;
      const isExistingGroup = state.groups.find(g => g.name === newGroup.name);

      return isExistingGroup
        ? { ...state }
        : {
            ...state,
            groups: [...state.groups, action.payload]
          };
    }
    case SET_IS_FETCHING_FLAG_GROUP:
      return {
        ...state,
        group: {
          ...state.group,
          isFetching: action.payload
        }
      };
    case SET_GROUP_INFO:
      return {
        ...state,
        group: {
          ...state.group,
          info: action.payload
        }
      };
    case SET_GROUP_USERS:
      return {
        ...state,
        group: {
          ...state.group,
          users: action.payload
        }
      };
    case SET_GROUP_SUBSCRIPTIONS:
      return {
        ...state,
        group: {
          ...state.group,
          subscriptions: action.payload
        }
      };
    case SET_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload
      };
    default:
      return state;
  }
}

export const deletePayeeAccount = payload => ({
  payload,
  type: DELETE_PAYEE_ACCOUNT
});

export const editPayeeAccount = payload => ({
  payload,
  type: EDIT_PAYEE_ACCOUNT
});

export const addPayeeAccount = payload => ({
  payload,
  type: ADD_PAYEE_ACCOUNT
});

export const setPayeeAccounts = payload => ({
  payload,
  type: SET_PAYEE_ACCOUNTS
});

export const getPayeeAccounts = payload => ({
  payload,
  type: GET_PAYEE_ACCOUNTS
});

export const deleteUserAddress = payload => ({
  payload,
  type: DELETE_USER_ADDRESS
});

export const updateUserAddress = payload => ({
  payload,
  type: UPDATE_USER_ADDRESS
});

export const createAccount = payload => ({
  payload,
  type: CREATE_ACCOUNT
});

export const login = payload => ({
  payload,
  type: LOGIN
});

export const logout = () => ({
  type: LOGOUT
});

export const setReferrer = payload => ({
  payload,
  type: SET_REFERRER
});

export const setCurrentUserIdx = payload => ({
  payload,
  type: SET_REFERRER
});

export const changePassword = payload => ({
  payload,
  type: CHANGE_PASSWORD
});

export const changeEmail = payload => ({
  payload,
  type: CHANGE_EMAIL
});

export const resendEmail = () => ({
  type: RESEND_EMAIL
});

export const setUser = payload => ({
  type: SET_USER,
  payload
});

export const setActiveUser = payload => ({
  type: SET_ACTIVE_USER,
  payload
});

export const updateProfile = payload => ({
  payload,
  type: UPDATE_USER_PROFILE
});

export const setNoAccount = payload => ({
  type: SET_NO_ACCOUNT,
  payload
});

export const setIsFetchingFlag = payload => ({
  type: SET_IS_FETCHING_FLAG,
  payload
});

export const setIsFetchingFlagPayee = payload => ({
  type: SET_IS_FETCHING_FLAG_PAYEE,
  payload
});

export const fetchTimezonesList = payload => ({
  type: FETCH_TIMEZONES_LIST,
  payload
});

export const setTimezonesList = payload => ({
  type: SET_TIMEZONES_LIST,
  payload
});

export const fetchGroups = payload => ({
  type: FETCH_GROUPS,
  payload
});

export const fetchGroupsReport = payload => ({
  type: FETCH_GROUPS_REPORT,
  payload
});

export const setGroups = payload => ({
  type: SET_GROUPS,
  payload
});

export const createGroup = payload => ({
  type: CREATE_GROUP,
  payload
});

export const setNewGroup = payload => ({
  type: SET_NEW_GROUP,
  payload
});

export const fetchGroupInfo = payload => ({
  type: FETCH_GROUP_INFO,
  payload
});

export const setGroupInfo = payload => ({
  type: SET_GROUP_INFO,
  payload
});

export const setGroupUsers = payload => ({
  type: SET_GROUP_USERS,
  payload
});

export const setGroupSubscriptions = payload => ({
  type: SET_GROUP_SUBSCRIPTIONS,
  payload
});

export const setIsFetchingGroup = payload => ({
  type: SET_IS_FETCHING_FLAG_GROUP,
  payload
});

export const updateGroupInfo = payload => ({
  type: UPDATE_GROUP_INFO,
  payload
});

export const fetchEventBillingStatements = payload => ({
  type: FETCH_EVENT_BILLING_STATEMENTS,
  payload
});

export const setPastEventBillingStatements = payload => ({
  type: SET_PAST_EVENT_BILLING_STATEMENTS,
  payload
});

export const setDueDatetEventBillingStatements = payload => ({
  type: SET_DUE_DATE_EVENT_BILLING_STATEMENTS,
  payload
});

export const setRecentEventBillingStatements = payload => ({
  type: SET_RECENT_EVENT_BILLING_STATEMENTS,
  payload
});

export const setIsFetchingFlagPastStatement = payload => ({
  type: SET_IS_FETCHING_FLAG_PAST_STATEMENT,
  payload
});

export const setIsFetchingFlagDueDateStatement = payload => ({
  type: SET_IS_FETCHING_FLAG_DUE_DATE_STATEMENT,
  payload
});

export const setIsFetchingFlagRecentStatement = payload => ({
  type: SET_IS_FETCHING_FLAG_RECENT_STATEMENT,
  payload
});

export const setSelectedRole = payload => ({
  type: SET_SELECTED_ROLE,
  payload
});

export const storeSelectedRole = payload => ({
  type: STORE_SELECTED_ROLE,
  payload
});
