import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
`;

export const TaxInfoWrapper = styled.div`
  padding: 5px 30px;
`;

export const ButtonWrapper = styled.div`
  width: 60%;
  margin: 0 auto 20px auto;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 12px 0px;
`;

export const HalfContent = styled.div`
  width: ${({ width }) => width};
  display: inline-block;
  margin: ${({ margin }) => margin || '0px 25px 0px 0px'};
  padding: 15px 0px;
`;

export const FormFields = styled.div`
  display: block;
`;
