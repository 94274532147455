import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export default Wrapper;
