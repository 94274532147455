import React from 'react';

import { MODALS } from '../config';
import AddGroupAdmin from './AddGroupAdmin';
import RemoveGroupAdmin from './RemoveGroupAdmin';

export const Modal = ({ modalType, ...props }) => {
  switch (modalType) {
    case MODALS.ADD_GROUP_ADMIN:
      return <AddGroupAdmin {...props} />;
    case MODALS.REMOVE_GROUP_ADMIN:
      return <RemoveGroupAdmin {...props} />;
    default:
      return null;
  }
};

export default Modal;
