import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import { fetchEventTeams } from '../../../../core/Teams';
import { getUser } from '../../../../core/Auth/selectors';
import { getEventAdmins } from '../../../../core/Users/selectors';
import { getTeams, getTeamMeta } from '../../../../core/Teams/selectors';
import { getRoles } from '../../../../core/Roles/selectors';
import { getEventGameLevels } from '../../../../core/Events/selectors';
import { paginationAware, filterConfigToNames, updateOnPageChange } from '../../PaginationAware';
import buildConfig from '../../../components/TeamsList/config';
import { fetchEventGameLevels } from '../../../../core/Events';

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch(fetchEventTeams(payload)) && dispatch(fetchEventGameLevels(payload))
});

const PAGINATION_AWARE_PREFIX = 'tm';

const mapStateToProps = (state, props) => ({
  teams: getTeams(state),
  admins: getEventAdmins(state),
  user: getUser(state),
  metaData: getTeamMeta(state),
  gamelevels: getEventGameLevels(state),
  onLoadPageArgs: {
    roles: getRoles(state),
    eventId: props && props.match && props.match.params && props.match.params.eventId
  }
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware(
    {
      fetcherQuote: 'onLoad',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'metaData',
      searchFilter: ['team.name'],
      filterNames: filterConfigToNames(buildConfig({}).filters)
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  )
);
