import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Icon from '../../Icon';
import { pxToRem } from '../../../../utils/helpers';
import { COLORS } from '../../../../utils/styles';

export const Container = styled.div`
  width: ${pxToRem('255px')};
`;

export const Header = styled.div`
  height: ${pxToRem('126px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bgColor }) => bgColor};
`;

export const Quantity = styled.span`
  font: bold ${pxToRem('75px')} 'RobotoBold', sans-serif;
  letter-spacing: -0.9px;
  color: ${COLORS.white};
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
  padding: ${pxToRem('20px')} ${pxToRem('24px')};
  background-image: linear-gradient(to bottom, ${COLORS.aliceBlue}, ${COLORS.solitude});
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  min-width: ${pxToRem('44px')};
  min-height: ${pxToRem('44px')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${pxToRem('15px')};
  border-radius: 50%;
  background: ${({ bgColor }) => bgColor};
`;

export const StyledIcon = styled(Icon)`
  margin: 0;
`;

export const Label = styled.span`
  font: 500 ${pxToRem('17px')} 'RobotoMedium', sans-serif;
  letter-spacing: -0.2px;
  color: ${COLORS.cobaltGrey};
`;

export default Container;
