import React from 'react';
import { Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import { SectionContainer } from './styled-components';
import {
  PrimaryButton,
  SecondaryButton,
  GhostButton,
  CancelButton,
  H3,
  Checkbox
} from '../../global-material-components';

const ButtonMargin = {
  marginRight: '20px'
};

const IconMargin = {
  marginRight: '10px'
};

export default function Buttons() {
  const [checked, setChecked] = React.useState(false);

  return (
    <>
      <H3>Buttons</H3>
      <Divider />

      <SectionContainer>
        <PrimaryButton style={ButtonMargin}>Primary</PrimaryButton>
        <SecondaryButton style={ButtonMargin}>Secondary</SecondaryButton>
        <GhostButton style={ButtonMargin}>Ghost</GhostButton>
        <CancelButton style={ButtonMargin}>Cancel</CancelButton>
      </SectionContainer>

      <SectionContainer>
        <PrimaryButton style={ButtonMargin}>
          <AddIcon style={IconMargin} /> Primary
        </PrimaryButton>
        <SecondaryButton style={ButtonMargin}>
          <AddIcon style={IconMargin} /> Secondary
        </SecondaryButton>
        <GhostButton style={ButtonMargin}>
          <AddIcon style={IconMargin} /> Ghost
        </GhostButton>
        <CancelButton style={ButtonMargin}>
          <AddIcon style={IconMargin} /> Cancel
        </CancelButton>
      </SectionContainer>

      <SectionContainer>
        <Checkbox value="checkbox1" checked={checked} onChange={() => setChecked(!checked)} />
      </SectionContainer>

      {/* Add Sliders, Radio Buttons */}
    </>
  );
}
