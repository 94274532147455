import styled from 'styled-components';
import { isNil } from 'ramda';
import Icon from '../Icon';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const SearchContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchField = styled.input`
  position: relative;
  width: 100%;
  height: ${pxToRem('24px')};
  box-sizing: border-box;
  padding: 0 ${pxToRem('18px')} 0 ${pxToRem('11px')};
  margin-right: ${pxToRem('12px')};
  background: transparent;
  border: none;
  border-radius: ${pxToRem('12px')};
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('13px')};
  color: ${({ color }) => (isNil(color) ? COLORS.white : color)};
  outline: none;

  &::placeholder {
    font-family: 'RobotoCondensedLight';
    font-size: ${pxToRem('13px')};
    color: ${({ color }) => (isNil(color) ? COLORS.denimBlue : color)};
  }
  ${props =>
    props.isUserAddAction &&
    `
    padding: 0 0 0 5px;
    margin-right: 0;
  `}
`;

export const SearchIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  margin: 0;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  ${props =>
    props.isUserAddAction &&
    `
    padding: 0 0 0 5px;
    margin-right: 0;
  `}
`;
