import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { VariableSizeList } from 'react-window';
import moment from 'moment-timezone';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  MainContainer,
  Primary,
  ItemList,
  HeaderContainer,
  MainTitle,
  Wrapper,
  EndText,
  Note,
  Scroller,
  SelectAll,
  ActionMenuWrapper,
  SelectAllWrapper,
  PaginationWrapper,
  Overlay
} from './styled-components';
import PaginationBar from '../PaginationBar';
import { COLORS } from '../../../utils/styles';
import FilterSortSearch from './FilterSortSearch';
import Item from './Item';
import ActionDropdown from '../ActionDropdown';
import LoaderWrapper from '../LoaderWrapper';

import Enhancer from '../../containers/SideList/unconnected';
import {
  areArraysDifferent,
  isFilteredByAvailability,
  isFilteredByDistance,
  removeNullProperties
} from '../../../utils/helpers';

let newExpandedItemSize;
let newNonExpandedItemSize;
const additonalRowHeight = 26;
const nonExpandedBasicItemSize = 66;

// Based on the following package and version
// https://www.npmjs.com/package/react-window-infinite-loader/v/1.0.9
const InfiniteLoaderWrapper = ({
  // Are there more items to load? This data should come from API.
  hasNextPage,

  // Are we currently loading a page of items? Should be based on flag set in redux store.
  isNextPageLoading,
  // Array of items loaded so far.
  items,
  // Callback function responsible for loading the next page of items.
  loadNextPage,
  // Component that makes up an item in the infinite loader list
  ItemComponent,
  // Used for providing a ref to the dom object of the list. This gives the parent component the ability to reset the list to the top.
  listRef,
  // Used for providing a ref to the dom object so that we can reset the infinite loader when the results are reset.
  infiniteLoaderRef,
  // Component needs to know what item is open if any so that it can alocate space in the infinite scroll list based on the expanded size.
  openedItems,
  // Unique property to get the primary key or id of the item.
  selectedProp
}) => {
  // Pass an empty callback to InfiniteLoader in case component rerenders and asks to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  const isItemLoaded = index => !hasNextPage || index < items.length + 1; // Artificially adding an extra item to hold the end of list message.

  const getItemSize = index => {
    const item = items[index];
    const expanded = item && !!openedItems.find(u => u[selectedProp] === item[selectedProp]);
    // When an item in the list is expanded it can have various sizes depending on screen size and/or expanded sub tab. Infinite list must account for this.
    const defaultExpandedItemSize = 440;

    return expanded
      ? newExpandedItemSize || defaultExpandedItemSize
      : newNonExpandedItemSize || nonExpandedBasicItemSize;
  };

  return (
    <InfiniteLoader
      id="InfiniteLoader"
      loadMoreItems={loadMoreItems}
      isItemLoaded={isItemLoaded}
      // MUST BE AT LEAST AN ARBITRARY LARGE NUMBER.. CANNOT BE SAME OR SMALLER THAN THE ARRAY SIZE OTHERWISE LOAD MORE ITEMS WILL NOT TRIGGER
      itemCount={items.length + 100}
      threshold={40} // When reaching the threshold number from the end the loader will start fetching additional data.
      ref={infiniteLoaderRef}
    >
      {({ onItemsRendered }) => (
        <VariableSizeList
          id="VariableSizeList"
          ref={listRef}
          style={{ overflowX: 'hidden', width: 'auto' }}
          itemCount={items.length + 1} // Artificially adding an extra item to hold the end of list message.
          height={window.innerHeight}
          width="100%"
          itemSize={getItemSize}
          className="infinite-scroll-component"
          onItemsRendered={onItemsRendered}
        >
          {ItemComponent}
        </VariableSizeList>
      )}
    </InfiniteLoader>
  );
};

/**
 * Note: to utilize pagination, parent must use paginationAware middleware
 * Note: pass {...props} to include any misc. props
 * @param onLoad {Function} function to fetch items
 * @param onFetchItemDetail {Function} function to fetch item details when item is expanded
 * @param items {Array} Collection of items to display in list
 * @param roles {Array} *optional* list of roles (with properties alias & list) - only for users
 * @param selectedProp {String} *optional* specify the list item's unique selector prop
 * @param type {String} name of item type (i.e. team, official, location, etc.)
 * @param mainComponent {Function} Component for displaying each row (see Generic for example)
 * @param expandedComponent {Function} Component for displaying info on expand (see Generic for example)
 * @param headerComponent {Function} *optional* Custom header component
 * @param config {Object} Object with sort, filters, and actions objects to customize functionality
 * @param noCollapse {Boolean} *optional* Collapse chevron is hidden when set to true
 * @param singleSelect {Boolean} *optional* Enforces single select on list when set to true
 */
export const SideList = ({
  items: listItems,
  type,
  eventName,
  role,
  selectedProp,
  mainComponent,
  expandedComponent,
  headerComponent: HeaderComponent,
  onKeyPressed,
  onKeyReleased,
  onItemSelected,
  selectable: { selected: selectedItems, opened: openedItems, conditions, selectAll },
  onExpandItem,
  pagination,
  search,
  sort,
  filter,
  config,
  isGamesEventGroupAdmin,
  categories,
  allowSelfAssignment,
  isFetching,
  isFetchingDetails,
  addClassName,
  infinity,
  canEdit,
  expandSideList,
  setExpandSideList,
  availibiltyTab,
  setAvailibiltyTab,
  calendarActiveMonth,
  setCalendarActiveMonth,
  eventInfo,
  onAllSelect,
  eventsPageActive,
  searchLabel = null,
  hasNextPage,
  fetchNextOfficials,
  isFetchingEventRoles,
  isFetchingEventUsers
}) => {
  const infiniteLoaderRef = useRef(null);
  const listRef = useRef(null);
  const prevIsFetching = useRef(isFetching);

  const [calendarTimezone, setCalendarTimezone] = React.useState(moment.tz.guess());
  const [activeTab, setActiveTab] = React.useState(eventsPageActive ? 1 : 0);
  const [localOpenItems, setLocalOpenedItems] = React.useState([]);
  const [items, setItems] = React.useState(listItems);

  const fetchNextSet = () => {
    fetchNextOfficials({ role });
  };

  React.useEffect(() => {
    // Check if isFetching switched from true to false this means search or filter has been applied. Must reset loader.
    if (prevIsFetching.current && !isFetching) {
      if (infiniteLoaderRef.current && listRef.current) {
        const accountForAdditionalRow =
          isFilteredByDistance(filter) || isFilteredByAvailability(filter) ? additonalRowHeight : 0;
        newNonExpandedItemSize = nonExpandedBasicItemSize + accountForAdditionalRow;
        infiniteLoaderRef.current.resetloadMoreItemsCache();
        listRef.current.resetAfterIndex(0);
        listRef.current.scrollTo(0);
      }
    }
    prevIsFetching.current = isFetching;
  }, [isFetching, filter]);

  // Callback function to get the correct size based on the rendered flexable expanded sub tab.
  const handleResize = (index, newSize) => {
    const accountForAdditionalRowExpanded =
      isFilteredByDistance(filter) || isFilteredByAvailability(filter) ? additonalRowHeight : 0;
    newExpandedItemSize = newSize + accountForAdditionalRowExpanded;
    if (listRef.current) {
      listRef.current.resetAfterIndex(index);
    }
  };

  React.useEffect(() => {
    setItems(listItems);
  }, [listItems]);

  React.useEffect(() => {
    if (
      (openedItems && openedItems.length === 0) ||
      areArraysDifferent(openedItems, localOpenItems, selectedProp)
    ) {
      setActiveTab(eventsPageActive ? 1 : 0);
    }

    if (areArraysDifferent(openedItems, localOpenItems, selectedProp)) {
      // Must rerender the whole thing to support going from any expanded item to any other expanded item.
      // This is because f the item we are closing is above the one we are opening we would miss the rerender
      // of the item being closed as part of opening an item that is lower in the list.
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
        if (localOpenItems.length > 0 && openedItems.length > 0) {
          // With this scenario we must also assure that we are moving to the top of the current index so
          // that we do not end up expanding an item and just seeing the tail end of it. Setting the option
          // to smart helps it minimize the need to do this when the item is mostly in view.
          const index = items.findIndex(i => i[selectedProp] === openedItems[0][selectedProp]);
          listRef.current.scrollToItem(index, 'smart');
        }
        setLocalOpenedItems(openedItems);
      }
    }
  }, [openedItems, localOpenItems, selectedProp, items, eventsPageActive]);

  const ItemComponent = ({ index, style }) => {
    const item = items[index];
    const expanded = item && !!openedItems.find(u => u[selectedProp] === item[selectedProp]);
    const extraItem = index === items.length;

    return item ? (
      <Item
        mainComponent={mainComponent}
        expandedComponent={expandedComponent}
        filter={filter}
        isFirst={index === 0}
        item={item}
        key={`${item[selectedProp]}${index}`}
        onSelected={e => onItemSelected(item, e)}
        selected={selectAll || !!selectedItems.find(u => u[selectedProp] === item[selectedProp])}
        onExpand={() => onExpandItem(item, expanded)}
        expanded={expanded}
        role={role}
        isFetchingDetails={isFetchingDetails}
        canEdit={canEdit}
        expandSideList={expandSideList}
        setExpandSideList={setExpandSideList}
        availibiltyTab={availibiltyTab}
        setAvailibiltyTab={setAvailibiltyTab}
        calendarActiveMonth={calendarActiveMonth}
        setCalendarActiveMonth={setCalendarActiveMonth}
        eventInfo={eventInfo}
        eventsPageActive={eventsPageActive}
        style={removeNullProperties(style)}
        handleResize={handleResize}
        index={index}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        setCalendarTimezone={setCalendarTimezone}
        calendarTimezone={calendarTimezone}
        isFetchingEventUsers={isFetchingEventUsers}
      />
    ) : (
      <>
        {extraItem && items.length > 0 && !isFetchingEventRoles && (
          <div style={style}>
            <EndText infinite>End of the list</EndText>
          </div>
        )}
        {extraItem && items.length > 0 && isFetchingEventRoles && (
          <div style={style}>
            <EndText infinite>Pending.....</EndText>
          </div>
        )}
        {extraItem && items.length === 0 && (
          <div style={style}>
            <EndText infinite>There are no results</EndText>
          </div>
        )}
      </>
    );
  };

  return (
    <Wrapper className={addClassName} expand={expandSideList}>
      <Primary>
        {config.actions.options.map(
          ({ showModal, onSubmit, component: Component, onClick, label, ...rest }) =>
            showModal && (
              <Component
                key={label}
                onSubmit={onSubmit}
                onClose={() => onClick(false)}
                selected={selectedItems}
                allSelect={selectAll}
                type={type}
                eventName={eventName}
                config={config}
                toggleSelectAll={onAllSelect}
                {...rest}
              />
            )
        )}
        <MainContainer onKeyDown={onKeyPressed} onKeyUp={onKeyReleased} tabIndex="0">
          <HeaderContainer expand={expandSideList}>
            {HeaderComponent ? (
              <HeaderComponent />
            ) : (
              <>
                <MainTitle>{`${type}s`.toUpperCase()}</MainTitle>
                {!isGamesEventGroupAdmin &&
                  !!categories.length &&
                  !!categories[0].user_categories.length && (
                    <Note>
                      You can view {type.toLowerCase()}s for categories:{' '}
                      {categories.map(category => category.name).join(', ')}
                    </Note>
                  )}
              </>
            )}
            <ActionMenuWrapper>
              {config.enableSelectAll && items.length ? (
                <SelectAllWrapper>
                  <SelectAll
                    name="selectAll"
                    value={selectAll}
                    label="select all"
                    onChange={() => onAllSelect()}
                  />
                </SelectAllWrapper>
              ) : null}
              {!allowSelfAssignment && !!config.actions.options.length && (
                <ActionDropdown
                  conditions={conditions}
                  disabled={!selectAll && !selectedItems.length}
                  config={config.actions}
                  theme={{
                    icon: {
                      color: COLORS.denimBlue,
                      marginTop: '0',
                      marginRight: '6px'
                    },
                    dropdown: {
                      topOffset: '48px',
                      rightOffset: '0px',
                      width: '150px'
                    },
                    option: {
                      background: COLORS.white,
                      color: COLORS.hawkesBlue,
                      hoverBackground: COLORS.aliceBlueVariant,
                      hoverColor: COLORS.denimBlue
                    }
                  }}
                />
              )}
            </ActionMenuWrapper>
          </HeaderContainer>
          {!allowSelfAssignment && !expandSideList && (
            <FilterSortSearch
              backgroundColor={COLORS.catSkillWhite}
              filterBarItemFontColor={COLORS.shuttleGray}
              placeholder={`Search ${
                searchLabel
                  ? searchLabel[0].toUpperCase() + searchLabel.slice(1)
                  : type[0].toUpperCase() + type.slice(1)
              } Name`}
              filter={filter}
              search={search}
              sort={{
                ...sort,
                onSort: sortingValue => {
                  if (infinity) {
                    sort.onSort(sortingValue, 1);
                  } else sort.onSort(sortingValue);
                }
              }}
              config={config}
            />
          )}
          {infinity ? (
            <>
              <Overlay show={isFetching}>
                <LoaderWrapper isFetching />
              </Overlay>
              <Scroller
                id="height-provider"
                availaibiltyExpand={expandSideList}
                className="infinite-scroll-component"
              >
                <InfiniteLoaderWrapper
                  ItemComponent={ItemComponent}
                  listRef={listRef}
                  items={items}
                  openedItems={openedItems}
                  selectedProp={selectedProp}
                  isNextPageLoading={isFetchingEventRoles}
                  hasNextPage={hasNextPage}
                  loadNextPage={fetchNextSet}
                  infiniteLoaderRef={infiniteLoaderRef}
                />
              </Scroller>
            </>
          ) : (
            <LoaderWrapper isFetching={isFetching}>
              {items.length ? (
                <ItemList>
                  {items.map((item, i) => {
                    const expanded = !!openedItems.find(
                      u => u[selectedProp] === item[selectedProp]
                    );
                    return (
                      <Item
                        mainComponent={mainComponent}
                        expandedComponent={expandedComponent}
                        isFirst={i === 0}
                        filter={filter}
                        item={item}
                        key={`${item[selectedProp]}${i}`}
                        onSelected={() => onItemSelected(item)}
                        selected={
                          selectAll ||
                          !!selectedItems.find(u => u[selectedProp] === item[selectedProp])
                        }
                        onExpand={() => onExpandItem(item, expanded)}
                        expanded={expanded}
                        role={role}
                        isFetchingDetails={isFetchingDetails}
                        canEdit={canEdit}
                        expandSideList={expandSideList}
                        setExpandSideList={setExpandSideList}
                        availibiltyTab={availibiltyTab}
                        setAvailibiltyTab={setAvailibiltyTab}
                        calendarActiveMonth={calendarActiveMonth}
                        setCalendarActiveMonth={setCalendarActiveMonth}
                        eventInfo={eventInfo}
                        isFetchingEventUsers={isFetchingEventUsers}
                      />
                    );
                  })}
                </ItemList>
              ) : (
                <EndText>There are no results</EndText>
              )}
            </LoaderWrapper>
          )}
        </MainContainer>
      </Primary>
      {infinity
        ? ''
        : !!pagination.totalCount && (
            <PaginationWrapper>
              <PaginationBar {...pagination} showPagination={false} />
            </PaginationWrapper>
          )}
    </Wrapper>
  );
};

SideList.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  isFetchingEventUsers: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(Object),
  requestUserListCSV: PropTypes.func.isRequired,
  onKeyPressed: PropTypes.func,
  onKeyReleased: PropTypes.func,
  onItemSelected: PropTypes.func,
  fetchNextOfficials: PropTypes.func,
  onExpandItem: PropTypes.func.isRequired,
  selectable: PropTypes.shape({
    selected: PropTypes.arrayOf(Object),
    opened: PropTypes.arrayOf(Object),
    showImportModal: PropTypes.bool,
    showRemoveUsersModal: PropTypes.bool
  }).isRequired,
  toggleVisibility: PropTypes.func,
  isVisible: PropTypes.bool,
  actionDropdownOptions: PropTypes.arrayOf(Object),
  metaData: PropTypes.shape({
    page: PropTypes.number,
    totalCount: PropTypes.number,
    limit: PropTypes.number
  }).isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  sort: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  pagination: PropTypes.objectOf(Object).isRequired,
  downloadable: PropTypes.bool,
  config: PropTypes.shape({
    sort: PropTypes.object,
    filters: PropTypes.arrayOf(Object),
    actions: PropTypes.object
  }).isRequired,
  type: PropTypes.string.isRequired,
  role: PropTypes.string,
  selectedProp: PropTypes.string,
  mainComponent: PropTypes.func.isRequired,
  expandedComponent: PropTypes.func.isRequired,
  headerComponent: PropTypes.func,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  noCollapse: PropTypes.bool,
  setFilterCount: PropTypes.func.isRequired,
  filterCount: PropTypes.number.isRequired,
  isGamesEventGroupAdmin: PropTypes.bool,
  categories: PropTypes.arrayOf(Object),
  hasNextPage: PropTypes.bool,
  isFetchingEventRoles: PropTypes.bool
};

SideList.defaultProps = {
  items: [],
  onKeyPressed: always(undefined),
  onKeyReleased: always(undefined),
  onItemSelected: always(undefined),
  toggleVisibility: always(undefined),
  isVisible: false,
  actionDropdownOptions: [],
  downloadable: false,
  role: null,
  selectedProp: 'eventRoleId',
  noCollapse: false,
  headerComponent: null,
  isGamesEventGroupAdmin: false,
  categories: [],
  fetchNextOfficials: null,
  hasNextPage: false,
  isFetchingEventRoles: false
};

export default Enhancer(React.memo(SideList));
