import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { pxToRem } from '../../../utils/helpers';
import { HEIGHTS, COLORS, mediaQueries } from '../../../utils/styles';

export const FieldWrapper = styled.div`
  margin: 2rem ${pxToRem('30px')} ${pxToRem('10px')} 0;
  flex: 1;
  ${mediaQueries.xs(` {
    margin: 2rem ${pxToRem('15px')} ${pxToRem('10px')} 0;

  }`)}
  ${({ custom }) => custom || ''};
`;

export const SubSectionTitle = styled.h4`
  color: ${COLORS.denimBlue};
  font-size: ${pxToRem('15px')};
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 1rem 0 1.5rem 0.5rem;
  text-transform: uppercase;
  ${mediaQueries.sm(`
  margin: 1rem 0 0rem 0.5rem;
  `)};
`;

export const SubSectionWrapper = styled(Col)`
  padding-left: ${pxToRem('20px')};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Content = styled.div`
  margin-top: ${HEIGHTS.appHeader};
  background-color: ${COLORS.lightCyanGray};
  padding: ${pxToRem('20px')} 6%;
`;

export const Note = styled.h2`
  font-size: ${pxToRem('21px')};
  color: ${COLORS.sapphire};
  margin: 0 0 ${pxToRem('20px')};
`;

export const Paper = styled.div`
  padding: ${pxToRem('40px')} 4% ${pxToRem('30px')};
  background-color: ${COLORS.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
`;

export const checkFieldStyles = `
margin-top: ${pxToRem('12.5px')};
padding-left: ${pxToRem('8px')};
`;

export const checkFieldsStyles = `
padding-left: ${pxToRem('8px')};
`;

export const Wrapper = styled.div`
  background: ${COLORS.white};
  padding: 1.875rem;
  box-shadow: 0 0 8px rgb(0 0 0 / 30%);
`;

export const Section = styled.div`
  padding: 10px; /* Adjust padding for smaller screens */
  background-color: none !important;
  margin-top: 10px; /* Adjust margin for smaller screens */
`;

export const Title = styled.div`
  color: ${COLORS.denimBlue};
  font-weight: bold;
  margin-bottom: 5px; /* Adjust margin for smaller screens */
`;

export const Line = styled.div`
  margin-bottom: 5px; /* Adjust margin for smaller screens */
  display: flex;
  align-items: center;
`;

export const BlackText = styled.span`
  color: black;
  flex: 1;
`;

export const BlueText = styled.span`
  color: ${COLORS.denimBlue};
`;

export const RadioLabel = styled.label`
  margin-left: 5px;
`;

export const RadioInput = styled.input`
  margin-right: 3px;
`;

// Media query for mobile devices
const mobileBreakpoint = '576px';

export const MobileContainer = styled.div`
  @media (max-width: ${mobileBreakpoint}) {
    ${Section} {
      padding: 5px;
      margin-top: 5px;
    }

    ${Title} {
      margin-bottom: 3px;
    }

    ${Line} {
      margin-bottom: 3px;
    }

    ${RadioLabel} {
      margin-left: 3px;
    }

    ${RadioInput} {
      margin-right: 2px;
    }
  }
`;
