import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-flexbox-grid';
import { range } from 'ramda';

import PositionCell from './Game/Positions/PositionCell';

import { Clickable, Location, PositionsStyle } from './styled-components';

import Game from './Game';
import {
  GameWrapper,
  GamesListContainer,
  GameData,
  Checkbox,
  GameId,
  Detail,
  Status,
  Header,
  RightContent,
  GameInfoStyle
} from '../../StyledGames/GameRow/styled-components';

import { categoryFilter, getAssignedUsers, getGameById } from './utils';
import { arePropsChanged } from '../config';

const GamesList = ({
  games,
  crewLabels,
  selectedUser,
  selectedRoleId,
  onAssign,
  onRemoveAssignment,
  handleCheckboxClick,
  checkedGames,
  canEdit,
  setAssignmentWarnings,
  gamesInProgress,
  handleInProgress,
  gamesEventId,
  gamesEventUploadType,
  allowSelfAssignment,
  categories,
  onAssessmentReportClick,
  onIncidentReportClick,
  onGamesReportClick,
  onCrewVerificationClick,
  gameLevels,
  pagination,
  setModalProps,
  isEventChanged,
  setIsEventChanged,
  timezonesList,
  eventInfo,
  assignmentNote,
  currentEventUsers
}) => (
  <GamesListContainer>
    {games.map((game, index) => (
      <Game
        key={game.id}
        {...games[index]}
        crewLabels={crewLabels}
        selectedUser={selectedUser}
        selectedRoleId={selectedRoleId}
        onAssign={onAssign}
        onRemoveAssignment={onRemoveAssignment}
        handleCheckboxClick={handleCheckboxClick}
        checked={checkedGames.includes(game.id)}
        canEdit={canEdit}
        setAssignmentWarnings={setAssignmentWarnings}
        inProgress={gamesInProgress.findIndex(gameId => gameId === game.id) !== -1}
        onAction={() => handleInProgress(game.id)}
        gamesEventId={gamesEventId}
        gamesEventUploadType={gamesEventUploadType}
        categories={categoryFilter(categories, games, index)}
        onAssessmentReportClick={onAssessmentReportClick}
        onIncidentReportClick={onIncidentReportClick}
        onGamesReportClick={onGamesReportClick}
        onCrewVerificationClick={onCrewVerificationClick}
        gameLevels={getGameById(gameLevels, game)}
        position={index}
        pagination={pagination}
        setModalProps={setModalProps}
        allowSelfAssignment={allowSelfAssignment}
        isEventChanged={isEventChanged}
        setIsEventChanged={setIsEventChanged}
        timezonesList={timezonesList}
        eventInfo={eventInfo}
        assignmentNote={assignmentNote}
        users={getAssignedUsers(currentEventUsers, games[index])}
      />
    ))}
  </GamesListContainer>
);

GamesList.propTypes = {
  isEventChanged: PropTypes.bool.isRequired,
  setIsEventChanged: PropTypes.func.isRequired,
  games: PropTypes.arrayOf(Object).isRequired,
  crewLabels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      role_id: PropTypes.number.isRequired
    })
  ).isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    eventRoleId: PropTypes.string
  }),
  onAssign: PropTypes.func.isRequired,
  onRemoveAssignment: PropTypes.func.isRequired,
  selectedRoleId: PropTypes.number.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedGames: PropTypes.arrayOf(PropTypes.string).isRequired,
  canEdit: PropTypes.bool,
  setAssignmentWarnings: PropTypes.func.isRequired,
  gamesInProgress: PropTypes.arrayOf(PropTypes.string),
  handleInProgress: PropTypes.func.isRequired,
  gamesEventId: PropTypes.string,
  gamesEventUploadType: PropTypes.number,
  categories: PropTypes.arrayOf(Object),
  onAssessmentReportClick: PropTypes.func.isRequired,
  onIncidentReportClick: PropTypes.func.isRequired,
  onGamesReportClick: PropTypes.func.isRequired,
  gameLevels: PropTypes.arrayOf(Object).isRequired,
  pagination: PropTypes.objectOf(Object).isRequired,
  setModalProps: PropTypes.func.isRequired
};

GamesList.defaultProps = {
  selectedUser: null,
  canEdit: false,
  gamesInProgress: [],
  categories: [],
  gamesEventId: null,
  gamesEventUploadType: null
};
const GamePlaceholder = ({ crewLabels, gameLevels }) => (
  <GameWrapper>
    <GameInfoStyle>
      <Checkbox type="checkbox" onChange={() => null} />
      <Row>
        <Col xs={12}>
          <Header>
            <GameData header>
              <Clickable onClick={() => null}>~~~~~~~~~~ - ~~ @ ~~</Clickable>
              <GameId>~~~~</GameId>
            </GameData>

            <RightContent>
              <Status>Scheduled</Status>
            </RightContent>
          </Header>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <GameData>
            <Location>~~~~~~~~</Location>
          </GameData>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Header>
            <GameData lastDataRow>
              <Detail>Type: ~~~~</Detail>
              <Detail>Level: ~~~~</Detail>
            </GameData>
          </Header>
        </Col>
      </Row>
    </GameInfoStyle>
    <PositionsStyle>
      {range(0, crewLabels.length).map(i => (
        <PositionCell
          assignment={undefined}
          onClick={() => null}
          onDisabledClick={() => null}
          label={
            gameLevels && gameLevels.labels && gameLevels.labels[i] ? gameLevels.labels[i] : ''
          }
          selectedUser={undefined}
          onRemoveAssignment={() => null}
          canEdit
          onAction={() => null}
          key={`GamePlaceholder_${i}`}
          disabled
          cellLoading={false}
        />
      ))}
    </PositionsStyle>
  </GameWrapper>
);

GamePlaceholder.propTypes = {
  crewLabels: PropTypes.arrayOf(Object).isRequired
};

export default React.memo(GamesList, arePropsChanged);
