import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import { NotificationGridRow, Summary, ExpandedNotificationWrapper } from '../styled-components';
import { pxToRem, sInterpolator as routeBuilder } from '../../../../utils/helpers';
import { userIdxGetter } from '../../../../utils/auth';
import * as paths from '../../../../core/paths';
import Enhancer from '../../../containers/NotificationsMenu';

const NotificationExpanded = ({
  selectedNotification,
  onPayoutUpdateNotification,
  history,
  userId
}) => {
  return (
    <>
      <ExpandedNotificationWrapper flexDirection="column">
        <>
          <Button
            label="Go to Payment Profile page"
            primary
            heightNotificationExpanded={pxToRem('32px')}
            center
            margin="0.75rem 0 0"
            onClick={() => {
              onPayoutUpdateNotification({ expandedId: selectedNotification.id });
              history.push(
                routeBuilder(
                  { userIdx: userIdxGetter() || 0, userId },
                  paths.PROFILE_PAYMENT_METHOD
                )
              );
            }}
          />
        </>
      </ExpandedNotificationWrapper>
    </>
  );
};

NotificationExpanded.propTypes = {
  selectedNotification: PropTypes.shape({
    id: PropTypes.string,
    source_id: PropTypes.string,
    user_id: PropTypes.number,
    alert_type: PropTypes.string,
    status: PropTypes.string,
    game: PropTypes.object,
    created_at: PropTypes.string
  }),

  onPayoutUpdateNotification: PropTypes.func.isRequired
};

NotificationExpanded.defaultProps = {
  selectedNotification: {}
};

const SubMenu = ({ notifications, onPayoutUpdateNotification, history, userId }) => {
  return (
    <>
      {notifications.length &&
        notifications.map((notification, i) => {
          return (
            <Fragment key={notification.id}>
              <NotificationGridRow noBorder={i === notifications.length - 1}>
                <div style={{ textAlign: 'left' }}>
                  <Summary>
                    Your recent payout attempt has failed. Please verify your account information on
                    the Payment Profile page and try again. Contact Support if this problem
                    persists.
                  </Summary>
                </div>
                <NotificationExpanded
                  selectedNotification={notification}
                  onPayoutUpdateNotification={onPayoutUpdateNotification}
                  history={history}
                  userId={userId}
                />
              </NotificationGridRow>
            </Fragment>
          );
        })}
    </>
  );
};

SubMenu.propTypes = {
  notifications: PropTypes.arrayOf(Object),
  onPayoutUpdateNotification: PropTypes.func.isRequired,
  selectedNotification: PropTypes.shape({
    id: PropTypes.string,
    source_id: PropTypes.string,
    user_id: PropTypes.number,
    alert_type: PropTypes.string,
    status: PropTypes.string,
    created_at: PropTypes.string
  })
};

SubMenu.defaultProps = {
  notifications: [],
  selectedNotification: {}
};

export default Enhancer(SubMenu);
