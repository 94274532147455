import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, TableWrapper, Label } from './styled-components';
import config, { customStyle as configStyle } from './config';
import Button from '../../../Button';
import PayTableCard from './PayTableCard';
import COLORS from '../../../../../utils/styles';
import GameLevelAndTypeSorter from '../../GameLevels/GameLevelsSorter';
import CrewWarningModal from '../Modal/CrewWarningModal';

const useStyles = makeStyles(() => ({
  customTableHeader: {
    backgroundColor: COLORS.prussianBlue
  },
  customHeader: {
    color: COLORS.white,
    padding: '1px 16px'
  },
  customRowCell: {
    borderLeft: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0'
  },
  customExpandRowCell: {
    backgroundColor: COLORS.blackSqueeze,
    borderLeft: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0',
    boxShadow: 'inset -2px 5px 6px -5px #737373'
  }
}));

export const Header = ({ handleGameLevelsSorting, sort }) => {
  const classes = useStyles();
  return (
    <TableHead classes={{ root: classes.customTableHeader }}>
      <TableRow>
        {config.crewPayHeader.map(({ label, colSpan, name }) => (
          <TableCell colSpan={colSpan} key={name} classes={{ root: classes.customHeader }}>
            <Label name={name}>{label}</Label>
            {config.sortColumn.includes(name) && (
              <GameLevelAndTypeSorter
                value={sort && sort.value ? sort.value : 'game_level'}
                onSort={sort && sort.onSort}
                option={config.options.filter(option => option.name === name)}
                handleGameLevelsSorting={handleGameLevelsSorting}
                customStyle={configStyle.ArrowStyle}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

Header.propTypes = {
  handleGameLevelsSorting: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired
};

export const Body = ({
  crewPayTableInEdit,
  listData,
  fetchGameLevel,
  gameLevels,
  setCrewPayTableInEdit,
  onSubmit,
  cancelEdit,
  checkAndSetCrewPayTableInEdit,
  setCrewWarningModal,
  crewWarningModal,
  copyFeesFromGameLevel,
  crewPayFetching
}) => {
  const classes = useStyles();
  return (
    <TableBody>
      {listData && listData.length ? (
        listData.map(gameLevel => (
          <>
            <TableRow classes={{ root: classes.customRowCell }}>
              {config.crewPayHeader.map(({ name, colSpan }) => (
                <>
                  {name === 'EditIcon' ? (
                    <TableCell colSpan={colSpan}>
                      <Button
                        type="button"
                        label="Edit"
                        name="label"
                        noShadow
                        gameEdit
                        onClick={() => {
                          fetchGameLevel(gameLevel.id);
                          checkAndSetCrewPayTableInEdit(gameLevel.id);
                        }}
                        disabled={gameLevel.id === crewPayTableInEdit}
                        customStyle={gameLevel.id === crewPayTableInEdit && configStyle.EditButton}
                      />
                    </TableCell>
                  ) : (
                    <TableCell colSpan={colSpan}>{gameLevel[name] || 'NA'}</TableCell>
                  )}
                </>
              ))}
            </TableRow>
            {gameLevel.id === crewPayTableInEdit && (
              <TableCell colSpan={11} classes={{ root: classes.customExpandRowCell }}>
                <PayTableCard
                  crewPayTableInEdit={crewPayTableInEdit}
                  ListData={listData}
                  inEdit={gameLevel.id === crewPayTableInEdit}
                  setCrewPayTableInEdit={setCrewPayTableInEdit}
                  otherTables={listData.filter(glo => glo.id !== gameLevel.id)}
                  onSubmit={onSubmit}
                  cancelEdit={cancelEdit}
                  checkAndSetCrewPayTableInEdit={checkAndSetCrewPayTableInEdit}
                  crew_labels={gameLevels.filter(glo => glo.id === gameLevel.id)}
                  copyFeesFromGameLevel={copyFeesFromGameLevel}
                  gameLevels={gameLevels}
                  crewPayFetching={crewPayFetching}
                  payTable={gameLevels.filter(glo => glo.id === gameLevel.id)[0]}
                />
              </TableCell>
            )}
            {crewWarningModal && (
              <CrewWarningModal
                setCrewWarningModal={setCrewWarningModal}
                crewWarningModal={crewWarningModal}
                onSubmit={onSubmit}
              />
            )}
          </>
        ))
      ) : (
        <TableRow hover>
          <TableCell> There is No data to display</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

const CrewPayTable = ({
  crewPayTableInEdit,
  gameLevels,
  setCrewPayTableInEdit,
  onSubmit,
  cancelEdit,
  checkAndSetCrewPayTableInEdit,
  setCrewWarningModal,
  crewWarningModal,
  copyFeesFromGameLevel,
  fetchGameLevel,
  sort,
  crewPayList,
  handleGameLevelsSorting,
  crewPayFetching
}) => {
  return (
    <TableWrapper>
      <TableContainer>
        <Table>
          <Header handleGameLevelsSorting={handleGameLevelsSorting} sort={sort} />
          <Body
            crewPayTableInEdit={crewPayTableInEdit}
            listData={crewPayList}
            setCrewPayTableInEdit={setCrewPayTableInEdit}
            onSubmit={onSubmit}
            cancelEdit={cancelEdit}
            checkAndSetCrewPayTableInEdit={checkAndSetCrewPayTableInEdit}
            setCrewWarningModal={setCrewWarningModal}
            crewWarningModal={crewWarningModal}
            copyFeesFromGameLevel={copyFeesFromGameLevel}
            gameLevels={gameLevels}
            fetchGameLevel={fetchGameLevel}
            crewPayFetching={crewPayFetching}
          />
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

CrewPayTable.propTypes = {
  crewPayTableInEdit: PropTypes.string.isRequired,
  setCrewPayTableInEdit: PropTypes.func.isRequired,
  gameLevels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchGameLevel: PropTypes.func.isRequired,
  copyFeesFromGameLevel: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  checkAndSetCrewPayTableInEdit: PropTypes.func.isRequired,
  setCrewWarningModal: PropTypes.func.isRequired,
  crewWarningModal: PropTypes.object.isRequired,
  sort: PropTypes.object.isRequired,
  crewPayList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleGameLevelsSorting: PropTypes.func.isRequired
};

export default CrewPayTable;
