import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import IconWrapper from './styled-components';
import COLORS from '../../../utils/styles';

const HelpIcon = ({
  width,
  height,
  icon,
  color,
  title,
  link,
  linkTraget,
  addClassName,
  clickable
}) => (
  <IconWrapper className={addClassName}>
    <Link to={{ pathname: link }} target={linkTraget}>
      <Icon
        width={width}
        height={height}
        icon={icon}
        color={color}
        title={title}
        clickable={clickable}
        margin="10px 10px"
      />
    </Link>
  </IconWrapper>
);

HelpIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
  clickable: PropTypes.bool
};

HelpIcon.defaultProps = {
  width: 16,
  height: 16,
  color: COLORS.white,
  title: '',
  clickable: false
};

export default HelpIcon;
