import { compose, withState, withHandlers, pure } from 'recompose';
import { connect } from 'react-redux';
import { fetchEventUsers } from '../../../core/Users';

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch(fetchEventUsers(payload))
});
const mapStateToProps = state => ({
  ...state
});
export default compose(
  withState('activeFile', 'setActiveFile', null),
  withState('resultMessage', 'setResultMessage', null),
  withState('inProgress', 'setInProgress', false),
  withState('rank', 'setRank', ''),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    resolve: ({ setActiveFile, setInProgress, setResultMessage }) => response => {
      if (response && response.type === 'error') {
        setActiveFile(null);
        setResultMessage(response);
      } else if (response && response.data) {
        setResultMessage({ type: 'success', message: response.data });
      }

      setInProgress(false);
    }
  }),
  withHandlers({
    onSubmitImport: ({ onSubmit, resolve, setInProgress }) => file => {
      setInProgress(true);
      onSubmit({ file, resolve });
    }
  }),
  pure
);
