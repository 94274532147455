import React from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import './availabilityCalendar.css';
import FREQUENCIES from './availabilityConstants';
import CustomEvent from './AvailabilityEvents/AvailabilityEvents';
import { COLORS, ICONS } from '../../../utils/styles';
import {
  Wrapper,
  HeaderWrapper,
  DayName,
  DateNumber,
  ToolbarWrapper,
  ToolbarHeader,
  DateRange,
  Chevron
} from './styled-components';
import { convertToTimezone } from '../../../utils/helpers';
import { DATE_FORMATS } from '../../../constants';
import Enhancer from '../../containers/AssignmentAvailability';

require('moment-recur');

moment.updateLocale('en', {
  week: {
    dow: 0
  }
});

BigCalendar.momentLocalizer(moment);
const minTime = moment().hour(7).minute(0).toDate();
const maxTime = moment().hour(23).minute(0).toDate();

const formatDateRange = ({ start, end }, culture, localizer) => {
  const startString = localizer.format(
    start,
    moment(start).isSame(end, 'year') ? 'MMMM D' : 'MMMM D, YYYY'
  );
  const endString = localizer.format(
    end,
    moment(end).isSame(start, 'month') ? 'D, YYYY' : 'MMMM D, YYYY'
  );
  return `${startString} - ${endString}`;
};
/**
 * Takes two moment dates and returns a moment that combines the date and time accordingly
 * @param {Object} time Moment object with desired time
 * @param {Object} day  Moment object with desired date
 * @return {Object} Moment object with combined properties
 */
const dateFromTimeAndDay = (time, day) =>
  moment({
    year: day.year(),
    month: day.month(),
    day: day.date(),
    hour: time.hour(),
    minute: time.minute(),
    second: time.second()
  }).toDate();

const Header = ({ date, label }) => {
  const isToday = moment(date).isSame(moment(), 'day');
  return (
    <HeaderWrapper>
      <DayName>{label.split(' ')[0].slice(0, 3)}</DayName>
      <DateNumber isToday={isToday}>{label.split(' ')[1]}</DateNumber>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

/**
 * Takes an availability object and returns an array of original event and all future events
 * based on recurring, frequency, interval, and count properties
 * @param {Object} availability
 * @return {Array} Array of Moment objects for availability and all future occurrences
 * formatted as calendar events
 */

const Toolbar = ({ onNavigate, date }) => (
  <ToolbarWrapper>
    <ToolbarHeader>
      <Chevron
        icon={ICONS.ARROWWITHLINE}
        color={COLORS.newDarkBlue}
        width={16}
        height={16}
        onClick={() => {
          onNavigate('PREV', moment(date));
        }}
      />

      <Chevron
        icon={ICONS.ARROWWITHLINE}
        color={COLORS.newDarkBlue}
        width={16}
        height={16}
        onClick={() => {
          onNavigate('NEXT', moment(date));
        }}
        reverse
      />
      <DateRange>{moment(date).format('MMM-YYYY')}</DateRange>
    </ToolbarHeader>
  </ToolbarWrapper>
);

Toolbar.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

const parseRecur = (availability, timezone) => {
  const assignment_Status = ['declined'];
  if (!availability) return [];
  if (assignment_Status.includes(availability && availability.availability_type.toLowerCase()))
    return [];
  // TODO: need to update the logic as below
  /**
   return getAllSlots({
    type: availability.frequency,
    start: moment.parseZone(availability.time_from).format(DATE_FORMATS.FULL_DATE_TIME),
    end: moment.parseZone(availability.time_to).format(DATE_FORMATS.FULL_DATE_TIME),
    recursion: availability.count,
    weekDays: availability.interval
    }).map(({ start, end }) => ({
    id: availability.id,
    title: `${availability.availability_type}`,
    allDay: false,
    start: convertUtcToTimezone(start, timezone, DATE_FORMATS.DATE_TIME_IOS),
    end: convertUtcToTimezone(end, timezone, DATE_FORMATS.DATE_TIME_IOS),
    gameId: availability.gameId,
    gameStatus: availability.game_Status,
    assignedBy: availability.assigned_by,
    eventName: availability.eventName
    }));
   */
  const initialEvent = {
    id: availability.id,
    title: availability.availability_type,
    allDay: false,
    start: convertToTimezone(
      new Date(availability.time_from),
      timezone,
      DATE_FORMATS.DATE_TIME_IOS,
      DATE_FORMATS.FULL_DATE_TIME
    ),
    end: convertToTimezone(
      new Date(availability.time_to),
      timezone,
      DATE_FORMATS.DATE_TIME_IOS,
      DATE_FORMATS.FULL_DATE_TIME
    ),
    gameId: availability.gameId,
    gameStatus: availability.game_Status,
    assignedBy: availability.assigned_by,
    eventName: availability.eventName,
    note: availability.note
  };
  const events = [initialEvent];
  if (!availability.recurring) return events;
  let futureEvents;

  if (availability.frequency === FREQUENCIES.daily) {
    futureEvents = moment(availability.time_from).recur().every(1).days().next(availability.count);
  } else if (availability.frequency === FREQUENCIES.weekly) {
    futureEvents = moment(availability.time_from)
      .recur()
      .every(availability.interval.map(day => (Number(day) + 1) % 7))
      .daysOfWeek()
      .next(availability.count);
  } else {
    futureEvents = moment(availability.time_from)
      .recur()
      .every(1)
      .months()
      .next(availability.count);
  }
  return [
    ...events,
    ...futureEvents.map(event => ({
      id: availability.id,
      title: `${availability.availability_type}`,
      allDay: false,
      start: convertToTimezone(
        dateFromTimeAndDay(moment(availability.time_from), moment(event)),
        timezone,
        DATE_FORMATS.DATE_TIME_IOS,
        DATE_FORMATS.FULL_DATE_TIME
      ),
      end: convertToTimezone(
        dateFromTimeAndDay(moment(availability.time_to), moment(event)),
        timezone,
        DATE_FORMATS.DATE_TIME_IOS,
        DATE_FORMATS.FULL_DATE_TIME
      ),
      gameId: availability.gameId,
      gameStatus: availability.game_Status,
      assignedBy: availability.assigned_by,
      eventName: availability.eventName,
      note: availability.note
    }))
  ];
};

const Availability = ({
  userAvailability,
  calendarActiveMonth,
  setCalendarActiveMonth,
  expand,
  timezone,
  handleResize,
  index
}) => {
  React.useLayoutEffect(() => {
    const divElement = document.querySelector('.rbc-calendar');
    const handleResizeLocal = () => {
      const nonFlexibleCalendarSize = 100; // Get the height of the div when it's resized
      const newDivHeight = divElement.offsetHeight + nonFlexibleCalendarSize;
      handleResize(index, newDivHeight);
    };

    if (divElement) {
      window.addEventListener('resize', handleResizeLocal);
      handleResizeLocal(); // Call handleResize initially to set the initial height
    }
  }, [handleResize, index]);

  const sortedEvents =
    userAvailability && userAvailability.length
      ? userAvailability.sort((a, b) =>
          moment(a.time_from).isSameOrBefore(moment(b.time_from)) ? -1 : 1
        )
      : [];
  return (
    <Wrapper>
      <BigCalendar
        events={
          sortedEvents &&
          sortedEvents.reduce(
            (allEvents, event) => [...allEvents, ...parseRecur(event, timezone)],
            []
          )
        }
        view="month"
        views={['month']}
        components={{
          toolbar: props => <Toolbar {...props} />,
          month: { header: Header },
          event: events => <CustomEvent events={events} expand={expand} />
        }}
        defaultDate={calendarActiveMonth}
        formats={{
          dateFormat: 'D',
          timeGutterFormat: 'h A',
          dayRangeHeaderFormat: (range, culture, localizer) =>
            formatDateRange(range, culture, localizer),
          eventTimeRangeFormat: ({ start, end }) => {
            if (moment(end).diff(moment(start), 'minutes') <= 30) return null;
            return `${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`;
          },
          eventTimeRangeEndFormat: ({ start, end }) => {
            if (moment(end).diff(moment(start), 'minutes') <= 30) return null;
            return `${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`;
          }
        }}
        min={minTime}
        max={maxTime}
        selectable="ignoreEvents"
        tooltipAccessor={null}
        popup
        onNavigate={props => {
          setCalendarActiveMonth(props);
        }}
      />
    </Wrapper>
  );
};

Availability.propTypes = {
  userAvailability: PropTypes.arrayOf(Object)
};

Availability.defaultProps = {
  userAvailability: []
};

export default Enhancer(Availability);
