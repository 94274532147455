import React from 'react';
import PropTypes from 'prop-types';
import { defaultTo } from 'ramda';

import buildConfig from './config';
import SideList from '../SideList';
// import CarretButton from '../CarretButton';
import generateUserExpanded from '../SideList/Generic/Expanded';
import {
  PrimaryContainer,
  Summary,
  InfoRow,
  Name,
  Detail,
  PrimaryLabel,
  PrimaryValue
} from './styled-components';

import ExpandedEnhancer from '../../containers/Lists/TeamsList/expanded';
import Enhancer from '../../containers/Lists/TeamsList';

const defaultToEmpty = defaultTo('');

const formatExpandedInfo = team => [
  {
    title: 'DETAILS',
    columns: [
      [
        {
          label: 'Contact Name',
          info: defaultToEmpty(team.contact_name)
        },
        {
          label: 'Phone',
          info: defaultToEmpty(team.contact_phone)
        },
        {
          label: 'Email',
          info: defaultToEmpty(team.contact_email)
        }
      ],
      [
        {
          label: 'Team Club',
          info: defaultToEmpty(team.club)
        },
        {
          label: 'Team League',
          info: defaultToEmpty(team.league)
        }
      ]
    ]
  }
];

const infoSummary = [
  { label: 'LEVEL', name: 'game_lvl' },
  { label: 'TYPE', name: 'game_type' }
];

const Main = ({ item: team, selected, expanded, isFirst, onSelected }) => (
  <PrimaryContainer opened={expanded} isFirst={isFirst} selected={selected} onClick={onSelected}>
    <Summary selected={selected}>
      <InfoRow first>
        <Name selected={selected}>{team.name}</Name>
      </InfoRow>
      <InfoRow last>
        {infoSummary.map(({ label, name }) => (
          <Detail key={label}>
            <PrimaryLabel selected={selected}>{label}</PrimaryLabel>
            <PrimaryValue selected={selected}>{team[name]}</PrimaryValue>
          </Detail>
        ))}
      </InfoRow>
    </Summary>
    {/* <CarretButton opened={expanded} onClick={onExpand} selected={selected} width={2} /> */}
  </PrimaryContainer>
);

Main.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  selected: PropTypes.bool,
  expanded: PropTypes.bool,
  isFirst: PropTypes.bool
};

Main.defaultProps = {
  selected: false,
  expanded: false,
  isFirst: false
};

export const TeamsList = props => (
  <SideList
    onLoad={props.onLoad}
    onFetchItemDetail={() => true}
    items={props.teams}
    type="team"
    selectedProp="id"
    mainComponent={Main}
    expandedComponent={generateUserExpanded({ formatExpandedInfo, Enhancer: ExpandedEnhancer })}
    config={buildConfig(props)}
    eventId={props.match.params.eventId}
    {...props}
  />
);

TeamsList.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onFetchItemDetail: PropTypes.func,
  teams: PropTypes.arrayOf(Object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  }).isRequired
};

TeamsList.defaultProps = {
  onFetchItemDetail: () => true
};

export default Enhancer(TeamsList);
