import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

import { Elements } from '@stripe/react-stripe-js';
import Enhancer from '../../../../containers/Profile';
import { WhiteContentContainer } from '../../styled-components';
import { LoaderContainer } from './styled-components';

import DirectDeposit from './DirectDeposit';
import stripe from '../../../../../utils/stripe';
import { elevatedWritePermissionCheck } from '../../../../../utils/helpers';

const Payer = ({
  isFetching,
  paramId: userId,
  paymentMethods,
  addPaymentSource,
  deletePaymentSource,
  addNotifications,
  currentUserId,
  fetchActiveUserInfo,
  pathUrl,
  isFetchingPayee,
  paymentProfileAccessPermissions
}) => {
  const [dialogDisplay, setDialogDisplay] = React.useState('');
  useEffect(
    () => {
      fetchActiveUserInfo({ userId, pathUrl });
    },
    // eslint-disable-next-line
    []
  );
  return (
    <WhiteContentContainer height="60vh">
      {isFetching || isFetchingPayee ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <>
          <Elements stripe={stripe.getStripe()}>
            <DirectDeposit
              userId={userId}
              paymentMethods={paymentMethods}
              addPaymentSource={addPaymentSource}
              isFetching={isFetching}
              deletePaymentSource={deletePaymentSource}
              addNotifications={addNotifications}
              currentUserId={currentUserId}
              dialogDisplay={dialogDisplay}
              setDialogDisplay={setDialogDisplay}
              writeAccess={elevatedWritePermissionCheck(
                userId,
                currentUserId,
                paymentProfileAccessPermissions
              )}
            />
          </Elements>
        </>
      )}
    </WhiteContentContainer>
  );
};

export default Enhancer(Payer);
