import React from 'react';
import PropTypes from 'prop-types';
import RouteWithSubRoutes from '../RouteWithSubRoutes';

const Auth = ({ routes }) => (
  <>
    {routes.map(({ path, ...route }) => (
      <RouteWithSubRoutes key={path} path={path} {...route} />
    ))}
  </>
);

Auth.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Auth;
