import { call, put, takeLatest, select } from 'redux-saga/effects';
import { compose } from 'ramda';
import {
  FETCH_EVENT_LOCATIONS,
  FETCH_EVENT_COMPLEXS,
  setEventLocations,
  setEventComplexs,
  setIsFetching,
  setCurrentIsFetching,
  setGameLocation,
  FETCH_GAME_LOCATION,
  setTooltipTarget
} from './index';

import { getLocationLimit } from './selectors';
import Api from '../../utils/api-wrapper';
import { errorProgressDecorate } from '../Progress/helper';
import { dataWithPagination, mergeData } from '../../utils/parsers';

export function* processfetchEventLocations({
  payload: { eventId, sort, page, filters, toCSV = false, resolve = () => {} }
}) {
  const limit = yield select(getLocationLimit);

  const response = yield call(Api.fetchEventLocations, {
    id: eventId,
    filters,
    sortingValue: sort,
    page,
    limit,
    toCSV
  });

  if (!toCSV) {
    yield compose(
      put,
      setEventLocations,
      values => ({
        ...values,
        meta: {
          ...values.meta,
          limit
        },
        eventId
      }),
      dataWithPagination
    )(response);
  } else yield resolve(response);
}

export function* processfetchEventComplexs({ payload: { eventId } }) {
  const response = yield call(Api.fetchEventComplexs, {
    id: eventId
  });
  yield compose(
    put,
    setEventComplexs,
    values => ({
      ...values,
      meta: {
        ...values.meta
      }
    }),
    dataWithPagination
  )(response);
}

export function* fetchEventLocations(params) {
  yield errorProgressDecorate(processfetchEventLocations, params, setIsFetching);
}

export function* fetchEventComplexs(params) {
  yield errorProgressDecorate(processfetchEventComplexs, params, setIsFetching);
}

export function* processFetchGameLocation({ payload: { gameId } }) {
  yield put(setTooltipTarget(gameId));
  const response = yield call(Api.fetchGameLocation, {
    id: gameId
  });
  yield put(setGameLocation(mergeData(response)));
}

export function* fetchGameLocation(params) {
  yield errorProgressDecorate(processFetchGameLocation, params, setCurrentIsFetching);
}

export const locationsSagas = [
  takeLatest(FETCH_EVENT_LOCATIONS, fetchEventLocations),
  takeLatest(FETCH_EVENT_COMPLEXS, fetchEventComplexs),
  takeLatest(FETCH_GAME_LOCATION, fetchGameLocation)
];

export default locationsSagas;
