import { compose, pure, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { unsetShowModalFor as unsetShowModalForAction } from '../../../core/Modals';

const mapDispatchToProps = () => ({
  unsetShowModalFor: unsetShowModalForAction
});

export default compose(
  withState('showBannerForGamesUnscheduled', 'setShowBannerForGamesUnscheduled', true),
  connect(null, mapDispatchToProps),
  withHandlers({
    bannerClose: ({ deleteNotification }) => async notificationsIds => {
      deleteNotification({ id: notificationsIds });
    }
  }),
  lifecycle({
    componentWillUnmount() {
      const { unsetShowModalFor } = this.props;
      unsetShowModalFor({});
    }
  }),
  pure
);
