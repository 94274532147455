import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { CheckCircle, Error } from '@material-ui/icons';

import LoaderWrapper from '../../LoaderWrapper';
import MaterialInput from '../../MaterialInput';
import COLORS from '../../../../utils/styles';
import TitleBar from '../../TitleBar';
import Button from '../../Button';
import Line from '../../Line';

import {
  FormContainerElement,
  UssfId,
  Title,
  Note,
  Sport,
  IconWrapper,
  LicenseStatus,
  LicenseWrapper,
  LicenseHeader,
  LicenseBody,
  LicenseTile,
  LicenseRow,
  ExpiredLicense,
  NoLicenseText,
  LicenseContent,
  UssfIdHeading,
  Wrapper,
  UssfWrapper,
  ColumnWrapper,
  ExternalLink
} from './styled-components';
import { LICENSE_STATUSES, URLS } from '../../../../constants';
import { parseDateOnly } from '../../../../utils/parsers';
import config from './config';
import { required } from '../../../../utils/validations';
import { filterEmptyRoles, formatUssfId, writePermissionCheck } from '../../../../utils/helpers';

const isDisabled = values =>
  !!required(values.ussf_id) ||
  !!required(values.ussf_first_name) ||
  !!required(values.ussf_last_name);

export const CustomButton = ({ display, ...buttonProps }) => (
  <Wrapper>
    <ColumnWrapper>
      <Button
        width="200px"
        margin="7px 30px"
        {...buttonProps}
        primary
        noShadow
        customStyle={{
          display,
          background: COLORS.skyBlue,
          fontWeight: 'bold',
          fontSize: '14px',
          fontFamily: 'Roboto',
          height: '45px'
        }}
      />
    </ColumnWrapper>
  </Wrapper>
);

CustomButton.propTypes = {
  display: PropTypes.string,
  buttonProps: PropTypes.objectOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  )
};

CustomButton.defaultProps = {
  display: 'none',
  buttonProps: {
    disabled: false,
    onClick: () => null
  }
};

export const Status = ({ status, color, icon: Icon }) => (
  <>
    <IconWrapper>
      <Icon style={{ color, fontSize: '1rem' }} />
    </IconWrapper>
    <LicenseStatus color={color}>{status}</LicenseStatus>
  </>
);

Status.propTypes = {
  status: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.func.isRequired
};

Status.defaultProps = {
  color: ''
};

export const License = ({ sport, license }) => (
  <LicenseWrapper>
    <LicenseTile
      customStyle={{
        border: '0.1rem solid',
        'border-color': COLORS.gray,
        'box-shadow': 'none',
        'border-radius': '4px'
      }}
      border
    >
      <LicenseHeader>
        <Sport>{sport}</Sport>
        <Status
          status={license.status}
          color={license.status === LICENSE_STATUSES.ACTIVE ? COLORS.forestGreen : COLORS.errorRed}
          icon={license.status === LICENSE_STATUSES.ACTIVE ? CheckCircle : Error}
        />
      </LicenseHeader>
      <Line />
      {config.license.map(
        ({ label, name }, key) =>
          license[name] && (
            <LicenseBody key={`certifications_${key}`}>
              <LicenseContent color={COLORS.naturalGray}>{label}</LicenseContent>
              <LicenseContent>{license[name] || 'NA'}</LicenseContent>
            </LicenseBody>
          )
      )}
    </LicenseTile>
  </LicenseWrapper>
);

License.propTypes = {
  sport: PropTypes.string,
  license: PropTypes.objectOf(
    PropTypes.shape({
      provider: PropTypes.string,
      level: PropTypes.string,
      valid_through: PropTypes.instanceOf(Date),
      issuer: PropTypes.string
    })
  )
};

License.defaultProps = {
  sport: 'soccer',
  license: {}
};

export const Certifications = ({ role, licenses }) => (
  <>
    <TitleBar
      bgColor="#fff"
      height="1rem"
      customStyle={{
        'font-size': '1.4rem',
        'font-family': 'BarlowMedium',
        backgroundColor: '#fff'
      }}
    >{`${role} Certifications`}</TitleBar>
    <LicenseRow>
      {licenses.length > 0 ? (
        <>
          {licenses.map((license, key) => (
            <License license={license} key={key} />
          ))}
        </>
      ) : (
        <>
          <ExpiredLicense>
            <NoLicenseText>The USSF Learning Center only provides active</NoLicenseText>
            <NoLicenseText>licenses. To renew your license contact</NoLicenseText>
            <NoLicenseText>your state referee committee or go to</NoLicenseText>
            <NoLicenseText onClick={() => window.open(URLS.USSF_LC, 'lcTab')}>
              <ExternalLink>{URLS.USSF_LC}</ExternalLink>
            </NoLicenseText>
          </ExpiredLicense>
        </>
      )}
    </LicenseRow>
  </>
);

Certifications.propTypes = {
  role: PropTypes.string.isRequired,
  licenses: PropTypes.array
};

Certifications.defaultProps = {
  licenses: []
};

export const UssfInput = ({
  isValid,
  values,
  isSuperAdmin,
  onClear,
  writePermission,
  ...ussfData
}) => (
  <>
    {!isValid && (
      <>
        <Title>Add certifications to your account</Title>
        <Note>
          Enter your First and Last names EXACTLY as shown on your U.S. Soccer Learning Center
          profile. Then enter your U.S. Soccer Federation ID and we will do the rest.
        </Note>
      </>
    )}
    {config.input.map(({ field, NextComponent, isFirstRow }) => (
      <React.Fragment key={field.name}>
        <UssfWrapper paddingtop={!isFirstRow}>
          <ColumnWrapper>
            <Wrapper>
              <UssfIdHeading>{field.label}</UssfIdHeading>
            </Wrapper>
            <Wrapper>
              {!isValid ? (
                <Field
                  component={MaterialInput}
                  id={field.name}
                  style={{ background: 'transparent', color: '#AEB0B3' }}
                  marginTop="0px"
                  placeholder={
                    !!field.placeholder && field.placeholder + (field.isRequired(values) ? '*' : '')
                  }
                  {...field}
                />
              ) : (
                <UssfId>{ussfData[field.name]}</UssfId>
              )}
            </Wrapper>
          </ColumnWrapper>
          {NextComponent && (
            <ColumnWrapper>
              <NextComponent
                disabled={isDisabled(values) || !writePermission}
                isValid={isValid}
                ussfId={ussfData.ussf_id}
                isSuperAdmin={isSuperAdmin}
                onClear={onClear}
                writePermission={writePermission}
              />
            </ColumnWrapper>
          )}
        </UssfWrapper>
      </React.Fragment>
    ))}
  </>
);

UssfInput.propTypes = {
  isValid: PropTypes.bool,
  writePermission: PropTypes.bool,
  values: PropTypes.objectOf(
    PropTypes.shape({
      ussf_id: PropTypes.string
    })
  ),
  ussfData: PropTypes.objectOf(
    PropTypes.shape({
      ussf_id: PropTypes.string,
      ussf_first_name: PropTypes.string,
      ussf_last_name: PropTypes.string,
      ussf_dob: PropTypes.instanceOf(Date)
    })
  ),
  isSuperAdmin: PropTypes.bool,
  onClear: PropTypes.func.isRequired
};

UssfInput.defaultProps = {
  isValid: false,
  writePermission: false,
  values: {
    ussf_id: ''
  },
  ussfData: {
    ussf_id: '',
    ussf_first_name: '',
    ussf_last_name: '',
    ussf_dob: null
  },
  isSuperAdmin: false
};

const CertificationPage = ({
  isFetching,
  ussf: { id: ussf_id, isValid, first_name: ussf_first_name, last_name: ussf_last_name },
  onSubmit,
  dob,
  certs: certificates,
  isSuperAdmin,
  onClear,
  allowAdminUpdates,
  certificationAccessPermissions,
  currentUserId,
  userId,
  ...formProps
}) => {
  const writePermission = writePermissionCheck(
    userId,
    currentUserId,
    isSuperAdmin,
    certificationAccessPermissions,
    allowAdminUpdates
  );

  const filteredCertificates = filterEmptyRoles(certificates);

  return (
    <LoaderWrapper isFetching={isFetching}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ussf_id,
          ussf_first_name,
          ussf_last_name,
          ussf_dob: parseDateOnly(dob)
        }}
        {...formProps}
        render={({ handleSubmit, values }) => (
          <FormContainerElement onSubmit={handleSubmit}>
            <UssfInput
              isValid={isValid}
              ussf_id={formatUssfId(ussf_id)}
              values={values}
              ussf_first_name={ussf_first_name}
              ussf_last_name={ussf_last_name}
              ussf_dob={values.ussf_dob}
              isSuperAdmin={isSuperAdmin}
              onClear={onClear}
              writePermission={writePermission}
            />

            {ussf_id && isValid && (
              <div>
                {Object.keys(filteredCertificates).map((role, key) => {
                  return <Certifications key={key} role={role} licenses={certificates[role]} />;
                })}
              </div>
            )}
          </FormContainerElement>
        )}
      />
    </LoaderWrapper>
  );
};

CertificationPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  ussf: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      isValid: PropTypes.bool,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  ).isRequired,
  onSubmit: PropTypes.func,
  dob: PropTypes.string.isRequired,
  certs: PropTypes.object,
  formProps: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  onClear: PropTypes.func.isRequired
};

CertificationPage.defaultProps = {
  onSubmit: () => null,
  certs: {
    official: [],
    assessor: [],
    assignor: []
  },
  formProps: {},
  isSuperAdmin: false
};

export default CertificationPage;
