/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Banner from '..';
import { H2 } from '../../Calendar/styled-components';
import { URLS } from '../../../../constants';

export function payoutBanner({
  user,
  editPayeeAccountAction,
  bannerHeading,
  bannerBody,
  showBanner
}) {
  return (
    <>
      {' '}
      {
        <Banner showBanner={showBanner} hasClose={false}>
          <span style={{ paddingLeft: '20px' }}>
            <H2>{bannerHeading}</H2>
            <p style={{ color: '#3D4956' }}>{bannerBody}</p>
            {/* no href because it interferes with onclick event */}
            <a
              className="paymentInfo"
              onClick={() =>
                editPayeeAccountAction({
                  id: user.payee.id,
                  userId: user.id
                })
              }
            >
              Update Payment Info
            </a>
            <a href={URLS.HELP_DESK_PAYMENTS} target="blank" className="paymentInfo">
              Help
            </a>
          </span>
        </Banner>
      }
    </>
  );
}

const PayoutFailedBanner = ({ user, editPayeeAccountAction }) => {
  const bannerHeading = 'Your recent payment failed!';
  const bannerBody = 'To continue receiving payouts, update your payment information';
  const hasFailedPayout = user && user.latest_payout_failed;
  return payoutBanner({
    user,
    editPayeeAccountAction,
    bannerHeading,
    bannerBody,
    showBanner: hasFailedPayout
  });
};

export default PayoutFailedBanner;
