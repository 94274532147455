import React from 'react';
import PropTypes from 'prop-types';
import Circle from 'react-circle';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import { styled } from '@material-ui/core/styles';
import Popover from '../../../global-material-components/Popover';
import Spinner from '../../Spinner';
import {
  BatchTitle,
  BatchNumber,
  TileTextWrapper,
  TileTextSpan,
  TileText,
  BatchTotal,
  BarDiv,
  Overlay,
  SpinnerMessage,
  CashPayment,
  FundingActionItem
} from './styled-components';
import { Grid, Tile, TitleRow, ViewButton, Flex } from '../MonthTiles/styled-components';
import { StyledButton, StyledIcon } from '../SummaryBar/styled-components';
import { numToCurrency } from '../../../../utils/parsers';
import { COLORS, ICONS } from '../../../../utils/styles';
import {
  PAYMENT_UI_STATUSES,
  PAYMENT_STATUSES,
  LOCATIONS,
  allEventsId
} from '../../../../constants';
import DownloadBatchListDialog from './DownloadBatchListDialog';
import DropDownList from './DropDownList';

const DownloadBatchList = styled(Button)(() => ({
  color: '#fff',
  backgroundColor: '#1976d2',
  '&:hover': {
    backgroundColor: '#0d194f'
  }
}));

const FundingBatchList = ({
  handlePaymentSource,
  isProcessingPayment,
  eventBatches,
  setFundDialogOpen,
  setBatch,
  fetchFundSummaryDetails,
  fetchBatchFailedHistory,
  fetchAgreement,
  fetchPaymentToken,
  setCurrentPaymentSourceMeta,
  setValues,
  failedSummaryOpen,
  setFailedSummaryOpen,
  viewBatch,
  fetchPaymentMethods,
  payer,
  downloadFinancialReportSummary,
  paymentsEvent,
  setDialogDisplay
}) => {
  const [downloadModalOpen, setDownloadModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadModal = () => {
    setDownloadModalOpen(true);
    setAnchorEl(null);
  };
  return (
    <div>
      {downloadModalOpen && (
        <DownloadBatchListDialog
          setDownloadModalOpen={setDownloadModalOpen}
          downloadFinancialReportSummary={downloadFinancialReportSummary}
        />
      )}
      <Overlay show={isProcessingPayment}>
        <Spinner
          message={
            <SpinnerMessage>Please do not refresh the page or press back button</SpinnerMessage>
          }
        />
      </Overlay>
      <Flex margin="40px 115px" justification="space-between">
        <BatchTitle>Batch List</BatchTitle>
        <FundingActionItem>
          <DownloadBatchList
            id="download-batch-list"
            aria-controls="download-batch-list"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : null}
            variant="contained"
            disableElevation
            onClick={handleClick}
            disabled={paymentsEvent.id === allEventsId}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Download
          </DownloadBatchList>
          <DropDownList
            id="download-batch-list"
            MenuListProps={{
              'aria-labelledby': 'download-batch-list'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDownloadModal} disableRipple>
              Batch summary
            </MenuItem>
          </DropDownList>
        </FundingActionItem>
      </Flex>
      <Flex>
        <Grid gutter="50px" margin="0px 40px 80px 40px">
          {eventBatches.map(batchSummary => {
            const {
              id,
              submitted_by: { first_name, last_name },
              funding_admin,
              batch_payment,
              transaction = '---',
              pay_batched,
              description,
              has_failed_payment_attempts,
              created_at,
              payment_profile_enabled,
              event: { name },
              event_id: eventId
            } = batchSummary;
            const batchPayStatus = batch_payment && batch_payment.status;
            const processed =
              batchPayStatus === PAYMENT_STATUSES.SUCCEEDED
                ? pay_batched
                : PAYMENT_STATUSES.CREATED;
            const completed = processed / pay_batched || 0;
            const pending = pay_batched - processed;
            return (
              <Tile padding="0px 15px" noPointer key={id}>
                <TitleRow padding="0">
                  <BatchNumber margin="10px 0px">Batch #{id}</BatchNumber>
                  <ViewButton onClick={() => viewBatch(id, eventId)}>Edit</ViewButton>
                </TitleRow>
                <TileTextWrapper margin="0 0 1rem">
                  <TileText>{description}</TileText>
                </TileTextWrapper>
                <TileTextWrapper>
                  Event Name: <TileText>{name}</TileText>
                </TileTextWrapper>
                <TileTextWrapper>
                  Creator: <TileText>{`${first_name} ${last_name}`}</TileText>
                </TileTextWrapper>

                <TileTextWrapper>
                  Funding Admin:
                  <TileText>
                    {' '}
                    {funding_admin
                      ? `${funding_admin.first_name} ${funding_admin.last_name}`
                      : 'All Event Administrators'}
                  </TileText>
                </TileTextWrapper>

                <TileTextWrapper>
                  Status: <TileText>{PAYMENT_UI_STATUSES[batchPayStatus] || 'In Review'}</TileText>
                </TileTextWrapper>
                <TileTextWrapper>
                  Created On:{' '}
                  <TileText>{moment.utc(created_at).local().format('YYYY-MM-DD')}</TileText>
                </TileTextWrapper>
                <TileTextWrapper>
                  Transaction: <TileText>{transaction}</TileText>
                </TileTextWrapper>
                {batchPayStatus === PAYMENT_STATUSES.SUCCEEDED ||
                batchPayStatus === PAYMENT_STATUSES.PENDING ? (
                  <>
                    <TileTextWrapper>
                      Batch Amount: <TileText> {numToCurrency(pay_batched)}</TileText>
                    </TileTextWrapper>
                    <TileTextWrapper>
                      Fee: <TileText> {numToCurrency(batch_payment.application_fee)}</TileText>
                    </TileTextWrapper>
                  </>
                ) : (
                  <p style={{ margin: '2.9em 0' }} />
                )}

                <BatchTotal margin="1rem 0 0 0">
                  {batchPayStatus === PAYMENT_STATUSES.SUCCEEDED ||
                  batchPayStatus === PAYMENT_STATUSES.PENDING
                    ? numToCurrency(pay_batched + batch_payment.application_fee)
                    : numToCurrency(pay_batched)}{' '}
                </BatchTotal>

                <Grid columns={2} margin="30px 0px 30px">
                  <Flex justification="flex-start" alignment="center">
                    <Circle
                      progress={completed * 100}
                      bgColor={
                        batchPayStatus === PAYMENT_STATUSES.FAILED
                          ? COLORS.red
                          : COLORS.lightCyanGray
                      }
                      showPercentage
                      lineWidth="30"
                      progressColor={
                        batchPayStatus === PAYMENT_STATUSES.FAILED ? COLORS.red : COLORS.forestGreen
                      }
                      textColor={completed ? COLORS.forestGreen : 'rgb(107, 119, 140)'}
                      textStyle={{ font: 'bold 6rem Helvetica, Arial, sans-serif' }}
                    />
                  </Flex>
                  <Flex justification="flex-start" alignment="space-between" direction="column">
                    <BarDiv
                      green={batchPayStatus !== PAYMENT_STATUSES.FAILED}
                      margin="0px 0px 10px"
                    >
                      <BatchTotal size="18px">
                        {batchPayStatus === PAYMENT_STATUSES.SUCCEEDED
                          ? numToCurrency(processed + batch_payment.application_fee)
                          : numToCurrency(processed)}
                      </BatchTotal>
                      <TileTextWrapper size="13px">Processed</TileTextWrapper>
                    </BarDiv>
                    <BarDiv margin="10px 0px 0px" red={batchPayStatus === PAYMENT_STATUSES.FAILED}>
                      <BatchTotal size="18px">
                        {batchPayStatus === PAYMENT_STATUSES.PENDING
                          ? numToCurrency(pending + batch_payment.application_fee)
                          : numToCurrency(pending)}
                      </BatchTotal>

                      {has_failed_payment_attempts ? (
                        <>
                          <Popover
                            messageTitle="Batch Failed History"
                            messageBody="See details for failed payment attempts"
                            transformOriginHorizontal="center"
                            color={COLORS.denimBlue}
                          >
                            {' '}
                            <TileTextSpan
                              color={batchPayStatus === PAYMENT_STATUSES.FAILED && COLORS.red}
                              size="13px"
                              onClick={() => {
                                setFailedSummaryOpen(!failedSummaryOpen);
                                setBatch(id);
                                fetchFundSummaryDetails({ batchId: id });
                                fetchBatchFailedHistory({ batchId: id });
                              }}
                            >
                              {batchPayStatus === PAYMENT_STATUSES.FAILED ? 'Failed' : 'Pending'}
                              <StyledIcon
                                icon={ICONS.QUESTION_MARK}
                                width={12}
                                height={12}
                                color={COLORS.red}
                              />
                            </TileTextSpan>
                          </Popover>
                        </>
                      ) : (
                        <TileTextSpan size="13px">Pending</TileTextSpan>
                      )}
                    </BarDiv>
                  </Flex>
                </Grid>
                {payment_profile_enabled ? (
                  <StyledButton
                    disabled={
                      batchPayStatus === PAYMENT_STATUSES.SUCCEEDED ||
                      batchPayStatus === PAYMENT_STATUSES.PENDING ||
                      batchPayStatus === PAYMENT_STATUSES.INCOMPLETE
                    }
                    filled
                    width="100%"
                    margin="0px 0px 15px"
                    onClick={() => {
                      const addPaymentMethodId = document.getElementById('addPaymentMethodDialog');
                      if (addPaymentMethodId) {
                        setDialogDisplay('block');
                      }
                      setFundDialogOpen(true);
                      setBatch(id);
                      fetchFundSummaryDetails({ batchId: id });
                      fetchPaymentToken({ batchId: id });
                      fetchAgreement({ locId: LOCATIONS.FUND_BATCH_DIALOG });
                      handlePaymentSource(null);
                      setCurrentPaymentSourceMeta({
                        name: null,
                        address: {
                          line1: null,
                          line2: null,
                          city: null,
                          state: null,
                          zipcode: null,
                          country: 'US'
                        }
                      });
                      setValues({
                        type: 'bank_account',
                        description: '',
                        accountName: '',
                        bankAccountNumber: '',
                        confirmBankAccountNumber: '',
                        routingNumber: '',
                        confirmRoutingNumber: '',
                        accountType: '',
                        cardComplete: null,
                        batchTotalError: '',
                        nickName: ''
                      });
                      if (payer) {
                        fetchPaymentMethods();
                      }
                    }}
                  >
                    Fund Now
                  </StyledButton>
                ) : (
                  <CashPayment>Cash Payment</CashPayment>
                )}
              </Tile>
            );
          })}
        </Grid>
      </Flex>
    </div>
  );
};

FundingBatchList.propTypes = {
  isProcessingPayment: PropTypes.bool.isRequired,
  eventBatches: PropTypes.arrayOf(Object).isRequired
};

export default FundingBatchList;
