import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Label, Value } from './styled-components';

/**
 * Built to integrate with React-Final-Form, simple input
 * @param {String} value *optional* field value, defaults to empty string
 * @param {String} label field name to display
 * @param {Object} theme *optional* for customizing styles
 */
const InfoField = ({ value, label, theme }) => (
  <Wrapper theme={theme.wrapper}>
    <Label theme={theme.label}>{label}</Label>
    <Value empty={!value} theme={theme.value}>
      {value || ''}
    </Value>
  </Wrapper>
);

InfoField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.shape({
    wrapper: PropTypes.string
  })
};

InfoField.defaultProps = {
  theme: {},
  value: null
};

export default InfoField;
