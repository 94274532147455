import styled from 'styled-components';

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = ColumnWrapper.extend`
  padding: 1rem 3rem 1rem 3rem;
`;

export const SimplePadding = styled.div`
  padding-top: 2rem;
`;

export const ButtonContainer = styled.div`
  width: 15%;
  align-self: 'flex-end';
`;
