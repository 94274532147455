import { compose, withState, withHandlers } from 'recompose';
import { NOTIFICATION_STATUS } from '../../../../../constants';

export default compose(
  withState('expandedId', 'setExpandedId', null),
  withHandlers({
    onExpand: ({ fetchNotification, setExpandedId, clearNotification }) => expandedId => {
      clearNotification();
      setExpandedId(expandedId);
      if (expandedId) fetchNotification(expandedId);
    },
    onGameStatusExpandNotification: ({ updateNotification, closeMenu }) => async ({
      expandedId
    }) => {
      closeMenu();
      updateNotification({ id: expandedId, data: { status: NOTIFICATION_STATUS.action_taken } });
    }
  })
);
