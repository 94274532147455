import { compose } from 'recompose';
import { connect } from 'react-redux';

import { updateProfile } from '../../../core/Auth';
import { fetchActiveUserInfo } from '../../../core/User';
import {
  getUserProfile,
  getUserContactsByTypeId,
  getUserAddressesByTypeId,
  getActiveUser,
  getUser,
  isSuperAdmin
} from '../../../core/Auth/selectors';
import { removeNullProperties } from '../../../utils/helpers';
import unconnected from './unconnected';

const defaultValues = {
  profile: {},
  emergencyInfo: {
    type_id: 1,
    primary_phone_type: 2
  },
  primaryAddress: {
    type_id: 1,
    makeTravelAddress: false
  },
  travelAddress: {
    type_id: 2
  },
  primaryContact: {
    type_id: 3,
    show_phone: true,
    show_email: true,
    primary_phone_type: 2,
    secondary_phone_type: 3
  },
  parentGuardianContact: {
    type_id: 2,
    primary_phone_type: 2
  }
};

const mapStateToProps = (state, props) => ({
  isFetching: state.auth.isFetching,
  userIdx: state.auth && state.auth.userIdx,
  paramId: props && props.match && props.match.params && props.match.params.userId,
  path: props && props.match && props.match.url,
  allowAdminUpdates: getActiveUser(state).allow_admin_updates,
  personalInfoAccessPermissions: getUser(state).cross_edit_permissions.PERSONAL_INFO,
  userId: state.auth && state.user.activeUser && state.user.activeUser.id,
  currentUserId: state.auth && state.auth.user && state.auth.user.id,
  isSuperAdmin: isSuperAdmin(state),
  profile: {
    ...defaultValues.profile,
    ...removeNullProperties(getUserProfile(state))
  },
  emergencyInfo: {
    ...defaultValues.emergencyInfo,
    ...getUserContactsByTypeId(1)(state)
  },
  primaryAddress: {
    ...defaultValues.primaryAddress,
    ...getUserAddressesByTypeId(1)(state)
  },
  travelAddress: {
    ...defaultValues.travelAddress,
    ...getUserAddressesByTypeId(2)(state)
  },
  primaryContact: {
    ...defaultValues.primaryContact,
    ...removeNullProperties(getUserContactsByTypeId(3)(state)),
    primary_email: getUserProfile(state).email
  },
  parentGuardianContact: {
    ...defaultValues.parentGuardianContact,
    ...removeNullProperties(getUserContactsByTypeId(2)(state))
  }
});

const mapDispatchToProps = dispatch => ({
  submitForm: payload => dispatch(updateProfile(payload)),
  fetchActiveUserInfo: payload => dispatch(fetchActiveUserInfo(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
