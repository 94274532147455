import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col } from 'react-flexbox-grid';

import Modal from '../Modal';
import MaterialInput from '../MaterialInput';
import MaterialDropDown from '../MaterialDropDown';
import MaterialTypeahead from '../../controls/MaterialTypeahead';
import Button from '../Button';
import { FieldWrapper, FormWrapper, ButtonsWrapper } from './styled-components';
import { required } from '../../../utils/validations';
import { usaStates, canadaProvinces, countries } from '../../../constants';
import LoaderWrapper from '../LoaderWrapper';

const configuration = (sports, groups, governingBodies) => [
  {
    postion: 'left',
    fields: [
      {
        name: 'name',
        placeholder: 'Event Name*',
        component: MaterialInput,
        validate: required
      },
      {
        name: 'group',
        placeholder: 'Group Name*',
        component: MaterialDropDown,
        options: groups.map(({ id, name }) => ({ name: `${name} - ${id}`, value: +id })),
        validate: required,
        showError: true
      },
      {
        name: 'governing_body',
        placeholder: 'Governing Body*',
        component: MaterialDropDown,
        options: governingBodies.map(({ value, label }) => ({
          name: `${label}`,
          value: `${value}`
        })),
        validate: required,
        showError: true
      }
    ]
  },
  {
    postion: 'right',
    fields: [
      {
        name: 'sport_id',
        placeholder: 'Sport*',
        component: MaterialDropDown,
        options: sports.map(({ name, id }) => ({ name, value: +id })),
        validate: required,
        showError: true
      },
      {
        name: 'area_state',
        placeholder: 'State/Province',
        component: MaterialTypeahead,
        options: [...usaStates, ...canadaProvinces]
      },
      {
        name: 'area_country',
        placeholder: 'Country*',
        component: MaterialTypeahead,
        options: countries,
        validate: required,
        showError: true
      }
    ]
  }
];

const CreateNewEventModal = ({
  onClose,
  onSubmit,
  onSubmitAndRedirect,
  sports,
  governingBodies,
  groups,
  isFetching
}) => (
  <Modal title="Create New Event" onClose={onClose}>
    <LoaderWrapper isFetching={isFetching}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, invalid, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <FormWrapper start="xs">
              {configuration(sports, groups, governingBodies).map(({ postion, fields }) => (
                <Col xs={6} key={postion}>
                  {fields.map(({ name, component, ...input }) => (
                    <FieldWrapper key={name}>
                      <Row middle="xs">
                        <Col xs={12}>
                          <Field name={name} component={component} {...input} />
                        </Col>
                      </Row>
                    </FieldWrapper>
                  ))}
                </Col>
              ))}
            </FormWrapper>
            <ButtonsWrapper center="xs">
              <Col xs={4}>
                <Button
                  type="button"
                  label="Create Event and Update Settings"
                  disabled={pristine || invalid || submitting}
                  onClick={() => {
                    onSubmitAndRedirect(values);
                  }}
                  primary
                />
              </Col>
            </ButtonsWrapper>
          </form>
        )}
      />
    </LoaderWrapper>
  </Modal>
);

CreateNewEventModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  governingBodies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  onSubmitAndRedirect: PropTypes.func.isRequired
};

CreateNewEventModal.defaultProps = {};

export default CreateNewEventModal;
