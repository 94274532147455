import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import Error from '../InputError';
import Label from '../InputLabel';
import { Wrapper, Border, LabelPrimary, RawInput } from './styled-components';

// TODO: get rid of the primary and secondary label
const Input = ({
  type,
  name,
  required,
  label,
  labelType,
  error,
  onChange,
  value,
  active,
  noMargin,
  ...rest
}) => (
  <Wrapper noMargin={noMargin}>
    <Row middle="xs">
      {label && labelType === 'secondary' && (
        <Col xs={3}>
          <Label htmlFor={name}>{label}</Label>
        </Col>
      )}
      <Col xs={labelType === 'primary' ? 12 : 9}>
        <Border isFocus={active} hasLabel={label && labelType === 'primary'}>
          <RawInput
            type={type}
            name={name}
            required={required}
            onChange={onChange}
            value={value}
            {...rest}
          />
          {label && labelType === 'primary' && (
            <LabelPrimary isFilled={value && value.trim().length !== 0} htmlFor={name}>
              {label}
            </LabelPrimary>
          )}
        </Border>
        {error && <Error>{error}</Error>}
      </Col>
    </Row>
  </Wrapper>
);

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelType: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  noMargin: PropTypes.bool,
  noBorder: PropTypes.bool
};

Input.defaultProps = {
  type: 'text',
  active: false,
  required: false,
  label: false,
  labelType: 'primary',
  error: '',
  noMargin: false,
  noBorder: false,
  value: undefined
};

export default Input;
