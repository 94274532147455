import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'ramda';
import GameBasicInfo from './GameBasicInfo';
import PositionCell from './PositionCell';
import { PositionsWrapper, GameWrapper, GamesListContainer } from './styled-components';
import AssignorCell from './AssignorCell';
import { Wrapper as EmptyCell } from './PositionCell/styled-components';
import { calcAssignorFee, isChecked, isIndeterminate } from '../helpers';

const hasAssignor = (assignment_primary_game, assignments_game) => {
  if (
    (assignment_primary_game &&
      assignment_primary_game.batch_pay &&
      assignment_primary_game.batch_pay[0] &&
      assignments_game &&
      assignments_game.length &&
      assignments_game[0].batch_pay &&
      assignment_primary_game.batch_pay[0].batch_pay_id ===
        assignments_game[0].batch_pay.batch_pay_id) ||
    (assignments_game && assignments_game.length && assignments_game[0].batch_pay_staging)
  ) {
    return assignment_primary_game;
  }
  return null;
};

const Game = ({
  display_id,
  id,
  game_level,
  start_time,
  start_date,
  count,
  handleCheckboxClick,
  status,
  crewLabels,
  assignments_game,
  togglePanel,
  selectedAssignment,
  selectedGame,
  setSelectedGame,
  setSelectedAssignment,
  assignment_primary_game,
  allGamesCheck,
  setAllGamesCheck,
  reviewMode,
  requiredPaymentProfile,
  minTransferAmount,
  report_settings,
  timezone,
  field,
  field_name,
  location,
  team_home,
  team_visitor
}) => (
  <GameWrapper>
    <GameBasicInfo
      id={id}
      display_id={display_id}
      game_level={game_level}
      start_time={start_time}
      start_date={start_date}
      count={count}
      checked={reviewMode || isChecked(assignments_game)}
      indeterminate={reviewMode || isIndeterminate(assignments_game)}
      handleCheckboxClick={handleCheckboxClick}
      status={status}
      reviewMode={reviewMode}
      assignor={hasAssignor(assignment_primary_game, assignments_game)}
      timezone={timezone}
      field={field}
      field_name={field_name}
      location={location}
      team_home={team_home}
      team_visitor={team_visitor}
    />

    <PositionsWrapper>
      {crewLabels && crewLabels.labels
        ? range(0, crewLabels.labels.length).map(i =>
            crewLabels.role_ids[i] ? (
              <PositionCell
                key={i}
                assignor={hasAssignor(assignment_primary_game, assignments_game)}
                assignment={assignments_game.find(
                  assignment => i === assignment.official_label_col
                )}
                label={crewLabels.labels[i]}
                togglePanel={togglePanel}
                adjustmentPanelOpenFor={selectedAssignment ? selectedAssignment.id : null}
                basePay={game_level.crew_pay.find(({ crew_label_col }) => i === crew_label_col)}
                setSelectedGame={setSelectedGame}
                assignorPay={game_level.assignor_pay}
                setAllGamesCheck={setAllGamesCheck}
                selectedGame={selectedGame}
                reviewMode={reviewMode}
                requiredPaymentProfile={requiredPaymentProfile}
                minTransferAmount={minTransferAmount}
                report_settings={report_settings}
              />
            ) : (
              <EmptyCell key={i} />
            )
          )
        : range(0, 7).map(i => <EmptyCell key={i} />)}
      <AssignorCell
        assignment={hasAssignor(assignment_primary_game, assignments_game)}
        selectedGame={selectedGame}
        setSelectedGame={setSelectedGame}
        setSelectedAssignment={setSelectedAssignment}
        currentId={display_id}
        uniqueGameId={id}
        gameLevel={game_level}
        allGamesCheck={allGamesCheck}
        setAllGamesCheck={setAllGamesCheck}
        grayOut={
          reviewMode ? false : !isChecked(assignments_game) && !isIndeterminate(assignments_game)
        }
        reviewMode={reviewMode}
        requiredPaymentProfile={requiredPaymentProfile}
        minTransferAmount={minTransferAmount}
        totalAssignorFee={assignments_game
          .map(assignment => {
            const crew_pay =
              game_level &&
              game_level.crew_pay.find(pay => pay.crew_label_col === assignment.official_label_col);

            const travelPay = assignment.batch_pay
              ? assignment.batch_pay.travel_pay
              : assignment.travel_pay
              ? assignment.travel_pay.amount
              : 0;
            return reviewMode
              ? assignment.batch_pay && assignment.batch_pay.assignor_pay
              : calcAssignorFee({
                  adjustment:
                    assignment.adjustments.length &&
                    assignment.adjustments.reduce((result, curr) => result + curr.amount, 0),
                  assignor: assignment_primary_game,
                  assignor_pay: game_level && game_level.assignor_pay,
                  base_pay: crew_pay ? crew_pay.base_pay : 0,
                  travel_pay: travelPay,
                  total: true,
                  inBatch: reviewMode
                    ? true
                    : assignment.batch_pay_staging.in_batch &&
                      assignment.batch_pay_staging.has_payment_profile
                });
          })
          .reduce((result, curr) => result + curr, 0)}
      />
    </PositionsWrapper>
  </GameWrapper>
);
Game.propTypes = {
  display_id: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  game_level: PropTypes.objectOf(Object).isRequired,
  start_time: PropTypes.string.isRequired,
  start_date: PropTypes.string.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  crewLabels: PropTypes.objectOf(Object).isRequired,
  assignments_game: PropTypes.arrayOf(Object),
  togglePanel: PropTypes.func.isRequired,
  selectedAssignment: PropTypes.objectOf(Object),
  selectedGame: PropTypes.objectOf(Object),
  setSelectedGame: PropTypes.func,
  setSelectedAssignment: PropTypes.func,
  assignment_primary_game: PropTypes.objectOf(Object),
  allGamesCheck: PropTypes.bool.isRequired,
  setAllGamesCheck: PropTypes.func.isRequired,
  reviewMode: PropTypes.bool.isRequired
};

Game.defaultProps = {
  assignments_game: { event_role: { user: { first_name: '', last_name: '' } } },
  selectedAssignment: null,
  selectedGame: null,
  setSelectedGame: () => null,
  setSelectedAssignment: () => null,
  assignment_primary_game: null
};
const getCrewLabelByGame = (crewLabels, game) =>
  crewLabels.length && crewLabels.find(x => x.id === (game.game_level && game.game_level.id));

const GamesList = ({
  games,
  handleCheckboxClick,
  pagination,
  crewLabels,
  togglePanel,
  selectedAssignment,
  selectedGame,
  setSelectedGame,
  setSelectedAssignment,
  allGamesCheck,
  setAllGamesCheck,
  reviewMode,
  requiredPaymentProfile,
  minTransferAmount,
  report_settings
}) => (
  <GamesListContainer className="games-list" summaryBar>
    {games.map((game, index) => (
      <Game
        key={game.id}
        {...games[index]}
        handleCheckboxClick={handleCheckboxClick}
        pagination={pagination}
        crewLabels={getCrewLabelByGame(crewLabels, game)}
        togglePanel={togglePanel}
        selectedAssignment={selectedAssignment}
        selectedGame={selectedGame}
        setSelectedGame={setSelectedGame}
        setSelectedAssignment={setSelectedAssignment}
        allGamesCheck={allGamesCheck}
        setAllGamesCheck={setAllGamesCheck}
        reviewMode={reviewMode}
        requiredPaymentProfile={requiredPaymentProfile}
        minTransferAmount={minTransferAmount}
        report_settings={report_settings}
      />
    ))}
  </GamesListContainer>
);

GamesList.propTypes = {
  games: PropTypes.arrayOf(Object).isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  pagination: PropTypes.objectOf(Object).isRequired,
  crewLabels: PropTypes.arrayOf(Object).isRequired,
  togglePanel: PropTypes.func.isRequired,
  selectedAssignment: PropTypes.objectOf(Object),
  selectedGame: PropTypes.objectOf(Object),
  setSelectedGame: PropTypes.func,
  setSelectedAssignment: PropTypes.func,
  allGamesCheck: PropTypes.bool.isRequired,
  setAllGamesCheck: PropTypes.func.isRequired,
  height: PropTypes.number,
  reviewMode: PropTypes.bool.isRequired
};

GamesList.defaultProps = {
  selectedAssignment: null,
  selectedGame: null,
  setSelectedGame: () => null,
  setSelectedAssignment: () => null,
  height: 0
};

export default GamesList;
