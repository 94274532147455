import { START_PROFILE_INFORMATION, START_PROFILE_PAYMENT_METHOD } from '../../../core/paths';

const onBoardingConfig = (
  isProfileCompleted,
  officialAssessorAssignor,
  isPaymentProfileRequired,
  isPaymentSetUpComplete,
  isPaidThroughSO
) => {
  if (!isProfileCompleted) {
    return START_PROFILE_INFORMATION;
  }
  if (isPaidThroughSO && officialAssessorAssignor && isPaymentProfileRequired) {
    if (!isPaymentSetUpComplete) {
      return START_PROFILE_PAYMENT_METHOD;
    }
  }
  return null;
};

export default onBoardingConfig;
