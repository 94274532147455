import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../Flex';
import { Field, Checkbox } from '../styled-components';
import { HEIGHTS } from '../../../../utils/styles';
import ColumnSorter from '../../Reusable/ColumnSorter';
import { REPORT_TYPES } from '../../../../constants';

const sortableFields = ['Home Cautions', 'Home SendOffs', 'Away Cautions', 'Away SendOffs'];
const sortableFieldMapping = {
  'Home Cautions': 'home_caution',
  'Home SendOffs': 'home_send_off',
  'Away Cautions': 'away_caution',
  'Away SendOffs': 'away_send_off'
};
const Subheader = ({
  handleSelectAll,
  checked,
  crewLabels,
  sort: { onSort, value: sortingValue },
  isReportsEventAdmin,
  isReportsEventGroupAdmin,
  fields,
  reportType,
  isReportsEventAssignor
}) => (
  <Flex
    bgImage="linear-gradient(to top, #05579d, #022151)"
    height={HEIGHTS.eventFilterBar}
    padding="0 36px"
    fullWidth
    noShrink
  >
    {(isReportsEventAssignor || isReportsEventAdmin || isReportsEventGroupAdmin) && (
      <Checkbox
        type="checkbox"
        onChange={e => handleSelectAll(e.target.checked)}
        checked={checked}
      />
    )}
    {crewLabels ? (
      <>
        <Field header justifyContent="center">
          Game ID
        </Field>
        <Field header justifyContent="center">
          <ColumnSorter
            label="Game Date"
            onSort={onSort}
            value={sortingValue}
            sortColumnName="game_date"
            justifyContent="space-evenly"
            padding="20px"
          />
        </Field>
        {crewLabels.map(({ label }) => (
          <Field key={label} justifyContent="center" flex={3} header>
            {label}
          </Field>
        ))}
      </>
    ) : (
      <>
        <Field header justifyContent="center">
          Game ID
        </Field>
        <Field key="Game Date" header justifyContent="center">
          <ColumnSorter
            label="Game Date"
            onSort={onSort}
            value={sortingValue}
            sortColumnName="game_date"
            justifyContent="space-evenly"
            padding="20px"
          />
        </Field>

        {reportType === REPORT_TYPES.game &&
          fields &&
          fields.map((field, index) => (
            <>
              {sortableFields.includes(field) ? (
                <Field key={field} header justifyContent="center">
                  <ColumnSorter
                    label={field}
                    onSort={onSort}
                    value={sortingValue}
                    sortColumnName={sortableFieldMapping[field]}
                    justifyContent="space-evenly"
                  />
                </Field>
              ) : (
                <Field key={index} header justifyContent="center">
                  {field}
                </Field>
              )}
            </>
          ))}

        {reportType === REPORT_TYPES.game && (
          <Field header justifyContent="center" padding="0px 10px 0px 0px">
            Submitted by
          </Field>
        )}

        {reportType !== REPORT_TYPES.game &&
          ['Type', 'Reason', 'Reporting On', 'Submitted By', 'Offensive Language'].map(field => (
            <Field key={field} header justifyContent="center">
              {field}
            </Field>
          ))}
        {reportType === REPORT_TYPES.game ? (
          <Field key="Approved" header justifyContent="center">
            <ColumnSorter
              label="Approved"
              onSort={onSort}
              value={sortingValue}
              sortColumnName="approved"
              justifyContent="space-evenly"
            />
          </Field>
        ) : (
          <Field header justifyContent="center">
            Approved
          </Field>
        )}
      </>
    )}
  </Flex>
);

Subheader.propTypes = {
  handleSelectAll: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  crewLabels: PropTypes.arrayOf(Object),
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  isReportsEventAdmin: PropTypes.bool,
  isReportsEventAssignor: PropTypes.bool
};

Subheader.defaultProps = {
  checked: false,
  crewLabels: null,
  isReportsEventAdmin: false,
  isReportsEventAssignor: false
};

export default Subheader;
