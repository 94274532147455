import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const Label = styled.label`
  display: flex;
  margin-top: ${pxToRem('7px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('12px')};
  ${({ color }) => (color ? `color: ${COLORS.white}` : `color: ${COLORS.nightRider}`)};
  ${({ theme }) => theme || ''};
`;

export default Label;
