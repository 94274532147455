import { takeEvery, call, put, select } from 'redux-saga/effects';
import { compose, isEmpty } from 'ramda';
import Api from '../../utils/api-wrapper';
import { FETCH_SPORTS, setSports } from './index';
import { getSports } from './selectors';
import { addNotifications } from '../Notifications';
import { mergeData } from '../../utils/parsers';
import Logger from '../../utils/logger';

export function* fetchSports() {
  try {
    const sports = yield select(getSports);
    if (isEmpty(sports)) {
      const response = yield call(Api.getSports);
      yield put(compose(setSports, mergeData)(response));
    }
  } catch (e) {
    yield put(addNotifications(e.message));
    Logger.error(e);
  }
}

export const sportsSagas = [takeEvery(FETCH_SPORTS, fetchSports)];

export default sportsSagas;
