import React from 'react';
import { Tooltip } from '@material-ui/core';
import { NameDetail } from '../../../styled-components';
import { isTeamChanged } from '../../../../config';

const GameTeam = ({ team_home, team_visitor }) => {
  return (
    <>
      <Tooltip
        title={team_home && team_home.name}
        PopperProps={{
          disablePortal: true
        }}
        arrow
      >
        <NameDetail> {team_home && team_home.name} </NameDetail>
      </Tooltip>
      <span className="teamVs">VS</span>
      <Tooltip
        title={team_visitor && team_visitor.name}
        PopperProps={{
          disablePortal: true
        }}
        arrow
      >
        <NameDetail>{team_visitor && team_visitor.name}</NameDetail>
      </Tooltip>
    </>
  );
};

export default React.memo(GameTeam, isTeamChanged);
