import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Field } from 'react-final-form';
import theme, { StyledLabel, NumberValue } from './styled-components';
import Dropdown from '../../../../Reusable/Dropdown';
import options from '../constants';
import { NumericField } from '../../../../Event/AssignorSettings/NotificationCollapsible';
import { valueInRange } from '../../../../../../utils/validations';
import '../GameReport/styles.css';
import { NullableField } from '../../../../../../utils/hocs/final-form-field-adapter';

const Summary = ({ teamName, scoreOption, eventSportId, label, heading, disabled, values }) => (
  <Col md={6}>
    <Row>
      <Col md={12} lg={12}>
        <div className="summaryTitle">
          {heading}- {teamName}
        </div>
      </Col>
      <Col md={12} lg={12} className="text-left">
        <div className="wholeScoreWrapper">
          <div className="scores">
            {scoreOption === 'Score by Player' ? (
              <NumberValue>
                {values &&
                  values[`${label}_roster`] &&
                  values[`${label}_roster`].reduce(
                    (total, roster) => total + (roster.name ? Number(roster.total_goals) : 0),
                    0
                  )}
              </NumberValue>
            ) : (
              <NumericField
                validate={valueInRange({ min: 0, max: 999 })}
                min={0}
                max={999}
                className="fieldValue"
                name={`${label}_score`}
                defaultValue={0}
                disabled={disabled}
              />
            )}
            <div className="scoreLabel">
              <StyledLabel>Score</StyledLabel>
            </div>
          </div>

          {(eventSportId === 5 || eventSportId === 9) && (
            <>
              <div className="scores scoreCaution">
                {scoreOption === 'Score by Player' ? (
                  <NumberValue>
                    {values &&
                      values[`${label}_roster`] &&
                      values[`${label}_roster`].reduce(
                        (total, roster) => total + (roster.is_caution ? 1 : 0),
                        0
                      )}
                  </NumberValue>
                ) : (
                  <NumericField
                    validate={valueInRange({ min: 0, max: 999 })}
                    min={0}
                    max={999}
                    name={`${label}_cautions`}
                    defaultValue={0}
                    disabled={disabled}
                  />
                )}

                <div className="scoreLabel">
                  <StyledLabel>Cautions</StyledLabel>
                </div>
              </div>
              <div className="scores">
                {scoreOption === 'Score by Player' ? (
                  <NumberValue>
                    {values &&
                      values[`${label}_roster`] &&
                      values[`${label}_roster`].reduce(
                        (total, roster) => total + (roster.is_send_off ? 1 : 0),
                        0
                      )}
                  </NumberValue>
                ) : (
                  <NumericField
                    validate={valueInRange({ min: 0, max: 999 })}
                    min={0}
                    max={999}
                    name={`${label}_send_off`}
                    defaultValue={0}
                    disabled={disabled}
                  />
                )}
                <div className="scoreLabel">
                  <StyledLabel>Send Off</StyledLabel>
                </div>{' '}
              </div>
            </>
          )}
        </div>
      </Col>
    </Row>
    {(eventSportId === 5 || eventSportId === 9) &&
      scoreOption === 'Score by Player' &&
      values &&
      values[`${label}_roster`] &&
      values[`${label}_roster`].reduce((acc, roster, index) => {
        return acc.concat(
          roster.goals
            ? roster.goals.map(
                (goal, i) =>
                  roster.name && (
                    <Row key={`${roster.uid}_${i}`}>
                      <Col md={12} className="margin-top-15">
                        <div className="totalScore">
                          <div className="totalScoreField">
                            <StyledLabel className="totalScoreName">{roster.name}</StyledLabel>
                          </div>
                          <div className="totalScoreField totalFieldModified">
                            <StyledLabel className="totalScoreNumber">{roster.uniform}</StyledLabel>
                          </div>
                          <div className="totalScoreField fieldDropdown totalFieldModified">
                            <Field
                              name={`${label}_roster[${index}].goals[${i}].type`}
                              validate={null}
                              options={options.goalDropdownOptions.map(name => ({
                                name,
                                value: name
                              }))}
                              render={({ input }) => (
                                <Dropdown
                                  label="Goal Options"
                                  theme={theme.dropdownField}
                                  options={options.goalDropdownOptions.map(name => ({
                                    name,
                                    value: name
                                  }))}
                                  input={input}
                                  displayValue
                                  disabled={disabled}
                                />
                              )}
                            />
                          </div>
                          <div className="atMinWrap atMinModified">
                            <div className="atminHead">at minute:</div>
                            <div className="InVal customError">
                              <NullableField
                                name={`${label}_roster[${index}].goals[${i}].minute`}
                                disabled={disabled}
                                validate={valueInRange({ min: 0, max: 999 })}
                                component={NumericField}
                                min={0}
                                max={999}
                                fromGameReport
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
              )
            : []
        );
      }, [])}
  </Col>
);

Summary.propTypes = {
  teamName: PropTypes.string,
  scoreOption: PropTypes.string,
  eventSportId: PropTypes.number,
  label: PropTypes.string
};

Summary.defaultProps = {
  teamName: null,
  scoreOption: '',
  eventSportId: 1,
  label: ''
};

export default Summary;
