import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';

import { PAPER, COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';
import Icon from '../Icon';

export const Container = styled(Row)`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const Label = styled.div`
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('15px')};
  font-weight: 500;
  text-align: center;
  color: ${COLORS.denimBlue};
  text-transform: uppercase;
  border-left: ${pxToRem('1px')} solid #d8dee4;
  line-height: ${pxToRem('48px')};
  padding-left: ${pxToRem('16px')};
  cursor: pointer;
`;

export const Content = styled.div`
  min-width: ${({ theme }) => pxToRem(theme.width)};
  width: max-content;
  position: absolute;
  top: ${({ theme }) => pxToRem(theme.topOffset)};
  right: ${({ theme }) => pxToRem(theme.rightOffset)};
  font-size: ${pxToRem('13px')};
  font-stretch: condensed;
  font-family: 'RobotoCondensed', sans-serif;
  z-index: 9999;
  ${PAPER};
`;

export const OptionList = styled.div``;

export const Option = styled.div`
  min-height: ${pxToRem('32px')};
  display: flex;
  align-items: center;
  padding: 0 ${pxToRem('16px')};
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.hoverBackground};
    color: ${({ theme }) => theme.hoverColor}};
  }
`;

export const OptionLabel = styled.span``;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-top: ${({ theme }) => pxToRem(theme.marginTop)};
  margin-right: ${({ theme }) => pxToRem(theme.marginRight)};
`;
