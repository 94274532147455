import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';
import { COLORS } from '../../../../../utils/styles';
import Icon from '../../../Icon';

export const ContainerPaddingTopBottom = styled(Col)`
  padding: 0.7rem;
`;

export const CenteredContentContainer = styled.div`
  width: 60 %;
  justify-content: 'center';
  display: flex;
`;

export const ContainerPadding = styled.div`
  padding: 0rem 1rem;
`;

export const TabsContainer = styled.div`
width: 100 %;
margin: 0;
padding: 0;
border - bottom: 1px solid ${COLORS.lightGray};
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: center;
  margin-left: 0.9rem;
`;

export const LoaderContainer = styled.div`
  width: 100 %;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmationIcon = styled(Icon)`
  position: relative;
  top: -1px;
  right: -10px;
`;

export const AccountText = styled.span`
font-size: 1rem;
font-family: 'Roboto', 'Helvetica', 'Arial', sans - serif;
font-weight: 400;
line-height: 1.5;
letter-spacing: 0.00938em;
color: ${({ color }) => color || `${COLORS.navyBlue}`}
margin-left: ${({ marginL }) => marginL || 0}
margin-top: ${({ marginT }) => marginT || 0}
display: ${({ display }) => display || null}
`;

export const TextBorder = styled.div`
border - bottom: 1px solid ${COLORS.lightGray};
`;

export const AccountInfoText = styled.div`
margin - bottom: -0.8rem;
color: black;
`;

export const PayoutText = styled.span`
  color: black;
`;

export const RowWrapper = styled(Row)`
margin - right: 10px!important;
margin - left: 10px!important;
`;

export const InnerPageCol = styled(Col)`
  width: 100 %;
`;

export const InnerFormCol = InnerPageCol.extend`
margin - right: 0;
padding: 0px 20px;
`;

export const BannerSpan = styled.span`
  padding-left: 20px;
`;

export const BannerP = styled.p`
  color: ${COLORS.darkCyanBlue};
`;
