import React from 'react';
import { AccountBalanceWalletOutlined } from '@material-ui/icons';
import { formatMoney } from 'accounting-js';
import { Col, Row } from 'react-flexbox-grid';
import CarretButton from '../CarretButton';
import LoaderWrapper from '../LoaderWrapper';
import {
  RowsWrapper,
  TotalBalance,
  BoldSpan,
  VerticalLine,
  SpanBlue,
  AddFundBtn,
  SpanContainer
} from './styled-components';
import Enhancer from '../../containers/Wallet';
import TransactionTable from './TransactionHistory';
import AddWalletFundsModal from '../AddWalletFundsModal';
import ACHModal from '../ACHModal';

const Wallet = ({
  showAddFundsBtn,
  wallet,
  walletTotal,
  isFetching,
  wideView,
  showTransactionHistoryTable,
  handleTransactionHistoryCarretToggle,
  handleFetchTransactionHistory,
  limit,
  defaultAlignment,
  showMoreTransactionHistory,
  setShowTransactionHistoryTable,
  readOnly
}) => {
  const [addFunds, setAddFund] = React.useState(false);
  const [achModal, setAchModal] = React.useState(false);

  const ACHModalCloseHandler = () => {
    setAchModal(false);
    setAddFund(true);
  };
  return (
    <LoaderWrapper isFetching={isFetching}>
      <RowsWrapper wideView={!!wideView} defaultAlignment={!!defaultAlignment}>
        {wideView && (
          <Col xs={1}>
            <CarretButton
              opened={showTransactionHistoryTable}
              onClick={() => {
                if (!showTransactionHistoryTable) {
                  handleFetchTransactionHistory({ limit });
                }
                handleTransactionHistoryCarretToggle();
              }}
              nobg={showTransactionHistoryTable}
            />
          </Col>
        )}
        <Col xs={1}>
          <SpanBlue>
            <AccountBalanceWalletOutlined />
          </SpanBlue>
        </Col>
        <Col xs>
          <Row middle="xs">
            <Col>
              <SpanContainer textCenter="center">
                <TotalBalance>Total Balance</TotalBalance>
                <SpanBlue fontSize="16px">{formatMoney(walletTotal)}</SpanBlue>
              </SpanContainer>
            </Col>
            <Col xs={1}>
              <Row center="xs">
                <VerticalLine />
              </Row>
            </Col>
            <Col>
              <SpanContainer>
                <BoldSpan width="70px">Available:</BoldSpan>
                <BoldSpan>{formatMoney(wallet.available)}</BoldSpan>
              </SpanContainer>
              <SpanContainer>
                <BoldSpan width="70px">Pending:</BoldSpan>
                <BoldSpan>
                  <SpanBlue>{formatMoney(wallet.pending)}</SpanBlue>
                </BoldSpan>
              </SpanContainer>
            </Col>
          </Row>
        </Col>
        {wideView && showAddFundsBtn && (
          <Col xs={2}>
            <Row middle="xs" start="xs">
              <AddFundBtn disabled={readOnly} onClick={() => setAddFund(true)}>
                Add Funds
              </AddFundBtn>
            </Row>
          </Col>
        )}
        {!wideView && (
          <Col xs={1}>
            <Row start="xs">
              <CarretButton
                opened={showTransactionHistoryTable}
                onClick={() => {
                  if (!showTransactionHistoryTable) {
                    handleFetchTransactionHistory({ limit });
                  }
                  handleTransactionHistoryCarretToggle();
                }}
                nobg={showTransactionHistoryTable}
              />
            </Row>
          </Col>
        )}
      </RowsWrapper>
      {showTransactionHistoryTable && (
        <TransactionTable
          handleFetchTransactionHistory={handleFetchTransactionHistory}
          wideView={!!wideView}
          showMoreTransactionHistory={showMoreTransactionHistory}
        />
      )}
      {addFunds && (
        <AddWalletFundsModal
          open={addFunds}
          setAddFund={setAddFund}
          setAchModal={setAchModal}
          setShowTransactionHistoryTable={setShowTransactionHistoryTable}
        />
      )}
      {achModal && <ACHModal open={achModal} closeHandler={ACHModalCloseHandler} />}
    </LoaderWrapper>
  );
};
export default Enhancer(React.memo(Wallet));
