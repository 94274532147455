import moment from 'moment';
import { is } from 'ramda';

import MultipleInput from '../../../../Reusable/MultipleInput';
import MultiSelectDropdown from '../../../../Reusable/MultiSelectDropdown';
import MaterialDatePicker from '../../../../../controls/MaterialDatePicker';
import theme from '../../../../Games/Header/GamesFilter/styled-components';
import {
  composeValidators,
  isSameOrBefore,
  isSameOrAfter
} from '../../../../../../utils/validations';
import { GAMES_STATUSES } from '../../constants';
import { sortListByKey } from '../../../../../../utils/helpers';

const generateFields = ({
  batchRange,
  gamelevels = [],
  categories = [],
  teams = [],
  complexes = []
}) => [
  {
    component: MultiSelectDropdown,
    name: 'field.location.name',
    placeholder: 'Complex Name',
    options: sortListByKey(
      [...new Set(complexes.map(({ name }) => name))].map(name => ({
        value: name,
        name
      })),
      'value'
    ),
    theme: theme.multiSelectDropdown,
    noCount: true
  },
  {
    component: MaterialDatePicker,
    name: 'game.start_date',
    placeholder: 'Start Date',
    parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
    format: value => (value ? moment(value, 'YYYY-MM-DD').toDate() : null),
    theme: theme.materialDatePicker,
    validate: composeValidators(
      val =>
        isSameOrAfter(
          val,
          moment(batchRange.start_date).format('YYYY-MM-DD'),
          'YYYY-MM-DD',
          'Start date precedes beginning of batch'
        ),
      (val, vals) => isSameOrBefore(val, vals['game=>end_date'], 'YYYY-MM-DD')
    ),
    inlineError: true,
    disabledDays: is(Object, batchRange)
      ? {
          before: batchRange.start_date && moment(batchRange.start_date).toDate(),
          after: batchRange.end_date && moment(batchRange.end_date).toDate()
        }
      : undefined
  },
  {
    component: MultiSelectDropdown,
    name: 'team.name',
    placeholder: 'Team Name',
    options: sortListByKey(
      teams.map(team => ({
        value: team.name,
        name: `${team.name} - ${team.game_lvl}`
      })),
      'value'
    ),
    theme: theme.multiSelectDropdown,
    noCount: true
  },
  {
    component: MaterialDatePicker,
    name: 'game.end_date',
    placeholder: 'End Date',
    parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
    format: value => (value ? moment(value, 'YYYY-MM-DD').toDate() : null),
    theme: theme.materialDatePicker,
    validate: val =>
      isSameOrBefore(
        val,
        moment(batchRange.end_date).format('YYYY-MM-DD'),
        'YYYY-MM-DD',
        'End date postdates end of batch'
      ),
    inlineError: true,
    disabledDays: is(Object, batchRange)
      ? {
          before: batchRange.start_date && moment(batchRange.start_date).toDate(),
          after: batchRange.end_date && moment(batchRange.end_date).toDate()
        }
      : undefined
  },
  {
    component: MultiSelectDropdown,
    name: 'game_level.schedule_id',
    placeholder: 'Level & Type',
    options: sortListByKey(gamelevels, 'schedule_name').map(level => ({
      value: level.schedule_id,
      name: `${level.schedule_name}`
    })),
    theme: theme.multiSelectDropdown,
    noCount: true
  },
  {
    component: MultiSelectDropdown,
    name: 'categories_filtered.category_id',
    placeholder: 'Category',
    options: categories.map(category => ({
      value: category.id,
      name: category.name
    })),
    theme: theme.multiSelectDropdown,
    noCount: true
  },
  {
    component: MultiSelectDropdown,
    name: 'game.status',
    placeholder: 'Status',
    options: GAMES_STATUSES.map(status => ({
      value: status.value,
      name: status.name
    })),
    theme: theme.multiSelectDropdown,
    noCount: true,
    setHeight: true
  },
  {
    component: MultipleInput,
    name: 'officialNames',
    placeholder: 'Name'
  },
  {
    component: MultipleInput,
    name: 'game.id',
    placeholder: 'Game ID'
  }
];

export default generateFields;
