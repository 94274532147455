import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from '../DateRangePicker';
import ActiveBatchMessage from '../ActiveBatchMessage';
import { Title, DatePickerWrapper } from '../styled-components';

const BatchCreation = ({ name, submitBatch, batch, deleteActiveBatch, editActiveBatch }) => (
  <>
    <Title>{name}</Title>
    <DatePickerWrapper>
      <DateRangePicker
        onSubmit={submitBatch}
        activeBatch={!!batch.id}
        initialValues={{ start_date: batch.start_date, end_date: batch.end_date }}
        buttonLabel="Create pay batch by date range"
        secondary
      />
      {batch.id && (
        <ActiveBatchMessage
          batch={batch}
          deleteActiveBatch={deleteActiveBatch}
          editActiveBatch={editActiveBatch}
        />
      )}
    </DatePickerWrapper>
  </>
);

BatchCreation.propTypes = {
  name: PropTypes.string.isRequired,
  submitBatch: PropTypes.func.isRequired,
  batch: PropTypes.objectOf(Object).isRequired,
  deleteActiveBatch: PropTypes.func.isRequired,
  editActiveBatch: PropTypes.func.isRequired
};

export default BatchCreation;
