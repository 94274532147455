import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, propEq, update } from 'ramda';

import RoundedCheckBox from '../RoundedCheckBox';
import { Container, RoundCheckboxWrapper, Label } from './styled-components';

const onCheckBoxChange = (checkBoxesData, checkBoxData, checked, onChange) => {
  const index = findIndex(propEq('name', checkBoxData.name), checkBoxesData);
  const newCheckBoxesData = update(index, { ...checkBoxData, checked }, checkBoxesData);

  onChange(newCheckBoxesData);
};

const buildCheckBoxes = (checkBoxesData, onChange) =>
  checkBoxesData.map(checkBoxData => (
    <RoundCheckboxWrapper key={checkBoxData.name}>
      <RoundedCheckBox
        value={checkBoxData.checked}
        name={checkBoxData.name}
        onChange={checked => onCheckBoxChange(checkBoxesData, checkBoxData, checked, onChange)}
      />
      <Label>{checkBoxData.label}</Label>
    </RoundCheckboxWrapper>
  ));

const CheckBoxGroup = ({ config: checkBoxesData, onChange }) => (
  <Container>{buildCheckBoxes(checkBoxesData, onChange)}</Container>
);

CheckBoxGroup.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default CheckBoxGroup;
