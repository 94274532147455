import { assoc, prop, equals, pickBy, identity, flatten, not, concat } from 'ramda';
import { withProps, compose, lifecycle } from 'recompose';
import {
  queryStringToJson,
  groupByPrefix,
  deGroupByPrefix,
  jsonToQueryString,
  notNilNotEmpty,
  sInterpolator as routeBuilder,
  encodeJSONObject
} from '../../../utils/helpers';
import { history } from '../../../core/store';
import { userIdxGetter } from '../../../utils/auth';

// TODO move me to helpers
const cleanObject = pickBy(compose(not, notNilNotEmpty));

/**
 * Takes a query object (location.search) will apply function on the pagination context
 * for example if you want to update a param like a page for events
 * prefix will be the event identifier update function will take all props for that context and returns a new updated context
 * Will remove empty parameters from the string and ignore parameters which are not part of the pagination
 * Returns an updated query string
 * @param {String} queryObject Current Query string
 * @param {String} forPrefix  Prefix identifier
 * @param {Function} updaterFn function will take props for context return new props
 */
export const buildQueryParams = (queryObject, forPrefix, updaterFn, to) => {
  const grouped = groupByPrefix('_', queryObject);
  const updated = assoc(forPrefix, updaterFn(prop(forPrefix, grouped)), grouped);
  const queryString = compose(jsonToQueryString, cleanObject, deGroupByPrefix('_'))(updated);
  return to ? concat(routeBuilder({ userIdx: userIdxGetter() }, to), queryString) : queryString;
};

/**
 * Used to compose PaginationAware
 * if used it will update on page change
 * If extra arguments are needed for onLoadPage,
 * they shoud be available in this.props.onLoadPageArgs
 */
export const updateOnPageChange = forPrefix =>
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const {
        location: { search }
      } = this.props;
      const {
        location: { search: nextSearch },
        pagination
      } = nextProps;

      const currQuery = queryStringToJson(decodeURIComponent(search));
      const nextQuery = queryStringToJson(decodeURIComponent(nextSearch));
      const currQueryObj = prop(forPrefix, groupByPrefix('_', currQuery)) || {};
      const nextQueryObj = prop(forPrefix, groupByPrefix('_', nextQuery)) || {};
      if (currQuery.mn_reload !== undefined) {
        currQueryObj.mn_reload = currQuery.mn_reload;
      }

      if (nextQuery.mn_reload !== undefined) {
        nextQueryObj.mn_reload = nextQuery.mn_reload;
      }

      if (!equals(currQueryObj, nextQueryObj)) {
        pagination.onLoadPage(this.props.onLoadPageArgs);
      }
    }
  });

/**
 * takes an array of filter configurations,
 * and returns filter names
 * @param {Array} config
 */
export function filterConfigToNames(config = []) {
  return flatten(
    config.reduce((nameArr, filter) => {
      if (Array.isArray(filter.name)) {
        return [...nameArr, ...filter.name];
      }
      return [...nameArr, filter.name];
    }, [])
  );
}

/**
 * Takes a configuration. and returns a function. To be used with recompose
 * Will inject 3 objects
 * pagination, sort, and search which are props to be used in each component
 * pagination:{
 * onLoadPage : function that will be injected to call the fetcher with the injected parameters
 * }
 * @param {Object} param.to String contains the base url
 * @param {Object} param.fetcherQuote String containing the name of the function that takes pagination/sorting parameters in
 * @param {Object} param.metaQuote String containing the name of the selector that returns the metadata
 * @param {Object} param.forPrefix String containing a prefix for the params url
 * @param {Object} param.searchFilter Array of filter keys the search will inject as an apiFilter when calling the api
 * @param {Object} param.filterNames Filters that should be taken into account [TODO refactor. This is not even necesary if refactor is done]
 * @param {Object} param.pageFilter Filters that should be used for UI pages only and do not interact with the API
 * @param {Function} additional Optional Function to compose. injecting `updateOnPageChange` will refresh on page change
 */
export function paginationAware(
  {
    to,
    fetcherQuote,
    forPrefix,
    metaQuote,
    searchFilter = [],
    filterNames = [],
    statusFilter = [],
    pageFilter = []
  },
  additional = identity
) {
  return compose(
    withProps(({ location: { search, pathname }, match, ...rest }) => {
      // Search must be encoded when it is passed to this funtion. Function will decode.
      const query = queryStringToJson(search);

      const contextPagination = prop(forPrefix, groupByPrefix('_', query)) || {};

      const { total_count: totalCount, limit } = prop(metaQuote, rest);

      const maxPages = Math.ceil(totalCount / limit);
      if (maxPages && maxPages < contextPagination.page) {
        history.push(buildQueryParams(query, forPrefix, assoc('page', maxPages), to));
      }

      const pageLinkBuilder = page =>
        `${pathname}${buildQueryParams(query, forPrefix, assoc('page', page))}`;

      const fetcherFunc = prop(fetcherQuote, rest);

      const {
        search: ctxSearch,
        status: ctxStatus,
        filterPage: ctxFilterPage,
        ...otherFilters
      } = contextPagination;

      const searchFilters = !notNilNotEmpty(ctxSearch)
        ? searchFilter.reduce((acc, v) => assoc(v, ctxSearch, acc), {})
        : {};

      const filtersFilters = !notNilNotEmpty(otherFilters)
        ? pickBy((_, key) => filterNames.includes(key), otherFilters)
        : {};

      const statusFilters = !notNilNotEmpty(ctxStatus)
        ? statusFilter.reduce((acc, v) => assoc(v, ctxStatus, acc), {})
        : {};

      const pageFilters = !notNilNotEmpty(ctxFilterPage)
        ? pageFilter.reduce((acc, v) => assoc(v, ctxFilterPage, acc), {})
        : {};

      /*
      Injected on the pagination object
      Will call the fetcherQuote passing the pagition/sort parameters plus the additional parametrs provided
      note you can override pagination parameters o the additionalParametrs
      */
      const onLoadPage = (additionalProps = {}) =>
        fetcherFunc({
          ...otherFilters,
          filters: {
            ...searchFilters,
            ...filtersFilters,
            ...statusFilters
          },
          pageFilters: {
            ...pageFilters
          },
          ...additionalProps
        });

      const onSearch = searchValue => {
        history.push(
          buildQueryParams(
            query,
            forPrefix,
            obj => ({ ...obj, search: searchValue, page: null }),
            to
          )
        );
      };

      const onSort = (sortParam, page) => {
        if (page)
          history.push(
            buildQueryParams(
              query,
              forPrefix,
              (obj = {}) => {
                const queryObj = { ...obj, page, sort: sortParam };
                return queryObj;
              },
              to
            )
          );
        else history.push(buildQueryParams(query, forPrefix, assoc('sort', sortParam), to));
      };

      const onFilter = filters => {
        const safeFilters = encodeJSONObject(filters);

        const filterAsQSParameters = buildQueryParams(
          query,
          forPrefix,
          obj => {
            const queryObj = {
              ...safeFilters,
              page: null
            };
            if (obj) {
              if (obj.type) {
                queryObj.type = obj.type;
              }
              if (obj.sort) {
                queryObj.sort = obj.sort;
              }
              if (obj.search) {
                queryObj.search = obj.search;
              }
              if (obj.byPlayer) {
                queryObj.byPlayer = obj.byPlayer;
              }
              if (obj.status) {
                queryObj.status = obj.status;
              }
            }
            return queryObj;
          },
          to
        );
        history.push(filterAsQSParameters);
      };

      const onClearFilters = () => {
        history.push(
          buildQueryParams(
            query,
            forPrefix,
            pickBy((_, key) => !filterNames.includes(key)),
            to
          )
        );
      };

      const onStatusChange = status => {
        history.push(
          buildQueryParams(query, forPrefix, obj => ({ ...obj, status, page: null }), to)
        );
      };

      const onPageFilterChange = (filterPage, byPlayer) => {
        let pageParams = {};
        if (byPlayer) {
          pageParams = { type: filterPage, page: null, byPlayer };
        } else {
          pageParams = { type: filterPage, page: null };
        }
        history.push(buildQueryParams(query, forPrefix, () => pageParams, to));
      };

      /**
       * Takes an array of prefixes and clears out all url params for each prefix
       * Defaults to just clearing the associated prefix if no array if provided
       * @param {Array} prefixes array of prefix strings to clear associated data
       */
      const clearParams = (prefixes = [forPrefix]) => {
        const grouped = groupByPrefix('_', query);
        prefixes.forEach(prefix => {
          grouped[prefix] = {};
        });
        const queryString = compose(jsonToQueryString, cleanObject, deGroupByPrefix('_'))(grouped);
        const path = to ? concat(to, queryString) : queryString;
        history.push(path);
      };

      return {
        pagination: {
          totalCount,
          limit,
          pageLinkBuilder,
          ...contextPagination,
          onLoadPage
        },
        search: {
          onSearch,
          value: contextPagination.search || null
        },
        sort: {
          onSort,
          value: contextPagination.sort || null
        },
        filter: {
          onFilter,
          onClear: onClearFilters,
          filterData: pickBy((_, key) => filterNames.includes(key), contextPagination) || {}
        },
        status: {
          onStatusChange,
          value: contextPagination.status || ''
        },
        pageFilter: {
          onPageFilterChange,
          value: contextPagination.filterPage || ''
        },
        clearParams
      };
    }),
    additional
  );
}
