import { compose, pure, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import {
  fetchEvents as fetchEventsAction,
  fetchFilteredEvents as fetchFilteredEventsAction
} from '../../../core/Events';
import {
  getPaymentsEvent,
  getPaymentsActiveBatch,
  getIsFetching,
  getPaymentsViewSelected,
  isPaymentsEventGroupAdmin
} from '../../../core/Payments/selectors';
import { getEvents, getFilteredEvents } from '../../../core/Events/selectors';
import {
  isSuperAdmin,
  isFundingAdmin,
  fundingAdminEvents,
  adminEvents
} from '../../../core/Auth/selectors';
import {
  storePaymentsEvent as storePaymentsEventAction,
  createBatch as createBatchAction,
  deleteBatch as deleteBatchAction,
  setPaymentsView as setPaymentsViewAction,
  setAllEventIsSelected as setAllEventIsSelectedAction
} from '../../../core/Payments';
import { CORE_ROLES, eventFilterLimit, PAYMENTS_VIEW } from '../../../constants';
import { history } from '../../../core/store';
import { hasAnyAdminEvents, isFundingAdminHighestRole } from '../../../utils/helpers';

const mapStateToProps = (state, props) => ({
  paymentsEvent: getPaymentsEvent()(state),
  activeBatch: getPaymentsActiveBatch(state),
  events: getEvents(state),
  isSuperAdmin: isSuperAdmin(state),
  isFundingAdmin: isFundingAdmin(state),
  isOnlyFundingAdmin: isFundingAdminHighestRole(
    getPaymentsEvent()(state),
    fundingAdminEvents(state),
    adminEvents(state)
  ),
  hasAnyAdminEvents: hasAnyAdminEvents(adminEvents(state)),
  isFetching: getIsFetching(state),
  paymentsViewSelected: getPaymentsViewSelected(state),
  filteredEvents: getFilteredEvents(state),
  isPaymentsEventGroupAdmin: isPaymentsEventGroupAdmin()(state),
  location: props.location
});

const mapDispatchToProps = dispatch => ({
  storePaymentsEvent: payload => dispatch(storePaymentsEventAction(payload)),
  fetchEvents: payload => dispatch(fetchEventsAction(payload)),
  createBatch: payload => dispatch(createBatchAction(payload)),
  deleteBatch: payload => dispatch(deleteBatchAction(payload)),
  setPaymentsViewSelected: payload => dispatch(setPaymentsViewAction(payload)),
  fetchFilteredEvents: payload => dispatch(fetchFilteredEventsAction(payload)),
  setAllEventIsSelected: payload => dispatch(setAllEventIsSelectedAction(payload))
});

const searchEventParms = {
  LIMIT: eventFilterLimit,
  SORTBY: '-created_at',
  roleId: CORE_ROLES.admin
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      if (this.props.fetchEvents)
        this.props.fetchEvents({
          userOnly: !this.props.isSuperAdmin,
          setLimit: searchEventParms.LIMIT,
          sort: searchEventParms.SORTBY,
          filters: {
            role_id: this.props.hasAnyAdminEvents
              ? searchEventParms.roleId
              : CORE_ROLES.funding_admin,
            set_crew_pay: 1
          },
          isFetchingList: true
        });
      if (this.props.isOnlyFundingAdmin) {
        this.props.setPaymentsViewSelected(PAYMENTS_VIEW.FUNDING);
      }
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.isOnlyFundingAdmin !== this.props.isOnlyFundingAdmin &&
        this.props.isOnlyFundingAdmin === true
      ) {
        this.props.setPaymentsViewSelected(PAYMENTS_VIEW.FUNDING);
      }
    }
  }),
  withHandlers({
    submitBatch: ({ createBatch, paymentsEvent: { id } }) => (data, formApi) => {
      createBatch({ id, data });
      return new Promise(resolve => resolve({ reset: formApi.reset }));
    },
    deleteActiveBatch: ({ deleteBatch, activeBatch: { id, event_id } }) => () =>
      deleteBatch({ batchId: id, eventId: event_id }),
    editActiveBatch: ({ paymentsEvent: { id } }) => () =>
      history.push(`${history.location.pathname}/${id}`),
    viewBatch: ({ setAllEventIsSelected }) => (id, eventId) => {
      setAllEventIsSelected({ eventId });
      return history.push(`${history.location.pathname}-review/${id}`);
    }
  }),
  pure
);
