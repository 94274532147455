import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export const Wrapper = styled.div`
  background-color: ${COLORS.athensGray};
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export default Wrapper;
