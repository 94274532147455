import React from 'react';
import { Checkbox } from '../../../../../StyledGames/GameRow/styled-components';
import { isGameChecked } from '../../../../config';

const GameCheckbox = ({ checked, handleCheckboxClick, id }) => {
  return (
    <>
      <Checkbox
        type="checkbox"
        onChange={e => handleCheckboxClick({ id, checked: e.target.checked })}
        checked={checked}
      />
    </>
  );
};

export default React.memo(GameCheckbox, isGameChecked);
