import MultiSelectDropdown from '../Reusable/MultiSelectDropdown';
import Dropdown from '../Reusable/Dropdown';
import MultipleInput from '../Reusable/MultipleInput';
import RemoveUsersModal from '../SideList/Modals/RemoveUsersModal';
import ImportModal from '../SideList/Modals/ImportModal';
import EmailUsersModal from '../SideList/Modals/EmailUsersModal';
import MinMax from '../Reusable/MinMax';
import UserCategoryAssignorModal from '../SideList/Modals/UserCategoryAssignorModal';
import { allAvailableProvinces, USER_ROLE_VALUES } from '../../../constants';
import theme from '../SideList/styled-components';
import AddNewUserModal from '../SideList/Modals/NewUser';

export const defaultConfig = {
  roles: [USER_ROLE_VALUES.ADMIN, USER_ROLE_VALUES.ASSIGNOR],
  enableSelectAll: true,
  label: 'admin'
};

export default ({
  showEmailSelectedModal,
  showRemoveSelectedModal,
  showImportModal,
  showCategoryAssignorModal,
  toggleShowEmailSelectedModal,
  toggleShowRemoveSelectedModal,
  toggleShowImportModal,
  toggleCategoryAssignorModal,
  sendEmail,
  onRemoveSelected,
  onUserImport,
  downloadCSV,
  categories = [],
  assignCategoriesToUsers,
  showAddNewUserModal,
  toggleAddNewUserModal,
  onAddUserManually
}) => ({
  ...defaultConfig,
  sort: {
    options: [
      {
        name: 'name',
        label: 'Name'
      },
      {
        name: 'age',
        label: 'Age'
      },
      {
        name: 'state',
        label: 'State'
      }
    ]
  },
  filters: [
    {
      component: MinMax,
      name: ['age_min', 'age_max'],
      placeholder: 'Age',
      theme: theme.multipleInput,
      hasCustomFields: true,
      convert: true,
      maxLength: 2
    },
    {
      component: MultiSelectDropdown,
      name: 'user.address_primary.state',
      placeholder: 'State/Province',
      options: allAvailableProvinces.map(({ name, value }) => ({
        name,
        value
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MultipleInput,
      name: 'user.address_primary.postal_code',
      placeholder: 'Zip Code',
      maxLength: 7,
      theme: theme.multipleInput
    },
    {
      component: Dropdown,
      name: 'user.gender',
      placeholder: 'Gender',
      options: [
        {
          name: 'Male',
          value: 'male'
        },
        {
          name: 'Female',
          value: 'female'
        }
      ],
      theme: theme.multiSelectDropdown
    },
    {
      component: MultiSelectDropdown,
      name: 'categories_filtered.category_id',
      placeholder: 'Category',
      options: categories.map(category => ({
        value: category.id,
        name: category.name
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    }
  ],
  actions: {
    options: [
      {
        component: EmailUsersModal,
        label: 'Email Selected',
        showOption: ['hasSelected'],
        showModal: showEmailSelectedModal,
        onClick: toggleShowEmailSelectedModal,
        onSubmit: sendEmail
      },
      {
        component: RemoveUsersModal,
        label: 'Remove Selected',
        showOption: ['hasSelected'],
        showModal: showRemoveSelectedModal,
        onClick: toggleShowRemoveSelectedModal,
        onSubmit: onRemoveSelected
      },
      {
        component: ImportModal,
        label: `Import New Admins`,
        showOption: [],
        showModal: showImportModal,
        onClick: toggleShowImportModal,
        onSubmit: onUserImport
      },
      {
        component: AddNewUserModal,
        label: `Add New Admin`,
        showOption: [],
        showModal: showAddNewUserModal,
        onClick: toggleAddNewUserModal,
        onSubmit: onAddUserManually
      },
      {
        component: UserCategoryAssignorModal,
        label: `Assign Categories`,
        showOption: ['hasSelected'],
        showModal: showCategoryAssignorModal,
        onClick: toggleCategoryAssignorModal,
        onSubmit: assignCategoriesToUsers,
        categories
      },
      {
        label: 'Download List',
        showOption: [],
        showModal: false,
        onClick: downloadCSV
      }
    ]
  }
});
