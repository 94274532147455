import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';

import { COLORS } from '../../../../utils/styles';
import Icon from '../../Icon';
import { pxToRem } from '../../../../utils/helpers';

export const Wrapper = styled.div`
  padding: ${pxToRem('22px')};
  font-family: 'RobotoCondensed';
`;

export const FormWrapper = styled.div`
  padding-left: ${pxToRem('14px')};
  padding-right: ${pxToRem('14px')};
`;

export const PayTableContainer = styled.div`
  margin: 0 ${pxToRem('0px')};
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  padding-left: 1rem;
  overflow: auto;
`;

export const ButtonsRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  margin-top: ${pxToRem('30px')};
`;

export const TravelPayRow = styled(Row)`
  padding-bottom: 30px;
`;

export const DropdownChevron = styled(Icon)`
  cursor: pointer;
  position: absolute;
  padding-top: ${pxToRem('5px')};
  right: -0.25rem;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: ${pxToRem('36px')};
  &.input {
    margin-top: ${pxToRem('32px')};
  }
  &:last-of-type {
    margin-right: 0;
  }
  &.hidden {
    display: none;
  }
`;

export const LabelContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const FieldLabel = styled.span`
  display: ${({ display }) => display || 'inline-block'};
  color: ${COLORS.shuttleGray};
  font-family: 'RobotoCondensed';
  padding: ${({ display }) =>
    display === 'block' ? `${pxToRem('11px')} 0 0.25rem 0.5rem` : '0 0.5rem'};
  font-size: ${pxToRem('13px')};
  ${({ display }) =>
    !display &&
    `
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  `};
`;

export const FieldDropdown = styled.select`
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  background-color: white;
  height: 1.5rem;
  border-radius: 0;
  border: none;
  box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);
  font-family: 'Roboto';
  color: ${COLORS.denimBlue};
  font-size: ${pxToRem('13px')};
  display: inline-block;
  padding-left: 0.5rem;
  width: ${pxToRem('238px')};
`;

export const Hint = styled.p`
  width: 100%;
  position: absolute;
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: ${COLORS.shuttleGray};
  margin: ${pxToRem('6px')} 0 0;
  text-align: center;
`;

export const FieldOption = styled.option`
  color: ${COLORS.denimBlue};
`;

export const FieldInput = styled.input`
  box-sizing: border-box;
  color: ${COLORS.denimBlue};
  border: solid 1px ${COLORS.spindle};
  width: ${pxToRem('67px')};
  height: 1.5rem;
  text-align: right;
  padding-right: ${pxToRem('10px')};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const EditIconContainer = styled.div`
  position: relative;
`;

export const Label = styled.label`
  position: relative;
  top: ${pxToRem('2.5px')};
  color: ${COLORS.nightRider};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoCondensedLight';
  margin-right: 0.3rem;
  margin-top: ${pxToRem('17px')};
  &.hidden {
    visibility: hidden;
  }
`;

export const LabelValue = styled.span`
  font-family: 'RobotoCondensed';
`;

export const IconLabel = styled.span`
  display: inline-block;
  position: relative;
  top: ${pxToRem('2px')};
  left: ${pxToRem('-6.5px')};
`;

export const IconButton = styled.button`
  float: right;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`;

export const Edit = styled(Icon)`
  cursor: pointer;
  margin-top: ${pxToRem('15px')};
  overflow: visible;
`;

export const StyledCol = styled(Col)`
  margin-left: ${pxToRem('3.8px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('15px')};
  letter-spacing: 0.5px;
`;

export const Table = styled.table`
  width: '100%';
  background-color: ${COLORS.blackSqueeze};
  font-size: ${pxToRem('13px')};
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  &.no-left-margin {
    margin-left: 0;
  }
`;

export const TableCell = styled.td`
  text-align: center;
  max-width: ${pxToRem('94px')};
  height: ${pxToRem('40px')};
  margin: ${pxToRem('6px')} 0;
  line-height: ${pxToRem('2.31px')};
`;

export const HeaderCell = styled.th`
  font-family: 'RobotoCondensed';
  text-align: center;
  width: ${pxToRem('94px')};
  height: ${pxToRem('40px')};
  margin: ${pxToRem('6px')} 0;
  &.bold {
    font-family: 'RobotoCondensedBold';
  }
`;

export const IconHeaderCell = styled(HeaderCell)`
  font-family: 'Roboto';
  color: ${COLORS.denimBlue};
`;

export const InnerCell = styled(TableCell)`
  font-family: 'Roboto';
  color: ${COLORS.nightRider};
  &.bold {
    font-family: 'RobotoBold';
  }
`;

export const CellInput = styled.input`
  text-align: center;
  padding: 0 0.25rem;
  font-size: ${pxToRem('13px')};
  border: none;
  box-sizing: border-box;
  width: ${pxToRem('69px')};
  height: ${pxToRem('30px')};
  background-color: ${COLORS.blackSqueeze};
  box-shadow: inset 1px 1px 3px 0 rgba(154, 163, 174, 0.53);

  &::placeholder {
    color: ${COLORS.nightRider};
  }
`;

export const StyledTableRow = styled.tr`
  box-sizing: border-box;
  padding: 3px 0;
`;

export const InnerCellContainer = styled.div`
  width: 100%;
  background-color: white;
`;

export const OuterCell = styled(TableCell)`
  font-family: 'RobotoCondensed';
  color: ${COLORS.denimBlue};
  line-height: normal;
`;

export const FieldError = styled.p`
  width: 100%;
  position: absolute;
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: ${COLORS.venetianRed};
  margin: 0;
  padding: ${({ paddingLeft }) => `${pxToRem('10px')} 0 0 ${pxToRem(paddingLeft)}`};
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
`;

export default {
  dropdownField: {
    error: true,
    wrapper: `
      box-sizing: border-box;
      cursor: pointer;
      background-color: white;
      height: 1.5rem;
      border-radius: 0;
      border: none;
      box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);
      font-family: 'Roboto';
      color: ${COLORS.denimBlue};
      font-size: ${pxToRem('13px')};
      display: inline-block;
      width: ${pxToRem('238px')};
    `,
    dropableContainer: {
      noDefaultError: true,
      clickable: `
        padding-top: 0;
        height: 100%:
      `,
      caption: `
        box-shadow: none;
        height: 1.5rem;
      `,
      placeholder: `
        line-height: 1.5rem;
        font-size: ${pxToRem('13px')};
        padding-left: 0.5rem;
        font-family: 'Roboto';
        vertical-align: middle;
      `,
      icon: `
        position: relative;
        bottom: ${pxToRem('3px')};
        right: ${pxToRem('6px')};
      `,
      content: `
        box-shadow: 0 ${pxToRem('2px')} 0.25rem 0 rgba(0, 0, 0, 0.5);
      `,
      label: `
      `
    },
    itemsContainer: `
    `,
    dropdownItem: `
      color: ${COLORS.shuttleGray}75;
      line-height: 1.5rem;
      font-family: 'RobotoCondensed';
      background-color: ${COLORS.white};
      height: ${pxToRem('24px')};
      border: none;

      &:hover {
        background-color: ${COLORS.aliceBlueVariant};
        color: ${COLORS.shuttleGray};
      }
      `
  },
  dropdownHeader: {
    error: true,
    wrapper: `
      box-sizing: border-box;
      cursor: pointer;
      background-color: white;
      height: ${pxToRem('20px')};
      border-radius: 0;
      border: none;
      box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);
      font-family: 'Roboto';
      color: ${COLORS.denimBlue};
      font-size: ${pxToRem('13px')};
      display: inline-block;
      width: ${pxToRem('176px')};
    `,
    dropableContainer: {
      noDefaultError: true,
      clickable: `
        padding-top: 0;
        height: 100%:
      `,
      caption: `
        box-shadow: none;
        height: ${pxToRem('20px')};
      `,
      placeholder: `
        color: ${COLORS.shuttleGray};
        line-height: 1.4rem;
        font-size: ${pxToRem('13px')};
        padding-left: 0.5rem;
        font-family: 'RobotoCondensedLight';
        vertical-align: middle;
      `,
      icon: `
        position: relative;
        bottom: ${pxToRem('5px')};
        right: ${pxToRem('6px')};
      `,
      content: `
        box-shadow: 0 ${pxToRem('2px')} 0.25rem 0 rgba(0, 0, 0, 0.5);
      `,
      label: 'hide'
    },
    itemsContainer: `
    `,
    dropdownItem: `
      color: ${COLORS.shuttleGray}75;
      line-height: 1.4rem;
      font-family: 'RobotoCondensed';
      background-color: ${COLORS.white};
      height: ${pxToRem('20px')};
      border: none;

      &:hover {
        background-color: ${COLORS.aliceBlueVariant};
        color: ${COLORS.shuttleGray};
      }
      `
  }
};
