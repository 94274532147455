import { loadStripe } from '@stripe/stripe-js/pure';
import Logger from './logger';

let deferred = null;

const getStripe = () => {
  if (deferred) {
    return deferred;
  }
  deferred = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  return deferred;
};

export const config = {
  defaultCurrency: 'usd',
  defaultCountry: 'US'
};

export default {
  getStripe,
  createToken: async (type, options) => {
    try {
      const stripe = await getStripe();
      const response = await stripe.createToken(type, options);
      if (response.error) {
        Logger.dbLog(response.error.message, { type, data: options });
      }
      return response;
    } catch (err) {
      Logger.dbLog(err, { type, data: options });
      throw err;
    }
  },
  setUpBankAccount: ({ details, secret }) => {
    return new Promise((resolve, reject) =>
      getStripe().then(stripe => {
        stripe
          .collectBankAccountForSetup({
            clientSecret: secret,
            params: {
              payment_method_type: 'us_bank_account',
              payment_method_data: {
                billing_details: details
              }
            },
            expand: ['payment_method']
          })
          .then(({ setupIntent, error }) => {
            if (error) {
              // PaymentMethod collection failed for some reason.
              return reject(error);
            }

            if (setupIntent.status === 'requires_payment_method') {
              // Customer canceled the hosted verification modal. Present them with other
              // payment method type options.
              return reject(error);
            }

            if (setupIntent.status === 'requires_confirmation') {
              // We collected an account - possibly instantly verified, but possibly
              // manually-entered. Display payment method details and mandate text
              // to the customer and confirm the intent once they accept
              // the mandate.
              return resolve(setupIntent);
            }
            const err = new Error('Stripejs: Unhandled request state');
            Logger.dbLog(err, { type: 'Stripejs: Unhandled request state', data: setupIntent });
            return reject(err);
          });
      })
    );
  }
};
