import styled from 'styled-components';
import Icon from '../Icon';
import { pxToRem } from '../../../utils/helpers';
import { COLORS } from '../../../utils/styles';

export const Container = styled.div``;

export const Header = styled.div`
  min-height: ${props => props.theme.headerMinHeight};
  display: flex;
  justify-content: ${props =>
    props.theme.justifyContent ? props.theme.justifyContent : 'space-between'};
  align-items: center;
  padding: ${props => props.theme.headerMinPaddingTop} ${pxToRem('15px')} 0;
  background: ${props => props.theme.headerBg};
  cursor: pointer;
  box-shadow: ${props =>
    props.theme.boxShadow
      ? props.theme.boxShadow
      : `inset 0 ${pxToRem('-1px')} 0 0 ${COLORS.hawkesBlue}`};
`;

export const TitleContainer = styled.div``;

export const Title = styled.span`
  color: ${props => props.color};
  font-family: ${props => props.theme.titleFont};
  font-size: ${props => props.theme.titleSize};
  font-weight: ${props => props.theme.titleWeight};
  text-transform: ${props => props.theme.titleTransform};
  letter-spacing: ${props => props.theme.titleLetterSpacing};
`;

export const CollapsedContent = styled.div``;

export const ChevronIcon = styled(Icon)`
  margin: 0;
`;
