export const withFetch = (state, value) => {
  const newState = { ...state };
  if (value) {
    newState.fetching = value;
  } else {
    delete newState.newState;
  }
  return newState;
};

class EnhancedState {
  constructor(enhancers) {
    this.state = null;
    const self = this;
    Object.keys(enhancers).forEach(enhancer => {
      self[enhancer] = value => enhancers[enhancer](self.state, value);
    });
  }

  set(state) {
    this.state = { ...state };
    return this;
  }

  flush() {
    this.state = null;
    return this;
  }
}

export const enhanceState = enhancers => new EnhancedState(enhancers);
