import React from 'react';
import { formatMoney } from 'accounting-js';
import COLORS from '../../../utils/styles';
import { composeValidators, maxValue, minValue, required } from '../../../utils/validations';
import { Input, SelectOutlined } from '../../global-material-components';
import { FieldWrapper, Error } from './styled-component';
import { currencyToNum, normalizePrice, numToCurrency } from '../../../utils/parsers';

export default {
  fields: [
    {
      field: {
        component: props => (
          <FieldWrapper>
            <SelectOutlined
              {...props}
              inputLabel="From"
              dataOptions={props.options}
              addOptionButton={{ name: 'Add ACH Account', value: '12' }}
              addOptionButtonHandler={() => {
                props.setAddFund(false);
                props.setOpenACHModal(true);
                props.mutators.setACHAccountValue(null);
              }}
              inputStyle={{ color: COLORS.denimBlue, fontSize: '16px' }}
            />
            {props.error && <Error>{props.error}</Error>}
          </FieldWrapper>
        ),
        name: 'sourceId',
        validate: required
      }
    },
    {
      field: {
        component: props => (
          <FieldWrapper className="walletBalance">
            <Input
              {...props}
              label="To"
              value={`WALLET - ${formatMoney(props.totalBalance)} - Total Balance`}
              inputFontSize="16px"
              inputStyle={{ color: COLORS.denimBlue, fontWeight: 600 }}
              readOnly
              disabled
            />
            {props.error && <Error>{props.error}</Error>}
          </FieldWrapper>
        ),
        name: 'wallet_balance'
      }
    },
    {
      field: {
        component: props => (
          <FieldWrapper>
            <Input
              label="Amount"
              inputFontSize="16px"
              inputStyle={{ color: COLORS.denimBlue }}
              {...props}
            />
            {props.error && <Error>{props.error}</Error>}
          </FieldWrapper>
        ),
        name: 'amount',
        parse: value => currencyToNum(normalizePrice(value, 5, false, false, true)),
        format: value => normalizePrice(numToCurrency(value), 5, false, false, true),
        validate: composeValidators(required, minValue(1), maxValue(99999))
      }
    },
    {
      field: {
        component: props => (
          <FieldWrapper>
            <Input
              {...props}
              label="Description"
              inputFontSize="16px"
              inputStyle={{ color: COLORS.denimBlue }}
            />
            {props.error && <Error>{props.error}</Error>}
          </FieldWrapper>
        ),
        name: 'description',
        validate: required
      }
    }
  ]
};
