import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { getPaymentHistory } from '../../../core/Payments';
import { paginationAware, filterConfigToNames } from '../PaginationAware';
import { fetchActiveUserInfo } from '../../../core/User';

const mapStateToProps = ({ payments, auth }, props) => ({
  userId: props && props.match && props.match.params && props.match.params.userId,
  isFetching: payments.isFetching,
  paymentHistory: payments.paymentHistory,
  paymentBatchDetails: payments.paymentBatchDetails,
  pathUrl: props && props.match && props.match.path,
  isFetchingPayee: auth && auth.isFetchingPayee
});

const mapDispatchToProps = dispatch => ({
  getPaymentHistoryAction: info => dispatch(getPaymentHistory(info)),
  fetchActiveUserInfo: payload => dispatch(fetchActiveUserInfo(payload))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware({
    fetcherQuote: 'getPaymentHistoryAction',
    forPrefix: 'hstry',
    metaQuote: 'paymentHistory',
    filterNames: filterConfigToNames([{ name: 'month' }, { name: 'year' }])
  })
);
