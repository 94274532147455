import React from 'react';
import CardInfoRow from './CardInfoRow';
import BankInfoRow from './BankInfoRow';
import { TextBorder } from '../styled-components';

export default function PaymentSources({
  userId,
  paymentSources,
  isFetching,
  deletePaymentSource,
  writeAccess
}) {
  const cards = paymentSources.card;
  const accounts = paymentSources.bank_account;

  return (
    <TextBorder>
      {cards &&
        cards.map(cardInfo => (
          <CardInfoRow
            Info={cardInfo}
            deletePaymentSource={deletePaymentSource}
            sourceName="CardView"
            isFetching={isFetching}
            writeAccess={writeAccess}
          />
        ))}
      {accounts &&
        accounts.map(accountInfo => (
          <BankInfoRow
            Info={accountInfo}
            sourceName="BankView"
            isFetching={isFetching}
            deletePaymentSource={deletePaymentSource}
            userId={userId}
            writeAccess={writeAccess}
          />
        ))}
    </TextBorder>
  );
}
