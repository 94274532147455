import { Tooltip } from '@material-ui/core';
import React from 'react';
import { Detail } from '../../../../../StyledGames/GameRow/styled-components';
import { isGameLevelTypeChanged } from '../../../../config';

const GameLevelAndType = ({ game_level }) => {
  return (
    <>
      <Tooltip
        title={game_level && game_level.game_type}
        PopperProps={{
          disablePortal: true
        }}
        arrow
      >
        <Detail>TYPE: {game_level && game_level.game_type}</Detail>
      </Tooltip>

      <Tooltip
        title={game_level && game_level.game_level}
        PopperProps={{
          disablePortal: true
        }}
        arrow
      >
        <Detail>LEVEL: {game_level && game_level.game_level}</Detail>
      </Tooltip>
    </>
  );
};

export default React.memo(GameLevelAndType, isGameLevelTypeChanged);
