import { buildGameReducerState } from '../../utils/helpers';

// Actions
export const FETCH_GAMES = 'GAMES/FETCH_GAMES';
export const SET_GAMES = 'GAMES/SET_GAMES';
export const SET_SELECTED_GAMES_EVENT = 'GAMES/SET_SELECTED_GAMES_EVENT';
export const STORE_GAMES_EVENT = 'GAMES/STORE_GAMES_EVENT';
export const STORE_GAMES_EVENT_AND_REDIRECT = 'GAMES/STORE_GAMES_EVENT_AND_REDIRECT';
export const FETCH_GAMES_EVENT_CREW_LABELS = 'GAMES/FETCH_GAMES_EVENT_CREW_LABELS';
export const SET_GAMES_EVENT_CREW_LABELS = 'GAMES/SET_GAMES_EVENT_CREW_LABELS';
export const CREATE_GAME_ASSIGNMENT = 'GAMES/CREATE_GAME_ASSIGNMENT';
export const DELETE_GAME_ASSIGNMENTS = 'GAMES/DELETE_GAME_ASSIGNMENTS';
export const UPDATE_GAME_ASSIGNMENTS = 'GAMES/UPDATE_GAME_ASSIGNMENTS';
export const UPDATE_GAME = 'GAMES/UPDATE_GAME';
export const UPDATE_GAMES = 'GAMES/UPDATE_GAMES';
export const DELETE_BULK_GAMES = 'GAMES/DELETE_BULK_GAMES';

export const FETCH_CURRENT_GAME = 'GAMES/FETCH_CURRENT_GAME';
export const SET_CURRENT_GAME = 'GAMES/SET_CURRENT_GAME';

export const ADD_GAME_ASSIGNMENT_TO_GAME = 'GAMES/ADD_GAME_ASSIGNMENT_TO_GAME';
export const REMOVE_GAME_ASSIGNMENT_FROM_GAME = 'GAMES/REMOVE_GAME_ASSIGNMENT_FROM_GAME';
export const UPDATE_GAME_ASSIGNMENT_TO_GAME = 'GAMES/UPDATE_GAME_ASSIGNMENT_TO_GAME';

export const CREATE_INCIDENT_REPORT = 'GAMES/CREATE_INCIDENT_REPORT';
export const ADD_INCIDENT_REPORT = 'GAMES/ADD_INCIDENT_REPORT';
export const EDIT_INCIDENT_REPORT = 'GAMES/EDIT_INCIDENT_REPORT';
export const UPDATE_INCIDENT_REPORT = 'GAMES/UPDATE_INCIDENT_REPORT';
export const DELETE_INCIDENT_REPORT = 'GAMES/DELETE_INCIDENT_REPORT';
export const REMOVE_INCIDENT_REPORT = 'GAMES/REMOVE_INCIDENT_REPORT';
export const FETCH_CURRENT_INCIDENT_REPORTS = 'GAMES/FETCH_INCIDENT_REPORTS';
export const SET_CURRENT_INCIDENT_REPORTS = 'GAMES/SET_INCIDENT_REPORTS';

export const SET_IS_FETCHING = 'GAMES/SET_IS_FETCHING';
export const SET_CURRENT_IS_FETCHING = 'GAMES/SET_CURRENT_IS_FETCHING';

export const CREATE_GAME_REPORT = 'GAMES/CREATE_GAME_REPORT';
export const ADD_GAME_REPORT = 'GAMES/ADD_GAME_REPORT';
export const EDIT_GAME_REPORT = 'GAMES/EDIT_GAME_REPORT';
export const UPDATE_GAME_REPORT = 'GAMES/UPDATE_GAME_REPORT';
export const DELETE_GAME_REPORT = 'GAMES/DELETE_GAME_REPORT';
export const REMOVE_GAME_REPORT = 'GAMES/REMOVE_GAME_REPORT';
export const FETCH_GAME_REPORT = 'GAMES/FETCH_GAME_REPORT';
export const SET_GAME_REPORT = 'GAMES/SET_GAME_REPORT';
export const FETCH_CURRENT_GAME_REPORTS = 'GAMES/FETCH_GAME_REPORTS';
export const SET_CURRENT_GAME_REPORTS = 'GAMES/SET_GAME_REPORTS';

export const CREATE_CREW_VERIFICATION_REPORT = 'GAMES/CREATE_CREW_VERIFICATION_REPORT';
export const ADD_CREW_VERIFICATION_REPORT = 'GAMES/ADD_CREW_VERIFICATION_REPORT';
export const FETCH_CURRENT_CREW_VERIFICATION_REPORTS = 'GAMES/FETCH_CREW_VERIFICATION_REPORTS';
export const SET_CURRENT_CREW_VERIFICATION_REPORTS = 'GAMES/SET_CREW_VERIFICATION_REPORTS';
export const SET_CREW_VERIFICATION_REPORT = 'GAMES/SET_CREW_VERIFICATION_REPORT';
export const SET_CURRENT_CREW_VERIFICATION_NOTES = 'GAMES/SET_CREW_VERIFICATION_NOTES';

export const SUBMIT_GAMES_IMPORT = 'GAMES/SUBMIT_GAMES_IMPORT';
export const SUBMIT_GAMES_UPDATE = 'GAMES/SUBMIT_GAMES_UPDATE';

export const SET_ASSIGNMENT_FETCHING = 'GAMES/SET_ASSIGNMENT_FETCHING';
export const SET_CALENDAR_GAME = 'GAMES/SET_CALENDAR_GAME';

export const SET_PAGINATION_PAGE = 'GAMES/SET_PAGINATION_PAGE';
export const SUBMIT_COPIED_GAME_ID = 'GAMES/SUBMIT_COPIED_GAME_ID';
export const IS_COPIED_GAME_ID_CORRECT = 'GAMES/IS_COPIED_GAME_ID_CORRECT';
export const IS_FETCHING_COPIED_GAME_ID = 'GAMES/IS_FETCHING_COPIED_GAME_ID';

export const FETCHING_ASSIGNMENT_NOTE = 'GAMES/FETCHING_ASSIGNMENT_NOTE';
export const SET_ASSIGNMENT_NOTE = 'GAMES/SET_ASSIGNMENT_NOTE';
export const FETCH_ASSIGNMENT_NOTE = 'GAMES/FETCH_ASSIGNMENT_NOTE';

// Reducer
export const initialState = {
  list: [],
  meta: { total_count: 0, limit: 100 },
  selectedGamesEvent: {
    id: null
  },
  paginationPage: 1,
  gamesEventCrewLabels: [],
  calendarGame: {},
  current: {
    id: null,
    schedule_id: null,
    display_id: null,
    summary: null,
    description: null,
    kind: null,
    timezone: null,
    status: null,
    published: null,
    field_name: null,
    schedule_name: null,
    schedule_owner: null,
    start_time: null,
    start_date: null,
    end_time: null,
    end_date: null,
    assignment_games: [],
    location: {},
    team_home: {},
    team_visitor: {},
    game_level: {},
    selected_assignment: null,
    isFetching: false
  },
  isFetching: false,
  assignmentFetching: {},
  copiedGameIdCorrectFlag: { flag: null, display_id: '' },
  isFetchingCopiedGameId: false,
  assignmentNote: {
    fetching: false,
    data: null
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_GAMES:
      return {
        ...state,
        list: action.payload.data.map(g => ({
          ...g,
          copy: !!(g.count && g.parent_id)
        })),
        meta: { ...state.meta, ...action.payload.meta }
      };
    case UPDATE_GAME: {
      const gameIdx = state.list.findIndex(game => game.id === action.payload.id);
      return {
        ...state,
        list: [
          ...state.list.slice(0, gameIdx),
          { ...state.list[gameIdx], ...action.payload },
          ...state.list.slice(gameIdx + 1)
        ]
      };
    }
    case SET_SELECTED_GAMES_EVENT:
      return {
        ...state,
        selectedGamesEvent: action.payload
      };

    case SET_CALENDAR_GAME:
      return {
        ...state,
        calendarGame: action.payload
      };
    case SET_GAMES_EVENT_CREW_LABELS:
      return {
        ...state,
        gamesEventCrewLabels: action.payload
      };
    case ADD_GAME_ASSIGNMENT_TO_GAME: {
      // const gameIdx = state.list.findIndex(game => game.id === action.payload.gameId);
      // return gameIdx !== -1
      //   ? {
      //     ...state,
      //     list: [
      //       ...state.list.slice(0, gameIdx),
      //       {
      //         ...state.list[gameIdx],
      //         assignments_game: [
      //           ...state.list[gameIdx].assignments_game,
      //           action.payload.assignment
      //         ]
      //       },
      //       ...state.list.slice(gameIdx + 1)
      //     ]
      //   }
      //   : state;

      const gameIdx = state.list.findIndex(game => game.id === action.payload.gameId);
      if (gameIdx !== -1) {
        /* eslint-disable no-param-reassign */
        state.list[gameIdx].assignments_game = [
          ...state.list[gameIdx].assignments_game,
          action.payload.assignment
        ];
        /* eslint-enable no-param-reassign */
      }
      return state;
    }
    case REMOVE_GAME_ASSIGNMENT_FROM_GAME: {
      const gameIdx = state.list.findIndex(game => game.id === action.payload.gameId);
      if (gameIdx !== -1) {
        /* eslint-disable no-param-reassign */
        state.list[gameIdx].assignments_game = state.list[gameIdx].assignments_game.filter(
          assignment => assignment.id !== action.payload.assignmentId
        );
        /* eslint-enable no-param-reassign */
      }
      return state;
    }
    case UPDATE_GAME_ASSIGNMENT_TO_GAME: {
      action.payload.assignments.forEach(assignment => {
        const gameIdx = state.list.findIndex(game => game.id === assignment.game.id);
        if (gameIdx !== -1) {
          const assignmentIdx = state.list[gameIdx].assignments_game.findIndex(
            ga => ga.id === assignment.id
          );
          /* eslint-disable no-param-reassign */
          state.list[gameIdx].assignments_game[assignmentIdx] = {
            ...state.list[gameIdx].assignments_game[assignmentIdx],
            status: assignment.status
          };
          /* eslint-enable no-param-reassign */
        }
      });
      return state;
    }
    case SET_CURRENT_GAME:
      return {
        ...state,
        current: {
          ...action.payload,
          gameReport: state.current.gameReport
        }
      };
    case ADD_INCIDENT_REPORT: {
      const gameIdx = state.list.findIndex(game => game.id === action.payload.external_game_id);
      const currentState = {
        ...state.current,
        incidentReports: [...state.current.incidentReports, action.payload]
      };
      return buildGameReducerState(state, currentState, currentState.incidentReports, gameIdx);
    }
    case UPDATE_INCIDENT_REPORT: {
      const gameIdx = state.list.findIndex(game => game.id === action.payload.game.id);
      const reportIdx = state.current.incidentReports.findIndex(
        report => report.id === action.payload.id
      );

      const currentState = {
        ...state.current,
        incidentReports: [
          ...state.current.incidentReports.slice(0, reportIdx),
          { ...state.current.incidentReports[reportIdx], ...action.payload },
          ...state.current.incidentReports.slice(reportIdx + 1)
        ]
      };
      return buildGameReducerState(state, currentState, currentState.incidentReports, gameIdx);
    }
    case REMOVE_INCIDENT_REPORT: {
      const reportToRemove = state.current.incidentReports.filter(
        report => report.id === action.payload
      );
      const gameIdx = state.list.findIndex(game => game.id === reportToRemove[0]?.external_game_id);
      const currentState = {
        ...state.current,
        incidentReports: state.current.incidentReports.filter(
          report => report.id !== action.payload
        )
      };
      return buildGameReducerState(state, currentState, currentState.incidentReports, gameIdx);
    }
    case SET_CURRENT_INCIDENT_REPORTS:
      return {
        ...state,
        current: {
          ...state.current,
          incidentReports: action.payload
        }
      };

    case ADD_CREW_VERIFICATION_REPORT:
      return {
        ...state,
        current: {
          ...state.current,
          crewVerificationReports: [...state.current.crewVerificationReports, action.payload]
        }
      };
    case SET_CURRENT_CREW_VERIFICATION_REPORTS:
      return {
        ...state,
        current: {
          ...state.current,
          crewVerificationReports: action.payload
        }
      };
    case SET_CURRENT_CREW_VERIFICATION_NOTES:
      return {
        ...state,
        current: {
          ...state.current,
          crewVerificationNotes: action.payload
        }
      };
    case ADD_GAME_REPORT:
      return {
        ...state,
        current: {
          ...state.current,
          gameReports: [...state.current.gameReports, action.payload]
        }
      };
    case UPDATE_GAME_REPORT: {
      if (!state.current.gameReports || !state.current.gameReports.length) {
        return {
          ...state,
          current: {
            ...state.current,
            gameReport: { ...state.current.gameReport, ...action.payload }
          }
        };
      }
      const reportIdx = state.current.gameReports.findIndex(
        report => report.id === action.payload.id
      );
      return {
        ...state,
        current: {
          ...state.current,
          gameReport: { ...state.current.gameReport, ...action.payload },
          gameReports: [
            ...state.current.gameReports.slice(0, reportIdx),
            { ...state.current.gameReports[reportIdx], ...action.payload },
            ...state.current.gameReports.slice(reportIdx + 1)
          ]
        }
      };
    }
    case REMOVE_GAME_REPORT:
      return {
        ...state,
        current: {
          ...state.current,
          gameReports: state.current.gameReports.filter(report => report.id !== action.payload)
        }
      };

    case SET_CURRENT_GAME_REPORTS:
      return {
        ...state,
        current: {
          ...state.current,
          gameReports: action.payload
        }
      };
    case SET_GAME_REPORT:
      return {
        ...state,
        current: {
          ...state.current,
          gameReport: action.payload
        }
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_CURRENT_IS_FETCHING:
      return {
        ...state,
        current: {
          ...state.current,
          isFetching: action.payload
        }
      };
    case SET_ASSIGNMENT_FETCHING:
      return {
        ...state,
        assignmentFetching: action.payload
      };

    case SET_CREW_VERIFICATION_REPORT: {
      const gameIdx = state.list.findIndex(game => game.id === action.payload.id);
      return gameIdx !== -1
        ? {
            ...state,
            list: [
              ...state.list.slice(0, gameIdx),
              {
                ...state.list[gameIdx],
                assignments_game: state.list[gameIdx].assignments_game.map(ga =>
                  action.payload.crewCheckedIn[ga.official_label_col]
                    ? {
                        ...ga,
                        crew_verification_report: !ga.crew_verification_report
                          ? {
                              external_game_id: action.payload.id
                            }
                          : ga.crew_verification_report
                      }
                    : { ...ga, crew_verification_report: null }
                )
              },
              ...state.list.slice(gameIdx + 1)
            ]
          }
        : state;
    }

    case SET_PAGINATION_PAGE:
      return {
        ...state,
        paginationPage: action.payload
      };

    case IS_COPIED_GAME_ID_CORRECT:
      return {
        ...state,
        copiedGameIdCorrectFlag: action.payload
      };

    case IS_FETCHING_COPIED_GAME_ID:
      return {
        ...state,
        isFetchingCopiedGameId: action.payload
      };

    case FETCHING_ASSIGNMENT_NOTE:
      return {
        ...state,
        assignmentNote: {
          fetching: true,
          data: null
        }
      };

    case SET_ASSIGNMENT_NOTE:
      return {
        ...state,
        assignmentNote: {
          fetching: false,
          data: action.payload
        }
      };

    default:
      return state;
  }
}

// Action Creators
export const fetchGames = payload => ({
  type: FETCH_GAMES,
  payload
});

export const setGames = payload => ({
  type: SET_GAMES,
  payload
});

export const setSelectedGamesEvent = payload => ({
  type: SET_SELECTED_GAMES_EVENT,
  payload
});

export const storeGamesEvent = payload => ({
  type: STORE_GAMES_EVENT,
  payload
});

export const storeGamesEventAndRedirect = payload => ({
  type: STORE_GAMES_EVENT_AND_REDIRECT,
  payload
});

export const fetchGamesEventCrewLabels = payload => ({
  type: FETCH_GAMES_EVENT_CREW_LABELS,
  payload
});

export const setGamesEventCrewLabels = payload => ({
  type: SET_GAMES_EVENT_CREW_LABELS,
  payload
});

export const createGameAssignment = payload => ({
  type: CREATE_GAME_ASSIGNMENT,
  payload
});
export const deleteGameAssignments = payload => ({
  type: DELETE_GAME_ASSIGNMENTS,
  payload
});

export const updateGameAssignments = payload => ({
  type: UPDATE_GAME_ASSIGNMENTS,
  payload
});

export const updateGame = payload => ({
  type: UPDATE_GAME,
  payload
});

export const updateGames = payload => ({
  type: UPDATE_GAMES,
  payload
});

export const deleteBulkGames = payload => ({
  type: DELETE_BULK_GAMES,
  payload
});

export const addGameAssignmentToGame = payload => ({
  type: ADD_GAME_ASSIGNMENT_TO_GAME,
  payload
});

export const updateGameAssignmentToGame = payload => ({
  type: UPDATE_GAME_ASSIGNMENT_TO_GAME,
  payload
});

export const removeGameAssignmentFromGame = payload => ({
  type: REMOVE_GAME_ASSIGNMENT_FROM_GAME,
  payload
});

export const fetchCurrentGame = payload => ({
  type: FETCH_CURRENT_GAME,
  payload
});

export const setCurrentGame = payload => ({
  type: SET_CURRENT_GAME,
  payload
});

export const setIsFetching = payload => ({
  type: SET_IS_FETCHING,
  payload
});

export const setCurrentIsFetching = payload => ({
  type: SET_CURRENT_IS_FETCHING,
  payload
});

export const createIncidentReport = payload => ({
  type: CREATE_INCIDENT_REPORT,
  payload
});

export const addIncidentReport = payload => ({
  type: ADD_INCIDENT_REPORT,
  payload
});

export const deleteIncidentReport = payload => ({
  type: DELETE_INCIDENT_REPORT,
  payload
});

export const removeIncidentReport = payload => ({
  type: REMOVE_INCIDENT_REPORT,
  payload
});

export const fetchCurrentIncidentReports = payload => ({
  type: FETCH_CURRENT_INCIDENT_REPORTS,
  payload
});

export const setCurrentIncidentReports = payload => ({
  type: SET_CURRENT_INCIDENT_REPORTS,
  payload
});

export const editIncidentReport = payload => ({
  type: EDIT_INCIDENT_REPORT,
  payload
});

export const updateIncidentReport = payload => ({
  type: UPDATE_INCIDENT_REPORT,
  payload
});

export const createCrewVerificationReport = payload => ({
  type: CREATE_CREW_VERIFICATION_REPORT,
  payload
});

export const addCrewVerificationReport = payload => ({
  type: ADD_CREW_VERIFICATION_REPORT,
  payload
});

export const fetchCurrentCrewVerificationReports = payload => ({
  type: FETCH_CURRENT_CREW_VERIFICATION_REPORTS,
  payload
});

export const setCurrentCrewVerificationReports = payload => ({
  type: SET_CURRENT_CREW_VERIFICATION_REPORTS,
  payload
});

export const setCurrentCrewVerificationNotes = payload => ({
  type: SET_CURRENT_CREW_VERIFICATION_NOTES,
  payload
});

export const createGameReport = payload => ({
  type: CREATE_GAME_REPORT,
  payload
});

export const addGameReport = payload => ({
  type: ADD_GAME_REPORT,
  payload
});

export const deleteGameReport = payload => ({
  type: DELETE_GAME_REPORT,
  payload
});

export const removeGameReport = payload => ({
  type: REMOVE_GAME_REPORT,
  payload
});

export const fetchGameReport = payload => ({
  type: FETCH_GAME_REPORT,
  payload
});

export const setGameReport = payload => ({
  type: SET_GAME_REPORT,
  payload
});

export const fetchCurrentGameReports = payload => ({
  type: FETCH_CURRENT_GAME_REPORTS,
  payload
});

export const setCurrentGameReports = payload => ({
  type: SET_CURRENT_GAME_REPORTS,
  payload
});

export const editGameReport = payload => ({
  type: EDIT_GAME_REPORT,
  payload
});

export const updateGameReport = payload => ({
  type: UPDATE_GAME_REPORT,
  payload
});

export const submitGamesImport = payload => ({
  type: SUBMIT_GAMES_IMPORT,
  payload
});

export const submitGamesUpdate = payload => ({
  type: SUBMIT_GAMES_UPDATE,
  payload
});

export const setAssignmentFetching = payload => ({
  type: SET_ASSIGNMENT_FETCHING,
  payload
});

export const setCalendarGame = payload => ({
  type: SET_CALENDAR_GAME,
  payload
});

export const setCrewVerificationReports = payload => ({
  type: SET_CREW_VERIFICATION_REPORT,
  payload
});

export const setPaginationPage = payload => ({
  type: SET_PAGINATION_PAGE,
  payload
});

export const submitCopiedGameId = payload => ({
  type: SUBMIT_COPIED_GAME_ID,
  payload
});

export const isCopiedGameIdCorrect = payload => ({
  type: IS_COPIED_GAME_ID_CORRECT,
  payload
});

export const setFetchingCopiedGameId = payload => ({
  type: IS_FETCHING_COPIED_GAME_ID,
  payload
});

export const isfetchingAssignmentNote = () => ({
  type: FETCHING_ASSIGNMENT_NOTE
});

export const setAssignmentNote = payload => ({
  type: SET_ASSIGNMENT_NOTE,
  payload
});

export const fetchAssignmentNote = payload => ({
  type: FETCH_ASSIGNMENT_NOTE,
  payload
});
