import styled from 'styled-components';
import { pxToRem } from '../../../utils/helpers';

export const Frosted = styled.div`
  box-sizing: border-box;
  max-width: ${pxToRem('460px')};
  width: 100%;
  border-radius: ${pxToRem('10px')};
  -webkit-backdrop-filter: blur(${pxToRem('6px')});
  backdrop-filter: blur(${pxToRem('6px')});
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${pxToRem('35px')} ${pxToRem('52px')};
`;

export default Frosted;
