import { compose, withState, withHandlers, pure } from 'recompose';

export default compose(
  withState('activeFile', 'setActiveFile', null),
  withState('filter', 'setFilter', ['Email All']),
  withState('resultMessage', 'setResultMessage', null),
  withState('inProgress', 'setInProgress', false),
  withState('recipient', 'setRecipient', ({ selected }) => {
    const selectedUser = [];
    if (selected && selected.length > 0) {
      selected.forEach(game => {
        if (game && game.assignments_game && game.assignments_game.length > 0) {
          game.assignments_game.forEach(assignment => {
            const user = { ...assignment.event_role.user };
            const duplicate = selectedUser.map(u => u.id === user.id);
            if (duplicate.indexOf(true) === -1) {
              user.status = [];
              user.status.push(assignment.status);
              selectedUser.push(user);
            } else {
              selectedUser[duplicate.indexOf(true)].status.push(assignment.status);
            }
          });
        }
      });
    }
    return selectedUser;
  }),
  withHandlers({
    resolve: ({ setActiveFile, setInProgress, setResultMessage }) => response => {
      if (response && response.type === 'error') {
        setActiveFile(null);
        setResultMessage(response);
      } else if (response && response.data) {
        setResultMessage({ type: 'success', message: response.data });
      }

      setInProgress(false);
    }
  }),
  withHandlers({
    onSubmitImport: ({ onSubmit, resolve, setInProgress }) => file => {
      setInProgress(true);
      onSubmit({ file, resolve });
    },
    onSubmitManually: ({ onSubmit }) => values => {
      const { email, first_name, last_name, role } = values;
      onSubmit({ email, first_name, last_name, role });
    }
  }),
  pure
);
