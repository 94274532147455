import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';
import { COLORS } from '../../../../../../utils/styles';
import { pxToRem } from '../../../../../../utils/helpers';

export const GameDate = styled.span`
  color: ${COLORS.denimBlue};
  white-space: nowrap;
  font-weight: bold;
`;

export const Wrapper = styled.div`
  font-family: 'Barlow';
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  padding-left: ${pxToRem('59px')};
  border-right: 1px solid ${COLORS.lightCyanGray};
  align-self: center;
  justify-content: top;
  min-width: 22.5rem;
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  left: 1.5rem;
  top: 2px;
`;

export const NameDetail = styled.span`
  font-size: ${pxToRem('13px')};
  font-family: 'BarlowSemiCondensed';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 26ch;
`;

export const Location = styled.div`
  font-weight: 600;
  max-width: 20rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TeamCol = styled(Col)`
  &:nth-child(1) {
    padding: 0px 0px 0px 8px;
  }
  padding: 0px 0px;
`;
