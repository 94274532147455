import React from 'react';
import PropTypes from 'prop-types';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import {
  StyledAssignor,
  NonPaymentIcon,
  PaymentMouseOverPopoverWrapper
} from '../styled-components';
import MouseOverPopover from '../../../../../global-material-components/Popover';

const Assignor = ({
  assignor,
  current,
  handlePrimaryAssignment,
  handlePrimaryAssignmentBulk,
  allGames,
  allGamesChecked,
  requiredPaymentProfile,
  setAssignmentWarnings
}) => {
  const isPaymentNotSetUp = requiredPaymentProfile && !(assignor && assignor.transfers_enabled);
  return (
    <StyledAssignor
      previous={assignor && current && Number(assignor.id) === Number(current.event_role_id)}
      onClick={() => {
        if (!isPaymentNotSetUp) {
          if (allGamesChecked) {
            handlePrimaryAssignmentBulk({ current, allGames, assignor });
          } else {
            handlePrimaryAssignment({ current, selected: assignor });
          }
        } else {
          setAssignmentWarnings({
            assignmentWarnings: [
              {
                message: `Payment Profile is required to be included in a batch.`,
                error: true,
                header: 'PAYMENT ERROR'
              }
            ]
          });
        }
      }}
    >
      <> {`${assignor.users.first_name} ${assignor.users.last_name}`}</>
      <>
        {' '}
        {isPaymentNotSetUp ? (
          <PaymentMouseOverPopoverWrapper>
            <MouseOverPopover
              transformOriginHorizontal="left"
              messageTitle="Incomplete Profile"
              messageBody="Payment profile is not completed"
            >
              <NonPaymentIcon>
                {' '}
                <MonetizationOnOutlinedIcon />{' '}
              </NonPaymentIcon>
            </MouseOverPopover>
          </PaymentMouseOverPopoverWrapper>
        ) : (
          <span />
        )}
      </>

      <span>
        {assignor.users.city ? `${assignor.users.city}, ${assignor.users.state}` : <span />}
      </span>
    </StyledAssignor>
  );
};

Assignor.propTypes = {
  assignor: PropTypes.objectOf(Object).isRequired,
  handlePrimaryAssignment: PropTypes.func.isRequired,
  handlePrimaryAssignmentBulk: PropTypes.func.isRequired,
  current: PropTypes.objectOf(Object),
  allGames: PropTypes.arrayOf(Object).isRequired,
  allGamesChecked: PropTypes.bool.isRequired
};

Assignor.defaultProps = {
  current: null
};

export default Assignor;
