import React from 'react';
import sanitizeHtml from 'sanitize-html';

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, options)
});

const SanitizeHTML = ({ children, options }) => (
  <div dangerouslySetInnerHTML={sanitize(children, options)} />
);

export default SanitizeHTML;
