import InfoField from '../../../Reusable/InfoField';
import Input from '../../../Reusable/Input';
import Dropdown from '../../../Reusable/Dropdown';
import TextArea from '../../../Reusable/TextArea';
import theme, { Label } from '../styled-components';
import options from './constants';
import { COLORS } from '../../../../../utils/styles';
import { composeValidators, required, valueInRange } from '../../../../../utils/validations';
import { addCountToDisplayId, getTimezoneAbbr, onlyDigits } from '../../../../../utils/helpers';
import { formatDate } from '../../../../../utils/parsers';

const isRequired = () => true;

export const generateGameInfo = ({ game, report }) => [
  {
    label: 'Game ID',
    value: addCountToDisplayId(game)
  },
  {
    label: 'Date',
    value: `${formatDate(game.start_date, 'dddd, MMMM Do YYYY')} -  ${
      game.start_time
    } (${getTimezoneAbbr(game.start_date, game.timezone)})`
  },
  {
    label: 'Level & League',
    value: `${game.game_level.game_level} - ${game.game_level.game_type} (${game.schedule_owner})`
  },
  {
    label: 'Location',
    value: `${game.location.name} (${game.location.city})`
  },
  {
    label: 'Home Team',
    value: game.team_home.name
  },
  {
    label: 'Away Team',
    value: game.team_visitor.name
  },
  {
    label: 'Official',
    value:
      report.game_assignment &&
      `${report.game_assignment.event_role.user.first_name} ${report.game_assignment.event_role.user.last_name}`
  },
  {
    label: 'Assessor',
    value: report.created_by_user_id
      ? `${report.submitted_by.first_name} ${report.submitted_by.last_name}`
      : 'None'
  }
];

export const generateReportInfo = ({ report }) => [
  {
    component: InfoField,
    label: 'Overall Score',
    value: report.score
  },
  {
    component: InfoField,
    label: 'Type',
    value: report.report_type
  },
  {
    component: InfoField,
    label: 'Degree of Difficulty',
    value: report.difficulty
  },
  {
    component: TextArea,
    name: 'difficulty_reason',
    label: 'Reasons for Difficulty',
    placeholder: 'Reasons for Difficulty',
    input: { value: report.difficulty_reason, onChange: () => true },
    theme: theme.textArea(),
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true
  },
  {
    component: Label,
    value: 'Control of The Game',
    theme: theme.label,
    simple: true
  },
  {
    component: TextArea,
    name: 'positive_control',
    label: 'Positive Points',
    placeholder: 'Positive Points',
    input: { value: report.positive_control, onChange: () => true },
    theme: theme.textArea(),
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true,
    message:
      '(Correct and consistent application of the laws of the game. Appropriate disciplinary sanctions, tactical approach and management of the game)'
  },
  {
    component: TextArea,
    name: 'negative_control',
    label: 'Areas for Improvement',
    placeholder: 'Areas for Improvement',
    input: { value: report.negative_control, onChange: () => true },
    theme: theme.textArea(),
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true
  },
  {
    component: Label,
    value: 'Teamwork',
    theme: theme.label,
    simple: true
  },
  {
    component: TextArea,
    name: 'positive_teamwork',
    label: 'Positive Points',
    placeholder: 'Positive Points',
    input: { value: report.positive_teamwork, onChange: () => true },
    theme: theme.textArea(),
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true,
    message: '(Cooperation with Assistant Referees and 4th Official)'
  },
  {
    component: TextArea,
    name: 'negative_teamwork',
    label: 'Areas for Improvement',
    placeholder: 'Areas for Improvement',
    input: { value: report.negative_teamwork, onChange: () => true },
    theme: theme.textArea(),
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true
  },
  {
    component: Label,
    value: 'Physical Fitness',
    theme: theme.label,
    simple: true
  },
  {
    component: TextArea,
    name: 'positive_physical_fitness',
    label: 'Positive Points',
    placeholder: 'Positive Points',
    input: { value: report.positive_physical_fitness, onChange: () => true },
    theme: theme.textArea(),
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true,
    message: '(Stamina, speed, acceleration whenever needed. Positioning and Movement)'
  },
  {
    component: TextArea,
    name: 'negative_physical_fitness',
    label: 'Areas for Improvement',
    placeholder: 'Areas for Improvement',
    input: { value: report.negative_physical_fitness, onChange: () => true },
    theme: theme.textArea(),
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true
  },
  {
    component: TextArea,
    name: 'comments',
    label: 'Additional Comments',
    placeholder: 'Additional Comments',
    input: { value: report.comments, onChange: () => true },
    theme: theme.textArea(COLORS.sapphire),
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true
  }
];

export default {
  fields: [
    {
      component: Input,
      name: 'score',
      placeholder: 'Overall Score',
      theme: theme.input,
      parse: onlyDigits,
      maxLength: 3,
      validate: composeValidators(required, valueInRange({ min: 1, max: 100 })),
      isRequired,
      message: 'Must be between 1 and 100'
    },
    {
      component: Dropdown,
      name: 'report_type',
      placeholder: 'Report Type',
      options: options.reportType.map(name => ({ name, value: name })),
      theme: theme.dropdown,
      displayValue: true,
      validate: required,
      isRequired
    },
    {
      component: Dropdown,
      name: 'difficulty',
      placeholder: 'Degree of Difficulty',
      options: options.difficulty.map(name => ({ name, value: name })),
      theme: theme.dropdown,
      displayValue: true,
      validate: required,
      isRequired
    },
    {
      component: TextArea,
      name: 'difficulty_reason',
      placeholder: 'Reason for Difficulty',
      theme: theme.textArea(),
      rows: 6,
      width: 12,
      resize: 'vertical',
      validate: required,
      isRequired
    },
    {
      component: Label,
      name: 'control',
      value: 'Control of The Game',
      theme: theme.label,
      simple: true
    },
    {
      component: TextArea,
      name: 'positive_control',
      label: 'Positive Points',
      placeholder: 'Positive Points',
      theme: theme.textArea(),
      rows: 6,
      width: 12,
      resize: 'vertical',
      validate: required,
      isRequired,
      message:
        '(Correct and consistent application of the laws of the game. Appropriate disciplinary sanctions, tactical approach and management of the game)'
    },
    {
      component: TextArea,
      name: 'negative_control',
      label: 'Areas for Improvement',
      placeholder: 'Areas for Improvement',
      theme: theme.textArea(),
      rows: 6,
      width: 12,
      resize: 'vertical',
      validate: required,
      isRequired
    },
    {
      component: Label,
      name: 'teamwork',
      value: 'Teamwork',
      theme: theme.label,
      simple: true
    },
    {
      component: TextArea,
      name: 'positive_teamwork',
      label: 'Positive Points',
      placeholder: 'Positive Points',
      theme: theme.textArea(),
      rows: 6,
      width: 12,
      resize: 'vertical',
      validate: required,
      isRequired,
      message: '(Cooperation with Assistant Referees and 4th Official)'
    },
    {
      component: TextArea,
      name: 'negative_teamwork',
      label: 'Areas for Improvement',
      placeholder: 'Areas for Improvement',
      theme: theme.textArea(),
      rows: 6,
      width: 12,
      resize: 'vertical',
      validate: required,
      isRequired
    },
    {
      component: Label,
      name: 'physical_fitness',
      value: 'Physical Fitness',
      theme: theme.label,
      simple: true
    },
    {
      component: TextArea,
      name: 'positive_physical_fitness',
      label: 'Positive Points',
      placeholder: 'Positive Points',
      theme: theme.textArea(),
      rows: 6,
      width: 12,
      resize: 'vertical',
      validate: required,
      isRequired,
      message: '(Stamina, speed, acceleration whenever needed. Positioning and Movement)'
    },
    {
      component: TextArea,
      name: 'negative_physical_fitness',
      label: 'Areas for Improvement',
      placeholder: 'Areas for Improvement',
      theme: theme.textArea(),
      rows: 6,
      width: 12,
      resize: 'vertical',
      validate: required,
      isRequired
    },
    {
      component: TextArea,
      name: 'comments',
      label: 'Additional Comments',
      placeholder: 'Additional Comments',
      theme: theme.textArea(COLORS.sapphire),
      rows: 6,
      width: 12,
      resize: 'vertical',
      message: '(Displayed to Officials)'
    }
  ]
};
