import styled from 'styled-components';
import Icon from '../../Icon';
import { COLORS, HEIGHTS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const Wrapper = styled.div`
  background: ${COLORS.dopeGray};
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${pxToRem('10px')} ${pxToRem('30px')};
  box-sizing: border-box;
  font-family: 'Roboto';
  min-height: ${HEIGHTS.summaryBar};
  position: fixed;
  bottom: 32px;
`;

export const StyledButton = styled.button`
  background: ${({ filled }) => (filled ? COLORS.denimBlue : COLORS.white)};
  background: ${({ disabled }) => (disabled ? COLORS.gray : null)};
  color: ${({ filled }) => (filled ? COLORS.white : COLORS.denimBlue)};
  border-radius: ${pxToRem('3px')};
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: ${pxToRem('0.5px')};
  font-stretch: normal;
  font-size: ${pxToRem('14px')};
  font-weight: 500;
  outline: none;
  padding: ${pxToRem('10px')};
  ${({ filled }) => !filled && `border: 1px solid ${COLORS.denimBlue}`};
  display: block;
  transition: ease-in-out 0.1s all;
  ${({ width }) => width && `width: ${width}`};
  ${({ margin }) => margin && `margin: ${margin};`};

  &:hover {
    background: ${({ filled, disabled }) =>
      !disabled && (filled ? COLORS.white : COLORS.denimBlue)};
    color: ${({ filled, disabled }) => !disabled && (filled ? COLORS.denimBlue : COLORS.white)};
  }

  &:active {
    background: ${({ filled }) => (filled ? `#d1e7fa` : `#1263aa`)};
  }
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-left: ${pxToRem('4px')};
  margin-bottom: ${pxToRem('2px')};
`;

export const ButtonWrapper = styled.div`
  width: 15rem;
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const BoldBlue = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${pxToRem('20px')};
  font-weight: bold;
  color: ${COLORS.denimBlue};
`;

export const Subheader = styled.span`
  margin: 0;
  padding: 0;
  font-size: ${pxToRem('14px')};
  color: ${COLORS.offBlack};
  margin: ${pxToRem('5px')} ${({ noLeftMargin }) => (noLeftMargin ? '0' : pxToRem('70px'))}
    ${pxToRem('0px')} ${pxToRem('5px')};
`;
