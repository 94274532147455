import React from 'react';
import PropTypes from 'prop-types';

import GameReport from './GameReport';
import Subheader from '../Subheader';
import helpers from './helpers';
import LoaderWrapper from '../../LoaderWrapper';
import Flex from '../../Flex';
import theme, { EmptyMessage } from '../styled-components';

const GameReports = ({
  reports,
  event,
  handleCheckboxClick,
  checkedReports,
  handleSelectAll,
  onGameReportClick,
  sort,
  isReportsEventAdmin,
  isReportsEventAssignor,
  isReportsEventGroupAdmin,
  getReportsEventSport,
  isFetchingGameReports
}) => {
  const fields = helpers.getReportFields(
    reports[0],
    event && event.report_settings,
    getReportsEventSport
  );
  return (
    <>
      <Subheader
        handleSelectAll={handleSelectAll}
        checked={!!reports.length && reports.length === checkedReports.length}
        sort={sort}
        isReportsEventAdmin={isReportsEventAdmin}
        isReportsEventGroupAdmin={isReportsEventGroupAdmin}
        reportType="game"
        fields={fields}
        isReportsEventAssignor={isReportsEventAssignor}
      />
      <LoaderWrapper isFetching={isFetchingGameReports}>
        {reports.length ? (
          <Flex direction="column" full fullWidth theme={theme.row.wrapper}>
            {reports.map(report => (
              <GameReport
                key={report.id}
                report={report}
                handleCheckboxClick={handleCheckboxClick}
                checked={checkedReports.includes(report.id)}
                onGameReportClick={onGameReportClick}
                isReportsEventAdmin={isReportsEventAdmin}
                isReportsEventAssignor={isReportsEventAssignor}
                isReportsEventGroupAdmin={isReportsEventGroupAdmin}
                getReportsEventSport={getReportsEventSport}
                settings={event && event.report_settings}
              />
            ))}
          </Flex>
        ) : (
          <Flex fullWidth>
            <EmptyMessage>No reports available</EmptyMessage>
          </Flex>
        )}
      </LoaderWrapper>
    </>
  );
};

GameReports.propTypes = {
  reports: PropTypes.arrayOf(Object),
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedReports: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  onGameReportClick: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  isReportsEventAdmin: PropTypes.bool,
  isFetchingGameReports: PropTypes.bool.isRequired
};

GameReports.defaultProps = {
  reports: [],
  isReportsEventAdmin: false
};

export default GameReports;
