import MultiSelectDropdown from '../Reusable/MultiSelectDropdown';
import Dropdown from '../Reusable/Dropdown';
import MultipleInput from '../Reusable/MultipleInput';
import RemoveUsersModal from '../SideList/Modals/RemoveUsersModal';
import ImportModal from '../SideList/Modals/ImportModal';
import AddNewUserModal from '../SideList/Modals/NewUser';
import EmailUsersModal from '../SideList/Modals/EmailUsersModal';
import AddRankModal from '../SideList/Modals/AddRankModal';
import MinMax from '../Reusable/MinMax';
import UserCategoryAssignorModal from '../SideList/Modals/UserCategoryAssignorModal';
import {
  allAvailableProvinces,
  LICENSE_STATUSES,
  OFFICIAL_MIN_GRADE,
  PAYMENT_PROFILE_FILTER,
  USER_ROLE_VALUES
} from '../../../constants';
import theme from '../SideList/styled-components';
import { capitalize, getEventId } from '../../../utils/helpers';
import CopyUsersModal from '../SideList/Modals/CopyUsersModal';
import roleConstants from '../../../core/Roles/roleConstants';

export const defaultConfig = {
  roles: [USER_ROLE_VALUES.OFFICIAL],
  enableSelectAll: true,
  label: 'official'
};

// get a simplified data contract for info needed to
// perform select all operations in the officials list
const getSelectAllProps = (match, eventInfo) => {
  return {
    role_ids: [roleConstants.OFFICIAL],
    event_id: getEventId(match, eventInfo)
  };
};

export default ({
  showEmailSelectedModal,
  showRemoveSelectedModal,
  showImportModal,
  showCategoryAssignorModal,
  showAddRankModal,
  showCopyUserModal,
  toggleShowEmailSelectedModal,
  toggleShowRemoveSelectedModal,
  toggleShowImportModal,
  toggleCategoryAssignorModal,
  toggleShowAddRankModal,
  toggleShowCopyUserModal,
  showAddNewUserModal,
  toggleAddNewUserModal,
  sendEmail,
  copyEventUsers,
  events,
  onRemoveSelected,
  onUserImport,
  onAddUserManually,
  onAddRank,
  downloadCSV,
  categories = [],
  assignCategoriesToUsers,
  filteredEvents,
  match,
  eventInfo
}) => {
  const Filters = [
    {
      component: MinMax,
      name: ['age_min', 'age_max'],
      placeholder: 'Age',
      theme: theme.multipleInput,
      hasCustomFields: true,
      convert: true,
      maxLength: 2
    },
    {
      component: MinMax,
      name: ['certyr_min', 'certyr_max'],
      placeholder: 'Cert Yr.',
      theme: theme.multipleInput,
      hasCustomFields: true,
      convert: true,
      maxLength: 4,
      minLength: 4
    },
    {
      component: Dropdown,
      name: 'cert_level',
      placeholder: 'Cert Level',
      options: OFFICIAL_MIN_GRADE.slice(1).map(grade => ({
        name: grade.label,
        value: grade.label
      })),
      theme: theme.multiSelectDropdown
    },
    {
      component: Dropdown,
      name: 'cert_status',
      placeholder: 'Cert. Status',
      options: Object.keys(LICENSE_STATUSES).map(key => ({
        name: capitalize(LICENSE_STATUSES[key]),
        value: LICENSE_STATUSES[key]
      })),
      theme: theme.multiSelectDropdown
    },
    {
      component: MultiSelectDropdown,
      name: 'user.address_primary.state',
      placeholder: 'State/Province',
      options: allAvailableProvinces.map(({ name, value }) => ({
        name,
        value
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MultipleInput,
      name: 'user.address_primary.postal_code',
      placeholder: 'Zip Code',
      maxLength: 7,
      theme: theme.multipleInput
    },
    {
      component: Dropdown,
      name: 'user.gender',
      placeholder: 'Gender',
      options: [
        {
          name: 'Male',
          value: 'male'
        },
        {
          name: 'Female',
          value: 'female'
        }
      ],
      theme: theme.multiSelectDropdown
    },
    {
      component: MultiSelectDropdown,
      name: 'categories_filtered.category_id',
      placeholder: 'Category',
      options: categories.map(category => ({
        value: category.id,
        name: category.name
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: Dropdown,
      name: 'payment_profile',
      placeholder: 'Payment Profile',
      options: Object.keys(PAYMENT_PROFILE_FILTER).map(key => ({
        name: capitalize(PAYMENT_PROFILE_FILTER[key]),
        value: PAYMENT_PROFILE_FILTER[key]
      })),
      theme: theme.multiSelectDropdown
    }
  ];

  const selectAllProps = getSelectAllProps(match, eventInfo);

  return {
    ...defaultConfig,
    sort: {
      options: [
        {
          name: 'name',
          label: 'Name'
        },
        {
          name: 'age',
          label: 'Age'
        },
        {
          name: 'rank',
          label: 'Rank'
        },
        {
          name: 'city',
          label: 'City'
        },
        {
          name: 'state',
          label: 'State'
        },
        {
          name: 'postal_code',
          label: 'Zip'
        }
      ]
    },
    filters:
      !eventInfo || (eventInfo && eventInfo.required_payment_profile)
        ? Filters
        : Filters.slice(0, -1),
    actions: {
      options: [
        {
          component: EmailUsersModal,
          label: 'Email Selected',
          showOption: ['hasSelected'],
          showModal: showEmailSelectedModal,
          onClick: toggleShowEmailSelectedModal,
          onSubmit: sendEmail,
          ...selectAllProps
        },
        {
          component: AddRankModal,
          label: 'Add/Edit Rank',
          showOption: ['hasSelected'],
          showModal: showAddRankModal,
          onClick: toggleShowAddRankModal,
          onSubmit: onAddRank,
          ...selectAllProps
        },
        {
          component: RemoveUsersModal,
          label: 'Remove Selected',
          showOption: ['hasSelected'],
          showModal: showRemoveSelectedModal,
          onClick: toggleShowRemoveSelectedModal,
          onSubmit: onRemoveSelected,
          ...selectAllProps
        },
        {
          component: ImportModal,
          label: `Import New Officials`,
          showOption: [],
          showModal: showImportModal,
          onClick: toggleShowImportModal,
          onSubmit: onUserImport
        },
        {
          component: AddNewUserModal,
          label: `Add New Official`,
          showOption: [],
          showModal: showAddNewUserModal,
          onClick: toggleAddNewUserModal,
          onSubmit: onAddUserManually
        },
        {
          component: CopyUsersModal,
          label: 'Copy Selected',
          showOption: ['hasSelected'],
          showModal: showCopyUserModal,
          onClick: toggleShowCopyUserModal,
          onSubmit: copyEventUsers,
          events,
          filteredEvents,
          ...selectAllProps
        },
        {
          component: UserCategoryAssignorModal,
          label: `Assign Categories`,
          showOption: ['hasSelected'],
          showModal: showCategoryAssignorModal,
          onClick: toggleCategoryAssignorModal,
          onSubmit: assignCategoriesToUsers,
          categories,
          ...selectAllProps
        },
        {
          label: 'Download List',
          showOption: [],
          showModal: false,
          onClick: downloadCSV
        }
      ]
    }
  };
};
