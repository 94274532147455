import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  ButtonWrapper,
  SubSectionTitle,
  FieldWrapper,
  SubSectionWrapper,
  Section,
  Title,
  Line,
  BlackText,
  RadioLabel,
  RadioInput,
  BlueText
} from '../styled-components';
import TitleBar from '../../TitleBar';
import Button from '../../Button';
import formConfig from '../config';
import { Caption1 } from '../../../global-material-components';
import { allowAdminUpdatesOptions, writePermissionCheck } from '../../../../utils/helpers';
import { ON_BOARDING } from '../../../../core/paths';

const FormSubSection = ({
  title: subSectionTitle,
  rows,
  name: subSectionName,
  values,
  userIdx
}) => (
  <SubSectionWrapper xs={12} md={6}>
    {subSectionTitle && <SubSectionTitle>{subSectionTitle}</SubSectionTitle>}
    {subSectionTitle === 'Primary Address' &&
      values.primaryAddress &&
      values.primaryAddress.is_mailing && (
        <Caption1 style={{ marginLeft: '10px', marginTop: '10px' }}>
          Selected address for Mail Check payment method.{' '}
          <span>
            <Link to={`/u/${userIdx}/profile/payment-method`}>Manage.</Link>
          </span>
        </Caption1>
      )}
    {subSectionTitle === 'Travel Address' &&
      values.travelAddress &&
      values.travelAddress.is_mailing && (
        <Caption1 style={{ marginLeft: '10px', marginTop: '10px' }}>
          Selected address for Mail Check payment method.{' '}
          <span>
            <Link to={`/u/${userIdx}/profile/payment-method`}>Manage.</Link>
          </span>
        </Caption1>
      )}
    {rows.map(row => (
      <FieldWrapper custom={row.custom} key={row.fields && row.fields[0].name}>
        <Row between="xs">
          {row.fields.map(
            ({
              component,
              name,
              placeholder,
              options,
              isRequired = () => false,
              validate,
              parse,
              width = 12,
              birthday = false,
              style,
              showError,
              readOnly,
              theme
            }) => (
              <Col xs={width} md={width} key={name}>
                <Field
                  name={`${subSectionName}.${name}`}
                  component={component}
                  placeholder={!!placeholder && placeholder + (isRequired(values) ? '*' : '')}
                  options={options}
                  validate={validate}
                  parse={parse}
                  birthday={birthday}
                  style={style}
                  showError={showError}
                  readOnly={readOnly}
                  theme={theme}
                />
              </Col>
            )
          )}
        </Row>
      </FieldWrapper>
    ))}
  </SubSectionWrapper>
);

FormSubSection.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(Object),
  name: PropTypes.string.isRequired,
  values: PropTypes.shape({
    personal: PropTypes.object
  }).isRequired
};

FormSubSection.defaultProps = {
  title: '',
  rows: []
};

const FormSection = ({ title, subSections, formConditions, values, userIdx }) => {
  return (
    <>
      <TitleBar margin="10px 0px 0px 0px">{title}</TitleBar>
      <Row>
        {subSections.map(
          subSection =>
            (!subSection.conditions || subSection.conditions.every(c => formConditions[c])) && (
              <FormSubSection
                userIdx={userIdx}
                key={subSection.title}
                values={values}
                {...subSection}
              />
            )
        )}
      </Row>
    </>
  );
};

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  subSections: PropTypes.arrayOf(Object),
  formConditions: PropTypes.shape({
    copyPrimaryAddress: PropTypes.bool,
    requiresParental: PropTypes.bool
  }),
  values: PropTypes.shape({
    personal: PropTypes.object
  }).isRequired
};

FormSection.defaultProps = {
  subSections: [],
  formConditions: {
    copyPrimaryAddress: false,
    requiresParental: false
  }
};

const ProfileInformationForm = ({
  onSubmit,
  initialValues,
  buttonLabel,
  userIdx,
  width,
  allowAdminUpdates: initialAllowAdminUpdates,
  isSuperAdmin,
  currentUserId,
  personalInfoAccessPermissions,
  userId,
  path
}) => {
  const [allowAdminUpdates, setAllowAdminUpdates] = useState(
    initialAllowAdminUpdates !== undefined ? initialAllowAdminUpdates : true
  );
  return (
    <Form
      onSubmit={values => {
        const updatedValues = { ...values };
        updatedValues.profile.allow_admin_updates = allowAdminUpdates;
        onSubmit(updatedValues);
      }}
      initialValues={{ ...initialValues, allow_admin_updates: allowAdminUpdates }}
      render={({ handleSubmit, invalid, values }) => (
        <form onSubmit={handleSubmit}>
          {path && path !== ON_BOARDING && (
            <Section>
              <Title>ADMIN UPDATES</Title>
              <Line>
                <BlackText>
                  Permit Administrators to update information on your behalf
                  {allowAdminUpdatesOptions.map(option => (
                    <RadioLabel key={option.value}>
                      <RadioInput
                        type="radio"
                        name="allowAdminUpdates"
                        value={option.value}
                        checked={allowAdminUpdates === option.value}
                        onChange={() => setAllowAdminUpdates(option.value)}
                      />
                      {option.name}
                    </RadioLabel>
                  ))}
                </BlackText>
              </Line>
              <Line>
                <BlueText>
                  Updates are limited to “Personal Info”, “Certifications” and “Availability”
                  sections.
                </BlueText>
              </Line>
            </Section>
          )}

          {formConfig.map(({ title, subSections }) => (
            <FormSection
              key={title}
              title={title}
              allowAdminUpdates={allowAdminUpdates}
              subSections={subSections}
              values={values}
              userIdx={userIdx}
              formConditions={{
                copyPrimaryAddress:
                  !values.primaryAddress || !values.primaryAddress.makeTravelAddress,
                requiresParental:
                  values.profile &&
                  values.profile.dob &&
                  moment(values.profile.dob, 'YYYY-MM-DD').isAfter(moment().subtract(18, 'years'))
              }}
            />
          ))}

          <ButtonWrapper center="xs">
            <Button
              type="submit"
              width={width || '144px'}
              height="32px"
              label={buttonLabel}
              disabled={
                invalid ||
                !writePermissionCheck(
                  userId,
                  currentUserId,
                  isSuperAdmin,
                  personalInfoAccessPermissions,
                  initialAllowAdminUpdates
                )
              }
              primary
            />
          </ButtonWrapper>
        </form>
      )}
    />
  );
};

ProfileInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    profile: PropTypes.object.isRequired,
    emergencyInfo: PropTypes.object,
    primaryAddress: PropTypes.object,
    travelAddress: PropTypes.object,
    primaryContact: PropTypes.object.isRequired,
    parentGuardianContact: PropTypes.object.isRequired
  }).isRequired,
  buttonLabel: PropTypes.string
};

ProfileInformationForm.defaultProps = {
  buttonLabel: 'Submit'
};

export default ProfileInformationForm;
