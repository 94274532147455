import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const FieldInput = styled.input`
  box-sizing: border-box;
  color: ${COLORS.denimBlue};
  border: solid 1px ${COLORS.spindle};
  width: ${pxToRem('67px')};
  height: 1.5rem;
  text-align: right;
  padding-right: ${pxToRem('10px')};
`;

export const FieldError = styled.p`
  width: 100%;
  position: absolute;
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: ${COLORS.venetianRed};
  margin: 0;
  padding: ${({ paddingLeft }) => `${pxToRem('10px')} 0 0 ${pxToRem(paddingLeft)}`};
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  &.push-down {
    margin-top: 0.75rem;
  }
  &.push-down-more {
    margin-top: 1.5rem;
  }
`;
