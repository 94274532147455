import React from 'react';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { Container, MTextArea, HelpText } from './styled-components';

export const TextArea = ({
  placeholder,
  rows,
  input,
  readOnly,
  resize,
  helpText,
  isDisabled,
  customStyle,
  helpTextStyle,
  containerStyle
}) => (
  <Container style={containerStyle}>
    {helpText && <HelpText style={helpTextStyle}>{helpText}</HelpText>}
    <MTextArea
      placeholder={placeholder}
      rows={rows}
      {...input}
      readOnly={readOnly}
      resize={resize}
      disabled={isDisabled}
      customStyle={customStyle}
    />
  </Container>
);

TextArea.propTypes = {
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  isDisabled: PropTypes.bool,
  rows: PropTypes.number,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func
  }),
  readOnly: PropTypes.bool,
  resize: PropTypes.oneOf(['both', 'horizontal', 'inherit', 'initial', 'none', 'vertical'])
};

TextArea.defaultProps = {
  placeholder: '',
  helpText: null,
  isDisabled: false,
  rows: 5,
  input: {
    value: '',
    onChange: always(undefined)
  },
  readOnly: false,
  resize: 'both'
};
export default TextArea;
