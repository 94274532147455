import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects';
import { compose } from 'ramda';
import moment from 'moment';
import Api from '../../utils/api-wrapper';
import { errorProgressDecorate, errorDecorate } from '../Progress/helper';
import {
  CREATE_USER_AVAILABILITY,
  setAvailability,
  setAvailabilities,
  updateAvailability,
  FETCH_USER_AVAILABILITIES,
  UPDATE_USER_AVAILABILITY,
  FETCH_USER_ASSIGNMENTS,
  setAssignments,
  setIsFetching,
  setFetching,
  DELETE_USER_AVAILABILITY,
  setPeopleEventId,
  STORE_PEOPLE_EVENT,
  FETCH_USER_EVENT_SETTINGS,
  FETCH_ACTIVE_USER_INFO,
  setUserEventSettings,
  SET_ACTIVE_USER_INFO,
  ASSIGNMENTS,
  PROFILE_META,
  FETCH_USER_TAX_INFO,
  setUserTaxInfo,
  CREATE_USER_TAX_INFO,
  UPDATE_USER_TAX_INFO,
  DELETE_USER_ROLE,
  FETCH_USER_PROFILE_META,
  setUserProfileMeta,
  FETCH_USER_SCHEDULE,
  setUserSchedule,
  USERSCHEDULE,
  FETCH_USER_SELF_ASSIGN_GAMES,
  SELFASSIGNGAMES,
  setUserSelfAssignGames,
  FETCH_USER_AVAILABILITY_NOTE,
  AVAILABILITYNOTE,
  setUserAvailabilityNote,
  FETCH_UPCOMING_GAMES,
  UPCOMINGGAMES,
  setUpcomingGames,
  UPCOMINGASSIGNMENTS,
  setUpcomingAssignments,
  FETCH_UPCOMING_ASSIGNMENTS,
  setUserSelfAssignmentSummaryAction,
  FETCH_USER_SELF_ASSIGNMENT_SUMMARY,
  removeUserSelfAssignGames,
  updateSummary
} from './index';
import { addNotifications } from '../Notifications';
import { getActiveUser, getUser } from '../Auth/selectors';
import { mergeData } from '../../utils/parsers';
import { userIdxGetter, setPeopleEvent } from '../../utils/auth';
import { fetchPaymentHistory, fetchPaymentMethods } from '../Payments/sagas';
import { getPayeeAccounts } from '../Auth/sagas';
import { fetchGroupInfo, setIsFetchingFlag, setIsFetchingFlagPayee } from '../Auth';
import { history } from '../store';
import * as paths from '../paths';
import { sInterpolator } from '../../utils/helpers';
import { DATE_FORMATS, SELF_ASSIGNMENT_SUMMARY_ACTIONS } from '../../constants';

const AVAILABILITY_TYPES = {
  available: 'availability',
  unavailable: 'unavailability'
};

export function* processFetchUserAvailabilities(data = { payload: {} }) {
  const { payload } = data;
  const { id: user_id } = yield select(getUser);
  const { id: active_user_id } = yield select(getActiveUser);
  const response = yield call(Api.fetchAvailabilities, {
    user_id: payload.paramId || active_user_id || user_id
  });
  yield put(compose(setAvailabilities, mergeData)(response));
}

export function* fetchUserAvailabilities(payload) {
  yield call(errorProgressDecorate, processFetchUserAvailabilities, payload, setIsFetching);
}

export function* processCreateUserAvailability({ payload: data }) {
  const { id: user_id } = yield select(getUser);
  const { id: active_user_id } = yield select(getActiveUser);
  const response = yield call(Api.createAvailability, {
    data: {
      user_id: data.paramId || active_user_id || user_id,
      ...data
    }
  });

  yield put(compose(setAvailability, mergeData)(response));
  yield put(
    addNotifications([
      {
        type: 'success',
        message: `Successfully created ${AVAILABILITY_TYPES[data.availability_type]}`
      }
    ])
  );
}

export function* createUserAvailability(params) {
  yield call(errorProgressDecorate, processCreateUserAvailability, params, setIsFetching);
}

export function* processUpdateUserAvailability({ payload: { id, data } }) {
  const response = yield call(Api.updateAvailability, {
    id,
    data
  });

  yield put(compose(updateAvailability, mergeData)(response));
  yield put(
    addNotifications([
      {
        type: 'success',
        message: `Successfully updated ${AVAILABILITY_TYPES[data.availability_type]}`
      }
    ])
  );
}

export function* updateUserAvailability(params) {
  yield call(errorProgressDecorate, processUpdateUserAvailability, params, setIsFetching);
}

export function* processFetchUserAssignments({ payload: { start_date, end_date, payment } }) {
  yield put(setFetching(ASSIGNMENTS, true));
  const { id } = yield select(getUser);
  const response = yield call(Api.fetchUserAssignments, {
    id,
    start_date: moment.utc(start_date).format(DATE_FORMATS.YYYY_MM_DD_HH_mm),
    end_date: moment.utc(end_date).add(1, 'day').format(DATE_FORMATS.YYYY_MM_DD_HH_mm),
    payment
  });
  yield put(setAssignments(response.data));
  yield put(setFetching(ASSIGNMENTS, false));
}

export function* fetchUserAssignments(params) {
  yield call(errorProgressDecorate, processFetchUserAssignments, params);
}

export function* processFetchUserSelfAssignmentSummary({
  payload: { userId, filters, sortingValue, timezone, games_for_date }
}) {
  const response = yield call(Api.fetchSelfAssignmentSummary, {
    userId,
    filters,
    sortingValue,
    timezone,
    games_for_date
  });
  // Added this to remove the days that are in the past when timezones are different
  // Parse the start_date from filters
  const startDateMoment = moment(filters.start_date);
  const currentMoment = moment();

  // Check if start_date is in the current month and year
  if (
    startDateMoment.month() === currentMoment.month() &&
    startDateMoment.year() === currentMoment.year()
  ) {
    // Filter out days in the past for the current month
    response.data.summaryData = response.data.summaryData.filter(
      day => day >= currentMoment.date()
    );
  }
  yield put(setUserSelfAssignmentSummaryAction(response.data));
}

export function* fetchUserSelfAssignmentSummarySaga(params) {
  yield call(errorProgressDecorate, processFetchUserSelfAssignmentSummary, params);
}

export function* processDeleteUserAvailability({ payload: { id, onClose } }) {
  yield call(Api.deleteAvailability, {
    id
  });
  yield call(onClose);
  yield call(fetchUserAvailabilities);
  yield put(
    addNotifications([
      {
        type: 'success',
        message: 'Successfully deleted unavailability'
      }
    ])
  );
}

export function* deleteUserAvailability(params) {
  yield call(errorDecorate, processDeleteUserAvailability, params);
}

export function* storePeopleEvent({ payload: { id, usrIdx = userIdxGetter() } }) {
  yield call(setPeopleEvent, { id, usrIdx });
  yield put(setPeopleEventId(id));
}

export function* processFetchUserEventSettings() {
  const { id } = yield select(getUser);
  const response = yield call(Api.fetchUserEventSettings, {
    id
  });
  yield put(setUserEventSettings(response));
}

export function* fetchUserEventSettings(params) {
  yield call(errorProgressDecorate, processFetchUserEventSettings, params, setIsFetching);
}

export function* processfetchActiveUserInfo({ payload: { userId, month, year, pathUrl } }) {
  try {
    yield put(setIsFetchingFlag(true));
    yield put(setIsFetchingFlagPayee(true));
    const {
      data: { attributes, id }
    } = yield call(Api.fetchActiveUserInfo, {
      userId
    });
    yield put({ type: SET_ACTIVE_USER_INFO, payload: { ...attributes, id } });
    if (
      pathUrl === paths.PROFILE_PAYMENT_METHOD ||
      pathUrl === paths.START_PROFILE_PAYMENT_METHOD ||
      pathUrl === paths.TAX_INFO
    ) {
      yield put(setIsFetchingFlagPayee(false));
      yield call(fetchPaymentMethods, {
        userId
      });
      yield call(getPayeeAccounts);
    }
    if (pathUrl === paths.PROFILE_PAYMENT_HISTORY) {
      if (month && year) {
        yield put(setIsFetchingFlagPayee(false));
        yield call(fetchPaymentHistory, {
          month,
          year
        });
      }
    }
    if (pathUrl === paths.PROFILE_AVAILABILITY) {
      yield put(setIsFetchingFlagPayee(false));
    }
    yield put(setIsFetchingFlag(false));
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(setIsFetchingFlag(false));
    yield put(setIsFetchingFlagPayee(false));
  }
}

export function* fetchActiveUserInfo(payload) {
  yield call(errorProgressDecorate, processfetchActiveUserInfo, payload, setIsFetching);
}

export function* processFetchUserTaxInfo({ payload: { userId } }) {
  const { id: user_id } = yield select(getUser);
  const { id: active_user_id } = yield select(getActiveUser);
  const response = yield call(Api.fetchUserTaxInfo, {
    user_id: userId || active_user_id || user_id
  });
  yield put(setUserTaxInfo(response.data[0]));
}

export function* fetchUserTaxInfo(payload) {
  yield call(errorProgressDecorate, processFetchUserTaxInfo, payload, setIsFetching);
}

export function* processFetchUserProfileMeta() {
  yield put(setFetching(PROFILE_META, true));
  const { id: user_id } = yield select(getUser);
  const { id: active_user_id } = yield select(getActiveUser);
  const response = yield call(Api.fetchUserProfileMeta, {
    user_id: active_user_id || user_id
  });
  yield put(compose(setUserProfileMeta, mergeData)(response));
  yield put(setFetching(PROFILE_META, false));
}

export function* fetchUserProfileMeta(payload) {
  yield call(errorProgressDecorate, processFetchUserProfileMeta, payload);
}

export function* processCreateUserTaxInfo({ payload: data }) {
  const { id: active_user_id } = yield select(getActiveUser);
  const { id: user_id } = yield select(getUser);
  const userId = active_user_id || user_id;
  const response = yield call(Api.createTaxInfo, {
    data: {
      user_id: userId,
      ...data
    },
    user_id: userId
  });
  yield put(setUserTaxInfo(response.data[0]));
  yield call(fetchUserProfileMeta, { payload: { userId } });
  yield put(
    addNotifications([
      {
        type: 'success',
        message: 'Successfully created tax info'
      }
    ])
  );

  if (sInterpolator({ userIdx: userIdxGetter(), userId }, paths.START_TAX_INFO) === data.path) {
    yield call(history.push, sInterpolator({ userIdx: userIdxGetter() }, paths.MAIN));
  }
  yield call(fetchUserTaxInfo, { payload: { userId } });
}

export function* createUserTaxInfo(params) {
  yield call(errorProgressDecorate, processCreateUserTaxInfo, params, setIsFetching);
}

export function* processUpdateUserTaxInfo({ payload: data }) {
  const { id: user_id } = yield select(getUser);
  const { id: active_user_id } = yield select(getActiveUser);
  const userId = active_user_id || user_id;
  const response = yield call(Api.updateTaxInfo, {
    data: {
      user_id: userId,
      ...data
    },
    user_id: userId
  });
  yield put(setUserTaxInfo(response.data[0]));
  yield put(
    addNotifications([
      {
        type: 'success',
        message: 'Successfully Updated tax info'
      }
    ])
  );
  if (sInterpolator({ userIdx: userIdxGetter(), userId }, paths.START_TAX_INFO) === data.path) {
    yield call(history.push, sInterpolator({ userIdx: userIdxGetter() }, paths.MAIN));
  }
  yield call(fetchUserTaxInfo, { payload: { userId } });
}

export function* updateUserTaxInfo(params) {
  yield call(errorProgressDecorate, processUpdateUserTaxInfo, params, setIsFetching);
}

export function* deleteUserRole({ payload: { userId, roleId, isFetchGroupInfo, groupId } }) {
  try {
    const response = yield call(Api.deleteUserRole, { userId, roleId, groupId });
    if (response && response.message) {
      yield put(
        addNotifications([{ type: response.type || 'success', message: response.message }])
      );
    }
    if (isFetchGroupInfo) {
      yield put(fetchGroupInfo({ groupId }));
    }
  } catch (err) {
    yield put(addNotifications(err.message));
  }
}

export function* processFetchUserScheduleSaga({ payload: { userId, start_date, end_date } }) {
  yield put(setFetching(USERSCHEDULE, true));
  const response = yield call(Api.fetchUserSchedule, {
    userId,
    filters: {
      start_date: moment.utc(start_date).format(DATE_FORMATS.YYYY_MM_DD_HH_mm),
      end_date: moment.utc(end_date).add(1, 'day').format(DATE_FORMATS.YYYY_MM_DD_HH_mm)
    }
  });
  yield put(compose(setUserSchedule, mergeData)(response));
  yield put(setFetching(USERSCHEDULE, false));
}

export function* fetchUserScheduleSaga(payload) {
  yield call(errorProgressDecorate, processFetchUserScheduleSaga, payload);
}

export function* processfetchUserSelfAssignGames({
  payload: { userId, filters, sortingValue, supress_event_data }
}) {
  yield put(setFetching(SELFASSIGNGAMES, true));
  const response = yield call(Api.fetchUserSelfAssignGames, {
    userId,
    filters,
    sortingValue,
    supress_event_data
  });

  const day = filters && filters.start_date && moment(filters.start_date).date();
  if (day) {
    yield put(removeUserSelfAssignGames({ games_day_to_remove: day }));
    const summary_action =
      response.data && response.data.games && response.data.games.length > 0
        ? SELF_ASSIGNMENT_SUMMARY_ACTIONS.ADD
        : SELF_ASSIGNMENT_SUMMARY_ACTIONS.REMOVE;
    yield put(updateSummary({ summary_action, day }));
  }

  yield put(setUserSelfAssignGames(response.data));
  yield put(setFetching(SELFASSIGNGAMES, false));
}

export function* fetchUserSelfAssignGames(payload) {
  yield call(errorProgressDecorate, processfetchUserSelfAssignGames, payload);
}

export function* fetchUserAvailabilityNote({ payload: { id, target } }) {
  yield put(setFetching(AVAILABILITYNOTE, true));
  try {
    yield put(setUserAvailabilityNote({ target }));
    const response = yield call(Api.fetchAvailabilityNote, { id });
    if (response) {
      yield put(setUserAvailabilityNote({ ...response, target }));
    }
  } catch (err) {
    if (err.status !== '404') yield put(addNotifications(err.message));
    yield put(setUserAvailabilityNote({ target }));
  }
  yield put(setFetching(AVAILABILITYNOTE, false));
}

export function* fetchUpcomingGames({ payload: { userId, filters } }) {
  try {
    yield put(setFetching(UPCOMINGGAMES, true));
    const response = yield call(Api.fetchUpcomingGames, { userId, filters });
    if (response) {
      yield put(setUpcomingGames(mergeData(response)));
    }
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setFetching(UPCOMINGGAMES, false));
  }
}

export function* fetchUpcomingAssignmentsSaga({ payload: { userId, filters } }) {
  try {
    yield put(setFetching(UPCOMINGASSIGNMENTS, true));
    const response = yield call(Api.fetchUpcomingGames, { userId, filters });
    if (response) {
      yield put(setUpcomingAssignments(mergeData(response)));
    }
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setFetching(UPCOMINGASSIGNMENTS, false));
  }
}

export const UserSagas = [
  takeLatest(CREATE_USER_AVAILABILITY, createUserAvailability),
  takeLatest(FETCH_USER_AVAILABILITIES, fetchUserAvailabilities),
  takeLatest(UPDATE_USER_AVAILABILITY, updateUserAvailability),
  takeLatest(FETCH_USER_ASSIGNMENTS, fetchUserAssignments),
  takeLatest(DELETE_USER_AVAILABILITY, deleteUserAvailability),
  takeEvery(STORE_PEOPLE_EVENT, storePeopleEvent),
  takeLatest(FETCH_USER_EVENT_SETTINGS, fetchUserEventSettings),
  takeLatest(FETCH_ACTIVE_USER_INFO, fetchActiveUserInfo),
  takeLatest(FETCH_USER_TAX_INFO, fetchUserTaxInfo),
  takeLatest(CREATE_USER_TAX_INFO, createUserTaxInfo),
  takeLatest(UPDATE_USER_TAX_INFO, updateUserTaxInfo),
  takeLatest(DELETE_USER_ROLE, deleteUserRole),
  takeLatest(FETCH_USER_PROFILE_META, fetchUserProfileMeta),
  takeLatest(FETCH_USER_SCHEDULE, fetchUserScheduleSaga),
  takeLatest(FETCH_USER_SELF_ASSIGN_GAMES, fetchUserSelfAssignGames),
  takeLatest(FETCH_USER_AVAILABILITY_NOTE, fetchUserAvailabilityNote),
  takeLatest(FETCH_UPCOMING_GAMES, fetchUpcomingGames),
  takeLatest(FETCH_UPCOMING_ASSIGNMENTS, fetchUpcomingAssignmentsSaga),
  takeLatest(FETCH_USER_SELF_ASSIGNMENT_SUMMARY, fetchUserSelfAssignmentSummarySaga)
];

export default UserSagas;
