import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Switcher = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ theme }) => theme};
`;

export const Label = styled.div`
  font-size: ${pxToRem('13px')};
  font-stretch: condensed;
  text-align: center;
  color: ${COLORS.shuttleGrey};
  margin-right: 0.8rem;

  ${({ theme }) => theme};
`;

export const OnLabel = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  border-radius: 4px;
  padding: ${pxToRem('3px')} ${pxToRem('13px')};
  font-size: ${pxToRem('12px')};
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: ${pxToRem('0.2px')};

  ${({ switchState, theme: { onState, offState } }) =>
    switchState
      ? `
    box-shadow: ${pxToRem('1px')} ${pxToRem('1px')} ${pxToRem('2px')} 0 rgba(0, 0, 0, 0.38);
    border: solid ${pxToRem('0.5px')} transparent;
    background-image: linear-gradient(to bottom, ${COLORS.kellyGreen}, ${
          COLORS.forestGreen
        }), linear-gradient(to top, ${COLORS.kellyGreen}, ${COLORS.forestGreen});
    background-origin: border-box;
    color: ${COLORS.white};
    z-index:2;
    text-shadow: ${pxToRem('1px')} ${pxToRem('1px')} ${pxToRem('1px')} rgba(0, 0, 0, 0.2);

    ${onState && onState}
      `
      : `
    background-image: linear-gradient(to bottom, ${COLORS.white}, ${COLORS.blackSqueeze});
    box-shadow: inset ${pxToRem('1px')} ${pxToRem('1px')} ${pxToRem(
          '3px'
        )} 0 rgba(154, 163, 174, 0.66);
    opacity: 0.32;
    letter-spacing: normal;
    color: ${COLORS.shuttleGrey};
    z-index:1;
    padding: ${pxToRem('3px')} ${pxToRem('24px')} ${pxToRem('3px')} ${pxToRem('12px')};

    font-size: ${pxToRem('13px')}};
    ${offState && offState}
    `};
`;

export const OffLabel = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  border-radius: ${pxToRem('4px')};
  padding: ${pxToRem('3px')} ${pxToRem('13px')};
  font-size: ${pxToRem('12px')};
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: ${pxToRem('0.2px')};
  right: ${pxToRem('14px')};

  ${({ switchState, theme: { onState, offState } }) =>
    switchState
      ? `
    border-radius: ${pxToRem('4px')};
    box-shadow: ${pxToRem('1px')} ${pxToRem('1px')} ${pxToRem('2px')} 0 rgba(0, 0, 0, 0.38);
    background-image: linear-gradient(to bottom, ${COLORS.wildWatermelon}, ${COLORS.carmine});
    border: solid ${pxToRem('0.5px')} ${COLORS.venetianRed};
    z-index:2;
    padding: ${pxToRem('3px')} ${pxToRem('7.5px')} ${pxToRem('3px')} ${pxToRem('7.5px')};
    color: ${COLORS.white};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

    ${onState && onState}
    `
      : `
    background-image: linear-gradient(to bottom, ${COLORS.white}, ${COLORS.blackSqueeze});
    box-shadow: inset ${pxToRem('1px')} ${pxToRem('1px')} ${pxToRem(
          '3px'
        )} 0 rgba(154, 163, 174, 0.66);
    opacity: 0.32;
    letter-spacing: normal;
    color: ${COLORS.shuttleGrey};
    padding: ${pxToRem('3px')} ${pxToRem('7.5px')} ${pxToRem('3px')} ${pxToRem('20.5px')};
    z-index:1;

    font-size: ${pxToRem('13px')};
    ${offState && offState}
    `};
`;
