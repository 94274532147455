import { call, put, takeLatest, select } from 'redux-saga/effects';
import { isEmpty } from 'ramda';
import Logger from '../../utils/logger';
import { FETCH_ROLES, setRoles, FETCH_FUNDING_ADMINS, setFundingAdmins } from './index';
import Api from '../../utils/api-wrapper';
import { addNotifications } from '../Notifications';
import { getPaymentsEventId } from '../Payments/selectors';

export function* fetchRoles() {
  try {
    const response = yield call(Api.fetchRoles);

    if (!isEmpty(response)) {
      yield put(setRoles(response));
    }
  } catch (error) {
    Logger.error(error.message);
    yield put(setRoles([]));
  }
}

export function* fetchFundingAdmins() {
  const eventId = yield select(getPaymentsEventId);
  try {
    const response = yield call(Api.fetchFundingAdmins, { id: eventId });

    if (!isEmpty(response)) {
      yield put(setFundingAdmins(response));
    }
  } catch (error) {
    Logger.error(error.message);
    yield put(addNotifications(error));
    yield put(setFundingAdmins([]));
  }
}

export const roleSagas = [
  takeLatest(FETCH_ROLES, fetchRoles),
  takeLatest(FETCH_FUNDING_ADMINS, fetchFundingAdmins)
];

export default roleSagas;
