import styled from 'styled-components';
import { pxToRem } from '../../../../utils/helpers';

export const Header = styled.div`
  min-height: ${pxToRem('32px')};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: center;
  padding-left: 0;
  padding-right: ${pxToRem('10px')};
  background: ${props => props.theme.sorterHeaderBg || 'inherit'};
  color: ${props => props.theme.sorterLabelColor};
`;

export default Header;
