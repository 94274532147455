import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/helpers';
import { COLORS } from '../../../../../utils/styles';

export const Container = styled.div`
  min-width: ${props => `${props.minWidth}px`};
  position: relative;
  font-size: ${pxToRem('13px')};
  font-stretch: condensed;
  font-family: 'RobotoCondensed', sans-serif;
  z-index: 99;

  &:focus {
    outline: 0;
  }
`;

export const Header = styled.div`
  min-height: ${pxToRem('32px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${pxToRem('16px')};
  padding-right: ${pxToRem('10px')};
  background: ${props => props.theme.sorterHeaderBg || 'inherit'};
  color: ${props => props.theme.sorterLabelColor};
`;

export const SortIconContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Arrow = styled.span`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  cursor: pointer;

  ${SortIconContainer} & {
    &:first-child {
      margin-bottom: 1.5px;
    }

    &:last-child {
      margin-top: 1.5px;
    }
  }
`;

export const ArrowUp = Arrow.extend`
  border-bottom: 6px solid;
  border-bottom-color: ${props => (props.isActive ? COLORS.denimBlue : COLORS.greyChateau)};
`;

export const ArrowDown = Arrow.extend`
  border-top: 6px solid;
  border-top-color: ${props => (props.isActive ? COLORS.denimBlue : COLORS.greyChateau)};
`;
