import styled from 'styled-components';

import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MainWrapper = styled.div`
  padding-bottom: 35px;
  .paymentInfo {
    width: 40px;
    padding: 0px 17px;
    margin-top: -6px;
  }
`;

export const MinMaxWarning = styled.span`
  color: ${COLORS.errorRed};
`;

export const EmptyLine = styled.br``;

export default FlexRow;

export const PeopleConatiner = styled.div`
  font-size: ${pxToRem('13px')};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const Field = styled.div`
  display: flex;
  flex: ${({ flex }) => flex || 4};
  font-family: 'RobotoCondensed';
  font-size: 12px;
  color: ${COLORS.nightRider};
  padding: 0 0.25rem;

  ${({ originalCased }) => !originalCased && `text-transform: capitalize;`};
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent}` : '')};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : '')};
  ${({ header }) => (header ? `color: ${COLORS.white}` : '')};
  ${({ clickable }) =>
    clickable
      ? `
    color: ${COLORS.denimBlue};
    text-decoration: underline;
    cursor: pointer;
  `
      : ''};
  ${({ fullHeight }) => (fullHeight ? 'height: 100%' : '')};

  ${({ theme }) => theme || ''};
  ${({ isEventName }) => isEventName && `height: 90%; overflow: auto; align-items: center`}
  ${({ isEventName }) =>
    isEventName
      ? `
  &::-webkit-scrollbar {
    width: ${pxToRem('7px')};
    height: ${pxToRem('5px')};
  }

  &::-webkit-scrollbar-thumb {
    height: ${pxToRem('50px')};
    background: ${COLORS.gray};
  }

  &::-webkit-scrollbar-track-piece {
    background: ${COLORS.lightGray};
  }

  &.side-list::-webkit-scrollbar-track-piece {
    background: ${COLORS.gray};
  }`
      : ''}
`;

export const Checkbox = styled.input`
  display: flex;
  flex: 1;
`;

export const EmptyMessage = styled.p`
  margin: ${pxToRem('23px')} 0 0 ${pxToRem('34px')};
  font-family: 'RobotoLight';
  font-size: 1rem;
  line-height: 1.06;
  color: ${COLORS.shuttleGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
