import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, head, defaultTo, equals, prop, contains } from 'ramda';
import { ThemeProvider } from 'styled-components';
import Icon from '../Icon';
import { COLORS, ICONS } from '../../../utils/styles';
import {
  Container,
  Header,
  LabelContainer,
  Label,
  SortIconContainer,
  ArrowUp,
  ArrowDown,
  Content,
  OptionList,
  Option,
  OptionLabel,
  ClearButtonContainer,
  ClearButton
} from './styled-components';
import ShowHide from '../../controls/ShowHide';

const maybeValue = defaultTo('');
const ascDesc = value => (contains('-', maybeValue(value)) ? '-' : '');

const selectedOptionLabel = ({ options, value }) =>
  head(
    options
      .filter(({ name }) => equals(name, maybeValue(value).replace(/-/g, '')))
      .map(prop('label'))
  );

const handleSort = (name, order = '') =>
  name
    .split(',')
    .map(v => `${order}${maybeValue(v).replace('-', '')}`)
    .join(',');

const Sorter = ({
  label,
  minWidth,
  onSort,
  options,
  setVisibility,
  theme,
  toggleVisibility,
  value,
  isVisible
}) => {
  const isOptionSelected = !isEmpty(maybeValue(value));

  return (
    <ThemeProvider theme={theme}>
      <Container tabIndex={-1} onBlur={() => setVisibility(false)} minWidth={minWidth}>
        <Header>
          <LabelContainer onClick={toggleVisibility}>
            <Label>{isOptionSelected ? selectedOptionLabel({ options, value }) : label}</Label>
            <Icon
              height={11}
              width={11}
              icon={ICONS.BOLD_CHEVRON_DOWN}
              color={theme.chevronDownColor}
            />
          </LabelContainer>
          {isOptionSelected && (
            <SortIconContainer>
              <ArrowUp
                isActive={isEmpty(ascDesc(value))}
                onClick={() => {
                  let sort = maybeValue(value).replace('-', '');
                  if (value && value.includes(',')) {
                    sort = handleSort(value);
                  }
                  return onSort(sort);
                }}
              />
              <ArrowDown
                isActive={!isEmpty(ascDesc(value))}
                onClick={() => {
                  let sort = `-${maybeValue(value).replace('-', '')}`;
                  if (value && value.includes(',')) {
                    sort = handleSort(value, '-');
                  }
                  return onSort(sort);
                }}
              />
            </SortIconContainer>
          )}
        </Header>
        <Content isVisible={isVisible} minWidth={minWidth}>
          <OptionList>
            {options.map(option => (
              <Option
                key={option.name}
                disabled={option.disabled === true}
                onMouseDown={event => {
                  if (option.disabled) {
                    event.preventDefault();
                    event.stopPropagation(); // This prevents the event from propagating to parent elements
                    return;
                  }

                  if (option && option.name && option.name.includes(',')) {
                    if (ascDesc(value) === '-') {
                      const sort = handleSort(option.name, '-');
                      toggleVisibility();
                      onSort(sort);
                      return;
                    }
                  }

                  toggleVisibility();
                  onSort(`${ascDesc(value)}${option.name}`);
                }}
              >
                <OptionLabel>{option.label}</OptionLabel>
              </Option>
            ))}
          </OptionList>
          <ClearButtonContainer>
            <ClearButton onClick={() => onSort('')}>Reset Sort</ClearButton>
          </ClearButtonContainer>
        </Content>
      </Container>
    </ThemeProvider>
  );
};

Sorter.propTypes = {
  label: PropTypes.string,
  onSort: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  minWidth: PropTypes.number,
  setVisibility: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    sorterHeaderBg: PropTypes.string,
    sorterLabelColor: PropTypes.string,
    chevronDownColor: PropTypes.string,
    optionBg: PropTypes.string,
    optionColor: PropTypes.string,
    optionHoverBg: PropTypes.string,
    optionHoverColor: PropTypes.string,
    clearButtonColor: PropTypes.string
  }),
  toggleVisibility: PropTypes.func.isRequired,
  value: PropTypes.string,
  isVisible: PropTypes.bool
};

Sorter.defaultProps = {
  label: 'Sort by',
  minWidth: 117,
  theme: {
    sorterHeaderBg: '',
    sorterLabelColor: COLORS.white,
    chevronDownColor: COLORS.white,
    optionBg: COLORS.darkCerulean,
    optionColor: COLORS.white,
    optionHoverBg: COLORS.sapphire,
    optionHoverColor: COLORS.denimBlue,
    clearButtonColor: COLORS.white
  },
  value: '',
  isVisible: false
};

export default ShowHide(Sorter);
