import { compose, pure, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import {
  createAccount as createAccountAction,
  setNoAccount as setNoAccountAction
} from '../../../core/Auth';

export default compose(
  connect(({ auth: { noAccount } }) => ({ noAccount }), {
    createAccount: createAccountAction,
    setNoAccount: setNoAccountAction
  }),
  withState('submitting', 'isSubmitting', false),
  withHandlers({
    // NOTE: This function needs to return `undefined`
    // on success or the form will keep its invalid state
    onCreateAccount: ({ isSubmitting, isSuperAdmin, createAccount }) => (
      newCredentials,
      formApi
    ) => {
      isSubmitting(true);
      new Promise(resolve =>
        createAccount({ values: { ...newCredentials, isSuperAdmin }, resolve })
      ).then(() => {
        isSubmitting(false);
        formApi.restart();
      });
    }
  }),
  pure
);
