import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import COLORS from '../../../utils/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  select: {
    fontSize: 13
  },
  input: {
    backgroundColor: 'white',
    fontSize: 13,
    padding: '0 5px',
    color: COLORS.denimBlue
  },
  menu: {
    fontSize: 13
  },
  icon: {
    color: 'lightblue'
  }
});

export default function SelectOutlined({
  id,
  value,
  onChange,
  inputLabel,
  labelWidth,
  name,
  dataOptions,
  error,
  defaultEmptyOption,
  addOptionButton,
  addOptionButtonHandler,
  inputStyle,
  placeholder,
  ...rest
}) {
  const classes = useStyles();
  const menuProps = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null,
    style: { zIndex: '9999', marginTop: '60px' }
  };
  return (
    <form className={classes.root} autoComplete="off">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel className={classes.input} htmlFor="outlined-age-simple" error={error}>
          {inputLabel}
        </InputLabel>
        <Select
          value={value}
          onChange={onChange}
          input={<OutlinedInput id={id} labelWidth={labelWidth} name={name} style={inputStyle} />}
          className={classes.select}
          IconComponent={ExpandMore}
          error={error}
          MenuProps={menuProps}
          displayEmpty
          style={{ width: '100%' }}
          defaultValue={false}
          {...rest}
        >
          {addOptionButton && (
            <MenuItem
              onClick={() => addOptionButtonHandler()}
              value={addOptionButton.value}
              className={classes.menu}
            >
              <strong>{addOptionButton.name}</strong>
            </MenuItem>
          )}
          {dataOptions.map((val, index) => (
            <MenuItem key={index} value={val.value} className={classes.menu}>
              {val.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
}

SelectOutlined.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  inputLabel: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dataOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  )
};

SelectOutlined.defaultProps = {
  id: '',
  label: '',
  inputLabel: '',
  name: '',
  dataOptions: []
};
