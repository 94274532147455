import React from 'react';
import PropTypes from 'prop-types';
import { always, isNil } from 'ramda';
import { COLORS, ICONS } from '../../../utils/styles';
import { SearchContainer, SearchField, SearchIcon } from './styled-components';
import Enhancer, { onKeyDown, onClick } from '../../containers/Search';

export const Search = ({
  name,
  placeholder,
  iconColor,
  iconWidth,
  iconHeight,
  onSearch,
  searchValue,
  onChange,
  fieldColor,
  autoComplete,
  showSearch,
  setShowSearch,
  value: initialValue,
  showSearchEvent,
  isUserAddAction
}) => (
  <SearchContainer>
    {(showSearch || searchValue || initialValue || showSearchEvent) && (
      <SearchField
        autoFocus
        autoComplete={autoComplete || 'on'}
        type="text"
        name={name}
        placeholder={placeholder}
        onKeyDown={event => onKeyDown(event, searchValue, onSearch)}
        onChange={onChange}
        value={isNil(searchValue) ? '' : searchValue}
        color={fieldColor}
        isUserAddAction={isUserAddAction}
      />
    )}
    <SearchIcon
      isUserAddAction={isUserAddAction}
      height={iconHeight}
      width={iconWidth}
      icon={ICONS.SEARCH}
      color={iconColor}
      onClick={() =>
        searchValue || initialValue ? onClick(searchValue, onSearch) : setShowSearch(s => !s)
      }
    />
  </SearchContainer>
);

Search.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  iconColor: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  onSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  onChange: PropTypes.func,
  fieldColor: PropTypes.string,
  showSearch: PropTypes.bool,
  setShowSearch: PropTypes.func.isRequired,
  value: PropTypes.string
};

Search.defaultProps = {
  name: 'search',
  placeholder: 'Search',
  iconColor: COLORS.white,
  iconWidth: 13,
  iconHeight: 13,
  searchValue: '',
  fieldColor: null,
  onChange: always(undefined),
  showSearch: false,
  value: null
};

export default Enhancer(React.memo(Search));
