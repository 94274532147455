import React from 'react';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { compose, pure, withStateHandlers } from 'recompose';
import { TableCell, TableRow } from '@material-ui/core';
import { ChevronIcon } from './styled-component';
import { ICONS, COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

const CollapsableContent = ({
  children,
  isOpen,
  setOpen,
  theme,
  fetch,
  row,
  dataKey,
  headings,
  i
}) => (
  <>
    <TableRow key={`row[${dataKey}]${i}`}>
      {Object.keys(row).map((item, index) => (
        <TableCell key={`row[${item}]${index}`}>
          {!(
            headings.find(heading => heading.id === item) &&
            headings.find(heading => heading.id === item).parser
          )
            ? row[item]
            : headings.find(heading => heading.id === item).parser(row[item])}
        </TableCell>
      ))}
      <TableCell>
        <ChevronIcon
          width={theme.iconWidth}
          height={theme.iconHeight}
          icon={isOpen ? ICONS.BOLD_CHEVRON_DOWN : ICONS.BOLD_CHEVRON_RIGHT}
          color={isOpen ? theme.iconColorWhenOpen : theme.iconColorWhenClosed}
          onClick={() => {
            fetch();
            setOpen();
          }}
        />
      </TableCell>
    </TableRow>
    {isOpen && <TableRow>{children}</TableRow>}
  </>
);

CollapsableContent.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fetch: PropTypes.func,
  theme: PropTypes.shape({
    headerBg: PropTypes.string,
    headerMinHeight: PropTypes.string,
    headerMinPaddingTop: PropTypes.string,
    iconColorWhenClosed: PropTypes.string,
    iconColorWhenOpen: PropTypes.string,
    iconHeight: PropTypes.number,
    iconWidth: PropTypes.number,
    titleColorWhenClosed: PropTypes.string,
    titleColorWhenOpen: PropTypes.string,
    titleFont: PropTypes.string,
    titleLetterSpacing: PropTypes.string,
    titleSize: PropTypes.string,
    titleTransform: PropTypes.oneOf([
      'capitalize',
      'inherit',
      'initial',
      'lowercase',
      'none',
      'uppercase'
    ]),
    titleWeight: PropTypes.oneOf([
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'bold',
      'bolder',
      'inherit',
      'initial',
      'lighter',
      'normal'
    ])
  })
};

CollapsableContent.defaultProps = {
  fetch: always(undefined),
  theme: {
    headerBg: 'transparent',
    headerMinHeight: `${pxToRem('32px')}`,
    headerMinPaddingTop: `${pxToRem('30px')}`,
    iconColorWhenClosed: COLORS.shuttleGrey,
    iconColorWhenOpen: COLORS.denimBlue,
    iconHeight: 13,
    iconWidth: 13,
    titleColorWhenClosed: COLORS.shuttleGray,
    titleColorWhenOpen: COLORS.denimBlue,
    titleFont: 'Roboto',
    titleLetterSpacing: '0.5px',
    titleSize: `${pxToRem('15px')}`,
    titleTransform: 'none',
    titleWeight: 'bold'
  }
};

export default compose(
  withStateHandlers(
    ({ externalIsOpen = false }) => ({
      isOpen: externalIsOpen
    }),
    {
      setOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen
      })
    }
  ),
  pure
)(CollapsableContent);
