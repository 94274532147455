import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationRow,
  Summary,
  OrgName,
  Chevron,
  ExpandedWrapper,
  GameDateLink,
  Details,
  GameDataRow,
  GameId,
  GameDataRowItem
} from '../styled-components';
import { ICONS, COLORS } from '../../../../utils/styles';
import { formatDate } from '../../../../utils/parsers';
import Enhancer from '../../../containers/NotificationsMenu/SubMenu/GameReportsList';
import { convertUtcToTimezone, getTimezoneAbbr } from '../../../../utils/helpers';

const NotificationExpanded = ({
  selectedNotification,
  storeReportsEventAndRedirect,
  closeMenu
}) => {
  const { id: notificationId, game: gameObj } = selectedNotification;
  const gameDate =
    gameObj &&
    formatDate(
      convertUtcToTimezone(`${gameObj.start_date} ${gameObj.start_time}`, gameObj.timezone),
      'ddd - MM/DD/YYYY @ h:mm A'
    );
  const tzAbbr =
    gameObj && getTimezoneAbbr(formatDate(gameDate, 'MM/DD/YYYY hh:mm A'), gameObj.timezone);

  return (
    <ExpandedWrapper flexDirection="column">
      {selectedNotification && gameObj && (
        <>
          <Details>
            <GameDateLink
              onClick={e => {
                closeMenu(e);
                storeReportsEventAndRedirect({
                  notificationId,
                  id: gameObj.game_level.event_id.toString(),
                  gameId: gameObj.display_id
                });
              }}
            >
              {`${gameDate} (${tzAbbr})`}
            </GameDateLink>
            <GameDataRow>
              <GameId>{gameObj.display_id || gameObj.id} -</GameId>
              {gameObj.field ? (
                <GameDataRowItem textBold>
                  {gameObj.field.location.name} - {gameObj.field.name} -{' '}
                  {gameObj.field.location.city}, {gameObj.field.location.state}
                </GameDataRowItem>
              ) : (
                <GameDataRowItem textBold>
                  {gameObj.location.name}
                  {gameObj.location.city
                    ? `${gameObj.field_name ? ' - ' : ''}${gameObj.field_name || ''} - ${
                        gameObj.location.city
                      }, ${gameObj.location.state}`
                    : ''}
                </GameDataRowItem>
              )}
            </GameDataRow>
            <GameDataRow>
              <GameDataRowItem>
                {gameObj.team_visitor.name} {gameObj.team_visitor.age_group || ''} @{' '}
                {gameObj.team_home.name} {gameObj.team_home.age_group || ''}
              </GameDataRowItem>
            </GameDataRow>
          </Details>
        </>
      )}
    </ExpandedWrapper>
  );
};

NotificationExpanded.propTypes = {
  selectedNotification: PropTypes.shape({
    id: PropTypes.string,
    source_id: PropTypes.string,
    user_id: PropTypes.number,
    alert_type: PropTypes.string,
    status: PropTypes.string,
    game: PropTypes.object,
    created_at: PropTypes.string
  }),
  storeReportsEventAndRedirect: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired
};

NotificationExpanded.defaultProps = {
  selectedNotification: {}
};

const SubMenu = ({
  notifications,
  selectedNotification,
  storeReportsEventAndRedirect,
  expandedId,
  onExpand,
  closeMenu
}) => (
  <>
    {notifications.map((notification, i) => {
      const isExpanded = expandedId === notification.id;
      const { external_event_id: displayId } =
        (notification && notification.notifications_game) || {};

      return (
        <Fragment key={notification.id}>
          <NotificationRow isExpanded={isExpanded} noBorder={i === notifications.length - 1}>
            <Summary>
              Reports for game <OrgName>{displayId || notification.source_id}</OrgName> are ready
              for submission.
            </Summary>
            <Chevron
              icon={isExpanded ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_RIGHT}
              width={15}
              height={10}
              color={isExpanded ? COLORS.denimBlue : COLORS.shuttleGray}
              onClick={() => onExpand(isExpanded ? null : notification.id)}
              isExpanded={isExpanded}
            />
          </NotificationRow>
          {isExpanded && (
            <NotificationExpanded
              selectedNotification={selectedNotification}
              storeReportsEventAndRedirect={storeReportsEventAndRedirect}
              closeMenu={closeMenu}
            />
          )}
        </Fragment>
      );
    })}
  </>
);

SubMenu.propTypes = {
  notifications: PropTypes.arrayOf(Object),
  selectedNotification: PropTypes.shape({
    id: PropTypes.string,
    source_id: PropTypes.string,
    user_id: PropTypes.number,
    alert_type: PropTypes.string,
    status: PropTypes.string,
    created_at: PropTypes.string
  }),
  storeReportsEventAndRedirect: PropTypes.func.isRequired,
  expandedId: PropTypes.string,
  onExpand: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired
};

SubMenu.defaultProps = {
  notifications: [],
  selectedNotification: {},
  expandedId: null
};

export default Enhancer(SubMenu);
