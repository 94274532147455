import React from 'react';
import { Field } from 'react-final-form';
import { Row, Col } from 'react-flexbox-grid';
import RoundedCheckBox from '../../../RoundedCheckBox';
import { FillHeight } from '../styled-components';
import { falsyToNull } from '../../../../../utils/parsers';
import ConditionalField from '../../../ConditionalField';

export default () => (
  <Col xs={12}>
    <FillHeight>
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <Field name="allow_officials_to_submit" parse={falsyToNull}>
              {({ input }) => (
                <RoundedCheckBox {...input} label="Allow officials to submit incident reports" />
              )}
            </Field>
          </Col>
        </Row>
        <ConditionalField when="allow_officials_to_submit" predicate={type => !type}>
          {settingsEnable => (
            <div>
              <Col xs={12}>
                <Field name="auto_approve_incident" parse={falsyToNull}>
                  {({ input }) => (
                    <RoundedCheckBox
                      {...input}
                      label="Automatically approve incident reports once submitted by an official"
                      disabled={settingsEnable}
                      locked={settingsEnable}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12}>
                <Field name="official_to_official_viewability" parse={falsyToNull}>
                  {({ input }) => (
                    <RoundedCheckBox
                      {...input}
                      label="Allow officials to view incident reports completed by other officials"
                      disabled={settingsEnable}
                      locked={settingsEnable}
                    />
                  )}
                </Field>
              </Col>
            </div>
          )}
        </ConditionalField>
      </Col>
    </FillHeight>
  </Col>
);
