import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';
import { CollapsedContent } from '../../../CollapsableTableRowContent/styled-component';
import Icon from '../../../Icon';

export const Block = styled(Col)`
  position: relative;
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.shuttleGray};
`;

export const Content = styled.div`
  ${({ absolute }) =>
    absolute
      ? `
      position: relative;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      `
      : `
      position: relative;
      `};
  ${({ light }) => (light ? 'font-family: RobotoLight' : '')};
`;

export const ButtonsContainer = styled.div`
  margin: 0 auto 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`;

export default Block;

export const EmailSenderContainer = styled.div`
  min-height: ${props => pxToRem(`${props.height}px`)};
  background: ${COLORS.white};
  transition: all 0.3s;
  display: flex;
  flex-direction: column;

  & ${CollapsedContent} {
    padding: ${pxToRem('10px')} 0;
    border-bottom: 1px solid ${COLORS.hawkesBlue}50;
  }
`;

export const EmailSenderInnerContainer = styled.div`
  flex: 1;
`;

export const SuccessMessageContainer = styled.div`
  min-width: ${pxToRem('368px')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const SuccessMessage = styled.span`
  color: ${COLORS.forestGreen};
  font-size: ${pxToRem('13px')};
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledIcon = styled(Icon)`
  margin: 0;
`;

export const SuccessIcon = StyledIcon.extend`
  margin-right: ${pxToRem('5px')};
`;

export const RecipientList = styled.div`
  padding-right: ${'10px'};
  text-align: left;
`;

export const AlertMessageContainer = styled.div`
  margin: ${pxToRem('10px')} 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlertMessage = styled.span`
  font-size: ${pxToRem('12px')};
  font-weight: bold;
  color: ${COLORS.denimBlue};
`;
