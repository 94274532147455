import { COLORS, ICONS } from '../../../../utils/styles';
import { GAME_ASSIGNMENT_STATUSES, GAMES_STATUSES } from '../../../../constants';
import { AVAILABILITY_TYPES } from '../../Profile/Availability/availabilityConstants';

const config = {
  calendarScheduleStatus: {
    [GAME_ASSIGNMENT_STATUSES.pending]: {
      color: COLORS.yellow,
      icon: ICONS.SYNC,
      width: 12,
      height: 12,
      background: `rgba(255, 187, 56, 0.1)`,
      hover: `rgba(255, 187, 56, 0.3)`
    },
    [GAME_ASSIGNMENT_STATUSES.accepted]: {
      color: COLORS.forestGreen,
      icon: ICONS.ONLY_CHECK,
      width: 12,
      height: 12,
      background: `rgba(24, 169, 87, 0.1)`,
      hover: `rgba(24, 169, 87, 0.3)`
    },
    [GAME_ASSIGNMENT_STATUSES.unpublished]: {
      color: '#3c3c3cbb',
      icon: ICONS.CIRCLE,
      width: 12,
      height: 12,
      background: `rgb(126, 126, 126, 0.2)`,
      hover: `rgb(126, 126, 126, 0.5)`
    },
    [AVAILABILITY_TYPES.UNAVAILABLE]: {
      color: '#48749B',
      width: 12,
      height: 12,
      background: `rgba(72, 116, 155,0.1)`,
      hover: `rgba(72, 116, 155, 0.3)`,
      fontSize: '15px'
    },
    [AVAILABILITY_TYPES.AVAILABLE]: {
      color: '#48749B',
      width: 12,
      height: 12,
      background: '#8cc4ff',
      hover: `rgba(196, 225, 255, 1)`,
      fontSize: '15px'
    },
    [`${GAMES_STATUSES.POSTPONED}${GAME_ASSIGNMENT_STATUSES.accepted}`]: {
      color: COLORS.venetianRed,
      icon: ICONS.ONLY_CHECK,
      width: 12,
      height: 12,
      background: `rgba(222, 13, 38,0.1)`,
      hover: `rgba(222, 13, 38,0.3)`
    },
    [`${GAMES_STATUSES.CANCELLED}${GAME_ASSIGNMENT_STATUSES.accepted}`]: {
      color: COLORS.venetianRed,
      icon: ICONS.ONLY_CHECK,
      width: 12,
      height: 12,
      background: `rgba(222, 13, 38,0.1)`,
      hover: `rgba(222, 13, 38,0.3)`
    },
    [`${GAMES_STATUSES.POSTPONED}${GAME_ASSIGNMENT_STATUSES.pending}`]: {
      color: COLORS.venetianRed,
      icon: ICONS.SYNC,
      width: 12,
      height: 12,
      background: `rgba(222, 13, 38,0.1)`,
      hover: `rgba(222, 13, 38,0.3)`
    },
    [`${GAMES_STATUSES.CANCELLED}${GAME_ASSIGNMENT_STATUSES.pending}`]: {
      color: COLORS.venetianRed,
      icon: ICONS.SYNC,
      width: 12,
      height: 12,
      background: `rgba(222, 13, 38,0.1)`,
      hover: `rgba(222, 13, 38,0.3)`
    }
  },
  tooltipAssignmentStatus: {
    [GAME_ASSIGNMENT_STATUSES.pending]: {
      color: COLORS.yellow,
      icon: ICONS.SYNC,
      width: 12,
      height: 12,
      background: `rgba(255, 187, 56, 0.1)`,
      hover: `rgba(255, 187, 56, 0.3)`
    },
    [GAME_ASSIGNMENT_STATUSES.accepted]: {
      color: COLORS.forestGreen,
      icon: ICONS.CHECK_CIRCLE,
      width: 12,
      height: 12,
      background: `rgba(24, 169, 87, 0.1)`,
      hover: `rgba(24, 169, 87, 0.3)`
    },
    [GAME_ASSIGNMENT_STATUSES.unpublished]: {
      color: '#3c3c3cbb',
      icon: ICONS.CIRCLE,
      width: 12,
      height: 12,
      background: `rgb(126, 126, 126, 0.2)`,
      hover: `rgb(126, 126, 126, 0.5)`
    }
  }
};

export default config;
