import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import Modals from '../Modals';
import Notifications from '../Notifications';
import './styled-components';
import { COLORS } from '../../../utils/styles';

export const Theme = ({ children }) => (
  <ThemeProvider theme={{ primaryColor: COLORS.denimBlue }}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.element.isRequired
};

const Root = ({ store, history, routes: Routes }) => (
  <Provider store={store}>
    <Theme>
      <>
        <Notifications />
        <Modals />
        {Routes(history)}
      </>
    </Theme>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  routes: PropTypes.func.isRequired
};

export default Root;
