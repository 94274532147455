import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { pxToRem } from '../../../utils/helpers';
import { COLORS, mediaQueries } from '../../../utils/styles';

export const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0d194f;
  z-index: 1300;
  width: 99.5%;
  margin-right: -8px;
  margin-left: -8px;
  & > div {
    height: 100%;
    text-align: center;
  }
  .colReverse {
    flex-wrap: wrap-reverse;
    align-items: center;
  }
  .logo {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }

  .sideMobilePanel {
    display: flex;
    justify-content: flex-end;
    background: #fff;
  }
  .menuLabel {
    font-size: 14px;
    color: #fff;
    padding: 0px 5px;
  }

  .pz {
    padding: 0px 0px;
  }
  ${mediaQueries.md(`
  width:100%;
  padding:10px 0px;
  .desktop{display:none}
  .mobile{display:flex;}
  .whiteBg{background:${COLORS.white}}
  .sideMobilePanel{background:rgba(0,0,0,0)}
  `)};
`;

export const Logo = styled.img`
  width: 70%;
  cursor: pointer;
  image-rendering: -webkit-optimize-contrast;
  ${mediaQueries.sm(`width:80%`)};
  ${mediaQueries.xs(`width:100%`)};
  ${`@media (max-width: 320px) {width:80%}`}
`;

export const InnerContainer = styled.div.attrs({
  'data-intercom-target': 'ToolBar'
})`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  margin-right: ${pxToRem('40px')};
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-end;
  max-width: ${pxToRem('328px')};
  ${mediaQueries.md(`
  justify-content:center;
  max-width:100%;
`)};
`;

export const StyledNavLink = styled(NavLink)`
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('15px')};
  font-weight: bold;
  font-stretch: condensed;
  padding: 0 ${pxToRem('9px')} ${pxToRem('10px')};
  letter-spacing: ${pxToRem('0.5px')};
  opacity: 0.7;
  color: ${COLORS.white};
  width: 100%;
  display: block;
  ${mediaQueries.lg(`
  font-size: ${pxToRem('12px')};
  `)}
  ${mediaQueries.md(`
  font-size: ${pxToRem('11px')};
  color: ${COLORS.black};
  padding: ${pxToRem('8px')} ${pxToRem('18px')};
  display:flex;
  text-transform: uppercase;
    align-items:center;
  &>div{display:inline-block; }
  `)};

  &.active {
    opacity: 1;
    padding: 0 ${pxToRem('9px')} ${pxToRem('7px')};
    padding-bottom: ${pxToRem('7px')};
    border-bottom: 3px solid ${COLORS.denimBlue};

    ${mediaQueries.md(`
    color: ${COLORS.white};
    background:${COLORS.denimBlue};
    padding: ${pxToRem('8px')} ${pxToRem('18px')};

    `)};
    svg {
      color: ${COLORS.white};
    }
  }
`;

export const CopyRight = styled.div`
  position: relative;
  color: #167cd4;
  width: 100%;
  padding: 20px 0px;
  & > div {
    position: fixed;
    bottom: 0px;
    padding: 0px 23.3px;
    background: #fff;
  }
`;

export const NavSpan = styled.span`
  width: 100%;
  height: 100%;
`;

export const DashboardOptions = styled(StyledNavLink)`
  div > span {
    font-size: 13px;
  }
  ${mediaQueries.md(`
  color: #4d4d4d;
  `)};
  &.active {
    border-bottom: none;

    ${mediaQueries.md(`
    color: #4d4d4d;
    background: transparent;
    `)};
  }
`;
