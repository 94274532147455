import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const Container = styled.div`
  width: 100%;
`;

export const StyledTextArea = styled.textarea`
  font-size: ${pxToRem('13px')};
  border-color: ${({ hasError }) => (hasError ? COLORS.carminePink : COLORS.altoGray)};
  font-family: 'RobotoCondensed';
  color: ${COLORS.denimBlue};
  width: 100%;
  min-height: ${pxToRem('96px')};
  box-sizing: border-box;
  padding: ${pxToRem('3px')} 0 0 ${pxToRem('8px')};

  &::placeholder {
    color: ${COLORS.altoGray};
  }

  & {
    resize: ${props => props.resize};
  }

  ${({ readOnly }) =>
    readOnly
      ? `
    border: none;
    color: ${COLORS.cobaltGrey};
  `
      : ''};

  ${({ theme }) => theme || ''};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Label = styled.p`
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  text-align: left;
  font-family: 'RobotoCondensedLight';
  padding-left: 0.5rem;
  margin: 0.25rem 0 0.5rem;

  ${({ theme }) => theme || ''};
`;

export const LabelMessage = styled.span`
  color: ${COLORS.hawkesBlue};
`;

export const ErrorMessage = styled.span`
  color: ${COLORS.red};
`;
