import OfficialAssessorDashboard from '../Calendar';
import DefaultDashboard from '../Dashboard';
import SuperAdminDashboard from './SuperAdmin';
import GroupAdminDashboard from './GroupAdmin';
import AssignorAdminDashboard from './AssignorAdminDashboard';
import { SELECTABLE_ROLES } from '../Header/utils';

const ROLES = {
  superAdmin: 'superAdmin',
  groupAdmin: 'groupAdmin',
  admin: 'admin',
  assignor: 'assignor',
  officialAssessor: 'officialAssessor',
  fundingAdmin: 'fundingAdmin'
};

const MAP_SELECTED_ROLES = {
  [SELECTABLE_ROLES.ADMIN_OR_ASSIGNOR]: [
    ROLES.superAdmin,
    ROLES.groupAdmin,
    ROLES.admin,
    ROLES.assignor
  ],
  [SELECTABLE_ROLES.OFFICIAL_OR_ASSESSOR]: [ROLES.officialAssessor]
};

const getCurrentRole = (selectedRole, roles = {}) => {
  const userRoles = [];
  Object.keys(roles).map(key => roles[key] && userRoles.push(`${key}`));
  const selected = MAP_SELECTED_ROLES[selectedRole] || userRoles;

  return selected.find(s => userRoles.includes(s));
};

const config = ({
  isSuperAdmin: superAdmin,
  groupAdmin,
  admin,
  fundingAdmin,
  assignor,
  officialAssessor,
  selectedRole
}) => {
  const role = getCurrentRole(selectedRole, {
    superAdmin,
    groupAdmin,
    admin,
    assignor,
    officialAssessor,
    fundingAdmin
  });

  switch (role) {
    case ROLES.superAdmin:
      return SuperAdminDashboard;

    case ROLES.groupAdmin:
      return GroupAdminDashboard;

    case ROLES.officialAssessor:
      return OfficialAssessorDashboard;

    case ROLES.admin:
    case ROLES.assignor:
      return AssignorAdminDashboard;

    default:
      return DefaultDashboard;
  }
};

export default config;
