import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Form, Field } from 'react-final-form';
import Toggle from 'react-toggle';
import '../toggle.css';

import Flex from '../../../Flex';
import Modal from '../../../Modal';
import Icon from '../../../Icon';
import LoaderWrapper from '../../../LoaderWrapper';
import CollapseableHeader from '../../../CollapseableHeader';
import Button from '../../../Button';
import InfoField from '../../../Reusable/InfoField';
import { Header, Body, FieldWrapper, Label } from '../styled-components';
import { COLORS, ICONS } from '../../../../../utils/styles';
import config, { generateGameInfo, generateReportInfo } from './config';
import Enhancer from '../../../../containers/AssessmentReportModal';

const AssessmentReportModal = ({
  onSubmit,
  onClose,
  onApprove,
  game,
  report,
  inEdit,
  setInEdit,
  isGameFetching,
  isReportFetching,
  crewLabel,
  isReportsEventGroupAdmin,
  isReportsEventAdmin,
  isReportsEventAssignor
}) => (
  <Modal
    title="Assessment Report"
    onClose={onClose}
    size={{
      xs: 8,
      sm: 8,
      md: 8,
      lg: 6
    }}
  >
    <Body>
      {report.updated_by_user_id && (
        <Flex justifyContent="space-between" padding="0 0 0.5rem">
          <Flex>
            {isReportsEventGroupAdmin || isReportsEventAdmin || isReportsEventAssignor ? (
              <>
                <Label color={report.approved ? COLORS.forestGreen : COLORS.hawkesBlue}>
                  Approved
                </Label>
                <Toggle
                  checked={report.approved}
                  icons={false}
                  onChange={() => !isReportFetching && onApprove(report)}
                />
              </>
            ) : (
              report.approved && <Label color={COLORS.forestGreen}>Approved</Label>
            )}
          </Flex>
          {(isReportsEventGroupAdmin ||
            isReportsEventAdmin ||
            isReportsEventAssignor ||
            !report.approved) && (
            <Icon
              width={22}
              height={18}
              icon={ICONS.EDIT}
              color={COLORS.denimBlue}
              clickable
              onClick={() => setInEdit(s => !s)}
            />
          )}
        </Flex>
      )}
      <CollapseableHeader label="Game Details">
        <LoaderWrapper isFetching={isGameFetching || isReportFetching}>
          <Row style={{ marginBottom: '0.75rem' }}>
            {generateGameInfo({
              game,
              report
            }).map(info => (
              <Col key={info.label} xs={12} md={info.width || 6}>
                <FieldWrapper verticalMargins="0.5rem">
                  <InfoField {...info} />
                </FieldWrapper>
              </Col>
            ))}
          </Row>
        </LoaderWrapper>
      </CollapseableHeader>
      <Header>Assessment</Header>
      {report.game_assignment && (
        <Flex fullWidth>
          <Label theme="padding-left: 1.5rem" color={COLORS.denimBlue}>
            Assessment of {report.game_assignment.event_role.user.first_name}{' '}
            {report.game_assignment.event_role.user.last_name} as {crewLabel}
          </Label>
        </Flex>
      )}
      {report.updated_by_user_id && !inEdit ? (
        <>
          <Row>
            {generateReportInfo({ report }).map(
              ({ component: Component, isDisabled, simple, ...info }) => (
                <Col key={info.name || info.value} xs={12} md={info.width || 6}>
                  {!isDisabled && (
                    <FieldWrapper verticalMargins="0.5rem">
                      {simple ? (
                        <Component theme={info.theme}>{info.value}</Component>
                      ) : (
                        <Component {...info} />
                      )}
                    </FieldWrapper>
                  )}
                </Col>
              )
            )}
          </Row>
        </>
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={inEdit ? report : null}
          render={({ handleSubmit, invalid, pristine, values }) => (
            <>
              <Row>
                {config.fields.map(
                  ({
                    isRequired = () => false,
                    isDisabled = () => false,
                    component: Component,
                    simple,
                    ...field
                  }) => (
                    <Col key={field.name} xs={12} md={field.width || 6}>
                      {!isDisabled(values) && (
                        <FieldWrapper>
                          {simple ? (
                            <Component theme={field.theme}>{field.value}</Component>
                          ) : (
                            <Field
                              component={Component}
                              {...field}
                              placeholder={
                                !!field.placeholder &&
                                field.placeholder + (isRequired(values) ? '*' : '')
                              }
                            />
                          )}
                        </FieldWrapper>
                      )}
                    </Col>
                  )
                )}
              </Row>
              <Row center="xs">
                <Button
                  width="84px"
                  height="32px"
                  margin="0 8px 0"
                  label="Cancel"
                  onClick={onClose}
                />
                <Button
                  type="submit"
                  width="84px"
                  height="32px"
                  margin="0 8px 0"
                  label={inEdit ? 'Save' : 'Submit'}
                  disabled={invalid || pristine}
                  onClick={handleSubmit}
                  primary
                />
              </Row>
            </>
          )}
        />
      )}
    </Body>
  </Modal>
);

AssessmentReportModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  game: PropTypes.shape({}),
  report: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  inEdit: PropTypes.bool,
  setInEdit: PropTypes.func.isRequired,
  isGameFetching: PropTypes.bool,
  isReportFetching: PropTypes.bool,
  crewLabel: PropTypes.string.isRequired,
  isReportsEventAdmin: PropTypes.bool,
  isReportsEventAssignor: PropTypes.bool
};

AssessmentReportModal.defaultProps = {
  report: null,
  inEdit: false,
  game: null,
  isGameFetching: false,
  isReportFetching: false,
  isReportsEventAdmin: false,
  isReportsEventAssignor: false
};

export default Enhancer(AssessmentReportModal);
