import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import NotificationsMenu from '../NotificationsMenu';
import AssignmentWarningsMenu from './AssignmentWarningsMenu';
import { history } from '../../../core/store';
import {
  Wrapper,
  UserMenuContainer,
  ArrowDown,
  FirstNameWrapper,
  FirstName,
  NotificationIconContainer
} from './styled-components';
import UserMenu from './UserMenu';
import NotificationIcon from './NotificationIcon';
import { ICONS, COLORS, mediaQueriesCheck } from '../../../utils/styles';
import Enhancer from '../../containers/SidePanel';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import { DASHBOARD, PROFILE_AVAILABILITY } from '../../../core/paths';
import { URLS } from '../../../constants';

const SidePanel = ({
  showModal,
  handleModalClick,
  handleNotificationsClick,
  userProfile,
  showNotificationsMenu,
  notificationCounts,
  clearAssignmentWarnings,
  assignmentWarnings,
  currentDayAssignmentCount,
  userIdx,
  setShowCalendarAction,
  calendarIcon,
  userId,
  isDashboardActive
}) => {
  const [iconColor, setIconColor] = useState(
    mediaQueriesCheck.mdCheck.matches === true ? COLORS.white : COLORS.hawkesBlue
  );

  function checkMediaQuery() {
    setIconColor(mediaQueriesCheck.mdCheck.matches === true ? COLORS.white : COLORS.hawkesBlue);
  }

  useEffect(() => {
    window.addEventListener('resize', checkMediaQuery);
    return () => {
      window.removeEventListener('resize', checkMediaQuery);
    };
  });

  return (
    <Wrapper>
      <NotificationIconContainer>
        {!!assignmentWarnings.length && (
          <NotificationIcon
            height={23}
            width={23}
            icon={ICONS.EXCLAMATION_CIRCLE_HOLLOW}
            color={COLORS.denimBlue}
            handleOnClick={clearAssignmentWarnings}
            canClickIfEmpty
            addClassName="desktop"
          />
        )}
        {calendarIcon.show && (
          <NotificationIcon
            height={23}
            width={23}
            icon={ICONS.LIGHT_CALENDAR}
            color={calendarIcon.calendarActive ? COLORS.denimBlue : COLORS.altoGray}
            notificationNumber={currentDayAssignmentCount}
            handleOnClick={() => {
              setShowCalendarAction({ show: true, calendarActive: !calendarIcon.calendarActive });
              return history.push(
                routeBuilder(
                  { userIdx, userId },
                  calendarIcon.calendarActive ? PROFILE_AVAILABILITY : DASHBOARD
                )
              );
            }}
            canClickIfEmpty
            tooltipText="Set your availability"
          />
        )}
        <NotificationIcon
          height={23}
          width={23}
          icon={ICONS.QUESTION_MARK_HOLLOW}
          color={iconColor === '#ffffff' ? COLORS.white : COLORS.denimBlue}
          canClickIfEmpty
          handleOnClick={() => {
            window.open(URLS.HELP_DESK, '_blank');
          }}
          addClassName={isDashboardActive && 'desktop'}
        />
        <NotificationIcon
          height={23}
          width={23}
          icon={ICONS.LIGHT_BELL}
          color={showNotificationsMenu ? COLORS.denimBlue : iconColor}
          notificationNumber={notificationCounts.total_count}
          handleOnClick={handleNotificationsClick}
        />
      </NotificationIconContainer>
      <UserMenuContainer onClick={handleModalClick} className="desktop">
        <FirstNameWrapper>
          <FirstName>{userProfile.first_name}</FirstName>
          <ArrowDown
            height={12}
            width={12}
            icon={ICONS.BOLD_CHEVRON_DOWN}
            color={COLORS.shuttleGrey}
          />
        </FirstNameWrapper>
      </UserMenuContainer>

      {showModal && <UserMenu onLinkClick={handleModalClick} className="desktop" />}
      {showNotificationsMenu && (
        <NotificationsMenu
          notificationCounts={notificationCounts}
          handleNotificationsClick={handleNotificationsClick}
        />
      )}
      {!!assignmentWarnings.length && (
        <OutsideClickHandler onOutsideClick={clearAssignmentWarnings}>
          <AssignmentWarningsMenu
            assignmentWarnings={assignmentWarnings}
            clearAssignmentWarnings={clearAssignmentWarnings}
          />
        </OutsideClickHandler>
      )}
    </Wrapper>
  );
};

SidePanel.propTypes = {
  showModal: PropTypes.bool,
  showNotificationsMenu: PropTypes.bool,
  handleModalClick: PropTypes.func.isRequired,
  handleNotificationsClick: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    dob: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    gender: PropTypes.string,
    last_name: PropTypes.string,
    middle_name: PropTypes.string
  }).isRequired,
  notificationCounts: PropTypes.shape({
    total_count: PropTypes.number
  }),
  clearAssignmentWarnings: PropTypes.func.isRequired,
  assignmentWarnings: PropTypes.arrayOf(Object),
  currentDayAssignmentCount: PropTypes.number
};

SidePanel.defaultProps = {
  showModal: false,
  showNotificationsMenu: false,
  notificationCounts: {},
  assignmentWarnings: [],
  currentDayAssignmentCount: 0
};

export default Enhancer(SidePanel);
