import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';

export const ButtonWrapper = styled(Row)`
  margin: 20px 0px;
`;

export const ButtonContainer = styled(Col)``;

export default ButtonWrapper;
