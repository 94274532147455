import React from 'react';

import { StylesContainer } from './styled-components';
import Buttons from './Buttons';
import Typography from './Typography';
import Modals from './Modals';
import Tables from './Tables';
import Selects from './Selects';
import { H1 } from '../../global-material-components';

export default function Styles() {
  return (
    <StylesContainer>
      <H1>Officals Connect Style Guide</H1>
      <Buttons />
      <Selects />
      <Typography />
      <Modals />
      <Tables />
      {/* Add Date Pickers */}
    </StylesContainer>
  );
}
