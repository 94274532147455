import { takeEvery, call, put, takeLatest, select } from 'redux-saga/effects';

import Api from '../../utils/api-wrapper';
import {
  STORE_REPORTS_EVENT,
  STORE_REPORTS_EVENT_AND_REDIRECT,
  setIncidentReports,
  setAssessmentReports,
  FETCH_INCIDENT_REPORTS,
  setIsFetching,
  BULK_UPDATE_REPORTS,
  BULK_UPDATE_ASSESSMENT_REPORTS,
  BULK_UPDATE_GAME_REPORTS,
  fetchIncidentReports as fetchIncidentReportsAction,
  fetchAssessmentReports as fetchAssessmentReportsAction,
  fetchGameReports as fetchGameReportsAction,
  FETCH_ASSESSMENT_REPORTS,
  FETCH_REPORTS_EVENT_CREW_LABELS,
  setReportsEventCrewLabels,
  setCurrentIsFetching,
  FETCH_CURRENT_ASSESSMENT_REPORT,
  setCurrentReport,
  EDIT_ASSESSMENT_REPORT,
  updateAssessmentReport,
  EDIT_INCIDENT_REPORT,
  updateIncidentReport,
  FETCH_GAME_REPORTS,
  setGameReports,
  UPDATE_GAME_REPORT,
  setGameReport,
  setFetchingGameReport,
  setSelectedReportsEvent
} from './index';
import { getLimit, getReportsEventId } from './selectors';
import { isReportsEventAdmin, selectUserId, isReportEventAssignor } from '../Auth/selectors';

import { errorProgressDecorate } from '../Progress/helper';
import { mergeData, dataWithPagination } from '../../utils/parsers';
import { persistReportsEvent, userIdxGetter } from '../../utils/auth';
import { sInterpolator as routeBuilder } from '../../utils/helpers';
import { history } from '../store';
import { REPORTS } from '../paths';
import { addNotifications, updateNotification } from '../Notifications';
import { PAGINATION_AWARE_PREFIX } from '../../views/containers/Reports';
import { NOTIFICATION_STATUS, REPORT_TYPES } from '../../constants';
import { getEventAssessmentReportSettings } from '../Events/selectors';

export function* processFetchIncidentReports({ payload: { filters = {}, page, sort } }) {
  const limit = yield select(getLimit);
  const eventId = yield select(getReportsEventId);
  const response = yield call(Api.fetchEventIncidentReports, {
    id: eventId,
    filters,
    page,
    limit,
    sortingValue: sort
  });

  if (response) yield put(setIncidentReports(dataWithPagination(response)));
}

export function* fetchIncidentReports(params) {
  yield call(errorProgressDecorate, processFetchIncidentReports, params, setIsFetching);
}

export function* processFetchAssessmentReports({
  payload: { filters: filtersObj = {}, page, sort }
}) {
  const filters = filtersObj;
  const reportsAdmin = yield select(isReportsEventAdmin);
  const reportsAssignor = yield select(isReportEventAssignor);
  const eventsAssessmentSettings = yield select(getEventAssessmentReportSettings);

  if (!reportsAdmin && !reportsAssignor) filters.assigned_user_id = yield select(selectUserId);
  const limit = yield select(getLimit);
  const eventId = yield select(getReportsEventId);
  if (eventsAssessmentSettings && eventsAssessmentSettings.allow_assessors_to_submit) {
    const response = yield call(Api.fetchEventAssessmentReports, {
      id: eventId,
      filters,
      page,
      limit,
      sortingValue: sort
    });

    if (response) yield put(setAssessmentReports(response));
  }
}

export function* fetchAssessmentReports(params) {
  yield call(errorProgressDecorate, processFetchAssessmentReports, params, setIsFetching);
}

export function* processEditAssessmentReport({
  payload: { id, data, message = 'Successfully updated assessment report' }
}) {
  const response = yield call(Api.updateAssessmentReport, { id, data });
  if (response) {
    const merged = mergeData(response);
    yield put(setCurrentReport(merged));
    yield put(updateAssessmentReport(merged));
    yield put(addNotifications([{ type: 'success', message }]));
  }
}

export function* editAssessmentReport(params) {
  yield call(errorProgressDecorate, processEditAssessmentReport, params, setCurrentIsFetching);
}

export function* storeReportsEvent({ payload: event }) {
  const { usrIdx = userIdxGetter() } = event;
  yield call(persistReportsEvent, { event, usrIdx });
  yield put(setSelectedReportsEvent(event));
}

export function* storeReportsEventAndRedirect(params) {
  const { gameId, notificationId } = params.payload;
  const userIdx = yield call(userIdxGetter);
  yield call(storeReportsEvent, params);
  if (notificationId) {
    yield put(
      updateNotification({ id: notificationId, data: { status: NOTIFICATION_STATUS.action_taken } })
    );
  }
  history.push(
    routeBuilder(
      { userIdx },
      `${REPORTS}?${PAGINATION_AWARE_PREFIX}_type=${REPORT_TYPES.assessment}&${PAGINATION_AWARE_PREFIX}_search=${gameId}`
    )
  );
}

export function* processBulkUpdateReports({
  payload: { ids, data, eventId, sort, filters = {}, page, message = 'Sucessfully updated reports' }
}) {
  yield call(Api.bulkUpdateIncidentReports, { eventId, ids, data });
  yield put(fetchIncidentReportsAction({ id: eventId, sort, filters, page }));
  yield put(addNotifications([{ type: 'success', message }]));
}

export function* processBulkUpdateAssessmentReports({
  payload: { ids, data, eventId, sort, filters = {}, page, message = 'Sucessfully updated reports' }
}) {
  yield call(Api.bulkUpdateAssessmentReports, { eventId, ids, data });
  yield put(fetchAssessmentReportsAction({ id: eventId, sort, filters, page }));
  yield put(addNotifications([{ type: 'success', message }]));
}

export function* bulkUpdateReports(params) {
  yield call(errorProgressDecorate, processBulkUpdateReports, params, setIsFetching);
}

export function* bulkUpdateAssessmentReports(params) {
  yield call(errorProgressDecorate, processBulkUpdateAssessmentReports, params, setIsFetching);
}
export function* processBulkUpdateGameReports({
  payload: { ids, data, eventId, sort, filters = {}, page, message = 'Sucessfully updated reports' }
}) {
  yield call(Api.bulkUpdateGameReports, { eventId, ids, data });
  yield put(fetchGameReportsAction({ id: eventId, sort, filters, page }));
  yield put(addNotifications([{ type: 'success', message }]));
}

export function* bulkUpdateGameReports(params) {
  yield call(errorProgressDecorate, processBulkUpdateGameReports, params, setIsFetching);
}

export function* processFetchReportsEventCrewLabels() {
  const reportsEventId = yield select(getReportsEventId);
  if (reportsEventId) {
    const response = yield call(Api.fetchEventCrewLabels, { id: reportsEventId });
    const parsedResponse = mergeData(response);
    yield put(
      setReportsEventCrewLabels(
        parsedResponse.labels.map((label, i) => ({ label, role_id: parsedResponse.role_ids[i] }))
      )
    );
  }
}

export function* fetchReportsEventCrewLabels() {
  yield call(errorProgressDecorate, processFetchReportsEventCrewLabels, {}, setIsFetching);
}

export function* processFetchCurrentAssessmentReport({ payload: { id } }) {
  const response = yield call(Api.fetchAssessmentReport, { id });
  if (response) yield put(setCurrentReport(mergeData(response)));
}

export function* fetchCurrentAssessmentReport(params) {
  yield call(
    errorProgressDecorate,
    processFetchCurrentAssessmentReport,
    params,
    setCurrentIsFetching
  );
}

export function* processEditIncidentReport({
  payload: { id, data, message = 'Successfully updated incident report' }
}) {
  const external_team_id = data.external_team_id === 'N/A' ? null : data.external_team_id;
  const response = yield call(Api.updateIncidentReport, {
    id,
    data: { ...data, external_team_id }
  });
  if (response) {
    const merged = mergeData(response);
    yield put(updateIncidentReport(merged));
    yield put(setCurrentReport(merged));
    yield put(addNotifications([{ type: 'success', message }]));
  }
}

export function* editIncidentReport(params) {
  yield call(errorProgressDecorate, processEditIncidentReport, params, setCurrentIsFetching);
}

export function* processFetchGameReports({
  payload: { filters: filtersObj = {}, page, sort, byPlayer }
}) {
  const filters = filtersObj;
  // const reportsAdmin = yield select(isReportsEventAdmin);
  // if (!reportsAdmin) filters.assigned_user_id = yield select(selectUserId);
  const limit = yield select(getLimit);
  const eventId = yield select(getReportsEventId);
  const response = yield call(Api.fetchEventGameReports, {
    id: eventId,
    filters,
    page,
    sort,
    limit,
    byPlayer
  });

  if (response) yield put(setGameReports(dataWithPagination(response)));
}

export function* fetchGameReports(params) {
  yield call(errorProgressDecorate, processFetchGameReports, params, setFetchingGameReport);
}

export function* processUpdateGameReport({
  payload: {
    data: { id }
  }
}) {
  const response = yield call(Api.fetchGameReport, { id });
  if (response) yield put(setGameReport(mergeData(response)));
}

export function* updateGameReport(params) {
  yield call(errorProgressDecorate, processUpdateGameReport, params);
}

export const reportsSagas = [
  takeEvery(STORE_REPORTS_EVENT, storeReportsEvent),
  takeLatest(STORE_REPORTS_EVENT_AND_REDIRECT, storeReportsEventAndRedirect),
  takeLatest(FETCH_INCIDENT_REPORTS, fetchIncidentReports),
  takeLatest(FETCH_ASSESSMENT_REPORTS, fetchAssessmentReports),
  takeLatest(EDIT_ASSESSMENT_REPORT, editAssessmentReport),
  takeLatest(BULK_UPDATE_REPORTS, bulkUpdateReports),
  takeLatest(BULK_UPDATE_ASSESSMENT_REPORTS, bulkUpdateAssessmentReports),
  takeLatest(BULK_UPDATE_GAME_REPORTS, bulkUpdateGameReports),
  takeLatest(FETCH_REPORTS_EVENT_CREW_LABELS, fetchReportsEventCrewLabels),
  takeEvery(FETCH_CURRENT_ASSESSMENT_REPORT, fetchCurrentAssessmentReport),
  takeEvery(EDIT_INCIDENT_REPORT, editIncidentReport),
  takeLatest(FETCH_GAME_REPORTS, fetchGameReports),
  takeEvery(UPDATE_GAME_REPORT, updateGameReport)
];

export default reportsSagas;
