import styled from 'styled-components';

import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

export const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: ${pxToRem('36px')};
  &.input {
    margin-top: ${pxToRem('30px')};
  }
  &:last-of-type {
    margin-right: 0;
  }
  &.hidden {
    display: none;
  }
`;

export const FieldLabel = styled.span`
  display: ${({ display }) => display || 'inline-block'};
  color: ${COLORS.shuttleGray};
  font-family: 'RobotoCondensed';
  padding: ${({ display }) =>
    display === 'block' ? `${pxToRem('11px')} 0 0.25rem 0.5rem` : '0 0.5rem'};
  font-size: ${pxToRem('13px')};
  ${({ display }) =>
    !display &&
    `
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  `};
`;
