import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import { Block, Content, ButtonsContainer, ButtonContainer } from '../styled-components';

const ProgressGameDeletionModal = ({ onClose }) => (
  <Modal title="Game deletion in progress" onClose={onClose} rightPanel>
    <>
      <Row>
        <Block xs={10} xsOffset={1}>
          <Content light first>
            <p
              dangerouslySetInnerHTML={{
                __html: `This can take up to a few minutes to process based on the number of games you have selected. 
                  <br> Once the selected games are deleted a CSV file will automatically download. 
                  <br><br> You can close this message at any time.`
              }}
            />
          </Content>
        </Block>
      </Row>
      <Row>
        <Block xs={12} className="last">
          <ButtonsContainer>
            <ButtonContainer>
              <Button label="Close" width="84px" height="32px" onClick={onClose} primary />
            </ButtonContainer>
          </ButtonsContainer>
        </Block>
      </Row>
    </>
  </Modal>
);

ProgressGameDeletionModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

ProgressGameDeletionModal.defaultProps = {};

export default ProgressGameDeletionModal;
