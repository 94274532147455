import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import FileReaderInput from 'react-file-reader-input';
import DropZone from 'react-dropzone';

import Modal from '../../../Modal';
import Button from '../../../Button';
import Icon from '../../../Icon';
import {
  Block,
  Content,
  SuccessMessage,
  ErrorMessage,
  Error,
  ButtonsContainer,
  ButtonContainer,
  TextContainer,
  InputSelect,
  InputLink,
  DropZoneStyles,
  DropZoneAcceptStyles,
  DropZoneRejectStyles,
  ContentWrapper,
  FullWidthBlock
} from './styled-components';
import { getAssetsURL } from '../../../../../utils/helpers';
import { ICONS, COLORS } from '../../../../../utils/styles';
import Enhancer from '../../../../containers/ImportModal';
import modalInfo from './constants';

const InProgressContent = () => (
  <>
    <Content>
      This may take a few minutes to process. You will be emailed once the upload is complete so you
      can review all successfully added users and any errors that may have been discovered in the
      file.
    </Content>
  </>
);

const SuccessContent = ({ activeFile }) => (
  <FullWidthBlock xs={12} className="content">
    <ContentWrapper>
      <Content marginBottom="8px">
        <Icon width={27} height={27} color={COLORS.forestGreen} icon={ICONS.CHECK_CIRCLE} />
        <SuccessMessage>Upload Successful</SuccessMessage>
      </Content>
      <Content light>{activeFile.name}</Content>
      <Block xs={12} className="content">
        <InProgressContent />
      </Block>
    </ContentWrapper>
  </FullWidthBlock>
);

SuccessContent.propTypes = {
  activeFile: PropTypes.shape(Object).isRequired
};

const ErrorContent = ({ resultMessage }) => (
  <Content>
    <Icon width={27} height={27} color={COLORS.carmine} icon={ICONS.X} />
    <ErrorMessage>
      <Error>error: </Error>
      {resultMessage.message}
    </ErrorMessage>
  </Content>
);

ErrorContent.propTypes = {
  resultMessage: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string
  }).isRequired
};

const ImportModal = ({
  onClose,
  onSubmitImport,
  activeFile,
  setActiveFile,
  resultMessage,
  setResultMessage,
  inProgress,
  type
}) => {
  const isSuccess = resultMessage && resultMessage.type === 'success';
  const isError = resultMessage && resultMessage.type === 'error';
  const roleLabel = type[0].toUpperCase() + type.slice(1);
  const style = activeFile && !resultMessage && !inProgress ? DropZoneAcceptStyles : DropZoneStyles;
  return (
    <Modal title={`Add New ${roleLabel}(s)`} onClose={onClose} rightPanel background userList>
      <Row>
        {isSuccess ? (
          <SuccessContent activeFile={activeFile} />
        ) : (
          <DropZone
            accept="text/plain, text/csv, application/vnd.ms-excel"
            disableClick
            disabled={isSuccess}
            multiple={false}
            onDrop={acceptedFiles => {
              setActiveFile(acceptedFiles[0]);
              setResultMessage(null);
            }}
            acceptStyle={DropZoneAcceptStyles}
            rejectStyle={DropZoneRejectStyles}
          >
            {({ getRootProps }) => (
              <div {...getRootProps({ style })}>
                <Block xs={12} className="content">
                  {inProgress && <InProgressContent />}
                  {!inProgress && (
                    <>
                      {!isSuccess && (
                        <>
                          {isError && <ErrorContent resultMessage={resultMessage} />}
                          <Content>
                            Use{' '}
                            <InputLink
                              href={`${getAssetsURL()}${modalInfo.s3FileName}`}
                              download={`${modalInfo.s3FileName}`}
                            >
                              {modalInfo.linkName}
                            </InputLink>{' '}
                            as an example of all required and optional information that can be
                            included when adding {roleLabel.toLowerCase()}s.
                          </Content>
                          <Content>
                            {modalInfo.importInfo.replace(/{roleLabel}/gi, roleLabel.toLowerCase())}
                          </Content>
                          <Content extraPadding>
                            <TextContainer>Drag and drop .CSV file here or </TextContainer>
                            <TextContainer>
                              <FileReaderInput
                                accept="text/csv"
                                onChange={(e, results) => {
                                  setActiveFile(results[0][1]);
                                  setResultMessage(null);
                                }}
                              >
                                <InputSelect>choose a file</InputSelect>
                              </FileReaderInput>
                            </TextContainer>
                          </Content>
                          {!isError && (
                            <Content light absolute>
                              {activeFile && activeFile.name}
                            </Content>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Block>
              </div>
            )}
          </DropZone>
        )}

        <Block xs={12}>
          <ButtonsContainer>
            {!inProgress &&
              (isSuccess ? (
                <>
                  <ButtonContainer>
                    <Button
                      label={`Add Another ${roleLabel}`}
                      onClick={() => {
                        setActiveFile(null);
                        setResultMessage(null);
                      }}
                      width="140px"
                      height="32px"
                      primary
                    />
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button label="Done" width="84px" height="32px" onClick={onClose} primary />
                  </ButtonContainer>
                </>
              ) : (
                <>
                  <ButtonContainer>
                    <Button
                      label="Import"
                      onClick={() => onSubmitImport(activeFile)}
                      width="84px"
                      height="32px"
                      disabled={!activeFile}
                      primary
                    />
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button
                      label="Cancel"
                      width="84px"
                      height="32px"
                      onClick={onClose}
                      primary={!activeFile}
                    />
                  </ButtonContainer>
                </>
              ))}
          </ButtonsContainer>
        </Block>
      </Row>
    </Modal>
  );
};

ImportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitImport: PropTypes.func.isRequired,
  activeFile: PropTypes.shape(Object),
  setActiveFile: PropTypes.func.isRequired,
  resultMessage: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string
  }),
  setResultMessage: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  type: PropTypes.string.isRequired
};

ImportModal.defaultProps = {
  activeFile: null,
  resultMessage: null,
  inProgress: false
};

export default Enhancer(ImportModal);
