import moment from 'moment';

import INCIDENT_REPORT_TYPES from '../../../Games/Modals/IncidentReportsModal/constants';
import Dropdown from '../../../Reusable/Dropdown';
import MaterialDatePicker from '../../../../controls/MaterialDatePicker';
import MultiSelectDropdown from '../../../Reusable/MultiSelectDropdown';
import MultipleInput from '../../../Reusable/MultipleInput';
import theme from './styled-components';

export default {
  assessment: [
    {
      component: MultipleInput,
      name: 'name',
      placeholder: 'Official Name'
    },
    {
      component: MaterialDatePicker,
      name: 'start_date',
      placeholder: 'Game Start Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      theme: theme.materialDatePicker
    },
    {
      component: Dropdown,
      name: 'approved',
      placeholder: 'Approved',
      theme: theme.multiSelectDropdown,
      tagsByName: true,
      options: [
        {
          name: 'Approved',
          value: 'true'
        },
        {
          name: 'Unapproved',
          value: 'false'
        }
      ]
    },
    {
      component: MaterialDatePicker,
      name: 'end_date',
      placeholder: 'Game End Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      theme: theme.materialDatePicker,
      validate: (value, values) =>
        !value || !values.start_date || moment(value).isSameOrAfter(moment(values.start_date))
          ? undefined
          : 'cannot be before Game Start Date',
      inlineError: true
    }
  ],
  incident: [
    {
      component: MultiSelectDropdown,
      name: 'report_type',
      placeholder: 'Report Type',
      theme: theme.multiSelectDropdown,
      noCount: true,
      options: INCIDENT_REPORT_TYPES.reportType.map(type => ({ name: type, value: type }))
    },
    {
      component: MaterialDatePicker,
      name: 'start_date',
      placeholder: 'Game Start Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      theme: theme.materialDatePicker
    },
    {
      component: Dropdown,
      name: 'approved',
      placeholder: 'Approved',
      theme: theme.multiSelectDropdown,
      tagsByName: true,
      options: [
        {
          name: 'Approved',
          value: 'true'
        },
        {
          name: 'Unapproved',
          value: 'false'
        }
      ]
    },
    {
      component: MaterialDatePicker,
      name: 'end_date',
      placeholder: 'Game End Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      theme: theme.materialDatePicker,
      validate: (value, values) =>
        !value || !values.start_date || moment(value).isSameOrAfter(moment(values.start_date))
          ? undefined
          : 'cannot be before Game Start Date',
      inlineError: true
    }
  ],
  game: [
    {
      component: MultipleInput,
      name: 'name',
      placeholder: 'Team Name'
    },
    {
      component: MaterialDatePicker,
      name: 'start_date',
      placeholder: 'Game Start Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      theme: theme.materialDatePicker
    },
    {
      component: Dropdown,
      name: 'approved',
      placeholder: 'Approved',
      theme: theme.multiSelectDropdown,
      tagsByName: true,
      options: [
        {
          name: 'Approved',
          value: 'true'
        },
        {
          name: 'Unapproved',
          value: 'false'
        }
      ]
    },
    {
      component: MaterialDatePicker,
      name: 'end_date',
      placeholder: 'Game End Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      theme: theme.materialDatePicker,
      validate: (value, values) =>
        !value || !values.start_date || moment(value).isSameOrAfter(moment(values.start_date))
          ? undefined
          : 'cannot be before Game Start Date',
      inlineError: true
    }
  ],
  gameSoccerOrFutsal: [
    {
      component: MultipleInput,
      name: 'name',
      placeholder: 'Team Name'
    },
    {
      component: MaterialDatePicker,
      name: 'start_date',
      placeholder: 'Game Start Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      theme: theme.materialDatePicker
    },
    {
      component: MultiSelectDropdown,
      name: 'misconduct',
      placeholder: 'Misconduct',
      theme: theme.multiSelectDropdown,
      options: [
        {
          name: 'Has Send Offs',
          value: 'hasSendOffs'
        },
        {
          name: 'Has Cautions',
          value: 'hasCautions'
        }
      ]
    },
    {
      component: MaterialDatePicker,
      name: 'end_date',
      placeholder: 'Game End Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      theme: theme.materialDatePicker,
      validate: (value, values) =>
        !value || !values.start_date || moment(value).isSameOrAfter(moment(values.start_date))
          ? undefined
          : 'cannot be before Game Start Date',
      inlineError: true
    },
    {
      component: Dropdown,
      name: 'approved',
      placeholder: 'Approved',
      theme: theme.multiSelectDropdown,
      tagsByName: true,
      options: [
        {
          name: 'Approved',
          value: 'true'
        },
        {
          name: 'Unapproved',
          value: 'false'
        }
      ]
    }
  ]
};
