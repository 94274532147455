import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import { always } from 'ramda';
import { Form, Field } from 'react-final-form';
import Paper from '../../../layouts/Paper';
import Dropdown from '../../Dropdown';
import CollapsableContent from '../../CollapsableContent';
import Checkbox from '../../Checkbox';
import Title from '../../Title';
import Button from '../../Button';
import {
  FormContainer,
  GroupContainer,
  ColumnContainer,
  HeadSelector,
  ButtonContainer
} from './styled-components';
import { ActualContentContainer } from '../styled-components';
import initialFormValues from './initialValues';

const roles = {
  selector: [
    {
      label: 'Assignor',
      value: 'assignor'
    },
    {
      label: 'Official',
      value: 'official'
    },
    {
      label: 'Assessor',
      value: 'assessor'
    },
    {
      label: 'Admin',
      value: 'admin'
    },
    {
      label: 'Funding Admin',
      value: 'funding'
    }
  ]
};

const EventRoles = ({ internalSelectedRole, setSelectedRole }) => (
  <ActualContentContainer>
    <Form
      onSubmit
      initialValues={initialFormValues[internalSelectedRole]}
      render={() => (
        <Paper>
          <HeadSelector>
            <Dropdown
              dropdownWidth="9rem"
              label="ROLES/PERMISSIONS"
              options={roles.selector}
              value={internalSelectedRole}
              name="selectedRole"
              onChange={({ target: { value } }) => setSelectedRole(value)}
            />
          </HeadSelector>
          <FormContainer>
            <CollapsableContent title="USERS" pt={1}>
              <GroupContainer>
                <ColumnContainer>
                  <Title>Officials</Title>
                  <Field name="roles.users.officials.viewAll">
                    {({ input }) => (
                      <Checkbox locked label="View All Officials in Event List" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.officials.addOfficials">
                    {({ input }) => <Checkbox locked label="Add Officials to Event" {...input} />}
                  </Field>
                  <Field name="roles.users.officials.removeOfficials">
                    {({ input }) => (
                      <Checkbox locked label="Remove Officials from Event" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.officials.viewOfficialProfile">
                    {({ input }) => (
                      <Checkbox locked label="View Official Profile Information" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.officials.editOfficialProfile">
                    {({ input }) => (
                      <Checkbox locked label="Edit Official Profile Information" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.officials.viewOfficialCertification">
                    {({ input }) => (
                      <Checkbox
                        locked
                        label="View Official Certifications/U.S. Soccer ID"
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="roles.users.officials.editOfficialCertification">
                    {({ input }) => (
                      <Checkbox
                        locked
                        label="Edit Official Certifications/U.S. Soccer ID"
                        {...input}
                      />
                    )}
                  </Field>
                </ColumnContainer>
                <ColumnContainer>
                  <Title>Assessors</Title>
                  <Field name="roles.users.assesors.viewAll">
                    {({ input }) => (
                      <Checkbox locked label="View All Officials in Event List" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.assesors.addOfficials">
                    {({ input }) => <Checkbox locked label="Add Officials to Event" {...input} />}
                  </Field>
                  <Field name="roles.users.assesors.removeOfficials">
                    {({ input }) => (
                      <Checkbox locked label="Remove Officials from Event" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.assesors.viewOfficialProfile">
                    {({ input }) => (
                      <Checkbox locked label="View Official Profile Information" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.assesors.editOfficialProfile">
                    {({ input }) => (
                      <Checkbox locked label="Edit Official Profile Information" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.assesors.viewOfficialCertification">
                    {({ input }) => (
                      <Checkbox
                        locked
                        label="View Official Certifications/U.S. Soccer ID"
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="roles.users.assesors.editOfficialCertification">
                    {({ input }) => (
                      <Checkbox
                        locked
                        label="Edit Official Certifications/U.S. Soccer ID"
                        {...input}
                      />
                    )}
                  </Field>
                </ColumnContainer>
                <ColumnContainer>
                  <Title>All Other Users</Title>
                  <Field name="roles.users.users.viewAll">
                    {({ input }) => (
                      <Checkbox locked label="View All Officials in Event List" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.users.addOfficials">
                    {({ input }) => <Checkbox locked label="Add Officials to Event" {...input} />}
                  </Field>
                  <Field name="roles.users.users.removeOfficials">
                    {({ input }) => (
                      <Checkbox locked label="Remove Officials from Event" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.users.viewOfficialProfile">
                    {({ input }) => (
                      <Checkbox locked label="View Official Profile Information" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.users.editOfficialProfile">
                    {({ input }) => (
                      <Checkbox locked label="Edit Official Profile Information" {...input} />
                    )}
                  </Field>
                  <Field name="roles.users.users.viewOfficialCertification">
                    {({ input }) => (
                      <Checkbox
                        locked
                        label="View Official Certifications/U.S. Soccer ID"
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="roles.users.users.editOfficialCertification">
                    {({ input }) => (
                      <Checkbox
                        locked
                        label="Edit Official Certifications/U.S. Soccer ID"
                        {...input}
                      />
                    )}
                  </Field>
                </ColumnContainer>
              </GroupContainer>
            </CollapsableContent>
            <CollapsableContent title="EVENTS" pt={1} externalIsOpen={false}>
              <GroupContainer>
                <ColumnContainer>
                  <Field name="roles.events.addEvents">
                    {({ input }) => <Checkbox locked label="Create New Events" {...input} />}
                  </Field>
                  <Field name="roles.events.copyEvents">
                    {({ input }) => <Checkbox locked label="Copy Events" {...input} />}
                  </Field>
                  <Field name="roles.events.deleteEvents">
                    {({ input }) => <Checkbox locked label="Delete Events" {...input} />}
                  </Field>
                  <Field name="roles.events.manageAssignorSettings">
                    {({ input }) => <Checkbox locked label="Manage Assignor Settings" {...input} />}
                  </Field>
                  <Field name="roles.events.manageAssessorSettings">
                    {({ input }) => <Checkbox locked label="Manage Assessor Settings" {...input} />}
                  </Field>
                </ColumnContainer>
                <ColumnContainer>
                  <Field name="roles.events.manageOfficialSettings">
                    {({ input }) => <Checkbox locked label="Manage Official Settings" {...input} />}
                  </Field>
                  <Field name="roles.events.manageGameSettings">
                    {({ input }) => <Checkbox locked label="Manage Game Settings" {...input} />}
                  </Field>
                  <Field name="roles.events.manageAgeGroups">
                    {({ input }) => <Checkbox locked label="Manage Age Groups/Levels" {...input} />}
                  </Field>
                  <Field name="roles.events.manageCrewPay">
                    {({ input }) => <Checkbox locked label="Manage Crew Pay" {...input} />}
                  </Field>
                  <Field name="roles.events.manageMerchantAccounts">
                    {({ input }) => <Checkbox locked label="Manage Merchant Accounts" {...input} />}
                  </Field>
                </ColumnContainer>
              </GroupContainer>
            </CollapsableContent>
            <CollapsableContent title="GAMES / ASSIGNMENTS" pt={1} externalIsOpen={false}>
              <GroupContainer>
                <ColumnContainer>
                  <Field name="roles.games.addImportEvents">
                    {({ input }) => <Checkbox locked label="Add New Games via Import" {...input} />}
                  </Field>
                  <Field name="roles.games.editDeleteEvents">
                    {({ input }) => <Checkbox locked label="Edit & Delete Games" {...input} />}
                  </Field>
                  <Field name="roles.games.viewEvents">
                    {({ input }) => <Checkbox locked label="View Games" {...input} />}
                  </Field>
                </ColumnContainer>
                <ColumnContainer>
                  <Field name="roles.games.assignOfficials">
                    {({ input }) => <Checkbox locked label="Assign Officials" {...input} />}
                  </Field>
                  <Field name="roles.games.assignAssessors">
                    {({ input }) => <Checkbox locked label="Assign Assessors" {...input} />}
                  </Field>
                  <Field name="roles.games.viewAssignmentHistory">
                    {({ input }) => (
                      <Checkbox locked label="View Game Assignment History" {...input} />
                    )}
                  </Field>
                </ColumnContainer>
              </GroupContainer>
            </CollapsableContent>
            <CollapsableContent title="ASSESSMENTS" pt={1} externalIsOpen={false}>
              <GroupContainer>
                <ColumnContainer>
                  <Field name="roles.assessments.view">
                    {({ input }) => <Checkbox locked label="View Assessments" {...input} />}
                  </Field>
                  <Field name="roles.assessments.editPast">
                    {({ input }) => <Checkbox locked label="Edit Past Assessments" {...input} />}
                  </Field>
                  <Field name="roles.assessments.submitNew">
                    {({ input }) => <Checkbox locked label="Submit New Assessments" {...input} />}
                  </Field>
                </ColumnContainer>
              </GroupContainer>
            </CollapsableContent>
            <CollapsableContent title="FINANCIALS" pt={1} externalIsOpen={false}>
              <GroupContainer>
                <ColumnContainer>
                  <Field name="roles.financials.postPayments">
                    {({ input }) => <Checkbox locked label="Post Payments" {...input} />}
                  </Field>
                  <Field name="roles.financials.addAdjustments">
                    {({ input }) => <Checkbox locked label="Add Adjustments" {...input} />}
                  </Field>
                  <Field name="roles.financials.submitRefunds">
                    {({ input }) => <Checkbox locked label="Submit Refunds" {...input} />}
                  </Field>
                  <Field name="roles.financials.manageMerchantAccounts">
                    {({ input }) => <Checkbox locked label="Manage Merchant Accounts" {...input} />}
                  </Field>
                  <Field name="roles.financials.viewPaymentHistory">
                    {({ input }) => <Checkbox locked label="View Payment History" {...input} />}
                  </Field>
                </ColumnContainer>
              </GroupContainer>
            </CollapsableContent>
            <CollapsableContent title="REPORTING" pt={1} externalIsOpen={false}>
              <GroupContainer>
                <ColumnContainer>
                  <Field name="roles.reporting.myEvents">
                    {({ input }) => <Checkbox locked label="My Events Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.officialsReport">
                    {({ input }) => <Checkbox locked label="Officials Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.assessorReport">
                    {({ input }) => <Checkbox locked label="Assessor Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.assignorReport">
                    {({ input }) => <Checkbox locked label="Assignor Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.adminReport">
                    {({ input }) => <Checkbox locked label="Admin Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.gameStatsReport">
                    {({ input }) => <Checkbox locked label="Game Stats Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.gameAssignmentReport">
                    {({ input }) => <Checkbox locked label="Game Assignment Report" {...input} />}
                  </Field>
                </ColumnContainer>
                <ColumnContainer>
                  <Field name="roles.reporting.gameAssignmentHistoryReport">
                    {({ input }) => (
                      <Checkbox locked label="Game Assignment History Report" {...input} />
                    )}
                  </Field>
                  <Field name="roles.reporting.assessmentReport">
                    {({ input }) => <Checkbox locked label="Assessment Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.gamePayReport">
                    {({ input }) => <Checkbox locked label="Game Pay Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.ccTransaccionReport">
                    {({ input }) => <Checkbox locked label="CC Transaction Report" {...input} />}
                  </Field>
                  <Field name="roles.reporting.financialAdjustmentReport">
                    {({ input }) => (
                      <Checkbox locked label="Financial Adjustment Report" {...input} />
                    )}
                  </Field>
                  <Field name="roles.reporting.financialSummaryReport">
                    {({ input }) => <Checkbox locked label="Financial Summary Report" {...input} />}
                  </Field>
                </ColumnContainer>
              </GroupContainer>
            </CollapsableContent>
            <ButtonContainer>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Button type="submit" label="Update" primary />
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Button type="reset" label="Cancel" />
              </Col>
            </ButtonContainer>
          </FormContainer>
        </Paper>
      )}
    />
  </ActualContentContainer>
);

EventRoles.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  internalSelectedRole: PropTypes.string,
  setSelectedRole: PropTypes.func
};

EventRoles.defaultProps = {
  onChange: always(undefined),
  value: '',
  internalSelectedRole: '',
  setSelectedRole: PropTypes.func
};

export default EventRoles;
