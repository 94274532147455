import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, defaultTo, contains } from 'ramda';

import { Container, Header, SortIconContainer, ArrowUp, ArrowDown } from './styled-components';

const defaultValue = defaultTo('');

const ascDesc = value => (contains('-', defaultValue(value)) ? '-' : '');

const GameLevelsSorter = ({ onSort, value, option, handleGameLevelsSorting, customStyle }) => {
  const { name } = option.length ? option[0] : { name: '' };

  const isOptionSelected = !isEmpty(defaultValue(value));

  return (
    <Container minWidth={0} style={customStyle}>
      <Header>
        {isOptionSelected && (
          <SortIconContainer>
            <ArrowUp
              isActive={isEmpty(ascDesc(value)) && value === name}
              onClick={() => {
                const sort = defaultValue(name).replace('-', '');
                handleGameLevelsSorting(name);
                return onSort(sort);
              }}
            />
            <ArrowDown
              isActive={!isEmpty(ascDesc(value)) && `-${name}` === value}
              onClick={() => {
                const sort = `-${defaultValue(name).replace('-', '')}`;
                const desc = true;
                handleGameLevelsSorting(name, desc);
                return onSort(sort);
              }}
            />
          </SortIconContainer>
        )}
      </Header>
    </Container>
  );
};

GameLevelsSorter.propTypes = {
  onSort: PropTypes.func.isRequired,
  value: PropTypes.string,
  option: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  handleGameLevelsSorting: PropTypes.func
};

GameLevelsSorter.defaultProps = {
  value: '',
  handleGameLevelsSorting: () => null
};

export default GameLevelsSorter;
