import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import MaterialDropDown from '../../../MaterialDropDown';
import { Wrapper, Title, DropdownLabel, DropdownContainer } from './styled-components';

const SubHeader = ({ title, name, list, focus, blur, theme, wrapperStyle }) => (
  <Wrapper wrapperStyle={wrapperStyle}>
    {list.length > 0 ? (
      <>
        <Title>{title}</Title>
        <DropdownLabel>Copy Fees From</DropdownLabel>
        <DropdownContainer>
          <Field
            component={MaterialDropDown}
            name={name}
            fieldName={name}
            placeholder="Select"
            options={list.map(t => ({
              name: `${t.game_level} - ${t.game_type}`,
              value: t.id
            }))}
            focus={focus}
            blur={blur}
            theme={theme}
          />
        </DropdownContainer>
      </>
    ) : (
      <>
        <Title>{title}</Title>
      </>
    )}
  </Wrapper>
);

SubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  focus: PropTypes.func.isRequired,
  blur: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    dropableContainer: PropTypes.object
  })
};

SubHeader.defaultProps = {
  theme: {
    dropableContainer: {}
  }
};

export default SubHeader;
