import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  StyledTextArea,
  Label,
  LabelWrapper,
  LabelMessage,
  ErrorMessage
} from './styled-components';

export const TextArea = ({
  placeholder,
  rows,
  input,
  readOnly,
  resize,
  theme,
  message,
  meta: { error, dirty },
  showError,
  innerPlaceholder,
  disabled,
  nonDirty
}) => (
  <Container>
    <LabelWrapper>
      <Label theme={theme.label}>
        {placeholder} {message && <LabelMessage>{message}</LabelMessage>}
        {((showError && error && nonDirty) || (showError && error && dirty)) && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
      </Label>
    </LabelWrapper>
    <StyledTextArea
      rows={rows}
      {...input}
      readOnly={readOnly}
      resize={resize}
      placeholder={innerPlaceholder}
      theme={theme.textArea}
      disabled={disabled}
      hasError={(showError && error && nonDirty) || (showError && error && dirty)}
    />
  </Container>
);

TextArea.propTypes = {
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  readOnly: PropTypes.bool,
  resize: PropTypes.oneOf(['both', 'horizontal', 'inherit', 'initial', 'none', 'vertical']),
  theme: PropTypes.shape({}),
  message: PropTypes.string,
  meta: PropTypes.objectOf(Object),
  showError: PropTypes.bool,
  innerPlaceholder: PropTypes.string
};

TextArea.defaultProps = {
  placeholder: '',
  rows: 5,
  readOnly: false,
  resize: 'both',
  theme: {},
  message: null,
  meta: {},
  showError: false,
  innerPlaceholder: null
};
export default TextArea;
