import React from 'react';
import PropTypes from 'prop-types';
import { always, not, isEmpty } from 'ramda';
import TimePicker from 'rc-time-picker';
import { emptyStringToNull } from '../../../utils/parsers';
import ErrorContainer from '../MaterialErrorContainer';
import {
  GroupContainer,
  BarContainer,
  HeighlightContainer,
  Label,
  TimePickerWrapper
} from './styled-components';

const MaterialTimePicker = ({
  placeholder,
  input: { value, onChange },
  meta: { touched, error, invalid },
  disabled,
  theme,
  inlineError,
  alwaysShowError
}) => {
  const hasError = (touched || alwaysShowError) && invalid && error;
  return (
    <GroupContainer theme={theme.wrapper}>
      <TimePickerWrapper theme={theme.timePickerWrapper}>
        {/* styles for TimePicker are declared globally */}
        <TimePicker
          value={emptyStringToNull(value)}
          onChange={onChange}
          showSecond={false}
          popupClassName="time-picker-bring-to-front"
          format="h:mm a"
          disabled={disabled}
          use12Hours
          inputReadOnly
        />
      </TimePickerWrapper>
      <HeighlightContainer />
      <BarContainer hasError={hasError} />
      {hasError && !inlineError && <ErrorContainer>{error}</ErrorContainer>}
      <Label hasError={hasError} hasValue={not(isEmpty(value))} theme={theme.label}>
        {placeholder}
        {inlineError && error && ` - ${error}`}
      </Label>
    </GroupContainer>
  );
};

MaterialTimePicker.propTypes = {
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string
  }),
  disabled: PropTypes.bool,
  theme: PropTypes.shape({
    wrapper: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  inlineError: PropTypes.bool,
  alwaysShowError: PropTypes.bool
};

MaterialTimePicker.defaultProps = {
  placeholder: '',
  inlineError: false,
  meta: {
    touched: false,
    error: ''
  },
  alwaysShowError: false,
  input: {
    value: '',
    onChange: always(undefined)
  },
  disabled: false,
  theme: {
    wrapper: ``,
    label: ''
  }
};

export default MaterialTimePicker;
