import React from 'react';
import PropTypes from 'prop-types';

import LoaderWrapper from '../../../LoaderWrapper';
import Button from '../../../Button';
import Icon from '../../../Icon';
import Flex from '../../../Flex';
import IncidentReport from './IncidentReport';
import Modal from '../../../Modal';
import Enhancer from '../../../../containers/IncidentReportsModal';
import { Summary, Count, ClickableLink } from '../styled-components';
import { COLORS, ICONS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';
import { FROM_REPORTS, FROM_GAMES, FROM_NOTIFICATIONS } from '../../../../../core/Modals/constants';

const IncidentReportsModal = ({
  onClose,
  onSubmit,
  onEdit,
  onDelete,
  onApprove,
  game,
  report,
  reports,
  creatingReport,
  setCreatingReport,
  inEdit,
  setInEdit,
  reportId,
  setReportId,
  deleteReportId,
  setDeleteReportId,
  isFetching,
  onBack,
  isGamesEventAdminOrAssignor,
  isReportsEventGroupAdmin,
  isReportsEventAssignor,
  isReportsEventAdmin,
  currentUserId,
  from,
  isFetchingReports
}) => {
  const viewableReports = reports.filter(
    vreport =>
      vreport.approved ||
      isGamesEventAdminOrAssignor ||
      isReportsEventGroupAdmin ||
      Number(currentUserId) === vreport.created_by_user_id
  );
  return (
    <Modal
      title="Incident Report"
      onClose={() => {
        onClose(from);
      }}
      onBack={(reportId || creatingReport || deleteReportId) && onBack}
      size={{
        xs: 8,
        sm: 8,
        md: 8,
        lg: 6
      }}
      overflow
    >
      <>
        {deleteReportId && (
          <Flex justifyContent="center" direction="column" padding="1rem" full>
            <Summary>Are you sure you want to delete this Incident Report?</Summary>
            <Flex padding="1.25rem 0 0 ">
              <Button
                width="84px"
                height="32px"
                margin="0 8px 0"
                label="Cancel"
                onClick={() => setDeleteReportId(null)}
              />
              <Button
                type="submit"
                width="84px"
                height="32px"
                margin="0 8px 0"
                label="Yes"
                onClick={() => onDelete(deleteReportId)}
                primary
              />
            </Flex>
          </Flex>
        )}

        {!deleteReportId &&
          (creatingReport || reportId || (from === FROM_REPORTS && report.id)) && (
            <IncidentReport
              onSubmit={onSubmit}
              onEdit={onEdit}
              game={game}
              report={
                from === FROM_GAMES || from === FROM_NOTIFICATIONS
                  ? viewableReports.find(vreport => vreport.id === reportId)
                  : report
              }
              onBack={onBack}
              onClose={onClose}
              onApprove={onApprove}
              isFetching={isFetching}
              isGamesEventAdminOrAssignor={isGamesEventAdminOrAssignor}
              isReportsEventAdmin={isReportsEventAdmin}
              isReportsEventAssignor={isReportsEventAssignor}
              isReportsEventGroupAdmin={isReportsEventGroupAdmin}
              from={from}
              inEdit={inEdit}
              setInEdit={setInEdit}
              isFetchingReports={isFetchingReports}
            />
          )}

        {!deleteReportId && !creatingReport && !reportId && from !== FROM_REPORTS && (
          <LoaderWrapper isFetching={isFetching} simple>
            <Flex justifyContent="center" padding="1rem 0" highlighted>
              <Flex justifyContent="space-between" maxWidth="380px" full>
                <Summary noMargin>
                  <Count color={COLORS.denimBlue}>{viewableReports.length}</Count> Incident Reports
                  were submitted for this game
                </Summary>
                <Icon
                  width={22}
                  height={22}
                  icon={ICONS.NEW}
                  color={COLORS.denimBlue}
                  onClick={() => setCreatingReport(true)}
                  clickable
                />
              </Flex>
            </Flex>
            {!!viewableReports.length && (
              <Flex padding="1rem" direction="column" alignItems="center" full>
                {viewableReports.map(vreport => (
                  <Flex
                    justifyContent="space-between"
                    padding={`0.5rem ${pxToRem('10px')} 0.5rem 0`}
                    maxWidth="380px"
                    fullWidth
                    key={vreport.id}
                  >
                    <ClickableLink onClick={() => setReportId(vreport.id)}>
                      {vreport.report_type} - {vreport.report_on}
                    </ClickableLink>
                    <Flex>
                      {vreport.approved ? (
                        <Icon
                          width={20}
                          height={20}
                          icon={ICONS.CHECK_CIRCLE_HOLLOW}
                          color={COLORS.forestGreen}
                          noMargin
                        />
                      ) : (
                        <Icon
                          width={20}
                          height={20}
                          icon={ICONS.TRASH}
                          color={COLORS.hawkesBlue}
                          onClick={() => setDeleteReportId(vreport.id)}
                          clickable
                          noMargin
                        />
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            )}
          </LoaderWrapper>
        )}
      </>
    </Modal>
  );
};

IncidentReportsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  game: PropTypes.shape({
    id: PropTypes.string
  }),
  report: PropTypes.shape({
    id: PropTypes.string
  }),
  from: PropTypes.string,
  reports: PropTypes.arrayOf(Object),
  creatingReport: PropTypes.bool,
  setCreatingReport: PropTypes.func.isRequired,
  inEdit: PropTypes.bool,
  setInEdit: PropTypes.func.isRequired,
  reportId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setReportId: PropTypes.func.isRequired,
  deleteReportId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setDeleteReportId: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  isGamesEventAdminOrAssignor: PropTypes.bool,
  isReportsEventAssignor: PropTypes.bool,
  isReportsEventAdmin: PropTypes.bool,
  currentUserId: PropTypes.string
};

IncidentReportsModal.defaultProps = {
  reports: [],
  game: { id: null },
  report: { id: null },
  creatingReport: false,
  inEdit: false,
  reportId: null,
  deleteReportId: null,
  isFetching: false,
  isGamesEventAdminOrAssignor: false,
  isReportsEventAdmin: false,
  isReportsEventAssignor: false,
  currentUserId: null,
  from: FROM_GAMES
};

export default Enhancer(IncidentReportsModal);
