import { compose, pure, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import {
  fetchGroups,
  fetchEventBillingStatements as fetchEventBillingStatementsAction,
  setPastEventBillingStatements as setPastEventBillingStatementsAction,
  setDueDatetEventBillingStatements as setDueDatetEventBillingStatementsAction,
  setRecentEventBillingStatements as setRecentEventBillingStatementsAction
} from '../../../../core/Auth';
import { getGroups } from '../../../../core/Auth/selectors';
import { getPlatformSubscriptions, getCurrentPlatform } from '../../../../core/Platform/selectors';
import {
  fetchProductInvoices,
  fetchPlatformSubscriptions,
  setGroup,
  setPlatformSubscriptions as setPlatformSubscriptionsAction
} from '../../../../core/Platform/actions';
import { EVENT_BILLING_STATEMENT_TYPES } from '../../../../constants';
import { sInterpolator as routeBuilder } from '../../../../utils/helpers';
import {
  setGroupPageRedirectLabel,
  setGroupPageRedirectPath,
  userIdxGetter
} from '../../../../utils/auth';
import * as paths from '../../../../core/paths';
import config from '../config';

const mapStateToProps = state => ({
  groups: getGroups(state),
  platformId: getCurrentPlatform(state),
  isFetching: state.auth.isFetching,
  platformSubscriptions: getPlatformSubscriptions(state)
});

const mapDispatchToProps = dispatch => ({
  fetchGroups: payload => dispatch(fetchGroups(payload)),
  setGroup: group => dispatch(setGroup(group)),
  fetchEventBillingStatements: payload => dispatch(fetchEventBillingStatementsAction(payload)),
  fetchInvoices: payload => dispatch(fetchProductInvoices(payload)),
  fetchSubscriptions: payload => dispatch(fetchPlatformSubscriptions(payload)),
  setPlatformSubscriptions: payload => dispatch(setPlatformSubscriptionsAction(payload)),
  setPastEventBillingStatements: payload => dispatch(setPastEventBillingStatementsAction(payload)),
  setDueDatetEventBillingStatements: payload =>
    dispatch(setDueDatetEventBillingStatementsAction(payload)),
  setRecentEventBillingStatements: payload =>
    dispatch(setRecentEventBillingStatementsAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    getStatements: ({ fetchEventBillingStatements, fetchSubscriptions }) => platformId => {
      EVENT_BILLING_STATEMENT_TYPES.forEach(type => {
        fetchEventBillingStatements({
          type,
          platformId
        });
      });
      fetchSubscriptions({ platformId });
    },
    onViewGroupInfo: () => groupId => {
      const userIdx = userIdxGetter();

      setGroupPageRedirectPath({ path: routeBuilder({ userIdx }, paths.DASHBOARD) });
      setGroupPageRedirectLabel({ label: 'Back to Event Billing' });
      return routeBuilder({ userIdx, groupId }, paths.GROUP_INFO);
    },
    removeStatements: ({
      setPlatformSubscriptions,
      setPastEventBillingStatements,
      setDueDatetEventBillingStatements,
      setRecentEventBillingStatements
    }) => () => {
      setPlatformSubscriptions();
      setPastEventBillingStatements();
      setDueDatetEventBillingStatements();
      setRecentEventBillingStatements();
    }
  }),
  lifecycle({
    componentDidMount() {
      const myEvents = 'My Events';
      this.props.fetchGroups({});
      if (this.props.platformId && this.props.platformId !== myEvents) {
        this.props.getStatements(this.props.platformId);
      }
    },
    componentWillReceiveProps(nextProps) {
      if (
        !this.props.platformSubscriptions.data &&
        nextProps.platformSubscriptions.data &&
        nextProps.platformSubscriptions.data.length
      ) {
        const self = this;
        Object.keys(config).forEach(product => {
          const subscription = nextProps.platformSubscriptions.data.find(
            elem => elem.order_product.product === product
          );
          if (subscription) {
            self.props.fetchInvoices({
              product,
              platformId: this.props.platformId,
              config: config[product](subscription)
            });
          }
        });
      }
    }
  }),
  pure
);
