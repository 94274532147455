import { compose, withProps } from 'recompose';

import MODAL_NAMES from '../../../core/Modals/constants';

export default compose(
  withProps(({ IncidentReportsModal, GameReportModal, CrewVerificationReportModal }) => ({
    modals: [
      {
        component: IncidentReportsModal,
        name: MODAL_NAMES.incidentReportsModal
      },
      {
        component: GameReportModal,
        name: MODAL_NAMES.gameReportsModal
      },
      {
        component: CrewVerificationReportModal,
        name: MODAL_NAMES.crewVerificationReportsModal
      }
    ]
  }))
);
