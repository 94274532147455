import { compose, withState, withHandlers, lifecycle } from 'recompose';
import FileSaver from 'file-saver';

import { formatDate } from '../../../../utils/parsers';

export default compose(
  withState('showEmailSelectedModal', 'toggleShowEmailSelectedModal', false),
  withState('showRemoveSelectedModal', 'toggleShowRemoveSelectedModal', false),
  withState('showCopyUserModal', 'toggleShowCopyUserModal', false),
  withState('showImportModal', 'toggleShowImportModal', false),
  withState('showCategoryAssignorModal', 'toggleCategoryAssignorModal', false),
  withState('selectedCategories', 'setSelectedCategories', []),
  withState('showAddNewUserModal', 'toggleAddNewUserModal', false),
  withState('showAddRankModal', 'toggleShowAddRankModal', false),
  withState('eventsPageActive', 'setEventsPageActive', true),
  withHandlers({
    onUserImport: ({
      match: {
        params: { eventId }
      },
      submitUserImport,
      pagination
    }) => ({ file, resolve }) =>
      submitUserImport({ eventId, file, resolve, roles: ['assessors'], pagination }),
    onRemoveSelected: ({
      match: {
        params: { eventId }
      },
      removeSelectedUsers,
      roles,
      toggleShowRemoveSelectedModal,
      pagination
    }) => (selected, allSelect) => {
      toggleShowRemoveSelectedModal(false);
      return removeSelectedUsers({
        eventId,
        roles,
        pagination,
        selected,
        allSelect
      });
    },
    onAddRank: ({
      match: {
        params: { eventId }
      },
      addRank,
      toggleShowAddRankModal
    }) => ({ selected, rank, allSelect, role_ids }) => {
      toggleShowAddRankModal(false);
      const context = {
        rank,
        selected
      };

      if (allSelect) {
        context.role_ids = role_ids;
        context.event_id = eventId;
      }

      return addRank(context);
    },
    downloadCSV: ({
      match: {
        params: { eventId }
      },
      pagination: { onLoadPage }
    }) => () =>
      new Promise(resolve =>
        onLoadPage({
          eventId,
          roles: { alias: 'assessor', list: ['Assessor'] },
          toCSV: true,
          resolve
        })
      ).then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' });
        FileSaver.saveAs(blob, `assessor_list_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
      }),
    onAddUserManually: ({
      match: {
        params: { eventId }
      },
      submitUserManually
    }) => ({ email, first_name, last_name, role }) => {
      submitUserManually({
        eventId,
        user: {
          email,
          first_name,
          last_name
        },
        role
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchEvent(
        this.props && this.props.match && this.props.match.params && this.props.match.params.eventId
      );
    }
  })
);
