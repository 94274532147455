import styled from 'styled-components';

import { COLORS, mediaQueries } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pxToRem('376px')};
  background-image: linear-gradient(to bottom, ${COLORS.aliceBlue}, ${COLORS.solitude});
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
  ${mediaQueries.sm(`
  margin-top:${pxToRem('20px')}
   `)};

  ${mediaQueries.xs(`
  width: ${pxToRem('280px')};
   `)};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: ${pxToRem('72px')};
  padding: 0 ${pxToRem('24px')};
  background-image: linear-gradient(to bottom, ${COLORS.aliceBlue}, ${COLORS.solitude});
`;

export const Image = styled.img`
  height: ${({ imageHeight }) => imageHeight};
  max-width: 100%;
  margin-right: ${pxToRem('15px')};
`;

export const Title = styled.span`
  font: 500 ${pxToRem('18px')} 'RobotoMedium', sans-serif;
  color: ${COLORS.cobalt};
  ${mediaQueries.sm(`
  font: 500 ${pxToRem('15px')} 'RobotoMedium', sans-serif;
  `)};
`;

export const Content = styled.div`
  padding: ${pxToRem('16px')} ${pxToRem('30px')} ${pxToRem('20px')};
  box-shadow: inset 0 24px 22px -12px rgba(2, 32, 80, 0.3);
`;

export const EmptyMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyMessage = styled.p`
  margin: 0;
`;

export const HelpLinkContainer = styled.div``;

export const HelpLink = styled.a`
  display: inline-block;
  font: 400 ${pxToRem('15px')} 'Roboto', sans-serif;
  color: ${COLORS.denimBlue};
  text-decoration: underline;

  ${HelpLinkContainer} > & {
    margin-bottom: ${pxToRem('5px')};
  }
`;

export default Container;
