import React from 'react';
import PropTypes from 'prop-types';
import Sorter from '../../../Sorter';
import { COLORS } from '../../../../../utils/styles';

const SideListSorter = ({ onSort, value, config }) => (
  <Sorter
    minWidth={95}
    theme={{
      sorterHeaderBg: COLORS.lightCyanGray,
      sorterLabelColor: COLORS.nightRider,
      chevronDownColor: COLORS.nightRider,
      optionBg: COLORS.white,
      optionColor: COLORS.hawkesBlue,
      optionHoverBg: COLORS.aliceBlueVariant,
      optionHoverColor: COLORS.denimBlue,
      clearButtonColor: COLORS.lightCyanGray
    }}
    options={config.options}
    onSort={onSort}
    value={value || 'name'}
  />
);

SideListSorter.propTypes = {
  config: PropTypes.shape({
    options: PropTypes.array
  }).isRequired,
  onSort: PropTypes.func.isRequired,
  value: PropTypes.string
};
SideListSorter.defaultProps = {
  value: ''
};
export default SideListSorter;
