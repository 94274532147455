import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export const LineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Rectangle = styled.label`
  position: relative;
  display: inline-block;
  width: 31px;
  height: 31px;
  cursor: pointer;
`;

export const Input = styled.input`
  visibility: hidden;
  position: absolute;
  left: 7px;
  bottom: 7px;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  opacity: 0;
`;

export const Label = styled.label`
  color: ${COLORS.silverSand};
  font-family: 'FiraSans';
  font-size: 0.7rem;
  padding-top: 0.5rem;
`;

export const Span = styled.span`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: -9px;

  ${Input} + &:before {
    content: ${({ indeterminate }) => (indeterminate ? "'\\e909'" : "'\\e835'")};
    color: ${({ indeterminate }) => (indeterminate ? `${COLORS.cerulean}` : `${COLORS.iron}`)};
  }

  ${Input}:checked + &:before {
    content: '\\e834';
    color: ${COLORS.cerulean};
  }

  ${Input}:active:not(:disabled) + &:before {
    transform: scale3d(0.88, 0.88, 1);
  }

  ${Input}:disabled + &:before {
    color: rgba(0, 0, 0, 0.157) !important;
  }

  ${Input} + &:before {
    position: absolute;
    left: -2px;
    bottom: 7px;
    width: 18px;
    height: 18px;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    vertical-align: -6px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    transition: all 0.2s ease;
    z-index: 1;
  }
`;
