import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

export default {
  multiSelectDropdown: {
    wrapper: `
      background: transparent;
    `,
    header: `
      background: transparent;
      color: ${COLORS.white};
      box-shadow: inset 0 -1px 0 0 ${COLORS.white}88;

      &.hasValue {
        box-shadow: inset 0 -2px 0 0 ${COLORS.white};
      }
    `,
    chevron: {
      color: COLORS.white
    },
    option: `
      background-color: ${COLORS.bahamaBlue};
      color: ${COLORS.white};

      &:hover {
        background-color: ${COLORS.sapphire};
      }
    `,
    options: `
      &::-webkit-scrollbar {
        background-color: ${COLORS.bahamaBlue};
      }

      &::-webkit-scrollbar-thumb {
        background: ${COLORS.sapphire};
      }
    `,
    checkColor: COLORS.white
  },
  materialDatePicker: {
    wrapper: 'background: transparent',
    input: `
      background: transparent;
      color: ${COLORS.white};
      box-shadow: inset 0 -1px 0 0 ${COLORS.white}88;
      border: none;

      &.hasValue {
        box-shadow: inset 0 -2px 0 0 ${COLORS.white};
      }

      &.hasError {
        box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed};
      }
    `,
    label: `
      color: ${COLORS.white};
      font-size: ${pxToRem('14px')};
      font-family: 'RobotoLight';
    `,
    icon: COLORS.white
  }
};
