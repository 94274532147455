import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function ghostButton({ children, ...rest }) {
  return (
    <Button color="primary" {...rest}>
      {children}
    </Button>
  );
}

ghostButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired
};
