import { getTimezoneAbbr } from '../../../../../utils/helpers';
import { formatDate } from '../../../../../utils/parsers';
import COLORS from '../../../../../utils/styles';
import CheckField from '../../../CheckField';
import { MODAL_STATUSES } from '../constants';

export const getDisableCrew = ({ currentPosition, settingPositions }) => {
  if (settingPositions[currentPosition]) {
    return false;
  }
  return true;
};

export const generateCrewLabels = ({ game, crewLabels, currentUserId, reportSettings }) => {
  let all_active = false;
  const gameCrews =
    game.game_level &&
    game.game_level.labels &&
    game.game_level.labels.map((label, i) => ({ label, role_id: game.game_level.role_ids[i] }));
  const labels = (gameCrews || crewLabels).map(crew => {
    return {
      label: crew.label,
      user: false
    };
  });

  const settingPositions = [
    reportSettings && reportSettings.checkin_position_1,
    reportSettings && reportSettings.checkin_position_2,
    reportSettings && reportSettings.checkin_position_3,
    reportSettings && reportSettings.checkin_position_4,
    reportSettings && reportSettings.checkin_position_5,
    reportSettings && reportSettings.checkin_position_6,
    reportSettings && reportSettings.checkin_position_7,
    reportSettings && reportSettings.checkin_position_8
  ];

  (game.crew || game.assignments_game).forEach(assignment_game => {
    const user = (assignment_game.event_role && assignment_game.event_role.user) || assignment_game;
    labels[assignment_game.official_label_col] = {
      label: `${labels[assignment_game.official_label_col].label} - ${user.first_name} ${
        user.last_name
      }`,
      user: true,
      disabled: Number(user.id) !== Number(currentUserId),
      user_id: user.id,
      game_assignment_id: assignment_game.game_assignment_id || assignment_game.id
    };
    if (
      Number(user.id) === Number(currentUserId) &&
      settingPositions[assignment_game.official_label_col]
    ) {
      all_active = true;
    }
  });

  return { all_active, labels };
};

export const generateAttendance = report => {
  const attendance = {
    crew_checkin_position_0: false,
    crew_checkin_position_1: false,
    crew_checkin_position_2: false,
    crew_checkin_position_3: false,
    crew_checkin_position_4: false,
    crew_checkin_position_5: false,
    crew_checkin_position_6: false,
    crew_checkin_position_7: false
  };
  if (report) {
    report.forEach(a => {
      if (a.game_assignment) {
        attendance[`crew_checkin_position_${a.game_assignment.official_label_col}`] = true;
      }
    });
  }

  return attendance;
};
export const generateGameInfo = ({ game, display_id }) => {
  return [
    {
      label: 'Game ID',
      value: display_id
    },
    {
      label: 'Date & Time',
      value: `${formatDate(game.start_date, 'dddd, MMMM Do YYYY')} -  ${
        game.start_time
      } (${getTimezoneAbbr(game.start_date, game.timezone)})`
    },
    {
      label: 'Level & League',
      value: `${game.game_level.game_level} - ${game.game_level.game_type} (${game.schedule_owner})`
    },
    {
      label: 'Location',
      value: `${game.location.name} (${game.location.city})`
    },
    {
      label: 'Home Team',
      value: game.team_home.name
    },
    {
      label: 'Away Team',
      value: game.team_visitor.name
    },

    {
      label: 'Home Score',
      value:
        game.report_teams &&
        game.report_teams
          .filter(t => t.type === 'home')
          .map(t => `${t.score}`)
          .join(', ')
    },
    {
      label: 'Away Score',
      value:
        game.report_teams &&
        game.report_teams
          .filter(t => t.type === 'away')
          .map(t => `${t.score}`)
          .join(', ')
    },
    {
      label: 'Crew Members',
      value:
        (game.crew &&
          game.crew
            .filter(({ role_id }) => role_id === 5 || role_id === 4)
            .map(({ first_name, last_name }) => `${first_name} ${last_name}`)
            .join(', ')) ||
        (game.assignments_game &&
          game.assignments_game
            .filter(({ event_role: { role_id } }) => role_id === 5 || role_id === 4)
            .map(({ event_role: { user } }) => `${user.first_name} ${user.last_name}`)
            .join(', '))
    }
  ];
};

const checkDisabled = ({
  in_batch,
  all_active_crew,
  is_user,
  game_assignment_id,
  batch_pay,
  disabled,
  attendance
}) => {
  if (in_batch && attendance) {
    return true;
  }
  if (
    batch_pay &&
    is_user &&
    attendance &&
    batch_pay.find(b => b && Number(b.game_assignment_id) === Number(game_assignment_id))
  ) {
    return true;
  }
  if (all_active_crew && is_user) {
    return false;
  }
  if (is_user && disabled) {
    return disabled;
  }
  if (!is_user) {
    return true;
  }
  return disabled;
};

const removeExtraCharacter = (l, limit) => {
  if (l.length > limit) {
    return `${l.slice(0, limit)}...`;
  }
  return l;
};

export default ({ labels, all_active_crew, in_batch, batch_pay, attendance }) => {
  return {
    fields: new Array(8).fill(null).map((i, idx) => {
      const current = labels && labels[idx] && labels[idx];
      return {
        component: CheckField,
        key: idx,
        name: `crew_checkin_position_${idx}`,
        disabled: checkDisabled({
          in_batch,
          all_active_crew,
          is_user: current.user,
          disabled: current.disabled,
          game_assignment_id: current.game_assignment_id,
          batch_pay,
          attendance: attendance && attendance[`crew_checkin_position_${idx}`]
        }),
        placeholder: removeExtraCharacter(current.label, 24),
        padding: '0 0 0 0.2rem'
      };
    })
  };
};

export const getCVRIconColors = ({ status }) => {
  switch (status) {
    case MODAL_STATUSES.NOT:
      return {
        icon: COLORS.denimBlue,
        iconBG: COLORS.blueGray
      };

    case MODAL_STATUSES.PARTIAL:
      return {
        icon: COLORS.white,
        iconBG: COLORS.yellow
      };

    case MODAL_STATUSES.COMPLETE:
      return {
        icon: COLORS.white,
        iconBG: COLORS.forestGreen
      };

    default:
      return {
        icon: COLORS.denimBlue,
        iconBG: COLORS.blueGray
      };
  }
};
