import styled from 'styled-components';

export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const DatePickerWrapper = styled.div`
  width: 60%;
  min-height: 10rem;
  min-width: 30rem;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  align-self: center;
`;

export const PaymentBodyWrapper = styled.div`
  height: auto;
  overflow-y: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
`;
