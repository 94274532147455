import { combineReducers } from 'redux';
import { equals } from 'ramda';

import auth, { LOGOUT } from './Auth';
import notifications from './Notifications';
import progress from './Progress';
import certs from './Certifications';
import events from './Events';
import sports from './Sports';
import roles from './Roles';
import users from './Users';
import games from './Games';
import teams from './Teams';
import locations from './Locations';
import user from './User';
import modals from './Modals';
import reports from './Reports';
import payments from './Payments';
import platform from './Platform';
import billingCustomers from './BillingCustomers';

/**
 *
 * Combine all reducers in this file and export the combined reducers.
 *
 * @return {object} combined reducers
 */
const appReducer = combineReducers({
  auth,
  notifications,
  progress,
  certs,
  events,
  sports,
  roles,
  users,
  games,
  teams,
  locations,
  user,
  modals,
  reports,
  payments,
  platform,
  billingCustomers
});

// Upon logout reset reducers
const rootReducer = (state, action) =>
  action && equals(LOGOUT, action.type) ? appReducer(undefined, action) : appReducer(state, action);

export default rootReducer;
