import React, { useEffect } from 'react';
import { useElements } from '@stripe/react-stripe-js';
import { Row, Col } from 'react-flexbox-grid';
import { isEmpty } from 'ramda';
import stripe from '../../../utils/stripe';
import PaymentFieldSection from './billingInfo';
import { AddBtn, GhostContainer } from './style-components';
import AddElement from './cardElement/cardElement';
import { COLORS } from '../../../utils/styles';
import { requiredValidationError } from '../../../utils/helpers';

const AddCard = ({ billingInfo, setBillingInfo, addNotifications, addCard }) => {
  const [cardNumberComplete, setCardNumberComplete] = React.useState(true);
  const [cardCvcComplete, setCardCvcComplete] = React.useState(true);
  const [cardExpiryComplete, setCardExpiryComplete] = React.useState(true);
  const [cardPostalComplete, setCardPostalComplete] = React.useState(true);
  const [isEmptypostalCode, setIsEmptypostalCode] = React.useState(true);
  const [isEmptyCardNo, setIsEmptyCardNo] = React.useState(true);
  const [isEmptyCardCvc, setIsEmptyCardCvc] = React.useState(true);
  const [isEmptyCardExp, setIsEmptyCardExp] = React.useState(true);
  const elements = useElements({});

  const CARD_ELEMENTS = {
    number: 'cardNumber',
    cvc: 'cardCvc',
    expiry: 'cardExpiry',
    postalCode: 'postalCode'
  };
  const cardDisabled = () => {
    const { line1, city, state, country } = billingInfo.address;
    return (
      !billingInfo ||
      requiredValidationError(billingInfo.name) ||
      !billingInfo.address ||
      requiredValidationError(line1) ||
      requiredValidationError(city) ||
      requiredValidationError(state) ||
      !country ||
      cardPostalComplete ||
      cardExpiryComplete ||
      cardCvcComplete ||
      cardNumberComplete
    );
  };

  const style = {
    base: {
      iconColor: '#666EE8',
      color: '#31325F',
      lineHeight: '40px',
      fontWeight: 500,
      fontSize: '15px',
      '::placeholder': {
        color: '#CFD7E0',
        fontSize: '15px'
      }
    }
  };

  const postalStyle = {
    base: {
      iconColor: '#666EE8',
      color: '#31325F',
      lineHeight: '40px',
      fontWeight: 500,
      fontSize: '15px'
    }
  };

  const POSTAL_FOCUS_STYLE = {
    transform: 'translate(0px, 0.15rem)',
    'transition-duration': '0.4s',
    'font-size': '14px',
    color: 'rgba(0, 0, 0, 0.54)',
    height: '20px',
    padding: '10px 4px'
  };

  const POSTAL_BLUR_STYLE = {
    transform: 'translate(0px, -0.7rem)',
    'transition-duration': '0.4s',
    'font-size': '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
    height: '10px',
    padding: '3px 4px'
  };
  const errorNum = {
    color: '#e74c3c',
    fontSize: ' 0.7rem',
    position: 'relative',
    boxShadow: 'none !important'
  };

  function setStyle(objId, propertyObject) {
    const elem = document.getElementById(objId);
    Object.keys(propertyObject).map(key => {
      elem.style[key] = propertyObject[key];
      return 0;
    });
  }

  useEffect(() => {
    let postalCode = true;
    const cardcvc = elements.create(CARD_ELEMENTS.cvc, { style });
    const cardexp = elements.create(CARD_ELEMENTS.expiry, { style });
    const cardno = elements.create(CARD_ELEMENTS.number, {
      placeholder: 'Card Number',
      iconStyle: 'solid',
      showIcon: true,
      style
    });
    const cardpostal = elements.create(CARD_ELEMENTS.postalCode, {
      placeholder: 'PostalCode/Zip',
      style: postalStyle
    });

    // mount
    cardno.mount('#card-number');
    cardexp.mount('#card-Expiry');
    cardcvc.mount('#card-cvc');
    cardpostal.mount('#card-postal');

    // onchange
    cardno.on('change', function (event) {
      setIsEmptyCardNo(event.empty);
      return event.complete ? setCardNumberComplete(false) : setCardNumberComplete(true);
    });

    cardexp.on('change', function (event) {
      setIsEmptyCardExp(event.empty);
      return event.complete ? setCardCvcComplete(false) : setCardCvcComplete(true);
    });

    cardcvc.on('change', function (event) {
      setIsEmptyCardCvc(event.empty);
      return event.complete ? setCardExpiryComplete(false) : setCardExpiryComplete(true);
    });

    cardpostal.on('change', function (event) {
      postalCode = !event.value || (event.value && isEmpty(event.value.trim()));
      setIsEmptypostalCode(!event.value || (event.value && isEmpty(event.value.trim())));
      return event.complete ? setCardPostalComplete(false) : setCardPostalComplete(true);
    });

    cardpostal.on('focus', function () {
      setStyle('postalLabel', {
        ...POSTAL_BLUR_STYLE,
        ...{ color: postalCode ? COLORS.errorRed : '#3F7CD4' }
      });

      setStyle('card-postal', {
        border: postalCode ? `1px solid ${COLORS.errorRed}` : '2px solid #3F7CD4'
      });
    });

    cardpostal.on('blur', function () {
      if (postalCode) {
        setStyle('card-postal', {
          border: postalCode ? `1px solid ${COLORS.errorRed}` : '1px solid #ccc'
        });
        setStyle('postalLabel', POSTAL_FOCUS_STYLE);
      } else {
        setStyle('postalLabel', POSTAL_BLUR_STYLE);

        setStyle('card-postal', {
          border: postalCode ? `1px solid ${COLORS.errorRed}` : '1px solid #ccc'
        });
      }
    });

    return () => {
      cardpostal.destroy();
      cardexp.destroy();
      cardcvc.destroy();
      cardno.destroy();
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = name => evt => {
    if (evt.target && evt.target.tagName === 'SELECT') {
      setBillingInfo({
        ...billingInfo,
        address: {
          ...billingInfo.address,
          [name]: evt.target.options[evt.target.selectedIndex].value
        }
      });
    } else if (name === 'name') {
      setBillingInfo({
        ...billingInfo,
        [name]: evt.target.value
      });
    } else {
      setBillingInfo({
        ...billingInfo,
        address: {
          ...billingInfo.address,
          [name]: evt.target.value
        }
      });
    }
  };

  return (
    <>
      <AddElement />
      <div style={{ position: 'relative' }}>
        <Row>
          <Col xs={7}>
            <div style={errorNum}>{isEmptyCardNo && <span>Required</span>}</div>
          </Col>
          <Col xs={3}>
            <div style={errorNum}>{isEmptyCardExp && <span>Required</span>}</div>
          </Col>

          <Col xs={2}>
            <div style={errorNum}>{isEmptyCardCvc && <span>Required</span>}</div>
          </Col>
        </Row>
      </div>
      <PaymentFieldSection
        handleChange={handleChange}
        billingInfo={billingInfo}
        isEmptypostalCode={isEmptypostalCode}
      />

      <GhostContainer>
        <AddBtn
          disabled={cardDisabled()}
          onClick={() => {
            const { line1, line2, city, state, country } = billingInfo.address;
            const card_info = {
              name: billingInfo.name || '',
              address_line1: line1 || '',
              address_line2: line2 || '',
              address_city: city || '',
              address_state: state || '',
              address_country: country || ''
            };
            stripe
              .createToken(
                elements.getElement(
                  CARD_ELEMENTS.number,
                  CARD_ELEMENTS.cvc,
                  CARD_ELEMENTS.postalCode,
                  CARD_ELEMENTS.expiry
                ),
                card_info
              )
              .then(payload => {
                setBillingInfo({
                  name: null,
                  address: {
                    line1: null,
                    line2: null,
                    city: null,
                    state: null,
                    country: 'US'
                  }
                });
                if (payload.error) {
                  return addNotifications([
                    {
                      type: 'error',
                      message: payload.error.message
                    }
                  ]);
                }
                if (payload.token) {
                  return addCard({ ...payload.token });
                }
                return null;
              });
          }}
        >
          ADD CARD
        </AddBtn>
      </GhostContainer>
    </>
  );
};

export default AddCard;
