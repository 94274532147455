import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  fetchMenuNotifications as fetchMenuNotificationsAction,
  updateNotification as updateNotificationAction
} from '../../../core/Notifications';
import { getIsFetching, getMenuNotifications } from '../../../core/Notifications/selectors';

import unconnected from './unconnected';
import { setPaymentsView, setSelectedPaymentsEvent } from '../../../core/Payments';
import { getUser } from '../../../core/Auth/selectors';

const mapStateToProps = state => ({
  notifications: getMenuNotifications(state),
  isFetching: getIsFetching(state),
  userId: getUser(state).id
});

const mapDispatchToProps = dispatch => ({
  fetchMenuNotifications: payload => dispatch(fetchMenuNotificationsAction(payload)),
  updateNotification: payload => dispatch(updateNotificationAction(payload)),
  setPaymentsView: payload => dispatch(setPaymentsView(payload)),
  setSelectedPaymentsEvent: payload => dispatch(setSelectedPaymentsEvent(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
