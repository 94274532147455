import React from 'react';
import { Divider } from '@material-ui/core';

import { SectionContainer } from './styled-components';
import { PrimaryButton, Dialog, H3 } from '../../global-material-components';

export default function Buttons() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [fullwidthOpen, setFullWidthOpen] = React.useState(false);
  const [noTitleOpen, setNoTitleOpen] = React.useState(false);

  return (
    <>
      <H3>Modals</H3>
      <Divider />
      <SectionContainer>
        <PrimaryButton onClick={() => setDialogOpen(!dialogOpen)} style={{ marginRight: '20px' }}>
          Simple Modal
        </PrimaryButton>
        <Dialog
          title="Simple Modal"
          onClose={() => setDialogOpen(false)}
          open={dialogOpen}
          cancelAction={() => setDialogOpen(false)}
          primaryAction={() => setDialogOpen(false)}
          primaryButtonText="Confirm"
        >
          <div style={{ padding: '20px' }}>This is a simple Dialog Modal.</div>
        </Dialog>

        <PrimaryButton
          onClick={() => setFullWidthOpen(!fullwidthOpen)}
          style={{ marginRight: '20px' }}
        >
          Fullwidth Modal
        </PrimaryButton>
        <Dialog
          title="Fullwidth Modal"
          fullWidth
          onClose={() => setFullWidthOpen(false)}
          open={fullwidthOpen}
          cancelAction={() => setFullWidthOpen(false)}
          primaryAction={() => setFullWidthOpen(false)}
          primaryButtonText="Confirm"
        >
          <div style={{ padding: '20px' }}>This is a Dialog Modal with the fullWidth option.</div>
        </Dialog>

        <PrimaryButton onClick={() => setNoTitleOpen(!noTitleOpen)} style={{ marginRight: '20px' }}>
          No Title Modal
        </PrimaryButton>
        <Dialog
          noTitle
          onClose={() => setNoTitleOpen(false)}
          open={noTitleOpen}
          cancelAction={() => setNoTitleOpen(false)}
          primaryAction={() => setNoTitleOpen(false)}
          primaryButtonText="Confirm"
        >
          <div style={{ padding: '20px' }}>This is a Dialog Modal with no title.</div>
        </Dialog>
      </SectionContainer>

      {/* Add Drawers Here as well */}
    </>
  );
}
