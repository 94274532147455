import React from 'react';
import { Divider } from '@material-ui/core';

import { SectionContainer } from './styled-components';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  Headline,
  BodyText,
  Subheading,
  Caption1,
  Caption2
} from '../../global-material-components';

export default function Buttons() {
  return (
    <>
      <H3>Typography</H3>
      <Divider />
      <SectionContainer>
        <H1>H1</H1>
        <H2>H2</H2>
        <H3>H3</H3>
        <H4>H4</H4>
        <H5>H5</H5>
        <Headline>Headline</Headline>
        <BodyText>Body</BodyText>
        <Subheading>Subheading</Subheading>
        <Caption1>Caption1</Caption1>
        <Caption2>Caption2</Caption2>
      </SectionContainer>
    </>
  );
}
