import moment from 'moment';
import { isNil } from 'ramda';
import { convertToUtc } from './helpers';
import { ASSIGNMENT_STATUS } from '../core/Games/constants';
import ROLE_IDS from '../core/Roles/roleConstants';
import { ASSESSOR_MIN_GRADE, CORE_ROLES, GAMES_STATUSES, OFFICIAL_MIN_GRADE } from '../constants';

export const fetchCategory = (
  categories,
  gameState,
  gamePostalCode,
  gameTeamHome,
  gameTeamVisitor,
  gameLocation
) =>
  categories.some(
    category =>
      (category.states && category.states.includes(gameState.toUpperCase())) ||
      (category.postal_codes && category.postal_codes.includes(gamePostalCode.toUpperCase())) ||
      (category.teams && category.teams.includes(gameTeamHome)) ||
      (category.teams && category.teams.includes(gameTeamVisitor)) ||
      (category.locations && category.locations.includes(gameLocation))
  );

export const isMaxGamePerDayExceeded = (
  maxGamePerDay,
  startDate,
  gameAssignments,
  gamesEventId
) => {
  const gameDate = moment.utc(startDate).format('YYYY-MM-DD');
  const gameDateAssignmentCount = gameAssignments.reduce((acc, gameAssignment) => {
    const assignmentGameDate =
      gameAssignment.game &&
      gameAssignment.game.start_at &&
      moment.utc(gameAssignment.game.start_at).format('YYYY-MM-DD');
    if (assignmentGameDate === gameDate && gamesEventId === gameAssignment.event_id) {
      return acc + 1;
    }
    return acc;
  }, 0);
  return gameDateAssignmentCount >= maxGamePerDay;
};

export const isMaxGamePer24hExceeded = (
  maxGamePer24h,
  startDate,
  startTime,
  gameAssignments,
  gamesEventId
) => {
  const time24hBeforeGame = moment.utc(`${startDate} ${startTime}`).subtract(1, 'days');
  const time24hAfterGame = moment.utc(`${startDate} ${startTime}`).add(1, 'days');

  const timeDiffs = gameAssignments.reduce(
    (acc, assignment) => {
      const assignmentGameDate =
        assignment.game && assignment.game.start_at && moment(assignment.game.start_at);
      if (
        assignmentGameDate >= time24hBeforeGame &&
        assignmentGameDate <= time24hAfterGame &&
        assignment.event_id === gamesEventId
      ) {
        const duration = moment.duration(assignmentGameDate.diff(time24hBeforeGame));
        if (duration !== 24) acc.push(duration.asHours());
      }
      return acc;
    },
    [24]
  );

  timeDiffs.sort();

  if (timeDiffs.length <= maxGamePer24h) return false;

  let timeWindowStart = 0;
  let timeWindowEnd = 0;

  while (timeWindowEnd < timeDiffs.length) {
    if (timeWindowStart === timeWindowEnd) {
      timeWindowEnd += 1;
    }
    if (timeDiffs[timeWindowEnd] - timeDiffs[timeWindowStart] > 24) {
      timeWindowStart += 1;
    } else {
      if (timeWindowEnd - timeWindowStart + 1 > maxGamePer24h) return true;
      timeWindowEnd += 1;
    }
  }
  return false;
};

// Get filtered games on the basis of assignment status.
export const applyAssignmentFilter = (games, assignmentFilter) => {
  switch (assignmentFilter) {
    case ASSIGNMENT_STATUS.unassignedOff:
    case ASSIGNMENT_STATUS.unassignedAss: {
      const roleId =
        assignmentFilter === ASSIGNMENT_STATUS.unassignedOff
          ? ROLE_IDS.OFFICIAL
          : ROLE_IDS.ASSESSOR;
      const filteredGames = games.filter(game => {
        const result = game.game_level.role_ids.reduce((acc, value, index) => {
          if (value === roleId) {
            acc.push(index);
          }
          return acc;
        }, []);
        game.assignments_game.map(ass => {
          if (result.includes(ass.official_label_col)) {
            const index = result.indexOf(ass.official_label_col);
            if (index > -1) {
              result.splice(index, 1);
            }
          }
          return result;
        });
        if (result.length !== 0) {
          return game;
        }
        return null;
      });
      return filteredGames;
    }
    case ASSIGNMENT_STATUS.unpublished: {
      const filteredGames =
        games.length &&
        games.filter(({ assignments_game }) => {
          const unpublishedAssignment = assignments_game.filter(assignment => {
            return assignment.status === ASSIGNMENT_STATUS.unpublished;
          });
          return unpublishedAssignment.length;
        });
      return filteredGames;
    }
    default:
      return null;
  }
};

/**
 * Generates assignment errors for provided error types with optional name configuration
 * @param {String} name selected user's name
 * @param {Array} errorTypes array of error type objects with message if error exists and includeName option
 */
const generateErrors = (name, errorTypes) =>
  errorTypes.reduce(
    (errors, { message, includeName, gameData, ignoreRule }) =>
      message
        ? [
            ...errors,
            {
              message,
              name: includeName ? name : null,
              gameData: gameData || null,
              ignoreRule: ignoreRule || false
            }
          ]
        : errors,
    []
  );

export const assignmentValidator = ({
  selectedUser,
  assignments_game,
  display_id,
  location,
  start_date,
  start_time,
  end_date,
  end_time,
  gamesEventId,
  selfAssignSettings,
  isGamesEventAdmin,
  isGamesEventOfficial,
  timezone,
  id
}) => {
  const game = {
    id,
    display_id,
    start: moment.utc(convertToUtc(`${start_date} ${start_time}`, timezone)),
    end: moment.utc(convertToUtc(`${end_date} ${end_time}`, timezone))
  };

  let alreadyAssigned =
    selectedUser &&
    !!assignments_game.find(
      assignment => assignment.event_role.user_id === Number(selectedUser.id)
    );

  const isSelfAssign =
    selectedUser &&
    selfAssignSettings &&
    selfAssignSettings.checked &&
    !isGamesEventAdmin &&
    isGamesEventOfficial;

  let gameAssignments = [];
  if (selectedUser) {
    gameAssignments = selectedUser.game_assignments;
    if (isSelfAssign) {
      gameAssignments = gameAssignments.filter(
        assignment =>
          assignment.game.published ||
          [ASSIGNMENT_STATUS.unpublished, ASSIGNMENT_STATUS.declined].indexOf(assignment.status) < 0
      );
    }
  }

  const isMaxGamePerDayExceededForSelfAssign =
    isSelfAssign &&
    selfAssignSettings.max_games_per_day &&
    isMaxGamePerDayExceeded(
      selfAssignSettings.max_games_per_day,
      start_date,
      gameAssignments,
      gamesEventId
    );

  const isMaxGamePer24hExceededForSelfAssign =
    isSelfAssign &&
    selfAssignSettings.max_games_per_24h &&
    isMaxGamePer24hExceeded(
      selfAssignSettings.max_games_per_24h,
      start_date,
      start_time,
      gameAssignments,
      gamesEventId
    );

  const isSelfAssignAllowedBeforeGame =
    isSelfAssign &&
    selfAssignSettings.allow_days_before_game &&
    game.start.diff(moment.utc(), 'days') >= selfAssignSettings.allow_days_before_game;

  const isSelfAssignBlockedBeforeGame =
    isSelfAssign &&
    selfAssignSettings.block_days_before_game &&
    game.start.diff(moment.utc(), 'days') <= selfAssignSettings.block_days_before_game;

  const name = selectedUser && `${selectedUser.first_name} ${selectedUser.last_name} `;
  const locationId = location && location.id;

  const otherEventConflicts = [];
  const sameTimeConflicts = [];
  const backToBackConflicts = [];

  if (gameAssignments.length) {
    gameAssignments.forEach(otherAssignment => {
      const otherAssignmentGameStatus = otherAssignment.game && otherAssignment.game.status;
      if (
        otherAssignmentGameStatus === GAMES_STATUSES.CANCELLED ||
        otherAssignmentGameStatus === GAMES_STATUSES.POSTPONED
      )
        return;

      if (game.id === (otherAssignment && otherAssignment.external_game_id)) {
        alreadyAssigned = true;
        return;
      }

      const start =
        otherAssignment &&
        otherAssignment.game &&
        otherAssignment.game.start_at &&
        moment.utc(otherAssignment.game.start_at);
      const end =
        otherAssignment &&
        otherAssignment.game &&
        otherAssignment.game.end_at &&
        moment.utc(otherAssignment.game.end_at);

      if (Number(gamesEventId) !== otherAssignment.event_id) {
        if (
          otherAssignment.status === 'accepted' &&
          (start.isBetween(game.start, game.end, null, '[]') ||
            end.isBetween(game.start, game.end, null, '[]') ||
            game.start.isBetween(start, end, null, '[]') ||
            game.end.isBetween(start, end, null, '[]'))
        ) {
          otherEventConflicts.push(otherAssignment);
        }
      } else {
        if (
          (start.isSame(game.end) || end.isSame(game.start)) &&
          locationId !== otherAssignment.game.external_location_id
        ) {
          backToBackConflicts.push(otherAssignment);
        }
        if (
          start.isBetween(game.start, game.end, null, '[)') ||
          end.isBetween(game.start, game.end, null, '(]') ||
          game.start.isBetween(start, end, null, '[)') ||
          game.end.isBetween(start, end, null, '(]')
        ) {
          sameTimeConflicts.push(otherAssignment);
        }
      }
    });
  }
  const sameTimeConflictsLabel = sameTimeConflicts.length !== 1 ? 'Games' : 'Game';

  const errors = generateErrors(name, [
    {
      message: selectedUser && alreadyAssigned && `is already assigned to game ${game.display_id}.`,
      includeName: true
    },
    {
      message:
        isMaxGamePerDayExceededForSelfAssign &&
        `You can only be assigned ${selfAssignSettings.max_games_per_day}
        ${selfAssignSettings.max_games_per_day > 1 ? 'games' : 'game'} per day.`,
      includeName: false
    },
    {
      message:
        isMaxGamePer24hExceededForSelfAssign &&
        `You can only be assigned ${selfAssignSettings.max_games_per_24h}
        ${selfAssignSettings.max_games_per_24h > 1 ? 'games' : 'game'} for every 24 hours.`,
      includeName: false
    },
    {
      message:
        isSelfAssignAllowedBeforeGame &&
        `Self Assign is not available for games starting in ${
          selfAssignSettings.allow_days_before_game
        }
          ${selfAssignSettings.allow_days_before_game > 1 ? 'days or more' : 'day or more'}.
          Contact your assignor for help.`,
      includeName: false
    },
    {
      message:
        isSelfAssignBlockedBeforeGame &&
        `Self Assign is not available for games starting in ${
          selfAssignSettings.block_days_before_game
        }
          ${selfAssignSettings.block_days_before_game > 1 ? 'days or less' : 'day'}.
          Contact your assignor for help.`,
      includeName: false
    },
    {
      message:
        otherEventConflicts.length &&
        `has another game for another event on the time and date of Game ${game.display_id}.`,
      includeName: true
    },
    {
      message:
        sameTimeConflicts.length &&
        `is assigned to ${
          sameTimeConflicts.length
        } ${sameTimeConflictsLabel.toLowerCase()} with the same date and time: ${sameTimeConflictsLabel} ${sameTimeConflicts
          .map(({ game: gameInfo }) => gameInfo.external_event_id)
          .join(', ')}.`,
      includeName: true
    },
    {
      message:
        backToBackConflicts.length &&
        `cannot be assigned back to back games at different complexes: Games ${game.display_id}, ${backToBackConflicts[0].game.external_event_id}`,
      includeName: true,
      ignoreRule: true,
      gameData: { id: game.id }
    }
  ]);

  return {
    userGameErrors: errors
  };
};

/* Assignment Ignore Rule Utiles Start */
export const userGrades = (user, gameLevel, currentIndex) => {
  let activeCertifications = null;
  const roleId = gameLevel && gameLevel.role_ids.length > 0 && gameLevel.role_ids[currentIndex];
  const roleName = Object.keys(CORE_ROLES).find(key => CORE_ROLES[key] === roleId);
  const rankValue =
    roleName === 'official'
      ? OFFICIAL_MIN_GRADE.find(value => value.abbr === gameLevel.assessor_min_grades[currentIndex])
      : ASSESSOR_MIN_GRADE.find(
          value => value.abbr === gameLevel.assessor_min_grades[currentIndex]
        );
  if (user && !isNil(user.certifications) && user.certifications[roleName]) {
    const [cert] = user.certifications[roleName];
    activeCertifications = cert;
  } else {
    activeCertifications = user && !isNil(user.certifications) ? user.certifications : null;
  }
  return { rankValue, activeCertifications };
};

export const isIgnoreGradeRule = (currentIndex, ignoreRules, assignedPosition, id) =>
  !!(
    ignoreRules &&
    ignoreRules.payload &&
    ignoreRules.payload.id === id &&
    parseInt(assignedPosition, 10) === currentIndex
  );

export const byPassGradeValidation = (gameLevel, currentIndex) =>
  !(
    gameLevel.role_ids &&
    gameLevel.role_ids[currentIndex] &&
    gameLevel.assessor_min_grades &&
    gameLevel.assessor_min_grades.length &&
    gameLevel.assessor_min_grades[currentIndex]
  );

export const checkGrade = (user, gameLevel, currentIndex) => {
  const { rankValue, activeCertifications } = userGrades(user, gameLevel, currentIndex);
  return !!((rankValue && rankValue.value === 0) || isNil(rankValue)
    ? true
    : activeCertifications &&
      activeCertifications.ussf_license &&
      activeCertifications.ussf_license.rank &&
      activeCertifications.ussf_license.rank <= rankValue.value);
};

export const checkExpiredGrade = (user, gameLevel, currentIndex) => {
  const { activeCertifications } = userGrades(user, gameLevel, currentIndex);
  return !!(user && user.ussfDetails && user.ussfDetails.isValid && activeCertifications);
};

export const isValidGrade = (
  selectedUser,
  gameLevel,
  currentIndex,
  ignoreRules,
  assignedPosition,
  id
) =>
  byPassGradeValidation(gameLevel, currentIndex) ||
  isIgnoreGradeRule(currentIndex, ignoreRules, assignedPosition, id) ||
  checkGrade(selectedUser, gameLevel, currentIndex);

export const isExpiredGrade = (
  selectedUser,
  gameLevel,
  currentIndex,
  ignoreRules,
  assignedPosition,
  id
) =>
  isIgnoreGradeRule(currentIndex, ignoreRules, assignedPosition, id) ||
  checkExpiredGrade(selectedUser, gameLevel, currentIndex);

export const UserCertificationsSuspended = (selectedUser, gameLevel, currentIndex) => {
  const { activeCertifications } = userGrades(selectedUser, gameLevel, currentIndex);
  return !(isNil(activeCertifications) ? false : activeCertifications.suspended);
};

export const isIgnoreRankRule = (currentIndex, ignoreRules, assignedPosition, id) =>
  !!(
    ignoreRules &&
    ignoreRules.payload &&
    ignoreRules.payload.id === id &&
    parseInt(assignedPosition, 10) === currentIndex
  );

export const byPassRankValidation = (gameLevel, currentIndex) =>
  !(
    gameLevel.role_ids &&
    gameLevel.role_ids[currentIndex] &&
    gameLevel.min_ranks &&
    gameLevel.min_ranks.length &&
    gameLevel.min_ranks[currentIndex]
  );

export const checkRank = (positionRank, gameLevel, currentIndex) => {
  const response =
    gameLevel.min_ranks.length > currentIndex &&
    positionRank &&
    positionRank >= gameLevel.min_ranks[currentIndex];

  return response;
};

export const isValidRank = (
  selectedUser,
  gameLevel,
  currentIndex,
  ignoreRules = false,
  assignedPosition,
  id
) => {
  let positionRank;
  // TODO: Consolidate "rank" so that a single data type is set. The calendar now will always set the rank as an array
  // where as the games tab passes a number. To be consistent the games tab should pass in the arraay of event roles and corresponding rank here.
  if (typeof selectedUser.rank === 'number') {
    positionRank = selectedUser.rank;
  } else if (Array.isArray(selectedUser.rank)) {
    const roleIdForGameAssignmentPosition =
      (gameLevel && Array.isArray(gameLevel.role_ids) && gameLevel.role_ids[currentIndex]) || 0;
    positionRank = Number(
      selectedUser.rank
        .filter(eventRoles => Number(eventRoles.role_id) === roleIdForGameAssignmentPosition)
        .map(val => val.rank)
        .join()
    );
  }

  return (
    byPassRankValidation(gameLevel, currentIndex) ||
    isIgnoreRankRule(currentIndex, ignoreRules, assignedPosition, id) ||
    checkRank(positionRank, gameLevel, currentIndex)
  );
  /* Assignment Ignore Rule Utiles End */
};
