import { compose, lifecycle, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getEventById } from '../../../../core/Events/selectors';
import { isAdmin, isNotFundingAdmin } from '../../../../core/Auth/selectors';
import { findSportById } from '../../../../core/Sports/selectors';
import { fetchGameLocationCounts as fetchGameLocationCountsAction } from '../../../../core/Events';
import { getRemainingGamesFilter } from '../../../../utils/helpers';

const mapStateToProps = state => ({
  findSportById: id => findSportById(state, id),
  isAdmin: () => isAdmin(state),
  findEventById: id => getEventById(id, state),
  isNotFundingAdmin: isNotFundingAdmin()(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchGameLocationCounts: fetchGameLocationCountsAction
  }),
  lifecycle({
    componentDidMount() {
      const { fetchGameLocationCounts, fetchCountDelay, summary, id: eventId } = this.props;
      if (summary && summary.game_levels) {
        setTimeout(() => {
          fetchGameLocationCounts({ eventId, gamesFilters: getRemainingGamesFilter({}) });
        }, fetchCountDelay);
      }
    }
  }),
  withStateHandlers(
    ({ initialShowModalInfo = false }) => ({
      showModalInfo: initialShowModalInfo
    }),
    {
      setShowModalInfo: () => value => ({
        showModalInfo: value
      })
    }
  )
);
