import { compose, pure, withHandlers, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import {
  updateCrewPayTable,
  fetchGameLevel,
  copyFeesFromGameLevel,
  setCrewWarningModal,
  fetchEventInfoCrewPay
} from '../../../../core/Events';
import {
  getEventGameLevels,
  getEventCrewLabels,
  getEventCrewPay
} from '../../../../core/Events/selectors';
import { paginationAware } from '../../PaginationAware';
import { numToCurrency } from '../../../../utils/parsers';
import { sortListByKey } from '../../../../utils/helpers';

const mapStateToProps = state => ({
  gameLevels: getEventGameLevels(state),
  crewLabels: getEventCrewLabels(state),
  isFetching: state.events.eventPageFetching,
  crewWarningModal: state.events.crewWarningModal,
  crewPay: getEventCrewPay(state),
  crewPayFetching: state.events.isFetching
});

const mapDispatchToProps = dispatch => ({
  updateCrewPayTable: payload => dispatch(updateCrewPayTable(payload)),
  fetchGameLevel: payload => dispatch(fetchGameLevel(payload)),
  copyFeesFromGameLevel: payload => dispatch(copyFeesFromGameLevel(payload)),
  setCrewWarningModal: payload => dispatch(setCrewWarningModal(payload)),
  onLoad: eventId => dispatch(fetchEventInfoCrewPay(eventId))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware({
    fetcherQuote: 'fetchEventInfoCrewPay',
    forPrefix: 'cp',
    metaQuote: 'gameLevels'
  }),
  withState('crewPayTableInEdit', 'setCrewPayTableInEdit', '0'),
  withState('ignoreWarning', 'setIgnoreWarning', 0),
  withState('showModal', 'toggleShowModal', false),
  withState('crewPayList', 'setCrewPayList', []),
  withHandlers({
    onSubmit: ({
      updateCrewPayTable: updateCPT,
      crewPayTableInEdit,
      setCrewPayTableInEdit
    }) => values => {
      return new Promise(resolve => {
        updateCPT({
          crew_pay_table: {
            ...values,
            id: values.ignoreWarning ? values.id : crewPayTableInEdit
          },
          resolve,
          ignoreWarning: values.ignoreWarning
        });
        setCrewPayTableInEdit('0');
      });
    },
    cancelEdit: ({ setCrewPayTableInEdit }) => reset => {
      reset();
      setCrewPayTableInEdit('0');
    },

    checkAndSetCrewPayTableInEdit: ({
      setCrewPayTableInEdit,
      crewPayTableInEdit,
      toggleShowModal
    }) => id => {
      if (crewPayTableInEdit === '0') {
        setCrewPayTableInEdit(id);
      } else {
        toggleShowModal(id);
      }
    },
    fetchGameLevel: ({ fetchGameLevel: fetchGL }) => id => {
      fetchGL({ gameLevelId: id });
    },
    copyFeesFromGameLevel: ({ copyFeesFromGameLevel: cpFromGameLevel }) => (
      gameLevelId,
      callback
    ) => {
      cpFromGameLevel({ gameLevelId, callback });
    },
    handleGameLevelsSorting: ({ crewPayList, setCrewPayList }) => (
      sortingColumn = 'game_level',
      desc = false
    ) => {
      const list = sortListByKey(crewPayList, sortingColumn, desc);
      setCrewPayList(list);
    }
  }),
  lifecycle({
    componentWillMount() {
      const {
        match: {
          params: { eventId }
        },
        onLoad
      } = this.props;
      onLoad(eventId);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.crewPay !== this.props.crewPay) {
        const { setCrewPayList, gameLevels } = this.props;
        setCrewPayList(
          gameLevels
            .filter(({ set_crew_pay }) => set_crew_pay)
            .map(({ crew_pay, game_type, game_level, id }) => {
              return {
                id,
                game_type,
                game_level,
                ...crew_pay.map(value => (value.base_pay ? numToCurrency(value.base_pay) : ''))
              };
            })
        );
      }
    }
  }),
  pure
);
