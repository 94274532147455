import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { defaultTo, prop, flip, compose, propEq, ifElse, isNil, always } from 'ramda';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import shortid from 'shortid';
import { LICENSE_STATUSES, PRIMARY_PHONE_TYPE, ROLES_CONSTANT } from '../../../../../constants';
import { roundedFormatDate } from '../../../../../utils/parsers';
import { addressFormatter } from '../../Formatters';
import { notNilNotEmpty, formatUssfId } from '../../../../../utils/helpers';
import {
  ExpandedWrapper,
  SectionWrapper,
  InfoContainer,
  ColWrapper,
  InfoRow,
  InfoLabel,
  InfoValue,
  Uppercase,
  StatusIconContainer,
  StatusIcon,
  ExpandButton,
  TimezoneDropdownWrapper
} from './styled-components';
import defaultEnhancer from '../../../../containers/SideList/expanded';
import CategoryTags from '../../CategoryTags';
import Availability from '../../../AssignmentAvailability';
import LoaderWrapper from '../../../LoaderWrapper';
import Dropdown from '../../../Dropdown';
import COLORS, { ICONS } from '../../../../../utils/styles';

const useStyles = makeStyles({
  tabPanel: {
    overflowY: 'auto',
    maxHeight: 'auto !important',
    padding: '10px 15px'
  },
  tabPanelAvalibility: {
    overflowY: 'auto',
    maxHeight: 'auto !important'
  },
  customTooltip: {
    backgroundColor: 'rgba(61, 73, 86, 1)',
    width: '200px'
  },
  customArrow: {
    color: 'rgba(61, 73, 86, 1)'
  }
});
const PRIMARY_CONTACT_TYPE_ID = 3;
const GUARDIAN_CONTACT_TYPE_ID = 2;
const defaultToEmpty = defaultTo('');
const PRIMARY_ADDRESS = 1;
const TRAVEL_ADDRESS = 2;

const guardianFormatInfo = ({ profile }) => {
  const profileValues = flip(prop)(profile);
  const getGuardianInfo = name =>
    (profileValues('contacts') || [])
      .filter(propEq('type_id', GUARDIAN_CONTACT_TYPE_ID))
      .map(compose(defaultToEmpty, prop(name)))
      .join('');

  return [
    {
      title: 'Parent / Guardian Info',
      columns: [
        [
          {
            label: 'Name',
            info: `${getGuardianInfo('first_name')} ${getGuardianInfo('last_name')}`
          },
          {
            label: 'Relationship',
            info: getGuardianInfo('relationship')
          },
          {
            label: 'Email',
            info: getGuardianInfo('primary_email')
          },
          {
            label: 'Prim. Phone',
            info: `${getGuardianInfo('primary_phone_number')} ${
              getGuardianInfo('primary_phone_number') &&
              getGuardianInfo('primary_phone_type') &&
              `(${PRIMARY_PHONE_TYPE[getGuardianInfo('primary_phone_type')]})`
            }`
          }
        ]
      ]
    }
  ];
};
const defaultFormatExpandedInfo = ({ profile, age }) => {
  const profileValues = flip(prop)(profile);
  const mainAddress =
    profileValues('addresses') &&
    profileValues('addresses').filter(propEq('type_id', PRIMARY_ADDRESS));
  let travelAddress =
    profileValues('addresses') &&
    profileValues('addresses').filter(propEq('type_id', TRAVEL_ADDRESS));
  if (isNil(travelAddress)) {
    travelAddress = mainAddress;
  }
  return [
    {
      columns: [
        [
          {
            label: 'Email',
            info: profileValues('email') && defaultToEmpty(profileValues('email'))
          },
          {
            label: age && age > 18 ? 'Phone' : 'Prim. Phone',
            info: (profileValues('contacts') || [])
              .filter(propEq('type_id', PRIMARY_CONTACT_TYPE_ID))
              .map(compose(defaultToEmpty, prop('primary_phone_number')))
              .join('')
          },
          {
            label: 'Gender-DOB',
            info:
              profileValues('gender') &&
              `${defaultToEmpty(profileValues('gender').slice(0, 1).toUpperCase())} - ${ifElse(
                notNilNotEmpty,
                defaultToEmpty,
                roundedFormatDate
              )(profileValues('dob'), 'MMMM DD, YYYY')}`
          },
          {
            label: 'Travel Address',
            info: travelAddress && addressFormatter(travelAddress)
          }
        ]
      ]
    }
  ];
};

const certificationExpand = user => {
  const {
    certifications,
    ussfDetails: { first_name, last_name, isValid, ussf_id }
  } = user;
  const {
    valid_through = 'NA',
    issuer = 'NA',
    safe_sport_valid_through = 'NA',
    background_valid_through = 'NA',
    active,
    ussf_license
  } = !isNil(certifications) && certifications;

  return [
    {
      columns: [
        [
          {
            label: 'License Type',
            info: !isValid
              ? 'No record found in LC'
              : (ussf_license && ussf_license.name) || 'No Active License'
          },
          {
            label: 'Status',
            info: active ? LICENSE_STATUSES.ACTIVE : isValid ? LICENSE_STATUSES.EXPIRED : 'NA'
          },
          {
            label: ' Valid Through',
            info: valid_through
          },
          {
            label: 'Issuer',
            info: issuer
          },
          {
            label: 'SafeSport Valid Through',
            info: safe_sport_valid_through
          },
          {
            label: 'NCSI Background Valid Through',
            info: background_valid_through
          }
        ]
      ]
    },
    {
      title: 'Certification Match : US Soccer Federation',
      columns: [
        [
          {
            label: 'Name',
            info:
              isValid && !isNil(first_name) && !isNil(last_name)
                ? `${first_name} ${last_name}`
                : 'NA'
          },
          {
            label: 'USSF-ID',
            info: isValid && !isNil(ussf_id) ? formatUssfId(ussf_id) : 'NA'
          },
          {
            label: 'Date of Birth',
            info: isValid
              ? ifElse(
                  notNilNotEmpty,
                  defaultToEmpty,
                  roundedFormatDate
                )(user && user.profile && user.profile.dob, 'MMMM DD, YYYY')
              : 'NA'
          }
        ]
      ]
    }
  ];
};

export const DetailTabContainer = ({
  handleResize,
  index,
  formattedInfo,
  item,
  canEdit,
  allowSelfAssignment,
  onUpdateCategories,
  guardianInfo
}) => {
  React.useLayoutEffect(() => {
    const divElement = document.getElementById('detailTabContainer');
    const handleDetailTabResize = () => {
      const staticPartOfDetailTab = 150;
      const newDivHeight = divElement.offsetHeight + staticPartOfDetailTab;
      handleResize(index, newDivHeight);
    };

    if (divElement) {
      window.addEventListener('resize', handleDetailTabResize);
      handleDetailTabResize();
    }
  }, [handleResize, index]);

  return (
    <div id="detailTabContainer">
      {formattedInfo &&
        formattedInfo.map(({ title, columns, fullWidth }) => (
          <Section title={title} border key={title} columns={columns} fullWidth={fullWidth} />
        ))}
      {!isNil(item.categories) && (
        <CategoryTags
          categories={item.categories}
          onChange={updatedCategories => onUpdateCategories({ user: item, updatedCategories })}
          canEdit={canEdit || allowSelfAssignment}
        />
      )}
      {item.age &&
        item.age < 18 &&
        guardianInfo.map(({ title, columns, fullWidth }) => (
          <Section title={title} border key={title} columns={columns} fullWidth={fullWidth} />
        ))}
    </div>
  );
};

export const CertificationContainer = ({ certificationInfo, handleResize, index }) => {
  React.useLayoutEffect(() => {
    const divElement = document.getElementById('sectionCountainerDiv');
    const handleResizeLocal = () => {
      const staticPartOfCertificationTab = 140;
      const newDivHeight = divElement.offsetHeight + staticPartOfCertificationTab;
      handleResize(index, newDivHeight);
    };

    if (divElement) {
      window.addEventListener('resize', handleResizeLocal);
      handleResizeLocal();
    }
  }, [handleResize, index]);

  return (
    <div id="sectionCountainerDiv">
      {certificationInfo.map(({ title, columns, fullWidth }) => (
        <Section title={title} border key={title} columns={columns} fullWidth={fullWidth} />
      ))}
    </div>
  );
};

export const Section = ({ title, columns, fullWidth }) => (
  <SectionWrapper>
    {title && (
      <InfoRow>
        {' '}
        <InfoLabel />
        <InfoValue fontSize="16px"> {title}</InfoValue>
      </InfoRow>
    )}
    <InfoContainer>
      {columns.map(col => (
        <ColWrapper key={shortid.generate()}>
          {col.map(({ label, info }) => (
            <InfoRow key={shortid.generate()}>
              <InfoLabel>{label}</InfoLabel>
              <InfoValue
                color={
                  label === 'Email'
                    ? COLORS.skyBlue
                    : info === LICENSE_STATUSES.EXPIRED
                    ? COLORS.reddish
                    : ''
                }
                label={label}
              >
                {info}
              </InfoValue>
            </InfoRow>
          ))}
        </ColWrapper>
      ))}
    </InfoContainer>
    <InfoContainer>
      {fullWidth.map(row => (
        <ColWrapper key={shortid.generate()}>
          <InfoRow>
            <InfoLabel>{row.label}</InfoLabel>
            <InfoValue>{row.info}</InfoValue>
          </InfoRow>
        </ColWrapper>
      ))}
    </InfoContainer>
  </SectionWrapper>
);

Section.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.array).isRequired,
  fullWidth: PropTypes.arrayOf(PropTypes.object)
};

Section.defaultProps = {
  fullWidth: []
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const generateUserExpanded = ({
  formatExpandedInfo = defaultFormatExpandedInfo,
  guardianExpandedFormatInfo = guardianFormatInfo,
  certificationExpandInfo = certificationExpand,
  Enhancer = defaultEnhancer,
  onUpdateCategories = always(undefined),
  canEdit = false,
  allowSelfAssignment = false
}) =>
  Enhancer(
    ({
      item,
      activeTab,
      setActiveTab,
      expandSideList,
      setExpandSideList,
      availibiltyTab,
      calendarActiveMonth,
      setCalendarActiveMonth,
      isFetching,
      dropDownTimezoneList,
      calendarTimezone,
      setCalendarTimezone,
      isGoverningBodyUSSF,
      eventsPageActive,
      handleResize,
      index
    }) => {
      const classes = useStyles();
      const [open, setOpen] = React.useState(false);
      let defaultDropdownValue;
      const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        event.stopPropagation();
      };

      const formattedInfo = item ? formatExpandedInfo(item) : [];
      const guardianInfo = item ? guardianExpandedFormatInfo(item) : [];
      const certificationInfo = item ? certificationExpandInfo(item) : [];
      return (
        <ExpandedWrapper expand={expandSideList} tabValues={activeTab}>
          <Tabs
            value={activeTab}
            indicatorColor="#fff"
            onChange={handleChange}
            className="tabsHeader"
          >
            {availibiltyTab && (
              <Tab
                label="Availability"
                value={0}
                className="detailTab"
                {...a11yProps(0)}
                disabled={!!expandSideList}
              />
            )}
            {!expandSideList && (
              <Tab label="Details" className="detailTab" value={1} {...a11yProps(1)} />
            )}
            {!expandSideList &&
              item &&
              item.role !== ROLES_CONSTANT[2] &&
              item.role !== ROLES_CONSTANT[6] &&
              isGoverningBodyUSSF && (
                <Tab label="Certifications" value={2} className="detailTab" {...a11yProps(2)} />
              )}
          </Tabs>

          {!eventsPageActive && activeTab === 0 && (
            <>
              <ExpandButton
                onClick={() => {
                  setExpandSideList(!expandSideList);
                }}
                expand={expandSideList}
              >
                <StatusIconContainer>
                  <StatusIcon
                    width={15}
                    height={15}
                    icon={expandSideList ? ICONS.Shrink : ICONS.Expand}
                  />
                </StatusIconContainer>{' '}
                <Uppercase>{expandSideList ? 'Shrink' : 'Expand'} Calendar</Uppercase>
              </ExpandButton>
              <TimezoneDropdownWrapper expand={expandSideList}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  open={open}
                  title="Game and availability times on this calendar are displayed in the selected time zone"
                  arrow
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow
                  }}
                  className="infoTooltip"
                >
                  <div>
                    <StatusIconContainer
                      paddingRight="0px"
                      height="1.5rem"
                      onMouseEnter={() => setOpen(true)}
                      onClick={() => setOpen(true)}
                      onMouseLeave={() => setOpen(false)}
                    >
                      <StatusIcon width={15} height={15} icon={ICONS.EXCLAMATION_CIRCLE} />
                    </StatusIconContainer>
                  </div>
                </Tooltip>
                <Dropdown
                  dropdownWidth="9rem"
                  options={dropDownTimezoneList}
                  columnSize={2}
                  value={calendarTimezone}
                  borderRadius="5px"
                  backgroundColor={COLORS.white}
                  borderColor={COLORS.slateGray}
                  outline="none"
                  dropdownHeight="1.5rem"
                  placeholder={defaultDropdownValue}
                  onChange={({ target: { value } }) => {
                    setCalendarTimezone(value);
                  }}
                />
              </TimezoneDropdownWrapper>{' '}
            </>
          )}

          {!expandSideList && activeTab === 1 && item && (
            <TabPanel value={activeTab} index={1} classes={{ root: classes.tabPanel }}>
              <DetailTabContainer
                index={index}
                handleResize={handleResize}
                formattedInfo={formattedInfo}
                item={item}
                canEdit={canEdit}
                allowSelfAssignment={allowSelfAssignment}
                onUpdateCategories={onUpdateCategories}
                guardianInfo={guardianInfo}
              />
            </TabPanel>
          )}

          {!eventsPageActive && availibiltyTab && activeTab === 0 && (
            <TabPanel value={activeTab} index={0} classes={{ root: classes.tabPanelAvalibility }}>
              <div>
                <Availability
                  calendarActiveMonth={calendarActiveMonth}
                  setCalendarActiveMonth={setCalendarActiveMonth}
                  expand={expandSideList}
                  timezone={calendarTimezone}
                  item={item}
                  handleResize={handleResize}
                  index={index}
                />
              </div>
            </TabPanel>
          )}

          {activeTab === 2 &&
            item &&
            item.role !== ROLES_CONSTANT[2] &&
            item.role !== ROLES_CONSTANT[6] &&
            isGoverningBodyUSSF && (
              <TabPanel value={activeTab} index={2} classes={{ root: classes.tabPanel }}>
                <LoaderWrapper isFetching={isFetching}>
                  <CertificationContainer
                    certificationInfo={certificationInfo}
                    handleResize={handleResize}
                    index={index}
                  />
                </LoaderWrapper>
              </TabPanel>
            )}
        </ExpandedWrapper>
      );
    }
  );

export default generateUserExpanded;
