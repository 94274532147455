import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  RowWrapper,
  UpcomingData,
  Header,
  H3,
  H1,
  Title,
  TabWrapper,
  Tabs,
  EventRow,
  EventName,
  EventInfo,
  Key,
  Value,
  Container,
  ColWrapper,
  GameStatus,
  Nodata,
  ClickableKey,
  EventRowWrapper,
  ViewGamesButton,
  TabRows
} from './styled-components';
import Enhancer from '../../containers/UpcomingGameInfo';
import LoaderWrapper from '../LoaderWrapper';
import { assignmentConfig, gamesConfig } from './config';
import FilterMenu from './FilterMenu';
import { UPCOMINGEVENTS } from '../../../constants';
import { sortListByKey } from '../../../utils/helpers';

export const Tab = ({
  isActive,
  setIsActive,
  title,
  onClickHandler,
  games,
  getCount,
  setEventInfo,
  margin
}) => {
  useEffect(() => {
    if (isActive === title) {
      onClickHandler({ setIsActive, title, setEventInfo, games });
    }
    // eslint-disable-next-line
  }, []);
  const gameCount = getCount({ games });
  return (
    <TabWrapper
      margin={margin}
      onClick={() => {
        onClickHandler({ setIsActive, title, setEventInfo, games });
      }}
      className={isActive === title ? 'selected' : null}
    >
      <H1 fontSize={gameCount && gameCount.toString().length > 4 && '28px'}>
        <span>{gameCount}</span>
      </H1>
      <Title>{title}</Title>
    </TabWrapper>
  );
};

export const TabEventInfo = ({
  isActive,
  eventInfo,
  tabTitle,
  gameStatusClickHandler,
  games,
  filter
}) => {
  const assignmentFilterTab = isActive && isActive.split(' ')[0].toLowerCase();
  const sortedEvents = eventInfo && eventInfo.length && sortListByKey(eventInfo, 'eventName');
  return (
    <TabRows>
      {eventInfo && eventInfo.length ? (
        sortedEvents.map(info => (
          <EventRow>
            <EventRowWrapper>
              {' '}
              <EventName>{Object.values(info)[0]}</EventName>{' '}
              <ViewGamesButton
                onClick={() => {
                  gameStatusClickHandler({
                    eventName: Object.values(info)[0],
                    games,
                    value: assignmentFilterTab,
                    filter,
                    viewGameClicked: UPCOMINGEVENTS.UPCOMING_GAMES === tabTitle && true
                  });
                }}
              >
                View Games
              </ViewGamesButton>
            </EventRowWrapper>
            {tabTitle === UPCOMINGEVENTS.UPCOMING_GAMES && <GameStatus>Game Status: </GameStatus>}
            {Object.entries(info).map(([key, value]) => {
              return (
                key !== 'eventName' &&
                key !== 'eventId' && (
                  <EventInfo>
                    {tabTitle === UPCOMINGEVENTS.UPCOMING_GAMES ? (
                      <ClickableKey
                        onClick={() => {
                          gameStatusClickHandler({
                            eventName: Object.values(info)[0],
                            value: key,
                            games,
                            filter
                          });
                        }}
                      >
                        {key}
                      </ClickableKey>
                    ) : (
                      <Key>{key}</Key>
                    )}
                    <Value>{value}</Value>
                  </EventInfo>
                )
              );
            })}
          </EventRow>
        ))
      ) : (
        <EventRow>
          <Nodata>There are no events with this criteria</Nodata>
        </EventRow>
      )}
    </TabRows>
  );
};

export const UpcomingDataTable = ({
  title,
  games,
  config,
  fetching,
  active,
  margin,
  fetchGames,
  platformId,
  user,
  filter,
  setFilter,
  gameStatusClickHandler
}) => {
  const [isActive, setIsActive] = useState(active);
  const [eventInfo, setEventInfo] = useState({});
  const isfetching =
    title === UPCOMINGEVENTS.UPCOMING_GAMES ? fetching.upcomingGames : fetching.upcomingAssignments;
  useEffect(() => {
    if (active) {
      setIsActive(active);
    }
  }, [isfetching, active]);
  return (
    <UpcomingData>
      <Header>
        <H3>{title}</H3>
        <FilterMenu
          fetchGames={fetchGames}
          platformId={platformId}
          user={user}
          filter={filter}
          setFilter={setFilter}
        />
      </Header>
      <LoaderWrapper isFetching={isfetching}>
        <Tabs>
          {config.map((con, val) => (
            <Tab
              key={val}
              isActive={isActive}
              setIsActive={setIsActive}
              games={games}
              setEventInfo={setEventInfo}
              margin={margin}
              {...con}
            />
          ))}
        </Tabs>
        <TabEventInfo
          isActive={isActive}
          eventInfo={eventInfo}
          tabTitle={title}
          games={games}
          gameStatusClickHandler={gameStatusClickHandler}
          filter={filter}
        />
      </LoaderWrapper>
    </UpcomingData>
  );
};

const UpcomingGamesInfo = ({
  fetching,
  upcomingGames,
  getUpcomingGameInfo,
  platformId,
  user,
  getUpcomingAssignments,
  upcomingAssignments,
  gameFilter,
  setGameFilter,
  assignmentFilter,
  setAssignmentFilter,
  gameStatusClickHandler
}) => {
  return (
    <Container>
      <Wrapper>
        <RowWrapper center="xs">
          <ColWrapper>
            <UpcomingDataTable
              title={UPCOMINGEVENTS.UPCOMING_GAMES}
              games={upcomingGames}
              config={gamesConfig}
              fetching={fetching}
              fetchGames={getUpcomingGameInfo}
              platformId={platformId}
              user={user}
              filter={gameFilter}
              setFilter={setGameFilter}
              gameStatusClickHandler={gameStatusClickHandler}
              active="Total Games"
              margin="20px 15px"
            />
          </ColWrapper>
          <ColWrapper>
            <UpcomingDataTable
              title={UPCOMINGEVENTS.UPCOMING_ASSIGNMENTS}
              games={upcomingAssignments}
              config={assignmentConfig}
              fetching={fetching}
              fetchGames={getUpcomingAssignments}
              platformId={platformId}
              user={user}
              filter={assignmentFilter}
              setFilter={setAssignmentFilter}
              gameStatusClickHandler={gameStatusClickHandler}
              active="Open Positions"
            />
          </ColWrapper>
        </RowWrapper>
      </Wrapper>
    </Container>
  );
};

export default Enhancer(UpcomingGamesInfo);
