import { createSelector } from 'reselect';
import pluralize from 'pluralize';
import { getRoleForName } from '../Roles/selectors';
import { getEventUser } from '../Events/selectors';
import { getUserSchedule } from '../User/selectors';

export const getUsers = ({ users }) => users;

export const getTotalUserCount = (state, type) =>
  createSelector(getUsers, users => users[type].total_count)(state);

export const getUsersByType = (state, type) =>
  createSelector(getUsers, users => users[pluralize(type)].data)(state);

export const getMetaByType = type => createSelector(getUsers, users => users[pluralize(type)].meta);

export const getIsFetchingInit = ({ users }) => users.isFetchingInit;
export const getEventRoleById = (state, roleName, eventRoleId) =>
  getUsersByType(state, roleName).find(eventRole => eventRole.eventRoleId === eventRoleId);

export const getEventAdmins = ({ users: { admins } }) => admins.data;

export const getIsFetching = ({ users }) => users.isFetching;

export const getEventRoleLinks = ({ users }) => {
  return users.eventRoleLinks;
};

export const hasNextPage = ({ users }) => {
  return !!(users.eventRoleLinks && users.eventRoleLinks.next);
};
export const getIsFetchingEventRoles = ({ users }) => {
  return !!users.isFetchingEventRoles;
};

export const getSelectedUser = (state, singleUser) => {
  let eventUser;
  let role_id = null;
  let userCert;
  const updateState = singleUser ? state.user.userSchedule : state.users.selectedUser;
  if (!singleUser) {
    eventUser = getEventUser(state.users.selectedUser && state.users.selectedUser.id)(state);
    role_id =
      state.users.selectedUser &&
      state.users.selectedUser.role &&
      Number(getRoleForName(state.users.selectedUser.role)(state)[0].id);
    userCert = eventUser && eventUser.roles.find(role => role.role_id === role_id);
  } else {
    eventUser = getUserSchedule(state);
  }
  return (
    updateState && {
      ...updateState,
      role_id,
      game_assignments: eventUser
        ? eventUser.event_roles &&
          eventUser.event_roles.reduce(
            (assignments, role) => [
              ...assignments,
              ...role.game_assignments.map(gameAssignment => ({
                ...gameAssignment,
                event_id: role.event_id
              }))
            ],
            []
          )
        : [],
      availabilities: eventUser ? eventUser.availabilities : [],
      roleCert: userCert && { grade: userCert.grade, year: userCert.year }
    }
  );
};

export const getUserAvailability = (state, userId) => {
  const eventUser = getEventUser(userId && userId.id)(state);
  const availability = eventUser ? eventUser.availabilities : [];
  const assignmentAvailabilites =
    eventUser &&
    eventUser.event_roles.reduce(
      (assignments, role) => [
        ...assignments,
        ...role.game_assignments.map(gameAssignment => {
          const {
            id,
            game: { end_at: time_to, start_at: time_from, status: game_Status, event },
            assigned_by,
            status,
            external_game_id
          } = gameAssignment;
          return {
            id,
            time_to,
            time_from,
            game_Status,
            status,
            availability_type: status,
            gameId: external_game_id,
            assigned_by,
            eventName: event && event.name
          };
        })
      ],
      []
    );
  return availability && availability.concat(assignmentAvailabilites);
};

export const getAssignmentAceeptanceUser = state => {
  return (
    state.users.selectedUserForAssignmentAccept &&
    state.users.selectedUserForAssignmentAccept.map(user => {
      let role_id = null;
      const updateState = user;
      const eventUser = getEventUser(user && user.id)(state);
      role_id = user && user.role && Number(getRoleForName(user.role)(state)[0].id);
      return (
        updateState && {
          ...updateState,
          role_id,
          game_assignments: eventUser
            ? eventUser.event_roles &&
              eventUser.event_roles.reduce(
                (assignments, role) => [
                  ...assignments,
                  ...role.game_assignments.map(gameAssignment => ({
                    ...gameAssignment,
                    event_id: role.event_id
                  }))
                ],
                []
              )
            : []
        }
      );
    })
  );
};
