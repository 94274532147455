import {
  FETCH_PRODUCT_INVOICES,
  SET_PRODUCT_INVOICES,
  SET_FETCHING_INVOICE,
  FETCH_PLATFORM_SUBSCRIPTIONS,
  SET_PLATFORM_SUBSCRIPTIONS,
  SET_PLATFORM,
  SET_GROUP
} from './constants';

export const fetchProductInvoices = payload => ({
  payload,
  type: FETCH_PRODUCT_INVOICES
});

export const setProductInvoices = payload => ({
  payload,
  type: SET_PRODUCT_INVOICES
});

export const fetchPlatformSubscriptions = payload => ({
  payload,
  type: FETCH_PLATFORM_SUBSCRIPTIONS
});

export const setPlatformSubscriptions = payload => ({
  payload,
  type: SET_PLATFORM_SUBSCRIPTIONS
});

export const setFetchingInvoice = (product, value) => ({
  product,
  value,
  type: SET_FETCHING_INVOICE
});

export const setPlatform = platform => ({
  platform,
  type: SET_PLATFORM
});

export const setGroup = payload => ({
  payload,
  type: SET_GROUP
});
