import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';

export const CarretButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ width }) => width && `${width}rem`};
  ${({ selected, nobg }) =>
    ` background:${
      !nobg &&
      (selected
        ? `linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue})`
        : `linear-gradient(to bottom, ${COLORS.white} 0%, ${COLORS.blackSqueeze} 100%);`)
    }`};
`;

export default CarretButtonContainer;
