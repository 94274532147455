import React from 'react';
import { range } from 'ramda';
import PropTypes from 'prop-types';
import {
  isExpiredGrade,
  isValidGrade,
  isValidRank,
  UserCertificationsSuspended
} from '../../../../../../utils/assignmentValidations';
import { isEventGoverningBodyUSSF } from '../../../../../../utils/helpers';
import { PositionsStyle, EmptyCell } from '../../styled-components';
import PositionCell from './PositionCell';
import { arePropsChanged } from '../../../config';

const Positions = ({
  copy,
  gameLevels,
  game_level,
  assignments_game,
  selectedUser,
  selectedRoleId,
  currentEvent,
  ignoreRules,
  assignedPosition,
  id,
  display_id,
  allowSelfAssignment,
  userGameErrors,
  canEdit,
  clearAssignmentPosition,
  onAssign,
  eventInfo,
  status,
  published,
  start_date,
  start_time,
  end_date,
  end_time,
  location,
  timezone,
  onRemoveAssignment,
  setAssignmentWarnings,
  currentUserId,
  onAction,
  assignmentFetching,
  assignmentNotePermissions,
  assignmentNote,
  fetchAssignmentNote,
  isEventChanged,
  inProgress,
  setIsEventChanged
}) => {
  const isGoverningBodyUSSF = isEventGoverningBodyUSSF(eventInfo);
  return (
    <>
      <PositionsStyle isCopy={copy}>
        {range(
          0,
          gameLevels && gameLevels.labels && gameLevels.labels.length > 0
            ? gameLevels.labels.length
            : game_level.crew_size
        ).map(i => {
          const assignment = assignments_game.find(a => i === a.official_label_col);
          const selected =
            assignment && selectedUser && assignment.event_role.user.id === selectedUser.id;

          const roleMatchingErrors =
            gameLevels && gameLevels.role_ids && selectedRoleId !== gameLevels.role_ids[i]
              ? [
                  {
                    message: `User list role does not match column role.`
                  }
                ]
              : [];
          const paymentProfileWarning =
            gameLevels &&
            selectedUser &&
            (!selectedUser.payee || !selectedUser.payee.transfers_enabled) &&
            currentEvent &&
            currentEvent.required_payment_profile
              ? [
                  {
                    message: `${selectedUser.first_name} ${selectedUser.last_name} has not completed Payment Profile setup.`,
                    warning: true,
                    gameData: { id, position: i }
                  }
                ]
              : [];
          const gradeError =
            isGoverningBodyUSSF &&
            gameLevels &&
            selectedUser &&
            !isValidGrade(selectedUser, gameLevels, i, ignoreRules, assignedPosition, id)
              ? [
                  {
                    message: `${selectedUser.first_name} ${selectedUser.last_name} does not have the minimum grade required for Game ${display_id}`,
                    gameData: { id, position: i },
                    ignoreRule: true
                  }
                ]
              : [];
          const rankError =
            gameLevels &&
            selectedUser &&
            !isValidRank(selectedUser, gameLevels, i, ignoreRules, assignedPosition, id)
              ? [
                  {
                    message: `${selectedUser.first_name} ${selectedUser.last_name} does not have the minimum rank required for Game ${display_id}`,
                    gameData: { id, position: i },
                    ignoreRule: true
                  }
                ]
              : [];
          const rankAndGradeError =
            rankError.length && gradeError.length
              ? [
                  {
                    message: `${selectedUser.first_name} ${selectedUser.last_name} does not have the minimum rank and grade required for Game ${display_id}`,
                    gameData: { id, position: i },
                    ignoreRule: true
                  }
                ]
              : [];
          const paymentProfileError =
            selectedUser &&
            (!selectedUser.payee || !selectedUser.payee.transfers_enabled) &&
            currentEvent &&
            currentEvent.required_payment_profile &&
            allowSelfAssignment
              ? [
                  {
                    message: `${selectedUser.first_name} ${selectedUser.last_name} has not completed Payment Profile setup.`,
                    gameData: { id, position: i }
                  }
                ]
              : [];
          const gradeExpiredError =
            isGoverningBodyUSSF &&
            gameLevels &&
            selectedUser &&
            !isExpiredGrade(selectedUser, gameLevels, i, ignoreRules, assignedPosition, id)
              ? [
                  {
                    message: `${selectedUser.first_name} ${selectedUser.last_name}  has a license that is currently inactive.`,
                    gameData: { id, position: i },
                    ignoreRule: true
                  }
                ]
              : [];
          const certificationSuspendedError =
            isGoverningBodyUSSF &&
            gameLevels &&
            selectedUser &&
            !UserCertificationsSuspended(selectedUser, gameLevels, i)
              ? [
                  {
                    message: `${selectedUser.first_name} ${selectedUser.last_name} has a license that is currently suspended.`,
                    gameData: { id, position: i },
                    ignoreRule: true
                  }
                ]
              : [];
          let mergedErrors = [];
          if (rankAndGradeError.length) {
            mergedErrors = [
              ...userGameErrors,
              ...roleMatchingErrors,
              ...rankAndGradeError,
              ...gradeExpiredError,
              ...certificationSuspendedError
            ];
          } else {
            mergedErrors = [
              ...userGameErrors,
              ...roleMatchingErrors,
              ...rankError,
              ...gradeError,
              ...gradeExpiredError,
              ...paymentProfileError,
              ...certificationSuspendedError
            ];
          }

          const disabled =
            (allowSelfAssignment ? !allowSelfAssignment : !canEdit) || !!mergedErrors.length;
          return gameLevels &&
            gameLevels.labels &&
            (gameLevels.labels[i] === '' || gameLevels.labels[i]) ? (
            ignoreRules &&
            ignoreRules.payload &&
            ignoreRules.payload.id === id &&
            assignedPosition === i ? (
              clearAssignmentPosition() &&
              onAssign(
                {
                  gameId: id,
                  crewLabelCol: i,
                  eventRoleId: selectedUser.eventRoleId
                },
                {
                  display_id,
                  status,
                  published,
                  start_date,
                  start_time,
                  end_date,
                  end_time,
                  external_location_id: location.id,
                  game_level_id: game_level.id,
                  ignore_rules: true,
                  timezone
                },
                gameLevels
              ) && (
                <PositionCell
                  inProgress={inProgress}
                  assignment={assignment}
                  assignmentNote={{
                    permissions: assignmentNotePermissions,
                    data: assignmentNote,
                    fetch: fetchAssignmentNote
                  }}
                  min_ranks={
                    gameLevels && gameLevels.min_ranks.length && gameLevels.min_ranks[i]
                      ? `${gameLevels.min_ranks[i]}`
                      : ''
                  }
                  label={
                    gameLevels && gameLevels.labels && gameLevels.labels[i]
                      ? gameLevels.labels[i]
                      : ''
                  }
                  selectedUser={selectedUser}
                  onRemoveAssignment={(assignmentId, userId, eventRoleId) =>
                    !inProgress &&
                    onRemoveAssignment({
                      gameId: id,
                      id: assignmentId,
                      userId,
                      eventRoleId,
                      crewLabelCol: i
                    })
                  }
                  canEdit={canEdit}
                  allowSelfAssignment={allowSelfAssignment}
                  currentUserId={Number(currentUserId)}
                  onAction={onAction}
                  key={i}
                  selected={selected}
                  disabled={disabled}
                  errors={mergedErrors}
                  cellLoading={
                    assignmentFetching.gameId === id && assignmentFetching.crewLabelCol === i
                  }
                />
              )
            ) : (
              <PositionCell
                inProgress={inProgress}
                assignment={assignment}
                assignmentNote={{
                  permissions: assignmentNotePermissions,
                  data: assignmentNote,
                  fetch: fetchAssignmentNote
                }}
                min_ranks={
                  gameLevels && gameLevels.min_ranks.length && gameLevels.min_ranks[i]
                    ? `${gameLevels.min_ranks[i]}`
                    : ''
                }
                onClick={warnings => {
                  if (
                    isEventChanged &&
                    selectedUser &&
                    (!selectedUser.payee || !selectedUser.payee.status)
                  ) {
                    setAssignmentWarnings({
                      assignmentWarnings: warnings,
                      position: i,
                      user: selectedUser && selectedUser.id
                    });
                    setIsEventChanged(false);
                  }
                  return (
                    !inProgress &&
                    onAssign(
                      {
                        gameId: id,
                        crewLabelCol: i,
                        eventRoleId: selectedUser.eventRoleId
                      },
                      {
                        display_id,
                        status,
                        published,
                        start_date,
                        start_time,
                        end_date,
                        end_time,
                        external_location_id: location.id,
                        game_level_id: game_level.id,
                        timezone
                      },
                      gameLevels
                    )
                  );
                }}
                onDisabledClick={e => {
                  clearAssignmentPosition();
                  setAssignmentWarnings({
                    assignmentWarnings: e,
                    position: i,
                    user: selectedUser && selectedUser.id
                  });
                }}
                label={
                  gameLevels && gameLevels.labels && gameLevels.labels[i]
                    ? gameLevels.labels[i]
                    : ''
                }
                selectedUser={selectedUser}
                onRemoveAssignment={(assignmentId, userId, eventRoleId, checkReport) =>
                  !inProgress &&
                  onRemoveAssignment({
                    gameId: id,
                    id: assignmentId,
                    userId,
                    eventRoleId,
                    crewLabelCol: i,
                    checkReport
                  })
                }
                canEdit={canEdit}
                allowSelfAssignment={allowSelfAssignment}
                currentUserId={Number(currentUserId)}
                onAction={onAction}
                key={i}
                selected={selected}
                disabled={disabled}
                errors={mergedErrors}
                warnings={paymentProfileWarning}
                cellLoading={
                  assignmentFetching.gameId === id && assignmentFetching.crewLabelCol === i
                }
              />
            )
          ) : (
            <EmptyCell key={i} />
          );
        })}
      </PositionsStyle>
    </>
  );
};

Positions.propTypes = {
  isEventChanged: PropTypes.bool.isRequired,
  setIsEventChanged: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  game_level: PropTypes.shape({
    game_level: PropTypes.string,
    game_type: PropTypes.string
  }),
  start_time: PropTypes.string.isRequired,
  field: PropTypes.shape({
    location: PropTypes.object
  }),
  start_date: PropTypes.string.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    eventRoleId: PropTypes.string
  }),
  onAssign: PropTypes.func,
  onRemoveAssignment: PropTypes.func,
  selectedRoleId: PropTypes.number.isRequired,
  canEdit: PropTypes.bool,
  currentUserId: PropTypes.number,
  userGameErrors: PropTypes.arrayOf(Object)
};

Positions.defaultProps = {
  game_level: {},
  selectedUser: null,
  onAssign: () => true,
  onRemoveAssignment: () => true,
  canEdit: false,
  field: null,
  currentUserId: null,
  userGameErrors: []
};

export default React.memo(Positions, arePropsChanged);
