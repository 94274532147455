import React from 'react';
import ReportAvailability from './ReportAvailability';
import IncidentReportOptions from './IncidentReportOptions';
import CrewVerification from './CrewVerification';
import { mutators as ReportAvailabilityMutators } from './ReportAvailability/config';

export const gameReportsConfig = [
  {
    Component: ReportAvailability,
    name: 'ReportAvailability'
  },
  {
    Component: () => <div />,
    name: ''
  }
];

export const incidentReportsConfig = [
  {
    Component: IncidentReportOptions,
    name: 'IncidentReportOptions'
  },
  {
    Component: () => <div />,
    name: ''
  }
];

export const crewVerificationConfig = [
  {
    Component: CrewVerification,
    name: 'CrewVerification'
  },
  {
    Component: () => <div />,
    name: ''
  }
];

export const combinedMutators = {
  ...ReportAvailabilityMutators
};
