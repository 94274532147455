import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import MaterialDropDown from '../../../MaterialDropDown';
import { InputContainer, FieldLabel } from './styled-components';

const Dropdown = ({ name, options, validate, focus, blur, label, className, children, theme }) => (
  <InputContainer className={className}>
    <FieldLabel display="block">{label}</FieldLabel>
    <Field
      component={MaterialDropDown}
      name={name}
      fieldName={name}
      options={options.map(option => ({ name: option, value: option }))}
      theme={theme}
      focus={focus}
      blur={blur}
      validate={validate}
      showError
    />
    {children}
  </InputContainer>
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  validate: PropTypes.func,
  focus: PropTypes.func.isRequired,
  blur: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.arrayOf(String)
  ]),
  theme: PropTypes.shape({
    dropableContainer: PropTypes.object
  })
};

Dropdown.defaultProps = {
  validate: () => null,
  className: '',
  children: null,
  theme: {
    dropableContainer: {}
  }
};

export default Dropdown;
