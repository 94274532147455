import COLORS from '../../../../../utils/styles';

export default {
  crewPayHeader: [
    {
      label: 'Game Type',
      name: 'game_type'
    },
    {
      label: 'Game Level',
      name: 'game_level'
    },
    {
      label: 'Pos. 1',
      name: 0
    },
    {
      label: 'Pos. 2',
      name: 1
    },
    {
      label: 'Pos. 3',
      name: 2
    },
    {
      label: 'Pos. 4',
      name: 3
    },
    {
      label: 'Pos. 5',
      name: 4
    },
    {
      label: 'Pos. 6',
      name: 5
    },
    {
      label: 'Pos. 7',
      name: 6
    },
    {
      label: 'Pos. 8',
      name: 7
    },
    {
      label: '',
      name: 'EditIcon'
    }
  ],
  sortColumn: ['game_level', 'game_type'],
  options: [
    {
      name: 'game_level',
      label: 'Game Level'
    },
    {
      name: 'game_type',
      label: 'Game Type'
    }
  ],
  updateButton: ({ handleSubmit }) => ({
    label: 'Update',
    name: 'update',
    width: '84px',
    height: '32px',
    onClick: () => {
      handleSubmit();
    }
  }),
  cancelButton: ({ cancelEdit, reset }) => ({
    type: 'button',
    label: 'Cancel',
    onClick: () => cancelEdit(reset),
    width: '84px',
    height: '32px'
  })
};

export const customStyle = {
  ArrowStyle: {
    display: 'inline-block',
    top: '-5px'
  },
  EditButton: {
    border: `1px solid ${COLORS.gray}`,
    backgroundColor: `${COLORS.white}!important`,
    color: COLORS.darkGrey
  }
};
