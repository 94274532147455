import { compose, pure, withHandlers, withProps, withState } from 'recompose';

import { PLATFORM_TYPES } from '../../../constants';

export default compose(
  withState('isSubmitting', 'setIsSubmitting', false),
  withProps(({ groups }) => ({
    options: groups
      .filter(group => group.platform_type === PLATFORM_TYPES.PLATFORM)
      .map(({ id: value, name }) => ({
        value,
        label: `${name}  - ${value}`
      }))
  })),
  withHandlers({
    onCreateGroup: ({ setIsSubmitting, isSuperAdmin, createGroup }) => (values, formApi) => {
      setIsSubmitting(true);
      new Promise(resolve => createGroup({ values: { ...values, isSuperAdmin }, resolve }))
        .then(() => {
          setIsSubmitting(false);
          formApi.restart();
        })
        .finally(() => setIsSubmitting(false));
    }
  }),
  pure
);
