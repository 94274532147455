import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { ErrorOutline as Notes } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import { ReportsIconContainer, ReportsIcon } from '../../../styled-components';
import gameReportActionConfig from './config';

import { arePropsChanged } from '../../../../config';

const useStyles = makeStyles({
  tooltip: {
    background: 'rgba(0,0,0,0.9)'
  }
});

const GameReportAction = props => {
  const classes = useStyles();
  return (
    <ReportsIconContainer>
      {gameReportActionConfig
        .config(props)
        .map(({ title, showIcon, note, data_tooltip, ...rest }) => {
          return (
            showIcon && (
              <Tooltip
                title={title}
                arrow
                classes={{ tooltip: classes.tooltip }}
                key={data_tooltip}
              >
                <ReportsIcon data-tooltip={data_tooltip} clickable {...rest}>
                  {note && <Notes {...note} />}
                </ReportsIcon>
              </Tooltip>
            )
          );
        })}
    </ReportsIconContainer>
  );
};

export default React.memo(GameReportAction, arePropsChanged);
