import { compose, withHandlers, withProps, withState } from 'recompose';

export default compose(
  withState('openConfirmationModal', 'setOpenConfirmationModal', false),
  withState('openACHModal', 'setOpenACHModal', false),
  withState('formData', 'setFormData', null),

  withProps(({ userAccounts, formData }) => ({
    options: userAccounts
      .filter(bank => bank.method === 'bank_account')
      .map(({ id, details: { bank, nick_name } }) => ({
        label: `${bank} - ${nick_name}`,
        value: id
      })),
    bankName: userAccounts
      .filter(bank => formData && bank.method === 'bank_account' && bank.id === formData.sourceId)
      .map(({ id, details: { bank, nick_name } }) => ({
        bank: `${bank} - ${nick_name}`,
        value: id
      }))
  })),

  withHandlers({
    onSubmit: ({ setOpenConfirmationModal, setFormData }) => value => {
      setFormData(value);
      setOpenConfirmationModal(true);
    },
    closeConfirmationModal: ({ setOpenConfirmationModal }) => () => {
      setOpenConfirmationModal(false);
    },
    closeACHAccount: ({ setOpenACHModal }) => () => {
      setOpenACHModal(false);
    },
    confirmButtonHandler: ({
      setOpenConfirmationModal,
      addWalletFund,
      setShowTransactionHistoryTable
    }) => data => {
      addWalletFund(data);
      setOpenConfirmationModal(false);
      setShowTransactionHistoryTable(false);
    }
  })
);
