import styled from 'styled-components';
import { COLORS } from '../../../../../utils/styles';

export const Wrapper = styled.div`
  width: 25rem;
  background-color: ${COLORS.lightCyanGray};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TitleWrapper = styled.div`
  min-height: 3.5rem;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

export const Title = styled.span`
  color: ${COLORS.denimBlue};
  font-size: 1.2rem;
  font-weight: bold;
`;

export const CheckboxContainer = styled.div`
  margin-left: auto;
  padding-bottom: 0.3rem;
`;

export const RateText = styled.span`
  color: ${COLORS.cobaltGrey};
`;

export const RateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const RateTextBlue = styled.span`
  color: ${COLORS.denimBlue};
  font-weight: bold;
`;

export const Name = styled.span`
  color: ${COLORS.black};
  font-weight: bold;
`;

export const InfoWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Info = styled.span`
  font-size: 0.8rem;
  color: ${COLORS.cobaltGrey};
  color: ${({ red }) => red && `${COLORS.red}`};
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.catSkillWhite};
  margin-top: 1rem;
`;

export const ContentWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme};
`;

export const ButtonWrapper = styled.div`
  width: 50%;
  align-self: flex-end;
`;

export const DisabledSpan = styled.span`
  ${({ locked }) => locked && `color: ${COLORS.lightCyanGray}`};
`;
