import React from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

import { Field } from 'react-final-form';
import Modal from '../../../../../Modal';

const ImagePreview = ({ values }) => {
  return (
    <Field name="preview">
      {({ input }) => (
        <Modal
          title="Preview"
          onClose={() => {
            /* eslint-disable no-param-reassign */
            values.previewSrc = null;
            input.onChange({ ...{} });
            /* eslint-enable no-param-reassign */
          }}
          size={{
            xs: 8,
            sm: 8,
            md: 8,
            lg: 6
          }}
        >
          <div
            style={{
              position: 'relative',
              minHeight: '400px',
              maxHeight: '700px'
            }}
          >
            <ReactPanZoom image={values.previewSrc} />
          </div>
        </Modal>
      )}
    </Field>
  );
};

export default ImagePreview;
