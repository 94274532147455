import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { COLORS } from '../../../utils/styles';

const combineClassNames = require('classnames');

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  menu: {
    width: 200
  },
  errorMessage: {
    color: COLORS.errorRed,
    position: 'absolute',
    fontSize: 10,
    paddingTop: 45,
    zIndex: 0
  },
  errorMessageRelative: {
    color: COLORS.errorRed,
    position: 'absolute',
    fontSize: 10,
    paddingTop: 55,
    paddingBottom: 2,
    zIndex: 0
  }
});

export default function TextFieldOutlined({
  id,
  label,
  placeholder,
  classNames,
  onChange,
  value,
  maxLength,
  endAdornment,
  error,
  errorMessage,
  relativeError,
  noEndAdornmentStyle,
  endAdornmentStyle = {},
  inputFontSize,
  startAdornment,
  inputStyle,
  noStartAdornmentStyle,
  readOnly,
  disabled,
  ...rest
}) {
  const classes = useStyles();
  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        id={id}
        label={label}
        placeholder={placeholder}
        className={combineClassNames(classNames, classes.textField)}
        margin="normal"
        variant="outlined"
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
        InputProps={{
          style: { fontSize: inputFontSize || 13, ...inputStyle },
          endAdornment: endAdornment ? (
            <InputAdornment
              style={{ margin: 10, paddingTop: 3, ...endAdornmentStyle }}
              position="end"
            >
              {endAdornment}
            </InputAdornment>
          ) : (
            <div style={noEndAdornmentStyle} />
          ),
          startAdornment: startAdornment ? (
            <InputAdornment style={{ color: COLORS.azureGray }} position="start">
              {startAdornment}
            </InputAdornment>
          ) : (
            <></>
          )
        }}
        // InputProps and inputProps are two different properties of TextField. This wasted a few hours of my life.
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          // bc maxLength
          // eslint-disable-next-line object-shorthand
          maxLength: maxLength,
          readOnly
        }}
        InputLabelProps={{
          style: { fontSize: 13 }
        }}
        {...rest}
      />
      {error && (
        <FormHelperText
          className={relativeError ? classes.errorMessageRelative : classes.errorMessage}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </form>
  );
}

TextFieldOutlined.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  classNames: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  error: PropTypes.bool,
  errorMessage: PropTypes.string
};

TextFieldOutlined.defaultProps = {
  id: '',
  label: '',
  placeholder: '',
  classNames: '',
  maxLength: '',
  endAdornment: null,
  error: false,
  errorMessage: ''
};
