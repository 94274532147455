import { pxToRem } from '../../../../utils/helpers';
import { COLORS } from '../../../../utils/styles';

export default {
  headerBg: 'transparent',
  headerMinHeight: pxToRem('32px'),
  headerMinPaddingTop: `${pxToRem('13px')}`,
  iconColorWhenClosed: COLORS.shuttleGrey,
  iconColorWhenOpen: COLORS.denimBlue,
  iconHeight: 13,
  iconWidth: 13,
  titleColorWhenClosed: COLORS.shuttleGray,
  titleColorWhenOpen: COLORS.denimBlue,
  titleFont: 'RobotoBold',
  titleLetterSpacing: '0.5px',
  titleSize: pxToRem('15px'),
  titleTransform: 'none',
  titleWeight: 'bold'
};
