import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import { userIdxGetter } from '../../../utils/auth';

/**
 * *important* selector should only return false if data has been fetched and user should not have access
 * *important* must pass props through Authorize for route to have access
 * @param component {Function} component to render if user is authorized
 * @param selector {Function} selector that returns boolean that determines auth, passed match
 * @param redirectPath {String} path to redirect to when invalid
 */
const Authorize = ({ component: Component, valid, redirectPath, ...props }) =>
  valid !== false ? (
    <Component {...props} />
  ) : (
    <Redirect
      to={routeBuilder(
        { userIdx: userIdxGetter(), userId: props.match && props.match.params.userId },
        redirectPath
      )}
    />
  );

Authorize.propTypes = {
  component: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  redirectPath: PropTypes.string.isRequired
};

Authorize.defaultProps = {
  valid: null
};

const Enhancer = compose(
  connect(
    (state, { selector, match }) => ({
      valid: selector(match)(state)
    }),
    null
  )
);

export default Enhancer(Authorize);
