import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Flex from '../../Flex';

export const PeopleActionItemContainer = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
`;

export const PeopleActionItem = styled.div`
  display: flex;
  width: auto;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  cursor: pointer;
  &:not(:last-child) {
    margin-right: ${pxToRem('20px')};
  }
`;
