import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

export const Svg = styled.svg`
  animation: ${rotate360} 2s linear infinite;

  & circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: ${dash} 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: ${({ color, theme }) => color || theme.primaryColor};
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  margin: 3rem;
`;

export default Svg;
