import React from 'react';
import PropTypes from 'prop-types';
import { Link, Switch } from 'react-router-dom';
import { nest } from 'recompose';
import Enhancer from '../../containers/CreateAccount';
import Button from '../Button';
import CreateAccountForm from '../CreateAccountForm';
import { Title, Message } from '../../layouts/AuthLayout/styled-components';
import Frosted from '../Auth/styled-components';
import FullBackgroundImage from '../../layouts/FullBackgroundImage';
import AuthLayout from '../../layouts/AuthLayout';

const Wrapper = nest(
  FullBackgroundImage,
  ({ children }) => <Frosted authFlow>{children}</Frosted>,
  AuthLayout,
  Switch
);

const CreateAccount = ({ onCreateAccount, submitting, noAccount, setNoAccount }) => (
  <Wrapper>
    <>
      {noAccount && (
        <Message>
          Oops! It looks like you don&#39;t have an Stack Officials account. Please add your
          information below and you will be sent a verification email for your new account.
        </Message>
      )}
      <Title>Create Account</Title>
      <CreateAccountForm
        onCreateAccount={onCreateAccount}
        primaryButtonLabel="Create Account"
        submitting={submitting}
        secondaryButtonLabel={
          <Link to="login">
            <Button
              width="150px"
              label="Cancel"
              onClick={() => setNoAccount(false)}
              clear
              noShadow
            />
          </Link>
        }
      />
    </>
  </Wrapper>
);

CreateAccount.propTypes = {
  onCreateAccount: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  noAccount: PropTypes.bool,
  setNoAccount: PropTypes.func.isRequired
};

CreateAccount.defaultProps = {
  noAccount: false
};

export default Enhancer(CreateAccount);
