import styled from 'styled-components';
import Icon from '../../Icon';
import { pxToRem } from '../../../../utils/helpers';
import { COLORS } from '../../../../utils/styles';

export const Wrapper = styled.div`
  user-select: none;
  display: inline-block;
  position: relative;
  ${({ notificationNumber, canClickIfEmpty }) =>
    notificationNumber || canClickIfEmpty ? 'cursor: pointer' : ''};
`;

export const StyledIcon = styled(Icon)`
  margin: 0;
`;

export const NotificationNumber = styled.span`
  width: ${pxToRem('12px')};
  height: ${pxToRem('12px')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60%;
  left: 60%;
  transform: translateY(-50%);
  padding: 1.3px 2.3px;
  border-radius: 50%;
  font-size: ${pxToRem('9px')};
  font-family: 'RobotoBold', sans-serif;
  font-weight: bold;
  color: ${COLORS.white};
  background-color: ${COLORS.denimBlue};
`;

export default Wrapper;
