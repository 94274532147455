import { compose, pure, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import {
  updateEvent,
  fetchEventGameLevels as fetchEventGameLevelsAction,
  fetchGoverningBodies
} from '../../../../core/Events';
import {
  getEventInfo,
  getEventBilling,
  getEventTaxInfo,
  eventHasGames,
  getEventInfoIsFetching,
  getGoverningBodies
} from '../../../../core/Events/selectors';
import { getSports } from '../../../../core/Sports/selectors';
import { fetchSports } from '../../../../core/Sports';
import { getGroups } from '../../../../core/Auth/selectors';
import { fetchGroups } from '../../../../core/Auth';
import { fetchBillingCustomers as fetchBillingCustomerAction } from '../../../../core/BillingCustomers';
import {
  billingCustomerIsFetching,
  getBillingCustomerList,
  isFetching as isFetchingSelector
} from '../../../../core/BillingCustomers/selector';
import { isGamesEventGroupAdmin as isGamesEventGroupAdminSelector } from '../../../../core/Games/selectors';

const mapStateToProps = state => ({
  eventInfo: getEventInfo(state),
  eventBilling: getEventBilling(state),
  eventTaxInfo: getEventTaxInfo(state),
  sportsList: getSports(state),
  groupsList: getGroups(state),
  governingBodies: getGoverningBodies(state),
  isFetching: state.events.eventPageFetching,
  do_games_exist: eventHasGames(state),
  billingCustomerList: getBillingCustomerList(state) || '',
  billingCustomerIsFetching: billingCustomerIsFetching(state),
  isGamesEventGroupAdmin: isGamesEventGroupAdminSelector()(state),
  isFetchingBillingCustomerList: isFetchingSelector(state),
  isEventInfoFetching: getEventInfoIsFetching(state)
});

const mapDispatchToProps = dispatch => ({
  updateFullEvent: info => dispatch(updateEvent(info)),
  onFetchSports: () => dispatch(fetchSports()),
  onFetchGroups: () => dispatch(fetchGroups()),
  onFetchGoverningBodies: () => dispatch(fetchGoverningBodies()),
  fetchEventGameLevels: payload => dispatch(fetchEventGameLevelsAction(payload)),
  fetchBillingCustomer: payload => dispatch(fetchBillingCustomerAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentWillMount() {
      this.props.onFetchSports();
      this.props.onFetchGroups();
      this.props.onFetchGoverningBodies();
      const {
        match: {
          params: { eventId }
        }
      } = this.props;
      this.props.fetchEventGameLevels({ eventId });
    }
  }),
  withHandlers({
    onSubmit: ({ updateFullEvent }) => (fullEvent, isEventGroupAdmin) => {
      const { group, id, required_payment_profile, area_country, area_state } =
        fullEvent && fullEvent.info;
      const billingInfo =
        fullEvent && fullEvent.billing && !Number.isNaN(fullEvent.billing)
          ? { group, ...{ event_id: id, billingCustomerId: fullEvent.billing } }
          : null;
      const taxInfo =
        fullEvent && fullEvent.tax_info && !Number.isNaN(fullEvent.tax_info)
          ? { group, ...{ event_id: id, billingCustomerId: fullEvent.tax_info } }
          : null;
      updateFullEvent({
        ...fullEvent,
        info: {
          ...fullEvent.info,
          required_payment_profile: required_payment_profile === 1,
          area_country: area_country.value,
          area_state: area_state.value
        },
        billing: billingInfo,
        tax_info: taxInfo,
        isEventGroupAdmin
      });
    }
  }),
  pure
);
