import React from 'react';
import { compose, map, head } from 'ramda';
import {
  AddressLine,
  AddressContainer,
  SingleCertificationContainer,
  CertificationValue,
  CertificationContainer
} from './styled-components';
import { formatDate } from '../../../../utils/parsers';

/**
 * Array<Address> ::: Component
 * Takes an address object, returns a Styled Address
 */
export const addressFormatter = compose(
  head,
  map(
    ({ address_1 = '', address_2 = '', city = '', state: addressState = '', postal_code = '' }) => (
      <AddressContainer>
        <AddressLine>{address_1},</AddressLine>
        <AddressLine>
          {`${address_2 ? `${address_2},` : ''} ${city}, ${addressState} ${postal_code}`}{' '}
        </AddressLine>
      </AddressContainer>
    )
  )
);

/**
 *
 * Array<Certifications> :: Array<{title:<String>, component:Function}>
 * Takes an array of Certifications returns formatted certification ExpandedColumn
 *
 */
export const certificationFormatter = ({
  role: { label },
  grade,
  year,
  cert_type,
  valid_to,
  name,
  provider
}) => (
  <CertificationContainer>
    <SingleCertificationContainer>
      <CertificationValue>{label}</CertificationValue>
      <CertificationValue> {`Grade ${grade}`}</CertificationValue>
      <CertificationValue> {cert_type}</CertificationValue>
      <CertificationValue> {year}</CertificationValue>
      <CertificationValue>{` Expires ${formatDate(valid_to)}`}</CertificationValue>
      <CertificationValue>{name}</CertificationValue>
      <CertificationValue>{provider}</CertificationValue>
    </SingleCertificationContainer>
  </CertificationContainer>
);
