import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';
import { COLORS, mediaQueries } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const GameWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  position: relative;
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  border-bottom: 1px solid #e5ebf2;
  min-height: ${pxToRem('95px')};
  max-height: ${pxToRem('95px')};
  overflow-y: auto;
  overflow-x: hidden;

  ${mediaQueries.md(`{
    flex-direction:column;
    min-height: ${pxToRem('230px')};
  max-height: ${pxToRem('230px')};
  }`)}
`;

export const GamesListContainer = styled.div`
  font-size: ${pxToRem('13px')};
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: ${pxToRem('35px')};
  ${mediaQueries.md(` {
  height: auto;
  padding-bottom: ${pxToRem('5px')};
}`)}
`;

const lastDataRowStyles = `
  font-size: ${pxToRem('13px')};
  display: flex;
  justify-content: around;
  font-weight: 300;
`;

export const TeamCol = styled(Col)`
  &:nth-child(1) {
    padding: 0px 0px 0px 8px;
  }
  padding: 0px 0px;
`;

export const GameData = styled.div`
  display: flex;
  cursor: default;
  line-height: 20px;
  overflow: hidden;
  color: ${COLORS.shuttleGray};
  flex-grow: ${({ header }) => (header ? '0' : '1')};
  ${({ lastDataRow }) => (lastDataRow ? lastDataRowStyles : '')};
  .teamVs {
    font-family: 'BarlowSemiCondensed';
    padding: 0px 3px;
    font-weight: 400;
    font-size: ${pxToRem('13px')};
  }
`;

export const Checkbox = styled.input`
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  left: ${pxToRem('34px')};
  top: ${pxToRem('7px')};

  ${({ theme }) => theme};
  ${mediaQueries.md(` {
    top: ${pxToRem('16px')};
    left: ${pxToRem('20px')};
  }`)}
`;

export const GameId = styled.span`
  color: ${COLORS.shuttleGray};
  font-weight: 400;
  margin-top: -1px;
  margin-left: 0.5rem;
  margin-right: ${pxToRem('4px')};
  line-height: 1.6;
  max-width: 4rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${`@media (max-width: 1315px) {
    max-width: 2rem !important;
  }`}

  ${mediaQueries.md(` {
max-width:8rem !important;
  }`)}
`;

export const Detail = styled.span`
  max-width: 14rem !important;
  min-width: 2.5rem;
  margin-right: 1rem;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'BarlowSemiCondensed';
  ${mediaQueries.lg(` {
    margin-right: 4px;
    max-width: 5.5rem !important;
  }`)}
`;

export const NameDetail = styled.span`
  font-size: ${pxToRem('11px')};
  margin-right: ${pxToRem('20px')};
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50ch;
`;

export const Status = styled.span`
  text-transform: capitalize;
  margin-top: ${pxToRem('3px')};
  margin-right: ${pxToRem('21px')};
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('12px')};
  color: ${COLORS.sapphire};
`;

export const CancelledOrPostponedStatus = styled(Status)`
  color: ${COLORS.red};
  font-weight: bold;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GameInfoStyle = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Barlow';
  flex-grow: 1;
  background-image: linear-gradient(
    to top,
    ${({ isCopy }) =>
      isCopy
        ? `${COLORS.shadowGray}, ${COLORS.shadowGray}`
        : `${COLORS.blackSqueeze}, ${COLORS.white}`}
  );
  border-right: 1px solid ${COLORS.greyChateau};
  padding: ${pxToRem('6px')} 0px 0px ${pxToRem('59px')};
  ${mediaQueries.md(` {
    flex-grow: 0;
    padding-top:${pxToRem('15px')}
padding-left:${pxToRem('40px')};
border:0px;
  }`)}
`;

export const RightContent = styled.div``;
