import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';
import { pxToRem } from '../../../utils/helpers';
import { COLORS, mediaQueries } from '../../../utils/styles';
import Icon from '../Icon';

export const Desktop = styled.span`
  display: inline;
  ${mediaQueries.md(`{
  display:none;
}`)}
`;

export const Mobile = styled.span`
  display: none;
  ${mediaQueries.md(`{
  display:block;
}`)}
`;
export const CalendarRow = styled(Row)`
  height: 100%;
  width: 100%;
  margin-right: 0px !important;
  margin-left: 0px !important;
  ${mediaQueries.sm(`{
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  }`)}
`;

export const CalendarLeftCol = styled(Col)`
  width: 320px;
  border-right: 2px solid #d9d9d9;
  background: #fff;
  ${mediaQueries.sm(` {
    width: 100%;
    height:370px;
    display:flex;
  }`)};
`;

export const CalendarRightCol = styled(Col)`
  width: calc(100% - 322px);
  background: #fff;
  ${mediaQueries.sm(` {
    width:100%;
  }`)};
`;

export const Wrapper = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AssignmentListScroller = styled.div`
  background: #fff;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

export const CurrentDate = styled.div`
  box-sizing: border-box;
  background-image: linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue});
  border-right: 1px solid ${COLORS.lightCyanGray};
  padding: 5px 10px;
  height: ${pxToRem('36px')};
`;

export const GameStatus = styled.span`
  background: ${({ color }) => color || COLORS.mustardYellow};
  color: ${COLORS.white};
  font-family: 'Barlow';
  font-weight: 600;
  font-size: ${pxToRem('14px')};
  margin: 0;
  padding: 0px 6px 3px 3px;
  margin: 0px 5px;
  text-transform: capitalize;
  white-space: nowrap;
  ${mediaQueries.xs(`{
    margin-right:${pxToRem('10px')};
    float:right;
  }`)};
`;

export const RoleLabel = styled.span`
  margin: 0;
  font-family: 'RobotoCondensed';
  color: ${COLORS.nightRider};
`;

export const MonthLabel = styled.span`
  margin: 0;
  color: ${COLORS.white};
  font-family: 'Roboto';
  font-size: ${pxToRem('12px')};
`;

export const DayLabel = styled.span`
  margin: 0;
  color: ${COLORS.white};
  font-family: 'Roboto';
  font-size: ${pxToRem('12px')};
`;

export const WeekdayLabel = styled.span`
  margin: 0;
  color: ${COLORS.white};
  font-family: 'Roboto';
  font-size: ${pxToRem('12px')};
  ${mediaQueries.md(`
  margin-left: 32px;
`)}
`;

export const GameName = styled.p`
  margin: 0 0 ${pxToRem('2px')};
  color: ${COLORS.darkGrey};
  font-weight: bold;
  text-transform: capitalize;
  text-align: left;
  word-break: break-word;
  display: flex;
  align-items: center;
  ${mediaQueries.md(`
  margin-left: 6px;
`)}
`;

export const GamePosition = styled.div`
  display: flex;
  align-item: center;
  padding: 0px 8px 2px 3px;
  word-break: normal;
  width: ${({ mincontent }) => (mincontent ? `fit-content` : `100%`)};
  margin: 0 0 ${pxToRem('2px')};
  color: ${COLORS.darkGrey};
  font-family: 'Barlow';
  text-align: left;
`;

export const GameId = styled.span`
  color: ${COLORS.newDarkBlue};
  word-break: break-word;
  font-size: ${({ fontSize }) => fontSize || `14px`};
  font-family: ${({ fontFamily }) => (fontFamily ? `Barlow` : 'Barlow Semi Condensed')};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  ${props =>
    mediaQueries.sm(`
    font-size:${props.fontSize ? `14px` : `16px`};
    letter-space: 1px;
`)}
`;

export const GamePositionStatus = styled(GameId)`
  display: flex;
  align-items: center;
  margin-right: ${pxToRem('7px')};
  padding-left: 2px !important;
  ${mediaQueries.md(`{
  margin-right:${pxToRem('0px')};
}`)};
`;

export const StatusIcon = styled(Icon)`
  margin: 0;
  position: relative;
  top: ${pxToRem('-3px')};
  right: ${pxToRem('0px')};
`;

export const StatusIconContainer = styled.div`
  margin-top: ${pxToRem('4px')};
  margin-bottom: ${pxToRem('4px')};
  width: ${pxToRem('16px')};
  height: ${pxToRem('16px')};
  overflow: visible;
  border-radius: 50%;
  padding-right: ${pxToRem('10px')};
`;

export const GamePositionStatusIcon = styled(StatusIconContainer)`
  margin: 0px;
  padding-right: ${pxToRem('10px')};
`;

export const GamePaymentStatus = styled.span`
  color: ${props => props.color || COLORS.forestGreen};
  margin: 0;
  font-weight: bold;
  padding: 0.125rem 0px 0px 0.325rem !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const ReportLink = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: ${COLORS.lightBlue};
  margin-right: 10px;
`;

export const RowsWrapper = styled.div`
  margin-left: 0px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  border-top: 2px solid ${COLORS.denimBlue};
  position: relative;
  font-family: 'Barlow';
  &:last-child {
    border-bottom: 2px solid ${COLORS.denimBlue};
  }
  ${mediaQueries.md(`
  flex-direction: row;
  `)}
`;

export const GameTile = styled(Col)`
  width: calc(100% - 50px);
  padding: 7px 0px;
  background-image: linear-gradient(#ffffff, #f2f5f7);
  .crewPosition {
    display: flex;
    justify-content: space-between;
  }
  .col-xs-12 {
    padding: 4px 0px 4px 22px;
    .row {
      flex-wrap: nowrap !important;
    }
  }
  ${mediaQueries.md(`
  width: calc(100% - 51px);
  padding: 7px 1px;
  .col-xs-12 {
    padding: 4px 0px 4px 45px;
  }
  `)}
`;
export const CarrentIconCol = styled(Col)`
  width: 50px;
  background: ${({ background }) => background || COLORS.seaBlue};
  height: 100%;
  position: absolute;
  left: 0px;
  right: auto;
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }
  ${mediaQueries.md(`
  right:0px;
  left:auto;
  `)}
`;

export const BannerStyle = styled.div`
  position: relative;
  box-sizing: border-box;
  font-family: Barlow;
  display: flex;
  width: 100%;
  padding: 10px;
  border-top: 4px solid #ffbb38;
  background: lightyellow;
  font-weight: 400;
  border-bottom: 1px solid #ccc;
  .paymentInfo {
    padding: 10px 12px;
    border: 1px solid ${COLORS.newDarkBlue};
    color: ${COLORS.newDarkBlue};
    font-size: 16px;
    border-radius: 4px;
    font-weight: 500;
    display: inline-block;
    margin-top: 10px;
  }
  & p {
    padding: 0px;
    margin: 10px 0px 0px 0px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  background: rgba(0, 0, 0, 0);
  border: 0px;
  color: ${COLORS.newDarkBlue};
`;

export const H2 = styled.span`
  font-size: 20px;
  color: #0d194f;
  font-weight: 500;
  display: flex;
`;

export const NoAssignmentLabel = styled.div`
  color: ${COLORS.denimBlue};
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding-top: 8rem;
  ${mediaQueries.md(`padding-top:60px;`)}
  ${`@media (max-width: 320px) {font-size:1rem;  padding-top:25px;}`}
`;

export const DirectionLink = styled(ReportLink)`
  font-weight: normal;
`;

export const DirectionWrapper = styled.span``;

export const IconStyle = styled.span`
  font-size: 18px;
  color: ${COLORS.newDarkBlue};
`;

export const DarkGray = styled.span`
  color: ${COLORS.darkGrey};
  padding-right: 3px;
  font-family: 'Barlow';
  font-weight: 500;
`;
export const SkyBlueSpan = styled.span`
  color: ${COLORS.lightBlue};
  font-family: 'Barlow';
  font-weight: 500;
`;
export const SelfAssignSpan = styled.span`
  background: #d2e9ff;
  color: ${COLORS.darkGrey};
  padding: 2px 6px;
  margin-left: 5px;
  font-family: 'Barlow';
  font-weight: 500;
  white-space: nowrap;
`;

export const CalendarColorPalette = styled(Col)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 20px;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-between;
  ${mediaQueries.sm(`
  flex-direction: row;
  align-items: center;
  `)};
`;

export const MonthlyCalendar = styled(Col)`
  width: 100%;
  height: 320px;
`;

export const ColorPaletteHeading = styled.span`
  width: 100%;
  font-size: 14px;
  color: ${COLORS.darkGrey};
  font-family: 'Barlow Semi Condensed';
  font-weight: 600;
  padding: 15px 0px 0px 0px;
  ${mediaQueries.sm(`
  padding:0px 0px 0px 0px;
  `)};
`;

export const MonthlyCalendarRow = styled(Row)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ColorPaletteCol = styled(Col)`
  display: flex;
  padding: 7px 10px;
  font-size: 14px;
  color: ${COLORS.newDarkBlue};
  font-family: 'Barlow Semi Condensed';
  font-weight: 400;
  align-items: center;
  ${mediaQueries.xs(`
  padding: 3px 0px !important;
  `)}
`;

export const ColorCol = styled.span`
  font-size: 18px !important;
  color: ${({ color }) => color || COLORS.newDarkBlue};
  padding-right: 10px;
  ${mediaQueries.xs(`
  padding-right:2px;
  margin-top: 1px;
  `)}
`;

export const SelfAssignPositionWrapper = styled.span`
  display: flex;
  flex-direction: row;
  ${mediaQueries.xs(`
flex-direction: column;
  `)}
`;
