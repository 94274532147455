import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  hasAvailability as hasAvailabilitySelector,
  isLoggedInUser,
  isPayee,
  isAnyEventAssignorOrAssessorOrOfficial as isAnyEventAssignorOrAssessorOrOfficialSelector,
  getUserProfile,
  isSuperAdmin,
  crossViewTaxInfoCheck,
  getUser,
  crossPaymentMethodCheck,
  crossEditPayeeCheck,
  isLoggedInUserOrSuperAdmin
} from '../../../core/Auth/selectors';
import {
  addPaymentSource,
  deletePaymentSource,
  fetchPaymentMethods,
  setPaymentMethods
} from '../../../core/Payments';
import { getIsFetching, getPaymentMethods } from '../../../core/Payments/selectors';
import { addNotifications as addNotificationsAction } from '../../../core/Notifications';
import unconnected from './unconnected';
import { fetchActiveUserInfo } from '../../../core/User';
import { getReturnUrl } from '../../../utils/auth';

const mapStateToProps = (state, props) => ({
  hasAvailability: hasAvailabilitySelector(state),
  paymentMethods: getPaymentMethods(state),
  isPayee: isPayee()(state),
  isFetching: getIsFetching(state),
  isLoggedInUser: isLoggedInUser()(state),
  isLoggedInUserOrSuperAdmin: isLoggedInUserOrSuperAdmin(state),
  userId: state.auth && state.user.activeUser && state.user.activeUser.id,
  payer: state.user.activeUser.payer,
  firstName: getUserProfile(state).first_name,
  lastName: getUserProfile(state).last_name,
  currentUserId: state.auth && state.auth.user && state.auth.user.id,
  paramId: props && props.match && props.match.params && props.match.params.userId,
  pathUrl: props && props.match && props.match.path,
  isSuperAdmin: isSuperAdmin(state),
  isAnyEventAssignorOrAssessorOrOfficial: isAnyEventAssignorOrAssessorOrOfficialSelector(state),
  crossViewTaxInfoCheck: crossViewTaxInfoCheck(state),
  crossPaymentMethodCheck: crossPaymentMethodCheck(state),
  crossEditPayeeCheck: crossEditPayeeCheck()(state),
  redirectPath: getReturnUrl(),
  isFetchingPayee: state.auth && state.auth.isFetchingPayee,
  paymentProfileAccessPermissions: getUser(state).cross_edit_permissions.PAYMENT_PROFILE
});

const mapDispatchToProps = dispatch => ({
  addPaymentSource: payload => dispatch(addPaymentSource(payload)),
  deletePaymentSource: payload => dispatch(deletePaymentSource(payload)),
  addNotifications: payload => dispatch(addNotificationsAction(payload)),
  fetchPaymentMethods: payload => dispatch(fetchPaymentMethods(payload)),
  fetchActiveUserInfo: payload => dispatch(fetchActiveUserInfo(payload)),
  setPaymentMethods: payload => dispatch(setPaymentMethods(payload))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), unconnected);
