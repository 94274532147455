import styled from 'styled-components';

import Icon from '../Icon';
import { COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${mediaQueries.md(` {
    width:100%;

  }`)}
`;

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-left: 1rem;
  margin-right: ${pxToRem('33px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('17px')};
  color: ${({ selected }) => (selected ? COLORS.denimBlue : COLORS.hawkesBlue)};
  font-weight: 500;
  ${`@media (max-width: 1315px) {margin-right: ${pxToRem('10px')};}`}
`;

export const HeadText = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-left: 1rem;
  margin-right: ${pxToRem('33px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('17px')};
  color: ${COLORS.denimBlue};
  font-weight: 1000;
  ${mediaQueries.md(` {
    font-size: ${pxToRem('14px')};
    justify-content: space-between;
    width:100%;
  }`)}
`;

export const CheckIcon = styled(Icon)`
  margin-left: 0;
  margin-right: 0.5rem;
`;
