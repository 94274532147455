import styled from 'styled-components';

import Icon from '../../Icon';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${COLORS.denimBlue};
  background: ${COLORS.white};
  min-height: 2rem;
  width: 100%;

  ${({ theme }) => theme || ''};
`;

export const Header = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${COLORS.denimBlue};
  background: ${COLORS.white};
  font-family: 'RobotoLight';
  font-size: ${pxToRem('14px')};
  height: 2rem;
  box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);

  ${({ theme }) => theme || ''};
`;

export const Label = styled.span`
  font-size: ${pxToRem('13px')};

  ${({ theme }) => theme || ''};
`;

export const Chevron = styled(Icon)`
  margin-right: 0.75rem;
  margin-left: auto;

  ${({ theme }) => theme || ''};
`;

export const Option = styled.div`
  z-index: 100000;
  box-sizing: border-box;
  padding: 0 0.5rem;
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('13px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.denimBlue};
  background: ${COLORS.white};
  min-height: 2rem;
  width: 100%;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:hover {
    background: ${COLORS.aliceBlueVariant};
  }

  ${({ theme }) => theme || ''};
`;

export const Options = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: ${({ setHeight }) => (setHeight ? 'auto' : '10rem')};
  width: 100%;
  max-width: ${pxToRem('260px')};
  overflow: auto;
  top: 2rem;
  z-index: 100000;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);

  &::-webkit-scrollbar {
    width: ${pxToRem('7px')};
    height: ${pxToRem('5px')};
    background-color: ${COLORS.white};
  }

  &::-webkit-scrollbar-thumb {
    height: ${pxToRem('50px')};
    background: ${COLORS.lightCyanGray};
  }

  ${({ theme }) => theme || ''};
`;

export const Count = styled.span`
  font-size: ${pxToRem('12px')};
  width: 1rem;
  height: 1rem;
  background: ${COLORS.denimBlue};
  display: inline-block;
  border-radius: 50%;
  color: ${COLORS.white};
  text-align: center;
  padding: ${pxToRem('2px')} ${pxToRem('1px')} 0 0;
  margin-right: 0.5rem;

  ${({ theme }) => theme || ''};
`;

export const CheckIcon = styled(Icon)`
  margin-left: 0;
  margin-right: 0.5rem;

  ${({ theme }) => theme || ''};
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem;
`;
