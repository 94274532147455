import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';

import { COLORS } from '../../../../../../utils/styles';
import { pxToRem } from '../../../../../../utils/helpers';

export const PrimaryContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  z-index: ${({ opened }) => opened && 1};
  border-bottom: 1px solid ${COLORS.lightCyanGray};
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.31);
  padding: 20px;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;

export const StyledCol = styled(Col)`
  ${({ noPadding }) => (noPadding ? 'padding: 0' : '')};
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const InfoRow = styled.div`
  display: flex;
  width: 100%;
  ${({ first }) => (first ? `padding-top: ${pxToRem('6px')}` : '')};
  ${({ last }) => (last ? `padding-bottom: ${pxToRem('6px')}` : '')};
`;

export const SpanColor = styled.span`
  color: #697386;
`;

export const SpanGrey = styled.span`
  color: #a3acb9;
`;

export const SpanBlack = styled.span`
  color: ${COLORS.black};
`;
