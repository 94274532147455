import React from 'react';
import PropTypes from 'prop-types';
import GameEnhancer from '../../../../containers/Games/SingleGame';
import Positions from './Positions';
import { GameWrapper, GameInfoStyle } from '../../../StyledGames/GameRow/styled-components';
import GameInfo from './GameInfo';
import GameCheckbox from './GameInfo/GameCheckbox';
import ROLES from '../../../../../core/Roles/roleConstants';
import { ASSIGNMENT_STATUS } from '../../../../../core/Games/constants';
import { MODAL_STATUSES } from '../../Modals/constants';
import { arePropsChanged } from '../../config';

const Game = ({
  display_id,
  id,
  game_level,
  start_time,
  field,
  field_name,
  location,
  start_date,
  division,
  toggleModal,
  isEventChanged,
  setIsEventChanged,
  currentEvent,
  game_assignments: assignments_game,
  selectedUser,
  selectedRoleId,
  onAssign,
  onRemoveAssignment,
  handleCheckboxClick,
  checked,
  status,
  published,
  canEdit,
  setAssignmentWarnings,
  allowSelfAssignment,
  end_date,
  end_time,
  inProgress,
  onAction,
  assessment_report_display,
  incident_report_display,
  has_game_reports: hasGameReports,
  onAssessmentReportClick,
  onIncidentReportClick,
  onGamesReportClick,
  onCrewVerificationClick,
  isGamesEventGroupAdmin,
  isGamesEventAdminOrAssignor,
  currentUserId,
  userGameErrors,
  assignmentFetching,
  team_home,
  team_visitor,
  gameLevels,
  ignoreRules,
  assignedPosition,
  clearAssignmentPosition,
  approvedReportCount,
  eventsAssessmentSettings,
  gameReportSyncLock,
  reportSettings,
  reportSubmittedStatus,
  timezone,
  eventInfo,
  copy,
  count: copyNo,
  tooltipTarget,
  setTooltipTarget,
  assignmentNote,
  assignmentNotePermissions,
  fetchAssignmentNote,
  notes
}) => {
  const isGameAssessor = assignments_game.some(a => {
    const {
      event_role: { user_id: userId, role_id: roleId }
    } = a;
    return userId === Number(currentUserId) && roleId === ROLES.ASSESSOR;
  });
  const gameOfficial = assignments_game.find(a => {
    const {
      event_role: { user_id: userId, role_id: roleId }
    } = a;
    return userId === Number(currentUserId) && roleId === ROLES.OFFICIAL;
  });
  const isUserAssignmentStatusAccepted = assignments_game.find(a => {
    const {
      event_role: { user_id: userId }
    } = a;
    return userId === Number(currentUserId) && a.status === ASSIGNMENT_STATUS.accepted;
  });
  const cvrLength = assignments_game.filter(ga => ga.crew_verification_report).length || 0;
  let modalStatus = '';
  if (cvrLength === 0) {
    modalStatus = MODAL_STATUSES.NOT;
  } else if (cvrLength === assignments_game.length) {
    modalStatus = MODAL_STATUSES.COMPLETE;
  } else if (cvrLength < assignments_game.length) {
    modalStatus = MODAL_STATUSES.PARTIAL;
  }
  const allowedToSeeGameReports =
    reportSettings &&
    reportSettings.score_entry_stats &&
    reportSettings.score_entry_stats !== 'hidden' &&
    (isGamesEventGroupAdmin ||
      isGamesEventAdminOrAssignor ||
      assignments_game.some(a => {
        const {
          event_role: { user_id: userId, role_id: roleId },
          official_label_col: officialLabelCol
        } = a;

        return (
          userId === Number(currentUserId) &&
          (roleId === ROLES.OFFICIAL || (roleId === ROLES.ASSESSOR && approvedReportCount)) &&
          reportSettings[`link_position_${officialLabelCol + 1}`]
        );
      }));

  return (
    <GameWrapper
      onClick={() => {
        if (tooltipTarget) {
          setTooltipTarget();
        }
      }}
    >
      <GameInfoStyle isCopy={copy}>
        <GameCheckbox checked={checked} handleCheckboxClick={handleCheckboxClick} id={id} />
        <GameInfo
          start_date={start_date}
          start_time={start_time}
          timezone={timezone}
          toggleModal={toggleModal}
          copyNo={copyNo}
          display_id={display_id}
          id={id}
          published={published}
          status={status}
          field={field}
          field_name={field_name}
          location={location}
          team_home={team_home}
          team_visitor={team_visitor}
          game_level={game_level}
          division={division}
          end_date={end_date}
          isGamesEventGroupAdmin={isGamesEventGroupAdmin}
          isGamesEventAdminOrAssignor={isGamesEventAdminOrAssignor}
          currentUserId={Number(currentUserId)}
          reportSettings={reportSettings}
          onCrewVerificationClick={onCrewVerificationClick}
          notes={notes}
          approvedReportCount={approvedReportCount}
          onGamesReportClick={onGamesReportClick}
          gameReportSyncLock={gameReportSyncLock}
          incident_report_display={incident_report_display}
          onIncidentReportClick={onIncidentReportClick}
          eventsAssessmentSettings={eventsAssessmentSettings}
          assessment_report_display={assessment_report_display}
          reportSubmittedStatus={reportSubmittedStatus}
          hasGameReports={hasGameReports}
          end_time={end_time}
          onAssessmentReportClick={onAssessmentReportClick}
          isGameAssessor={isGameAssessor}
          gameOfficial={gameOfficial}
          isUserAssignmentStatusAccepted={isUserAssignmentStatusAccepted}
          modalStatus={modalStatus}
          allowedToSeeGameReports={allowedToSeeGameReports}
        />
      </GameInfoStyle>
      <Positions
        copy={copy}
        gameLevels={gameLevels}
        game_level={game_level}
        assignments_game={assignments_game}
        selectedUser={selectedUser}
        selectedRoleId={selectedRoleId}
        currentEvent={currentEvent}
        ignoreRules={ignoreRules}
        assignedPosition={assignedPosition}
        id={id}
        display_id={display_id}
        allowSelfAssignment={allowSelfAssignment}
        userGameErrors={userGameErrors}
        canEdit={canEdit}
        clearAssignmentPosition={clearAssignmentPosition}
        onAssign={onAssign}
        eventInfo={eventInfo}
        status={status}
        published={published}
        start_date={start_date}
        start_time={start_time}
        end_date={end_date}
        end_time={end_time}
        location={location}
        timezone={timezone}
        onRemoveAssignment={onRemoveAssignment}
        setAssignmentWarnings={setAssignmentWarnings}
        currentUserId={Number(currentUserId)}
        onAction={onAction}
        assignmentFetching={assignmentFetching}
        assignmentNotePermissions={assignmentNotePermissions}
        assignmentNote={assignmentNote}
        fetchAssignmentNote={fetchAssignmentNote}
        isEventChanged={isEventChanged}
        inProgress={inProgress}
        setIsEventChanged={setIsEventChanged}
      />
    </GameWrapper>
  );
};

Game.propTypes = {
  isEventChanged: PropTypes.bool.isRequired,
  setIsEventChanged: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  division: PropTypes.string,
  start_time: PropTypes.string.isRequired,
  field: PropTypes.shape({
    location: PropTypes.object
  }),
  start_date: PropTypes.string.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    eventRoleId: PropTypes.string
  }),
  onAssign: PropTypes.func,
  onRemoveAssignment: PropTypes.func,
  selectedRoleId: PropTypes.number.isRequired,
  canEdit: PropTypes.bool,
  onAssessmentReportClick: PropTypes.func.isRequired,
  onIncidentReportClick: PropTypes.func.isRequired,
  onGamesReportClick: PropTypes.func.isRequired,
  onCrewVerificationClick: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  userGameErrors: PropTypes.arrayOf(Object),
  incident_report_display: PropTypes.string.isRequired,
  assessment_report_display: PropTypes.string.isRequired
};

Game.defaultProps = {
  division: null,
  selectedUser: null,
  onAssign: () => true,
  onRemoveAssignment: () => true,
  canEdit: false,
  field: null,
  currentUserId: null,
  userGameErrors: []
};

export default GameEnhancer(React.memo(Game, arePropsChanged));
