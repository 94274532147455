import React from 'react';
import { PrimaryContainer, StyledCol, StyledRow, SpanColor, SpanBlack } from './styled-components';

const ExpandInfoPanel = ({
  lastDigits,
  expireMonth,
  expireYear,
  brand,
  bankName,
  ViewName,
  nickName
}) => {
  return (
    <PrimaryContainer>
      {ViewName === 'CardView' ? (
        <StyledRow>
          <StyledCol xs={3}>
            <SpanColor> Payment Method</SpanColor>{' '}
          </StyledCol>
          <StyledCol>:</StyledCol>
          <StyledCol xs={6}>
            {' '}
            <SpanBlack>Card</SpanBlack>
          </StyledCol>
          <StyledCol xs={3}>
            <SpanColor> Number </SpanColor>{' '}
          </StyledCol>
          <StyledCol>:</StyledCol>
          <StyledCol xs={6}>
            {' '}
            <SpanBlack>•••• {lastDigits}</SpanBlack>
          </StyledCol>

          <StyledCol xs={3}>
            <SpanColor> Expires </SpanColor>{' '}
          </StyledCol>
          <StyledCol>:</StyledCol>
          <StyledCol xs={6}>
            {' '}
            <SpanBlack>
              {expireMonth} / {expireYear}
            </SpanBlack>
          </StyledCol>
          <StyledCol xs={3}>
            <SpanColor> Type</SpanColor>{' '}
          </StyledCol>
          <StyledCol>:</StyledCol>
          <StyledCol xs={6}>
            {' '}
            <SpanBlack>{brand}</SpanBlack>
          </StyledCol>
        </StyledRow>
      ) : (
        <StyledRow>
          <StyledCol xs={3}>
            <SpanColor> Payment Method</SpanColor>{' '}
          </StyledCol>
          <StyledCol>:</StyledCol>
          <StyledCol xs={6}>
            {' '}
            <SpanBlack>Bank Account</SpanBlack>
          </StyledCol>
          <StyledCol xs={3}>
            <SpanColor> Bank Name </SpanColor>
          </StyledCol>
          <StyledCol>:</StyledCol>
          <StyledCol xs={6}>
            {' '}
            <SpanBlack>{bankName}</SpanBlack>
          </StyledCol>
          <StyledCol xs={3}>
            <SpanColor>Account Nickname </SpanColor>{' '}
          </StyledCol>
          <StyledCol>:</StyledCol>
          <StyledCol xs={6}>
            {' '}
            <SpanBlack>{nickName}</SpanBlack>
          </StyledCol>
        </StyledRow>
      )}
    </PrimaryContainer>
  );
};

export default ExpandInfoPanel;
