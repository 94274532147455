import { createSelector } from 'reselect';
import { getUserGroups } from '../Auth/selectors';
import { getAllEvents, getSelectedEventPlatform } from '../Events/selectors';

export const getPaymentsEventId = ({ payments: { allEvents, selectedPaymentsEvent } }) => {
  if (allEvents && allEvents.isSelected) {
    return allEvents.selectedBatchEventId;
  }
  return selectedPaymentsEvent.id;
};

export const isAllEventSelected = ({
  payments: {
    allEvents: { isSelected }
  }
}) => isSelected;

export const getPaymentsEvent = () =>
  createSelector(getAllEvents, getPaymentsEventId, (events, eventId) =>
    events.find(event => event.id === eventId)
  );

export const getPaymentsIsAssignmentFetching = ({ payments }) =>
  payments.selectedAssignment.isFetching;

export const getPaymentsSelectedAssignment = ({ payments }) =>
  payments.selectedAssignment.assignment;

export const getPaymentsGames = ({ payments }) => payments.gamesInBatch.games;

export const getPaymentsSummary = createSelector(
  ({ payments }) => payments.summary,
  summary => summary
);

export const getAssignmentPayments = createSelector(
  ({ payments }) => payments.assignmentPayments,
  payments => payments
);

export const getPaymentsGamesMeta = ({ payments }) => payments.gamesInBatch.meta;

export const getPaymentsGamesIsFetching = ({ payments }) => payments.gamesInBatch.isFetching;

export const getPaymentsAssignorIsFetching = ({ payments }) =>
  payments.gamesInBatch.isAssignorFetching;

export const getPaymentsActiveBatch = ({ payments }) => payments.paymentsEventHasActiveBatch;

export const getPaymentsCrewLabels = ({ payments }) => payments.paymentsEventCrewLabels;

export const getPaymentsGamesLimit = createSelector(getPaymentsGamesMeta, ({ limit }) => limit);

export const getMonthTilesIsFetching = createSelector(
  ({ payments }) => payments.monthTilesData.isFetching,
  fetching => fetching
);

export const getMonthTilesData = createSelector(
  ({ payments }) => payments.monthTilesData.dataArray,
  dataArray => dataArray
);

export const getIsFetching = ({ payments }) => payments.isFetching;

export const getPaymentsViewSelected = ({ payments }) => payments.paymentsViewSelected;

export const getEventBatches = ({ payments }) => payments.eventBatches.list;

export const getEventBatchesMeta = ({ payments }) => payments.eventBatches.meta;

export const getEventBatcheLimit = createSelector(getEventBatchesMeta, ({ limit }) => limit);

export const getIsProcessingPayment = ({ payments }) => payments.isProcessingPayment;

export const getFundSummaryDetails = ({ payments }) => payments.fundSummaryDetails;
export const getBatchFailedHistory = ({ payments }) => payments.batchFailedHistory;

export const getBatchPaymentFee = ({ payments }) => {
  if (payments.batchPaymentFee && payments.batchPaymentFee.fee) {
    const { fixed, percentage, perDeposit, totalDeposits } = payments.batchPaymentFee.fee;
    return {
      totalFees: fixed + percentage + perDeposit * totalDeposits,
      min_amount: payments.batchPaymentFee.min_amount,
      max_amount: payments.batchPaymentFee.max_amount
    };
  }
  return null;
};

export const getPayoutFee = createSelector(
  ({ payments }) => payments.payoutFee.fixed + payments.payoutFee.percentage,
  payoutFee => payoutFee
);

export const getMinAndMaxAmount = createSelector(
  ({ payments }) => ({
    min_amount: payments.payoutFee && payments.payoutFee.min_amount,
    max_amount: payments.payoutFee && payments.payoutFee.max_amount,
    min_fee: payments.payoutFee && payments.payoutFee.min_fee,
    max_fee: payments.payoutFee && payments.payoutFee.max_fee
  }),
  payoutFee => payoutFee
);

export const getIsPaymentSupported = ({ payments }) => payments.isPaymentSupported;
export const getIsLastPayoutFailed = ({ payments }) => payments.payoutFee.isLastPayoutFailed;

export const getAgreement = ({ payments }) => payments.agreement;
export const getPaymentMethods = ({ payments }) => payments.paymentMethods;

export const isPaymentsEventGroupAdmin = () =>
  createSelector(getUserGroups, getSelectedEventPlatform, (groups, platform) =>
    groups.includes(platform)
  );

export const getPaymentsEventCategories = ({ payments }) => payments.paymentsEventCategories;

export const getPaymentMethodSecret = ({ payments }) => payments.paymentMethod.secret;
export const getPaymentMethodConfirming = ({ payments }) => payments.paymentMethod.confirming;
export const getPaymentMethodMandate = ({ payments }) => payments.paymentMethod.mandate;
export const getWalletIsFetching = state => state.payments.wallet.fetching;
export const getUserWallet = ({ payments }) => payments.wallet;
export const getUserWalletTotal = ({ payments }) =>
  payments.wallet.available + payments.wallet.pending;

export const getTransactionHistory = createSelector(
  ({
    payments: {
      wallet: { transactions }
    }
  }) => transactions,
  transactions => transactions
);
