import { compose, pure, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { updateGameLevels } from '../../../../core/Events';

const mapStateToProps = state => ({
  isFetching: state.events.eventPageFetching
});

const mapDispatchToProps = dispatch => ({
  updateGameLevels: payload => dispatch(updateGameLevels(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectOpen', 'setSelectOpen', null),
  withHandlers({
    onSubmit: ({ updateGameLevels: updateGL, currentId, setIndex }) => crewLabel => {
      /* eslint-disable no-param-reassign */
      crewLabel.schedule_id = currentId.val;
      /* eslint-enable no-param-reassign */
      setIndex(null);
      return new Promise(resolve => updateGL({ crew_labels: { crewLabel }, resolve }));
    }
  }),
  pure
);
