import { INTERCOM_APP_ID } from '../constants';
import { capitalize } from '../utils/helpers';

const intercomScript = userProfile => {
  const hasFullName = userProfile.first_name && userProfile.last_name;
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: INTERCOM_APP_ID,
    name: hasFullName
      ? `${capitalize(userProfile.first_name)} ${capitalize(userProfile.last_name)} `
      : '',
    email: userProfile.email,
    roles: userProfile.intercom_role_names_csv
  };

  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      class i {
        constructor() {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        }

        static c(args) {
          i.q.push(args);
        }
      }
      i.q = [];
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export default intercomScript;
