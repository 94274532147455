import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { getTimezoneAbbr, convertUtcToTimezone } from '../../../../utils/helpers';
import { formatDate } from '../../../../utils/parsers';
import COLORS, { ICONS } from '../../../../utils/styles';
import { GAME_ASSIGNMENT_STATUSES } from '../../../../constants';
import {
  H4,
  H6,
  CrewWrapper,
  CrewMembers,
  Status,
  CrewName,
  NotificationWrapper,
  GameId,
  Location,
  EventName,
  StatusIcon,
  Level
} from '../styled-component';

const NotificationRow = ({ bannerInfo, bannerHeading }) => {
  return (
    <>
      <Col xs={11}> {bannerInfo ? <H4>{bannerHeading}</H4> : ''}</Col>
      {bannerInfo &&
        bannerInfo.map((bi, i) => {
          const gameObj = bi.game;
          const gameDate =
            gameObj &&
            formatDate(
              convertUtcToTimezone(gameObj.start_at, gameObj.timezone),
              'MM/DD/YYYY @ h:mm A'
            );
          const tzAbbr =
            gameObj && getTimezoneAbbr(gameObj.start_at, gameObj.timezone, 'YYYY-MM-DD');
          const { location, external_event_id, start_at, eventName, gameLevel } = gameObj;
          return (
            <NotificationWrapper xs={12} key={i} className="NotificationBackground">
              <Row>
                <Col xs={12}>
                  {start_at ? `${gameDate} (${tzAbbr})` : ' '}
                  <EventName>{eventName ? ` - ${eventName}` : ''}</EventName>
                  <GameId> {external_event_id ? `[#${external_event_id}]` : ''}</GameId>
                  {location && (
                    <StatusIcon
                      width={12}
                      height={12}
                      color={COLORS.shuttleGray}
                      icon={ICONS.LOCATION}
                    />
                  )}
                  <Location>
                    {location && location.name ? ` ${location.name}` : ''}
                    {location && location.surface_name ? `- ${location.surface_name}` : ''}
                    {location && location.city ? `- ${gameObj.location.city}` : ''}
                    {location && location.state ? `, ${gameObj.location.state}` : ''}
                  </Location>
                  <Level>
                    {' '}
                    {gameLevel && gameLevel.length
                      ? `(Level : ${gameLevel[0].game_level})`
                      : ''}{' '}
                  </Level>
                </Col>
                {gameObj && gameObj.crew && gameObj.crew.length ? (
                  <CrewWrapper>
                    <H6>Crew: </H6>
                    <CrewMembers>
                      {gameObj.crew.map(crew => (
                        <div>
                          <CrewName className="crewName">{crew.name}</CrewName> -{' '}
                          <Status>
                            {'('}
                            {crew.official_label_col ? crew.official_label_col : ''}
                            {crew.official_label_col ? ' - ' : ''}
                            {crew.status === GAME_ASSIGNMENT_STATUSES.tentative
                              ? GAME_ASSIGNMENT_STATUSES.pending
                              : crew.status}
                            {')'}
                          </Status>
                        </div>
                      ))}
                    </CrewMembers>
                  </CrewWrapper>
                ) : (
                  ''
                )}
              </Row>
            </NotificationWrapper>
          );
        })}
    </>
  );
};

NotificationRow.propTypes = {
  bannerInfo: PropTypes.array.isRequired
};

export default NotificationRow;
