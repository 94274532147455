import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { pxToRem } from '../../../../utils/helpers';
import { COLORS } from '../../../../utils/styles';

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = ColumnWrapper.extend`
  padding: 1rem 3rem 1rem 3rem;
`;

export const ButtonWrapper = styled.div`
  width: 10%;
  align-self: flex-end;
`;

export const TitleBar = styled.div`
  background-color: ${COLORS.blackSqueeze};
  font-family: 'RobotoCondensed';
  font-size: 1rem;
  text-transform: uppercase;
  height: 3rem;
  line-height: 3rem;
  font-weight: bold;
  padding-left: 0.5rem;
  margin-top: 1.25rem;
  text-align: left;
  color: ${COLORS.greenVogue};
  width: 100%;
`;

export const TagContainer = styled.div`
  display: flex;
`;

export const TagsContainer = styled.div`
  padding-top: 1.25rem;
`;

export const GameLockedContainer = styled.span`
  margin-top: ${pxToRem('8px')};
  margin-bottom: ${pxToRem('4px')};
  width: ${pxToRem('16px')};
  height: ${pxToRem('16px')};
  overflow: visible;
  background-color: ${COLORS.white};
  border-radius: 50%;
  margin-right: 60px;
`;

export default FormWrapper;

export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const TooltipStyle = styled(Tooltip)`
  z-index: 10000 !important;
`;
