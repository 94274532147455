import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { SectionContainer, TileText } from '../style-components';

const AddElement = () => {
  return (
    <SectionContainer>
      <TileText>Card Details</TileText>
      <Row>
        <Col xs={7} id="card-number">
          <input name="card_Number" placeholder="Card Number" label="CardNumber" />
        </Col>

        <Col xs={3} id="card-Expiry">
          <input name="card_Expiry" placeholder="MM/YY" label="cardExpiry" />
        </Col>

        <Col xs={2} id="card-cvc">
          <input name="card_Cvc" placeholder="CVC" label="cardCvc" />
        </Col>
      </Row>
    </SectionContainer>
  );
};

export default AddElement;
