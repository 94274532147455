import React from 'react';
import PropTypes from 'prop-types';
import Search from '../../Search';
import EventsFilter from '../EventsFilter';
import EventSorter from '../EventSorter';
import { COLORS } from '../../../../utils/styles';
import {
  EventFilterBarContainer,
  EventFilterBarInnerContainer,
  EventFilterBarSummaryHeader,
  EventFilterBarItemContainer,
  EventFilterBarRightItemContainer,
  EventFilterBarSummaryItem
} from './styled-components';
import Enhancer from '../../../containers/Events/EventFilterBar';

const EventFilterBar = ({
  sports,
  governingBodies,
  filter,
  search: { onSearch, value: searchValue },
  sort: { onSort, value: sortValue }
}) => (
  <EventFilterBarContainer>
    <EventFilterBarInnerContainer>
      <EventFilterBarItemContainer>
        <EventFilterBarItemContainer>
          <EventSorter onSort={onSort} value={sortValue} />
        </EventFilterBarItemContainer>
        {!!sports.length && (
          <EventsFilter sports={sports} governingBodies={governingBodies} {...filter} />
        )}
      </EventFilterBarItemContainer>

      <EventFilterBarRightItemContainer>
        <Search
          iconHeight={14}
          iconWidth={14}
          iconColor={COLORS.white}
          fieldColor={COLORS.white}
          onSearch={onSearch}
          value={searchValue}
        />
      </EventFilterBarRightItemContainer>
    </EventFilterBarInnerContainer>
    <EventFilterBarSummaryHeader>
      <EventFilterBarSummaryItem>Games Remaining</EventFilterBarSummaryItem>
      <EventFilterBarSummaryItem>Locations</EventFilterBarSummaryItem>
      <EventFilterBarSummaryItem>Officials</EventFilterBarSummaryItem>
      <EventFilterBarSummaryItem>Assessors</EventFilterBarSummaryItem>
      <EventFilterBarSummaryItem>Admins</EventFilterBarSummaryItem>
    </EventFilterBarSummaryHeader>
  </EventFilterBarContainer>
);

EventFilterBar.propTypes = {
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  governingBodies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  sort: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired
};

export default Enhancer(EventFilterBar);
