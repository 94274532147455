import React from 'react';

import Filters from '../../../Filters';
import config from './config';

const PeopleFilter = ({ categories, isNotAllEvents, ...filterProps }) => (
  <Filters
    config={
      isNotAllEvents ? config({ categories }).filters : config({ categories }).allEventsFilter
    }
    {...filterProps}
  />
);

export default PeopleFilter;
