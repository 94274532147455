import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';
import COLORS, { mediaQueries } from '../../../utils/styles';
import Icon from '../Icon';
import { pxToRem } from '../../../utils/helpers';

export const H4 = styled.span`
  font-size: 15px;
  color: #0d194f;
  font-weight: 300;
  display: flex;
`;

export const H6 = styled.span`
  font-size: 13px;
  color: #353535;
  font-weight: 600;
`;

export const CrewWrapper = styled(Col)`
  display: flex;
  align-items: center;
  padding-top: 5px;
  margin: 0px 10px;
  width: 100%;
`;

export const CrewMembers = styled.div`
  display: flex;
  font-size: 13px;
  padding-left: 10px;
  flex-wrap: wrap;
  & div {
    padding: 1px 5px;
    background: #fff;
    border: 1px solid #e6e3e3;
    margin: 0px 10px 0px 0px;
  }
  ${mediaQueries.xs(`
  flex-direction: column;
  `)};
`;

export const Status = styled.span`
  color: ${COLORS.denimBlue};
  text-transform: capitalize;
`;

export const CrewName = styled.span`
  font-weight: 500;
  color: ${COLORS.offBlack};
`;

export const NotificationWrapper = styled(Col)`
  padding: 10px 10px;
  margin: 6px 0px 3px 7px;
  border-radius: 5px;
  font-size: 13px;
  max-width: 96% !important;
  background: #fff1ba;
`;

export const GameId = styled.span`
  font-weight: 600;
  color: #615f5f;
  font-size: 12px;
`;

export const Location = styled.span`
  font-weight: 500;
  color: ${COLORS.shuttleGray};
  margin-right: 5px;
`;

export const EventName = styled.span`
  font-weight: 600;
  color: #167cd4;
  font-size: 14px;
`;

export const StatusIcon = styled(Icon)`
  margin: 0;
  position: relative;
  top: ${pxToRem('-2px')};
  left: ${pxToRem('3px')};
  opacity: 0.7;
  margin-left: 5px;
`;

export const Level = styled.span`
  font-weight: 600;
  color: #615f5f;
  font-size: 12.4px;
`;

export const ViewMore = styled(Col)`
  width: 100%;
  margin-left: 8px;
  .ViewMoreButton {
    font-size: 10px;
    margin-top: 10px;
    color: #167cd4;
    padding: 5px 6px;
  }
`;

export const ModalWrapper = styled.div`
  padding: 20px;
  text-align: left;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  .NotificationBackground {
    background: #f7f7f7;
    max-width: 100% !important;
    margin: 10px 0px;
  }
  .crewName {
    color: #444;
  }
`;
