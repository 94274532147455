import { createSelector } from 'reselect';
import { identity } from 'ramda';

// Actions
export const SHOW = 'PROGRESS/SHOW';
export const HIDE = 'PROGRESS/HIDE';
export const TOGGLE = 'PROGRESS/TOGGLE';

// Reducer
const initialState = {
  visibility: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE:
      return {
        visibility: action.payload
      };

    default:
      return state;
  }
}

// Selectors
export const getProgressVisibility = createSelector(state => state.progress.visibility, identity);

// Action Creators
export const hideProgress = () => ({
  type: HIDE,
  payload: false
});

export const showProgress = () => ({
  type: SHOW,
  payload: true
});
