import React from 'react';
import PropTypes from 'prop-types';
import { COLORS, ICONS } from '../../../../utils/styles';
import {
  UserItemOuterContainer,
  UserItemInnerContainer,
  FullNameLabel,
  StyledIcon,
  UserLabelContainer,
  UserLabel
} from './styled-components';

const UserItem = ({ handleCloseIconClick, id, email, firstName, lastName }) => (
  <UserItemOuterContainer>
    <UserItemInnerContainer>
      <FullNameLabel>{`${firstName} ${lastName}`}</FullNameLabel>
      <StyledIcon
        height={12}
        width={12}
        icon={ICONS.CLOSE}
        color={COLORS.shuttleGrey}
        onClick={() => handleCloseIconClick(id)}
      />
      <UserLabelContainer>
        <UserLabel>{email}</UserLabel>
      </UserLabelContainer>
    </UserItemInnerContainer>
  </UserItemOuterContainer>
);

UserItem.propTypes = {
  handleCloseIconClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
};

export default UserItem;
