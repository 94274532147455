import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import MultiEmailSender from '../../../EmailSender/multiEmailSender';
import { Block, Content } from './styled-components';
import Enhancer from '../../../../containers/ImportModal';
import MultiSelectDropdown from '../../../Reusable/MultiSelectDropdown';
import theme from '../../styled-components';

const EmailUsersModal = ({
  onClose,
  selected,
  onSubmit,
  filter,
  setFilter,
  recipient,
  setRecipient,
  allSelected
}) => (
  <Modal title="Email selected" onClose={onClose} leftPanel background>
    <Row>
      <Block xs={12}>
        <MultiSelectDropdown
          placeholder="Assignment Status"
          input={{
            value: filter,
            onChange: value => {
              let val = [...value];
              if (val[val.length - 1] === 'Email All') {
                val = ['Email All'];
              } else if (val.indexOf('Email All') > -1) {
                val.splice(val.indexOf('Email All'), 1);
              }
              const selectedUser = [];
              selected.forEach(game => {
                game.assignments_game.forEach(assignment => {
                  const user = { ...assignment.event_role.user };
                  const duplicate = selectedUser.map(u => u.id === user.id);
                  if (val.indexOf('Email All') > -1 || val.indexOf(assignment.status) > -1) {
                    if (duplicate.indexOf(true) === -1) {
                      user.status = [];
                      user.status.push(assignment.status);
                      selectedUser.push(user);
                    } else {
                      selectedUser[duplicate.indexOf(true)].status.push(assignment.status);
                    }
                  }
                });
              });
              return setRecipient(selectedUser, () => setFilter(val));
            }
          }}
          options={[
            { name: 'Email All', value: 'Email All' },
            { name: 'Unpublished', value: 'unpublished' },
            { name: 'Pending', value: 'pending' },
            { name: 'Accepted', value: 'accepted' },
            { name: 'Declined', value: 'declined' }
          ]}
          theme={theme.multiSelectDropdownUserList}
        />
        <Content>
          <MultiEmailSender
            recipientList={recipient}
            setRecipientList={setRecipient}
            onSubmit={onSubmit}
            onClose={onClose}
            allSelected={allSelected}
          />
        </Content>
      </Block>
    </Row>
  </Modal>
);

EmailUsersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired
};

EmailUsersModal.defaultProps = {};

export default Enhancer(EmailUsersModal);
