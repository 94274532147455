import React from 'react';
import HelpBox from './HelpBox';
// import QuantityBox from './QuantityBox';
import Enhancer from '../../containers/Dashboard';
import {
  Container,
  HelpSection,
  HelpSectionHeader,
  WelcomeMessage,
  WelcomeToText,
  HelpSectionContent,
  HelpBoxContainer,
  HelpSectionFooter,
  AdditionalHelpLink,
  NeedAdditionalHelpText
  // QuantitySection,
} from './styled-components';
import { getAssetsURL } from '../../../utils/helpers';
import BannerUnsheduledNotifications from '../BannerNotifications';
import PayoutFailedBanner from '../Banner/PayoutFailed';
import PayAccountInformational from '../Banner/PayAccountInformational';

const officialAssessorResources = [
  { text: 'New User & Account Login', link: `${getAssetsURL()}new-users-account-login.pdf` },
  { text: 'Manage Profile', link: `${getAssetsURL()}manage-profile.pdf` },
  { text: 'U.S. Soccer Certifications', link: `${getAssetsURL()}us-soccer-certifications.pdf` },
  { text: 'Availability', link: `${getAssetsURL()}availability.pdf` },
  { text: 'Assignments', link: `${getAssetsURL()}assignments.pdf` },
  { text: 'Officials - After Game Reports', link: `${getAssetsURL()}after-game-reports.pdf` },
  { text: 'Assessors - Assessments', link: `${getAssetsURL()}assessments.pdf` }
];

const assignorResources = [
  { text: 'Manage Events', link: `${getAssetsURL()}manage-events.pdf` },
  { text: 'Manage Users', link: `${getAssetsURL()}manage-users.pdf` },
  { text: 'Game Assignment', link: `${getAssetsURL()}assign-games.pdf` }
];

const Dashboard = ({
  setShowBannerForGamesUnscheduled,
  showBannerForGamesUnscheduled,
  notifications,
  bannerClose,
  user
}) => {
  return (
    <Container>
      <BannerUnsheduledNotifications
        setShowBannerForGamesUnscheduled={setShowBannerForGamesUnscheduled}
        showBannerForGamesUnscheduled={showBannerForGamesUnscheduled}
        notifications={notifications}
        bannerClose={bannerClose}
        bannerHeading="These games have been unscheduled and will affect the crews listed below. A game COPY in “Cancelled” status will appear in your event to manage assignments and/or create pay."
      />
      <PayoutFailedBanner user={user} />
      <PayAccountInformational user={user} />
      <HelpSection>
        <HelpSectionHeader>
          <WelcomeMessage>
            <WelcomeToText>Welcome to</WelcomeToText>
            STACK OFFICIALS!
          </WelcomeMessage>
        </HelpSectionHeader>
        <HelpSectionContent>
          <HelpBoxContainer>
            <HelpBox
              imageSrc={`${getAssetsURL()}user+icon+dashboard.png`}
              title="Officials & Assessors"
              helpLinks={officialAssessorResources}
            />
            <HelpBox
              imageSrc={`${getAssetsURL()}admin+icon+dashboard.png`}
              title="Assignors"
              helpLinks={assignorResources}
            />
          </HelpBoxContainer>
        </HelpSectionContent>
        <HelpSectionFooter>
          <AdditionalHelpLink
            target="_blank"
            to="https://stacksportsportal.force.com/customersupport/s/"
          >
            <NeedAdditionalHelpText>Need Additional Help?</NeedAdditionalHelpText>
            Contact Our Support Team.
          </AdditionalHelpLink>
        </HelpSectionFooter>
      </HelpSection>
      {/* <QuantitySection>
      <QuantityBox
        bgColor={`linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue});`}
        icon={ICONS.LIGHT_CALENDAR}
        quantity={0}
        link={{ label: 'Total Events', to: '#' }}
      />
      <QuantityBox
        bgColor={`linear-gradient(to top, ${COLORS.greenCyan}, ${COLORS.orangeYellow});`}
        icon={ICONS.SYNC}
        quantity={0}
        link={{ label: 'Total Pending Game Assignments', to: '#' }}
      />
      <QuantityBox
        bgColor={`linear-gradient(to top, ${COLORS.forestGreen}, ${COLORS.kellyGreen});`}
        icon={ICONS.CHECK}
        quantity={0}
        link={{ label: 'Total Accepted Game Assignments', to: '#' }}
      />
    </QuantitySection> */}
    </Container>
  );
};

export default Enhancer(Dashboard);
