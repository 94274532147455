import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getTransactionHistory } from '../../../../core/Payments/selectors';
// TODO:
const mapStateToProps = state => ({
  transactions: getTransactionHistory(state).data,
  isFetching: getTransactionHistory(state).fetching,
  transactionsMeta: getTransactionHistory(state).meta
});

export default compose(connect(mapStateToProps));
