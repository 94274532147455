import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';

import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Title = styled.span`
  color: ${COLORS.sapphire};
  text-transform: uppercase;
  font-size: 0.85rem;
  font-size: ${pxToRem('15px')};
  font-weight: 500;
  letter-spacing: 0.5px;
  ${({ customStyle }) => customStyle}
`;

export const Wrapper = styled(Row)`
  background-color: ${({ bgColor }) => bgColor || COLORS.blackSqueeze};
  padding: ${pxToRem('7px')};
  height: ${({ height }) => height || pxToRem('48px')};
  margin: ${({ margin }) => margin || '1rem 0 1rem 0'};
  display: flex;
  align-items: center;
`;

export const PaleTitleBar = styled.div`
  font-family: 'RobotoCondensed';
  font-size: 1rem;
  text-transform: uppercase;
  height: 3rem;
  line-height: 3rem;
  font-weight: bold;
  padding-left: 1rem;
  color: ${COLORS.greenVogue};
`;
