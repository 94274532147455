import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { OnboadingBtns, ButtonWrapperRight } from './styled-components';
import { history } from '../../../core/store';
import COLORS from '../../../utils/styles';

const SecondaryBtnStyle = {
  width: '105px',
  marginRight: '20px',
  background: `linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(232,232,232,1) 79%, rgba(209,209,209,1) 100%)`,
  fontWeight: '600',
  boxShadow: '0px 1px 3px 0px #929292',
  color: COLORS.lightBlue
};

const BoardingFlowButtons = ({
  nextBtnPath,
  previousBtnPath,
  disabled,
  justifyContent,
  margin,
  skipButtonPath
}) => {
  return (
    <OnboadingBtns disabled={disabled} justifyContent={justifyContent} margin={margin}>
      <>
        {previousBtnPath && (
          <Button
            type="button"
            customStyle={SecondaryBtnStyle}
            label="Previous"
            onClick={() => history.push(previousBtnPath)}
          />
        )}
        <ButtonWrapperRight>
          <Button
            type="button"
            customStyle={{ width: '105px', marginRight: '20px' }}
            label="Next"
            primary
            onClick={() => history.push(nextBtnPath)}
            disabled={disabled}
          />
          <Button
            type="button"
            customStyle={SecondaryBtnStyle}
            label="Skip for now"
            onClick={() => history.push(skipButtonPath)}
          />
        </ButtonWrapperRight>
      </>
    </OnboadingBtns>
  );
};

BoardingFlowButtons.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default BoardingFlowButtons;
