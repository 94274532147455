import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import Paper from '../../../layouts/Paper';
import TitleBar from '../../TitleBar';
import LoaderWrapper from '../../LoaderWrapper';
import { Wrapper } from './styled-components';
import Enhancer from '../../../containers/Event/CrewPay';
import { ActualContentContainer } from '../styled-components';
import CancelConfirmModal from './Modal/CancelConfirmModal';
import CrewPayTable from './CrewPayTable';

const PayTables = ({
  crewPayTableInEdit,
  setCrewPayTableInEdit,
  checkAndSetCrewPayTableInEdit,
  toggleShowModal,
  gameLevels,
  fetchGameLevel,
  copyFeesFromGameLevel,
  onSubmit,
  cancelEdit,
  showModal,
  isFetching,
  crewWarningModal,
  setCrewWarningModal,
  gameLevelFetching,
  sort,
  crewPayList,
  setCrewPayList,
  handleGameLevelsSorting,
  crewPayFetching
}) => {
  useEffect(() => {
    if (crewPayList.length) {
      if (sort && !sort.value) handleGameLevelsSorting();
      else if (sort && sort.value && sort.value[0] === '-') {
        handleGameLevelsSorting(sort.value.split('-')[1], true);
      } else if (sort && sort.value) handleGameLevelsSorting(sort.value);
    }
    // eslint-disable-next-line
  }, [crewPayList]);

  return (
    <LoaderWrapper isFetching={isFetching}>
      <ActualContentContainer>
        <Paper>
          <Wrapper>
            {showModal && (
              <CancelConfirmModal
                onClose={() => {
                  toggleShowModal(false);
                  setCrewPayTableInEdit(showModal);
                }}
                onSubmit={() => toggleShowModal(false)}
                tableName={(() => {
                  const game_level = gameLevels.find(gl => gl.id === crewPayTableInEdit);
                  return `${game_level.game_level} - ${game_level.game_type}`;
                })()}
              />
            )}
            <Col xs={12}>
              <TitleBar>CREW PAY</TitleBar>
            </Col>
            <CrewPayTable
              gameLevels={gameLevels}
              setCrewPayTableInEdit={setCrewPayTableInEdit}
              onSubmit={onSubmit}
              cancelEdit={cancelEdit}
              checkAndSetCrewPayTableInEdit={checkAndSetCrewPayTableInEdit}
              setCrewWarningModal={setCrewWarningModal}
              crewWarningModal={crewWarningModal}
              copyFeesFromGameLevel={copyFeesFromGameLevel}
              isFetching={isFetching}
              fetchGameLevel={fetchGameLevel}
              crewPayTableInEdit={crewPayTableInEdit}
              gameLevelFetching={gameLevelFetching}
              sort={sort}
              crewPayList={crewPayList}
              setCrewPayList={setCrewPayList}
              handleGameLevelsSorting={handleGameLevelsSorting}
              crewPayFetching={crewPayFetching}
            />
          </Wrapper>
        </Paper>
      </ActualContentContainer>
    </LoaderWrapper>
  );
};

PayTables.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  crewPayTableInEdit: PropTypes.string.isRequired,
  setCrewPayTableInEdit: PropTypes.func.isRequired,
  gameLevels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchGameLevel: PropTypes.func.isRequired,
  copyFeesFromGameLevel: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  checkAndSetCrewPayTableInEdit: PropTypes.func.isRequired,
  showModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  crewLabels: PropTypes.shape({
    labels: PropTypes.array,
    role_ids: PropTypes.array
  }),
  toggleShowModal: PropTypes.func.isRequired
};

PayTables.defaultProps = {
  showModal: false,
  crewLabels: PropTypes.shape({
    labels: [],
    role_ids: []
  })
};

export default Enhancer(PayTables);
