import React from 'react';
import PropTypes from 'prop-types';

import Container, { StyledCol } from './styled-components';

const Top = ({ children, xs, sm, md, lg, onClick }) => (
  <Container center="xs" middle="xs" onClick={onClick}>
    <StyledCol xs={xs} sm={sm} md={md} lg={lg}>
      {children}
    </StyledCol>
  </Container>
);

Top.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  onClick: PropTypes.func
};

Top.defaultProps = {
  xs: 10,
  sm: 10,
  md: 8,
  lg: 5,
  onClick: () => true
};

export default Top;
