import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, NumberContainer, NumberInput } from './styled-components';

const validateNumsAndMax = (val, prev, max, trimLeadingZeros) => {
  if (max && val.length > max) return prev;
  let updatedVal = val;

  if (trimLeadingZeros && val[0] === '0') updatedVal = val.slice(1);

  return updatedVal.match(/^\d+$/) || updatedVal === '' ? updatedVal : prev;
};

const NumberInputSelector = ({ value, onChange, max, theme, trimLeadingZeros }) => (
  <Wrapper theme={theme.wrapper}>
    <NumberContainer>
      <NumberInput
        theme={theme.input}
        value={value}
        onChange={e => {
          const val = e.target.value;
          onChange(validateNumsAndMax(val, value, max, trimLeadingZeros));
        }}
      />
    </NumberContainer>
  </Wrapper>
);

NumberInputSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  theme: PropTypes.shape({
    input: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired
  }),
  trimLeadingZeros: PropTypes.bool
};

NumberInputSelector.defaultProps = {
  max: null,
  value: '',
  theme: {
    input: '',
    wrapper: ''
  },
  trimLeadingZeros: false
};

export default NumberInputSelector;
