import React from 'react';
import PropTypes from 'prop-types';

import buildConfig from './config';
import SideList from '../SideList';
import generateUserMain from '../SideList/Generic/UserMain';
import generateUserExpanded from '../SideList/Generic/Expanded';

import Enhancer from '../../containers/Lists/AssessorsList';

const summaryInfo = [
  { label: 'Age', name: 'age', width: '6ch' },
  { label: 'Rank', name: 'rank', width: '6ch' },
  { label: 'Cert.', name: 'cert', width: '11ch' },
  { label: 'State', name: 'state', width: '15ch' },
  { label: 'Assigned', name: user => user.assignments_count, width: '7ch' }
];

export const AssessorsList = ({ onUpdateCategories, ...props }) => (
  <SideList
    onLoad={props.onLoad}
    onFetchItemDetail={props.onFetchItemDetail}
    fetchNextOfficials={props.onFetchNextOfficials}
    items={props.users}
    roles={{ alias: 'assessor', list: ['Assessor'] }}
    selectedProp="eventRoleId"
    type="assessor"
    mainComponent={generateUserMain({ summaryInfo })}
    expandedComponent={generateUserExpanded({
      onUpdateCategories,
      canEdit: props.isGamesEventGroupAdmin || props.isEventAdmin
    })}
    config={buildConfig(props)}
    eventId={props.match.params.eventId}
    setSelectedUser={props.setSelectedUser}
    infinity
    eventName={props.eventInfo && props.eventInfo.name}
    eventInfo={props.eventInfo}
    hasNextPage={props.hasNextPage}
    isFetchingEventRoles={props.isFetchingEventRoles}
    {...props}
  />
);

AssessorsList.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onFetchItemDetail: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(Object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  }).isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  onUpdateCategories: PropTypes.func.isRequired,
  isGamesEventGroupAdmin: PropTypes.bool.isRequired
};

export default Enhancer(AssessorsList);
