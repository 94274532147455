import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import { fetchEventLocations } from '../../../../core/Locations';
import { getUser } from '../../../../core/Auth/selectors';
import { getEventAdmins } from '../../../../core/Users/selectors';
import {
  getLocations,
  getLocationMeta,
  getUnfilteredLocations
} from '../../../../core/Locations/selectors';
import { getRoles } from '../../../../core/Roles/selectors';
import { paginationAware, filterConfigToNames, updateOnPageChange } from '../../PaginationAware';
import buildConfig from '../../../components/LocationsList/config';

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch(fetchEventLocations(payload))
});

const mapStateToProps = (state, props) => ({
  locations: getLocations(state),
  admins: getEventAdmins(state),
  user: getUser(state),
  metaData: getLocationMeta(state),
  onLoadPageArgs: {
    roles: getRoles(state),
    eventId: props && props.match && props.match.params && props.match.params.eventId
  },
  allLocations: getUnfilteredLocations(state)
});
const PAGINATION_AWARE_PREFIX = 'loc';

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware(
    {
      fetcherQuote: 'onLoad',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'metaData',
      searchFilter: ['location.surface_name'],
      filterNames: filterConfigToNames(buildConfig({}).filters)
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  )
);
