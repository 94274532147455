import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Paper from '../../../layouts/Paper';
import Button from '../../Button';
import RoundedCheckBox from '../../RoundedCheckBox';
import LoaderWrapper from '../../LoaderWrapper';
import { ColHeaderGame, Container as ValueColContainer } from '../../SimpleTable';
import { PaleTitleBar } from '../../TitleBar';
import { COLORS, ICONS } from '../../../../utils/styles';
import { CrewLabels } from '..';
import GameLevelAndTypeSorter from './GameLevelsSorter';
import config from './GameLevelsSorter/config';

import {
  TableRowGame,
  TableContainer,
  OutsideContainer,
  CheckboxContainer,
  StatusIconGame,
  HeaderRowGame,
  StaticValue,
  NavItem
} from './styled-components';
import { sortListByKey } from '../../../../utils/helpers';

const StaticValueField = ({ unit = '', ...rProps }) => (
  <Field
    component={({ input: { value } }) => (
      <StaticValue> {`${value || (unit ? 0 : 'None')} ${unit}`}</StaticValue>
    )}
    {...rProps}
  />
);

const copyInfo = (game_levels, currentIndex, index) => {
  const copyIndex = currentIndex === 0 ? 0 : currentIndex || index;
  const copyArray = [
    'official_min_grades',
    'assessor_min_grades',
    'min_ranks',
    'role_ids',
    'labels',
    'length_period',
    'set_crew_pay'
  ];
  if (currentIndex !== null) {
    const result = {};
    Object.keys(game_levels[copyIndex]).forEach(d => {
      if (copyArray.indexOf(d) > -1) {
        result[d] = game_levels[copyIndex][d];
      } else {
        result[d] = game_levels[index][d];
      }
      return null;
    });
    return result;
  }
  return game_levels[copyIndex];
};

StaticValueField.propTypes = {
  unit: PropTypes.string
};

StaticValueField.defaultProps = {
  unit: ''
};

export const GameLevels = ({
  game_levels,
  isFetching,
  currentId,
  setId,
  currentIndex,
  setIndex,
  event,
  sort
}) => {
  const [sortedGameLevels, setSortedGameLevels] = useState(game_levels || []);

  const handleGameLevelsSorting = (sortingColumn = 'schedule_name', desc = false) => {
    const gameLevels = sortListByKey(game_levels, sortingColumn, desc);
    setSortedGameLevels(gameLevels);
  };

  useEffect(() => {
    if (sort && !sort.value) handleGameLevelsSorting();
    else if (sort && sort.value && sort.value[0] === '-') {
      handleGameLevelsSorting(sort.value.split('-')[1], true);
    } else if (sort && sort.value) handleGameLevelsSorting(sort.value);
    // eslint-disable-next-line
  }, [game_levels]);

  return (
    <LoaderWrapper isFetching={isFetching}>
      <Paper>
        <PaleTitleBar>GAME LEVELS & TYPES</PaleTitleBar>
        <OutsideContainer>
          <TableContainer>
            <HeaderRowGame between="xs">
              {[
                'Schedule Name',
                'Game Level',
                'Game Type',
                'Game Length',
                'Period Length',
                'Crew Size',
                'Crew Pay Enabled',
                ''
              ].map(name => (
                <ColHeaderGame xs key={name}>
                  <NavItem>
                    {name}{' '}
                    <GameLevelAndTypeSorter
                      value={
                        name === 'Schedule Name' || name === 'Game Level' || name === 'Game Type'
                          ? (sort && sort.value) || 'schedule_name'
                          : ''
                      }
                      onSort={sort && sort.onSort}
                      option={config.options.filter(option => option.label === name)}
                      handleGameLevelsSorting={handleGameLevelsSorting}
                    />{' '}
                  </NavItem>
                </ColHeaderGame>
              ))}
            </HeaderRowGame>
            {sortedGameLevels.map((e, index) => (
              <Fragment key={`frangment-${e.schedule_id}`}>
                <TableRowGame between="xs" key={`key-${e.schedule_id}`}>
                  <ValueColContainer xs>
                    <StaticValue>{e.schedule_name || 'None'}</StaticValue>
                  </ValueColContainer>
                  <ValueColContainer xs>
                    <StaticValue>{e.game_level || 'None'}</StaticValue>
                  </ValueColContainer>
                  <ValueColContainer xs>
                    <StaticValue>{e.game_type || 'None'}</StaticValue>
                  </ValueColContainer>
                  <ValueColContainer xs>
                    <StaticValue>
                      {e.length_game || 0}
                      {' Min'}
                    </StaticValue>
                  </ValueColContainer>
                  <ValueColContainer xs>
                    <StaticValue>
                      {e.length_period || 0}
                      {' Min'}
                    </StaticValue>
                  </ValueColContainer>
                  <ValueColContainer xs>
                    <StaticValue>{e.crew_size || 'None'}</StaticValue>
                  </ValueColContainer>
                  <ValueColContainer xs>
                    <CheckboxContainer>
                      {e.set_crew_pay ? (
                        <StatusIconGame
                          width={18}
                          height={18}
                          color={COLORS.forestGreen}
                          icon={ICONS.CHECK_CIRCLE_HOLLOW}
                        />
                      ) : (
                        <RoundedCheckBox disabled />
                      )}
                    </CheckboxContainer>
                  </ValueColContainer>
                  <ValueColContainer xs>
                    <Button
                      type="button"
                      label="Edit"
                      name="label"
                      noShadow
                      gameEdit
                      onClick={() => {
                        setIndex(null);
                        return setId({
                          index,
                          val: game_levels[index].schedule_id,
                          eventId: game_levels[index].event_id
                        });
                      }}
                    />
                  </ValueColContainer>
                </TableRowGame>
                {currentId.index === index && (
                  <CrewLabels
                    isUSSoccer={event.info.governing_body === 'U.S. Soccer Federation'}
                    locked={game_levels[index].locked}
                    currentId={currentId}
                    crew_labels={copyInfo(game_levels, currentIndex, index)}
                    eventId={currentId.eventId}
                    currentIndex={currentIndex}
                    game_levels={game_levels.map((d, i) => ({
                      label: d.game_level,
                      value: i
                    }))}
                    setIndex={setIndex}
                    setId={setId}
                    batchedOrStaged={game_levels[index].batchedOrStaged}
                  />
                )}
              </Fragment>
            ))}
          </TableContainer>
        </OutsideContainer>
      </Paper>
    </LoaderWrapper>
  );
};

GameLevels.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  game_levels: PropTypes.arrayOf(
    PropTypes.shape({
      game_level: PropTypes.string,
      game_type: PropTypes.string,
      length_game: PropTypes.number,
      length_period: PropTypes.number,
      crew_size: PropTypes.number,
      difficulty_male: PropTypes.number,
      difficulty_female: PropTypes.number
    })
  )
};

GameLevels.defaultProps = {
  game_levels: []
};

export default GameLevels;
