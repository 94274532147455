import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrimaryContainer = styled.div`
  width: 100%;
  display: flex;
  height: 4rem;
  box-sizing: border-box;
  z-index: ${({ opened }) => opened && 1};
  border-bottom: ${({ opened }) => opened && `1px solid ${COLORS.lightCyanGray}`};
  -webkit-box-shadow: ${({ opened }) => opened && '-1px 4px 5px 0px rgba(0,0,0,0.31)'};
  -moz-box-shadow: ${({ opened }) => opened && '-1px 4px 5px 0px rgba(0,0,0,0.31)'};
  box-shadow: ${({ opened }) => opened && '-1px 4px 5px 0px rgba(0,0,0,0.31)'};
`;

export const MainRow = styled.div`
  position: relative;
  top: ${pxToRem('10px')};
  display: flex;
  width: 100%;
`;

export const StyledCol = styled(Col)`
  ${({ noPadding }) => (noPadding ? 'padding: 0' : '')};
`;

export const ProfilePic = styled.img`
  width: 4rem;
  height: 4rem;
`;

export const DefaultProfilePic = styled.div`
  padding-top: ${pxToRem('10px')};
`;

export const Wrapper = MainWrapper.extend`
  flex: 0 0 auto;
`;

export const InfoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${({ first }) => (first ? `padding-top: ${pxToRem('6px')}; justify-content: flex-start` : '')};
  ${({ last }) => (last ? `padding-bottom: ${pxToRem('6px')}` : '')};
`;

export const Name = styled.span`
  display: inline-flex;
  align-items: flex-end;
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('15px')};
  font-weight: 500;
  text-align: left;
  ${({ selected }) => `color:${selected ? COLORS.white : COLORS.mineShaft}`};
`;

export const Field = styled.span`
  display: inline-flex;
  align-items: flex-end;
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('15px')};
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  letter-spacing: normal;
  text-align: left;
  padding-left: ${({ padding }) => (padding ? `0px` : `0.5rem`)};
  ${({ selected }) => `color:${selected ? COLORS.white : COLORS.denimBlue}`};
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  flex-grow: 1;
  justify-content: space-around;
  ${({ selected }) =>
    ` background:${
      selected
        ? `linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue})`
        : `linear-gradient(to bottom, ${COLORS.white} 0%, ${COLORS.blackSqueeze} 100%);`
    }`};
`;

export const PrimaryLabel = styled.span`
  display: inline-flex;
  align-items: flex-end;
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('11px')};
  letter-spacing: -0.3px;
  text-align: left;
  ${({ selected }) => `color:${selected ? COLORS.white : COLORS.shuttleGrey}`};
`;

export const PrimaryValue = styled.span`
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('15px')};
  line-height: 1rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  letter-spacing: normal;
  text-align: left;
  padding-left: 0.5rem;
  ${({ selected }) => `color:${selected ? COLORS.white : COLORS.denimBlue}`};
`;

export const Detail = styled.div`
  display: flex;
  width: 100%;
`;
