import { compose, lifecycle, withHandlers, withState } from 'recompose';

export default compose(
  withState('showModal', 'setShowModal', false),
  withState('showTransactionHistoryTable', 'setShowTransactionHistoryTable', false),
  withState('page', 'setPage', 0),

  withHandlers({
    handleAddFundModal: ({ setShowModal }) => () => setShowModal(prev => !prev),
    handleFetchTransactionHistory: ({ fetchTransactionHistory }) => payload =>
      fetchTransactionHistory(payload),
    handleTransactionHistoryCarretToggle: ({
      showTransactionHistoryTable,
      setShowTransactionHistoryTable,
      setPage
    }) => () => {
      setPage(0);
      setShowTransactionHistoryTable(!showTransactionHistoryTable);
    },
    showMoreTransactionHistory: ({ fetchTransactionHistory, page, setPage }) => ({ limit }) => {
      fetchTransactionHistory({ limit: limit + 10 * (page + 1) });
      setPage(page + 1);
    }
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.payerId) this.props.getWallet();
    }
  })
);
