import { call, put, takeLatest, takeEvery, all, take, select } from 'redux-saga/effects';
import { compose, isEmpty, flatten, prop, difference, map } from 'ramda';
import moment from 'moment-timezone';
import {
  FETCH_EVENT_USERS,
  FETCH_EVENT_PEOPLE,
  FETCH_EVENT_ASSIGNORS,
  FETCH_EVENT_USER_DETAIL,
  FETCH_NEXT_SET_EVENT_USERS,
  DELETE_EVENT_USERS,
  ADD_RANK_EVENT_USERS,
  SUBMIT_USER_IMPORT,
  SUBMIT_USER_MANUALLY,
  SEND_BULK_EMAIL,
  ADD_EVENT_USERS,
  UPDATE_CATEGORIES,
  ASSIGN_CATEGORIES_TO_USERS,
  setEventUsers,
  updateUserDetail,
  setSelectedUser,
  setCategoriesToUsers,
  setIsFetching,
  setIsFetchingDetails,
  setEventAssignors,
  setIsFetchingInit,
  updateRankEvent,
  setEventRoleLinks,
  setIsFetchingEventRolesActionCreator
} from './index';

import {
  spreadEvent,
  fetchEventCategories,
  SET_EVENT_CATEGORIES,
  setEventUsersAvailability
} from '../Events';
import { getEventCategories } from '../Events/selectors';
import { getEventRoleLinks, getMetaByType } from './selectors';
import { SET_ROLES, fetchRoles } from '../Roles';
import { getRoleForName } from '../Roles/selectors';
import { addNotifications } from '../Notifications';
import Api from '../../utils/api-wrapper';
import { errorProgressDecorate } from '../Progress/helper';
import { mergeData, dataWithPagination } from '../../utils/parsers';
import { isEventAdmin } from '../Auth/selectors';
import { getPaymentsEventId } from '../Payments/selectors';
import { ROLES_CONSTANT, CORE_ROLES } from '../../constants';

const DEFAULT_SORT = 'name';
const DEFAULT_PAGE = '1';

export function* processUserItemFiller({ payload: { userId, eventRoleId, roleName } }) {
  const { profile } = yield all({
    profile: call(Api.fetchUserProfile, { userId })
  });

  yield put(
    updateUserDetail({
      roleName,
      eventRoleId,
      profile: mergeData(profile)
    })
  );
}

/**
 * Get roles by name
 * It queries role's state and brings roles
 * @param {Array} roleNames - array of role names
 */
const getRoles = roleNames => roleNames.map(name => select(getRoleForName(name)));

export function* processFetchEventAssignors({ payload: { sort, page, filters, limit } }) {
  const eventId = yield select(getPaymentsEventId);

  const response = yield call(Api.fetchEventRolesRelationship, {
    eventId,
    filters,
    sortingValue: sort || DEFAULT_SORT,
    page,
    limit,
    roles: [3]
  });

  yield put(setEventAssignors({ data: mergeData(response), meta: response.meta }));
}

export function* fetchEventAssignors(params) {
  yield errorProgressDecorate(processFetchEventAssignors, params, setIsFetching);
}

export function* saveEventRolesInState(response, roleName) {
  yield put(setEventRoleLinks(response && response.meta && response.meta.links));

  const metaData = yield select(getMetaByType(roleName));
  const { limit } = metaData;

  yield compose(
    put,
    setEventUsers,
    values => ({
      values: {
        ...values,
        meta: { limit, ...values.meta },
        data: values.data.map(info => {
          return {
            ...info.users,
            eventRoleId: info.id,
            userCert: info.roles,
            role: ROLES_CONSTANT[info.role_id],
            rank: info.rank ? info.rank : '',
            grade: info.roles && info.roles.grade,
            distanceToField: info.addresses && info.addresses.distance_to_field,
            city: info.addresses && info.addresses.city,
            state: info.addresses && info.addresses.state,
            categories: info.categories,
            assignments_count: info.game_assignments_count,
            requiredPaymentProfile: info.required_payment_profile,
            payee: { status: info.payeeStatus, transfers_enabled: info.transfers_enabled },
            taxStatus: info.taxStatus,
            certifications: info.certifications,
            ussfDetails: info.ussfDetails,
            availabilities: info.availabilities,
            availability_summary: info.availability_summary
          };
        })
      },
      roleName
    }),
    dataWithPagination
  )(response);

  const usersWithAvailability = {
    usersWithAvailability: response.data.map(ua => {
      return { id: ua.attributes.user_id, availabilities: ua.attributes.availabilities };
    })
  };

  if (usersWithAvailability) yield put(setEventUsersAvailability(usersWithAvailability));
}

export function* fetchNextSetOfEventUsers({ payload: { role: roleName } }) {
  try {
    const eventRoleLinks = yield select(getEventRoleLinks);

    // The API returns "/api/" but we need to strip it out because the utility that makes the http request already has it.
    // Not looking to change the contract of API or our api wrapper just for this.
    const nextLink =
      eventRoleLinks && eventRoleLinks.next && eventRoleLinks.next.replace('/api/', '/');

    if (!nextLink) {
      return;
    }

    yield put(setIsFetchingEventRolesActionCreator(true));

    const response = yield call(Api.fetchNextEventRolesRelationship, { nextLink });

    yield call(saveEventRolesInState, response, roleName);
  } catch (error) {
    let err = error;
    if (error.message) {
      err = error.message;
    }
    yield put(addNotifications(err));
  } finally {
    yield put(setIsFetchingEventRolesActionCreator(false));
  }
}

export function* fetchEventUsers({
  payload: {
    eventId,
    roles: { alias, list },
    userid,
    sort,
    page,
    filters,
    toCSV = false,
    resolve = () => {}
  }
}) {
  try {
    yield put(setIsFetching(true));
    yield put(setIsFetchingEventRolesActionCreator(true));
    if (!page || page === DEFAULT_PAGE) {
      yield put(setIsFetchingInit(true));
    }

    let roles = yield all([...getRoles(list)]);
    if (isEmpty(flatten(roles))) {
      yield put(fetchRoles());
      yield take(SET_ROLES);
      roles = yield all([...getRoles(list)]);
    }
    yield put(fetchEventCategories({ eventId }));
    yield take(SET_EVENT_CATEGORIES);
    const eventCategories = yield select(getEventCategories);

    const eventRoleCategoryFilters = (filters && filters['categories_filtered.category_id']) || [];
    const modifiedFilters = filters;
    const isAdmin = yield select(isEventAdmin({ params: { eventId } }));

    if (
      eventRoleCategoryFilters.length === 0 &&
      !isAdmin &&
      eventCategories.length &&
      eventCategories[0].user_categories.length
    ) {
      modifiedFilters['categories_filtered.category_id'] = eventCategories.map(
        category => category.id
      );
    }

    if (filters && filters.filter_date) {
      modifiedFilters.timezone = moment.tz.guess();
    }
    const roleName = alias.toLowerCase();
    const metaData = yield select(getMetaByType(roleName));
    const { limit } = metaData;

    const response = yield call(Api.fetchEventRolesRelationship, {
      eventId,
      filters: modifiedFilters,
      sortingValue: sort || DEFAULT_SORT,
      userid,
      page,
      limit,
      roles: flatten(roles).map(({ id }) => id),
      toCSV
    });

    if (!toCSV) {
      yield call(saveEventRolesInState, response, roleName);
      yield put(setSelectedUser(null));
    } else yield resolve(response);
  } catch (error) {
    let err = error;
    if (error.message) {
      err = error.message;
    }
    yield put(addNotifications(err));
  } finally {
    yield put(setIsFetching(false));
    yield put(setIsFetchingInit(false));
    yield put(setIsFetchingEventRolesActionCreator(false));
  }
}

export function* processfetchEventPeople({
  payload: {
    eventId,
    roles: { alias, list },
    userid,
    sort,
    page,
    filters,
    peopleTab = false,
    toCSV = false,
    resolve = () => {}
  }
}) {
  const roles = yield all([...getRoles(list)]);
  yield put(fetchEventCategories({ eventId }));
  yield take(SET_EVENT_CATEGORIES);
  const eventCategories = yield select(getEventCategories);
  const eventRoleCategoryFilters = (filters && filters['categories_filtered.category_id']) || [];
  const modifiedFilters = filters;
  const isAdmin = yield select(isEventAdmin({ params: { eventId } }));

  if (
    eventRoleCategoryFilters.length === 0 &&
    !isAdmin &&
    eventCategories.length &&
    eventCategories[0].user_categories.length
  ) {
    modifiedFilters['categories_filtered.category_id'] = eventCategories.map(
      category => category.id
    );
  }
  if (peopleTab) {
    if (modifiedFilters.search) {
      modifiedFilters.people_search = modifiedFilters.search;
      modifiedFilters.search = '';
    }
  }
  const roleName = alias.toLowerCase();
  const metaData = yield select(getMetaByType(roleName));
  const { people_limit, limit } = metaData;
  const newLimit = people_limit || limit;

  const response = yield call(Api.fetchEventRolesPeople, {
    eventId,
    filters: modifiedFilters,
    sortingValue: sort || (peopleTab && 'roles'),
    userid,
    page,
    limit: newLimit,
    roles: flatten(roles).map(({ id }) => id),
    toCSV
  });
  if (!toCSV) {
    yield compose(
      put,
      setEventUsers,
      values => ({
        values: {
          ...values,
          meta: { limit: newLimit, ...values.meta },
          data: values.data.map(info => ({
            ...info.users,
            eventRoleId: info.eventRoleId,
            role: ROLES_CONSTANT[info.role_id],
            rank: info.rank,
            grade: info.roles && info.roles.grade,
            categories: info.categories,
            contacts: info.contacts,
            addresses: info.addresses,
            eventName: info.events,
            certifications: info.certifications
          }))
        },
        roleName
      }),
      dataWithPagination
    )(response);
    yield put(setSelectedUser(null));
  } else yield resolve(response);
}

export function* fetchEventPeople(params) {
  yield errorProgressDecorate(processfetchEventPeople, params, setIsFetching);
}

export function* userItemFiller(params) {
  yield errorProgressDecorate(processUserItemFiller, params, setIsFetchingDetails);
}

const onlyCategoryRoleId = map(prop('categoryRoleId'));

export function* updateUserCategories({
  payload: {
    roleName,
    user: { eventRoleId, categories },
    updatedCategories
  }
}) {
  const removedIds = difference(
    onlyCategoryRoleId(categories),
    onlyCategoryRoleId(updatedCategories)
  );
  yield call(Api.deleteEventCategories, { eventRoleId, categoriesRoleIds: removedIds });
  yield put(
    updateUserDetail({
      roleName,
      eventRoleId,
      categories: updatedCategories
    })
  );
}

export function* submitUserImport({ payload: { eventId, file, resolve } }) {
  try {
    const response = yield call(Api.submitUserImport, { id: eventId, file });
    yield call(resolve, response);
  } catch (err) {
    yield call(resolve, err.message[0]);
  }
}

export function* submitUserManually({ payload: { eventId, user, role } }) {
  try {
    const role_id = CORE_ROLES[role];
    const reponse = yield call(Api.submitUserManually, {
      id: eventId,
      user,
      role_id
    });
    yield put(
      addNotifications([
        {
          type: 'success',
          message:
            reponse.data && reponse.data[1]
              ? 'New user role successfully added'
              : 'The user with this role has already been added'
        }
      ])
    );
  } catch (err) {
    const { message } = err;
    yield put(addNotifications([{ type: 'error', message: message && message[0].message }]));
  }
}

/** This algorithm is designed to handle the process of adding users to an event,
 * updating the event data, and providing feedback to the user in the form of a notification.
 * It uses the redux-saga library to handle asynchronous operations and side effects. */
export function* addEventUsersAPI({
  payload: { eventId, userList, role_id, events, allSelected, sourceEventId, sourceRoleIds }
}) {
  try {
    const request = { eventId, role_id };

    if (allSelected) {
      request.allSelected = allSelected;
      request.source_event_id = sourceEventId;
      request.source_role_ids = sourceRoleIds;
    } else {
      request.userList = userList;
    }

    yield call(Api.addEventUsers, request);
    // if the event that the users are being added to is not in events[0], then it is not on the current page and
    // does not need to be retrieved from the api yet
    if (events.some(event => event.id === eventId)) {
      const updatedEvent = yield call(Api.getEvent, { eventId });
      // todo: refactor this to use a single call from getEvent to fetch locations and games count
      const [
        {
          data: { locationsCount: locationCount }
        },
        {
          data: { gamesCount: gameCount }
        }
      ] = yield all([
        call(Api.fetchLocationsCount, { eventId }),
        call(Api.fetchGamesCount, { eventId })
      ]);
      yield put(
        compose(
          spreadEvent,
          ({ id, summary }) => ({
            id,
            props: {
              summary: {
                ...summary,
                gameCount,
                locationCount
              }
            }
          }),

          mergeData
        )(updatedEvent)
      );
    }
    yield put(
      addNotifications([{ type: 'success', message: 'Users successfully added to event' }])
    );
  } catch (error) {
    yield put(addNotifications([{ type: 'error', message: 'Failed to copy users' }]));
  }
}

export function* deleteEventUsers({
  payload: {
    selected: users,
    eventId,
    roles,
    pagination: { onLoadPage },
    allSelect
  }
}) {
  yield errorProgressDecorate(function* () {
    const request = { eventId };
    if (allSelect) {
      request.role_ids = roles.id;
    } else {
      const userIds = users && users.map(u => u.eventRoleId).join(',');
      request.userIds = userIds;
    }

    const { data } = yield call(Api.removeEventUsers, request);
    const updatedEvent = yield call(Api.getEvent, { eventId });
    const [
      {
        data: { locationsCount: locationCount }
      },
      {
        data: { gamesCount: gameCount }
      }
    ] = yield all([
      call(Api.fetchLocationsCount, { eventId }),
      call(Api.fetchGamesCount, { eventId })
    ]);
    yield put(
      compose(
        spreadEvent,
        ({ id, summary }) => ({
          id,
          props: {
            summary: {
              ...summary,
              gameCount,
              locationCount
            }
          }
        }),

        mergeData
      )(updatedEvent)
    );
    yield call(onLoadPage, { eventId, roles });
    yield put(
      addNotifications([
        data && data.unsuccessful > 0
          ? { type: 'error', message: 'Cannot remove users with game assignments' }
          : { type: 'success', message: 'Users successfully removed from event' }
      ])
    );
  });
}

export function* sendBulkEmail(payload) {
  const {
    payload: { userIds, subject, body, resolve, event_id, role_ids }
  } = payload;
  try {
    const message = {
      data: {
        subject: subject && subject.trim(),
        body: body && body.trim()
      }
    };

    if (event_id && role_ids) {
      message.data.event_id = event_id;
      message.data.role_ids = role_ids;
    } else {
      message.data.user_ids = userIds;
    }

    yield call(Api.sendEmail, message);
    yield call(resolve, true);
  } catch (error) {
    yield put(addNotifications([error]));
    yield call(resolve);
  }
}

const collapseCategoryArrayObjectPropertyArray = array => {
  // category data looks like this [{"181544":[{"category_id":"1569"}]},{"180820":[{"category_id":"1569"}]}]
  // we need to extract just the category ids which is also an array of objects
  let result = [];
  if (!array || !Array.isArray(array)) return array;

  array.forEach(item => {
    const innerObject = Object.values(item);
    let category;
    if (Array.isArray(innerObject[0]))
      category = collapseCategoryArrayObjectPropertyArray(innerObject[0]);
    else category = innerObject;
    result.push(category);
  });
  if (Array.isArray(result[0])) result = result.flatten();
  const returned = [...new Set(result)];
  return returned;
};

export function* assignCategoriesToUsers({
  payload: { categories: categoryData, userType, peopleTab, allSelected, event_id, role_ids }
}) {
  const hasCategoryData = !!categoryData.length;
  let assignedSuccessfuly = false;

  try {
    if (hasCategoryData) {
      let response;
      let request;

      if (allSelected && event_id && role_ids) {
        const categories = collapseCategoryArrayObjectPropertyArray(categoryData);
        request = { event_id, role_ids, categories };
        response = yield call(Api.assignCategoriesToUsersBulk, request);
        // had to match the structure of the other way of getting data
        response = [response];
      } else {
        response = yield all(
          categoryData.map(category => {
            const key = Object.keys(category)[0];
            const categories = category[key];
            request = { eventRoleId: key, categories };

            return call(Api.assignCategoriesToUsers, request);
          })
        );
      }
      const assignedCategories = flatten(response.map(res => mergeData(res)));
      assignedSuccessfuly = !!assignedCategories.length;
      if (!peopleTab) {
        const eventCategories = yield select(getEventCategories);
        yield put(
          setCategoriesToUsers({
            userType,
            assignedCategories: assignedCategories.map(assignedCategory => ({
              ...assignedCategory,
              category_id: assignedCategory.category_id.toString(),
              event_role_id: assignedCategory.event_role_id.toString(),
              created_by_user_id: assignedCategory.created_by_user_id.toString(),
              name: eventCategories.filter(
                ({ id }) => id === assignedCategory.category_id.toString()
              )[0].name
            }))
          })
        );
      }
    }

    const successMessage = assignedSuccessfuly
      ? 'The categories were assigned successfully'
      : 'The categories were already assigned';
    yield put(addNotifications([{ type: 'success', message: successMessage }]));
  } catch (error) {
    yield put(
      addNotifications([
        { type: 'error', message: 'An error occurred while trying to assign categories' }
      ])
    );
  }
}

export function* addRankEventUsers({ payload }) {
  const { rank, selected, event_id, role_ids } = payload;

  const requestBody = { rank };

  if (event_id && role_ids) {
    requestBody.event_id = event_id;
    requestBody.role_ids = role_ids;
  } else {
    const role_id =
      payload && payload.selected && payload.selected.map(d => parseInt(d.eventRoleId, 10));
    // i know it is confusing but this is the api's old data contract...
    // what this really means event role ids not the id of the role
    requestBody.role_id = role_id;
  }

  try {
    yield put(setIsFetchingInit(true));
    yield call(Api.addRank, requestBody);
    // this will only update the subset selected in the dom for the ui but when data is retrieved from the back end
    // it will get the correct rank values. again this is just so it is not necessary to do an api data fetch for data
    // already on hand
    yield put(updateRankEvent(selected, rank));
    yield put(setIsFetchingInit(false));
  } catch (error) {
    yield put(
      addNotifications({ type: 'error', message: 'An error occurred while trying to update rank' })
    );
    yield put(setIsFetchingInit(false));
  }
}

export const usersSagas = [
  takeLatest(FETCH_NEXT_SET_EVENT_USERS, fetchNextSetOfEventUsers),
  takeLatest(FETCH_EVENT_USERS, fetchEventUsers),
  takeLatest(FETCH_EVENT_PEOPLE, fetchEventPeople),
  takeEvery(FETCH_EVENT_USER_DETAIL, userItemFiller),
  takeLatest(SUBMIT_USER_IMPORT, submitUserImport),
  takeLatest(SUBMIT_USER_MANUALLY, submitUserManually),
  takeLatest(ADD_EVENT_USERS, addEventUsersAPI),
  takeEvery(DELETE_EVENT_USERS, deleteEventUsers),
  takeLatest(SEND_BULK_EMAIL, sendBulkEmail),
  takeLatest(UPDATE_CATEGORIES, updateUserCategories),
  takeLatest(ASSIGN_CATEGORIES_TO_USERS, assignCategoriesToUsers),
  takeLatest(FETCH_EVENT_ASSIGNORS, fetchEventAssignors),
  takeEvery(ADD_RANK_EVENT_USERS, addRankEventUsers)
];

export default usersSagas;
