import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';

const UnpublishGamesModal = ({ onClose, selected, onSubmit }) => {
  const published = selected.filter(game => game.published);
  return (
    <Modal title="Close self-assignment " onClose={onClose} leftPanel>
      <>
        <Row>
          <HighlightedBlock xs={12}>
            <Content>
              <Summary>
                <Count color={COLORS.denimBlue}>{published.length}</Count> Games Selected
              </Summary>
            </Content>
          </HighlightedBlock>
        </Row>
        <Row>
          <Block xs={10} xsOffset={1}>
            <Content first light>
              This hides the games which are open for self assignment from users without assignment
              permission and from users that have not been assignment to the game(s)
            </Content>
            <Content>
              {published.length
                ? 'Close self-assignment?'
                : 'The selected games are already closed for self-assignment'}
            </Content>
          </Block>
        </Row>
        <Row>
          <Block xs={12} className="last">
            <ButtonsContainer>
              <ButtonContainer>
                <Button
                  label="Confirm"
                  onClick={() => {
                    onClose();
                    onSubmit(published);
                  }}
                  disabled={!published.length}
                  width="96px"
                  height="32px"
                  primary
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
              </ButtonContainer>
            </ButtonsContainer>
          </Block>
        </Row>
      </>
    </Modal>
  );
};

UnpublishGamesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired
};

UnpublishGamesModal.defaultProps = {};

export default UnpublishGamesModal;
