import { createSelector } from 'reselect';
import { propEq, filter } from 'ramda';
import { mergeData } from '../../utils/parsers';

export const getRoles = createSelector(
  ({ roles }) => mergeData(roles.roleList),
  list => list
);
export const getRoleForName = roleName =>
  createSelector(getRoles, filter(propEq('label', roleName)));

export const getFundingAdmins = ({ roles }) =>
  roles.fundingAdminsList && roles.fundingAdminsList.included;

export default getRoles;
