import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../Flex';
import Icon from '../../../Icon';
import theme, { Checkbox, Field } from '../../styled-components';
import { COLORS, ICONS } from '../../../../../utils/styles';
import { convertUtcToTimezone, getReportDisplayIdWithCount } from '../../../../../utils/helpers';

const IncidentReport = ({
  report,
  handleCheckboxClick,
  checked,
  onIncidentReportClick,
  isReportsEventAdmin,
  isReportsEventGroupAdmin,
  isReportsEventAssignor
}) => {
  return (
    <Flex
      height="55px"
      bgImage="linear-gradient(to top, #f4f8fb, #ffffff)"
      justifyContent="space-between"
      fullWidth
      padding="0 36px"
      noShrink
      theme={theme.row.item}
    >
      {(isReportsEventAdmin || isReportsEventAssignor || isReportsEventGroupAdmin) && (
        <Checkbox
          type="checkbox"
          onChange={e => handleCheckboxClick({ id: report.id, checked: e.target.checked })}
          checked={checked}
        />
      )}
      <Field
        clickable
        onClick={() => onIncidentReportClick(report)}
        originalCased
        justifyContent="center"
      >
        {getReportDisplayIdWithCount(report)}
      </Field>
      {
        <Field justifyContent="center">
          {convertUtcToTimezone(report.game.start_at, report.game.timezone, 'M/D/YY')}
        </Field>
      }
      {['report_type', 'reason', 'report_on'].map(field => (
        <Field key={field} justifyContent="center">
          {report[field] || 'TBD'}
        </Field>
      ))}
      <Field justifyContent="center">{`${report.submitted_by.first_name} ${report.submitted_by.last_name}`}</Field>
      <Field justifyContent="center">
        <Flex justifyContent="center" maxWidth="95px" fullWidth>
          <>{report.offensive_language && 'Yes'}</>
        </Flex>
      </Field>
      <Field justifyContent="center">
        <Flex maxWidth="45px" fullWidth>
          <>
            {report.approved && (
              <Icon
                width={18}
                height={18}
                icon={ICONS.CHECK_CIRCLE_HOLLOW}
                color={COLORS.forestGreen}
              />
            )}
          </>
        </Flex>
      </Field>
    </Flex>
  );
};

IncidentReport.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  onIncidentReportClick: PropTypes.func.isRequired,
  isReportsEventAdmin: PropTypes.bool
};

IncidentReport.defaultProps = {
  checked: false,
  isReportsEventAdmin: false
};

export default IncidentReport;
