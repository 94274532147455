import React from 'react';
import { Link } from 'react-router-dom';
import { pure } from 'recompose';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import moment from 'moment';
import { isNil } from 'ramda';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import COLORS, { ICONS } from '../../../../../utils/styles';
import MouseOverPopover from '../../../../global-material-components/Popover';
import CarretButton from '../../../CarretButton';
import { ROLES_CONSTANT } from '../../../../../constants';
import {
  PrimaryContainer,
  Summary,
  InfoRow,
  Name,
  Detail,
  PrimaryLabel,
  PrimaryValue,
  NonPaymentIcon,
  PaymentMouseOverPopoverWrapper,
  LastLoginLabel,
  LastLoginValue,
  GamePositionStatusIcon,
  StatusIcon,
  HeadingInfo,
  DetailInfoWrapper,
  SidePanelIcon,
  AvailabilityInfoWrapper,
  DistanceInfoWrapper,
  NameNoOverFlow
} from './styled-components';
import { truncateWithEllipses } from '../../../../../utils/parsers';
import {
  isFilteredByAvailability,
  licensesShortForm,
  sInterpolator
} from '../../../../../utils/helpers';
import Icon from '../../../Icon';
import { setReturnUrl, userIdxGetter } from '../../../../../utils/auth';
import { PROFILE_INFORMATION } from '../../../../../core/paths';

const NO_LICENSE = 'No Lic.';

const useStyles = makeStyles({
  certValueColor: {
    '& span': {
      color: COLORS.reddish
    },
    '&:hover span': {
      color: '#0d194f'
    }
  },
  customTooltip: {
    backgroundColor: '#3D4956'
  }
});

const defaultSummaryInfo = [
  { label: 'Age', name: 'age', width: '5ch' },
  { label: 'Cert.', name: 'cert', width: '12ch' },
  { label: 'State', name: 'state', width: '15ch' },
  { label: 'Assigned', name: 'assigned', width: '7ch' }
];

const labelConstant = {
  CERT: 'Cert.'
};

const UserDetails = ({ label, name, width, useraddress, user, cert, selected }) => {
  const classes = useStyles();
  const { suspended } = user && !isNil(user.certifications) && user.certifications;
  const expired = cert === NO_LICENSE;
  const userRoleCheck = user && user.role !== ROLES_CONSTANT[2] && user.role !== ROLES_CONSTANT[6];
  return (
    <Detail
      key={label}
      title={user[name]}
      isRole={name === 'role'}
      name={label}
      width={width}
      className={
        (expired || suspended) &&
        label === labelConstant.CERT &&
        !selected &&
        userRoleCheck &&
        classes.certValueColor
      }
    >
      {label === labelConstant.CERT && userRoleCheck && (expired || suspended) ? (
        <Tooltip
          classes={{
            tooltip: classes.customTooltip
          }}
          title={expired ? 'No active license' : 'Certification Suspended'}
          arrow
        >
          <DetailInfoWrapper>
            <PrimaryLabel selected={selected} name={label}>
              {label === 'State' ? (
                <GamePositionStatusIcon>
                  <StatusIcon
                    width={13}
                    height={13}
                    color={selected ? COLORS.white : COLORS.darkGrey}
                    icon={ICONS.LOCATION}
                  />
                </GamePositionStatusIcon>
              ) : label === labelConstant.CERT && suspended ? (
                <GamePositionStatusIcon>
                  <StatusIcon
                    width={13}
                    height={13}
                    color={!selected && (expired || suspended) ? COLORS.reddish : COLORS.white}
                    icon={ICONS.EXCLAMATION_CIRCLE}
                  />{' '}
                  {label}
                </GamePositionStatusIcon>
              ) : (
                label
              )}
            </PrimaryLabel>
            <PrimaryValue selected={selected} name={label}>
              {name === 'state' && useraddress && user[name]
                ? `${useraddress}, ${user[name]}`
                : name === 'cert'
                ? cert
                : typeof name === 'function'
                ? name(user)
                : user[name] === 'Funding Admin'
                ? 'Funding'
                : user[name]}
            </PrimaryValue>
          </DetailInfoWrapper>
        </Tooltip>
      ) : (
        <DetailInfoWrapper>
          <PrimaryLabel selected={selected} name={label}>
            {label === 'State' ? (
              <GamePositionStatusIcon>
                <StatusIcon
                  width={13}
                  height={13}
                  color={selected ? COLORS.white : COLORS.darkGrey}
                  icon={ICONS.LOCATION}
                />
              </GamePositionStatusIcon>
            ) : label === labelConstant.CERT && expired && userRoleCheck ? (
              <GamePositionStatusIcon>
                <StatusIcon
                  width={13}
                  height={13}
                  color={!selected && expired ? COLORS.reddish : COLORS.white}
                  icon={ICONS.EXCLAMATION_CIRCLE}
                />{' '}
                {label}
              </GamePositionStatusIcon>
            ) : (
              label
            )}
          </PrimaryLabel>
          <PrimaryValue selected={selected} name={label}>
            {name === 'state' && useraddress && user[name]
              ? `${useraddress}, ${user[name]}`
              : name === 'cert' && userRoleCheck
              ? cert
              : typeof name === 'function'
              ? name(user)
              : user[name] === 'Funding Admin'
              ? 'Funding'
              : user[name]}
          </PrimaryValue>
        </DetailInfoWrapper>
      )}
    </Detail>
  );
};

const AvailabilityDisplay = ({ availabilities }) => {
  const { innerWidth: windowWidth } = window;
  const content = [];
  const length = availabilities && availabilities.length;
  let itemLimit = length <= 3 ? length : 3;
  if (windowWidth < 1200) itemLimit = length <= 2 ? length : 2;
  if (windowWidth < 800) itemLimit = length <= 1 ? length : 1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < itemLimit; i++) {
    const availability = availabilities[i];
    if (availability) {
      if (availability.note) {
        content.push(
          <Tooltip title={availability.note} arrow>
            <SidePanelIcon height="13" color={COLORS.orangeYellow} icon={ICONS.NOTES} />
          </Tooltip>
        );
      }
      content.push(<b>{availability.time}</b>);
    }
    if (i !== itemLimit - 1) {
      content.push(', ');
      content.push(' '); // pad
    }
    if (i + 1 === itemLimit && length > itemLimit) {
      content.push(' '); // pad
      content.push(
        <Tooltip title="Expand Row For Full List" arrow>
          <SidePanelIcon height="13" color={COLORS.black} icon={ICONS.ELLIPSIS} />
        </Tooltip>
      );
    }
  }

  return <NameNoOverFlow>{content}</NameNoOverFlow>;
};

const rolesArray = ['Official', 'Assessor', 'Assignor'];

const generateUserMain = ({ summaryInfo = defaultSummaryInfo }) =>
  pure(
    ({
      item: user,
      selected,
      expanded,
      isFirst,
      canEdit,
      onClickExpand,
      filter,
      onSelected,
      expandSideList,
      eventInfo,
      style
    }) => {
      const cert = !isNil(user.certifications)
        ? `${licensesShortForm(user.certifications.cert_name)} ${
            user.certifications.valid_through !== 'NA'
              ? moment.utc(user.certifications.valid_through).format('YYYY')
              : ''
          }`
        : user && user.ussfDetails && user.ussfDetails.isValid
        ? NO_LICENSE
        : '';
      let showIcon = false;
      if (eventInfo) {
        showIcon =
          eventInfo.required_payment_profile &&
          rolesArray.includes(user.role) &&
          !(user.payee && user.payee.transfers_enabled);
      }
      const handleClick = () => {
        const currentUrl = window.location.pathname + window.location.search;
        setReturnUrl({ path: currentUrl });
      };
      const distanceFilter = filter && filter.filterData && filter.filterData.distance;
      const showDistanceAndAvailabilityRow = distanceFilter || isFilteredByAvailability(filter);
      const isAdmin = user && user.role !== ROLES_CONSTANT[4] && user.role !== ROLES_CONSTANT[5];
      const complexName = (filter?.filterData?.location || '').split(',')[0];

      const availabilities =
        (user.availability_summary && user.availability_summary.availability) || [];

      return (
        <PrimaryContainer
          opened={expanded}
          selected={selected}
          isFirst={isFirst}
          onClick={onSelected}
          eventInfo={eventInfo}
          distanceFilter={showDistanceAndAvailabilityRow}
          style={style}
        >
          <Summary selected={selected}>
            <InfoRow first>
              <HeadingInfo flex style={{ marginRight: 0 }}>
                {!isAdmin && (
                  <>
                    {user && user.allow_admin_updates ? (
                      <Link
                        to={sInterpolator(
                          { userIdx: userIdxGetter(), userId: user.id },
                          PROFILE_INFORMATION
                        )}
                      >
                        <Tooltip title="Edit Profile" arrow>
                          <button
                            type="button"
                            onClick={handleClick}
                            style={{ border: 'none', background: 'none' }}
                          >
                            <Icon
                              height={15}
                              width={15}
                              icon={ICONS.EDIT_CLASSIC}
                              color={COLORS.darkBlue}
                              style={{ marginTop: -12, marginRight: 0, marginLeft: -10 }}
                            />
                          </button>
                        </Tooltip>
                      </Link>
                    ) : user && !user.allow_admin_updates ? (
                      <Link
                        to={sInterpolator(
                          { userIdx: userIdxGetter(), userId: user.id },
                          PROFILE_INFORMATION
                        )}
                      >
                        <Tooltip title="View Profile" arrow>
                          <button
                            type="button"
                            onClick={handleClick}
                            style={{ border: 'none', background: 'none' }}
                          >
                            <Icon
                              height={15}
                              width={15}
                              icon={ICONS.EDIT_CLASSIC}
                              color={COLORS.greyChateau}
                              style={{ marginTop: -12, marginRight: 0, marginLeft: -10 }}
                            />
                          </button>
                        </Tooltip>
                      </Link>
                    ) : null}
                  </>
                )}
                <Name selected={selected}>
                  <b> {user.last_name}</b>
                </Name>
                <Name selected={selected}>,{user.first_name}</Name>
                {!!showIcon && (
                  <PaymentMouseOverPopoverWrapper>
                    <MouseOverPopover
                      transformOriginHorizontal="left"
                      messageTitle="Incomplete Profile"
                      messageBody="Payment and/or tax info is missing"
                    >
                      <NonPaymentIcon>
                        {' '}
                        <MonetizationOnOutlinedIcon />{' '}
                      </NonPaymentIcon>
                    </MouseOverPopover>
                  </PaymentMouseOverPopoverWrapper>
                )}
              </HeadingInfo>
              {canEdit && (
                <HeadingInfo className="lastLogin">
                  <LastLoginLabel selected={selected} showIcon={!showIcon}>
                    Last Login:
                  </LastLoginLabel>
                  <LastLoginValue selected={selected} showIcon={!showIcon}>
                    {user.last_login
                      ? `${moment
                          .utc(user.last_login)
                          .utcOffset(moment().utcOffset())
                          .format('M/D/YY')} @ ${moment
                          .utc(user.last_login)
                          .utcOffset(moment().utcOffset())
                          .format('h:mm a')}`
                      : 'NA'}
                  </LastLoginValue>
                </HeadingInfo>
              )}
            </InfoRow>
            <InfoRow>
              {summaryInfo.map(({ label, name, width }) => {
                const useraddress = !isNil(user.city)
                  ? `${truncateWithEllipses(user.city, 15)}`
                  : '';
                return (
                  <UserDetails
                    key={label}
                    label={label}
                    name={name}
                    width={width}
                    useraddress={useraddress}
                    user={user}
                    cert={cert}
                    selected={selected}
                  />
                );
              })}
            </InfoRow>
            {showDistanceAndAvailabilityRow && (
              <InfoRow>
                <HeadingInfo flex>
                  {user.distanceToField && (
                    <DistanceInfoWrapper>
                      <GamePositionStatusIcon>
                        <Tooltip title={`Distance to ${complexName}`} arrow>
                          <SidePanelIcon
                            width="20"
                            height="13"
                            color={COLORS.naturalGray}
                            icon={ICONS.CAR}
                          />
                        </Tooltip>
                      </GamePositionStatusIcon>
                      <Name>
                        <b style={{ color: COLORS.naturalGray }}> {user.distanceToField} </b>
                      </Name>
                    </DistanceInfoWrapper>
                  )}
                  {user.availability_summary && (
                    <DistanceInfoWrapper>
                      <GamePositionStatusIcon>
                        <SidePanelIcon
                          height="13"
                          color={COLORS.naturalGray}
                          icon={ICONS.CALENDAR_SM}
                        />
                      </GamePositionStatusIcon>
                      <Name>
                        <b style={{ color: COLORS.naturalGray }}>
                          {user.availability_summary.date}
                        </b>
                      </Name>
                    </DistanceInfoWrapper>
                  )}
                  {user.availability_summary && (
                    <AvailabilityInfoWrapper>
                      <AvailabilityDisplay availabilities={availabilities} />
                    </AvailabilityInfoWrapper>
                  )}
                </HeadingInfo>
              </InfoRow>
            )}
          </Summary>
          <CarretButton
            opened={expanded}
            onClick={onClickExpand}
            selected={selected}
            width={1.5}
            customStyle={{ pointerEvents: expandSideList && 'none' }}
          />
        </PrimaryContainer>
      );
    }
  );

export default generateUserMain;
