import { compose, pickBy, not, equals, or, assoc, merge, assocPath, update, nth } from 'ramda';
import pluralize from 'pluralize';

// Actions
export const FETCH_EVENT_USERS = 'USERS/FETCH_EVENT_USERS';
export const FETCH_NEXT_SET_EVENT_USERS = 'USERS/FETCH_NEXT_SET_EVENT_USERS';
export const FETCH_EVENT_PEOPLE = 'USERS/FETCH_EVENT_PEOPLE';
export const FETCH_EVENT_USER_DETAIL = 'USERS/FETCH_EVENT_USER_DETAIL';

export const FETCH_EVENT_ASSIGNORS = 'USERS/FETCH_EVENT_ASSIGNORS';
export const SET_EVENT_ASSIGNORS = 'USERS/SET_EVENT_ASSINGORS';

export const SET_EVENT_ROLE_LINKS = 'USERS/SET_EVENT_ROLE_LINKS';
export const SET_IS_FETCHING_EVENT_ROLES = 'USERS/SET_IS_FETCHING_EVENT_ROLES';

export const SET_EVENT_USERS = 'USERS/SET_EVENT_USERS';
export const ADD_EVENT_USERS = 'USERS/ADD_EVENT_USERS';
export const DELETE_EVENT_USERS = 'USERS/DELETE_EVENT_USERS';
export const ADD_RANK_EVENT_USERS = 'USERS/ADD_RANK_EVENT_USERS';

export const UPDATE_USER_DETAIL = 'USERS/UPDATE_USER_DETAIL';
export const SUBMIT_USER_IMPORT = 'USERS/SUBMIT_USER_IMPORT';
export const SUBMIT_USER_MANUALLY = 'USERS/SUBMIT_USER_MANUALLY';
export const SET_META_DATA = 'USERS/SET_META_DATA';

export const SEND_BULK_EMAIL = 'USERS/SEND_BULK_EMAIL';

export const SET_SELECTED_USER = 'USERS/SET_SELECTED_USER';

export const SET_ASSIGNMENT_ACCEPTANCE_USER = 'USERS/SET_ASSIGNMENT_ACCEPTANCE_USER';

export const UPDATE_CATEGORIES = 'USERS/UPDATE_CATEGORIES';
export const UPDATE_SINGLE_USER = 'USERS/UPDATE_SINGLE_USER';

export const ASSIGN_CATEGORIES_TO_USERS = 'USERS/ASSIGN_CATEGORIES_TO_USERS';
export const SET_CATEGORIES_TO_USERS = 'USERS/SET_CATEGORIES_TO_USERS';

export const SET_IS_FETCHING_INIT = 'USERS/SET_IS_FETCHING_INIT';
export const SET_IS_FETCHING = 'USERS/SET_IS_FETCHING';
export const SET_IS_FETCHING_DETAILS = 'USERS/SET_IS_FETCHING_DETAILS';

export const ADD_ASSIGNMENT = 'USERS/ADD_ASSIGNMENT';
export const REMOVE_ASSIGNMENT = 'USERS/REMOVE_ASSIGNMENT';
export const UPDATE_RANK_EVENT_USERS = 'USERS/UPDATE_RANK_EVENT_USERS';

const metaDefaultState = {
  data: [],
  meta: { total_count: 0, limit: 100 }
};

const usersDefaultState = {
  data: [],
  meta: { total_count: 0, limit: 100, people_limit: 2000 }
};
// Reducer
export const initialState = {
  officials: metaDefaultState,
  assessors: metaDefaultState,
  admins: metaDefaultState,
  users: usersDefaultState,
  assignors: metaDefaultState,
  selectedUser: null,
  isFetching: false,
  isFetchingInit: false,
  isFetchingDetails: false,
  selectedUserForAssignmentAccept: [],
  eventRoleLinks: null,
  isFetchingEventRoles: false
};

/**
 * Takes an Object returns an object without roleName and userId props
 * Object :: Object
 */
const noAdditionalInfo = pickBy(compose(not, equals('roleName'), or, equals('userId')));

const getPluralOfRole = role => pluralize(role, true);

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_EVENT_USERS: {
      let users = action.payload.values;
      const currentUsers = state && state[pluralize(action.payload.roleName)];
      if (
        currentUsers.data &&
        currentUsers.data.length > 0 &&
        action.payload.values &&
        action.payload.values.meta &&
        action.payload.values.meta.links &&
        action.payload.values.meta.links.self &&
        action.payload.values.meta.links.prev
      ) {
        users = {
          data: currentUsers.data.concat(action.payload.values.data),
          meta: action.payload.values.meta
        };
      }
      return assoc(pluralize(action.payload.roleName), users)(state);
    }
    case UPDATE_USER_DETAIL: {
      const { eventRoleId, roleName, ...updatedInfo } = action.payload;
      const userIdx = state[roleName].data.findIndex(user => user.eventRoleId === eventRoleId);
      return {
        ...state,
        [roleName]: {
          ...state[roleName],
          data: update(
            userIdx,
            merge(nth(userIdx, state[roleName].data), updatedInfo),
            state[roleName].data
          )
        }
      };
    }
    case SET_META_DATA:
      return assocPath(
        [pluralize(action.payload.roleName), 'meta'],
        merge(state[pluralize(action.payload.roleName)].meta, noAdditionalInfo(action.payload)),
        state
      );
    case SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.payload
      };
    }
    case SET_EVENT_ROLE_LINKS: {
      return {
        ...state,
        eventRoleLinks: action.payload
      };
    }
    case SET_IS_FETCHING_EVENT_ROLES: {
      return {
        ...state,
        isFetchingEventRoles: action.payload
      };
    }
    case SET_ASSIGNMENT_ACCEPTANCE_USER: {
      return {
        ...state,
        selectedUserForAssignmentAccept: action.payload.flag
          ? [...state.selectedUserForAssignmentAccept, action.payload.user]
          : []
      };
    }
    case SET_EVENT_ASSIGNORS:
      return {
        ...state,
        assignors: action.payload
      };
    case SET_CATEGORIES_TO_USERS:
      return {
        ...state,
        [getPluralOfRole(action.payload.userType)]: {
          ...state[getPluralOfRole(action.payload.userType)],
          data: state[getPluralOfRole(action.payload.userType)].data.map(user => {
            const newCategories = user.categories;

            action.payload.assignedCategories.forEach(
              ({ id, category_id, event_role_id, name }) => {
                if (user.eventRoleId === event_role_id) {
                  newCategories.push({
                    id: Number(category_id),
                    categoryRoleId: id,
                    name
                  });
                }
              }
            );

            return {
              ...user,
              categories: newCategories
            };
          })
        }
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_IS_FETCHING_INIT:
      return {
        ...state,
        isFetchingInit: action.payload
      };
    case SET_IS_FETCHING_DETAILS:
      return {
        ...state,
        isFetchingDetails: action.payload
      };
    case ADD_RANK_EVENT_USERS:
      return {
        ...state
      };
    case ADD_ASSIGNMENT:
      return {
        ...state,
        [pluralize(action.payload.type)]: {
          ...state[pluralize(action.payload.type)],
          data: state[pluralize(action.payload.type)].data.map(user => {
            if (action.payload.eventRoleId === user.eventRoleId && user.assignments) {
              user.assignments.push({ id: parseInt(action.payload.assignment.id, 10) });
            }
            return user;
          })
        }
      };

    case REMOVE_ASSIGNMENT:
      return {
        ...state,
        [pluralize(action.payload.type)]: {
          ...state[pluralize(action.payload.type)],
          data: state[pluralize(action.payload.type)].data.map(user => {
            if (action.payload.eventRoleId === user.eventRoleId && user.assignments) {
              user.assignments.forEach((assignment, i) => {
                if (assignment.id === parseInt(action.payload.assignmentId, 10)) {
                  user.assignments.splice(i, 1);
                }
              });
            }
            return user;
          })
        }
      };
    case UPDATE_RANK_EVENT_USERS: {
      const users = {};
      let role = '';
      action.payload.forEach(user => {
        users[user.eventRoleId] = user;
        if (!role) {
          role = getPluralOfRole(user.role.toLowerCase());
        }
      });
      return {
        ...state,
        [role]: {
          ...state[role],
          data: state[role].data.map(user => {
            if (users[user.eventRoleId]) {
              return {
                ...user,
                rank: action.rank
              };
            }
            return user;
          })
        }
      };
    }
    default:
      return state;
  }
}

// Action Creators
export const fetchEventUsers = payload => ({
  type: FETCH_EVENT_USERS,
  payload
});

export const fetchNextSetOfEventUsers = payload => ({
  type: FETCH_NEXT_SET_EVENT_USERS,
  payload
});

export const fetchEventPeople = payload => ({
  type: FETCH_EVENT_PEOPLE,
  payload
});

export const fetchEventAssignors = payload => ({
  type: FETCH_EVENT_ASSIGNORS,
  payload
});

export const setEventAssignors = payload => ({
  type: SET_EVENT_ASSIGNORS,
  payload
});

export const fetchEventUserDetail = payload => ({
  type: FETCH_EVENT_USER_DETAIL,
  payload
});

export const setEventUsers = payload => ({
  type: SET_EVENT_USERS,
  payload
});

export const setEventRoleLinks = payload => ({
  type: SET_EVENT_ROLE_LINKS,
  payload
});

export const setIsFetchingEventRolesActionCreator = payload => ({
  type: SET_IS_FETCHING_EVENT_ROLES,
  payload
});

export const setEventMetaData = payload => ({
  type: SET_META_DATA,
  payload
});

export const updateUserDetail = payload => ({
  type: UPDATE_USER_DETAIL,
  payload
});

export const submitUserImport = payload => ({
  type: SUBMIT_USER_IMPORT,
  payload
});

export const submitUserManually = payload => ({
  type: SUBMIT_USER_MANUALLY,
  payload
});

export const addEventUsersSaga = payload => ({
  type: ADD_EVENT_USERS,
  payload
});

export const deleteEventUsers = payload => ({
  type: DELETE_EVENT_USERS,
  payload
});

export const sendBulkEmail = payload => ({
  type: SEND_BULK_EMAIL,
  payload
});

export const setSelectedUser = payload => ({
  type: SET_SELECTED_USER,
  payload
});

export const setAssignmentAceeptanceUser = payload => ({
  type: SET_ASSIGNMENT_ACCEPTANCE_USER,
  payload
});

export const assignCategoriesToUsers = payload => ({
  type: ASSIGN_CATEGORIES_TO_USERS,
  payload
});

export const setCategoriesToUsers = payload => ({
  type: SET_CATEGORIES_TO_USERS,
  payload
});

export const updateCategories = payload => ({
  type: UPDATE_CATEGORIES,
  payload
});

export const setIsFetching = payload => ({
  type: SET_IS_FETCHING,
  payload
});

export const setIsFetchingInit = payload => ({
  type: SET_IS_FETCHING_INIT,
  payload
});

export const setIsFetchingDetails = payload => ({
  type: SET_IS_FETCHING_DETAILS,
  payload
});

export const addRankEvent = payload => ({
  type: ADD_RANK_EVENT_USERS,
  payload
});

export const addAssignment = payload => ({
  type: ADD_ASSIGNMENT,
  payload
});

export const removeAssignment = payload => ({
  type: REMOVE_ASSIGNMENT,
  payload
});

export const updateRankEvent = (payload, rank) => ({
  type: UPDATE_RANK_EVENT_USERS,
  payload,
  rank
});
