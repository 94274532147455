import React from 'react';
import PropTypes from 'prop-types';
import { COLORS, ICONS } from '../../../../utils/styles';
import {
  RecipientItemOuterContainer,
  RecipientItemInnerContainer,
  EmailLabelContainer,
  EmailLabel,
  FullNameLabel,
  StyledIcon
} from './styled-components';

const RecipientItem = ({ handleCloseIconClick, id, email, firstName, lastName }) => (
  <RecipientItemOuterContainer>
    <RecipientItemInnerContainer>
      <FullNameLabel>{`${firstName} ${lastName}`}</FullNameLabel>
      <StyledIcon
        height={12}
        width={12}
        icon={ICONS.CLOSE}
        color={COLORS.shuttleGrey}
        onClick={() => handleCloseIconClick(id)}
      />
      <EmailLabelContainer>
        <EmailLabel title={email}>{email}</EmailLabel>
      </EmailLabelContainer>
    </RecipientItemInnerContainer>
  </RecipientItemOuterContainer>
);

RecipientItem.propTypes = {
  handleCloseIconClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
};

export default RecipientItem;
