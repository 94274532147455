// Actions
export const FETCH_BILLING_CUSTOMER = 'BILLING_CUSTOMER/FETCH_BILLING_CUSTOMER';
export const CREATE_BILLING_CUSTOMER = 'BILLING_CUSTOMER/CREATE_BILLING_CUSTOMER';
export const SET_BILLING_CUSTOMERS = 'BILLING_CUSTOMER/SET_BILLING_CUSTOMERS';
export const ADD_BILLING_CUSTOMER = 'BILLING_CUSTOMER/ADD_BILLING_CUSTOMER';
export const SET_IS_FETCHING = 'BILLING_CUSTOMER/SET_IS_FETCHING';
export const FETCH_GROUP_BILLNIG_CUSTOMER = 'BILLING_CUSTOMER/FETCH_GROUP_BILLNIG_CUSTOMER';
export const SET_GAME_BILLING_CUSTOMER = 'BILLING_CUSTOMER/SET_GAME_BILLING_CUSTOMER';
export const SET_TAX_BILLING_CUSTOMER = 'BILLING_CUSTOMER/SET_TAX_BILLING_CUSTOMER';

export const UPDATE_BILLING_CUSTOMER = 'BILLING_CUSTOMER/UPDATE_BILLING_CUSTOMER';
export const SET_GAME_PAYER_BILLIG_CUSTOMER = 'BILLING_CUSOTMER/SET_GAME_PAYER_BILLIG_CUSTOMER';
export const SET_TAX_PAYER_BILLIG_CUSTOMER = 'BILLING_CUSOTMER/SET_TAX_PAYER_BILLIG_CUSTOMER';
export const SET_IS_FETCHING_PAYER = 'BILLING_CUSOTMER/SET_IS_FETCHING_PAYER';
export const SET_IS_FETCHING_CUSTOMER = 'BILLING_CUSOTMER/SET_IS_FETCHING_CUSTOMER';

// Reducer
const initialState = {
  customersList: [],
  isFetchingCustomers: false,
  isFetchingPayer: false,
  isFetching: false,
  gamePayerCustomers: [],
  taxPayerCustomers: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_BILLING_CUSTOMERS: {
      return {
        ...state,
        customersList: [...action.payload]
      };
    }
    case ADD_BILLING_CUSTOMER: {
      return {
        ...state,
        customersList: [...state.customersList, action.payload]
      };
    }
    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload
      };
    }
    case SET_GAME_BILLING_CUSTOMER: {
      return {
        ...state,
        gamePayerCustomers: action.payload
      };
    }
    case SET_TAX_BILLING_CUSTOMER: {
      return {
        ...state,
        taxPayerCustomers: action.payload
      };
    }
    case SET_GAME_PAYER_BILLIG_CUSTOMER: {
      const gamePayer = state.customersList.findIndex(
        gamePayerCustomer => Number(action.payload.id) === Number(gamePayerCustomer.id)
      );

      if (gamePayer !== -1) {
        const updatedCustomersList = [
          ...state.customersList.slice(0, gamePayer),
          {
            ...state.customersList[gamePayer],
            ...action.payload
          },
          ...state.customersList.slice(gamePayer + 1)
        ];

        return {
          ...state,
          gamePayerCustomers: updatedCustomersList
        };
      }

      return state;
    }
    case SET_TAX_PAYER_BILLIG_CUSTOMER: {
      const taxPayer = state.taxPayerCustomers.findIndex(
        taxPayerCustomer => Number(action.payload.id) === Number(taxPayerCustomer.id)
      );
      if (taxPayer !== -1) {
        return {
          ...state,
          taxPayerCustomers: [
            ...state.taxPayerCustomers.slice(0, taxPayer),
            {
              ...state.taxPayerCustomers[taxPayer],
              ...action.payload
            },
            ...state.taxPayerCustomers.slice(taxPayer + 1)
          ]
        };
      }
      return state;
    }
    case SET_IS_FETCHING_PAYER: {
      return {
        ...state,
        isFetchingPayer: action.payload
      };
    }
    case SET_IS_FETCHING_CUSTOMER: {
      return {
        ...state,
        isFetchingCustomers: action.payload
      };
    }
    default:
      return state;
  }
}

// Action Creators
export const fetchBillingCustomers = payload => ({
  type: FETCH_BILLING_CUSTOMER,
  payload
});

export const setBillingCustomers = payload => ({
  type: SET_BILLING_CUSTOMERS,
  payload
});

export const createBillingCustomer = payload => ({
  type: CREATE_BILLING_CUSTOMER,
  payload
});

export const addBillingCustomer = payload => ({
  type: ADD_BILLING_CUSTOMER,
  payload
});

export const updateBillingCustomer = payload => ({
  type: UPDATE_BILLING_CUSTOMER,
  payload
});

export const setIsFeching = payload => ({
  type: SET_IS_FETCHING,
  payload
});

export const fetchGroupBillingCustomers = payload => ({
  type: FETCH_GROUP_BILLNIG_CUSTOMER,
  payload
});

export const setGameBillingCustomer = payload => ({
  type: SET_GAME_BILLING_CUSTOMER,
  payload
});

export const setTaxBillingCustomer = payload => ({
  type: SET_TAX_BILLING_CUSTOMER,
  payload
});

export const setGamePayerBillingCustomer = payload => ({
  type: SET_GAME_PAYER_BILLIG_CUSTOMER,
  payload
});

export const setIsFechingPayer = payload => ({
  type: SET_IS_FETCHING_PAYER,
  payload
});

export const setIsFechingCustomer = payload => ({
  type: SET_IS_FETCHING_CUSTOMER,
  payload
});

export const setTaxPayerBillingCustomer = payload => ({
  type: SET_TAX_PAYER_BILLIG_CUSTOMER,
  payload
});
