import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ErrorOutline as AssignmentConflict } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Circle from '../../../../../Circle';
import LoaderWrapper from '../../../../../LoaderWrapper';

import {
  Placeholder,
  CellRow,
  CellWrapper,
  Name,
  EmptyCircle,
  Wrapper,
  Remove,
  StatusIcon,
  StatusIconContainer,
  PositionLoader,
  PaymentIconContainer,
  IconCell,
  NoteWrapper,
  NoteSpinnerWrapper,
  ConflictIconContainer
} from '../../../styled-components';
import { COLORS, ICONS } from '../../../../../../../utils/styles';
import {
  EXPECTED_PAYMENT_PROCESSING_DAYS,
  PAYMENT_FLOW_STATUSES
} from '../../../../../../../constants';
import { formatDate } from '../../../../../../../utils/parsers';
import { ASSIGNMENT_STATUS } from '../../../../../../../core/Games/constants';
import { arePropsChanged } from '../../../../config';

const useStyles = makeStyles({
  cursorTooltip: {
    width: '200px'
  }
});

const getStatusLabelProps = ({ assignment, assignmentNote, noteProps }) => {
  const props = {};
  if (
    assignmentNote.permissions &&
    assignmentNote.permissions.view &&
    assignment &&
    assignment.status === 'declined'
  ) {
    props.onClick = noteProps.handleClick;
  }
  return props;
};

const PositionCell = ({
  assignment,
  min_ranks,
  label,
  selectedUser,
  onClick,
  onRemoveAssignment,
  canEdit,
  onDisabledClick,
  onAction,
  selected: isSelected,
  disabled,
  errors,
  warnings,
  cellLoading,
  allowSelfAssignment,
  assignmentNote
}) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPopover, setShowPopover] = React.useState(false);

  const noteProps = {
    handleClick: e => {
      if (!showPopover) {
        setAnchorEl(e.currentTarget);
        setShowPopover(true);
        assignmentNote.fetch({ id: assignment.id });
      }
    }
  };
  const classes = useStyles();
  let paymentStatus = null;
  let isPaid = false;
  if (assignment && assignment.payment) {
    const { amount, date: createdAt, status } = assignment.payment;
    const createdAtDate = new Date(createdAt);
    if (status === PAYMENT_FLOW_STATUSES.PAID) {
      paymentStatus = `$${amount.toFixed(2)} paid on ${formatDate(createdAtDate)}`;
      isPaid = true;
    } else if (status === PAYMENT_FLOW_STATUSES.PROCESSING) {
      paymentStatus = `Pending - $${amount.toFixed(2)} expected payment on ${formatDate(
        createdAtDate.setDate(createdAtDate.getDate() + EXPECTED_PAYMENT_PROCESSING_DAYS)
      )}`;
    }
  }
  const selected = isSelected || (assignment && assignment.isConflict);
  const statusLabelProps = getStatusLabelProps({ assignment, assignmentNote, noteProps });
  return assignment && (canEdit || allowSelfAssignment || assignment.status !== 'unpublished') ? (
    <LoaderWrapper isFetching={cellLoading} theme={PositionLoader}>
      <Wrapper>
        <CellWrapper selected={isSelected} isConflict={assignment.isConflict}>
          <IconCell>
            {assignment.isConflict && (
              <Tooltip
                placement="top"
                PopperProps={{
                  disablePortal: true
                }}
                open={openTooltip}
                title={assignment.conflictTitle}
                classes={{ popper: classes.cursorTooltip }}
              >
                <ConflictIconContainer
                  onMouseEnter={() => setOpenTooltip(true)}
                  onMouseLeave={() => setOpenTooltip(false)}
                >
                  <AssignmentConflict
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      cursor: 'pointer',
                      color: COLORS.white,
                      backgroundColor: COLORS.red,
                      viewBox: '0 0 520 500'
                    }}
                  />
                </ConflictIconContainer>
              </Tooltip>
            )}

            {!paymentStatus && assignment.status === ASSIGNMENT_STATUS.pending && (
              <StatusIconContainer>
                <StatusIcon width={18} height={18} color={COLORS.yellow} icon={ICONS.SYNC} />
              </StatusIconContainer>
            )}
            {paymentStatus && (
              <Tooltip
                placement="top"
                PopperProps={{
                  disablePortal: true
                }}
                open={openTooltip}
                title={paymentStatus}
                classes={{ popper: classes.cursorTooltip }}
              >
                <PaymentIconContainer
                  onMouseEnter={() => setOpenTooltip(true)}
                  onMouseLeave={() => setOpenTooltip(false)}
                >
                  <StatusIcon
                    width={18}
                    height={18}
                    color={isPaid ? COLORS.forestGreen : COLORS.yellow}
                    icon={ICONS.DOLLAR_SIGN}
                    {...statusLabelProps}
                  />
                </PaymentIconContainer>
              </Tooltip>
            )}
            {!paymentStatus && assignment.status === ASSIGNMENT_STATUS.accepted && (
              <StatusIconContainer {...statusLabelProps}>
                <StatusIcon
                  width={18}
                  height={18}
                  color={COLORS.forestGreen}
                  icon={ICONS.CHECK_CIRCLE_HOLLOW}
                />
              </StatusIconContainer>
            )}
            {!paymentStatus && assignment.status === ASSIGNMENT_STATUS.declined && (
              <StatusIconContainer {...statusLabelProps}>
                <StatusIcon
                  width={18}
                  height={18}
                  color={COLORS.venetianRed}
                  icon={ICONS.X_CIRCLE_HOLLOW}
                />
              </StatusIconContainer>
            )}
            {!paymentStatus && assignment.status === ASSIGNMENT_STATUS.unpublished && (
              <EmptyCircle selected={selected} />
            )}
            {canEdit && (
              // || (allowSelfAssignment &&
              //  (assignment.event_role.user_id === Number(currentUserId) &&
              // assignment.status !== ASSIGNMENT_STATUS.accepted
              <Remove
                hidden={assignment.batched || assignment.staged}
                width={12}
                height={12}
                color={selected ? COLORS.white : `${COLORS.nightRider}`}
                icon={ICONS.CLOSE}
                onClick={() => {
                  onRemoveAssignment(
                    assignment.id,
                    assignment.event_role.user_id,
                    assignment.event_role.id,
                    canEdit
                  );
                  onAction();
                }}
              />
            )}
          </IconCell>
          <CellRow>
            <Name selected={selected}>{assignment.event_role.user.first_name}</Name>
          </CellRow>
          <CellRow>
            <Name selected={selected}>{assignment.event_role.user.last_name}</Name>
          </CellRow>
          <CellRow className="mobileView">
            <Placeholder style={{ color: '#ccc' }}>{label}</Placeholder>
          </CellRow>
          {min_ranks && (
            <CellRow>
              <Name selected={selected} minRank>
                {`(${Number(min_ranks)})`}
              </Name>
            </CellRow>
          )}
        </CellWrapper>
        {assignmentNote.permissions && assignmentNote.permissions.view ? (
          <Popover
            id="note"
            open={showPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            onClose={() => {
              setShowPopover(false);
              setAnchorEl(null);
            }}
          >
            {assignmentNote.data.fetching ? (
              <NoteSpinnerWrapper>
                <Circle height={20} width={20} cx={10} cy={10} r={8} strokeWidth={2} />
              </NoteSpinnerWrapper>
            ) : (
              <NoteWrapper>Reason for decline: {assignmentNote.data.data}</NoteWrapper>
            )}
          </Popover>
        ) : null}
      </Wrapper>
    </LoaderWrapper>
  ) : (
    <LoaderWrapper isFetching={cellLoading} theme={PositionLoader}>
      <Wrapper
        disabled={disabled}
        onClick={() => {
          if (selectedUser && !disabled) {
            onClick(warnings);
            onAction();
          } else {
            onDisabledClick(errors);
          }
        }}
      >
        {' '}
        <CellWrapper>
          <CellRow unAssigned>
            <Placeholder disabled={disabled}>{label}</Placeholder>
          </CellRow>
          {min_ranks && (
            <CellRow>
              <Name disabled={disabled} selected={selected} minRank>
                {`(${Number(min_ranks)})`}
              </Name>
            </CellRow>
          )}
        </CellWrapper>
      </Wrapper>
    </LoaderWrapper>
  );
};

PositionCell.propTypes = {
  assignment: PropTypes.shape({
    event_role: PropTypes.shape({
      user: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string
      })
    })
  }),
  min_ranks: PropTypes.string,
  label: PropTypes.string.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    eventRoleId: PropTypes.string
  }),
  onClick: PropTypes.func,
  onRemoveAssignment: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  onDisabledClick: PropTypes.func,
  onAction: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(Object),
  cellLoading: PropTypes.bool.isRequired
};

PositionCell.defaultProps = {
  assignment: null,
  min_ranks: '',
  selectedUser: null,
  canEdit: false,
  selected: false,
  disabled: false,
  errors: [],
  onClick: () => null,
  onDisabledClick: () => null
};

export default React.memo(PositionCell, arePropsChanged);
