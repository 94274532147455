export const GAMES_STATUSES = [
  {
    value: 'none',
    name: 'None'
  },
  {
    value: 'new',
    name: 'New'
  },
  {
    value: 'updated',
    name: 'Updated'
  },
  {
    value: 'cancelled',
    name: 'Cancelled'
  },
  {
    value: 'postponed',
    name: 'Postponed'
  },
  {
    value: 'paid',
    name: 'Paid'
  }
];

export default GAMES_STATUSES;
