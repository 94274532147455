import styled from 'styled-components';
import { pxToRem } from '../../../utils/helpers';
import { RawButton } from '../Button/styled-components';

export const UserList = styled.div`
  padding-right: ${'10px'};
  text-align: left;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  & ${RawButton}:not(:last-child) {
    margin-right: ${pxToRem('16px')};
  }
`;

export const StyledDiv = styled.div`
  text-align: left;
  border-bottom: 1px solid black;
  margin-top: '10px';
`;
