import { Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { pxToRem } from '../../../utils/helpers';
import COLORS from '../../../utils/styles';

export const Container = styled.div`
  width: 100%;
  height: auto;
  background: #e5ebf2 0% 0% no-repeat padding-box;
  font-family: Barlow;
`;
export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;
export const RowWrapper = styled(Row)`
  width: 100%;
  padding: 3rem 0rem;
`;

export const UpcomingData = styled.div`
  width: 95%;
  border-radius: 6px;
  margin: 0 auto;
  height: 500px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f2f5f7 100%) 0% 0% no-repeat
    padding-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 40px;
  background: #132367;
  color: #fff;
  font-family: Barlow;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 15px;
  box-sizing: border-box;
`;

export const H3 = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-family: 'Barlow Semi Condensed';
`;

export const H1 = styled.div`
  font-size: 6px;
  font-weight: bold;
  font-family: 'Barlow Semi Condensed';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  & > span {
    font-size: ${({ fontSize }) => fontSize || '40px'};
    padding-bottom: 10px;
  }
`;
export const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 0px 12px;
  font-family: 'Barlow Semi Condensed';
  color: #48749b;
`;

export const TabWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 3px 3px #00000029;
  border-radius: 6px;
  width: 92px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #132367;
  padding: 0px 5px;
  box-sizing: border-box;
  text-align: center;
  margin: ${({ margin }) => margin || '20px auto'};
  cursor: pointer;
  border: 1px solid #c3ccda;
  &.selected {
    background: transparent linear-gradient(180deg, #167cd3 0%, #04589d 100%) 0% 0% no-repeat;
    color: #fff !important;
    border: 1px solid #167cd3;
    & > div {
      color: #fff !important;
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  padding: 0px 15px;
  flex-wrap: wrap;
`;

export const TabRows = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 180px);
  width: 100%;
  &::-webkit-scrollbar {
    border-left: ${pxToRem('1px')} solid ${COLORS.headerBlue};
    background: ${COLORS.white};
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border: ${pxToRem('2px')} solid transparent;
    border-radius: ${pxToRem('9px')};
    background-clip: content-box;
  }
`;

export const EventRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f2f5f7 100%) 0% 0% no-repeat
    padding-box;
  text-transform: capitalize;
  border-top: 1px solid #e5ebf2;
  border-bottom: 1px solid #e5ebf2;
  flex-wrap: wrap;
`;

export const EventInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  margin-right: 8px;
`;

export const EventName = styled.div`
  color: #0565d9;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  text-align: left;
`;

export const Key = styled.div`
  color: ${COLORS.newDarkBlue};
  font-size: 14px;
  font-weight: 500;
`;

export const ClickableKey = styled(Key)`
  cursor: pointer;
  text-decoration: underline;
  color: ${COLORS.lightBlue};
`;

export const Nodata = styled.span`
  color: ${COLORS.newDarkBlue};
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
`;

export const Value = styled.div`
  color: ${COLORS.newDenimBlue};
  font-size: 14px;
  font-weight: 600;
  padding: 0px 5px;
`;

export const ColWrapper = styled(Col)`
  width: 550px;
`;

export const Filters = styled.div`
  color: #fff;
  font-family: Roboto;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
`;

export const GameStatus = styled.span`
  color: #132367;
  font-size: 14px;
  font-weight: 900;
  padding-right: 8px;
  line-height: 26px;
`;

export const MenuDropdown = styled.div`
  display: block;
  position: absolute;
  z-index: 99999;
  width: 180px;
  color: #000;
  background: #f2f5f7;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  right: 0px;
`;

export const MenuTitle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  cursor: pointer;
  padding: 0px 5px;
`;

export const MenuItem = styled.div`
  background: #f2f5f7;
  height: 40px;
  text-align: left;
  padding: 5px;
  color: #132367;
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  box-sizing: border-box;
`;

export const FieldWrapper = styled.div`
  background: #f2f5f7;
  height: 53px;
  text-align: left;
  padding: 5px;
  color: #132367;
  display: flex;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  position: relative;
`;

export const EventRowWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ViewGamesButton = styled.button`
  height: 22px;
  width: 100px;
  border-radius: 5px;
  font-size: 11px;
  padding: 0px;
  margin: 0px;
  background: #2978cf;
  border: 1px solid #fff;
  outline: none;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: #fff;
    border: 1px solid #3d88c5;
    color: #3d88c5;
  }
`;
