import React from 'react';
import PropTypes from 'prop-types';

import Cover from './styled-components';

const FullBackgroundImage = ({ children }) => <Cover>{children}</Cover>;

FullBackgroundImage.propTypes = {
  children: PropTypes.element.isRequired
};

export default FullBackgroundImage;
