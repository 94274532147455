import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';

export const H1 = styled.h1`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 3.5rem;
  font-family: 'Roboto';
  font-weight: 400;
  margin: ${({ margin }) => margin || '0 0 20px 0'};
`;

export const H2 = styled.h2`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 2.5rem;
  font-family: 'Roboto';
  font-weight: 400;
  margin: ${({ margin }) => margin || '0 0 20px 0'};
`;

export const H3 = styled.h3`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 2rem;
  font-family: 'Roboto';
  font-weight: 400;
  margin: ${({ margin }) => margin || '0 0 20px 0'};
`;

export const H4 = styled.h4`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 1.5rem;
  font-family: 'Roboto';
  font-weight: 400;
  margin: ${({ margin }) => margin || '0 0 10px 0'};
`;

export const H5 = styled.h5`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 1.25rem;
  font-family: 'Roboto';
  font-weight: 400;
  margin: ${({ margin }) => margin || '0 0 10px 0'};
`;

export const H6 = styled.h6`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 400;
  margin: ${({ margin }) => margin || '0 0 10px 0'};
`;

export const Headline = styled.p`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 600;
  margin: ${({ margin }) => margin || '0 0 10px 0'};
`;

export const BodyText = styled.div`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 400;
  width: 100%;
  margin: ${({ margin }) => margin || 0};
`;

export const Subheading = styled.p`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 600;
  margin: ${({ margin }) => margin || 0};
`;

export const Caption1 = styled.p`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 0.6875rem;
  font-family: 'Roboto';
  font-weight: 400;
  margin: ${({ margin }) => margin || 0};
`;

export const Caption2 = styled.p`
  color: ${({ color }) => color || `${COLORS.textColor}`};
  font-size: 0.5625rem;
  font-family: 'Roboto';
  font-weight: 400;
  margin: ${({ margin }) => margin || 0};
`;

export const Inline = styled.div`
  width: 2rem;
  overflow: hidden;
  padding-right: 0.5rem;
  display: inline-block;
  margin: 0 0.5rem;
`;

export const Spaced = styled.div`
  margin: 0 4rem 0 4rem;
  display: inherit;
`;

export const SpacedLeft = styled.div`
  margin: 0 4rem 0 0rem;
  display: inherit;
`;
