import { connect } from 'react-redux';

import { PRODUCTS } from '../../../constants';
import {
  getDueDateEventBillingStatements,
  getPastEventBillingStatements,
  getRecentEventBillingStatements
} from '../../../core/Auth/selectors';
import { getPlatformSubscriptions, getProductInvoices } from '../../../core/Platform/selectors';

const mapStateToProps = state => ({
  pastEventBillingStatements: getPastEventBillingStatements(state),
  dueDateEventBillingStatements: getDueDateEventBillingStatements(state),
  recentEventBillingStatements: getRecentEventBillingStatements(state),
  platformSubscriptionStatements: getProductInvoices(PRODUCTS.PLATFORM_FEE)(state),
  platformSubscriptions: getPlatformSubscriptions(state),
  isFetchingPastStatement: state.auth.isFetchingPastStatement,
  isFetchingDueDateStatement: state.auth.isFetchingDueDateStatement,
  isFetchingRecentStatement: state.auth.isFetchingRecentStatement
});

export default connect(mapStateToProps, null);
