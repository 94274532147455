import React from 'react';
import PropTypes from 'prop-types';

import CrewVerification from './CrewVerification';
import Modal from '../../../Modal';
import Enhancer from '../../../../containers/CrewVerificationReportsModal';

import { FROM_GAMES } from '../../../../../core/Modals/constants';

const CrewVerificationReportModal = ({
  onClose,
  onSubmit,
  reportSettings,
  crewLabels,
  onEdit,
  onApprove,
  game,
  report,
  isFetching,
  onBack,
  isGamesEventAdminOrAssignor,
  isGamesEventGroupAdmin,
  isReportsEventAssignor,
  isReportsEventAdmin,
  currentUserId,
  from,
  isGamesEventOfficialOrAssessor,
  notes
}) => {
  return (
    <Modal
      title="Crew Check-In"
      onClose={() => {
        onClose(from);
      }}
      size={{
        xs: 8,
        sm: 8,
        md: 8,
        lg: 6
      }}
      overflow
    >
      <CrewVerification
        onSubmit={onSubmit}
        onEdit={onEdit}
        game={game}
        report={report}
        onBack={onBack}
        onClose={onClose}
        onApprove={onApprove}
        isFetching={isFetching}
        isGamesEventAdminOrAssignor={isGamesEventAdminOrAssignor}
        isReportsEventAdmin={isReportsEventAdmin}
        isReportsEventAssignor={isReportsEventAssignor}
        isGamesEventGroupAdmin={isGamesEventGroupAdmin}
        from={from}
        crewLabels={crewLabels}
        currentUserId={currentUserId}
        reportSettings={reportSettings}
        isGamesEventOfficialOrAssessor={isGamesEventOfficialOrAssessor}
        notes={notes}
      />
    </Modal>
  );
};

CrewVerificationReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  game: PropTypes.shape({
    id: PropTypes.string
  }),

  from: PropTypes.string,
  isFetching: PropTypes.bool,
  isGamesEventAdminOrAssignor: PropTypes.bool,
  isReportsEventAssignor: PropTypes.bool,
  isReportsEventAdmin: PropTypes.bool
};

CrewVerificationReportModal.defaultProps = {
  game: { id: null },

  isFetching: false,
  isGamesEventAdminOrAssignor: false,
  isReportsEventAdmin: false,
  isReportsEventAssignor: false,
  from: FROM_GAMES
};

export default Enhancer(CrewVerificationReportModal);
