import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDate } from '../../../../../../utils/parsers';
import Checkbox from '../../../../Checkbox';

import {
  GameDate,
  Wrapper,
  CheckboxWrapper,
  Location,
  TeamCol,
  NameDetail
} from './styled-components';
import {
  GameData,
  GameId,
  Detail,
  Status,
  Header,
  RightContent,
  CancelledOrPostponedStatus
} from '../../../../StyledGames/GameRow/styled-components';
import {
  getGameStatus,
  convertToTimezone,
  getTimezoneAbbr,
  updateDisplayIdWithCount
} from '../../../../../../utils/helpers';
import { GAMES_STATUSES } from '../../../../../../constants';

const GameBasicInfo = ({
  display_id,
  id,
  game_level,
  count,
  start_time,
  start_date,
  checked,
  handleCheckboxClick,
  status,
  indeterminate,
  reviewMode,
  assignor,
  timezone,
  field,
  field_name,
  location,
  team_home,
  team_visitor
}) => {
  const gameStatus = getGameStatus(status);
  const cancelledOrPostponedGame =
    gameStatus === GAMES_STATUSES.CANCELLED || gameStatus === GAMES_STATUSES.POSTPONED;

  const localStartTime = convertToTimezone(`${start_date} ${start_time}`, timezone, 'h:mm A');
  const localStartDate = convertToTimezone(`${start_date} ${start_time}`, timezone, 'MM/DD/YYYY');
  const timezoneAbbr = getTimezoneAbbr(localStartDate, timezone, 'MM/DD/YYYY');
  const updatedId = updateDisplayIdWithCount(display_id, count);
  return (
    <Wrapper>
      <CheckboxWrapper>
        {!reviewMode && (
          <Checkbox
            onChange={() =>
              handleCheckboxClick({
                id,
                in_batch: !checked,
                is_assignor_batched: assignor && assignor.batched
              })
            }
            value={checked}
            indeterminate={indeterminate}
          />
        )}
      </CheckboxWrapper>
      <Row>
        <Col xs={12}>
          <Header>
            <GameData header>
              <GameDate>
                {formatDate(localStartDate, 'ddd')} - {localStartDate} @ {localStartTime}
              </GameDate>
              <GameId>({timezoneAbbr})</GameId>
              <Tooltip title={updatedId || id} arrow>
                <GameId>{updatedId || id}</GameId>
              </Tooltip>
            </GameData>
            <RightContent>
              {(cancelledOrPostponedGame && (
                <CancelledOrPostponedStatus>{gameStatus}</CancelledOrPostponedStatus>
              )) || <Status>{gameStatus}</Status>}
            </RightContent>
          </Header>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <GameData>
            {field ? (
              <Location>
                {field_name && `${field_name} - `}
                {field.location && field.location.name}
                {field.location && field.location.city && ` - ${field.location.city}`}
                {field.location && field.location.state && `, ${field.location.state}`}
              </Location>
            ) : (
              <Location>
                {field_name && `${field_name} - `}
                {location.name}
                {location.city && ` - ${location.city}`}
                {location.state && `, ${location.state}`}
              </Location>
            )}
          </GameData>
        </Col>
      </Row>
      <Row>
        <TeamCol>
          <GameData>
            <NameDetail> {team_home && team_home.name} </NameDetail>
            <span className="teamVs">VS</span>
            <NameDetail>{team_visitor && team_visitor.name}</NameDetail>
          </GameData>
        </TeamCol>
      </Row>
      <Row>
        <Col xs={12}>
          <Header>
            <GameData lastDataRow>
              <Detail>Type: {game_level && game_level.game_type}</Detail>
              <Detail>Level: {game_level && game_level.game_level}</Detail>
            </GameData>
          </Header>
        </Col>
      </Row>
    </Wrapper>
  );
};

GameBasicInfo.propTypes = {
  display_id: PropTypes.string.isRequired,

  id: PropTypes.string.isRequired,
  game_level: PropTypes.objectOf(Object).isRequired,
  start_time: PropTypes.string.isRequired,
  start_date: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  reviewMode: PropTypes.bool.isRequired,
  assignor: PropTypes.objectOf(Object)
};

GameBasicInfo.defaultProps = {
  assignor: null
};

export default GameBasicInfo;
