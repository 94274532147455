import React from 'react';
import PropTypes from 'prop-types';

import CheckRadioButton from '../CheckRadioButton';
import { Label, Wrapper, Message } from './styled-components';

/**
 * Built to integrate with React-Final-Form, a single toggle-able field with text
 * @param {Object} input contains a value and onChange function to update state
 * @param {String} placeholder label for field
 * @param {Object} message *optional* message to display beside field
 * @param {Object} disabled *optional* makes field read only
 * @param {String} padding *optional* specify padding with a css string
 */
const CheckField = ({
  input: { onChange, value },
  placeholder: label,
  message,
  disabled,
  padding,
  onClick,
  isLabelBlue
}) => (
  <Wrapper
    onClick={() => {
      if (onClick && !disabled) {
        onClick(value);
      }
      if (!disabled) onChange(!value);
    }}
    padding={padding}
  >
    <CheckRadioButton checked={value} />
    <Label checked={value} isLabelBlue={isLabelBlue}>
      {label}
    </Label>
    {message && <Message>{message}</Message>}
  </Wrapper>
);

CheckField.propTypes = {
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  padding: PropTypes.string
};

CheckField.defaultProps = {
  placeholder: '',
  message: null,
  disabled: false,
  padding: null
};

export default CheckField;
