import { withStateHandlers } from 'recompose';

export default withStateHandlers(
  ({ isVisible = false }) => ({
    isVisible
  }),
  {
    setVisibility: () => isVisible => ({
      isVisible
    }),
    toggleVisibility: ({ isVisible }) => () => ({
      isVisible: !isVisible
    })
  }
);
