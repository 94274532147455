/* eslint-disable */
import { isDevelopment } from './helpers';
import api from './api-wrapper';

// TODO: This is an MVP of a logger
class Logger {
  static log(...args) {
    // if (isDevelopment()) {
    console.log.apply(null, args);
    // }
  }

  static error(...args) {
    // if (isDevelopment()) {
    console.error.apply(null, args);
    // }
  }

  static warn(...args) {
    if (isDevelopment()) {
      console.warn.apply(null, args);
    }
  }

  static dbLog(error, payload = {}, level = 'error') {
    const log = {
      method: 'client',
      level,
      error: error,
      body: payload,
      url: location.pathname,
      module: 'web_app'
    };
    api.paymentLog(log);
  }

  static appLog(error, payload = {}, level = 'info') {
    const log = {
      method: 'client',
      level,
      error: error,
      body: payload,
      url: location.pathname,
      module: 'web_app'
    };
    try {
      api.applicationLog(log);
      //console.error.apply(null, args);
    } catch (e) {
      console.error.apply(null, args);
    }
  }
}

export default Logger;
