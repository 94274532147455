import styled from 'styled-components';

export const StylesContainer = styled.div`
  background: #fff;
  height: calc(100vh - 84px);
  min-width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
`;

export const SectionContainer = styled.div`
  margin: 20px 20px 40px;
`;

export const ButtonContainer = styled.div`
  box-sizing: border-box;
  margin: 20px auto 0 auto;
`;
