import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import moment from 'moment';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count,
  DeclineNote,
  NoteValidationError
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';

const DeclineAssignmentsModal = ({
  onClose,
  selected,
  onSubmit,
  userId,
  canEdit,
  eventDisableGameDeclineSettings
}) => {
  const [note, setNote] = React.useState('');
  const publishedAssignments = selected.reduce(
    (assignments, game) => [
      ...assignments,
      ...game.assignments_game
        .filter(
          assignment =>
            Number(userId) === assignment.event_role.user_id &&
            (assignment.status === 'pending' || assignment.status === 'accepted')
        )
        .map(assignment => ({
          ...assignment,
          game_display_id: game.display_id,
          game_start_date: game.start_date
        }))
    ],
    []
  );

  const lockedAssignments = [];
  const declineableAssignments = [];
  const pastDateAssignments = [];
  publishedAssignments.forEach(assignment =>
    assignment.status === 'accepted' &&
    moment().isAfter(assignment.game_start_date) &&
    moment.utc().format('MM/DD/YYYY') !==
      moment.utc(assignment.game_start_date).format('MM/DD/YYYY')
      ? pastDateAssignments.push(assignment)
      : eventDisableGameDeclineSettings.checked &&
        moment.utc().toDate() >=
          moment
            .utc(assignment.game_start_date)
            .subtract(eventDisableGameDeclineSettings.days_before_game, 'days')
            .toDate() &&
        assignment.status === 'accepted'
      ? lockedAssignments.push(assignment)
      : declineableAssignments.push(assignment)
  );

  return (
    <Modal title="Decline Game Assignments" onClose={onClose} leftPanel={canEdit}>
      <>
        <Row>
          <HighlightedBlock xs={12}>
            <Content>
              <Summary>
                <Count color={COLORS.denimBlue}>{selected.length}</Count>
                Game{selected.length !== 1 ? 's' : ''} Selected
              </Summary>
              <Summary>
                <Count color={COLORS.venetianRed}>{declineableAssignments.length}</Count>
                Assignment{declineableAssignments.length !== 1 ? 's' : ''} Declined
              </Summary>
            </Content>
          </HighlightedBlock>
        </Row>
        <Row>
          <Block xs={10} xsOffset={1}>
            {!!pastDateAssignments.length && (
              <Content first light>
                Game{pastDateAssignments.length > 1 && 's'} with ID
                {pastDateAssignments.length > 1 ? 's ' : ' '}
                {pastDateAssignments.map(assignment => assignment.game_display_id).join(', ')}{' '}
                cannot be declined after the game has been played.
              </Content>
            )}
            {!!lockedAssignments.length && (
              <Content first light>
                Game{lockedAssignments.length > 1 && 's'} with ID
                {lockedAssignments.length > 1 ? 's ' : ' '}
                {lockedAssignments.map(assignment => assignment.game_display_id).join(', ')} cannot
                be declined within {eventDisableGameDeclineSettings.days_before_game} days of the
                game start date
              </Content>
            )}
            {declineableAssignments.length && (
              <Content>
                <DeclineNote
                  value={note}
                  onChange={e => setNote(e.target.value)}
                  placeholder="Please explain why you are declining"
                />
                {!(note && note.length > 3) ? (
                  <NoteValidationError>Enter at least 4 characters</NoteValidationError>
                ) : null}
              </Content>
            )}
          </Block>
        </Row>
        <Row>
          <Block xs={12} className="last">
            <ButtonsContainer>
              <ButtonContainer>
                <Button
                  label="Decline"
                  onClick={() => {
                    onClose();
                    onSubmit({ assignments: declineableAssignments, note });
                  }}
                  disabled={!declineableAssignments.length || !note || note.length < 4}
                  width="72px"
                  height="32px"
                  primary
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
              </ButtonContainer>
            </ButtonsContainer>
          </Block>
        </Row>
      </>
    </Modal>
  );
};

DeclineAssignmentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool
};

DeclineAssignmentsModal.defaultProps = {
  canEdit: false
};

export default DeclineAssignmentsModal;
