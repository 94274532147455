import { assocPath, compose, mergeDeepRight, update, prop, sortBy } from 'ramda';
import moment from 'moment';
// Actions
export const FETCH_EVENTS = 'EVENTS/FETCH_EVENTS';
export const SET_EVENTS = 'EVENTS/SET_EVENTS';
export const FETCH_FILTERED_EVENTS = 'EVENTS/FETCH_FILTERED_EVENTS';
export const SET_FILTERED_EVENTS = 'EVENTS/SET_FILTERED_EVENTS';

export const FETCH_EVENT = 'EVENTS/FETCH_EVENT';
export const CREATE_EVENT = 'EVENTS/CREATE_EVENT';
export const CREATE_EVENT_AND_REDIRECT = 'EVENTS/CREATE_EVENT_AND_REDIRECT';
export const FETCH_GOVERNING_BODIES = 'EVENTS/FETCH_GOVERNING_BODIES';
export const SET_GOVERNING_BODIES = 'EVENTS/SET_GOVERNING_BODIES';
export const SET_EVENT_INFO = 'EVENTS/SET_EVENT_INFO';
export const SET_EVENT_BILLING = 'EVENTS/SET_EVENT_BILLING';
export const SPREAD_EVENT = 'EVENTS/SPREAD_EVENT';
export const FETCH_EVENT_INFO_CATEGORIES = 'FETCH_EVENT_INFO_CATEGORIES';
export const FETCH_EVENT_INFO_CREW_LABELS = 'FETCH_EVENT_INFO_CREW_LABELS';
export const FETCH_EVENT_INFO_GAME_LEVELS = 'FETCH_EVENT_INFO_GAME_LEVELS';
export const FETCH_EVENT_INFO_REPORT_SETTINGS = 'FETCH_EVENT_INFO_REPORT_SETTINGS';
export const FETCH_EVENT_INFO_ASSESSMENT_SETTINGS = 'FETCH_EVENT_INFO_ASSESSMENT_SETTINGS';
export const FETCH_EVENT_INFO_CREW_PAY = 'FETCH_EVENT_INFO_CREW_PAY';

export const SET_EVENT_TAX_INFO = 'EVENTS/SET_EVENT_TAX_INFO';
export const FETCH_EVENT_CATEGORIES = 'EVENTS/FETCH_EVENT_CATEGORIES';
export const SET_EVENT_CATEGORIES = 'EVENTS/SET_EVENT_CATEGORIES';
export const FETCH_EVENT_GAME_LEVELS = 'EVENTS/FETCH_EVENT_GAME_LEVELS';
export const FETCH_GAME_LEVEL = 'EVENTS/FETCH_GAME_LEVEL';
export const COPY_FEES_FROM_GAME_LEVEL = 'EVENTS/COPY_FEES_FROM_GAME_LEVEL';
export const SET_GAME_LEVELS = 'EVENTS/SET_GAME_LEVELS';
export const SET_GAME_LEVEL = 'EVENTS/SET_GAME_LEVEL';
export const SET_REPORT_SETTINGS = 'EVENTS/SET_REPORT_SETTINGS';
export const SET_ASSESSMENT_SETTINGS = 'EVENTS/SET_ASSESSMENT_SETTINGS';
export const SET_EVENT_CREW_PAY = 'EVENTS/SET_CREW_PAY';
export const UPDATE_REPORT_SETTINGS = 'EVENTS/UPDATE_REPORT_SETTINGS';
export const UPDATE_ASSESSMENT_SETTINGS = 'EVENTS/UPDATE_ASSESSMENT_SETTINGS';
export const UPDATE_EVENT_CATEGORIES = 'EVENTS/UPDATE_EVENT_CATEGORIES';
export const DELETE_EVENT_AND_REDIRECT = 'EVENTS/DELETE_EVENT';
export const DELETE_EVENT_CATEGORY = 'EVENTS/DELETE_EVENT_CATEGORY';
export const UPDATE_EVENT = 'EVENTS/UPDATE_EVENT';
export const SWITCH_EVENT_STATUS = 'EVENTS/SWITCH_EVENT_STATUS';
export const UPDATE_EVENT_STATUS = 'EVENTS/UPDATE_EVENT_STATUS';
export const UPDATE_GAME_LEVELS = 'EVENTS/UPDATE_GAME_LEVELS';
export const UPDATE_EVENT_CREW_PAY_TABLE = 'EVENTS/UPDATE_CREW_PAY_TABLES';

export const FETCH_ALL_USER_EVENTS = 'EVENTS/FETCH_ALL_USER_EVENTS';
export const SET_ALL_USER_EVENTS = 'EVENTS/SET_ALL_USER_EVENTS';
export const LINK_GAMES_TO_EVENT = 'EVENTS/LINK_GAMES_TO_EVENTS';

export const UPDATE_CREW_LABELS = 'EVENTS/UPDATE_CREW_LABELS';

export const SET_EVENT_CREW_LABELS = 'EVENTS/SET_EVENT_CREW_LABELS';

export const ADD_EVENT_USER_GAME_ASSIGNMENT = 'EVENTS/ADD_EVENT_USER_GAME_ASSIGNMENT';
export const REMOVE_EVENT_USER_GAME_ASSIGNMENT = 'EVENTS/REMOVE_EVENT_USER_GAME_ASSIGNMENT';

export const FETCH_EVENT_USERS = 'EVENTS/FETCH_EVENT_USERS';
export const FETCH_EVENT_USERS_BY_USER_ID = 'EVENTS/FETCH_EVENT_USERS_BY_USER_ID';
export const CLEAR_EVENT_USERS_DATA = 'EVENTS/CLEAR_EVENT_USERS_DATA';
export const SET_EVENT_USERS = 'EVENTS/SET_EVENT_USERS';
export const APPEND_EVENT_USERS = 'EVENTS/APPEND_EVENT_USERS';
export const SET_EVENT_USERS_AVAILABILITY = 'EVENTS/SET_EVENT_USERS_AVAILABILITY';
export const SET_IS_FETCHING = 'EVENTS/SET_IS_FETCHING';
export const SET_IS_FETCHING_USERS = 'EVENTS/SET_IS_FETCHING_USERS';
export const SET_EVENT_PAGE_FETCHING = 'EVENTS/SET_EVENT_PAGE_FETCHING';
export const FINISHED_FETCH_EVENT = 'EVENTS/FINISHED_FETCH_EVENT';
export const SET_CREW_WARNING_MODAL = 'EVENTS/SET_CREW_WARNING_MODAL';
export const CHECK_BATCH_PAY_FOR_GAME_ASSIGNMENT = 'EVENTS/CHECK_BATCH_PAY_FOR_GAME_ASSIGNMENT';

export const FETCH_EVENT_GAME_LOCATION_COUNTS = 'EVENT/FETCH_EVENT_GAME_LOCATION_COUNTS';
export const SET_EVENT_GAME_LOCATION_COUNTS = 'EVENT/SET_EVENT_GAME_LOCATION_COUNTS';

export const SET_COPY_FEES_FROM_GAME_LEVEL = 'EVENT/SET_COPY_FEES_FROM_GAME_LEVEL';
export const SET_EVENT_INFO_IS_FETCHING = 'EVENT/SET_EVENT_INFO_IS_FETCHING';

// Reducer
const initialState = {
  eventList: {
    meta: {
      limit: 20
    },
    data: []
  },
  filteredEventList: {
    meta: {
      limit: 20
    },
    data: []
  },
  filters: {},
  sortingValue: '',
  crewWarningModal: false,
  current: {
    id: null,
    info: {},
    billing: {},
    tax_info: {},
    game_levels: [],
    crew_labels: {},
    pay_tables: [],
    categories: [],
    users: [],
    crew_pay: []
  },
  allUserEvents: [],
  isFetching: false,
  isFetchingEventUsers: false,
  eventPageFetching: false,
  reportsEventId: null,
  copyFeesGameLevelFeching: false,
  eventInfoIsFetching: false,
  governingBodies: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_GOVERNING_BODIES:
      return { ...state, governingBodies: initialState.governingBodies };
    case SET_GOVERNING_BODIES:
      return {
        ...state,
        governingBodies: action.payload
      };
    case FETCH_EVENTS:
      return !action.payload.toCSV
        ? {
            ...state,
            eventList: initialState.eventList,
            filters: action.payload.filters || state.filters,
            sortingValue: action.payload.sortingValue || state.sortingValue
          }
        : state;
    case SET_EVENTS:
      return {
        ...state,
        eventList: mergeDeepRight(state.eventList, action.payload)
      };
    case FETCH_FILTERED_EVENTS:
      return {
        ...state,
        filteredEventList: initialState.filteredEventList,
        filters: action.payload.filters || state.filters,
        sortingValue: action.payload.sortingValue || state.sortingValue
      };
    case SET_FILTERED_EVENTS:
      return {
        ...state,
        filteredEventList: mergeDeepRight(state.filteredEventList, action.payload)
      };
    case SET_EVENT_INFO:
      return compose(
        assocPath(['current', 'info'], action.payload),
        assocPath(['current', 'id'], action.payload.id)
      )(state);
    case SET_EVENT_BILLING: {
      return assocPath(['current', 'billing'], action.payload.data)(state);
    }
    case SET_EVENT_TAX_INFO:
      return assocPath(['current', 'tax_info'], action.payload.data)(state);
    case SET_EVENT_CATEGORIES: {
      const { data: stateData } = state.eventList;
      const eventIdx =
        !!action.payload.length &&
        stateData.findIndex(event => Number(event.id) === action.payload[0].event_id);
      return {
        ...state,
        current: {
          ...state.current,
          categories: action.payload
        },
        eventList: {
          ...state.eventList,
          data:
            eventIdx !== -1 && eventIdx !== false
              ? [
                  ...stateData.slice(0, eventIdx),
                  { ...stateData[eventIdx], categories: action.payload },
                  ...stateData.slice(eventIdx + 1)
                ]
              : stateData
        }
      };
    }
    case SET_GAME_LEVELS:
      return assocPath(['current', 'game_levels'], action.payload)(state);
    case SET_GAME_LEVEL: {
      const gameLevel = action.payload;
      gameLevel.assignor_pay = gameLevel.assignor_pay || {
        fee_type: 'No Assignor Pay',
        bill_to: '',
        free_amount: ''
      };
      gameLevel.travel_pay = gameLevel.travel_pay || {
        fee_type: 'No Travel Pay'
      };

      const initialCrewPay = new Array(gameLevel.role_ids.length)
        .fill({ base_pay: 0 })
        .map((v, i) => ({ ...v, crew_label_col: i }));

      const crewPay = gameLevel.crew_pay.length ? gameLevel.crew_pay : initialCrewPay;

      gameLevel.crew_pay = sortBy(prop('crew_label_col'))(crewPay);

      const index = state.current.game_levels.findIndex(gl => gl.id === gameLevel.id);
      return {
        ...state,
        current: {
          ...state.current,
          game_levels: update(index, gameLevel, state.current.game_levels)
        }
      };
    }
    case SET_REPORT_SETTINGS:
      return assocPath(['current', 'report_settings'], action.payload)(state);
    case SET_ASSESSMENT_SETTINGS:
      return assocPath(['current', 'assessment_settings'], action.payload)(state);
    case SET_EVENT_CREW_LABELS:
      return assocPath(['current', 'crew_labels'], action.payload)(state);
    case SET_EVENT_CREW_PAY:
      return assocPath(['current', 'crew_pay'], action.payload)(state);
    case SPREAD_EVENT: {
      const { payload } = action;
      const { eventList } = state;
      const index = state.eventList.data.findIndex(event => event.id === payload.id);
      return {
        ...state,
        eventList: {
          ...state.eventList,
          data: [
            ...eventList.data.slice(0, index),
            { ...state.eventList.data[index], ...payload.props },
            ...eventList.data.slice(index + 1)
          ]
        }
      };
    }
    case UPDATE_EVENT_STATUS:
      return assocPath(['current', 'info', 'status'], action.payload.data.attributes.status)(state);
    case SET_ALL_USER_EVENTS:
      return {
        ...state,
        allUserEvents: action.payload
      };
    case SET_EVENT_USERS:
      return {
        ...state,
        current: {
          ...state.current,
          users: action.payload
        }
      };
    case APPEND_EVENT_USERS: {
      return {
        ...state,
        current: {
          ...state.current,
          users: [...state.current.users, ...action.payload]
        }
      };
    }
    case CLEAR_EVENT_USERS_DATA: {
      return {
        ...state,
        current: {
          ...state.current,
          users: []
        }
      };
    }
    case SET_EVENT_USERS_AVAILABILITY: {
      const newStateWithAvailability = {
        ...state,
        current: {
          ...state.current,
          users: state.current.users.map(u => {
            const { usersWithAvailability } = action.payload;
            const user = usersWithAvailability.find(
              userWithAvail => userWithAvail.id.toString() === u.id
            );
            if (user) {
              const { availabilities, id } = user;
              return {
                ...u,
                availabilities:
                  availabilities.length > 0
                    ? availabilities.map(a => {
                        return {
                          ...a,
                          interval:
                            a.interval && a.interval.trim() !== '' ? a.interval.split(',') : [],
                          user_id: id,
                          id: a.id.toString(), // expected this to be a string
                          recurring: !!a.recurring, // expected to be a boolean and not 1/0
                          time_from: moment.utc(a.time_from).toISOString(),
                          time_to: moment.utc(a.time_to).toISOString()
                        };
                      })
                    : []
              };
            }
            return { ...u };
          })
        }
      };
      return newStateWithAvailability;
    }
    case FETCH_EVENT_CATEGORIES:
      return assocPath(['current', 'categories'], [])(state);
    case ADD_EVENT_USER_GAME_ASSIGNMENT: {
      const userIdx = state.current.users.findIndex(user => user.id === action.payload.userId);
      const eventRoleIdx = state.current.users[userIdx].event_roles.findIndex(
        role => role.id === action.payload.eventRoleId
      );
      if (userIdx !== -1 && eventRoleIdx !== -1) {
        /* eslint-disable no-param-reassign */
        state.current.users[userIdx].event_roles[eventRoleIdx].game_assignments = [
          ...state.current.users[userIdx].event_roles[eventRoleIdx].game_assignments,
          action.payload.assignment
        ];
        /* eslint-enable no-param-reassign */
      }
      return state;
      // {
      // ...state,
      // current: {
      //   ...state.current,
      //   users: [
      //     ...state.current.users.slice(0, userIdx),
      //     {
      //       ...state.current.users[userIdx],
      //       event_roles: [
      //         ...state.current.users[userIdx].event_roles.slice(0, eventRoleIdx),
      //         {
      //           ...state.current.users[userIdx].event_roles[eventRoleIdx],
      //           game_assignments: [
      //             ...state.current.users[userIdx].event_roles[eventRoleIdx].game_assignments,
      //             action.payload.assignment
      //           ]
      //         },
      //         ...state.current.users[userIdx].event_roles.slice(eventRoleIdx + 1)
      //       ]
      //     },
      //     ...state.current.users.slice(userIdx + 1)
      //   ]
      // }
      // };
    }
    case REMOVE_EVENT_USER_GAME_ASSIGNMENT: {
      const userIdx = state.current.users.findIndex(
        user => Number(user.id) === action.payload.userId
      );
      const eventRoleIdx = state.current.users[userIdx].event_roles.findIndex(
        role => role.id === action.payload.eventRoleId
      );

      if (userIdx !== -1 && eventRoleIdx !== -1) {
        /* eslint-disable no-param-reassign */
        state.current.users[userIdx].event_roles[
          eventRoleIdx
        ].game_assignments = state.current.users[userIdx].event_roles[
          eventRoleIdx
        ].game_assignments.filter(
          assignment => Number(assignment.id) !== Number(action.payload.assignmentId)
        );
        /* eslint-enable no-param-reassign */
      }
      return state;
      // return {
      //   ...state,
      //   current: {
      //     ...state.current,
      //     users: [
      //       ...state.current.users.slice(0, userIdx),
      //       {
      //         ...state.current.users[userIdx],
      //         event_roles: [
      //           ...state.current.users[userIdx].event_roles.slice(0, eventRoleIdx),
      //           {
      //             ...state.current.users[userIdx].event_roles[eventRoleIdx],
      //             game_assignments: state.current.users[userIdx].event_roles[
      //               eventRoleIdx
      //             ].game_assignments.filter(
      //               assignment => Number(assignment.id) !== Number(action.payload.assignmentId)
      //             )
      //           },
      //           ...state.current.users[userIdx].event_roles.slice(eventRoleIdx + 1)
      //         ]
      //       },
      //       ...state.current.users.slice(userIdx + 1)
      //     ]
      //   }
      // };
    }
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_IS_FETCHING_USERS: {
      return {
        ...state,
        isFetchingEventUsers: action.payload
      };
    }
    case SET_EVENT_PAGE_FETCHING:
      return {
        ...state,
        eventPageFetching: action.payload
      };
    case SET_CREW_WARNING_MODAL:
      return {
        ...state,
        crewWarningModal: action.payload
      };
    case SET_EVENT_GAME_LOCATION_COUNTS:
      return {
        ...state,
        eventList: {
          ...state.eventList,
          data: [
            ...state.eventList.data.map(event => {
              if (event.id === action.payload.eventId)
                return {
                  ...event,
                  summary: {
                    ...event.summary,
                    gameCount: action.payload.gamesCount,
                    locationCount: action.payload.locationsCount
                  }
                };
              return event;
            })
          ]
        }
      };
    case SET_COPY_FEES_FROM_GAME_LEVEL:
      return {
        ...state,
        copyFeesGameLevelFeching: action.payload
      };
    case SET_EVENT_INFO_IS_FETCHING:
      return {
        ...state,
        eventInfoIsFetching: action.payload
      };
    default:
      return state;
  }
}

// Action Creators
export const fetchEvents = payload => ({
  type: FETCH_EVENTS,
  payload
});

export const fetchGoverningBodies = payload => ({
  type: FETCH_GOVERNING_BODIES,
  payload
});

export const setGoverningBodies = payload => ({
  type: SET_GOVERNING_BODIES,
  payload
});

export const fetchEventInfoCategories = payload => ({
  type: FETCH_EVENT_INFO_CATEGORIES,
  payload
});

export const fetchEventInfoCrewLabels = payload => ({
  type: FETCH_EVENT_INFO_CREW_LABELS,
  payload
});

export const fetchEventInfoGameLevels = payload => ({
  type: FETCH_EVENT_INFO_GAME_LEVELS,
  payload
});

export const fetchEventInfoReportSettings = payload => ({
  type: FETCH_EVENT_INFO_REPORT_SETTINGS,
  payload
});

export const fetchEventInfoAssessmentSettings = payload => ({
  type: FETCH_EVENT_INFO_ASSESSMENT_SETTINGS,
  payload
});

export const fetchEventInfoCrewPay = payload => ({
  type: FETCH_EVENT_INFO_CREW_PAY,
  payload
});

export const createEvent = payload => ({
  payload,
  type: CREATE_EVENT
});

export const createEventAndRedirect = payload => ({
  payload,
  type: CREATE_EVENT_AND_REDIRECT
});

export const deleteEventAndRedirect = payload => ({
  payload,
  type: DELETE_EVENT_AND_REDIRECT
});

export const setEvents = payload => ({
  payload,
  type: SET_EVENTS
});

export const setEventCrewLabels = payload => ({
  type: SET_EVENT_CREW_LABELS,
  payload
});

export const setEventInfo = payload => ({
  payload,
  type: SET_EVENT_INFO
});

export const setBillingInfo = payload => ({
  payload,
  type: SET_EVENT_BILLING
});

export const setTaxInfo = payload => ({
  payload,
  type: SET_EVENT_TAX_INFO
});

export const fetchEventCategories = payload => ({
  payload,
  type: FETCH_EVENT_CATEGORIES
});

export const setCategories = payload => ({
  payload,
  type: SET_EVENT_CATEGORIES
});

export const fetchEventGameLevels = payload => ({
  payload,
  type: FETCH_EVENT_GAME_LEVELS
});

export const fetchGameLevel = payload => ({
  payload,
  type: FETCH_GAME_LEVEL
});

export const copyFeesFromGameLevel = payload => ({
  payload,
  type: COPY_FEES_FROM_GAME_LEVEL
});

export const setGameLevels = payload => ({
  payload,
  type: SET_GAME_LEVELS
});

export const setGameLevel = payload => ({
  payload,
  type: SET_GAME_LEVEL
});

export const setReportSettings = payload => ({
  payload,
  type: SET_REPORT_SETTINGS
});

export const setAssessmentSettings = payload => ({
  payload,
  type: SET_ASSESSMENT_SETTINGS
});

export const setCrewPay = payload => ({
  payload,
  type: SET_EVENT_CREW_PAY
});

export const spreadEvent = payload => ({
  payload,
  type: SPREAD_EVENT
});

export const deleteCategory = payload => ({
  payload,
  type: DELETE_EVENT_CATEGORY
});

export const updateCategories = payload => ({
  payload,
  type: UPDATE_EVENT_CATEGORIES
});

export const updateReportSettings = payload => ({
  payload,
  type: UPDATE_REPORT_SETTINGS
});

export const updateAssessmentsSettings = payload => ({
  payload,
  type: UPDATE_ASSESSMENT_SETTINGS
});

export const updateGameLevels = payload => ({
  payload,
  type: UPDATE_GAME_LEVELS
});

export const updateCrewLabels = payload => ({
  payload,
  type: UPDATE_CREW_LABELS
});

export const updateCrewPayTable = payload => ({
  payload,
  type: UPDATE_EVENT_CREW_PAY_TABLE
});

export const updateEvent = payload => ({
  type: UPDATE_EVENT,
  payload
});

export const fetchEvent = (eventId, include_billing_customer_data = false) => ({
  type: FETCH_EVENT,
  payload: { eventId, include_billing_customer_data }
});

export const switchEventStatus = () => ({
  type: SWITCH_EVENT_STATUS
});

export const updateEventStatus = result => ({
  type: UPDATE_EVENT_STATUS,
  payload: result
});

export const fetchAllUserEvents = payload => ({
  type: FETCH_ALL_USER_EVENTS,
  payload
});

export const setAllUserEvents = payload => ({
  type: SET_ALL_USER_EVENTS,
  payload
});

export const linkGamesToEvent = payload => ({
  type: LINK_GAMES_TO_EVENT,
  payload
});

export const fetchEventUsers = payload => ({
  type: FETCH_EVENT_USERS,
  payload
});

export const fetchEventUsersByUserIdAction = payload => ({
  type: FETCH_EVENT_USERS_BY_USER_ID,
  payload
});

export const clearEventUsersDataStateAction = payload => ({
  type: CLEAR_EVENT_USERS_DATA,
  payload
});

export const setEventUsers = payload => ({
  type: SET_EVENT_USERS,
  payload
});

export const appendEventUsersStateAction = payload => ({
  type: APPEND_EVENT_USERS,
  payload
});

export const setEventUsersAvailability = payload => ({
  type: SET_EVENT_USERS_AVAILABILITY,
  payload
});

export const addEventUserGameAssignment = payload => ({
  type: ADD_EVENT_USER_GAME_ASSIGNMENT,
  payload
});

export const removeEventUserGameAssignment = payload => ({
  type: REMOVE_EVENT_USER_GAME_ASSIGNMENT,
  payload
});

export const setIsFetching = payload => ({
  type: SET_IS_FETCHING,
  payload
});

export const setIsFetchingEventUsersAction = payload => ({
  type: SET_IS_FETCHING_USERS,
  payload
});

export const setEventPageFetching = payload => ({
  type: SET_EVENT_PAGE_FETCHING,
  payload
});

export const setCrewWarningModal = payload => ({
  type: SET_CREW_WARNING_MODAL,
  payload
});

export const fetchFilteredEvents = payload => ({
  type: FETCH_FILTERED_EVENTS,
  payload
});

export const setFilteredEvents = payload => ({
  payload,
  type: SET_FILTERED_EVENTS
});

export const fetchGameLocationCounts = payload => ({
  type: FETCH_EVENT_GAME_LOCATION_COUNTS,
  payload
});

export const setGameLocationCounts = payload => ({
  type: SET_EVENT_GAME_LOCATION_COUNTS,
  payload
});

export const setCopyFeesGameLevelFeching = payload => ({
  type: SET_COPY_FEES_FROM_GAME_LEVEL,
  payload
});

export const setEventInfoIsFetching = payload => ({
  type: SET_EVENT_INFO_IS_FETCHING,
  payload
});
