import { compose } from 'recompose';
import { connect } from 'react-redux';

import unconnected from './unconnected';
import {
  isGamesEventAdminOrAssignor,
  isReportsEventAdmin,
  selectUserId,
  isReportEventAssignor,
  isGamesEventOfficial
} from '../../../core/Auth/selectors';
import {
  createIncidentReport as createIncidentReportAction,
  editIncidentReport as editIncidentReportAction,
  fetchCurrentIncidentReports as fetchCurrentIncidentReportsAction,
  deleteIncidentReport as deleteIncidentReportAction
} from '../../../core/Games';
import { editIncidentReport as editReportAction } from '../../../core/Reports';
import { getCurrentGame, getCurrentGameEventReportSettings } from '../../../core/Games/selectors';
import {
  getCurrentIsFetching,
  getCurrentReport,
  isReportsEventGroupAdmin
} from '../../../core/Reports/selectors';
import { setShowModalFor as setShowModalForAction } from '../../../core/Modals';
import { getFrom } from '../../../core/Modals/selectors';
import { getReportSettings } from '../../../core/Events/selectors';

const mapStateToProps = state => ({
  game: getCurrentGame(state),
  from: getFrom(state),
  report: getCurrentReport(state),
  reportSettings: getReportSettings(state) || getCurrentGameEventReportSettings(state),
  isGamesEventAdminOrAssignor: isGamesEventAdminOrAssignor(state),
  isReportsEventGroupAdmin: isReportsEventGroupAdmin()(state),
  isReportsEventAdmin: isReportsEventAdmin(state),
  currentUserId: selectUserId(state),
  isFetching: state.games.current.isFetching,
  isReportsEventAssignor: isReportEventAssignor(state),
  isGamesEventOfficial: isGamesEventOfficial(state),
  isFetchingReports: getCurrentIsFetching(state)
});

const mapDispatchToProps = dispatch => ({
  createIncidentReport: payload => dispatch(createIncidentReportAction(payload)),
  editIncidentReport: payload => dispatch(editIncidentReportAction(payload)),
  editReport: payload => dispatch(editReportAction(payload)),
  deleteIncidentReport: payload => dispatch(deleteIncidentReportAction(payload)),
  setShowModalFor: payload => dispatch(setShowModalForAction(payload)),
  fetchCurrentIncidentReports: payload => dispatch(fetchCurrentIncidentReportsAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
