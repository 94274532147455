import { compose, pure, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { fetchSports } from '../../../core/Sports';

const mapDispatchToProps = dispatch => ({
  onFetchSports: () => dispatch(fetchSports())
});

export default compose(
  connect(null, mapDispatchToProps),
  lifecycle({
    componentWillMount() {
      const { onFetchSports } = this.props;
      onFetchSports();
    }
  }),
  pure
);
