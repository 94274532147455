import MaterialInput from '../MaterialInput';
import MaterialDatePicker from '../../controls/MaterialDatePicker';
import CheckField from '../CheckField';
import ChecksField from '../ChecksField';

import { normalizePhone } from '../../../utils/parsers';
import {
  minLength,
  required,
  composeValidators,
  mustBeEmail,
  minAge,
  isValidAreaCode
} from '../../../utils/validations';
import { usaStates } from '../../../constants';
import { checkFieldStyles, checkFieldsStyles } from './styled-components';
import { checkForValues, travelFields } from '../../../utils/helpers';
import MaterialUiDropDown from '../../global-material-components/MaterialUiDropdown';
import MaterialUiTypehead from '../../global-material-components/MaterialUiTypehead';

const travelAddressLogic = {
  validate: (value, values) =>
    checkForValues(values.travelAddress, travelFields) ? required(value) : undefined,
  isRequired: values => checkForValues(values.travelAddress, travelFields)
};

const isRequired = () => true;

export default [
  {
    title: 'Personal',
    subSections: [
      {
        title: 'Personal Info',
        name: 'profile',
        rows: [
          {
            fields: [
              {
                component: MaterialInput,
                name: 'first_name',
                placeholder: 'First Name',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'last_name',
                placeholder: 'Last Name',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialDatePicker,
                name: 'dob',
                placeholder: 'Birthdate (YYYY-MM-DD)',
                validate: composeValidators(required, minAge),
                birthday: true,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: ChecksField,
                name: 'gender',
                placeholder: 'Gender',
                options: [
                  { name: 'Male', value: 'male' },
                  { name: 'Female', value: 'female' }
                ],
                validate: required,
                isRequired
              }
            ],
            custom: checkFieldsStyles
          }
        ]
      },
      {
        title: 'Emergency Info',
        name: 'emergencyInfo',
        rows: [
          {
            fields: [
              {
                component: MaterialInput,
                name: 'first_name',
                placeholder: 'First Name',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'last_name',
                placeholder: 'Last Name',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'relationship',
                placeholder: 'Relationship',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'primary_phone_number',
                placeholder: 'Primary Phone',
                parse: normalizePhone,
                validate: composeValidators(required, minLength(14), isValidAreaCode),
                width: 7,
                isRequired
              },
              {
                component: MaterialUiDropDown,
                name: 'primary_phone_type',
                options: [
                  { name: 'HOME', value: 1 },
                  { name: 'CELL', value: 2 },
                  { name: 'WORK', value: 3 }
                ],
                validate: required,
                width: 4,
                placeholder: '',
                isRequired,
                theme: {
                  selectWrapper: { marginTop: '0px !important' }
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: 'Addresses',
    subSections: [
      {
        title: 'Primary Address',
        name: 'primaryAddress',
        rows: [
          {
            fields: [
              {
                component: MaterialInput,
                name: 'address_1',
                placeholder: 'Address 1',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'address_2',
                placeholder: 'Address 2'
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'city',
                placeholder: 'City',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialUiTypehead,
                name: 'state',
                options: usaStates,
                placeholder: 'State',
                validate: required,
                width: 7,
                isRequired,
                showError: true
              },
              {
                component: MaterialInput,
                name: 'postal_code',
                placeholder: 'Zip Code',
                validate: required,
                width: 4,
                isRequired,
                style: { marginTop: '15px' }
              }
            ]
          },
          {
            fields: [
              {
                component: CheckField,
                name: 'makeTravelAddress',
                placeholder: 'Make this my travel address'
              }
            ],
            custom: checkFieldStyles
          }
        ]
      },
      {
        title: 'Travel Address',
        name: 'travelAddress',
        conditions: ['copyPrimaryAddress'],
        rows: [
          {
            fields: [
              {
                component: MaterialInput,
                name: 'address_1',
                placeholder: 'Address 1',
                ...travelAddressLogic
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'address_2',
                placeholder: 'Address 2'
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'city',
                placeholder: 'City',
                ...travelAddressLogic
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialUiTypehead,
                name: 'state',
                options: usaStates,
                placeholder: 'State',
                width: 7,
                ...travelAddressLogic
              },
              {
                component: MaterialInput,
                name: 'postal_code',
                placeholder: 'Zip Code',
                width: 4,
                style: { marginTop: '15px' },
                ...travelAddressLogic
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: 'Contact',
    subSections: [
      {
        title: 'Primary Contact',
        name: 'primaryContact',
        rows: [
          {
            fields: [
              {
                component: MaterialInput,
                name: 'primary_email',
                placeholder: 'Primary Email Address',
                readOnly: true
              }
            ]
          },
          {
            fields: [
              {
                component: CheckField,
                name: 'show_email',
                placeholder: 'Display to all users'
              }
            ],
            custom: checkFieldStyles
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'primary_phone_number',
                placeholder: 'Primary Phone',
                parse: normalizePhone,
                validate: composeValidators(required, minLength(14), isValidAreaCode),
                width: 7,
                isRequired
              },
              {
                component: MaterialUiDropDown,
                name: 'primary_phone_type',
                options: [
                  { name: 'HOME', value: 1 },
                  { name: 'CELL', value: 2 },
                  { name: 'WORK', value: 3 }
                ],
                validate: required,
                width: 4,
                placeholder: '',
                isRequired,
                theme: {
                  selectWrapper: { marginTop: '0px !important' }
                }
              }
            ]
          },
          {
            fields: [
              {
                component: CheckField,
                name: 'show_phone',
                placeholder: 'Display to all users'
              }
            ],
            custom: checkFieldStyles
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'secondary_email',
                placeholder: 'Secondary Email Address',
                validate: mustBeEmail
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'secondary_phone_number',
                placeholder: 'Secondary Phone',
                parse: normalizePhone,
                validate: composeValidators(minLength(14), isValidAreaCode),
                width: 7
              },
              {
                component: MaterialUiDropDown,
                name: 'secondary_phone_type',
                options: [
                  { name: 'HOME', value: 1 },
                  { name: 'CELL', value: 2 },
                  { name: 'WORK', value: 3 }
                ],
                width: 4,
                placeholder: '',
                theme: {
                  selectWrapper: { marginTop: '0px !important' }
                }
              }
            ]
          }
        ]
      },
      {
        title: 'Parental',
        name: 'parentGuardianContact',
        conditions: ['requiresParental'],
        rows: [
          {
            fields: [
              {
                component: MaterialInput,
                name: 'first_name',
                placeholder: 'First Name',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'last_name',
                placeholder: 'Last Name',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'relationship',
                placeholder: 'Relationship',
                validate: required,
                isRequired
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'primary_phone_number',
                placeholder: 'Primary Phone',
                parse: normalizePhone,
                validate: composeValidators(required, minLength(14), isValidAreaCode),
                width: 7,
                isRequired
              },
              {
                component: MaterialUiDropDown,
                name: 'primary_phone_type',
                options: [
                  { name: 'HOME', value: 1 },
                  { name: 'CELL', value: 2 },
                  { name: 'WORK', value: 3 }
                ],
                validate: required,
                width: 4,
                placeholder: '',
                isRequired,
                theme: {
                  selectWrapper: { marginTop: '0px !important' }
                }
              }
            ]
          },
          {
            fields: [
              {
                component: MaterialInput,
                name: 'primary_email',
                placeholder: 'Email Address',
                validate: composeValidators(required, mustBeEmail),
                isRequired
              }
            ]
          }
        ]
      }
    ]
  }
];
