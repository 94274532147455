import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Col } from 'react-flexbox-grid';

import Input from '../Input';
import Button from '../Button';
import { EmptyLine } from './styled-components';
import { ButtonsWrappers } from '../../layouts/AuthLayout/styled-components';
import { required, mustBeEmail, composeValidators } from '../../../utils/validations';
import { COLORS } from '../../../utils/styles';
import Dropdown from '../Dropdown';
import Enhancer from '../../containers/CreateUser';
import LoaderWrapper from '../LoaderWrapper';
import { PLATFORM_TYPES } from '../../../constants';

const roles = [
  {
    value: 'superAdmin',
    label: 'Super Admin User'
  },
  {
    value: 'admin',
    label: 'Group Admin User'
  }
];

const groupDefaultValue = 'Select Group';

const CreateAdmin = ({ onCreateAccount, submitting, groups, isFetching }) => {
  const [focus, setFocus] = React.useState(false);

  return (
    <LoaderWrapper isFetching={isFetching}>
      <Form
        onSubmit={onCreateAccount}
        initialValues={{
          userRole: roles[0].value
        }}
        render={({ handleSubmit, pristine, invalid, values }) => (
          <form
            onSubmit={e => {
              handleSubmit(e);
              setFocus(false);
            }}
          >
            <Field name="userRole">
              {({ input: { name, value, onChange }, meta: { active, error, touched } }) => (
                <Dropdown
                  dropdownWidth="9rem"
                  label="user role"
                  options={roles}
                  onChange={({ target: { value: userRole } }) => {
                    onChange(userRole);
                  }}
                  active={active}
                  columnSize={2}
                  error={error && touched ? error : ''}
                  name={name}
                  value={value}
                  borderRadius="5px"
                  backgroundColor={COLORS.white}
                  borderColor={COLORS.slateGray}
                  outline="none"
                  dropdownHeight="1.5rem"
                />
              )}
            </Field>
            <EmptyLine />
            <Field name="groupId" validate={required} key={values.userRole}>
              {({ input: { name, value, onChange }, meta: { error, active } }) => {
                const groupOptions =
                  values.userRole === roles[0].value
                    ? groups
                        .filter(group => group.platform_type === PLATFORM_TYPES.PLATFORM)
                        .map(({ id, name: label }) => ({
                          value: id,
                          label: `${label} - ${id}`
                        }))
                    : groups.map(({ id, name: label }) => ({
                        value: id,
                        label: `${label} - ${id}`
                      }));

                return (
                  <Dropdown
                    dropdownWidth="9rem"
                    label="group/platform"
                    options={groupOptions}
                    onChange={({ target: { value: group } }) =>
                      onChange(group === groupDefaultValue ? null : group)
                    }
                    onFocus={() => setFocus(true)}
                    active={active}
                    columnSize={2}
                    error={focus && error ? error : ''}
                    name={name}
                    value={value}
                    borderRadius="5px"
                    backgroundColor={COLORS.white}
                    borderColor={COLORS.slateGray}
                    outline="none"
                    dropdownHeight="1.5rem"
                    placeholder={groupDefaultValue}
                  />
                );
              }}
            </Field>
            <Field name="firstName" validate={required}>
              {({ input, meta: { active, error, touched } }) => (
                <Input
                  label="first name"
                  labelType="secondary"
                  placeholder="first name"
                  active={active}
                  error={error && touched ? error : ''}
                  {...input}
                />
              )}
            </Field>
            <Field name="lastName" validate={required}>
              {({ input, meta: { active, error, touched } }) => (
                <Input
                  label="last name"
                  labelType="secondary"
                  placeholder="last name"
                  active={active}
                  error={error && touched ? error : ''}
                  {...input}
                />
              )}
            </Field>
            <Field name="email" validate={composeValidators(required, mustBeEmail)}>
              {({ input, meta: { active, error, touched } }) => (
                <Input
                  label="email"
                  labelType="secondary"
                  placeholder="email"
                  active={active}
                  error={error && touched ? error : ''}
                  {...input}
                />
              )}
            </Field>
            <ButtonsWrappers center="xs">
              <Col xs={12} sm={12} md={8} lg={5}>
                <Button
                  type="submit"
                  label={`Create ${roles.filter(role => role.value === values.userRole)[0].label}`}
                  submitting={submitting}
                  disabled={pristine || invalid || submitting}
                  primary
                />
              </Col>
            </ButtonsWrappers>
          </form>
        )}
      />
    </LoaderWrapper>
  );
};

CreateAdmin.propTypes = {
  onCreateAccount: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  groups: PropTypes.instanceOf(Array),
  isFetching: PropTypes.bool
};

CreateAdmin.defaultProps = {
  groups: [],
  isFetching: false
};

export default Enhancer(CreateAdmin);
