/* eslint-disable */
import { injectGlobal } from 'styled-components';

import FiraSans from '../../../assets/fonts/FiraSans.otf';
import FiraSansUltraLight from '../../../assets/fonts//FiraSansUltraLight.otf';
import SanFrancisco from '../../../assets/fonts/SanFrancisco.otf';
import Roboto from '../../../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
import RobotoMedium from '../../../assets/fonts/Roboto-Medium.ttf';
import RobotoLight from '../../../assets/fonts/Roboto-Light.ttf';
import RobotoCondensed from '../../../assets/fonts/RobotoCondensed-Regular.ttf';
import RobotoCondensedBold from '../../../assets/fonts/RobotoCondensed-Bold.ttf';
import RobotoCondensedLight from '../../../assets/fonts/RobotoCondensed-Light.ttf';
import BarlowSemiCondensed from '../../../assets/fonts/BarlowSemiCondensed-Regular.ttf';
import BarlowMedium from '../../../assets/fonts/Barlow-Medium.ttf';
import BarlowSemiBold from '../../../assets/fonts/Barlow-SemiBold.ttf';
import Barlow from '../../../assets/fonts/Barlow-Regular.ttf';
import BarlowLight from '../../../assets/fonts/Barlow-Light.ttf';
import BarlowBold from '../../../assets/fonts/Barlow-Bold.ttf';
export const fonts = `
@font-face {
  font-family: 'FiraSans';
  src: local('FiraSans'), url(${FiraSans}) format('opentype');
}

@font-face {
  font-family: 'FiraSansUltraLight';
  src: local('FiraSansUltraLight'), url(${FiraSansUltraLight}) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(${Roboto}) format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold'), url(${RobotoBold}) format('truetype');
}

@font-face {
  font-family: 'RobotoMedium';
  src: local('RobotoMedium'), url(${RobotoMedium}) format('truetype');
}

@font-face {
  font-family: 'RobotoLight';
  src: local('RobotoLight'), url(${RobotoLight}) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'), url(${RobotoCondensed}) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedBold';
  src: local('RobotoCondensedBold'), url(${RobotoCondensedBold}) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedLight';
  src: local('RobotoCondensedLight'), url(${RobotoCondensedLight}) format('truetype');
}

@font-face {
  font-family: 'FiraSansUltraLight';
  src: local('FiraSansUltraLight'), url(${FiraSansUltraLight}) format('opentype');
}

@font-face {
  font-family: 'SanFrancisco';
  src: local('SanFrancisco'), url(${SanFrancisco}) format('opentype');
}

@font-face {
  font-family: 'BarlowSemiCondensed';
  src: local('BarlowSemiCondensed'), url(${BarlowSemiCondensed}) format('truetype');
}

@font-face {
  font-family: 'BarlowSemiCondensedSemiBold';
  src: local('BarlowSemiCondensedSemiBold'), url(${BarlowSemiCondensed}) format('truetype');
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url(${Barlow}) format('truetype');
}

@font-face {
  font-family: 'BarlowMedium';
  src: local('BarlowMedium'), url(${BarlowMedium}) format('truetype');
}

@font-face {
  font-family: 'BarlowSemiBold';
  src: local('BarlowSemiBold'), url(${BarlowSemiBold}) format('truetype');
}

@font-face {
  font-family: 'BarlowLight';
  src: local('BarlowLight'), url(${BarlowLight}) format('truetype');
}

@font-face {
  font-family: 'BarlowBold';
  src: local('BarlowBold'), url(${BarlowBold}) format('truetype');
}
`;

injectGlobal`

  ${fonts}

  html,
  body,
  #root {
      height:100%;
    }

    html,
    body {
      margin: 0;
      padding: 0;
      font-family: 'Roboto';
      font-size: 1rem;
    }

    a {
      text-decoration: none;
    }

  #swPopUp {
    position:fixed;
    width:100%;
    top:0px;
    left:0%;
    z-index:9999999;
    background:#fff;
    padding:5px 10px;
    font-size:12px;
    display:none;
    align-items:center;
    justify-content:center;
    border-bottom:2px solid #d3d2d2;
  }
  @media (max-width: 560px) {
    #swPopUp {
      padding:5px;
    }

    .button {
      margin: 14px 0px;
      display: block;
    }

    .swTitle {
      padding: 0px 5px;
    }
  }
  #reload {
    cursor:pointer;
    border:1px solid #05579d;
    background:#05579d;
    color:#fff;
    padding:2px 10px;
    border-radius:4px;
  }
  #close {
    cursor:pointer;
    border:1px solid #05579d;
    background:#fff;
    color:#05579d;
    padding:2px 10px;
    border-radius:4px;
  }
`;
