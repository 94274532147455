import { compose, withState, withHandlers } from 'recompose';
import MODAL_NAMES, { FROM_NOTIFICATIONS } from '../../../../../core/Modals/constants';
import { NOTIFICATION_STATUS } from '../../../../../constants';
import { convertUtcToTimezone } from '../../../../../utils/helpers';
import { formatDate } from '../../../../../utils/parsers';

export default compose(
  withState('expandedId', 'setExpandedId', null),
  withHandlers({
    onExpand: ({ fetchNotification, setExpandedId, clearNotification }) => expandedId => {
      clearNotification();
      setExpandedId(expandedId);
      if (expandedId) fetchNotification(expandedId);
    },
    onSubmit: ({
      setCurrentGame,
      setNotificationEvent,
      setExpandedId,
      updateNotification,
      notifications,
      closeMenu,
      setShowModalFor,
      setCurrentEventInfo
    }) => async ({ expandedId, game: gameObj, event }) => {
      const gameDate = convertUtcToTimezone(
        `${gameObj.start_date} ${gameObj.start_time}`,
        gameObj.timezone
      );
      const game = {
        ...gameObj,
        start_date: formatDate(gameDate, 'MM/DD/YYYY'),
        start_time: formatDate(gameDate, 'h:mm A')
      };
      setCurrentEventInfo(event);
      setCurrentGame(game);
      setNotificationEvent(event);
      const modal = { name: MODAL_NAMES.crewVerificationReportsModal, from: FROM_NOTIFICATIONS };
      setShowModalFor(modal);

      if (notifications.length === 1) closeMenu();
      updateNotification({ id: expandedId, data: { status: NOTIFICATION_STATUS.action_taken } });
      setExpandedId(null);
    }
  })
);
