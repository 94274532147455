import { compose, withState, withProps } from 'recompose';

export default ({ setShowAddAvailabilityModal, setUserAvailabilityNote, writeAccess }) =>
  compose(
    withState('showDatePicker', 'setShowDatePicker', false),
    withProps({
      setShowAddAvailabilityModal,
      setUserAvailabilityNote,
      writeAccess
    })
  );
