import Geocode from 'react-geocode';
import { IOS_DEVICES, URLS } from '../constants';
import { encodeQueryString } from './helpers';

export const LOCATION_ERRORS = {
  DEFAULT: 'An error occured while fetching your current location.',
  PERMISSION_DENIED:
    'Permission denied, please allow location permission from your browser settings.',
  POSITION_UNAVAILABLE: 'Can not determine your current location.',
  TIMEOUT: 'Request timed out while fetching your current location.',
  UNKNOWN: 'An unknown error occurred in fetching your current location.',
  UNSUPPORTED: 'Your browser do not support location service.'
};

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

export const redirectToMaps = {
  /**
   * Searches for direction on default maps for provided source and destination
   *
   * @param {String} src source address or geolocation
   * @param {String} dest destination address or geolocation
   *
   */
  searchDirection: (src, dest) => {
    const isIOSDevice = IOS_DEVICES.some(d => navigator.userAgent.indexOf(d) > -1);
    const url = isIOSDevice
      ? `${URLS.APPLE_MAPS}/?saddr=${src}&daddr=${dest}`
      : `${URLS.GOOGLE_MAPS}/dir/?saddr=${src}&daddr=${dest}`;

    if (isIOSDevice) window.location.href = url;
    else
      setTimeout(() => {
        window.open(url, 'mapsTab');
      });

    return true;
  },

  /**
   * Searches for provided location query on default maps
   *
   * @param {String} query URL encoded query string
   *
   */
  searchLocation: query => {
    const isIOSDevice = IOS_DEVICES.some(d => navigator.userAgent.indexOf(d) > -1);
    const url = isIOSDevice
      ? `${URLS.APPLE_MAPS}/?q=${query}`
      : `${URLS.GOOGLE_MAPS}/search/?api=1&query=${query}`;

    if (isIOSDevice) window.location.href = url;
    else
      setTimeout(() => {
        window.open(url, 'mapsTab');
      });

    return true;
  }
};

export default class Maps {
  /**
   * Get directions from current location to supplied destination on default maps
   *
   * @param {Object} dest object having geocode and address of destination
   * @param {Function} handleError function to handle error
   *
   * @returns Directions
   */
  static getDirections(dest, handleError) {
    if (!navigator.geolocation) {
      return handleError(LOCATION_ERRORS.UNSUPPORTED);
    }

    return navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const source = `${lat},${lng}`;
        const destination =
          dest.geoLocation && dest.geoLocation.lat && dest.geoLocation.lng
            ? `${dest.geoLocation.lat},${dest.geoLocation.lng}`
            : encodeQueryString(dest.address);
        return redirectToMaps.searchDirection(source, destination);
      },
      error => {
        let message = '';

        switch (error.code) {
          case error.PERMISSION_DENIED:
            message = LOCATION_ERRORS.PERMISSION_DENIED;
            break;
          case error.TIMEOUT:
            message = LOCATION_ERRORS.TIMEOUT;
            break;
          case error.UNKNOWN_ERROR:
            message = LOCATION_ERRORS.UNKNOWN;
            break;
          case error.POSITION_UNAVAILABLE:
            message = LOCATION_ERRORS.POSITION_UNAVAILABLE;
            break;
          default:
            message = LOCATION_ERRORS.DEFAULT;
        }

        return handleError(message);
      }
    );
  }

  /**
   * Get locations for supplied address on default maps
   *
   * @param {Object} location object having geocode and address
   *
   * @returns Locations
   */
  static getLocations(location) {
    const query = location.address
      ? encodeQueryString(location.address)
      : location.geoLocation
      ? `${location.geoLocation.lat},${location.geoLocation.lng}`
      : '';

    return redirectToMaps.searchLocation(query);
  }

  /**
   * Get latitude & longitude from address.
   *
   * @param {String} address full address as string
   *
   * @returns object with { lat, lng }
   */
  static async getGeoLocation(address) {
    const data = await Geocode.fromAddress(address).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        return { lat, lng };
      },
      error => error
    );
    return data;
  }
}
