import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { COLORS } from '../../../utils/styles';
import './index.css';
import { Subheading } from '..';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  TableWrapper: {
    minWidth: 700
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#167CD4',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const sortData = (data, direction, sortFunction) => {
  if (sortFunction) {
    if (direction === 'desc') {
      return data.sort(sortFunction).reverse();
    }
    return data.sort(sortFunction);
  }
  return data;
};

export default function TableWrapper({ headings, data, dataKey, total, ...rest }) {
  const classes = useStyles();
  const [sortDirection, setSortDirection] = React.useState('desc');
  const [sortKey, setSortKey] = React.useState(null);
  const [sortFunction, setSortFunction] = React.useState(null);

  const sortedData = sortData(data, sortDirection, sortFunction);
  const numberOfColumns = headings.length;

  return (
    <Paper className={classes.root}>
      <Table {...rest} className={classes.TableWrapper}>
        <TableHead>
          <TableRow>
            {headings.map(heading => (
              <StyledTableCell key={heading.name}>
                {heading.sortable ? (
                  <TableSortLabel
                    style={{
                      color: '#fff'
                    }}
                    active={sortKey === heading.id}
                    direction={sortDirection}
                    onClick={() => {
                      if (sortKey !== heading.id) {
                        setSortKey(heading.id);
                        setSortDirection('desc');
                        setSortFunction(heading.sortFunction);
                      } else {
                        setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                      }
                    }}
                  >
                    {heading.name}
                  </TableSortLabel>
                ) : (
                  heading.name
                )}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.length ? (
            sortedData.map((row, i) => (
              // eslint-disable-next-line
              <TableRow key={`row[${dataKey}]${i}`}>
                {/* eslint-disable-next-line */}
                {Object.keys(row).map((item, i) => (
                  <TableCell
                    key={`row[${item}]${i}`}
                    style={{
                      color: row.status === 'Pending' ? COLORS.naturalGray : COLORS.textColor
                    }}
                  >
                    {!(
                      headings.find(heading => heading.id === item) &&
                      headings.find(heading => heading.id === item).parser
                    )
                      ? row[item]
                      : headings.find(heading => heading.id === item).parser(row[item])}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={numberOfColumns}>There is no data to display.</TableCell>
            </TableRow>
          )}

          {total ? (
            <TableRow>
              <TableCell colSpan={numberOfColumns - 3} />
              <TableCell>
                <Subheading>Total</Subheading>
              </TableCell>
              <TableCell>
                <Subheading>{total}</Subheading>
              </TableCell>
            </TableRow>
          ) : null}
          {/* I realize that && should produce the same output as above, but it throws a warning when I use it. */}
        </TableBody>
      </Table>
    </Paper>
  );
}

TableWrapper.propTypes = {
  headings: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired
};
