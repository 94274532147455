import { compose, withState, withHandlers } from 'recompose';
import FileSaver from 'file-saver';

import { formatDate } from '../../../../utils/parsers';

export default compose(
  withState('showEmailSelectedModal', 'toggleShowEmailSelectedModal', false),
  withHandlers({
    downloadCSV: ({ eventId, pagination: { onLoadPage }, roles }) => () =>
      new Promise(resolve =>
        onLoadPage({
          eventId,
          roles,
          toCSV: true,
          resolve
        })
      ).then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' });
        FileSaver.saveAs(blob, `user_list_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
      })
  })
);
