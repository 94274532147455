import styled from 'styled-components';
import Icon from '../Icon';
import { COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';
import { Wrapper as NotificationIconWrapper } from './NotificationIcon/styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${pxToRem('20px')} ${pxToRem('24px')};
  background: ${COLORS.white};
  position: relative;
  box-sizing: border-box;
  .desktop {
    display: block;
  }

  ${mediaQueries.md(`
  padding: 10px 0px;
  background: rgba(0,0,0,0);
  box-shadow: inset 0 0 0 0 ${COLORS.sapphire};
  .desktop{display:none;}
`)};
`;

export const UserMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const FirstNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const FirstName = styled.span`
  margin: 0 0.75rem 0 ${pxToRem('14px')};
  font-family: 'Roboto', sans-serif;
  font-size: ${pxToRem('13px')};
  color: ${COLORS.denimBlue};
`;

export const StyledIcon = styled(Icon)`
  margin: 0;
`;

export const ArrowDown = StyledIcon.extend`
  position: relative;
  top: 1px;
`;

export const NotificationIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > ${NotificationIconWrapper} {
    margin-right: ${pxToRem('15px')};
  }
`;

export const MouseOverPopoverWrapper = styled.div`
  width: 100%;
`;
