import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Col, Row } from 'react-flexbox-grid';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment/moment';
import {
  GameData,
  GameId,
  Status,
  Header,
  RightContent,
  CancelledOrPostponedStatus,
  TeamCol
} from '../../../../StyledGames/GameRow/styled-components';
import { GAMES_STATUSES } from '../../../../../../constants';
import { Clickable, RowIcon } from '../../styled-components';
import { formatDate } from '../../../../../../utils/parsers';
import {
  convertToTimezone,
  getTimezoneAbbr,
  getGameStatus,
  convertToUtc
} from '../../../../../../utils/helpers';
import publishedPath from '../../../../../../assets/images/published.png';
import GameDetails from './GameDetails';
import GameTeam from './GameTeam';
import GameLevelAndType from './GameLevelAndType';
import GameReportAction from './GameReportAction';

const useStyles = makeStyles({
  tooltip: {
    background: 'rgba(0,0,0,0.9)'
  }
});

const GameInfo = ({
  start_date,
  start_time,
  timezone,
  toggleModal,
  copyNo,
  display_id,
  id,
  published,
  status,
  field,
  field_name,
  location,
  team_home,
  team_visitor,
  game_level,
  division,
  end_date,
  isGamesEventGroupAdmin,
  isGamesEventAdminOrAssignor,
  reportSettings,
  onCrewVerificationClick,
  notes,
  onGamesReportClick,
  gameReportSyncLock,
  incident_report_display,
  onIncidentReportClick,
  eventsAssessmentSettings,
  assessment_report_display,
  reportSubmittedStatus,
  hasGameReports,
  end_time,
  onAssessmentReportClick,
  isGameAssessor,
  gameOfficial,
  isUserAssignmentStatusAccepted,
  modalStatus,
  allowedToSeeGameReports
}) => {
  const classes = useStyles();

  const isGameComplete = moment.utc().format() > convertToUtc(`${end_date} ${end_time}`, timezone);

  const gameStatus = getGameStatus(status);
  const cancelledOrPostponedGame =
    gameStatus === GAMES_STATUSES.CANCELLED || gameStatus === GAMES_STATUSES.POSTPONED;

  const localStartTime = convertToTimezone(`${start_date} ${start_time}`, timezone, 'h:mm A');
  const localStartDate = convertToTimezone(`${start_date} ${start_time}`, timezone, 'MM/DD/YYYY');
  const timezoneAbbr = getTimezoneAbbr(localStartDate, timezone, 'MM/DD/YYYY');
  const gameIdWithIteration = copyNo ? `${display_id || id}_${copyNo}` : display_id || id;

  return (
    <>
      <Row>
        <Col xs={12}>
          <Header>
            <GameData header>
              <Clickable onClick={toggleModal}>
                {formatDate(localStartDate, 'ddd')} - {localStartDate} @ {localStartTime}
              </Clickable>
              <GameId>({timezoneAbbr})</GameId>
              <Tooltip title={gameIdWithIteration} arrow>
                <GameId>{gameIdWithIteration}</GameId>
              </Tooltip>
            </GameData>

            <RightContent>
              {published && (
                <Tooltip
                  title="This game is available for self-assignment"
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                >
                  <RowIcon src={publishedPath} width="13" height="10" alt="published" />
                </Tooltip>
              )}
              {(cancelledOrPostponedGame && (
                <CancelledOrPostponedStatus>{gameStatus}</CancelledOrPostponedStatus>
              )) || <Status>{gameStatus}</Status>}
            </RightContent>
          </Header>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <GameData>
            <GameDetails field={field} location={location} field_name={field_name} />
          </GameData>
        </Col>
      </Row>
      <Row>
        <TeamCol>
          <GameData>
            <GameTeam team_home={team_home} team_visitor={team_visitor} />
          </GameData>
        </TeamCol>
      </Row>
      <Row>
        <Col xs={12}>
          <Header>
            <GameData lastDataRow>
              <GameLevelAndType game_level={game_level} division={division} />
            </GameData>
            {isGameComplete && (
              <GameReportAction
                isGameAssessor={isGameAssessor}
                gameOfficial={gameOfficial}
                isUserAssignmentStatusAccepted={isUserAssignmentStatusAccepted}
                modalStatus={modalStatus}
                allowedToSeeGameReports={allowedToSeeGameReports}
                isGamesEventGroupAdmin={isGamesEventGroupAdmin}
                isGamesEventAdminOrAssignor={isGamesEventAdminOrAssignor}
                reportSettings={reportSettings}
                getGameStatus={getGameStatus}
                status={status}
                onCrewVerificationClick={onCrewVerificationClick}
                id={id}
                notes={notes}
                onGamesReportClick={onGamesReportClick}
                gameReportSyncLock={gameReportSyncLock}
                onIncidentReportClick={onIncidentReportClick}
                eventsAssessmentSettings={eventsAssessmentSettings}
                onAssessmentReportClick={onAssessmentReportClick}
                reportSubmittedStatus={reportSubmittedStatus}
                hasGameReports={hasGameReports}
                incident_report_display={incident_report_display}
                assessment_report_display={assessment_report_display}
                display_id={display_id}
                game_level={game_level}
              />
            )}
          </Header>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(GameInfo);
