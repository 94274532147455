import { compose, withState, withHandlers, lifecycle, pure, withProps } from 'recompose';
import moment from 'moment';

export default compose(
  withState('showModal', 'setShowModal', false),
  withState('showNotificationsMenu', 'setShowNotificationsMenu', false),
  withState('showAssignmentWarningsMenu', 'setShowAssignmentWarningsMenu', false),
  withState('showIncidentReportsModal', 'setShowIncidentReportsModal', false),
  withProps({
    startAndEndDates: date => {
      const [month, year] = date.format('MM-YYYY').split('-');
      const start_date = moment([year, month - 1]);
      return {
        start_date,
        end_date: moment(start_date).endOf('month')
      };
    }
  }),
  withHandlers({
    onDocumentClick: ({
      showModal,
      setShowModal,
      showNotificationsMenu,
      setShowNotificationsMenu
    }) => () => {
      if (showModal) setShowModal(false);
      if (showNotificationsMenu) setShowNotificationsMenu(false);
    },
    handleModalClick: ({
      setShowModal,
      setShowNotificationsMenu,
      showNotificationsMenu,
      assignmentWarnings,
      clearAssignmentWarnings,
      fetchActiveUserInfo,
      userId,
      showModal
    }) => event => {
      event.nativeEvent.stopImmediatePropagation();
      setShowModal(s => !s);
      if (showModal) fetchActiveUserInfo({ userId });
      if (showNotificationsMenu) setShowNotificationsMenu(false);
      if (assignmentWarnings.length) clearAssignmentWarnings();
    },
    handleNotificationsClick: ({
      setShowNotificationsMenu,
      setShowModal,
      showModal,
      assignmentWarnings,
      clearAssignmentWarnings
    }) => event => {
      if (event) event.nativeEvent.stopImmediatePropagation();
      setShowNotificationsMenu(s => !s);
      if (showModal) setShowModal(false);
      if (assignmentWarnings.length) clearAssignmentWarnings();
    },
    handleCalendarClick: ({
      showModal,
      setShowModal,
      showNotificationsMenu,
      setShowNotificationsMenu
    }) => event => {
      if (event) event.nativeEvent.stopImmediatePropagation();
      if (showModal) setShowModal(false);
      if (showNotificationsMenu) setShowNotificationsMenu(false);
    }
  }),
  lifecycle({
    componentDidMount() {
      const { onDocumentClick } = this.props;
      document.addEventListener('click', onDocumentClick);
    },
    componentWillUnmount() {
      const { onDocumentClick } = this.props;
      document.removeEventListener('click', onDocumentClick);
    }
  }),
  pure
);
