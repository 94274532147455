import { compose, pure, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  updateEvent,
  fetchEventGameLevels as fetchEventGameLevelsAction
} from '../../../../core/Events';
import { getUser } from '../../../../core/Auth/selectors';
import { getEventInfo } from '../../../../core/Events/selectors';

const mapStateToProps = state => ({
  getEventInfo: getEventInfo(state),
  getUser: getUser(state)
});

const mapDispatchToProps = dispatch => ({
  updateFullEvent: info => dispatch(updateEvent(info)),
  fetchEventGameLevels: payload => dispatch(fetchEventGameLevelsAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  lifecycle({
    componentWillMount() {
      const {
        match: {
          params: { eventId }
        }
      } = this.props;
      this.props.fetchEventGameLevels({ eventId });
    }
  }),
  withHandlers({
    onSubmit: ({ updateFullEvent }) => info => {
      const fullEvent = {
        info
      };
      updateFullEvent(fullEvent);
    }
  }),
  pure
);
