import { equals, find } from 'ramda';
import { createSelector } from 'reselect';
import { getUserGroups } from '../Auth/selectors';
import { getAllEvents, getSelectedEventPlatform } from '../Events/selectors';

export const getGames = ({ games }) => games.list;

export const getGameMeta = ({ games: { meta } }) => meta;

export const getPaginationPage = ({ games: { paginationPage } }) => paginationPage;

export const getTotalGameCount = createSelector(
  getGameMeta,
  ({ total_count: totalCount }) => totalCount
);

export const getLimit = createSelector(getGameMeta, ({ limit }) => limit);

export const getGameById = (state, gameID) =>
  createSelector(
    getGames,
    find(({ id }) => equals(id, gameID))
  )(state);

export const getGamesEventId = ({ games }) => games.selectedGamesEvent.id;

export const getCalendarEventId = ({ games }) =>
  games.calendarGame && games.calendarGame.game_level && games.calendarGame.game_level.event.id;

export const getCurrentGamesEventId = ({
  games: {
    current: { game_level }
  }
}) => game_level && game_level.event_id;

export const getCurrentGameEventReportSettings = ({ games }) =>
  games.calendarGame &&
  games.calendarGame.game_level &&
  games.calendarGame.game_level.event &&
  games.calendarGame.game_level.event.report_settings;

export const getCalendarGameLevels = ({ games }) =>
  games.calendarGame && [games.calendarGame.game_level];

export const getGamesEvent = () =>
  createSelector(getAllEvents, getGamesEventId, (events, eventId) =>
    events.find(event => Number(event.id) === Number(eventId))
  );

export const getCalendarEventSport = ({ games }) =>
  games.calendarGame &&
  games.calendarGame.game_level &&
  games.calendarGame.game_level.event &&
  games.calendarGame.game_level.event.sport_id;

export const getGamesEventCrewLabels = ({ games }) => games.gamesEventCrewLabels;

export const getCurrentGame = ({ games: { current } }) => current;

export const getCurrentGameCrewReport = ({ games: { current } }) => {
  return current && current.crewVerificationReports;
};

export const getCurrentGameCrewNotes = ({ games: { current } }) => {
  return current && current.crewVerificationNotes;
};

export const getCurrentCopiedGameFlag = ({ games }) => {
  return games.copiedGameIdCorrectFlag;
};

export const isGamesEventGroupAdmin = () =>
  createSelector(getUserGroups, getSelectedEventPlatform, (groups, platform) =>
    groups.includes(platform)
  );

export const getAssignmentNote = ({ games }) => games.assignmentNote;

export default getGames;
