import React from 'react';

import { NavLink } from 'react-router-dom';
import Flex from '../../Flex';
import LoaderWrapper from '../../LoaderWrapper';
import { Checkbox, Field, EmptyMessage, MainWrapper } from './styled-components';

import { HEIGHTS, COLORS, ICONS } from '../../../../utils/styles';
import Icon from '../../Icon';
import * as paths from '../../../../core/paths';

import { sInterpolator as routeBuilder } from '../../../../utils/helpers';
import { setReturnUrl } from '../../../../utils/auth';

const UserList = function ({
  users,
  isFetching,
  theme,
  handleCheckboxClick,
  checkedUsers,
  handleSelectAll,
  isNotAllEvents,
  editButtonClicked
}) {
  const largeFlexColumns = ['username', 'primary_email', 'categories', 'eventName'];
  const multiValueColumns = ['categories', 'eventName'];
  const headings = [
    {
      name: 'SO Role',
      id: 'role',
      showColumn: true
    },
    {
      name: 'Event Name',
      id: 'eventName',
      showColumn: !isNotAllEvents
    },
    {
      name: 'SO UserName',
      id: 'username',
      showColumn: true
    },
    {
      name: 'Last Name',
      id: 'last_name',
      showColumn: true
    },
    {
      name: 'First Name',
      id: 'first_name',
      showColumn: true
    },
    {
      name: 'Age',
      id: 'age',
      showColumn: true
    },
    {
      name: 'Gender',
      id: 'gender',
      showColumn: true
    },
    {
      name: 'Categories',
      id: 'categories',
      showColumn: isNotAllEvents
    },
    {
      name: 'Rank',
      id: 'rank',
      showColumn: isNotAllEvents
    },
    {
      name: 'Cert.',
      id: 'cert',
      showColumn: true
    },
    {
      name: 'City',
      id: 'city',
      showColumn: true
    },
    {
      name: 'State',
      id: 'state',
      showColumn: true
    },
    {
      name: 'Zip Code',
      id: 'zip',
      showColumn: true
    },
    {
      name: 'Primary Email',
      id: 'primary_email',
      showColumn: true
    },
    {
      name: 'Primary Phone',
      id: 'phone',
      showColumn: true
    },
    {
      name: 'Date added to SO',
      id: 'dateAdded',
      showColumn: true
    }
  ];

  const handleClick = user => {
    const currentUrl = window.location.pathname + window.location.search;
    setReturnUrl({ path: currentUrl });
    editButtonClicked(user);
  };
  return (
    <LoaderWrapper isFetching={isFetching}>
      <MainWrapper
        style={{
          width: '100%',
          overflow: 'auto',
          height: '100%'
        }}
      >
        <Flex
          bgImage="linear-gradient(to top, #05579d, #022151)"
          height={HEIGHTS.eventFilterBar}
          padding="0 36px"
          peopleTab
          noShrink
        >
          <Field key={0} header justifyContent="center">
            Edit
          </Field>
          <Checkbox
            type="checkbox"
            onChange={e => handleSelectAll(e.target.checked)}
            checked={!!users.length && users.length === checkedUsers.length}
          />
          {headings &&
            headings.map(
              (heading, index) =>
                heading.showColumn && (
                  <Field
                    key={index}
                    header
                    flex={largeFlexColumns.includes(heading.id) ? 10 : 6}
                    justifyContent="center"
                  >
                    {heading.name}
                  </Field>
                )
            )}
        </Flex>
        {users.length ? (
          <Flex direction="column" full theme={theme.row.wrapper} peopleTab>
            {users.map((user, index) => (
              <Flex
                height="80px"
                bgImage="linear-gradient(to top, #f4f8fb, #ffffff)"
                justifyContent="space-between"
                padding="0 36px"
                fullWidth
                noShrink
                theme={theme.row.item}
                key={index}
              >
                <NavLink
                  to={routeBuilder({ userIdx: 0, userId: user.id }, paths.PROFILE_INFORMATION)}
                  className="paymentInfo"
                >
                  <Icon
                    width={20}
                    height={14}
                    icon={ICONS.EDIT}
                    color={COLORS.denimBlue}
                    clickable
                    onClick={() => {
                      handleClick(user);
                    }}
                  />
                </NavLink>
                <Checkbox
                  type="checkbox"
                  onChange={e =>
                    handleCheckboxClick({
                      id: user.id,
                      checked: e.target.checked
                    })
                  }
                  checked={checkedUsers.includes(user.id)}
                />
                {headings.map(
                  (heading, i) =>
                    heading.showColumn && (
                      <Field
                        key={i}
                        originalCased
                        flex={largeFlexColumns.includes(heading.id) ? 10 : 6}
                        justifyContent="center"
                        isEventName={largeFlexColumns.includes(heading.id)}
                      >
                        {multiValueColumns.includes(heading.id) && user[heading.id].length > 120 ? (
                          <p
                            style={{
                              'align-self': 'flex-start',
                              width: '100%',
                              padding: '0px 10px'
                            }}
                          >
                            {user[heading.id]}
                          </p>
                        ) : (
                          <p
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              padding: '0px 10px'
                            }}
                          >
                            {user[heading.id]}
                          </p>
                        )}
                      </Field>
                    )
                )}
              </Flex>
            ))}
          </Flex>
        ) : (
          <Flex fullWidth>
            <EmptyMessage>No Users available</EmptyMessage>
          </Flex>
        )}
      </MainWrapper>
    </LoaderWrapper>
  );
};

export default UserList;
