import styled from 'styled-components';

import { pxToRem } from '../../../../../utils/helpers';

const Container = styled.div`
  padding: ${pxToRem('15px')};
  width: 44%;
`;

export default Container;
