import { compose, pure, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getBannerNotifications } from '../../../core/Notifications/selectors';
import { NOTIFICATION_STATUS, NOTIFICATION_TYPES } from '../../../constants';
import {
  deleteNotification as deleteNotificationAction,
  fetchBannerNotifications as fetchBannerNotificationsAction,
  updateNotification as updateNotificationAction
} from '../../../core/Notifications';
import {
  getSelectedRole,
  isAnyEventAdmin,
  isAnyEventAssignor,
  isAnyEventOfficialOrAssessor,
  isFundingAdmin,
  isGroupAdmin,
  isSuperAdmin
} from '../../../core/Auth/selectors';
import { SELECTABLE_ROLES } from '../../components/Header/utils';
import { storeSelectedRole } from '../../../core/Auth';

const mapStateToProps = state => ({
  notifications: getBannerNotifications(state),
  isSuperAdmin: isSuperAdmin(state),
  assignor: isAnyEventAssignor()(state),
  admin: isAnyEventAdmin()(state),
  groupAdmin: isGroupAdmin(state),
  officialAssessor: isAnyEventOfficialOrAssessor()(state),
  fundingAdmin: isFundingAdmin(state),
  selectedRole: getSelectedRole(state),
  user: state.user.profileMeta
});
const mapDispatchToProps = dispatch => ({
  fetchBannerNotifications: payload => dispatch(fetchBannerNotificationsAction(payload)),
  updateNotification: payload => dispatch(updateNotificationAction(payload)),
  deleteNotification: payload => dispatch(deleteNotificationAction(payload)),
  persistRole: payload => dispatch(storeSelectedRole(payload))
});

export default compose(
  withState('showBannerForGamesUnscheduled', 'setShowBannerForGamesUnscheduled', true),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    bannerClose: ({ deleteNotification }) => async notificationsIds => {
      deleteNotification({ id: notificationsIds });
    }
  }),
  lifecycle({
    componentDidMount() {
      const {
        isSuperAdmin: superAdmin,
        groupAdmin,
        admin,
        assignor,
        persistRole,
        selectedRole,
        fetchBannerNotifications
      } = this.props;

      fetchBannerNotifications({
        filters: {
          alert_type: NOTIFICATION_TYPES.game_unscheduled,
          status: NOTIFICATION_STATUS.action_required
        }
      });

      if (!selectedRole)
        persistRole({
          role:
            superAdmin || groupAdmin || admin || assignor
              ? SELECTABLE_ROLES.ADMIN_OR_ASSIGNOR
              : SELECTABLE_ROLES.OFFICIAL_OR_ASSESSOR
        });
    }
  }),
  pure
);
