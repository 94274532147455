import React from 'react';
import Enhancer from '../../containers/PaymentProfile';
import PayerProfile from '../Profile/PaymentMethod/Payer';
import PayeeProfile from '../Profile/PaymentMethod/Payee';

export const PaymentProfile = ({
  fetchPayProfilePayee,
  isAdmin,
  isFundingAdmin,
  isAnyEventAdmin,
  isSuperAdmin
}) => (
  <>
    {fetchPayProfilePayee && <PayeeProfile />}
    {(isAdmin || isFundingAdmin || isAnyEventAdmin || isSuperAdmin) && <PayerProfile />}
  </>
);

export default Enhancer(PaymentProfile);
