import React from 'react';
import PropTypes from 'prop-types';
import { propEq, always, prop } from 'ramda';
import MaterialDropableContainer from '../../controls/MaterialDropableContainer';
import { ICONS } from '../../../utils/styles';
import ItemContainer, {
  ItemsContainer,
  Wrapper,
  FieldError,
  LeftDropDownError
} from './styled-components';
import { HelpText } from '../Event/Categories/styled-components';

export { LeftDropDownError };

export const DropDownItem = ({ name, value, theme, onItemSelected }) => (
  <ItemContainer theme={theme} onClick={() => onItemSelected(value)}>
    {name}
  </ItemContainer>
);

DropDownItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]).isRequired,
  onItemSelected: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired
};

const MaterialDropDown = ({
  typeahead,
  updateFilter,
  input: { onChange, value },
  options,
  placeholder,
  disabled,
  disabledBlue,
  theme,
  focus,
  blur,
  fieldName,
  meta,
  showError,
  fieldError: ErrorHolder,
  helpText,
  style,
  onInputChange,
  ...rest
}) => (
  <Wrapper theme={theme.wrapper} style={style}>
    {helpText && <HelpText>{helpText}</HelpText>}
    <MaterialDropableContainer
      typeahead={typeahead}
      disabled={disabled}
      disabledBlue={disabledBlue}
      focus={() => focus(fieldName)}
      blur={() => blur(fieldName)}
      fieldName={fieldName}
      theme={theme.dropableContainer}
      showError={showError}
      meta={meta}
      content={({ onChange: selectValue }) => (
        <ItemsContainer theme={theme.itemsContainer}>
          {options.map(({ name, value: itemValue }) => (
            <DropDownItem
              key={itemValue}
              name={name}
              theme={theme.dropdownItem}
              value={itemValue}
              onItemSelected={() => {
                selectValue(itemValue);
                if (onInputChange) onInputChange(itemValue);
              }}
            />
          ))}
        </ItemsContainer>
      )}
      icon={ICONS.CHEVRON_DOWN}
      onChange={onChange}
      updateFilter={updateFilter}
      value={value}
      valueRenderer={val => prop('name', options.find(propEq('value', val)))}
      placeholder={placeholder}
      {...rest}
    />
    {theme.error && showError && meta.error && <ErrorHolder>{meta.error}</ErrorHolder>}
  </Wrapper>
);

MaterialDropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
    })
  ),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired
  }),
  helpText: PropTypes.string,
  typeahead: PropTypes.bool,
  updateFilter: PropTypes.func,
  theme: PropTypes.shape({
    wrapper: PropTypes.string.isRequired,
    dropableContainer: PropTypes.object.isRequired,
    itemsContainer: PropTypes.string.isRequired,
    dropdownItem: PropTypes.string.isRequired
  }),
  focus: PropTypes.func,
  blur: PropTypes.func,
  fieldName: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }),
  showError: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledBlue: PropTypes.bool,
  fieldError: PropTypes.func
};

MaterialDropDown.defaultProps = {
  options: [],
  placeholder: '',
  input: {
    value: null,
    onChange: always(undefined)
  },
  theme: {
    wrapper: '',
    dropableContainer: {
      clickable: '',
      caption: '',
      placeholder: '',
      icon: '',
      content: '',
      label: ''
    },
    itemsContainer: '',
    dropdownItem: ''
  },
  helpText: null,
  focus: () => true,
  blur: () => true,
  meta: {},
  showError: false,
  fieldName: '',
  required: false,
  typeahead: false,
  updateFilter: () => {},
  disabled: false,
  disabledBlue: false,
  fieldError: FieldError
};

export default MaterialDropDown;
