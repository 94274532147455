import React, { useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { List, ListItem, ListItemIcon, ListItemText, Divider, Collapse } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import { userIdxGetter } from '../../../utils/auth';
import { mediaQueriesCheck, COLORS } from '../../../utils/styles';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import { StyledNavLink, CopyRight, DashboardOptions } from './styled-components';
import { ROUTESLABEL } from '../../../constants';

const useStyles = makeStyles({
  list: {
    width: 220
  },
  fullList: {
    width: 'auto'
  },
  listUl: {
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    height: '90%'
  },
  listLi: {
    paddingLeft: 0,
    paddingRight: 0
  },
  iconStyle: {
    minWidth: '50px',
    fontSize: '0.6rem'
  },
  label: {
    fontSize: '15px'
  },
  userBg: {
    background: `${COLORS.darkBlue}`,
    padding: '20px 16px',
    color: `${COLORS.white}`,
    textTransform: 'uppercase'
  },
  svgColor: { color: `${COLORS.white}` },
  dashboardStyle: {
    padding: '13px 18px',
    color: '#4d4d4d',
    marginBottom: '3px',
    cursor: 'pointer'
  },
  dashboardOptionsStyle: {
    padding: '0px'
  },
  dashboardSubStyle: {
    padding: '0px'
  },
  dashboardSelectedStyle: {
    background: `${COLORS.denimBlue}`,
    color: `${COLORS.white}`,
    '&:focus': { background: `${COLORS.denimBlue}`, color: `${COLORS.white}` }
  }
});

const LeftMenu = ({
  toggle,
  setToggle,
  toggleDrawerHandler,
  navRoutes,
  props,
  userProfile,
  onLogout,
  setIsDashboardActive,
  setOpen,
  open,
  selected,
  isDashboardActive,
  handleClick
}) => {
  const menuLabelClick = route => {
    setOpen(false);
    if (props.currentUserId !== props.userId) {
      props.fetchUserInfo({ userId: props.currentUserId });
    }
    return route.label === ROUTESLABEL.dashboard ||
      (route.label === ROUTESLABEL.help &&
        window.location.href.includes(ROUTESLABEL.dashboard.toLowerCase()))
      ? setIsDashboardActive(true)
      : setIsDashboardActive(false);
  };

  function checkMediaQuery() {
    setToggle(!!(mediaQueriesCheck.mdCheck.matches === true && toggle === true));
  }
  useEffect(() => {
    window.addEventListener('resize', checkMediaQuery);
    return () => {
      window.removeEventListener('resize', checkMediaQuery);
    };
  });

  const classes = useStyles();
  const sideList = () => (
    <div
      role="presentation"
      className={classes.list}
      onClick={toggleDrawerHandler}
      onKeyDown={toggleDrawerHandler}
    >
      <List className={classes.listUl}>
        <ListItem className={(classes.listLi, classes.userBg)} key={0}>
          <ListItemIcon className={`${classes.iconStyle} ${classes.svgColor}`}>
            <FaceIcon />
          </ListItemIcon>
          <ListItemText>{userProfile.first_name}</ListItemText>
        </ListItem>
        <Divider />
        {navRoutes.map(
          (route, index) =>
            (!route.requireAuth || props[route.requireAuth]) &&
            (route.list && route.list.length ? (
              <>
                <ListItem
                  className={`${classes.listLi} ${classes.dashboardStyle} ${
                    isDashboardActive ? classes.dashboardSelectedStyle : ''
                  }`}
                  key={index + 1}
                  onClick={e => handleClick(e, index)}
                >
                  <ListItemIcon
                    className={`${classes.iconStyle}  ${isDashboardActive ? classes.svgColor : ''}`}
                  >
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText>{route.label}</ListItemText>
                </ListItem>

                {route.list
                  .filter(item => item.value !== selected)
                  .map(({ label, onClick, to, value }) => (
                    <Collapse in={open && toggle} timeout="auto" unmountOnExit>
                      <List
                        component="div"
                        disablePadding
                        onClick={() => {
                          onClick(value);
                          menuLabelClick(route);
                        }}
                      >
                        <ListItem button className={`${classes.dashboardSubStyle}`}>
                          <DashboardOptions to={routeBuilder({ userIdx: userIdxGetter() }, to)}>
                            <ListItemText>{label}</ListItemText>
                          </DashboardOptions>
                        </ListItem>
                      </List>
                    </Collapse>
                  ))}
              </>
            ) : (
              <ListItem className={`${classes.listLi}`} key={index + 1}>
                <StyledNavLink
                  button
                  key={route.label}
                  isActive={route.isActive}
                  to={
                    route.isExternalLink
                      ? { pathname: route.to }
                      : routeBuilder(
                          { userIdx: userIdxGetter(), userId: props.currentUserId },
                          route.to
                        )
                  }
                  target={route.target}
                  onClick={() => {
                    menuLabelClick(route);
                  }}
                >
                  <ListItemIcon className={classes.iconStyle}>{route.icon}</ListItemIcon>
                  <ListItemText>{route.label}</ListItemText>
                </StyledNavLink>
              </ListItem>
            ))
        )}
        <ListItem className={`${classes.listLi}`}>
          <StyledNavLink button to="#" onClick={onLogout}>
            <ListItemIcon className={classes.iconStyle}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>SIGN OUT</ListItemText>
          </StyledNavLink>
        </ListItem>
      </List>
      <CopyRight>
        <div>
          <p>© STACK OFFICIALS.</p>
        </div>
      </CopyRight>
      <Divider />
    </div>
  );

  return (
    <Drawer open={toggle} onClose={toggleDrawerHandler}>
      {sideList('left')}
    </Drawer>
  );
};

export default LeftMenu;
