import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../Modal';
import Button from '../../Button';
import {
  Block,
  Content,
  ContentWrapper,
  ButtonsContainer,
  ButtonContainer
} from './styled-components';

const DeleteEventModal = ({ onClose, onSubmit }) => (
  <Modal title="Delete Event" onClose={onClose} background>
    <Row>
      <ContentWrapper>
        <Block xs={10} xsOffset={1} className="content">
          <Content>
            Deleting this event will permanently delete all event information, settings, and remove
            all current users.
          </Content>
          <Content>Are you sure you wish to delete this event?</Content>
        </Block>
      </ContentWrapper>
      <Block xs={12}>
        <ButtonsContainer>
          <ButtonContainer>
            <Button
              label="Yes, Delete This Event"
              onClick={onSubmit}
              width="151px"
              height="32px"
              primary
            />
          </ButtonContainer>
          <ButtonContainer>
            <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
          </ButtonContainer>
        </ButtonsContainer>
      </Block>
    </Row>
  </Modal>
);

DeleteEventModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

DeleteEventModal.defaultProps = {};

export default DeleteEventModal;
