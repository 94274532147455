import React from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import Enhancer from '../../containers/EventFilter';
import { COLORS, ICONS } from '../../../utils/styles';
import { allEventsId, EVENT_STATUS, PARAM_PREFIXES, PAYMENTS_VIEW } from '../../../constants';
import { Wrapper, FilterChevron, Header, Option, Options } from './styled-components';
import Search from '../Search';

export const EventFilter = ({
  toggleShowOptions,
  showOptions,
  events,
  selectedEvent,
  onSelectEvent,
  clearParams,
  filteredEvents,
  clickedOutside,
  setClickedOutside,
  value,
  setValue,
  searchValue,
  onSearchEvent,
  paymentsViewSelected,
  isSuperAdmin,
  isUserAddAction
}) => {
  const furtherFilteredEvents = isUserAddAction
    ? filteredEvents.filter(event => event.status === EVENT_STATUS.OPEN)
    : filteredEvents;

  let mutableSearchValue = searchValue;
  let mutableSelectedEvent = selectedEvent;
  if (isSuperAdmin && paymentsViewSelected && paymentsViewSelected === PAYMENTS_VIEW.FUNDING) {
    if (mutableSearchValue) {
      if (!furtherFilteredEvents.some(e => e.id === allEventsId))
        furtherFilteredEvents.unshift({ id: allEventsId, name: 'All Events' });
    } else if (!events.some(e => e.id === allEventsId))
      events.unshift({ id: allEventsId, name: 'All Events' });
  } else if (
    paymentsViewSelected &&
    paymentsViewSelected === PAYMENTS_VIEW.GAME_PAY &&
    mutableSelectedEvent &&
    mutableSelectedEvent.id === allEventsId
  ) {
    if (mutableSearchValue) {
      if (furtherFilteredEvents.some(e => e.id === allEventsId)) {
        furtherFilteredEvents.shift();
        mutableSearchValue = '';
        mutableSelectedEvent = null;
      }
    } else if (events.some(e => e.id === allEventsId)) {
      events.shift();
      mutableSearchValue = '';
      mutableSelectedEvent = null;
    }
  }
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        toggleShowOptions(false);
        setClickedOutside(true);
        setValue(mutableSelectedEvent && mutableSelectedEvent.name);
      }}
      display="contents"
    >
      <Wrapper
        isUserAddAction={isUserAddAction}
        onClick={() => {
          toggleShowOptions(s => !s);
          setClickedOutside(false);
        }}
        onKeyDown={event => {
          if (event.keyCode === 13) {
            toggleShowOptions(s => !s);
            setClickedOutside(false);
          }
        }}
      >
        <Header isUserAddAction={isUserAddAction}>
          <Search
            iconHeight={14}
            iconWidth={14}
            iconColor={isUserAddAction ? COLORS.black : COLORS.white}
            fieldColor={isUserAddAction ? COLORS.black : COLORS.white}
            autoComplete="off"
            onSearch={onSearchEvent}
            value={
              clickedOutside
                ? mutableSearchValue
                : value || (mutableSelectedEvent && mutableSelectedEvent.name)
            }
            placeholder="Select Event"
            showSearchEvent
            isUserAddAction={isUserAddAction}
          />
          <FilterChevron
            width={10}
            height={16}
            color={isUserAddAction ? COLORS.skyBlue : COLORS.white}
            icon={ICONS.CHEVRON_DOWN}
            isUserAddAction={isUserAddAction}
          />
        </Header>
        {showOptions && (
          <Options isUserAddAction={isUserAddAction}>
            {!mutableSearchValue && (
              <Option isUserAddAction={isUserAddAction} className="labels">
                RECENT EVENTS :
              </Option>
            )}
            {furtherFilteredEvents.length === 0 && mutableSearchValue && (
              <Option isUserAddAction={isUserAddAction} className="labels">
                NO RESULTS FOUND
              </Option>
            )}
            {(mutableSearchValue ? furtherFilteredEvents : events).map(event => (
              <Option
                isUserAddAction={isUserAddAction}
                key={event.id}
                onClick={() => {
                  onSelectEvent(event);
                  clearParams([PARAM_PREFIXES.GAMES, PARAM_PREFIXES.USERS]);
                  setClickedOutside(false);
                }}
                className={
                  event.name === mutableSelectedEvent && mutableSelectedEvent.name ? 'active' : null
                }
              >
                {event.name}
              </Option>
            ))}
          </Options>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

EventFilter.propTypes = {
  toggleShowOptions: PropTypes.func.isRequired,
  showOptions: PropTypes.bool,
  events: PropTypes.arrayOf(Object).isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  clearParams: PropTypes.func.isRequired,
  clickedOutside: PropTypes.bool,
  searchValue: PropTypes.string
};

EventFilter.defaultProps = {
  showOptions: false,
  clickedOutside: false,
  searchValue: ''
};
export default Enhancer(EventFilter);
