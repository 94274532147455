import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction || 'row'};
`;

export const ElementWrapper = styled.div`
  width: ${({ width }) => width || '25%'};
  margin: ${({ margin }) => margin && margin};
`;
