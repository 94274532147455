import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Flex from '../Flex';
import Header from './Header';
import PaginationBar from '../PaginationBar';
import theme from './styled-components';
import UserList from './UserList';
import { formatDate } from '../../../utils/parsers';
import { LICENSE_TYPES } from '../../../constants';
import Enhancer from '../../containers/People';
import { capitalize, licensesShortForm } from '../../../utils/helpers';

export const People = ({
  events,
  storePeopleEvent,
  peopleEvent,
  isFetching,
  pagination,
  filter,
  search,
  clearParams,
  pageFilter,
  sort,
  location,
  filteredEvents,
  eventSearchValue,
  userList,
  categories,
  checkedUsers,
  config,
  handleCheckboxClick,
  handleSelectAll,
  onLoadPageArgs,
  inEdit,
  setInEdit,
  editButtonClicked,
  pathUrl,
  searchParams
}) => {
  if (eventSearchValue) {
    if (!filteredEvents.some(e => e.id === '-1'))
      filteredEvents.unshift({ id: '-1', name: 'All Events' });
  } else if (!events.some(e => e.id === '-1')) events.unshift({ id: '-1', name: 'All Events' });
  const users = userList.map(user => {
    const activecert = user &&
      user.certifications &&
      user.certifications.length > 0 && [
        user.certifications
          .filter(
            certification =>
              certification &&
              certification.is_valid &&
              LICENSE_TYPES[certification.cert_type] &&
              LICENSE_TYPES[certification.cert_type].role === user.role.toLowerCase()
          )
          .sort((a, b) => a.rank - b.rank)[0]
      ];

    const cert =
      activecert &&
      activecert[0] &&
      `${licensesShortForm(activecert[0].cert_name && activecert[0].cert_name)} ${
        activecert[0].expiration_date && moment(activecert[0].expiration_date).format('YYYY')
      }`;

    return {
      id: user.id,
      role: user.role || 'N/A',
      username: user.email || 'N/A',
      last_name: user.last_name || 'N/A',
      first_name: user.first_name || 'N/A',
      age: user.age || 'N/A',
      gender: (user.gender && capitalize(user.gender)) || 'N/A',
      categories: (user.categories && user.categories.replace(/,/g, ', ')) || 'N/A',
      rank: user.rank || 'N/A',
      grade: user.grade || 'N/A',
      city: user.addresses.city || 'N/A',
      state: user.addresses.state || 'N/A',
      zip: user.addresses.postal_code || 'N/A',
      primary_email: user.contacts.primary_email || 'N/A',
      phone: user.contacts.primary_phone_number || 'N/A',
      dateAdded: formatDate(user.created_at) || 'N/A',
      eventRoleId: user.eventRoleId,
      eventName: user.eventName && user.eventName.replace(/,/g, ', '),
      cert: cert || 'N/A'
    };
  });
  const getCheckedUsers = (selectedUsers, allUsers, type) => {
    let usersArray = [];
    usersArray = selectedUsers.map(id => allUsers.find(user => user.id === id));
    if (type === 'Add Rank') {
      usersArray = usersArray.filter(user => user.role === 'Official' || user.role === 'Assessor');
    }
    return usersArray;
  };
  const isNotAllEvents = peopleEvent && peopleEvent.id !== '-1';
  return (
    <>
      {config.action.options.map(
        ({ showModal, onSubmit, component: Component, onClick, label }) =>
          showModal && (
            <Component
              key={label}
              onClose={() => onClick(false)}
              selected={getCheckedUsers(
                checkedUsers,
                users,
                label === 'Update Rank' ? 'Add Rank' : ''
              )}
              onSubmit={onSubmit}
              type="user"
              eventId={peopleEvent.id}
              categories={categories}
              peopleTab
              pagination={pagination}
              onLoadPageArgs={onLoadPageArgs}
            />
          )
      )}
      <Flex direction="column" full fullWidth>
        <Flex
          direction="column"
          full
          fullWidth
          theme={{ height: 'calc(100vh - 64px)', overflowY: 'auto' }}
        >
          <Header
            storePeopleEvent={storePeopleEvent}
            peopleEvent={peopleEvent}
            filter={filter}
            search={search}
            clearParams={clearParams}
            pageFilter={pageFilter}
            location={location}
            sort={sort}
            categories={categories}
            conditions={{ usersSelected: !!checkedUsers.length, isNotAllEvents }}
            config={config}
            isNotAllEvents={isNotAllEvents}
          />
          <UserList
            users={users}
            theme={theme}
            isFetching={isFetching}
            handleCheckboxClick={handleCheckboxClick}
            checkedUsers={checkedUsers}
            handleSelectAll={handleSelectAll}
            isNotAllEvents={isNotAllEvents}
            inEdit={inEdit}
            setInEdit={setInEdit}
            editButtonClicked={editButtonClicked}
            searchParams={searchParams}
            pathUrl={pathUrl}
          />
        </Flex>
      </Flex>
      {users && !!users.length && <PaginationBar {...pagination} />}
    </>
  );
};

People.propTypes = {
  users: PropTypes.arrayOf(Object),
  events: PropTypes.arrayOf(Object),
  storePeopleEvent: PropTypes.func.isRequired,
  peopleEvent: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  isFetching: PropTypes.bool,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number,
    limit: PropTypes.number,
    pageLinkBuilder: PropTypes.func,
    onLoadPage: PropTypes.func
  }).isRequired,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  clearParams: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired
};

People.defaultProps = {
  peopleEvent: null,
  users: [],
  events: [],
  isFetching: false
};

export default Enhancer(People);
