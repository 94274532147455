import React from 'react';

import { composeValidators, mustBeEmail, required } from '../../../utils/validations';
import { Input } from '../../global-material-components';
import { Error, FieldWrapper } from './style-components';

export default {
  fields: [
    {
      component: props => (
        <FieldWrapper>
          {' '}
          <Input
            {...props}
            type="text"
            label="Account Holder Name"
            placeholder="Account Holder Name"
          />
          {props.error && <Error>{props.error}</Error>}
        </FieldWrapper>
      ),
      name: 'name',
      validate: required
    },
    {
      component: props => (
        <FieldWrapper>
          <Input {...props} type="text" label="Email" placeholder="Email" />
          {props.error && <Error>{props.error}</Error>}
        </FieldWrapper>
      ),
      name: 'email',
      validate: composeValidators(required, mustBeEmail)
    },
    {
      component: props => (
        <FieldWrapper>
          <Input {...props} type="text" label="Account Nickname" placeholder="Account Nickname" />
          {props.error && <Error>{props.error}</Error>}
        </FieldWrapper>
      ),
      name: 'nickName',
      validate: required
    }
  ],

  initialValues: ({ user = { profile: {} } }) => ({
    accNumber: '',
    agreed: false,
    name: `${user.profile.first_name || ''} ${user.profile.last_name || ''}`,
    email: user.profile.email
  })
};
