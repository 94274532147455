import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'ramda';

import { Wrapper, PageNumberWrapper, ResultsText, DefaultChevrons } from './styled-components';

const MAX_TOTAL_PAGES_TO_DISPLAY = 11;
const MIN_INNER_PAGE = 2;

// protection incase there is no handler attached to the click event
// the pagination code is a nesting doll of command executions and wrappers so we need to extract the actual event
const onDefaultClickHandlerWrapper = handler => {
  if (handler && handler instanceof Function) {
    const handlerResponse = handler();
    if (handlerResponse) {
      onDefaultClickHandlerWrapper(handlerResponse);
    }
  }
};

const PageNumber = ({ i, page, to, onClickEventHandler }) => {
  return (
    <PageNumberWrapper
      key={i}
      to={to}
      page={page}
      className={page === i && 'active'}
      onClick={() => onClickEventHandler()}
    >
      {i}
    </PageNumberWrapper>
  );
};

PageNumber.propTypes = {
  i: PropTypes.number.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  to: PropTypes.string.isRequired
};

const PaginationBar = ({
  page: rawPage,
  totalCount,
  limit,
  pageLinkBuilder,
  showPagination = true,
  onDefaultClick
}) => {
  const page = Number(rawPage);
  const totalPageCount = Math.ceil(totalCount / limit);
  const renderPages = onRenderPagesClickParam => {
    const pageMargin = (MAX_TOTAL_PAGES_TO_DISPLAY - 3) / 2;
    const start = Math.max(
      MIN_INNER_PAGE,
      Math.min(page - pageMargin, totalPageCount - 2 * pageMargin)
    );
    const end = Math.min(totalPageCount - 1, Math.max(page + pageMargin, 2 * pageMargin + 1));

    return [
      <PageNumber
        i={1}
        page={page}
        to={pageLinkBuilder(1)}
        onClickEventHandler={() => onRenderPagesClickParam(onDefaultClick)}
      />
    ]
      .concat(start !== 2 ? <ResultsText key="...1">...</ResultsText> : [])
      .concat(
        range(start, end + 1).map(i => (
          <PageNumber
            i={i}
            page={page}
            to={pageLinkBuilder(i)}
            onClickEventHandler={() => onRenderPagesClickParam(onDefaultClick)}
          />
        ))
      )
      .concat(end !== totalPageCount - 1 ? <ResultsText key="...2">...</ResultsText> : [])
      .concat(
        totalPageCount > 1 ? (
          <PageNumber
            i={totalPageCount}
            to={pageLinkBuilder(totalPageCount)}
            page={page}
            onClickEventHandler={() => onRenderPagesClickParam(onDefaultClick)}
          />
        ) : (
          []
        )
      );
  };

  return (
    <Wrapper>
      <ResultsText>{totalCount} Total Results</ResultsText>
      {showPagination && (
        <>
          <ResultsText>|</ResultsText>
          <ResultsText>
            Showing {page} of {totalPageCount}
          </ResultsText>
          <ResultsText />
          {page > 1 ? (
            <PageNumberWrapper
              page={page}
              to={pageLinkBuilder(page - 1)}
              light="true"
              onClick={() => onDefaultClickHandlerWrapper(onDefaultClick)}
            >
              {'<<'}
            </PageNumberWrapper>
          ) : (
            <DefaultChevrons>{'<<'}</DefaultChevrons>
          )}
          {renderPages(onDefaultClickHandlerWrapper)}
          {page < totalPageCount ? (
            <PageNumberWrapper
              page={page}
              to={pageLinkBuilder(page + 1)}
              light="true"
              onClick={() => onDefaultClickHandlerWrapper(onDefaultClick)}
            >
              {'>>'}
            </PageNumberWrapper>
          ) : (
            <DefaultChevrons>{'>>'}</DefaultChevrons>
          )}
        </>
      )}
    </Wrapper>
  );
};

PaginationBar.propTypes = {
  totalCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageLinkBuilder: PropTypes.func.isRequired
};

PaginationBar.defaultProps = {
  page: 1
};

export default React.memo(PaginationBar);
