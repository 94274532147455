import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

const ErrorContainer = styled.div`
  text-align: left;
  left: 0.5rem;
  width: 100%;
  color: ${COLORS.carminePink};
  font-family: 'RobotoCondensed';
  font-size: 0.7rem;
  position: absolute;

  ${({ theme }) => theme};
`;

export default ErrorContainer;
