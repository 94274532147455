import { MODAL_STATUSES, MODAL_TYPES } from '../../../../Modals/constants';
import { GAMES_STATUSES, NOTE } from '../../../../../../../constants';
import ModalsConfig from '../../../../Modals';

export default {
  config: ({
    isGamesEventGroupAdmin,
    isGamesEventAdminOrAssignor,
    reportSettings,
    getGameStatus,
    status,
    onCrewVerificationClick,
    id,
    notes,
    onGamesReportClick,
    onIncidentReportClick,
    eventsAssessmentSettings,
    onAssessmentReportClick,
    reportSubmittedStatus,
    hasGameReports,
    incident_report_display,
    assessment_report_display,
    display_id,
    gameReportSyncLock,
    game_level,
    isGameAssessor,
    gameOfficial,
    isUserAssignmentStatusAccepted,
    modalStatus,
    allowedToSeeGameReports
  }) => {
    const { icon: crewColor, iconBG: crewBGColor } = ModalsConfig.getIconColors({
      modal: MODAL_TYPES.CVR,
      status: modalStatus
    });

    const { icon: incidentReportColor, iconBG: incidentReportBGColor } = ModalsConfig.getIconColors(
      {
        modal: MODAL_TYPES.IR,
        status: incident_report_display
      }
    );

    const {
      icon: assessmentReportColor,
      iconBG: assessmentReportBGColor
    } = ModalsConfig.getIconColors({
      modal: MODAL_TYPES.AR,
      status: assessment_report_display
    });

    let gameModalState = '';
    if (reportSubmittedStatus) {
      gameModalState = MODAL_STATUSES.COMPLETE;
    } else if (hasGameReports) {
      gameModalState = MODAL_STATUSES.PARTIAL;
    }

    const { icon: gameReportColor, iconBG: gameReportBGColor } = ModalsConfig.getIconColors({
      modal: MODAL_TYPES.GR,
      status: gameModalState
    });
    const gameStatus = getGameStatus(status);
    const cancelledOrPostponedGame =
      gameStatus === GAMES_STATUSES.CANCELLED || gameStatus === GAMES_STATUSES.POSTPONED;
    const gameOfficialAR = gameOfficial && gameOfficial.assessment_report;
    const allowedToSeeReportsForGameReport = isGamesEventGroupAdmin || isGamesEventAdminOrAssignor;

    const allowedToSeeReports =
      isGamesEventGroupAdmin || isGamesEventAdminOrAssignor || isGameAssessor || gameOfficial;
    const allowedForGameOfficialReport =
      gameOfficial && !isGamesEventAdminOrAssignor && !isGameAssessor && !isGamesEventGroupAdmin;
    const allowedToSeeCVR = reportSettings && reportSettings.allow_officials_to_checkin;
    const allowedToSeeIR = reportSettings && reportSettings.allow_officials_to_submit;
    const isAssessorAllowToSeeReport =
      isGameAssessor && isUserAssignmentStatusAccepted && !cancelledOrPostponedGame;
    const isOfficialAllowToSeeReport =
      gameOfficial && isUserAssignmentStatusAccepted && !cancelledOrPostponedGame;
    const allowedToSeeCVRNotes =
      !!notes.find(n => n.type === NOTE.TYPES.CREW_VERIFICATION) &&
      (isGamesEventGroupAdmin || isGamesEventAdminOrAssignor);

    return [
      {
        title: 'Crew Verification',
        alt: 'crew-verification',
        data_tooltip: 'C',
        showIcon:
          allowedToSeeReports &&
          allowedToSeeCVR &&
          (isGamesEventAdminOrAssignor ||
            isGamesEventGroupAdmin ||
            isOfficialAllowToSeeReport ||
            isAssessorAllowToSeeReport),
        note: allowedToSeeCVRNotes
          ? {
              style: {
                width: '0.9rem',
                height: '0.9rem',
                cursor: 'pointer',
                viewBox: '0 0 520 500',
                color: crewColor
              }
            }
          : null,
        IconColor: crewColor,
        backGround: crewBGColor,
        onClick: () => onCrewVerificationClick(id)
      },
      {
        title: 'Game Report',
        alt: 'game-reports',
        data_tooltip: 'G',
        showIcon:
          allowedToSeeGameReports &&
          (allowedToSeeReportsForGameReport || isAssessorAllowToSeeReport),
        shownNotes: null,
        IconColor: gameReportColor,
        backGround: gameReportBGColor,
        onClick: () => onGamesReportClick(id)
      },
      {
        title: 'Game Report',
        alt: 'game-reports',
        data_tooltip: 'G',
        showIcon:
          allowedForGameOfficialReport &&
          allowedToSeeGameReports &&
          isOfficialAllowToSeeReport &&
          !gameReportSyncLock,
        note: null,
        IconColor: gameReportColor,
        backGround: gameReportBGColor,
        onClick: () => onGamesReportClick(id)
      },
      {
        title: 'Incident Report',
        alt: 'incident-reports',
        data_tooltip: 'I',
        showIcon:
          allowedToSeeReports &&
          allowedToSeeIR &&
          (isGamesEventGroupAdmin ||
            isGamesEventAdminOrAssignor ||
            (gameOfficial && isUserAssignmentStatusAccepted)),
        note: null,
        IconColor: incidentReportColor,
        backGround: incidentReportBGColor,
        onClick: () => onIncidentReportClick(id)
      },
      {
        title: 'Assessment Report',
        alt: 'assessment-reports',
        data_tooltip: 'A',
        showIcon:
          allowedToSeeReports &&
          eventsAssessmentSettings &&
          eventsAssessmentSettings.allow_assessors_to_submit &&
          (isGamesEventAdminOrAssignor ||
            isGameAssessor ||
            isGamesEventGroupAdmin ||
            (gameOfficialAR &&
              gameOfficialAR.approved &&
              eventsAssessmentSettings &&
              eventsAssessmentSettings.allow_assessors_to_submit &&
              eventsAssessmentSettings.official_auto_release_assessment)),

        note: null,
        IconColor: assessmentReportColor,
        backGround: assessmentReportBGColor,
        onClick: () =>
          onAssessmentReportClick({
            eventId: game_level.event_id.toString(),
            gameId: display_id,
            assignmentOfficial: gameOfficial
          })
      }
    ];
  }
};
