import styled from 'styled-components';
import { COLORS, mediaQueries } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';
import Icon from '../../../Icon';

export const ExpandedInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ first }) => (first ? 0.4 : 1)}rem;
  border-top: ${({ border }) => (border ? `1px solid ${COLORS.lightCyanGray}` : 'none')};
  padding-bottom: 0.5rem;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpandedWrapper = MainWrapper.extend`
  padding: 0rem 0rem 0rem 0rem;
  pointer-events: auto;
  cursor: default;
  position: relative;
  .detailTab {
    color: ${COLORS.newDarkBlue};
    font-family: 'BarlowMedium';
    font-size: 16px;
    border-radius: 4px;
    min-width: auto !important;
    padding: 0px 8px;
    min-height: 35px !important;
    max-height: 35px;
    text-transform: capitalize;
    opacity: 1 !important;
  }
  .detailTab.Mui-selected {
    color: #0565d9;
    font-family: BarlowSemiBold;
    background: #e5f1ff;
  }
  .tabsHeader {
    border-bottom: 1px solid #e5ebf2;
    padding: 3px 0px 0px 15px;
    flex-wrap: wrap;
    min-height: 39px;
  }

  ${props =>
    `@media (max-width: 1410px) {
      .tabsHeader{
        min-height: ${props.expand ? '39px' : props.tabValues === 1 ? '60px' : '40px'};

      }
    };
  }`}
`;

export const SectionWrapper = MainWrapper.extend``;

export const SectionTitle = styled.div`
  height: ${pxToRem('13px')};
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('11px')};
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  color: #167cd4;
  padding-bottom: 0.3rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColWrapper = MainWrapper.extend`
  width: 100%;
  min-width: 30%;
`;

export const InfoRow = MainWrapper.extend`
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;
  flex-direction: row;
`;

export const InfoLabel = styled.span`
  opacity: 0.72;
  font-family: 'Barlow';
  font-size: ${pxToRem('13px')};
  letter-spacing: -0.3px;
  color: ${({ color }) => color || '#3D4956'};
  flex: 0.25;
  padding-right: 10px;
  text-align: right;
  align-self: center;
  ${mediaQueries.xs(`
  flex: 0.25;
    `)}
`;

export const InfoValue = styled.div`
  font-family: 'BarlowMedium';
  font-size: ${({ fontSize }) => fontSize || `${pxToRem('13px')}`}
  text-align: left;
  text-transform:  ${({ label }) => (label === 'Email' ? 'none' : 'capitalize')};
  flex: 0.75;
  color: ${({ color }) => color || '#0d194f'};
  display: inline-flex;
  flex-wrap: wrap;
  word-break: break-word;
  align-items:center;
  ${mediaQueries.xs(`
  flex: 0.75;
  `)}
  ${`@media (max-width: 540px) {
    overflow-x: auto;
  }`}
`;

export const ExpandButton = styled.button`
  border: 0px;
  background: #fff;
  color: ${COLORS.newDarkBlue};
  font-family: BarlowSemiBold;
  font-size: 16px;
  display: flex;
  position: absolute;
  padding: 4px 5px;
  cursor: pointer;
  right: 15px;
  top: ${({ expand }) => (expand ? '8px' : '8px')};
  ${props =>
    `@media (max-width: 1410px) {
      right: 8px;
      font-size: 13px;
      top:${props.expand ? '11px' : '10px'};
  }`}
`;

export const StatusIcon = styled(Icon)`
  margin: 0;
  position: relative;
  top: ${pxToRem('-3px')};
  right: ${pxToRem('0px')};
  color: ${COLORS.skyBlue};
`;

export const StatusIconContainer = styled.div`
  width: ${pxToRem('16px')};
  height: ${({ height }) => height || pxToRem('16px')};
  overflow: visible;
  border-radius: 50%;
  padding-right: ${({ paddingRight }) => paddingRight || pxToRem('10px')};
  margin: 1px 0px 0px 0px;
`;

export const Uppercase = styled.span`
  text-transform: capitilized !important;
  word-break: ${({ wordbreak }) => (wordbreak ? `break-word` : `normal`)};
  display: inline-block;
  padding: 0px !important;
`;

export const TimezoneDropdownWrapper = styled.div`
  pointer-events: auto;
  position: absolute;
  right: 15px;
  top: ${({ expand }) => (expand ? '51px' : '51px')};
  z-index: 1;
  display: flex;
  .infoTooltip {
    display: flex;
    padding: 0px 10px;
    width: 1rem;
    background: #fff;
  }
  ${props =>
    `@media (max-width: 1410px) {
      right: 8px;
       top:${props.expand ? '50px' : '52px'};
      .infoTooltip {padding:2px 0px 0px 0px; margin:0px 8px;}
   }`}
`;
