import styled from 'styled-components';

import Icon from '../Icon';
import { HEIGHTS, COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: ${pxToRem('278px')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${COLORS.white};
  background: ${COLORS.denimBlue};
  min-height: ${HEIGHTS.eventNavigationBar};
  ${mediaQueries.md(` {
    width:100%;
  }`)}
  ${props =>
    props.isUserAddAction &&
    `
    color: ${COLORS.black};
    background:  ${COLORS.white};
    align-items: flex-start;
    width: 100%;
  `}
`;

export const Header = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  padding-left: ${pxToRem('34px')};
  width: 100%;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.white};
  background: ${COLORS.denimBlue};
  height: ${HEIGHTS.eventNavigationBar};

  ${props =>
    props.isUserAddAction &&
    `
    color: ${COLORS.black};
    background:  ${COLORS.white};
    padding-left: ${pxToRem('0')};
    width: 100%;
  `}
`;

export const FilterLabel = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
`;

export const FilterChevron = styled(Icon)`
  margin-right: ${pxToRem('17px')};
  ${props =>
    props.isUserAddAction &&
    `
   margin-right: ${pxToRem('13px')};
  `}
`;

export const Option = styled.div`
  z-index: 100000;
  box-sizing: border-box;
  padding: 0.5rem 0 0.5rem 1rem;
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('13px')};
  flex: none;
  color: ${COLORS.white};
  background: ${COLORS.denimBlue};
  min-height: 2rem;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:hover {
    background: ${COLORS.darkCerulean};
  }

  &.active {
    background: ${COLORS.darkCerulean};
  }

  &.labels {
    color: ${COLORS.darkCerulean};
    font-weight: bold;

    &:hover {
      background: ${COLORS.denimBlue};
      cursor: auto;
    }
  }
  ${props =>
    props.isUserAddAction &&
    `
    color: ${COLORS.black};
    background:  ${COLORS.white};
    max-width: none;
    padding: 0 0.5rem 0 0;
    justify-content: space-between;
    align-items: center;
    padding-left: 6px;
    &:hover {
      background: ${COLORS.lightGray};
    }
    &.active {
      background: ${COLORS.white};
    }
    &.labels {
      color: ${COLORS.black};
      font-weight: bold;

      &:hover {
        background: ${COLORS.white};
        cursor: auto;
      }
    }
  `}
`;

export const Options = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 22rem;
  width: 100%;
  max-width: ${pxToRem('260px')};
  overflow: auto;
  top: ${HEIGHTS.eventNavigationBar};
  z-index: 100000;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: ${COLORS.darkCerulean};
  &::-webkit-scrollbar {
    width: ${pxToRem('7px')};
    height: ${pxToRem('5px')};
  }

  &::-webkit-scrollbar-thumb {
    height: ${pxToRem('50px')};
    background: ${props => (props.isUserAddAction ? COLORS.lightCyanGray : COLORS.darkCerulean)};
  }

  &::-webkit-scrollbar-track-piece {
    background: ${props => (props.isUserAddAction ? COLORS.white : COLORS.denimBlue)};
  }

  &.side-list::-webkit-scrollbar-track-piece {
    background: ${props => (props.isUserAddAction ? COLORS.lightCyanGray : COLORS.darkCerulean)};
  }
  ${props =>
    props.isUserAddAction &&
    `
    color: ${COLORS.black};
    background:  ${COLORS.white};
    max-width: none;
  `}
`;
