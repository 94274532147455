import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { Button } from '@material-ui/core';
import { ViewMore, ModalWrapper } from './styled-component';
import Banner from '../Banner';
import Modal from '../Modal';
import NotificationRow from './NotificationRows';

const BannerUnsheduledNotifications = ({
  setShowBannerForGamesUnscheduled,
  showBannerForGamesUnscheduled,
  notifications,
  bannerClose,
  bannerHeading
}) => {
  const [viewBannerModal, setViewBannerModal] = useState(false);
  let bannerInfo =
    notifications &&
    notifications.reduce((acc, n) => {
      acc[n.source_id] = {
        source_id: n.source_id,
        game: n.data,
        event_id: n.event_id,
        id: n.id
      };
      return acc;
    }, {});
  bannerInfo = Object.values(bannerInfo);
  return (
    <>
      {bannerInfo && bannerInfo.length ? (
        <Banner
          onClose={() => {
            const notificationIds = notifications.map(n => n.id);
            setShowBannerForGamesUnscheduled(null);
            bannerClose(notificationIds);
          }}
          showBanner={showBannerForGamesUnscheduled}
        >
          <Row style={{ paddingLeft: '20px' }}>
            <NotificationRow bannerInfo={bannerInfo.slice(0, 2)} bannerHeading={bannerHeading} />
            <ViewMore>
              {bannerInfo.length > 2 && (
                <Button className="ViewMoreButton" onClick={() => setViewBannerModal(true)}>
                  Click to view more notifications
                </Button>
              )}
            </ViewMore>
            {viewBannerModal && (
              <Modal
                title="Unscheduled Notifications"
                onClose={() => setViewBannerModal(false)}
                titleStyle={{
                  fontSize: '16px',
                  marginRight: '50px'
                }}
              >
                <ModalWrapper>
                  <NotificationRow bannerInfo={bannerInfo} bannerHeading={bannerHeading} />
                </ModalWrapper>
              </Modal>
            )}
          </Row>
        </Banner>
      ) : (
        ''
      )}
    </>
  );
};

BannerUnsheduledNotifications.propTypes = {
  setShowBannerForGamesUnscheduled: PropTypes.func.isRequired,
  showBannerForGamesUnscheduled: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  bannerClose: PropTypes.func.isRequired,
  bannerHeading: PropTypes.string.isRequired
};

export default BannerUnsheduledNotifications;
