import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import config from './config';

export const TableContainer = styled.div`
  width: 100%;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const TableWrapper = styled(Row)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0 !important;
  width: 100%;
`;

export const Label = styled.div`
  display: inline-block;
  padding-top: ${({ name }) => (config.sortColumn.includes(name) ? '10px' : '0px')};
`;
