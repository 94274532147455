import { call, put, takeLatest } from 'redux-saga/effects';
import { compose } from 'recompose';
import Api from '../../utils/api-wrapper';
import {
  addBillingCustomer,
  CREATE_BILLING_CUSTOMER,
  FETCH_BILLING_CUSTOMER,
  setBillingCustomers,
  setIsFeching,
  setGameBillingCustomer,
  setTaxBillingCustomer,
  FETCH_GROUP_BILLNIG_CUSTOMER,
  UPDATE_BILLING_CUSTOMER,
  setGamePayerBillingCustomer,
  setTaxPayerBillingCustomer,
  setIsFechingPayer,
  setIsFechingCustomer
} from './index';
import { addNotifications } from '../Notifications';
import { EVENT_BILLING_CUSTOMER_TYPE } from '../../constants';
import { mergeData } from '../../utils/parsers';

export function* fetchBillingCustomerList({ payload: { platformId } }) {
  try {
    yield put(setIsFeching(true));
    const response = yield call(Api.fetchBillingCustomers, { platformId });
    if (response) {
      yield put(setBillingCustomers(response));
    }
  } catch (error) {
    yield put(addNotifications(error.message));
  } finally {
    yield put(setIsFeching(false));
  }
}

export function* createBillingCustomerSaga({ payload: { data, platformId } }) {
  try {
    yield put(setIsFechingCustomer(true));
    const response = yield call(Api.createBillingCustomers, { data, platformId });
    if (response) {
      yield put(addBillingCustomer(response));
      yield put(addNotifications([{ type: 'success', message: 'Successfully added' }]));
      yield put({ type: FETCH_BILLING_CUSTOMER, payload: { platformId } });
    }
  } catch (error) {
    yield put(addNotifications(error.message));
  } finally {
    yield put(setIsFechingCustomer(false));
  }
}

export function* fetchGroupBillingCustomersSaga({ payload }) {
  try {
    yield put(setIsFechingPayer(true));
    const { platformId } = payload;
    const gameBillingCustomer = yield call(Api.fetchGroupBillingCustomer, {
      platformId,
      customerType: EVENT_BILLING_CUSTOMER_TYPE.GAME_PAYER
    });
    yield put(compose(setGameBillingCustomer, mergeData)(gameBillingCustomer));

    const taxBillingCustomer = yield call(Api.fetchGroupBillingCustomer, {
      platformId,
      customerType: EVENT_BILLING_CUSTOMER_TYPE.BATCH_PAYER
    });
    yield put(compose(setTaxBillingCustomer, mergeData)(taxBillingCustomer));
  } catch (error) {
    yield put(addNotifications(error.message));
  } finally {
    yield put(setIsFechingPayer(false));
  }
}

export function* updateBillingCustomersSaga({ payload: { data, platformId, customerId } }) {
  try {
    yield put(setIsFechingPayer(true));
    const response = yield call(Api.updateBillingCustomer, { data, platformId, customerId });
    if (response) {
      yield put(setGamePayerBillingCustomer(response.data));
      yield put(setTaxPayerBillingCustomer(response.data));
      yield put(addNotifications([{ type: 'success', message: 'Successfully updated' }]));
    }
  } catch (error) {
    yield put(addNotifications(error.message));
  } finally {
    yield put(setIsFechingPayer(false));
  }
}

export const certSagas = [
  takeLatest(CREATE_BILLING_CUSTOMER, createBillingCustomerSaga),
  takeLatest(FETCH_BILLING_CUSTOMER, fetchBillingCustomerList),
  takeLatest(FETCH_GROUP_BILLNIG_CUSTOMER, fetchGroupBillingCustomersSaga),
  takeLatest(UPDATE_BILLING_CUSTOMER, updateBillingCustomersSaga)
];

export default certSagas;
