import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';
import { COLORS } from '../../../utils/styles';

export const ColHeader = styled(Col)`
  color: ${COLORS.denimBlue};
  font-size: 0.85rem;
  font-weight: bold;
  font-stretch: condensed;
`;

export const ColHeaderGame = styled(Col)`
  color: ${COLORS.white};
  font-size: 0.85rem;
  font-weight: bold;
  font-stretch: condensed;
  text-align: ${({ textAlign }) => textAlign || 'center'};
`;

export const HeaderRow = styled(Row)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const Container = styled(Col)`
  max-width: 90%;
  text-align: ${({ textAlign }) => textAlign || 'center'};
`;

export const Input = styled.input`
  width: 80%;
`;

export const ValueColContainerCenter = styled(Col)`
  max-width: 90%;
  text-align: center;
`;

export const ButtonContainerCrew = styled(Col)`
  max-width: 90%;
  text-align: center;
`;
