import { call, put, takeLatest, select } from 'redux-saga/effects';
import { compose } from 'ramda';
import {
  FETCH_EVENT_TEAMS,
  setEventTeams,
  FETCH_EVENT_UNIQUE_TEAMS,
  setEventUniqueTeams,
  setIsFetching
} from './index';

import { getTeamLimit } from './selectors';
import Api from '../../utils/api-wrapper';
import { errorProgressDecorate } from '../Progress/helper';
import { dataWithPagination } from '../../utils/parsers';

export function* processfetchEventTeams({
  payload: { eventId, sort, page, filters, toCSV = false, resolve = () => {} }
}) {
  const limit = yield select(getTeamLimit);
  const response = yield call(Api.fetchEventTeams, {
    id: eventId,
    filters,
    sortingValue: sort,
    page,
    limit,
    toCSV
  });

  if (!toCSV) {
    yield compose(
      put,
      setEventTeams,
      values => ({
        ...values,
        meta: {
          ...values.meta,
          limit
        }
      }),
      dataWithPagination
    )(response);
  } else yield resolve(response);
}

export function* processfetchEventUniqueTeams({ payload: { eventId } }) {
  const response = yield call(Api.fetchEventUniqueTeams, {
    id: eventId
  });
  yield compose(
    put,
    setEventUniqueTeams,
    values => ({
      ...values,
      meta: {
        ...values.meta
      }
    }),
    dataWithPagination
  )(response);
}

export function* fetchEventTeams(params) {
  yield errorProgressDecorate(processfetchEventTeams, params, setIsFetching);
}

export function* fetchEventUniqueTeams(params) {
  yield errorProgressDecorate(processfetchEventUniqueTeams, params, setIsFetching);
}

export const teamsSagas = [
  takeLatest(FETCH_EVENT_TEAMS, fetchEventTeams),
  takeLatest(FETCH_EVENT_UNIQUE_TEAMS, fetchEventUniqueTeams)
];

export default teamsSagas;
