import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import Toggle from 'react-toggle';
import '../../toggle.css';

import LoaderWrapper from '../../../../LoaderWrapper';
import Flex from '../../../../Flex';
import Icon from '../../../../Icon';
import CollapseableHeader from '../../../../CollapseableHeader';
import Button from '../../../../Button';
import InfoField from '../../../../Reusable/InfoField';
import { Header, Body, FieldWrapper, Label } from '../../styled-components';
import { COLORS, ICONS } from '../../../../../../utils/styles';
import config, { generateGameInfo, generateReportInfo } from '../config';
import { FROM_GAMES } from '../../../../../../core/Modals/constants';
import { addCountToDisplayId } from '../../../../../../utils/helpers';

const IncidentReport = ({
  onSubmit,
  onEdit,
  onBack,
  onClose,
  onApprove,
  game,
  report,
  inEdit,
  setInEdit,
  isGamesEventAdminOrAssignor,
  isReportsEventGroupAdmin,
  isReportsEventAdmin,
  isReportsEventAssignor,
  isFetching,
  from,
  isFetchingReports
}) => {
  const display_id = addCountToDisplayId(game);

  return (
    <Body>
      <LoaderWrapper isFetching={game.isFetching || isFetchingReports} simple>
        {report && (
          <Flex justifyContent="space-between" padding="0 0 0.5rem">
            <Flex>
              {isReportsEventGroupAdmin ||
              isGamesEventAdminOrAssignor ||
              isReportsEventAssignor ||
              isReportsEventAdmin ? (
                <>
                  <Label color={report.approved ? COLORS.forestGreen : COLORS.hawkesBlue}>
                    Approved
                  </Label>
                  <Toggle
                    checked={report.approved}
                    icons={false}
                    onChange={() => !isFetching && onApprove(report, from)}
                  />
                </>
              ) : (
                report.approved && <Label color={COLORS.forestGreen}>Approved</Label>
              )}
            </Flex>
            {(isReportsEventGroupAdmin || isGamesEventAdminOrAssignor || !report.approved) && (
              <Icon
                width={22}
                height={18}
                icon={ICONS.EDIT}
                color={COLORS.denimBlue}
                clickable
                onClick={() => setInEdit(s => !s)}
              />
            )}
          </Flex>
        )}
        <CollapseableHeader label="Game Details">
          {game && (
            <Row style={{ marginBottom: '0.75rem' }}>
              {generateGameInfo({ game, display_id }).map(info => (
                <Col key={info.label} xs={12} md={info.width || 6}>
                  <FieldWrapper verticalMargins="0.5rem">
                    <InfoField {...info} />
                  </FieldWrapper>
                </Col>
              ))}
            </Row>
          )}
        </CollapseableHeader>
        <Header>Report Details</Header>
        {report && !inEdit ? (
          <>
            <Row>
              {generateReportInfo({ report, game }).map(
                ({ component: Component, isDisabled, ...info }) => (
                  <Col key={info.label} xs={12} md={info.width || 6}>
                    {!isDisabled && (
                      <FieldWrapper verticalMargins="0.5rem">
                        <Component {...info} />
                      </FieldWrapper>
                    )}
                  </Col>
                )
              )}
            </Row>
          </>
        ) : (
          <Form
            onSubmit={inEdit ? values => onEdit({ values, report, from }) : onSubmit}
            initialValues={inEdit ? report : { offensive_language: false }}
            render={({ handleSubmit, invalid, pristine, values, change }) => {
              const { fields, onChanges } = config({ game, change });

              return (
                <>
                  <Row>
                    {fields.map(
                      ({ isRequired = () => false, isDisabled = () => false, ...field }) => (
                        <Col key={field.name} xs={12} md={field.width || 6}>
                          {!isDisabled(values) && (
                            <FieldWrapper>
                              <Field
                                {...field}
                                placeholder={
                                  !!field.placeholder &&
                                  field.placeholder + (isRequired(values) ? '*' : '')
                                }
                              />
                            </FieldWrapper>
                          )}
                        </Col>
                      )
                    )}
                  </Row>
                  <Row center="xs">
                    <Button
                      width="84px"
                      height="32px"
                      margin="0 8px 0"
                      label="Cancel"
                      onClick={
                        from === FROM_GAMES
                          ? onBack
                          : () => {
                              onClose(from);
                            }
                      }
                    />
                    <Button
                      type="submit"
                      width="84px"
                      height="32px"
                      margin="0 8px 0"
                      label={inEdit ? 'Save' : 'Submit'}
                      disabled={invalid || pristine}
                      onClick={handleSubmit}
                      primary
                    />
                    {onChanges.map(({ name, onChangeFn }) => (
                      <OnChange key={name} name={name}>
                        {onChangeFn}
                      </OnChange>
                    ))}
                  </Row>
                </>
              );
            }}
          />
        )}
      </LoaderWrapper>
    </Body>
  );
};

IncidentReport.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  game: PropTypes.shape({
    id: PropTypes.string
  }),
  report: PropTypes.shape({
    id: PropTypes.string
  }),
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  inEdit: PropTypes.bool,
  setInEdit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isGamesEventAdminOrAssignor: PropTypes.bool,
  isReportsEventAdmin: PropTypes.bool,
  isReportsEventAssignor: PropTypes.bool,
  isFetching: PropTypes.bool,
  from: PropTypes.string
};

IncidentReport.defaultProps = {
  game: null,
  report: null,
  inEdit: false,
  isGamesEventAdminOrAssignor: false,
  isReportsEventAdmin: false,
  isReportsEventAssignor: false,
  isFetching: false,
  from: FROM_GAMES
};

export default IncidentReport;
