import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { CloseButton, BannerStyle } from '../Calendar/styled-components';

const Banner = ({ children, onClose, showBanner, hasClose = true }) => {
  return showBanner ? (
    <BannerStyle>
      {hasClose && (
        <CloseButton onClick={() => onClose(null)}>
          <CloseIcon />
        </CloseButton>
      )}

      <InfoIcon style={{ color: '#FFBB38', marginTop: '1px' }} />
      {children}
    </BannerStyle>
  ) : (
    ''
  );
};

Banner.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.element.isRequired
};

Banner.defaultProps = {
  onClose: () => {}
};
export default Banner;
