import { payoutBanner } from '../PayoutFailed';

const PayAccountInformational = ({ user, editPayeeAccountAction }) => {
  const bannerHeading = 'Your pay account needs attention!';
  const bannerBody = 'To continue receiving payouts, update your payment information';
  const hasFailedPayout = user && user.payee && user.payee.transfers_enabled && !user.payee.status;
  return payoutBanner({
    user,
    editPayeeAccountAction,
    bannerHeading,
    bannerBody,
    showBanner: hasFailedPayout
  });
};

export default PayAccountInformational;
