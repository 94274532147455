import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const StyledInput = styled.input`
  display: flex;
  flex: 1;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoLight';
  background: transparent;
  border-bottom: 1px solid
    ${({ hasError }) => (hasError ? COLORS.carminePink : 'rgba(137, 143, 149, 0.3)')}!important;
  border: none;
  padding: ${pxToRem('6px')} 0.5rem 0.5rem;
  margin: 0;
  outline-width: 0;
  ${({ addable }) => (!addable ? 'width: 100%' : '')};

  &::placeholder {
    color: ${COLORS.white};
    text-transform: capitalize;
  }

  ${({ theme }) => theme || ''};
`;

export const StyledLabel = styled.label`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('13px')};
  color: rgba(255, 255, 255, 0.5);
  margin: ${pxToRem('10px')} 0 0;
  display: inline-block;
  text-transform: capitalize;
  ${({ isHidden }) => (isHidden ? 'visibility: hidden' : '')};
  position: absolute;
  left: 0.5rem;
  top: -1.25rem;

  ${({ theme }) => theme || ''};
`;

export const Wrapper = styled.div`
  position: relative;
  box-shadow: inset 0 -1px 0 0 ${COLORS.white}88;

  &.hasValue {
    box-shadow: inset 0 -2px 0 0 ${COLORS.white};
  }

  ${({ hasError }) => (hasError ? `box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed}` : '')};

  ${({ theme }) => theme || ''};
`;

export const Message = styled.p`
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  text-align: left;
  font-family: 'RobotoCondensedLight';
  padding-left: 0.5rem;
  margin: ${pxToRem('4px')} 0 0;
`;

export const Error = styled.span`
  position: absolute;
  left: 0.5rem;
  font-size: ${pxToRem('10px')};
  color: ${COLORS.carminePink};
  font-family: 'RobotoCondensedLight';
`;

export const ErrorMessage = styled.span`
  color: ${COLORS.red};
`;

export const ErrorLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const ErrorLabel = styled.p`
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  text-align: left;
  font-family: 'RobotoCondensedLight';
  padding-left: 0.5rem;
  margin: 0px;

  ${({ theme }) => theme || ''};
`;
