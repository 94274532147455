import { createSelector } from 'reselect';
import { equals, find } from 'ramda';

export const getSports = createSelector(
  ({ sports }) => sports,
  ({ list }) => list
);

export const findSportById = (state, sportId) =>
  createSelector(getSports, sports => find(({ id }) => equals(Number(id), sportId), sports))(state);

export default getSports;
