import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Location } from '../../../styled-components';
import { isGameDetailChanged } from '../../../../config';

const GameDetails = ({ field, field_name, location }) => {
  return (
    <>
      {field ? (
        <Tooltip
          title={`${field_name} - ${field.location.name} - ${field.location.city} , ${field.location.state}`}
          PopperProps={{
            disablePortal: true
          }}
          arrow
        >
          <Location>
            {field_name && `${field_name} - `}
            {field.location.name}
            {field.location.city && ` - ${field.location.city}`}
            {field.location.state && `, ${field.location.state}`}
          </Location>
        </Tooltip>
      ) : (
        <Tooltip
          title={`${field_name} - ${location.name}   - ${location.city}, ${location.state}`}
          PopperProps={{
            disablePortal: true
          }}
          arrow
        >
          <Location>
            {field_name && `${field_name} - `}
            {location.name}
            {location.city && ` - ${location.city}`}
            {location.state && `, ${location.state}`}
          </Location>
        </Tooltip>
      )}
    </>
  );
};

export default React.memo(GameDetails, isGameDetailChanged);
