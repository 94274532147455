import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Enhancer from '../../../containers/AssignmentAvailability/availabilityTooltip';
import { AvailabilityWrapper, AvailabilityTitle } from '../styled-components';
import LoaderWrapper from '../../LoaderWrapper';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'rgba(61, 73, 86, 1)',
    width: '160px'
  },
  customArrow: {
    color: 'rgba(61, 73, 86, 1)'
  }
}));

const AvailabilityTooltipTitle = ({
  headingTitle,
  startTimeFormat,
  endTimeFormat,
  note,
  isFetching
}) => (
  <AvailabilityWrapper>
    <AvailabilityTitle>{headingTitle} at:</AvailabilityTitle>
    <LoaderWrapper isFetching={isFetching}>
      <div>
        {' '}
        {startTimeFormat} - {endTimeFormat}{' '}
      </div>
      {note && (
        <>
          <AvailabilityTitle>Note: </AvailabilityTitle>
          <div>{note}</div>
        </>
      )}
    </LoaderWrapper>
  </AvailabilityWrapper>
);

const AvailabilityTooltip = ({
  children,
  setUserAvailabilityNote,
  id,
  open,
  setOpen,
  fetchUserAvailabilityNote,
  start,
  availabilityNote: { note, target },
  availabilityNotefetching,
  startTimeFormat,
  endTimeFormat,
  headingTitle,
  setTooltipTarget,
  location
}) => {
  const classes = useStyles();
  const callBackfun = () => {
    setOpen(false);
    if (location.target) setTooltipTarget();
    if (target) setUserAvailabilityNote();
  };
  const handleTooltipOpen = () => {
    setTooltipTarget();
    fetchUserAvailabilityNote({ id, target: start });
  };

  useEffect(() => {
    if (target === start) {
      setOpen(true);
    }
    // eslint-disable-next-line
  }, [target]);

  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    <ClickAwayListener onClickAway={callBackfun} mouseEvent="onMouseDown" touchEvent="onTouchStart">
      <Tooltip
        open={open}
        title={
          <AvailabilityTooltipTitle
            headingTitle={headingTitle}
            startTimeFormat={startTimeFormat}
            endTimeFormat={endTimeFormat}
            note={note}
            isFetching={availabilityNotefetching}
          />
        }
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow
        }}
        arrow
      >
        <div onClick={handleTooltipOpen}>{children}</div>
      </Tooltip>
    </ClickAwayListener>
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
  );
};

export default Enhancer(AvailabilityTooltip);
