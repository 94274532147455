import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

import {
  MenuContainer,
  MenuItemContainer,
  TextContainer,
  SideMenuHeader,
  SideMenuSubheader,
  StyledIcon
} from './styled-components';
import { sInterpolator } from '../../../utils/helpers';
import COLORS, { ICONS } from '../../../utils/styles';

export const MenuItem = ({ title, link, icon }) => (
  <MenuItemContainer to={link}>
    <TextContainer>
      {!!icon && (
        <Icon fontSize="small" style={{ marginRight: '5px' }}>
          {icon}
        </Icon>
      )}
      {title}
    </TextContainer>
  </MenuItemContainer>
);

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export const Menu = ({ newMenu, items, displayView, subHeadingView, currentUserId, userId }) => {
  const profileItems = items.filter(item => item.type === 'profile');
  const paymentInfoItems = items.filter(item => item.type === 'paymentInfo');

  return newMenu ? (
    <MenuContainer className={displayView}>
      <SideMenuHeader className={subHeadingView}>
        {currentUserId && userId && currentUserId !== userId ? (
          <TextContainer>
            <StyledIcon
              height={20}
              width={20}
              icon={ICONS.TRIANGLE_EXCLAMATION}
              color={COLORS.lightOrange}
              style={{ marginRight: '5px', marginTop: -6 }}
            />
            Alternate Account
          </TextContainer>
        ) : (
          <TextContainer>
            <Icon fontSize="small" style={{ marginRight: '5px' }}>
              account_circle
            </Icon>
            My Account
          </TextContainer>
        )}
      </SideMenuHeader>

      {!!profileItems.length && (
        <>
          <SideMenuSubheader>
            <TextContainer>Profile</TextContainer>
          </SideMenuSubheader>

          {profileItems.map(({ title, link, icon }) => (
            <MenuItem icon={icon} key={link} title={title} link={link} />
          ))}
        </>
      )}

      {!!paymentInfoItems.length && (
        <>
          <SideMenuSubheader borderTop>
            <TextContainer>Payment Info</TextContainer>
          </SideMenuSubheader>

          {paymentInfoItems.map(({ title, link, icon }) => (
            <MenuItem icon={icon} key={link} title={title} link={link} />
          ))}
        </>
      )}
    </MenuContainer>
  ) : (
    <MenuContainer className={displayView}>
      {items.map(({ title, link }) => (
        <MenuItem key={link} title={title} link={link} />
      ))}
    </MenuContainer>
  );
};

const stripRoutes = (routes, titleToClean) => {
  const cleanRoutes = routes.filter(route => route.title !== titleToClean);
  return cleanRoutes;
};

export const routesToMenuItems = ({
  routes,
  summary = { game_levels: 0 },
  has_crew_pay = false,
  ...rest
}) => {
  let cleanRoutes = has_crew_pay ? routes : stripRoutes(routes, 'Crew Pay');
  cleanRoutes =
    summary.game_levels > 0 ? cleanRoutes : stripRoutes(cleanRoutes, 'Game Levels & Types');
  return cleanRoutes.map(({ title, path: link, icon, type }) => ({
    title,
    link: sInterpolator(rest, link),
    icon,
    type
  }));
};

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string.isRequired,
      selected: PropTypes.bool
    })
  ).isRequired
};

export default Menu;
