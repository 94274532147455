import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';
import { Container as HelpBoxWrapper } from './HelpBox/styled-components';
import { Container as QuantityBoxWrapper } from './QuantityBox/styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${pxToRem('40px')};
  background-image: radial-gradient(
    circle at 52% -36%,
    ${COLORS.darkerCerulean},
    ${COLORS.darkerSapphire}
  );

  ${mediaQueries.sm(`
  padding:0px;
  height: 100%;
    justify-content: center;
  `)};
`;

export const HelpSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${pxToRem('35px')};
  background-color: ${COLORS.denimBlue}30;
  ${mediaQueries.sm(`
  padding: ${pxToRem('0px')};
  height: 100%;
  `)};
`;

export const HelpSectionHeader = styled.div`
  ${mediaQueries.sm(`
  display:none;
`)};
`;

export const WelcomeMessage = styled.span`
  font: bold ${pxToRem('32px')} 'RobotoBold', sans-serif;
  letter-spacing: 0.9px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: ${COLORS.white};
`;

export const WelcomeToText = styled.span`
  margin-right: ${pxToRem('10px')};
  font-family: 'Roboto';
  font-weight: 400;
  letter-spacing: 1px;
`;

export const HelpSectionContent = styled.div`
  margin: ${pxToRem('40px')} 0;
  ${mediaQueries.sm(`
  margin:${pxToRem('0px')};
   `)};
`;

export const HelpBoxContainer = styled.div`
  display: flex;

  & > ${HelpBoxWrapper}:not(:last-child) {
    margin-right: ${pxToRem('40px')};
    ${mediaQueries.sm(`
    margin-right: ${pxToRem('0px')};
   `)};
  }

  ${mediaQueries.sm(`
  flex-direction: column;
  margin-top:${pxToRem('0px')};
   `)};
`;

export const HelpSectionFooter = styled.div`
  ${mediaQueries.sm(`
  text-align:center;
  padding:${pxToRem('20px')} 0px;
  `)};
`;

export const AdditionalHelpLink = styled(Link)`
  font: 400 ${pxToRem('18px')} 'Roboto', sans-serif;
  letter-spacing: 0.6px;
  color: ${COLORS.white};
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  ${mediaQueries.sm(`
  text-shadow: 0px 0px 0px;
  `)};
`;

export const NeedAdditionalHelpText = styled.span`
  margin-right: ${pxToRem('5px')};
  font-family: 'RobotoLight';
  font-weight: 300;
  ${mediaQueries.sm(`
  display:block;
  `)};
`;

export const QuantitySection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${pxToRem('53px')};

  & > ${QuantityBoxWrapper}:not(:last-child) {
    margin-right: ${pxToRem('54px')};
  }
`;

export default Container;
