import React from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
  pure,
  withProps,
  lifecycle
} from 'recompose';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

import { ICONS, COLORS } from '../../../utils/styles';
import Tags from '../../components/Tags';
import Icon from '../../components/Icon';
import { formatDate, parseDate } from '../../../utils/parsers';
import { Container, IconWrapper } from './styled-components';
import YearMonthForm from './YearMonthForm';
import InputWithFocus from './InputWithFocus';

const MaterialDatePicker = ({
  input,
  meta,
  placeholder,
  month,
  fromMonth,
  toMonth,
  setMonth,
  onBindRef,
  showDayPicker,
  format,
  theme,
  disabledDays,
  withTags,
  inlineError,
  birthday,
  toMonthBirthday,
  style,
  containerWidth,
  customClass,
  ...rest
}) => (
  <>
    <Container containerWidth={containerWidth}>
      <DayPickerInput
        component={InputWithFocus}
        style={style}
        inputProps={{
          input,
          meta,
          theme,
          inlineError
        }}
        value={input.value}
        formatDate={formatDate}
        format={format}
        placeholder={placeholder}
        onDayChange={input.onChange}
        ref={onBindRef}
        dayPickerProps={{
          month,
          fromMonth,
          toMonth,
          disabledDays,
          className: customClass,
          // eslint-disable-next-line
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={setMonth}
              fromMonth={fromMonth}
              toMonth={birthday ? toMonthBirthday : toMonth}
            />
          )
        }}
        {...rest}
      />
      <IconWrapper>
        <Icon
          icon={ICONS.CALENDAR}
          color={theme.icon || COLORS.denimBlue}
          noMargin
          onClick={showDayPicker}
          style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
        />
      </IconWrapper>
    </Container>
    {withTags && input.value && (
      <Tags
        tags={[
          {
            name: moment(input.value).format('YYYY-MM-DD'),
            onRemove: () => input.onChange(''),
            canEdit: true,
            color: COLORS.denimBlue
          }
        ]}
      />
    )}
  </>
);

MaterialDatePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)])
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string
  }).isRequired,
  placeholder: PropTypes.string,
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]).isRequired,
  fromMonth: PropTypes.instanceOf(Date).isRequired,
  toMonth: PropTypes.instanceOf(Date).isRequired,
  toMonthBirthday: PropTypes.instanceOf(Date).isRequired,
  setMonth: PropTypes.func.isRequired,
  onBindRef: PropTypes.func.isRequired,
  showDayPicker: PropTypes.func.isRequired,
  format: PropTypes.string,
  theme: PropTypes.shape({
    wrapper: PropTypes.string.isRequired
  }),
  disabledDays: PropTypes.shape({
    before: PropTypes.instanceOf(Date),
    after: PropTypes.instanceOf(Date)
  }),
  withTags: PropTypes.bool,
  inlineError: PropTypes.bool,
  birthday: PropTypes.bool
};

MaterialDatePicker.defaultProps = {
  input: {
    name: '',
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    value: ''
  },
  placeholder: '',
  format: 'YYYY-MM-DD',
  theme: {
    wrapper: '',
    label: '',
    input: '',
    icon: ''
  },
  disabledDays: {},
  withTags: false,
  inlineError: false,
  birthday: false
};

export default compose(
  withState('month', 'setMonth', ''),
  withState('isOpen', 'setVisibility', false),
  withPropsOnChange(['input', 'month', 'meta'], ({ input: { value }, month }) => ({
    month: month || parseDate(value)
  })),
  withHandlers(() => {
    let dayPicker = null;
    return {
      setValue: ({ setMonth }) => month => setMonth(month),
      onBindRef: () => ref => {
        dayPicker = ref;
      },
      onClickOutside: () => event => {
        if (dayPicker && dayPicker.getDayPicker()) {
          if (!dayPicker.getDayPicker().dayPicker.contains(event.target)) {
            dayPicker.hideDayPicker();
          }
        }
      },
      showDayPicker: ({ isOpen, setVisibility }) => () => {
        if (dayPicker) {
          if (isOpen) {
            dayPicker.hideDayPicker();
          } else dayPicker.showDayPicker();
          setVisibility(v => !v);
        }
      }
    };
  }),
  withProps(() => ({
    fromMonth: new Date(1900, 0),
    toMonth: new Date(new Date().getFullYear() + 1, 11),
    toMonthBirthday: new Date(new Date().getFullYear(), 11)
  })),
  lifecycle({
    componentDidMount() {
      document.addEventListener('mousedown', this.props.onClickOutside);
    },
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.props.onClickOutside);
    }
  }),
  pure
)(MaterialDatePicker);
