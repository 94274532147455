import { Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import COLORS from '../../../../utils/styles';

export const PrimaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${({ wideView }) =>
    wideView
      ? `box-shadow:1px 4px 5px 0px rgba(0, 0, 0, 0.31);  border-bottom: 1px solid ${COLORS.lightCyanGray};  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.31);
      -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.31);`
      : ''};

  padding: 5px;
`;

export const ViewMore = styled(Col)`
  margin-left: auto;
  margin-right: 15px;
  .ViewMoreButton {
    font-size: 10px;
    margin-top: 10px;
    color: #167cd4;
    padding: 5px 6px;
  }
`;
