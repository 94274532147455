import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import { NavLink } from 'react-router-dom';

import { pxToRem } from '../../../utils/helpers';
import { PAPER, COLORS, mediaQueries } from '../../../utils/styles';

export const Wrapper = styled(Row)`
  position: relative;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme || ''}
`;

export const Content = styled.div`
  top: ${pxToRem('25px')};
  max-width: ${pxToRem('130px')};
  width: max-content;
  position: absolute;
  font-size: ${pxToRem('13px')};
  font-stretch: condensed;
  font-family: 'RobotoCondensed';
  z-index: 9999;
  ${PAPER};

  ${({ theme }) => theme || ''}
`;

export const Options = styled.div``;

export const Option = styled.div`
  min-height: ${pxToRem('32px')};
  display: flex;
  align-items: center;
  padding: 0 ${pxToRem('16px')};
  cursor: pointer;
  background: ${COLORS.denimBlue};
  color: ${COLORS.white};

  &:hover {
    background: ${COLORS.white};
    color: ${COLORS.denimBlue};
  }

  ${({ theme }) => theme || ''}
`;

export const OptionLabel = styled.span``;

export const Heading = styled.label`
  cursor: pointer;
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('15px')};
  font-weight: bold;
  font-stretch: condensed;
  padding: 0 9px 0;
  -webkit-letter-spacing: 0rem;
  -moz-letter-spacing: 0rem;
  -ms-letter-spacing: 0rem;
  letter-spacing: 0rem;
  opacity: 0.7;
  color: #ffffff;
  width: 100%;

  ${mediaQueries.lg(`
  font-size: ${pxToRem('12px')};
  `)}

  ${({ theme }) => theme || ''}
`;

export const StyledNavLink = styled(NavLink)`
  color: ${COLORS.white};

  &:hover {
    color: ${COLORS.denimBlue};
  }

  ${({ theme }) => theme || ''}
`;
