import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Field } from 'react-final-form';
import { FillHeight } from '../../Reports/styled-components';
import RoundedCheckBox from '../../../RoundedCheckBox';
import { falsyToNull } from '../../../../../utils/parsers';
import ConditionalField from '../../../ConditionalField';

const AssesmentReportOptions = () => (
  <Col xs={12}>
    <FillHeight>
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <Field name="allow_assessors_to_submit" parse={falsyToNull}>
              {({ input }) => (
                <RoundedCheckBox {...input} label="Allow assessors to submit assessments" />
              )}
            </Field>
          </Col>
        </Row>
        <ConditionalField when="allow_assessors_to_submit" predicate={type => !type}>
          {settingsEnable => (
            <div>
              <Col xs={12}>
                <Field name="auto_approve_assessment" parse={falsyToNull}>
                  {({ input }) => (
                    <RoundedCheckBox
                      {...input}
                      label="Automatically mark assessments as approved once submitted"
                      disabled={settingsEnable}
                      locked={settingsEnable}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12}>
                <Field name="official_auto_release_assessment" parse={falsyToNull}>
                  {({ input }) => (
                    <RoundedCheckBox
                      {...input}
                      label="Automatically release assessments to officials for viewing after approval"
                      disabled={settingsEnable}
                      locked={settingsEnable}
                    />
                  )}
                </Field>
              </Col>
            </div>
          )}
        </ConditionalField>
      </Col>
    </FillHeight>
  </Col>
);

export default AssesmentReportOptions;
