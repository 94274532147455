import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import CollapsibleContent from '../../../CollapsableContent';
import { gameSettingsCollapsibleTheme } from '../config';
import { noZero } from '../../../../../utils/validations';
import Container from './styled-components';
import MaterialUiDropDown from '../../../../global-material-components/MaterialUiDropdown';

const GameSettingsCollapsible = ({ do_games_exist }) => (
  <CollapsibleContent title="GAME SETTINGS" theme={gameSettingsCollapsibleTheme} externalIsOpen>
    <Container>
      <Field
        component={MaterialUiDropDown}
        name="info.game_upload_type"
        validate={noZero}
        options={[
          { name: 'Sync Games', value: 1 },
          { name: 'Manual Upload (Import)', value: 2 }
        ]}
        helpText="How will you be adding games to this event?"
        showError
        disabled={do_games_exist}
      />
    </Container>
  </CollapsibleContent>
);

GameSettingsCollapsible.propTypes = {
  do_games_exist: PropTypes.bool
};

GameSettingsCollapsible.defaultProps = {
  do_games_exist: null
};

export default GameSettingsCollapsible;
