import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../Flex';
import Header from './Header';
import PaginationBar from '../PaginationBar';
import IncidentReports from './IncidentReports';
import AssessmentReports from './AssessmentReports';
import GameReports from './GameReports';
import AssessmentReportModal from './Modals/AssessmentReportModal';
import theme from './styled-components';
import { REPORT_TYPES } from '../../../constants';

import Enhancer from '../../containers/Reports';

export const Reports = ({
  reports,
  checkedReports,
  handleCheckboxClick,
  handleSelectAllAssessment,
  handleSelectAllIncident,
  storeReportsEvent,
  reportsEvent,
  isFetching,
  pagination,
  config,
  getCheckedReports,
  getAssessmentCheckedReports,
  filter,
  reportType,
  setReportType,
  reportSettings,
  crewLabels,
  showAssessmentModal,
  setShowAssessmentModal,
  search,
  clearParams,
  pageFilter,
  onAssessmentReportClick,
  onIncidentReportClick,
  onGameReportClick,
  sort,
  isReportsEventAdmin,
  isReportsEventGroupAdmin,
  isReportsEventAssignor,
  isReportsEventAssessor,
  location,
  isFetchingGameReports,
  getReportsEventSport,
  eventsAssessmentSettings
}) => (
  <>
    {showAssessmentModal && (
      <AssessmentReportModal
        reportId={showAssessmentModal.reportId}
        gameId={showAssessmentModal.gameId}
        crewLabel={showAssessmentModal.crewLabel}
        onClose={() => setShowAssessmentModal(null)}
      />
    )}
    {config.action.options.map(
      ({ showModal, onSubmit, component: Component, onClick, label }) =>
        showModal && (
          <Component
            key={label}
            onClose={() => onClick(false)}
            selected={
              reportType === REPORT_TYPES.assessment
                ? getAssessmentCheckedReports()
                : getCheckedReports()
            }
            reportType={reportType}
            onSubmit={onSubmit}
          />
        )
    )}
    <Flex direction="column" full fullWidth theme={theme.content}>
      <Header
        storeReportsEvent={storeReportsEvent}
        reportsEvent={reportsEvent}
        config={config}
        conditions={{ reportsSelected: !!checkedReports.length }}
        filter={filter}
        reportType={reportType}
        setReportType={setReportType}
        search={search}
        clearParams={clearParams}
        pageFilter={pageFilter}
        isReportsEventAdmin={isReportsEventAdmin}
        isReportsEventGroupAdmin={isReportsEventGroupAdmin}
        isReportsEventAssignor={isReportsEventAssignor}
        isReportsEventAssessor={isReportsEventAssessor}
        getReportsEventSport={getReportsEventSport}
        location={location}
        reportSettings={reportSettings}
        eventsAssessmentSettings={eventsAssessmentSettings}
      />
      {reportType === REPORT_TYPES.assessment && (
        <AssessmentReports
          reports={reports}
          handleCheckboxClick={handleCheckboxClick}
          checkedReports={checkedReports}
          handleSelectAll={handleSelectAllAssessment}
          isFetching={isFetching}
          crewLabels={crewLabels.filter(({ role_id }) => role_id === 5)}
          onAssessmentReportClick={onAssessmentReportClick}
          sort={sort}
          isReportsEventAdmin={isReportsEventAdmin}
          isReportsEventAssignor={isReportsEventAssignor}
          isReportsEventGroupAdmin={isReportsEventGroupAdmin}
        />
      )}
      {reportType === REPORT_TYPES.incident && (
        <IncidentReports
          reports={reports}
          handleCheckboxClick={handleCheckboxClick}
          checkedReports={checkedReports}
          handleSelectAll={handleSelectAllIncident}
          isFetching={isFetching}
          onIncidentReportClick={onIncidentReportClick}
          crewLabels={crewLabels.filter(({ role_id }) => role_id === 5)}
          setShowAssessmentModal={setShowAssessmentModal}
          sort={sort}
          isReportsEventAdmin={isReportsEventAdmin}
          isReportsEventAssignor={isReportsEventAssignor}
          isReportsEventGroupAdmin={isReportsEventGroupAdmin}
        />
      )}
      {reportType === REPORT_TYPES.game && (
        <GameReports
          reports={reports}
          event={reportsEvent}
          handleCheckboxClick={handleCheckboxClick}
          checkedReports={checkedReports}
          handleSelectAll={handleSelectAllIncident}
          isFetchingGameReports={isFetchingGameReports}
          onGameReportClick={onGameReportClick}
          crewLabels={crewLabels.filter(({ role_id }) => role_id === 5)}
          setShowAssessmentModal={setShowAssessmentModal}
          sort={sort}
          isReportsEventAdmin={isReportsEventAdmin}
          getReportsEventSport={getReportsEventSport}
          isReportsEventAssignor={isReportsEventAssignor}
          isReportsEventGroupAdmin={isReportsEventGroupAdmin}
        />
      )}
    </Flex>
    <>{reports && !!reports.length && <PaginationBar {...pagination} />}</>
  </>
);

Reports.propTypes = {
  reports: PropTypes.arrayOf(Object),
  checkedReports: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  handleSelectAllAssessment: PropTypes.func.isRequired,
  handleSelectAllIncident: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(Object),
  storeReportsEvent: PropTypes.func.isRequired,
  reportsEvent: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  isFetching: PropTypes.bool,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number,
    limit: PropTypes.number,
    pageLinkBuilder: PropTypes.func,
    onLoadPage: PropTypes.func
  }).isRequired,
  getCheckedReports: PropTypes.func.isRequired,
  getAssessmentCheckedReports: PropTypes.func.isRequired,
  config: PropTypes.shape({
    action: PropTypes.shape({
      options: PropTypes.arrayOf(Object)
    })
  }).isRequired,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  reportType: PropTypes.string.isRequired,
  setReportType: PropTypes.func.isRequired,
  crewLabels: PropTypes.arrayOf(Object),
  showAssessmentModal: PropTypes.shape({
    reportId: PropTypes.number.isRequired,
    gameId: PropTypes.string.isRequired
  }),
  setShowAssessmentModal: PropTypes.func.isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  clearParams: PropTypes.func.isRequired,
  pageFilter: PropTypes.shape({
    onPageFilterChange: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  onAssessmentReportClick: PropTypes.func.isRequired,
  onIncidentReportClick: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  isReportsEventAdmin: PropTypes.bool,
  isFetchingGameReports: PropTypes.bool
};

Reports.defaultProps = {
  reportsEvent: null,
  reports: [],
  events: [],
  isFetching: false,
  crewLabels: [],
  showAssessmentModal: null,
  isReportsEventAdmin: false,
  isFetchingGameReports: false
};

export default Enhancer(Reports);
