export default {
  s3FileName: 'game-import-template.csv',
  linkName: 'THIS IMPORT TEMPLATE',
  importInfoFirstParagraph: `Any games with the same Game ID will be returned as a duplicate and will need to be edited
    before being re-added.`,
  importInfoSecondParagraph: `After games are added you can view all unique teams and locations for the event.
    Remember, team and location names should be identical across games.
    For example, a team named "Bears" and a team named "The Bears" will be treated as two different teams.`,
  importInfoThirdParagraph: `Once games are added you can also update settings for all unique game levels, game types, and crew pay.`
};
