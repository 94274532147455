import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Modal from '../../Modal';
import Tag from '../../Tag';
import Button from '../../Button';
import FieldSpreadLayoutContainer from '../../../layouts/FieldSpreadLayoutContainer';
import FormWrapper, {
  TitleBar,
  TagContainer,
  TagsContainer,
  ButtonWrapper,
  TooltipStyle
} from './styled-components';
import { generalInfoConfig, locationConfig, teamsConfig } from './config';
import { getTimezoneByCode } from '../../../../utils/helpers';
import LoaderWrapper from '../../LoaderWrapper';
import GameLevelChangeModal from '../Modals/GameLevelChangeModal';
import { printDateDifferenceInMinutes } from '../../../../utils/parsers';
import { GAME_UPLOAD_TYPES } from '../../../../constants';

const GeneralInformation = ({
  readOnly,
  gameLevels,
  display_id,
  start_date,
  start_time,
  end_date,
  end_time,
  game_level,
  schedule_name,
  schedule_owner,
  isCopiedGameIdCorrect,
  duration,
  shouldPrefillGameId
}) => (
  <FieldSpreadLayoutContainer
    columns={2}
    deviation={3}
    useAsKey="placeholder"
    fields={generalInfoConfig(
      readOnly,
      gameLevels,
      display_id,
      start_date,
      start_time,
      end_date,
      end_time,
      game_level,
      schedule_name,
      schedule_owner,
      isCopiedGameIdCorrect,
      duration,
      shouldPrefillGameId
    )}
  />
);

GeneralInformation.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  display_id: PropTypes.string,
  start_date: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  end_date: PropTypes.string,
  game_level: PropTypes.shape({
    game_level: PropTypes.string,
    game_type: PropTypes.string,
    length_period: PropTypes.number
  }),
  schedule_name: PropTypes.string,
  schedule_owner: PropTypes.string,
  gameLevels: PropTypes.arrayOf(Object).isRequired,
  duration: PropTypes.string,
  shouldPrefillGameId: PropTypes.bool.isRequired
};

GeneralInformation.defaultProps = {
  display_id: '',
  start_date: '',
  start_time: '',
  end_time: '',
  end_date: '',
  game_level: {
    game_level: '',
    game_type: '',
    length_period: null
  },
  schedule_name: '',
  schedule_owner: '',
  duration: ''
};

const Location = ({
  readOnly,
  field_name,
  location: { name, address, address_two, city, state, postal_code },
  timezone,
  timezonesList
}) => (
  <>
    <TitleBar>LOCATION</TitleBar>
    <FieldSpreadLayoutContainer
      columns={2}
      deviation={5}
      useAsKey="placeholder"
      fields={locationConfig({
        readOnly,
        field_name,
        name,
        address,
        address_two,
        city,
        state,
        postal_code,
        timezonesList,
        timezone: getTimezoneByCode(timezonesList, timezone).name
      })}
    />
  </>
);

Location.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  field_name: PropTypes.string,
  location: PropTypes.shape({
    name: PropTypes.string
  })
};

Location.defaultProps = {
  field_name: '',
  location: {}
};

const Categories = ({ categories }) => (
  <>
    <TitleBar>CATEGORIES</TitleBar>
    <TagsContainer>
      <TagContainer>
        {categories.map(category => (
          <Tag {...category} key={category.id} />
        ))}
      </TagContainer>
    </TagsContainer>
  </>
);

Categories.propTypes = {
  categories: PropTypes.arrayOf(Object)
};

Categories.defaultProps = {
  categories: []
};

const Teams = ({ readOnly, team_home, home_score, team_visitor, visitor_score }) => (
  <>
    <TitleBar>TEAMS</TitleBar>
    <FieldSpreadLayoutContainer
      columns={2}
      deviation={3}
      useAsKey="placeholder"
      fields={teamsConfig(readOnly, team_home, home_score, team_visitor, visitor_score)}
    />
  </>
);

const teamShape = {
  contact_name: PropTypes.string,
  contact_phone: PropTypes.string,
  contact_email: PropTypes.string,
  division: PropTypes.string,
  age: PropTypes.string,
  name: PropTypes.string
};

Teams.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  team_home: PropTypes.shape(teamShape),
  home_score: PropTypes.number,
  team_visitor: PropTypes.shape(teamShape),
  visitor_score: PropTypes.number
};

Teams.defaultProps = {
  team_home: {
    name: '',
    contact_name: '',
    contact_email: ''
  },
  home_score: null,
  team_visitor: '',
  visitor_score: null
};

export const CopyGameInfoModal = ({
  onClose,
  gameInfo: game,
  categories,
  onSubmit,
  gameList,
  confirmModifiedGameLevelModalOpen,
  setConfirmModifiedGameLevelModalOpen,
  setGameLevelChangeConfirmed,
  timezonesList,
  isCopiedGameIdCorrect,
  isFetchingCopiedGameId,
  isGameUploadTypeManual,
  gamesEventUploadType
}) => {
  const gameInitialValues = {
    ...game,
    duration: printDateDifferenceInMinutes({
      start_date: game.start_date,
      start_time: game.start_time,
      end_date: game.end_date,
      end_time: game.end_time
    }),
    display_id: !isCopiedGameIdCorrect.flag ? '' : isCopiedGameIdCorrect.display_id
  };

  // Synced games have the ability to submit without making any changes.
  // This is due to the fact that a new unique id for the UI will be automatically generated.
  if (GAME_UPLOAD_TYPES.connect_game_sync === gamesEventUploadType && !isGameUploadTypeManual) {
    gameInitialValues.display_id = game.display_id;
  }

  return (
    <Modal title="Game Copy- Update your new game details" onClose={onClose}>
      <LoaderWrapper isFetching={isFetchingCopiedGameId}>
        <Form
          initialValues={gameInitialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, pristine, invalid, submitting }) => (
            <>
              {confirmModifiedGameLevelModalOpen && (
                <GameLevelChangeModal
                  onClose={() => setConfirmModifiedGameLevelModalOpen(false)}
                  onSubmit={() => {
                    setGameLevelChangeConfirmed(true, data => {
                      handleSubmit(data);
                    });
                  }}
                />
              )}
              {!confirmModifiedGameLevelModalOpen && (
                <form onSubmit={handleSubmit}>
                  <FormWrapper>
                    <GeneralInformation
                      readOnly={false}
                      {...game}
                      gameLevels={gameList}
                      isCopiedGameIdCorrect={isCopiedGameIdCorrect}
                      shouldPrefillGameId={!isGameUploadTypeManual}
                    />
                    <Location readOnly={false} timezonesList={timezonesList} {...game} />
                    <Teams readOnly={false} {...game} />
                    <Categories categories={categories} />
                    <TooltipStyle
                      title="Save updates and communicate game changes."
                      direction="right"
                      disableHoverListener={isGameUploadTypeManual}
                    >
                      <ButtonWrapper>
                        <Button
                          type="submit"
                          label="Confirm"
                          primary
                          disabled={(pristine && isGameUploadTypeManual) || invalid || submitting}
                        />
                      </ButtonWrapper>
                    </TooltipStyle>
                  </FormWrapper>
                </form>
              )}
            </>
          )}
        />
      </LoaderWrapper>
    </Modal>
  );
};

CopyGameInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  gameInfo: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    id: PropTypes.string,
    home_score: PropTypes.number,
    visitor_score: PropTypes.number,
    division: PropTypes.string,
    field: PropTypes.object,
    team_home: PropTypes.object,
    team_visitor: PropTypes.object,
    game_level: PropTypes.object
  }).isRequired,
  categories: PropTypes.arrayOf(Object),
  gamesEventUploadType: PropTypes.number,
  isGamesEventGroupAdmin: PropTypes.bool.isRequired,
  modalEditMode: PropTypes.bool.isRequired,
  toggleModalEditMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  confirmModifiedGameLevelModalOpen: PropTypes.bool,
  setConfirmModifiedGameLevelModalOpen: PropTypes.func.isRequired,
  setGameLevelChangeConfirmed: PropTypes.func.isRequired
};

CopyGameInfoModal.defaultProps = {
  categories: [],
  gamesEventUploadType: null,
  confirmModifiedGameLevelModalOpen: false
};

export default CopyGameInfoModal;
