import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Enhancer from '../../../../containers/PaymentMethod';
import { WhiteContentContainer, StyledDiv, H2 } from '../../styled-components';
import { BannerP, BannerSpan, LoaderContainer } from './styled-components';

import DirectDeposit from './DirectDeposit';
import { sInterpolator as routeBuilder } from '../../../../../utils/helpers';
import * as paths from '../../../../../core/paths';
import BoardingFlowButtons from '../../../BoardingFlowButtons';
import Banner from '../../../Banner';

const Payee = ({
  isFetching,
  bankAccounts,
  userId,
  addPayeeAccountAction,
  editPayeeAccountAction,
  deletePayeeAccountAction,
  height,
  currentUserId,
  pathUrl,
  fetchActiveUserInfo,
  isFetchingPayee,
  userIdx,
  path,
  paramId,
  isFundingAdmin,
  isPaidThroughSO
}) => {
  const isOnBoardingUrl =
    routeBuilder({ userIdx, userId }, paths.START_PROFILE_PAYMENT_METHOD) === path;
  useEffect(
    () => {
      fetchActiveUserInfo({ userId, pathUrl });
    },
    // eslint-disable-next-line
    []
  );
  // disable the banner iif:
  // - paidthrough SO is false
  // - paid through so is true and no bank accounts with payouts enabled found
  const disabled =
    !isPaidThroughSO ||
    (bankAccounts && bankAccounts.some(account => account.attributes.payouts_enabled));

  const waitingForData = isFetching || isFetchingPayee;
  if (!waitingForData && isOnBoardingUrl && disabled) {
    return (
      <Redirect
        to={routeBuilder(
          { userIdx, userId },
          isFundingAdmin ? `${paths.EVENTS}?ev_status=2` : paths.DASHBOARD
        )}
      />
    );
  }
  return (
    <>
      {isOnBoardingUrl &&
        (waitingForData ? (
          <> </>
        ) : (
          !disabled && (
            <Banner showBanner hasClose={false}>
              <BannerSpan style={{ paddingLeft: '20px' }}>
                <H2>Getting paid through Stack Officials</H2>
                <BannerP>
                  The Payment Profile button will redirect you to our payment partner where user
                  data is encrypted and securely stored.{' '}
                  <b style={{ fontWeight: 'bold' }}>For users under the age of 18</b>, the parent or
                  guardian information will be used to complete this process.
                </BannerP>
                <BannerP>The following information will be needed:</BannerP>
                <ul>
                  <li>A mobile number</li>
                  <li>Taxpayer id (SSN, ITIN or EIN)</li>
                  <li>Bank account info or a debit card number</li>
                </ul>
                <BannerP>
                  If you need time to gather this information, please click “Skip for Now” at the
                  bottom of this page.
                </BannerP>
              </BannerSpan>
            </Banner>
          )
        ))}
      <StyledDiv onBoarding={isOnBoardingUrl}>
        <WhiteContentContainer height={height} onBoarding={isOnBoardingUrl}>
          {isFetching || isFetchingPayee ? (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          ) : (
            <DirectDeposit
              userId={userId}
              addPayeeAccountAction={addPayeeAccountAction}
              account={bankAccounts}
              editPayeeAccountAction={editPayeeAccountAction}
              deletePayeeAccountAction={deletePayeeAccountAction}
              currentUserId={currentUserId}
            />
          )}
        </WhiteContentContainer>
        {isOnBoardingUrl && (
          <BoardingFlowButtons
            disabled={!disabled}
            nextBtnPath={routeBuilder({ userIdx, userId: paramId }, paths.DASHBOARD)}
            skipButtonPath={routeBuilder(
              { userIdx },
              isFundingAdmin ? `${paths.EVENTS}?ev_status=2` : paths.DASHBOARD
            )}
            previousBtnPath={routeBuilder(
              { userIdx, userId: paramId },
              paths.START_PROFILE_INFORMATION
            )}
            justifyContent="space-between"
            margin="20px 0px"
          />
        )}
      </StyledDiv>
    </>
  );
};

export default Enhancer(Payee);
