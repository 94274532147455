import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { COLORS, ICONS } from '../../../../../../../utils/styles';
import { StyledTrashIcon } from '../styled-components';
import Button from '../../../../../Button';
import { pxToRem } from '../../../../../../../utils/helpers';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  marginLeft: 30
};

const thumb = {
  display: 'inline-flex',
  width: 100,
  height: 100,
  boxSizing: 'border-box',
  marginRight: 30
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const Message = styled.span`
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoCondensedLight';
`;

let duplicateFiles = [];

function ImageUploader(props) {
  const [files, setFiles] = useState(props.attachments || []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: true,
    maxSize: 5242880, // 5 MB
    noDrag: true,
    onDrop: acceptedFiles => {
      // Prevent duplicate
      duplicateFiles = [];
      files.forEach(file => {
        acceptedFiles.forEach(acceptedFile => {
          if (
            file.name === acceptedFile.name &&
            file.size === acceptedFile.size &&
            file.lastModified === acceptedFile.lastModified
          ) {
            duplicateFiles.push(acceptedFile.name);
          }
        });
      });

      const fileData = acceptedFiles.filter(file => {
        if (duplicateFiles.find(dFile => dFile === file.name)) {
          return false;
        }
        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
      });

      setFiles(prevfiles => {
        const mergedFiles = [...prevfiles, ...fileData].splice(0, 4);
        return mergedFiles;
      });

      if (props.onChange) {
        const mergedFiles = [...files, ...fileData].splice(0, 4);
        props.onChange(mergedFiles);
      }
    }
  });

  const removeFile = file => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    props.onChange([...newFiles]);
    duplicateFiles = [];
  };

  const onPreview = ({ location, preview }) => {
    if (!props.disabled) {
      /* eslint-disable no-param-reassign */
      props.values.previewSrc = location || preview;
      props.onChange([...files]);
      /* eslint-enable no-param-reassign */
    }
  };

  const thumbs = files.map(file => {
    return (
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview || file.location}
            style={img}
            alt=""
            onClick={() => onPreview(file)}
          />
        </div>
        {!props.disabled && (
          <div className="trashIcon">
            <StyledTrashIcon
              name="del"
              width={15}
              height={15}
              style={{ marginLeft: '5px' }}
              icon={ICONS.TRASH}
              color={COLORS.hawkesBlue}
              onClick={() => {
                removeFile(file);
              }}
              clickable
              noMargin
            />
          </div>
        )}
      </div>
      /* eslint-enable jsx-a11y/click-events-have-key-events */
      /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
    );
  });

  useEffect(() => {
    duplicateFiles = [];
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-inline-block mt-4">
      <div {...getRootProps({ className: 'btn-dropzone', style: { outline: 'none' } })}>
        <input disabled={props.disabled} {...getInputProps()} />
        <div style={{ textAlign: 'left', marginLeft: 30, marginBottom: 5 }}>
          <Message>Maximum size of attachments: 5 MB and Maximum number of attachments: 4</Message>
        </div>
        <Button
          disabled={props.disabled}
          width="90px"
          height="32px"
          margin="0 0 0 30px"
          label="Choose File"
          primary
        />
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
      {!!duplicateFiles.length && (
        <div style={{ textAlign: 'left', marginLeft: 30, marginBottom: 5 }} className="errorNum">
          <span>Duplicate attachments are not accepted: {duplicateFiles.toString()}</span>
        </div>
      )}
      <br />
    </div>
  );
}

export default ImageUploader;
