import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';
import { REPORT_TYPES } from '../../../../../constants';

const BulkApproveModal = ({ onClose, selected, onSubmit, reportType }) => {
  const reportTypeCapitalized = reportType.charAt(0).toUpperCase() + reportType.slice(1);
  const selectedByType = REPORT_TYPES.assessment === reportType ? [].concat(...selected) : selected;
  const approveable = selectedByType.filter(report => !report.approved);
  return (
    <Modal
      title="Approve Selected"
      onClose={onClose}
      size={{
        xs: 8,
        sm: 8,
        md: 8,
        lg: 6
      }}
    >
      <>
        <Row>
          <HighlightedBlock xs={12}>
            <Content>
              <Summary>
                <Count color={COLORS.denimBlue}>{approveable.length}</Count> {reportTypeCapitalized}{' '}
                Reports selected for approval
              </Summary>
            </Content>
          </HighlightedBlock>
        </Row>
        <Row>
          <Block xs={12} className="last">
            <ButtonsContainer>
              <ButtonContainer>
                <Button
                  label="Approve"
                  onClick={() => {
                    onClose();
                    onSubmit(approveable);
                  }}
                  disabled={!approveable.length}
                  width="72px"
                  height="32px"
                  primary
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
              </ButtonContainer>
            </ButtonsContainer>
          </Block>
        </Row>
      </>
    </Modal>
  );
};

BulkApproveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired
};

BulkApproveModal.defaultProps = {};

export default BulkApproveModal;
