import styled from 'styled-components';

import Icon from '../../../Icon';
import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  padding: ${pxToRem('8px')} ${pxToRem('8px')} ${pxToRem('9px')} ${pxToRem('8px')};
  background-color: #dff0fb;
  font-size: ${pxToRem('13px')};
  margin-top: ${({ wrapperStyle }) => (wrapperStyle ? pxToRem('10px') : pxToRem('40px'))};
`;

export const Title = styled.span`
  padding-right: ${pxToRem('7px')};
  color: ${COLORS.sapphire};
  font-family: 'Roboto';
`;

export const DropdownLabel = styled.span`
  position: absolute;
  left: ${pxToRem('105px')};
  padding-top: ${pxToRem('2.5px')};
  font-family: 'RobotoCondensedLight';
  color: ${COLORS.shuttleGray};
`;

export const DropdownContainer = styled.div`
  position: absolute;
  left: ${pxToRem('192px')};
`;

export const DropdownChevron = styled(Icon)`
  cursor: pointer;
  position: relative;
  left: ${pxToRem('-26px')};
`;
