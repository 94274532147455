import styled from 'styled-components';

export const Container = styled.div`
  position: ${({ containerWidth }) => (containerWidth ? 'absolute' : 'relative')};

  & .DayPickerInput {
    display: inherit;
  }
  & .DayPicker-Month {
    margin-top: 3rem;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0.5em;
  cursor: pointer;
  right: 0;
`;
