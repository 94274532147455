import React from 'react';
import { Col } from 'react-flexbox-grid';
import { makeStyles } from '@material-ui/core/styles';
import ToolTip from '../../../global-material-components/ToolTip';
import { ASSIGNMENT_STATUS } from '../../../../core/Games/constants';
import { COLORS, ICONS } from '../../../../utils/styles';
import { getAssignedCrewMember } from '../config';
import { StatusIcon, StatusIconContainer, SubInfo, CrewName, UserName } from './styled-components';

const useStyles = makeStyles(() => ({
  crewTooltip: { width: '100%' }
}));

const AssignedPosition = ({ crewMembers, index, game, userId, selfAssign = false }) => {
  const classes = useStyles();
  const { gameAssignment, iconColor, icon } = getAssignedCrewMember({ crewMembers, index });
  return gameAssignment &&
    (gameAssignment.status === ASSIGNMENT_STATUS.accepted ||
      gameAssignment.status === ASSIGNMENT_STATUS.pending ||
      gameAssignment.status === ASSIGNMENT_STATUS.declined ||
      (selfAssign && gameAssignment.status === ASSIGNMENT_STATUS.unpublished)) ? (
    <>
      {gameAssignment && gameAssignment.id === userId ? (
        <Col key={index} className="flexNoWrap">
          <CrewName userActive={gameAssignment.id === userId}>
            <span>
              {' '}
              <StatusIconContainer>
                <StatusIcon width={16} height={16} color={iconColor} icon={icon} />
              </StatusIconContainer>
            </span>
            <SubInfo
              color={COLORS.darkGrey}
              fontWeight={gameAssignment.id === userId ? '500' : null}
              whiteSpace
            >
              {game.game_level && game.game_level.labels[index]}
              {' :  '}
            </SubInfo>
            <UserName userActive={gameAssignment.id === userId}>
              {gameAssignment.first_name}
              {'  '}
              {gameAssignment.last_name}
            </UserName>{' '}
          </CrewName>
        </Col>
      ) : (
        <Col className="flexNoWrap">
          <CrewName userActive={gameAssignment.id === userId}>
            <ToolTip
              messageTitle={
                gameAssignment && `${gameAssignment.first_name} ${gameAssignment.last_name}`
              }
              messageBody={gameAssignment && gameAssignment.phone_number}
              buttonText="Copy"
              color={COLORS.lightBlue}
              classes={{ root: classes.crewTooltip }}
            >
              <span>
                {' '}
                <StatusIconContainer>
                  <StatusIcon
                    width={icon === ICONS.EMPTY ? 18 : 16}
                    height={icon === ICONS.EMPTY ? 18 : 16}
                    color={iconColor}
                    icon={icon}
                  />
                </StatusIconContainer>
              </span>
              <SubInfo
                color={COLORS.darkGrey}
                fontWeight={gameAssignment.id === userId ? '500' : null}
                whiteSpace
              >
                {game.game_level && game.game_level.labels[index]}
                {' :  '}
              </SubInfo>
              <UserName userActive={gameAssignment.id === userId}>
                {gameAssignment.first_name}
                {'  '}
                {gameAssignment.last_name}
              </UserName>{' '}
            </ToolTip>
          </CrewName>
        </Col>
      )}
    </>
  ) : (
    game.game_level && game.game_level.labels[index] && (
      <Col key={index} className="flexNoWrap">
        <CrewName>
          <span>
            <StatusIconContainer>
              <StatusIcon width={18} height={18} color={COLORS.greyChateau} icon={ICONS.EMPTY} />
            </StatusIconContainer>
          </span>
          <SubInfo color={COLORS.darkGrey} whiteSpace>
            {game.game_level && game.game_level.labels[index]}
            {' :  '}
          </SubInfo>
        </CrewName>
      </Col>
    )
  );
};

export default AssignedPosition;
