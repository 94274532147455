import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';
import Icon from '../../../Icon';
import { COLORS, mediaQueries } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrimaryContainer = styled.div`
  width: 100%;
  display: flex;
  height: ${props => (props.distanceFilter ? '6rem' : '4rem')};
  box-sizing: border-box;
  z-index: ${({ opened }) => opened && 1};
`;

export const MainRow = styled.div`
  position: relative;
  top: ${pxToRem('10px')};
  display: flex;
  width: 100%;
`;

export const StyledCol = styled(Col)`
  ${({ noPadding }) => (noPadding ? 'padding: 0' : '')};
`;

export const ProfileLink = styled(NavLink)`
  margin-right: 1rem;
  text-decoration: inherit;
  font-family: 'RobotoCondensed';
  font-size: 0.7rem;
  line-height: 2;
  letter-spacing: -0.3px;
  text-align: right;
  float: right;
  flex-grow: 0;
  ${({ selected }) => `color:${selected ? COLORS.white : COLORS.denimBlue}`};
`;

export const ProfilePic = styled.img`
  width: 4rem;
  height: 4rem;
`;

export const DefaultProfilePic = styled.div`
  padding-top: ${pxToRem('10px')};
`;

export const Wrapper = MainWrapper.extend`
  flex: 0 0 auto;
`;

export const InfoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: ${pxToRem('10x')};
  .lastLogin {
    align-items: baseline;
    justify-content: end;
  }
`;

export const Name = styled.span`
  font-family: 'Barlow';
  font-size: ${pxToRem('13px')};
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
  padding-right: 2px;
  width: auto;
  max-width: 15ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & span {
    width: 10ch;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ${({ selected }) => `color:${selected ? COLORS.white : '#0d194f'}`};
  & span {
    font-family: 'BarlowMedium';
  }
  ${`@media (max-width: 1315px) { max-width: 6ch;}`}
  ${mediaQueries.sm(`max-width:15ch;`)};
  ${mediaQueries.xs(`max-width:13ch;`)};
  ${`@media (max-width: 375px) { max-width:5ch;}`}
`;

export const NameNoOverFlow = styled.span`
  font-family: 'Barlow';
  font-size: ${pxToRem('13px')};
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
  padding-right: 2px;
  padding-top: 4px;
  width: auto;
  white-space: nowrap;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0.85rem;
  flex-grow: 1;
  ${({ selected }) =>
    ` background:${
      selected
        ? `linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue})`
        : `linear-gradient(to bottom, ${COLORS.white} 0%, ${COLORS.blackSqueeze} 100%);`
    }`};

  ${`@media (max-width: 350px) {padding:0 0.4rem;}`}
`;

export const PrimaryLabel = styled.span`
  display: inline-flex;
  font-family: 'Barlow';
  font-size: ${pxToRem('13px')};
  letter-spacing: -0.3px;
  text-align: left;
  ${({ selected }) => `color:${selected ? COLORS.white : 'rgba(108, 117, 125, 1)'}`};
  ${`@media (max-width: 1315px) {display:block;}`}
  ${mediaQueries.xs(`{font-size: ${pxToRem('12px')};}`)}
`;

export const PrimaryValue = styled.span`
  font-family: 'BarlowMedium';
  font-size: ${pxToRem('13px')};
  line-height: 1rem;
  font-style: normal;
  font-stretch: condensed;
  letter-spacing: -0.2px;
  text-align: left;
  padding-left: 0.2rem;
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space:nowrap;
  ${({ selected }) => `color:${selected ? COLORS.white : '#0d194f'}`};
 ${`@media (max-width: 1315px) { padding-left: 0.3rem;}`}
 ${props =>
   `@media (max-width: 991px) {
    max-width:${props.name === 'State' ? '15ch' : props.name === 'Assigned' ? '2ch' : '8ch'};
}`}
 ${props =>
   ` ${mediaQueries.xs(`
    max-width:${props.name === 'State' ? '5ch' : props.name === 'Assigned' ? '2ch' : '4ch'};
    white-space:nowrap;
    padding-left: 0.1rem;
    font-size: ${pxToRem('12px')};
  `)}`}
`;

export const Detail = styled.div`
  flex: auto;
  width: ${props => (props.width ? props.width : 'auto')};
  display: ${flex => (flex ? 'flex' : '')};
  ${props => (props.name === 'Assigned' ? 'justify-content:end' : '')};
  align-items: center;
  &:not(:last-child) {
    margin-right: ${pxToRem('7px')};
  }
  ${`@media (max-width: 1480px) {
  &:not(:last-child) {margin-right:0px;}
  }`};

  ${props =>
    `@media (max-width: 1370px) {
          width:${
            props.name === 'State'
              ? '14ch'
              : props.name === 'Assigned'
              ? '6ch'
              : props.name === 'Cert.'
              ? '8ch'
              : '4ch'
          };
          padding-left: 0.1rem;
    }`}

  ${props =>
    `@media (max-width: 1295px) {
            width:${
              props.name === 'State'
                ? '11ch'
                : props.name === 'Assigned'
                ? '7ch'
                : props.name === 'Cert.'
                ? '10ch'
                : '5ch'
            };
            padding-left: 0.1rem;
  }`}

  ${props =>
    `@media (max-width: 1195px) {
            width:${
              props.name === 'State'
                ? '6ch'
                : props.name === 'Assigned'
                ? '7ch'
                : props.name === 'Cert.'
                ? '7ch'
                : '5ch'
            };
            padding-left: 0.1rem;
  }`}

  ${`@media (max-width: 991px) {
    flex:1rem;
    width:10%;
    }`};

`;

export const HeadingInfo = styled.div`
  flex: 1;
  width: 100%;
  display: ${flex => (flex ? 'flex' : '')};
`;

export const NonPaymentIcon = styled.span`
  padding: 1px 1px 0px 1px;
  position: relative;
  font-size: 15px;
  color: #ef0101;
  cursor: pointer;
  .MuiSvgIcon-root {
    width: 0.75em;
    height: 0.75em;
  }
  &::after {
    position: absolute;
    content: '/';
    width: 0.75em;
    height: 0.9em;
    font-size: 16px;
    top: 5.4px;
    right: auto;
    left: 8px;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(110deg);
    color: #ef0101;
  }
`;

export const PaymentMouseOverPopoverWrapper = styled.div`
  font-size: 0.6em;
  height: 1rem;
`;

export const LastLoginLabel = styled(PrimaryLabel)`
  font-size: ${pxToRem('13px')};
  white-space: nowrap;
`;

export const LastLoginValue = styled(LastLoginLabel)`
  padding-left: 0.5rem;
  text-align: center;
  white-space: nowrap;
`;

export const GamePositionStatusIcon = styled.div`
  margin: 1px 0px 0px 2px;
  white-space: nowrap;
`;

export const StatusIcon = styled(Icon)`
  margin: 0;
  position: relative;
  top: ${pxToRem('-2px')};
  right: ${pxToRem('0px')};
`;

export const SidePanelIcon = styled(Icon)`
  margin: 7px 0px 0px 0px;
  position: relative;
  top: ${pxToRem('-6px')};
  right: ${pxToRem('3px')};
  padding-left: 2px;
`;

export const DetailInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  overflow: hidden;
`;

export const DistanceInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 20%;
`;

export const AvailabilityInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  width: 100%;
`;
