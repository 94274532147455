import { equals, assoc, slice, max, min, props, map, remove } from 'ramda';
import {
  compose,
  withHandlers,
  withReducer,
  lifecycle,
  shouldUpdate,
  withState,
  pure
} from 'recompose';
import FileSaver from 'file-saver';

import { connect } from 'react-redux';
import ShowHide from '../../controls/ShowHide';
import { formatDate } from '../../../utils/parsers';
import { getUsersByType } from '../../../core/Users/selectors';

const isShift = equals('Shift');
const isCtrl = equals('Control');

const SHIFT_ON = 'SHIFT_ON';
const SHIFT_OFF = 'SHIFT_OFF';
const CTRL_ON = 'CTRL_ON';
const CTRL_OFF = 'CTRL_OFF';
const UNSELECT_ITEMS = 'UNSELECT_ITEMS';
const TOGGLE_SELECT_ITEM = 'SELECT_ITEM';
const MULTI_SELECT_ITEM = 'MULTI_SELECT_ITEM';
const TOGGLE_OPEN = 'TOGGLE_OPEN';
const SET_IS_EVENT_ADMIN = 'SET_IS_EVENT_ADMIN';
const RESET_STATE = 'RESET_STATE';
const CLOSE_ALL = 'CLOSE_ALL';
const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';

const initialState = {
  isShiftSelecting: false,
  isCtrlSelecting: false,
  selected: [],
  currentSelected: {},
  opened: [],
  filters: {},
  sortingValue: null,
  search: null,
  filterCount: 0,
  conditions: {
    hasSelected: false,
    isGamesEventGroupAdmin: false
  },
  selectAll: false
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHIFT_ON:
      return assoc('isShiftSelecting', true)(state);
    case SHIFT_OFF:
      return assoc('isShiftSelecting', false)(state);
    case CTRL_ON:
      return assoc('isCtrlSelecting', true)(state);
    case CTRL_OFF:
      return assoc('isCtrlSelecting', false)(state);
    case TOGGLE_SELECT_ITEM: {
      const { selectedItem, selectedProp } = payload;
      return {
        ...state,
        selected:
          state.selected.length && state.selected[0][selectedProp] === selectedItem[selectedProp]
            ? []
            : [selectedItem],
        currentSelected:
          state.currentSelected[selectedProp] === selectedItem[selectedProp] ? {} : selectedItem,
        conditions: {
          ...state.conditions,
          hasSelected: !state.selected.find(u => u[selectedProp] === selectedItem[selectedProp])
        },
        selectAll: false
      };
    }
    case MULTI_SELECT_ITEM:
      return {
        ...state,
        selected: payload.multiSelection,
        conditions: {
          ...state.conditions,
          hasSelected: !!state.selected.length
        },
        selectAll: false
      };
    case UNSELECT_ITEMS:
      return {
        ...state,
        selected: [],
        currentSelected: {},
        conditions: {
          hasSelected: false,
          isGamesEventGroupAdmin: false
        },
        selectAll: false
      };
    case TOGGLE_OPEN: {
      const { item, selectedProp } = payload;
      return {
        ...state,
        opened: state.opened.find(u => u[selectedProp] === item[selectedProp])
          ? state.opened.filter(u => u[selectedProp] !== item[selectedProp])
          : [item]
      };
    }
    case CLOSE_ALL: {
      return {
        ...state,
        opened: [],
        selectAll: false,
        selected: []
      };
    }
    case SET_IS_EVENT_ADMIN:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          isGamesEventGroupAdmin: action.payload
        }
      };
    case TOGGLE_SELECT_ALL:
      return {
        ...state,
        selected:
          state.selected.length &&
          state.selected[0][payload.selectedProp] === payload.items[0][payload.selectedProp]
            ? []
            : payload.items,
        currentSelected: {},
        conditions: {
          hasSelected: true,
          isGamesEventGroupAdmin: true
        },
        selectAll: !state.selectAll
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

const onlyIdsAndCategories = map(props(['id', 'categories']));

const mapStateToProps = (state, { role }) => ({
  usersList: role ? getUsersByType(state, role) : []
});

export const unconnected = compose(
  ShowHide,
  withState('filterCount', 'setFilterCount', 0),
  shouldUpdate(
    (
      {
        items,
        eventId,
        type,
        metaData,
        location: { search },
        config,
        isGamesEventGroupAdmin,
        isVisible,
        filterCount,
        isFetching,
        isFetchingInit,
        isFetchingDetails,
        isFetchingEventRoles,
        isFetchingEventUsers,
        hasNextPage
      },
      {
        items: nextItems,
        eventId: nextEventId,
        type: nextType,
        metaData: nextMetaData,
        location: { search: nextSearch },
        config: nextConfig,
        isGamesEventGroupAdmin: nextIsGamesEventGroupAdmin,
        isVisible: nextIsVisible,
        filterCount: nextFilterCount,
        isFetching: nextIsFetching,
        isFetchingInit: nextIsFetchingInit,
        isFetchingDetails: nextIsFetchingDetails,
        isFetchingEventRoles: nextIsFetchingEventRoles,
        isFetchingEventUsers: nextIsFetchingEventUsers,
        hasNextPage: nextHasNextPage
      }
    ) =>
      !equals(onlyIdsAndCategories(items), onlyIdsAndCategories(nextItems)) ||
      !equals(eventId, nextEventId) ||
      !equals(type, nextType) ||
      !equals(metaData, nextMetaData) ||
      !equals(search, nextSearch) ||
      !equals(config, nextConfig) ||
      !equals(isGamesEventGroupAdmin, nextIsGamesEventGroupAdmin) ||
      !equals(isVisible, nextIsVisible) ||
      !equals(filterCount, nextFilterCount) ||
      !equals(isFetching, nextIsFetching) ||
      !equals(isFetchingInit, nextIsFetchingInit) ||
      !equals(isFetchingDetails, nextIsFetchingDetails) ||
      !equals(isFetchingEventRoles, nextIsFetchingEventRoles) ||
      !equals(isFetchingEventUsers, nextIsFetchingEventUsers) ||
      !equals(hasNextPage, nextHasNextPage)
  ),
  withReducer('selectable', 'dispatch', reducer, initialState),
  withHandlers({
    updateResults: ({ eventId, pagination: { onLoadPage }, roles }) => () => {
      onLoadPage({ eventId, roles });
    },
    hideFilter: ({ isVisible, setVisibility }) => () => {
      if (isVisible) {
        setVisibility(false);
      }
    },
    requestUserListCSV: ({ eventId, roles, pagination: { onLoadPage }, type }) => () =>
      new Promise(resolve => onLoadPage({ eventId, roles, toCSV: true, resolve })).then(
        ({ data }) => {
          const blob = new Blob([data], { type: 'text/csv' });
          FileSaver.saveAs(blob, `${type}_list_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
        }
      )
  }),
  withHandlers({
    onKeyPressed: ({ dispatch }) => ({ key }) => {
      if (isShift(key)) {
        dispatch({ type: SHIFT_ON });
      } else if (isCtrl(key)) {
        dispatch({ type: CTRL_ON });
      }
    },
    onKeyReleased: ({ dispatch }) => ({ key }) => {
      if (isShift(key)) {
        dispatch({ type: SHIFT_OFF });
      } else if (isCtrl(key)) {
        dispatch({ type: CTRL_OFF });
      }
    },
    onItemSelected: ({
      selectable: { isShiftSelecting, isCtrlSelecting, currentSelected, selected },
      items,
      dispatch,
      singleSelect,
      selectedProp = 'eventRoleId',
      fetchEventUsersCalendarData,
      eventUsersList,
      setEventUsersList,
      eventId,
      setSelectedUser = () => true
    }) => selectedItem => {
      if (singleSelect) {
        setSelectedUser(
          selectedItem.eventRoleId === currentSelected.eventRoleId ? null : selectedItem
        );
        dispatch({
          type: TOGGLE_SELECT_ITEM,
          payload: { selectedItem, selectedProp }
        });

        if (eventUsersList && eventUsersList.indexOf(selectedItem.id) < 0) {
          setEventUsersList([...eventUsersList, selectedItem.id]);
          fetchEventUsersCalendarData({ id: eventId, user_id: selectedItem.id });
        }
      } else if (isShiftSelecting) {
        const currentSelectedIndex = items.findIndex(item => item.id === currentSelected.id);
        const lastSelectedIndex = items.findIndex(item => item.id === selectedItem.id);
        const minIdx = min(currentSelectedIndex, lastSelectedIndex);
        const maxIdx = max(currentSelectedIndex, lastSelectedIndex);
        const multiSelection = slice(minIdx, maxIdx + 1, items);
        dispatch({
          type: MULTI_SELECT_ITEM,
          payload: { multiSelection, selectedProp }
        });
      } else if (isCtrlSelecting) {
        const idx = selected.findIndex(equals(selectedItem));
        let multiSelection;

        if (idx !== -1) {
          multiSelection = remove(idx, 1, selected);
        } else {
          multiSelection = selected.concat(selectedItem);
        }
        dispatch({
          type: MULTI_SELECT_ITEM,
          payload: { multiSelection, selectedProp }
        });
      } else {
        dispatch({
          type: TOGGLE_SELECT_ITEM,
          payload: { selectedItem, selectedProp }
        });
      }
    },
    onExpandItem: ({
      dispatch,
      onFetchItemDetail,
      selectedProp = 'eventRoleId',
      eventUsersList,
      setEventUsersList,
      eventId,
      fetchEventUsersCalendarData,
      usersDemographicsList,
      setUsersDemographicsList
    }) => (item, expanded) => {
      if (!expanded && eventUsersList && eventUsersList.indexOf(item.id) < 0) {
        setEventUsersList([...eventUsersList, item.id]);
        fetchEventUsersCalendarData({ id: eventId, user_id: item.id });
      }

      if (
        !expanded &&
        usersDemographicsList &&
        usersDemographicsList.indexOf(item.eventRoleId) < 0
      ) {
        onFetchItemDetail(item.id, item.eventRoleId);
        setUsersDemographicsList([...usersDemographicsList, item.eventRoleId]);
      }
      dispatch({ type: TOGGLE_OPEN, payload: { item, selectedProp } });
    },
    unselectItems: ({ dispatch }) => () => dispatch({ type: UNSELECT_ITEMS }),
    onAllSelect: ({ dispatch, selectedProp = 'eventRoleId', usersList: items }) => () =>
      dispatch({ type: TOGGLE_SELECT_ALL, payload: { items, selectedProp } })
  }),
  lifecycle({
    componentWillMount() {
      document.addEventListener('click', this.props.hideFilter);
    },
    componentWillUnmount() {
      document.removeEventListener('click', this.props.hideFilter);
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.isGamesEventGroupAdmin !== prevProps.selectable.conditions.isGamesEventGroupAdmin
      ) {
        this.props.dispatch({
          type: SET_IS_EVENT_ADMIN,
          payload: this.props.isGamesEventGroupAdmin
        });
      }
    },
    componentWillReceiveProps(nextProps) {
      const {
        pagination: { onLoadPage },
        eventId,
        filter,
        roles = { alias: null, list: [] },
        sort,
        search,
        location
      } = nextProps;
      let demographic_data_cleared = false;
      if (
        (this.props.filter && !equals(this.props.filter.filterData, filter.filterData)) ||
        (this.props.sort && !equals(this.props.sort.value, sort.value)) ||
        (this.props.search && !equals(this.props.search.value, search.value)) ||
        !equals(this.props.location.search, location.search)
      ) {
        this.props.dispatch({
          type: CLOSE_ALL
        });
        demographic_data_cleared = true;
      }
      if (eventId !== this.props.eventId || (roles.alias && !equals(roles, this.props.roles))) {
        this.props.dispatch({
          type: RESET_STATE
        });
        demographic_data_cleared = true;

        onLoadPage({ eventId, roles });
      }
      if (this.props.setUsersDemographicsList && demographic_data_cleared) {
        this.props.setUsersDemographicsList([]);
      }
    },
    componentDidMount() {
      const {
        eventId,
        roles = { alias: null, list: [] },
        // userid,
        // allowSelfAssignment,
        pagination: { onLoadPage }
      } = this.props;
      // if (allowSelfAssignment) {
      //   onLoadPage({ eventId, roles, userid });
      // } else {
      onLoadPage({ eventId, roles });
      // }

      this.props.dispatch({
        type: SET_IS_EVENT_ADMIN,
        payload: this.props.isGamesEventGroupAdmin
      });
    }
  })
);

export default compose(connect(mapStateToProps, null), unconnected, pure);
