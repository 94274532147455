import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../Button';
import { required, mustBeEmail, composeValidators } from '../../../../../utils/validations';
import Modal from '../../../Modal';
import { Input } from '../../../../global-material-components';
import { ButtonContainer, UserForm } from './styled-components';
import Enhancer from '../../../../containers/ImportModal';
import { USER_ROLES } from '../../../../../constants';

const useStyles = makeStyles(() => ({
  root: {
    top: '-9px',
    fontSize: '11px'
  },
  slimLabelShrink: {
    transform: 'translate(15px, 5px) scale(0.75)!important'
  }
}));

const AddNewUserModal = ({ onClose, onSubmitManually, type, eventName }) => {
  const classes = useStyles();
  const roleLabel = type[0].toUpperCase() + type.slice(1);
  const [userRole, setUserRole] = useState(roleLabel === 'Admin' ? 'Event Admin' : roleLabel);
  return (
    <Modal
      title={`Add New ${userRole} - ${eventName}`}
      onClose={onClose}
      rightPanel
      background
      userList
      titleStyle={{
        textAlign: 'left',
        fontSize: '16px',
        marginRight: '50px'
      }}
    >
      <UserForm>
        <Form
          onSubmit={onSubmitManually}
          initialValues={{ role: roleLabel !== 'User' ? roleLabel.toLowerCase() : 'admin' }}
          render={({ handleSubmit, pristine, submitting, invalid, form }) => (
            <form
              onSubmit={() => {
                handleSubmit();
                onClose();
              }}
            >
              <Field name="first_name" validate={required}>
                {({ input, meta }) => (
                  <div>
                    <Input
                      {...input}
                      type="text"
                      label="First Name"
                      placeholder="First Name"
                      error={!!meta.error}
                      InputProps={{ style: { height: '35px' } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.root,
                          shrink: classes.slimLabelShrink
                        }
                      }}
                    />
                    {meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="last_name" validate={required}>
                {({ input, meta }) => (
                  <div>
                    <Input
                      {...input}
                      type="text"
                      label="last name"
                      placeholder="Last Name"
                      error={!!meta.error}
                      InputProps={{ style: { height: '35px' } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.root,
                          shrink: classes.slimLabelShrink
                        }
                      }}
                    />
                    {meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="email" validate={composeValidators(required, mustBeEmail)}>
                {({ input, meta }) => (
                  <div>
                    <Input
                      {...input}
                      type="email"
                      label="Email"
                      placeholder="Email"
                      error={!!meta.error}
                      InputProps={{ style: { height: '35px' } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.root,
                          shrink: classes.slimLabelShrink
                        }
                      }}
                    />
                    {meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field
                name="role"
                component="select"
                parse={val => {
                  if (val === 'funding_admin') {
                    setUserRole('Funding Admin');
                  } else if (val === 'admin') {
                    setUserRole('Event Admin');
                  } else {
                    setUserRole(val);
                  }
                  return val;
                }}
              >
                <option value="official">Official</option>
                <option value="assessor">Assessor</option>
                {USER_ROLES.map(user => (
                  <option value={user === 'funding admin' ? 'funding_admin' : user}>
                    {user === 'admin' ? 'Event Admin' : user}
                  </option>
                ))}
              </Field>
              <ButtonContainer>
                <Button
                  type="submit"
                  label={`Create ${userRole} User`}
                  customStyle={{ textTransform: 'capitalize' }}
                  disabled={pristine || submitting || invalid}
                  primary
                />
              </ButtonContainer>
            </form>
          )}
        />
      </UserForm>
    </Modal>
  );
};

AddNewUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitManually: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default Enhancer(AddNewUserModal);
