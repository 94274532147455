import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { formatMoney } from 'accounting-js';
import { Divider, Typography } from '@material-ui/core';
import { Dialog } from '../../../global-material-components';
import {
  SmallerText,
  BoldSpan,
  SpanBlue,
  AmountText,
  Heading,
  SectionHeading,
  AlignCenter
} from '../styled-component';

const ConfirmationPopUp = ({
  openModel,
  closeConfirmationHandler,
  data,
  confirmButtonHandler,
  setAddFund,
  bankName
}) => {
  return (
    <Dialog
      title="Add Funds"
      open={openModel}
      onClose={() => {
        closeConfirmationHandler();
      }}
      cancelAction={() => {
        closeConfirmationHandler();
      }}
      primaryAction={() => {
        confirmButtonHandler(data);
        setAddFund(false);
      }}
      style={{
        width: '450px',
        margin: 'auto'
      }}
    >
      <Heading>
        <Typography>
          <span>
            Are you sure you want to add <AmountText>{formatMoney(data.amount)}</AmountText> to your
            wallet?
          </span>
        </Typography>
      </Heading>
      <Divider />
      <SectionHeading>
        <Row>
          <Col xs={2}>
            <BoldSpan>From:</BoldSpan>
          </Col>
          <Col xs={10} className="paddingZero">
            <BoldSpan>{bankName[0].bank}</BoldSpan>
          </Col>
        </Row>

        <Row>
          <Col xs={2}>
            <BoldSpan>For:</BoldSpan>
          </Col>

          <Col xs={10} className="paddingZero">
            <BoldSpan>{data.description}</BoldSpan>
          </Col>
        </Row>
      </SectionHeading>

      <AlignCenter>
        <SpanBlue>Funds will be available in 4 to 5 business days.</SpanBlue>
      </AlignCenter>

      <SmallerText>
        I understand that these funds will be refunded if they are not used within 30 days, and I
        agree by clicking confirm
      </SmallerText>
    </Dialog>
  );
};

export default ConfirmationPopUp;
