import { compose, lifecycle, withState, withHandlers, pure } from 'recompose';

const ENTER_KEY_CODE = 13;

export const onKeyDown = (event, searchValue, onSearch) => {
  if (event.keyCode === ENTER_KEY_CODE) {
    onSearch(searchValue);
  }
};

export const onClick = (searchValue, onSearch) => {
  onSearch(searchValue);
};

export default compose(
  withState('searchValue', 'setSearchValue', ({ value }) => value),
  withState('showSearch', 'setShowSearch', false),
  withHandlers({
    onChange: props => event => props.setSearchValue(event.target.value)
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.value !== prevProps.value) {
        this.props.setSearchValue(this.props.value);
      }
    }
  }),
  pure
);
