import { takeEvery, call, put, select, takeLatest, all, take } from 'redux-saga/effects';
import { compose, isEmpty, always } from 'ramda';
import moment from 'moment';
import Logger from '../../utils/logger';
import {
  CREATE_EVENT,
  CREATE_EVENT_AND_REDIRECT,
  DELETE_EVENT_AND_REDIRECT,
  FETCH_EVENT,
  UPDATE_EVENT,
  FETCH_EVENTS,
  FETCH_FILTERED_EVENTS,
  DELETE_EVENT_CATEGORY,
  UPDATE_EVENT_CATEGORIES,
  SWITCH_EVENT_STATUS,
  UPDATE_REPORT_SETTINGS,
  UPDATE_ASSESSMENT_SETTINGS,
  UPDATE_GAME_LEVELS,
  UPDATE_CREW_LABELS,
  UPDATE_EVENT_CREW_PAY_TABLE,
  COPY_FEES_FROM_GAME_LEVEL,
  setEventCrewLabels,
  setEventInfo,
  setBillingInfo,
  setTaxInfo,
  setCategories,
  setGameLevels,
  setGameLevel,
  setReportSettings,
  setAssessmentSettings,
  setCrewPay,
  setEvents,
  setFilteredEvents,
  updateEventStatus,
  fetchEvent as fetchEventAction,
  fetchEvents as fetchEventsAction,
  fetchEventInfoCategories as fetchEventInfoCategoriesAction,
  fetchEventInfoCrewLabels as fetchEventInfoCrewLabelsAction,
  fetchEventInfoCrewPay as fetchEventInfoCrewPayAction,
  fetchEventInfoGameLevels as fetchEventInfoGameLevelsAction,
  fetchEventInfoReportSettings as fetchEventInfoReportSettingsAction,
  fetchEventInfoAssessmentSettings as fetchEventInfoAssessmentSettingsAction,
  FETCH_EVENT_GAME_LEVELS,
  FETCH_GAME_LEVEL,
  FETCH_EVENT_CATEGORIES,
  FETCH_ALL_USER_EVENTS,
  setAllUserEvents,
  LINK_GAMES_TO_EVENT,
  setIsFetching,
  setEventPageFetching,
  setCrewWarningModal,
  FETCH_EVENT_USERS,
  setEventUsers,
  FINISHED_FETCH_EVENT,
  FETCH_EVENT_INFO_CATEGORIES,
  FETCH_EVENT_INFO_CREW_LABELS,
  FETCH_EVENT_INFO_GAME_LEVELS,
  FETCH_EVENT_INFO_REPORT_SETTINGS,
  FETCH_EVENT_INFO_ASSESSMENT_SETTINGS,
  FETCH_EVENT_INFO_CREW_PAY,
  FETCH_EVENT_GAME_LOCATION_COUNTS,
  setGameLocationCounts,
  setCopyFeesGameLevelFeching,
  setEventInfoIsFetching,
  FETCH_GOVERNING_BODIES,
  setGoverningBodies,
  FETCH_EVENT_USERS_BY_USER_ID,
  appendEventUsersStateAction,
  setIsFetchingEventUsersAction
} from './index';
import { history } from '../store';
import {
  getEventId,
  getEventStatus,
  getEventGameLevels,
  getEventGameLevelById,
  getLimit
} from './selectors';
import Api from '../../utils/api-wrapper';
import { addNotifications, removeMenuNotification } from '../Notifications';
import { showProgress, hideProgress } from '../Progress';
import { errorProgressDecorate, errorDecorate } from '../Progress/helper';
import { mergeData, dataWithPagination } from '../../utils/parsers';
import {
  preferFirst,
  emptyToNull,
  emptyToZero,
  convertPrices,
  sInterpolator
} from '../../utils/helpers';
import { getUserProfile } from '../Auth/sagas';
import { setSelectedUser } from '../Users';
import { userIdxGetter } from '../../utils/auth';
import { EVENT_INFORMATION, EVENTS } from '../paths';
import { fetchGames } from '../Games';
import { INVITATION_NOTIFICATION_TYPE } from './eventConstants';
import { EVENT_BILLING_CUSTOMER_TYPE, GAMES_STATUSES } from '../../constants';
import { getUsers } from '../Users/selectors';

export function* processFetchEvents({
  payload: {
    filters,
    page,
    sort,
    setLimit,
    toCSV = false,
    userOnly = false,
    noLimit = false,
    isFetchingList = false,
    resolve = always({})
  }
}) {
  let sortWithDefault = sort;
  if (!sort) {
    sortWithDefault = '-created_at';
  }
  const limit = yield noLimit ? null : setLimit || select(getLimit);
  if (isFetchingList) {
    const response = yield call(Api.fetchEventList, {
      filters,
      page,
      limit,
      sortingValue: sortWithDefault,
      userOnly
    });
    if (!isEmpty(response)) {
      yield put(compose(setEvents, dataWithPagination)(response));
      yield put(setSelectedUser(null));
    }
  } else {
    const response = yield call(Api.fetchEvents, {
      filters,
      page,
      limit,
      toCSV,
      sortingValue: sortWithDefault,
      userOnly
    });

    if (!isEmpty(response) && !toCSV) {
      yield put(compose(setEvents, dataWithPagination)(response));
      yield put(setSelectedUser(null));
    }

    if (toCSV) yield call(resolve, response);
  }
}

// fetch list of events on the basis of search.
export function* processFetchFilteredEvents({
  payload: {
    filters,
    page,
    sort,
    setLimit,
    toCSV = false,
    userOnly = false,
    noLimit = false,
    isFetchingList = false,
    resolve = always({})
  }
}) {
  const limit = yield noLimit ? null : setLimit || select(getLimit);
  let sortWithDefault = sort;
  if (!sort) {
    sortWithDefault = '-created_at';
  }
  if (isFetchingList) {
    const response = yield call(Api.fetchEventList, {
      filters,
      page,
      limit,
      sortingValue: sortWithDefault,
      userOnly
    });
    if (!isEmpty(response)) {
      yield put(compose(setFilteredEvents, dataWithPagination)(response));
      yield put(setSelectedUser(null));
    }
  } else {
    const response = yield call(Api.fetchEvents, {
      filters,
      page,
      limit,
      toCSV,
      sortingValue: sortWithDefault,
      userOnly
    });

    if (!isEmpty(response) && !toCSV) {
      yield put(compose(setFilteredEvents, dataWithPagination)(response));
      yield put(setSelectedUser(null));
    }

    if (toCSV) yield call(resolve, response);
  }
}

export function* workGetGoverningBodies() {
  try {
    const result = yield call(Api.fetchGoverningBodies);
    const arrayResult = Object.entries(result);
    const governingBodies = Object.entries(arrayResult[0][1]).map(item => item['1']);
    yield put(setGoverningBodies(governingBodies));
  } catch (e) {
    if (parseInt(e.status, 10) !== 403) {
      yield put(addNotifications(e));
    }
  }
}

export function* fetchGoverningBodies() {
  yield call(FETCH_GOVERNING_BODIES, workGetGoverningBodies);
}
export function* fetchEvents(params) {
  yield call(errorProgressDecorate, processFetchEvents, params, setIsFetching);
}

export function* fetchFilteredEvents(params) {
  if (params.payload.isUserAddAction) {
    yield call(errorProgressDecorate, processFetchFilteredEvents, params);
  } else {
    yield call(errorProgressDecorate, processFetchFilteredEvents, params, setIsFetching);
  }
}

export function* createEvent({ payload: { event, resolve, onLoadPage = always(undefined) } }) {
  try {
    yield put(showProgress());
    const response = yield call(Api.createEvent, { data: event });
    yield call(resolve);
    yield call(onLoadPage);
    yield call(getUserProfile);
    yield put(addNotifications([{ type: 'success', message: 'Event created successfully' }]));

    return response;
  } catch (e) {
    Logger.error(e.message);
    yield put(addNotifications(e.message));
  } finally {
    yield put(hideProgress());
  }
  return {};
}

export function* createEventAndRedirect({ payload: { event, resolve } }) {
  const response = yield call(createEvent, { payload: { event, resolve } });
  if (!isEmpty(response)) {
    const {
      data: { id }
    } = response;

    history.push(sInterpolator({ userIdx: userIdxGetter(), eventId: id }, EVENT_INFORMATION));
  }
}

export function* deleteEvent({ payload: { eventId, resolve } }) {
  try {
    yield put(showProgress());
    yield call(Api.deleteEvent, { eventId });
    yield call(resolve);
    yield put(addNotifications([{ type: 'success', message: 'Event deleted successfully' }]));
  } catch (e) {
    Logger.error(e.message);
    yield put(addNotifications(e.message));
  } finally {
    yield put(hideProgress());
  }
  return {};
}

export function* processDeleteEventAndRedirect({ payload: { eventId, resolve } }) {
  yield call(deleteEvent, { payload: { eventId, resolve } });
  yield put(fetchEventsAction({}));
  history.push(sInterpolator({ userIdx: userIdxGetter() }, EVENTS));
}

export function* deleteEventAndRedirect(params) {
  yield call(errorProgressDecorate, processDeleteEventAndRedirect, params);
}

export function* fetchEventCategories({ payload: { eventId } }) {
  const response = yield call(Api.getEventCategories, { id: eventId });
  yield put(compose(setCategories, mergeData)(response));
}

export function* processFetchEvent({ payload }) {
  yield put(setEventInfoIsFetching(true));
  const { eventId, include_billing_customer_data } = payload;
  const eventInfo = yield call(Api.getEvent, { eventId });
  yield put(compose(setEventInfo, mergeData)(eventInfo));

  if (include_billing_customer_data) {
    const billingInfo = yield call(Api.fetchEventBillingCustomer, {
      eventId,
      customerType: EVENT_BILLING_CUSTOMER_TYPE.GAME_PAYER
    });
    yield put(setBillingInfo(billingInfo));

    const taxInfo = yield call(Api.fetchEventBillingCustomer, {
      eventId,
      customerType: EVENT_BILLING_CUSTOMER_TYPE.BATCH_PAYER
    });
    yield put(setTaxInfo(taxInfo));
  }

  yield put({ type: FINISHED_FETCH_EVENT });
  yield put(setEventInfoIsFetching(false));
}

export function* fetchEvent(params) {
  yield call(errorProgressDecorate, processFetchEvent, params);
}

export function* processFetchEventInfoCategories(params) {
  yield call(fetchEventCategories, { payload: { eventId: params.payload } });
}

export function* fetchEventInfoCategories(params) {
  yield call(errorProgressDecorate, processFetchEventInfoCategories, params, setEventPageFetching);
}

export function* processFetchEventInfoCrewLabels(params) {
  const crew_labels = yield call(Api.getEvent, {
    eventId: params.payload,
    section: 'official-labels'
  });
  yield put(compose(setEventCrewLabels, mergeData)(crew_labels));
}

export function* fetchEventInfoCrewLabels(params) {
  yield call(errorProgressDecorate, processFetchEventInfoCrewLabels, params, setEventPageFetching);
}

export function* processFetchEventInfoGameLevels(params) {
  const game_levels = yield call(Api.getEvent, { eventId: params.payload, section: 'game-levels' });
  yield put(compose(setGameLevels, mergeData)(game_levels));
}

export function* fetchEventInfoGameLevels(params) {
  yield call(errorProgressDecorate, processFetchEventInfoGameLevels, params, setEventPageFetching);
}

export function* processFetchEventInfoReportSettings(params) {
  const report_settings = yield call(Api.getEvent, {
    eventId: params.payload,
    section: 'game-report-settings'
  });
  yield put(compose(setReportSettings, mergeData)(report_settings));
}

export function* fetchEventInfoReportSettings(params) {
  yield call(
    errorProgressDecorate,
    processFetchEventInfoReportSettings,
    params,
    setEventPageFetching
  );
}

export function* processFetchEventInfoAssessmentSettings(params) {
  const assessment_settings = yield call(Api.getEvent, {
    eventId: params.payload,
    section: 'assessment-report-settings'
  });
  yield put(compose(setAssessmentSettings, mergeData)(assessment_settings));
}

export function* fetchEventInfoAssessmentSettings(params) {
  yield call(
    errorProgressDecorate,
    processFetchEventInfoAssessmentSettings,
    params,
    setEventPageFetching
  );
}

export function* processFetchEventInfoCrewPay(params) {
  const eventId = params.payload;
  const crew_pay = yield call(Api.getEvent, { eventId, section: 'game-levels' });
  const crew_labels = yield call(Api.getEvent, { eventId, section: 'official-labels' });
  yield put(compose(setEventCrewLabels, mergeData)(crew_labels));
  yield put(compose(setGameLevels, mergeData)(crew_pay));
  yield put(compose(setCrewPay, mergeData)(crew_pay));
}

export function* fetchEventInfoCrewPay(params) {
  yield call(errorProgressDecorate, processFetchEventInfoCrewPay, params, setEventPageFetching);
}

export function* processUpdateEvent({ payload: fullEvent }) {
  const eventId = yield select(getEventId);
  if (fullEvent.info) {
    yield call(Api.updateEvent, { eventId, section: '', data: fullEvent.info });
  }
  if (fullEvent.isEventGroupAdmin) {
    if (fullEvent.billing) {
      yield call(Api.createEventBillingCustomer, {
        eventId,
        data: { ...fullEvent.billing, customerType: EVENT_BILLING_CUSTOMER_TYPE.GAME_PAYER }
      });
    }

    if (fullEvent.tax_info) {
      yield call(Api.createEventBillingCustomer, {
        eventId,
        data: { ...fullEvent.tax_info, customerType: EVENT_BILLING_CUSTOMER_TYPE.BATCH_PAYER }
      });
    }
  }

  yield put(addNotifications([{ type: 'success', message: 'Event updated successfully' }]));
  yield call(fetchEvent, { payload: { eventId, include_billing_customer_data: true } });
}

export function* updateEvent(params) {
  yield call(errorProgressDecorate, processUpdateEvent, params, setEventPageFetching);
}

export function* deleteCategory({ payload: { id } }) {
  try {
    const eventId = yield select(getEventId);
    yield put(setEventPageFetching(true));
    yield call(Api.deleteCategory, { id });
    yield put(fetchEventInfoCategoriesAction(eventId));
    yield put(addNotifications([{ type: 'success', message: 'Category deleted successfully' }]));
  } catch (error) {
    yield put(addNotifications(error));
  } finally {
    yield put(setEventPageFetching(false));
  }
}

export function* updateCategories({ payload: { newCategories, oldCategories } }) {
  try {
    yield put(setEventPageFetching(true));
    const eventId = yield select(getEventId);
    yield all([
      ...newCategories.map(category => call(Api.createEventCategory, { eventId, data: category }))
    ]);
    yield all([...oldCategories.map(({ id, ...data }) => call(Api.updateCategory, { id, data }))]);
    yield put(fetchEventInfoCategoriesAction(eventId));
    yield put(addNotifications([{ type: 'success', message: 'Categories updated successfully' }]));
  } catch (error) {
    yield put(addNotifications(error));
  } finally {
    yield put(setEventPageFetching(false));
  }
}

export function* createOrUpdateReport({ payload: report_settings }) {
  try {
    yield put(setEventPageFetching(true));
    const eventId = yield select(getEventId);
    yield call(Api.createOrUpdateReport, { eventId, data: report_settings });
    yield put(fetchEventInfoReportSettingsAction(eventId));
    yield put(addNotifications([{ type: 'success', message: 'Settings updated succuessfully' }]));
  } catch (error) {
    yield put(addNotifications(error));
  } finally {
    yield put(setEventPageFetching(false));
  }
}

export function* createOrUpdateAssessment({ payload: assessment_settings }) {
  try {
    yield put(setEventPageFetching(true));
    const eventId = yield select(getEventId);
    yield call(Api.createOrUpdateAssessment, { eventId, data: assessment_settings });
    yield put(fetchEventInfoAssessmentSettingsAction(eventId));
    yield put(addNotifications([{ type: 'success', message: 'Settings updated succuessfully' }]));
  } catch (error) {
    yield put(addNotifications(error));
  } finally {
    yield put(setEventPageFetching(false));
  }
}
export function* processFetchEventGameLevels({ payload: { eventId, filters } }) {
  const response = yield call(Api.getEventGameLevels, { id: eventId, filters });
  yield put(compose(setGameLevels, mergeData)(response));
}

export function* fetchEventGameLevels(params) {
  yield call(errorProgressDecorate, processFetchEventGameLevels, params, setEventPageFetching);
}

export function* processFetchGameLevel({ payload: { gameLevelId } }) {
  const response = yield call(Api.getGameLevel, { id: gameLevelId });
  yield put(compose(setGameLevel, mergeData)(response));
}

export function* fetchGameLevel(params) {
  yield call(errorProgressDecorate, processFetchGameLevel, params, setIsFetching);
}

export function* processCopyFeesFromGameLevel(params) {
  const {
    payload: { gameLevelId, callback }
  } = params;
  yield call(processFetchGameLevel, params);
  const gameLevel = yield select(getEventGameLevelById, gameLevelId);
  callback(gameLevel);
}

export function* copyFeesFromGameLevel(params) {
  yield call(
    errorProgressDecorate,
    processCopyFeesFromGameLevel,
    params,
    setCopyFeesGameLevelFeching
  );
}

export function* processUpdateGameLevels(params) {
  const {
    payload: { crew_labels: crewLabel, resolve }
  } = params;
  const eventId = yield select(getEventId);
  yield call(Api.updateGameLevels, { id: crewLabel.crewLabel.id, data: crewLabel });
  yield put(fetchEventInfoGameLevelsAction(eventId));
  if (crewLabel.crewLabel.set_crew_pay) yield put(fetchEventAction(eventId));
  yield call(resolve);
  yield put(addNotifications([{ type: 'success', message: 'Game Levels updated successfully' }]));
}

export function* updateGameLevels(params) {
  yield call(errorProgressDecorate, processUpdateGameLevels, params, setEventPageFetching);
}

export function* processUpdateCrewLabels({ payload: { crew_labels: crewLabels, resolve } }) {
  const updatedValues = crewLabels;
  const eventId = yield select(getEventId);
  yield call(Api.createEvent, { eventId, section: 'official-labels', data: updatedValues });
  yield put(fetchEventInfoCrewLabelsAction(eventId));
  yield call(resolve);
  yield put(addNotifications([{ type: 'success', message: 'Crew Labels updated successfully' }]));
}

export function* updateCrewLabels(params) {
  yield call(errorProgressDecorate, processUpdateCrewLabels, params, setEventPageFetching);
}

export function* processUpdateCrewPayTable({
  payload: { crew_pay_table, resolve, ignoreWarning, gameLevelId }
}) {
  try {
    const existingEventGameLevels = yield select(getEventGameLevels);
    const existingGameLevel = existingEventGameLevels.find(gl => gl.id === crew_pay_table.id);
    const eventId = yield select(getEventId);

    const updatedAssignorPay = compose(
      convertPrices,
      emptyToNull
    )(preferFirst(crew_pay_table.assignor_pay, existingGameLevel.assignor_pay));

    const updatedTravelPay = compose(
      convertPrices,
      emptyToNull
    )(preferFirst(crew_pay_table.travel_pay, existingGameLevel.travel_pay));

    const updatedCrewPay = crew_pay_table.crew_pay.map(cp =>
      compose(convertPrices, emptyToZero)(cp)
    );

    yield call(Api.updateAssignorPay, { id: crew_pay_table.id, data: updatedAssignorPay });
    yield call(Api.updateTravelPay, { id: crew_pay_table.id, data: updatedTravelPay });

    const crewPayUpdate = yield call(Api.updateCrewPay, {
      id: crew_pay_table.id,
      data: {
        ignoreWarning,
        crewPay: updatedCrewPay,
        gameLevelId
      }
    });
    if (crewPayUpdate.warning) {
      yield put(
        setCrewWarningModal({
          warning: crewPayUpdate.warning,
          gameLevelId,
          crew_pay_table
        })
      );
    } else {
      yield put(fetchEventInfoCrewPayAction(eventId));
      yield take(FINISHED_FETCH_EVENT);
      yield put(
        addNotifications([{ type: 'success', message: 'Crew Pay Table updated successfully' }])
      );

      yield call(resolve);
    }
  } catch (e) {
    yield put(addNotifications(e));
  }
}

export function* updateCrewPayTable(params) {
  yield call(errorProgressDecorate, processUpdateCrewPayTable, params, setEventPageFetching);
}

export function* switchEventStatus() {
  try {
    yield put(setEventPageFetching(true));
    const status = yield select(getEventStatus);
    const eventId = yield select(getEventId);
    yield put(
      updateEventStatus(
        yield call(Api.updateEvent, { eventId, data: { status: status.value ? 0 : 1 } })
      )
    );
    yield put(fetchEventsAction({}));

    yield put(
      addNotifications([{ type: 'success', message: 'Event Status updated successfully' }])
    );
  } catch (error) {
    yield put(addNotifications(error));
  } finally {
    yield put(setEventPageFetching(false));
  }
}
// Fetch event list for Sync games.
export function* processFetchAllUserEvents({ payload }) {
  const response = yield call(Api.fetchEventList, payload);
  if (response) yield put(compose(setAllUserEvents, mergeData)(response));
}

export function* fetchAllUserEvents(params) {
  yield call(errorDecorate, processFetchAllUserEvents, params);
}

export function* processLinkGamesToEvent({ payload: { eventId, invitationId, notificationId } }) {
  yield call(Api.linkGamesToEvent, {
    id: invitationId,
    data: { event_id: eventId }
  });
  yield put(addNotifications([{ type: 'success', message: 'Games were successfully added' }]));
  yield put(
    removeMenuNotification({ id: notificationId, alertType: INVITATION_NOTIFICATION_TYPE })
  );
  yield put(fetchGames({}));
}

export function* linkGamesToEvent(params) {
  yield call(errorProgressDecorate, processLinkGamesToEvent, params);
}

export function* processEventUsersResponse(response) {
  const { usersState, users } = yield all({
    usersState: select(getUsers),
    users: call(mergeData, response)
  });

  const usersWithAvailability = [...usersState.officials.data, ...usersState.assessors.data].map(
    ua => ({
      id: ua.id,
      availabilities: ua.availabilities
    })
  );

  const eventUsers = users.map(u => {
    const user = usersWithAvailability.find(userWithAvail => userWithAvail.id.toString() === u.id);

    if (user) {
      const { availabilities, id } = user;

      return {
        ...u,
        availabilities: availabilities.length
          ? availabilities.map(a => {
              return {
                ...a,
                interval: a.interval && a.interval.trim() !== '' ? a.interval.split(',') : [],
                user_id: id,
                id: a.id.toString(),
                recurring: !!a.recurring,
                time_from: moment.utc(a.time_from).toISOString(),
                time_to: moment.utc(a.time_to).toISOString()
              };
            })
          : []
      };
    }

    return { ...u, availabilities: u.availabilities || [] };
  });

  return eventUsers;
}

export function* processFetchEventUsers({ payload: { id: eventId } }) {
  const response = yield call(Api.fetchEventUsers, { id: eventId });

  if (response) {
    const eventUsers = yield call(processEventUsersResponse, response);
    yield put(setEventUsers(eventUsers));
  }
}

export function* fetchEventUsers(params) {
  yield call(errorProgressDecorate, processFetchEventUsers, params);
}

export function* processFetchEventUsersByUserIdSaga({ payload: { id: eventId, user_id } }) {
  const response = yield call(Api.fetchEventUsersByUserId, { id: eventId, user_id });

  if (response) {
    const eventUsers = yield call(processEventUsersResponse, response);
    yield put(appendEventUsersStateAction(eventUsers));
  }
}

export function* fetchEventUsersByUserIdAPISaga(params) {
  yield call(
    errorProgressDecorate,
    processFetchEventUsersByUserIdSaga,
    params,
    setIsFetchingEventUsersAction
  );
}

export function* processFetchGamesLocationsCount({
  payload: { eventId, gamesFilters = {}, locationFilters = {} }
}) {
  const modifiedGameFilters = { ...gamesFilters };

  if (modifiedGameFilters && !modifiedGameFilters['game.status']) {
    modifiedGameFilters['game.status'] = Object.values(GAMES_STATUSES);
  }

  const [{ data: location = {} }, { data: game = {} }] = yield all([
    call(Api.fetchLocationsCount, { eventId, filters: locationFilters }),
    call(Api.fetchGamesCount, { eventId, filters: modifiedGameFilters })
  ]);
  yield put(setGameLocationCounts({ ...location, ...game }));
}

export function* fetchGameLocationCounts({ payload }) {
  yield call(errorProgressDecorate, processFetchGamesLocationsCount, { payload });
}

export const eventSagas = [
  takeLatest(CREATE_EVENT, createEvent),
  takeEvery(CREATE_EVENT_AND_REDIRECT, createEventAndRedirect),
  takeEvery(DELETE_EVENT_AND_REDIRECT, deleteEventAndRedirect),
  takeLatest(FETCH_EVENT, fetchEvent),
  takeLatest(FETCH_GOVERNING_BODIES, workGetGoverningBodies),
  takeLatest(FETCH_EVENT_INFO_CATEGORIES, fetchEventInfoCategories),
  takeLatest(FETCH_EVENT_INFO_CREW_LABELS, fetchEventInfoCrewLabels),
  takeLatest(FETCH_EVENT_INFO_GAME_LEVELS, fetchEventInfoGameLevels),
  takeLatest(FETCH_EVENT_INFO_REPORT_SETTINGS, fetchEventInfoReportSettings),
  takeLatest(FETCH_EVENT_INFO_ASSESSMENT_SETTINGS, fetchEventInfoAssessmentSettings),
  takeLatest(FETCH_EVENT_INFO_CREW_PAY, fetchEventInfoCrewPay),
  takeEvery(UPDATE_EVENT, updateEvent),
  takeEvery(FETCH_EVENTS, fetchEvents),
  takeEvery(FETCH_FILTERED_EVENTS, fetchFilteredEvents),
  takeEvery(FETCH_EVENT_CATEGORIES, fetchEventCategories),
  takeEvery(DELETE_EVENT_CATEGORY, deleteCategory),
  takeEvery(UPDATE_EVENT_CATEGORIES, updateCategories),
  takeEvery(SWITCH_EVENT_STATUS, switchEventStatus),
  takeEvery(UPDATE_GAME_LEVELS, updateGameLevels),
  takeEvery(UPDATE_REPORT_SETTINGS, createOrUpdateReport),
  takeEvery(UPDATE_ASSESSMENT_SETTINGS, createOrUpdateAssessment),
  takeEvery(UPDATE_CREW_LABELS, updateCrewLabels),
  takeEvery(UPDATE_EVENT_CREW_PAY_TABLE, updateCrewPayTable),
  takeEvery(FETCH_EVENT_GAME_LEVELS, fetchEventGameLevels),
  takeEvery(FETCH_GAME_LEVEL, fetchGameLevel),
  takeEvery(COPY_FEES_FROM_GAME_LEVEL, copyFeesFromGameLevel),
  takeEvery(FETCH_ALL_USER_EVENTS, fetchAllUserEvents),
  takeEvery(LINK_GAMES_TO_EVENT, linkGamesToEvent),
  takeEvery(FETCH_EVENT_USERS, fetchEventUsers),
  takeEvery(FETCH_EVENT_USERS_BY_USER_ID, fetchEventUsersByUserIdAPISaga),
  takeEvery(FETCH_EVENT_GAME_LOCATION_COUNTS, fetchGameLocationCounts)
];

export default eventSagas;
