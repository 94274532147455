import { compose, pure, lifecycle, withHandlers, withState, withProps } from 'recompose';
import { prop } from 'ramda';
import FileSaver from 'file-saver';

import moment from 'moment';
import { generateRefreshToken, convertToTimezone, getTimezoneByCode } from '../../../utils/helpers';
import ROLE_IDS from '../../../core/Roles/roleConstants';
import ShowHide from '../../controls/ShowHide';
import MODAL_NAMES, { FROM_GAMES } from '../../../core/Modals/constants';
import ImportModal from '../../components/Games/ImportModal';
import { formatDate } from '../../../utils/parsers';
import EmailUsersModal from '../../components/SideList/Modals/EmailUsersModal/multiEmailSender';
import { eventFilterLimit } from '../../../constants';
import { getCheckedGames, getGameData, openExternalGameReportURL, parseFormData } from './utils';

const searchEventParms = {
  LIMIT: eventFilterLimit,
  SORTBY: '-created_at'
};

export default compose(
  ShowHide,
  withState('modalEditMode', 'toggleModalEditMode', false),
  withState('modalCopyGameMode', 'toggleModalCopyGameMode', false),
  withState('modalProps', 'setModalProps', {}),
  withState('confirmModifiedGameLevelModalOpen', 'setConfirmModifiedGameLevelModalOpen', false),
  withState('gameLevelChangeConfirmed', 'setGameLevelChangeConfirmed', false),
  withState('selectedRoleId', 'setSelectedRoleId', ROLE_IDS.OFFICIAL),
  withState('roles', 'setRoles', { alias: 'official', list: ['Official'] }),
  withState('showPublishAssignmentsModal', 'setShowPublishAssignmentsModal', false),
  withState('showDeleteGamesModal', 'setShowDeleteGamesModal', false),
  withState('showProgressGameDeletionModal', 'setShowProgressGameDeletionModal', false),
  withState('showPublishGamesModal', 'setShowPublishGamesModal', false),
  withState('showUnpublishGamesModal', 'setShowUnpublishGamesModal', false),
  withState('showAcceptAssignmentsModal', 'setShowAcceptAssignmentsModal', false),
  withState('showAcceptErrorAssignmentsModal', 'setShowAcceptErrorAssignmentsModal', false),
  withState('showDeclineAssignmentsModal', 'setShowDeclineAssignmentsModal', false),
  withState('showAssessmentReportModal', 'setShowAssessmentReportModal', null),
  withState('showImportModal', 'toggleShowImportModal', false),
  withState('checkedGames', 'updateCheckedGames', []),
  withState('gamesInProgress', 'updateGamesInProgress', []),
  withState('filterCount', 'setFilterCount', 0),
  withState('showEmailSelectedModal', 'toggleShowEmailSelectedModal', false),
  withState('assignmentFilter', 'setAssignmentFilter', ''),
  withState('showGameStatusChangeModal', 'setShowGameStatusChangeModal', false),
  withState('gameStatus', 'setGameStatus', ''),
  withState('isEventChanged', 'setIsEventChanged', true),
  withState('activeModalDetails', 'setActiveModalDetails', {}),
  withState('calendarActiveMonth', 'setCalendarActiveMonth', new Date()),
  withState('eventUsersList', 'setEventUsersList', []),
  withState('usersDemographicsList', 'setUsersDemographicsList', []),
  withHandlers({
    handleInProgress: ({ updateGamesInProgress }) => id =>
      updateGamesInProgress(gamesInProgress =>
        gamesInProgress.findIndex(gameId => gameId === id) !== -1
          ? gamesInProgress.filter(gameId => gameId !== id)
          : [...gamesInProgress, id]
      )
  }),
  withHandlers({
    onSubmitModal: ({
      submitGamesUpdate,
      setModalProps,
      toggleModalEditMode,
      setConfirmModifiedGameLevelModalOpen,
      gameLevelChangeConfirmed,
      setGameLevelChangeConfirmed,
      pagination: { onLoadPage },
      timezonesList
    }) => (data, formApi) => {
      if (
        data.game_level.schedule_id !== formApi.getState().initialValues.game_level.schedule_id &&
        !gameLevelChangeConfirmed
      ) {
        setConfirmModifiedGameLevelModalOpen(true);
      } else {
        const statuses = ['', 'new', 'scheduled'];
        const currentData = {
          ...data,
          timezone: getTimezoneByCode(timezonesList, data.timezone).id,
          end_time:
            data &&
            data.start_time &&
            data.duration &&
            moment
              .utc(data.start_time, 'hh:mm:ss A')
              .add(data.duration, 'minutes')
              .format('hh:mm:ss A')
        };
        if (statuses.indexOf(data.status) >= 0) {
          currentData.status = 'updated';
        }
        submitGamesUpdate({
          gameId: prop('id', currentData),
          data: parseFormData(currentData),
          onLoadPage
        });
        toggleModalEditMode(false);
        setConfirmModifiedGameLevelModalOpen(false);
        setGameLevelChangeConfirmed(false);
        setModalProps({});
      }
    },
    onSubmitCopyModal: ({
      submitCopiedGameId,
      setConfirmModifiedGameLevelModalOpen,
      gameLevelChangeConfirmed,
      setGameLevelChangeConfirmed,
      timezonesList,
      pagination: { onLoadPage }
    }) => (data, formApi) => {
      if (
        data.game_level.schedule_id !== formApi.getState().initialValues.game_level.schedule_id &&
        !gameLevelChangeConfirmed
      ) {
        setConfirmModifiedGameLevelModalOpen(true);
      } else {
        const currentData = {
          ...data,
          timezone: getTimezoneByCode(timezonesList, data.timezone).name,
          end_time:
            data &&
            data.start_time &&
            data.duration &&
            moment
              .utc(data.start_time, 'hh:mm:ss A')
              .add(data.duration, 'minutes')
              .format('hh:mm:ss A')
        };
        submitCopiedGameId({
          gameId: prop('id', currentData),
          data: parseFormData(currentData),
          onLoadPage
        });
        setConfirmModifiedGameLevelModalOpen(false);
        setGameLevelChangeConfirmed(false);
      }
    },
    hideFilter: ({ isVisible, setVisibility }) => () => {
      if (isVisible) {
        setVisibility(false);
      }
    },
    handleAssignmentFilter: ({
      filter,
      setAssignmentFilter,
      pagination,
      setPaginationPage
    }) => e => {
      setPaginationPage(pagination && pagination.page);
      setAssignmentFilter(e);
      filter.onFilter({ ...filter.filterData, assignmentFilter: e });
    },
    handleGameStatus: ({ setGameStatus }) => e => {
      setGameStatus(e);
    },
    onRemoveAssignment: ({ deleteGameAssignments, handleInProgress, roles }) => ({
      gameId,
      id,
      userId,
      eventRoleId,
      crewLabelCol,
      checkReport
    }) =>
      deleteGameAssignments({
        gameId,
        id,
        resolve: handleInProgress,
        userId,
        eventRoleId,
        crewLabelCol,
        type: roles.alias,
        checkReport
      }),
    handleCheckboxClick: ({ updateCheckedGames }) => ({ id, checked }) =>
      updateCheckedGames(checkedGames =>
        checked ? [...checkedGames, id] : checkedGames.filter(gameId => gameId !== id)
      ),
    handleSelectAll: ({ updateCheckedGames, games }) => checked =>
      updateCheckedGames(checked ? games.map(game => game.id) : []),
    onAssign: ({ createAssignment, handleInProgress, selectedUser, gamesEvent, roles }) => (
      data,
      game
    ) =>
      createAssignment({
        ...data,
        external_start_date: convertToTimezone(
          `${game.start_date} ${game.start_time}`,
          game.timezone
        ),
        external_end_date: convertToTimezone(`${game.end_date} ${game.end_time}`, game.timezone),
        timezone: game.timezone,
        external_event_id: game.display_id,
        external_location_id: game.external_location_id,
        game_status: game.status,
        game_published: game.published,
        userId: selectedUser.id,
        eventId: gamesEvent.id,
        game_level_id: game.game_level_id,
        ignore_rules: game.ignore_rules,
        resolve: handleInProgress,
        type: roles.alias
      }),
    onPublishAssignments: ({ updateGameAssignments, updateCheckedGames }) => assignments =>
      new Promise(resolve =>
        updateGameAssignments({
          assignmentIds: assignments.map(({ id }) => id),
          data: { status: 'pending' },
          message: 'Successfully published assignment(s)',
          resolve
        })
      ).then(() => updateCheckedGames([])),
    onGameStatusChanged: ({ pagination: { onLoadPage }, updateGames, gameStatus }) => games =>
      updateGames({
        gameIds: games.reduce((acc, game) => {
          if (game.status !== gameStatus) acc.push(game.id);
          return acc;
        }, []),
        onLoadPage,
        data: { status: gameStatus },
        message: 'Successfully game(s) status changed'
      }),
    onDeleteGames: ({
      pagination: { onLoadPage },
      deleteBulkGames,
      setShowProgressGameDeletionModal
    }) => games => {
      setShowProgressGameDeletionModal(s => !s);
      return new Promise(resolve =>
        deleteBulkGames({
          data: { gamesData: getGameData(games) },
          onLoadPage,
          resolve
        })
      ).then(csvData => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        FileSaver.saveAs(blob, `game_deletion_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
      });
    },
    onPublishGames: ({ pagination: { onLoadPage }, updateGames }) => games =>
      updateGames({
        gameIds: games.map(game => game.id),
        onLoadPage,
        data: { published: true },
        message: 'Successfully opened self-assignment'
      }),
    onUnpublishGames: ({ pagination: { onLoadPage }, updateGames }) => games =>
      updateGames({
        gameIds: games.map(game => game.id),
        onLoadPage,
        data: { published: false },
        message: 'Successfully closed self-assignment'
      }),
    onAcceptAssignments: ({ updateGameAssignments, updateCheckedGames }) => assignments =>
      new Promise(resolve =>
        updateGameAssignments({
          assignmentIds: assignments.map(({ id }) => id),
          data: { status: 'accepted' },
          message: 'Successfully accepted assignment(s)',
          resolve
        })
      ).then(() => updateCheckedGames([])),
    handleFetchUserAssignment: ({ setAssignmentAceeptanceUser }) => user => {
      if (!user) {
        setAssignmentAceeptanceUser({ flag: false });
      } else {
        setAssignmentAceeptanceUser({ user, flag: true });
      }
    },
    onDeclineAssignments: ({ updateGameAssignments }) => ({ assignments, note }) =>
      updateGameAssignments({
        assignmentIds: assignments.map(({ id }) => id),
        data: { status: 'declined', note },
        message: 'Successfully declined assignment(s)'
      }),
    onAssessmentReportClick: ({
      storeReportsEventAndRedirect,
      setShowAssessmentReportModal,
      setSelectedReportsEvent,
      gamesEvent,
      crewLabels
    }) => ({ eventId, gameId, assignmentOfficial }) => {
      const report = assignmentOfficial && assignmentOfficial.assessment_report;
      setSelectedReportsEvent({ id: gamesEvent.id });

      return report && report.approved
        ? setShowAssessmentReportModal({
            crewLabel: crewLabels[assignmentOfficial.official_label_col].label,
            gameId: assignmentOfficial.external_game_id,
            reportId: report.id
          })
        : storeReportsEventAndRedirect({ id: eventId, gameId });
    },
    onIncidentReportClick: ({
      setCurrentGame,
      games,
      setShowModalFor,
      setActiveModalDetails,
      gamesEvent,
      setSelectedReportsEvent
    }) => gameId => {
      setCurrentGame(games.find(game => game.id === gameId));
      setSelectedReportsEvent({ id: gamesEvent.id });
      setShowModalFor({ name: MODAL_NAMES.incidentReportsModal, from: FROM_GAMES });
      setActiveModalDetails({
        name: MODAL_NAMES.incidentReportsModal
      });
    },
    onGamesReportClick: ({
      setCurrentGame,
      games,
      setSelectedReportsEvent,
      setShowModalFor,
      isGameUploadTypeManual,
      userIdx,
      user,
      setActiveModalDetails,
      gamesEvent
    }) => async gameId => {
      const game = games.find(item => item.id === gameId);
      setSelectedReportsEvent({ id: gamesEvent.id });
      // not manual and for whatever reason has a report url
      // report urls are populated by integrating parties but that is just happenstance
      // treat the existence of a value as an override
      if (!isGameUploadTypeManual && game && game.report_url) {
        const token = await generateRefreshToken(userIdx, user);
        openExternalGameReportURL(game.report_url, token);
        return;
      }
      if (game && game.allow_game_reports) {
        setCurrentGame(game);
        setShowModalFor({ name: MODAL_NAMES.gameReportsModal, from: FROM_GAMES });
        setActiveModalDetails({
          name: MODAL_NAMES.gameReportsModal
        });
      }
    },
    onCrewVerificationClick: ({
      setCurrentGame,
      games,
      setShowModalFor,
      setSelectedReportsEvent,
      gamesEvent
    }) => async gameId => {
      const game = games.find(item => item.id === gameId);
      setCurrentGame(game);
      setSelectedReportsEvent({ id: gamesEvent.id });
      setShowModalFor({ name: MODAL_NAMES.crewVerificationReportsModal, from: FROM_GAMES });
      return true;
    }
  }),
  withProps(({ games, checkedGames }) => ({
    getGameOptionsForNonIntegratedGames: () => {
      const options = ['isAuthorized'];
      const checkedGamesList = getCheckedGames(checkedGames, games);
      const checkedGamesCount = (checkedGamesList && checkedGamesList.length) || 0;

      const copiedGames = checkedGamesList.filter(game => game && game.copy);
      const copiedGamesCount = (copiedGames && copiedGames.length) || 0;

      if (!copiedGames.length || checkedGamesCount !== copiedGamesCount)
        options.push('isGameUploadTypeManual');

      return options;
    }
  })),
  withProps(
    ({
      downloadGames,
      showPublishAssignmentsModal,
      PublishAssignmentsModal,
      setShowPublishAssignmentsModal,
      DeleteGamesModal,
      showDeleteGamesModal,
      setShowDeleteGamesModal,
      ProgressGameDeletionModal,
      showProgressGameDeletionModal,
      setShowProgressGameDeletionModal,
      onDeleteGames,
      onPublishAssignments,
      showPublishGamesModal,
      PublishGamesModal,
      setShowPublishGamesModal,
      onPublishGames,
      showUnpublishGamesModal,
      UnpublishGamesModal,
      setShowUnpublishGamesModal,
      onUnpublishGames,
      AcceptAssignmentsModal,
      setShowAcceptAssignmentsModal,
      showAcceptAssignmentsModal,
      onAcceptAssignments,
      DeclineAssignmentsModal,
      setShowDeclineAssignmentsModal,
      showDeclineAssignmentsModal,
      onDeclineAssignments,
      showImportModal,
      toggleShowImportModal,
      submitGamesImport,
      gamesEvent,
      sendEmail,
      toggleShowEmailSelectedModal,
      showEmailSelectedModal,
      GameStatusChangeModal,
      showGameStatusChangeModal,
      setShowGameStatusChangeModal,
      onGameStatusChanged,
      gameStatus,
      handleGameStatus,
      setGameStatus,
      getGameOptionsForNonIntegratedGames,
      isGameUploadTypeManual,
      handleFetchUserAssignment
    }) => ({
      config: {
        action: {
          options: [
            {
              label: 'Download List',
              showOption: ['isAuthorized'],
              clickableOption: [],
              onClick: downloadGames
            },
            {
              component: GameStatusChangeModal,
              label: 'Change Game Status',
              clickableOption: ['gamesSelected'],
              showOption: getGameOptionsForNonIntegratedGames(),
              showModal: showGameStatusChangeModal,
              onClick: () => {
                setGameStatus('');
                setShowGameStatusChangeModal(s => !s);
              },
              onSubmit: onGameStatusChanged,
              handleGameStatus,
              gameStatus
            },
            {
              component: EmailUsersModal,
              label: 'Email Selected Game Crews',
              showOption: ['gamesSelected', 'isAuthorized'],
              showModal: showEmailSelectedModal,
              onClick: toggleShowEmailSelectedModal,
              onSubmit: sendEmail
            },
            {
              component: ImportModal,
              label: `Upload Games`,
              showOption: ['canUploadGames', 'isGameUploadTypeManual'],
              showModal: showImportModal,
              onClick: toggleShowImportModal,
              onSubmit: ({ file, resolve }) =>
                submitGamesImport({ eventId: gamesEvent.id, file, resolve })
            },
            {
              component: PublishGamesModal,
              label: 'Open self-assignment',
              showOption: ['isAuthorized'],
              clickableOption: ['gamesSelected'],
              showModal: showPublishGamesModal,
              onClick: () => setShowPublishGamesModal(s => !s),
              onSubmit: onPublishGames
            },
            {
              component: UnpublishGamesModal,
              label: 'Close self-assignment ',
              showOption: ['isAuthorized'],
              clickableOption: ['gamesSelected'],
              showModal: showUnpublishGamesModal,
              onClick: () => setShowUnpublishGamesModal(s => !s),
              onSubmit: onUnpublishGames
            },
            {
              component: PublishAssignmentsModal,
              label: 'Publish Assignments',
              showOption: ['isAuthorized'],
              clickableOption: ['gamesSelected'],
              showModal: showPublishAssignmentsModal,
              onClick: () => setShowPublishAssignmentsModal(s => !s),
              onSubmit: onPublishAssignments
            },
            {
              component: AcceptAssignmentsModal,
              label: 'Accept Assignments',
              showOption: [],
              clickableOption: ['singleGameSelected'],
              showModal: showAcceptAssignmentsModal,
              onClick: () => setShowAcceptAssignmentsModal(s => !s),
              onSubmit: onAcceptAssignments,
              fetchUserAssignments: handleFetchUserAssignment
            },
            {
              component: DeclineAssignmentsModal,
              label: 'Decline Assignments',
              showOption: ['canDecline'],
              clickableOption: ['singleGameSelected'],
              showModal: showDeclineAssignmentsModal,
              onClick: () => setShowDeclineAssignmentsModal(s => !s),
              onSubmit: onDeclineAssignments
            },
            {
              component: DeleteGamesModal,
              label: 'Delete Selected',
              showOption: getGameOptionsForNonIntegratedGames(),
              clickableOption: ['gamesSelected'],
              showModal: showDeleteGamesModal,
              onClick: () => setShowDeleteGamesModal(s => !s),
              onSubmit: onDeleteGames,
              props: { isGameUploadTypeManual }
            },
            {
              label: 'Hidden',
              component: ProgressGameDeletionModal,
              showOption: ['noShow'],
              showModal: showProgressGameDeletionModal,
              onClick: () => setShowProgressGameDeletionModal(s => !s)
            }
          ]
        }
      }
    })
  ),
  withProps(({ roles }) => ({
    type: roles.alias
  })),
  lifecycle({
    componentWillMount() {
      document.addEventListener('click', this.props.hideFilter);
    },
    componentWillUnmount() {
      document.removeEventListener('click', this.props.hideFilter);
      this.props.unsetShowModalFor({ name: this.props.activeModalDetails.name, from: FROM_GAMES });
    },
    componentDidMount() {
      if (this.props.fetchEvents)
        this.props.fetchEvents({
          userOnly: !this.props.isSuperAdmin,
          setLimit: searchEventParms.LIMIT,
          sort: searchEventParms.SORTBY,
          isFetchingList: true
        });
      if (this.props.fetchFilteredEvents && this.props.gamesEvent && this.props.gamesEvent.name)
        this.props.fetchFilteredEvents({
          filters: { search: this.props.gamesEvent.name },
          sort: searchEventParms.SORTBY,
          userOnly: !this.props.isSuperAdmin,
          noLimit: true,
          isFetchingList: true
        });
      if (this.props.pagination && this.props.pagination.assignmentFilter)
        this.props.setAssignmentFilter(this.props.pagination.assignmentFilter);
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.gamesEvent &&
        (!prevProps.gamesEvent || prevProps.gamesEvent.id !== this.props.gamesEvent.id)
      ) {
        this.props.fetchGamesEventCrewLabels();
        this.props.setEventUsersList([]);
        this.props.setUsersDemographicsList([]);
        this.props.clearEventUsersData({});

        this.props.pagination.onLoadPage();
        this.props.setIsEventChanged(true);
        this.props.fetchEventInfoReportSettings(this.props.gamesEvent.id);
      }
      if (prevProps.gamesEvent && this.props.gamesEvent) {
        if (prevProps.gamesEvent.id !== this.props.gamesEvent.id) {
          this.props.setAssignmentFilter('');
        }
      }

      if (this.props.games !== prevProps.games) this.props.updateCheckedGames([]);
    }
  }),
  pure
);
