import React from 'react';
import { isNil, range } from 'ramda';
import InputWrapper from '../InputWrapper';
import {
  printValue,
  printDate,
  normalizePhone,
  fromMomentTo12Hours,
  from12HoursToMoment,
  returnOnlyNumbers
} from '../../../../utils/parsers';
import MaterialInput from '../../MaterialInput';
import MaterialDatePicker from '../../../controls/MaterialDatePicker';
import MaterialDropDown from '../../MaterialDropDown';
import MaterialTimePicker from '../../MaterialTimePicker';
import { usaStates } from '../../../../constants';
import {
  required,
  mustBeEmail,
  minLength,
  composeValidators,
  isValidAreaCode
} from '../../../../utils/validations';

const createLevelOptions = gameLevels =>
  gameLevels.map(({ game_level, game_type, schedule_id }) => ({
    name: `${game_level} / ${game_type}`,
    value: schedule_id
  }));

const LABELS = ['Pos. 1', 'Pos. 2', 'Pos. 3', 'Pos. 4', 'Pos. 5', 'Pos. 6', 'Pos. 7', 'Pos. 8'];

export const generalInfoConfig = (
  readOnly,
  gameLevels,
  display_id,
  start_date,
  start_time,
  end_date,
  end_time,
  game_level,
  schedule_name,
  schedule_owner,
  duration
) =>
  [
    {
      placeholder: 'Game ID',
      value: printValue(display_id),
      readOnly: true,
      disabled: !readOnly,
      component: MaterialInput,
      name: 'display_id',
      validate: required,
      alwaysShowError: true
    },
    {
      placeholder: 'Date',
      value: printDate(start_date),
      readOnly,
      component: MaterialDatePicker,
      name: 'start_date',
      validate: required,
      alwaysShowError: true
    },
    {
      placeholder: 'Start Time',
      value: printValue(start_time),
      readOnly,
      format: from12HoursToMoment,
      parse: fromMomentTo12Hours,
      component: MaterialTimePicker,
      name: 'start_time',
      // TODO: Remove this once QA passedgit
      // validate: composeValidators(required, (val, { end_time: endTime }) =>
      //   isSameOrBefore(val, endTime, undefined, '"Start Time" cannot be greater than "End Time"')
      // ),
      alwaysShowError: true
    },
    {
      placeholder: 'End Time',
      value: printValue(end_time),
      readOnly: true,
      format: from12HoursToMoment,
      parse: fromMomentTo12Hours,
      component: MaterialTimePicker,
      validateFields: ['start_time'],
      name: 'end_time',
      disabled: !readOnly,
      alwaysShowError: true
    },
    {
      placeholder: 'Duration',
      value: `${printValue(duration)} Min`,
      disabled: !readOnly,
      component: MaterialInput,
      name: 'duration',
      validate: required,
      alwaysShowError: true
    },
    {
      placeholder: 'Period Length',
      value: printValue(isNil(game_level.length_period) ? '' : `${game_level.length_period} Min`),
      readOnly: true,
      disabled: !readOnly
    },
    {
      placeholder: 'Level / Type',
      value: printValue(`${game_level.game_level} / ${game_level.game_type}`),
      readOnly,
      component: MaterialDropDown,
      options: createLevelOptions(gameLevels),
      name: 'game_level.schedule_id',
      validate: required,
      alwaysShowError: true
    },
    {
      placeholder: 'Schedule',
      value: printValue(schedule_name),
      readOnly: true,
      disabled: !readOnly
    },
    {
      placeholder: 'Schedule Owner',
      value: printValue(schedule_owner),
      readOnly: true,
      disabled: !readOnly
    },
    {
      component: () => null,
      readOnly: false,
      name: 'empty_space'
    }
  ].map(({ placeholder, value = '', pair, ...rest }) => (
    <InputWrapper value={value} placeholder={placeholder} pair={pair} {...rest} />
  ));

export const crewConfig = (assignments_game, game_level, gameList) => {
  const game_assigments = [];
  let crewLabels = LABELS;
  range(0, 8).forEach(index => {
    const assignment = assignments_game.find(
      assignment_game => assignment_game.official_label_col === index
    );
    if (assignment && ['pending', 'accepted', 'declined'].indexOf(assignment.status) > -1) {
      const gameLevel = gameList.find(game => game.game_level === game_level.game_level);
      if (gameLevel) {
        crewLabels = gameLevel.labels;
      }
      game_assigments.push({
        placeholder: `${crewLabels[assignment.official_label_col]}`,
        value: printValue(
          assignment.event_role.user.first_name + assignment.event_role.user.last_name
        )
      });
      game_assigments.push({ hidden: true });
      game_assigments.push({
        placeholder: `${crewLabels[assignment.official_label_col]} Phone`,
        value: printValue(assignment.event_role.user.primary_phone_number)
      });
      game_assigments.push({
        placeholder: `${crewLabels[assignment.official_label_col]} email`,
        value: printValue(assignment.event_role.user.email)
      });
    }
    return null;
  });

  return game_assigments.map(
    ({
      placeholder,
      value = '',
      component = MaterialInput,
      hidden = false,
      pair,
      readOnly = true,
      ...rest
    }) =>
      hidden ? (
        <div />
      ) : (
        <InputWrapper
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          component={component}
          disabled
          {...rest}
        />
      )
  );
};

export const locationConfig = ({
  readOnly,
  field_name,
  name,
  address,
  address_two,
  city,
  state,
  postal_code,
  timezone,
  timezonesList
}) => {
  return [
    {
      value: printValue(name),
      placeholder: 'Complex',
      readOnly,
      component: MaterialInput,
      name: 'location.name',
      validate: required,
      alwaysShowError: true
    },
    {
      placeholder: 'Location',
      value: printValue(field_name),
      readOnly,
      component: MaterialInput,
      name: 'location.surface_name',
      parse: null
    },
    {
      placeholder: 'Location Address',
      value: printValue(address),
      readOnly,
      component: MaterialInput,
      name: 'location.address',
      validate: required,
      alwaysShowError: true
    },
    {
      placeholder: 'Location Address 2',
      value: printValue(address_two),
      readOnly,
      component: MaterialInput,
      name: 'location.address_two'
    },
    {
      placeholder: 'City',
      value: printValue(city),
      readOnly,
      component: MaterialInput,
      name: 'location.city',
      validate: required,
      alwaysShowError: true
    },
    {
      pair: [
        {
          placeholder: 'State/Province',
          value: printValue(state),
          readOnly,
          component: MaterialDropDown,
          options: usaStates,
          name: 'location.state',
          validate: required,
          alwaysShowError: true
        },
        {
          placeholder: 'Zip',
          value: printValue(postal_code),
          readOnly,
          component: MaterialInput,
          name: 'location.postal_code',
          maxLength: 7,
          validate: required,
          alwaysShowError: true
        }
      ]
    },
    {
      placeholder: 'Timezone',
      value: printValue(timezone),
      component: MaterialDropDown,
      options: timezonesList,
      readOnly,
      name: 'timezone',
      validate: required,
      showError: true
    },
    {
      component: () => null,
      readOnly: false,
      name: 'empty_space'
    }
  ].map(({ placeholder, value = '', pair, ...rest }) => (
    <InputWrapper value={value} placeholder={placeholder} pair={pair} {...rest} />
  ));
};

export const teamsConfig = (readOnly, team_home, home_score, team_visitor, visitor_score) =>
  [
    {
      placeholder: 'Home Team',
      value: printValue(team_home.name),
      readOnly,
      component: MaterialInput,
      name: 'team_home.name',
      validate: required,
      alwaysShowError: true
    },
    {
      placeholder: 'Home Team Score',
      value: printValue(home_score),
      readOnly: true,
      disabled: !readOnly
    },
    {
      placeholder: 'Home Team Club',
      value: printValue(team_home.club_name),
      readOnly,
      component: MaterialInput,
      name: 'team_home.club_name',
      parse: null
    },
    {
      placeholder: 'Home Team Contact Name',
      value: printValue(team_home.contact_name),
      readOnly,
      component: MaterialInput,
      name: 'team_home.contact_name',
      parse: null
    },
    {
      placeholder: 'Home Team Contact Phone',
      value: printValue(normalizePhone(team_home.contact_phone)),
      readOnly,
      component: MaterialInput,
      format: normalizePhone,
      parse: returnOnlyNumbers,
      validate: composeValidators(minLength(10), isValidAreaCode),
      name: 'team_home.contact_phone',
      alwaysShowError: true
    },
    {
      placeholder: 'Home Team Contact Email',
      value: printValue(team_home.contact_email),
      readOnly,
      component: MaterialInput,
      validate: mustBeEmail,
      name: 'team_home.contact_email',
      alwaysShowError: true,
      parse: null
    },
    {
      placeholder: 'Home Team Division',
      value: printValue(team_home.division),
      readOnly,
      component: MaterialInput,
      name: 'team_home.division',
      parse: null
    },
    {
      placeholder: 'Home Team Age',
      value: printValue(team_home.age_group),
      readOnly,
      component: MaterialInput,
      name: 'team_home.age_group',
      parse: null
    },
    {
      placeholder: 'Away Team',
      value: printValue(team_visitor.name),
      readOnly,
      component: MaterialInput,
      name: 'team_visitor.name',
      validate: required
    },
    {
      placeholder: 'Away Team Score',
      value: printValue(visitor_score),
      readOnly: true,
      disabled: !readOnly
    },
    {
      placeholder: 'Away Team Club',
      value: printValue(team_visitor.club_name),
      readOnly,
      component: MaterialInput,
      name: 'team_visitor.club_name',
      parse: null
    },
    {
      placeholder: 'Away Team Contact Name',
      value: printValue(team_visitor.contact_name),
      readOnly,
      component: MaterialInput,
      name: 'team_visitor.contact_name',
      parse: null
    },
    {
      placeholder: 'Away Team Contact Phone',
      value: printValue(normalizePhone(team_visitor.contact_phone)),
      readOnly,
      component: MaterialInput,
      format: normalizePhone,
      parse: returnOnlyNumbers,
      validate: composeValidators(minLength(10), isValidAreaCode),
      name: 'team_visitor.contact_phone',
      alwaysShowError: true
    },
    {
      placeholder: 'Away Team Contact Email',
      value: printValue(team_visitor.contact_email),
      readOnly,
      component: MaterialInput,
      validate: mustBeEmail,
      name: 'team_visitor.contact_email',
      alwaysShowError: true,
      parse: null
    },
    {
      placeholder: 'Away Team Division',
      value: printValue(team_visitor.division),
      readOnly,
      component: MaterialInput,
      name: 'team_visitor.division',
      parse: null
    },
    {
      placeholder: 'Away Team Age',
      value: printValue(team_visitor.age_group),
      readOnly,
      component: MaterialInput,
      name: 'team_visitor.age_group',
      parse: null
    }
  ].map(({ placeholder, value = '', pair, ...rest }) => (
    <InputWrapper value={value} placeholder={placeholder} pair={pair} {...rest} />
  ));
