import React from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { nest } from 'recompose';
import OutsideClickHandler from 'react-outside-click-handler';

import { Float, Close, TitleWrapper, Title, Back, BackBtnWrapper } from './styled-components';
import Top from '../../layouts/Top';
import Paper from '../../layouts/Paper';
import Icon from '../Icon';
import { COLORS, ICONS } from '../../../utils/styles';
import { StyledIcon } from '../SideMenu/styled-components';

const handleOuterClick = (event, onClose) => {
  if (event.currentTarget === event.target) {
    onClose();
  }
};

const Wrapper = nest(
  ({ background, children, onClose, leftPanel, rightPanel, wrapperStyle }) => (
    <Float
      onClick={event => handleOuterClick(event, onClose)}
      background={background}
      leftPanel={leftPanel}
      rightPanel={rightPanel}
      wrapperStyle={wrapperStyle}
    >
      {children}
    </Float>
  ),
  ({ children, size, onClose }) => (
    <Top onClick={event => handleOuterClick(event, onClose)} {...size}>
      {children}
    </Top>
  ),
  Paper
);

const Modal = ({
  children,
  title,
  inCrossEditMode,
  onClose,
  size,
  background,
  leftPanel,
  rightPanel,
  overflow,
  onBack,
  titleStyle,
  wrapperStyle
}) =>
  ReactDOM.createPortal(
    <OutsideClickHandler onOutsideClick={() => {}}>
      <Wrapper
        size={size}
        background={background}
        onClose={onClose}
        leftPanel={leftPanel}
        rightPanel={rightPanel}
        overflow={overflow}
        wrapperStyle={wrapperStyle}
      >
        <TitleWrapper>
          <Row>
            <Col xs={12}>
              {onBack && (
                <BackBtnWrapper>
                  <Back>
                    <Icon
                      width={20}
                      height={20}
                      icon={ICONS.BACK_ARROW}
                      color={COLORS.white}
                      onClick={onBack}
                      clickable
                    />
                  </Back>
                </BackBtnWrapper>
              )}
              <Title
                textAlign={titleStyle && titleStyle.textAlign}
                fontSize={titleStyle && titleStyle.fontSize}
                marginRight={titleStyle && titleStyle.marginRight}
              >
                {inCrossEditMode && (
                  <StyledIcon
                    height={25}
                    width={25}
                    icon={ICONS.TRIANGLE_EXCLAMATION}
                    color={COLORS.lightOrange}
                    style={{ marginRight: '10px', marginTop: -6 }}
                  />
                )}
                {title}
              </Title>
              <Close onClick={onClose} type="button">
                <Icon height={28} width={28} icon={ICONS.CLOSE} color="white" />
              </Close>
            </Col>
          </Row>
        </TitleWrapper>
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </Wrapper>
    </OutsideClickHandler>,
    document.body
  );

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  background: PropTypes.bool,
  size: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number
  }),
  leftPanel: PropTypes.bool,
  rightPanel: PropTypes.bool,
  overflow: PropTypes.bool,
  onBack: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

Modal.defaultProps = {
  title: '',
  background: true,
  size: {
    xs: 10,
    sm: 10,
    md: 9,
    lg: 9
  },
  leftPanel: false,
  rightPanel: false,
  overflow: false,
  onBack: null
};

export default Modal;
