import React from 'react';
import PropTypes from 'prop-types';

import { LogoConnect, Description, FooterLink } from './styled-components';
import logoConnect from '../../../assets/images/new-official-connect-logo-white-red.png';
import { version } from '../../../../package.json';
import { URLS } from '../../../constants';

const AuthLayout = ({ children }) => (
  <>
    <LogoConnect src={logoConnect} alt="logoConnect" />
    {children}
    <Description>
      By signing in, you agree to our{' '}
      <FooterLink onClick={() => window.open(URLS.TERMS_OF_SERVICE)}>Terms of Service</FooterLink>{' '}
      and
      <FooterLink onClick={() => window.open(URLS.PRIVACY_POLICY)}> Privacy Policy</FooterLink>
    </Description>
    <div title={version} />
  </>
);

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default AuthLayout;
