import { all } from 'redux-saga/effects';

import AuthSagas from './Auth/sagas';
import NotificationsSagas from './Notifications/sagas';
import ProgressSagas from './Progress/sagas';
import certsSagas from './Certifications/sagas';
import eventsSagas from './Events/sagas';
import usersSagas from './Users/sagas';
import sportsSagas from './Sports/sagas';
import rolesSagas from './Roles/sagas';
import gamesSagas from './Games/sagas';
import teamsSagas from './Teams/sagas';
import locationsSagas from './Locations/sagas';
import userSagas from './User/sagas';
import reportsSagas from './Reports/sagas';
import paymentsSagas from './Payments/sagas';
import platformSagas from './Platform/sagas';
import billingCustomerSagas from './BillingCustomers/sagas';

/**
 * Combine all reducers in this file and export the combined reducers.
 *
 * @return {object} combined sagas
 */
function* rootSaga() {
  yield all([
    ...AuthSagas,
    ...NotificationsSagas,
    ...ProgressSagas,
    ...certsSagas,
    ...eventsSagas,
    ...sportsSagas,
    ...rolesSagas,
    ...usersSagas,
    ...gamesSagas,
    ...teamsSagas,
    ...locationsSagas,
    ...userSagas,
    ...reportsSagas,
    ...paymentsSagas,
    ...platformSagas,
    ...billingCustomerSagas
  ]);
}

export default rootSaga;
