import { compose, withHandlers, pure, lifecycle, withState } from 'recompose';
import moment from 'moment';
import { isNil } from 'ramda';
import { CORE_ROLES, DATE_FORMATS, GAMES_STATUSES, PARAM_PREFIXES } from '../../../constants';
import { userIdxGetter } from '../../../utils/auth';
import {
  addPrefixToKeys,
  formatGameFilterEndDate,
  formatGameFilterStartDate,
  sInterpolator as routeBuilder,
  upcomingGamesFilter
} from '../../../utils/helpers';
import { GAMES } from '../../../core/paths';
import { history } from '../../../core/store';
import { tzfilter } from '../../components/UpcomingGamesInfo/config';

const defaultFilterValue = {
  filter: 'Next 6 Months',
  start_date: null,
  end_date: null
};

const GAME_ASSIGNMENT_STATUSES_FILTER = {
  pending: 'tentative',
  accepted: 'accepted',
  declined: 'declined',
  unpublished: 'unpublished',
  open: 'unassignedOff'
};

export default compose(
  withState('gameFilter', 'setGameFilter', defaultFilterValue),
  withState('assignmentFilter', 'setAssignmentFilter', defaultFilterValue),
  withHandlers({
    getUpcomingGameInfo: ({ fetchUpcomingGames }) => async ({
      userId,
      platformId,
      start_date,
      end_date
    }) => {
      const startDate =
        start_date || formatGameFilterStartDate(moment().format(DATE_FORMATS.YYYY_MM_DD_HH_mm));
      const endDate =
        end_date ||
        formatGameFilterEndDate(moment().add(6, 'month').format(DATE_FORMATS.YYYY_MM_DD_HH_mm));
      fetchUpcomingGames({
        userId,
        filters: {
          start_date: startDate,
          end_date: endDate,
          eventStatus: true,
          role: [CORE_ROLES.admin, CORE_ROLES.assignor],
          platformId
        }
      });
    },
    getUpcomingAssignments: ({ fetchUpcomingAssignments }) => async ({
      userId,
      platformId,
      start_date,
      end_date
    }) => {
      const startDate =
        start_date || formatGameFilterStartDate(moment().format(DATE_FORMATS.YYYY_MM_DD_HH_mm));
      const endDate =
        end_date ||
        formatGameFilterEndDate(moment().add(6, 'month').format(DATE_FORMATS.YYYY_MM_DD_HH_mm));
      fetchUpcomingAssignments({
        userId,
        filters: {
          start_date: startDate,
          end_date: endDate,
          eventStatus: true,
          role: [CORE_ROLES.admin, CORE_ROLES.assignor],
          platformId
        }
      });
    },
    gameStatusClickHandler: ({ storeGamesEventAndRedirect, setEvents }) => async ({
      eventName,
      value,
      filter,
      games,
      viewGameClicked
    }) => {
      const userIdx = userIdxGetter();
      const gamesQuery = [];
      const status = [];
      const filterTodayTommorrow = filter.filter === 'Today' || filter.filter === 'Tomorrow';
      if (GAMES_STATUSES[value.toUpperCase()] === value) status.push(value);
      if (viewGameClicked && GAMES_STATUSES.POSTPONED === value)
        status.push(GAMES_STATUSES.CANCELLED);
      const event = games
        .filter(
          ({ game_level }) =>
            eventName === (game_level && game_level.event && game_level.event.name)
        )
        .map(({ game_level }) => game_level.event)[0];

      setEvents({ data: [event] });
      if (!isNil(filter)) {
        const [dateRange] = tzfilter.filter(v => v.value === filter.filter);
        const gameFilters = addPrefixToKeys(
          upcomingGamesFilter({
            startDate: dateRange.start_date ? dateRange.start_date : filter.start_date,
            ...((filterTodayTommorrow || !viewGameClicked) && {
              endDate: dateRange.end_date ? dateRange.end_date : filter.end_date
            }),
            assignmentFilter: GAME_ASSIGNMENT_STATUSES_FILTER[value]
              ? GAME_ASSIGNMENT_STATUSES_FILTER[value]
              : null,
            status
          }),
          `${PARAM_PREFIXES.GAMES}_`
        );

        Object.keys(gameFilters).forEach(key => {
          if (Array.isArray(gameFilters[key])) {
            gamesQuery.push(...gameFilters[key].map(val => `${key}=${val}`));
          } else {
            gamesQuery.push(`${key}=${gameFilters[key]}`);
          }
        });
      }

      storeGamesEventAndRedirect({
        id: event.id.toString(),
        game_upload_type: event.game_upload_type,
        platform_id: event.platform_id
      });
      if (!isNil(filter)) {
        history.push(routeBuilder({ userIdx }, `${GAMES}?${gamesQuery.join('&')}`));
      } else {
        history.push(routeBuilder({ userIdx }, `${GAMES}`));
      }
    }
  }),

  lifecycle({
    componentDidMount() {
      const { getUpcomingGameInfo, platformId, getUpcomingAssignments } = this.props;
      const userId = this.props.user && this.props.user.id;
      getUpcomingGameInfo({ userId, platformId });
      getUpcomingAssignments({ userId, platformId });
    },
    componentDidUpdate(preProps) {
      const {
        getUpcomingGameInfo,
        platformId,
        getUpcomingAssignments,
        setGameFilter,
        setAssignmentFilter
      } = this.props;
      const { platformId: prevPlatformId } = preProps;
      if (prevPlatformId !== platformId) {
        const userId = this.props.user && this.props.user.id;
        getUpcomingGameInfo({ userId, platformId });
        getUpcomingAssignments({ userId, platformId });
        setGameFilter(defaultFilterValue);
        setAssignmentFilter(defaultFilterValue);
      }
    }
  }),
  pure
);
