// Actions
export const SET_SHOW_MODAL_FOR = 'MODALS/SET_SHOW_MODAL_FOR';
export const UNSET_SHOW_MODAL_FOR = 'MODALS/UNSET_SHOW_MODAL_FOR';

// Reducer
export const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SHOW_MODAL_FOR:
      return {
        ...state,
        [action.payload.name]: !state[action.payload.name],
        from: action.payload.from,
        additional: action.payload.additional || {}
      };
    case UNSET_SHOW_MODAL_FOR:
      return {};

    default:
      return state;
  }
}

// Action Creators
export const setShowModalFor = payload => ({
  type: SET_SHOW_MODAL_FOR,
  payload
});

export const unsetShowModalFor = payload => ({
  type: UNSET_SHOW_MODAL_FOR,
  payload
});
