import moment from 'moment';
import { formatMoney } from 'accounting-js';
import { DATE_FORMATS, WALLET } from '../../../../constants';

export default {
  transactionHistoryTable: ({
    status,
    transactionType,
    paymentSource,
    batchId,
    eventName,
    created_at,
    description,
    amount,
    availableDate,
    refundId
  }) => [
    {
      label: 'Date',
      name: 'created_at',
      value: moment(created_at).format(DATE_FORMATS.M_D_YY),
      colSpan: 1
    },
    {
      label: 'Date Available',
      name: 'available_date',
      value:
        availableDate &&
        transactionType &&
        transactionType === WALLET.TRANSACTION.TYPES.PREFUND_PAYMENT &&
        !refundId
          ? moment(availableDate).format(DATE_FORMATS.M_D_YY)
          : 'N/A',
      colSpan: 1
    },
    {
      label: 'Funding Source',
      name: 'funding_source',
      value:
        transactionType &&
        transactionType === WALLET.TRANSACTION.TYPES.PREFUND_PAYMENT &&
        paymentSource
          ? `${paymentSource.bank}`
          : `Batch Id: ${batchId}`,
      subValue:
        transactionType &&
        transactionType === WALLET.TRANSACTION.TYPES.PREFUND_PAYMENT &&
        paymentSource
          ? `${paymentSource.nickName}`
          : null,
      colSpan: 1
    },
    {
      label: 'Status',
      name: 'status',
      value: status,
      colSpan: 1
    },
    {
      label: 'Description',
      name: 'description',
      value:
        transactionType && transactionType === WALLET.TRANSACTION.TYPES.BATCH_PAYMENT
          ? `${eventName} ${description}`
          : refundId && paymentSource
          ? 'Automated refund'
          : description,
      colSpan: 3
    },
    {
      label: 'Amount',
      name: 'amount',
      value: formatMoney(amount),
      colSpan: 1
    }
  ]
};
