import { compose } from 'recompose';
import { connect } from 'react-redux';

import { setCurrentGame as setCurrentGameAction } from '../../../../../core/Games';
import {
  fetchNotification as fetchNotificationAction,
  updateNotification as updateNotificationAction,
  clearNotification as clearNotificationAction,
  setEvent as setNotificationEventAction
} from '../../../../../core/Notifications';
import { getNotification, getMenuNotifications } from '../../../../../core/Notifications/selectors';
import { setShowModalFor as setShowModalForAction } from '../../../../../core/Modals';
import { storeReportsEventAndRedirect as storeReportsEventAndRedirectAction } from '../../../../../core/Reports';
import unconnected from './unconnected';
import { getUser, getUserIndex } from '../../../../../core/Auth/selectors';

const mapStateToProps = state => ({
  notifications: getMenuNotifications(state),
  selectedNotification: getNotification(state),
  user: getUser(state),
  userIdx: getUserIndex(state)
});

const mapDispatchToProps = dispatch => ({
  fetchNotification: id => dispatch(fetchNotificationAction(id)),
  setCurrentGame: payload => dispatch(setCurrentGameAction(payload)),
  setNotificationEvent: payload => dispatch(setNotificationEventAction(payload)),
  setShowModalFor: payload => dispatch(setShowModalForAction(payload)),
  updateNotification: payload => dispatch(updateNotificationAction(payload)),
  storeReportsEventAndRedirect: payload => dispatch(storeReportsEventAndRedirectAction(payload)),
  clearNotification: () => dispatch(clearNotificationAction())
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
