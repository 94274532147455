import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export const Error = styled.span`
  color: ${COLORS.carminePink};
  font-size: 10px;
`;

export const Wrapper = styled.div`
  margin: ${({ noMargin }) => (noMargin ? '0' : '10px auto')};
  position: relative;
`;

export const Border = styled.div`
  position: relative;
  height: ${({ hasLabel }) => (hasLabel ? '50' : '28')}px;
  overflow: hidden;
  border: ${({ noBorder, isFocus, theme: { primaryColor } }) =>
    noBorder && `1px solid ${isFocus ? primaryColor : COLORS.iron}`};
  padding-left: 10px;
`;

export const LabelPrimary = styled.label`
  color: ${({ isFilled, theme: { primaryColor } }) => (isFilled ? primaryColor : COLORS.slateGray)};
  bottom: 25px;
  font-size: 12px;
  position: absolute;
  pointer-events: none;
  transition: 0.1s ease-in-out;
  text-transform: uppercase;
`;

export const RawInput = styled.input`
  bottom: 0;
  color: ${COLORS.slateGray};
  width: 100%;
  border: none;
  outline: none;
  padding-bottom: 8px;
  font-family: 'FiraSans';
  font-size: 12px;
  position: absolute;
  background: transparent;
  transition: all 1s ease-in-out;

  &::placeholder {
    font-family: 'FiraSans';
    text-transform: capitalize;
    color: ${COLORS.silverSand};
  }
`;
