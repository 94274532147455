import React from 'react';
import PropTypes from 'prop-types';
import { equals, not, compose } from 'ramda';
import { InfoRow, InfoLabel, InfoValue } from '../Generic/Expanded/styled-components';
import Tag from '../../Tag';

const notEquals = val => compose(not, equals(val));

export const CategoryTags = ({ onChange, categories, canEdit }) => (
  <InfoRow>
    <InfoLabel>Categories</InfoLabel>
    <InfoValue>
      {categories.map(category => (
        <Tag
          {...category}
          key={category.id}
          onRemove={() => {
            onChange(categories.filter(notEquals(category)));
          }}
          canEdit={canEdit}
          width="fit-content"
          color="#0D194F"
          fontWeight="normal"
          fontFamily="BarlowMedium"
        />
      ))}
    </InfoValue>
  </InfoRow>
);
CategoryTags.propTypes = {
  categories: PropTypes.arrayOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired
};
export default CategoryTags;
