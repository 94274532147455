import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { ifElse, is } from 'ramda';
import { Row, Col } from 'react-flexbox-grid';
import MaterialInput from '../../MaterialInput';

const InputWrapper = ({ readOnly, disabled, placeholder, value, pair, ...props }) =>
  ifElse(
    () => is(Array)(pair),
    () => (
      <Row>
        {pair.map(
          ({
            readOnly: readOnlyPair,
            placeholder: placeholderPair,
            value: valuePair,
            disabled: disabledPair,
            ...propsPair
          }) => (
            <Col xs={6} key={propsPair.name}>
              {readOnlyPair ? (
                <MaterialInput
                  disabled={disabledPair}
                  input={{ value: valuePair }}
                  placeholder={placeholderPair}
                  readOnly={readOnlyPair}
                  {...propsPair}
                />
              ) : (
                <Field placeholder={placeholderPair} {...propsPair} />
              )}
            </Col>
          )
        )}
      </Row>
    ),
    () =>
      readOnly ? (
        <MaterialInput disabled={disabled} input={{ value }} placeholder={placeholder} readOnly />
      ) : (
        <Field placeholder={placeholder} {...props} />
      )
  )();

InputWrapper.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool
};

InputWrapper.defaultProps = {
  readOnly: false,
  placeholder: '',
  value: '',
  disabled: false
};

export default InputWrapper;
