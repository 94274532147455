import { compose, withStateHandlers, lifecycle } from 'recompose';
import ShowHide from '../../../controls/ShowHide';

export default compose(
  ShowHide,
  withStateHandlers(
    {
      filterCount: 0
    },
    {
      hideFilter: (state, { isVisible, setVisibility }) => () => {
        if (isVisible) {
          setVisibility(false);
        }
      },
      setFilterCount: () => filterCount => ({ filterCount })
    }
  ),
  lifecycle({
    componentWillMount() {
      document.addEventListener('click', this.props.hideFilter);
    },
    componentWillUnmount() {
      document.removeEventListener('click', this.props.hideFilter);
    }
  })
);
