import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ padding }) => (padding ? `padding: ${padding}` : '')};
`;

export const Label = styled.span`
  display: inline-block;
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoCondensed';
  color: ${({ checked, isLabelBlue }) =>
    checked || isLabelBlue ? COLORS.denimBlue : COLORS.shuttleGray};
  margin-top: ${pxToRem('3px')};
  cursor: pointer;
  user-select: none;
`;

export const Message = styled.span`
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  text-align: left;
  font-family: 'RobotoCondensedLight';
  padding-left: 0.5rem;
  margin: ${pxToRem('4px')} 0 0;
`;
