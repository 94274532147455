import { concat } from 'ramda';

export const usaStates = [
  {
    name: 'Alabama',
    value: 'AL'
  },
  {
    name: 'Alaska',
    value: 'AK'
  },
  {
    name: 'American Samoa',
    value: 'AS'
  },
  {
    name: 'Arizona',
    value: 'AZ'
  },
  {
    name: 'Arkansas',
    value: 'AR'
  },
  {
    name: 'California',
    value: 'CA'
  },
  {
    name: 'Colorado',
    value: 'CO'
  },
  {
    name: 'Connecticut',
    value: 'CT'
  },
  {
    name: 'Delaware',
    value: 'DE'
  },
  {
    name: 'District Of Columbia',
    value: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    name: 'Florida',
    value: 'FL'
  },
  {
    name: 'Georgia',
    value: 'GA'
  },
  {
    name: 'Guam',
    value: 'GU'
  },
  {
    name: 'Hawaii',
    value: 'HI'
  },
  {
    name: 'Idaho',
    value: 'ID'
  },
  {
    name: 'Illinois',
    value: 'IL'
  },
  {
    name: 'Indiana',
    value: 'IN'
  },
  {
    name: 'Iowa',
    value: 'IA'
  },
  {
    name: 'Kansas',
    value: 'KS'
  },
  {
    name: 'Kentucky',
    value: 'KY'
  },
  {
    name: 'Louisiana',
    value: 'LA'
  },
  {
    name: 'Maine',
    value: 'ME'
  },
  {
    name: 'Marshall Islands',
    value: 'MH'
  },
  {
    name: 'Maryland',
    value: 'MD'
  },
  {
    name: 'Massachusetts',
    value: 'MA'
  },
  {
    name: 'Michigan',
    value: 'MI'
  },
  {
    name: 'Minnesota',
    value: 'MN'
  },
  {
    name: 'Mississippi',
    value: 'MS'
  },
  {
    name: 'Missouri',
    value: 'MO'
  },
  {
    name: 'Montana',
    value: 'MT'
  },
  {
    name: 'Nebraska',
    value: 'NE'
  },
  {
    name: 'Nevada',
    value: 'NV'
  },
  {
    name: 'New Hampshire',
    value: 'NH'
  },
  {
    name: 'New Jersey',
    value: 'NJ'
  },
  {
    name: 'New Mexico',
    value: 'NM'
  },
  {
    name: 'New York',
    value: 'NY'
  },
  {
    name: 'North Carolina',
    value: 'NC'
  },
  {
    name: 'North Dakota',
    value: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    name: 'Ohio',
    value: 'OH'
  },
  {
    name: 'Oklahoma',
    value: 'OK'
  },
  {
    name: 'Oregon',
    value: 'OR'
  },
  {
    name: 'Palau',
    value: 'PW'
  },
  {
    name: 'Pennsylvania',
    value: 'PA'
  },
  {
    name: 'Puerto Rico',
    value: 'PR'
  },
  {
    name: 'Rhode Island',
    value: 'RI'
  },
  {
    name: 'South Carolina',
    value: 'SC'
  },
  {
    name: 'South Dakota',
    value: 'SD'
  },
  {
    name: 'Tennessee',
    value: 'TN'
  },
  {
    name: 'Texas',
    value: 'TX'
  },
  {
    name: 'Utah',
    value: 'UT'
  },
  {
    name: 'Vermont',
    value: 'VT'
  },
  {
    name: 'Virgin Islands',
    value: 'VI'
  },
  {
    name: 'Virginia',
    value: 'VA'
  },
  {
    name: 'Washington',
    value: 'WA'
  },
  {
    name: 'West Virginia',
    value: 'WV'
  },
  {
    name: 'Wisconsin',
    value: 'WI'
  },
  {
    name: 'Wyoming',
    value: 'WY'
  }
];

export const canadaProvinces = [
  { name: 'Alberta', value: 'AB' },
  { name: 'British Columbia', value: 'BC' },
  { name: 'Manitoba', value: 'MB' },
  { name: 'New Brunswick', value: 'NB' },
  { name: 'Newfoundland and Labrador', value: 'NL' },
  { name: 'Northwest Territories', value: 'NT' },
  { name: 'Nova Scotia', value: 'NS' },
  { name: 'Nunavut', value: 'NU' },
  { name: 'Ontario', value: 'ON' },
  { name: 'Prince Edward Island', value: 'PE' },
  { name: 'Quebec', value: 'QC' },
  { name: 'Saskatchewan', value: 'SK' },
  { name: 'Yukon Territory', value: 'YT' }
];

export const allAvailableProvinces = concat(usaStates, canadaProvinces);

export const countries = [
  { value: 'US', name: 'United States' },
  { value: 'CA', name: 'Canada' },
  { value: 'AF', name: 'Afghanistan' },
  { value: 'AL', name: 'Albania' },
  { value: 'DZ', name: 'Algeria' },
  { value: 'AD', name: 'Andorra' },
  { value: 'AO', name: 'Angola' },
  { value: 'AI', name: 'Anguilla' },
  { value: 'AG', name: 'Antigua and Barbuda' },
  { value: 'AR', name: 'Argentina' },
  { value: 'AM', name: 'Armenia' },
  { value: 'AW', name: 'Aruba' },
  { value: 'AU', name: 'Australia' },
  { value: 'AT', name: 'Austria' },
  { value: 'AZ', name: 'Azerbaijan' },
  { value: 'BS', name: 'Bahamas' },
  { value: 'BH', name: 'Bahrain' },
  { value: 'BD', name: 'Bangladesh' },
  { value: 'BB', name: 'Barbados' },
  { value: 'BY', name: 'Belarus' },
  { value: 'BE', name: 'Belgium' },
  { value: 'BZ', name: 'Belize' },
  { value: 'BJ', name: 'Benin' },
  { value: 'BM', name: 'Bermuda' },
  { value: 'BT', name: 'Bhutan' },
  { value: 'BO', name: 'Bolivia' },
  { value: 'BA', name: 'Bosnia Herzegovina' },
  { value: 'BW', name: 'Botswana' },
  { value: 'BV', name: 'Bouvet Island' },
  { value: 'BR', name: 'Brazil' },
  { value: 'IO', name: 'British Indian Ocean Territory' },
  { value: 'VG', name: 'British Virgin Islands' },
  { value: 'BN', name: 'Brunei Darussalam' },
  { value: 'BG', name: 'Bulgaria' },
  { value: 'BF', name: 'Burkina Faso' },
  { value: 'BI', name: 'Burundi' },
  { value: 'KH', name: 'Cambodia' },
  { value: 'CM', name: 'Cameroon' },
  { value: 'CV', name: 'Cape Verde' },
  { value: 'KY', name: 'Cayman Islands' },
  { value: 'CF', name: 'Central African Republic' },
  { value: 'TD', name: 'Chad' },
  { value: 'CL', name: 'Chile' },
  { value: 'CN', name: 'China' },
  { value: 'CX', name: 'Christmas Island' },
  { value: 'CC', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', name: 'Colombia' },
  { value: 'KM', name: 'Comoros' },
  { value: 'CG', name: 'Congo' },
  { value: 'CD', name: 'Congo (The Democratic Republic of the)' },
  { value: 'CK', name: 'Cook Islands' },
  { value: 'CR', name: 'Costa Rica' },
  { value: 'CI', name: 'Cote d Ivoire (Ivory Coast)' },
  { value: 'HR', name: 'Croatia' },
  { value: 'CU', name: 'Cuba' },
  { value: 'CY', name: 'Cyprus' },
  { value: 'CZ', name: 'Czech Republic' },
  { value: 'DK', name: 'Denmark' },
  { value: 'DJ', name: 'Djibouti' },
  { value: 'DM', name: 'Dominica' },
  { value: 'DO', name: 'Dominican Republic' },
  { value: 'TL', name: 'East Timor' },
  { value: 'EC', name: 'Ecuador' },
  { value: 'EG', name: 'Egypt' },
  { value: 'SV', name: 'El Salvador' },
  { value: 'GQ', name: 'Equatorial Guinea' },
  { value: 'ER', name: 'Eritrea' },
  { value: 'EE', name: 'Estonia' },
  { value: 'ET', name: 'Ethiopia' },
  { value: 'FK', name: 'Falkland Islands (Malvinas)' },
  { value: 'FO', name: 'Faroe Islands' },
  { value: 'FJ', name: 'Fiji' },
  { value: 'FI', name: 'Finland' },
  { value: 'FR', name: 'France' },
  { value: 'GF', name: 'French Guiana' },
  { value: 'PF', name: 'French Polynesia' },
  { value: 'TF', name: 'French Southern Territories' },
  { value: 'GA', name: 'Gabon' },
  { value: 'GM', name: 'Gambia' },
  { value: 'GE', name: 'Georgia' },
  { value: 'DE', name: 'Germany' },
  { value: 'GH', name: 'Ghana' },
  { value: 'GI', name: 'Gibraltar' },
  { value: 'GR', name: 'Greece' },
  { value: 'GL', name: 'Greenland' },
  { value: 'GD', name: 'Grenada' },
  { value: 'GP', name: 'Guadeloupe' },
  { value: 'GT', name: 'Guatemala' },
  { value: 'GN', name: 'Guinea' },
  { value: 'GW', name: 'Guinea-Bissau' },
  { value: 'GY', name: 'Guyana' },
  { value: 'HT', name: 'Haiti' },
  { value: 'HM', name: 'Heard Island and McDonald Islands' },
  { value: 'VA', name: 'Holy See (Vatican City State)' },
  { value: 'HN', name: 'Honduras' },
  { value: 'HK', name: 'Hong Kong' },
  { value: 'HU', name: 'Hungary' },
  { value: 'IS', name: 'Iceland' },
  { value: 'IN', name: 'India' },
  { value: 'ID', name: 'Indonesia' },
  { value: 'IQ', name: 'Iraq' },
  { value: 'IE', name: 'Ireland' },
  { value: 'IR', name: 'Islamic Republic of Iran' },
  { value: 'IL', name: 'Israel' },
  { value: 'IT', name: 'Italy' },
  { value: 'JM', name: 'Jamaica' },
  { value: 'JP', name: 'Japan' },
  { value: 'JO', name: 'Jordan' },
  { value: 'KZ', name: 'Kazakhstan' },
  { value: 'KE', name: 'Kenya' },
  { value: 'KI', name: 'Kiribati' },
  { value: 'KP', name: 'Korea (Democratic People s Republic of)' },
  { value: 'KR', name: 'Korea (Republic of)' },
  { value: 'KW', name: 'Kuwait' },
  { value: 'KG', name: 'Kyrgzstan' },
  { value: 'LA', name: 'Lao People s Democratic Republic' },
  { value: 'LV', name: 'Latvia' },
  { value: 'LB', name: 'Lebanon' },
  { value: 'LS', name: 'Lesotho' },
  { value: 'LR', name: 'Liberia' },
  { value: 'LY', name: 'Libyan Arab Jamahiriya' },
  { value: 'LI', name: 'Liechtenstein' },
  { value: 'LT', name: 'Lithuania' },
  { value: 'LU', name: 'Luxembourg' },
  { value: 'MO', name: 'Macao' },
  { value: 'MK', name: 'Macedonia (The Former Yugoslav Republic of)' },
  { value: 'MG', name: 'Madagascar' },
  { value: 'MW', name: 'Malawi' },
  { value: 'MY', name: 'Malaysia' },
  { value: 'MV', name: 'Maldives' },
  { value: 'ML', name: 'Mali' },
  { value: 'MT', name: 'Malta' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MQ', name: 'Martinique' },
  { value: 'MR', name: 'Mauritania' },
  { value: 'MU', name: 'Mauritius' },
  { value: 'YT', name: 'Mayotte' },
  { value: 'MX', name: 'Mexico' },
  { value: 'MD', name: 'Moldova' },
  { value: 'MC', name: 'Monaco' },
  { value: 'MN', name: 'Mongolia' },
  { value: 'MS', name: 'Montserrat' },
  { value: 'MA', name: 'Morocco' },
  { value: 'MZ', name: 'Mozambique' },
  { value: 'MM', name: 'Myanmar' },
  { value: 'NA', name: 'Namibia' },
  { value: 'NR', name: 'Nauru' },
  { value: 'NP', name: 'Nepal' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'AN', name: 'Netherlands Antilles' },
  { value: 'NC', name: 'New Caledonia' },
  { value: 'NZ', name: 'New Zealand' },
  { value: 'NI', name: 'Nicaragua' },
  { value: 'NE', name: 'Niger' },
  { value: 'NG', name: 'Nigeria' },
  { value: 'NU', name: 'Niue' },
  { value: 'NF', name: 'Norfolk Island' },
  { value: 'NO', name: 'Norway' },
  { value: 'OM', name: 'Oman' },
  { value: 'PK', name: 'Pakistan' },
  { value: 'PW', name: 'Palau' },
  { value: 'PA', name: 'Panama' },
  { value: 'PG', name: 'Papua New Guinea' },
  { value: 'PY', name: 'Paraguay' },
  { value: 'PE', name: 'Peru' },
  { value: 'PH', name: 'Philippines' },
  { value: 'PN', name: 'Pitcairn' },
  { value: 'PL', name: 'Poland' },
  { value: 'PT', name: 'Portugal' },
  { value: 'QA', name: 'Qatar' },
  { value: 'RE', name: 'Reunion' },
  { value: 'RO', name: 'Romania' },
  { value: 'RU', name: 'Russian Federation' },
  { value: 'RW', name: 'Rwanda' },
  { value: 'SH', name: 'Saint Helena' },
  { value: 'KN', name: 'Saint Kitts and Nevis' },
  { value: 'LC', name: 'Saint Lucia' },
  { value: 'PM', name: 'Saint Pierre and Miquelon' },
  { value: 'VC', name: 'Saint Vincent and the Grenadines' },
  { value: 'WS', name: 'Samoa' },
  { value: 'SM', name: 'San Marino' },
  { value: 'ST', name: 'Sao Tome and Principe' },
  { value: 'SA', name: 'Saudi Arabia' },
  { value: 'SN', name: 'Senegal' },
  { value: 'RS', name: 'Serbia' },
  { value: 'SC', name: 'Seychelles' },
  { value: 'SL', name: 'Sierra Leone' },
  { value: 'SG', name: 'Singapore' },
  { value: 'SK', name: 'Slovakia' },
  { value: 'SI', name: 'Slovenia' },
  { value: 'SB', name: 'Solomon Islands' },
  { value: 'SO', name: 'Somalia' },
  { value: 'ZA', name: 'South Africa' },
  { value: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { value: 'ES', name: 'Spain' },
  { value: 'LK', name: 'Sri Lanka' },
  { value: 'SD', name: 'Sudan' },
  { value: 'SR', name: 'Suriname' },
  { value: 'SJ', name: 'Svalbard and Jan Mayen' },
  { value: 'SZ', name: 'Swaziland' },
  { value: 'SE', name: 'Sweden' },
  { value: 'CH', name: 'Switzerland' },
  { value: 'SY', name: 'Syrian Arab Republic' },
  { value: 'TW', name: 'Taiwan' },
  { value: 'TJ', name: 'Tajikstan' },
  { value: 'TZ', name: 'Tanzania United Republic' },
  { value: 'TH', name: 'Thailand' },
  { value: 'TG', name: 'Togo' },
  { value: 'TK', name: 'Tokelau' },
  { value: 'TO', name: 'Tonga' },
  { value: 'TT', name: 'Trinidad and Tobago' },
  { value: 'TN', name: 'Tunisia' },
  { value: 'TR', name: 'Turkey' },
  { value: 'TM', name: 'Turkmenistan' },
  { value: 'TC', name: 'Turks and Caicos Islands' },
  { value: 'TV', name: 'Tuvalu' },
  { value: 'UG', name: 'Uganda' },
  { value: 'UA', name: 'Ukraine' },
  { value: 'AE', name: 'United Arab Emirates' },
  { value: 'GB', name: 'United Kingdom' },
  { value: 'UY', name: 'Uruguay' },
  { value: 'UZ', name: 'Uzbekistan' },
  { value: 'VU', name: 'Vanuatu' },
  { value: 'VE', name: 'Venezuela' },
  { value: 'VN', name: 'Vietnam' },
  { value: 'WF', name: 'Wallis and Futuna' },
  { value: 'EH', name: 'Western Sahara' },
  { value: 'YE', name: 'Yemen' },
  { value: 'ZM', name: 'Zambia' },
  { value: 'ZW', name: 'Zimbabwe' }
];

export const PARAM_PREFIXES = {
  GAMES: 'gm',
  USERS: 'usrs',
  EVENTS: 'ev',
  LOCATIONS: 'loc',
  TEAMS: 'tm'
};

export const REPORT_TYPES = {
  incident: 'incident',
  assessment: 'assessment',
  game: 'game'
};

export const NOTIFICATION_TYPES = {
  invitation_event: 'invitation_event',
  report_assessment: 'report_assessment',
  payout_failed: 'payout_failed',
  payment_failed: 'payment_failed',
  status_game: 'status_game',
  game_unscheduled: 'game_unscheduled',
  add_funds_failed: 'add_funds_failed'
};

export const NOTIFICATION_STATUS = {
  action_required: 'action_required',
  action_taken: 'action_taken',
  unread: 'unread',
  read: 'read'
};

export const GAME_ASSIGNMENT_STATUSES = {
  pending: 'pending',
  accepted: 'accepted',
  declined: 'declined',
  unpublished: 'unpublished',
  tentative: 'tentative'
};

export const GAME_UPLOAD_TYPES = {
  none: 0,
  connect_game_sync: 1,
  manual_upload: 2
};

export const EVENT_STATUS = {
  OPEN: 1,
  CLOSED: 0
};

export const CORE_ROLES = {
  super_admin: 1,
  admin: 2,
  assignor: 3,
  assessor: 4,
  official: 5,
  funding_admin: 6
};

export const SPORT_TYPES = {
  soccer: 9,
  futsal: 5
};

export const ROLES = ['super admin', 'admin', 'assessor', 'assignor', 'official', 'funding admin'];

export const ROLES_CONSTANT = [
  '',
  'Super Admin',
  'Admin',
  'Assignor',
  'Assessor',
  'Official',
  'Funding Admin'
];

export const USER_ROLES = ['assignor', 'admin', 'funding admin'];

export default {
  allAvailableProvinces,
  countries,
  PARAM_PREFIXES
};

export const POSITION_LABELS = [
  { label: 'Pos. 1' },
  { label: 'Pos. 2' },
  { label: 'Pos. 3' },
  { label: 'Pos. 4' },
  { label: 'Pos. 5' },
  { label: 'Pos. 6' },
  { label: 'Pos. 7' },
  { label: 'Pos. 8' }
];

export const REPORT_LABELS = [
  { link_position_1: 0 },
  { link_position_2: 1 },
  { link_position_3: 2 },
  { link_position_4: 3 },
  { link_position_5: 4 },
  { link_position_6: 5 },
  { link_position_7: 6 },
  { link_position_8: 7 }
];

export const PAYOUT_STATUSES = ['Pending', 'Completed', 'Failed'];

export const PAYMENT_UI_STATUSES = ['Created', 'Pending', 'Completed', 'Failed', 'Pending'];

export const TRANSFER_UI_STATUSES = [
  'Ready',
  'Pending',
  'Failed',
  'Completed',
  'Payout Pending',
  'Paid'
];

export const TRANSFER_STATUSES = {
  READY: 0,
  PENDING: 1,
  FAILED: 2,
  AVAILABLE: 3,
  PAYOUT_PENDING: 4,
  PAID: 5
};

export const GAMES_STATUSES = {
  NEW: 'new',
  UPDATED: 'updated',
  NONE: 'none',
  CANCELLED: 'cancelled',
  POSTPONED: 'postponed',
  PAID: 'paid',
  SCHEDULED: 'scheduled'
};

export const CORE_ROLES_DROPDOWN = [
  { value: '1', name: 'Super Admin' },
  { value: '2', name: 'Admin' },
  { value: '3', name: 'Assignor' },
  { value: '4', name: 'Assessor' },
  { value: '5', name: 'Official' },
  { value: '6', name: 'Funding Admin' }
];

export const PAYMENT_STATUSES = {
  CREATED: 0,
  PENDING: 1,
  SUCCEEDED: 2,
  FAILED: 3,
  INCOMPLETE: 4
};

export const PAYMENTS_VIEW = {
  GAME_PAY: 'game-pay',
  FUNDING: 'funding'
};

export const LOCATIONS = {
  FUND_BATCH_DIALOG: 'FUND_BATCH_DIALOG',
  TAX_INFO: 'TAX_INFO'
};

export const ENTITIES = {
  BATCH_PAY: 'BATCH_PAY'
};

export const PLATFORM_TYPES = {
  PLATFORM: 'P',
  ORGANISATION: 'O',
  GROUP: 'G'
};

export const URLS = {
  PRIVACY_POLICY: 'https://stacksports.com/legal-privacy/',
  TERMS_OF_SERVICE: 'https://stacksports.com/legal-terms',
  HELP_DESK: 'https://stacksportsportal.force.com/helpcenter/s/?b=0011T00002XDT7OQAX',
  HELP_DESK_PAYMENTS:
    'https://stacksports.my.site.com/helpcenter/s/topic/a4U1T000000oan8UAA/l04070?b=0011T00002XDT7OQAX',
  USSF_LC: 'https://learning.ussoccer.com/referee',
  GOOGLE_MAPS: 'https://maps.google.com/maps',
  APPLE_MAPS: 'http://maps.apple.com/'
};

export const PAYMENT_FLOW_STATUSES = {
  PROCESSING: 1,
  PAID: 2
};

export const EXPECTED_PAYMENT_PROCESSING_DAYS = 3;

export const PRIMARY_PHONE_TYPE = {
  1: 'Home',
  2: 'cell',
  3: 'work'
};

export const LICENSE_STATUSES = {
  ACTIVE: 'active',
  EXPIRED: 'inactive',
  NONE: 'none'
};

export const LICENSE_TYPES = {
  referee_assessor: { role: 'assessor' },
  referee_educator: { role: 'assessor' },
  referee_instructor: { role: 'assessor' },
  referee_futsal: { role: 'official' },
  referee_emeritus: { role: 'official' },
  referee_specialist: { role: 'official' },
  referee_pathway: { role: 'official' },
  referee_assignor: { role: 'assignor' },
  safety_compliance: { role: 'safety' }
};

export const OFFICIAL_MIN_GRADE = [
  { label: 'None - (No Grade Required)', value: 0, abbr: 'None' },
  { label: 'R - Referee', value: 10, abbr: 'R' },
  { label: 'DR - Digital Referee', value: 12, abbr: 'DR' },
  { label: 'ER - Emeritus', value: 2, abbr: 'ER' }, // not in DB
  { label: 'RER - Regional Emeritus', value: 2, abbr: 'RER' },
  { label: 'NER - National Emeritus', value: 1, abbr: 'NER' },
  { label: 'RR - Regional', value: 7, abbr: 'RR' },
  { label: 'NAR - National Assistant', value: 6, abbr: 'NAR' },
  { label: 'NR - National', value: 5, abbr: 'NR' },
  { label: 'FAR - FIFA Assistant', value: 2, abbr: 'FAR' },
  { label: 'FR - FIFA', value: 1, abbr: 'FR' }
];

export const ASSESSOR_MIN_GRADE = [
  { label: 'None - (No Grade Required)', value: 0, abbr: 'None' },
  { label: 'RA - Regional Assessor', value: 2, abbr: 'RA' },
  { label: 'NA - National Assessor', value: 1, abbr: 'NA' },
  { label: 'RC - Referee Coach', value: 2, abbr: 'RC' },
  { label: 'NRC - National Referee Coach', value: 1, abbr: 'NRC' }
];

export const EVENT_BILLING_STATEMENT_TYPES = ['past_due', 'due_date', 'recent'];

export const INVOICE_STATUS = ['open', 'paid'];

export const ROUTESLABEL = {
  profile: 'PROFILE',
  dashboard: 'DASHBOARD',
  help: 'HELP'
};

export const PRODUCTS = {
  PER_GAME_FEE: 'PER_GAME_FEE',
  PLATFORM_FEE: 'PLATFORM_FEE'
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const SUBSCRIPTION_CYCLE = {
  monthly: 'month',
  yearly: 'year'
};

export const DATE_FORMATS = {
  FULL_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_IOS: 'YYYY/MM/DD HH:mm:ss',
  YYYY_MM_DD: 'YYYY-MM-DD',
  H_MM_A: 'h:mm A',
  MM_YYYY: 'MM-YYYY',
  MM_DD_YYYY: 'MM/DD/YYYY',
  YYYY_MM_DD_H_mm_ss: 'YYYY/MM/DD H:mm:ss',
  YYYY_MM_DD_HH_mm: 'YYYY-MM-DD HH:mm',
  M_D_YY: 'M/D/YY',
  MM_DD_YYYY_hh_mm_A: 'MM/DD/YYYY hh:mm A',
  DD_MM_YYYY: 'DD/MM/YYYY'
};

export const IOS_DEVICES = ['iPhone', 'iPod', 'iPad'];

export const URL_ENCODINGS = {
  SPACE: '+',
  '"': '%22',
  '<': '%3C',
  '>': '%3E',
  '#': '%23',
  '%': '%25',
  '|': '%7C',
  '+': '%2B',
  '&': '%26'
};

export const EVENT_GOVERNING_BODY = {
  USSF: 'U.S. Soccer Federation',
  OTHER: 'Other'
};

export const PAYMENT_PROFILE_FILTER = {
  YES: 'yes',
  NO: 'no'
};

export const AVAILABILITY_CALENDAR_TZ = [
  { id: 1, name: 'Hawaiian-Honolulu', code: 'Pacific/Honolulu', offset: '-10' },
  { id: 3, name: 'Marquesas', code: 'Pacific/Marquesas', offset: '-9.5' },
  { id: 4, name: 'Alaska-Anchorage', code: 'America/Anchorage', offset: '-9' },
  { id: 5, name: 'Pacific-Los Angeles', code: 'America/Los_Angeles', offset: '-8' },
  { id: 8, name: 'Mountain-Denver', code: 'America/Denver', offset: '-7' },
  { id: 9, name: 'US Mountain-Phoenix', code: 'America/Phoenix', offset: '-7' },
  { id: 10, name: 'Central-Chicago', code: 'America/Chicago', offset: '-6' },
  { id: 12, name: 'Eastern-New York', code: 'America/New_York', offset: '-5' },
  { id: 14, name: 'Atlantic-Halifax', code: 'America/Halifax', offset: '-4' }
];

export const USER_ROLE_VALUES = {
  OFFICIAL: 'official',
  ASSESSOR: 'assessor',
  ADMIN: 'admin',
  ASSIGNOR: 'assignor'
};

export const eventFilterLimit = 20;

export const allEventsId = '-1';

export const PAYMENT_METHODS = {
  BANK_ACCOUNT: 'bank_account',
  CARD: 'card',
  WALLET: 'wallet'
};

export const NOTE = {
  TYPES: {
    CREW_VERIFICATION: 'crew_verification',
    AVAILABILITY: 'availability',
    ASSIGNMENT_DECLINE: 'assignment_declined'
  }
};

export const UPCOMINGEVENTS = {
  UPCOMING_GAMES: 'Upcoming Games',
  UPCOMING_ASSIGNMENTS: 'Upcoming Assignments'
};

export const EVENT_BILLING_CUSTOMER_TYPE = {
  BATCH_PAYER: 'batch_payer',
  GAME_PAYER: 'game_payer'
};

export const WALLET = {
  TRANSACTION: {
    TYPES: {
      PREFUND_PAYMENT: 'prefund_payment',
      BATCH_PAYMENT: 'batch_payment'
    },
    STATUSES: {
      SUCCEEDED: 1,
      PENDING: 0,
      FAILED: 2,
      CANCELED: 5
    }
  }
};

export const MAP_TRANSACTION_STATUSES = {
  [WALLET.TRANSACTION.TYPES.BATCH_PAYMENT]: {
    [WALLET.TRANSACTION.STATUSES.SUCCEEDED]: 'Paid',
    [WALLET.TRANSACTION.STATUSES.PENDING]: 'Pending',
    [WALLET.TRANSACTION.STATUSES.FAILED]: 'Failed'
  },
  [WALLET.TRANSACTION.TYPES.PREFUND_PAYMENT]: {
    [WALLET.TRANSACTION.STATUSES.SUCCEEDED]: 'Completed',
    [WALLET.TRANSACTION.STATUSES.PENDING]: 'Pending',
    [WALLET.TRANSACTION.STATUSES.FAILED]: 'Failed'
  }
};

export const INTERCOM_APP_ID = 'bwk0qbpa';

export const REPORT_DISPLAY_STATUS = {
  COMPLETE: 'complete',
  PARTIAL: 'partial',
  NONE: 'none'
};

export const SELF_ASSIGNMENT_SUMMARY_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove'
};
