import React from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import COLORS from '../../../utils/styles';
import Button from '../Button';
import Dropdown from '../Dropdown';
import Input from '../Input';
import Enhancer from '../../containers/CreateGroup';
import LoaderWrapper from '../LoaderWrapper';

import { required } from '../../../utils/validations';
import { ButtonWrapper, ButtonContainer } from './styled-components';

const CreateGroup = ({ isSubmitting, onCreateGroup, options, isFetching }) => (
  <LoaderWrapper isFetching={isFetching}>
    <Form
      onSubmit={onCreateGroup}
      initialValues={{ parentId: 1 }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Field name="parentId" validate={required}>
            {({ input, meta: { active, error, touched } }) => (
              <Dropdown
                dropdownWidth="9rem"
                label="parent group"
                options={options}
                active={active}
                columnSize={2}
                error={error && touched ? error : ''}
                borderRadius="5px"
                backgroundColor={COLORS.white}
                borderColor={COLORS.slateGray}
                outline="none"
                dropdownHeight="1.5rem"
                {...input}
              />
            )}
          </Field>
          <Field name="groupName" validate={required}>
            {({ input, meta: { active, error, touched } }) => (
              <Input
                label="group name"
                labelType="secondary"
                placeholder="group name"
                active={active}
                error={error && touched ? error : ''}
                {...input}
              />
            )}
          </Field>
          <ButtonWrapper center="xs">
            <ButtonContainer xs={12} sm={12} md={8} lg={5}>
              <Button
                type="submit"
                label="Create New Group"
                submitting={isSubmitting}
                disabled={pristine || invalid || isSubmitting}
                primary
              />
            </ButtonContainer>
          </ButtonWrapper>
        </form>
      )}
    />
  </LoaderWrapper>
);

CreateGroup.propTypes = {
  onCreateGroup: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  options: PropTypes.instanceOf(Array),
  isFetching: PropTypes.bool
};

CreateGroup.defaultProps = {
  options: [],
  isFetching: false
};

export default Enhancer(CreateGroup);
