import React from 'react';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../../Button';
import Modal from '../../../Modal';
import { Input } from '../../../../global-material-components';
import { ButtonContainer, InputWrapper, UserForm } from './styled-components';
import Enhancer from '../../../../containers/GroupInfo/Modals/AddGroupAdmin';
import config from './config';
import { USER_ROLES } from '../../../../../constants';

const useStyles = makeStyles(() => ({
  root: {
    top: '-9px',
    fontSize: '11px'
  },
  slimLabelShrink: {
    transform: 'translate(15px, 5px) scale(0.75)!important'
  }
}));

const AddGroupAdmin = ({ onSubmit, setShowModal, groupId }) => {
  const classes = useStyles();

  return (
    <Modal onClose={() => setShowModal(false)} {...config.modal}>
      <UserForm>
        <Form
          onSubmit={onSubmit}
          initialValues={{ userRole: USER_ROLES[1], groupId }}
          render={({ handleSubmit, pristine, submitting, invalid, values }) => (
            <form
              onSubmit={() => {
                handleSubmit(values);
                setShowModal(false);
              }}
            >
              {config.fields.map(({ name, validate, ...props }) => (
                <Field name={name} validate={validate}>
                  {({ input, meta }) => (
                    <InputWrapper>
                      <Input
                        error={!!meta.error}
                        {...input}
                        {...props}
                        {...config.inputProps({ classes })}
                      />
                      {meta.error && <span>{meta.error}</span>}
                    </InputWrapper>
                  )}
                </Field>
              ))}
              <ButtonContainer>
                <Button disabled={pristine || submitting || invalid} {...config.button} />
              </ButtonContainer>
            </form>
          )}
        />
      </UserForm>
    </Modal>
  );
};

export default Enhancer(AddGroupAdmin);
