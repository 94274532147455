import styled from 'styled-components';
import { mediaQueries } from '../../../utils/styles';

export const wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100vh;
`;

export const LoginLink = styled.a`
  width: 145px;
  margin: 0px 10px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgb(13, 25, 79);
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  ${mediaQueries.xs(`flex-direction: column;`)};
`;

export const Logo = styled.img`
  width: 18%;
  image-rendering: -webkit-optimize-contrast;
  ${mediaQueries.sm(`width:30%`)};
  ${mediaQueries.xs(`width:70%; margin:6px 0px;`)};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-flow: row-reverse;
  text-align: end;
`;

export const LoginButton = styled.button`
  width: ${({ width }) => width || '150px'};
  height: ${({ height }) => height || '150px'};
  background: #fff;
  color: #000c66 !important;
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-weight: 700;
  text-transform: uppercase;
  border: 0px;
  cursor: pointer;
  ${mediaQueries.xs(`font-size:13px; height:30px; margin:8px 0px`)};
`;

export const Iframe = styled.iframe`
  border: 0px;
  width: 100%;
  height: calc(100% - 100px);
  ${mediaQueries.xs(`height: calc(100% - 108px);`)};
`;
