import React from 'react';
import PropTypes from 'prop-types';

import Tag from '../Tag';
import { Wrapper } from './styled-components';

/**
 * Renders an array of tags in a wrapping flex container
 * @param {Array} tags array of objects with name, onRemove, and canEdit to render
 */
const Tags = ({ tags, justifyContent }) => (
  <Wrapper justifyContent={justifyContent}>
    {tags.map(tag => (
      <Tag key={tag.name} {...tag} />
    ))}
  </Wrapper>
);

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onRemove: PropTypes.func.isRequired,
      canEdit: PropTypes.bool
    })
  ),
  justifyContent: PropTypes.string
};

Tags.defaultProps = {
  tags: [],
  justifyContent: 'flex-start'
};

export default Tags;
