import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';

const PublishGamesModal = ({ onClose, selected, onSubmit }) => {
  const unpublished = selected.filter(game => !game.published);
  return (
    <Modal title="Open self-assignment" onClose={onClose} leftPanel>
      <>
        <Row>
          <HighlightedBlock xs={12}>
            <Content>
              <Summary>
                <Count color={COLORS.denimBlue}>{unpublished.length}</Count> Games Selected
              </Summary>
            </Content>
          </HighlightedBlock>
        </Row>
        <Row>
          <Block xs={10} xsOffset={1}>
            <Content first light>
              This allows users without assignment permission to see game information, whether or
              not they have been assigned to the game(s).
            </Content>
            <Content>
              {unpublished.length
                ? 'Open self-assignment?'
                : 'The selected games are already open for self-assignment'}
            </Content>
          </Block>
        </Row>
        <Row>
          <Block xs={12} className="last">
            <ButtonsContainer>
              <ButtonContainer>
                <Button
                  label="Confirm"
                  onClick={() => {
                    onClose();
                    onSubmit(unpublished);
                  }}
                  disabled={!unpublished.length}
                  width="72px"
                  height="32px"
                  primary
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
              </ButtonContainer>
            </ButtonsContainer>
          </Block>
        </Row>
      </>
    </Modal>
  );
};

PublishGamesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired
};

PublishGamesModal.defaultProps = {};

export default PublishGamesModal;
