import React from 'react';
import PropTypes from 'prop-types';

import Filters from '../../../Filters';
import config from './config';

const ReportFilter = ({ reportType, ...filterProps }) => (
  <Filters config={config[reportType]} {...filterProps} />
);

ReportFilter.propTypes = {
  reportType: PropTypes.string.isRequired
};

export default ReportFilter;
