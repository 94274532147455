import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import EmailSender from '../../../EmailSender';
import { Block, Content } from './styled-components';
import Enhancer from '../../../../containers/ImportModal';

const EmailUsersModal = ({
  onClose,
  selected,
  onSubmit,
  allSelect,
  toggleSelectAll,
  config,
  eventInfo,
  role_ids
}) => (
  <Modal title="Email selected" onClose={onClose} rightPanel background userList>
    <Row>
      <Block xs={12}>
        <Content>
          <EmailSender
            userList={selected}
            onSubmit={onSubmit}
            onClose={onClose}
            allSelected={allSelect}
            config={config}
            toggleSelectAll={toggleSelectAll}
            eventInfo={eventInfo}
            roleIds={role_ids}
          />
        </Content>
      </Block>
    </Row>
  </Modal>
);

EmailUsersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired
};

EmailUsersModal.defaultProps = {};

export default Enhancer(EmailUsersModal);
