import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import Modal from '../../../../Modal';
import Button from '../../../../Button';
import { ButtonsContainer, ContentWrapper } from '../../../../Reports/Modals/styled-components';
import { Block } from '../../../Categories/styled-components';
import { Content } from '../../../../Modal/styled-components';
import { ButtonContainer } from '../../styled-components';

const CrewWarningModal = ({ setCrewWarningModal, crewWarningModal, onSubmit }) => (
  <Modal
    title="Crew Warning"
    onClose={() => {
      setCrewWarningModal(false);
    }}
    background
  >
    <Row>
      <ContentWrapper>
        <Block xs={10} xsOffset={1} className="content">
          <Content>{crewWarningModal.warning}</Content>
        </Block>
      </ContentWrapper>
      <Block xs={12}>
        <ButtonsContainer>
          <ButtonContainer>
            <Button
              label="Update"
              primary
              onClick={() => {
                onSubmit({ ...crewWarningModal.crew_pay_table, ignoreWarning: true });
                setCrewWarningModal(false);
              }}
              name="update"
              width="84px"
              height="32px"
            />
          </ButtonContainer>
          <ButtonContainer>
            <Button
              label="Cancel"
              width="84px"
              height="32px"
              onClick={() => {
                setCrewWarningModal(false);
              }}
            />
          </ButtonContainer>
        </ButtonsContainer>
      </Block>
    </Row>
  </Modal>
);

CrewWarningModal.propTypes = {
  crewWarningModal: PropTypes.object.isRequired,
  setCrewWarningModal: PropTypes.func.isRequired
};

CrewWarningModal.defaultProps = {};

export default CrewWarningModal;
