import { compose } from 'recompose';
import { connect } from 'react-redux';

import unconnected from './unconnected';
import { fetchEvent, fetchFilteredEvents as fetchFilteredEventsAction } from '../../../core/Events';
import { getEvents, getFilteredEvents } from '../../../core/Events/selectors';
import { isSuperAdmin } from '../../../core/Auth/selectors';

const mapStateToProps = state => ({
  events: getEvents(state),
  filteredEvents: getFilteredEvents(state),
  isSuperAdmin: isSuperAdmin(state)
});

const mapDispatchToProps = dispatch => ({
  fetchEvent: payload => dispatch(fetchEvent(payload)),
  fetchFilteredEvents: payload => dispatch(fetchFilteredEventsAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
