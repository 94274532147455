import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import {
  NotificationRow,
  Summary,
  OrgName,
  Chevron,
  ExpandedWrapper,
  Details,
  GameDataRow,
  GameDataRowItem
} from '../styled-components';
import { ICONS, COLORS } from '../../../../utils/styles';
import { formatDate } from '../../../../utils/parsers';
import Enhancer from '../../../containers/NotificationsMenu/SubMenu/CrewVerificationList';
import LoaderWrapper from '../../LoaderWrapper';
import { convertUtcToTimezone, getTimezoneAbbr } from '../../../../utils/helpers';

const NotificationExpanded = ({ selectedNotification, onSubmit }) => {
  const { game, event } = selectedNotification;
  const crew =
    (game.assignments_game.length &&
      game.assignments_game.filter(
        ga =>
          ga.event_role &&
          ga.event_role.user &&
          ga.event_role.user.id === selectedNotification.user_id
      )) ||
    [];
  const crewPosition =
    game && game.game_level && crew.length && game.game_level.labels[crew[0].official_label_col];

  return (
    <ExpandedWrapper flexDirection="column">
      {selectedNotification && game && event && (
        <>
          <Details>
            <GameDataRow>
              <GameDataRowItem>
                {event.name || event.id} - {game.display_id || game.id}
              </GameDataRowItem>
            </GameDataRow>
            {crewPosition && (
              <GameDataRow>
                <GameDataRowItem>{crewPosition}</GameDataRowItem>
              </GameDataRow>
            )}
            <GameDataRow>
              {game.field ? (
                <GameDataRowItem>
                  {`${game.field.location.name}:${' '}
                  ${game.field.name ? `${game.field.name} - ` : ''}
                  ${game.field.location.city},${' '}
                  ${game.field.location.state}`}
                </GameDataRowItem>
              ) : (
                <GameDataRowItem>
                  {`${game.location.name}:${' '}
                  ${
                    game.location.city
                      ? `${game.field_name ? `${game.field_name} - ` : ''}
                         ${game.location.city},${' '}
                         ${game.location.state}`
                      : ''
                  }`}
                </GameDataRowItem>
              )}
            </GameDataRow>
            <GameDataRow>
              <GameDataRowItem>
                {game.team_visitor.name} {game.team_visitor.age_group || ''} @ {game.team_home.name}{' '}
                {game.team_home.age_group || ''}
              </GameDataRowItem>
            </GameDataRow>
          </Details>
          <Button
            label="Mark Attendance"
            primary
            width="144px"
            height="32px"
            center
            margin="0.75rem 0 0"
            customStyle={{ justifyContent: 'center' }}
            onClick={() => {
              onSubmit({
                game,
                event,
                expandedId: selectedNotification.id
              });
            }}
          />
        </>
      )}
    </ExpandedWrapper>
  );
};

NotificationExpanded.propTypes = {
  selectedNotification: PropTypes.shape({
    id: PropTypes.string,
    source_id: PropTypes.string,
    user_id: PropTypes.number,
    alert_type: PropTypes.string,
    status: PropTypes.string,
    game: PropTypes.object,
    created_at: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired
};

NotificationExpanded.defaultProps = {
  selectedNotification: {}
};

const SubMenu = ({
  notifications,
  selectedNotification,
  expandedId,
  onExpand,
  onSubmit,
  isFetching
}) => (
  <>
    <LoaderWrapper isFetching={isFetching && !notifications.length}>
      {notifications.map((notification, i) => {
        const isExpanded = expandedId === notification.id;
        const gameObj = notification.notifications_game;
        const gameDate =
          gameObj &&
          formatDate(
            convertUtcToTimezone(gameObj.start_at, gameObj.timezone),
            'ddd - MM/DD/YYYY @ h:mm A'
          );
        const tzAbbr =
          gameObj && getTimezoneAbbr(formatDate(gameDate, 'MM/DD/YYYY hh:mm A'), gameObj.timezone);

        return (
          <Fragment key={notification.id}>
            <NotificationRow isExpanded={isExpanded} noBorder={i === notifications.length - 1}>
              <Summary>
                Complete your crew verification from{' '}
                {gameObj && (
                  <>
                    <OrgName>{`${gameDate} (${tzAbbr})`}</OrgName>
                  </>
                )}
              </Summary>
              <Chevron
                icon={isExpanded ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_RIGHT}
                width={15}
                height={10}
                color={isExpanded ? COLORS.denimBlue : COLORS.shuttleGray}
                onClick={() => onExpand(isExpanded ? null : notification.id)}
                isExpanded={isExpanded}
              />
            </NotificationRow>
            {isExpanded && (
              <LoaderWrapper isFetching={isFetching}>
                <NotificationExpanded
                  selectedNotification={selectedNotification}
                  onSubmit={onSubmit}
                />
              </LoaderWrapper>
            )}
          </Fragment>
        );
      })}
    </LoaderWrapper>
  </>
);

SubMenu.propTypes = {
  notifications: PropTypes.arrayOf(Object),
  expandedId: PropTypes.string,
  onExpand: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedNotification: PropTypes.shape({
    id: PropTypes.string,
    source_id: PropTypes.string,
    user_id: PropTypes.number,
    alert_type: PropTypes.string,
    status: PropTypes.string,
    created_at: PropTypes.string
  }),
  isFetching: PropTypes.bool
};

SubMenu.defaultProps = {
  notifications: [],
  expandedId: null,
  selectedNotification: {},
  isFetching: false
};

export default Enhancer(SubMenu);
