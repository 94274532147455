import moment from 'moment';

import MultiSelectDropdown from '../../../Reusable/MultiSelectDropdown';
import MaterialDatePicker from '../../../../controls/MaterialDatePicker';
import MultipleInput from '../../../Reusable/MultipleInput';
import theme from './styled-components';
import STATUSES from '../../constants';
import { sortListByKey } from '../../../../../utils/helpers';

export const generateFields = ({
  gamelevels = [],
  categories = [],
  showOffNameFilter = true,
  complexes = [],
  teams = []
}) => [
  {
    component: MultiSelectDropdown,
    name: 'field.location.name',
    placeholder: 'Complex Name',
    options: sortListByKey(
      [...new Set(complexes.map(({ name }) => name))].map(name => ({
        value: name,
        name
      })),
      'value'
    ),
    theme: theme.multiSelectDropdown,
    noCount: true
  },
  {
    component: MaterialDatePicker,
    name: 'game.start_date',
    placeholder: 'Start Date',
    parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
    format: value => (value ? moment(value, 'YYYY-MM-DD').toDate() : null),
    theme: theme.materialDatePicker,
    style: { left: '-50px' },
    customClass: 'datepicker-alignment'
  },
  {
    component: MultiSelectDropdown,
    name: 'team.name',
    placeholder: 'Team Name',
    options: sortListByKey(
      teams.map(team => ({
        value: team.name,
        name: `${team.name} - ${team.game_lvl}`
      })),
      'value'
    ),
    theme: theme.multiSelectDropdown,
    noCount: true
  },
  {
    component: MaterialDatePicker,
    name: 'game.end_date',
    placeholder: 'End Date',
    parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
    format: value => (value ? moment(value, 'YYYY-MM-DD').toDate() : null),
    theme: theme.materialDatePicker,
    validate: (value, values) =>
      !value ||
      !values['game=>start_date'] ||
      moment(value).isSameOrAfter(moment(values['game=>start_date']))
        ? undefined
        : 'cannot be before Start Date',
    inlineError: true,
    customClass: 'datepicker-alignment'
  },
  {
    component: MultiSelectDropdown,
    name: 'game_level.schedule_id',
    placeholder: 'Level & Type',
    options: gamelevels
      .map(level => ({
        value: level.schedule_id,
        name: `${level.game_level} ${level.game_type}`
      }))
      .sort(
        (a, b) =>
          /^[0-9]/.test(a.name) - /^[0-9]/.test(b.name) ||
          a.name.localeCompare(b.name, undefined, { numeric: true })
      ),
    theme: theme.multiSelectDropdown,
    noCount: true
  },
  {
    component: MultiSelectDropdown,
    name: 'categories_filtered.category_id',
    placeholder: 'Category',
    options: categories.map(category => ({
      value: category.id,
      name: category.name
    })),
    theme: theme.multiSelectDropdown,
    noCount: true
  },
  {
    component: MultiSelectDropdown,
    name: 'game.status',
    placeholder: 'Status',
    options: STATUSES.map(status => ({
      value: status.value,
      name: status.name
    })),
    theme: theme.multiSelectDropdown,
    noCount: true,
    setHeight: true
  },
  showOffNameFilter
    ? {
        component: MultipleInput,
        name: 'officialNames',
        placeholder: 'Official/Assessor Name'
      }
    : null
];

export default generateFields;
