/**
 * In production, we register a service worker to serve assets from local cache.
 *
 * This lets the app load faster on subsequent visits in production, and gives
 * it offline capabilities. However, it also means that developers (and users)
 * will see deployed updates when they reload the page after getting
 * 'New version available' notification.
 */
import Logger from './logger';

export const swConfig = {
  onUpdate: ({ isNewVersion }) => {
    document.getElementById('swPopUp').style.display = isNewVersion ? 'flex' : 'none';

    document.getElementById('reload').onclick = function () {
      window.location.reload();
      document.getElementById('swPopUp').style.display = 'none';
    };
    document.getElementById('close').onclick = function () {
      document.getElementById('swPopUp').style.display = 'none';
    };
  }
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export class ServiceWorker {
  /**
   * Check if service worker exists for the provided url
   *
   * @param {URL} swUrl Service worker url
   * @param {Object} config Object containing callbacks
   * @logs The errors, if any
   */
  static checkValidSW(swUrl, config) {
    fetch(swUrl)
      .then(({ status, headers }) => {
        const jsIdx = headers.get('content-type').indexOf('javascript');

        // Ensure service worker exists, and that we really are getting a JS file.
        if (status === 404 || jsIdx === -1)
          navigator.serviceWorker.ready.then(reg =>
            reg.unregister().then(() => {
              window.location.reload();
            })
          );
        else this.registerSW(swUrl, config);
      })
      .catch(() => Logger.log('No internet connection found. App is running in offline mode.'));
  }

  /**
   * Registers and updates the service worker for provided url and
   * executes the passed callbacks if any update found
   *
   * @param {URL} swUrl Service worker url
   * @param {Object} config Object containing callbacks
   * @logs The errors and new version availability, if any
   */
  static async registerSW(swUrl, config) {
    navigator.serviceWorker
      .register(swUrl)
      .then(reg => {
        // eslint-disable-next-line
        reg.onupdatefound = () => {
          const { waiting, installing } = reg;

          installing.onstatechange = () => {
            if (installing.state === 'installed' || waiting) {
              // At this point, the fresh content will be in waiting state.
              // Its the perfect time to execute onUpdate callback
              if (config && config.onUpdate) {
                config.onUpdate({ isNewVersion: true });

                Logger.log('New version is available.');
              } // At this point, everything has been precached.
              else Logger.log('Content is cached for offline use.');
            }
          };
        };
      })
      .catch(err => Logger.error('Error during service worker registration:', err));
  }

  /**
   * Check if browser supports the service worker and environment
   * is production and then registers the service worker to serve
   * the assets from cache and executes the provided callbacks
   *
   * @param {Object} config Object containing callbacks
   * @logs Service worker registration or errors, if any
   */
  static register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location);

      // Our service worker won't work if PUBLIC_URL is on a
      // different origin from what our page is served on.
      if (publicUrl.origin !== window.location.origin) return;

      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        // If running on localhost. Lets check if a service worker still exists or not.
        if (isLocalhost) this.checkValidSW(swUrl, config);
        // If not localhost. Just register service worker.
        else this.registerSW(swUrl, config);
      });

      window.addEventListener('click', () => {
        navigator.serviceWorker.ready.then(res => {
          // Update the service worker registration, if any.
          res.update();

          Logger.log('Updated the service worker registration.');
        });
      });
    }
  }

  /**
   * Check if browser supports the service worker and
   * unregisters the service worker, if any
   */
  static unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
      });
    }
  }
}
