import React from 'react';
import PropTypes from 'prop-types';

import { Switcher, Label, OnLabel, OffLabel } from './styled-components';
import { pxToRem } from '../../../utils/helpers';

const Switch = ({ label, onLabel, offLabel, onClick, switchState, theme }) => (
  <Switcher theme={theme.switcher} onClick={onClick}>
    {label && <Label theme={theme.label}>{label}</Label>}
    <OnLabel switchState={switchState} theme={theme.onLabel}>
      {onLabel}
    </OnLabel>
    <OffLabel switchState={!switchState} theme={theme.offLabel}>
      {offLabel}
    </OffLabel>
  </Switcher>
);

Switch.propTypes = {
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  onClick: PropTypes.func,
  switchState: PropTypes.number,
  label: PropTypes.string,
  theme: PropTypes.shape({
    switcher: PropTypes.string,
    label: PropTypes.string,
    offLabel: PropTypes.shape({
      onState: PropTypes.string,
      offState: PropTypes.string
    }),
    onLabel: PropTypes.shape({
      onState: PropTypes.string,
      offState: PropTypes.string
    })
  })
};

Switch.defaultProps = {
  label: 'Event is: ',
  onLabel: 'Open',
  offLabel: 'Closed',
  switchState: 1,
  onClick: () => {},
  theme: {
    switcher: '',
    label: '',
    offLabel: {
      onState: `height: ${pxToRem('20px')};`,
      offState: `height: ${pxToRem('22px')};`
    },
    onLabel: {
      onState: `height: ${pxToRem('20px')};`,
      offState: `height: ${pxToRem('22px')};`
    }
  }
};

export default Switch;
