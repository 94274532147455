import { compose, withHandlers, lifecycle, withState } from 'recompose';

export default compose(
  withState('inEdit', 'setInEdit', false),
  withHandlers({
    onSubmit: ({
      editAssessmentReport,
      eventsAssessmentSettings,
      setInEdit,
      report: { id, updated_by_user_id }
    }) => data => {
      editAssessmentReport({
        id,
        data:
          !updated_by_user_id &&
          eventsAssessmentSettings &&
          eventsAssessmentSettings.allow_assessors_to_submit &&
          eventsAssessmentSettings.auto_approve_assessment
            ? { ...data, approved: true }
            : data
      });
      setInEdit(false);
    },
    onApprove: ({ editAssessmentReport }) => ({ id, approved }) =>
      editAssessmentReport({
        id,
        data: { approved: !approved },
        message: `Successfully ${approved ? 'un' : ''}approved assessment report`
      })
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchCurrentGame({ id: this.props.gameId });
      this.props.fetchCurrentAssessmentReport({ id: this.props.reportId });
    }
  })
);
