import styled from 'styled-components';
import { COLORS } from '../../../../../utils/styles';

export const Rows = styled.div`
  margin-left: 0px !important;
  margin-right: 0px !important;
`;

export const ModalContainer = styled.div`
  font-size: 13px;
  padding: 20px;
`;

export const Description = styled.p`
  font-size: 13px;
  color: ${COLORS.grey};
  text-align: center;
`;

export const Note = styled.textarea`
  width: 100%;
  font-size: 13px;
  height: 52px;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.gray};
  resize: none;
`;

export const ValidationError = styled.div`
  color: red;
  font-size: 10px;
`;
