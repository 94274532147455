import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export default styled.hr`
  margin: 0;
  border-style: solid;
  border-width: 0.02rem;
  border-color: ${COLORS.iron};
`;
