import React from 'react';
import { Field } from 'react-final-form';
import { Row, Col } from 'react-flexbox-grid';
import RoundedCheckBox from '../../../RoundedCheckBox';
import { FillHeight, WrapperCrewPosition, Note } from '../styled-components';
import { falsyToNull } from '../../../../../utils/parsers';
import ConditionalField from '../../../ConditionalField';
import { HelpText } from '../../Categories/styled-components';
import FieldError from '../../../FieldError';

export default () => (
  <Row middle="xs">
    <Col xs={12}>
      <Row>
        <Col xs={12}>
          <Field name="allow_officials_to_checkin" parse={falsyToNull}>
            {({ input }) => (
              <RoundedCheckBox {...input} label="Require crew members to confirm attendance" />
            )}
          </Field>
          <Note>
            <i>Note: This will restrict any unconfirmed crew members from being paid.</i>
          </Note>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <HelpText>Which crew members are allowed to check in other crew members?</HelpText>
        </Col>
      </Row>
      <ConditionalField when="allow_officials_to_checkin" predicate={type => !type}>
        {settingsEnable => (
          <WrapperCrewPosition>
            <Col xs={12}>
              <FillHeight>
                {[1, 2, 3, 4, 5, 6, 7, 8].map(position => (
                  <Col xs={3} key={position}>
                    <Field name={`checkin_position_${position}`} parse={falsyToNull}>
                      {({ input }) => (
                        <RoundedCheckBox
                          {...input}
                          disabled={settingsEnable}
                          locked={settingsEnable}
                        />
                      )}
                    </Field>
                    <HelpText>Position {position}</HelpText>
                  </Col>
                ))}
              </FillHeight>
              <FieldError name="checkin_position" />
            </Col>
          </WrapperCrewPosition>
        )}
      </ConditionalField>
    </Col>
  </Row>
);
