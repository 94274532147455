import { createSelector } from 'reselect';
import { find, equals } from 'ramda';

export const getTeams = ({ teams: { list } }) => list;

export const getTeamMeta = ({ teams: { meta } }) => meta;

export const getTotalTeamCount = createSelector(
  getTeamMeta,
  ({ total_count: totalCount }) => totalCount
);

export const getTeamLimit = createSelector(getTeamMeta, ({ limit }) => limit);

export const getTeamById = (state, id) =>
  createSelector(
    () => getTeams(state),
    find(({ teamId }) => equals(teamId, id))
  )(state);
