export default {
  reports: {
    nonInvoiced: {
      code: 1,
      view: {
        label: 'Non-Invoiced Fees report'
      },
      csv: {
        prefix: 'non_invoiced_fee_report'
      }
    },
    invoiced: {
      code: 2,
      view: {
        label: 'Invoiced Fees report'
      },
      csv: {
        prefix: 'invoiced_fee_report'
      }
    },
    platformTransactionDetails: {
      code: 3,
      view: {
        label: 'Platform Itemized transaction details'
      },
      csv: {
        prefix: 'platform_itemized_transaction_details'
      }
    },
    groupsReport: {
      code: 4,
      view: {
        label: 'Groups Report'
      },
      csv: {
        prefix: 'group_report'
      }
    }
  }
};
