import { Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import COLORS from '../../../utils/styles';

export const FormWrapper = styled.div`
  width: 450px;
  margin: 20px auto;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  margin: 5px auto;
  padding-left: 2.5%;
  box-sizing: border-box;
  & form > div {
    width: 95%;
  }
  &.walletBalance {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }
`;

export const Error = styled.span`
  color: #c61e1e;
  font-size: 12px;
  width: 95%;
  margin-left: 2%;
`;

export const ButtonContainer = styled.div`
  width: 90%;
  margin-left: 20px;
  padding: 10px 0px;
`;

export const Heading = styled.div`
  margin: 16px 10px 0px 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const SectionHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0px 10px 0px 10px;
  & .paddingZero {
    padding: 0px;
  }
`;
export const SmallerText = styled.div`
  padding: 15px 20px;
  font-size: x-small;
`;

export const AmountText = styled.span`
  font-size: 15px;
  display: inline-block;
  font-weight: bolder;
`;
export const BoldSpan = styled.span`
  padding: 5px 0px 0px 0px;
  font-size: 15px;
  display: inline-block;
`;
export const SpanBlue = styled.span`
  color: ${COLORS.denimBlue};
  padding-top: 3px;
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-weight: bolder;
`;

export const SectionCol = styled(Col)`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px 0px 0px 10px;
`;
