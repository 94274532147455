import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';

export const TitleContainer = styled.div`
  background: ${`linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue})`};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const ContentContainer = styled.div`
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ overflowY }) => overflowY && `overflow-Y: ${overflowY}`};
`;

export const ActionsContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  ${({ noCancel }) => noCancel && 'justify-content: center'};
  background: ${({ background }) => background || ''}
  z-index:9;
`;

export const HelpText = styled.div`
  font-size: 13px;
  color: ${COLORS.greyish};
  text-align: center;
  padding-bottom: 10px;
`;
