// Actions
export const FETCH_INCIDENT_REPORTS = 'REPORTS/FETCH_INCIDENT_REPORTS';
export const SET_INCIDENT_REPORTS = 'REPORTS/SET_INCIDENT_REPORTS';
export const EDIT_INCIDENT_REPORT = 'REPORTS/EDIT_INCIDENT_REPORT';
export const UPDATE_INCIDENT_REPORT = 'REPORTS/UPDATE_INCIDENT_REPORT';

export const FETCH_ASSESSMENT_REPORTS = 'REPORTS/FETCH_ASSESSMENT_REPORTS';
export const SET_ASSESSMENT_REPORTS = 'REPORTS/SET_ASSESSMENT_REPORTS';
export const EDIT_ASSESSMENT_REPORT = 'REPORTS/EDIT_ASSESSMENT_REPORT';
export const UPDATE_ASSESSMENT_REPORT = 'REPORTS/UPDATE_ASSESSMENT_REPORT';

export const SET_SELECTED_REPORTS_EVENT = 'REPORTS/SET_SELECTED_REPORTS_EVENT';

export const STORE_REPORTS_EVENT = 'REPORTS/STORE_REPORTS_EVENT';
export const STORE_REPORTS_EVENT_AND_REDIRECT = 'REPORTS/STORE_REPORTS_EVENT_AND_REDIRECT';

export const BULK_UPDATE_REPORTS = 'REPORTS/BULK_UPDATE_REPORTS';
export const BULK_UPDATE_ASSESSMENT_REPORTS = 'REPORTS/BULK_UPDATE_ASSESSMENT_REPORTS';
export const BULK_UPDATE_GAME_REPORTS = 'REPORTS/BULK_UPDATE_GAME_REPORTS';

export const FETCH_REPORTS_EVENT_CREW_LABELS = 'REPORTS/FETCH_REPORTS_EVENT_CREW_LABELS';
export const SET_REPORTS_EVENT_CREW_LABELS = 'REPORTS/SET_REPORTS_EVENT_CREW_LABELS';

export const FETCH_CURRENT_ASSESSMENT_REPORT = 'REPORTS/FETCH_CURRENT_ASSESSMENT_REPORT';
export const SET_CURRENT_REPORT = 'REPORTS/SET_CURRENT_REPORT';

export const SET_IS_FETCHING = 'REPORTS/SET_IS_FETCHING';
export const SET_CURRENT_IS_FETCHING = 'REPORTS/SET_CURRENT_IS_FETCHING';

export const FETCH_GAME_REPORTS = 'REPORTS/FETCH_GAME_REPORTS';
export const SET_GAME_REPORTS = 'REPORTS/SET_GAME_REPORTS';
export const UPDATE_GAME_REPORT = 'REPORTS/UPDATE_GAME_REPORT';
export const SET_GAME_REPORT = 'REPORTS/SET_GAME_REPORT';

export const SET_IS_FETCHING_GAME_REPORTS = 'REPORTS/SET_IS_FETCHING_GAME_REPORTS';

// Reducer
export const initialState = {
  incidentReports: [],
  assessmentReports: [],
  gameReports: [],
  meta: { total_count: 0, limit: 100 },
  selectedReportsEvent: {
    id: null
  },
  isFetching: false,
  current: {
    id: null,
    isFetching: false
  },
  isFetchingGameReports: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_INCIDENT_REPORTS:
      return {
        ...state,
        incidentReports: action.payload.data,
        meta: { ...state.meta, ...action.payload.meta }
      };
    case UPDATE_INCIDENT_REPORT: {
      const reportIdx = state.incidentReports.findIndex(report => report.id === action.payload.id);

      return {
        ...state,
        incidentReports: [
          ...state.incidentReports.slice(0, reportIdx),
          { ...state.incidentReports[reportIdx], ...action.payload },
          ...state.incidentReports.slice(reportIdx + 1)
        ]
      };
    }
    case SET_ASSESSMENT_REPORTS:
      return {
        ...state,
        assessmentReports: action.payload.data,
        meta: { ...state.meta, ...action.payload.meta }
      };
    case UPDATE_ASSESSMENT_REPORT: {
      const gameIdx = state.assessmentReports.findIndex(reports =>
        reports.find(r => Number(r.id) === Number(action.payload.id))
      );
      const reportIdx = state.assessmentReports[gameIdx].findIndex(
        r => Number(r.id) === Number(action.payload.id)
      );

      return {
        ...state,
        assessmentReports: [
          ...state.assessmentReports.slice(0, gameIdx),
          [
            ...state.assessmentReports[gameIdx].slice(0, reportIdx),
            { ...state.assessmentReports[gameIdx][reportIdx], ...action.payload },
            ...state.assessmentReports[gameIdx].slice(reportIdx + 1)
          ],
          ...state.assessmentReports.slice(gameIdx + 1)
        ]
      };
    }
    case SET_SELECTED_REPORTS_EVENT:
      return {
        ...state,
        selectedReportsEvent: action.payload
      };
    case SET_REPORTS_EVENT_CREW_LABELS:
      return {
        ...state,
        reportsEventCrewLabels: action.payload
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_IS_FETCHING_GAME_REPORTS:
      return {
        ...state,
        isFetchingGameReports: action.payload
      };
    case SET_CURRENT_REPORT:
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload
        }
      };
    case SET_CURRENT_IS_FETCHING:
      return {
        ...state,
        current: {
          ...state.current,
          isFetching: action.payload
        }
      };
    case SET_GAME_REPORTS:
      return {
        ...state,
        gameReports: action.payload.data,
        meta: { ...state.meta, ...action.payload.meta }
      };
    case SET_GAME_REPORT: {
      const reportIdx = state.gameReports.findIndex(report => report.id === action.payload.id);
      const report = action.payload;
      const updatedReport = {
        ...report,
        home_team: {
          id: report.home_team.id,
          type: report.home_team.type,
          score: report.home_team.score,
          cautions: report.home_team.cautions,
          send_off: report.home_team.send_off,
          team_rosters: report.home_team.team_rosters.map(
            ({ id, is_caution, is_send_off, total_goals }) => ({
              id,
              is_caution,
              is_send_off,
              total_goals
            })
          )
        },
        away_team: {
          id: report.away_team.id,
          type: report.away_team.type,
          score: report.away_team.score,
          cautions: report.away_team.cautions,
          send_off: report.away_team.send_off,
          team_rosters: report.away_team.team_rosters.map(
            ({ id, is_caution, is_send_off, total_goals }) => ({
              id,
              is_caution,
              is_send_off,
              total_goals
            })
          )
        }
      };
      return {
        ...state,
        gameReports: [
          ...state.gameReports.slice(0, reportIdx),
          {
            ...state.gameReports[reportIdx],
            ...updatedReport
          },
          ...state.gameReports.slice(reportIdx + 1)
        ]
      };
    }
    default:
      return state;
  }
}

// Action Creators
export const setIsFetching = payload => ({
  type: SET_IS_FETCHING,
  payload
});

export const setCurrentIsFetching = payload => ({
  type: SET_CURRENT_IS_FETCHING,
  payload
});

export const fetchIncidentReports = payload => ({
  type: FETCH_INCIDENT_REPORTS,
  payload
});

export const setIncidentReports = payload => ({
  type: SET_INCIDENT_REPORTS,
  payload
});

export const fetchAssessmentReports = payload => ({
  type: FETCH_ASSESSMENT_REPORTS,
  payload
});

export const setAssessmentReports = payload => ({
  type: SET_ASSESSMENT_REPORTS,
  payload
});

export const editAssessmentReport = payload => ({
  type: EDIT_ASSESSMENT_REPORT,
  payload
});

export const updateAssessmentReport = payload => ({
  type: UPDATE_ASSESSMENT_REPORT,
  payload
});

export const setSelectedReportsEvent = payload => ({
  type: SET_SELECTED_REPORTS_EVENT,
  payload
});

export const storeReportsEvent = payload => ({
  type: STORE_REPORTS_EVENT,
  payload
});

export const storeReportsEventAndRedirect = payload => ({
  type: STORE_REPORTS_EVENT_AND_REDIRECT,
  payload
});

export const bulkUpdateReports = payload => ({
  type: BULK_UPDATE_REPORTS,
  payload
});

export const bulkUpdateAssessmentReports = payload => ({
  type: BULK_UPDATE_ASSESSMENT_REPORTS,
  payload
});
export const bulkUpdateGameReports = payload => ({
  type: BULK_UPDATE_GAME_REPORTS,
  payload
});

export const fetchReportsEventCrewLabels = () => ({
  type: FETCH_REPORTS_EVENT_CREW_LABELS
});

export const setReportsEventCrewLabels = payload => ({
  type: SET_REPORTS_EVENT_CREW_LABELS,
  payload
});

export const fetchCurrentAssessmentReport = payload => ({
  type: FETCH_CURRENT_ASSESSMENT_REPORT,
  payload
});

export const setCurrentReport = payload => ({
  type: SET_CURRENT_REPORT,
  payload
});

export const editIncidentReport = payload => ({
  type: EDIT_INCIDENT_REPORT,
  payload
});

export const updateIncidentReport = payload => ({
  type: UPDATE_INCIDENT_REPORT,
  payload
});

export const fetchGameReports = payload => ({
  type: FETCH_GAME_REPORTS,
  payload
});

export const setGameReports = payload => ({
  type: SET_GAME_REPORTS,
  payload
});

export const updateGameReport = payload => {
  return {
    type: UPDATE_GAME_REPORT,
    payload
  };
};

export const setGameReport = payload => ({
  type: SET_GAME_REPORT,
  payload
});

export const setFetchingGameReport = payload => ({
  type: SET_IS_FETCHING_GAME_REPORTS,
  payload
});
