import React from 'react';

import Enhancer from '../../../containers/Dashboards/GroupsAndSubscriptions';
import { Wrapper } from './styled-components';
import BannerUnsheduledNotifications from '../../BannerNotifications';
import UpcomingGamesInfo from '../../UpcomingGamesInfo';

const Dashboard = props => (
  <Wrapper>
    <BannerUnsheduledNotifications
      bannerHeading="These games have been unscheduled and will affect the crews listed below. A game COPY in “Cancelled” status will appear in your event to manage assignments and/or create pay."
      {...props}
    />
    <UpcomingGamesInfo />
  </Wrapper>
);

export default Enhancer(Dashboard);
