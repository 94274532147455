import styled from 'styled-components';

import { COLORS } from '../../../../utils/styles';

export const HeadSelector = styled.div`
  display: flex;
  background-color: ${COLORS.blackSqueeze};
  margin: 1.5rem 1.5rem 0rem 1.5rem;
  padding: 0.5rem;
  align-items: center;
  justify-content: flex-start;
`;

export const FormContainer = styled.div`
  margin: 1.5rem 1.5rem;
`;

export const GroupContainer = styled.div`
  display: flex;
  margin: 0rem 1rem 1rem 1rem;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  align-self: flex-end;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${COLORS.altoGray};
  display: none;
`;
