import styled from 'styled-components';

import { pxToRem } from '../../../../../utils/helpers';
import { COLORS } from '../../../../../utils/styles';

export const Content = styled.div`
  display: flex;
  padding: 0 ${pxToRem('15px')};
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToRem('25px')};
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  color: ${({ textColor }) => textColor};

  &:not(:first-child) {
    margin-top: ${pxToRem('13px')};
  }
`;

export const AcceptGameOption = Option.extend`
  align-items: flex-start;
`;

export const InnerWrapper = styled.div``;

export const InputOuterWrapper = styled.div`
  margin-top: ${pxToRem('7px')};
  margin-left: ${pxToRem('38px')};
`;

export const InputWrapper = styled.div`
  margin-top: ${pxToRem('4px')};
`;

export const Input = styled.input`
  width: ${pxToRem('25px')};
  height: ${pxToRem('25px')};
  margin-right: ${pxToRem('4px')};
  border: 1px solid ${COLORS.hawkesBlue};
  opacity: 0.5;
  text-align: center;
  font: 500 ${pxToRem('13px')} 'RobotoMedium', sans-serif;
  color: inherit;
`;

export const DeclineInput = Input.extend`
  margin: 0 ${pxToRem('6px')};
`;

export const Label = styled.span`
  font: 500 ${pxToRem('13px')} 'RobotoMedium', sans-serif;
`;

export const Description = styled.p`
  margin: ${pxToRem('10px')} 0;
  font: 400 ${pxToRem('13px')} 'RobotoCondensed', sans-serif;
  font-stretch: condensed;
  color: ${COLORS.shuttleGray};
`;

export default Content;
