import React from 'react';
import PropTypes from 'prop-types';
import { Note, Wrapper } from './styled-components';
import Enhancer from '../../containers/ProfileInformation';
import LoaderWrapper from '../LoaderWrapper';
import ProfileInformationForm from './ProfileInformationForm';

const ProfileInformation = ({ onSubmit, initialValues, userIdx, isFetching }) => {
  const theme = {
    wrapper: {
      height: '100vh'
    }
  };

  return (
    <>
      <Wrapper>
        <Note>Start by filling out your personal information</Note>
        <LoaderWrapper isFetching={!initialValues.profile.email || isFetching} theme={theme}>
          <ProfileInformationForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            buttonLabel="Next"
            width="105px"
            userIdx={userIdx}
          />
        </LoaderWrapper>
      </Wrapper>
    </>
  );
};

ProfileInformation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    profile: PropTypes.object.isRequired,
    emergencyInfo: PropTypes.object,
    primaryAddress: PropTypes.object,
    travelAddress: PropTypes.object,
    primaryContact: PropTypes.object.isRequired,
    parentGuardianContact: PropTypes.object.isRequired
  }).isRequired
};
export default Enhancer(ProfileInformation);
