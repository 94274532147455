import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styled-components';

const MainLayout = ({ children }) => <Wrapper>{children}</Wrapper>;

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};

export default MainLayout;
