import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Enhancer from '../../../containers/AssignmentAvailability/availabilityTooltip';
import {
  AssignmentTitle,
  AssignmentWrapper,
  GreyTitle,
  Info,
  GameStatus,
  Chevron
} from '../styled-components';
import LoaderWrapper from '../../LoaderWrapper';
import config from '../AvailabilityEvents/config';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: '#fff',
    border: '1px solid rgb(0, 123, 255)',
    borderRadius: '0px',
    padding: '0px 0px',
    width: '160px'
  },
  customArrow: {
    '&::before': {
      backgroundColor: '#D2E9FF',
      border: '1px solid rgb(0, 123, 255)'
    }
  }
}));

const game_status = ['cancelled', 'postponed'];
const AssignmentTooltipTitle = ({
  headingTitle,
  startTimeFormat,
  endTimeFormat,
  gameStatus,
  eventName,
  assignedBy,
  location,
  isLocationFetching
}) => {
  return (
    <AssignmentWrapper>
      <AssignmentTitle>Assignment Details</AssignmentTitle>
      <LoaderWrapper isFetching={isLocationFetching}>
        <GreyTitle> Event name </GreyTitle>
        <Info>{eventName}</Info>
        <GameStatus>
          {game_status.includes(gameStatus && gameStatus.toLowerCase()) && <div>{gameStatus}</div>}
        </GameStatus>
        <GreyTitle>Location</GreyTitle>
        <Info>
          {location && location.name}{' '}
          {location && location.surface_name && `, ${location.surface_name}`}
        </Info>
        <Info />
        <GreyTitle>Time</GreyTitle>
        <Info>
          {startTimeFormat} - {endTimeFormat}
        </Info>
        <GreyTitle>Assigned by</GreyTitle>
        <Info>
          {assignedBy && assignedBy.first_name} {assignedBy && assignedBy.last_name}
        </Info>
        <GreyTitle>Status</GreyTitle>
        <Info>
          <Chevron {...config.tooltipAssignmentStatus[headingTitle]} />
          <span>{headingTitle}</span>
        </Info>
        <div />
      </LoaderWrapper>
    </AssignmentWrapper>
  );
};

const BlueThemeTooltip = ({
  children,
  fetchGameLocation,
  gameId,
  open,
  setOpen,
  setTooltipTarget,
  location,
  isLocationFetching,
  startTimeFormat,
  endTimeFormat,
  headingTitle,
  configValue,
  gameStatus,
  eventName,
  assignedBy,
  setUserAvailabilityNote,
  availabilityNote: { target }
}) => {
  const classes = useStyles();

  const callBackfun = () => {
    setOpen(false);
    if (location.target) setTooltipTarget();
    if (target) setUserAvailabilityNote();
  };
  const handleTooltipOpen = () => {
    setUserAvailabilityNote();
    fetchGameLocation({ gameId });
  };

  useEffect(() => {
    if (location.target === gameId) {
      setOpen(true);
    }
    // eslint-disable-next-line
  }, [location.target]);

  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    <ClickAwayListener onClickAway={callBackfun} mouseEvent="onMouseDown" touchEvent="onTouchStart">
      <Tooltip
        arrow
        title={
          <AssignmentTooltipTitle
            headingTitle={headingTitle}
            startTimeFormat={startTimeFormat}
            endTimeFormat={endTimeFormat}
            configValue={configValue}
            gameStatus={gameStatus}
            eventName={eventName}
            assignedBy={assignedBy}
            location={location}
            isLocationFetching={isLocationFetching}
          />
        }
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
          popper: classes.customPopper
        }}
        open={open}
      >
        <div onClick={handleTooltipOpen}>{children}</div>
      </Tooltip>
    </ClickAwayListener>
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
  );
};

export default Enhancer(BlueThemeTooltip);
