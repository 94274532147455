import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline;
  flex: 1;
  width: 100%;
  height: auto;
  background: #e5ebf2 0% 0% no-repeat padding-box;
`;

export default Wrapper;
