import React, { useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Modal from '../../Modal';
import Tag from '../../Tag';
import Icon from '../../Icon';
import Button from '../../Button';
import FieldSpreadLayoutContainer from '../../../layouts/FieldSpreadLayoutContainer';
import FormWrapper, {
  TitleBar,
  TagContainer,
  TagsContainer,
  ButtonWrapper,
  GameLockedContainer,
  TooltipWrapper,
  TooltipStyle
} from './styled-components';
import { COLORS, ICONS } from '../../../../utils/styles';
import { GAME_UPLOAD_TYPES } from '../../../../constants';
import { generalInfoConfig, locationConfig, teamsConfig, crewConfig } from './config';
import GameLevelChangeModal from '../Modals/GameLevelChangeModal';
import { getTimezoneByCode } from '../../../../utils/helpers';
import { printDateDifferenceInMinutes } from '../../../../utils/parsers';

const GeneralInformation = ({
  readOnly,
  gameLevels,
  display_id,
  start_date,
  start_time,
  end_date,
  end_time,
  game_level,
  schedule_name,
  schedule_owner,
  duration
}) => (
  <FieldSpreadLayoutContainer
    columns={2}
    deviation={3}
    useAsKey="placeholder"
    fields={generalInfoConfig(
      readOnly,
      gameLevels,
      display_id,
      start_date,
      start_time,
      end_date,
      end_time,
      game_level,
      schedule_name,
      schedule_owner,
      duration
    )}
  />
);

GeneralInformation.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  display_id: PropTypes.string,
  start_date: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  end_date: PropTypes.string,
  game_level: PropTypes.shape({
    game_level: PropTypes.string,
    game_type: PropTypes.string,
    length_period: PropTypes.number
  }),
  schedule_name: PropTypes.string,
  schedule_owner: PropTypes.string,
  gameLevels: PropTypes.arrayOf(Object).isRequired,
  duration: PropTypes.string
};

GeneralInformation.defaultProps = {
  display_id: '',
  start_date: '',
  start_time: '',
  end_time: '',
  end_date: '',
  game_level: {
    game_level: '',
    game_type: '',
    length_period: null
  },
  schedule_name: '',
  schedule_owner: '',
  duration: ''
};

const isCrewVisible = assignments_game => {
  let isVisible = false;
  if (assignments_game && assignments_game.length) {
    assignments_game.forEach(assignment => {
      if (!isVisible && ['pending', 'accepted', 'declined'].indexOf(assignment.status) > -1)
        isVisible = true;
      return assignment;
    });
  }
  return isVisible;
};

const Crew = ({ assignments_game, game_level, gameList }) =>
  isCrewVisible(assignments_game) && (
    <>
      <TitleBar>Crew</TitleBar>
      <FieldSpreadLayoutContainer
        columns={2}
        deviation={3}
        useAsKey="placeholder"
        fields={crewConfig(assignments_game, game_level, gameList)}
      />
    </>
  );

const Location = ({
  readOnly,
  field_name,
  location: { name, address, address_two, city, state, postal_code },
  timezone,
  timezonesList
}) => (
  <>
    <TitleBar>LOCATION</TitleBar>
    <FieldSpreadLayoutContainer
      columns={2}
      deviation={5}
      useAsKey="placeholder"
      fields={locationConfig({
        readOnly,
        field_name,
        name,
        address,
        address_two,
        city,
        state,
        postal_code,
        timezonesList,
        timezone: getTimezoneByCode(timezonesList, timezone).name
      })}
    />
  </>
);

Location.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  field_name: PropTypes.string,
  location: PropTypes.shape({
    name: PropTypes.string
  })
};

Location.defaultProps = {
  field_name: '',
  location: {}
};

const Categories = ({ categories }) => (
  <>
    <TitleBar>CATEGORIES</TitleBar>
    <TagsContainer>
      <TagContainer>
        {categories.map(category => (
          <Tag {...category} key={category.id} />
        ))}
      </TagContainer>
    </TagsContainer>
  </>
);

Categories.propTypes = {
  categories: PropTypes.arrayOf(Object)
};

Categories.defaultProps = {
  categories: []
};

const Teams = ({ readOnly, team_home, home_score, team_visitor, visitor_score }) => (
  <>
    <TitleBar>TEAMS</TitleBar>
    <FieldSpreadLayoutContainer
      columns={2}
      deviation={3}
      useAsKey="placeholder"
      fields={teamsConfig(readOnly, team_home, home_score, team_visitor, visitor_score)}
    />
  </>
);

const teamShape = {
  contact_name: PropTypes.string,
  contact_phone: PropTypes.string,
  contact_email: PropTypes.string,
  division: PropTypes.string,
  age: PropTypes.string,
  name: PropTypes.string
};

Teams.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  team_home: PropTypes.shape(teamShape),
  home_score: PropTypes.number,
  team_visitor: PropTypes.shape(teamShape),
  visitor_score: PropTypes.number
};

Teams.defaultProps = {
  team_home: {
    name: '',
    contact_name: '',
    contact_email: ''
  },
  home_score: null,
  team_visitor: '',
  visitor_score: null
};

const useStyles = makeStyles({
  cursorTooltip: {
    width: '300px'
  },
  tooltip: {
    fontSize: '13px'
  }
});

export const GameInfoModal = ({
  onClose,
  gameInfo: game,
  categories,
  gamesEventUploadType,
  isGamesEventGroupAdmin,
  isGamesEventAdmin,
  modalEditMode,
  toggleModalEditMode,
  onSubmit,
  gameList,
  confirmModifiedGameLevelModalOpen,
  setConfirmModifiedGameLevelModalOpen,
  setGameLevelChangeConfirmed,
  timezonesList,
  modalCopyGameMode,
  toggleModalCopyGameMode
}) => {
  // const game = getDisplayGameObject(gameInfo);
  const gameBatchedMessage =
    'This game cannot be changed because someone has already been paid for it.';
  const [openTooltip, setOpenTooltip] = useState(false);
  const classes = useStyles();
  const gameInitialValue = {
    ...game,
    duration: printDateDifferenceInMinutes({
      start_date: game.start_date,
      start_time: game.start_time,
      end_date: game.end_date,
      end_time: game.end_time
    })
  };
  return (
    <Modal title="Game Information" onClose={onClose}>
      <Form
        initialValues={gameInitialValue}
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, invalid, submitting, reset }) => (
          <>
            {confirmModifiedGameLevelModalOpen && !modalCopyGameMode && (
              <GameLevelChangeModal
                onClose={() => setConfirmModifiedGameLevelModalOpen(false)}
                onSubmit={() => {
                  setGameLevelChangeConfirmed(true, data => {
                    handleSubmit(data);
                  });
                }}
              />
            )}
            {!confirmModifiedGameLevelModalOpen && (
              <form onSubmit={handleSubmit}>
                {(isGamesEventAdmin || isGamesEventGroupAdmin) &&
                  (game.isGameBatched ? (
                    <TooltipWrapper>
                      <TooltipStyle
                        title="Create a game copy, which will NOT be connected to synced games or schedules."
                        direction="right"
                        disableHoverListener={
                          gamesEventUploadType === GAME_UPLOAD_TYPES.manual_upload
                        }
                      >
                        <FileCopy
                          fontSize="small"
                          onClick={() => toggleModalCopyGameMode(!modalCopyGameMode)}
                          style={{
                            verticalAlign: 'middle',
                            color: COLORS.denimBlue,
                            cursor: 'pointer',
                            marginBottom: '0.25rem'
                          }}
                        />
                      </TooltipStyle>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true
                        }}
                        open={openTooltip}
                        title={gameBatchedMessage}
                        arrow
                        classes={{ popper: classes.cursorTooltip, tooltip: classes.tooltip }}
                      >
                        <GameLockedContainer
                          onMouseEnter={() => setOpenTooltip(true)}
                          onMouseLeave={() => setOpenTooltip(false)}
                        >
                          <Icon width={22} height={18} icon={ICONS.LOCK} color={COLORS.denimBlue} />
                        </GameLockedContainer>
                      </Tooltip>
                    </TooltipWrapper>
                  ) : (
                    <TooltipWrapper>
                      <TooltipStyle
                        title="Create a game copy, which will NOT be connected to synced games or schedules."
                        direction="right"
                        disableHoverListener={
                          gamesEventUploadType === GAME_UPLOAD_TYPES.manual_upload
                        }
                      >
                        <FileCopy
                          fontSize="small"
                          onClick={() => toggleModalCopyGameMode(!modalCopyGameMode)}
                          style={{
                            verticalAlign: 'middle',
                            color: COLORS.denimBlue,
                            cursor: 'pointer'
                          }}
                        />
                      </TooltipStyle>

                      {(gamesEventUploadType === GAME_UPLOAD_TYPES.manual_upload || game.copy) && (
                        <TooltipStyle
                          title="Edit this un-synced game copy."
                          direction="right"
                          disableHoverListener={
                            gamesEventUploadType === GAME_UPLOAD_TYPES.manual_upload
                          }
                        >
                          <div>
                            <Icon
                              width={22}
                              height={18}
                              icon={ICONS.EDIT}
                              color={modalEditMode ? COLORS.red : COLORS.denimBlue}
                              clickable
                              margin="0px 0px"
                              onClick={() => toggleModalEditMode(!modalEditMode)}
                            />
                          </div>
                        </TooltipStyle>
                      )}
                    </TooltipWrapper>
                  ))}
                <FormWrapper>
                  <GeneralInformation readOnly={!modalEditMode} {...game} gameLevels={gameList} />
                  <Crew {...game} gameList={gameList} />
                  <Location readOnly={!modalEditMode} timezonesList={timezonesList} {...game} />
                  <Teams readOnly={!modalEditMode} {...game} />
                  <Categories categories={categories} />
                  {modalEditMode && (
                    <ButtonWrapper>
                      <FieldSpreadLayoutContainer
                        fields={[
                          <Button
                            type="button"
                            label="Cancel"
                            onClick={() => {
                              reset();
                              toggleModalEditMode(!modalEditMode);
                            }}
                            name="label"
                          />,
                          <Button
                            type="submit"
                            label="Update"
                            primary
                            disabled={pristine || invalid || submitting}
                          />
                        ]}
                        columns={2}
                      />
                    </ButtonWrapper>
                  )}
                </FormWrapper>
              </form>
            )}
          </>
        )}
      />
    </Modal>
  );
};

GameInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  gameInfo: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    id: PropTypes.string,
    home_score: PropTypes.number,
    visitor_score: PropTypes.number,
    division: PropTypes.string,
    field: PropTypes.object,
    team_home: PropTypes.object,
    team_visitor: PropTypes.object,
    game_level: PropTypes.object
  }).isRequired,
  categories: PropTypes.arrayOf(Object),
  gamesEventUploadType: PropTypes.number,
  isGamesEventGroupAdmin: PropTypes.bool.isRequired,
  modalEditMode: PropTypes.bool.isRequired,
  toggleModalEditMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  confirmModifiedGameLevelModalOpen: PropTypes.bool,
  setConfirmModifiedGameLevelModalOpen: PropTypes.func.isRequired,
  setGameLevelChangeConfirmed: PropTypes.func.isRequired
};

GameInfoModal.defaultProps = {
  categories: [],
  gamesEventUploadType: null,
  confirmModifiedGameLevelModalOpen: false
};

export default GameInfoModal;
