import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { isEmpty } from 'ramda';

import Modal from '../../../Modal';
import CheckBoxGroup from '../../../CheckBoxGroup';
import { notNilNotEmpty, capitalize } from '../../../../../utils/helpers';
import Button from '../../../Button';
import {
  Container,
  HeaderContainer,
  SelectedUsersLabel,
  UsersQuantity,
  ContentContainer,
  CheckBoxGroupContainer,
  CheckBoxGroupTitle,
  ButtonContainer
} from './styled-components';
import Enhancer from '../../../../containers/CategoryAssignorModal';

const assignCategories = ({
  userType,
  selectedUsers,
  checkBoxData,
  onSubmit,
  onClose,
  peopleTab,
  pagination,
  onLoadPageArgs,
  allSelected,
  event_id,
  role_ids
}) => {
  const checkedCategories = checkBoxData
    .filter(v => v.checked)
    .map(v => ({ category_id: v.value }));

  const categoriesToAssign = selectedUsers
    .map(v => v.eventRoleId)
    .reduce(
      (accumulator, eventRoleId) => accumulator.concat({ [eventRoleId]: checkedCategories }),
      []
    );

  onSubmit({
    categories: categoriesToAssign,
    userType,
    peopleTab,
    allSelected,
    event_id,
    role_ids
  });
  if (peopleTab) pagination.onLoadPage(onLoadPageArgs);
  onClose();
};

const UserCategoryAssignorModal = ({
  categories,
  checkBoxData,
  onClose,
  onSubmit,
  selected: selectedUsers,
  allSelect: allSelected,
  setCheckBoxData,
  type: userType,
  peopleTab,
  pagination,
  onLoadPageArgs,
  config,
  event_id,
  role_ids
}) => (
  <Modal title="Assign Categories" rightPanel onClose={onClose}>
    <Container>
      <HeaderContainer>
        {allSelected ? (
          <SelectedUsersLabel>
            <UsersQuantity>All</UsersQuantity>
            {`${pluralize(capitalize(config.label))} Selected`}
          </SelectedUsersLabel>
        ) : (
          <SelectedUsersLabel>
            <UsersQuantity>{selectedUsers.length}</UsersQuantity>
            {`${pluralize(capitalize(userType), selectedUsers.length)} Selected`}
          </SelectedUsersLabel>
        )}
      </HeaderContainer>
      <ContentContainer>
        {!notNilNotEmpty(checkBoxData) && (
          <CheckBoxGroupContainer>
            <CheckBoxGroupTitle>Select Categories</CheckBoxGroupTitle>
            <CheckBoxGroup config={checkBoxData} onChange={setCheckBoxData} />
          </CheckBoxGroupContainer>
        )}
        <ButtonContainer>
          <Button
            width="72"
            label="Assign"
            onClick={() =>
              assignCategories({
                categories,
                userType,
                selectedUsers,
                checkBoxData,
                onSubmit,
                onClose,
                peopleTab,
                pagination,
                onLoadPageArgs,
                allSelected,
                event_id,
                role_ids
              })
            }
            primary
            disabled={isEmpty(checkBoxData.filter(data => data.checked))}
          />
          <Button width="84" label="Cancel" onClick={onClose} secondary />
        </ButtonContainer>
      </ContentContainer>
    </Container>
  </Modal>
);

UserCategoryAssignorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  checkBoxData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setCheckBoxData: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default Enhancer(UserCategoryAssignorModal);
