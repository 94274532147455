import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';
import OutsideClickHandler from 'react-outside-click-handler';

import Tags from '../../Tags';
import { COLORS, ICONS } from '../../../../utils/styles';
import {
  Wrapper,
  Label,
  Chevron,
  Header,
  Option,
  Options,
  Count,
  CheckIcon
} from './styled-components';

/**
 * Built to integrate with React-Final-Form, dropdown with multi-select functionality
 * @param {Array} options Array of option objects with name and value keys
 * @param {Object} input contains onChange and value attributes
 * @param {Object} theme *optional* for customizing styles
 * @param {String} placeholder  Label to display on dropdown
 * @param {Boolean} noCount *optional* hides summary count
 * @param {Boolean} withTags *optional* adds removable tags underneath input
 */
const MultiSelectDropdown = ({
  toggleShowOptions,
  showOptions,
  options,
  input: { value, onChange },
  theme,
  placeholder: label,
  noCount,
  withTags,
  outsideClickStyle,
  checkValueCount,
  setHeight
}) => {
  let values = value || [];
  values = Array.isArray(values) ? values : [values];
  let count = 0;
  options.map(option => {
    if (values.find(val => val === option.value)) {
      count += 1;
    }
    return count;
  });
  return (
    <div style={outsideClickStyle}>
      <OutsideClickHandler onOutsideClick={() => showOptions && toggleShowOptions(false)}>
        <Wrapper theme={theme.wrapper}>
          <Header
            onClick={() => toggleShowOptions(s => !s)}
            theme={theme.header}
            className={values.length ? 'hasValue' : ''}
          >
            {!noCount && (
              <Count theme={theme.count}>{checkValueCount ? count : values.length}</Count>
            )}
            <Label theme={theme.label}>{label}</Label>
            <Chevron
              width={10}
              height={16}
              color={(theme.chevron && theme.chevron.color) || COLORS.denimBlue}
              icon={ICONS.CHEVRON_DOWN}
              theme={theme.chevron}
            />
          </Header>
          {showOptions && (
            <Options theme={theme.options} setHeight={setHeight}>
              {options.map(option => (
                <Option
                  theme={theme.option}
                  key={option.value}
                  onClick={() => {
                    onChange(
                      !!values && values.find(val => val === option.value)
                        ? values.filter(val => val !== option.value)
                        : [...values, option.value]
                    );
                    toggleShowOptions(true);
                  }}
                >
                  {option.name}
                  {!!values && !!values.find(val => val === option.value) && (
                    <CheckIcon
                      theme={theme.checkIcon}
                      width={15}
                      height={15}
                      icon={ICONS.CHECK_CIRCLE}
                      color={theme.checkColor || COLORS.denimBlue}
                    />
                  )}
                </Option>
              ))}
            </Options>
          )}
        </Wrapper>
        {withTags && values && !!values.length && (
          <Tags
            tags={values
              .filter(val => !!options.find(option => option.value === val))
              .map(val => ({
                name: options.find(option => option.value === val).name,
                onRemove: () => onChange(values.filter(otherVal => val !== otherVal)),
                canEdit: true,
                color: COLORS.denimBlue,
                backgroundColor: theme.tagBackgroundColor
              }))}
          />
        )}
      </OutsideClickHandler>
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  toggleShowOptions: PropTypes.func.isRequired,
  showOptions: PropTypes.bool,
  options: PropTypes.arrayOf(Object),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    wrapper: PropTypes.string
  }),
  noCount: PropTypes.bool,
  withTags: PropTypes.bool
};

MultiSelectDropdown.defaultProps = {
  showOptions: false,
  options: [],
  theme: {},
  noCount: false,
  withTags: false
};

export default compose(withState('showOptions', 'toggleShowOptions', false))(MultiSelectDropdown);
