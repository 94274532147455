import React from 'react';
import PropTypes from 'prop-types';
import { Round, Label, CheckBox, InlineLabel, CustomRound } from './styled-components';
import { HelpText } from '../Event/Categories/styled-components';

const Tick = ({
  name,
  value,
  required,
  onChange,
  locked,
  disabled,
  readOnly,
  inlineLabel,
  height,
  width,
  widthLabel,
  heightLabel,
  selected
}) => (
  <>
    <CheckBox
      name={name}
      type="checkbox"
      checked={value}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      selected={selected}
    />
    <Label
      htmlFor="checkbox"
      height={height}
      width={width}
      widthLabel={widthLabel}
      heightLabel={heightLabel}
      disabled={disabled}
      selected={selected}
      onClick={() => (!locked || !disabled) && onChange(!value)}
      locked={locked}
    />
    {inlineLabel && <InlineLabel>{inlineLabel}</InlineLabel>}
  </>
);

const RoundCheckbox = ({
  name,
  value,
  required,
  onChange,
  locked,
  disabled,
  readOnly,
  label,
  inlineLabel,
  height,
  width,
  widthLabel,
  heightLabel,
  customRound,
  selected,
  mainContainerStyle,
  labelStyle,
  customStyle
}) => (
  <span style={mainContainerStyle && mainContainerStyle}>
    {customRound ? (
      <CustomRound style={customStyle}>
        <Tick
          name={name}
          value={value}
          required={required}
          onChange={onChange}
          locked={locked}
          disabled={disabled}
          readOnly={readOnly}
          inlineLabel={inlineLabel}
          height={height}
          width={width}
          widthLabel={widthLabel}
          heightLabel={heightLabel}
        />
      </CustomRound>
    ) : (
      <Round style={customStyle}>
        <Tick
          name={name}
          value={value}
          required={required}
          onChange={onChange}
          locked={locked}
          disabled={disabled}
          readOnly={readOnly}
          inlineLabel={inlineLabel}
          height={height}
          width={width}
          widthLabel={widthLabel}
          heightLabel={heightLabel}
          selected={selected}
        />
      </Round>
    )}
    {label && <HelpText style={labelStyle && labelStyle}>{label}</HelpText>}
  </span>
);

RoundCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

RoundCheckbox.defaultProps = {
  name: '',
  required: false,
  value: false,
  label: null,
  locked: false,
  disabled: false,
  onChange: () => true,
  readOnly: false
};

export default RoundCheckbox;
