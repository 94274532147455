import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import {
  NotificationGridRow,
  Summary,
  ExpandedNotificationWrapper,
  OrgName
} from '../styled-components';
import { pxToRem } from '../../../../utils/helpers';
import Enhancer from '../../../containers/NotificationsMenu';
import { numToCurrency } from '../../../../utils/parsers';
import LoaderWrapper from '../../LoaderWrapper';

const SubMenu = ({ notifications, onAddFundsUpdate, isFetching }) => {
  return (
    <>
      <LoaderWrapper isFetching={isFetching && !notifications.length}>
        {notifications.map((notification, i) => {
          return (
            <Fragment key={notification.id}>
              <NotificationGridRow noBorder={i === notifications.length - 1}>
                <div style={{ textAlign: 'left' }}>
                  <Summary>
                    Your <OrgName>Add Funds To Wallet</OrgName> attempt of{' '}
                    <OrgName>{numToCurrency(Number(notification.data.amount))}</OrgName> has failed.
                    Please view your wallet information and transaction history on the Payment
                    Profile page and try again. Contact Support if this problem persists.
                  </Summary>
                </div>
                <ExpandedNotificationWrapper flexDirection="column">
                  <Button
                    label="Go to Payment Profile page"
                    primary
                    heightNotificationExpanded={pxToRem('32px')}
                    center
                    margin="0.75rem 0 0"
                    onClick={() => onAddFundsUpdate({ expandedId: notification.id })}
                  />
                </ExpandedNotificationWrapper>
              </NotificationGridRow>
            </Fragment>
          );
        })}
      </LoaderWrapper>
    </>
  );
};

SubMenu.propTypes = {
  notifications: PropTypes.arrayOf(Object),
  onAddFundsUpdate: PropTypes.func.isRequired
};

SubMenu.defaultProps = {
  notifications: []
};

export default Enhancer(SubMenu);
