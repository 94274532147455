import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { FieldArray } from 'react-final-form-arrays';
import Field, { NullableField } from '../../../../../../../utils/hocs/final-form-field-adapter';
import Dropdown from '../../../../../Reusable/Dropdown';
import MaterialInput from '../../../../../MaterialInput';
import {
  required,
  valueInRange,
  composeValidators,
  mustBeNumber
} from '../../../../../../../utils/validations';
import CheckField from '../../../../../CheckField';
import { generateUUID } from '../../../../../../../utils/helpers';

import { Wrapper, StyledRosterTrashIcon } from '../styled-components';
import '../styles.css';

import {
  FieldWrapper,
  Label,
  SubHeader,
  ButtonLabel,
  StyledIcon,
  AddButton
} from '../../../styled-components';
import { COLORS, ICONS } from '../../../../../../../utils/styles';
import options from '../../constants';
import { NumericField } from '../../../../../Event/AssignorSettings/NotificationCollapsible';
import { getTeamRosterTemplate } from '../utils';

const Roster = ({
  roster,
  disabled,
  theme,
  byPlayer,
  isSoccer,
  eventSportId,
  values,
  mutateGoals,
  index,
  field,
  fields,
  update
}) => {
  return (
    <Col xs={12} md={12} key={values[roster][index].uid} className="roster-wrapper">
      <FieldWrapper verticalMargins="0.5rem">
        <Row>
          <Col xs={12} md={6} className="Uniform-Jersey">
            <Wrapper theme={theme.wrapper}>
              <div className="serialNumber">{index + 1}.</div>
              <div className="inputField" style={{ marginLeft: '0.7rem' }}>
                <Field name={`${field}.name`} validate={required} placeholder="Player Name">
                  {({ input, meta }) => (
                    <>
                      <MaterialInput
                        input={{
                          ...input,

                          value:
                            values[roster] && values[roster][index] && values[roster][index].name
                        }}
                        placeholder="Player Name"
                        disabled={disabled}
                        meta={meta}
                      />
                      {/* <div className="errorNum text-left">
                        {(!input.value && meta.error) ||
                          (meta.invalid && meta.error && meta.modified && (
                            <span>Player Name {meta.error}</span>
                          ))}
                      </div> */}
                    </>
                  )}
                </Field>
              </div>
            </Wrapper>
          </Col>
          <Col xs={12} md={6} className="roster-jersery">
            <Row>
              <Col xs={12} md={5} className="Uniform-Jersey">
                <Wrapper theme={theme.wrapper}>
                  <div className="inputField">
                    <Field
                      name={`${field}.uniform`}
                      validate={composeValidators(mustBeNumber, valueInRange({ min: 0, max: 999 }))}
                      placeholder="Uniform/Jersey#"
                    >
                      {({ input, meta }) => (
                        <>
                          <MaterialInput
                            input={{
                              ...input
                            }}
                            placeholder="Uniform/Jersey#"
                            disabled={disabled}
                          />
                          <div className="errorNum">
                            {meta.dirty && meta.invalid && meta.error && <span>{meta.error}</span>}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                </Wrapper>
              </Col>
              <Col xs={12} md={5} className="Roster-pass">
                <Wrapper theme={theme.wrapper}>
                  <div className="inputField">
                    <Field
                      name={`${field}.roster`}
                      placeholder="Roster/pass#"
                      disabled={disabled}
                      component={MaterialInput}
                    />
                  </div>
                </Wrapper>
              </Col>
              <Col md={2}>
                {fields.value && fields.value.length > 1 && (
                  <div className="trashIcon iconUpdate">
                    <Field
                      name="del"
                      component={StyledRosterTrashIcon}
                      width={15}
                      height={15}
                      icon={ICONS.TRASH}
                      color={COLORS.hawkesBlue}
                      onClick={() => {
                        fields.remove(index);
                      }}
                      clickable
                      noMargin
                      disabled={disabled}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="roster-wrapper">
            <Row>
              <Col xs={12} md={12}>
                <div className="minINputWrap">
                  <div className="checkWrap">
                    <Field
                      name={`${field}.present`}
                      component={CheckField}
                      placeholder="Present"
                      width={12}
                      padding="0 0 0 1rem"
                      label="Present"
                      disabled={disabled}
                      isLabelBlue
                    />
                  </div>
                  <div className="checkWrap">
                    <Field
                      name={`${field}.suspended`}
                      component={CheckField}
                      placeholder="Suspended"
                      width={12}
                      padding="0 0 0 1rem"
                      label="Suspended"
                      disabled={disabled}
                      isLabelBlue
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {!isSoccer && byPlayer && (
            <Col xs={12} md={6} className="text-left">
              <div className="totalGoal">
                <Label color={COLORS.denimBlue}>
                  Total {eventSportId === 1 || eventSportId === 10 ? 'Runs' : 'Points'} Scored
                </Label>
                <div style={{ display: 'inline-block' }}>
                  <Field
                    name={`${field}.total_goals`}
                    validate={valueInRange({ min: 0, max: 999 })}
                    min={1}
                    max={999}
                    defaultValue={0}
                    padding="0 0 0 1rem"
                    label=""
                  >
                    {({ input, meta }) => (
                      <>
                        <NumericField
                          input={{
                            ...input,
                            value:
                              (values[roster] &&
                                values[roster][index] &&
                                values[roster][index].total_goals) ||
                              0
                          }}
                          min={0}
                          max={999}
                          disabled={disabled}
                        />
                        <div className="errorNum">
                          {meta.touched && meta.error && <span>{meta.error}</span>}
                        </div>
                      </>
                    )}
                  </Field>
                </div>
              </div>
            </Col>
          )}
          {isSoccer && byPlayer && (
            <>
              <Col xs={12} md={6}>
                <Row>
                  <Col md={12} className="cautionWrap">
                    <div className="checkWrap">
                      <Field
                        name={`${field}.is_caution`}
                        component={CheckField}
                        width={12}
                        padding="0 0 0 1rem"
                        label="Caution"
                        placeholder="Caution"
                        disabled={disabled}
                        render={({ input }) => {
                          return (
                            <>
                              <NumericField
                                input={{
                                  ...input,
                                  value:
                                    (values[roster] &&
                                      values[roster][index] &&
                                      values[roster][index].is_caution) ||
                                    false
                                }}
                                disabled={disabled}
                              />
                            </>
                          );
                        }}
                        isLabelBlue
                      />
                    </div>

                    <div className="caution dropdownField">
                      <Field
                        name={`${field}.caution`}
                        validate={null}
                        render={({ input }) => (
                          <Dropdown
                            label="Cautions"
                            theme={theme.dropdownField}
                            options={options.cautionOptions.map(name => ({
                              name,
                              value: name
                            }))}
                            input={input}
                            displayValue
                            disabled={disabled}
                            width={12}
                            padding="0 0 0 1rem"
                            hasHeight
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="minINputWrap">
                      <div className="checkWrap">
                        <Field
                          name={`${field}.is_subbed_in`}
                          component={CheckField}
                          placeholder="Subbed IN"
                          width={12}
                          padding="0 0 0 1rem"
                          label="Subbed IN"
                          disabled={disabled}
                          isLabelBlue
                        />
                      </div>
                      <div className="atMinTogether atMinTogetherOut">
                        <div className="checkWrap">
                          <Label color={COLORS.denimBlue}>at minute:</Label>
                        </div>
                        <div className="atMinuteField checkWrap">
                          <NullableField
                            name={`${field}.subbed_in_at_minute`}
                            validate={valueInRange({
                              min: 1,
                              max: 999
                            })}
                            min={1}
                            max={999}
                            component={NumericField}
                            defaultValue={null}
                            padding="0 0 0 1rem"
                            label=""
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="cautionMinWrap">
                      <div className="checkWrap">
                        <Label color={COLORS.denimBlue}>Caution Minute</Label>
                      </div>

                      <div className="atMinuteField checkWrap">
                        <NullableField
                          name={`${field}.caution_minute`}
                          validate={valueInRange({
                            min: 1,
                            max: 999
                          })}
                          min={1}
                          max={999}
                          component={NumericField}
                          defaultValue={null}
                          padding="0 0 0 1rem"
                          label=""
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={12} className="text-left">
                    <div className="minINputWrap">
                      <div className="checkWrap">
                        <Field
                          name={`${field}.is_subbed_out`}
                          component={CheckField}
                          placeholder="Subbed OUT"
                          width={12}
                          padding="0 0 0 1rem"
                          label="Subbed OUT"
                          disabled={disabled}
                          isLabelBlue
                        />
                      </div>
                      <div className="atMinTogether atMinTogetherOut">
                        <div className="checkWrap ">
                          <div className="atMinute">
                            <Label color={COLORS.denimBlue}>at minute:</Label>
                            <div className="atMinuteField checkWrap">
                              <NullableField
                                name={`${field}.subbed_out_at_minute`}
                                validate={valueInRange({
                                  min: 1,
                                  max: 999
                                })}
                                min={1}
                                max={999}
                                component={NumericField}
                                defaultValue={null}
                                padding="0 0 0 1rem"
                                label=""
                                disabled={disabled}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={12} className="text-left">
                    <div className="cautionWrap">
                      <div className="checkWrap no-padding-right">
                        <Field
                          name={`${field}.is_send_off`}
                          component={CheckField}
                          placeholder="Send Off"
                          width={12}
                          padding="0 0 0 1rem"
                          label="Send Off"
                          disabled={disabled}
                          isLabelBlue
                        />
                      </div>
                      <div className="caution dropdownField">
                        <Field
                          name={`${field}.send_off`}
                          validate={null}
                          render={({ input }) => (
                            <Dropdown
                              label="Send Off"
                              theme={theme.dropdownField}
                              options={options.sendOffOptions.map(name => ({
                                name,
                                value: name
                              }))}
                              input={input}
                              displayValue
                              disabled={disabled}
                              width={12}
                              padding="0 0 0 1rem"
                              hasHeight
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className="text-left">
                <div className="totalGoal">
                  <Label color={COLORS.denimBlue} style={{ paddingLeft: 0 }}>
                    Total Goal Scored
                  </Label>
                  <div className="atMinuteField checkWrap">
                    <Field
                      name={`${field}.total_goals`}
                      validate={valueInRange({ min: 0, max: 99 })}
                      min={0}
                      max={99}
                      padding="0 0 0 1rem"
                      label=""
                    >
                      {({ input, meta }) => (
                        <>
                          <NumericField
                            input={{
                              ...input,
                              onChange: e => {
                                if (e.target.value > 99 || e.target.value < 0) return;
                                mutateGoals(
                                  e.target.value - values[roster][index].goals.length,
                                  roster,
                                  index
                                );
                                update(`${field}.total_goals`, null, e.target.value);
                              }
                            }}
                            min={0}
                            max={999}
                            disabled={disabled}
                          />
                          <div className="errorNum">
                            {meta.touched && meta.error && <span>{meta.error}</span>}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="cautionMinWrap">
                      <div className="checkWrap removePdRht">
                        <Label color={COLORS.denimBlue}>Send Off Minute</Label>
                      </div>
                      <div className="atMinuteField checkWrap">
                        <NullableField
                          name={`${field}.send_off_minute`}
                          validate={valueInRange({
                            min: 1,
                            max: 999
                          })}
                          min={1}
                          max={999}
                          component={NumericField}
                          defaultValue={null}
                          padding="0 0 0 1rem"
                          label=""
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </FieldWrapper>
    </Col>
  );
};

const TeamRoster = ({
  roster,
  push,
  update,
  disabled,
  byPlayer,
  isSoccer,
  theme,
  reportSettings,
  eventSportId,
  values,
  mutateGoals
}) => (
  <>
    <SubHeader color={COLORS.sapphire}>TEAM ROSTER</SubHeader>
    <Row style={{ marginBottom: '0.75rem', marginLeft: '0.4rem' }}>
      <FieldArray name={roster}>
        {({ fields }) =>
          fields.map(
            (field, index) =>
              values[roster][index] && (
                <Roster
                  key={values[roster][index].uid}
                  roster={roster}
                  disabled={disabled}
                  theme={theme}
                  byPlayer={byPlayer}
                  isSoccer={isSoccer}
                  reportSettings={reportSettings}
                  eventSportId={eventSportId}
                  values={values}
                  mutateGoals={mutateGoals}
                  index={index}
                  field={field}
                  fields={fields}
                  update={update}
                />
              )
          )
        }
      </FieldArray>
    </Row>
    <div className="addWrapper">
      <AddButton>
        <StyledIcon
          width={22}
          height={22}
          icon={ICONS.NEW}
          color={COLORS.denimBlue}
          onClick={() => {
            push(roster, {
              ...getTeamRosterTemplate(byPlayer, isSoccer),
              index: (values[roster] && values[roster].length) || 0,
              uid: generateUUID(),
              goals: []
            });
          }}
          clickable
        />
        <ButtonLabel color={COLORS.denimBlue}>Add Roster member</ButtonLabel>
      </AddButton>
    </div>
  </>
);

TeamRoster.propTypes = {
  roster: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  reportSettings: PropTypes.object.isRequired,
  eventSportId: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  mutateGoals: PropTypes.func.isRequired
};

export default TeamRoster;
