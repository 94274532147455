import { normalizePrice } from '../../../../utils/parsers';
import { precisionRound } from '../../../../utils/helpers';

export const formatAssignorMessage = ({ fee_type, bill_to, fee_amount }) => {
  if (fee_type === 'No Assignor Pay') {
    return fee_type;
  }
  if (fee_type === 'Flat Rate') {
    return `${normalizePrice(fee_amount.toString(), 20, true, true)}${
      bill_to === 'Crew' ? ' of Crew Pay' : ''
    }`;
  }
  return `${fee_amount}%${bill_to === 'Crew' ? ' of Crew Pay' : ''}`;
};

export const formatPrices = pay_table => {
  const new_pay_table = { ...pay_table };
  const { assignor_pay, travel_pay, crew_pay } = pay_table;

  if (assignor_pay.fee_amount && assignor_pay.fee_type === 'Flat Rate') {
    new_pay_table.assignor_pay.fee_amount = normalizePrice(assignor_pay.fee_amount.toString());
  }

  ['rate_mileage', 'rate_min', 'rate_max'].forEach(field => {
    if (travel_pay[field]) {
      new_pay_table.travel_pay[field] = normalizePrice(pay_table.travel_pay[field].toString());
    }
  });

  crew_pay.forEach((cp, i) => {
    new_pay_table.crew_pay[i].base_pay = normalizePrice(cp.base_pay.toString());
  });

  return new_pay_table;
};

export const forFeeType = ({
  value,
  values,
  category = 'assignor_pay',
  fee_types = ['Flat Rate']
}) =>
  values[category].fee_type &&
  fee_types.indexOf(values[category].fee_type) !== -1 &&
  !value &&
  'Required for this fee type';

export const maxMinValidator = ({
  value,
  values,
  category = 'travel_pay',
  fee_types = ['Flat Rate']
}) => {
  const isEmpty =
    values[category].fee_type && fee_types.indexOf(values[category].fee_type) !== -1 && !value;

  let minLessThanMax = true;
  if (values.travel_pay.rate_min && values.travel_pay.rate_max) {
    minLessThanMax =
      Number(values.travel_pay.rate_min.replace('$', '')) <=
      Number(values.travel_pay.rate_max.replace('$', ''));
  }

  return isEmpty
    ? 'Required for this fee type'
    : !minLessThanMax && 'Min. rate cannot be greater than max. rate.';
};

export const copyFeesFromAssignor = ({ gl, change }) => {
  change('assignor_pay.fee_type', gl.assignor_pay.fee_type);
  change('assignor_pay.percentage_source', gl.assignor_pay.percentage_source);
  change('assignor_pay.bill_to', gl.assignor_pay.bill_to);
  change(
    'assignor_pay.fee_amount',
    gl.assignor_pay.fee_type === 'Flat Rate'
      ? normalizePrice(gl.assignor_pay.fee_amount.toString(), 4, false, true)
      : gl.assignor_pay.fee_amount
  );
};

export const assignorChange = ({ value, prev, active, change }) => {
  if (active === 'assignor_pay.fee_type' && value && value !== prev) {
    change('assignor_pay.percentage_source', '');
    change('assignor_pay.bill_to', '');
    change('assignor_pay.fee_amount', '');
  }
};

export const copyFeesFromTravel = ({ gl, change }) => {
  change('travel_pay.fee_type', gl.travel_pay.fee_type);
  change(
    'travel_pay.rate_mileage',
    gl.travel_pay.fee_type === 'Flat Rate'
      ? normalizePrice(gl.travel_pay.rate_mileage.toString(), 4, false, true)
      : gl.travel_pay.rate_mileage
  );
  change(
    'travel_pay.rate_min',
    gl.travel_pay.fee_type === 'Flat Rate'
      ? normalizePrice(gl.travel_pay.rate_min.toString(), 4, false, true)
      : gl.travel_pay.rate_min
  );
  change(
    'travel_pay.rate_max',
    gl.travel_pay.fee_type === 'Flat Rate'
      ? normalizePrice(gl.travel_pay.rate_max.toString(), 4, false, true)
      : gl.travel_pay.rate_max
  );
};

export const travelChange = ({ value, prev, active, change }) => {
  if (active === 'travel_pay.fee_type' && value && value !== prev) {
    change('travel_pay.rate_mileage', '');
    change('travel_pay.rate_min', '');
    change('travel_pay.rate_max', '');
  }
};

export const copyFeesFromCrew = ({ gl, change }) => {
  gl.crew_pay.forEach((_, i) => {
    if (gl.crew_pay[i]) {
      change(
        `crew_pay[${i}].base_pay`,
        normalizePrice(gl.crew_pay[i].base_pay.toString(), 4, false, true)
      );
    }
  });
};

export const getClassName = ({
  values,
  className = '',
  fee_types = ['Flat Rate'],
  category = 'assignor_pay'
}) =>
  fee_types.indexOf(values[category].fee_type) !== -1 ? `${className}` : `${className} hidden`;

export const calculateTotal = (values, adjustment = 0) =>
  precisionRound(
    values.reduce((total, val) => (val ? total + parseFloat(val.slice(1)) : total), 0) + adjustment,
    2
  );

export const percentValidator = value => String(value).includes('-') && 'Invalid percentage value';

export const requiredFeeValidator = (value, allValues, feeTypes, category) =>
  forFeeType({
    value,
    values: allValues,
    fee_types: feeTypes || ['Flat Rate', 'Percentage of Game Fees'],
    category
  });

/**
 * validator for percentage value between
 * 0 and 100 and no decimal
 */
export const valueValidator = value =>
  (value > 100 || String(value).includes('.')) &&
  'Percentage should be a whole number, between 0 and 100';

/**
 * takes a list of validators and
 * returns a composed validator
 */
export const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce(
    (error, validator) => error || validator(value, allValues && allValues),
    undefined
  );
