// Actions
export const FETCH_EVENT_LOCATIONS = 'EVENTS_OFFICIALS/FETCH_EVENT_LOCATIONS';
export const FETCH_EVENT_COMPLEXS = 'EVENTS_OFFICIALS/FETCH_EVENT_COMPLEXS';
export const SET_EVENT_LOCATIONS = 'EVENTS_USERS/SET_EVENT_LOCATIONS';
export const SET_EVENT_COMPLEXS = 'EVENTS_USERS/SET_EVENT_COMPLEXS';
export const SET_IS_FETCHING = 'EVENT_LOCATION/SET_IS_FETCHING';
export const FETCH_GAME_LOCATION = 'GAME_LOCATION/FETCH_GAME_LOCATION';
export const SET_GAME_LOCATION = 'GAME_LOCATION/SET_GAME_LOCATION';
export const SET_CURRENT_IS_FETCHING = 'GAME_LOCATION/SET_CURRENT_IS_FETCHING';
export const SET_TOOLTIP_TARGET = 'GAME_LOCATION/SET_TOOLTIP_TARGET';

// Reducer
const initialState = {
  list: [],
  meta: { total_count: 0, limit: 50000 },
  isFetching: false,
  data: {
    eventId: null,
    unfilteredList: []
  },
  current: {
    name: null,
    address: null,
    city: null,
    state: null,
    postal_code: null,
    surface_name: null,
    isFetching: false,
    target: ''
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_EVENT_LOCATIONS: {
      const isOtherEvent = !!(state.data.eventId !== action.payload.eventId);
      return {
        ...state,
        meta: action.payload.meta,
        list: action.payload.data,
        data: {
          ...state.data,
          eventId: action.payload.eventId,
          unfilteredList: isOtherEvent ? action.payload.data : state.data.unfilteredList
        }
      };
    }
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_EVENT_COMPLEXS:
      return {
        ...state,
        meta: action.payload.meta,
        list: action.payload.data
      };
    case SET_GAME_LOCATION:
      return {
        ...state,
        current: { ...state.current, ...Object.assign({}, ...action.payload) }
      };
    case SET_CURRENT_IS_FETCHING:
      return {
        ...state,
        current: {
          ...state.current,
          isFetching: action.payload
        }
      };
    case SET_TOOLTIP_TARGET:
      return {
        ...state,
        current: {
          ...state.current,
          target: action.payload
        }
      };
    default:
      return state;
  }
}

// Action Creators
export const fetchEventLocations = payload => ({
  type: FETCH_EVENT_LOCATIONS,
  payload
});

export const setEventLocations = payload => ({
  type: SET_EVENT_LOCATIONS,
  payload
});

export const setIsFetching = payload => ({
  type: SET_IS_FETCHING,
  payload
});

export const fetchEventComplexs = payload => ({
  type: FETCH_EVENT_COMPLEXS,
  payload
});

export const setEventComplexs = payload => ({
  type: SET_EVENT_COMPLEXS,
  payload
});

export const fetchGameLocation = payload => ({
  type: FETCH_GAME_LOCATION,
  payload
});

export const setGameLocation = payload => ({
  type: SET_GAME_LOCATION,
  payload
});

export const setCurrentIsFetching = payload => ({
  type: SET_CURRENT_IS_FETCHING,
  payload
});

export const setTooltipTarget = payload => ({
  type: SET_TOOLTIP_TARGET,
  payload
});
