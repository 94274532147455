import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { Field } from 'react-final-form';
import CollapseableHeader from '../../../../../CollapseableHeader';
import CheckField from '../../../../../CheckField';

import '../styles.css';
import LoaderWrapper from '../../../../../LoaderWrapper';
import TeamRoster from '../TeamRoster';
import TeamStaff from '../TeamStaff';

const Team = ({
  type,
  push,
  update,
  disabled,
  byPlayer,
  isSoccer,
  theme,
  game,
  reportSettings,
  eventSportId,
  values,
  mutateGoals
}) => (
  <>
    {reportSettings && (reportSettings.team_staff_section || reportSettings.roster_section) && (
      <CollapseableHeader
        label={`${type === 'home' ? game.team_home.name : game.team_visitor.name}`}
      >
        <>
          {reportSettings.mark_forfeit && (
            <LoaderWrapper isFetching={false} simple>
              <Row style={{ marginBottom: '0.75rem' }}>
                <Field
                  name={`${type === 'home' ? 'home_forfeit' : 'away_forfeit'}`}
                  component={CheckField}
                  placeholder="Team Forfeits"
                  width={12}
                  padding="0 0 0 1rem"
                  label="Team Forfeits"
                  disabled={
                    (`${type}` === 'home' ? values.away_forfeit : values.home_forfeit) || disabled
                  }
                  isLabelBlue
                  message={`defaults forfeiting team score to ${reportSettings.default_score_forfeit} and non-forfeit team score to ${reportSettings.default_score_non_forfeit}`}
                />
              </Row>
            </LoaderWrapper>
          )}
          {reportSettings.team_staff_section && (
            <TeamStaff
              team={`${type}_team`}
              reportSettings={reportSettings}
              theme={theme}
              push={push}
              byPlayer={byPlayer}
              isSoccer={isSoccer}
              disabled={disabled}
              values={values}
              eventSportId={eventSportId}
            />
          )}
          {reportSettings.roster_section && (
            <TeamRoster
              roster={`${type}_roster`}
              reportSettings={reportSettings}
              theme={theme}
              byPlayer={byPlayer}
              isSoccer={isSoccer}
              push={push}
              update={update}
              disabled={disabled}
              values={values}
              eventSportId={eventSportId}
              mutateGoals={mutateGoals}
            />
          )}
        </>
      </CollapseableHeader>
    )}
  </>
);

Team.propTypes = {
  type: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  game: PropTypes.object.isRequired,
  reportSettings: PropTypes.object.isRequired,
  eventSportId: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  mutateGoals: PropTypes.func.isRequired
};

export default Team;
