import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  display: inline-block;
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoCondensed';
  color: ${({ checked }) => (checked ? COLORS.denimBlue : COLORS.shuttleGray)};
  margin-top: ${pxToRem('3px')};
  cursor: pointer;
  user-select: none;
  ${({ hasError }) =>
    `color: ${hasError ? COLORS.carminePink : COLORS.shuttleGrey};
    margin-left: 10px;
    font-size: ${hasError ? '0.7rem' : `${pxToRem('13px')}`};
    cursor: ${hasError ? 'default' : 'pointer'}`};
`;

export const FieldLabel = styled.span`
  display: inline-block;
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoCondensed';
  color: ${COLORS.shuttleGray};
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 1rem;
  cursor: pointer;
`;
