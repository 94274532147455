import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../Modal';
import Enhancer from '../../../../containers/GameReportsModal';
import Button from '../../../Button';
import Icon from '../../../Icon';
import Flex from '../../../Flex';
import GameReport from './GameReport';
import { COLORS, ICONS } from '../../../../../utils/styles';
import { Summary, Count, ClickableLink } from '../styled-components';
import { pxToRem } from '../../../../../utils/helpers';
import { FROM_REPORTS, FROM_GAMES } from '../../../../../core/Modals/constants';
import { fetchIsLocked } from './GameReport/utils';

const GameReportModal = ({
  onClose,
  onSubmit,
  onEdit,
  onDelete,
  game,
  report,
  reports,
  creatingReport,
  setCreatingReport,
  presentcheck,
  setPresentcheck,
  coachDismissed,
  setCoachDismissed,
  inEdit,
  setInEdit,
  reportId,
  deleteReportId,
  setDeleteReportId,
  isFetching,
  onBack,
  isGamesEventAdmin,
  isGamesEventAssignor,
  isReportsEventAdmin,
  isGamesEventAssessor,
  isGamesEventOfficial,
  reportSettings,
  currentUserId,
  eventSportId,
  createTeamStaff,
  from,
  gamesEventId,
  isDisabled,
  setIsdisabled,
  onApprove,
  gameReport,
  fetchReport,
  isReportsEventGroupAdmin,
  setMutator,
  mutator,
  totalGoal,
  setTotalGoal
}) => {
  const viewableReports = reports.filter(
    vreport =>
      vreport.approved ||
      isReportsEventGroupAdmin ||
      isGamesEventAdmin ||
      isGamesEventAssignor ||
      (vreport.submitted_by && Number(currentUserId) === vreport.submitted_by.id)
  );
  const isLocked =
    reportSettings && fetchIsLocked(game.end_date, game.end_time, reportSettings.lock_after);
  const showNewIcon =
    isReportsEventGroupAdmin ||
    isGamesEventAdmin ||
    isGamesEventAssignor ||
    (isGamesEventOfficial && !isLocked);
  const showLockIcon = isGamesEventOfficial && isLocked;
  const showBack =
    from !== FROM_REPORTS && (reportId || creatingReport || deleteReportId) && onBack;
  return (
    <Modal
      title="Game Report"
      onClose={() => {
        onClose(from);
      }}
      onBack={showBack}
      size={{
        xs: 8,
        sm: 8,
        md: 8,
        lg: 7
      }}
    >
      <>
        {deleteReportId && (
          <Flex justifyContent="center" direction="column" padding="1rem" full>
            <Summary>Are you sure you want to delete this Game Report?</Summary>
            <Flex padding="1.25rem 0 0 ">
              <Button
                width="84px"
                height="32px"
                margin="0 8px 0"
                label="Cancel"
                onClick={() => setDeleteReportId(null)}
              />
              <Button
                type="submit"
                width="84px"
                height="32px"
                margin="0 8px 0"
                label="Yes"
                onClick={() => onDelete(deleteReportId)}
                primary
              />
            </Flex>
          </Flex>
        )}
        {!deleteReportId &&
          (creatingReport || reportId || isFetching || (from === FROM_REPORTS && report.id)) && (
            <GameReport
              onSubmit={onSubmit}
              onEdit={onEdit}
              game={game}
              report={
                from === FROM_GAMES
                  ? viewableReports.find(vreport => vreport.id === reportId)
                  : gameReport
              }
              gameReport={gameReport}
              onBack={onBack}
              onClose={onClose}
              createTeamStaff={createTeamStaff}
              onApprove={onApprove}
              currentUserId={Number(currentUserId)}
              isFetching={isFetching}
              isGamesEventAdmin={isGamesEventAdmin}
              isGamesEventAssignor={isGamesEventAssignor}
              isReportsEventAdmin={isReportsEventAdmin}
              isReportsEventGroupAdmin={isReportsEventGroupAdmin}
              from={from}
              setMutator={setMutator}
              mutator={mutator}
              inEdit={inEdit}
              reportSettings={reportSettings}
              presentcheck={presentcheck}
              setPresentcheck={setPresentcheck}
              coachDismissed={coachDismissed}
              setCoachDismissed={setCoachDismissed}
              setInEdit={setInEdit}
              eventSportId={eventSportId}
              gamesEventId={gamesEventId}
              isDisabled={isDisabled}
              setIsdisabled={setIsdisabled}
              totalGoal={totalGoal}
              setTotalGoal={setTotalGoal}
              isGamesEventAssessor={isGamesEventAssessor}
              isGamesEventOfficial={isGamesEventOfficial}
              isLocked={isLocked}
            />
          )}
        {!deleteReportId && !creatingReport && !reportId && from !== FROM_REPORTS && (
          <div>
            <Flex justifyContent="center" padding="1rem 0" highlighted>
              <Flex justifyContent="space-between" maxWidth="380px" full>
                <Summary noMargin>
                  <Count color={COLORS.denimBlue}>{viewableReports.length}</Count>
                  Game Reports were submitted for this game
                </Summary>
                {showNewIcon && (
                  <Icon
                    width={22}
                    height={22}
                    icon={ICONS.NEW}
                    color={COLORS.denimBlue}
                    onClick={() => {
                      setCreatingReport(true);
                      setInEdit(false);
                      setIsdisabled(false);
                    }}
                    clickable
                  />
                )}
                {showLockIcon && (
                  <Icon width={22} height={22} icon={ICONS.LOCK} color={COLORS.hawkesBlue} />
                )}
              </Flex>
            </Flex>
            {!!viewableReports.length && (
              <Flex padding="1rem" direction="column" alignItems="center" full>
                {viewableReports.map(vreport => (
                  <Flex
                    justifyContent="space-between"
                    padding={`0.5rem ${pxToRem('10px')} 0.5rem 0`}
                    maxWidth="380px"
                    fullWidth
                    key={vreport.id}
                  >
                    <ClickableLink
                      onClick={() => {
                        fetchReport(vreport.id);
                        setInEdit(false);
                      }}
                    >
                      {vreport.position} - {vreport.submitted_by && vreport.submitted_by.first_name}{' '}
                      {vreport.submitted_by && vreport.submitted_by.last_name}
                    </ClickableLink>
                    <Flex>
                      {vreport.approved && (
                        <Icon
                          width={20}
                          height={20}
                          icon={ICONS.CHECK_CIRCLE_HOLLOW}
                          color={COLORS.forestGreen}
                          noMargin
                        />
                      )}
                      {!vreport.approved && !showLockIcon && (
                        <Icon
                          width={20}
                          height={20}
                          icon={ICONS.TRASH}
                          color={COLORS.hawkesBlue}
                          onClick={() => setDeleteReportId(vreport.id)}
                          clickable
                          noMargin
                        />
                      )}
                      {!vreport.approved && showLockIcon && (
                        <Icon
                          width={20}
                          height={20}
                          icon={ICONS.LOCK}
                          color={COLORS.hawkesBlue}
                          noMargin
                        />
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            )}
          </div>
        )}
      </>
    </Modal>
  );
};

GameReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  from: PropTypes.string,
  reports: PropTypes.arrayOf(Object)
};

GameReportModal.defaultProps = {
  reports: [],
  from: FROM_GAMES
};

export default Enhancer(GameReportModal);
