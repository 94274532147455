import React from 'react';

import { formatUssfId } from '../../../../utils/helpers';
import COLORS from '../../../../utils/styles';
import { composeValidators, required, validateUssfId } from '../../../../utils/validations';
import { CustomButton } from './index';

const isRequired = () => true;

const NONE = 'none';
const BUTTON_TYPES = {
  SUBMIT: 'submit',
  DEFAULT: 'button',
  RESET: 'reset'
};
const BUTTON_LABELS = {
  CLEAR: 'Clear',
  VERIFY: 'Verify'
};

export default {
  input: [
    {
      field: {
        name: 'ussf_first_name',
        label: 'U.S. Soccer Federation First Name',
        placeholder: 'First Name',
        validate: required,
        isRequired
      },
      NextComponent: null
    },
    {
      field: {
        name: 'ussf_last_name',
        label: 'U.S. Soccer Federation Last Name',
        placeholder: 'Last Name',
        validate: required,
        isRequired
      },
      NextComponent: null
    },
    {
      field: {
        name: 'ussf_id',
        label: 'U.S. Soccer Federation ID',
        placeholder: 'XXXX-XXXX-XXXX-XXXX',
        validate: composeValidators(required, validateUssfId),
        parse: formatUssfId,
        maxLength: '19',
        isRequired
      },
      NextComponent: ({ ussfId, isValid, isSuperAdmin, onClear, ...props }) =>
        ussfId ? (
          <CustomButton
            onClick={onClear}
            label={BUTTON_LABELS.CLEAR}
            type={BUTTON_TYPES.DEFAULT}
            display={!isValid && NONE}
            {...props}
          />
        ) : (
          <CustomButton
            label={BUTTON_LABELS.VERIFY}
            type={BUTTON_TYPES.SUBMIT}
            display={isValid && NONE}
            {...props}
          />
        )
    },
    {
      field: {
        name: 'ussf_dob',
        label: 'U.S. Soccer Federation Date of Birth',
        placeholder: 'YYYY-MM-DD',
        readOnly: true,
        theme: { input: { color: COLORS.slateGray } },
        isRequired
      },
      NextComponent: null
    }
  ],

  license: [
    {
      label: 'Provider',
      name: 'provider'
    },
    {
      label: 'Level',
      name: 'level'
    },
    {
      label: 'Valid Through',
      name: 'valid_through'
    },
    {
      label: 'Issuer',
      name: 'issuer'
    },
    {
      label: 'SafeSport Valid Through',
      name: 'safe_sport_valid_through'
    },
    {
      label: 'NCSI Background Valid Through',
      name: 'background_valid_through'
    }
  ]
};
