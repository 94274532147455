// Actions
export const FETCH_SPORTS = 'SPORTS/FETCH_SPORTS';
export const SET_SPORTS = 'SPORTS/SET_SPORTS';

// Reducer
const initialState = {
  list: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SPORTS:
      return {
        list: action.payload
      };

    default:
      return state;
  }
}

// Action Creators
export const setSports = payload => ({
  payload,
  type: SET_SPORTS
});

export const fetchSports = () => ({
  type: FETCH_SPORTS
});
