export default () => ({
  sort: {
    options: [
      {
        name: 'name',
        label: 'Name'
      }
    ]
  },
  filters: [{}],
  actions: {
    options: [{}]
  }
});
