export const isGameChecked = (prevProps, nextProps) => {
  if (prevProps.checked === nextProps.checked) {
    return true;
  }
  return false;
};

export const isTeamChanged = (prevProps, nextProps) => {
  if (
    JSON.stringify(prevProps.team_home) === JSON.stringify(nextProps.team_home) &&
    JSON.stringify(prevProps.team_visitor) === JSON.stringify(nextProps.team_visitor)
  ) {
    return true;
  }
  return false;
};

export const isGameDetailChanged = (prevProps, nextProps) => {
  if (
    JSON.stringify(prevProps.field) === JSON.stringify(nextProps.field) &&
    JSON.stringify(prevProps.field_name) === JSON.stringify(nextProps.field_name) &&
    JSON.stringify(prevProps.location) === JSON.stringify(nextProps.location)
  ) {
    return true;
  }
  return false;
};

export const isGameLevelTypeChanged = (prevProps, nextProps) => {
  if (
    JSON.stringify(prevProps.game_level) === JSON.stringify(nextProps.game_level) &&
    JSON.stringify(prevProps.division) === JSON.stringify(nextProps.division)
  ) {
    return true;
  }
  return false;
};

export const arePropsChanged = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
    return true;
  }
  return false;
};
