import { compose, pure, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';

import moment from 'moment';
import { formatDate } from '../../../utils/parsers';
import {
  fetchFeeReport as fetchFeeReportAction,
  fetchPlatformTransactionDetails as fetchPlatformTransDetailsAction
} from '../../../core/Payments';

import config from './config';
import { DATE_FORMATS } from '../../../constants';
import { fetchGroupsReport as fetchGroupsReportAction } from '../../../core/Auth';

const mapDispatchToProps = dispatch => ({
  fetchFeeReport: (invoiced, payload) => dispatch(fetchFeeReportAction(invoiced, payload)),
  fetchPlatformTransactionDetails: payload => dispatch(fetchPlatformTransDetailsAction(payload)),
  fetchGroupsReport: payload => dispatch(fetchGroupsReportAction(payload))
});

export default compose(
  connect(() => {}, mapDispatchToProps),
  withState('submitting', 'isSubmitting', false),
  withState('reportType', 'setReportType', null),
  withState('showDateField', 'setShowDateField', false),
  withHandlers({
    generateReport: ({
      fetchFeeReport,
      isSubmitting,
      reportType,
      fetchPlatformTransactionDetails,
      fetchGroupsReport
    }) => values =>
      new Promise((resolve, reject) => {
        isSubmitting(true);
        if (Number(reportType) === config.reports.platformTransactionDetails.code) {
          const startDate = moment
            .utc(values.startDate)
            .startOf('day')
            .format(DATE_FORMATS.FULL_DATE_TIME);
          const endDate = moment
            .utc(values.endDate)
            .endOf('day')
            .format(DATE_FORMATS.FULL_DATE_TIME);
          return fetchPlatformTransactionDetails({ startDate, endDate, resolve, reject });
        } else if (Number(reportType) === config.reports.groupsReport.code) {
          return fetchGroupsReport({ resolve, reject });
        }
        return fetchFeeReport(Number(reportType) === config.reports.invoiced.code, {
          resolve,
          reject
        });
      })
        .then(({ data }) => {
          const csvConfig = Object.values(config.reports).find(
            item => item.code === Number(reportType)
          ).csv;
          isSubmitting(false);
          const blob = new Blob([data], { type: 'text/csv' });
          FileSaver.saveAs(
            blob,
            `${csvConfig.prefix}_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`
          );
        })
        .catch(() => {
          isSubmitting(false);
        })
  }),
  pure
);
