import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import './global-styles/rc-time-picker.css';
import Root from './views/components/Root';
import Routes from './core/routes';
import { store, history } from './core/store';
import { ServiceWorker, swConfig } from './utils/serviceWorker';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_SITE_ID, {
  debug: process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG
});

hotjar.initialize(process.env.REACT_APP_HOTJAR_HJID, process.env.REACT_APP_HOTJAR_HJSV);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#167cd4'
    },
    secondary: {
      main: '#f5a623'
    },
    error: {
      main: '#c61e1e'
    }
  }
});

const render = (reduxStore, browserHistory, routes) => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Root store={reduxStore} history={browserHistory} routes={routes} />
    </ThemeProvider>,
    document.getElementById('root')
  );
};

render(store, history, Routes);

ServiceWorker.register(swConfig);
