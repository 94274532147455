import React from 'react';
import { IconButton } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CarretButton from '../../../../CarretButton';
import ExpandInfoPanel from '../ExpandInfoPanel';
import { RowsWrapper, SpanBlue, SpanGrey } from '../styled-components';
import LoaderWrapper from '../../../../LoaderWrapper';
import ConfirmationPopup from '../../../../popupModals/ComfirmationPopup';

export default function BankInfoRow({
  Info,
  sourceName,
  isFetching,
  deletePaymentSource,
  userId,
  writeAccess
}) {
  const [showInfo, setShowInfo] = React.useState(false);
  const [deleteConfimation, setDeleteConfimation] = React.useState(false);

  return (
    <LoaderWrapper isFetching={isFetching}>
      <div>
        {[Info].map(account => (
          <>
            <RowsWrapper>
              <Col xs={1}>
                {' '}
                <CarretButton
                  opened={showInfo}
                  onClick={() => setShowInfo(!showInfo)}
                  nobg={showInfo}
                />
              </Col>
              <Col xs={10}>
                <Row middle="xs">
                  <Col xs={1}>
                    {' '}
                    {account.verified ? (
                      <SpanBlue>
                        <AccountBalanceIcon />
                      </SpanBlue>
                    ) : (
                      <SpanGrey>
                        <AccountBalanceIcon />
                      </SpanGrey>
                    )}{' '}
                  </Col>
                  <Col xs={9} style={{ padding: '0px 10px' }}>
                    <Row>
                      <Col xs={12}>
                        Payment Method:{' '}
                        {account.verified ? (
                          <SpanBlue>Bank Account</SpanBlue>
                        ) : (
                          <SpanGrey>Bank Account</SpanGrey>
                        )}
                      </Col>

                      <Col xs={12}>
                        Bank Name:{' '}
                        {account.verified ? (
                          <SpanBlue>{account.details.bank}</SpanBlue>
                        ) : (
                          <SpanGrey>{account.details.bank}</SpanGrey>
                        )}
                      </Col>
                      <Col xs={12}>
                        Account Nickname :{' '}
                        {account.verified ? (
                          <SpanBlue>
                            {account.details.nick_name && account.details.nick_name}
                          </SpanBlue>
                        ) : (
                          <SpanGrey>
                            {account.details.nick_name && account.details.nick_name}
                          </SpanGrey>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {writeAccess && (
                <Col xs={1}>
                  <Row middle="xs" around="xs">
                    <Col>
                      <IconButton>
                        <DeleteIcon
                          onClick={() => {
                            setDeleteConfimation(true);
                          }}
                        />
                      </IconButton>
                    </Col>
                  </Row>
                </Col>
              )}
            </RowsWrapper>
            {showInfo && (
              <ExpandInfoPanel
                expanded={showInfo}
                onExpand={showInfo}
                ViewName={sourceName}
                bankName={account.details.bank}
                nickName={account.details.nick_name}
              />
            )}

            {deleteConfimation && (
              <>
                <ConfirmationPopup
                  title="Delete Payment Method"
                  description="Are you sure you want to delete this Payment Method?"
                  SubmitBtnText="YES"
                  confirmationPopupAction={deleteConfimation}
                  setConfirmationPopupAction={setDeleteConfimation}
                  primaryActionFun={deletePaymentSource}
                  sourceId={Info.id}
                  userId={userId}
                  type="bank"
                />
              </>
            )}

            <br />
          </>
        ))}
      </div>
    </LoaderWrapper>
  );
}
