import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Icon from '../../Icon';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;
export const NavItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 ${pxToRem('8px')};
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  cursor: pointer;

  ${({ divider }) => (divider ? `border-right: 1px solid ${COLORS.white}` : '')};
  ${({ theme }) => theme};
`;

export const StaticValue = styled.span`
  font-family: 'RobotoCondensed';
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize || '0.9rem'};
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: ${({ lineHeight }) => lineHeight || '2.31'};
  letter-spacing: normal;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  color: ${COLORS.nightRider};
`;
export const StatusIconGame = styled(Icon)`
  position: relative;
  display: inline-block;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
  width: 20%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SquareInput = styled.input`
  width: 75%;
  height: 2rem;
  padding-left: 0.8rem;
`;

export const OutsideContainer = styled.div`
  margin-top: 1.43rem;
`;

export const TableContainer = styled.div`
  width: 95%;
  margin: auto;
  background-color: ${COLORS.blackSqueeze};
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  line-height: 2.3rem;
  margin-top: ${pxToRem('4px')};
  align-items: center;
`;

export const TableRow = styled(Row)`
  background-color: ${COLORS.white};
  margin: 0.9rem 0 0.9rem;
  min-height: 3.5rem;
  align-self: center;
  padding-top: ${pxToRem('9px')};
`;

export const TableRowGame = styled(Row)`
  background-color: ${COLORS.white};
  min-height: 3.5rem;
  align-self: center;
  padding-top: ${pxToRem('9px')};
  border: 1px solid #d8d8d8;
  margin: 0 !important;
  box-shadow: 0 7px 6px 0 rgba(0, 0, 0, 0.06);
`;

export const HeaderRowGame = styled(Row)`
  background-color: ${COLORS.prussianBlue};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0 !important;
`;
