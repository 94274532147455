import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export const FlexContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  ${({ full }) => (full ? 'flex: 1' : '')};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ direction }) => direction};
  ${({ bgColor }) => `background-color: ${bgColor}` || ''};
  ${({ bgImage }) => `background-image: ${bgImage}` || ''};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}` : '')};
  ${({ fullWidth, peopleTab }) => (fullWidth ? 'width: 100%' : peopleTab ? 'width: 130%' : '')};
  ${({ height }) => (height ? `height: ${height}` : '')};
  ${({ highlighted }) =>
    highlighted
      ? `background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white})`
      : ''};
  ${({ noShrink }) => (noShrink ? 'flex-shrink: 0' : '')};
  ${({ theme }) => theme || ''};
`;

export default FlexContainer;
