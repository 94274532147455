import styled from 'styled-components';
import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

export const AssignorWrapper = styled.div`
  width: 25rem;
  height: 100%;
  box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
  min-height: 3.5rem;
  background-color: ${COLORS.white};
  border-bottom: 1px solid ${COLORS.altoGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px ${pxToRem('15px')};
`;

export const AssignorList = styled.div`
  background: ${COLORS.pale_grey_two};
  height: calc(100% - ${pxToRem('158px')});
  overflow-y: scroll;
  width: 100%;
`;

export const StyledAssignor = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.6fr 0.2fr 1fr;
  align-items: center;
  color: ${({ previous }) => (previous ? COLORS.white : 'default')};
  background: ${({ header }) => (header ? COLORS.white : 'transparent')};
  background: ${({ previous, header }) =>
    header
      ? COLORS.white
      : (previous && `linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue})`) ||
        'transparent'};
  padding: ${pxToRem('10px')} ${pxToRem('15px')};
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.altoGray};
  ${({ header }) => !header && `cursor: pointer`};
  ${({ header }) =>
    !header &&
    `&:hover {
    background: ${COLORS.lightCyanGray};
  }`};
  margin: 0;
  font-family: 'RobotoCondensed';
  font-size: 12px;
  & span {
    padding-left: 7px;
  }
`;

export const AssignorText = styled.p`
  margin: 0;
  font-family: 'RobotoCondensed';
  font-size: 12px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NoAssignor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d8dee3;
  padding: ${pxToRem('10px')} ${pxToRem('15px')};
  cursor: pointer;
`;
export const NonPaymentIcon = styled.span`
  padding: 1px 1px 0px 1px;
  position: relative;
  font-size: 15px;
  color: #ef0101;
  cursor: pointer;
  .MuiSvgIcon-root {
    width: 0.75em;
    height: 0.75em;
  }
  &::after {
    position: absolute;
    content: '/';
    width: 0.75em;
    height: 0.75em;
    font-size: 16px;
    top: 9px;
    right: 0px;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(110deg);
    color: #ef0101;
  }
`;
export const PaymentMouseOverPopoverWrapper = styled.span`
  font-size: 0.6em;
`;
