import React from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';
import { Row, Col } from 'react-flexbox-grid';
import { Field } from 'react-final-form';

import Tags from '../../Tags';
import { Wrapper, Label, StyledInput, StyledIcon, Error } from './styled-components';
import { COLORS, ICONS } from '../../../../utils/styles';
import Enhancer from '../../../containers/Reusable/MinMax';

const SingleInput = ({
  input: { value, onChange },
  meta: { error },
  placeholder,
  theme,
  current,
  setCurrent,
  withTags,
  maxLength,
  minLength
}) => {
  const addable = !!current && current !== value;
  return (
    <>
      <Wrapper
        className={`${value ? 'hasValue' : ''} ${error ? 'hasError' : ''}`}
        theme={theme.wrapper}
      >
        <StyledInput
          type="text"
          maxLength={maxLength}
          minLength={minLength}
          value={current}
          onChange={e =>
            (!e.target.value || e.target.value.match(/^[1-9]\d*$/)) && setCurrent(e.target.value)
          }
          placeholder={placeholder}
          hasValue={current}
          autoComplete="off"
          addable={addable}
          theme={theme.input}
        />
        {addable && (
          <StyledIcon
            width={16}
            height={16}
            icon={ICONS.NEW}
            color={theme.plusIconColor || COLORS.white}
            onClick={() => {
              onChange(current);
              setCurrent('');
            }}
            noMargin
          />
        )}
      </Wrapper>
      {value && withTags && (
        <Tags
          tags={[
            {
              name: value,
              onRemove: () => onChange(''),
              canEdit: true,
              color: COLORS.denimBlue,
              backgroundColor: theme.tagBackgroundColor
            }
          ]}
          justifyContent="center"
        />
      )}
    </>
  );
};

SingleInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired,
  theme: PropTypes.shape({
    wrapper: PropTypes.string
  }),
  current: PropTypes.string.isRequired,
  setCurrent: PropTypes.func.isRequired,
  withTags: PropTypes.bool,
  placeholder: PropTypes.string.isRequired
};

SingleInput.defaultProps = {
  withTags: false,
  theme: {}
};

const EnhancedSingleInput = withState('current', 'setCurrent', '')(SingleInput);

/**
 * Built to integrate with React-Final-Form, multi-input that stores each values as an array
 * @param {Object} input contains onChange and value attributes
 * @param {String} error error to display under input and underline input in red
 * @param {String} placeholder  Label to display on dropdown
 * @param {Object} theme *optional* for customizing styles
 * @param {Boolean} withTags *optional* adds removable tags underneath input, defaults to true
 */
const MinMax = ({
  placeholder: label,
  theme,
  withTags,
  name,
  error,
  setError,
  color,
  maxLength,
  minLength
}) => (
  <div style={{ position: 'relative' }}>
    {error && <Error>{error}</Error>}
    <Row between="xs">
      <Col xs={12} md={4}>
        <Label color={color}>{label}</Label>
      </Col>
      <Col xs={6} md={4}>
        <Field
          component={EnhancedSingleInput}
          placeholder="Min"
          name={name[0]}
          theme={theme}
          withTags={withTags}
          maxLength={maxLength}
          minLength={minLength}
          validate={value => {
            if (minLength && value && minLength !== value.length) {
              setError(`Value must be of ${minLength} digits`);
            }
            return error;
          }}
        />
      </Col>
      <Col xs={6} md={4}>
        <Field
          component={EnhancedSingleInput}
          placeholder="Max"
          name={name[1]}
          theme={theme}
          withTags={withTags}
          maxLength={maxLength}
          minLength={minLength}
          validate={(value, allValues) => {
            if (minLength && value && minLength !== value.length) {
              setError(`Value must be of ${minLength} digits`);
            }
            if (!value || !allValues[name[0]] || Number(value) >= Number(allValues[name[0]])) {
              if (error) setError(null);
              return undefined;
            }

            if (!error) setError('Max cannot be less than Min');
            return error;
          }}
        />
      </Col>
    </Row>
  </div>
);

MinMax.propTypes = {
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  withTags: PropTypes.bool,
  theme: PropTypes.shape({
    wrapper: PropTypes.string
  }),
  name: PropTypes.arrayOf(PropTypes.string).isRequired
};

MinMax.defaultProps = {
  withTags: true,
  placeholder: '',
  theme: {},
  error: null
};

export default Enhancer(MinMax);
