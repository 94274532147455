import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import Line from '../../components/Line';
import { Title, ColWrapper } from './styled-components';
import Paper from '../Paper';

const Box = ({ children, title }) => (
  <Paper>
    <Row>
      <Col xs={12}>
        <Title>{title}</Title>
      </Col>
    </Row>
    <Line />
    <ColWrapper>
      <Col xs={12}>{children}</Col>
    </ColWrapper>
  </Paper>
);

Box.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired
};

export default Box;
