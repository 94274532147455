import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Modal from '../../Modal';
import MaterialInput from '../../MaterialInput';
import TextArea from '../../TextArea';
import { ButtonAsLink } from '../../Button';
import FieldSpreadLayoutContainer from '../../../layouts/FieldSpreadLayoutContainer';
import { formatDate } from '../../../../utils/parsers';
import { userIdxGetter } from '../../../../utils/auth';
import { FormWrapper } from './styled-components';
import enhancer from '../../../containers/SportsDependent';
import { notNilNotEmpty, sInterpolator as routeBuilder } from '../../../../utils/helpers';
import { EVENT_INFORMATION } from '../../../../core/paths';

const printValue = value => (notNilNotEmpty(value) ? 'None' : value);
const printDate = date => (notNilNotEmpty(date) ? 'None' : formatDate(date));

const InputWrapper = ({ placeholder, value }) => (
  <MaterialInput input={{ value }} placeholder={placeholder} readOnly />
);

InputWrapper.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export const EventInfoModal = ({
  eventInfo: {
    name,
    season_start_date,
    season_end_date,
    area_state,
    area_country,
    description,
    governing_body,
    group_name,
    sport,
    id,
    contact_name,
    contact_phone,
    contact_email
  },
  isEventAdmin,
  onClose,
  isSuperAdmin,
  isGroupAdmin
}) => (
  <Modal title="Event Information" onClose={onClose}>
    <div>
      <FormWrapper>
        <FieldSpreadLayoutContainer
          columns={2}
          deviation={2}
          useAsKey="placeholder"
          fields={[
            <InputWrapper value={printValue(name)} placeholder="Event Name" />,
            <InputWrapper value={printDate(season_start_date)} placeholder="Start Date" />,
            <InputWrapper value={printValue(group_name)} placeholder="Group Name" />,
            <InputWrapper value={printDate(season_end_date)} placeholder="End Date" />,
            <InputWrapper value={printValue(governing_body)} placeholder="Governing Body" />,
            <InputWrapper value={printValue(contact_name)} placeholder="Contact Name" />,
            <InputWrapper value={printValue(area_state)} placeholder="State/Province" />,
            <InputWrapper value={printValue(contact_phone)} placeholder="Contact Phone" />,
            <InputWrapper value={printValue(area_country)} placeholder="Country" />,
            <InputWrapper value={printValue(contact_email)} placeholder="Contact Email" />,
            <InputWrapper value={printValue(sport.name)} placeholder="Sport" />,
            <TextArea
              input={{ value: printValue(description) }}
              placeholder="description"
              readOnly
            />
          ]}
        />
      </FormWrapper>
      {(isEventAdmin || isSuperAdmin || isGroupAdmin) && (
        <Row end="xs">
          <Col xs={2}>
            <ButtonAsLink
              label="Edit"
              primary
              to={routeBuilder({ userIdx: userIdxGetter(), eventId: id }, EVENT_INFORMATION)}
            />
          </Col>
          <Col xs={1} />
        </Row>
      )}
    </div>
  </Modal>
);

EventInfoModal.propTypes = {
  eventInfo: PropTypes.shape({
    name: PropTypes.string,
    season_start_date: PropTypes.string,
    season_end_date: PropTypes.string,
    area_city: PropTypes.string,
    area_state: PropTypes.string,
    area_country: PropTypes.string,
    description: PropTypes.string,
    governing_body: PropTypes.string,
    group_name: PropTypes.string,
    sport: {
      id: PropTypes.number,
      name: PropTypes.string
    },
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  isEventAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isGroupAdmin: PropTypes.bool
};

EventInfoModal.defaultProps = {
  isEventAdmin: false,
  isSuperAdmin: false,
  eventInfo: {
    name: '',
    season_start_date: '',
    season_end_date: '',
    area_city: '',
    area_state: '',
    area_country: '',
    description: '',
    governing_body: '',
    group_name: '',
    sport: {
      id: 0,
      name: ''
    },
    id: 0
  },
  isGroupAdmin: false
};

export default enhancer(EventInfoModal);
