import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import { withState } from 'recompose';
import {
  fetchEventUsers,
  fetchEventUserDetail,
  submitUserImport as submitUserImportAction,
  deleteEventUsers,
  sendBulkEmail,
  setSelectedUser as setSelectedUserAction,
  updateCategories,
  submitUserManually as submitUserManuallyAction,
  fetchNextSetOfEventUsers
} from '../../../../core/Users';
import { getUser } from '../../../../core/Auth/selectors';
import {
  getUsersByType,
  getEventAdmins,
  getMetaByType,
  getIsFetchingInit,
  hasNextPage,
  getIsFetchingEventRoles
} from '../../../../core/Users/selectors';
import { paginationAware, filterConfigToNames, updateOnPageChange } from '../../PaginationAware';
import buildConfig from '../../../components/UsersList/config';
import { fetchEvent } from '../../../../core/Events';
import unconnected from './unconnected';
import { getEventById } from '../../../../core/Events/selectors';

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch(fetchEventUsers(payload)),
  fetchEvent: payload => dispatch(fetchEvent(payload)),
  onFetchNextOfficials: payload => {
    return dispatch(fetchNextSetOfEventUsers(payload));
  },
  onFetchItemDetail: (userId, eventRoleId) =>
    dispatch(fetchEventUserDetail({ userId, eventRoleId, roleName: 'users' })),
  submitUserImport: payload =>
    dispatch(
      submitUserImportAction({
        ...payload,
        roles: { alias: 'user', list: ['Assignor', 'Admin', 'Funding Admin'] }
      })
    ),
  submitUserManually: payload => dispatch(submitUserManuallyAction(payload)),
  removeSelectedUsers: payload =>
    dispatch(
      deleteEventUsers({
        ...payload,
        roles: { alias: 'user', list: ['Assignor', 'Admin', 'Funding Admin'] }
      })
    ),
  sendEmail: payload => dispatch(sendBulkEmail(payload)),
  setSelectedUser: payload => dispatch(setSelectedUserAction(payload)),
  onUpdateCategories: payload => dispatch(updateCategories({ roleName: 'users', ...payload }))
});

const mapStateToProps = (state, props) => ({
  users: getUsersByType(state, 'user'),
  admins: getEventAdmins(state),
  user: getUser(state),
  metaData: getMetaByType('user')(state),
  isFetchingInit: getIsFetchingInit(state),
  onLoadPageArgs: {
    roles: { alias: 'user', list: ['Assignor', 'Admin', 'Funding Admin'] },
    eventId: props && props.match && props.match.params && props.match.params.eventId
  },
  eventInfo: getEventById(
    props && props.match && props.match.params && props.match.params.eventId,
    state
  ),
  hasNextPage: hasNextPage(state),
  isFetchingEventRoles: getIsFetchingEventRoles(state)
});

const PAGINATION_AWARE_PREFIX = 'usrs';

export default compose(
  withState('usersDemographicsList', 'setUsersDemographicsList', []),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware(
    {
      fetcherQuote: 'onLoad',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'metaData',
      searchFilter: ['search'],
      filterNames: filterConfigToNames(buildConfig({}).filters)
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  unconnected
);
