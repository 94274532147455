import { connect } from 'react-redux';
import { compose } from 'recompose';

import { createAccount, fetchGroupInfo } from '../../../../../core/Auth';
import { isGroupAdmin } from '../../../../../core/Auth/selectors';

import unconnected from './unconnected';

const mapStateToProps = state => ({
  isGroupAdmin: isGroupAdmin(state)
});

const mapDispatchToProps = dispatch => ({
  createAccount: payload => dispatch(createAccount(payload)),
  fetchGroupInfo: payload => dispatch(fetchGroupInfo(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
