import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  getEventBatches,
  getPaymentsEvent,
  getIsProcessingPayment,
  getFundSummaryDetails,
  getIsFetching,
  getBatchPaymentFee,
  getIsPaymentSupported,
  getBatchFailedHistory,
  getAgreement,
  getPaymentMethods,
  getEventBatchesMeta,
  getUserWallet
} from '../../../../core/Payments/selectors';
import {
  fetchFundingBatches as fetchFundingBatchesAction,
  addPaymentSource,
  processBatchFund,
  fetchPaymentToken,
  fetchFundSummaryDetails,
  fetchBatchFailedHistory,
  fetchBatchPaymentFee,
  removeBatchPaymentError,
  fetchAgreement,
  fetchPaymentMethods
} from '../../../../core/Payments';
import { getUser } from '../../../../core/Auth/selectors';
import { addNotifications as addNotificationsAction } from '../../../../core/Notifications';
import unconnected from './unconnected';
import { paginationAware, updateOnPageChange } from '../../PaginationAware';

const mapStateToProps = state => ({
  eventBatches: getEventBatches(state),
  paymentsEvent: getPaymentsEvent()(state),
  paymentMethods: getPaymentMethods(state),
  user: getUser(state),
  isProcessingPayment: getIsProcessingPayment(state),
  fundSummaryDetails: getFundSummaryDetails(state),
  batchFailedHistory: getBatchFailedHistory(state),
  isFetching: getIsFetching(state),
  batchPaymentFee: getBatchPaymentFee(state),
  isPaymentSupported: getIsPaymentSupported(state),
  agreement: getAgreement(state),
  metaData: getEventBatchesMeta(state),
  userWallet: getUserWallet(state)
});

const mapDispatchToProps = dispatch => ({
  fetchFundingBatches: payload => dispatch(fetchFundingBatchesAction(payload)),
  addPaymentSource: payload => dispatch(addPaymentSource(payload)),
  processBatchFund: payload => dispatch(processBatchFund(payload)),
  fetchPaymentToken: payload => dispatch(fetchPaymentToken(payload)),
  fetchFundSummaryDetails: payload => dispatch(fetchFundSummaryDetails(payload)),
  fetchBatchFailedHistory: payload => dispatch(fetchBatchFailedHistory(payload)),
  fetchAgreement: payload => dispatch(fetchAgreement(payload)),
  fetchBatchPaymentFee: payload => dispatch(fetchBatchPaymentFee(payload)),
  addNotifications: payload => dispatch(addNotificationsAction(payload)),
  removeBatchPaymentError: payload => dispatch(removeBatchPaymentError(payload)),
  fetchPaymentMethods: payload => dispatch(fetchPaymentMethods(payload))
});

export const PAGINATION_AWARE_PREFIX = 'pay';

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware(
    {
      fetcherQuote: 'fetchFundingBatches',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'metaData'
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  unconnected
);
