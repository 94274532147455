import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Col } from 'react-flexbox-grid';

import Modal from '../../../../Modal';
import MaterialInput from '../../../../MaterialInput';
import Button from '../../../../Button';
import { FieldWrapper, FormWrapper, ButtonsWrapper } from '../styled-components';
import { required } from '../../../../../../utils/validations';

const ChangePasswordModal = ({ onClose, onSubmit }) => (
  <Modal title="Change Password" onClose={onClose} size={{ lg: 5 }}>
    <Form
      onSubmit={onSubmit}
      validate={({ new_password, confirm_password }) =>
        new_password !== confirm_password && { confirm_password: 'Password do not match' }
      }
      initialValues={{ old_password: '', new_password: '', confirm_password: '' }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <FormWrapper start="xs">
            <FieldWrapper xs={12}>
              <Field
                name="old_password"
                component={MaterialInput}
                placeholder="Old Password"
                type="password"
                validate={required}
              />
            </FieldWrapper>
            <FieldWrapper xs={12}>
              <Field
                name="new_password"
                component={MaterialInput}
                placeholder="New Password"
                type="password"
                validate={required}
              />
            </FieldWrapper>
            <FieldWrapper xs={12}>
              <Field
                name="confirm_password"
                component={MaterialInput}
                placeholder="Confirm Password"
                type="password"
                validate={required}
              />
            </FieldWrapper>
          </FormWrapper>
          <ButtonsWrapper center="xs">
            <Col xs={4}>
              <Button type="button" label="Cancel" onClick={onClose} primary />
            </Col>
            <Col xs={4}>
              <Button type="submit" label="Update" disabled={pristine || invalid} primary />
            </Col>
          </ButtonsWrapper>
        </form>
      )}
    />
  </Modal>
);

ChangePasswordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ChangePasswordModal;
