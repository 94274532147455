import React from 'react';
import moment from 'moment';
import {
  Wrapper,
  Container,
  InnerWrapper,
  SubscriptionFeeBanner,
  SFBannerTitle,
  SFBannerFee,
  SFBannerDesc,
  Circle,
  SFBannerStatus,
  SFBannerStatusIcon
} from './styled-components';
import { PRODUCTS } from '../../../constants';
import { numToCurrency } from '../../../utils/parsers';
import Enhancer from '../../containers/EventBilling';
import config from './config';

const EventBillingInfo = ({ platformSubscriptionStatements, platformSubscriptions }) => {
  const monthlySubscription =
    platformSubscriptions &&
    platformSubscriptions.data &&
    platformSubscriptions.data.find(
      subscription => subscription.order_product.product === PRODUCTS.PER_GAME_FEE
    );
  if (monthlySubscription) {
    const today = moment();
    const billingStartDate = moment(monthlySubscription.billing_start_date);
    monthlySubscription.overDue =
      (platformSubscriptionStatements &&
        platformSubscriptionStatements.due &&
        platformSubscriptionStatements.due.reduce(
          (acc, value) => acc + value.invoice_details.amount,
          0
        )) ||
      0;
    monthlySubscription.nextBillingDate =
      today.date() <= billingStartDate.date()
        ? today.set('date', billingStartDate.date())
        : today.set('date', billingStartDate.date()).set('month', today.month() + 1);
  }
  return (
    <Wrapper>
      <Container>
        <InnerWrapper xs={12} sm={12} md={12} lg={12} style={{ margin: '0 auto' }}>
          {monthlySubscription ? (
            <SubscriptionFeeBanner
              status={config.subscriptionStatus[monthlySubscription.active ? 'ACTIVE' : 'INACTIVE']}
            >
              <SFBannerTitle>
                <span>Game Subscription</span>
                <SFBannerStatus>
                  <Circle
                    status={
                      config.subscriptionStatus[monthlySubscription.active ? 'ACTIVE' : 'INACTIVE']
                    }
                  />
                  <SFBannerStatusIcon>
                    {monthlySubscription.active ? 'Active' : 'Inactive'}
                  </SFBannerStatusIcon>
                </SFBannerStatus>
              </SFBannerTitle>
              <SFBannerFee>{numToCurrency(monthlySubscription.order_product.price)}</SFBannerFee>
              {monthlySubscription.overDue ? (
                <div>Overdue: {numToCurrency(monthlySubscription.overDue)}</div>
              ) : null}
              {moment().set('day', moment(monthlySubscription.cycle_start_date)) ? (
                <SFBannerDesc>
                  Next Billing Date:{' '}
                  {moment(monthlySubscription.nextBillingDate, 'YYYY/MM/DD').format('DD MMM YYYY')}
                </SFBannerDesc>
              ) : null}
            </SubscriptionFeeBanner>
          ) : null}
          {/*
          Will be bringing this back once the billing feature is in a better state
          <Heading>Event Billing Statements</Heading>
          {config.billing(props).map(billing => (
            <EventBilling {...billing} />
          ))} */}
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

export default Enhancer(EventBillingInfo);
