import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export const GroupContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: ${({ renderType }) => (renderType === 'Availability' ? '0.32rem' : '0')};

  ${({ theme }) => theme};
`;

export const HeighlightContainer = styled.span`
  position: absolute;
  height: 60%;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
`;

export const BarContainer = styled.span`
  position: relative;
  display: block;
  width: 100%;
  &:before,
  &:after {
    content: '';
    height: 0.125rem;
    width: 0;
    bottom: 0.06rem;
    position: absolute;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
`;

export const Label = styled.label`
  font-family: 'RobotoCondensed';
  color: ${COLORS.shuttleGrey};
  font-size: 13px;
  font-stretch: condensed;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 0.7rem;
  left: 0.5rem;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;

  ${({ theme }) => theme};

  ${({ hasValue, hasError, marginTop }) =>
    hasValue &&
    `top: -.3rem;
    font-size: 0.6rem;
    color: ${hasError ? COLORS.carminePink : COLORS.shuttleGrey};
    margin-top: ${hasError && !marginTop ? '-15px' : '0px'};`};
`;

// Description should take size of the container
// Space between Description
// Same font on Placeholders
// Less dark when going to the top
// End Date, Start Date
export const Input = styled.input`
  box-sizing: border-box;
  font-size: 0.8rem;
  padding: 0.6rem 0 0.2rem 0.5rem;
  display: block;
  border: none;
  font: 'RobotoCondensed';
  box-shadow: ${({ hasError }) => (hasError ? `none` : `inset 0 -1px 0 0 ${COLORS.hawkesBlue}`)};
  border-bottom: 1px solid ${({ hasError }) => (hasError ? COLORS.carminePink : COLORS.white)};
  width: 100%;
  color: ${COLORS.denimBlue};

  &:focus ~ ${BarContainer}:before, &:focus ~ ${BarContainer}:after {
    width: 50%;
  }

  &:focus {
    outline: none;
  }

  &:focus ~ ${Label} {
    ${({ theme }) =>
      theme
        ? `top: -.3rem;
           color: ${({ hasError }) => (hasError ? COLORS.carminePink : COLORS.shuttleGrey)};
          `
        : `top: -.3rem;
           font-size: 0.6rem;
           color: ${({ hasError }) => (hasError ? COLORS.carminePink : COLORS.shuttleGrey)};
          `}
  }

  &:focus ~ ${HeighlightContainer} {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: ${({ hideArrows }) => (hideArrows ? 'none' : 'inner-spin-button')};
  }

  ${({ hideArrows }) => hideArrows && '-moz-appearance: textfield;'}

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: ${COLORS.sanMarino};
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @-moz-keyframes inputHighlighter {
    from {
      background: ${COLORS.sanMarino};
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @keyframes inputHighlighter {
    from {
      background: ${COLORS.sanMarino};
    }
    to {
      width: 0;
      background: transparent;
    }
  }

  ${({ theme }) => theme};
`;
