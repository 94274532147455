import styled from 'styled-components';

import Icon from '../Icon';

export const CheckIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 0;
  margin-right: 0.5rem;
`;

export default CheckIcon;
