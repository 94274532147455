import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import Button from '../Button';
import {
  ButtonsContainer,
  Note,
  StyledInput,
  StyledLabel,
  InputWrapper,
  Error
} from '../../layouts/AuthLayout/styled-components';
import { required, mustBeEmail, composeValidators } from '../../../utils/validations';

const SimpleInput = ({ input, error, label }) => (
  <InputWrapper>
    <StyledLabel isHidden={!input.value}>{label}</StyledLabel>
    <StyledInput
      type="text"
      {...input}
      placeholder={label}
      hasValue={input.value}
      hasError={error}
    />
    {error && <Error>{error}</Error>}
  </InputWrapper>
);

SimpleInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string.isRequired
};

SimpleInput.defaultProps = {
  error: null
};

const CreateAccountForm = ({
  onCreateAccount,
  primaryButtonLabel,
  secondaryButtonLabel,
  submitting
}) => (
  <Form
    onSubmit={onCreateAccount}
    render={({ handleSubmit, pristine, invalid }) => (
      <form onSubmit={handleSubmit}>
        <Field name="firstName" validate={required}>
          {({ input, meta: { error } }) => (
            <SimpleInput label="first name" error={!!input.value && error} input={input} />
          )}
        </Field>
        <Field name="lastName" validate={required}>
          {({ input, meta: { error } }) => (
            <SimpleInput label="last name" error={!!input.value && error} input={input} />
          )}
        </Field>
        <Field name="email" validate={composeValidators(required, mustBeEmail)}>
          {({ input, meta: { error } }) => (
            <SimpleInput label="email" error={!!input.value && error} input={input} />
          )}
        </Field>
        <Note>You will receive an email containing an access code for verification.</Note>
        <ButtonsContainer>
          <Button
            width="150px"
            type="submit"
            label={primaryButtonLabel}
            submitting={submitting}
            disabled={pristine || invalid || submitting}
            primary
            noShadow
          />
          {secondaryButtonLabel}
        </ButtonsContainer>
      </form>
    )}
  />
);

CreateAccountForm.propTypes = {
  onCreateAccount: PropTypes.func.isRequired,
  primaryButtonLabel: PropTypes.string.isRequired,
  secondaryButtonLabel: PropTypes.element,
  submitting: PropTypes.bool.isRequired
};

CreateAccountForm.defaultProps = {
  secondaryButtonLabel: null
};

export default CreateAccountForm;
