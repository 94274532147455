import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const ClickableContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: 0.32rem;
  cursor: pointer;

  ${({ theme }) => theme};
`;

export const CaptionContainer = styled.div`
  box-shadow: inset 0 -1px 0 0 ${({ hasError }) => (hasError ? ` ${COLORS.carminePink}` : ` ${COLORS.hawkesBlue}`)};
  line-height: 1.8rem;
  display: flex;
  background-color: ${({ disabled, disabledBackgroundColor }) =>
    disabled ? disabledBackgroundColor || COLORS.disabled : 'inherit'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ theme }) => theme};
`;
export const DropDownContent = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme};
`;

export const Label = styled.label`
  font-family: 'RobotoCondensed';
  color: ${COLORS.shuttleGrey};
  font-size: ${({ fontSize }) => fontSize || pxToRem('9.6px')};
  font-stretch: condensed;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: ${pxToRem('-6.4px')};
  left: ${pxToRem('8px')};
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  ${({ hasValue, hasError }) =>
    hasValue &&
    `top: ${pxToRem('-16px')};
    font-size: ${({ fontSize }) => fontSize || pxToRem('9.6px')};
    color: ${hasError ? COLORS.carminePink : COLORS.shuttleGrey};`};

  ${({ empty, theme }) => (!empty && theme === 'hide' ? 'visibility: hidden;' : '')};

  ${({ theme }) => theme};
`;

export const PlaceHolderValueContainer = styled.div`
  color: ${({ color }) => color};
  flex: 1;
  font-size: 0.8rem;
  padding-left: 0.5rem;
  font-family: 'RobotoCondensed';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme};
`;
export const CarretContainer = styled(Col)`
  align-self: center;
`;

export const IconOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${pxToRem('5px')};
`;

export const IconContainer = styled.div`
  ${({ theme }) => theme};
`;
