import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles, { InputWrapper } from './styles';
import InputError from '../../components/InputError';

const TextInput = ({
  classes,
  value,
  onChange,
  style = {
    width: '100%',
    marginTop: '30px'
  },
  required = false,
  disabled = false,
  error,
  helperText = false,
  label = false,
  onBlur = null,
  InputProps = {}
}) => {
  const inputPropsStyle = {
    notched: false,
    classes: {
      root: classes.cssOutlinedInput,
      focused: classes.cssFocused,
      notchedOutline: classes.notchedOutline
    }
  };
  return (
    <InputWrapper>
      <TextField
        style={style}
        value={value || ''}
        type="text"
        onChange={e => onChange(e.target.value)}
        onBlur={onBlur && onBlur}
        variant="outlined"
        required={required}
        disabled={disabled}
        error={!!error}
        helperText={helperText}
        label={label && label}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            shrink: classes.slimLabelShrink,
            focused: classes.cssFocused
          }
        }}
        InputProps={{ ...inputPropsStyle, ...InputProps }}
      />
      <InputError>{error || ''}</InputError>
    </InputWrapper>
  );
};

export default withStyles(styles)(TextInput);
