import styled from 'styled-components';

import { COLORS, PAPER } from '../../../utils/styles';

export const Background = styled.div`
  background-color: ${COLORS.white};
  padding: ${({ authFlow, crewLabel }) => (authFlow && !crewLabel ? '35px 60px 5px 60px' : '0rem')};
  ${({ crewLabel }) => !crewLabel && PAPER};
  ${({ customStyle }) => customStyle}
`;

export const Title = styled.h3`
  background-color: ${COLORS.white};
  padding: ${({ padding }) => padding};
  ${PAPER};
`;

export const ChildContainer = styled.div`
  overflow: ${({ showOverflow }) => (showOverflow ? 'visible' : 'hidden')};
`;

export default Background;
