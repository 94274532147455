import { compose, withState, withHandlers } from 'recompose';
import MODAL_NAMES, { FROM_NOTIFICATIONS } from '../../../../../core/Modals/constants';
import { NOTIFICATION_STATUS } from '../../../../../constants';
import { convertUtcToTimezone, generateRefreshToken } from '../../../../../utils/helpers';
import { openExternalGameReportURL } from '../../../Games/utils';
import { formatDate } from '../../../../../utils/parsers';

export default compose(
  withState('expandedId', 'setExpandedId', null),
  withHandlers({
    onExpand: ({ fetchNotification, setExpandedId, clearNotification }) => expandedId => {
      clearNotification();
      setExpandedId(expandedId);
      if (expandedId) fetchNotification(expandedId);
    },
    onSubmit: ({
      setCurrentGame,
      setNotificationEvent,
      setExpandedId,
      updateNotification,
      notifications,
      closeMenu,
      setShowModalFor,
      userIdx,
      user
    }) => async ({
      expandedId,
      game: gameObj,
      includeGameReport,
      includeIncidentReport,
      event
    }) => {
      const gameDate = convertUtcToTimezone(
        `${gameObj.start_date} ${gameObj.start_time}`,
        gameObj.timezone
      );
      const game = {
        ...gameObj,
        start_date: formatDate(gameDate, 'MM/DD/YYYY'),
        start_time: formatDate(gameDate, 'h:mm A')
      };
      if (includeGameReport) {
        if (game.report_url) {
          const token = await generateRefreshToken(userIdx, user);
          openExternalGameReportURL(game.report_url, token);
          if (includeIncidentReport) {
            setCurrentGame(game);
            setNotificationEvent(event);
            setShowModalFor({ name: MODAL_NAMES.incidentReportsModal, from: FROM_NOTIFICATIONS });
          }
        } else {
          setCurrentGame(game);
          setNotificationEvent(event);
          const modal = { name: MODAL_NAMES.gameReportsModal, from: FROM_NOTIFICATIONS };
          if (includeIncidentReport) {
            modal.additional = {
              showIncidentReport: true
            };
          }
          setShowModalFor(modal);
        }
      }

      if (notifications.length === 1) closeMenu();

      updateNotification({ id: expandedId, data: { status: NOTIFICATION_STATUS.action_taken } });
      setExpandedId(null);
    }
  })
);
