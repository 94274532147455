import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { COLORS, mediaQueries } from '../../../utils/styles';
import Icon from '../Icon';

export const MenuContainer = styled.div`
  display: flex;
  background-image: linear-gradient(to right, ${COLORS.bahamaBlue}, ${COLORS.denimBlue});
  flex-direction: column;
  width: 22%;
  z-index: 99;
  min-height: -webkit-fill-available;
  ${mediaQueries.md(`
    width: 100%;
  background:rgba(256,256,256,1);
  background-image:none;
`)};
`;

export const MenuItemContainer = styled(NavLink)`
  color: ${COLORS.white};
  line-height: 3rem;
  font-family: 'RobotoCondensed';
  font-size: 0.9rem;
  font-weight: 400;
  background-image: linear-gradient(to right, ${COLORS.bahamaBlue}, ${COLORS.denimBlue});
  padding: 0.5rem 0.5rem 0.5rem 0;
  height: 3rem;
  &.active {
    background-image: linear-gradient(to right, ${COLORS.greenVogue}, ${COLORS.congressBlue});
    ${mediaQueries.md(`background-image:none;`)};
  }
  ${mediaQueries.md(`
  color: #167CD4;
  background:rgba(256,256,256,1);
  background-image:none;
  height: 2rem;
  line-height: 2rem;
  text-transform:capitalize;
  span{display:none;}
  `)};
`;
export const TextContainer = styled.div`
  padding-left: 2.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${mediaQueries.md(` padding-left: 1.5rem;`)};
`;

export const GradienLine = styled.hr`
  border: 0;
  height: 2px;
  background-image: -webkit - linear - gradient(left, ${COLORS.denimBlue}, transparent);
  background-image: -moz - linear - gradient(left, ${COLORS.denimBlue}, transparent);
  background-image: -o - linear - gradient(left, ${COLORS.denimBlue}, transparent);
`;

export const SideMenuHeader = styled.div`
  background: rgba(1, 43, 84, 0.3);
  line-height: 2.5rem;
  font-family: 'RobotoCondensed';
  padding: 0.5rem 0.5rem 0.5rem 0;
  height: 2.5rem;
  color: #fff;
`;

export const SideMenuSubheader = styled.div`
line-height: 2.5rem;
font-family: 'RobotoCondensed';
padding: 0.5rem 0.5rem 0.5rem 0;
height: 2.5rem;
color: #fff;
text - transform: uppercase;
${({ borderTop }) => borderTop && `border-top: 1px solid ${COLORS.congressBlue}`};
${mediaQueries.md(`
  color: ${COLORS.cobaltGrey};
  padding: 0.8rem 0;
  height: 1rem;
  line-height: 1rem;
  font-weight:600;
  border-top: 0px solid rgba(0,0,0,0)`)};
  font-size:1rem;
`;

export const StyledIcon = styled(Icon)`
  margin: 0;
`;
