import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';

import background from '../../../assets/images/login-background.jpg';

export default styled(Row)`
  background: url(${background}) no-repeat 50% fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
