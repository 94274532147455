import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import {
  isAnyEventOfficialOrAssessorOrAssignor,
  isAdminActiveUser,
  isFundingAdminActiveUser,
  isActiveUserAnyEventAdmin,
  isSuperAdminActiveUser,
  crossPaymentMethodCheck
} from '../../../core/Auth/selectors';

export default compose(
  connect(state => ({
    fetchPayProfilePayee: isAnyEventOfficialOrAssessorOrAssignor()(state),
    isAdmin: isAdminActiveUser(state),
    isFundingAdmin: isFundingAdminActiveUser(state),
    isAnyEventAdmin: isActiveUserAnyEventAdmin()(state),
    isSuperAdmin: isSuperAdminActiveUser(state),
    crossPaymentMethodCheck: crossPaymentMethodCheck(state)
  })),
  pure
);
