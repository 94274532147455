import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, pure, withHandlers } from 'recompose';
import {
  getUser,
  isGamesEventAdmin,
  isGamesEventAdminOrAssignor,
  isGamesEventOfficial
} from '../../../../core/Auth/selectors';
import {
  getEventById,
  getEventCategories,
  isOfficialSelfAssignAllowed
} from '../../../../core/Events/selectors';
import { isGamesEventGroupAdmin } from '../../../../core/Games/selectors';
import {
  fetchEventUserDetail,
  fetchEventUsers,
  fetchNextSetOfEventUsers,
  sendBulkEmail,
  setSelectedUser as setSelectedUserAction,
  updateCategories
} from '../../../../core/Users';
import {
  getIsFetchingEventRoles,
  getIsFetchingInit,
  getMetaByType,
  getUsersByType,
  hasNextPage
} from '../../../../core/Users/selectors';
import buildConfig from '../../../components/AssignmentsList/config';
import { filterConfigToNames, paginationAware, updateOnPageChange } from '../../PaginationAware';
import unconnected from './unconnected';

const mapDispatchToProps = (dispatch, { roles }) => ({
  onLoad: payload => {
    return dispatch(fetchEventUsers(payload));
  },
  onFetchNextOfficials: payload => {
    return dispatch(fetchNextSetOfEventUsers(payload));
  },
  onFetchItemDetail: (userId, eventRoleId) =>
    dispatch(
      fetchEventUserDetail({
        userId,
        eventRoleId,
        roleName: pluralize(roles.alias)
      })
    ),
  sendEmail: payload => dispatch(sendBulkEmail(payload)),
  setSelectedUser: payload => dispatch(setSelectedUserAction(payload)),
  onUpdateCategories: payload => dispatch(updateCategories(payload))
});

const mapStateToProps = (state, props) => ({
  users: getUsersByType(state, props.roles.alias),
  user: getUser(state),
  metaData: getMetaByType(props.roles.alias)(state),
  categories: getEventCategories(state),
  isGamesEventAdmin: isGamesEventAdmin(state),
  allowSelfAssignment:
    isGamesEventOfficial(state) &&
    isOfficialSelfAssignAllowed(state) &&
    !(isGamesEventGroupAdmin()(state) || isGamesEventAdminOrAssignor(state)),
  userid: props.user && props.user.id,
  isFetchingInit: getIsFetchingInit(state),
  onLoadPageArgs: {
    roles: props.roles,
    eventId: props.eventId
    // userid:
    //   isGamesEventOfficial(state) &&
    //   isOfficialSelfAssignAllowed(state) &&
    //   props.user &&
    //   props.user.id
  },
  eventInfo: getEventById(props && props.eventId, state),
  hasNextPage: hasNextPage(state),
  isFetchingEventRoles: getIsFetchingEventRoles(state)
});

const PAGINATION_AWARE_PREFIX = 'usrs';

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleUpdateCategories: ({ onUpdateCategories, roles }) => categoriesPayload => {
      onUpdateCategories({
        ...categoriesPayload,
        roleName: pluralize(roles.alias)
      });
    }
  }),
  paginationAware(
    {
      fetcherQuote: 'onLoad',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'metaData',
      searchFilter: ['search'],
      filterNames: filterConfigToNames(buildConfig({}).filters)
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  unconnected,
  pure
);
