import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { addNotifications as addNotificationsAction } from '../../../core/Notifications';
import Maps from '../../../utils/maps';
import { DirectionLink, DirectionWrapper } from './styled-components';

const Enhancer = compose(
  connect(null, { addNotifications: addNotificationsAction }),
  withProps(({ addNotifications }) => ({
    onError: message => addNotifications([{ type: 'error', message }])
  })),
  withHandlers({
    openMaps: ({ onError, location: { name, address, city, state, postal_code } }) => async () => {
      const gameAddress = `${name} ${address}, ${city}, ${state}, ${postal_code}`;
      const geoLocation = await Maps.getGeoLocation(gameAddress);
      const location = {
        address: Object.values({ name, address, city }).join(', '),
        geoLocation
      };

      return Maps.getDirections(location, onError);
    }
  })
);

const Direction = ({ openMaps }) => (
  <DirectionLink onClick={openMaps}>
    {' '}
    <DirectionWrapper>Directions</DirectionWrapper>
    {/* <DirectionWrapper>
      {` `}
      <StatusIconContainer>
        <StatusIcon width={16} height={16} color={COLORS.lightBlue} icon={ICONS.DIRACTIONLINK} />
      </StatusIconContainer>
    </DirectionWrapper> */}
  </DirectionLink>
);

export default Enhancer(Direction);
