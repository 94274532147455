import { compose, pure, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import {
  fetchMonthTilesData as fetchMonthTilesDataAction,
  createBatch as createBatchAction
} from '../../../../core/Payments';
import {
  getMonthTilesIsFetching,
  getMonthTilesData,
  getPaymentsActiveBatch,
  getPaymentsEventId
} from '../../../../core/Payments/selectors';
import { addNotifications } from '../../../../core/Notifications';

const mapStateToProps = state => ({
  isFetching: getMonthTilesIsFetching(state),
  monthTilesData: getMonthTilesData(state),
  currentEventHasBatch: getPaymentsActiveBatch(state),
  eventId: getPaymentsEventId(state)
});

const mapDispatchToProps = dispatch => ({
  fetchMonthTilesData: payload => dispatch(fetchMonthTilesDataAction(payload)),
  addNotification: payload => dispatch(addNotifications(payload)),
  createBatch: payload => dispatch(createBatchAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchMonthTilesData();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.eventId !== this.props.eventId) {
        this.props.fetchMonthTilesData();
      }
    }
  }),
  withHandlers({
    createMonthBatch: ({ currentEventHasBatch, addNotification, createBatch, eventId }) => ({
      data
    }) => {
      if (currentEventHasBatch.id) {
        addNotification([
          {
            type: 'error',
            message: 'This event already has a current batch. Please delete and try again.'
          }
        ]);
      } else {
        createBatch({ id: eventId, data });
      }
    }
  }),
  pure
);
