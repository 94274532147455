import React from 'react';
import { Field } from 'react-final-form';
import { and } from 'ramda';
import CollapsibleContent from '../../../CollapsableContent';
import RoundedCheckBox from '../../../RoundedCheckBox';
import { COLORS } from '../../../../../utils/styles';
import {
  Container,
  Header,
  Description,
  Content,
  RoleOptionColumn,
  RoleLabel,
  RoleOptionContainer,
  Option,
  OptionWithInput,
  OptionInnerWrapper,
  InputWrapper,
  Input,
  OptionLabel,
  InputContainer
} from './styled-components';
import notificationCollapsibleTheme from '../config';
import InputError from '../../../InputError';
import { valueInRange } from '../../../../../utils/validations';

export const NumericField = props => {
  return (
    <Field {...props}>
      {({
        input,
        meta: { error, touched, invalid },
        min,
        max,
        disabled,
        defaultValue = 0,
        fromGameReport
      }) => {
        const data = { ...input };
        data.value = data.value === '' ? (fromGameReport ? '' : defaultValue) : data.value;
        return (
          <InputContainer>
            <InputWrapper>
              <Input {...data} type="number" min={min} max={max} disabled={disabled} />
              <OptionLabel color={COLORS.shuttleGray}>{props.label}</OptionLabel>
            </InputWrapper>
            {and(touched, invalid) && <InputError>{error}</InputError>}
          </InputContainer>
        );
      }}
    </Field>
  );
};

const NotificationCollapsible = ({ disabled }) => (
  <CollapsibleContent title="NOTIFICATIONS" theme={notificationCollapsibleTheme} externalIsOpen>
    <Container>
      <Header>
        <Description>
          Notifications will be sent out to users based on the actions listed below:
        </Description>
      </Header>
      <Content>
        <RoleOptionColumn>
          <RoleLabel>OFFICIALS</RoleLabel>
          <RoleOptionContainer>
            <Option>
              <Field name="officials_setting.new_assignment" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>New Assignment</OptionLabel>
            </Option>
            <Option>
              <Field name="officials_setting.assignment_declined" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Assignment Declined</OptionLabel>
            </Option>
            <Option>
              <Field name="officials_setting.game_change" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Game Change</OptionLabel>
            </Option>
            <OptionWithInput>
              <Field name="officials_setting.game_reminder" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionInnerWrapper>
                <OptionLabel color={COLORS.shuttleGray}>Game Reminder:</OptionLabel>
                <NumericField
                  validate={valueInRange({ min: 0, max: 30 })}
                  min={0}
                  max={30}
                  name="officials_setting.game_reminder_days"
                  disabled={disabled}
                  label="days before game"
                />
              </OptionInnerWrapper>
            </OptionWithInput>
            <Option>
              <RoundedCheckBox disabled />
              <OptionLabel color={COLORS.shuttleGray}>Daily Assignment Summary</OptionLabel>
            </Option>
          </RoleOptionContainer>
        </RoleOptionColumn>

        <RoleOptionColumn>
          <RoleLabel>ASSESSORS</RoleLabel>
          <RoleOptionContainer>
            <Option>
              <Field name="assessors_setting.new_assignment" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>New Assignment</OptionLabel>
            </Option>
            <Option>
              <Field name="assessors_setting.assignment_declined" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Assignment Declined</OptionLabel>
            </Option>
            <Option>
              <Field name="assessors_setting.game_change" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Game Change</OptionLabel>
            </Option>
            <OptionWithInput>
              <Field name="assessors_setting.game_reminder" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionInnerWrapper>
                <OptionLabel color={COLORS.shuttleGray}>Game Reminder:</OptionLabel>
                <NumericField
                  validate={valueInRange({ min: 0, max: 30 })}
                  min={0}
                  max={30}
                  name="assessors_setting.game_reminder_days"
                  disabled={disabled}
                  label="days before game"
                />
              </OptionInnerWrapper>
            </OptionWithInput>
            <Option>
              <RoundedCheckBox disabled />
              <OptionLabel color={COLORS.shuttleGray}>Daily Assignment Summary</OptionLabel>
            </Option>
          </RoleOptionContainer>
        </RoleOptionColumn>

        <RoleOptionColumn>
          <RoleLabel>ASSIGNORS</RoleLabel>
          <RoleOptionContainer>
            <Option>
              <Field name="assignors_setting.new_assignment" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>New Assignment</OptionLabel>
            </Option>
            <Option>
              <Field name="assignors_setting.assignment_declined" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Assignment Declined</OptionLabel>
            </Option>
            <Option>
              <Field name="assignors_setting.game_change" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Game Change</OptionLabel>
            </Option>
            <OptionWithInput>
              <Field name="assignors_setting.game_reminder" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionInnerWrapper>
                <OptionLabel color={COLORS.shuttleGray}>Game Reminder:</OptionLabel>
                <NumericField
                  validate={valueInRange({ min: 0, max: 30 })}
                  min={0}
                  max={30}
                  name="assignors_setting.game_reminder_days"
                  disabled={disabled}
                  label="days before game"
                />
              </OptionInnerWrapper>
            </OptionWithInput>
            <Option>
              <Field name="assignors_setting.daily_assignment_summary" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Daily Assignment Summary</OptionLabel>
            </Option>
          </RoleOptionContainer>
        </RoleOptionColumn>

        <RoleOptionColumn>
          <RoleLabel>ADMINS</RoleLabel>
          <RoleOptionContainer>
            <Option>
              <Field name="admins_setting.new_assignment" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>New Assignment</OptionLabel>
            </Option>
            <Option>
              <Field name="admins_setting.assignment_declined" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Assignment Declined</OptionLabel>
            </Option>
            <Option>
              <Field name="admins_setting.game_change" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Game Change</OptionLabel>
            </Option>
            <OptionWithInput>
              <Field name="admins_setting.game_reminder" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionInnerWrapper>
                <OptionLabel color={COLORS.shuttleGray}>Game Reminder:</OptionLabel>
                <NumericField
                  validate={valueInRange({ min: 0, max: 30 })}
                  min={0}
                  max={30}
                  name="admins_setting.game_reminder_days"
                  disabled={disabled}
                  label="days before game"
                />
              </OptionInnerWrapper>
            </OptionWithInput>
            <Option>
              <Field name="admins_setting.daily_assignment_summary" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Daily Assignment Summary</OptionLabel>
            </Option>
          </RoleOptionContainer>
        </RoleOptionColumn>
      </Content>
    </Container>
  </CollapsibleContent>
);
export default NotificationCollapsible;
