import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import Flex from '../../Flex';
import ActionDropdown from '../../ActionDropdown';
import Dropdown from '../../Reusable/Dropdown';
import EventFilter from '../../EventFilter';
import GamesFilter from './GamesFilter';
import Search from '../../Search';
import theme, {
  GamesActionItemContainer,
  GamesActionItem,
  RefreshButton,
  StatusIconContainer,
  StatusIcon
} from './styled-components';
import { HEIGHTS, COLORS, ICONS, mediaQueriesCheck } from '../../../../utils/styles';
import Icon from '../../Icon';
import { capitalize, generateRefreshQuery } from '../../../../utils/helpers';
import { ASSIGNMENT_STATUS, UNASSIGNED_ASSIGNMENT_STATUS } from '../../../../core/Games/constants';

const assignmentFilterOptions = [
  { name: 'All Assignments', value: 'all-games' },
  { name: 'Unassigned-Official', value: 'unassignedOff' },
  { name: 'Unassigned-Assessor', value: 'unassignedAss' },
  { name: capitalize(ASSIGNMENT_STATUS.pending), value: 'tentative' },
  { name: capitalize(ASSIGNMENT_STATUS.accepted), value: ASSIGNMENT_STATUS.accepted },
  { name: capitalize(ASSIGNMENT_STATUS.declined), value: ASSIGNMENT_STATUS.declined },
  {
    name: capitalize(ASSIGNMENT_STATUS.unpublished),
    value: ASSIGNMENT_STATUS.unpublished
  }
];
const Header = ({
  storeGamesEvent,
  gamesEvent,
  config,
  conditions,
  clearParams,
  gamelevels,
  categories,
  filter,
  search: { onSearch, value },
  assignmentFilter,
  handleAssignmentFilter,
  location,
  teams,
  complexes,
  setGameImportNotification,
  isGamesEventGroupAdmin,
  isGamesEventAdmin,
  setEventUsersList,
  setUsersDemographicsList,
  clearEventUsersData
}) => {
  const [mediaCheck, setMediaCheck] = React.useState(mediaQueriesCheck.mdCheck.matches === true);
  const [open, setOpen] = React.useState(false);
  function checkMediaQuery() {
    setMediaCheck(mediaQueriesCheck.mdCheck.matches === true);
  }

  useEffect(() => {
    window.addEventListener('resize', checkMediaQuery);
    return () => {
      window.removeEventListener('resize', checkMediaQuery);
    };
  });
  const isUnassignedFilter = UNASSIGNED_ASSIGNMENT_STATUS.includes(assignmentFilter);
  return (
    <Flex
      bgImage="linear-gradient(to top,#05579d 20%,#167cd4 100%);"
      height={mediaCheck ? 'auto' : HEIGHTS.eventNavigationBar}
      justifyContent="space-between"
      padding={mediaCheck ? '0px 0px' : '0 1.5rem 0 0'}
      fullWidth
      noShrink
      direction={mediaCheck ? 'column' : 'row'}
    >
      <Flex fullWidth={!!mediaCheck}>
        <EventFilter
          onSelectEvent={storeGamesEvent}
          clearParams={clearParams}
          selectedEvent={gamesEvent}
        />
      </Flex>
      <Flex justifyContent="space-between" fullWidth padding="0px 10px">
        <Flex>
          <Flex theme={theme.dropdownContainer} height={HEIGHTS.eventNavigationBar}>
            <Dropdown
              options={assignmentFilterOptions}
              placeholder="Assignment Status"
              input={{ value: assignmentFilter, onChange: handleAssignmentFilter }}
              theme={theme.dropdown}
              displayValue
              setHeight
            />
            {isUnassignedFilter && (
              <Tooltip
                PopperProps={{
                  disablePortal: true
                }}
                open={open}
                title="Selected filters will only apply to current page games"
                arrow
              >
                <div>
                  <StatusIconContainer
                    paddingRight="0px"
                    height="1.5rem"
                    onMouseEnter={() => setOpen(true)}
                    onClick={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                  >
                    <StatusIcon
                      width={15}
                      height={15}
                      color={COLORS.white}
                      icon={ICONS.EXCLAMATION_CIRCLE}
                    />
                  </StatusIconContainer>
                </div>
              </Tooltip>
            )}
          </Flex>
          <Flex
            theme={theme.dropdownContainer}
            padding="0px 0px 0px 10px"
            height={HEIGHTS.eventNavigationBar}
          >
            <GamesFilter
              gamelevels={gamelevels}
              categories={categories}
              showOffNameFilter={conditions.isAuthorized}
              teams={teams}
              complexes={complexes}
              {...filter}
            />
          </Flex>
        </Flex>

        <GamesActionItemContainer justifyContent="space-between">
          <GamesActionItem>
            <Flex full maxWidth="120px">
              <Search
                iconHeight={14}
                iconWidth={14}
                iconColor={COLORS.white}
                fieldColor={COLORS.white}
                onSearch={onSearch}
                value={value}
                placeholder="Search Game ID"
              />
            </Flex>
          </GamesActionItem>
          <GamesActionItem>
            <NavLink to={`${location.pathname}${generateRefreshQuery(location.search)}`}>
              <RefreshButton
                onClick={() => {
                  setEventUsersList([]);
                  setUsersDemographicsList([]);
                  clearEventUsersData({});
                  if (isGamesEventGroupAdmin || isGamesEventAdmin) {
                    setGameImportNotification(true);
                  }
                }}
              >
                <Icon height={18} width={18} icon={ICONS.REFRESH} color={COLORS.white} />
              </RefreshButton>
            </NavLink>
          </GamesActionItem>
          <GamesActionItem>
            <ActionDropdown theme={theme.action} config={config.action} conditions={conditions} />
          </GamesActionItem>
        </GamesActionItemContainer>
      </Flex>
    </Flex>
  );
};

Header.propTypes = {
  events: PropTypes.arrayOf(Object),
  gamesEvent: PropTypes.shape({
    id: PropTypes.string
  }),
  storeGamesEvent: PropTypes.func.isRequired,
  config: PropTypes.shape({
    action: PropTypes.shape({
      options: PropTypes.arrayOf(Object)
    })
  }).isRequired,
  conditions: PropTypes.shape({
    gamesSelected: PropTypes.bool,
    singleGameSelected: PropTypes.bool
  }),
  clearParams: PropTypes.func.isRequired,
  gamelevels: PropTypes.arrayOf(Object),
  categories: PropTypes.arrayOf(Object),
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    searchValue: PropTypes.string
  }).isRequired,
  assignmentFilter: PropTypes.string.isRequired,
  handleAssignmentFilter: PropTypes.func.isRequired
};

Header.defaultProps = {
  events: [],
  gamesEvent: null,
  conditions: [],
  gamelevels: [],
  categories: []
};

export default React.memo(Header);
