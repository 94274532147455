import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const DropZoneStyles = {
  width: '100%',
  backgroundImage: `radial-gradient(circle at 53% 50%, ${COLORS.white}, ${COLORS.solitudeVariant})`,
  margin: `${pxToRem('20px')} ${pxToRem('28px')} 0`,
  boxShadow: 'inset 0 0 5px 0 rgba(0, 0, 0, 0.3)',
  border: `2px solid ${COLORS.white}`,
  outline: 'none'
};

export const DropZoneAcceptStyles = {
  color: 'white',
  width: '100%',
  backgroundImage: `radial-gradient(circle at 53% 50%, ${COLORS.white}, ${COLORS.solitudeVariant})`,
  boxSizing: 'border-box',
  border: `2px solid ${COLORS.strongGreen}`,
  boxShadow: 'none',
  margin: `${pxToRem('20px')} ${pxToRem('28px')} 0`,
  outline: 'none'
};

export const DropZoneRejectStyles = {
  cursor: 'not-allowed',
  width: '100%',
  backgroundImage: `radial-gradient(circle at 53% 50%, ${COLORS.white}, ${COLORS.solitudeVariant})`,
  boxSizing: 'border-box',
  border: `2px solid ${COLORS.venetianRed}`,
  boxShadow: 'none',
  margin: `${pxToRem('20px')} ${pxToRem('28px')} 0`,
  outline: 'none'
};

export const ContentWrapper = styled.div`
  margin: 0 0.5rem;
  padding-bottom: ${pxToRem('14px')};
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
`;

export const Block = styled(Col)`
  position: relative;
  margin: ${pxToRem('20px')} auto;
  padding: 0 ${pxToRem('20px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.shuttleGray};
  line-height: 1.31;
`;

export const FullWidthBlock = styled(Block)`
  width: 100%;
  padding: 0;
  margin-bottom: 0;
`;

export const Content = styled.div`
  ${({ absolute }) =>
    absolute
      ? `
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      `
      : `
      position: relative;
      margin-bottom: 1rem;
      `};

  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${pxToRem(marginBottom)}` : '')};
  ${({ light }) => (light ? 'font-family: RobotoLight' : '')};
  ${({ extraPadding }) => (extraPadding ? `padding-bottom: ${pxToRem('30px')}` : '')};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ButtonsContainer = styled.div`
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`;

export const TextContainer = styled.div`
  display: inline-block;
  padding: 0 ${pxToRem('2px')};
`;

export const InputLink = styled.a`
  cursor: pointer;
  color: ${COLORS.denimBlue};
  text-decoration: underline;
`;

export const InputSelect = styled.span`
  cursor: pointer;
  color: ${COLORS.denimBlue};
  text-decoration: underline;
`;

export const SuccessMessage = styled.span`
  display: inline-block;
  position: relative;
  top: ${pxToRem('3px')};
  left: ${pxToRem('-1px')};
  text-transform: uppercase;
  color: ${COLORS.forestGreen};
  font-family: 'Roboto';
`;

export const ErrorMessage = styled.span`
  display: inline-block;
  position: relative;
  top: ${pxToRem('3px')};
  left: ${pxToRem('-4px')};
  font-family: 'Roboto';
`;

export const Error = styled.span`
  text-transform: uppercase;
  color: ${COLORS.venetianRed};
`;

export default Block;
