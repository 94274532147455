// Actions
export const FETCH_ROLES = 'ROLES/FETCH_ROLES';
export const SET_ROLES = 'ROLES/SET_ROLES';
export const FETCH_FUNDING_ADMINS = 'ROLES/FETCH_FUNDING_ADMINS';
export const SET_FUNDING_ADMINS = 'ROLES/SET_FUNDING_ADMINS';

// Reducer
const initialState = {
  roleList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        eventList: []
      };

    case SET_ROLES:
      return {
        ...state,
        roleList: action.payload
      };
    case FETCH_FUNDING_ADMINS:
      return {
        ...state,
        fundingAdminsList: []
      };
    case SET_FUNDING_ADMINS:
      return {
        ...state,
        fundingAdminsList: action.payload
      };

    default:
      return state;
  }
}

// Action Creators
export const fetchRoles = () => ({
  type: FETCH_ROLES
});

export const setRoles = payload => ({
  payload,
  type: SET_ROLES
});

export const fetchFundingAdmins = () => ({
  type: FETCH_FUNDING_ADMINS
});

export const setFundingAdmins = payload => ({
  payload,
  type: SET_FUNDING_ADMINS
});
