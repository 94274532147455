import { compose, withHandlers, lifecycle, withState, withProps } from 'recompose';
import { REPORT_TYPES, CORE_ROLES, SPORT_TYPES, eventFilterLimit } from '../../../constants';
import MODAL_NAMES, { FROM_REPORTS } from '../../../core/Modals/constants';
import { formatReportFilters } from '../../../utils/helpers';

const searchEventParms = {
  LIMIT: eventFilterLimit,
  SORTBY: '-created_at',
  roleId: [CORE_ROLES.admin, CORE_ROLES.assignor, CORE_ROLES.assessor]
};
export default compose(
  withState('checkedReports', 'updateCheckedReports', []),
  withState('showBulkApproveModal', 'setShowBulkApproveModal', false),
  withState('showBulkUnapproveModal', 'setShowBulkUnapproveModal', false),
  withState('filterCount', 'setFilterCount', 0),
  withState('showAssessmentModal', 'setShowAssessmentModal', null),
  withState('activeModalDetails', 'setActiveModalDetails', {}),
  withProps(
    ({
      incidentReports,
      assessmentReports,
      gameReports,
      isReportsEventAdmin,
      isReportsEventGroupAdmin,
      isReportsEventAssignor,
      pagination: { type: reportType }
    }) => {
      const props = {
        reportType:
          reportType ||
          (isReportsEventAdmin || isReportsEventAssignor || isReportsEventGroupAdmin
            ? REPORT_TYPES.incident
            : REPORT_TYPES.assessment),
        reports: []
      };

      switch (props.reportType) {
        case REPORT_TYPES.incident:
          props.reports = incidentReports;
          break;
        case REPORT_TYPES.assessment:
          props.reports = assessmentReports;
          break;
        case REPORT_TYPES.game:
          props.reports = gameReports;
          break;
        default:
          break;
      }

      return props;
    }
  ),
  withHandlers({
    handleCheckboxClick: ({ updateCheckedReports }) => ({ id, checked }) =>
      updateCheckedReports(checkedReports =>
        checked ? [...checkedReports, id] : checkedReports.filter(reportId => reportId !== id)
      ),
    handleSelectAllAssessment: ({ updateCheckedReports, reports }) => checked =>
      updateCheckedReports(
        checked ? reports.map(report => report[0].game_assignment.external_game_id) : []
      ),
    handleSelectAllIncident: ({ updateCheckedReports, reports }) => checked =>
      updateCheckedReports(checked ? reports.map(report => report.id) : []),
    getCheckedReports: ({ checkedReports, reports }) => () =>
      checkedReports.map(id => reports.find(report => report.id === id)),
    getAssessmentCheckedReports: ({ checkedReports, reports }) => () =>
      checkedReports.map(id =>
        reports.find(report => report[0].game_assignment.external_game_id === id)
      ),
    onBulkApprove: ({
      bulkApproveReports,
      bulkApproveAssessmentReports,
      bulkApproveGameReports,
      reportsEvent,
      filter,
      pagination,
      search,
      reportType,
      sort: { value: sortValue }
    }) => reports => {
      const filters = formatReportFilters(filter.filterData);
      const payload = {
        data: { approved: true },
        eventId: reportsEvent.id,
        sort: sortValue,
        filters: { ...filters, ...(search.value && { external_event_id: search.value }) },
        page: pagination.page,
        message: 'Successfully approved reports'
      };
      payload.ids = reports.map(item => {
        if (reportType === REPORT_TYPES.assessment) {
          return item.game_assignment.external_game_id;
        }
        if (reportType === REPORT_TYPES.incident) {
          return item.id;
        }
        return item.id;
      });
      if (reportType === REPORT_TYPES.assessment) {
        return bulkApproveAssessmentReports(payload);
      }
      if (reportType === REPORT_TYPES.incident) {
        return bulkApproveReports(payload);
      }
      return bulkApproveGameReports(payload);
    },
    onBulkUnapprove: ({
      bulkApproveReports,
      bulkApproveAssessmentReports,
      bulkApproveGameReports,
      reportsEvent,
      filter,
      pagination,
      search,
      reportType,
      sort: { value: sortValue }
    }) => reports => {
      const filters = formatReportFilters(filter.filterData);
      const payload = {
        data: { approved: false },
        eventId: reportsEvent.id,
        sort: sortValue,
        filters: { ...filters, ...(search.value && { external_event_id: search.value }) },
        page: pagination.page,
        message: 'Successfully unapproved reports'
      };
      payload.ids = reports.map(item => {
        if (reportType === REPORT_TYPES.assessment) {
          return item.game_assignment.external_game_id;
        }
        if (reportType === REPORT_TYPES.incident) {
          return item.id;
        }
        return item.id;
      });
      if (reportType === REPORT_TYPES.assessment) {
        return bulkApproveAssessmentReports(payload);
      }
      if (reportType === REPORT_TYPES.incident) {
        return bulkApproveReports(payload);
      }
      return bulkApproveGameReports(payload);
    },
    onAssessmentReportClick: ({
      isReportsEventAdmin,
      isReportsEventGroupAdmin,
      currentUserId,
      setShowAssessmentModal,
      isReportsEventAssignor
    }) => ({ crewLabel, report }) => {
      if (crewLabel && report) {
        const {
          approved,
          id: reportId,
          created_by_user_id: createdBy,
          updated_by_user_id: updatedBy,
          game_assignment: gameAssignment
        } = report;
        const unowned = !createdBy || !updatedBy;
        const ownedByCurrentUser =
          createdBy === Number(currentUserId) || updatedBy === Number(currentUserId);

        if (
          isReportsEventGroupAdmin ||
          isReportsEventAdmin ||
          approved ||
          ownedByCurrentUser ||
          unowned ||
          isReportsEventAssignor
        ) {
          setShowAssessmentModal({
            reportId,
            gameId: gameAssignment.external_game_id,
            crewLabel: crewLabel.label
          });
        }
      }
    },
    onIncidentReportClick: ({
      fetchCurrentGame,
      setCurrentReport,
      setShowModalFor,
      setActiveModalDetails
    }) => report => {
      fetchCurrentGame({ id: report.external_game_id });
      setCurrentReport(report);
      setShowModalFor({ name: MODAL_NAMES.incidentReportsModal, from: FROM_REPORTS });
      setActiveModalDetails({
        name: MODAL_NAMES.incidentReportsModal
      });
    },
    onGameReportClick: ({ fetchCurrentGame, setShowModalFor, setActiveModalDetails }) => report => {
      fetchCurrentGame({ id: report.external_game_id });
      setShowModalFor({
        name: MODAL_NAMES.gameReportsModal,
        from: FROM_REPORTS,
        additional: {
          reportId: report.id
        }
      });
      setActiveModalDetails({
        name: MODAL_NAMES.gameReportsModal
      });
    }
  }),
  withProps(
    ({
      showBulkApproveModal,
      setShowBulkApproveModal,
      BulkApproveModal,
      onBulkApprove,
      showBulkUnapproveModal,
      setShowBulkUnapproveModal,
      BulkUnapproveModal,
      onBulkUnapprove
    }) => ({
      config: {
        action: {
          options: [
            {
              component: BulkApproveModal,
              label: 'Approve Selected',
              showOption: [],
              clickableOption: ['reportsSelected'],
              showModal: showBulkApproveModal,
              onClick: () => setShowBulkApproveModal(s => !s),
              onSubmit: onBulkApprove
            },
            {
              component: BulkUnapproveModal,
              label: 'Unapprove Selected',
              showOption: [],
              clickableOption: ['reportsSelected'],
              showModal: showBulkUnapproveModal,
              onClick: () => setShowBulkUnapproveModal(s => !s),
              onSubmit: onBulkUnapprove
            }
          ]
        }
      }
    })
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.fetchEvents)
        this.props.fetchEvents({
          userOnly: true,
          setLimit: searchEventParms.LIMIT,
          sort: searchEventParms.SORTBY,
          filters: { role_id: searchEventParms.roleId },
          isFetchingList: true
        });
    },
    componentDidUpdate(prevProps) {
      const {
        reports,
        reportsEvent,
        pagination,
        filter,
        reportType,
        search,
        sort,
        getReportsEventSport,
        eventsAssessmentSettings,
        reportSettings
      } = this.props;
      let byPlayer = false;
      if (
        (getReportsEventSport === SPORT_TYPES.futsal ||
          getReportsEventSport === SPORT_TYPES.soccer) &&
        reportSettings &&
        reportSettings.score_entry_option === 'Score by Player'
      ) {
        byPlayer = true;
      }
      const reportsFilter = {
        page: pagination.page,
        type: pagination.type,
        sort: sort.value,
        byPlayer,
        filters: {
          ...filter.filterData,
          ...(search.value && { external_event_id: search.value })
        }
      };

      if (
        (reportsEvent &&
          reportType &&
          (!prevProps.reportsEvent || prevProps.reportsEvent.id !== reportsEvent.id)) ||
        (!prevProps.eventsAssessmentSettings && eventsAssessmentSettings) ||
        (prevProps.eventsAssessmentSettings &&
          eventsAssessmentSettings &&
          prevProps.eventsAssessmentSettings.event_id !== eventsAssessmentSettings.event_id)
      ) {
        this.props.fetchReports(reportsFilter);
        this.props.fetchReportsEventCrewLabels();
      }
      if (reports !== prevProps.reports) this.props.updateCheckedReports([]);
    },
    componentWillUnmount() {
      this.props.unsetShowModalFor({
        name: this.props.activeModalDetails.name,
        from: FROM_REPORTS
      });
    }
  })
);
