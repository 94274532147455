import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { WIDTHS, HEIGHTS, COLORS, mediaQueries } from '../../../utils/styles';

import Icon from '../Icon';
import { pxToRem } from '../../../utils/helpers';
import Checkbox from '../Checkbox';

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${WIDTHS.userMenu};
  outline: none;
  box-shadow: 0 0 9px 0 rgba(61, 73, 86, 0.25);
  ${mediaQueries.md(`{
    width:100%;
  }`)}
`;

export const ItemList = MainContainer.extend`
  width: 100%;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 35px;
  ${mediaQueries.md(`{
    height:10vh;
    padding-bottom:25px;
  }`)}
`;

export const HeaderContainer = styled.div`
  display: ${({ expand }) => (expand ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  min-height: ${HEIGHTS.userListHeader};
  border-bottom: 1px solid #e2e2e2;
`;

export const MainTitle = styled.div`
  font-family: 'RobotoCondensed';
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: left;
  color: ${COLORS.denimBlue};
  padding-left: 1rem;
  line-height: 3rem;
`;

export const EndText = styled.p`
  font-family: 'RobotoCondensed';
  font-size: 0.8rem;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${COLORS.denimBlue};
  margintop: ${pxToRem('20px')};
  z-index: ${({ infinite }) => (infinite ? '9999' : '499')};
  position: relative;
`;

export const Primary = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const Supress = styled.div`
  height: ${pxToRem('100px')};
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-color: ${COLORS.white};
    ${props => (props.expand ? `flex-basis: 100%` : `flex-basis: calc(33.73333333% - 12px);`)}
    ${props => (props.expand ? `max-width :100%` : `max-width: calc(33.73333333% - 12px);`)}
    ${mediaQueries.md(` {
    flex-basis: 100%;
    max-width: 100%;
  }`)};
`;

export const GrayBarContainer = styled.div`
  background-color: ${COLORS.greyChateau};
  width: ${pxToRem('12px')};
  height: 80px;
  display: flex;
  ${mediaQueries.md(`{
    width: ${pxToRem('0px')};
  }`)}
`;
export const GrayBar = styled(NavLink)`
  background-color: ${COLORS.greyChateau};
  align-self: center;
  text-align: center;
  margin-left: ${pxToRem('2px')};
`;

export const StyledIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Scroller = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: auto;
  ${mediaQueries.md(`{
    height:20vh;
  }`)}
`;

export const FilterLabel = styled.span`
  color: ${COLORS.shuttleGray};
  font-size: ${pxToRem('13px')};
  padding-top: ${pxToRem('1px')};
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 1rem;
  position: relative;
`;

export const FilterLabelContainer = styled.div`
  display: flex;
  padding-top: ${pxToRem('2px')};
`;

export const Note = styled.span`
  font-size: ${pxToRem('12px')};
  padding: 0 1rem 0 0.5rem;
  max-width: 70%;
  text-overflow: ellipsis;
  max-height: 100%;
`;

export const ActionMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectAllWrapper = styled.div`
  margin-right: 10px;
  padding-right: 10px;
`;

export const SelectAll = styled(Checkbox)``;

export default {
  multiSelectDropdown: {
    wrapper: `
      background: transparent;
    `,
    header: `
      background: transparent;
      color: ${COLORS.nightRider};
      box-shadow: inset 0 -1px 0 0 ${COLORS.shadow}88;

      &.hasValue {
        box-shadow: inset 0 -2px 0 0 ${COLORS.shadow};
      }
    `,
    chevron: {
      color: COLORS.nightRider
    },
    option: `
      background-color: ${COLORS.white};
      color: ${COLORS.nightRider};

      &:hover {
        color: ${COLORS.denimBlue};
        background-color: ${COLORS.aliceBlueVariant};
      }
    `,
    options: `
      &::-webkit-scrollbar {
        background-color: ${COLORS.aliceBlueVariant};
      }

      &::-webkit-scrollbar-thumb {
        background: ${COLORS.shadow};
      }
    `,
    checkColor: COLORS.denimBlue,
    tagBackgroundColor: COLORS.white
  },
  multiSelectDropdownUserList: {
    wrapper: `
      background: transparent;
      width: 35%;
    `,
    header: `
      background: transparent;
      color: ${COLORS.nightRider};
      box-shadow: inset 0 -1px 0 0 ${COLORS.shadow}88;

      &.hasValue {
        box-shadow: inset 0 -2px 0 0 ${COLORS.shadow};
      }
    `,
    chevron: {
      color: COLORS.nightRider
    },
    option: `
      background-color: ${COLORS.denimBlue};
      color: ${COLORS.white};
      &:hover {
        color: ${COLORS.denimBlue};
        background-color: ${COLORS.aliceBlueVariant};
      }
    `,
    options: `
      max-height: 12rem;
      &::-webkit-scrollbar {
        background-color: ${COLORS.aliceBlueVariant};
      }

      &::-webkit-scrollbar-thumb {
        background: ${COLORS.shadow};
      }
    `,
    checkColor: COLORS.white,
    tagBackgroundColor: COLORS.white
  },
  multipleInput: {
    wrapper: `
      box-shadow: inset 0 -1px 0 0 ${COLORS.shadow}88;

      &.hasValue {
        box-shadow: inset 0 -2px 0 0 ${COLORS.shadow};
      }

      &.hasError {
        box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed};
      }
    `,
    input: `
      color: ${COLORS.nightRider};

      &::placeholder {
        color: ${COLORS.nightRider};
      }
    `,
    label: `color: ${COLORS.nightRider};`,
    tagBackgroundColor: COLORS.white,
    plusIconColor: COLORS.nightRider
  }
};

export const PaginationWrapper = styled.div`
  width: 100%;
  display: block;
  height: 31px;
  box-shadow: 0 0 9px 0 rgb(61 73 86 / 25%);
  z-index: 9999;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${props => (props.show ? 'block' : 'none')};
  background-color: rgba(0, 0, 0, 0.03);
  -webkit-backdrop-filter: blur(${pxToRem('2px')});
  backdrop-filter: blur(${pxToRem('2px')});
  &:before {
    content: '';
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.03);
  }
`;
