import React from 'react';
import { OutlinedInput, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// To force the menu to render BELOW the select box,
// add the following code as a prop to the Select during implementation:
// MenuProps={{
//   anchorOrigin: {
//     vertical: 'bottom',
//     horizontal: 'left'
//   },
//   transformOrigin: {
//     vertical: 'top',
//     horizontal: 'left'
//   },
//   getContentAnchorEl: null
// }}
// Does not work if the list is huge... in that case add PaperProps with max height:
// MenuProps={{
//   anchorOrigin: {
//     vertical: 'bottom',
//     horizontal: 'left'
//   },
//   transformOrigin: {
//     vertical: 'top',
//     horizontal: 'left'
//   },
//   getContentAnchorEl: null,
//   PaperProps: {
//     style: {
//       maxHeight: 400
//     }
//   }
// }}

const styles = () => ({
  slim: {
    paddingBottom: '10px',
    paddingTop: '10px',
    backgroundColor: '#ffff'
  },
  slimLabel: {
    top: '-6px'
  },
  slimLabelShrink: {
    transform: 'translate(0px, -12px) scale(0.75)!important'
  }
});

const MaterialSelect = ({
  setSelectOpen,
  isMinGrade = false,
  selectOpen = false,
  isRoleField = false,
  fieldIndex = null,
  position = null,
  classes,
  value,
  onChange,
  style = {
    width: '100%',
    marginTop: '30px'
  },
  required = false,
  disabled = false,
  label = false,
  name = '',
  options = [{ value: 'test', label: 'test' }],
  ...rest
}) => (
  <FormControl style={style} variant="outlined" required={required}>
    <InputLabel
      htmlFor={name}
      classes={{
        root: classes.slimLabel,
        shrink: classes.slimLabelShrink
      }}
    >
      {label}
    </InputLabel>
    <Select
      {...rest}
      value={value}
      disabled={disabled}
      onOpen={() => isMinGrade && setSelectOpen(fieldIndex)}
      onClose={() => isMinGrade && setSelectOpen(null)}
      onChange={e => {
        if (isRoleField) {
          setSelectOpen(`reset-${position}`);
        }
        return onChange(e.target.value);
      }}
      inputProps={{
        classes: {
          select: classes.slim
        }
      }}
      input={<OutlinedInput name={name} id={name} labelWidth={0} />}
    >
      {options.map(option => (
        <MenuItem
          key={isMinGrade ? option.abbr : option.value}
          value={isMinGrade ? option.abbr : option.value}
        >
          {isMinGrade && selectOpen === fieldIndex
            ? option.label
            : isMinGrade
            ? option.abbr
            : option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default withStyles(styles)(MaterialSelect);
