import { compose, pure, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { getAssessmentSettings, getGameUploadType } from '../../../../core/Events/selectors';
import {
  updateAssessmentsSettings as updateAssessmentsSettingsAction,
  fetchEventInfoAssessmentSettings
} from '../../../../core/Events';
import { addNotifications as addNotificationsAction } from '../../../../core/Notifications';

const mapDispatchToProps = dispatch => ({
  onLoad: eventId => dispatch(fetchEventInfoAssessmentSettings(eventId)),
  updateAssessmentsSettings: payload => dispatch(updateAssessmentsSettingsAction(payload)),
  addNotifications: payload => dispatch(addNotificationsAction(payload))
});

export default compose(
  connect(
    state => ({
      assessment_settings: {
        ...getAssessmentSettings(state),
        game_upload_type: getGameUploadType(state)
      },
      isFetching: state.events.eventPageFetching
    }),
    mapDispatchToProps
  ),
  withHandlers({
    onSubmit: ({ updateAssessmentsSettings }) => values => {
      updateAssessmentsSettings(values);
      return undefined;
    }
  }),
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { eventId }
        },
        onLoad
      } = this.props;
      onLoad(eventId);
    }
  }),
  pure
);
