import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { always } from 'ramda';
import moment from 'moment';
import { compose } from 'recompose';
import {
  PROCESS_ASSIGNMENT,
  CREATE_BATCH,
  DELETE_BATCH,
  GET_BATCH_GAMES,
  STORE_PAYMENTS_EVENT,
  FETCH_GAME_ASSIGNMENT,
  FETCH_PAYMENTS_EVENT_CREW_LABELS,
  CREATE_PRIMARY_ASSIGNOR_ON_GAME,
  UPDATE_PRIMARY_ASSIGNOR_ON_GAME,
  DELETE_PRIMARY_ASSIGNOR_ON_GAME,
  DELETE_ALL_PRIMARY_ASSIGNORS,
  CREATE_ALL_PRIMARY_ASSIGNORS,
  UPDATE_ALL_PRIMARY_ASSIGNORS,
  UPDATE_FULL_GAME_ASSIGNMENTS,
  CONFIRM_BATCH,
  GET_REVIEW_BATCH_INFO,
  FETCH_WALLET,
  setWalletIsFetching,
  setPaymentsEventHasBatch,
  setBatchGames,
  setGameAssignmentIsFetching,
  setGameAssignment,
  setAssignmentBasePay,
  setAssignmentAssignorPay,
  setPaymentsEventCrewLabels,
  setBatchGamesIsFetching,
  updateAssignorInState,
  removeAssignorInState,
  updateAllPrimaryAssignorsInState,
  setAssignorFetching,
  storeBatchStagingSummary,
  setFullGameAssignments,
  FETCH_BATCH_MODAL_INFO,
  storeBatchModalInfo,
  FETCH_MONTH_SUMMARY_DATA,
  setMonthTilesDataFetching,
  storeMonthTilesData,
  setEventBatches,
  setPaymentsFetching,
  FETCH_FUNDING_BATCHES,
  setBatchInfo,
  GET_AVAILABLE_BALANCE,
  setAvailableBalance,
  setBatchDetails,
  GET_PAYMENT_HISTORY,
  setPaymentHistory,
  GET_BATCH_PAY_GAMES_INFO,
  setBatchPayGamesInfo,
  ADD_PAYMENT_SOURCE,
  PROCESS_BATCH_FUND,
  REQUEST_PAYOUT,
  FETCH_PAYMENT_TOKEN,
  setClientSecret,
  setProcessingPayment,
  setBatchPaymentSuccess,
  setBatchPaymentError,
  setError,
  SET_BATCH_PAYMENT_ERROR,
  SET_BATCH_PAYMENT_SUCCESS,
  setPaymentBatchDetails,
  DELETE_PAYMENT_SOURCE,
  FETCH_FUND_SUMMARY,
  setFundSummary,
  setBatchFailedHistory,
  FETCH_BATCH_FAILED_HISTORY,
  FETCH_BATCH_PAYMENT_FEE,
  setBatchPaymentFee,
  FETCH_PAYOUT_FEE,
  setPayoutFee,
  setPaymentSupported,
  DELETE_BATCH_PAY,
  FETCH_AGREEMENT,
  setAgreement,
  FETCH_PAYMENT_METHODS,
  setPaymentMethods,
  setPaymentMethod,
  removePaymentMethod,
  setSelectedPaymentsEvent,
  setPaymentsEventCategories,
  GET_INVOICED_FEES_REPORT,
  GET_NON_INVOICED_FEES_REPORT,
  setPaymentsView,
  FETCH_PAYMENT_METHOD_SECRET,
  setPaymentMethodSecretFetching,
  setPaymentMethodSecret,
  setPaymentMethodConfirming,
  CONFIRM_PAYMENT_METHOD,
  setAgreementFetching,
  setWallet,
  FUND_WALLET,
  FETCH_TRANSACTION_HISTORY,
  setTransactionHistoryFetching,
  setTransactionHistory,
  GET_PLATFORM_ITEMIZED_TRANSACTION_DETAILS,
  BULK_UPDATE_FULL_GAME_ASSIGNMENTS
} from './index';
import {
  getPaymentsEventId,
  getPaymentsActiveBatch,
  getPaymentsGamesLimit,
  getPaymentsGames,
  getEventBatcheLimit
} from './selectors';
import { persistPaymentsEvent, userIdxGetter } from '../../utils/auth';
import { errorProgressDecorate } from '../Progress/helper';
import { history } from '../store';
import * as paths from '../paths';
import Api from '../../utils/api-wrapper';
import {
  formatGameFilterEndDate,
  formatGameFilterStartDate,
  sInterpolator as routeBuilder,
  sInterpolator
} from '../../utils/helpers';
import { dataWithPagination, mergeData, simpleGroupBy } from '../../utils/parsers';
import { addNotifications } from '../Notifications';
import { showProgress, hideProgress } from '../Progress';
import Logger from '../../utils/logger';
import {
  allEventsId,
  PAYMENTS_VIEW,
  PAYOUT_STATUSES,
  TRANSFER_STATUSES,
  TRANSFER_UI_STATUSES
} from '../../constants';
import { getPayeeId, getPayerId, selectUserId } from '../Auth/selectors';
import { fetchEventUniqueTeams } from '../Teams';
import { fetchEventComplexs } from '../Locations';
import { SET_ACTIVE_USER_INFO } from '../User';

const DEFAULT_SORT = 'date_location_time';

export function* fetchBatchStagingSummary() {
  try {
    const batch = yield select(getPaymentsActiveBatch);
    const { id } = batch;
    const response = yield call(Api.fetchBatchStagingSummary, { id });
    yield put(storeBatchStagingSummary(mergeData(response)));
  } catch (err) {
    yield put(addNotifications(err));
  }
}

export function* createAdjustment({ id, data, onLoadPage = always(undefined) }) {
  try {
    yield put(showProgress());
    yield call(Api.createAdjustment, { id, data });
    yield put(addNotifications([{ type: 'success', message: 'Adjustment created successfully' }]));
    yield call(onLoadPage);
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(hideProgress());
  }
}

export function* updateAdjustment({ id, data, onLoadPage = always(undefined) }) {
  try {
    yield put(showProgress());
    yield call(Api.updateAdjustment, { id, data });
    yield put(addNotifications([{ type: 'success', message: 'Adjustment updated successfully' }]));
    yield call(onLoadPage);
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(hideProgress());
  }
}

export function* updateAssignmentInBatch({
  gameAssignmentId,
  in_batch,
  onLoadPage = always(undefined)
}) {
  try {
    yield put(showProgress());
    yield call(Api.updateAssignmentInBatch, { id: gameAssignmentId, data: { in_batch } });
    yield put(addNotifications([{ type: 'success', message: 'Assignment updated successfully' }]));
    yield call(onLoadPage);
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(hideProgress());
  }
}

export function* processAssignment({
  payload: {
    adjustmentData,
    gameAssignmentId,
    in_batch,
    shouldUpdateAdjustment,
    shouldUpdateBatch,
    onLoadPage,
    base_pay,
    assignor_pay
  }
}) {
  if (shouldUpdateBatch) {
    yield call(updateAssignmentInBatch, {
      gameAssignmentId,
      in_batch,
      onLoadPage
    });
  }
  if (shouldUpdateAdjustment) {
    const { isNew, adjustmentId: id, ...data } = adjustmentData;
    const args = { id, data, onLoadPage };
    if (isNew) {
      yield call(createAdjustment, args);
      const response = yield call(Api.fetchGameAssignment, { id });
      if (response) {
        yield put(setGameAssignment({ ...mergeData(response), batch_pay_staging: { in_batch } }));
        yield put(setAssignmentBasePay(base_pay));
        yield put(setAssignmentAssignorPay(assignor_pay));
      }
    } else {
      yield call(updateAdjustment, args);
    }
  }
  yield call(fetchBatchStagingSummary);
}

export function* getEventBatch(eventId) {
  try {
    if (eventId !== -1) {
      const response = yield call(Api.fetchBatch, { id: eventId });
      yield put(setPaymentsEventHasBatch(mergeData(response)));
    }
  } catch (err) {
    yield put(addNotifications(err));
  }
}

export function* fetchBatchGames({
  payload: { filters, page, sort, reviewMode, batchId: confirmedBatchId }
}) {
  /* eslint-disable no-param-reassign */
  if (filters && filters['game.start_date']) {
    filters['game.start_date'] = formatGameFilterStartDate(filters['game.start_date']);
  }
  if (filters && filters['game.end_date']) {
    filters['game.end_date'] = formatGameFilterEndDate(filters['game.end_date']);
  }
  /* eslint-enable  no-param-reassign */

  const activeBatch = yield select(getPaymentsActiveBatch);
  const limit = yield select(getPaymentsGamesLimit);
  if (!activeBatch.id && !confirmedBatchId) return;
  try {
    yield put(setBatchGamesIsFetching(true));
    const eventId = yield select(getPaymentsEventId);
    yield put(fetchEventUniqueTeams({ eventId }));
    yield put(fetchEventComplexs({ eventId }));
    const response = yield call(Api.fetchBatchGames, {
      id: reviewMode ? confirmedBatchId : activeBatch.id,
      filters,
      page,
      sort: sort || DEFAULT_SORT,
      limit,
      reviewMode
    });
    yield put(setBatchGames(dataWithPagination(response)));
    if (!reviewMode) yield call(fetchBatchStagingSummary);
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(setBatchGamesIsFetching(false));
  }
}

export function* fetchBatchPayGamesInfo({
  payload: {
    filters = {},
    sort,
    resolve = () => true,
    toCSV,
    batchId: confirmedBatchId,
    reviewMode
  }
}) {
  const activeBatch = yield select(getPaymentsActiveBatch);
  if (!activeBatch.id && !confirmedBatchId) return;
  try {
    const response = yield call(Api.fetchBatchPayGamesInfo, {
      id: reviewMode ? confirmedBatchId : activeBatch.id,
      filters,
      toCSV,
      reviewMode,
      sort
    });
    yield put(setBatchPayGamesInfo(response));
    yield call(resolve, response);
  } catch (err) {
    yield put(addNotifications(err));
  }
}

export function* createBatch({
  payload: {
    id,
    data: { start_date, end_date }
  }
}) {
  const data = {
    start_date: moment(start_date).startOf('day').format(),
    end_date: moment(end_date).endOf('day').format()
  };

  try {
    yield put(showProgress());
    yield call(Api.createBatch, { id, data });
    yield put(addNotifications([{ type: 'success', message: 'Batch created' }]));
    yield call(getEventBatch, id);
    const toUrl = routeBuilder(
      { userIdx: userIdxGetter(), eventId: id },
      paths.PAYMENTS_BATCH_EDIT
    );
    yield call(history.push, toUrl);
  } catch (err) {
    if (err.message && Array.isArray(err.message)) {
      yield put(addNotifications(err.message));
    } else {
      yield put(addNotifications(err));
    }
  } finally {
    yield put(hideProgress());
  }
}

export function* deleteBatch({ payload: { batchId, eventId, redirect } }) {
  try {
    yield put(showProgress());
    yield call(Api.deleteBatch, { id: batchId });
    yield put(addNotifications([{ type: 'success', message: 'Batch deleted' }]));
    if (redirect) {
      history.push(sInterpolator({ userIdx: userIdxGetter() }, paths.PAYMENTS));
    }
    yield call(getEventBatch, eventId);
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(hideProgress());
  }
}

export function* deleteBatchPay({ payload: { batchId, redirect } }) {
  try {
    yield put(showProgress());
    yield call(Api.deleteBatchPay, { id: batchId });
    yield put(addNotifications([{ type: 'success', message: 'Batch deleted' }]));
    if (redirect) {
      history.push(sInterpolator({ userIdx: userIdxGetter() }, paths.PAYMENTS));
    }
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(hideProgress());
  }
}

export function* deleteBatchPayStaging({ payload: { batchId } }) {
  try {
    yield put(showProgress());
    yield call(Api.deleteBatchPay, { id: batchId });
    yield put(addNotifications([{ type: 'success', message: 'Batch deleted' }]));
    history.push(sInterpolator({ userIdx: userIdxGetter() }, paths.PAYMENTS));
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(hideProgress());
  }
}

export function* storePaymentsEvent({ payload: event }) {
  try {
    if (!event.id) {
      return;
    }
    const { usrIdx = userIdxGetter() } = event;
    yield call(persistPaymentsEvent, { event, usrIdx });
    yield put(setSelectedPaymentsEvent(event));
    yield call(getEventBatch, event.id);
  } catch (err) {
    yield put(addNotifications(err));
  }
}

export function* processFetchFundingBatches({
  payload = { resolve: () => null, toCSV: false, startDate: null, endDate: null }
}) {
  const { resolve, toCSV, startDate, endDate, page } = payload;
  const limit = yield select(getEventBatcheLimit);
  const eventId = yield select(getPaymentsEventId);
  const response = yield call(Api.fetchEventBatches, {
    id: eventId,
    startDate,
    endDate,
    toCSV,
    page,
    limit,
    sort: '-created_at'
  });
  if (!toCSV) yield put(setEventBatches({ data: mergeData(response), meta: response.meta }));
  else resolve(response);
}

export function* fetchFundingBatches(payload = {}) {
  yield call(errorProgressDecorate, processFetchFundingBatches, payload, setPaymentsFetching);
}

export function* fetchAssignment({
  payload: {
    id,
    basePay: { base_pay },
    assignorPay,
    batch_pay_staging
  }
}) {
  try {
    yield put(setGameAssignmentIsFetching(true));
    const response = yield call(Api.fetchGameAssignment, { id });
    if (response) {
      yield put(setGameAssignment({ ...mergeData(response), batch_pay_staging }));
      yield put(setAssignmentBasePay(base_pay));
      yield put(setAssignmentAssignorPay(assignorPay));
    }
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(setGameAssignmentIsFetching(false));
  }
}

export function* processFetchPaymentsEventCrewLabels() {
  const paymentsEventId = yield select(getPaymentsEventId);
  if (paymentsEventId) {
    const response = yield call(Api.fetchEventGameLevels, { id: paymentsEventId });
    yield put(setPaymentsEventCrewLabels(mergeData(response)));

    const categories = yield call(Api.getEventCategories, { id: paymentsEventId });
    yield put(setPaymentsEventCategories(mergeData(categories)));
  }
}

export function* fetchPaymentsEventCrewLabels() {
  yield call(errorProgressDecorate, processFetchPaymentsEventCrewLabels);
}

export function* processCreatePrimaryAssignor({ payload: { assignorObject, uniqueGameId } }) {
  const response = yield call(Api.createGameAssignor, { id: uniqueGameId, data: assignorObject });
  yield put(updateAssignorInState(mergeData(response)));
  yield call(fetchBatchStagingSummary);
}

export function* createPrimaryAssignor(params) {
  yield call(errorProgressDecorate, processCreatePrimaryAssignor, params, setAssignorFetching);
}
export function* processDeletePrimaryAssignor({ payload: { id, uniqueId } }) {
  yield call(Api.deleteGameAssignor, { id });
  yield put(removeAssignorInState({ external_game_id: uniqueId }));
  yield call(fetchBatchStagingSummary);
}

export function* deletePrimaryAssignor(params) {
  yield call(errorProgressDecorate, processDeletePrimaryAssignor, params, setAssignorFetching);
}

export function* processUpdatePrimaryAssignor({ payload: { assignorObject, id } }) {
  const response = yield call(Api.updateGameAssignor, { id, data: assignorObject });
  yield put(updateAssignorInState(mergeData(response)));
  yield call(fetchBatchStagingSummary);
}

export function* updatePrimaryAssignor(params) {
  yield call(errorProgressDecorate, processUpdatePrimaryAssignor, params, setAssignorFetching);
}

export function* processDeleteAllPrimaryAssignors({ payload: { ids } }) {
  const eventId = yield select(getPaymentsEventId);
  yield call(Api.bulkDeleteGameAssignors, { id: eventId, ids });
  yield call(fetchBatchStagingSummary);
}

export function* deleteAllPrimaryAssignors(params) {
  yield call(errorProgressDecorate, processDeleteAllPrimaryAssignors, params, setAssignorFetching);
}

export function* processCreateAllPrimaryAssignors({ payload: { allGames, assignor } }) {
  const eventId = yield select(getPaymentsEventId);
  const payload = allGames.map(game => ({
    external_game_id: game.id,
    event_role_id: assignor.id,
    external_event_id: game.display_id
  }));
  const response = yield call(Api.bulkCreateGameAssignors, { id: eventId, data: payload });
  yield put(updateAllPrimaryAssignorsInState(mergeData(response)));
  yield call(fetchBatchStagingSummary);
}

export function* createAllPrimaryAssignors(params) {
  yield call(errorProgressDecorate, processCreateAllPrimaryAssignors, params, setAssignorFetching);
}

export function* processUpdateAllPrimaryAssignors({ payload: { allGames, assignor } }) {
  const eventId = yield select(getPaymentsEventId);
  const payload = allGames.map(game => ({
    id: game.assignment_primary_game.id,
    external_game_id: game.id,
    event_role_id: assignor.id,
    external_event_id: game.display_id
  }));
  const response = yield call(Api.bulkUpdateGameAssignors, { id: eventId, data: payload });
  yield put(updateAllPrimaryAssignorsInState(mergeData(response)));
}

export function* updateAllPrimaryAssignors(params) {
  yield call(errorProgressDecorate, processUpdateAllPrimaryAssignors, params, setAssignorFetching);
}

export function* deleteAssociatedAssignor(id) {
  const uniqueGameId = id;
  const games = yield select(getPaymentsGames);
  const { assignment_primary_game: currentGameAssignor } =
    games.find(game => game.id === uniqueGameId) || {};
  if (currentGameAssignor) {
    const { id: primaryAssignmentId } = currentGameAssignor;
    yield call(processDeletePrimaryAssignor, {
      payload: { id: primaryAssignmentId, uniqueId: uniqueGameId }
    });
  }
}

export function* processUpdateFullGame({ payload: { id, in_batch, is_assignor_batched } }) {
  const response = yield call(Api.updateFullGameAssignmentsInBatch, { id, data: { in_batch } });
  yield put(setFullGameAssignments({ assignments: mergeData(response), id }));
  if (!in_batch && !is_assignor_batched) {
    yield call(deleteAssociatedAssignor, id);
  }
  yield call(fetchBatchStagingSummary);
}

export function* updateFullGame(params) {
  yield call(errorProgressDecorate, processUpdateFullGame, params, setAssignorFetching);
}

export function* bulkUpdateFullGamesAssignmentsAPI(params) {
  const { payload: data } = params;
  yield put(setBatchGamesIsFetching(true));

  const response = yield call(Api.bulkUpdateGameAssignmentsInBatch, { data });
  const merged = mergeData(response);
  const mergedByGame = simpleGroupBy(
    merged,
    item => item && item.game_assignment && item.game_assignment.external_game_id
  );

  const game_ids = Object.keys(mergedByGame);
  // eslint-disable-next-line no-restricted-syntax
  for (const game_id of game_ids) {
    const message = { assignments: mergedByGame[game_id], id: game_id };
    yield put(setFullGameAssignments(message));
  }

  yield call(fetchBatchStagingSummary);

  yield put(setBatchGamesIsFetching(false));
}

export function* fetchBatchModalInfo() {
  try {
    yield put(showProgress());
    const batch = yield select(getPaymentsActiveBatch);
    const { id } = batch;
    const response = yield call(Api.fetchBatchStagingSummaryDetails, { id });
    yield put(storeBatchModalInfo(response.data.attributes.payments));
  } catch (err) {
    yield put(addNotifications(err));
  } finally {
    yield put(hideProgress());
  }
}

export function* processConfirmBatch({ payload: { id, data } }) {
  yield call(Api.confirmBatch, { id, data });
  yield call(getEventBatch, id);
  const toUrl = routeBuilder({ userIdx: userIdxGetter() }, paths.PAYMENTS);
  yield call(history.push, toUrl);
  yield put(setPaymentsView(PAYMENTS_VIEW.FUNDING));
}

export function* confirmBatch(params) {
  yield call(errorProgressDecorate, processConfirmBatch, params, setPaymentsFetching);
}

export function* fetchMonthSummaryData() {
  try {
    yield put(setMonthTilesDataFetching(true));
    const paymentsEventId = yield select(getPaymentsEventId);
    if (paymentsEventId !== allEventsId) {
      const response = yield call(Api.fetchMonthSummaryData, { id: paymentsEventId });
      yield put(storeMonthTilesData(mergeData(response)));
    } else {
      yield put(storeMonthTilesData([]));
    }
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setMonthTilesDataFetching(false));
  }
}

export function* processFetchBatchInfo({ payload: { id } }) {
  const response = yield call(Api.fetchBatchInfo, { id });
  yield put(setBatchInfo(mergeData(response)));
}

export function* fetchBatchInfo(params) {
  yield call(errorProgressDecorate, processFetchBatchInfo, params);
}

export function* fetchAvailableBalance({ payload }) {
  try {
    const { data } = yield call(Api.getBatchPay, { payeeId: payload.payeeId });
    const accountBalance = {
      available: data.reduce(
        (acc, batch) =>
          batch.attributes.status === TRANSFER_STATUSES.AVAILABLE
            ? acc + batch.attributes.amount
            : acc,
        0
      ),
      pending: data.reduce(
        (acc, batch) =>
          batch.attributes.status === TRANSFER_STATUSES.PENDING
            ? acc + batch.attributes.amount
            : acc,
        0
      )
    };
    const formattedBalances = data.map(({ attributes }) => {
      const fees = 0;
      return {
        batchId: attributes.batch_pay_id,
        date:
          attributes &&
          attributes.batch_pay.funded_at &&
          moment.utc(attributes.batch_pay.funded_at).local().format('MM/DD/YY'),
        status: TRANSFER_UI_STATUSES[attributes.status] || 'Pending',
        eventName:
          attributes.batch_pay && attributes.batch_pay.event && attributes.batch_pay.event.name,
        paymentPeriod: `${
          attributes &&
          attributes.batch_pay &&
          attributes.batch_pay.start_date &&
          moment.utc(attributes.batch_pay.start_date).local().format('MM/DD/YY')
        } - ${
          attributes &&
          attributes.batch_pay &&
          attributes.batch_pay.end_date &&
          moment.utc(attributes.batch_pay.end_date).local().format('MM/DD/YY')
        }`,
        billingName:
          attributes.batch_pay &&
          attributes.batch_pay.event &&
          attributes.batch_pay.event.platform &&
          attributes.batch_pay.event.platform.name,
        amount: attributes.amount,
        total: attributes.amount - (fees || 0)
      }; // TODO: once fees are available, refactor to correctly show fees and calculate total.
    });

    yield put(setBatchDetails(formattedBalances));
    yield put(setAvailableBalance(accountBalance));
  } catch (err) {
    yield put(addNotifications(err.message));
    Logger.dbLog(err, { payload });
  } finally {
    yield put(setPaymentsFetching(false));
  }
}

export function* requestPayout({ payload }) {
  try {
    const { available, payeeId, userId } = payload;
    const data = yield call(Api.requestPayout, { data: { amount: available, payeeId } });
    if (data.payment) {
      yield put(addNotifications([{ type: 'success', message: data.payment }]));
    }
    yield call(fetchAvailableBalance, {
      payload: {
        userId,
        payeeId
      }
    });
  } catch (err) {
    yield put(addNotifications(err.message));
    Logger.dbLog(err, { payload });
  } finally {
    yield put(setPaymentsFetching(false));
  }
}

export function* fetchPaymentHistory(payload) {
  try {
    yield put(setPaymentsFetching(true));
    const { month, year } = payload;
    const payeeId = yield select(getPayeeId);

    const data = yield call(Api.getPaymentHistory, { payeeId, month, year });

    const paymentHistory = data.map(payment => ({
      requestDate: moment.utc(payment.created_at).local().format('MM/DD/YYYY'),
      depositDate: moment.utc(payment.created_at).local().format('MM/DD/YYYY'),
      status: PAYOUT_STATUSES[payment.status],
      fees: payment.fee || 0,
      total: payment.amount
    }));

    const paymentBatchDetails = data.map(payments =>
      payments.payout_histories.map(paymentHist => ({
        batchId: paymentHist.batch_pay_transfer.batch_pay.id,
        eventName:
          paymentHist.batch_pay_transfer.batch_pay &&
          paymentHist.batch_pay_transfer.batch_pay.event &&
          paymentHist.batch_pay_transfer.batch_pay.event.name,
        period: `${
          paymentHist.batch_pay_transfer.batch_pay &&
          paymentHist.batch_pay_transfer.batch_pay.start_date &&
          moment.utc(paymentHist.batch_pay_transfer.batch_pay.start_date).local().format('MM/DD/YY')
        } - ${
          paymentHist.batch_pay_transfer.batch_pay &&
          paymentHist.batch_pay_transfer.batch_pay.end_date &&
          moment.utc(paymentHist.batch_pay_transfer.batch_pay.end_date).local().format('MM/DD/YY')
        }`,
        batchTotal: paymentHist.batch_pay_transfer.amount
      }))
    );
    yield put(setPaymentHistory(paymentHistory));
    yield put(setPaymentBatchDetails(paymentBatchDetails));
  } catch (err) {
    yield put(addNotifications(err.message));
    yield put(setPaymentsFetching(false));
    Logger.dbLog(err, { payload });
  } finally {
    yield put(setPaymentsFetching(false));
  }
}

export function* addPaymentSource({ payload }) {
  try {
    yield put(setPaymentsFetching(true));
    const { data } = yield call(Api.addPaymentSource, payload);
    yield put(
      setPaymentMethod({
        id: Number(data.id),
        method: data.attributes.method,
        details: data.attributes.details,
        verified: data.attributes.verified,
        account_name: data.attributes.account_name
      })
    );
    yield put(setPaymentsFetching(false));
    yield put(addNotifications([{ type: 'success', message: 'Payment Source Added' }]));
  } catch (e) {
    yield put(addNotifications(e.message));
    yield put(setError('payment_source', 'add'));
    yield put(setPaymentsFetching(false));
    Logger.dbLog(e, { payload });
  }
}

export function* processBatchFund({ payload }) {
  try {
    yield put(setProcessingPayment());
    yield call(Api.processBatchFund, payload);
    yield put(setBatchPaymentSuccess({ id: payload.batchId }));
    yield call(fetchFundingBatches);
    yield put(addNotifications([{ type: 'success', message: 'Batch Funded' }]));
  } catch (e) {
    yield put(addNotifications(e.message));
    yield call(fetchFundingBatches);
    yield put(setBatchPaymentError({ id: payload.batchId, err: e }));
    yield put(setPaymentsFetching(false));
    Logger.dbLog(e, { payload });
  }
}

export function* fetchPaymentToken({ payload }) {
  try {
    const { batchId } = payload;
    const { client_secret } = yield call(Api.fetchPaymentToken, batchId);
    yield put(
      setClientSecret({
        batchId,
        clientSecret: client_secret
      })
    );
  } catch (e) {
    // Todo: error handling
    Logger.dbLog(e, { payload });
  }
}

export function* deletePaymentSource({ payload }) {
  try {
    yield put(setPaymentsFetching(true));
    yield call(Api.deletePaymentSource, payload);
    yield put(
      addNotifications([{ type: 'success', message: 'Payment Method deleted successfully' }])
    );
    yield put(
      removePaymentMethod({
        id: payload.id
      })
    );
    yield put(setPaymentsFetching(false));
  } catch (err) {
    yield put(addNotifications(err.message));
    Logger.dbLog(err, { payload });
  } finally {
    yield put(setPaymentsFetching(false));
  }
}
export function* fetchFundSummaryDetails({ payload }) {
  try {
    const { batchId } = payload;
    yield put(setPaymentsFetching(true));
    const response = yield call(Api.fetchFundSummary, batchId);
    if (response) {
      yield put(setFundSummary(response));
    }
    yield put(setPaymentsFetching(false));
  } catch (e) {
    yield put(addNotifications(e.message));
    yield put(setPaymentsFetching(false));
    Logger.dbLog(e, { payload });
  }
}

export function* fetchBatchFailedHistory({ payload }) {
  try {
    const { batchId } = payload;
    yield put(setPaymentsFetching(true));
    const response = yield call(Api.fetchBatchFailedHistory, batchId);
    if (response) {
      yield put(setBatchFailedHistory(response));
    }
    yield put(setPaymentsFetching(false));
  } catch (e) {
    yield put(addNotifications(e.message));
    yield put(setBatchFailedHistory(null));
    yield put(setPaymentsFetching(false));
    Logger.dbLog(e, { payload });
  }
}

export function* fetchBatchPaymentFee({ payload }) {
  try {
    const { batchId, paymentSourceId } = payload;
    const payerId = yield select(getPayerId);

    yield put(setPaymentsFetching(true));
    let response = {};

    if (payerId) {
      response = yield call(Api.fetchBatchPaymentFee, { batchId, paymentSourceId });
    } else {
      yield put(addNotifications([{ type: 'error', message: 'Insufficient funds available' }]));
    }
    if (response) {
      yield put(setBatchPaymentFee(response));
      yield put(setPaymentSupported(true));
    }
    yield put(setPaymentsFetching(false));
  } catch (e) {
    yield put(setBatchPaymentFee(null));
    yield put(setPaymentSupported(false));
    yield put(addNotifications(e.message));
    yield put(setPaymentsFetching(false));
    Logger.dbLog(e, { payload });
  }
}

export function* fetchPayoutFee({ payload }) {
  try {
    yield put(setPaymentsFetching(true));
    const data = yield call(Api.fetchPayoutFee, payload);
    if (data) yield put(setPayoutFee(data));
    yield put(setPaymentsFetching(false));
  } catch (err) {
    yield put(addNotifications(err.message));
    yield put(setPaymentsFetching(false));
    Logger.dbLog(err, { payload });
  }
}

export function* fetchAgreement({ payload }) {
  try {
    yield put(setAgreementFetching(payload, true));
    const data = yield call(Api.fetchAgreement, payload);
    if (data) yield put(setAgreement(mergeData(data)));
    yield put(setAgreementFetching(payload, false));
  } catch (err) {
    yield put(setAgreementFetching(payload, false));
    yield put(addNotifications(err.message));
  }
}

export function* fetchPaymentMethods(payload) {
  try {
    const payerId = yield select(getPayerId);
    yield put(setPaymentsFetching(true));
    let data = [];
    if (payerId) {
      data = yield call(Api.fetchPaymentMethods, { ...payload, payerId });
    }
    if (data) yield put(setPaymentMethods(mergeData(data)));
    yield put(setPaymentsFetching(false));
  } catch (err) {
    yield put(setPaymentsFetching(false));
    yield put(addNotifications(err.message));
  }
}

export function* fetchInvoicedFeesReport({
  payload: { resolve = () => true, reject = () => true }
}) {
  try {
    const response = yield call(Api.fetchInvoiceReport);
    if (response) {
      yield call(resolve, response);
    }
  } catch (err) {
    yield call(reject, err);
    yield put(addNotifications(err.message));
  }
}

export function* fetchNonInvoicedFeesReport({
  payload: { resolve = () => true, reject = () => true }
}) {
  try {
    const response = yield call(Api.fetchBatchPayReport);
    if (response) {
      yield call(resolve, response);
    }
  } catch (err) {
    yield call(reject, err);
    yield put(addNotifications(err.message));
  }
}

export function* fetchPlatformTransactionReport({
  payload = { resolve: () => true, reject: () => true, startDate: null, endDate: null }
}) {
  const { resolve, reject, startDate, endDate } = payload;

  try {
    const response = yield call(Api.fetchPlatformTransactionReport, { startDate, endDate });
    if (response) {
      yield call(resolve, response);
    }
  } catch (err) {
    yield call(reject, err);
    yield put(addNotifications(err.message));
  }
}

export function* processFetchPaymentMethodSecret({ payload: { payerId, method } }) {
  const response = yield call(Api.fetchPaymentMethodSecret, { payerId, method });
  if (response) {
    yield put(setPaymentMethodSecret(response.token));
  }
  const payer_id = yield select(getPayerId);
  if (!payer_id) {
    const userId = yield select(selectUserId);
    const {
      data: { attributes: attr, id: userid }
    } = yield call(Api.fetchActiveUserInfo, {
      userId
    });
    yield put({ type: SET_ACTIVE_USER_INFO, payload: { ...attr, id: userid } });
  }
}

export function* fetchPaymentMethodSecret(params) {
  yield call(
    errorProgressDecorate,
    processFetchPaymentMethodSecret,
    params,
    setPaymentMethodSecretFetching
  );
}

export function* confirmPaymentMethod({
  payload: {
    method,
    payerId,
    requestId,
    paymentMethodId,
    agreement,
    billingDetails,
    resolve = () => true
  }
}) {
  try {
    yield setPaymentMethodConfirming(true);
    const response = yield call(Api.confirmPaymentMethod, {
      method,
      payerId,
      data: { requestId, paymentMethodId, agreement, billingDetails }
    });
    yield put(setPaymentMethod(mergeData(response)));
    yield put(
      addNotifications([{ type: 'success', message: 'Payment Method added successfully' }])
    );
  } catch (e) {
    yield put(addNotifications(e.message));
  } finally {
    yield setPaymentMethodConfirming(true);
    yield call(resolve);
  }
}

export function* processFetchWallet() {
  const payerId = yield select(getPayerId);
  const response = yield call(Api.fetchWallet, { payerId });
  if (response.data) {
    yield put(compose(setWallet, mergeData)(response));
  }
}

export function* fetchWallet() {
  yield call(errorProgressDecorate, processFetchWallet, {}, setWalletIsFetching);
}

export function* addWalletFund({ payload }) {
  try {
    const payerId = yield select(getPayerId);
    const response = yield call(Api.createWalletFund, { payerId, data: payload });
    if (response.success) {
      yield put(addNotifications([{ type: 'success', message: 'Fund request initiated.' }]));
      yield call(fetchWallet);
    }
  } catch (e) {
    yield put(addNotifications(e.message));
  }
}

export function* processFetchTransactionHistory({ payload: { limit } }) {
  const payerId = yield select(getPayerId);
  if (payerId) {
    const response = yield call(Api.fetchTransactionHistory, { payerId, limit });
    if (response.data) {
      yield put(compose(setTransactionHistory, dataWithPagination)(response));
    }
  }
}

export function* fetchTransactionHistory(params) {
  yield call(
    errorProgressDecorate,
    processFetchTransactionHistory,
    params,
    setTransactionHistoryFetching
  );
}

export const paymentsSagas = [
  takeLatest(CREATE_BATCH, createBatch),
  takeLatest(DELETE_BATCH, deleteBatch),
  takeLatest(STORE_PAYMENTS_EVENT, storePaymentsEvent),
  takeLatest(GET_BATCH_GAMES, fetchBatchGames),
  takeLatest(FETCH_GAME_ASSIGNMENT, fetchAssignment),
  takeLatest(FETCH_PAYMENTS_EVENT_CREW_LABELS, fetchPaymentsEventCrewLabels),
  takeLatest(PROCESS_ASSIGNMENT, processAssignment),
  takeEvery(CREATE_PRIMARY_ASSIGNOR_ON_GAME, createPrimaryAssignor),
  takeEvery(UPDATE_PRIMARY_ASSIGNOR_ON_GAME, updatePrimaryAssignor),
  takeEvery(DELETE_PRIMARY_ASSIGNOR_ON_GAME, deletePrimaryAssignor),
  takeEvery(DELETE_ALL_PRIMARY_ASSIGNORS, deleteAllPrimaryAssignors),
  takeEvery(CREATE_ALL_PRIMARY_ASSIGNORS, createAllPrimaryAssignors),
  takeEvery(UPDATE_ALL_PRIMARY_ASSIGNORS, updateAllPrimaryAssignors),
  takeLatest(UPDATE_FULL_GAME_ASSIGNMENTS, updateFullGame),
  takeLatest(BULK_UPDATE_FULL_GAME_ASSIGNMENTS, bulkUpdateFullGamesAssignmentsAPI),
  takeEvery(FETCH_BATCH_MODAL_INFO, fetchBatchModalInfo),
  takeLatest(CONFIRM_BATCH, confirmBatch),
  takeEvery(FETCH_MONTH_SUMMARY_DATA, fetchMonthSummaryData),
  takeEvery(FETCH_FUNDING_BATCHES, fetchFundingBatches),
  takeLatest(GET_REVIEW_BATCH_INFO, fetchBatchInfo),
  takeLatest(GET_AVAILABLE_BALANCE, fetchAvailableBalance),
  takeLatest(REQUEST_PAYOUT, requestPayout),
  takeLatest(GET_PAYMENT_HISTORY, fetchPaymentHistory),
  takeLatest(GET_BATCH_PAY_GAMES_INFO, fetchBatchPayGamesInfo),
  takeLatest(ADD_PAYMENT_SOURCE, addPaymentSource),
  takeLatest(PROCESS_BATCH_FUND, processBatchFund),
  takeLatest(FETCH_PAYMENT_TOKEN, fetchPaymentToken),
  takeLatest(SET_BATCH_PAYMENT_ERROR, setBatchPaymentError),
  takeLatest(SET_BATCH_PAYMENT_SUCCESS, setBatchPaymentSuccess),
  takeLatest(DELETE_PAYMENT_SOURCE, deletePaymentSource),
  takeLatest(FETCH_FUND_SUMMARY, fetchFundSummaryDetails),
  takeLatest(FETCH_BATCH_FAILED_HISTORY, fetchBatchFailedHistory),
  takeLatest(FETCH_BATCH_PAYMENT_FEE, fetchBatchPaymentFee),
  takeLatest(FETCH_PAYOUT_FEE, fetchPayoutFee),
  takeLatest(DELETE_BATCH_PAY, deleteBatchPay),
  takeLatest(FETCH_AGREEMENT, fetchAgreement),
  takeLatest(FETCH_PAYMENT_METHODS, fetchPaymentMethods),
  takeLatest(GET_INVOICED_FEES_REPORT, fetchInvoicedFeesReport),
  takeLatest(GET_NON_INVOICED_FEES_REPORT, fetchNonInvoicedFeesReport),
  takeLatest(GET_PLATFORM_ITEMIZED_TRANSACTION_DETAILS, fetchPlatformTransactionReport),
  takeLatest(FETCH_PAYMENT_METHOD_SECRET, fetchPaymentMethodSecret),
  takeLatest(CONFIRM_PAYMENT_METHOD, confirmPaymentMethod),
  takeLatest(FETCH_WALLET, fetchWallet),
  takeLatest(FUND_WALLET, addWalletFund),
  takeLatest(FETCH_TRANSACTION_HISTORY, fetchTransactionHistory)
];

export default paymentsSagas;
