import React from 'react';
import { ModalContainer } from '../styled-components';
import AddBank from '../../../../AddBank';

const ACHAccount = props => {
  return (
    <ModalContainer>
      <AddBank {...props} />
    </ModalContainer>
  );
};

export default ACHAccount;
