import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

// import { COLORS } from '../../../../utils/styles';

export const Inline = styled.div`
  width: 2rem;
  overflow: hidden;
  padding-right: 0.5rem;
  display: inline-block;
  margin: 0 0.5rem;
`;

export const Spaced = Inline.extend`
  margin: 0 0.5rem 0 2rem;
`;

export const FillHeight = styled(Row)`
  margin-top: 1rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 8rem;
`;

export const ButtonContainer = styled.div`
  float: right;
  margin: 1em 2em;
  width: 40%;
`;

export const LabelText = styled.label`
  color: ${COLORS.shuttleGrey};
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

export const WrapperCrewPosition = styled.div`
  margin-left: ${pxToRem('-10px')};
`;

export const Note = styled.p`
  color: ${COLORS.shuttleGrey};
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 15px;
`;
