import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import { allowIgnoreRules } from '../../../../core/Notifications';
import { deleteGameAssignments as deleteGameAssignmentsAction } from '../../../../core/Games';

const mapStateToProps = state => ({
  ignoreRules: state.notifications.ignoreRules
});

const mapDispatchToProps = dispatch => ({
  allowIgnoreRules: input => dispatch(allowIgnoreRules(input)),
  deleteGameAssignments: payload => dispatch(deleteGameAssignmentsAction(payload))
});
export default compose(connect(mapStateToProps, mapDispatchToProps), pure);
