import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';
import OutsideClickHandler from 'react-outside-click-handler';

import Tags from '../../Tags';
import { COLORS, ICONS } from '../../../../utils/styles';
import {
  Wrapper,
  Label,
  Chevron,
  Header,
  Option,
  Options,
  MinorLabel,
  ErrorMessage,
  ErrorLabelWrapper,
  ErrorLabel
} from './styled-components';

/**
 * Built to integrate with React-Final-Form, basic single-select dropdown
 * @param {Array} options Array of option objects with name and value keys
 * @param {Object} input contains onChange and value attributes
 * @param {Object} theme *optional* for customizing styles
 * @param {String} placeholder  Label to display on dropdown
 * @param {Boolean} withTags *optional* adds removable tags underneath input
 * @param {Boolean} displayValue *optional* displays value in field and moves/shrinks label
 * @param {Boolean} tagsByName *optional* displays the option's name field instead of its value
 */
const Dropdown = ({
  toggleShowOptions,
  showOptions,
  options,
  input: { value, onChange },
  theme,
  placeholder: label,
  withTags,
  displayValue,
  disabled,
  tagsByName,
  setHeight,
  error,
  hasHeight,
  isUserAddAction
}) => {
  const filteredOption = value && options.find(option => option.value === value);
  const hasError = !value && error;
  return (
    <OutsideClickHandler onOutsideClick={() => showOptions && toggleShowOptions(false)}>
      <Wrapper theme={theme.wrapper} hasHeight={hasHeight}>
        <Header
          isUserAddAction={isUserAddAction}
          theme={theme.header}
          hasHeight={hasHeight}
          onClick={() => {
            if (disabled) {
              return toggleShowOptions(() => false);
            }
            return toggleShowOptions(s => !s);
          }}
          className={value ? 'hasValue' : ''}
          hasError={hasError}
        >
          {displayValue && value && filteredOption ? (
            <>
              <MinorLabel theme={theme.minorLabel}>{label}</MinorLabel>
              <Label theme={theme.value}>{filteredOption.name}</Label>
            </>
          ) : (
            <Label theme={theme.label}>{label}</Label>
          )}

          <Chevron
            width={10}
            height={16}
            color={(theme.chevron && theme.chevron.color) || COLORS.denimBlue}
            icon={ICONS.CHEVRON_DOWN}
            theme={theme.chevron}
          />
        </Header>
        {showOptions && (
          <Options theme={theme.options} setHeight={setHeight} isUserAddAction={isUserAddAction}>
            {options.map(option => (
              <Option
                isUserAddAction={isUserAddAction}
                theme={theme.option}
                hasHeight={hasHeight}
                key={option.value}
                onClick={() => {
                  onChange(option.value);

                  toggleShowOptions(false);
                }}
              >
                {option.name}
              </Option>
            ))}
          </Options>
        )}
        <ErrorLabelWrapper>
          <ErrorLabel>{hasError && <ErrorMessage>{error}</ErrorMessage>}</ErrorLabel>
        </ErrorLabelWrapper>
      </Wrapper>
      {withTags && value && (
        <Tags
          tags={[
            {
              name: tagsByName ? filteredOption.name : value,
              onRemove: () => onChange(''),
              canEdit: true,
              color: COLORS.denimBlue,
              backgroundColor: theme.tagBackgroundColor
            }
          ]}
        />
      )}
    </OutsideClickHandler>
  );
};

Dropdown.propTypes = {
  toggleShowOptions: PropTypes.func.isRequired,
  showOptions: PropTypes.bool,
  options: PropTypes.arrayOf(Object),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
  }).isRequired,
  placeholder: PropTypes.string,
  theme: PropTypes.shape({
    wrapper: PropTypes.string
  }),
  withTags: PropTypes.bool,
  displayValue: PropTypes.bool,
  tagsByName: PropTypes.bool,
  hasHeight: PropTypes.bool
};

Dropdown.defaultProps = {
  showOptions: false,
  options: [],
  theme: {},
  withTags: false,
  displayValue: false,
  placeholder: '',
  tagsByName: false,
  hasHeight: false
};

export default compose(withState('showOptions', 'toggleShowOptions', false))(Dropdown);
