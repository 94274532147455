import React from 'react';
import { Divider } from '@material-ui/core';
import moment from 'moment';

import { SectionContainer } from './styled-components';
import { H3, Table } from '../../global-material-components';

// the id should match the name of the key in the objects you provide as data.
// i.e. when providing an id of 'date', your data should have an object with a date key.
const headings = [
  {
    name: 'Date',
    id: 'date',
    sortable: true,
    sortFunction: () => (a, b) =>
      moment(a.date, 'MM/DD/YYYY').diff(moment(b.date, 'MM/DD/YYYY'), 'days')
  },
  {
    name: 'Event Name',
    id: 'name',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Paying Group',
    id: 'group',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Status',
    id: 'status',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Amount',
    id: 'amount',
    sortable: false,
    sortFunction: null,
    parser: val => `$${Number(val).toFixed(2)}`
  },
  {
    name: 'Fees',
    id: 'fees',
    sortable: false,
    sortFunction: null,
    parser: val => `$${Number(val).toFixed(2)}`
  },
  {
    name: 'Total',
    id: 'total',
    sortable: false,
    sortFunction: null,
    parser: val => `$${Number(val).toFixed(2)}`
  }
];

// Notice the keys all match the ids.
function createData(date, name, group, status, amount, fees, total) {
  return { date, name, group, status, amount, fees, total };
}

const data = [
  createData(
    moment('6/8/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('6/2/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('6/1/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/22/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/22/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/20/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/18/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/13/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/12/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/5/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/4/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/2/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  ),
  createData(
    moment('5/2/19', 'MM/DD/YYYY').format('MM/DD/YYYY'),
    'FC Dallas Soccer Tournament',
    'North Texas Soccer',
    'Available',
    25,
    2,
    23
  )
];

export default function Tables() {
  return (
    <>
      <H3>Tables</H3>
      <Divider />
      <SectionContainer>
        {/* dataKey will just be used to assign keys for react's sake. Just choose an id from one of your headings. */}
        {/* If it is unqiue that is great. If not, still fine. */}
        {/* You can also provide a total to the table. It will display whatever is given, so parse it first. */}
        {/* This will also pass along any Material-UI props to the base table component. Like size. */}
        <Table headings={headings} data={data} dataKey="name" />
      </SectionContainer>
    </>
  );
}
