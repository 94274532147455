import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import { Block, Content, ButtonsContainer, ButtonContainer } from './styled-components';
import Enhancer from '../../../../containers/ImportModal';
import { capitalize } from '../../../../../utils/helpers';
import { USER_ROLES } from '../../../../../constants';

const RemoveUsersModal = ({ onClose, selected, allSelect, onSubmit, list, config }) => {
  let listUserType = null;
  if (selected && selected.length) {
    let { role } = selected[0];

    if (USER_ROLES.includes(selected[0].role.toLowerCase())) {
      role = 'Admin';
    }
    listUserType = role;
  } else if (allSelect) {
    listUserType = list;
  }
  return (
    <Modal title="Remove Selected From Event" onClose={onClose} rightPanel background userList>
      <Row>
        <Block xs={10} xsOffset={1}>
          {allSelect ? (
            <Content>Remove all selected {`${config.label}s`}</Content>
          ) : (
            <Content>
              Remove {selected.length} selected {capitalize(listUserType)}
              {selected.length > 1 ? 's' : ''}
            </Content>
          )}
        </Block>
        <Block xs={12}>
          <ButtonsContainer>
            <ButtonContainer>
              <Button
                label="Remove Selected"
                onClick={() => onSubmit(selected, allSelect)}
                width="151px"
                height="32px"
                primary
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
            </ButtonContainer>
          </ButtonsContainer>
        </Block>
      </Row>
    </Modal>
  );
};

RemoveUsersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired
};

RemoveUsersModal.defaultProps = {};

export default Enhancer(RemoveUsersModal);
