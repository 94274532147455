export const MODAL_STATUSES = {
  DEFAULT: 'default',
  COMPLETE: 'complete',
  PARTIAL: 'partial',
  NOT: 'not'
};

export const MODAL_TYPES = {
  CVR: 'cvr',
  GR: 'gr',
  IR: 'ir',
  AR: 'ar'
};
