import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const RawButton = styled.button`
  ${({ primary }) =>
    primary
      ? `
        background-image: linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue});
        color: white;
        border: none;
      `
      : `
        background-color: white;
        color: ${COLORS.denimBlue};
        border: none;
      `};

  ${({ clear }) =>
    clear
      ? `
    background: transparent;
    color: white;
    border: 1px solid white
  `
      : ''};

  ${({ noShadow, gameEdit }) =>
    noShadow || gameEdit ? '' : `box-shadow: 1px 1px 2px 0 ${COLORS.shadow}`};
  display: block;
  width: ${({ width }) => (width ? `${pxToRem(width)}` : '100%')};
  ${({ height }) => (height ? `height: ${pxToRem(height)};` : '')};
  border-radius: 5px;
  font-size: ${({ fontSize }) => (fontSize ? pxToRem(fontSize) : pxToRem('12px'))};
  padding: ${({ padding }) => (padding ? pxToRem(padding) : pxToRem('10px'))};
  font-family: 'RobotoLight';
  font-weight: 100;
  cursor: pointer;
  outline: none;
  margin: ${({ margin }) => margin || `${pxToRem('5px')} 0`};

  &:disabled {
    background-color: ${COLORS.silverSand};
    cursor: not-allowed;
    background-image: none;
  }

  ${({ center }) => (center ? 'display: flex; align-self: center;' : '')};
  ${({ gameEdit }) =>
    gameEdit &&
    `
  border: 1px solid ${COLORS.denimBlue};
  font-weight: 100;
  `};
  ${({ customStyle }) => customStyle || ''};

  ${({ hover }) =>
    hover
      ? `&:hover {
    background-color: #D9D9D9;
  }`
      : ''};
`;

export const LinkWrapper = styled(NavLink)`
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
`;

export default RawButton;
