import styled from 'styled-components';

import { pxToRem } from '../../../../utils/helpers';

export const MandateContent = styled.div`
  padding: 10px 20px;
  font-size: ${pxToRem('15px')};
`;

export default MandateContent;
