import { compose, withState, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { NOTIFICATION_STATUS, PAYMENTS_VIEW } from '../../../constants';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import { userIdxGetter } from '../../../utils/auth';
import * as paths from '../../../core/paths';

export default compose(
  withRouter,
  withState('selectedType', 'setSelectedType', null),
  withState('expandedId', 'setExpandedId', null),
  withHandlers({
    onClick: ({ fetchMenuNotifications, setSelectedType }) => notificationType => {
      setSelectedType(notificationType);
      fetchMenuNotifications({
        filters: { alert_type: notificationType, status: NOTIFICATION_STATUS.action_required }
      });
    },
    onPaymentUpdateNotification: ({
      updateNotification,
      setSelectedPaymentsEvent,
      setPaymentsView,
      closeMenu
    }) => async ({ expandedId, eventId }) => {
      closeMenu();
      updateNotification({ id: expandedId, data: { status: NOTIFICATION_STATUS.action_taken } });
      setSelectedPaymentsEvent({ id: eventId && eventId.toString() });
      setPaymentsView(PAYMENTS_VIEW.FUNDING);
    },
    onPayoutUpdateNotification: ({ updateNotification, closeMenu }) => async ({ expandedId }) => {
      closeMenu();
      updateNotification({ id: expandedId, data: { status: NOTIFICATION_STATUS.action_taken } });
    },
    onAddFundsUpdate: ({ updateNotification, closeMenu, history, userId }) => async ({
      expandedId
    }) => {
      closeMenu();
      updateNotification({ id: expandedId, data: { status: NOTIFICATION_STATUS.action_taken } });
      history.push(
        routeBuilder({ userIdx: userIdxGetter(), userId }, paths.PROFILE_PAYMENT_METHOD)
      );
    }
  })
);
