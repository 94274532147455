export const displaySettings = {
  is_score_entry_option: {
    label: 'Score Entry Option:',
    value: false
  },
  team_staff_section: {
    label: 'Team Staff Section - which includes attendance options',
    value: false
  },
  roster_section: {
    label: 'Roster Section - which includes attendance options and player scoring stats',
    value: false
  },
  image_attachments: {
    label: 'Image Attachments',
    value: false
  },
  game_summary_notes: {
    label: 'Game Summary Notes',
    value: false
  },
  mark_forfeit: {
    label: 'Allow officials to mark a forfeit for one or both teams',
    value: false
  }
};

export const fields = {
  score_entry_stats: {
    label: 'Score Entry & Player Stats',
    options: [
      {
        name: 'Hidden',
        value: 'hidden'
      },
      {
        name: 'Optional',
        value: 'optional'
      },
      {
        name: 'Required',
        value: 'required'
      }
    ],
    getDisplaySettings: value => {
      switch (value) {
        case 'optional':
        case 'required':
          return {
            ...displaySettings,
            is_score_entry_option: {
              ...displaySettings.is_score_entry_option,
              value: true
            },
            image_attachments: {
              ...displaySettings.image_attachments,
              value: true
            },
            game_summary_notes: {
              ...displaySettings.game_summary_notes,
              value: true
            },
            score_entry_option: {
              value: 'Team Score Only'
            }
          };
        default:
          return displaySettings;
      }
    }
  }
};

export const mutators = {
  scoreEntryStats: (args, state, utils) => {
    const value = args[0];
    const config = fields.score_entry_stats.getDisplaySettings(value);
    Object.keys(config).forEach(field => {
      utils.changeValue(state, field, () => config[field].value);
    });
    utils.changeValue(state, 'score_entry_stats', () => value);
  }
};
