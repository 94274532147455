import { createSelector } from 'reselect';
import { getUserGroups } from '../Auth/selectors';
import { getAllEvents, getSelectedEventPlatform } from '../Events/selectors';

export const getIncidentReports = ({ reports }) => reports.incidentReports;

export const getAssessmentReports = ({ reports }) => reports.assessmentReports;

export const getReportMeta = ({ reports: { meta } }) => meta;

export const getLimit = createSelector(getReportMeta, ({ limit }) => limit);

export const getReportsEventId = ({ reports }) => reports.selectedReportsEvent.id;

export const getReportsEvent = () =>
  createSelector(getAllEvents, getReportsEventId, (events, eventId) =>
    events.find(event => event.id === eventId)
  );

export const getReportsEventCrewLabels = ({ reports }) => reports.reportsEventCrewLabels;

export const getIsFetching = ({ reports }) => reports.isFetching;

export const getCurrentReport = ({ reports }) => reports.current;

export const getSortingValue = ({ reports }) => reports.sortingValue;

export const getGameReports = ({ reports }) => reports.gameReports;

export const getIsFetchingGameReports = ({ reports }) => reports.isFetchingGameReports;

export const isReportsEventGroupAdmin = () =>
  createSelector(getUserGroups, getSelectedEventPlatform, (groups, platform) =>
    groups.includes(platform)
  );

export const getCurrentIsFetching = ({ reports }) => reports.current && reports.current.isFetching;
