import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { ifElse, is, applyTo, identity, always } from 'ramda';

const apply = value =>
  ifElse(
    is(Function),
    applyTo(value),
    ifElse(() => value, identity, always(null))
  );

const ConditionalField = ({ when, predicate, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => apply(predicate(value))(children)}
  </Field>
);

ConditionalField.propTypes = {
  when: PropTypes.string.isRequired,
  predicate: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired
};

export default ConditionalField;
