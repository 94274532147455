import styled from 'styled-components';

import { RawButton } from '../../../Button/styled-components';
import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';
import { RoundCheckboxWrapper } from '../../../CheckBoxGroup/styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  min-height: ${pxToRem('60px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
`;

export const SelectedUsersLabel = styled.span`
  font: 500 ${pxToRem('13px')} 'RobotoMedium', sans-serif;
  color: ${COLORS.cobaltGrey};
`;

export const UsersQuantity = styled.span`
  margin-right: ${pxToRem('6px')};
  font: bold ${pxToRem('24px')} 'Roboto', sans-serif;
  color: ${COLORS.denimBlue};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${pxToRem('20px')};
  align-self: center;
`;

export const CheckBoxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  & ${RoundCheckboxWrapper} {
    width: 100%;
    display: inline-block;
  }
`;

export const CheckBoxGroupTitle = styled.span`
  display: inline-block;
  font-family: 'RobotoCondensed', sans-serif;
  font-stretch: condensed;
  font-size: ${pxToRem('13px')};
  color: ${COLORS.shuttleGray};
  margin-bottom: ${pxToRem('10px')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${pxToRem('30px')};

  & > ${RawButton} {
    margin-right: ${pxToRem('16px')};
  }
`;

export default Container;
