import React from 'react';
import PropTypes from 'prop-types';

import { RawButton, LinkWrapper } from './styled-components';
import Circle from '../Circle';

const Button = ({
  label,
  onClick,
  type,
  disabled,
  name,
  primary,
  secondary,
  clear,
  submitting,
  width,
  height,
  noShadow,
  fontSize,
  margin,
  center,
  padding,
  gameEdit,
  customStyle,
  hover
}) => (
  <RawButton
    onClick={onClick}
    type={type}
    disabled={disabled}
    name={name}
    primary={primary}
    secondary={secondary}
    clear={clear}
    width={width}
    height={height}
    noShadow={noShadow}
    fontSize={fontSize}
    margin={margin}
    center={center}
    padding={padding}
    gameEdit={gameEdit}
    customStyle={customStyle}
    hover={hover}
  >
    {submitting ? (
      <Circle height={20} width={20} cx={10} cy={10} r={8} strokeWidth={2} color="white" />
    ) : (
      label
    )}
  </RawButton>
);

Button.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  submitting: PropTypes.bool,
  secondary: PropTypes.bool,
  clear: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  noShadow: PropTypes.bool,
  fontSize: PropTypes.string,
  margin: PropTypes.string,
  center: PropTypes.bool,
  gameEdit: PropTypes.bool
};

Button.defaultProps = {
  type: 'button',
  name: 'name',
  disabled: false,
  submitting: false,
  primary: false,
  secondary: false,
  clear: false,
  onClick: () => {},
  width: null,
  height: null,
  noShadow: false,
  fontSize: null,
  margin: '',
  center: false,
  padding: null,
  gameEdit: false
};

export const ButtonAsLink = ({ to, ...buttonProps }) => (
  <LinkWrapper to={to}>
    <Button {...buttonProps} />
  </LinkWrapper>
);

ButtonAsLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default Button;
