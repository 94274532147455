import { compose, withHandlers, withProps } from 'recompose';

export default compose(
  withProps(() => ({
    isFetchGroupInfo: true
  })),
  withHandlers({
    onConfirm: ({
      deleteUserRole,
      selectedUser: { id: userId, roleId },
      isFetchGroupInfo,
      groupId
    }) => values => {
      if (userId && roleId && groupId) {
        deleteUserRole({ userId, roleId, values, isFetchGroupInfo, groupId });
      }
    }
  })
);
