// Actions
export const FETCH_PRODUCT_INVOICES = 'PLATFORM/FETCH_PRODUCT_INVOICES';
export const SET_PRODUCT_INVOICES = 'PLATFORM/SET_PRODUCT_INVOICES';
export const SET_FETCHING_INVOICE = 'PLATFORM/SET_FETCHING_INVOICE';

export const FETCH_PLATFORM_SUBSCRIPTIONS = 'PLATFORM/FETCH_PLATFORM_SUBSCRIPTIONS';
export const SET_PLATFORM_SUBSCRIPTIONS = 'PLATFORM/SET_PLATFORM_SUBSCRIPTIONS';

export const SET_PLATFORM = 'PLATFORM/SET_PLATFORM';
export const SET_GROUP = 'PLATFORM/SET_GROUP';
