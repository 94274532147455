import { pxToRem } from '../../../utils/helpers';
import { COLORS } from '../../../utils/styles';

export default {
  content: 'min-height: 0',
  row: {
    wrapper: 'overflow-x: hidden; overflow-y: auto;',
    item: `border-bottom: 1px solid ${COLORS.lightCyanGray}`
  },
  dropdown: {
    wrapper: `
      width: 132px;
      height: 100%;
      background: transparent;
    `,
    header: `
      background: transparent;
      color: ${COLORS.white};
      box-shadow: none;
      font-size: ${pxToRem('13px')};

      &.hasValue {
        box-shadow: none;
      }
    `,
    chevron: {
      color: COLORS.white
    },
    option: `
      background-color: ${COLORS.bahamaBlue};
      color: ${COLORS.white};

      &:hover {
        background-color: ${COLORS.sapphire};
      }
    `,
    options: `
      top: ${pxToRem('40px')};

      &::-webkit-scrollbar {
        background-color: ${COLORS.bahamaBlue};
      }

      &::-webkit-scrollbar-thumb {
        background: ${COLORS.sapphire};
      }
    `,
    value: 'padding-left: 0.5rem;'
  },
  dropdownContainer: `border-right: 1px solid ${COLORS.denimBlue}`,
  action: {
    icon: {
      color: COLORS.white,
      marginTop: '0px',
      marginRight: '0px'
    },
    dropdown: {
      topOffset: '34px',
      rightOffset: '12px',
      width: '140px'
    },
    option: {
      background: COLORS.denimBlue,
      color: COLORS.white,
      hoverBackground: COLORS.darkCerulean,
      hoverColor: COLORS.white
    },
    optionDisabled: {
      background: COLORS.denimBlue,
      color: COLORS.strongCyan,
      hoverBackground: COLORS.denimBlue,
      hoverColor: COLORS.strongCyan
    }
  },
  assessmentCell: `
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  `
};
