import styled from 'styled-components';
import COLORS from '../../../utils/styles';
import Icon from '../Icon';

export const GhostContainer = styled.div`
  display: flex;
  justify-content: left;
`;

export const AddIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 9px;
`;

export const GhostStyle = {
  fontSize: '12px',
  fontWeight: '500',
  paddingRight: '40px',
  textTransform: 'lowercase'
};

export const FormStyles = styled.div`
  width: 100%;
  & div {
    width: 100%;
  }
  & Field {
    width: 100%;
  }
  & input {
    width: 100%;
  }
`;

export const Error = styled.span`
  color: ${COLORS.errorRed};
  font-size: 0.7rem;
  position: relative;
  box-shadow: none !important;
`;

export const TermsContainer = styled.div`
  padding-top: 10px;
`;

export const FieldWrapper = styled.div``;
