import { MenuItem } from '@material-ui/core';
import styled from 'styled-components';

export const HelpText = styled.div`
  font-size: 0.8rem;
  padding-left: 2px;
`;

export const ButtonMenuItem = styled(MenuItem)`
  text-decoration: underline !important;
  font-weight: 600 !important;
`;
