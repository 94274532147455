import React from 'react';
import PropTypes from 'prop-types';
import { TagContainer, TagLabel, Remove } from './styled-components';
import { ICONS, COLORS } from '../../../utils/styles';

export const Tag = ({
  name,
  onRemove,
  canEdit,
  color,
  backgroundColor,
  width,
  fontWeight,
  fontFamily
}) => (
  <TagContainer backgroundColor={backgroundColor} width={width}>
    <TagLabel color={color} fontWeight={fontWeight} fontFamily={fontFamily}>
      {name}
    </TagLabel>

    {canEdit && (
      <Remove
        icon={ICONS.CLOSE}
        width={10}
        height={10}
        color={COLORS.shuttleGray}
        onClick={onRemove}
      />
    )}
  </TagContainer>
);

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string
};

Tag.defaultProps = {
  canEdit: false,
  color: COLORS.shuttleGray,
  backgroundColor: COLORS.blackSqueeze
};
export default Tag;
