import { COLORS, ICONS } from '../../../utils/styles';
import {
  DATE_FORMATS,
  EXPECTED_PAYMENT_PROCESSING_DAYS,
  GAMES_STATUSES,
  GAME_ASSIGNMENT_STATUSES,
  PAYMENT_FLOW_STATUSES
} from '../../../constants';
import { formatDate } from '../../../utils/parsers';
import { ASSIGNMENT_STATUS } from '../../../core/Games/constants';
import { convertToTimezone } from '../../../utils/helpers';

const config = {
  gameStatus: {
    [GAMES_STATUSES.UPDATED]: { color: COLORS.mustardYellow },
    [GAMES_STATUSES.CANCELLED]: { color: COLORS.StatusRed },
    [GAMES_STATUSES.POSTPONED]: { color: COLORS.StatusRed }
  },
  assignmentStatus: {
    [GAME_ASSIGNMENT_STATUSES.pending]: {
      color: COLORS.yellow,
      icon: ICONS.SYNC,
      width: 16,
      height: 16
    },
    [GAME_ASSIGNMENT_STATUSES.accepted]: {
      color: COLORS.forestGreen,
      icon: ICONS.CHECK_CIRCLE_HOLLOW,
      width: 16,
      height: 16
    },
    [GAME_ASSIGNMENT_STATUSES.declined]: {
      color: COLORS.venetianRed,
      icon: ICONS.X_CIRCLE_HOLLOW,
      width: 16,
      height: 16
    },
    default: {
      color: COLORS.lightBlue,
      icon: ICONS.CIRCLE,
      width: 16,
      height: 16
    }
  },
  paymentStatus: {
    [PAYMENT_FLOW_STATUSES.PROCESSING]: ({ date, amount }) => ({
      paid: false,
      message: `Pending - $${amount.toFixed(2)} expected payment on ${formatDate(
        date.setDate(date.getDate() + EXPECTED_PAYMENT_PROCESSING_DAYS)
      )}`,
      styles: {
        color: COLORS.yellow
      }
    }),
    [PAYMENT_FLOW_STATUSES.PAID]: ({ date, amount }) => ({
      paid: true,
      message: `$${amount.toFixed(2)} paid on ${formatDate(date)}`,
      styles: {
        color: COLORS.forestGreen
      }
    })
  },
  expandAssignmentStatus: {
    [GAME_ASSIGNMENT_STATUSES.pending]: { background: COLORS.pendingYellow },
    [GAME_ASSIGNMENT_STATUSES.accepted]: { background: COLORS.acceptedGreen },
    [GAME_ASSIGNMENT_STATUSES.declined]: { background: COLORS.declinedRed },
    default: {
      background: COLORS.seaBlue
    }
  }
};

export const selfAssignCrewPosition = ({
  published,
  labels,
  crewMembers,
  userSchedule,
  eventId,
  roleIds
}) => {
  const userRoleIds = userSchedule.event_roles
    .filter(role => role.event_id === eventId)
    .map(val => Number(val.role_id));

  const filterLabels =
    labels &&
    labels.map((value, index) => {
      const roleIdMatch = roleIds.find((a, i) => userRoleIds.includes(Number(a)) && index === i);
      return roleIdMatch && { label: value, position: index };
    });

  if (published) {
    return (
      filterLabels &&
      filterLabels.filter(col => {
        const position =
          crewMembers &&
          crewMembers.length &&
          crewMembers.find(a => col && col.position === a.official_label_col);
        return !position && col;
      })
    );
  }
  return filterLabels;
};

export const getAssignedCrewMember = ({ crewMembers, index }) => {
  const gameAssignment = crewMembers && crewMembers.find(a => index === a.official_label_col);
  switch (gameAssignment && gameAssignment.status) {
    case ASSIGNMENT_STATUS.accepted:
      return {
        iconColor: COLORS.forestGreen,
        icon: ICONS.CHECK_CIRCLE_HOLLOW,
        gameAssignment
      };
    case ASSIGNMENT_STATUS.pending:
      return {
        iconColor: COLORS.yellow,
        icon: ICONS.SYNC,
        gameAssignment
      };
    case ASSIGNMENT_STATUS.declined:
      return {
        iconColor: COLORS.venetianRed,
        icon: ICONS.X_CIRCLE_HOLLOW,
        gameAssignment
      };
    default:
      return {
        iconColor: COLORS.greyChateau,
        icon: ICONS.EMPTY,
        gameAssignment
      };
  }
};

export const getCalendarFormatGames = (gamesInfo, activeMonth) => {
  const tzconvert = (value, timezone, format) =>
    convertToTimezone(value, timezone, format, DATE_FORMATS.FULL_DATE_TIME);
  return (
    gamesInfo.length &&
    gamesInfo
      .filter(game => tzconvert(game.start_at, game.timezone, 'MM') === activeMonth)
      .map(game => {
        return {
          ...game,
          start_at: tzconvert(game.start_at, game.timezone, DATE_FORMATS.YYYY_MM_DD_H_mm_ss),
          end_at: tzconvert(game.end_at, game.timezone, DATE_FORMATS.YYYY_MM_DD_H_mm_ss),
          start_date: tzconvert(game.start_at, game.timezone, DATE_FORMATS.MM_DD_YYYY),
          end_date: tzconvert(game.end_at, game.timezone, DATE_FORMATS.MM_DD_YYYY),
          start_time: tzconvert(game.start_at, game.timezone, DATE_FORMATS.H_MM_A),
          end_time: tzconvert(game.end_at, game.timezone, DATE_FORMATS.H_MM_A)
        };
      })
  );
};

export const crewMembersSort = game =>
  game.crew &&
  game.crew.length > 0 &&
  game.crew.sort(function (a, b) {
    return a.official_label_col - b.official_label_col;
  });

export default config;
