import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withProps, pure } from 'recompose';

import Container from './styled-components';

const YearMonthForm = ({ date, handleChange, months, years }) => (
  <Container>
    <select
      name="month"
      onChange={e =>
        handleChange({
          year: date.getFullYear(),
          month: e.target.value
        })
      }
      value={date.getMonth()}
    >
      {months.map((month, i) => (
        <option key={month} value={i}>
          {month}
        </option>
      ))}
    </select>
    <select
      name="year"
      onChange={e =>
        handleChange({
          year: e.target.value,
          month: date.getMonth()
        })
      }
      value={date.getFullYear()}
    >
      {years.map(year => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  </Container>
);

YearMonthForm.propTypes = {
  date: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  months: PropTypes.arrayOf(PropTypes.string).isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default compose(
  withHandlers({
    handleChange: ({ onChange }) => ({ year, month }) => onChange(new Date(year, month))
  }),
  withProps(({ localeUtils, fromMonth, toMonth }) => {
    const months = localeUtils.getMonths();
    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }
    return { months, years };
  }),
  pure
)(YearMonthForm);
