import { compose, withHandlers, lifecycle, withState, withProps } from 'recompose';
import FileSaver from 'file-saver';

import { formatDate } from '../../../utils/parsers';
import { eventFilterLimit } from '../../../constants';

const searchEventParms = {
  LIMIT: eventFilterLimit,
  SORTBY: '-created_at'
};
export default compose(
  withState('checkedUsers', 'updateCheckedUsers', []),
  withState('showEmailSelectedModal', 'setshowEmailSelectedModal', false),
  withState('showUpdateRankModal', 'setshowUpdateRankModal', false),
  withState('showUpdateCategoryModal', 'setshowUpdateCategoryModal', false),
  withState('filterCount', 'setFilterCount', 0),
  withState('eventSearchValue', 'setEventSearchValue', ''),
  withHandlers({
    handleCheckboxClick: ({ updateCheckedUsers }) => ({ id, checked }) =>
      updateCheckedUsers(checkedUsers =>
        checked ? [...checkedUsers, id] : checkedUsers.filter(userId => userId !== id)
      ),
    handleSelectAll: ({ updateCheckedUsers, userList }) => checked =>
      updateCheckedUsers(checked ? userList && userList.map(user => user.id) : []),
    onAddRank: ({ addRank, setshowUpdateRankModal, peopleEvent }) => selected => {
      setshowUpdateRankModal(s => !s);
      return addRank(selected, peopleEvent && peopleEvent.id);
    },
    editButtonClicked: ({ fetchActiveUserInfo }) => user => {
      fetchActiveUserInfo({ userId: user.id });
    },
    downloadCSV: ({ pagination: { onLoadPage }, peopleEvent }) => () =>
      new Promise(resolve =>
        onLoadPage({
          roles: { alias: 'users', list: [] },
          eventId: peopleEvent && peopleEvent.id,
          peopleTab: true,
          toCSV: true,
          resolve
        })
      ).then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' });
        FileSaver.saveAs(blob, `user_list_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
      })
  }),
  withProps(
    ({
      showEmailSelectedModal,
      setshowEmailSelectedModal,
      sendEmail,
      showUpdateRankModal,
      setshowUpdateRankModal,
      showUpdateCategoryModal,
      setshowUpdateCategoryModal,
      downloadCSV,
      UserCategoryAssignorModal,
      AddRankModal,
      onAddRank,
      EmailUsersModal,
      assignCategoriesToUsers
    }) => ({
      config: {
        action: {
          options: [
            {
              component: EmailUsersModal,
              label: 'Email Selected',
              showOption: ['usersSelected'],
              showModal: showEmailSelectedModal,
              onClick: () => setshowEmailSelectedModal(s => !s),
              onSubmit: sendEmail
            },
            {
              component: AddRankModal,
              label: 'Update Rank',
              showOption: ['usersSelected', 'isNotAllEvents'],
              showModal: showUpdateRankModal,
              onClick: () => setshowUpdateRankModal(s => !s),
              onSubmit: onAddRank
            },
            {
              component: UserCategoryAssignorModal,
              label: 'Update Category',
              showOption: ['usersSelected', 'isNotAllEvents'],
              showModal: showUpdateCategoryModal,
              onClick: () => setshowUpdateCategoryModal(s => !s),
              onSubmit: assignCategoriesToUsers
            },
            {
              label: 'Download List',
              showOption: ['isNotAllEvents'],
              showModal: false,
              onClick: downloadCSV
            }
          ]
        }
      }
    })
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.fetchEvents) {
        this.props.fetchEvents({
          setLimit: searchEventParms.LIMIT,
          sort: searchEventParms.SORTBY,
          isFetchingList: true
        });
      }
      if (this.props.fetchFilteredEvents && this.props.peopleEvent && this.props.peopleEvent.name) {
        this.props.fetchFilteredEvents({
          filters: { search: this.props.peopleEvent.name },
          sort: searchEventParms.SORTBY,
          noLimit: true,
          isFetchingList: true
        });
      }
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.peopleEvent &&
        (!prevProps.peopleEvent || prevProps.peopleEvent.id !== this.props.peopleEvent.id)
      ) {
        this.props.pagination.onLoadPage(this.props.onLoadPageArgs);
      }
      if (prevProps.peopleEvent && this.props.peopleEvent) {
        if (prevProps.peopleEvent.id !== this.props.peopleEvent.id) {
          this.props.setEventSearchValue('');
        }
      }
      if (this.props.userList !== prevProps.userList) this.props.updateCheckedUsers([]);
    }
  })
);
