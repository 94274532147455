import styled from 'styled-components';

export const Svg = styled.svg`
  display: inline-block;
  vertical-align: middle;
  ${({ noMargin }) => (noMargin ? 'margin: 0' : 'margin: 0 10px')};
  & path {
    fill: ${({ color, theme }) => color || theme.primaryColor};
  }

  ${({ margin }) => (margin ? `margin: ${margin}` : '')};
  ${({ clickable }) => (clickable ? 'cursor: pointer' : '')};
`;

export default Svg;
