import styled from 'styled-components';

import { pxToRem } from '../../../utils/helpers';
import { COLORS } from '../../../utils/styles';

export const Container = styled.div``;

export const RoundCheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.span`
  font: 500 ${pxToRem('13px')} RobotoMedium, sans-serif;
  color: ${COLORS.shuttleGray};
`;

export default Container;
