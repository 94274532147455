import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export const GroupContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: 0.32rem;

  ${({ theme }) => theme};
`;

export const TimePickerWrapper = styled.div`
  margin: 0.5rem;
  ${({ theme }) => theme};
`;

export const HeighlightContainer = styled.span`
  position: absolute;
  height: 60%;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
`;

export const BarContainer = styled.span`
  position: relative;
  display: block;
  width: 100%;
  &:before,
  &:after {
    content: '';
    height: 0.125rem;
    width: 0;
    bottom: 0.06rem;
    position: absolute;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
`;

export const Label = styled.label`
  font-family: 'RobotoCondensed';
  color: ${COLORS.shuttleGrey};
  font-size: 13px;
  font-stretch: condensed;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 0.7rem;
  left: 0.5rem;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;

  ${({ theme }) => theme};

  ${({ hasValue, hasError }) =>
    hasValue &&
    `top: -.3rem;
    font-size: 0.6rem;
    color: ${hasError ? COLORS.carminePink : COLORS.shuttleGrey};`};
`;
