import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { DeleteForeverOutlined as RemoveUser } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '../../../layouts/Paper';
import { TableWrapper } from '../styled-components';
import config, { HEADERS } from './config';
import COLORS from '../../../../utils/styles';
import LoaderWrapper from '../../LoaderWrapper';
import { formatTaxId } from '../../../../utils/helpers';

const Header = ({ headers }) => (
  <TableHead>
    <TableRow>
      {headers.map(({ label, colSpan, name }) => (
        <TableCell
          colSpan={colSpan}
          style={{ width: name === HEADERS.REMOVE || (name === HEADERS.EDIT && 40) }}
          key={name}
        >
          {label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const Body = ({ users, onButtonClick, headers, isFetching }) => (
  <TableBody>
    <LoaderWrapper isFetching={isFetching}>
      {users.length ? (
        users.map(user => (
          <TableRow hover style={{ borderBottom: `1px solid ${COLORS.lightGray}` }}>
            {headers.map(({ name, colSpan }) => (
              <>
                {name === HEADERS.REMOVE ? (
                  <RemoveUser
                    style={{ color: COLORS.errorRed, paddingTop: '12px', cursor: 'pointer' }}
                    onClick={() => onButtonClick(user)}
                  />
                ) : name === HEADERS.EDIT ? (
                  <EditIcon
                    style={{
                      color: COLORS.lightBlue,
                      paddingTop: '12px',
                      cursor: 'pointer',
                      marginLeft: '12px'
                    }}
                    onClick={() => onButtonClick(user)}
                  />
                ) : name === HEADERS.TAXID ? (
                  <TableCell colSpan={colSpan} style={{ borderBottom: '0px' }}>
                    {formatTaxId(user[name]) || 'NA'}
                  </TableCell>
                ) : (
                  <TableCell colSpan={colSpan} style={{ borderBottom: '0px' }}>
                    {user[name] || 'NA'}
                  </TableCell>
                )}
              </>
            ))}
          </TableRow>
        ))
      ) : (
        <TableRow hover>
          <TableCell> There is No data to display</TableCell>
        </TableRow>
      )}
    </LoaderWrapper>
  </TableBody>
);

const GroupUsersList = ({ ...props }) => (
  <TableWrapper>
    <Paper {...config.usersTile}>
      <TableContainer>
        <Table>
          <Header {...props} />
          <Body {...props} />
        </Table>
      </TableContainer>
    </Paper>
  </TableWrapper>
);

export default GroupUsersList;
