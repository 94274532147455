import React from 'react';
import PropTypes from 'prop-types';
import { withState, compose } from 'recompose';

import AssessmentReportsList from './SubMenu/AssessmentReportsList';
import GameReportsList from './SubMenu/GameReportsList';
import InvitationsList from './SubMenu/InvitationsList';
import PayoutNotification from './SubMenu/PayoutNotification';
import CrewVerificationList from './SubMenu/CrewVerificationList';
import {
  Wrapper,
  Header,
  HeaderText,
  Count,
  Show,
  SubMenuRow,
  Summary,
  Close,
  Notifications,
  NotificationCount,
  Back
} from './styled-components';
import { ICONS, COLORS } from '../../../utils/styles';
import Enhancer from '../../containers/NotificationsMenu';
import PaymentFailedNotification from './SubMenu/PaymentFailedNotification';
import GameStatusUpdateNotification from './SubMenu/GameStatusUpdate';
import AddFundsFailed from './SubMenu/AddFundsFailed';

const MAX_MINIMIZED = 4;

const SUBMENU_TYPES = {
  invitation_event: { label: 'New Games', component: InvitationsList },
  report_game: { label: 'Game Reports', component: GameReportsList },
  report_assessment: { label: 'Assessment Reports', component: AssessmentReportsList },
  payout_failed: { label: 'Payout Failed', component: PayoutNotification },
  payment_failed: { label: 'Payment Failed', component: PaymentFailedNotification },
  status_game: { label: 'Game Status Update', component: GameStatusUpdateNotification },
  crew_verification: { label: 'Crew Verification', component: CrewVerificationList },
  add_funds_failed: { label: 'Add Funds Failed', component: AddFundsFailed }
};

const SubMenu = ({
  notificationCount,
  handleNotificationsClick,
  showAll,
  setShowAll,
  label,
  setSelectedType,
  component: ListComponent,
  isFetching
}) => (
  <>
    <Header>
      <Back
        width={20}
        height={20}
        icon={ICONS.BACK_ARROW}
        color={COLORS.denimBlue}
        onClick={() => setSelectedType(null)}
      />
      <HeaderText>
        <Count>{notificationCount}</Count>
        {label} Notification{notificationCount === 1 ? '' : 's'}
      </HeaderText>
      <Close
        onClick={handleNotificationsClick}
        color={COLORS.zumthor}
        width={20}
        height={20}
        icon={ICONS.CLOSE}
      />
    </Header>
    <Notifications>
      <ListComponent closeMenu={handleNotificationsClick} isFetching={isFetching} />
    </Notifications>
    {notificationCount > MAX_MINIMIZED ? (
      <Show onClick={() => setShowAll(s => !s)} clickable expanded={showAll}>
        {showAll ? 'Show Less' : 'Show All'}
      </Show>
    ) : (
      <Show />
    )}
  </>
);

SubMenu.propTypes = {
  notificationCount: PropTypes.number,
  handleNotificationsClick: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
  setShowAll: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  setSelectedType: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired
};

SubMenu.defaultProps = {
  notificationCount: 0,
  showAll: false
};

const EnhancedSubMenu = compose(withState('showAll', 'setShowAll', false))(SubMenu);

const NotificationsMenu = ({
  notificationCounts,
  handleNotificationsClick,
  selectedType,
  setSelectedType,
  onClick,
  isFetching
}) => (
  <Wrapper onClick={e => e.nativeEvent.stopImmediatePropagation()}>
    {selectedType ? (
      <EnhancedSubMenu
        notificationCount={notificationCounts[selectedType]}
        handleNotificationsClick={handleNotificationsClick}
        setSelectedType={setSelectedType}
        isFetching={isFetching}
        {...SUBMENU_TYPES[selectedType]}
      />
    ) : (
      <>
        <Header>
          <HeaderText>
            <Count>{notificationCounts.total_count}</Count>Notification
            {notificationCounts.total_count === 1 ? '' : 's'}
          </HeaderText>
          <Close
            onClick={handleNotificationsClick}
            color={COLORS.zumthor}
            width={20}
            height={20}
            icon={ICONS.CLOSE}
          />
        </Header>
        {Object.keys(SUBMENU_TYPES).map(notificationType => {
          const count = notificationCounts[notificationType];
          const { label } = SUBMENU_TYPES[notificationType];
          return (
            !!count && (
              <SubMenuRow key={notificationType} onClick={() => onClick(notificationType)}>
                <NotificationCount>{count}</NotificationCount>
                <Summary>
                  {label} Notification{count === 1 ? '' : 's'}
                </Summary>
              </SubMenuRow>
            )
          );
        })}
      </>
    )}
  </Wrapper>
);

NotificationsMenu.propTypes = {
  notificationCounts: PropTypes.shape({
    invitation_event: PropTypes.number,
    report_game: PropTypes.number,
    total_count: PropTypes.number
  }).isRequired,
  handleNotificationsClick: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

NotificationsMenu.defaultProps = {
  selectedType: null
};

export default Enhancer(NotificationsMenu);
