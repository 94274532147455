import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import { logout } from '../../../core/Auth';
import { getUser } from '../../../core/Auth/selectors';

const mapStateToProps = state => ({
  user: getUser(state)
});

export default compose(connect(mapStateToProps, { onLogout: logout }), pure);
