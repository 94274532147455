import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';

import { COLORS, mediaQueries } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div`
  padding-top: ${pxToRem('7px')};
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
`;

export const Block = styled(Col)`
  margin: 2rem auto;
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.shuttleGray};
  line-height: 1.31;

  &:last-child {
    margin ${pxToRem('20px')} auto;
  }
`;

export const Content = styled.p`
  &:first-child {
    margin-bottom: 1rem;
  }
`;

export const ButtonsContainer = styled.div`
  // margin: ${pxToRem('15px')} auto 0;
  display: contents;
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`;

export const StyledRow = styled(Row)`
  padding: ${pxToRem('25px')} 3% 0;
  justify-content: space-between;

  &:only-of-type {
    background: ${COLORS.white};
  }

  &.days-hidden {
    justify-content: flex-start;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-around;
`;

export const StyledCol = styled(Col)`
  padding: ${pxToRem('8px')} ${pxToRem('15px')};
`;

export const Label = styled.span`
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  padding: 0 ${pxToRem('6px')};
`;

export const FlexedCol = styled(StyledCol)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DayBox = styled.div`
  display: flex;
  width: ${pxToRem('28px')};
  height: ${pxToRem('24px')};
  margin: ${pxToRem('4px')} ${pxToRem('4px')};
  border: 1px solid ${COLORS.hawkesBlue};
  background-color: ${COLORS.white};
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  font-family: 'Roboto';
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? `
    background-image: linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue});
    color: ${COLORS.white};
  `
      : ''};

  ${({ disabled }) =>
    disabled
      ? `
    background: ${COLORS.hawkesBlue}50
  `
      : ''};
`;

export const DaysContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  margin-left: 7px;
  text-align: left;
  flex-wrap: wrap;
  &.hidden {
    display: none;
  }
`;

export const FlexStatic = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${pxToRem('8px')};
  padding-right: ${pxToRem('9px')};

  &.hidden {
    display: none;
  }
  ${mediaQueries.md(`
    padding-left:${pxToRem('6px')};
  `)};
`;

export const DaysLabel = styled.span`
  display: inline-block;
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('11px')};
  position: absolute;
  left: ${pxToRem('4px')};
  top: ${pxToRem('-2px')};
  ${mediaQueries.md(`
  width:100%;
  margin:5px 0px;
  position:relative;
  `)};
`;

export const Note = styled.p`
  margin: ${pxToRem('8px')} 0 0;
  color: ${COLORS.shuttleGray}BB;
  font-size: ${pxToRem('13px')};
  padding: ${pxToRem('5px')} 0px;
  width: 100%;
`;

export const LastRow = styled(Row)`
  padding: ${pxToRem('25px')} ${pxToRem('23px')} ${pxToRem('24px')};
  justify-content: center;
  ${mediaQueries.sm(`
  padding: ${pxToRem('15px')} ${pxToRem('13px')} ${pxToRem('24px')};
  `)};
`;

export default {
  datePicker: {
    wrapper: ``,
    label: `
      color: ${COLORS.hawkesBlue};
    `,
    input: `
      background: transparent;
    `,
    noAutoTouch: true
  },
  dropdown: {
    wrapper: ``,
    dropableContainer: {
      clickable: ``,
      caption: `
        color: ${COLORS.hawkesBlue};
      `,
      placeholder: `
        text-align: left;
        font-size: ${pxToRem('13px')};
        font-family: 'Roboto';
      `,
      icon: `
        position: relative;
        bottom: ${pxToRem('1px')};
        right: ${pxToRem('6px')};
      `,
      content: ``,
      label: `
        font-size: 11px;
        color: ${COLORS.hawkesBlue};
      `
    },
    itemsContainer: `
      ::-webkit-scrollbar {
        width: ${pxToRem('6px')};
        position: absolute;
        background-color: ${COLORS.white};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${COLORS.azureGray};
      }
    `,
    dropdownItem: `
      color: ${COLORS.shuttleGray};
      line-height: 2;
      font-family: 'Roboto';
      font-size: ${pxToRem('13px')};
      background-color: ${COLORS.white};
      height: ${pxToRem('24px')};
      border: none;

      &:hover {
        background-color: ${COLORS.aliceBlueVariant};
        color: ${COLORS.denimBlue};
      }
      `
  },
  numberInput: {
    input: `
      font-family: 'RobotoBold';
      width: ${pxToRem('28px')};
      height: ${pxToRem('28px')};
      border: 1px solid ${COLORS.hawkesBlue};
      color: ${COLORS.denimBlue};
      background-color: ${COLORS.white};
      padding: 0;
      text-align: center;
      outline:${COLORS.black}
    `,
    wrapper: `
      width: auto;
      background-color: transparent;
      box-shadow: none;
    `
  }
};

export const Button = styled.button`
  border-radius: 4px;
  margin: 0px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  border: 1px solid #ccc;
  background: ${COLORS.white};
  box-shadow: 0px 2px 4px #ccc;
  color: ${({ color }) => color || COLORS.denimBlue};

  & span {
    padding: 0px 5px;
    font-size: 16px;
    font-weight: 800;
    line-height: 15px;
  }

  &:hover {
    border: ${({ border }) => border || `1px solid ${COLORS.denimBlue}`};
  }
  &:disabled {
    opacity: 0.4;
    border: 0px;
    pointer-events: none;
  }
  &:active {
    border: ${({ border }) => border || `1px solid ${COLORS.denimBlue}`};
    background: ${({ background }) => background || '#b3dcff'};
  }
`;
