import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  fetchCertifications as fetchCertificationsAction,
  updateCertifications as updateCertificationsAction,
  addCertifications as addCertificationsAction,
  deleteCertifications as deleteCertificationsAction
} from '../../../core/Certifications';
import { getUssfData, getCerts } from '../../../core/Certifications/selectors';
import unconnected from './unconnected';
import { getActiveUser, getUser, getUserProfile, isSuperAdmin } from '../../../core/Auth/selectors';
import { getParamId } from '../../../utils/helpers';

const mapStateToProps = (state, props) => ({
  ussf: getUssfData(state),
  certs: getCerts(state),
  isFetching: state.certs.isFetching,
  userId: getParamId(props) || getActiveUser(state).id,
  dob: getUserProfile(state).dob,
  isSuperAdmin: isSuperAdmin(state),
  allowAdminUpdates: getActiveUser(state).allow_admin_updates,
  certificationAccessPermissions: getUser(state).cross_edit_permissions.CERTIFICATIONS,
  currentUserId: state.auth && state.auth.user && state.auth.user.id
});

const mapDispatchToProps = dispatch => ({
  fetchCertifications: payload => dispatch(fetchCertificationsAction(payload)),
  addCertifications: payload => dispatch(addCertificationsAction(payload)),
  updateCertifications: payload => dispatch(updateCertificationsAction(payload)),
  deleteCertifications: payload => dispatch(deleteCertificationsAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
