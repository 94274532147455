import { compose, pure, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchEventAssignors as fetchEventAssignorsAction } from '../../../../../core/Users';
import { getUsersByType, getMetaByType } from '../../../../../core/Users/selectors';
import {
  createPrimaryAssignorOnGame,
  updatePrimaryAssignorOnGame,
  deletePrimaryAssignorOnGame,
  deleteAllPrimaryAssignors as deleteAllPrimaryAssignorsAction,
  createAllPrimaryAssignors as createAllPrimaryAssignorsAction,
  updateAllPrimaryAssignors as updateAllPrimaryAssignorsAction
} from '../../../../../core/Payments/index';
import { getPaymentsEvent } from '../../../../../core/Payments/selectors';

import { paginationAware, filterConfigToNames, updateOnPageChange } from '../../../PaginationAware';
import buildConfig from '../../../../components/Payments/BatchEdit/AssignorList/config';
import { getGamesEventId } from '../../../../../core/Games/selectors';

const mapStateToProps = state => ({
  eventId: getGamesEventId(state),
  eventAssignors: getUsersByType(state, 'assignor'),
  metaData: getMetaByType('assignor')(state),
  gamesEvent: getPaymentsEvent()(state)
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch(fetchEventAssignorsAction(payload)),
  createPrimaryAssignor: payload => dispatch(createPrimaryAssignorOnGame(payload)),
  updatePrimaryAssignor: payload => dispatch(updatePrimaryAssignorOnGame(payload)),
  deletePrimaryAssignor: payload => dispatch(deletePrimaryAssignorOnGame(payload)),
  deleteAllPrimaryAssignors: payload => dispatch(deleteAllPrimaryAssignorsAction(payload)),
  createAllPrimaryAssignors: payload => dispatch(createAllPrimaryAssignorsAction(payload)),
  updateAllPrimaryAssignors: payload => dispatch(updateAllPrimaryAssignorsAction(payload))
});

const PAGINATION_AWARE_PREFIX = 'usrs';

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware(
    {
      fetcherQuote: 'onLoad',
      forPrefix: 'usrs',
      metaQuote: 'metaData',
      searchFilter: ['search'],
      filterNames: filterConfigToNames(buildConfig({}).filters)
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  lifecycle({
    componentDidMount() {
      this.props.onLoad({ eventId: this.props.eventId });
    },
    componentDidUpdate(prevProps) {
      if (prevProps.eventId !== this.props.eventId) {
        this.props.onLoad({ eventId: this.props.eventId });
      }
    }
  }),
  withHandlers({
    handlePrimaryAssignment: ({
      createPrimaryAssignor,
      updatePrimaryAssignor,
      deletePrimaryAssignor,
      selectedGame,
      reload
    }) => ({ current, selected }) => {
      const assignorObject = {
        external_event_id: selectedGame.currentId,
        event_role_id: Number(selected.id)
      };

      if (!current) {
        createPrimaryAssignor({
          assignorObject,
          uniqueGameId: selectedGame.uniqueGameId,
          reload
        });
      } else if (Number(selected.id) === Number(current.event_role_id)) {
        deletePrimaryAssignor({
          id: current.id,
          uniqueId: selectedGame.uniqueGameId,
          reload
        });
      } else {
        updatePrimaryAssignor({ assignorObject, id: current.id, reload });
      }
    },
    handlePrimaryAssignmentBulk: ({
      deleteAllPrimaryAssignors,
      createAllPrimaryAssignors,
      updateAllPrimaryAssignors,
      reload
    }) => ({ allGames, assignor, ids, current }) => {
      if (ids) {
        deleteAllPrimaryAssignors({ ids });
      } else if (!current) {
        createAllPrimaryAssignors({ allGames, assignor, reload });
      } else if (Number(assignor.id) === current.event_role_id) {
        const allIds = allGames.map(game => game.assignment_primary_game.id);
        deleteAllPrimaryAssignors({ ids: allIds, reload });
      } else {
        updateAllPrimaryAssignors({ allGames, assignor, reload });
      }
    }
  }),
  pure
);
