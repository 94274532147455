export default {
  never: 'never',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly'
};

export const AVAILABILITY_TYPES = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable'
};
