import { createSelector } from 'reselect';
import { pipe, find, defaultTo, reject, isNil, uniq } from 'ramda';
import { getGamesEventId, getCalendarEventId, getCurrentGamesEventId } from '../Games/selectors';
import { getReportsEventId } from '../Reports/selectors';
import { getEvent as getNotificationEvent } from '../Notifications/selectors';
import { GAME_UPLOAD_TYPES } from '../../constants';
import { getEventId } from '../Events/selectors';

export const getUser = state => state.auth && state.auth.user;
export const getActiveUser = state =>
  state.user && Object.keys(state.user.activeUser).length !== 0
    ? state.user.activeUser
    : state.auth.user;

export const getPayerId = state =>
  state.user && Object.keys(state.user.activeUser).length !== 0
    ? state.user &&
      state.user.activeUser &&
      state.user.activeUser.payer &&
      state.user.activeUser.payer.id
    : state.auth && state.auth.user && state.auth.user.payer && state.auth.user.payer.id;
export const getPayeeId = state =>
  state.user &&
  state.user.activeUser &&
  state.user.activeUser.payee &&
  state.user.activeUser.payee.id;
export const getLoggedInUserIndex = createSelector(getUser, ({ userIndex }) => userIndex);
export const getUserIndex = createSelector(getActiveUser, ({ userIndex }) => userIndex);
export const getUserHeaderProfile = createSelector(getUser, ({ profile = {} }) => profile);
export const getUserProfile = createSelector(getActiveUser, ({ profile = {} }) => profile);
export const getUserProfileEmail = createSelector(getUserProfile, ({ email }) => email);
export const getAdminEvents = createSelector(getUser, ({ admin_events }) => admin_events);
export const getAdminAssessorEventIds = createSelector(
  getUser,
  ({ admin_events, assessor_events }) => uniq([...admin_events, ...assessor_events])
);
export const getAssignorEvents = createSelector(getUser, ({ assignor_events }) => assignor_events);

export const isEventRole = ({ params: { eventId }, role }) =>
  createSelector(getUser, ({ [`${role}_events`]: role_events }) => {
    if (!role_events) return null;
    return !!role_events.includes(Number(eventId));
  });

export const isEventAdmin = ({ params: { eventId } }) =>
  createSelector(getUser, ({ admin_events }) => {
    if (!admin_events) return null;
    return !!admin_events.includes(Number(eventId));
  });

export const isEventOfficial = ({ params: { eventId } }) =>
  createSelector(getUser, ({ official_events }) => {
    if (!official_events) return null;
    return !!official_events.includes(Number(eventId));
  });

export const isAnyEventAdmin = () =>
  createSelector(getUser, ({ admin_events }) => admin_events && !!admin_events.length);

export const isActiveUserAnyEventAdmin = () =>
  createSelector(getActiveUser, ({ admin_events }) => admin_events && !!admin_events.length);

export const isAnyEventAdminOrFundingAdmin = () => {
  return createSelector(
    getUser,
    ({ admin_events, funding_admin_events }) =>
      (admin_events && !!admin_events.length) ||
      (funding_admin_events && !!funding_admin_events.length)
  );
};

export const isEventAdminOrAssessorOrOfficial = () => {
  return createSelector(
    getUser,
    ({ admin_events, official_events, assessor_events }) =>
      (admin_events && !!admin_events.length) ||
      (assessor_events && !!assessor_events.length) ||
      (official_events && !!official_events.length)
  );
};

export const isAnyEventOfficialOrAssessorOrAssignor = () =>
  createSelector(getActiveUser, ({ official_events, assessor_events, assignor_events }) => {
    return (
      (official_events && !!official_events.length) ||
      (assessor_events && !!assessor_events.length) ||
      (assignor_events && !!assignor_events.length)
    );
  });

export const isAnyEventOfficialOrAssessor = () =>
  createSelector(getUser, ({ official_events, assessor_events }) => {
    return (
      (official_events && !!official_events.length) || (assessor_events && !!assessor_events.length)
    );
  });

export const isEventAdminOrAssignor = payload =>
  createSelector(
    isEventRole({ ...payload, role: 'admin' }),
    isEventRole({ ...payload, role: 'assignor' }),
    (eventAdmin, eventAssignor) => eventAdmin || eventAssignor
  );

export const isEventOfficialOrAssessor = payload =>
  createSelector(
    isEventRole({ ...payload, role: 'official' }),
    isEventRole({ ...payload, role: 'assessor' }),
    (eventOfficial, eventAssessor) => eventOfficial || eventAssessor
  );

export const isReportsEventAdmin = state => {
  const eventId = getReportsEventId(state);
  return (
    !!eventId &&
    !!createSelector(isEventAdmin({ params: { eventId } }), eventAdmin => eventAdmin)(state)
  );
};

export const isGamesEventAdmin = state => {
  const eventId = getGamesEventId(state);
  return (
    !!eventId &&
    !!createSelector(isEventAdmin({ params: { eventId } }), eventAdmin => eventAdmin)(state)
  );
};

export const isGamesEventAssignor = state => {
  const eventId = getGamesEventId(state);
  return (
    !!eventId &&
    !!createSelector(
      isEventRole({ params: { eventId }, role: 'assignor' }),
      eventAssignor => eventAssignor
    )(state)
  );
};

export const isReportEventAssignor = state => {
  const eventId = getReportsEventId(state);
  return (
    !!eventId &&
    !!createSelector(
      isEventRole({ params: { eventId }, role: 'assignor' }),
      eventAssignor => eventAssignor
    )(state)
  );
};
export const isReportEventAssessor = state => {
  const eventId = getReportsEventId(state);
  return (
    !!eventId &&
    !!createSelector(
      isEventRole({ params: { eventId }, role: 'assessor' }),
      eventAssessor => eventAssessor
    )(state)
  );
};

export const isGamesEventOfficial = state => {
  const eventId = getGamesEventId(state) || getCalendarEventId(state);
  return (
    !!eventId &&
    !!createSelector(
      isEventOfficial({ params: { eventId } }),
      eventOfficial => eventOfficial
    )(state)
  );
};

export const isGamesEventAdminOrAssignor = state => {
  const eventId = getGamesEventId(state);

  return (
    !!eventId &&
    !!createSelector(
      isEventAdminOrAssignor({ params: { eventId } }),
      isAdminOrAssignor => isAdminOrAssignor
    )(state)
  );
};

export const isGamesEventOfficialOrAssessor = state => {
  const eventId =
    getGamesEventId(state) || getCalendarEventId(state) || getCurrentGamesEventId(state);
  return (
    !!eventId &&
    !!createSelector(
      isEventOfficialOrAssessor({ params: { eventId } }),
      isOfficialOrAssessor => isOfficialOrAssessor
    )(state)
  );
};

export const isNotificationEventOfficial = state => {
  const event = getNotificationEvent(state);
  return (
    !!event &&
    !!event.id &&
    !!createSelector(
      isEventOfficial({ params: { eventId: event.id } }),
      eventOfficial => eventOfficial
    )(state)
  );
};

export const isSuperAdmin = createSelector(getUser, ({ super_admin: superAdmin }) => superAdmin);
export const isGroupAdmin = createSelector(getUser, ({ groups }) => groups && !!groups.length);
export const isSuperAdminActiveUser = createSelector(
  getActiveUser,
  ({ super_admin: superAdmin }) => superAdmin
);
export const isFundingAdmin = createSelector(
  getUser,
  ({ funding_admin_events: fundingAdmin }) => !!fundingAdmin.length
);
export const fundingAdminEvents = createSelector(
  getUser,
  ({ funding_admin_events }) => funding_admin_events
);
export const adminEvents = createSelector(getUser, ({ admin_events }) => admin_events);
export const isFundingAdminActiveUser = createSelector(
  getActiveUser,
  ({ funding_admin_events: fundingAdmin }) => fundingAdmin && !!fundingAdmin.length
);
export const isAdmin = createSelector(getUser, ({ admin }) => admin);
export const isAssignor = createSelector(
  getActiveUser,
  ({ assignor_events }) => assignor_events && !!assignor_events.length
);
export const isAdminActiveUser = createSelector(getActiveUser, ({ admin }) => admin);
export const isActive = createSelector(getActiveUser, ({ active = true }) => active);
export const selectUserUssfId = createSelector(getUser, ({ ussf_id: ussfId }) => ussfId);
export const activeUserUssfId = createSelector(getActiveUser, ({ ussf_id }) => ussf_id);
export const selectUserUsername = createSelector(getUser, ({ username }) => username);
export const selectUserId = createSelector(getActiveUser, ({ id }) => Number(id));
export const selectProfileUserId = createSelector(getUser, ({ id }) => id);
export const hasSetUpComplete = createSelector(getUser, ({ payee }) => payee);

export const getUserContacts = createSelector(getActiveUser, ({ contacts: { data } = {} }) => data);
export const getUserAddresses = createSelector(
  getActiveUser,
  ({ addresses: { data } = {} }) => data
);
const mapAttributes = typeId =>
  pipe(
    find(({ attributes: { type_id } }) => type_id === typeId),
    defaultTo({}),
    ({ attributes = {}, id }) => ({ id, ...attributes }),
    reject(isNil)
  );
export const getUserContactsByTypeId = typeId =>
  createSelector(getUserContacts, (contacts = {}) => mapAttributes(typeId)(contacts));
export const getUserAddressesByTypeId = typeId =>
  createSelector(getUserAddresses, (contacts = {}) => mapAttributes(typeId)(contacts));

export const hasAvailability = createSelector(
  getActiveUser,
  ({ assessor_events: assEvents, official_events: offEvents }) =>
    assEvents || offEvents
      ? !!assEvents.length || !!offEvents.length
      : createSelector(
          getUser,
          ({ assessor_events, official_events }) =>
            !!assessor_events.length || !!official_events.length
        )
);
export const getAllUserEvents = ({ events }) => events.allUserEvents;

export const getAllUserAdminEvents = createSelector(
  getAllUserEvents,
  getAdminEvents,
  (userEvents, userAdminEvents) =>
    userEvents.filter(event => userAdminEvents.includes(Number(event.id)))
);

export const getAllInvitationEvents = createSelector(
  getAllUserEvents,
  getAdminEvents,
  (userEvents, userAdminEvents) =>
    userEvents.filter(
      event =>
        userAdminEvents.includes(Number(event.id)) &&
        event.game_upload_type === GAME_UPLOAD_TYPES.connect_game_sync
    )
);

export const canViewUserList = payload =>
  createSelector(
    isEventAdminOrAssignor(payload),
    getUser,
    (isResult, { super_admin }) => isResult || super_admin
  );

export const canEditEvent = payload =>
  createSelector(
    isEventAdmin(payload),
    getUser,
    (isResult, { super_admin }) => isResult || super_admin
  );

export const hasNoAccount = ({ auth }) => auth.noAccount;

const getRoleByPriority = data => {
  if (data.admin_events.length) return 'admin';
  if (data.assignor_events.length) return 'assignor';
  if (data.assessor_events.length) return 'accessor';
  if (data.official_events.length) return 'official';
  if (data.funding_admin_events.length) return 'funding admin';

  return null;
};

export const isNotFundingAdmin = () =>
  createSelector(getUser, state => getRoleByPriority(state) !== 'funding admin');

export const isPayee = () =>
  createSelector(getActiveUser, getUser, (activeUser, user) =>
    Object.keys(activeUser).length > 0 ? activeUser.payee || false : user.payee || false
  );

export const isLoggedInUser = () =>
  createSelector(getActiveUser, getUser, (activeUser, user) =>
    Object.keys(activeUser).length > 0
      ? (activeUser.id === user.id && activeUser.payee) || false
      : (user && user.payee) || false
  );

export const payeeStatus = () =>
  createSelector(getUser, state => (state && state.payee && state.payee.status) || false);

export const taxStatus = () =>
  createSelector(getUser, state => (state && state.taxStatus) || false);

export const getGroups = state => (state.auth && state.auth.groups) || [];

export const getPastEventBillingStatements = state =>
  (state.auth && state.auth.pastEventBillingStatements) || [];

export const getDueDateEventBillingStatements = state =>
  (state.auth && state.auth.dueDateEventBillingStatements) || [];

export const getRecentEventBillingStatements = state =>
  (state.auth && state.auth.recentEventBillingStatements) || [];

export const getUserGroups = createSelector(getUser, ({ groups }) => groups);

export const isAnyEventAdminOrAssessorOrAssignor = () => {
  return createSelector(getUser, ({ admin_events, assessor_events, assignor_events }) => {
    return (
      (admin_events && !!admin_events.length) ||
      (assessor_events && !!assessor_events.length) ||
      (assignor_events && !!assignor_events.length)
    );
  });
};

export const canViewReports = () =>
  createSelector(
    isAnyEventAdminOrAssessorOrAssignor(),
    getUserGroups,
    (anyEventAdminOrAssessorOrAssignor, groups) =>
      !!(anyEventAdminOrAssessorOrAssignor || groups.length)
  );

export const canViewPayments = () =>
  createSelector(
    isAnyEventAdminOrFundingAdmin(),
    getUserGroups,
    (anyEventAdminOrAssessorOrAssignor, groups) =>
      !!(anyEventAdminOrAssessorOrAssignor || groups.length)
  );

export const isAnyEventAssignorOrAssessorOrOfficial = createSelector(
  getActiveUser,
  ({ assessor_events: assEvents, official_events: offEvents, assignor_events: assignorEvents }) =>
    assEvents || offEvents || assignorEvents
      ? !!assEvents.length || !!offEvents.length || !!assignorEvents.length
      : createSelector(
          getUser,
          ({ assessor_events, official_events, assignor_events }) =>
            !!assessor_events.length || !!official_events.length || !!assignor_events.length
        )
);

export const crossViewTaxInfoCheck = createSelector(
  getActiveUser,
  getUser,
  isSuperAdmin,
  (activeUser, user, superAdmin) => {
    const { assessor_events, official_events, assignor_events } = user;

    const individualViewTaxInfo =
      !!assessor_events.length || !!official_events.length || !!assignor_events.length;
    const crossViewTaxInfo =
      activeUser.assessor_events || activeUser.official_events || activeUser.assignor_events
        ? !!activeUser.assessor_events.length ||
          !!activeUser.official_events.length ||
          !!activeUser.assignor_events.length
        : false;

    return (superAdmin && crossViewTaxInfo) || (activeUser.id === user.id && individualViewTaxInfo);
  }
);

export const crossPaymentMethodCheck = createSelector(
  getActiveUser,
  getUser,
  isSuperAdmin,
  (activeUser, user, superAdmin) => {
    return superAdmin || activeUser.id === user.id;
  }
);

export const crossEditPayeeCheck = () =>
  createSelector(getActiveUser, getUser, isSuperAdmin, (activeUser, user, superAdmin) => {
    const payee = Object.keys(activeUser).length > 0 ? activeUser.payee || false : false;
    return (superAdmin || activeUser.id === user.id) && payee;
  });

export const isLoggedInUserOrSuperAdmin = () =>
  createSelector(getActiveUser, getUser, (activeUser, user) => {
    return Object.keys(activeUser).length > 0
      ? (activeUser.id === user.id && activeUser.payee) ||
          (user.superAdmin && activeUser.payee) ||
          false
      : (user && user.payee) || false;
  });

export const getTimezonesList = ({ auth }) => auth && auth.timezonesList;

export const isSelectedEventAdminOrAssignor = state => {
  const eventId = getEventId(state);

  return (
    !!eventId &&
    !!createSelector(
      isEventAdminOrAssignor({ params: { eventId } }),
      isAdminOrAssignor => isAdminOrAssignor
    )(state)
  );
};

export const isAnyEventAssignor = () =>
  createSelector(getActiveUser, ({ assignor_events: assignorEvents }) =>
    assignorEvents
      ? !!assignorEvents.length
      : createSelector(getUser, ({ assignor_events }) => !!assignor_events.length)
  );

export const canViewDashboard = () =>
  createSelector(
    isSuperAdmin,
    isGroupAdmin,
    isAnyEventAssignorOrAssessorOrOfficial,
    isAnyEventAdmin(),
    (superAdmin, groupAdmin, assignOroffOrAss, admin) =>
      superAdmin || groupAdmin || assignOroffOrAss || admin
  );

export const getSelectedRole = ({ auth: { selectedRole } }) => selectedRole;
