import React from 'react';
import PropTypes from 'prop-types';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Wrapper,
  Name,
  Ammount,
  EmptyCellLabel,
  NonPaymentIcon,
  PaymentMouseOverPopoverWrapper
} from './styled-components';
import { calcOfficialTotal } from '../../helpers';
import { numToCurrency } from '../../../../../../utils/parsers';
import MouseOverPopover from '../../../../../global-material-components/Popover';
import RoundCheckbox from '../../../../RoundedCheckBox';

const PositionCell = ({
  assignment,
  label,
  togglePanel,
  adjustmentPanelOpenFor,
  basePay,
  setSelectedGame,
  setAllGamesCheck,
  assignor,
  assignorPay,
  reviewMode,
  requiredPaymentProfile,
  minTransferAmount,
  report_settings
}) => {
  if (assignment.status === 'pending' || assignment.status === 'accepted') {
    const {
      event_role: {
        user: { first_name, last_name, payee }
      },
      adjustments,
      travel_pay,
      batch_pay_staging: {
        in_batch,
        has_payment_profile,
        crew_verification_report: has_attendance
      } = {},
      batch_pay
    } = assignment;
    const { amount = 0 } = adjustments[0] || {};
    const { base_pay = 0 } = basePay;
    const selected = adjustmentPanelOpenFor === assignment.id;
    const travel_pay_amount = travel_pay ? travel_pay.amount : 0;
    const calcOfficialTotalArgs = reviewMode
      ? {
          assignor,
          base_pay: batch_pay.base_pay,
          adjustment: batch_pay.adjustments || 0,
          assignor_pay: assignorPay,
          travel_pay: batch_pay.travel_pay
        }
      : {
          assignor,
          base_pay,
          adjustment: amount,
          assignor_pay: assignorPay,
          travel_pay: travel_pay_amount
        };
    const total = reviewMode ? batch_pay.total_payment : calcOfficialTotal(calcOfficialTotalArgs);
    const shouldInBatch =
      in_batch &&
      ((has_payment_profile && payee && payee.transfers_enabled) || !requiredPaymentProfile) &&
      (report_settings && report_settings.allow_officials_to_checkin ? has_attendance : true);
    const isTransferEligible = total >= minTransferAmount;

    return (
      <Wrapper
        onClick={() => {
          if (!reviewMode) {
            setSelectedGame(null);
            setAllGamesCheck(false);
            togglePanel({ ...assignment, basePay, assignorPay });
          }
        }}
        selected={selected}
      >
        {!reviewMode && report_settings && report_settings.allow_officials_to_checkin ? (
          has_attendance ? (
            <RoundCheckbox
              name="checkedIn"
              selected={selected}
              value
              disabled={reviewMode ? false : !shouldInBatch}
            />
          ) : (
            <RoundCheckbox name="checkedIn" disabled />
          )
        ) : (
          ''
        )}
        <Ammount selected={selected} grayOut={reviewMode ? false : !shouldInBatch}>
          {numToCurrency(total)}
        </Ammount>
        <Name selected={selected} grayOut={reviewMode ? false : !shouldInBatch}>
          {first_name}
        </Name>
        <Name selected={selected} grayOut={reviewMode ? false : !shouldInBatch}>
          {last_name}
        </Name>

        {Boolean(!(payee && payee.transfers_enabled) && requiredPaymentProfile) && (
          <NonPaymentIcon>
            <MonetizationOnOutlinedIcon />
          </NonPaymentIcon>
        )}
        {!reviewMode && !isTransferEligible && (
          <PaymentMouseOverPopoverWrapper>
            <MouseOverPopover
              transformOriginHorizontal="left"
              messageTitle="Invalid Amount"
              messageBody={`Pay amount must be greater than or equal to $${
                minTransferAmount && minTransferAmount.toFixed(2)
              }`}
            >
              <InfoOutlinedIcon />{' '}
            </MouseOverPopover>
          </PaymentMouseOverPopoverWrapper>
        )}
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <EmptyCellLabel>{label}</EmptyCellLabel>
    </Wrapper>
  );
};

PositionCell.propTypes = {
  assignment: PropTypes.objectOf(Object),
  assignor: PropTypes.objectOf(Object),
  label: PropTypes.string.isRequired,
  togglePanel: PropTypes.func.isRequired,
  adjustmentPanelOpenFor: PropTypes.string,
  basePay: PropTypes.objectOf(Object),
  setSelectedGame: PropTypes.func,
  assignorPay: PropTypes.objectOf(Object),
  setAllGamesCheck: PropTypes.func.isRequired,
  reviewMode: PropTypes.bool.isRequired
};

PositionCell.defaultProps = {
  assignment: {
    event_role: {
      user: {
        first_name: null,
        last_name: null
      }
    }
  },
  assignor: null,
  adjustmentPanelOpenFor: null,
  basePay: {},
  setSelectedGame: () => null,
  assignorPay: {}
};

export default PositionCell;
