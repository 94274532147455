import { not } from 'ramda';
import { compose, lifecycle, withHandlers } from 'recompose';

export default compose(
  withHandlers({
    onSubmit: ({
      addCertifications,
      updateCertifications,
      ussf: { id: ussf_id, isValid },
      userId
    }) => values => {
      if (ussf_id && not(isValid)) updateCertifications({ ...values, userId });
      else addCertifications({ ...values, userId });
    },
    onLoad: ({ fetchCertifications, userId }) => values =>
      fetchCertifications({ ...values, userId }),
    onClear: ({ deleteCertifications, userId }) => values =>
      deleteCertifications({ ...values, userId })
  }),
  lifecycle({
    componentDidMount() {
      this.props.onLoad();
    }
  })
);
