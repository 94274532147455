import { AGREEMENT_LOCATIONS } from './constants';

export default {
  agreement: (state, action) => {
    const { doc_locations } = action.payload;
    const locationCode = doc_locations[0].location_code;
    if (locationCode === AGREEMENT_LOCATIONS.US_BANK_ACCOUNT_MANDATE) {
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          mandate: {
            fetching: false,
            data: action.payload
          }
        }
      };
    }

    return {
      ...state,
      agreement: action.payload
    };
  },
  agreementFetch: (state, action) => {
    const { locId: locationCode } = action.payload;
    if (locationCode === AGREEMENT_LOCATIONS.US_BANK_ACCOUNT_MANDATE) {
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          mandate: {
            ...state.paymentMethod.mandate,
            fetching: action.value
          }
        }
      };
    }
    return {
      ...state,
      isFetching: action.value
    };
  }
};
