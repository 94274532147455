import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import pluralize from 'pluralize';
import CollapsableContent from '../CollapsableContent';
import RecipientItem from './RecipientItem';
import TextArea from '../TextArea';
import Input from '../Input';
import Button from '../Button';
import { COLORS, ICONS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';
import {
  EmailSenderContainer,
  EmailSenderInnerContainer,
  SuccessMessageContainer,
  SuccessMessage,
  SuccessIcon,
  Content,
  RecipientList,
  ButtonContainer
} from './styled-components';
import Enhancer from '../../containers/EmailSender';

const buildCollapsableTitle = recipientQuantity =>
  `${recipientQuantity} ${pluralize('Recipient', recipientQuantity)}`;

const buildRecipientList = (userList, removeRecipient) =>
  userList.map(recipient => (
    <RecipientItem
      key={recipient.id}
      id={recipient.id}
      email={recipient.email}
      firstName={recipient.first_name}
      lastName={recipient.last_name}
      handleCloseIconClick={removeRecipient}
    />
  ));

const EmailSender = ({
  userList,
  removeRecipient,
  setSubject,
  setBody,
  subject,
  body,
  sendSuccess,
  inProgress,
  handleOnSubmit,
  onClose,
  allSelected,
  removeAllRecipients,
  config
}) => (
  <EmailSenderContainer height={sendSuccess ? 123 : 315}>
    {sendSuccess ? (
      <SuccessMessageContainer>
        <SuccessIcon height={30} width={30} icon={ICONS.CHECK_CIRCLE} color={COLORS.forestGreen} />
        <SuccessMessage>Email sent</SuccessMessage>
      </SuccessMessageContainer>
    ) : (
      <EmailSenderInnerContainer>
        <CollapsableContent
          title={allSelected ? `All ${config.label}s` : buildCollapsableTitle(userList.length)}
          theme={{
            headerBg: COLORS.blackSqueeze,
            headerMinHeight: `${pxToRem('30px')}`,
            headerMinPaddingTop: `${pxToRem('0px')}`,
            iconColorWhenClosed: COLORS.shuttleGrey,
            iconColorWhenOpen: COLORS.denimBlue,
            iconHeight: 12,
            iconWidth: 12,
            titleColorWhenClosed: COLORS.denimBlue,
            titleColorWhenOpen: COLORS.denimBlue,
            titleFont: 'Roboto',
            titleLetterSpacing: 'normal',
            titleSize: `${pxToRem('13px')}`,
            titleTransform: 'none',
            titleWeight: 'bold'
          }}
        >
          {allSelected ? (
            <RecipientList>
              <RecipientItem
                key={1}
                id={1}
                email={`All ${config.label}s`}
                firstName={`All ${config.label}s`}
                lastName=""
                handleCloseIconClick={removeAllRecipients}
              />
            </RecipientList>
          ) : (
            <RecipientList>{buildRecipientList(userList, removeRecipient)}</RecipientList>
          )}
        </CollapsableContent>
        <Content>
          <Input
            name="subject"
            value={subject}
            onChange={e => setSubject(e.target.value)}
            placeholder="Subject"
            noMargin
          />
          <TextArea
            placeholder="Message"
            rows={15}
            resize="none"
            input={{
              onChange: e => setBody(e.target.value),
              value: body
            }}
          />
          {!sendSuccess && (
            <ButtonContainer>
              <Button
                label="Send"
                onClick={handleOnSubmit}
                width="84px"
                height="32px"
                disabled={
                  isEmpty(subject.trim()) ||
                  isEmpty(body.trim()) ||
                  (!allSelected && isEmpty(userList)) ||
                  inProgress
                }
                primary
              />
              <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
            </ButtonContainer>
          )}
        </Content>
      </EmailSenderInnerContainer>
    )}
  </EmailSenderContainer>
);

EmailSender.propTypes = {
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      state: PropTypes.string
    })
  ).isRequired,
  sendSuccess: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  removeRecipient: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  setSubject: PropTypes.func.isRequired,
  setBody: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

EmailSender.defaultProps = {};

export default Enhancer(EmailSender);
