import { compose, pure, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { updateGameLevels, fetchEventInfoGameLevels } from '../../../../core/Events';
import { fetchSports } from '../../../../core/Sports';
import { getEventGameLevels, getEvent, getEventSports } from '../../../../core/Events/selectors';
import { paginationAware } from '../../PaginationAware';

const mapStateToProps = state => ({
  game_levels: getEventGameLevels(state),
  event: getEvent(state),
  assignedSport: getEventSports(state),
  isFetching: state.events.eventPageFetching
});

const mapDispatchToProps = dispatch => ({
  onLoad: eventId => dispatch(fetchEventInfoGameLevels(eventId)),
  updateGameLevels: payload => dispatch(updateGameLevels(payload)),
  fetchSports: payload => dispatch(fetchSports(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  paginationAware({
    fetcherQuote: 'fetchEventInfoGameLevels',
    forPrefix: 'gmlvls',
    metaQuote: 'game_levels'
  }),
  withHandlers({
    onSubmit: ({ updateGameLevels: updateGL }) => ({ game_levels }) =>
      new Promise(resolve => updateGL({ game_levels, resolve }))
  }),
  withState('currentId', 'setId', { val: '', eventId: null }),
  withState('currentIndex', 'setIndex', null),
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { eventId }
        },
        onLoad
      } = this.props;
      fetchSports();
      onLoad(eventId);
    }
  }),
  pure
);
