import { compose, lifecycle, withHandlers, withState } from 'recompose';

import { allEventsId } from '../../../constants';

export default compose(
  withState('userList', 'setUserList', ({ userList }) => userList),
  withState('showOptions', 'toggleShowOptions', false),
  withState('clickedOutside', 'setClickedOutside', false),
  withState('inProgress', 'setInProgress', false),
  withState('searchValue', 'setValue', ''),
  withHandlers({
    resolve: ({ setInProgress, setSendSuccess }) => result => {
      setInProgress(false);
      setSendSuccess(!!result);
    }
  }),
  withHandlers({
    removeUser: ({ userList, setUserList }) => recipientId =>
      setUserList(userList.filter(recipient => recipient.id !== recipientId)),
    removeAllUsers: ({ setUserList, toggleSelectAll }) => () => {
      setUserList([]);
      toggleSelectAll();
    },
    handleOnSubmit: ({
      resolve,
      setInProgress,
      eventId,
      role,
      userList,
      events,
      onSubmit,
      allSelected,
      sourceEventId,
      sourceRoleIds
    }) => () => {
      setInProgress(true);
      onSubmit({
        eventId,
        role_id: role,
        userList,
        events,
        resolve,
        allSelected,
        sourceEventId,
        sourceRoleIds
      });
    }
  }),

  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.clickedOutside !== prevProps.clickedOutside) {
        this.props.setValue(this.props.name);
      }
      const eventId = this.props.selectedEvent && this.props.selectedEvent.id;
      const prevEventId = prevProps.selectedEvent && prevProps.selectedEvent.id;
      if (eventId && (!prevEventId || prevEventId !== eventId) && eventId !== allEventsId) {
        this.props.setValue('');
      }
    }
  })
);
