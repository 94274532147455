import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SidePanel from '../SidePanel';
import logoConnect from '../../../assets/images/new-official-connect-logo-white-red.png';
import {
  Container,
  InnerContainer,
  Logo,
  LinksContainer,
  StyledNavLink,
  NavSpan
} from './styled-components';
import Enhancer from '../../containers/Header';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import { userIdxGetter } from '../../../utils/auth';
import { history } from '../../../core/store';
import { ROUTESLABEL } from '../../../constants';
import LeftMenu from './leftMenu';
import { COLORS } from '../../../utils/styles';
import NavDropDown from '../NavDropDown';
import { getNavRoutes } from './utils';

const Header = ({ onLogout, userProfile, persistRole, userRoles, selected, ...props }) => {
  const [toggle, setToggle] = React.useState(false);
  const [isDashboardActive, setIsDashboardActive] = React.useState(
    !!window.location.href.includes(ROUTESLABEL.dashboard.toLowerCase())
  );
  const [open, setOpen] = React.useState(false);
  const navRoutes = getNavRoutes({ userRoles, persistRole });
  React.useEffect(() => {
    if (!window.location.href.includes(ROUTESLABEL.dashboard.toLowerCase())) {
      persistRole({ role: '' });
    }
  });

  const toggleDrawer = () => {
    setToggle(false);
    setOpen(false);
  };

  const openDrawer = () => {
    setToggle(true);
  };

  const handleClick = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <Container>
      <Row center="md" middle="md">
        <Col xs={3} md={0} className="mobile">
          <IconButton
            edge="start"
            color="default"
            aria-label="open drawer"
            onClick={openDrawer}
            style={{ padding: '0px', margin: '0px 10px' }}
          >
            <span>
              <MenuIcon
                style={{
                  color: `${COLORS.white}`,
                  marginTop: '4px',
                  fontSize: '14px',
                  width: '30',
                  height: '30'
                }}
              />
            </span>
            <span className="menuLabel">Menu</span>
          </IconButton>
          <LeftMenu
            toggle={toggle}
            setToggle={setToggle}
            toggleDrawerHandler={toggleDrawer}
            navRoutes={navRoutes}
            props={props}
            userProfile={userProfile}
            onLogout={onLogout}
            setIsDashboardActive={setIsDashboardActive}
            open={open}
            setOpen={setOpen}
            selected={selected}
            isDashboardActive={isDashboardActive}
            handleClick={handleClick}
          />
        </Col>
        <Col xs={6} sm={6} md={6} lg={3} className="logo">
          <Logo src={logoConnect} alt="logoConnect" onClick={() => history.push('/dashboard')} />
        </Col>
        <Col md={5} className="desktop">
          {' '}
          <InnerContainer>
            <LinksContainer>
              {navRoutes.map(
                (route, i) =>
                  !route.class &&
                  (!route.requireAuth || props[route.requireAuth]) && (
                    <NavSpan key={i}>
                      {route.list && route.list.length ? (
                        <NavDropDown
                          title={route.label}
                          options={route.list}
                          setIsActive={setIsDashboardActive}
                          theme={{
                            heading: isDashboardActive ? `opacity: 1;` : 'opacity: 0.7;'
                          }}
                        />
                      ) : (
                        <StyledNavLink
                          isActive={route.isActive}
                          key={route.label}
                          to={routeBuilder({ userIdx: userIdxGetter() }, route.to)}
                          onClick={() =>
                            route.label === ROUTESLABEL.dashboard
                              ? setIsDashboardActive(true)
                              : setIsDashboardActive(false)
                          }
                        >
                          {route.label}
                        </StyledNavLink>
                      )}
                    </NavSpan>
                  )
              )}
            </LinksContainer>
          </InnerContainer>
        </Col>
        <Col xs={3} sm={3} md={3} lg={4}>
          <div className="sideMobilePanel">
            <SidePanel
              logout={onLogout}
              userProfile={userProfile}
              isDashboardActive={isDashboardActive}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

Header.propTypes = {
  onLogout: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    dob: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    gender: PropTypes.string,
    last_name: PropTypes.string,
    middle_name: PropTypes.string
  }).isRequired,
  canViewReports: PropTypes.bool.isRequired,
  canViewPayments: PropTypes.bool.isRequired,
  persistRole: PropTypes.func.isRequired,
  userRoles: PropTypes.shape({
    superAdmin: PropTypes.bool,
    groupAdmin: PropTypes.bool,
    admin: PropTypes.bool,
    assignor: PropTypes.bool,
    officialAssessor: PropTypes.bool,
    fundingAdmin: PropTypes.bool
  }).isRequired
};

export default Enhancer(Header);
