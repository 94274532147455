import React from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

import { ICONS, COLORS } from '../../../utils/styles';
import {
  Container,
  Content,
  OptionList,
  Option,
  OptionLabel,
  StyledIcon
} from './styled-components';
import Enhancer from '../../containers/ActionDropdown';

/**
 * Configurable dropdown for conditionally hidden and disabled actions
 * @param config {object} object with options property that is an array of objects which
 * must contain a label, an onClick handler, required showOption array of keys and
 * optional clickableOption array of keys for disabling/hiding based on conditions object
 * @param conditions {object} object with key-boolean pairs for disabling/hiding options
 * @param theme {object} *optional* CSS styles for component (see default for structure)
 */
export const ActionDropdown = ({ isOpen, toggle, config, conditions, theme }) => (
  <OutsideClickHandler onOutsideClick={() => isOpen && toggle(false)}>
    <Container>
      <StyledIcon
        width={20}
        height={20}
        icon={ICONS.THREE_DOTS}
        color={theme.icon.color}
        theme={theme.icon}
        onClick={() => toggle(v => !v)}
      />
      {isOpen && (
        <Content theme={theme.dropdown}>
          <OptionList>
            {config.options.map(
              ({ label, onClick, showOption, clickableOption }) =>
                showOption.every(c => conditions[c]) && (
                  <Option
                    theme={
                      !clickableOption || clickableOption.every(c => conditions[c])
                        ? theme.option
                        : theme.optionDisabled || theme.option
                    }
                    key={label}
                    onMouseDown={() => {
                      if (!clickableOption || clickableOption.every(c => conditions[c])) {
                        onClick(true);
                        toggle(false);
                      }
                    }}
                  >
                    <OptionLabel>{label}</OptionLabel>
                  </Option>
                )
            )}
          </OptionList>
        </Content>
      )}
    </Container>
  </OutsideClickHandler>
);

ActionDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  config: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        showOption: PropTypes.arrayOf(PropTypes.string),
        clickableOption: PropTypes.arrayOf(PropTypes.string)
      })
    )
  }).isRequired,
  conditions: PropTypes.shape({
    isEventAdmin: PropTypes.bool,
    selected: PropTypes.bool
  }).isRequired,
  theme: PropTypes.shape({
    icon: PropTypes.object.isRequired,
    dropdown: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired
  })
};

ActionDropdown.defaultProps = {
  theme: {
    icon: {
      color: COLORS.denimBlue,
      marginTop: '13px',
      marginRight: '28px'
    },
    dropdown: {
      topOffset: '48px',
      rightOffset: '0px',
      width: '125px'
    },
    option: {
      background: COLORS.white,
      color: COLORS.hawkesBlue,
      hoverBackground: COLORS.aliceBlueVariant,
      hoverColor: COLORS.denimBlue
    }
  }
};

export default Enhancer(React.memo(ActionDropdown));
