import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';

import Enhancer from '../../../../containers/ImportModal';
import Block, { Content } from './styled-components';
import UserToEventCopier from '../../../UserToEventCopier';

const CopyUsersModal = ({
  onClose,
  selected,
  onSubmit,
  allSelect,
  toggleSelectAll,
  config,
  removeUser,
  removeAllUsers,
  clearParams,
  isSuperAdmin,
  events,
  event_id: sourceEventId,
  role_ids: sourceRoleIds
}) => {
  const [userSelectedEvent, setUserSelectedEvent] = useState(null);
  const [role, setSelectedRole] = useState(null);

  return (
    <Modal
      title="Copy Selected Users"
      onClose={onClose}
      rightPanel
      background
      userList
      overflow="visible"
    >
      <Row>
        <Block xs={12}>
          <Content>
            <UserToEventCopier
              userList={selected}
              removeUser={removeUser}
              onSubmit={onSubmit}
              onClose={onClose}
              allSelected={allSelect}
              events={events}
              config={config}
              toggleSelectAll={toggleSelectAll}
              removeAllUsers={removeAllUsers}
              onSelectEvent={setUserSelectedEvent}
              selectedEvent={userSelectedEvent}
              setClickedOutside={false}
              clearParams={clearParams}
              eventId={userSelectedEvent ? userSelectedEvent.id : undefined}
              role={role}
              setSelectedRole={setSelectedRole}
              isSuperAdmin={isSuperAdmin}
              sourceEventId={sourceEventId}
              sourceRoleIds={sourceRoleIds}
            />
          </Content>
        </Block>
      </Row>
    </Modal>
  );
};

CopyUsersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  clearParams: PropTypes.func
};

CopyUsersModal.defaultProps = {
  clearParams: () => null
};

export default Enhancer(CopyUsersModal);
