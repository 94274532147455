import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { COLORS, hexToRgba, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

// z-index determines the order of overlapping elements */

export const Float = styled.div`
  position: fixed;
  top: ${pxToRem('65px')};
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  right: 0;
  padding: 0 ${pxToRem('50px')};
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ background }) =>
    background &&
    `-webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-image: linear-gradient(
    122deg,
    ${hexToRgba(COLORS.congressBlue, 0.9)},
    ${hexToRgba(COLORS.greenVogue, 0.9)}
  );`};

  ${({ rightPanel }) =>
    rightPanel &&
    `width: 33.10333333%;
    padding: 0;`};

  ${({ leftPanel }) =>
    leftPanel &&
    `width: calc(100vw - 33%);
    left: 0;`};
  ${mediaQueries.md(` {
     width:100%;
    }`)}
  ${({ wrapperStyle }) => `${wrapperStyle}`}
`;

export const Close = styled.button`
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: 0.6rem;
  right: 0;
  cursor: pointer;
`;

export const Back = styled.div`
  display: flex;
  justify: flex - start;
  position: absolute;
  height: 100 %;
  align: center;
  top: 0;
  left: 0.5rem;
`;
export const BackBtnWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  width: 50px;
  height: 20px;
`;

export const TitleWrapper = styled(Col)`
  background-image: linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue});
  position: relative;
`;

export const Title = styled.h3`
  color: ${COLORS.white};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : ``)};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : '')};
  text-transform: capitalize;
  margin: 1rem;
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}` : '')};
`;

export const Content = styled.div`
  ${({ absolute }) =>
    absolute
      ? `
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      `
      : `
      position: relative;
      margin-bottom: 1rem;
      `};

  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${pxToRem(marginBottom)}` : '')};
  ${({ light }) => (light ? 'font-family: RobotoLight' : '')};
  ${({ extraPadding }) => (extraPadding ? `padding-bottom: ${pxToRem('30px')}` : '')};
  &: last - child {
    margin: 0;
  }
`;
