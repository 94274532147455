import { connect } from 'react-redux';
import { compose } from 'recompose';

import { fetchGroupInfo, updateGroupInfo } from '../../../core/Auth';
import { isGroupAdmin, isSuperAdmin } from '../../../core/Auth/selectors';
import {
  fetchGroupBillingCustomers as fetchGroupBillingCustomersAction,
  updateBillingCustomer as updateBillingCustomerAction
} from '../../../core/BillingCustomers';
import {
  getGamePayerCustomers,
  getTaxPayerCustomers,
  payerBillingCustomerIsFetching
} from '../../../core/BillingCustomers/selector';
import { DASHBOARD } from '../../../core/paths';
import {
  getGroupPageRedirectLabel,
  getGroupPageRedirectPath,
  userIdxGetter
} from '../../../utils/auth';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import unconnected from './unconnected';

const DEFAULT_VALUES = {
  REDIRECT_LABEL: 'Back to Event Billing',
  REDIRECT_PATH: routeBuilder({ userIdx: userIdxGetter() }, DASHBOARD)
};

const mapStateToProps = (state, props) => ({
  groupInfo: state.auth.group.info,
  groupUsers: state.auth.group.users,
  groupSubscriptions: state.auth.group.subscriptions,
  isFetching: state.auth.group.isFetching,
  groupId: props && props.match && props.match.params && props.match.params.groupId,
  isGroupAdmin: isGroupAdmin(state),
  redirectPath: getGroupPageRedirectPath() || DEFAULT_VALUES.REDIRECT_PATH,
  redirectLabel: getGroupPageRedirectLabel() || DEFAULT_VALUES.REDIRECT_LABEL,
  isSuperAdmin: isSuperAdmin(state),
  gamePayerCustomers: getGamePayerCustomers(state),
  taxPayerCustomers: getTaxPayerCustomers(state),
  payerBillingCustomerIsFetching: payerBillingCustomerIsFetching(state)
});

const mapDispatchToProps = dispatch => ({
  fetchGroupInfo: payload => dispatch(fetchGroupInfo(payload)),
  updateGroupInfo: payload => dispatch(updateGroupInfo(payload)),
  fetchGroupBillingCustomers: payload => dispatch(fetchGroupBillingCustomersAction(payload)),
  updateBillingCustomer: payload => dispatch(updateBillingCustomerAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
