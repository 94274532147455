import COLORS from '../../../utils/styles';
import { HEADERS } from './UsersList/config';

export default {
  redirectButton: ({ redirectLabel, redirectPath }) => ({
    customStyle: {
      borderRadius: '5px',
      height: '2.4rem',
      width: '9rem',
      fontFamily: 'FiraSans'
    },
    type: 'button',
    margin: '0rem',
    noShadow: true,
    primary: true,
    to: redirectPath,
    label: redirectLabel
  }),

  cancelButton: ({ isEditing, setIsEditing, resetForm }) => ({
    label: 'Cancel',
    customStyle: `
    border-radius: 5px;
    height: 2.4rem;
    width: 5rem;
    font-family: Barlow;
    border: 1px solid ${COLORS.greyish};
    color: ${COLORS.greyish};
    font-size: 15px;
    ${`@media (max-width: 1760px) { margin: 1.2rem 0 0 39rem;}`}
    ${`@media (max-width: 1530px) { margin: 1.2rem 0 0 26rem;}`}
    `,
    type: 'reset',
    margin: '1.2rem 0 0 27rem',
    noShadow: true,
    onClick: () => {
      setIsEditing(!isEditing);
      resetForm();
    }
  }),

  saveButton: ({ handleSubmit, handleSave, values }) => ({
    label: 'Save',
    customStyle: {
      borderRadius: '5px',
      height: '2.4rem',
      width: '5rem',
      fontFamily: 'Barlow',
      border: `1px solid ${COLORS.denimBlue}`,
      fontSize: '15px'
    },
    type: 'submit',
    margin: `1.2rem 0 0 1rem`,
    noShadow: true,
    onClick: () => {
      handleSubmit(values);
      handleSave();
    }
  }),

  editButton: ({ handleEdit }) => ({
    label: 'Edit',
    customStyle: `
    border-radius: 5px;
    height: 2.4rem;
    width: 5rem;
    font-family: Barlow;
    border: 1px solid ${COLORS.denimBlue};
    font-size: 15px;
    ${`@media (max-width: 1760px) { margin: 1.2rem 0 0 44rem;}`}
    ${`@media (max-width: 1530px) { margin: 1.2rem 0 0 32rem;}`}

    `,
    type: 'button',
    margin: `1.2rem 0 0  33rem`,
    noShadow: true,
    onClick: () => handleEdit()
  }),

  addAdminButton: ({ handleAddGroupAdmin }) => ({
    label: 'Add Group Admin',
    customStyle: {
      borderRadius: '5px',
      height: '2.4rem',
      width: '10rem',
      fontFamily: 'Barlow',
      position: 'absolute'
    },
    type: 'button',
    noShadow: true,
    onClick: handleAddGroupAdmin,
    primary: true
  }),

  groupInfoTile: {
    customStyle: {
      border: '0.1rem solid',
      'border-color': COLORS.gray,
      'box-shadow': 'none',
      'border-radius': '4px'
    },
    border: true
  },
  groupAdmins: [
    {
      label: 'Group Admin',
      name: HEADERS.NAME,
      colSpan: 1
    },
    {
      label: 'Username',
      name: HEADERS.USER_NAME,
      colSpan: 1
    },
    {
      label: 'Last Login',
      name: HEADERS.LAST_LOGIN,
      colSpan: 1
    },
    {
      label: '',
      name: HEADERS.REMOVE,
      colSpan: 1
    }
  ],
  billingContacts: [
    {
      label: 'Billing/Company Name',
      name: 'billing_name',
      colSpan: 1
    },
    {
      label: 'Contact Name',
      name: 'name',
      colSpan: 1
    },
    {
      label: 'Contact Email',
      name: 'email',
      colSpan: 1
    },
    {
      label: '',
      name: HEADERS.EDIT,
      colSpan: 1
    }
  ],
  taxPayer: [
    {
      label: 'Payer Name',
      name: 'billing_name',
      colSpan: 1
    },
    {
      label: 'Payer Tax ID',
      name: 'tax_id',
      colSpan: 1
    },
    {
      label: '',
      name: HEADERS.EDIT,
      colSpan: 1
    }
  ]
};

export const MODALS = {
  ADD_GROUP_ADMIN: 'add',
  REMOVE_GROUP_ADMIN: 'remove'
};

export const DEFAULT_VALUES = {
  NOTE: 'Please add a preferred contact name'
};
