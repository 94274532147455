import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { range } from 'ramda';
import Button from '../../../../Button';
import PriceField from '../../../../PriceField';
import Dropdown from '../../Dropdown';
import theme, {
  PayTableContainer,
  InputsContainer,
  InputContainer,
  FieldInput,
  Table,
  HeaderCell,
  CellInput,
  FieldLabel,
  InnerCell,
  StyledTableRow,
  ButtonContainer,
  FormWrapper,
  Hint,
  OuterCell,
  ButtonsRow,
  FieldError,
  TravelPayRow
} from '../../styled-components';
import SubHeader from '../../SubHeader';
import { normalizePrice } from '../../../../../../utils/parsers';
import {
  formatPrices,
  requiredFeeValidator,
  copyFeesFromAssignor,
  assignorChange,
  copyFeesFromTravel,
  travelChange,
  copyFeesFromCrew,
  getClassName,
  maxMinValidator,
  composeValidators,
  percentValidator,
  valueValidator
} from '../../helpers';
import { convertPrices } from '../../../../../../utils/helpers';
import config from '../config';
import LoaderWrapper from '../../../../LoaderWrapper';

const PAYTABLE_SETTINGS = {
  assignor_pay: {
    fee_type: ['No Assignor Pay', 'Flat Rate', 'Percentage of Game Fees'],
    percentage_source: ['Game Fee Total Before Adjustments', 'Game Fee Total After Adjustments'],
    bill_to: ['Crew', 'Game']
  },
  travel_pay: {
    fee_type: ['No Travel Pay', 'Flat Rate']
  }
};

export const PayTable = ({ crew_pay, crew_size, crew_labels, inEdit, values }) => (
  <Table className={inEdit ? 'no-left-margin' : ''}>
    <thead>
      <tr>
        <HeaderCell>CREW</HeaderCell>
        {range(0, crew_labels && crew_labels.labels.length).map(i => (
          <HeaderCell key={i}>{crew_labels.labels[i]}</HeaderCell>
        ))}
        <HeaderCell className="bold">TOTAL</HeaderCell>
      </tr>
    </thead>
    <tbody>
      {crew_pay && (
        <StyledTableRow key={crew_pay.length}>
          <OuterCell>
            {crew_size} Crew Member{crew_size > 1 ? 's' : ''}
          </OuterCell>
          <FieldArray name="crew_pay">
            {({ fields }) => (
              <>
                {fields.value.slice(0, crew_size) &&
                  fields.value.map((v, i) =>
                    i < crew_pay.length ? (
                      <InnerCell
                        key={`crew_pay[${
                          v.crew_label_col ? v.crew_label_col.toString() : 0
                        }].base_pay`}
                      >
                        <Field
                          parse={value => normalizePrice(value, 4, false, false, true, false)}
                          name={`crew_pay[${
                            v.crew_label_col ? v.crew_label_col.toString() : 0
                          }].base_pay`}
                          placeholder="$0.00"
                          disabled={!crew_labels.role_ids[i]}
                          style={{ border: '1px solid #ccc' }}
                        >
                          {({ input, meta, ...rest }) => <CellInput {...input} {...rest} />}
                        </Field>
                      </InnerCell>
                    ) : null
                  )}
              </>
            )}
          </FieldArray>
          {range(0, -crew_size).map(n => (
            <InnerCell key={n} />
          ))}
          <>
            <InnerCell className="bold">
              {(() => {
                const crewPay = inEdit ? values.crew_pay : crew_pay;
                return normalizePrice(
                  crewPay
                    .reduce((acc, cp) => acc + convertPrices(cp).base_pay, 0)
                    .toFixed(2)
                    .toString(),
                  20,
                  true,
                  true
                );
              })()}
            </InnerCell>
          </>
        </StyledTableRow>
      )}
    </tbody>
  </Table>
);

PayTable.propTypes = {
  crew_pay: PropTypes.arrayOf(PropTypes.object),
  crew_size: PropTypes.number.isRequired,
  crew_labels: PropTypes.shape({
    labels: PropTypes.array,
    role_ids: PropTypes.array
  }),
  values: PropTypes.objectOf(Object),
  inEdit: PropTypes.bool
};

PayTable.defaultProps = {
  crew_pay: undefined,
  crew_labels: {
    labels: [],
    role_ids: []
  },
  values: undefined,
  inEdit: false
};

const PayTableCard = ({
  payTable: {
    assignor_pay = { fee_type: '', bill_to: '', free_amount: '' },
    travel_pay = { fee_type: '' },
    crew_pay,
    crew_size,
    copy_from = { assignor_pay: [], travel_pay: [], crew_pay: [] },
    labels,
    role_ids
  },
  otherTables,
  inEdit,
  onSubmit,
  cancelEdit,
  copyFeesFromGameLevel,
  crewPayFetching
}) => (
  <LoaderWrapper isFetching={crewPayFetching}>
    <PayTableContainer>
      {inEdit && (
        <>
          <FormWrapper>
            <Form
              onSubmit={onSubmit}
              initialValues={formatPrices({ assignor_pay, travel_pay, crew_pay })}
              mutators={{ ...arrayMutators }}
              render={({
                handleSubmit,
                submitting,
                reset,
                values,
                change,
                pristine,
                active,
                invalid,
                focus,
                blur
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <SubHeader
                      name="crew_pay_copy_from"
                      title="Game Crew Pay"
                      theme={theme.dropdownHeader}
                      list={otherTables.filter(t =>
                        copy_from.crew_pay.includes(parseInt(t.id, 10))
                      )}
                      focus={focus}
                      blur={blur}
                    />
                    <OnChange name="crew_pay_copy_from">
                      {(value, prev) => {
                        if (value && value !== prev) {
                          copyFeesFromGameLevel(value, gl =>
                            copyFeesFromCrew({ gl, change }, crew_size)
                          );
                        }
                      }}
                    </OnChange>
                  </Row>
                  <Row>
                    <PayTable
                      crew_pay={crew_pay}
                      crew_size={crew_size}
                      crew_labels={{ labels, role_ids }}
                      values={values}
                      inEdit
                    />
                  </Row>
                  <Row>
                    <SubHeader
                      name="assignor_pay.copy_from"
                      title="Assignor Pay"
                      theme={theme.dropdownHeader}
                      focus={focus}
                      blur={blur}
                      list={otherTables.filter(t =>
                        copy_from.assignor_pay.includes(parseInt(t.id, 10))
                      )}
                      wrapperStyle
                    />
                    <OnChange name="assignor_pay.copy_from">
                      {(value, prev) => {
                        if (value && value !== prev) {
                          copyFeesFromGameLevel(value, gl => copyFeesFromAssignor({ gl, change }));
                        }
                      }}
                    </OnChange>
                  </Row>
                  <Row>
                    <InputsContainer>
                      <Dropdown
                        label="Fee Type"
                        theme={theme.dropdownField}
                        name="assignor_pay.fee_type"
                        options={PAYTABLE_SETTINGS.assignor_pay.fee_type}
                        validate={value => !value && 'Fee type is required'}
                        focus={focus}
                        blur={blur}
                      />
                      <OnChange name="assignor_pay.fee_type">
                        {(value, prev) => assignorChange({ value, prev, active, change })}
                      </OnChange>
                      <Dropdown
                        label="Percentage Source"
                        theme={theme.dropdownField}
                        name="assignor_pay.percentage_source"
                        options={PAYTABLE_SETTINGS.assignor_pay.percentage_source}
                        validate={(value, allValues) =>
                          requiredFeeValidator(value, allValues, ['Percentage of Game Fees'])
                        }
                        focus={focus}
                        blur={blur}
                        className={getClassName({
                          values,
                          fee_types: ['Percentage of Game Fees']
                        })}
                      />
                      <Dropdown
                        label="Bill To"
                        theme={theme.dropdownField}
                        name="assignor_pay.bill_to"
                        options={PAYTABLE_SETTINGS.assignor_pay.bill_to}
                        validate={requiredFeeValidator}
                        focus={focus}
                        blur={blur}
                        className={getClassName({
                          values,
                          fee_types: ['Flat Rate', 'Percentage of Game Fees']
                        })}
                      >
                        {values.assignor_pay.bill_to === 'Crew' && (
                          <Hint>Automatically deduct fee from amount owed to crew</Hint>
                        )}
                        {values.assignor_pay.bill_to === 'Game' && (
                          <Hint>Add assignor fees to a billing statement</Hint>
                        )}
                      </Dropdown>
                      <InputContainer
                        className={getClassName({
                          values,
                          fee_types: ['Flat Rate', 'Percentage of Game Fees'],
                          className: 'input'
                        })}
                      >
                        <FieldLabel>Fee Amount</FieldLabel>
                        {values.assignor_pay.fee_type === 'Flat Rate' ? (
                          <>
                            <PriceField
                              name="assignor_pay.fee_amount"
                              max={4}
                              validate={requiredFeeValidator}
                              paddingLeft="50px"
                              alignLeft
                            />
                            <FieldLabel>per crew member per game</FieldLabel>
                          </>
                        ) : (
                          <>
                            <Field
                              name="assignor_pay.fee_amount"
                              validate={composeValidators(
                                requiredFeeValidator,
                                percentValidator,
                                valueValidator
                              )}
                            >
                              {({ input, meta, ...rest }) => (
                                <>
                                  <FieldInput
                                    type="number"
                                    min="1"
                                    max="100"
                                    {...input}
                                    {...rest}
                                  />
                                  {meta.error && (
                                    <FieldError paddingLeft="50px" alignLeft>
                                      {meta.error}
                                    </FieldError>
                                  )}
                                </>
                              )}
                            </Field>
                            <FieldLabel>% per crew member per game</FieldLabel>
                          </>
                        )}
                      </InputContainer>
                    </InputsContainer>
                  </Row>
                  <Row>
                    <SubHeader
                      name="travel_pay.copy_from"
                      title="Travel Pay"
                      theme={theme.dropdownHeader}
                      list={otherTables.filter(t =>
                        copy_from.travel_pay.includes(parseInt(t.id, 10))
                      )}
                      focus={focus}
                      blur={blur}
                    />
                    <OnChange name="travel_pay.copy_from">
                      {(value, prev) => {
                        if (value && value !== prev) {
                          copyFeesFromGameLevel(value, gl => copyFeesFromTravel({ gl, change }));
                        }
                      }}
                    </OnChange>
                  </Row>
                  <TravelPayRow>
                    <Dropdown
                      label="Fee Type"
                      theme={theme.dropdownField}
                      name="travel_pay.fee_type"
                      options={PAYTABLE_SETTINGS.travel_pay.fee_type}
                      validate={value => !value && 'Fee type is required'}
                      focus={focus}
                      blur={blur}
                    />
                    <OnChange name="travel_pay.fee_type">
                      {(value, prev) => travelChange({ value, prev, active, change })}
                    </OnChange>
                    <InputContainer
                      className={getClassName({
                        values,
                        className: 'input',
                        category: 'travel_pay'
                      })}
                    >
                      <FieldLabel>One way mileage rate</FieldLabel>
                      <PriceField
                        name="travel_pay.rate_mileage"
                        max={2}
                        validate={(value, allValues) =>
                          requiredFeeValidator(value, allValues, ['Flat Rate'], 'travel_pay')
                        }
                      />
                      <FieldLabel>per mile</FieldLabel>
                    </InputContainer>
                    <InputContainer
                      className={getClassName({
                        values,
                        className: 'input',
                        category: 'travel_pay'
                      })}
                    >
                      <FieldLabel>Min. Rate</FieldLabel>
                      <PriceField
                        hasLongHint
                        name="travel_pay.rate_min"
                        max={4}
                        validate={(value, allValues) =>
                          maxMinValidator({
                            value,
                            values: allValues,
                            fee_types: ['Flat Rate'],
                            category: 'travel_pay'
                          })
                        }
                      />
                      <Hint>Minimum paid to all crew members</Hint>
                    </InputContainer>
                    <InputContainer
                      className={getClassName({
                        values,
                        className: 'input',
                        category: 'travel_pay'
                      })}
                    >
                      <FieldLabel>Max. Rate</FieldLabel>
                      <PriceField
                        hasLongHint
                        name="travel_pay.rate_max"
                        max={4}
                        validate={(value, allValues) =>
                          maxMinValidator({
                            value,
                            values: allValues,
                            fee_types: ['Flat Rate'],
                            category: 'travel_pay'
                          })
                        }
                      />
                      <Hint>Maximum paid to all crew members</Hint>
                    </InputContainer>
                  </TravelPayRow>
                  <ButtonsRow>
                    <ButtonContainer>
                      <Button
                        {...config.updateButton({ handleSubmit })}
                        primary
                        disabled={submitting || pristine || invalid}
                      />
                    </ButtonContainer>
                    <ButtonContainer>
                      <Button {...config.cancelButton({ cancelEdit, reset })} />
                    </ButtonContainer>
                  </ButtonsRow>
                </form>
              )}
            />
          </FormWrapper>
        </>
      )}
    </PayTableContainer>
  </LoaderWrapper>
);

PayTableCard.propTypes = {
  payTable: PropTypes.shape({
    id: PropTypes.string,
    game_level: PropTypes.string,
    game_type: PropTypes.string,
    assignor_pay: PropTypes.object,
    travel_pay: PropTypes.object,
    crew_pay: PropTypes.arrayOf(PropTypes.object),
    crew_size: PropTypes.number,
    copy_from: PropTypes.object
  }).isRequired,
  inEdit: PropTypes.bool.isRequired,
  otherTables: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  copyFeesFromGameLevel: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired
};

export default PayTableCard;
