import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import MaterialInput from '../../MaterialInput';
import { FieldWrapper } from '../styled-components';
import { Img, IconWrapper, Label, Resend } from './styled-components';
import { ICONS } from '../../../../utils/styles';
import Icon from '../../Icon';
import Enhancer from '../../../containers/Avatar';
import ChangePassword from './Modals/ChangePassword';
import ChangeUsername from './Modals/ChangeUsername';

export const Avatar = ({
  username,
  password,
  isActive,
  toggleModal,
  modalVisibility,
  onChangePassword,
  onChangeEmail,
  resendEmail,
  currentUserId,
  userId
}) => (
  <Row>
    {modalVisibility.password && (
      <ChangePassword onClose={() => toggleModal('password')} onSubmit={onChangePassword} />
    )}
    {modalVisibility.username && (
      <ChangeUsername
        onClose={() => toggleModal('username')}
        onSubmit={onChangeEmail}
        initialValues={{ current_username: username }}
      />
    )}
    <Col xs={12} md={8} lg={6}>
      <Row center="xs" middle="xs">
        <Col xs={4}>
          <Img alt="avatar" src="https://via.placeholder.com/150x150" />
        </Col>
        <Col xs={8}>
          <FieldWrapper>
            <Row>
              <Col xs={10}>
                <MaterialInput
                  name="username"
                  placeholder="Username"
                  input={{ value: username }}
                  readOnly
                />
              </Col>
              <Col xs={2}>
                <IconWrapper>
                  <Icon
                    icon={ICONS.EDIT}
                    noMargin
                    width={20}
                    onClick={() => toggleModal('username')}
                  />
                </IconWrapper>
              </Col>
            </Row>
            {!isActive && (
              <Row between="xs">
                <Col xs={4}>
                  <Label>Username not verified</Label>
                </Col>
                <Col xs={5}>
                  <Resend onClick={() => resendEmail()}>Resend Verification Email</Resend>
                </Col>
              </Row>
            )}
          </FieldWrapper>
          <FieldWrapper>
            <Row>
              <Col xs={10}>
                <MaterialInput
                  name="password"
                  type="password"
                  placeholder="Password"
                  input={{ value: password }}
                  readOnly
                />
              </Col>
              {currentUserId === userId && (
                <Col xs={2}>
                  <IconWrapper>
                    <Icon
                      icon={ICONS.EDIT}
                      noMargin
                      width={20}
                      onClick={() => toggleModal('password')}
                    />
                  </IconWrapper>
                </Col>
              )}
            </Row>
          </FieldWrapper>
        </Col>
      </Row>
    </Col>
  </Row>
);

Avatar.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  isActive: PropTypes.bool,
  modalVisibility: PropTypes.shape({
    username: PropTypes.bool,
    password: PropTypes.bool
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  resendEmail: PropTypes.func.isRequired
};

Avatar.defaultProps = {
  username: '',
  password: '',
  isActive: false
};

export default Enhancer(Avatar);
