import styled from 'styled-components';
import { pxToRem } from '../../../../utils/helpers';
import { COLORS, HEIGHTS, mediaQueries } from '../../../../utils/styles';
import Icon from '../../Icon';

export const Wrapper = styled.div`
  box-sizing: border-box;
  top: 10px;
  position: absolute;
  right: 4%;
  top: ${pxToRem('66px')};
  width: 92%;
  border-radius: ${pxToRem('6px')};
  max-height: calc(100vh - ${HEIGHTS.appHeader} - ${HEIGHTS.paginationBar});
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.45);
  z-index: 10000000;
  ${mediaQueries.md(` {
    position: fixed;
    top: ${pxToRem('90px')};
    background:#fff;
  }`)}
`;

export const Header = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${pxToRem('40px')};
  padding-left: ${pxToRem('14px')};
  border-bottom: 1px solid ${COLORS.lightCyanGray};
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  border-top-left-radius: ${pxToRem('6px')};
  border-top-right-radius: ${pxToRem('6px')};
`;

export const HeaderTextError = styled.span`
  font-family: 'RobotoBold';
  font-size: ${pxToRem('15px')};
  color: ${COLORS.venetianRed};
  text-transform: uppercase;
`;

export const HeaderTextWarning = styled.span`
  font-family: 'RobotoBold';
  font-size: ${pxToRem('15px')};
  color: ${COLORS.warningYellow};
  text-transform: uppercase;
`;

export const NotificationRow = styled.div`
  cursor: default;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-family: 'Roboto';
  box-sizing: border-box;
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});

  padding: ${pxToRem('5px')} ${pxToRem('14px')};

  ${({ isExpanded }) => (isExpanded ? 'box-shadow: -1px 4px 5px 0px rgba(0,0,0,0.31)' : '')};
  ${({ noBorder }) => (noBorder ? '' : `border-bottom: 1px solid ${COLORS.lightCyanGray}`)};

  &:last-child {
    border-bottom-left-radius: ${pxToRem('6px')};
    border-bottom-right-radius: ${pxToRem('6px')};
  }
`;

export const Summary = styled.span`
  font-size: ${pxToRem('15px')};
  color: ${COLORS.cobaltGrey};
`;

export const OrgName = styled.span`
  color: ${COLORS.denimBlue};
`;

export const Label = styled.p`
  margin-top: 0;
  margin-bottom: ${pxToRem('4px')};
  font-size: ${pxToRem('11px')};
  font-family: 'RobotoCondensed';
  color: ${COLORS.shuttleGray};
  opacity: 0.72;
`;

export const Value = styled.p`
  margin-top: 0;
  margin-bottom: ${pxToRem('4px')};
  font-size: ${pxToRem('13px')};
  color: ${COLORS.nightRider};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Close = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: ${pxToRem('10px')};
  right: ${pxToRem('14px')};
  margin: 0;
`;

export const Message = styled.p`
  color: ${COLORS.shuttleGray};
  font-size: ${pxToRem('13px')};
  margin: 0px;
`;

export const Name = styled.span`
  font-family: 'RobotoBold';
`;
export const ButtonWrapper = styled.div``;
