import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Flex from '../../Flex';
import EventFilter from '../../EventFilter';
import theme from '../styled-components';
import { PeopleActionItemContainer, PeopleActionItem } from './styled-components';
import { COLORS, HEIGHTS, ICONS } from '../../../../utils/styles';
import Icon from '../../Icon';
import { generateRefreshQuery } from '../../../../utils/helpers';
import PeopleFilter from './PeopleFilter';
import PeopleSorter from './PeopleSorter';
import Search from '../../Search';
import ActionDropdown from '../../ActionDropdown';

const Header = ({
  storePeopleEvent,
  peopleEvent,
  filter,
  sort: { onSort, value: sortingValue },
  location,
  clearParams,
  categories,
  search: { onSearch, value },
  config,
  conditions,
  isNotAllEvents
}) => {
  return (
    <Flex
      bgImage="linear-gradient(to top, #05579d, #167cd4)"
      height={HEIGHTS.eventNavigationBar}
      justifyContent="space-between"
      padding="0 1.5rem 0 0"
      fullWidth
      noShrink
    >
      <Flex>
        <EventFilter
          onSelectEvent={storePeopleEvent}
          clearParams={clearParams}
          selectedEvent={peopleEvent}
        />
        <PeopleSorter onSort={onSort} value={sortingValue} />
        <Flex theme={theme.dropdownContainer} height={HEIGHTS.eventNavigationBar}>
          <PeopleFilter {...filter} categories={categories} isNotAllEvents={isNotAllEvents} />
        </Flex>
      </Flex>
      <PeopleActionItemContainer justifyContent="space-between">
        <PeopleActionItem full maxWidth="120px">
          <Search
            iconHeight={14}
            iconWidth={14}
            iconColor={COLORS.white}
            fieldColor={COLORS.white}
            onSearch={onSearch}
            value={value}
          />
        </PeopleActionItem>
        <PeopleActionItem>
          <NavLink to={`${location.pathname}${generateRefreshQuery(location.search)}`}>
            <Icon height={18} width={18} icon={ICONS.REFRESH} color={COLORS.white} />
          </NavLink>
        </PeopleActionItem>
        <PeopleActionItem>
          <ActionDropdown theme={theme.action} config={config.action} conditions={conditions} />
        </PeopleActionItem>
      </PeopleActionItemContainer>
    </Flex>
  );
};

Header.propTypes = {
  peopleEvent: PropTypes.shape({
    id: PropTypes.string
  }),
  storePeopleEvent: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    searchValue: PropTypes.string
  }).isRequired
};

Header.defaultProps = {
  peopleEvent: null
};

export default Header;
