import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import {
  updateUserAddress,
  deleteUserAddress,
  addPayeeAccount,
  editPayeeAccount,
  deletePayeeAccount
} from '../../../core/Auth';
import { fetchActiveUserInfo } from '../../../core/User';
import { getActiveUser, getUser, isFundingAdmin } from '../../../core/Auth/selectors';

const mapStateToProps = (state, props) => ({
  userId: props && props.match && props.match.params && props.match.params.userId,
  payeeId:
    state.user &&
    state.user.activeUser &&
    state.user.activeUser.payee &&
    state.user.activeUser.payee.id,
  userIdx: state.auth && state.auth.userIdx,
  auth: state.auth,
  isFetching: state.auth && state.auth.isFetching,
  isFetchingPayee: state.auth && state.auth.isFetchingPayee,
  addresses:
    state.user &&
    state.user.activeUser &&
    state.user.activeUser.addresses &&
    state.user.activeUser.addresses.data,
  bankAccounts: state.auth && state.auth.activeUser && state.auth.activeUser.bankAccounts,
  mailToAddress:
    (state.user &&
      state.user.activeUser &&
      state.user.activeUser.addresses &&
      state.user.activeUser.addresses.data &&
      state.user.activeUser.addresses.data.find(address => address.attributes.is_mailing)) ||
    {},
  currentUserId: state.auth && state.auth.user && state.auth.user.id,
  pathUrl: props && props.match && props.match.path,
  path: props && props.match && props.match.url,
  paramId: getUser(state).id || getActiveUser(state).id,
  isFundingAdmin: isFundingAdmin(state),
  isPaidThroughSO: state.user.activeUser && state.user.activeUser.is_paid_through_so
});
const mapDispatchToProps = dispatch => ({
  updateUserAddressAction: info => dispatch(updateUserAddress(info)),
  deleteUserAddressAction: info => dispatch(deleteUserAddress(info)),
  addPayeeAccountAction: info => dispatch(addPayeeAccount(info)),
  editPayeeAccountAction: info => dispatch(editPayeeAccount(info)),
  deletePayeeAccountAction: info => dispatch(deletePayeeAccount(info)),
  fetchActiveUserInfo: payload => dispatch(fetchActiveUserInfo(payload))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
