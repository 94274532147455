import * as React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import Authorize from '../views/containers/Authorize';

// Helper to pass the sub-routes down to keep nesting
import RouteWithSubRoutes from '../views/components/RouteWithSubRoutes';

import Auth from '../views/components/Auth';
import Main from '../views/components/Main';
import SignIn from '../views/components/SignIn';
import CreateAccount from '../views/components/CreateAccount';
import SuccessMessage from '../views/components/SuccessMessage';
import Profile, {
  CertificationPage,
  PersonalPage,
  AvailabilityPage
} from '../views/components/Profile';
import AvailableBalancePage from '../views/components/Profile/AvailableBalance';
import PaymentHistoryPage from '../views/components/Profile/PaymentHistory';
// import PaymentMethodPage from '../views/components/Profile/PaymentMethod';
import PaymentMethodPage from '../views/components/PaymentProfile';
import PayeeProfile from '../views/components/Profile/PaymentMethod/Payee';
import Events, {
  OfficialList,
  UsersList,
  AssessorList,
  LocationList,
  TeamList
} from '../views/components/Events';
import Games from '../views/components/Games';
import Reports from '../views/components/Reports';
import Payments from '../views/components/Payments';
import BatchEdit from '../views/components/Payments/BatchEdit';
import People from '../views/components/People';
import EventPage, {
  InformationForm,
  EventRolesPage,
  GameLevels,
  // CrewLabels,
  AssignorSettings
} from '../views/components/Event';
import Categories from '../views/components/Event/Categories';
import EventReports from '../views/components/Event/Reports';
import EventAssessments from '../views/components/Event/Assessments';
import CrewPay from '../views/components/Event/CrewPay';
import {
  // canEditEvent,
  // canViewUserList,
  hasAvailability,
  isNotFundingAdmin,
  canViewReports,
  canViewPayments,
  isAnyEventAssignorOrAssessorOrOfficial,
  isGroupAdmin,
  canViewDashboard,
  crossViewTaxInfoCheck,
  crossPaymentMethodCheck,
  crossEditPayeeCheck,
  isLoggedInUserOrSuperAdmin
} from './Auth/selectors';
import StyleGuide from '../views/components/Styles';
import * as paths from './paths';
import Dashboard from '../views/components/Dashboards';
import TaxInfo from '../views/components/TaxInfo';
import GroupInfo from '../views/components/GroupInfo';
import OnBoarding from '../views/components/OnBoarding';
import ProfileInformation from '../views/components/ProfileInformation';

export const PUBLIC_URL = paths.AUTH;
export const PRIVATE_URL = paths.DASHBOARD;

const routeConfig = [
  {
    component: Auth,
    path: paths.AUTH,
    routes: [
      {
        component: SignIn,
        exact: true,
        path: paths.LOGIN
      },
      {
        component: CreateAccount,
        exact: true,
        path: paths.CREATE_ACCOUNT
      },
      {
        component: SuccessMessage,
        exact: true,
        path: paths.SUCCESS
      },
      {
        component: () => <Redirect to={paths.LOGIN} />,
        path: '*'
      }
    ]
  },
  {
    component: Main,
    path: paths.MAIN,
    isPrivate: true,
    routes: [
      {
        component: OnBoarding,
        path: paths.ON_BOARDING,
        exact: true,
        routes: [
          {
            component: ProfileInformation,
            exact: true,
            path: paths.START_PROFILE_INFORMATION
          },
          {
            component: props => (
              <Authorize
                component={PayeeProfile}
                redirectPath={paths.ON_BOARDING}
                selector={() => isAnyEventAssignorOrAssessorOrOfficial}
                {...props}
              />
            ),
            exact: true,
            path: paths.START_PROFILE_PAYMENT_METHOD
          },
          {
            component: props => (
              <Authorize
                component={TaxInfo}
                redirectPath={paths.ON_BOARDING}
                selector={() => isAnyEventAssignorOrAssessorOrOfficial}
                {...props}
              />
            ),
            exact: true,
            path: paths.START_TAX_INFO
          }
        ]
      },
      {
        component: props => (
          <Authorize
            component={Dashboard}
            redirectPath={paths.LOGIN}
            selector={() => canViewDashboard()}
            {...props}
          />
        ),
        exact: true,
        path: paths.DASHBOARD
      },
      {
        component: props => (
          <Authorize
            component={GroupInfo}
            redirectPath={paths.DASHBOARD}
            selector={() => isGroupAdmin}
            {...props}
          />
        ),
        exact: true,
        path: paths.GROUP_INFO
      },
      {
        component: props => (
          <Authorize
            component={Games}
            redirectPath={paths.DASHBOARD}
            selector={isNotFundingAdmin}
            {...props}
          />
        ),
        exact: true,
        path: paths.GAMES
      },
      {
        component: props => (
          <Authorize
            component={Reports}
            redirectPath={paths.DASHBOARD}
            selector={canViewReports}
            {...props}
          />
        ),
        exact: true,
        path: paths.REPORTS
      },
      {
        component: Profile,
        path: paths.PROFILE,
        routes: [
          {
            component: PersonalPage,
            exact: true,
            path: paths.PROFILE_INFORMATION,
            title: 'Personal Info',
            showRoute: [],
            icon: 'info',
            type: 'profile'
          },
          {
            component: props => (
              <Authorize
                component={CertificationPage}
                redirectPath={paths.PROFILE_INFORMATION}
                selector={() => isAnyEventAssignorOrAssessorOrOfficial}
                {...props}
              />
            ),
            exact: true,
            path: paths.PROFILE_CERTIFICATIONS,
            title: 'Certifications',
            showRoute: ['isAnyEventAssignorOrAssessorOrOfficial'],
            icon: 'grade',
            type: 'profile'
          },
          {
            component: props => (
              <Authorize
                component={AvailabilityPage}
                redirectPath={paths.PROFILE_INFORMATION}
                selector={() => hasAvailability}
                {...props}
              />
            ),
            exact: true,
            path: paths.PROFILE_AVAILABILITY,
            title: 'Availability',
            showRoute: ['hasAvailability'],
            icon: 'alarm_on',
            type: 'profile'
          },
          {
            // component: AvailableBalancePage,
            component: props => {
              return (
                <Authorize
                  component={AvailableBalancePage}
                  redirectPath={paths.PROFILE_INFORMATION}
                  selector={() => isLoggedInUserOrSuperAdmin}
                  {...props}
                />
              );
            },
            exact: true,
            path: paths.PROFILE_AVAILABLE_BALANCE,
            title: 'Available Balance',
            showRoute: ['crossEditPayeeCheck'],
            icon: 'account_balance',
            type: 'paymentInfo'
          },
          {
            // component: PaymentHistoryPage,
            component: props => (
              <Authorize
                component={PaymentHistoryPage}
                redirectPath={paths.PROFILE_INFORMATION}
                selector={crossEditPayeeCheck}
                {...props}
              />
            ),
            exact: true,
            path: paths.PROFILE_PAYMENT_HISTORY,
            title: 'Payment History',
            showRoute: ['crossEditPayeeCheck'],
            icon: 'settings_backup_restore',
            type: 'paymentInfo'
          },
          {
            component: props => (
              <Authorize
                component={PaymentMethodPage}
                selector={() => crossPaymentMethodCheck}
                {...props}
              />
            ),
            exact: true,
            path: paths.PROFILE_PAYMENT_METHOD,
            title: 'Payment Profile',
            showRoute: ['crossPaymentMethodCheck'],
            icon: 'attach_money',
            type: 'paymentInfo'
          },
          {
            component: props => (
              <Authorize
                component={TaxInfo}
                redirectPath={paths.PROFILE_PAYMENT_METHOD}
                selector={() => crossViewTaxInfoCheck}
                {...props}
              />
            ),
            exact: true,
            path: paths.TAX_INFO,
            title: 'Tax Info',
            showRoute: ['crossViewTaxInfoCheck'],
            icon: 'sticky_note_2',
            type: 'paymentInfo'
          }
        ]
      },
      {
        component: Events,
        path: paths.EVENTS,
        routes: [
          {
            component: OfficialList,
            // component: passedProps => (
            //   <Authorize
            //     component={OfficialList}
            //     redirectPath={paths.EVENTS}
            //     selector={canViewUserList}
            //     role={passedProps.role}
            //     {...passedProps['0']}
            //   />
            // ),
            exact: true,
            role: 'official',
            path: paths.EVENTS_OFFICIALS
          },
          {
            component: UsersList,
            // component: passedProps => (
            //   <Authorize
            //     component={UsersList}
            //     redirectPath={paths.EVENTS}
            //     selector={canViewUserList}
            //     role={passedProps.role}
            //     {...passedProps['0']}
            //   />
            // ),
            exact: true,
            role: 'user',
            path: paths.EVENTS_USERS
          },
          {
            component: AssessorList,
            // component: passedProps => (
            //   <Authorize
            //     component={AssessorList}
            //     redirectPath={paths.EVENTS}
            //     selector={canViewUserList}
            //     role={passedProps.role}
            //     {...passedProps['0']}
            //   />
            // ),
            exact: true,
            role: 'assessor',
            path: paths.EVENTS_ASSESSOR
          },
          {
            component: LocationList,
            exact: true,
            path: paths.EVENTS_LOCATION
          },
          {
            component: TeamList,
            exact: true,
            path: paths.EVENTS_TEAM
          }
        ]
      },
      {
        component: EventPage,
        // component: props => (
        //   <Authorize
        //     component={EventPage}
        //     redirectPath={paths.EVENTS}
        //     selector={canEditEvent}
        //     {...props}
        //   />
        // ),
        path: paths.SINGLE_EVENT,
        routes: [
          {
            title: 'Information',
            component: InformationForm,
            exact: true,
            path: paths.EVENT_INFORMATION
          },
          {
            title: 'Roles/Permissions',
            component: EventRolesPage,
            exact: true,
            path: paths.EVENT_ROLES
          },
          {
            title: 'Categories',
            component: Categories,
            exact: true,
            path: paths.EVENT_CATEGORIES
          },
          {
            title: 'Assignor Settings',
            component: AssignorSettings,
            exact: true,
            path: paths.EVENT_ASSIGNOR_SETTINGS
          },
          // Removed the component CREW LABELS
          // {
          //   title: 'Crew Labels',
          //   component: CrewLabels,
          //   exact: true,
          //   path: paths.EVENT_CREW_LABELS
          // },
          {
            title: 'Game Levels & Types',
            component: GameLevels,
            exact: true,
            path: paths.EVENT_GAME_LEVELS
          },
          {
            title: 'After Game Reports',
            component: EventReports,
            exact: true,
            path: paths.EVENT_AFTER_GAME_REPORTS
          },
          {
            title: 'Assessments',
            component: EventAssessments,
            exact: true,
            path: paths.EVENT_ASSESSMENTS
          },
          {
            title: 'Crew Pay',
            component: CrewPay,
            exact: true,
            path: paths.EVENT_CREW_PAY
          }
        ]
      },
      {
        component: props => (
          <Authorize
            component={Payments}
            redirectPath={paths.DASHBOARD}
            selector={canViewPayments}
            {...props}
          />
        ),
        exact: true,
        path: paths.PAYMENTS
      },
      {
        component: BatchEdit,
        exact: true,
        path: paths.PAYMENTS_BATCH_EDIT
      },
      {
        component: BatchEdit,
        exact: true,
        path: paths.PAYMENTS_BATCH_REVIEW
      },
      {
        component: StyleGuide,
        exact: true,
        path: paths.STYLE_GUIDE
      },
      {
        component: People,
        exact: true,
        path: paths.PEOPLE
      }
    ]
  },
  {
    component: () => '404',
    path: '*'
  }
];

export default history => (
  <Router basename="/" history={history}>
    <Switch>
      {routeConfig.map(({ path, ...route }) => (
        <RouteWithSubRoutes key={path} path={path} {...route} />
      ))}
    </Switch>
  </Router>
);
