import React from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Container,
  Wrapper,
  InnerContainer,
  Heading,
  CreatedOn,
  NoteWrapper,
  NoteContainer,
  NoteLabel,
  UserListWrapper,
  GroupInfoContainer,
  NoteFieldContainer,
  TableHeading,
  AddAdminSpan
} from './styled-components';
import Button, { ButtonAsLink } from '../Button';
import COLORS from '../../../utils/styles';
import Enhancer from '../../containers/GroupInfo';
import LoaderWrapper from '../LoaderWrapper';
import config, { MODALS, DEFAULT_VALUES } from './config';
import GroupUsersList from './UsersList';
import TextArea from '../TextArea';
import GroupInfo from './groupInfoTile';
import Modal from './Modals';
import BillingCustomer from '../Event/InformationForm/Modal';
import taxConfig from '../Event/InformationForm/TaxesSection/config';
import billingConfig from '../Event/InformationForm/BillingSection/config';
import { EVENT_BILLING_CUSTOMER_TYPE } from '../../../constants';
import { sortListByKey } from '../../../utils/helpers';

const Note = ({ isEditing, note }) => (
  <NoteWrapper>
    <NoteContainer>
      {' '}
      <NoteLabel isHeading>Note:</NoteLabel>
      <NoteLabel style={{ width: 400, padding: '8px 10px 5px 3px' }}>
        {isEditing ? (
          <Field
            component={TextArea}
            name="note"
            customStyle={`background-color: ${COLORS.white}; color: black;`}
            placeholder={DEFAULT_VALUES.NOTE}
          />
        ) : (
          note
        )}
      </NoteLabel>
    </NoteContainer>
  </NoteWrapper>
);

Note.propTyes = {
  isEditing: PropTypes.bool,
  note: PropTypes.string
};

Note.defaultProps = {
  isEditing: false,
  note: DEFAULT_VALUES.NOTE
};

const UserInfoTable = ({ users, onButtonClick, heading, headers, isFetching }) => (
  <UserListWrapper justifyContent="left" padding="30px 450px 0 450px" className="userList">
    <TableHeading>{heading}</TableHeading>
    <GroupUsersList
      users={users}
      onButtonClick={onButtonClick}
      headers={headers}
      isFetching={isFetching}
    />
  </UserListWrapper>
);

const GroupInfoPage = ({
  redirectPath,
  redirectLabel,
  isFetching,
  groupData,
  groupUsers,
  onSubmit,
  isSuperAdmin,
  gamePayerCustomers,
  taxPayerCustomers,
  updateBillingCustomer,
  groupId,
  payerBillingCustomerIsFetching
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [modalType, setModalType] = React.useState('');
  const [payerCustomerModal, setPayerCustomerModal] = React.useState(false);
  const [billingCustomer, setBillingCustomer] = React.useState({});
  const [billingCustomerType, setBillingCustomerType] = React.useState('');
  const [updatedPayerList = gamePayerCustomers, setNewList] = React.useState();

  const onUpdateBillingCustomer = user => {
    updateBillingCustomer({
      // state needs to be parsed out because the backend isnt expecting an object but just a string
      data: { ...user, state: user.state && user.state.value },
      platformId: groupId,
      customerId: user.id
    });
    setNewList(updatedPayerList.map(payer => (payer.id === user.id ? user : payer)));
  };
  const onEditBillingCustomer = user => {
    const { eventBillingCustomer } = user;
    setBillingCustomer(user);
    if (EVENT_BILLING_CUSTOMER_TYPE.BATCH_PAYER === eventBillingCustomer[0].customer_type) {
      setBillingCustomerType(EVENT_BILLING_CUSTOMER_TYPE.BATCH_PAYER);
    } else {
      setBillingCustomerType(EVENT_BILLING_CUSTOMER_TYPE.GAME_PAYER);
    }
    setPayerCustomerModal(true);
  };

  const handleRemoveGroupAdmin = user => {
    setShowModal(true);
    setModalType(MODALS.REMOVE_GROUP_ADMIN);
    setSelectedUser(user);
  };
  const handleAddGroupAdmin = () => {
    setShowModal(true);
    setModalType(MODALS.ADD_GROUP_ADMIN);
  };
  const handleEdit = () => setIsEditing(true);
  const handleSave = () => setIsEditing(false);

  return (
    <Wrapper>
      <LoaderWrapper isFetching={isFetching}>
        <Wrapper>
          <Container justifyContent="right" padding="20px 100px 0 0">
            <InnerContainer>
              <ButtonAsLink {...config.redirectButton({ redirectLabel, redirectPath })} />
            </InnerContainer>
          </Container>
        </Wrapper>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            name: groupData.name,
            note: groupData.note,
            gameFee: groupData.gameSubscription && groupData.gameSubscription.price,
            groupFee: groupData.groupSubscription && groupData.groupSubscription.price,
            subscriptionStatus: groupData.gameSubscription && groupData.gameSubscription.active,
            custId: groupData.salesforce_id,
            netsuiteId: groupData.netsuite_id,
            invoiceRunDay: groupData.gameSubscription && groupData.gameSubscription.invoiceRunDay,
            billingStartDate:
              groupData.gameSubscription && groupData.gameSubscription.billingStartDate,
            is_test: groupData.is_test
          }}
          render={({
            handleSubmit,
            values,
            pristine,
            submitting,
            invalid,
            form: { reset: resetForm }
          }) => (
            <>
              <GroupInfoContainer justifyContent="left" padding="0 0 0 450px">
                <InnerContainer>
                  <Heading>Group Information</Heading>
                  <CreatedOn>
                    Created On:{' '}
                    {(groupData &&
                      groupData.created_at &&
                      moment.utc(groupData.created_at).format('MM/DD/YYYY')) ||
                      'NA'}
                  </CreatedOn>
                </InnerContainer>
                <InnerContainer>
                  {isEditing && (
                    <Button {...config.cancelButton({ isEditing, setIsEditing, resetForm })} />
                  )}
                </InnerContainer>
                <InnerContainer>
                  {isEditing ? (
                    <Button
                      {...config.saveButton({ handleSubmit, values, handleSave })}
                      disabled={submitting || pristine || invalid}
                    />
                  ) : (
                    <Button {...config.editButton({ handleEdit })} disabled={!groupData.id} />
                  )}
                </InnerContainer>
              </GroupInfoContainer>
              <GroupInfoContainer justifyContent="center" padding="0 450px 0 450px">
                <GroupInfo info={groupData} isEditing={isEditing} isSuperAdmin={isSuperAdmin} />
              </GroupInfoContainer>
              <NoteFieldContainer justifyContent="left" padding="20px 0 0 470px">
                <Note note={values.note || DEFAULT_VALUES.NOTE} isEditing={isEditing} />
              </NoteFieldContainer>
            </>
          )}
        />
        <UserListWrapper justifyContent="right" padding="0 450px 0 0">
          <AddAdminSpan />
          <Button {...config.addAdminButton({ handleAddGroupAdmin })} disabled={!groupData.id} />
        </UserListWrapper>
        <UserInfoTable
          users={groupUsers}
          onButtonClick={handleRemoveGroupAdmin}
          heading="Group Admin"
          headers={config.groupAdmins}
        />
        <UserInfoTable
          users={sortListByKey(updatedPayerList, 'billing_name')}
          onButtonClick={onEditBillingCustomer}
          heading="List of Event Billing Contacts"
          headers={config.billingContacts}
          isFetching={payerBillingCustomerIsFetching}
        />
        <UserInfoTable
          users={sortListByKey(taxPayerCustomers, 'billing_name')}
          onButtonClick={onEditBillingCustomer}
          heading="List of Payer Details"
          headers={config.taxPayer}
          isFetching={payerBillingCustomerIsFetching}
        />
        {showModal && (
          <Modal
            modalType={modalType}
            selectedUser={selectedUser}
            showModal={showModal}
            setShowModal={setShowModal}
            groupId={groupData.id}
          />
        )}

        {payerCustomerModal && (
          <BillingCustomer
            title={
              billingCustomerType === EVENT_BILLING_CUSTOMER_TYPE.BATCH_PAYER
                ? 'Update payer details for tax reporting'
                : 'Update event billing contact'
            }
            onClose={() => setPayerCustomerModal(false)}
            onSubmitManually={onUpdateBillingCustomer}
            fields={
              billingCustomerType === EVENT_BILLING_CUSTOMER_TYPE.BATCH_PAYER
                ? taxConfig.taxPayerModalFields
                : billingConfig.billingModalFields
            }
            initialValues={billingCustomer}
            openModal={payerCustomerModal}
          />
        )}
      </LoaderWrapper>
      <br />
    </Wrapper>
  );
};

export default Enhancer(GroupInfoPage);
