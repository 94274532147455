import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
  Header,
  HeaderTextError,
  NotificationRow,
  Close,
  Message,
  Name,
  ButtonWrapper,
  HeaderTextWarning
} from './styled-components';
import { ICONS, COLORS } from '../../../../utils/styles';
import Enhancer from './Enhancer';
import Button from '../../Button';

const NotificationsMenu = ({
  assignmentWarnings,
  clearAssignmentWarnings,
  allowIgnoreRules,
  ignoreRules,
  deleteGameAssignments
}) => (
  <Wrapper onClick={e => e.nativeEvent.stopImmediatePropagation()}>
    <Header>
      {assignmentWarnings[0].warning ? (
        <HeaderTextWarning>Assignment Warning</HeaderTextWarning>
      ) : (
        <HeaderTextError>
          {assignmentWarnings[0].header ? assignmentWarnings[0].header : 'Assignment Error'}
        </HeaderTextError>
      )}
      <Close
        onClick={clearAssignmentWarnings}
        color={COLORS.zumthor}
        width={20}
        height={20}
        icon={ICONS.CLOSE}
      />
    </Header>
    {assignmentWarnings.map(({ name, message, gameData, reportExist }, i, arr) => (
      <NotificationRow key={message}>
        <Message>
          {name && <Name>{name}</Name>}
          {message}
        </Message>
        {!assignmentWarnings.some(warning => !warning.ignoreRule) && arr.length - 1 === i && (
          <ButtonWrapper>
            <Button
              label="Ignore Rule"
              onClick={() => {
                if (!ignoreRules) {
                  allowIgnoreRules(gameData);
                  return clearAssignmentWarnings();
                }
                return null;
              }}
              customStyle={{
                width: '20%',
                padding: '0.3rem 0.625rem',
                boxShadow: '0px 0px 3px 0 #898f95'
              }}
            />
          </ButtonWrapper>
        )}
        {gameData && reportExist && (
          <ButtonWrapper>
            <Button
              label="Remove"
              onClick={() => {
                deleteGameAssignments(gameData);
                return clearAssignmentWarnings();
              }}
              customStyle={{
                width: '20%',
                padding: '0.3rem 0.625rem',
                boxShadow: '0px 0px 3px 0 #898f95'
              }}
            />
          </ButtonWrapper>
        )}
      </NotificationRow>
    ))}
    {/* <div>
      <Button
        label="Ignore Rule"
        //onClick={() => onSubmit({ selected, rank })}
        width="84px"
        height="32px"
        primary
        // disabled={!(rank >= 1 && rank <= 99)}
      />
    </div> */}
  </Wrapper>
);
NotificationsMenu.propTypes = {
  assignmentWarnings: PropTypes.arrayOf(Object),
  clearAssignmentWarnings: PropTypes.func.isRequired
};

NotificationsMenu.defaultProps = {
  assignmentWarnings: []
};

export default Enhancer(NotificationsMenu);
