import { compose } from 'recompose';
import { connect } from 'react-redux';

import unconnected from './unconnected';
import {
  isGamesEventAdminOrAssignor,
  isReportsEventAdmin,
  selectUserId,
  isReportEventAssignor,
  isGamesEventOfficial,
  isGamesEventOfficialOrAssessor
} from '../../../core/Auth/selectors';
import {
  createCrewVerificationReport as createCrewVerificationReportAction,
  fetchCurrentCrewVerificationReports as fetchCurrentCrewVerificationReportsAction,
  fetchGamesEventCrewLabels as fetchGamesEventCrewLabelsAction
} from '../../../core/Games';
import { editIncidentReport as editReportAction } from '../../../core/Reports';
import {
  getCurrentGame,
  getGamesEventCrewLabels,
  getCurrentGameCrewReport,
  getCurrentGameEventReportSettings,
  isGamesEventGroupAdmin,
  getCurrentGameCrewNotes
} from '../../../core/Games/selectors';
import { setShowModalFor as setShowModalForAction } from '../../../core/Modals';
import { getFrom } from '../../../core/Modals/selectors';
import { getEventReportSettings } from '../../../core/Events/selectors';

const mapStateToProps = state => ({
  game: getCurrentGame(state),
  from: getFrom(state),
  crewLabels: getGamesEventCrewLabels(state),
  report: getCurrentGameCrewReport(state),
  reportSettings: getEventReportSettings(state) || getCurrentGameEventReportSettings(state),
  isGamesEventAdminOrAssignor: isGamesEventAdminOrAssignor(state),
  isGamesEventGroupAdmin: isGamesEventGroupAdmin()(state),
  isReportsEventAdmin: isReportsEventAdmin(state),
  currentUserId: selectUserId(state),
  isFetching: state.games.current.isFetching,
  isReportsEventAssignor: isReportEventAssignor(state),
  isGamesEventOfficial: isGamesEventOfficial(state),
  isGamesEventOfficialOrAssessor: isGamesEventOfficialOrAssessor(state),
  notes: getCurrentGameCrewNotes(state)
});

const mapDispatchToProps = dispatch => ({
  createCrewVerificationReport: payload => dispatch(createCrewVerificationReportAction(payload)),
  editReport: payload => dispatch(editReportAction(payload)),
  setShowModalFor: payload => dispatch(setShowModalForAction(payload)),
  fetchCurrentCrewVerificationReports: payload =>
    dispatch(fetchCurrentCrewVerificationReportsAction(payload)),
  fetchGamesEventCrewLabels: () => dispatch(fetchGamesEventCrewLabelsAction())
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
