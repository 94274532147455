import { compose, withHandlers, lifecycle, withProps, withState } from 'recompose';

import MODAL_NAMES, {
  FROM_REPORTS,
  FROM_GAMES,
  FROM_NOTIFICATIONS
} from '../../../core/Modals/constants';
import { convertToTimezone } from '../../../utils/helpers';

export default compose(
  withProps(({ game }) => ({
    reports: game && game.gameReports,
    gameReport: game && game.gameReport
  })),
  withState('reportId', 'setReportId', null),
  withState('deleteReportId', 'setDeleteReportId', null),
  withState('creatingReport', 'setCreatingReport', false),
  withState('inEdit', 'setInEdit', false),
  withState('isDisabled', 'setIsdisabled', false),
  withState('totalGoal', 'setTotalGoal', 0),
  withHandlers({
    onSubmit: ({
      createGameReport,
      setCreatingReport,
      game: { id, assignments_game, team_home, team_visitor, start_date, start_time, timezone },
      gameLevels,
      currentUserId,
      isReportsEventGroupAdmin,
      isGamesEventAdmin,
      isGamesEventAssignor,
      setShowModalFor,
      params,
      calendarGameLevel
    }) => values => {
      const gameLevel = gameLevels[0] ? gameLevels : calendarGameLevel;
      /* eslint-disable eqeqeq */
      const teamHomeRosters = values.home_roster.filter((v, i) => {
        let checkRoster = [];
        checkRoster = values.home_roster.reduce((a, val, ind) => {
          if (
            val.name.toLowerCase().trim() === v.name.toLowerCase().trim() &&
            val.uniform == v.uniform &&
            val.roster == v.roster
          ) {
            a.push(ind);
          }
          return a;
        }, []);

        if (
          checkRoster.length === 1 ||
          (checkRoster.length > 1 && Math.min(...checkRoster) === i)
        ) {
          return true;
        }
        return false;
      });
      const teamAwayRosters = values.away_roster.filter((v, i) => {
        let checkRoster = [];
        checkRoster = values.away_roster.reduce((a, val, ind) => {
          if (
            val.name.toLowerCase().trim() === v.name.toLowerCase().trim() &&
            val.uniform == v.uniform &&
            val.roster == v.roster
          ) {
            a.push(ind);
          }
          return a;
        }, []);

        if (
          checkRoster.length === 1 ||
          (checkRoster.length > 1 && Math.min(...checkRoster) === i)
        ) {
          return true;
        }
        return false;
      });

      createGameReport({
        id,
        data: {
          home_team: {
            type: 'home',
            name: team_home.name,
            score:
              values.home_score ||
              (values.home_roster &&
                values.home_roster.reduce((a, val) => {
                  let value = a;
                  if (val.total_goals) {
                    value = a + parseInt(val.total_goals, 10);
                  }
                  return value;
                }, 0)),
            cautions:
              values.home_cautions ||
              (values.home_roster &&
                values.home_roster.reduce((a, val) => {
                  let value = a;
                  if (val.is_caution) {
                    value = a + 1;
                  }
                  return value;
                }, 0)),
            send_off:
              values.home_send_off ||
              (values.home_roster &&
                values.home_roster.reduce((a, val) => {
                  let value = a;
                  if (val.is_send_off) {
                    value = a + 1;
                  }
                  return value;
                }, 0)),
            team_staffs: values.home_team,
            team_rosters: teamHomeRosters,
            players: values.players_HOME_team,
            is_forfeit: values.home_forfeit
          },
          away_team: {
            type: 'away',
            name: team_visitor.name,
            score:
              values.away_score ||
              (values.away_roster &&
                values.away_roster.reduce((a, val) => {
                  let value = a;
                  if (val.total_goals) {
                    value = a + parseInt(val.total_goals, 10);
                  }
                  return value;
                }, 0)),
            cautions:
              values.away_cautions ||
              (values.away_roster &&
                values.away_roster.reduce((a, val) => {
                  let value = a;
                  if (val.is_caution) {
                    value = a + 1;
                  }
                  return value;
                }, 0)),
            send_off:
              values.away_send_off ||
              (values.away_roster &&
                values.away_roster.reduce((a, val) => {
                  let value = a;
                  if (val.is_send_off) {
                    value = a + 1;
                  }
                  return value;
                }, 0)),
            team_staffs: values.away_team,
            team_rosters: teamAwayRosters,
            is_forfeit: values.away_forfeit
          },
          game_summary_notes: values.game_summary_notes,
          event_id: values.event_id,
          external_game_date: convertToTimezone(
            `${start_date} ${start_time}`,
            timezone,
            'YYYY-MM-DD HH:mm:ss'
          ),
          timezone,
          external_event_id: values.external_event_id,
          position:
            isGamesEventAdmin || isReportsEventGroupAdmin
              ? 'Admin'
              : isGamesEventAssignor
              ? 'Assignor'
              : gameLevel[0].labels[
                  assignments_game.filter(
                    obj => obj.event_role.user_id === Number(currentUserId)
                  )[0].official_label_col
                ],
          official_label_col:
            isGamesEventAdmin || isReportsEventGroupAdmin || isGamesEventAssignor
              ? null
              : assignments_game.filter(obj => obj.event_role.user_id === Number(currentUserId))[0]
                  .official_label_col,
          attachments: values.attachments
        }
      });
      setCreatingReport(false);

      if (params && params.showIncidentReport) {
        setShowModalFor({ name: MODAL_NAMES.gameReportsModal, from: FROM_NOTIFICATIONS });
        setShowModalFor({ name: MODAL_NAMES.incidentReportsModal, from: FROM_GAMES });
      }
    },
    onEdit: ({
      editGameReport: editGamesReport,
      updateReport,
      setReportId,
      setInEdit,
      setShowModalFor,
      gamesEventId,
      gameReport
    }) => ({ values, editableReport: { id }, from }) => {
      const teamHomeRosters = values.home_roster.filter((v, i) => {
        let checkRoster = [];
        checkRoster = values.home_roster.reduce((a, val, ind) => {
          if (
            val.name.toLowerCase().trim() === v.name.toLowerCase().trim() &&
            val.uniform == v.uniform &&
            val.roster == v.roster
          ) {
            a.push(ind);
          }
          return a;
        }, []);

        if (
          checkRoster.length === 1 ||
          (checkRoster.length > 1 && Math.min(...checkRoster) === i)
        ) {
          return true;
        }
        return false;
      });

      const teamAwayRosters = values.away_roster.filter((v, i) => {
        let checkRoster = [];
        checkRoster = values.away_roster.reduce((a, val, ind) => {
          if (
            val.name.toLowerCase().trim() === v.name.toLowerCase().trim() &&
            val.uniform == v.uniform &&
            val.roster == v.roster
          ) {
            a.push(ind);
          }
          return a;
        }, []);

        if (
          checkRoster.length === 1 ||
          (checkRoster.length > 1 && Math.min(...checkRoster) === i)
        ) {
          return true;
        }
        return false;
      });

      const submitValues = {
        id: values.id,
        approved: values.approved,
        type: 'game_report',
        game_summary_notes: values.game_summary_notes,
        attachments: values.attachments,
        home_team: {
          id:
            (values.home_team[0] && values.home_team[0].game_report_team_id) ||
            (values.home_roster[0] && values.home_roster[0].game_report_team_id),
          game_report_id: values.id,
          type: 'home',
          score:
            values.home_score ||
            values.home_roster.reduce((a, val) => {
              let value = a;
              if (val.total_goals) {
                value = a + parseInt(val.total_goals, 10);
              }
              return value;
            }, 0),
          cautions:
            values.home_cautions ||
            values.home_roster.reduce((a, val) => {
              let value = a;
              if (val.is_caution) {
                value = a + 1;
              }
              return value;
            }, 0),
          send_off:
            values.home_send_off ||
            values.home_roster.reduce((a, val) => {
              let value = a;
              if (val.is_send_off) {
                value = a + 1;
              }
              return value;
            }, 0),
          team_staffs: values.home_team,
          team_rosters: teamHomeRosters,
          is_forfeit: values.home_forfeit
        },
        away_team: {
          id:
            (values.away_team[0] && values.away_team[0].game_report_team_id) ||
            (values.away_roster[0] && values.away_roster[0].game_report_team_id),
          game_report_id: values.id,
          type: 'away',
          score:
            values.away_score ||
            values.away_roster.reduce((a, val) => {
              let value = a;
              if (val.total_goals) {
                value = a + parseInt(val.total_goals, 10);
              }
              return value;
            }, 0),
          cautions:
            values.away_cautions ||
            values.away_roster.reduce((a, val) => {
              let value = a;
              if (val.is_caution) {
                value = a + 1;
              }
              return value;
            }, 0),
          send_off:
            values.away_send_off ||
            values.away_roster.reduce((a, val) => {
              let value = a;
              if (val.is_send_off) {
                value = a + 1;
              }
              return value;
            }, 0),
          team_staffs: values.away_team,
          team_rosters: teamAwayRosters,
          is_forfeit: values.away_forfeit
        }
      };
      const payload = {
        id,
        event_id: gamesEventId || (gameReport && gameReport.event_id),
        data: submitValues
      };
      if (from === FROM_REPORTS) {
        payload.successHandler = updateReport;
        editGamesReport(payload);
        setShowModalFor({ name: MODAL_NAMES.gameReportsModal, from });
      } else {
        editGamesReport(payload);
      }
      setReportId(null);
      setInEdit(false);
    },
    onDelete: ({ deleteGameReport, setDeleteReportId }) => id => {
      deleteGameReport({ id });
      setDeleteReportId(null);
    },
    fetchReport: ({ fetchGameReport, setReportId, setIsdisabled }) => id => {
      fetchGameReport({ id });
      setReportId(id);
      setIsdisabled(true);
    },
    onClose: ({ setShowModalFor }) => from =>
      setShowModalFor({ name: MODAL_NAMES.gameReportsModal, from }),
    onBack: ({ setReportId, setCreatingReport, setDeleteReportId }) => () => {
      setReportId(null);
      setCreatingReport(false);
      setDeleteReportId(null);
    },
    onApprove: ({ editGameReport: editGamesReport, updateReport, gamesEventId, gameReport }) => (
      { id, approved },
      from
    ) => {
      const payload = {
        id,
        data: { approved: !approved },
        event_id: gamesEventId || (gameReport && gameReport.event_id),
        message: `Successfully ${approved ? 'un' : ''}approved Game report`
      };
      if (from === FROM_REPORTS) {
        payload.successHandler = updateReport;
        editGamesReport(payload);
      } else {
        editGamesReport(payload);
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.game && this.props.game.id) {
        if (this.props.params.reportId) {
          this.props.fetchReport(this.props.params.reportId);
        } else {
          const filters = {};
          if (
            !(
              this.props.isGamesEventAdminOrAssignor ||
              this.props.isReportsEventAdmin ||
              this.props.isReportsEventAssignor
            )
          )
            filters.user_id = this.props.currentUserId;
          this.props.fetchCurrentGameReports({ filters });
        }
      }
    },
    componentDidUpdate(prevProps) {
      if (!prevProps.game.id && this.props.game) {
        if (this.props.params.reportId) {
          this.props.fetchReport(this.props.params.reportId);
        } else {
          const filters = {};
          if (
            !(
              this.props.isGamesEventAdminOrAssignor ||
              this.props.isReportsEventAdmin ||
              this.props.isReportsEventAssignor
            )
          )
            filters.user_id = this.props.currentUserId;
          this.props.fetchCurrentGameReports({ filters });
        }
      }
    }
  })
);
