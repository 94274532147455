import { createSelector } from 'reselect';
import { equals, find, curryN } from 'ramda';
import { GAME_UPLOAD_TYPES, CORE_ROLES } from '../../constants';

import { findSportById } from '../Sports/selectors';
import { getGamesEventId } from '../Games/selectors';

export const getEvents = ({
  events: {
    eventList: { data }
  }
}) => data;

export const getFilteredEvents = ({
  events: {
    filteredEventList: { data }
  }
}) => data;

export const getAllEvents = ({ events: { eventList, filteredEventList } }) => {
  return filteredEventList ? [...eventList.data, ...filteredEventList.data] : [...eventList.data];
};

export const getIsFetching = (state, match) => {
  if (match && match.path) {
    if (match.path.includes('location')) {
      return state.locations.isFetching;
    }
    if (match.path.includes('team')) {
      return state.teams.isFetching;
    }
    return state.users.isFetching;
  }
  return state.users.isFetching;
};

export const getIsFetchingEventUsers = state => {
  return state.events.isFetchingEventUsers;
};

export const getAdminEvents = ({
  events: {
    eventList: { data }
  }
}) => data.filter(event => event.user_roles.find(role => role.role_id === CORE_ROLES.admin));
export const getEventMeta = ({
  events: {
    eventList: { meta }
  }
}) => meta;

export const getTotalEventCount = createSelector(
  getEventMeta,
  ({ total_count: totalCount }) => totalCount
);
export const getLimit = createSelector(getEventMeta, ({ limit }) => limit);

export const getFilters = ({ events }) => events.filters;

export const getSortingValue = ({ events }) => events.sortingValue;

export const getEvent = ({ events }) => events && events.current;

export const getEventId = createSelector(getEvent, ({ id }) => id);

export const getEventById = curryN(2, (eventId, state) =>
  createSelector(
    getAllEvents,
    find(({ id }) => equals(id, eventId))
  )(state)
);

export const getEventInfo = createSelector(getEvent, ({ info }) => info);

export const getEventBilling = createSelector(getEvent, ({ billing }) => billing);

export const getEventTaxInfo = createSelector(getEvent, ({ tax_info }) => tax_info);

export const getEventCategories = createSelector(getEvent, ({ categories }) => categories);

export const getReportSettings = createSelector(getEvent, ({ report_settings }) => report_settings);

export const getGoverningBodies = state => (state.events && state.events.governingBodies) || [];

export const getAssessmentSettings = createSelector(
  getEvent,
  ({ assessment_settings }) => assessment_settings
);

export const getGameUploadType = createSelector(
  getEventInfo,
  ({ game_upload_type }) => game_upload_type
);

export const isOfficialSelfAssignAllowed = createSelector(
  getEventInfo,
  ({ official_self_assign_setting }) =>
    official_self_assign_setting && official_self_assign_setting.checked
);

export const getOfficialSelfAssignSettings = createSelector(
  getEventInfo,
  ({ official_self_assign_setting }) => official_self_assign_setting
);

export const getEventDisableGameDeclineSettings = createSelector(
  getEventInfo,
  ({ disable_games_decline_setting }) => disable_games_decline_setting
);

export const getGameUploadTypeById = createSelector(
  getEventById,
  event => event && event.game_upload_type
);

export const isGameUploadTypeManual = createSelector(
  getGameUploadTypeById,
  uploadType => uploadType && uploadType === GAME_UPLOAD_TYPES.manual_upload
);

export const getEventGameLevels = createSelector(getEvent, ({ game_levels }) => game_levels);
export const getEventCrewPay = createSelector(getEvent, ({ crew_pay }) => crew_pay);

export const getEventGameLevelById = (state, gameID) =>
  createSelector(
    getEventGameLevels,
    find(({ id }) => equals(id, gameID))
  )(state);

export const getEventCrewLabels = createSelector(
  getEvent,
  ({ crew_labels: { event_id, role_ids, labels, locked } }) => ({
    event_id,
    role_ids,
    labels,
    locked
  })
);

export const getEventPayTables = createSelector(getEvent, ({ pay_tables }) => pay_tables);

export const getEventAvailabilities = createSelector(
  getEvent,
  ({ availabilities }) => availabilities
);

export const getEventGameAssignments = createSelector(
  getEvent,
  ({ game_assignments }) => game_assignments
);

export const getCurrentEvent = state =>
  createSelector(getGamesEventId, id => getEventById(id, state))(state);

export const getEventStatus = createSelector(getEventInfo, ({ status }) => status);

export const getAllUserEvents = ({ events }) => events.allUserEvents;

export const getEventSports = state =>
  createSelector(getEvent, ({ info: { sport_id } }) => findSportById(state, sport_id))(state);

export const getEventUser = id =>
  createSelector(getEvent, ({ users }) => users.find(user => user.id === id));

export const getEventUsers = createSelector(getEvent, ({ users }) => users || []);

export const eventHasGames = createSelector(
  getEventGameLevels,
  gameLevels => gameLevels.length > 0
);

export const getEventAssessmentReportSettings = createSelector(
  getEvent,
  ({ info: { assessment_report_settings } }) => assessment_report_settings
);

export const getEventReportSettings = createSelector(
  getEvent,
  ({ info: { report_settings } }) => report_settings
);

export const getEventSportsId = createSelector(getEvent, ({ info: { sport_id } }) => sport_id);

export const getSelectedEventPlatform = ({
  events: {
    current: { info }
  }
}) => info.group;

export const getEventInfoIsFetching = state => state.events.eventInfoIsFetching;
