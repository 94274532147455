import { sum } from 'ramda';
import { round } from '../../../../utils/helpers';
import { fixedDecimal } from '../../../../utils/parsers';

export const BILL_TO_VALUES = ['Crew', 'Game'];
export const FEE_TYPE_VALUES = ['No Assignor Pay', 'Flat Rate', 'Percentage of Game Fees'];
export const PERCENTAGE_SOURCE_VALUES = [
  'Game Fee Total Before Adjustments',
  'Game Fee Total After Adjustments'
];

export const calcAssignorFee = ({
  adjustment,
  assignor,
  assignor_pay,
  base_pay,
  total,
  inBatch = true
}) => {
  if (total && !inBatch) return 0;
  if (assignor && assignor_pay) {
    const {
      bill_to: billTo,
      fee_amount: feeAmount,
      fee_type: feeType,
      percentage_source: percentageSource
    } = assignor_pay;
    const billToCrew = billTo === BILL_TO_VALUES[0];
    const feeTypePercentageSource = feeType === FEE_TYPE_VALUES[2];
    const total_pay = base_pay;

    if (total || billToCrew) {
      if (feeType === FEE_TYPE_VALUES[1]) {
        if (billToCrew) return fixedDecimal(feeAmount < total_pay ? feeAmount : total_pay);
        return fixedDecimal(feeAmount);
      }
      if (feeTypePercentageSource) {
        if (percentageSource === PERCENTAGE_SOURCE_VALUES[0])
          return fixedDecimal((total_pay * feeAmount) / 100);
        if (percentageSource === PERCENTAGE_SOURCE_VALUES[1])
          return fixedDecimal(((total_pay + adjustment) * feeAmount) / 100);
      }
    }
  }

  return 0;
};

/**
 * Calculates a Crew member's total pay and returns the max value of the total or zero
 * @param {Object} assignor Primary Assignor for the Game
 * @param {Object} assignor_pay Game's Assignor pay configuration
 * @param {Number} base_pay Game's base pay for the Crew member
 * @param {Number} travel_pay Crew member's travel pay
 * @param {Number} adjustment Crew member's adjustment pay
 * @return {Number} returns the max value of the total from the calculation or zero
 */
export const calcOfficialTotal = ({ assignor, assignor_pay, base_pay, travel_pay, adjustment }) => {
  const crewPay = sum([base_pay, travel_pay]);
  const assignorPay = calcAssignorFee({
    adjustment,
    assignor,
    assignor_pay,
    base_pay
  });

  return assignorPay > crewPay
    ? round(Math.max(sum([-assignorPay, adjustment, crewPay]), Math.min(adjustment, 0)), 2)
    : round(sum([-assignorPay, adjustment, crewPay]), 2);
};

/**
 * determines whether all or only a subset of the assignments are in the batch
 * @param {*} assignments
 * @returns true if only a subset of assignments of a game are in the batch else false
 */
export const isIndeterminate = assignments => {
  const inBatchCount = assignments.filter(a => a.batch_pay_staging.in_batch).length;
  return inBatchCount > 0 && inBatchCount < assignments.length;
};

/**
 * determines whether all of the assignments are in the batch
 * @param {*} assignments
 * @returns true if all assignments of a game are in a batch
 */
export const isChecked = assignments =>
  !!assignments.length &&
  assignments.length === assignments.filter(a => a.batch_pay_staging.in_batch).length;
