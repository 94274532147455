import React from 'react';
import PropTypes from 'prop-types';

import { COLORS, ICONS } from '../../../utils/styles';
import { CheckIcon } from './styled-components';

/**
 * Simple icon that displays toggled check based on provided prop
 * @param checked {Boolean} determines if check icon is shown
 * @param size {Number} sets the width and height of the icon
 */
const CheckRadioButton = ({ checked, size }) => (
  <CheckIcon
    width={size}
    height={size}
    icon={checked ? ICONS.CHECK_CIRCLE : ICONS.CIRCLE}
    color={checked ? COLORS.denimBlue : COLORS.hawkesBlue}
  />
);

CheckRadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  size: PropTypes.number
};

CheckRadioButton.defaultProps = {
  size: 18
};

export default CheckRadioButton;
