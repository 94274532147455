import { createSelector } from 'reselect';

export const getInvitationNotifications = ({ notifications }) => notifications.invitations;

export const getAssignmentWarnings = state => state.notifications.assignmentWarnings;

export const getNotificationCounts = ({ notifications: { notificationCounts } }) =>
  notificationCounts;

export const getNotification = ({ notifications: { current } }) => current;

export const getMenuNotifications = ({ notifications: { menuNotifications } }) => menuNotifications;

export const getBannerNotifications = ({ notifications: { bannerNotifications } }) =>
  bannerNotifications;

export const getEvent = ({ notifications: { event } }) => event;

export const getIsFetching = ({ notifications }) => notifications.isFetching;

export const getNotificationReportSettings = createSelector(
  getNotification,
  ({ event: { report_settings } }) => report_settings
);
