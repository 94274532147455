import { compose, withState, withHandlers, lifecycle } from 'recompose';
import FileSaver from 'file-saver';

import { formatDate } from '../../../../utils/parsers';

export default compose(
  withState('showEmailSelectedModal', 'toggleShowEmailSelectedModal', false),
  withState('showRemoveSelectedModal', 'toggleShowRemoveSelectedModal', false),
  withState('showImportModal', 'toggleShowImportModal', false),
  withState('showAddNewUserModal', 'toggleAddNewUserModal', false),
  withState('showCategoryAssignorModal', 'toggleCategoryAssignorModal', false),
  withState('eventsPageActive', 'setEventsPageActive', true),
  withHandlers({
    onUserImport: ({
      match: {
        params: { eventId }
      },
      submitUserImport,
      pagination
    }) => ({ file, resolve }) =>
      submitUserImport({ eventId, file, resolve, roles: ['admins', 'assignors'], pagination }),
    onRemoveSelected: ({
      match: {
        params: { eventId }
      },
      removeSelectedUsers,
      roles,
      toggleShowRemoveSelectedModal,
      pagination
    }) => selected => {
      toggleShowRemoveSelectedModal(false);
      return removeSelectedUsers({
        eventId,
        roles,
        pagination,
        selected
      });
    },
    downloadCSV: ({
      match: {
        params: { eventId }
      },
      pagination: { onLoadPage }
    }) => () =>
      new Promise(resolve =>
        onLoadPage({
          eventId,
          roles: { alias: 'user', list: ['Assignor', 'Admin', 'Funding Admin'] },
          toCSV: true,
          resolve
        })
      ).then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' });
        FileSaver.saveAs(blob, `user_list_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
      }),
    onAddUserManually: ({
      match: {
        params: { eventId }
      },
      submitUserManually
    }) => ({ email, first_name, last_name, role }) => {
      submitUserManually({
        eventId,
        user: {
          email,
          first_name,
          last_name
        },
        role
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchEvent(
        this.props && this.props.match && this.props.match.params && this.props.match.params.eventId
      );
    }
  })
);
