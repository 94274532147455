import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Radio } from '@material-ui/core';
import { OpenPositions, StyledButton } from './styled-components';
import COLORS from '../../../../utils/styles';
import AssignedPosition from './AssignedPosition';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: '0px !important',
    backgroundImage: 'linear-gradient(#FFFFFF, #F2F5F7)',
    boxShadow: 'none',

    '&:before': {
      backgroundColor: 'transparent'
    }
  },
  summary: {
    minHeight: '40px',
    maxHeight: '40px'
  },
  expandedBg: {
    background: `transparent linear-gradient(180deg, #167CD3 0%, #04589D 100%) 0% 0% no-repeat padding-box`,
    color: '#fff !important',
    minHeight: '40px !important',
    maxHeight: '40px'
  },
  expandIcon: {
    background: `transparent`,
    color: `${COLORS.newDarkBlue}`
  },
  content: {
    background: `transparent`,
    margin: '0px',
    color: `${COLORS.darkGrey}`
  },
  checkbox: {
    color: COLORS.lightBlue
  },
  checked: {
    color: `${COLORS.white} !important`
  },
  accordionDetails: {
    background: `${COLORS.white}`,
    padding: `12px 16px`,
    marginLeft: '20px',
    borderBottom: `1px solid ${COLORS.blueGray}`
  }
}));

const OpenPosition = ({
  createGameAssignment,
  game,
  user,
  date,
  startAndEndDates,
  fetchAssignments,
  crewMembers,
  setPulledDates
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onAcceptSefltAssignButton = (gameUpdate, userDetails, label, day) => {
    const { start_date, end_date } = startAndEndDates(moment(day));
    setPulledDates([]); // why are we clearing the pull dates upon accepting a self assignment? perhaps at most just remove the single day
    createGameAssignment({
      game: gameUpdate,
      user: userDetails,
      label,
      onLoadPage: () => fetchAssignments({ start_date, end_date, payment: true })
    });
  };

  return (
    <div className={classes.root}>
      {game.game_level.labels.map((label, index) => {
        const openSelfAssign =
          game && game.selfAssignPositions && game.selfAssignPositions.find(a => label === a);

        return openSelfAssign ? (
          <Accordion
            expanded={expanded === label}
            onChange={handleChange(label)}
            key={label}
            classes={{ root: classes.root }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                root: classes.summary,
                expanded: classes.expandedBg,
                expandIcon: classes.expandIcon,
                content: classes.content
              }}
            >
              <OpenPositions>
                <Radio
                  size="small"
                  classes={{ root: classes.checkbox, checked: classes.checked }}
                  checked={expanded === label}
                />
                <span>
                  {label && `${label}: `}
                  <span className={expanded !== label ? `lightBlue` : ''}>Open</span>
                </span>
              </OpenPositions>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              <StyledButton
                label="Accept"
                margin="0px 0px 0px 50px"
                width="101px"
                onClick={() => onAcceptSefltAssignButton(game, user, label, date)}
              >
                <CheckCircleOutlineIcon style={{ fontSize: '1.3rem' }} />
                <span>Accept</span>
              </StyledButton>
            </AccordionDetails>
          </Accordion>
        ) : (
          <AssignedPosition
            key={label}
            crewMembers={crewMembers}
            index={index}
            game={game}
            userId={user.id}
            selfAssign
          />
        );
      })}
    </div>
  );
};

export default OpenPosition;
