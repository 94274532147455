import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalContainer, Rows, Description, Note, ValidationError } from './styled-components';
import { Dialog } from '../../../../global-material-components';

export default function ConfirmationPopup({
  userId,
  sourceId,
  type,
  title,
  description,
  SubmitBtnText,
  confirmationPopupAction,
  setConfirmationPopupAction,
  primaryActionFun,
  cancelButtonText,
  customStyle
}) {
  const [note, setNote] = useState('');
  return (
    <Dialog
      title={title}
      onClose={() => {
        setConfirmationPopupAction(false);
      }}
      open={confirmationPopupAction}
      cancelAction={() => setConfirmationPopupAction(false)}
      primaryAction={() => {
        primaryActionFun(sourceId !== '' && { id: sourceId, userId, type, note });
      }}
      primaryButtonText={SubmitBtnText}
      Cancel
      cancelButtonText={cancelButtonText}
      primaryButtonDisabled={!(note && note.length > 3)}
      style={customStyle}
    >
      <ModalContainer>
        <Rows>
          <Description>{description}</Description>
          <Note
            value={note}
            onChange={e => setNote(e.target.value)}
            placeholder="Please explain why you are declining"
          />
          {!(note && note.length > 3) ? (
            <ValidationError>Enter at least 4 characters</ValidationError>
          ) : null}
        </Rows>
      </ModalContainer>
    </Dialog>
  );
}

ConfirmationPopup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  SubmitBtnText: PropTypes.string.isRequired,
  confirmationPopupAction: PropTypes.bool.isRequired,
  setConfirmationPopupAction: PropTypes.func.isRequired,
  primaryActionFun: PropTypes.func.isRequired
};
