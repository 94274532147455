import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { FieldArray } from 'react-final-form-arrays';

import Field, { NullableField } from '../../../../../../../utils/hocs/final-form-field-adapter';
import MaterialInput from '../../../../../MaterialInput';
import { required, valueInRange } from '../../../../../../../utils/validations';
import CheckField from '../../../../../CheckField';

import { Wrapper, StyledTrashIcon } from '../styled-components';
import '../styles.css';

import {
  FieldWrapper,
  Label,
  SubHeader,
  ButtonLabel,
  StyledIcon,
  InnerFieldContainer,
  AddButton
} from '../../../styled-components';
import { COLORS, ICONS } from '../../../../../../../utils/styles';
import { NumericField } from '../../../../../Event/AssignorSettings/NotificationCollapsible';
import { getTeamStaffTemplate } from '../utils';
import { generateUUID } from '../../../../../../../utils/helpers';

const TeamStaff = ({ team, push, disabled, theme, values, byPlayer, isSoccer }) => (
  <>
    <SubHeader color={COLORS.sapphire}>TEAM STAFF</SubHeader>
    <Row style={{ marginBottom: '0.75rem' }}>
      <FieldArray name={team}>
        {({ fields }) => {
          return fields.map(
            (field, index) =>
              values[team][index] && (
                <Col xs={12} md={6} key={values[team][index].uid} className="team-fields">
                  <FieldWrapper>
                    <div className="teamStaffWrapper">
                      <div className="uniformCover">
                        <Wrapper theme={theme.input.wrapper} className="box-shadow-rm">
                          <div className="Uniform-Jersey inputField">
                            <Field
                              name={`${field}.name`}
                              validate={required}
                              className="Uniform-Jersey"
                              render={({ input, meta }) => {
                                return (
                                  <>
                                    <MaterialInput
                                      input={{
                                        ...input,
                                        value:
                                          values[team] &&
                                          values[team][index] &&
                                          values[team][index].name
                                      }}
                                      placeholder="Team Staff Name"
                                      disabled={disabled}
                                      meta={meta}
                                    />
                                    {/* <div className="errorNum text-left box-shadow-rm">
                                      {(!input.value && meta.error) ||
                                        (meta.invalid && meta.error && meta.modified && (
                                          <span>Team Staff Name {meta.error}</span>
                                        ))}
                                    </div> */}
                                  </>
                                );
                              }}
                            />
                          </div>
                        </Wrapper>
                      </div>
                      {fields.value.length > 1 && (
                        <div className="trashIcon">
                          {!disabled && (
                            <Field
                              name="del"
                              component={StyledTrashIcon}
                              width={15}
                              height={15}
                              icon={ICONS.TRASH}
                              color={COLORS.hawkesBlue}
                              onClick={() => {
                                if (!disabled) {
                                  fields.remove(index);
                                }
                              }}
                              clickable
                              noMargin
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="bottomFields">
                      <div className="checkWrap margin-btm-20">
                        <InnerFieldContainer>
                          <Field
                            name={`${field}.present`}
                            component={CheckField}
                            placeholder="Present"
                            width={12}
                            disabled={disabled}
                            color={COLORS.denimBlue}
                            padding="0 0 0 1rem"
                            label="Present"
                            isLabelBlue
                          />
                        </InnerFieldContainer>
                      </div>

                      {isSoccer && byPlayer && (
                        <>
                          <div className="coachDismissedField margin-btm-20">
                            <div className="checkWrap">
                              <InnerFieldContainer>
                                <Field
                                  name={`${field}.is_coach_dismissed`}
                                  component={CheckField}
                                  placeholder="Coach Dismissed"
                                  width={12}
                                  disabled={disabled}
                                  padding="0 0 0 1rem"
                                  color={COLORS.denimBlue}
                                  label="Coach Dismissed"
                                  isLabelBlue
                                />
                              </InnerFieldContainer>
                            </div>
                            <div className="checkWrap atMinuteLineAlgin">
                              <Label color={COLORS.denimBlue} style={{ display: 'inline-block' }}>
                                at Minute:
                              </Label>
                              <div
                                style={{ display: 'inline-block' }}
                                className="atMinuteField atMintueMin"
                              >
                                <NullableField
                                  name={`${field}.coach_dismissed_at_minute`}
                                  validate={valueInRange({ min: 1, max: 999 })}
                                  min={1}
                                  max={999}
                                  component={NumericField}
                                  disabled={disabled}
                                  defaultValue={null}
                                  padding="0 0 0 1rem"
                                  label=""
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </FieldWrapper>
                </Col>
              )
          );
        }}
      </FieldArray>
    </Row>
    <div className="addWrapper">
      <AddButton>
        <StyledIcon
          width={22}
          height={22}
          icon={ICONS.NEW}
          color={COLORS.denimBlue}
          onClick={() =>
            push(team, {
              ...getTeamStaffTemplate(byPlayer, isSoccer),
              index: (values[team] && values[team].length) || 0,
              uid: generateUUID()
            })
          }
          clickable
        />
        <ButtonLabel color={COLORS.denimBlue}>Add Team Staff</ButtonLabel>
      </AddButton>
    </div>
  </>
);

TeamStaff.propTypes = {
  team: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default TeamStaff;
