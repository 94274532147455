import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

export const Block = styled(Col)`
  position: relative;
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.shuttleGray};
`;

export const Content = styled.div`
  ${({ absolute }) =>
    absolute
      ? `
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      `
      : `
      position: relative;
      `};
  ${({ light }) => (light ? 'font-family: RobotoLight' : '')};
`;

export const ButtonsContainer = styled.div`
  margin: 0 auto 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`;

export default Block;
