import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import {
  MainContainer,
  EventTitleWrapper,
  EventInfo,
  ActionButtons,
  IconsContainer,
  ActionIcon,
  EventName,
  EventId,
  ColumnedContent
} from './styled-components';
import SideMenu, { routesToMenuItems } from '../SideMenu';
import Form from './InformationForm';
import AssignorSettingsForm from './AssignorSettings';
import Switch from '../Switch';
import DeleteEventModal from './DeleteEventModal';
import { COLORS, ICONS } from '../../../utils/styles';
import GameLevelForm from './GameLevels';
import CrewLabelsForm from './CrewLabels';

import Enhancer from '../../containers/Event';
import eventInformationEnhancer from '../../containers/Event/EventInformation';
import EventRoles from './Roles';
import EventRolesEnhancer from '../../containers/EventRoles';
import gameLevelFormEnhancer from '../../containers/Event/GameLevels';
import crewLabelEnhancer from '../../containers/Event/CrewLabels';
import assignorSettingsEnhancer from '../../containers/Event/AssignorSettings';
import { userIdxGetter } from '../../../utils/auth';

export const InformationForm = eventInformationEnhancer(Form);
export const EventRolesPage = EventRolesEnhancer(EventRoles);
export const AssignorSettings = assignorSettingsEnhancer(AssignorSettingsForm);
export const GameLevels = gameLevelFormEnhancer(GameLevelForm);
export const CrewLabels = crewLabelEnhancer(CrewLabelsForm);

export const EventTitle = ({
  name,
  eventId,
  status,
  onClick,
  isGroupAdmin,
  isSuperAdmin,
  canDelete,
  toggleShowModal
}) => (
  <EventTitleWrapper>
    <EventInfo>
      <EventName>{name}</EventName>
      <EventId>Event ID:{eventId}</EventId>
    </EventInfo>
    <ActionButtons>
      <Switch switchState={status.value} onClick={onClick} />
      <IconsContainer>
        <ActionIcon
          width={18}
          height={22.5}
          title={canDelete ? '' : 'Events cannot be deleted after games have been added'}
          icon={ICONS.TRASH}
          color={canDelete ? COLORS.denimBlue : COLORS.disabled}
          className={`${isSuperAdmin || isGroupAdmin ? '' : 'hidden'} ${
            canDelete ? '' : 'disabled'
          }`}
          onClick={() => toggleShowModal(true)}
        />
      </IconsContainer>
    </ActionButtons>
  </EventTitleWrapper>
);

EventTitle.propTypes = {
  name: PropTypes.string,
  eventId: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.objectOf(Object),
  isGroupAdmin: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool,
  toggleShowModal: PropTypes.func.isRequired
};

EventTitle.defaultProps = {
  name: '',
  eventId: '',
  status: {},
  onClick: () => {},
  canDelete: false
};

export const EventPage = ({
  routes,
  onToggleEventStatus,
  eventInfo: { name, id: eventId, status, summary, has_crew_pay, group },
  toggleShowModal,
  showModal,
  onDelete,
  isSuperAdmin,
  userGroups
}) => (
  <MainContainer>
    <SideMenu
      items={routesToMenuItems({
        routes,
        eventId,
        userIdx: userIdxGetter(),
        summary,
        has_crew_pay
      })}
    />
    <ColumnedContent>
      {showModal && (
        <DeleteEventModal
          onSubmit={() => onDelete(eventId)}
          onClose={() => toggleShowModal(false)}
        />
      )}
      <EventTitle
        name={name}
        eventId={eventId}
        status={status}
        onClick={onToggleEventStatus}
        isGroupAdmin={!!userGroups.includes(group)}
        isSuperAdmin={isSuperAdmin}
        canDelete={summary && summary.teams === 0 && summary.locations === 0}
        toggleShowModal={toggleShowModal}
      />
      {routes.map(({ path, component }) => (
        <Route key={path} path={path} component={component} />
      ))}
    </ColumnedContent>
  </MainContainer>
);

EventPage.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
      exact: PropTypes.bool,
      path: PropTypes.string
    })
  ).isRequired,
  eventInfo: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.object,
    has_crew_pay: PropTypes.bool,
    summary: PropTypes.objectOf(Object)
  }).isRequired,
  onToggleEventStatus: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool,
  toggleShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  userGroups: PropTypes.arrayOf(Number)
};

EventPage.defaultProps = {
  isSuperAdmin: false,
  userGroups: []
};

export default Enhancer(EventPage);
