import styled from 'styled-components';
import { mediaQueries } from '../../../utils/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1 1 auto;
  position: relative;
  ${mediaQueries.md(`{
    height:calc(100vh - 64px);

  }`)}
`;

export const WaitingDiv = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ isFetching }) => isFetching && 'display: none'};
`;

export default Wrapper;
