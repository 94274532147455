import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

export default {
  dropdown: {
    wrapper: `
      width: 200px;
      height: 100%;
      background: transparent;
    `,
    header: `
      background: transparent;
      color: ${COLORS.black};
      box-shadow: none;
      font-size: ${pxToRem('13px')};

      &.hasValue {
        box-shadow: none;
      }
    `,
    chevron: {
      color: COLORS.denimBlue
    },
    option: `
      background-color: ${COLORS.white};
      color: ${COLORS.black};
      border-bottom: 1px solid #D8D8D8;

      &:hover {
        background-color: ${COLORS.regalBlue};
        color: ${COLORS.white};
      }
    `,
    options: `
      top: 3rem;
    `,
    value: 'padding-left: 0.5rem;'
  },
  dropdownContainer: `border-bottom: 1px solid ${COLORS.black};
      width:200px;
      margin: auto;`
};
