import styled from 'styled-components';
import { COLORS } from '../../../../../../utils/styles';
import { pxToRem } from '../../../../../../utils/helpers';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 3rem;
  width: 100%;
  cursor: ${({ reviewMode }) => (reviewMode ? 'default' : 'pointer')};
  box-sizing: border-box;

  ${({ selected }) => selected && `background-image: linear-gradient(to top, #05579d, #167cd4)`};
`;

export const EmptyCellLabel = styled.span`
  color: ${({ grayOut }) => (grayOut ? COLORS.lightCyanGray : COLORS.sapphire)};
  color: ${({ selected }) => selected && COLORS.white};
  width: ${pxToRem('58px')};
  text-align: center;
`;

export const NonPaymentIcon = styled.span`
  padding: 1px 1px 0px 1px;
  position: relative;
  font-size: 15px;
  color: #ef0101;
  cursor: pointer;
  .MuiSvgIcon-root {
    width: 0.75em;
    height: 0.75em;
  }
  &::after {
    position: absolute;
    content: '/';
    width: 0.75em;
    height: 0.75em;
    font-size: 16px;
    top: 9px;
    right: 0px;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(110deg);
    color: #ef0101;
  }
`;

export const PaymentMouseOverPopoverWrapper = styled.span`
  padding-top: 0.4em;
  cursor: pointer;
  color: #f5a623;
`;
