import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';
import Icon from '../Icon';

export const TagLabel = styled.label`
  font-family: ${({ fontFamily }) => fontFamily || 'Roboto'};
  font-size: ${pxToRem('13px')};
  font-weight: ${({ fontWeight }) => fontWeight || '700'};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ color }) => color};
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TagContainer = styled.div`
  display: flex;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${pxToRem('3px')} ${pxToRem('5px')};
  margin: 0.25rem 0;
  align-items: center;
  max-width: ${({ width }) => width || `${pxToRem('160px')}`};
  box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);

  &:not(:last-child) {
    margin-right: ${pxToRem('8px')};
  }
`;

export const Remove = styled(Icon)`
  min-width: 0.5rem;
  margin: 0 0 0 ${pxToRem('5px')};
  color: ${COLORS.shuttleGrey};
  cursor: pointer;
`;
