import { compose, pure, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import {
  fetchBatchModalInfo,
  confirmBatch as confirmBatchAction
} from '../../../../../core/Payments/index';
import { fetchFundingAdmins as fetchFundingAdminsAction } from '../../../../../core/Roles';
import {
  getAssignmentPayments,
  getIsFetching,
  getPaymentsEventId
} from '../../../../../core/Payments/selectors';
import { getFundingAdmins } from '../../../../../core/Roles/selectors';

const mapStateToProps = state => ({
  assignmentPayments: getAssignmentPayments(state),
  paymentsEventId: getPaymentsEventId(state),
  fundingAdmins: getFundingAdmins(state),
  isFetching: getIsFetching(state)
});

const mapDispatchToProps = dispatch => ({
  fetchBatchSummary: payload => dispatch(fetchBatchModalInfo(payload)),
  confirmBatch: payload => dispatch(confirmBatchAction(payload)),
  fetchFundingAdmins: payload => dispatch(fetchFundingAdminsAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchBatchSummary();
      this.props.fetchFundingAdmins();
    }
  }),
  withHandlers({
    handleConfirmBatch: ({ confirmBatch, paymentsEventId }) => ({ description, assigneeId }) =>
      confirmBatch({ id: paymentsEventId, data: { description, assigneeId } })
  }),
  pure
);
