import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Animation, MessageWrapper } from './styled-components';
import Paper from '../../layouts/Paper';

const Spinner = ({ simple, theme, message }) => (
  <Wrapper simple={simple} theme={theme.wrapper}>
    {simple ? (
      <Animation customTheme={theme.animation} />
    ) : (
      <Paper>
        <Animation />
      </Paper>
    )}
    {message ? <MessageWrapper>{message}</MessageWrapper> : null}
  </Wrapper>
);

Spinner.propTypes = {
  simple: PropTypes.bool,
  theme: PropTypes.shape({
    wrapper: PropTypes.string,
    animation: PropTypes.string
  })
};

Spinner.defaultProps = {
  simple: false,
  theme: {
    wrapper: '',
    animation: ''
  }
};

export default Spinner;
