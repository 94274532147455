import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';

import { COLORS } from '../../../../utils/styles';
import Paper from '../../../layouts/Paper';

export const FormContainerElement = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  background: #fff;
`;

export const Title = styled.span`
  font-size: 1.1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: ${COLORS.sapphire}
  display: block;
`;

export const Note = styled.span`
  font-size: 0.85rem;
  color: ${COLORS.slateGray};
  padding: 5px 0px;
`;

export const Label = styled(Note)`
  font-size: 0.6rem;
  padding-left: 10px;
  font-family: Barlow;
`;

export const UssfId = styled(Note)`
  padding-top: 0px;
  padding-left: 10px;
  font-family: Barlow;
  font-size: 0.7rem;
  color: ${COLORS.black};
  font-weight: bold;
`;

export const UssfIdHeading = styled(Note)`
  font-size: 0.6rem;
  padding-left: 10px;
  font-family: Barlow;
  padding-bottom: 5px;
`;

export const Sport = styled(Col)`
  color: #0d217c;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding-right: 60px;
  font-family: Barlow;
  font-weight: bold;
`;

export const IconWrapper = styled(Col)`
  padding-left: 50px;
  font-family: Barlow;
`;

export const LicenseStatus = styled(IconWrapper)`
  color: ${({ color }) => color || COLORS.forestGreen};
  font-size: 0.9rem;
  padding-left: 5px;
  text-transform: uppercase;
`;

export const LicenseWrapper = styled.div`
  display: flex;
  margin-right: 20px;
  margin-top: 20px;
`;

export const LicenseHeader = styled(Row)`
  padding: 8px 20px 2px 20px;
  background-color: rgb(242 243 245);
`;

export const LicenseBody = styled(Row)`
  padding: 8px 20px 10px 20px;
`;

export const LicenseTile = styled(Paper)``;

export const ExpiredLicense = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(242, 243, 245);
  border-radius: 4px;
  font-family: Barlow;
  margin-top: 20px;
  flex-direction: column;
  font-size: 1rem;
  padding: 20px 65px;
  border: 0.1rem solid;
  border-color: ${COLORS.gray};
`;
export const LicenseRow = styled(Row)`
  padding: 0px 15px;
`;

export const EmptyTile = styled(LicenseTile)``;

export const NoLicenseText = styled.div`
  color: rgb(86 96 103);
  font-size: 0.9rem;
`;

export const LicenseContent = styled(Col)`
  color: ${({ color }) => color || 'rgb(86 96 103)'};
  font-size: 0.8rem;
  flex: 1;
  font-family: Barlow;
`;

export const ErrorMessage = styled.div`
  color: ${COLORS.errorRed};
  font-size: 0.7rem;
`;

export const ErrorMessageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  font-family: Barlow;
  flex-direction: column;
  padding-top: 7px;
`;

export const Wrapper = styled(Row)``;

export const UssfWrapper = styled(Wrapper)`
  margin-left: 10px;
  padding-left: 15px;
  padding-top: ${({ paddingtop }) => (paddingtop ? '20px' : '')};
`;

export const ColumnWrapper = styled(Col)``;

export const ExternalLink = styled.span`
  cursor: pointer;
  color: ${COLORS.bahamaBlue};
  text-decoration: underline;
`;
