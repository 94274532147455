import styled from 'styled-components';

import { COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  & .datepicker-alignment {
    background: #fff;
    position: absolute;
    left: -170px;
  }
`;

export const ExpandableWrapper = styled.div`
  background-color: ${COLORS.darkCerulean};
  width: ${pxToRem('420px')};
  z-index: 500;
  position: absolute;
  top: 100%;
  left: ${pxToRem('-1px')};
  cursor: default;
  box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.5);
  ${mediaQueries.md(`
  width:auto;
  left:${pxToRem('-120px')};
  height:400px;
  overflow:scroll;
  `)};
  ${({ sideList }) =>
    sideList
      ? `
        background-color: ${COLORS.aliceBlue};
        left: ${pxToRem('-95px')};
      `
      : ''};
`;

export const FieldWrapper = styled.div`
  margin: 1rem;
`;

export const FieldWrapperTime = styled.div`
  margin: 0.2rem;
`;

export const ButtonsRow = styled.div`
  box-sizing: border-box;
  height: ${pxToRem('72px')};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({ sideList }) => (sideList ? COLORS.lightCyanGray : `${COLORS.sapphire}66`)};
  padding: ${pxToRem('5px')};
`;

export const Count = styled.span`
  user-select: none;
  font-size: ${pxToRem('11px')};
  width: 1rem;
  height: 1rem;
  background: ${COLORS.darkishBlue};
  display: inline-block;
  border-radius: 50%;
  color: ${COLORS.white};
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;

  ${({ theme }) => theme || ''};
`;

export const Label = styled.span`
  user-select: none;
  color: ${({ sideList }) => (sideList ? COLORS.nightRider : COLORS.white)};
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('13px')};

  ${({ theme }) => theme || ''};
`;

export const AvailabityLabel = styled.span`
  user-select: none;
  font-size: ${pxToRem('13px')};
  color: #2b2b2b;
`;

export const FilterOptionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 0.25rem 0.5rem 0;
`;

export const NavOption = styled.div`
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  left: 0.2rem;
  position: relative;
  ${({ sideList }) =>
    sideList
      ? `
    position: relative;
    left:0.5rem;
  `
      : ''};
`;
