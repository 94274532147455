import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import MaterialDatePicker from '../../../controls/MaterialDatePicker';
import Button from '../../Button';
import { Wrapper, ElementWrapper } from './styled-components';
import { required, composeValidators, isSameOrBefore } from '../../../../utils/validations';
import { getEndOfDay } from '../../../../utils/parsers';

const theme = { noAutoTouch: true, wrapper: '', label: '', input: '' };

const DateRangePicker = ({
  onSubmit,
  activeBatch,
  initialValues: { start_date, end_date },
  fieldDirection,
  fieldWidth,
  fieldMargin,
  buttonLabel,
  primary,
  secondary
}) => (
  <Form
    onSubmit={(values, formApi) =>
      onSubmit(
        {
          start_date: moment(values.start_date).startOf('date').toDate(),
          end_date: moment(values.end_date).endOf('date').toDate()
        },
        formApi
      )
    }
    initialValues={{
      start_date: start_date && moment(start_date).toDate(),
      end_date: end_date && moment(end_date).toDate()
    }}
    render={({ handleSubmit, pristine, invalid, submitting }) => (
      <form
        onSubmit={event => {
          const promise = handleSubmit(event);
          if (promise) promise.then(({ reset }) => reset());
        }}
      >
        <Wrapper direction={fieldDirection}>
          <ElementWrapper width={fieldWidth} margin={fieldMargin}>
            <Field
              component={MaterialDatePicker}
              name="start_date"
              validate={composeValidators(required, (val, { end_date: endTime }) =>
                isSameOrBefore(val, endTime, undefined, '"Start Date" must precede "End Date"')
              )}
              placeholder="Start Date"
              theme={theme}
              disabledDays={{ after: new Date() }}
            />
          </ElementWrapper>
          <ElementWrapper width={fieldWidth} margin={fieldMargin}>
            <Field
              component={MaterialDatePicker}
              name="end_date"
              validate={composeValidators(required, val =>
                isSameOrBefore(
                  val,
                  getEndOfDay(new Date()),
                  undefined,
                  "End date can't postdate present day"
                )
              )}
              placeholder="End Date"
              theme={theme}
              disabledDays={{ after: new Date() }}
            />
          </ElementWrapper>
          <ElementWrapper width={fieldWidth} margin={fieldMargin}>
            <Button
              type="submit"
              label={buttonLabel}
              disabled={pristine || invalid || submitting || activeBatch}
              primary={primary}
              secondary={secondary}
            />
          </ElementWrapper>
        </Wrapper>
      </form>
    )}
  />
);

DateRangePicker.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  activeBatch: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  })
};

DateRangePicker.defaultProps = {
  initialValues: {
    start_date: '',
    end_date: ''
  }
};

export default DateRangePicker;
