import { allAvailableProvinces, countries } from '../../../../constants';
import { pxToRem } from '../../../../utils/helpers';
import MaterialInput from '../../MaterialInput';
import { isSameOrBefore, mustBeEmail, required } from '../../../../utils/validations';
import MaterialDatePicker from '../../../controls/MaterialDatePicker';
import { COLORS } from '../../../../utils/styles';
import MaterialUiDropDown from '../../../global-material-components/MaterialUiDropdown';
import MaterialUiTypehead from '../../../global-material-components/MaterialUiTypehead';

const isRequired = () => true;
export default {
  getInformationFields: (sportOptions, groupOptions, governingBodies, isEventGroupAdmin) => [
    {
      field: {
        component: MaterialInput,
        name: 'info.name',
        placeholder: 'Event Name*',
        validate: required,
        isRequired
      }
    },
    {
      field: {
        component: MaterialDatePicker,
        name: 'info.season_start_date',
        placeholder: 'Start Date*',
        validate: required,
        isRequired
      }
    },
    isEventGroupAdmin
      ? {
          field: {
            component: MaterialUiDropDown,
            name: 'info.group',
            options: groupOptions,
            placeholder: 'Assign Group Name*',
            validate: required,
            isRequired
          }
        }
      : {
          field: {
            component: MaterialInput,
            name: 'info.group_name',
            placeholder: 'Assign Group Name*',
            validate: required,
            disabled: true,
            isRequired
          }
        },
    {
      field: {
        component: MaterialDatePicker,
        name: 'info.season_end_date',
        placeholder: 'End Date*',
        validate: (value, allValues) => {
          const { season_start_date: startDate } = allValues.info;
          const dateFormat = 'YYYY-MM-DD';

          return isSameOrBefore(startDate, value, dateFormat) || required(value);
        },
        isRequired
      }
    },
    {
      field: {
        component: MaterialUiDropDown,
        name: 'info.governing_body',
        placeholder: 'Governing Body*',
        options: governingBodies,
        validate: required,
        isRequired
      }
    },
    { field: { component: MaterialInput, name: 'info.contact_name', placeholder: 'Contact Name' } },
    {
      field: {
        component: MaterialUiDropDown,
        name: 'info.sport_id',
        options: sportOptions,
        placeholder: 'Sport*',
        validate: required,
        isRequired
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'info.contact_email',
        placeholder: 'Contact Email',
        validate: mustBeEmail
      }
    },
    {
      field: {
        component: MaterialUiTypehead,
        name: 'info.area_country',
        placeholder: 'Country*',
        options: countries,
        validate: required,
        isRequired
      }
    },
    {
      field: { component: MaterialInput, name: 'info.contact_phone', placeholder: 'Contact Phone' }
    },
    {
      field: {
        component: MaterialUiTypehead,
        name: 'info.area_state',
        placeholder: 'State/Province',
        options: allAvailableProvinces
      }
    },
    {
      field: { name: 'empty_space' },
      isHalfContent: true
    }
  ]
};

export const gameSettingsCollapsibleTheme = {
  headerBg: 'transparent',
  headerMinHeight: pxToRem('32px'),
  headerMinPaddingTop: `${pxToRem('13px')}`,
  iconColorWhenClosed: COLORS.shuttleGrey,
  iconColorWhenOpen: COLORS.denimBlue,
  iconHeight: 13,
  iconWidth: 13,
  titleColorWhenClosed: COLORS.shuttleGray,
  titleColorWhenOpen: COLORS.denimBlue,
  titleFont: 'RobotoBold',
  titleLetterSpacing: '0.5px',
  titleSize: pxToRem('15px'),
  titleTransform: 'none',
  titleWeight: 'bold'
};
