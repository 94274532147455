export default {
  reportType: ['Misconduct', 'Serious Injury', 'Field Conditions', 'Other'],
  goalDropdownOptions: ['GOAL', 'PK', 'OWN'],
  sendOffOptions: [
    'Send Off - Denying Goal or Scoring Opportunity by Handling',
    'Send Off - Denying Obvious Goal Scoring Opportunity',
    'Send Off - Offensive, Insulting, or Abusive Language and/or Gestures',
    'Send Off - Receiving 2nd Caution',
    'Send Off - Serious Foul Play',
    'Send Off - Spitting',
    'Send Off - Violent Conduct',
    'Send Off - Other'
  ],
  cautionOptions: [
    'Caution - Delaying Restart of Play',
    'Caution - Dissent',
    'Caution - Entering Field Without Permission',
    'Caution - Failure to Respect Distance for Restart',
    'Caution - Leaving Field Without Permission',
    'Caution - Persistent Infringement',
    'Caution - Unsporting Behavior',
    'Caution - Other'
  ],
  timeOfIncident: [
    '1st half',
    '2nd half',
    'Overtime',
    'Before Game',
    'Half Time / Intermission',
    'After Game',
    'N/A'
  ],
  reason: [
    'Assault On Official',
    'Field Conditions',
    'Injury to Participant',
    'Irresponsible Behavior',
    'Serving Suspension / Sit Out',
    'Caution - Delaying Restart of Play',
    'Caution - Dissent',
    'Caution - Entering Field Without Permission',
    'Caution - Failure to Respect Distance for Restart',
    'Caution - Leaving Field Without Permission',
    'Caution - Persistent Infringement',
    'Caution - Unsporting Behavior',
    'Send Off - Denying Goal or Scoring Opportunity by Handling',
    'Send Off - Denying Obvious Goal Scoring Opportunity',
    'Send Off - Offensive, Insulting, or Abusive Language and/or Gestures',
    'Send Off - Receiving 2nd Caution',
    'Send Off - Serious Foul Play',
    'Send Off - Spitting',
    'Send Off - Violent Conduct',
    'Other'
  ],
  reportingOn: ['Player', 'Coach', 'Spectator', 'Other']
};
