import { compose, pure, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'ramda';
import {
  getAssignmentNote,
  getGameById,
  isGamesEventGroupAdmin
} from '../../../../core/Games/selectors';
import {
  isGamesEventAdmin as isGamesEventAdminSelector,
  isGamesEventOfficial as isGamesEventOfficialSelector,
  isGamesEventOfficialOrAssessor as isGamesEventOfficialOrAssessorSelector,
  isGamesEventAdminOrAssignor as isGamesEventAdminOrAssignorSelector,
  selectUserId,
  isSuperAdmin
} from '../../../../core/Auth/selectors';
import {
  getCurrentEvent,
  getEventAssessmentReportSettings,
  getReportSettings,
  getOfficialSelfAssignSettings
} from '../../../../core/Events/selectors';
import { clearAssignmentPosition } from '../../../../core/Notifications';
import { convertToUtc, isUnavailable } from '../../../../utils/helpers';
import {
  fetchCategory,
  isMaxGamePerDayExceeded,
  isMaxGamePer24hExceeded,
  hasSameTimeConflict,
  generateErrors
} from './util';
import { ASSIGNMENT_STATUS } from '../../../../core/Games/constants';
import { REPORT_LABELS, GAMES_STATUSES } from '../../../../constants';
import { AVAILABILITY_TYPES } from '../../../components/Profile/Availability/availabilityConstants';
import { fetchAssignmentNote as fetchAssignmentNoteAction } from '../../../../core/Games';

const mapStateToProps = state => ({
  currentEvent: getCurrentEvent(state),
  findGameById: id => getGameById(state, id),
  isGamesEventAdmin: isGamesEventAdminSelector(state),
  isSuperAdmin: isSuperAdmin(state),
  isGamesEventOfficial: isGamesEventOfficialSelector(state),
  isGamesEventOfficialOrAssessor: isGamesEventOfficialOrAssessorSelector(state),
  isGamesEventAdminOrAssignor: isGamesEventAdminOrAssignorSelector(state),
  currentUserId: selectUserId(state),
  assignmentFetching: state.games.assignmentFetching,
  ignoreRules: state.notifications.ignoreRules,
  assignedPosition: state.notifications.position,
  assignedUserId: state.notifications.user,
  selfAssignSettings: getOfficialSelfAssignSettings(state),
  eventsAssessmentSettings: getEventAssessmentReportSettings(state),
  isGamesEventGroupAdmin: isGamesEventGroupAdmin()(state),
  reportSettings: getReportSettings(state),
  assignmentNote: getAssignmentNote(state)
});

const mapDispatchToProps = dispatch => ({
  clearAssignmentPosition: () => dispatch(clearAssignmentPosition()),
  fetchAssignmentNote: payload => dispatch(fetchAssignmentNoteAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      selectedUser,
      assignments_game,
      display_id,
      location,
      start_date,
      start_time,
      end_date,
      end_time,
      gamesEventId,
      team_home,
      team_visitor,
      selfAssignSettings,
      isGamesEventAdmin,
      isGamesEventOfficial,
      reportSettings,
      report_submitted_by_position,
      has_game_reports,
      report_url,
      scores,
      timezone,
      id,
      isGamesEventOfficialOrAssessor,
      status,
      users
    }) => {
      const game = {
        id,
        display_id,
        start: moment.utc(convertToUtc(`${start_date} ${start_time}`, timezone)),
        end: moment.utc(convertToUtc(`${end_date} ${end_time}`, timezone)),
        status
      };

      const alreadyAssigned =
        selectedUser &&
        !!assignments_game.find(
          assignment => assignment.event_role.user_id === Number(selectedUser.id)
        );

      const isSelfAssign =
        selectedUser &&
        selfAssignSettings &&
        selfAssignSettings.checked &&
        !isGamesEventAdmin &&
        isGamesEventOfficial;

      let gameAssignments = [];
      if (selectedUser) {
        gameAssignments = selectedUser.game_assignments;
        if (isSelfAssign) {
          gameAssignments = gameAssignments.filter(
            assignment =>
              assignment.game.published ||
              [ASSIGNMENT_STATUS.unpublished, ASSIGNMENT_STATUS.declined].indexOf(
                assignment.status
              ) < 0
          );
        }
      }

      const isMaxGamePerDayExceededForSelfAssign =
        isSelfAssign &&
        selfAssignSettings.max_games_per_day &&
        isMaxGamePerDayExceeded(selfAssignSettings.max_games_per_day, start_date, gameAssignments);

      const isMaxGamePer24hExceededForSelfAssign =
        isSelfAssign &&
        selfAssignSettings.max_games_per_24h &&
        isMaxGamePer24hExceeded(
          selfAssignSettings.max_games_per_24h,
          start_date,
          start_time,
          gameAssignments
        );

      const isSelfAssignAllowedBeforeGame =
        isSelfAssign &&
        selfAssignSettings.allow_days_before_game &&
        game.start.diff(moment.utc(), 'days') >= selfAssignSettings.allow_days_before_game;

      const isSelfAssignBlockedBeforeGame =
        isSelfAssign &&
        selfAssignSettings.block_days_before_game &&
        game.start.diff(moment.utc(), 'days') <= selfAssignSettings.block_days_before_game;

      const name = selectedUser && `${selectedUser.first_name} ${selectedUser.last_name} `;

      const availabilities =
        selectedUser && selectedUser.availabilities && selectedUser.availabilities.length
          ? selectedUser.availabilities.filter(
              a => a.availability_type === AVAILABILITY_TYPES.UNAVAILABLE
            )
          : [];
      const gameState = location && location.state;
      const gamePostalCode = location && location.postal_code;
      const gameTeamHome = team_home && `${team_home.id}`;
      const gameTeamVisitor = team_visitor && `${team_visitor.id}`;
      const gameLocation = location && location.id;
      const locationId = location && location.id;

      const isNotAvailable = isUnavailable({ availabilities, game });
      const isMatchingCategory =
        !selectedUser ||
        !selectedUser.categories.length ||
        (gameState &&
          fetchCategory(
            selectedUser.categories,
            gameState,
            gamePostalCode,
            gameTeamHome,
            gameTeamVisitor,
            gameLocation
          ));

      const otherEventConflicts = [];
      const sameTimeConflicts = [];
      const backToBackConflicts = [];

      gameAssignments.forEach(otherAssignment => {
        // Allow assignments in cancelled and postponed games. Since they are not playable games then they don't need to
        // enforce rules where officials are not available.
        if (game.status === GAMES_STATUSES.CANCELLED || game.status === GAMES_STATUSES.POSTPONED)
          return;

        if (game.id === (otherAssignment && otherAssignment.external_game_id)) return;

        const otherAssignmentGameStatus = otherAssignment.game && otherAssignment.game.status;
        if (
          otherAssignmentGameStatus === GAMES_STATUSES.CANCELLED ||
          otherAssignmentGameStatus === GAMES_STATUSES.POSTPONED
        )
          return;

        const start =
          otherAssignment &&
          otherAssignment.game &&
          otherAssignment.game.start_at &&
          moment.utc(otherAssignment.game.start_at);
        const end =
          otherAssignment &&
          otherAssignment.game &&
          otherAssignment.game.end_at &&
          moment.utc(otherAssignment.game.end_at);

        if (Number(gamesEventId) !== otherAssignment.event_id) {
          if (
            [
              ASSIGNMENT_STATUS.pending,
              ASSIGNMENT_STATUS.accepted,
              ASSIGNMENT_STATUS.unpublished
            ].includes(otherAssignment.status) &&
            (start.isBetween(game.start, game.end, null, '[]') ||
              end.isBetween(game.start, game.end, null, '[]') ||
              game.start.isBetween(start, end, null, '[]') ||
              game.end.isBetween(start, end, null, '[]'))
          ) {
            otherEventConflicts.push(otherAssignment);
          }
        } else {
          if (
            (start.isSame(game.end) || end.isSame(game.start)) &&
            locationId !== otherAssignment.game.external_location_id
          ) {
            backToBackConflicts.push(otherAssignment);
          }
          if (
            start.isBetween(game.start, game.end, null, '[)') ||
            end.isBetween(game.start, game.end, null, '(]') ||
            game.start.isBetween(start, end, null, '[)') ||
            game.end.isBetween(start, end, null, '(]')
          ) {
            sameTimeConflicts.push(otherAssignment);
          }
        }
      });

      const sameTimeConflictsLabel = sameTimeConflicts.length !== 1 ? 'Games' : 'Game';

      const errors = generateErrors(name, [
        {
          message:
            selectedUser && alreadyAssigned && `is already assigned to game ${game.display_id}.`,
          includeName: true
        },
        {
          message:
            isNotAvailable && `is not available on the time and date of Game ${game.display_id}.`,
          includeName: true,
          ignoreRule: true,
          gameData: { id: game.id }
        },
        {
          message:
            isMaxGamePerDayExceededForSelfAssign &&
            `You can only be assigned ${selfAssignSettings.max_games_per_day}
          ${selfAssignSettings.max_games_per_day > 1 ? 'games' : 'game'} per day.`,
          includeName: false
        },
        {
          message:
            isMaxGamePer24hExceededForSelfAssign &&
            `You can only be assigned ${selfAssignSettings.max_games_per_24h}
          ${selfAssignSettings.max_games_per_24h > 1 ? 'games' : 'game'} for every 24 hours.`,
          includeName: false
        },
        {
          message:
            isSelfAssignAllowedBeforeGame &&
            `Self Assign is not available for games starting in ${
              selfAssignSettings.allow_days_before_game
            }
            ${selfAssignSettings.allow_days_before_game > 1 ? 'days or more' : 'day or more'}.
            Contact your assignor for help.`,
          includeName: false
        },
        {
          message:
            isSelfAssignBlockedBeforeGame &&
            `Self Assign is not available for games starting in ${
              selfAssignSettings.block_days_before_game
            }
            ${selfAssignSettings.block_days_before_game > 1 ? 'days or less' : 'day'}.
            Contact your assignor for help.`,
          includeName: false
        },
        {
          message:
            otherEventConflicts.length &&
            `has another game for another event on the time and date of Game ${game.display_id}.`,
          includeName: true
        },
        {
          message:
            sameTimeConflicts.length &&
            `is assigned to ${
              sameTimeConflicts.length
            } ${sameTimeConflictsLabel.toLowerCase()} with the same date and time: ${sameTimeConflictsLabel} ${sameTimeConflicts
              .map(({ game: gameInfo }) => gameInfo.external_event_id)
              .join(', ')}.`,
          includeName: true
        },
        {
          message:
            backToBackConflicts.length &&
            `cannot be assigned back to back games at different complexes: Games ${game.display_id}, ${backToBackConflicts[0].game.external_event_id}`,
          includeName: true,
          ignoreRule: true,
          gameData: { id: game.id }
        },
        {
          message:
            !isMatchingCategory && "User's assigned category does not match the game category",
          ignoreRule: true,
          gameData: { id: game.id }
        }
      ]);

      const gameReportSubmitted = () => {
        let result;
        if (report_url) {
          result = !!scores.length;
          return result;
        }
        const reportPosition = REPORT_LABELS.filter(
          (value, index) => !isEmpty(reportSettings) && reportSettings[`link_position_${index + 1}`]
        ).map(d => Object.values(d).toString());
        result =
          !isEmpty(reportSettings) && reportSettings.score_entry_stats === 'required'
            ? report_submitted_by_position &&
              reportPosition.length === report_submitted_by_position.length
            : has_game_reports;
        return result;
      };

      return {
        userGameErrors: errors,
        reportSubmittedStatus: gameReportSubmitted(),
        assignmentNotePermissions: {
          add: isGamesEventOfficialOrAssessor,
          view: !isGamesEventOfficialOrAssessor
        },
        game_assignments: assignments_game.map(ga => ({
          ...ga,
          isConflict: hasSameTimeConflict({
            game,
            user: users.find(u => Number(u.id) === (ga.event_role && ga.event_role.user_id)) || {},
            assignmentStatuses: [
              ASSIGNMENT_STATUS.unpublished,
              ASSIGNMENT_STATUS.pending,
              ASSIGNMENT_STATUS.accepted
            ],
            gameStatuses: [GAMES_STATUSES.CANCELLED, GAMES_STATUSES.POSTPONED]
          }),
          conflictTitle:
            'This person is in conflict and is assigned to another game during the same time.'
        }))
      };
    }
  ),
  withHandlers({
    toggleModal: ({
      setModalProps,
      findGameById,
      id,
      gamesEventUploadType,
      isGamesEventAdmin,
      categories,
      gameLevels
    }) => () => {
      setModalProps({
        gameId: id,
        gameInfo: findGameById(id),
        gamesEventUploadType,
        isGamesEventAdmin,
        categories,
        gameLevels
      });
    }
  }),
  pure
);
