import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { pxToRem } from '../../../../utils/helpers';
import Box from '../../../layouts/Box';

export const Container = styled.div`
  padding: 1rem 8rem;
  div {
    overflow: visible;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: ${pxToRem('84px')};
`;

export const InnerWrapper = styled(Col)``;

export const FormTile = styled(Box)``;

export const BillingWrapper = styled.div`
  display: inline;
  flex: 1;
  width: 100%;
`;
