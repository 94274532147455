import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import { pxToRem } from '../../../utils/helpers';
import { COLORS, HEIGHTS, mediaQueries } from '../../../utils/styles';
import Icon from '../Icon';

const MENU_HEIGHTS = {
  header: '57px',
  show: '44px'
};

export const Wrapper = styled.div`
  box-sizing: border-box;
  top: 10px;
  position: absolute;
  right: 4%;
  top: ${pxToRem('66px')};
  width: 92%;
  max-height: calc(100vh - ${HEIGHTS.appHeader} - ${HEIGHTS.paginationBar});
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  border-radius: ${pxToRem('6px')};
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.45);
  ${mediaQueries.md(` {
    position: fixed;
  }`)}
`;

export const Header = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${pxToRem('57px')};
  border-bottom: 1px solid ${COLORS.lightCyanGray};
`;

export const HeaderText = styled.span`
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.cobaltGrey};
`;

export const Count = styled.span`
  font-size: ${pxToRem('24px')};
  font-family: 'RobotoBold';
  color: ${COLORS.denimBlue};
  padding-right: ${pxToRem('6px')};
`;

export const Show = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-size: ${pxToRem('15px')};
  color: ${COLORS.nightRider};
  height: ${pxToRem('44px')};
  border-top: 1px solid ${COLORS.lightCyanGray};

  ${({ clickable }) => (clickable ? 'cursor: pointer' : '')};
`;

export const NotificationGridRow = styled(Row)`
  cursor: default;
  font-family: 'Roboto';
  box-sizing: border-box;
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: ${pxToRem('20px')};
  ${({ isExpanded }) => (isExpanded ? 'box-shadow: -1px 4px 5px 0px rgba(0,0,0,0.31)' : '')};
  ${({ noBorder }) => (noBorder ? '' : `border-bottom: 1px solid ${COLORS.lightCyanGray}`)};
`;

export const NotificationRow = styled.div`
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-family: 'Roboto';
  box-sizing: border-box;
  height: ${pxToRem('64px')};
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  padding: 0 ${pxToRem('18px')} 0 ${pxToRem('20px')};
  ${({ isExpanded }) => (isExpanded ? 'box-shadow: -1px 4px 5px 0px rgba(0,0,0,0.31)' : '')};
  ${({ noBorder }) => (noBorder ? '' : `border-bottom: 1px solid ${COLORS.lightCyanGray}`)};
`;

export const SubMenuRow = styled(NotificationRow)`
  cursor: pointer;
  justify-content: flex-start;

  &:hover {
    background: ${COLORS.lightCyanGray};
  }
`;

export const Summary = styled.span`
  font-size: ${pxToRem('15px')};
  color: ${COLORS.cobaltGrey};
`;

export const OrgName = styled.span`
  color: ${COLORS.denimBlue};
`;

export const Chevron = styled(Icon)`
  user-select: none;
  cursor: pointer;
  margin-left: ${pxToRem('12px')};
  margin-right: 0;

  ${({ isExpanded }) => (isExpanded ? `margin-right: ${pxToRem('2px')}` : '')};
`;

export const ExpandedNotificationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto';
  background-color: ${COLORS.white};
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection}` : '')};
`;

export const ExpandedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pxToRem('14px')} ${pxToRem('20px')} ${pxToRem('12px')};
  font-family: 'Roboto';
  background-color: ${COLORS.white};
  box-shadow: inset 0 4px 5px -4px rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid ${COLORS.lightCyanGray};
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection}` : '')};
`;

export const Label = styled.p`
  margin-top: 0;
  margin-bottom: ${pxToRem('4px')};
  font-size: ${pxToRem('11px')};
  font-family: 'RobotoCondensed';
  color: ${COLORS.shuttleGray};
  opacity: 0.72;
`;

export const Value = styled.p`
  margin-top: 0;
  margin-bottom: ${pxToRem('4px')};
  font-size: ${pxToRem('13px')};
  color: ${COLORS.nightRider};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionItems = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
  padding-left: ${pxToRem('20px')};
`;

export const DropdownContainer = styled.div`
  max-width: ${pxToRem('238px')};
  width: 100%;
  margin-bottom: ${pxToRem('15px')};
`;

export const Close = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: ${pxToRem('10px')};
  right: ${pxToRem('14px')};
  margin: 0;
`;

export const Back = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 1.25rem;
  left: ${pxToRem('14px')};
  margin: 0;
`;

export const Notifications = styled.div`
  max-height: calc(
    100vh - ${HEIGHTS.appHeader} - ${HEIGHTS.paginationBar} - ${MENU_HEIGHTS.header} -
      ${MENU_HEIGHTS.show}
  );
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: ${pxToRem('8px')};
  }

  &::-webkit-scrollbar-thumb {
    height: ${pxToRem('50px')};
    background: ${COLORS.greyChateau};
  }

  &::-webkit-scrollbar-track-piece {
    background: ${COLORS.white};
  }
`;

export const NotificationCount = styled.span`
  width: ${pxToRem('32px')};
  height: ${pxToRem('32px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: ${pxToRem('14px')};
  font-family: 'RobotoBold', sans-serif;
  font-weight: bold;
  padding-top: ${pxToRem('1px')};
  padding-left: ${pxToRem('1px')};
  color: ${COLORS.white};
  background-image: linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue});
  margin-right: 1rem;
`;

export const GameDate = styled.p`
  margin: 0;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.denimBlue};
`;

export const GameDateLink = styled.span`
  color: ${COLORS.denimBlue};
  font-size: ${pxToRem('13px')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    text-decoration: underline;
  }
`;

export const GameDataRow = styled.div`
  display: flex;
  cursor: default;
  margin-top: ${pxToRem('3px')};
  margin-bottom: 0;
  color: ${COLORS.cobaltGrey};
  flex-grow: ${({ header }) => (header ? '0' : '1')};
`;

export const GameId = styled.span`
  font-family: 'RobotoLight';
  font-size: 13px;
  margin-right: ${pxToRem('4px')};
  line-height: ${pxToRem('19px')};
`;

export const GameDataRowItem = styled.span`
  font-size: 13px;
  line-height: ${pxToRem('19px')};
  font-weight: 500;

  ${({ textBold }) => (textBold ? 'font-weight: bold' : '')};
  ${({ textBold }) => (textBold ? 'font-family: RobotoBold' : '')};
`;

export default {
  wrapper: `
    min-width: ${pxToRem('238px')}
  `,
  dropableContainer: {
    clickable: ``,
    caption: `
        color: ${COLORS.hawkesBlue};
      `,
    placeholder: `
        text-align: left;
        font-size: ${pxToRem('13px')};
        font-family: 'Roboto';
      `,
    icon: `
        position: relative;
        bottom: ${pxToRem('1px')};
        right: ${pxToRem('6px')};
      `,
    content: ``,
    label: 'hide'
  },
  itemsContainer: `
      ::-webkit-scrollbar {
        width: ${pxToRem('6px')};
        position: absolute;
        background-color: ${COLORS.white};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${COLORS.azureGray};
      }
    `,
  dropdownItem: `
      color: ${COLORS.shuttleGray};
      line-height: 2;
      font-family: 'Roboto';
      font-size: ${pxToRem('13px')};
      background-color: ${COLORS.white};
      height: ${pxToRem('24px')};
      border: none;

      &:hover {
        color: ${COLORS.shuttleGray};
        background-color: ${COLORS.aliceBlueVariant};
      }
      `
};
