import {
  SET_PRODUCT_INVOICES,
  SET_FETCHING_INVOICE,
  SET_PLATFORM_SUBSCRIPTIONS,
  SET_PLATFORM
} from './constants';
import { enhanceState, withFetch } from '../utils';
import { getGroupName } from '../../utils/auth';

const enhancer = enhanceState({
  fetch: withFetch
});

// Reducer
const initialState = {
  id: getGroupName({}),
  subscriptions: {},
  invoices: {}
};

export default function reducer(state = initialState, action = {}) {
  enhancer.flush();
  switch (action.type) {
    case SET_PRODUCT_INVOICES: {
      const {
        payload: { data, prop, product }
      } = action;
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [product]: {
            [prop]: data
          }
        }
      };
    }
    case SET_FETCHING_INVOICE: {
      const { product, value } = action;
      const invoice = state.invoices[action.invoice] || {};
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [product]: enhancer.set(invoice).fetch(value)
        }
      };
    }
    case SET_PLATFORM_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: {
          data: action.payload
        }
      };
    }
    case SET_PLATFORM: {
      return {
        ...state,
        id: action.platform
      };
    }
    default:
      return state;
  }
}
