import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../Flex';
import theme, {
  Checkbox,
  Field,
  AssessmentScore,
  ReportType,
  UserLabel,
  CrewLabel
} from '../../styled-components';
import { convertUtcToTimezone, getReportDisplayIdWithCount } from '../../../../../utils/helpers';

const AssessmentReport = ({
  gameReports,
  handleCheckboxClick,
  checked,
  crewLabels,
  onAssessmentReportClick,
  isReportsEventAdmin,
  isReportsEventAssignor,
  isReportsEventGroupAdmin
}) => (
  <Flex
    height="64px"
    bgImage="linear-gradient(to top, #f4f8fb, #ffffff)"
    justifyContent="space-between"
    fullWidth
    padding="0 36px"
    noShrink
    theme={theme.row.item}
  >
    {(isReportsEventAdmin || isReportsEventAssignor || isReportsEventGroupAdmin) && (
      <Checkbox
        type="checkbox"
        onChange={e =>
          handleCheckboxClick({
            id: gameReports[0].game_assignment.external_game_id,
            checked: e.target.checked
          })
        }
        checked={checked}
      />
    )}
    <Field flex={2} clickable originalCased justifyContent="center">
      {getReportDisplayIdWithCount(gameReports[0])}
    </Field>
    <Field flex={2} justifyContent="center">
      {convertUtcToTimezone(gameReports[0].game.start_at, gameReports[0].game.timezone, 'M/D/YY')}
    </Field>
    {crewLabels.map((crewLabel, i) => {
      const report = gameReports.find(
        ({ game_assignment: { official_label_col } }) => i === official_label_col
      );
      return (
        <Field
          flex={3}
          justifyContent="center"
          fullHeight
          alignItems="center"
          theme="overflow: hidden;"
          key={crewLabel.label}
        >
          {!report && <CrewLabel>{crewLabel.label}</CrewLabel>}

          {report && (
            <Flex
              key={report.id}
              direction="column"
              alignItems="center"
              justifyContent="center"
              theme={theme.assessmentCell}
              height="100%"
              onClick={() => onAssessmentReportClick({ crewLabel, report })}
            >
              <AssessmentScore approved={report.approved}>
                {report.updated_by_user_id ? report.score : '- -'}
              </AssessmentScore>
              <UserLabel>
                {report.game_assignment.event_role.user.first_name}{' '}
                {report.game_assignment.event_role.user.last_name}
              </UserLabel>
              {report.updated_by_user_id && <ReportType>{report.report_type}</ReportType>}
            </Flex>
          )}
        </Field>
      );
    })}
  </Flex>
);

AssessmentReport.propTypes = {
  gameReports: PropTypes.arrayOf(Object).isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  crewLabels: PropTypes.arrayOf(Object).isRequired,
  onAssessmentReportClick: PropTypes.func.isRequired,
  isReportsEventAdmin: PropTypes.bool,
  isReportsEventAssignor: PropTypes.bool
};

AssessmentReport.defaultProps = {
  checked: false,
  isReportsEventAdmin: false,
  isReportsEventAssignor: false
};

export default AssessmentReport;
