import styled from 'styled-components';

import { pxToRem } from '../../../utils/helpers';
import { COLORS, HEIGHTS } from '../../../utils/styles';

export const Field = styled.div`
  display: flex;
  flex: ${({ flex }) => flex || 4};
  font-family: 'RobotoCondensed';
  font-size: 12px;
  color: ${COLORS.nightRider};
  padding: ${({ padding }) => `${padding}` || '0 0.25rem'};

  ${({ originalCased }) => !originalCased && `text-transform: capitalize;`};
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent}` : '')};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : '')};
  ${({ header }) => (header ? `color: ${COLORS.white}` : '')};
  ${({ clickable }) =>
    clickable
      ? `
    color: ${COLORS.denimBlue};
    text-decoration: underline;
    cursor: pointer;
  `
      : ''};
  ${({ fullHeight }) => (fullHeight ? 'height: 100%' : '')};

  ${({ theme }) => theme || ''};
`;

export const Checkbox = styled.input`
  display: flex;
  flex: 1;
`;

export const EmptyMessage = styled.p`
  margin: ${pxToRem('23px')} 0 0 ${pxToRem('34px')};
  font-family: 'RobotoLight';
  font-size: 1rem;
  line-height: 1.06;
  color: ${COLORS.shuttleGray};
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - ${HEIGHTS.appHeader} - ${HEIGHTS.eventNavigationBar} - ${HEIGHTS.eventFilterBar} -
      ${HEIGHTS.paginationBar}
  );
  overflow-y: auto;
  overflow-x: hidden;
`;

export const AssessmentScore = styled.p`
  margin: 0;
  color: ${({ approved }) => (approved ? COLORS.denimBlue : COLORS.cobaltGrey)};
  font-size: ${pxToRem('17px')};
  font-family: 'Roboto';
  position: absolute;
  top: ${pxToRem('2px')};
`;

export const ReportType = styled.p`
  margin: 0;
  color: ${COLORS.sapphire};
  font-size: ${pxToRem('10px')};
  font-family: 'RobotoCondensed';
  padding-bottom: ${pxToRem('6px')};
  position: absolute;
  bottom: 0.25rem;
`;

export const UserLabel = styled.p`
  margin: 0;
  color: ${COLORS.sapphire};
  font-size: ${pxToRem('12px')};
  font-family: 'RobotoCondensedBold';
  padding-bottom: ${pxToRem('2px')};
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CrewLabel = styled.p`
  margin: 0;
  color: ${COLORS.greyChataeu};
  font-size: ${pxToRem('18px')};
  font-family: 'RobotoCondensed';
  opacity: 0.3;
  cursor: default;
`;

export default {
  content: `height:100%; overflow-y:hidden`,
  row: {
    wrapper: 'overflow-x: hidden; overflow-y: auto; margin-bottom: 35px',
    item: `border-bottom: 1px solid ${COLORS.lightCyanGray}`
  },
  dropdown: {
    wrapper: `
      width: 132px;
      height: 100%;
      background: transparent;
    `,
    header: `
      background: transparent;
      color: ${COLORS.white};
      box-shadow: none;
      font-size: ${pxToRem('13px')};

      &.hasValue {
        box-shadow: none;
      }
    `,
    chevron: {
      color: COLORS.white
    },
    option: `
      background-color: ${COLORS.bahamaBlue};
      color: ${COLORS.white};

      &:hover {
        background-color: ${COLORS.sapphire};
      }
    `,
    options: `
      top: ${pxToRem('40px')};

      &::-webkit-scrollbar {
        background-color: ${COLORS.bahamaBlue};
      }

      &::-webkit-scrollbar-thumb {
        background: ${COLORS.sapphire};
      }
    `,
    value: 'padding-left: 0.5rem;'
  },
  dropdownContainer: `border-right: 1px solid ${COLORS.denimBlue}`,
  action: {
    icon: {
      color: COLORS.white,
      marginTop: '0px',
      marginRight: '0px'
    },
    dropdown: {
      topOffset: '34px',
      rightOffset: '12px',
      width: '140px'
    },
    option: {
      background: COLORS.denimBlue,
      color: COLORS.white,
      hoverBackground: COLORS.darkCerulean,
      hoverColor: COLORS.white
    },
    optionDisabled: {
      background: COLORS.denimBlue,
      color: COLORS.strongCyan,
      hoverBackground: COLORS.denimBlue,
      hoverColor: COLORS.strongCyan
    }
  },
  assessmentCell: `
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  `
};
