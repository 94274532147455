import React from 'react';
import PropTypes from 'prop-types';

import IncidentReport from './IncidentReport';
import Subheader from '../Subheader';
import LoaderWrapper from '../../LoaderWrapper';
import Flex from '../../Flex';
import theme, { EmptyMessage } from '../styled-components';

const IncidentReports = ({
  reports,
  handleCheckboxClick,
  checkedReports,
  handleSelectAll,
  isFetching,
  onIncidentReportClick,
  sort,
  isReportsEventAdmin,
  isReportsEventAssignor,
  isReportsEventGroupAdmin
}) => (
  <>
    <Subheader
      handleSelectAll={handleSelectAll}
      checked={!!reports.length && reports.length === checkedReports.length}
      sort={sort}
      isReportsEventAdmin={isReportsEventAdmin}
      isReportsEventAssignor={isReportsEventAssignor}
      isReportsEventGroupAdmin={isReportsEventGroupAdmin}
    />
    <LoaderWrapper isFetching={isFetching}>
      {reports.length ? (
        <Flex direction="column" full fullWidth theme={theme.row.wrapper}>
          {reports.map(report => (
            <IncidentReport
              key={report.id}
              report={report}
              handleCheckboxClick={handleCheckboxClick}
              checked={checkedReports.includes(report.id)}
              onIncidentReportClick={onIncidentReportClick}
              isReportsEventAdmin={isReportsEventAdmin}
              isReportsEventAssignor={isReportsEventAssignor}
              isReportsEventGroupAdmin={isReportsEventGroupAdmin}
            />
          ))}
        </Flex>
      ) : (
        <Flex fullWidth>
          <EmptyMessage>No reports available</EmptyMessage>
        </Flex>
      )}
    </LoaderWrapper>
  </>
);

IncidentReports.propTypes = {
  reports: PropTypes.arrayOf(Object),
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedReports: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onIncidentReportClick: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  isReportsEventAdmin: PropTypes.bool,
  isReportsEventAssignor: PropTypes.bool,
  isReportsEventGroupAdmin: PropTypes.bool
};

IncidentReports.defaultProps = {
  reports: [],
  isReportsEventAdmin: false,
  isReportsEventGroupAdmin: false,
  isReportsEventAssignor: false
};

export default IncidentReports;
