import { uniqBy } from 'ramda';

// import MultipleInput from '../Reusable/MultipleInput';
import MultiSelectDropdown from '../Reusable/MultiSelectDropdown';
import theme from '../SideList/styled-components';

export default ({ gamelevels = [] }) => ({
  sort: {
    options: [
      {
        name: 'name',
        label: 'Name'
      },
      {
        name: 'game_level',
        label: 'Level'
      },
      {
        name: 'game_type',
        label: 'Type'
      }
    ]
  },
  filters: [
    {
      component: MultiSelectDropdown,
      name: 'game_level.game_level',
      placeholder: 'Level',
      options: uniqBy(({ game_level }) => game_level, gamelevels).map(level => ({
        name: level.game_level,
        value: level.game_level
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MultiSelectDropdown,
      name: 'game_level.game_type',
      placeholder: 'Type',
      options: uniqBy(({ game_type }) => game_type, gamelevels).map(level => ({
        name: level.game_type,
        value: level.game_type
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    }
    // {
    //   component: MultipleInput,
    //   name: 'team.club',
    //   placeholder: 'Club',
    //   theme: theme.multipleInput
    // },
    // {
    //   component: MultipleInput,
    //   name: 'team.league',
    //   placeholder: 'League',
    //   theme: theme.multipleInput
    // }
  ],
  actions: {
    options: []
  }
});
