import { createSelector } from 'reselect';
import { identity } from 'ramda';

// Actions
export const ADD_NOTIFICATIONS = 'NOTIFICATIONS/ADD_NOTIFICATIONS';
export const REMOVE_NOTIFICATIONS = 'NOTIFICATIONS/REMOVE_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'NOTIFICATIONS/SET_NOTIFICATIONS';
export const UNSET_NOTIFICATIONS = 'NOTIFICATIONS/UNSET_NOTIFICATIONS';
export const SET_INVITATIONS = 'NOTIFICATIONS/SET_INVITATIONS';
export const REMOVE_INVITATION = 'NOTIFICATIONS/REMOVE_INVITATION';
export const SET_ASSIGNMENT_WARNINGS = 'NOTIFICATIONS/SET_ASSIGNMENT_WARNINGS';
export const CLEAR_ASSIGNMENT_WARNINGS = 'NOTIFICATIONS/CLEAR_ASSIGNMENT_WARNINGS';

export const FETCH_NOTIFICATION = 'NOTIFICATIONS/FETCH_NOTIFICATION';
export const SET_NOTIFICATION = 'NOTIFICATIONS/SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'NOTIFICATIONS/CLEAR_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'NOTIFICATIONS/UPDATE_NOTIFICATION';
export const DELETE_NOTIFICATION = 'NOTIFICATIONS/DELETE_NOTIFICATIONS';

export const SET_NOTIFICATION_COUNTS = 'NOTIFICATIONS/SET_NOTIFICATION_COUNTS';
export const UPDATE_NOTIFICATION_COUNTS = 'NOTIFICATIONS/UPDATE_NOTIFICATION_COUNTS';
export const FETCH_MENU_NOTIFICATIONS = 'NOTIFICATIONS/FETCH_MENU_NOTIFICATIONS';
export const SET_MENU_NOTIFICATIONS = 'NOTIFICATIONS/SET_MENU_NOTIFICATIONS';
export const SET_BANNER_NOTIFICATIONS = 'NOTIFICATIONS/SET_BANNER_NOTIFICATIONS';
export const FETCH_BANNER_NOTIFICATIONS = 'NOTIFICATIONS/FETCH_BANNER_NOTIFICATIONS';

export const REMOVE_MENU_NOTIFICATION = 'NOTIFICATIONS/REMOVE_MENU_NOTIFICATION';

export const SET_IS_FETCHING = 'NOTIFICATIONS/SET_IS_FETCHING';

export const ALLOW_IGNORE_RULES = 'NOTIFICATIONS/ALLOW_IGNORE_RULES';
export const ALLOW_IGNORE_RULES_SUCCESS = 'NOTIFICATIONS/ALLOW_IGNORE_RULES_SUCCESS';
export const ALLOW_IGNORE_RULES_CLEAR = 'NOTIFICATIONS/ALLOW_IGNORE_RULES_CLEAR';
export const SET_EVENT = 'NOTIFICATION/SET_EVENT';
export const SET_GAME_NOTIFICATIONS = 'NOTIFICATIONS/SET_GAME_NOTIFICATIONS';

// Reducer
export const initialState = {
  list: [],
  assignmentWarnings: [],
  menuNotifications: [],
  bannerNotifications: [],
  notificationCounts: {},
  current: {},
  ignoreRules: false,
  isFetching: false,
  event: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        list: [...action.payload]
      };
    case UNSET_NOTIFICATIONS:
      return {
        ...state,
        list: []
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        current: action.payload
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        current: {}
      };
    case SET_NOTIFICATION_COUNTS:
      return {
        ...state,
        notificationCounts: action.payload
      };

    case UPDATE_NOTIFICATION_COUNTS:
      return {
        ...state,
        notificationCounts: {
          ...state.notificationCounts,
          total_count: state.notificationCounts.total_count - 1,
          [action.payload]: state.notificationCounts[action.payload]
        }
      };

    case FETCH_MENU_NOTIFICATIONS:
      return {
        ...state,
        menuNotifications: []
      };
    case FETCH_BANNER_NOTIFICATIONS:
      return {
        ...state,
        bannerNotifications: []
      };
    case SET_MENU_NOTIFICATIONS:
      return {
        ...state,
        menuNotifications: action.payload
      };
    case SET_BANNER_NOTIFICATIONS:
      return {
        ...state,
        bannerNotifications: action.payload
      };
    case REMOVE_MENU_NOTIFICATION:
      return {
        ...state,
        notificationCounts: {
          ...state.notificationCounts,
          total_count: state.notificationCounts.total_count - 1,
          [action.payload.alertType]: state.notificationCounts[action.payload.alertType] - 1
        },
        menuNotifications: state.menuNotifications.filter(
          notification => notification.id !== action.payload.id
        )
      };
    case SET_ASSIGNMENT_WARNINGS:
      return {
        ...state,
        assignmentWarnings:
          action.payload && action.payload.assignmentWarnings
            ? action.payload.assignmentWarnings
            : action.payload,
        position:
          action.payload && (action.payload.position === 0 || action.payload.position)
            ? action.payload.position
            : null,
        user:
          action.payload && (action.payload.user === 0 || action.payload.user)
            ? action.payload.user
            : null
      };
    case ALLOW_IGNORE_RULES_SUCCESS:
      return {
        ...state,
        ignoreRules: action.payload
      };
    case ALLOW_IGNORE_RULES_CLEAR:
      return {
        ...state,
        ignoreRules: null,
        position: null,
        user: null
      };
    case CLEAR_ASSIGNMENT_WARNINGS:
      return {
        ...state,
        assignmentWarnings: []
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_EVENT:
      return {
        ...state,
        event: action.payload
      };
    default:
      return state;
  }
}

// Selectors
export const getNotifications = createSelector(state => state.notifications.list, identity);

// Action Creators
export const addNotifications = payload => ({
  payload,
  type: ADD_NOTIFICATIONS
});

export const removeNotifications = () => ({
  type: REMOVE_NOTIFICATIONS
});

export const setAssignmentWarnings = payload => ({
  type: SET_ASSIGNMENT_WARNINGS,
  payload
});

export const allowIgnoreRules = payload => ({
  type: ALLOW_IGNORE_RULES,
  payload
});

export const clearAssignmentWarnings = () => ({
  type: CLEAR_ASSIGNMENT_WARNINGS
});

export const fetchNotification = id => ({
  type: FETCH_NOTIFICATION,
  payload: { id }
});

export const setNotification = payload => ({
  type: SET_NOTIFICATION,
  payload
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION
});

export const setNotificationCounts = payload => ({
  type: SET_NOTIFICATION_COUNTS,
  payload
});

export const updateNotificationCount = payload => ({
  type: UPDATE_NOTIFICATION_COUNTS,
  payload
});

export const fetchMenuNotifications = payload => ({
  type: FETCH_MENU_NOTIFICATIONS,
  payload
});

export const setMenuNotifications = payload => ({
  type: SET_MENU_NOTIFICATIONS,
  payload
});

export const fetchBannerNotifications = payload => ({
  type: FETCH_BANNER_NOTIFICATIONS,
  payload
});

export const setBannerNotifications = payload => ({
  type: SET_BANNER_NOTIFICATIONS,
  payload
});

export const removeMenuNotification = payload => ({
  type: REMOVE_MENU_NOTIFICATION,
  payload
});

export const updateNotification = payload => ({
  type: UPDATE_NOTIFICATION,
  payload
});

export const setIsFetching = payload => ({
  type: SET_IS_FETCHING,
  payload
});

export const clearAssignmentPosition = () => ({
  type: ALLOW_IGNORE_RULES_CLEAR
});

export const setEvent = payload => ({
  type: SET_EVENT,
  payload
});

export const deleteNotification = payload => ({
  type: DELETE_NOTIFICATION,
  payload
});
