import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { CircularProgress } from '@material-ui/core';

import Enhancer from '../../../containers/PaymentHistory';
import { WhiteContentContainer } from '../styled-components';
import { Select, CollapsableRowTable } from '../../../global-material-components';

/* monthsShort returns a 0 indexed value for months so we add 1 to correct it */
const monthOptions = moment.monthsShort().map((month, i) => ({
  value: i + 1,
  label: month
}));

const yearOptions = [...Array(10).keys()].map(index => ({
  value: moment().year() - index,
  label: moment().year() - index
}));

const headings = [
  // {
  //   name: 'Transaction #',
  //   id: 'transactionId',
  //   sortable: true,
  //   sortFunction: () => (a, b) => Number(a.batchId) - Number(b.batchId)
  // },
  {
    name: 'Request Date',
    id: 'requestDate',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Deposit Date',
    id: 'depositDate',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Status',
    id: 'status',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Fees',
    id: 'fees',
    sortable: false,
    sortFunction: null,
    parser: val => `$${Number(val).toFixed(2)}`
  },
  {
    name: 'Total',
    id: 'total',
    sortable: false,
    sortFunction: null,
    parser: val => `${Number(val) < 0 ? '-' : ''}$${Math.abs(Number(val)).toFixed(2)}`
  },
  {
    name: '',
    id: '',
    sortable: false,
    sortFunction: null
  }
];

const subTableHeadings = [
  {
    name: 'Batch Id',
    id: 'batchId',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Event Name',
    id: 'eventName',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Payment Period',
    id: 'period',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Amount',
    id: 'amount',
    sortable: false,
    sortFunction: null
  }
];

const PaymentHistoryPage = ({
  isFetching,
  fetchActiveUserInfo,
  paymentHistory,
  paymentBatchDetails,
  userId,
  pathUrl,
  filter: { onFilter, filterData },
  isFetchingPayee
}) => {
  const [year, setYear] = useState(filterData.year || moment().year());
  const [month, setMonth] = useState(filterData.month || moment().month() + 1);
  useEffect(
    () => {
      fetchActiveUserInfo({ userId, month, year, pathUrl });
    },
    // eslint-disable-next-line
    [year, month]
  );

  useEffect(() => {
    onFilter({ month, year });
    // eslint-disable-next-line
  }, [month, year]);

  return (
    <WhiteContentContainer padding fetching={isFetching || isFetchingPayee}>
      {isFetching || isFetchingPayee ? (
        <CircularProgress />
      ) : (
        <>
          <Select
            label="Year"
            style={{ width: '100px', marginRight: '20px' }}
            value={year}
            onChange={setYear}
            options={yearOptions}
            IconComponent={ExpandMore}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          />
          {/* All is set to 13 because using null or 0 doesn't raise an event calling the api. chalking it up to react chicanery */}
          <Select
            options={[...monthOptions, { label: 'All', value: 13 }]}
            label=" Month"
            style={{ width: '100px' }}
            value={month}
            onChange={setMonth}
            IconComponent={ExpandMore}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          />
          <CollapsableRowTable
            dataKey="transactionId"
            data={paymentHistory}
            paymentBatchDetails={paymentBatchDetails}
            headings={headings}
            subTableHeadings={subTableHeadings}
          />
        </>
      )}
    </WhiteContentContainer>
  );
};

export default Enhancer(PaymentHistoryPage);
