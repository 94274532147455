import styled from 'styled-components';
import Icon from '../../Icon';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const RecipientItemOuterContainer = styled.div`
  width: 50%; /* Fallback */
  box-sizing: border-box;
  display: inline-block;

  &:nth-child(odd) {
    width: calc(50% - ${pxToRem('20px')});
    margin: 0 ${pxToRem('10px')};
  }

  &:nth-child(even) {
    width: calc(50% - ${pxToRem('10px')});
    margin-left: 0;
  }

  &:nth-child(n + 3) {
    margin-top: ${pxToRem('10px')};
  }
`;

export const RecipientItemInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: ${pxToRem('5px')};
  background: ${COLORS.blackSqueeze};
`;

export const FullNameLabel = styled.span`
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  font-weight: bold;
  color: ${COLORS.shuttleGrey};
`;

export const EmailLabelContainer = styled.div`
  width: 100%;
  padding: ${pxToRem('5px')};
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: ${COLORS.white};
  box-shadow: 1px 1px 2px 0 ${COLORS.shadow}30;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 100000;

  ${RecipientItemInnerContainer}:hover & {
    display: block;
  }
`;

export const EmailLabel = styled.span`
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  font-stretch: condensed;
  color: ${COLORS.shuttleGrey};
`;

export const StyledIcon = styled(Icon)`
  margin: 0;
  cursor: pointer;
`;

export default RecipientItemOuterContainer;
