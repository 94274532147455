import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export default {
  dropdownContainer: `border-right: 1px solid ${COLORS.denimBlue}`,
  dropdown: {
    wrapper: `
          width: 132px;
          height: 100%;
          background: transparent;
        `,
    header: `
          background: transparent;
          color: ${COLORS.white};
          box-shadow: none;
          font-size: ${pxToRem('13px')};
    
          &.hasValue {
            box-shadow: none;
          }
        `,
    chevron: {
      color: COLORS.white
    },
    option: `
          background-color: ${COLORS.bahamaBlue};
          color: ${COLORS.white};
    
          &:hover {
            background-color: ${COLORS.sapphire};
          }
        `,
    options: `
          top: ${pxToRem('40px')};
    
          &::-webkit-scrollbar {
            background-color: ${COLORS.bahamaBlue};
          }
    
          &::-webkit-scrollbar-thumb {
            background: ${COLORS.sapphire};
          }
        `,
    value: 'padding-left: 0.5rem;'
  }
};
