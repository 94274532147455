import { createSelector } from 'reselect';
import { find, equals } from 'ramda';

export const getLocations = ({ locations: { list } }) => list;

export const getLocationMeta = ({ locations: { meta } }) => meta;

export const getTotalLocationCount = createSelector(
  getLocationMeta,
  ({ total_count: totalCount }) => totalCount
);

export const getLocationLimit = createSelector(getLocationMeta, ({ limit }) => limit);

export const getLocationById = (state, id) =>
  createSelector(
    () => getLocations(state),
    find(({ locationId }) => equals(locationId, id))
  )(state);

export const getCurrentLocation = ({ locations: { current } }) => current;
export const getCurrentLocationIsFetching = ({ locations: { current } }) => current.isFetching;
export const getCurrentLocationTooltipTarget = ({ locations: { current } }) => current.target;

export const getUnfilteredLocations = ({
  locations: {
    data: { unfilteredList }
  }
}) => unfilteredList;
