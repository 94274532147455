import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  fetchGameLocation as fetchGameLocationAction,
  setTooltipTarget
} from '../../../core/Locations';

import {
  getCurrentLocation,
  getCurrentLocationIsFetching,
  getCurrentLocationTooltipTarget
} from '../../../core/Locations/selectors';
import { fetchUserAvailabilityNote, setUserAvailabilityNote } from '../../../core/User';
import { getAvailabilityNote, getAvailabilityNoteisFetching } from '../../../core/User/selectors';

const mapStateToProps = state => ({
  isLocationFetching: getCurrentLocationIsFetching(state),
  location: getCurrentLocation(state),
  availabilityNote: getAvailabilityNote(state),
  availabilityNotefetching: getAvailabilityNoteisFetching(state),
  tooltipTarget: getCurrentLocationTooltipTarget(state)
});

const mapDispatchToProps = dispatch => ({
  fetchGameLocation: payload => dispatch(fetchGameLocationAction(payload)),
  fetchUserAvailabilityNote: payload => dispatch(fetchUserAvailabilityNote(payload)),
  setUserAvailabilityNote: payload => dispatch(setUserAvailabilityNote(payload)),
  setTooltipTarget: payload => dispatch(setTooltipTarget(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps));
