import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';

const DeleteGamesModal = ({
  onClose,
  selected: selectedGames,
  onSubmit,
  props: { isGameUploadTypeManual }
}) => {
  let selected = selectedGames;

  if (!isGameUploadTypeManual) {
    selected = selectedGames.filter(game => game.copy);
  }

  const gameAssignments = selected.reduce(
    (sum, game) => sum + game.assignments_game.filter(assignment => assignment).length,
    0
  );
  return (
    <Modal title="Delete Games" onClose={onClose} leftPanel>
      <>
        <Row>
          <HighlightedBlock xs={12}>
            <Content>
              <Summary>
                <Count color={COLORS.denimBlue}>{selected.length}</Count> Games Selected
              </Summary>
              <Summary>
                <Count color={COLORS.cobaltGrey}>{gameAssignments}</Count>
                Assignments
              </Summary>
            </Content>
          </HighlightedBlock>
        </Row>
        <Row>
          <Block xs={10} xsOffset={1}>
            <Content light first>
              <p
                dangerouslySetInnerHTML={{
                  __html: `Deleting games will permanently remove the games and crew assignments from the event.
              <br> Do you wish to permanently delete these games?`
                }}
              />
            </Content>
          </Block>
        </Row>
        <Row>
          <Block xs={12} className="last">
            <ButtonsContainer>
              <ButtonContainer>
                <Button
                  label="Delete"
                  onClick={() => {
                    onClose();
                    onSubmit(selected);
                  }}
                  disabled={!selected.length}
                  width="72px"
                  height="32px"
                  primary
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
              </ButtonContainer>
            </ButtonsContainer>
          </Block>
        </Row>
      </>
    </Modal>
  );
};

DeleteGamesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired
};

DeleteGamesModal.defaultProps = {};

export default DeleteGamesModal;
