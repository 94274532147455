import React from 'react';
import PropTypes from 'prop-types';

import helpers from '../helpers';
import Flex from '../../../Flex';
import Icon from '../../../Icon';
import theme, { Checkbox, Field } from '../../styled-components';
import { COLORS, ICONS } from '../../../../../utils/styles';
import { convertUtcToTimezone } from '../../../../../utils/helpers';

const GameReport = ({
  report,
  handleCheckboxClick,
  checked,
  onGameReportClick,
  isReportsEventAdmin,
  isReportsEventAssignor,
  isReportsEventGroupAdmin,
  getReportsEventSport,
  settings
}) => {
  const info = helpers.getReportInfo(report, settings, getReportsEventSport);
  return (
    <Flex
      height="55px"
      bgImage="linear-gradient(to top, #f4f8fb, #ffffff)"
      justifyContent="space-between"
      fullWidth
      padding="0 36px"
      noShrink
      theme={theme.row.item}
    >
      {(isReportsEventAssignor || isReportsEventAdmin || isReportsEventGroupAdmin) && report && (
        <Checkbox
          type="checkbox"
          onChange={e => handleCheckboxClick({ id: report.id, checked: e.target.checked })}
          checked={checked}
        />
      )}
      <Field
        clickable
        onClick={() => onGameReportClick(report)}
        originalCased
        justifyContent="center"
      >
        {report.external_event_id}
      </Field>
      <Field justifyContent="center">
        {convertUtcToTimezone(report.game_date, report.timezone, 'M/D/YY')}
      </Field>
      {info.map((value, index) => (
        <Field key={index} justifyContent="center">
          {value}
        </Field>
      ))}
      <Field justifyContent="center">
        {report &&
          report.submitted_by &&
          `${report.submitted_by.first_name} ${report.submitted_by.last_name}`}
      </Field>
      <Field justifyContent="center">
        <Flex justifyContent="center" maxWidth="45px" fullWidth>
          <>
            {!!report.approved && (
              <Icon
                width={18}
                height={18}
                icon={ICONS.CHECK_CIRCLE_HOLLOW}
                color={COLORS.forestGreen}
              />
            )}
          </>
        </Flex>
      </Field>
    </Flex>
  );
};

GameReport.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  onGameReportClick: PropTypes.func.isRequired,
  isReportsEventAdmin: PropTypes.bool
};

GameReport.defaultProps = {
  checked: false,
  isReportsEventAdmin: false
};

export default GameReport;
