import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FormHelperText } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import COLORS from '../../../utils/styles';
import { HelpText, ButtonMenuItem } from './styled-components';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'block'
  },
  extraNotes: {
    paddingLeft: '8px',
    fontSize: '10px'
  },
  formControl: {
    minWidth: '100%',
    width: '100%',
    '& .MuiInput-underline.Mui-error:after': {
      borderBottom: '1px solid #c61e1e'
    },
    '& .MuiInput-underline.Mui-focused:not(.Mui-error):after': {
      borderBottom: `1px solid ${COLORS.denimBlue}`
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #8b9198'
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  selectWrapper: theme.selectWrapper,
  selectRoot: {
    fontSize: '13px',
    color: COLORS.denimBlue,
    paddingLeft: '8px',
    '&:focus': {
      background: 'none'
    },
    '&.Mui-disabled': {
      backgroundColor: '#ebebe4',
      color: '#cecece'
    }
  },
  selectIcon: {
    color: COLORS.denimBlue,
    '&.Mui-disabled': {
      color: '#cecece'
    }
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 0,
    maxHeight: ({ listHeight }) => listHeight || '120px',
    borderBottom: '1px solid #ccc',
    maxWidth: '800px',

    '& li': {
      fontWeight: 200,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: '12px',
      borderBottom: '1px solid #ccc',
      background: `${COLORS.white}`,
      color: '#000',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block'
    },
    '& li.Mui-selected': {
      background: `${COLORS.white}`,
      color: '#000'
    },
    '& li.MuiListItem-root:hover, & li.MuiListItem-root.Mui-selected:hover': {
      background: `${COLORS.regalBlue}`,
      color: `#fff`
    },
    '& li.MuiListItem-root:focus': {
      background: `${COLORS.regalBlue}`,
      color: `#fff`
    },
    '& .MuiListItem-root.Mui-disabled': {
      background: `${COLORS.white} !important`,
      opacity: 1
    }
  },
  labelRoot: {
    paddingLeft: '10px',
    color: `${COLORS.black} !important`
  },
  errorRoot: {
    paddingLeft: '10px',
    fontSize: '10px'
  },
  paper: {
    maxWidth: '800px'
  }
}));

const MaterialUiDropDown = ({
  placeholder,
  value,
  options,
  defaultValue,
  disabled,
  readOnly,
  theme,
  meta: { error },
  helpText,
  input,
  optionButton,
  onButtonClick,
  onOptionClick,
  extraNotes,
  showExtraNotes
}) => {
  const classes = useStyles(theme);
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null,
    style: { zIndex: '9999' }
  };

  const handleOptionClick = (event, option) => {
    if (onOptionClick) {
      onOptionClick(option.value, showExtraNotes);
    }
  };

  return (
    <div>
      {helpText && <HelpText>{helpText}</HelpText>}
      <FormControl className={classes.formControl} error={error}>
        <InputLabel htmlFor="select-label" classes={{ root: classes.labelRoot }}>
          {placeholder}
        </InputLabel>
        <Select
          MenuProps={menuProps}
          value={value}
          disabled={!!disabled}
          readOnly={!!readOnly}
          style={theme && theme.selectWrapper ? { marginTop: '0px' } : { marginTop: '16px' }}
          classes={{
            root: classes.selectRoot,
            selectMenu: classes.selectMenu,
            icon: classes.selectIcon
          }}
          IconComponent={ExpandMoreRoundedIcon}
          {...input}
          onChange={input.onChange}
        >
          {defaultValue && (
            <MenuItem value="" disabled>
              {defaultValue}
            </MenuItem>
          )}
          {optionButton && <ButtonMenuItem onClick={onButtonClick}>{optionButton}</ButtonMenuItem>}
          {options &&
            options.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={e => {
                  return handleOptionClick(e, option);
                }}
              >
                {option.name}
              </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText classes={{ root: classes.errorRoot }}>{error}</FormHelperText>}
        {showExtraNotes && showExtraNotes(input.value) && (
          <FormHelperText classes={{ root: classes.extraNotes }}>{extraNotes}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default MaterialUiDropDown;
