import { compose, pure, withHandlers, withState } from 'recompose';
import moment from 'moment';
import { isEmpty, isNil } from 'ramda';
import { formConverter } from '../../../utils/helpers';

export default compose(
  withState('isExpanded', 'toggleExpanded', false),
  withHandlers({
    countFilters: () => ({ values }) =>
      Object.keys(values).reduce((count, key) => {
        let filterCount = Array.isArray(values[key])
          ? count + values[key].length
          : count + !!values[key];
        if (key === 'assignmentFilter' && !isEmpty(values[key]) && !isNil(values[key])) {
          filterCount -= 1;
        }
        return filterCount;
      }, 0),
    onSubmit: ({ onFilter, toggleExpanded, onClear }) => values => {
      toggleExpanded(false);
      /* eslint-disable no-param-reassign */
      if (values.start_time) {
        values.start_time = moment(values.start_time, 'h:mm a').format('h:mm a');
      }
      if (values.end_time) {
        values.end_time = moment(values.end_time, 'h:mm a').format('h:mm a');
      }
      if (
        Object.keys(values).length === 2 &&
        values.start_time === null &&
        values.end_time === null
      ) {
        return onClear();
      }
      /* eslint-enable no-param-reassign */
      return Object.keys(values).length ? onFilter(formConverter.revert(values)) : onClear();
    }
  }),
  pure
);
