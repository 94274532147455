import React from 'react';
import PropTypes from 'prop-types';

import { Svg } from './styled-components';

const Icon = ({ icon, width, height, color, title, clickable, margin, style, ...rest }) => (
  <Svg
    width={width}
    height={height}
    color={color}
    viewBox="0 0 520 500"
    clickable={clickable}
    margin={margin}
    style={style}
    {...rest}
  >
    {title && <title>{title}</title>}
    <path d={icon} />
  </Svg>
);

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
  clickable: PropTypes.bool,
  margin: PropTypes.string
};

Icon.defaultProps = {
  width: 16,
  height: 16,
  color: '',
  title: '',
  clickable: false,
  margin: ''
};

export default React.memo(Icon);
