import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';
import { pxToRem } from '../../../utils/helpers';
import COLORS from '../../../utils/styles';

export const Container = styled(Row)`
  padding: 1rem 8rem;
`;

export const Wrapper = styled.div`
  display: inline;
  flex: 1;
  width: 100%;
`;

export const InnerWrapper = styled(Col)``;

export const Heading = styled.h3`
  font-family: 'Barlow';
  font-size: ${pxToRem('25px')};
  color: ${COLORS.newDenimBlue};
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-top: 3rem;
`;

export const SubscriptionFeeBanner = styled.div`
  background: ${({ status }) => (status ? status.bgColor : '#d4efdf')};
  border: ${({ status }) => (status ? `1px solid ${status.borderColor}` : '1px solid #56a067')};
  & > div {
    color: ${({ status }) => (status ? status.color : '')};
  }
  display: inline-block;
  padding: 30px 50px;
  border-radius: 5px;
  margin-top: 20px;
`;

export const SFBannerTitle = styled.div`
  color: #0d194f;
  font-size: 1.5rem;
  line-height: 1.5;
`;

export const SFBannerFee = styled.div`
  color: #0d194f;
  font-size: 1.3rem;
  line-height: 1.5;
`;

export const SFBannerDesc = styled.div`
  color: #5a84a4;
  font-size: 0.85rem;
`;

export const Circle = styled.div`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0px 3px;
  background: ${({ status }) => (status ? status.color : COLORS.forestGreen)};
  display: inline-block;
`;

export const SFBannerStatus = styled.span`
  font-size: 0.8rem;
`;

export const SFBannerStatusIcon = styled.span`
  line-height: 0.8;
`;
