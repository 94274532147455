import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import moment from 'moment';

import Button from '../Button';
import {
  ButtonWrapper,
  ButtonContainer,
  DateFieldContainer,
  DateFieldLabel,
  DateContainer
} from './styled-components';
import { COLORS } from '../../../utils/styles';
import Dropdown from '../Dropdown';
import Enhancer from '../../containers/GenerateReports';
import config from '../../containers/GenerateReports/config';
import LoaderWrapper from '../LoaderWrapper';
import MaterialDatePicker from '../../controls/MaterialDatePicker';
import { composeValidators, isSameOrBefore, required } from '../../../utils/validations';
import { DATE_FORMATS } from '../../../constants';
import { getEndOfDay } from '../../../utils/parsers';

const isCurrentDate = date =>
  moment(moment(date).format('YYYY-MM-DD')).isSame(moment.utc().format('YYYY-MM-DD')) &&
  'Report might not contain all activity for today';

const defaultSelected = {
  value: 0,
  label: 'Select report'
};

const reports = [defaultSelected].concat(
  Object.keys(config.reports).map(item => ({
    label: config.reports[item].view.label,
    value: config.reports[item].code
  }))
);

const GenerateReport = ({
  generateReport,
  submitting,
  isFetching,
  setReportType,
  setShowDateField,
  showDateField
}) => (
  <LoaderWrapper isFetching={isFetching}>
    <Form
      onSubmit={generateReport}
      initialValues={{
        reportType: null,
        startDate: moment
          .utc()
          .subtract(1, 'months')
          .startOf('month')
          .format(DATE_FORMATS.YYYY_MM_DD),
        endDate: moment.utc().subtract(1, 'months').endOf('month').format(DATE_FORMATS.YYYY_MM_DD)
      }}
      render={({ handleSubmit, invalid, values }) => (
        <form
          onSubmit={e => {
            handleSubmit(e);
          }}
        >
          <Field name="reportType">
            {({ input: { name, value, onChange }, meta: { active, error, touched } }) => (
              <Dropdown
                dropdownWidth="14.4rem"
                label="Report"
                options={reports}
                onChange={({ target: { value: reportType } }) => {
                  onChange(reportType);
                  setReportType(reportType);
                  if (Number(reportType) === config.reports.platformTransactionDetails.code) {
                    setShowDateField(true);
                  } else {
                    setShowDateField(false);
                  }
                }}
                active={active}
                columnSize={2}
                error={error && touched ? error : ''}
                name={name}
                value={value}
                borderRadius="5px"
                backgroundColor={COLORS.white}
                borderColor={COLORS.slateGray}
                outline="none"
              />
            )}
          </Field>
          {showDateField && (
            <DateFieldContainer>
              <DateContainer className="row">
                <DateFieldLabel className="col-xs-4">START DATE</DateFieldLabel>
                <Field
                  name="startDate"
                  component={MaterialDatePicker}
                  validate={composeValidators(
                    required,
                    isCurrentDate,
                    (val, { endDate: endTime }) =>
                      isSameOrBefore(
                        val,
                        endTime,
                        DATE_FORMATS.YYYY_MM_DD,
                        '"Start Date" must precede "End Date"'
                      )
                  )}
                  parse={value => (value ? moment(value).format(DATE_FORMATS.YYYY_MM_DD) : null)}
                  format={value => (value ? moment(value, DATE_FORMATS.YYYY_MM_DD).toDate() : null)}
                  style={{ width: '100%', padding: '10px 0px 15px' }}
                  disabledDays={{ after: new Date() }}
                  className="col-xs-5"
                />
              </DateContainer>
              <DateContainer className="row">
                <DateFieldLabel className="col-xs-4">END DATE</DateFieldLabel>
                <Field
                  name="endDate"
                  component={MaterialDatePicker}
                  validate={composeValidators(
                    required,
                    val =>
                      isSameOrBefore(
                        val,
                        getEndOfDay(new Date()),
                        DATE_FORMATS.YYYY_MM_DD,
                        "End date can't postdate present day"
                      ),
                    isCurrentDate
                  )}
                  parse={value => (value ? moment(value).format(DATE_FORMATS.YYYY_MM_DD) : null)}
                  format={value => (value ? moment(value, DATE_FORMATS.YYYY_MM_DD).toDate() : null)}
                  style={{ width: '100%', padding: '10px 0px 15px' }}
                  disabledDays={{ after: new Date() }}
                  className="col-xs-5"
                />
              </DateContainer>
            </DateFieldContainer>
          )}
          <ButtonWrapper center="xs">
            <ButtonContainer xs={12} sm={12} md={8} lg={5}>
              <Button
                type="submit"
                label="Generate"
                submitting={submitting}
                disabled={invalid || !Number(values.reportType) || submitting}
                primary
              />
            </ButtonContainer>
          </ButtonWrapper>
        </form>
      )}
    />
  </LoaderWrapper>
);

GenerateReport.propTypes = {
  generateReport: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool
};

GenerateReport.defaultProps = {
  isFetching: false
};

export default Enhancer(GenerateReport);
