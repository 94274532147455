import { is, compose, range, flip, contains, either } from 'ramda';
import moment from 'moment';

import { notNilNotEmpty } from './helpers';

export const required = value => {
  const trimmedValue = is(String, value) ? value.trim() : value;
  return trimmedValue || trimmedValue === 0 ? undefined : 'Required';
};

export const disableField = ({ fiter_date: filterDate }) => !filterDate;

export const conditionalUploadTypeRequired = (value, { game_upload_type }) => {
  if (game_upload_type === 0) return undefined;
  const trimmedValue = is(String, value) ? value.trim() : value;
  return trimmedValue || trimmedValue === 0 ? undefined : 'Required';
};

export const multiSelectDropdownRequired = value => {
  const arrayValue = Array.isArray(value) ? value : [];
  return arrayValue.length ? undefined : 'Required';
};

export const noZero = value => (value === 0 ? 'Required' : undefined);

export const mustBeNumber = value => {
  if (value === '' || value === undefined || value === null) return undefined;
  return Number.isNaN(parseInt(value, 10)) ? 'Must be a number' : undefined;
};

export const minValue = min => value =>
  Number.isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const maxValue = max => value =>
  Number.isNaN(value) || value <= max ? undefined : `Should be less than ${max}`;

export const minLength = (min, errMessage) => value =>
  notNilNotEmpty(value) ||
  (is(String, value) && value.length === 0) ||
  (is(String, value) && value.length >= min)
    ? undefined
    : errMessage || `Expected ${min} or more characters`;

export const maxLength = max => value =>
  notNilNotEmpty(value) ||
  (is(String, value) && value.length === 0) ||
  (is(String, value) && value.length <= max)
    ? undefined
    : `Max length is ${max}`;

export const mustBeEmail = (email = '') => {
  if (!email) return undefined;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.trim()) ? undefined : 'Invalid email';
};

/**
 * Takes a date object and returns an error message if date is less than 10 years ago
 */
export const minAge = date =>
  moment(date, 'YYYY-MM-DD').isAfter(moment().subtract(10, 'years'))
    ? 'Minimum age is 10 years old'
    : undefined;

export const composeValidators = (...validators) => (value, values) =>
  validators.reduce((error, validator) => error || validator(value, values), undefined);

/**
 * Takes a min, max and strict params as object props
 * returns a function that receives a value.
 * if value is within range returns undefined. else returns error message
 * When strict flag is set, error message is returned in case of empty and null value
 * otherwise empty and null values does not result in error message
 * @param {*} param0
 */
export const valueInRange = ({ min, max }) => value => {
  const message = `between ${min} and ${max}`;
  return either(notNilNotEmpty, compose(flip(contains)(range(min, max + 1)), parseInt))(value)
    ? undefined
    : message;
};

/**
 * Takes a USSF ID and validates format
 * FORMAT: XXXX-XXXX-XXXX-XXXX
 * 16 digits, separated by a dash between each 4 digits.
 */

export const validateUssfId = (ussf_id = '') => {
  const USSF_ARRAY = ussf_id.split('-');
  const USSF_DIGITS = ussf_id.replace(/\D/g, '');
  let valid = true;
  if (ussf_id !== '') {
    valid = USSF_ARRAY.length === 4;
    if (valid) {
      valid = USSF_ARRAY.every(v => v.length === 4);
    }
    if (valid) {
      valid = USSF_DIGITS.length === 16;
    }
  }
  return valid ? undefined : 'Invalid USSF ID Format';
};

/**
 * Takes a string and validates it's in the XX:XX AM/PM format, with or without a single space
 */

export const validTimeOfDay = (time = '') => {
  const re = /(^(\d{1,2}):(\d{2})\s?([AP][M]))/;
  return re.test(time) ? undefined : 'Invalid time, please use XX:XX format followed by AM/PM';
};

export const isBefore = (
  start,
  finish,
  format = 'h:mm A',
  errorMessage = '"To" time cannot be before "From" time'
) =>
  !start || !finish || moment(start, format).isBefore(moment(finish, format))
    ? undefined
    : errorMessage;

export const isSameOrBefore = (
  start,
  finish,
  format = 'h:mm A',
  errorMessage = '"End Date" cannot be lower than "Start Date"'
) =>
  !start || !finish || moment(start, format).isSameOrBefore(moment(finish, format))
    ? undefined
    : errorMessage;

export const isSameOrAfter = (
  start,
  finish,
  format = 'h:mm A',
  errorMessage = '"End Date" cannot be lower than "Start Date"'
) =>
  !start || !finish || moment(start, format).isSameOrAfter(moment(finish, format))
    ? undefined
    : errorMessage;

export const isValidAreaCode = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums.charAt(0) === '0' || onlyNums.charAt(0) === '1'
    ? 'Invalid area code.'
    : undefined;
};

export const hasAtLeastOneField = (...args) =>
  args[1].categories.some(
    category =>
      notNilNotEmpty(category.states) &&
      notNilNotEmpty(category.postal_codes) &&
      notNilNotEmpty(category.teams) &&
      notNilNotEmpty(category.locations)
  );

export const isNotValidated = category =>
  notNilNotEmpty(category.states) &&
  notNilNotEmpty(category.postal_codes) &&
  notNilNotEmpty(category.teams) &&
  notNilNotEmpty(category.locations);
