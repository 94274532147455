import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Col } from 'react-flexbox-grid';

import Modal from '../../../../Modal';
import MaterialInput from '../../../../MaterialInput';
import Button from '../../../../Button';
import { FieldWrapper, FormWrapper, ButtonsWrapper } from '../styled-components';
import { required } from '../../../../../../utils/validations';

const ChangeUsernameModal = ({ onClose, onSubmit, initialValues }) => (
  <Modal title="Change Username" onClose={onClose} size={{ lg: 5 }}>
    <Form
      onSubmit={onSubmit}
      initialValues={{ current_username: '', email: '', password: '', ...initialValues }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <FormWrapper start="xs">
            <FieldWrapper xs={12}>
              <Field
                name="current_username"
                component={MaterialInput}
                placeholder="Current Username"
                readOnly
              />
            </FieldWrapper>
            <FieldWrapper xs={12}>
              <Field
                name="email"
                component={MaterialInput}
                placeholder="New Username"
                validate={required}
              />
            </FieldWrapper>
            <FieldWrapper xs={12}>
              <Field
                name="password"
                component={MaterialInput}
                placeholder="Password"
                type="password"
                validate={required}
              />
            </FieldWrapper>
          </FormWrapper>
          <ButtonsWrapper center="xs">
            <Col xs={4}>
              <Button type="button" label="Cancel" onClick={onClose} primary />
            </Col>
            <Col xs={4}>
              <Button type="submit" label="Update" disabled={pristine || invalid} primary />
            </Col>
          </ButtonsWrapper>
        </form>
      )}
    />
  </Modal>
);

ChangeUsernameModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    current_username: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string
  }).isRequired
};

export default ChangeUsernameModal;
