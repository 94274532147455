import React from 'react';
import { Field } from 'react-final-form';
import { SubHeader } from '../../../styled-components';
import ImageUploader from './ImageUploader';
import COLORS from '../../../../../../../utils/styles';

const Attachments = ({ values, disabled }) => {
  return (
    <>
      <SubHeader color={COLORS.sapphire}>ATTACHMENTS</SubHeader>
      <Field name="attachments">
        {props => (
          <ImageUploader
            {...props.input}
            disabled={disabled}
            attachments={values.attachments}
            values={values}
          />
        )}
      </Field>
    </>
  );
};

export default Attachments;
