import styled from 'styled-components';

import { COLORS, HEIGHTS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const EmptyMessage = styled.p`
  margin: ${pxToRem('23px')} 0 0 ${pxToRem('34px')};
  font-family: 'RobotoLight';
  font-size: 1rem;
  line-height: 1.06;
  color: ${COLORS.shuttleGray};
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - ${HEIGHTS.appHeader} - ${HEIGHTS.eventNavigationBar} - ${HEIGHTS.eventFilterBar} -
      ${HEIGHTS.paginationBar}
  );
  overflow-y: auto;
  overflow-x: hidden;
`;

export default EmptyMessage;
