import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  width: 100%;
  padding: ${pxToRem('6px')} 0.25rem;
  background-color: ${COLORS.white};
  color: ${COLORS.shuttleGray};
  box-shadow: inset 5px 0 6px -5px rgba(0, 0, 0, 0.65);

  ${({ theme }) => theme};
`;

export const NumberContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: ${pxToRem('8px')};
  }
`;

export const NumberInput = styled.input`
  width: ${pxToRem('112px')};
  height: ${pxToRem('24px')};
  border: 1px solid ${COLORS.denimBlue};
  background-color: transparent;
  padding: 0 ${pxToRem('7px')};
  color: ${COLORS.shuttleGray};
  box-sizing: border-box;

  &:focus {
    background-color: ${COLORS.catSkillWhite};
    color: ${COLORS.denimBlue};
  }

  ${({ theme }) => theme};
`;

export const NumberLabel = styled.span`
  display: inline-block;
  padding: 0 0 ${pxToRem('2px')} ${pxToRem('8px')};
  font-size: ${pxToRem('11px')};
`;

export default Wrapper;
