import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { NotificationRow, Summary, OrgName, Chevron } from '../styled-components';
import { ICONS, COLORS } from '../../../../utils/styles';

import Enhancer from '../../../containers/NotificationsMenu/SubMenu/NotificationList';
import { formatDate } from '../../../../utils/parsers';
import { convertUtcToTimezone, getTimezoneAbbr } from '../../../../utils/helpers';

const SubMenu = ({ notifications, onGameStatusExpandNotification }) => {
  return (
    <>
      {notifications.map((notification, i) => {
        const gameObj = notification.data;
        const gameDate =
          gameObj &&
          formatDate(
            convertUtcToTimezone(gameObj.start_at, gameObj.timezone),
            'MM/DD/YYYY @ h:mm A'
          );
        const tzAbbr =
          gameObj && getTimezoneAbbr(formatDate(gameDate, 'MM/DD/YYYY hh:mm A'), gameObj.timezone);

        return (
          <Fragment key={notification.id}>
            <NotificationRow noBorder={i === notifications.length - 1}>
              <Summary>
                Unscheduled Games
                {gameObj ? (
                  <>
                    <span>
                      {gameObj.external_event_id ? ` - ${gameObj.external_event_id} ` : ''} on
                    </span>
                    <br />
                    {gameObj.start_at && `${gameDate} (${tzAbbr}), `}
                    {gameObj.eventName ? (
                      <span>
                        <OrgName>{gameObj.eventName}</OrgName>
                      </span>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ' '
                )}
              </Summary>
              <Chevron
                icon={ICONS.CLOSE}
                width={18}
                height={17}
                color={COLORS.shuttleGray}
                onClick={() => {
                  onGameStatusExpandNotification({ expandedId: notification.id });
                }}
              />
            </NotificationRow>
          </Fragment>
        );
      })}
    </>
  );
};

SubMenu.propTypes = {
  notifications: PropTypes.arrayOf(Object),
  selectedNotification: PropTypes.shape({
    id: PropTypes.string,
    source_id: PropTypes.string,
    user_id: PropTypes.number,
    alert_type: PropTypes.string,
    status: PropTypes.string,
    created_at: PropTypes.string
  })
};

SubMenu.defaultProps = {
  notifications: [],
  selectedNotification: {}
};

export default Enhancer(SubMenu);
