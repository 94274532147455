import React from 'react';
import PropTypes from 'prop-types';

import Filters from '../../../../Filters';
import generateFields from './config';

const GamesFilter = ({
  gamelevels,
  setSelectedGame,
  setAllGamesCheck,
  batchRange,
  categories,
  teams,
  complexes,
  ...filtersProps
}) => (
  <Filters
    config={generateFields({ gamelevels, batchRange, categories, teams, complexes })}
    {...filtersProps}
  />
);

GamesFilter.propTypes = {
  gamelevels: PropTypes.arrayOf(Object),
  setSelectedGame: PropTypes.func,
  setAllGamesCheck: PropTypes.func,
  batchRange: PropTypes.objectOf(Object).isRequired
};

GamesFilter.defaultProps = {
  gamelevels: [],
  setAllGamesCheck: null,
  setSelectedGame: null
};

export default GamesFilter;
