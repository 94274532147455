import React from 'react';
import { Dialog, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import PropTypes from 'prop-types';

import { TitleContainer, ContentContainer, ActionsContainer, HelpText } from './styled-components';
import { H5, CancelButton, PrimaryButton } from '..';

export default function dialog({
  children,
  title,
  onClose,
  primaryButtonStyle,
  primaryButtonDisabled,
  primaryButtonText,
  cancelAction,
  cancelButtonText,
  primaryAction,
  noTitle,
  noCancel,
  noPrimary,
  noDivider,
  style,
  customStyle = { minHeight: '', overflowY: '' },
  footerBackground,
  helpText,
  ...rest
}) {
  const { minHeight, overflowY } = customStyle;
  return (
    <Dialog onClose={onClose} {...rest} style={style}>
      {!noTitle && (
        <TitleContainer>
          <H5 margin="0" color="#fff">
            {title}
          </H5>
          <CloseIcon
            onClick={onClose}
            style={{
              color: '#fff',
              position: 'absolute',
              right: '5px',
              top: '5px',
              cursor: 'pointer',
              zIndex: '99'
            }}
          />
        </TitleContainer>
      )}
      <ContentContainer minHeight={minHeight} overflowY={overflowY}>
        {children}
      </ContentContainer>
      {!noDivider && <Divider />}
      <ActionsContainer noCancel background={footerBackground || '#fff'}>
        {!noCancel && (
          <CancelButton style={{ marginRight: '20px' }} onClick={cancelAction}>
            {cancelButtonText || 'Cancel'}
          </CancelButton>
        )}
        {!noPrimary && (
          <PrimaryButton
            onClick={primaryAction}
            style={primaryButtonStyle}
            disabled={primaryButtonDisabled}
          >
            {primaryButtonText}
          </PrimaryButton>
        )}
      </ActionsContainer>
      {helpText && <HelpText>{helpText}</HelpText>}
    </Dialog>
  );
}

dialog.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  primaryButtonText: PropTypes.string,
  title: PropTypes.string,
  cancelAction: PropTypes.func.isRequired,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonStyle: PropTypes.instanceOf(Object),
  primaryAction: PropTypes.func.isRequired,
  noTitle: PropTypes.bool,
  noCancel: PropTypes.bool,
  noPrimary: PropTypes.bool,
  noDivider: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  footerBackground: PropTypes.string
};

dialog.defaultProps = {
  title: '',
  primaryButtonDisabled: false,
  primaryButtonStyle: null,
  primaryButtonText: 'Confirm',
  noTitle: false,
  noCancel: false,
  noPrimary: false,
  noDivider: false,
  style: null,
  footerBackground: ''
};
