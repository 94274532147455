import { compose, pure, withStateHandlers } from 'recompose';

export default compose(
  withStateHandlers(
    ({ externalSelectedRole = 'assignor' }) => ({
      internalSelectedRole: externalSelectedRole
    }),
    {
      setSelectedRole: () => newValue => ({
        internalSelectedRole: newValue
      })
    }
  ),
  pure
);
