import { compose, pure, lifecycle, withState, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { equals } from 'ramda';
import { withRouter } from 'react-router-dom';
import FileSaver from 'file-saver';

import {
  getPaymentsEventId,
  getPaymentsIsAssignmentFetching,
  getPaymentsSelectedAssignment,
  getPaymentsGames,
  getPaymentsActiveBatch,
  getPaymentsCrewLabels,
  getPaymentsGamesMeta,
  getPaymentsGamesIsFetching,
  getPaymentsSummary,
  getPaymentsEvent,
  isPaymentsEventGroupAdmin,
  getPaymentsEventCategories,
  isAllEventSelected as isAllEventSelectedSelector
} from '../../../../core/Payments/selectors';
import {
  storePaymentsEvent as storePaymentsEventAction,
  getBatchGames as getBatchGamesAction,
  fetchGameAssignment as fetchGameAssignmentAction,
  setGameAssignment as setGameAssignmentAction,
  fetchPaymentsEventCrewLabels as fetchPaymentsEventCrewLabelsAction,
  processAssignment as processAssignmentAction,
  updateFulLGameAssignments as updateFulLGameAssignmentsAction,
  getBatchInfo as getBatchInfoAction,
  setBatchGames as setBatchGamesAction,
  deleteBatchPay as deleteBatchPayAction,
  fetchBatchPayGamesInfo as fetchBatchPayGamesInfoAction,
  deleteBatch as deleteBatchAction,
  setAllEventIsSelected as setAllEventIsSelectedAction,
  bulkUpdateFullGameAssignmentsSaga
} from '../../../../core/Payments';
import { paginationAware, updateOnPageChange, filterConfigToNames } from '../../PaginationAware';
import generateFields from '../../../components/Payments/BatchEdit/Header/GamesFilter/config';
import { userIdxGetter } from '../../../../utils/auth';
import { sInterpolator as routeBuilder } from '../../../../utils/helpers';
import { PAYMENTS_BATCH_REVIEW } from '../../../../core/paths';
import { formatDate } from '../../../../utils/parsers';
import { isEventAdmin, isSuperAdmin } from '../../../../core/Auth/selectors';
import { setAssignmentWarnings as setAssignmentWarningsAction } from '../../../../core/Notifications';
import { getTeams } from '../../../../core/Teams/selectors';
import { getLocations } from '../../../../core/Locations/selectors';
import { allEventsId } from '../../../../constants';
import { isIndeterminate } from '../../../components/Payments/BatchEdit/helpers';

const mapStateToProps = state => ({
  isFetching: getPaymentsGamesIsFetching(state),
  isAssignmentFetching: getPaymentsIsAssignmentFetching(state),
  selectedAssignment: getPaymentsSelectedAssignment(state),
  games: getPaymentsGames(state),
  activeBatch: getPaymentsActiveBatch(state),
  paymentsEventId: getPaymentsEventId(state),
  crewLabels: getPaymentsCrewLabels(state),
  paymentsGamesMeta: getPaymentsGamesMeta(state),
  paymentsSummary: getPaymentsSummary(state),
  reviewBatchInfo: state.payments.reviewBatchInfo,
  isEventAdmin: isEventAdmin({ params: { eventId: getPaymentsEventId(state) } })(state),
  paymentEvent: getPaymentsEvent()(state),
  isPaymentsEventGroupAdmin: isPaymentsEventGroupAdmin()(state),
  categories: getPaymentsEventCategories(state),
  teams: getTeams(state),
  complexes: getLocations(state),
  isAllEventSelected: isAllEventSelectedSelector(state),
  isSuperAdmin: isSuperAdmin(state)
});

const mapDispatchToProps = dispatch => ({
  storePaymentsEvent: payload => dispatch(storePaymentsEventAction(payload)),
  fetchPaymentsEventCrewLabels: () => dispatch(fetchPaymentsEventCrewLabelsAction()),
  fetchGameAssignment: payload => dispatch(fetchGameAssignmentAction(payload)),
  setSelectedAssignment: payload => dispatch(setGameAssignmentAction(payload)),
  getBatchGames: payload => dispatch(getBatchGamesAction(payload)),
  processAssignment: payload => dispatch(processAssignmentAction(payload)),
  updateFulLGameAssignments: payload => dispatch(updateFulLGameAssignmentsAction(payload)),
  getBatchInfo: payload => dispatch(getBatchInfoAction(payload)),
  resetBatchGames: () => dispatch(setBatchGamesAction({ data: [] })),
  fetchBatchPayGamesInfo: payload => dispatch(fetchBatchPayGamesInfoAction(payload)),
  deleteBatchPay: payload => dispatch(deleteBatchPayAction(payload)),
  deleteBatch: payload => dispatch(deleteBatchAction(payload)),
  setAssignmentWarnings: payload => dispatch(setAssignmentWarningsAction(payload)),
  setAllEventIsSelected: payload => dispatch(setAllEventIsSelectedAction(payload)),
  bulkUpdateFullGameAssignmentsDispatch: payload =>
    dispatch(bulkUpdateFullGameAssignmentsSaga(payload))
});

export const PAGINATION_AWARE_PREFIX = 'gm';

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ match: { path, params: { batchId } } }) => ({
    onLoadPageArgs: {
      reviewMode: path === PAYMENTS_BATCH_REVIEW,
      batchId
    }
  })),
  paginationAware(
    {
      fetcherQuote: 'getBatchGames',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'paymentsGamesMeta',
      searchFilter: ['game.id'],
      filterNames: filterConfigToNames(generateFields({}))
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  withRouter,
  lifecycle({
    componentWillMount() {
      if (this.props.onLoadPageArgs.reviewMode) {
        const {
          onLoadPageArgs: { batchId }
        } = this.props;
        this.props.getBatchInfo({ id: batchId });
      } else {
        const {
          match: {
            params: { eventId }
          },
          storePaymentsEvent
        } = this.props;
        storePaymentsEvent({ eventId });
      }
    },
    componentDidMount() {
      this.props.pagination.onLoadPage(this.props.onLoadPageArgs);
      this.props.fetchPaymentsEventCrewLabels();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.paymentsEventId !== this.props.paymentsEventId)
        this.props.fetchPaymentsEventCrewLabels();
      if (this.props.activeBatch !== prevProps.activeBatch) {
        this.props.pagination.onLoadPage(this.props.onLoadPageArgs);
      }
    },
    componentWillUnmount() {
      this.props.resetBatchGames();
    }
  }),
  withState('selectedGame', 'setSelectedGame', null),
  withState('allGamesCheck', 'setAllGamesCheck', false), // D/N: i can't use this because existing code pegged this to assigners
  withState('selectedAllGames', 'setSelectedAllGames', false),
  withState('batchModalOpen', 'setBatchModalOpen', false),
  withHandlers({
    handleBackToDateSelection: ({ history, setAllEventIsSelected, isAllEventSelected }) => () => {
      if (isAllEventSelected) setAllEventIsSelected({ eventId: allEventsId });
      history.push(routeBuilder({ userIdx: userIdxGetter() }, '/u/:userIdx/payments'));
    },
    deleteActiveBatch: ({ deleteBatch, deleteBatchPay, activeBatch: { id, event_id } }) => (
      rid,
      reviewMode
    ) => {
      if (reviewMode) {
        return deleteBatchPay({ batchId: rid, event_id, redirect: true });
      }
      return deleteBatch({ batchId: id, eventId: event_id, redirect: true });
    },

    handleCheckboxClick: ({ updateFulLGameAssignments }) => ({
      id,
      in_batch,
      is_assignor_batched
    }) => updateFulLGameAssignments({ id, in_batch, is_assignor_batched }),
    handleSelectAll: ({
      bulkUpdateFullGameAssignmentsDispatch,
      setSelectedAllGames,
      games
    }) => checked => {
      const request = { gameIds: [], changes: { in_batch: checked } };
      games.forEach(game => {
        const indetermindate = isIndeterminate(game.assignments_game);
        // toggle the state of the assignments into the game unless the game was flagged as indeterminate
        // indetermindate means the assignments are a mixed state of in and not in the batch
        // indicates that this was manually done
        if (!(checked && indetermindate)) request.gameIds.push(game.id);
      });
      bulkUpdateFullGameAssignmentsDispatch(request);
      setSelectedAllGames(checked);
    },
    handleSetSelectedAssignment: ({
      selectedAssignment,
      setSelectedAssignment,
      fetchGameAssignment
    }) => assignment => {
      if (selectedAssignment && equals(selectedAssignment.id, assignment.id)) {
        setSelectedAssignment(null);
      } else {
        fetchGameAssignment(assignment);
      }
    },
    downloadBatchPayReport: ({
      fetchBatchPayGamesInfo,
      onLoadPageArgs: { batchId, reviewMode }
    }) => () =>
      new Promise(resolve =>
        fetchBatchPayGamesInfo({ toCSV: true, resolve, batchId, reviewMode })
      ).then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' });
        FileSaver.saveAs(blob, `Batch_pay_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
      }),
    handleAdjustmentSubmit: ({ processAssignment, pagination: { onLoadPage }, onLoadPageArgs }) => (
      values,
      formApi
    ) => {
      const {
        initialValues: {
          shouldInBatch: initialIn_batch,
          gameAssignmentId: initialGameAssignmentId,
          assignor_pay,
          base_pay,
          ...initialAdjustmentData
        }
      } = formApi.getState();
      const { shouldInBatch: in_batch, gameAssignmentId, ...adjustmentData } = values;
      const shouldUpdateBatch = !equals(in_batch, initialIn_batch);
      const shouldUpdateAdjustment = !equals(adjustmentData, initialAdjustmentData);

      processAssignment({
        shouldUpdateBatch,
        shouldUpdateAdjustment,
        in_batch,
        adjustmentData,
        gameAssignmentId,
        assignor_pay,
        base_pay,
        isSuperAdmin,
        onLoadPage: () => onLoadPage(onLoadPageArgs)
      });
      formApi.initialize(values);
    }
  }),
  pure
);
