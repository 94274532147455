import styled from 'styled-components';

import { pxToRem } from '../../../../../utils/helpers';
import { COLORS } from '../../../../../utils/styles';

export const Container = styled.div`
  padding: 0 ${pxToRem('15px')};
`;

export const Header = styled.div``;

export const Description = styled.p`
  margin: ${pxToRem('10px')} 0;
  font: 400 ${pxToRem('13px')} 'RobotoCondensed', sans-serif;
  font-stretch: condensed;
  color: ${COLORS.shuttleGray};
`;

export const Content = styled.div`
  display: flex;
`;

export const RoleOptionColumn = styled.div`
  flex: 1;
`;

export const RoleLabel = styled.h4`
  margin: ${pxToRem('11px')} 0;
  font: bold ${pxToRem('13px')} 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  color: ${COLORS.shuttleGray};
`;

export const RoleOptionContainer = styled.div``;

export const Option = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: ${pxToRem('13px')};
  }
`;

export const OptionWithInput = Option.extend`
  align-items: flex-start;
`;

export const OptionLabel = styled.span`
  font: 500 ${pxToRem('13px')} 'RobotoMedium', sans-serif;
  color: ${({ color }) => color};
  margin-right: ${pxToRem('4px')};
`;

export const OptionInnerWrapper = styled.div``;

export const InputWrapper = styled.div`
  margin-top: ${pxToRem('2px')};
`;

export const Input = styled.input`
  width: ${pxToRem('40px')};
  height: ${pxToRem('25px')};
  margin-right: ${pxToRem('4px')};
  border: 1px solid ${COLORS.hawkesBlue};
  opacity: 0.5;
  text-align: center;
  font: 500 ${pxToRem('13px')} 'RobotoMedium', sans-serif;
  color: ${({ color }) => color};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default Container;
