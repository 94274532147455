import { compose } from 'recompose';
import { connect } from 'react-redux';

import unconnected from './unconnected';
import {
  updateGameAssignments as updateGameAssignmentsAction,
  setCurrentGame as setCurrentGameAction,
  setCalendarGame,
  createGameAssignment
} from '../../../core/Games';
import {
  setShowCalendar as setShowCalendarAction,
  fetchUserAssignments,
  clearAssignments as clearAssignmentsAction,
  fetchUserEventSettings,
  fetchUserSchedule as fetchUserScheduleAction,
  fetchUserSelfAssignGames as fetchUserSelfAssignGamesAction,
  fetchUserSelfAssignmentSummaryAction,
  clearSummary,
  clearUserSelfAssignedGamesAction
} from '../../../core/User';
import {
  setShowModalFor as setShowModalForAction,
  unsetShowModalFor as unsetShowModalForAction
} from '../../../core/Modals';
import { getEventAssessmentReportSettings } from '../../../core/Events/selectors';
import { getLoggedInUserIndex, payeeStatus, getUser } from '../../../core/Auth/selectors';
import { storeReportsEventAndRedirect as storeReportsEventAndRedirectAction } from '../../../core/Reports';
import {
  getUserAssignments,
  getCurrentDayAssignmentCount,
  getShowBanner,
  getSelfAssignEvents,
  getSelfAssignGames,
  hasTaxInfo
} from '../../../core/User/selectors';

import { clearAssignmentWarnings as clearAssignmentWarningsAction } from '../../../core/Notifications';
import { getSelectedUser } from '../../../core/Users/selectors';
import { editPayeeAccount } from '../../../core/Auth';

const mapStateToProps = state => ({
  eventsAssessmentSettings: getEventAssessmentReportSettings(state),
  gamesInfo: getUserAssignments(state),
  currentDayAssignmentCount: getCurrentDayAssignmentCount(state),
  fetching: state.user.fetching,
  calendarIcon: state.user.calendarIcon,
  userIdx: getLoggedInUserIndex(state),
  payeeStatus: payeeStatus()(state),
  hasTaxInfo: hasTaxInfo(state),
  isUserBannerEligible: getShowBanner(state),
  user: getUser(state),
  selfAssignEvents: getSelfAssignEvents(state),
  selfAssignGames: getSelfAssignGames(state),
  userSchedule: getSelectedUser(state, { singleUser: true }),
  selfAssignmentSummary: state.user.selfAssignmentSummary,
  isPaidThroughSO: state.user.activeUser && state.user.activeUser.is_paid_through_so,
  user_profile: state && state.user && state.user.profileMeta
});

const mapDispatchToProps = dispatch => ({
  updateGameAssignments: payload => dispatch(updateGameAssignmentsAction(payload)),
  setCurrentGame: payload => dispatch(setCurrentGameAction(payload)),
  setShowModalFor: payload => dispatch(setShowModalForAction(payload)),
  storeReportsEventAndRedirect: payload => dispatch(storeReportsEventAndRedirectAction(payload)),
  setCalendarGame: payload => dispatch(setCalendarGame(payload)),
  clearAssignmentWarnings: () => dispatch(clearAssignmentWarningsAction()),
  fetchAssignments: payload => dispatch(fetchUserAssignments(payload)),
  fetchSelfAssignmentSummary: payload => dispatch(fetchUserSelfAssignmentSummaryAction(payload)),
  clearAssignments: payload => dispatch(clearAssignmentsAction(payload)),
  setShowCalendarAction: payload => dispatch(setShowCalendarAction(payload)),
  unsetShowModalFor: payload => dispatch(unsetShowModalForAction(payload)),
  fetchEventSettings: payload => dispatch(fetchUserEventSettings(payload)),
  fetchUserSchedule: payload => dispatch(fetchUserScheduleAction(payload)),
  fetchUserSelfAssignGames: payload => dispatch(fetchUserSelfAssignGamesAction(payload)),
  createAssignment: payload => dispatch(createGameAssignment(payload)),
  editPayeeAccountAction: info => dispatch(editPayeeAccount(info)),
  clearSummary: () => dispatch(clearSummary()),
  clearUserSelfAssignedGames: () => dispatch(clearUserSelfAssignedGamesAction())
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
