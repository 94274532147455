import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import AssessmentReportModal from '../Reports/Modals/AssessmentReportModal';
import Enhancer from '../../containers/CalendarMenu';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import {
  Wrapper,
  CurrentDate,
  MonthLabel,
  DayLabel,
  WeekdayLabel,
  AssignmentListScroller,
  CalendarRightCol,
  CalendarLeftCol,
  CalendarRow,
  H2,
  NoAssignmentLabel,
  CalendarColorPalette,
  MonthlyCalendar,
  ColorPaletteHeading,
  MonthlyCalendarRow,
  ColorPaletteCol,
  ColorCol
} from './styled-components';
import './day-picker.css';
import LoaderWrapper from '../LoaderWrapper';
import Banner from '../Banner';
import * as paths from '../../../core/paths';
import { DATE_FORMATS, GAME_ASSIGNMENT_STATUSES, URLS } from '../../../constants';
import BannerUnsheduledNotifications from '../BannerNotifications';
import CalendarGameRow from './CalendarGameRow';
import COLORS from '../../../utils/styles';
import { getCalendarFormatGames } from './config';
import { ASSIGNMENT_STATUS } from '../../../core/Games/constants';
import PayoutFailedBanner from '../Banner/PayoutFailed';
import PayAccountInformational from '../Banner/PayAccountInformational';

const Calendar = ({
  gamesInfo,
  date,
  setDate,
  fetchAssignments,
  startAndEndDates,
  updateGameAssignments,
  selectedUser,
  setCurrentGame,
  setShowModalFor,
  onAssessmentReportClick,
  onGamesReportClick,
  showAssessmentReportModal,
  setShowAssessmentReportModal,
  setCalendarGame,
  showBanner,
  setShowBanner,
  user,
  payeeStatus,
  isUserBannerEligible,
  setShowBannerForGamesUnscheduled,
  notifications,
  showBannerForGamesUnscheduled,
  bannerClose,
  fetching,
  activeMonth,
  onCalendarMonthChange,
  calendarFilterGames,
  createGameAssignment,
  userSchedule,
  isPaidThroughSO,
  user_profile,
  editPayeeAccountAction,
  selfAssignmentSummary,
  userSelfAssignGames,
  fetchUserSelfAssignGames,
  pulledDates,
  setPulledDates
}) => {
  const isFetchingTrue = fetching.assignments || fetching.userSchedule || fetching.selfAssignGame;
  const isFetchingTrueCalendar = fetching.assignments || fetching.userSchedule;
  const userAssignment = gamesInfo.length ? getCalendarFormatGames(gamesInfo, activeMonth) : [];
  const handleCalendarDayChange = async day => {
    setDate(day);

    const userId = user && user.id;
    const start_date = moment(day).startOf('day').format();

    // If the date is in pulledDates, don't make the API call
    if (pulledDates.includes(start_date)) {
      return;
    }

    if (
      moment().format(DATE_FORMATS.YYYY_MM_DD) <= moment(start_date).format(DATE_FORMATS.YYYY_MM_DD)
    ) {
      // Use the handler to fetch user self-assign games
      userSelfAssignGames({ userId, start_date });

      // Add the date to pulledDates
      setPulledDates(prevPulledDates => [...prevPulledDates, start_date]);
    }
  };

  const games = [...calendarFilterGames, ...userAssignment]
    .filter(game => (game && game.end_date && moment(game.end_date).format('MM')) === activeMonth)
    .sort((a, b) => (moment(a.start_at).isSameOrBefore(moment(b.start_at)) ? -1 : 1));

  /* Calendar Games Color Theme Start */
  const assignmentStatus = [
    ...games
      .sort((a, b) => (moment(a.start_at).isSameOrBefore(moment(b.start_at)) ? -1 : 1))
      .map(game => {
        return {
          status: game.assignments_game[0] && game.assignments_game[0].status,
          start_at: game.start_at,
          published: game.published,
          userId:
            game.assignments_game.length &&
            game.assignments_game[0].event_role.user_id === Number(user.id)
              ? Number(user.id)
              : null
        };
      })
  ];
  const assignmentDatesOfMonth = day => {
    const assignedDates = [
      ...(assignmentStatus &&
        assignmentStatus.map(
          d =>
            d.start_at &&
            d.userId &&
            d.status.toLowerCase() === GAME_ASSIGNMENT_STATUSES.accepted &&
            Number(String(String(d.start_at).split(' ')[0]).split('/')[2])
        ))
    ];
    return assignedDates.length ? assignedDates.includes(Number(day.getDate())) : null;
  };
  const pendingAssignmentDatesOfMonth = day => {
    const assignedDates = [
      ...(assignmentStatus &&
        assignmentStatus.map(
          d =>
            d.status &&
            d.status.toLowerCase() === GAME_ASSIGNMENT_STATUSES.pending &&
            Number(String(String(d.start_at).split(' ')[0]).split('/')[2])
        ))
    ];
    return assignedDates.length ? assignedDates.includes(Number(day.getDate())) : null;
  };

  const publishedAssignmentDatesOfMonth = day => {
    return selfAssignmentSummary && selfAssignmentSummary.includes(day.getDate());
  };

  const declinedAssignments = day => {
    const assignedDates = [
      ...(assignmentStatus &&
        assignmentStatus.map(
          d =>
            d.start_at &&
            d.status &&
            d.status.toLowerCase() === GAME_ASSIGNMENT_STATUSES.declined &&
            Number(String(String(d.start_at).split(' ')[0]).split('/')[2])
        ))
    ];
    return assignedDates.length ? assignedDates.includes(Number(day.getDate())) : null;
  };

  const pendingOpenAssignment = day => {
    const pending = pendingAssignmentDatesOfMonth(day);
    const published = publishedAssignmentDatesOfMonth(day);
    return pending && published ? true : null;
  };

  const pendingAcceptedAssignment = day => {
    const pending = pendingAssignmentDatesOfMonth(day);
    const accepted = assignmentDatesOfMonth(day);
    return pending && accepted ? true : null;
  };

  const openAcceptedAssignment = day => {
    const published = publishedAssignmentDatesOfMonth(day);
    const accepted = assignmentDatesOfMonth(day);
    return published && accepted ? true : null;
  };

  /* Calendar Games Color Theme End */

  const onDayClickGames = assignmentStatus.filter(value => {
    return (
      moment(value.start_at).format(DATE_FORMATS.YYYY_MM_DD) ===
      moment(date).format(DATE_FORMATS.YYYY_MM_DD)
    );
  });

  return (
    <>
      {showAssessmentReportModal && (
        <AssessmentReportModal
          reportId={showAssessmentReportModal.reportId}
          gameId={showAssessmentReportModal.gameId}
          crewLabel={showAssessmentReportModal.crewLabel}
          onClose={() => setShowAssessmentReportModal(null)}
        />
      )}
      <Wrapper onClick={e => e.nativeEvent.stopImmediatePropagation()}>
        <BannerUnsheduledNotifications
          setShowBannerForGamesUnscheduled={setShowBannerForGamesUnscheduled}
          showBannerForGamesUnscheduled={showBannerForGamesUnscheduled}
          notifications={notifications}
          bannerClose={bannerClose}
          bannerHeading="The following games have been unscheduled. Please confirm any “cancelled” assignments on your calendar."
        />
        <PayoutFailedBanner user={user_profile} editPayeeAccountAction={editPayeeAccountAction} />
        <PayAccountInformational
          user={user_profile}
          editPayeeAccountAction={editPayeeAccountAction}
        />
        {isUserBannerEligible && !payeeStatus && isPaidThroughSO ? (
          <Banner onClose={() => setShowBanner(null)} showBanner={showBanner}>
            <span style={{ paddingLeft: '20px' }}>
              <H2>You can&apos;t get paid yet</H2>
              <p style={{ color: '#3D4956' }}>
                To start receiving payouts, complete your payment information.
              </p>
              <NavLink
                to={routeBuilder({ userIdx: 0, userId: user.id }, paths.PROFILE_PAYMENT_METHOD)}
                className="paymentInfo"
              >
                Go to my payment info
              </NavLink>
              <a
                href={URLS.HELP_DESK_PAYMENTS}
                className="paymentInfo"
                target="_blank"
                rel="noopener noreferrer"
              >
                Help
              </a>
            </span>
          </Banner>
        ) : (
          ''
        )}
        <CalendarRow>
          <CalendarLeftCol>
            <MonthlyCalendarRow>
              <MonthlyCalendar>
                <LoaderWrapper isFetching={isFetchingTrueCalendar}>
                  <DayPicker
                    selectedDays={date}
                    month={date}
                    modifiers={{
                      pendingOpenAssignment,
                      publishedAssignmentDatesOfMonth,
                      assignmentDatesOfMonth,
                      pendingAssignmentDatesOfMonth,
                      openAcceptedAssignment,
                      pendingAcceptedAssignment,
                      declinedAssignments
                    }}
                    onDayClick={handleCalendarDayChange}
                    onMonthChange={day => {
                      setPulledDates([]);
                      onCalendarMonthChange({ day });
                    }}
                    firstDayOfWeek={0}
                  />
                </LoaderWrapper>
              </MonthlyCalendar>
              <CalendarColorPalette>
                <ColorPaletteCol xs={12}>
                  <ColorPaletteHeading>Calendar Key:</ColorPaletteHeading>
                </ColorPaletteCol>
                <ColorPaletteCol>
                  <ColorCol className="material-icons" color={COLORS.successGreen}>
                    square
                  </ColorCol>
                  <span>Accepted</span>
                </ColorPaletteCol>
                <ColorPaletteCol>
                  <ColorCol color={COLORS.mustardYellow} className="material-icons">
                    square
                  </ColorCol>
                  <span>Pending</span>
                </ColorPaletteCol>
                <ColorPaletteCol>
                  <ColorCol color={COLORS.skyBluish} className="material-icons">
                    square
                  </ColorCol>
                  <span>Games Available</span>
                </ColorPaletteCol>
              </CalendarColorPalette>
            </MonthlyCalendarRow>
          </CalendarLeftCol>
          <CalendarRightCol>
            <CurrentDate>
              <WeekdayLabel>{moment(date).format('dddd')}, </WeekdayLabel>
              <MonthLabel>{moment(date).format('MMMM')}</MonthLabel>
              <DayLabel>
                {` `}
                {moment(date).format('D')}
              </DayLabel>
            </CurrentDate>

            <AssignmentListScroller>
              <LoaderWrapper isFetching={isFetchingTrue}>
                {games &&
                  games
                    .filter(
                      a =>
                        a.assignments_game.length &&
                        (a.assignments_game[0].status === ASSIGNMENT_STATUS.accepted ||
                          a.assignments_game[0].status === ASSIGNMENT_STATUS.pending ||
                          a.assignments_game[0].status === ASSIGNMENT_STATUS.declined)
                    )
                    .map(
                      game =>
                        moment.utc(date).format(DATE_FORMATS.M_D_YY) ===
                          moment.utc(game.start_at).format(DATE_FORMATS.M_D_YY) && (
                          <CalendarGameRow
                            key={game.id}
                            game={game}
                            updateGameAssignments={updateGameAssignments}
                            userSelfAssignGames={fetchUserSelfAssignGames}
                            selectedUser={selectedUser}
                            setCurrentGame={setCurrentGame}
                            setShowModalFor={setShowModalFor}
                            onAssessmentReportClick={onAssessmentReportClick}
                            onGamesReportClick={onGamesReportClick}
                            date={date}
                            fetchAssignments={fetchAssignments}
                            startAndEndDates={startAndEndDates}
                            setCalendarGame={setCalendarGame}
                            user={user}
                            createGameAssignment={createGameAssignment}
                            userSchedule={userSchedule}
                            setPulledDates={setPulledDates}
                          />
                        )
                    )}

                {games &&
                  games
                    .filter(a => !a.assignments_game.length)
                    .map(
                      game =>
                        moment.utc(date).format(DATE_FORMATS.M_D_YY) ===
                          moment.utc(game.start_at).format(DATE_FORMATS.M_D_YY) && (
                          <CalendarGameRow
                            key={game.id}
                            game={game}
                            updateGameAssignments={updateGameAssignments}
                            userSelfAssignGames={fetchUserSelfAssignGames}
                            selectedUser={selectedUser}
                            setCurrentGame={setCurrentGame}
                            setShowModalFor={setShowModalFor}
                            onAssessmentReportClick={onAssessmentReportClick}
                            onGamesReportClick={onGamesReportClick}
                            date={date}
                            fetchAssignments={fetchAssignments}
                            startAndEndDates={startAndEndDates}
                            setCalendarGame={setCalendarGame}
                            user={user}
                            createGameAssignment={createGameAssignment}
                            userSchedule={userSchedule}
                            setPulledDates={setPulledDates}
                          />
                        )
                    )}
                {!onDayClickGames.length && (
                  <NoAssignmentLabel>You have no assignments on this date</NoAssignmentLabel>
                )}
              </LoaderWrapper>
            </AssignmentListScroller>
          </CalendarRightCol>
        </CalendarRow>
      </Wrapper>
    </>
  );
};

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  fetchAssignments: PropTypes.func.isRequired,
  startAndEndDates: PropTypes.func.isRequired,
  editPayeeAccountAction: PropTypes.func.isRequired
};

export default Enhancer(Calendar);
