import COLORS from '../../../../utils/styles';

export const HEADERS = {
  NAME: 'name',
  USER_NAME: 'username',
  LAST_LOGIN: 'last_login',
  REMOVE: 'remove',
  EDIT: 'edit',
  TAXID: 'tax_id'
};

export default {
  usersTile: {
    customStyle: {
      border: '0.1rem solid',
      'border-color': COLORS.gray,
      'box-shadow': 'none',
      'border-radius': '4px'
    },
    border: true
  }
};
