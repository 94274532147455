import { compose } from 'recompose';
import { connect } from 'react-redux';

import unconnected from './unconnected';
import { isReportsEventAdmin, isReportEventAssignor } from '../../../core/Auth/selectors';
import { fetchCurrentGame as fetchCurrentGameAction } from '../../../core/Games';
import {
  editAssessmentReport as editAssessmentReportAction,
  fetchCurrentAssessmentReport as fetchCurrentAssessmentReportAction
} from '../../../core/Reports';
import { getCurrentGame } from '../../../core/Games/selectors';
import { getCurrentReport, isReportsEventGroupAdmin } from '../../../core/Reports/selectors';
import { getEventAssessmentReportSettings } from '../../../core/Events/selectors';

const mapStateToProps = state => ({
  game: getCurrentGame(state),
  report: getCurrentReport(state),
  eventsAssessmentSettings: getEventAssessmentReportSettings(state),
  isReportsEventAdmin: isReportsEventAdmin(state),
  isReportsEventAssignor: isReportEventAssignor(state),
  isReportsEventGroupAdmin: isReportsEventGroupAdmin()(state),
  isGameFetching: state.games.current.isFetching,
  isReportFetching: state.reports.current.isFetching
});

const mapDispatchToProps = dispatch => ({
  editAssessmentReport: payload => dispatch(editAssessmentReportAction(payload)),
  fetchCurrentGame: payload => dispatch(fetchCurrentGameAction(payload)),
  fetchCurrentAssessmentReport: payload => dispatch(fetchCurrentAssessmentReportAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
