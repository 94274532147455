import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Field, Form } from 'react-final-form';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';
import RoundedCheckBox from '../../../RoundedCheckBox';
import { falsyToNull } from '../../../../../utils/parsers';
import {
  checkAssignmentConflict,
  DEFAULT_SELECTED_ASSIGNMENTS,
  getSelectedAssignments
} from './config';
import { GAME_ASSIGNMENT_STATUSES } from '../../../../../constants';

const SingleGameSelected = ({ assignments, fetchUserAssignments }) => (
  <Row center="xs">
    <Col xs={9}>
      <Row start={assignments.length >= 2 ? 'xs' : ''}>
        {assignments.map((assignment, key) => {
          const label = `${assignment.crewLabel}${
            assignment.event_role && assignment.event_role.user
              ? ` - ${assignment.event_role.user.first_name} ${assignment.event_role.user.last_name}`
              : ''
          }`;

          return (
            <Col xs={assignments.length >= 2 ? 6 : 12} key={key}>
              <Field name={`assignments.position_${assignment.official_label_col}`}>
                {({ input }) => (
                  <RoundedCheckBox
                    name={input.name}
                    value={input.value}
                    onChange={() => {
                      input.onChange(!input.value);
                      fetchUserAssignments(assignment.event_role.user);
                    }}
                    parse={falsyToNull}
                  />
                )}
              </Field>
              <Summary>{label}</Summary>
            </Col>
          );
        })}
      </Row>
    </Col>
  </Row>
);

SingleGameSelected.propTypes = {
  assignments: PropTypes.arrayOf(Object)
};

SingleGameSelected.defaultProps = {
  assignments: []
};

const AcceptAssignmentsModal = ({
  onClose,
  selected,
  onSubmit,
  userId,
  canEdit,
  allowSelfAssignment,
  fetchUserAssignments,
  userAssignments,
  showAcceptErrorAssignmentsModal,
  setShowAcceptErrorAssignmentsModal
}) => {
  const [hasConflictError, setHasConflictError] = React.useState([]);
  const pendingAssignments = selected.reduce(
    (assignments, game) =>
      canEdit
        ? [
            ...assignments,
            ...game.assignments_game
              .filter(assignment => assignment.status === GAME_ASSIGNMENT_STATUSES.pending)
              .map(assignment => ({
                ...assignment,
                crewLabel:
                  game.game_level &&
                  game.game_level.labels &&
                  game.game_level.labels[assignment.official_label_col]
              }))
              .sort((a, b) => a.official_label_col - b.official_label_col)
          ]
        : allowSelfAssignment
        ? [
            ...assignments,
            ...game.assignments_game.filter(
              assignment =>
                Number(userId) === assignment.event_role.user_id &&
                (assignment.status === GAME_ASSIGNMENT_STATUSES.pending ||
                  assignment.status === GAME_ASSIGNMENT_STATUSES.unpublished)
            )
          ]
        : [
            ...assignments,
            ...game.assignments_game.filter(
              assignment =>
                Number(userId) === assignment.event_role.user_id &&
                assignment.status === GAME_ASSIGNMENT_STATUSES.pending
            )
          ],
    []
  );
  useEffect(() => {
    if (!canEdit) {
      fetchUserAssignments(pendingAssignments[0].event_role.user);
    }
    // eslint-disable-next-line
  }, [canEdit]);
  const isSingleGameSelected = selected.length === 1 && canEdit;
  return (
    <>
      <Modal title="Accept Game Assignments" onClose={onClose} leftPanel={canEdit}>
        <Form
          onSubmit={onClose}
          initialValues={{ assignments: DEFAULT_SELECTED_ASSIGNMENTS }}
          render={({ handleSubmit, values, form: { reset } }) => {
            const isAssinmentSelected = Object.keys(values.assignments).filter(
              key => values.assignments[key]
            ).length;
            const buttonDisabled = isSingleGameSelected
              ? !isAssinmentSelected
              : !pendingAssignments.length;

            return (
              <>
                <Row>
                  <HighlightedBlock xs={12}>
                    <Content>
                      <Summary>
                        <Count color={COLORS.denimBlue}>{selected.length}</Count>
                        Game{selected.length !== 1 ? 's' : ''} Selected
                      </Summary>
                      <Summary>
                        <Count color={COLORS.forestGreen}>{pendingAssignments.length}</Count>
                        Assignment{pendingAssignments.length !== 1 ? 's' : ''} Accepted
                      </Summary>
                    </Content>
                  </HighlightedBlock>
                </Row>
                {isSingleGameSelected && (
                  <SingleGameSelected
                    assignments={pendingAssignments}
                    fetchUserAssignments={fetchUserAssignments}
                  />
                )}
                <Row>
                  <Block xs={10} xsOffset={1}>
                    <Content>
                      {pendingAssignments.length
                        ? isSingleGameSelected
                          ? 'Select which crew members to change to Accepted status'
                          : 'Accept all pending assignments for selected games?'
                        : 'There are no pending assignments to accept'}
                    </Content>
                  </Block>
                </Row>
                <Row>
                  <Block xs={12} className="last">
                    <ButtonsContainer>
                      <ButtonContainer>
                        <Button
                          label="Accept"
                          disabled={buttonDisabled}
                          width="72px"
                          height="32px"
                          primary
                          onClick={() => {
                            const selectedAssignments = getSelectedAssignments(
                              values,
                              isSingleGameSelected,
                              pendingAssignments
                            );
                            const hasError = userAssignments.map(user =>
                              checkAssignmentConflict({
                                userAssignments: user && user.game_assignments,
                                display_id: selected[0].display_id,
                                start_date: selected[0].start_date,
                                start_time: selected[0].start_time,
                                end_date: selected[0].end_date,
                                end_time: selected[0].end_time,
                                gamesEventId:
                                  selected[0].game_level && selected[0].game_level.event_id,
                                timezone: selected[0].timezone,
                                id: selected[0].id,
                                first_name: user.first_name,
                                last_name: user.last_name,
                                status: selected[0].status
                              })
                            );
                            if (hasError.some(error => error.userGameErrors.length)) {
                              setShowAcceptErrorAssignmentsModal(true);
                              setHasConflictError(hasError);
                            } else {
                              handleSubmit();
                              onSubmit(selectedAssignments);
                            }
                            fetchUserAssignments();
                          }}
                        />
                      </ButtonContainer>
                      <ButtonContainer>
                        <Button
                          label="Cancel"
                          width="84px"
                          height="32px"
                          onClick={() => {
                            onClose();
                            reset();
                            fetchUserAssignments();
                          }}
                        />
                      </ButtonContainer>
                    </ButtonsContainer>
                  </Block>
                </Row>
              </>
            );
          }}
        />
      </Modal>
      {hasConflictError.some(error => error.userGameErrors.length) &&
        showAcceptErrorAssignmentsModal && (
          <Modal
            title="Accept Game Assignments"
            onClose={() => {
              onClose();
              setHasConflictError([]);
              fetchUserAssignments();
            }}
            leftPanel={canEdit}
          >
            <Row>
              <Block xs={10} xsOffset={1}>
                {hasConflictError.map(error =>
                  error.userGameErrors.map(err => {
                    return !canEdit ? (
                      <Content>
                        This game is in conflict with Game {err.display_id}, please decline that
                        game to accept this one{!canEdit ? ` or contact your assignor.` : `.`}
                      </Content>
                    ) : (
                      <Content>
                        {err.name} has conflicting assignments with Game {err.display_id}, please
                        decline that game to accept this one
                        {!canEdit ? ` or contact your assignor.` : `.`}
                      </Content>
                    );
                  })
                )}
              </Block>
            </Row>
            <Row>
              <Block xs={12} className="last">
                <ButtonContainer>
                  <Button
                    label="Close"
                    width="84px"
                    height="32px"
                    primary
                    onClick={() => {
                      onClose();
                      fetchUserAssignments();
                    }}
                  />
                </ButtonContainer>
              </Block>
            </Row>
          </Modal>
        )}
    </>
  );
};

AcceptAssignmentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  allowSelfAssignment: PropTypes.bool
};

AcceptAssignmentsModal.defaultProps = {
  canEdit: false,
  allowSelfAssignment: false
};

export default AcceptAssignmentsModal;
