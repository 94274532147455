import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const Wrapper = styled.div``;

export const Label = styled.p`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: ${COLORS.nightRider};
  margin: 0 0 ${pxToRem('6px')};
  text-align: left;
  padding-left: 0.5rem;
`;

export const Value = styled.p`
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.denimBlue};
  margin: ${pxToRem('6px')} 0 0;
  text-align: left;
  padding-left: 0.5rem;
  ${({ empty }) => (empty ? `padding: ${pxToRem('9px')} 0 ${pxToRem('9px')} 0.5rem` : '')};
`;
