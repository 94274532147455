import styled from 'styled-components';

import { COLORS, mediaQueries } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Icon from '../../Icon';

export const Location = styled.div`
  font-weight: 600;
  max-width: 20rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ReportContainer = styled.span`
  justify-content: right;
`;

export const HomeTeam = styled.span`
  font-family: 'RobotoBold';
`;

export const Clickable = styled.span`
  font-weight: bold;
  color: ${COLORS.denimBlue};
  cursor: pointer;
  white-space: nowrap;
`;

export const Score = styled.span`
  color: ${COLORS.denimBlue};
  font-family: 'RobotoCondensedBold';
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
`;

export const Placeholder = styled.span`
  cursor: default;
  margin: 0 ${pxToRem('5px')};
  display: inline-block;
  color: ${({ disabled }) => (disabled ? `${COLORS.sapphire}44` : `${COLORS.sapphire}EE`)};
  font-family: ${({ disabled }) => (disabled ? 'RobotoCondensedLight' : 'RobotoCondensed')};
  font-size: ${pxToRem('18px')};
  overflow: hidden;
  text-overflow: ellipsis;
  ${`@media (max-width: 1315px) {
    font-size:${pxToRem('14px')}
  }`}
`;

export const EmptyCell = styled.div`
  width: 100%;
`;

export const PositionsStyle = styled.div`
  display: flex;
  flex-grow: 0;
  background-image: linear-gradient(
    to top,
    ${({ isCopy }) =>
      isCopy
        ? `${COLORS.shadowGray}, ${COLORS.shadowGray}`
        : `${COLORS.blackSqueeze}, ${COLORS.white}`}
  );
  width: 50%;
  ${`@media (max-width: 1315px) {
    width: 51%;
  }`}

  ${mediaQueries.md(` {
    width:90%;
    padding:10px 0px 10px 6%;
  }`)}

  ${`@media (max-width: 330px) {
    width:95%;
    padding:10px 0px 10px 2%;
  }`}
`;

export const CellRow = styled.div`
  padding-top: ${({ unAssigned }) => (unAssigned ? `${pxToRem('20px')}` : '0px')};
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CellWrapper = styled.div`
  ${({ selected, isConflict }) =>
    isConflict
      ? `background-image: linear-gradient(to top, ${COLORS.red}, ${COLORS.red})`
      : selected
      ? `background-image: linear-gradient(to top, ${COLORS.bahamaBlue}, ${COLORS.denimBlue})`
      : ''};
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
`;

export const Name = styled.span`
  display: inline-block;
  margin: 0 ${pxToRem('3px')};
  padding-bottom: ${pxToRem('2px')};
  font-family: 'RobotoCondensed';
  ${({ minRank }) =>
    minRank ? 'font-weight: normal' && ` padding-top: ${pxToRem('5px')}` : 'font-weight: bold'};
  font-size: ${pxToRem('12px')};
  color: ${({ selected, disabled }) =>
    selected ? COLORS.white : disabled ? `${COLORS.sapphire}44` : COLORS.sapphire};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.08;
  word-break: break-word;
  ${`@media (max-width: 1315px) {
    font-size:${pxToRem('10px')}
  }`}
  ${`@media (max-width: 350px) {
    font-size:${pxToRem('8px')}
  }`}
`;

export const CrewLabel = styled.span`
  margin: ${pxToRem('1px')};
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('10px')};
  color: ${({ selected }) => (selected ? COLORS.white : `${COLORS.sapphire}CC`)};
`;

export const StyledIcon = styled(Icon)`
  margin: 0;
`;

export const CrewIcon = styled(Icon)`
  display: inline-block;
  position: relative;
  justify-content: space-between;
  background: ${COLORS.white};
  padding-right: ${pxToRem('1px')};
  margin: 0 1px;
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')};
`;

export const EmptyCircle = styled.div`
  box-sizing: border-box;
  margin-top: ${pxToRem('8px')};
  margin-bottom: ${pxToRem('4px')};
  display: inline-block;
  width: ${pxToRem('18px')};
  height: ${pxToRem('18px')};
  background: ${({ selected }) => (selected ? COLORS.white : 'transparent')};
  border-radius: 50%;
  border: 1.5px solid ${COLORS.greyChateau}BB;
`;

export const StatusIcon = styled(Icon)`
  margin: 0;
  position: relative;
  top: ${pxToRem('-3px')};
  right: ${pxToRem('1px')};
`;

export const StatusIconContainer = styled.div`
  margin-top: ${pxToRem('8px')};
  margin-bottom: ${pxToRem('4px')};
  width: ${pxToRem('16px')};
  height: ${pxToRem('16px')};
  overflow: visible;
  background-color: ${COLORS.white};
  border-radius: 50%;
`;

export const Remove = styled(Icon)`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  cursor: pointer;
  margin: 0;
  position: absolute;
  right: ${pxToRem('3px')};
  top: ${pxToRem('3px')};

  ${mediaQueries.sm(`{
  right: ${pxToRem('0px')};
  top: ${pxToRem('0px')};
   } `)}
`;

export const RowIcon = styled.img`
  margin-right: ${pxToRem('6px')};
  display: inline-block;
  position: relative;
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')};
`;

export const ReportsIconContainer = styled.div`
  display: flex;
  margin-bottom: ${pxToRem('3px')};
  padding-right: ${pxToRem('3px')};
`;

export const ReportsIconLabel = styled.span`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: ${pxToRem('11px')};
  margin-top: ${pxToRem('3px')};
  margin-right: ${pxToRem('5px')};
  color: ${COLORS.shuttleGray};
`;

export const ReportsIcon = styled.span`
  display: inline-block;
  position: relative;
  justify-content: space-between;
  width: ${pxToRem('31px')};
  height: ${pxToRem('21px')};
  background: ${({ backGround }) => backGround || COLORS.blueGray};
  border-radius: 4px;
  padding-right: ${pxToRem('1px')};
  text-align: center;
  margin: 0px 1px;
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')};
  &:after {
    font-family: Barlow;
    font-weight: 600;
    content: attr(data-tooltip);
    font-size: ${pxToRem('14px')};
    color: ${({ IconColor }) => IconColor || '#3D4956'};
    position: relative;
    top: 1px;
    left: 0px;
  }
`;

export const GameInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  padding-left: ${pxToRem('59px')};
  border-right: 1px solid ${COLORS.lightCyanGray};
`;

export const NameDetail = styled.span`
  font-size: ${pxToRem('13px')};
  font-family: 'BarlowSemiCondensed';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 26ch;
`;

export const PositionLoader = {
  animation: `
    margin: 0 0.60rem;
  `
};

export const PaymentIconContainer = styled.span`
  margin-top: ${pxToRem('8px')};
  margin-bottom: ${pxToRem('4px')};
  width: ${pxToRem('16px')};
  height: ${pxToRem('16px')};
  overflow: visible;
  background-color: ${COLORS.white};
  border-radius: 50%;
`;

export const IconCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const NoteSpinnerWrapper = styled.div`
  min-width: ${pxToRem('50px')};
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoteWrapper = styled.div`
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
`;

export const ConflictIconContainer = styled.span`
  position: absolute;
  margin: 0 60px 28px 6px;
  width: ${pxToRem('16px')};
  height: ${pxToRem('16px')};
  overflow: visible;
  background-color: ${COLORS.white};
  border-radius: 50%;

  @media (max-width: 1650px) {
    margin: 0 35px 28px 3px;
  }
  @media (max-width: 1175px) {
    margin: 0 30px 28px 3px;
  }
  @media (max-width: 1085px) {
    margin: 0 20px 28px 3px;
  }
  @media (max-width: 992px) {
    margin: 0 85px 28px 3px;
  }
  @media (max-width: 865px) {
    margin: 0 70px 28px 3px;
  }
  @media (max-width: 730px) {
    margin: 0 60px 28px 3px;
  }
  @media (max-width: 635px) {
    margin: 0 50px 28px 3px;
  }
  @media (max-width: 550px) {
    margin: 0 40px 28px 3px;
  }
  @media (max-width: 465px) {
    margin: 0 30px 28px 3px;
  }
  @media (max-width: 380px) {
    margin: 0 20px 28px 3px;
  }
`;
