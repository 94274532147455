export const MAIN = '/u/:userIdx';
export const DASHBOARD = '/u/:userIdx/dashboard';
export const GAMES = '/u/:userIdx/games';
export const AUTH = '/auth';
export const LOGIN = '/auth/login';
export const CREATE_ACCOUNT = '/auth/create-account';
export const SUCCESS = '/auth/success';
export const PROFILE = '/u/:userIdx/profile/:userId';
export const PROFILE_CERTIFICATIONS = '/u/:userIdx/profile/:userId/certifications';
export const PROFILE_AVAILABLE_BALANCE = '/u/:userIdx/profile/:userId/available-balance';
export const PROFILE_PAYMENT_HISTORY = '/u/:userIdx/profile/:userId/payment-history';
export const PROFILE_PAYMENT_METHOD = '/u/:userIdx/profile/:userId/payment-method';
export const PROFILE_AVAILABILITY = '/u/:userIdx/profile/:userId/availability';
export const TAX_INFO = '/u/:userIdx/profile/:userId/tax-info';
export const EVENTS = '/u/:userIdx/events';
export const EVENTS_OFFICIALS = '/u/:userIdx/events/:eventId/officials';
export const EVENTS_USERS = '/u/:userIdx/events/:eventId/users';
export const EVENTS_ASSESSOR = '/u/:userIdx/events/:eventId/assessors';
export const EVENTS_LOCATION = '/u/:userIdx/events/:eventId/locations';
export const EVENTS_TEAM = '/u/:userIdx/events/:eventId/teams';
export const SINGLE_EVENT = '/u/:userIdx/event/:eventId';
export const EVENT_INFORMATION = '/u/:userIdx/event/:eventId/information';
export const EVENT_ROLES = '/u/:userIdx/event/:eventId/roles';
export const EVENT_CREW_PAY = '/u/:userIdx/event/:eventId/crew-pay';
export const EVENT_CATEGORIES = '/u/:userIdx/event/:eventId/categories';
export const EVENT_ASSIGNOR_SETTINGS = '/u/:userIdx/event/:eventId/assignor-settings';
export const EVENT_GAME_LEVELS = '/u/:userIdx/event/:eventId/game-levels';
export const EVENT_AFTER_GAME_REPORTS = '/u/:userIdx/event/:eventId/after-game-reports';
export const EVENT_ASSESSMENTS = '/u/:userIdx/event/:eventId/assessments';
export const EVENT_CREW_LABELS = '/u/:userIdx/event/:eventId/crew-labels';
export const PROFILE_INFORMATION = '/u/:userIdx/profile/:userId/information';
export const REPORTS = '/u/:userIdx/reports';
export const PAYMENTS = '/u/:userIdx/payments';
export const PAYMENTS_BATCH_EDIT = '/u/:userIdx/payments/:eventId';
export const PAYMENTS_BATCH_REVIEW = '/u/:userIdx/payments-review/:batchId';
export const STYLE_GUIDE = '/u/:userIdx/style-guide';
export const PEOPLE = '/u/:userIdx/people';
export const GROUP_INFO = '/u/:userIdx/group/:groupId';
export const ON_BOARDING = '/u/:userIdx/on-boarding';
export const START_PROFILE_INFORMATION = '/u/:userIdx/on-boarding/:userId/profile-information';
export const START_PROFILE_PAYMENT_METHOD = '/u/:userIdx/on-boarding/:userId/payment-method';
export const START_TAX_INFO = '/u/:userIdx/on-boarding/:userId/tax-info';
export const SSO_REDIRECT = '/sso-redirect';
