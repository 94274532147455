import React from 'react';
import moment from 'moment';
import {
  EventTime,
  EventTitle,
  Chevron,
  EventDetails,
  EventIcon,
  AvailableIconSpan
} from '../styled-components';
import config from './config';
import BlueThemeTooltip from '../Tooltip/AssignmentTooltip';
import { DATE_FORMATS } from '../../../../constants';
import { AVAILABILITY_TYPES } from '../../Profile/Availability/availabilityConstants';
import AvailabilityTooltip from '../Tooltip/AvailabilityTooltip';

const game_status = ['cancelled', 'postponed'];
const EventTile = ({ startTimeFormat, configValue, headingTitle, expand }) => (
  <EventDetails {...config.calendarScheduleStatus[configValue]}>
    <EventIcon>
      {configValue === AVAILABILITY_TYPES.AVAILABLE ? (
        <AvailableIconSpan className="material-icons">event_available</AvailableIconSpan>
      ) : configValue === AVAILABILITY_TYPES.UNAVAILABLE ? (
        <AvailableIconSpan className="material-icons">event_busy</AvailableIconSpan>
      ) : (
        <Chevron {...config.calendarScheduleStatus[configValue]} />
      )}
    </EventIcon>
    <EventTime {...config.calendarScheduleStatus[configValue]}>{startTimeFormat}</EventTime>
    {expand && <EventTitle>{headingTitle}</EventTitle>}
  </EventDetails>
);

const CustomEvent = ({ events, expand }) => {
  const { start, end, gameStatus, title, gameId, assignedBy, eventName, id } =
    events && events.event;
  const [open, setOpen] = React.useState(false);
  const startTimeFormat = start && moment(start).format(DATE_FORMATS.H_MM_A);
  const endTimeFormat = end && moment(end).format(DATE_FORMATS.H_MM_A);
  const configValue = game_status.includes(gameStatus && gameStatus.toLowerCase())
    ? `${gameStatus && gameStatus.toLowerCase()}${title && title.toLowerCase()}`
    : title && title.toLowerCase();
  const headingTitle = title;

  return (
    <>
      {Object.values(AVAILABILITY_TYPES).includes(headingTitle) ? (
        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        <AvailabilityTooltip
          id={id}
          key={id}
          open={open}
          setOpen={setOpen}
          start={start}
          startTimeFormat={startTimeFormat}
          endTimeFormat={endTimeFormat}
          headingTitle={headingTitle}
        >
          <EventTile
            startTimeFormat={startTimeFormat}
            configValue={configValue}
            headingTitle={headingTitle}
            expand={expand}
          />
        </AvailabilityTooltip>
      ) : (
        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        <BlueThemeTooltip
          id={id}
          gameId={gameId}
          open={open}
          setOpen={setOpen}
          startTimeFormat={startTimeFormat}
          endTimeFormat={endTimeFormat}
          headingTitle={headingTitle}
          configValue={configValue}
          gameStatus={gameStatus}
          eventName={eventName}
          assignedBy={assignedBy}
        >
          <EventTile
            startTimeFormat={startTimeFormat}
            configValue={configValue}
            headingTitle={headingTitle}
            expand={expand}
          />
        </BlueThemeTooltip>
      )}
    </>
  );
};

export default CustomEvent;
