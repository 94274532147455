import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers, lifecycle, withHandlers } from 'recompose';
import { ifElse, identity, always, and } from 'ramda';
import Icon from '../../components/Icon';
import Input from '../../components/Input';
import ErrorContainer from '../../components/MaterialErrorContainer';
import {
  ClickableContainer,
  CaptionContainer,
  DropDownContent,
  PlaceHolderValueContainer,
  Label,
  IconContainer,
  IconOuterContainer
} from './styled-components';
import { ICONS, COLORS } from '../../../utils/styles';
import { notNilNotEmpty } from '../../../utils/helpers';

const getColor = (disabled, hasValue, disabledBlue, disableColor) => {
  if (disabledBlue) {
    return COLORS.denimBlue;
  }

  if (disabled) {
    return disableColor || COLORS.veryLightGray;
  }

  if (hasValue) {
    return COLORS.denimBlue;
  }

  return COLORS.shuttleGrey;
};

const MaterialDropableContainer = ({
  placeholder,
  value,
  required,
  isOpen,
  toggleOpen,
  content: Content,
  onUpdateValue,
  icon,
  valueRenderer,
  onBindRef,
  focus,
  onUpdateFilter,
  theme,
  typeahead,
  disabled,
  disabledBlue,
  showError,
  meta: { error, touched, invalid },
  fontSize,
  disabledBackgroundColor,
  disableColor
}) => (
  <ClickableContainer innerRef={onBindRef} theme={theme.clickable}>
    <CaptionContainer
      onClick={() => {
        if (!disabled) {
          if (!isOpen) focus();
          toggleOpen();
        }
        return null;
      }}
      hasError={and(showError, !notNilNotEmpty(error))}
      disabled={disabled}
      theme={theme.caption}
      disabledBackgroundColor={disabledBackgroundColor}
    >
      <PlaceHolderValueContainer
        theme={theme.placeholder}
        xs={11}
        hasValue={!notNilNotEmpty(value)}
        disabled={disabled}
        color={getColor(disabled, !notNilNotEmpty(value), disabledBlue, disableColor)}
      >
        {typeahead && isOpen ? (
          <Input
            noBorder
            noMargin
            autoFocus
            name="dropfilter"
            onChange={e => {
              onUpdateFilter(e.target.value);
            }}
          />
        ) : (
          ifElse(
            notNilNotEmpty,
            always(required ? `${placeholder}*` : placeholder),
            valueRenderer
          )(value)
        )}
      </PlaceHolderValueContainer>
      {theme.icon ? (
        <IconContainer theme={theme.icon}>
          <Icon width={10} color={COLORS.shuttleGray} icon={icon} noMargin />
        </IconContainer>
      ) : (
        <IconOuterContainer>
          <IconContainer>
            <Icon icon={icon} noMargin color={disabled ? COLORS.veryLightGray : COLORS.denimBlue} />
          </IconContainer>
        </IconOuterContainer>
      )}
    </CaptionContainer>
    {!theme.noDefaultError && showError && error && (
      <ErrorContainer theme={theme.error}>{error}</ErrorContainer>
    )}
    {!notNilNotEmpty(value) && !theme.noError && (
      <Label
        theme={theme.label}
        hasError={and(touched, invalid)}
        empty={!value}
        fontSize={fontSize}
      >
        {placeholder}
      </Label>
    )}
    {isOpen && (
      <DropDownContent theme={theme.content}>
        <Content onChange={onUpdateValue} />
      </DropDownContent>
    )}
  </ClickableContainer>
);
MaterialDropableContainer.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: PropTypes.func,
  required: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onUpdateValue: PropTypes.func.isRequired,
  icon: PropTypes.string,
  content: PropTypes.func.isRequired,
  valueRenderer: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  onUpdateFilter: PropTypes.func,
  typeahead: PropTypes.bool,
  focus: PropTypes.func,
  showError: PropTypes.bool,
  theme: PropTypes.shape({
    clickable: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  onBindRef: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledBlue: PropTypes.bool
};

MaterialDropableContainer.defaultProps = {
  placeholder: '',
  value: null,
  onChange: always(undefined),
  required: false,
  icon: ICONS.CHEVRON_DOWN,
  valueRenderer: identity,
  showError: false,
  meta: {
    touched: false,
    error: ''
  },
  typeahead: false,
  focus: () => true,
  onUpdateFilter: () => {},
  theme: {
    clickable: '',
    caption: '',
    placeholder: '',
    icon: 'not empty',
    content: ''
  },
  disabled: false,
  disabledBlue: false
};

export default compose(
  withStateHandlers(
    ({ isOpen = false }) => ({
      isOpen
    }),
    {
      toggleOpen: ({ isOpen }) => () => ({ isOpen: !isOpen }),
      onUpdateValue: (_, { onChange, blur }) => value => {
        onChange(value);
        blur();
        return { isOpen: false };
      },
      close: (_, { typeahead, updateFilter }) => () => {
        if (typeahead) updateFilter('');
        return { isOpen: false };
      }
    }
  ),
  withHandlers(() => {
    let refReference = null;
    return {
      onClickOutside: ({ close, blur, isOpen }) => event => {
        if (refReference && !refReference.contains(event.target)) {
          close();
          if (isOpen) blur();
        }
      },
      onBindRef: () => ref => {
        refReference = ref;
      },
      onUpdateFilter: _ => value => {
        _.updateFilter(value);
      }
    };
  }),
  lifecycle({
    componentDidMount() {
      document.addEventListener('mousedown', this.props.onClickOutside);
    },
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.props.onClickOutside);
    }
  }),
  pure
)(MaterialDropableContainer);
