import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import OutsideClickHandler from 'react-outside-click-handler';
import Enhancer from '../../../../containers/Payments/BatchEdit/AssignorList';
import {
  StyledAssignor,
  AssignorText,
  AssignorList,
  AssignorWrapper,
  TitleWrapper,
  CheckboxContainer,
  NoAssignor
} from './styled-components';
import { Title } from '../AdjustmentPanel/styled-components';
import FilterSortSearch from '../../../SideList/FilterSortSearch';
import { COLORS } from '../../../../../utils/styles';
import config from './config';
import RoundedCheckBox from '../../../RoundedCheckBox';
import Assignor from './Assignor';

const AssignorPanel = ({
  eventAssignors,
  filter,
  search,
  sort,
  allGamesCheck,
  setAllGamesCheck,
  selectedGame,
  games,
  handlePrimaryAssignment,
  handlePrimaryAssignmentBulk,
  onOutsideClick,
  gamesEvent,
  setAssignmentWarnings
}) => {
  const current = games.find(game => game.display_id === selectedGame.currentId)
    .assignment_primary_game;
  const ids = games
    .filter(game => !!game.assignment_primary_game)
    .map(game => game.assignment_primary_game.id);
  const { required_payment_profile: requiredPaymentProfile } = gamesEvent || {
    required_payment_profile: true
  };
  eventAssignors.sort((a, b) => {
    const A = a.users.first_name;
    const B = b.users.first_name;
    if (A < B) return -1;
    if (A > B) return 1;
    return 0;
  });

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <AssignorWrapper>
        <FilterSortSearch
          filter={filter}
          search={search}
          sort={sort}
          config={config()}
          placeholder="Search Assignor Name"
          backgroundColor={COLORS.catSkillWhite}
          filterBarItemFontColor={COLORS.shuttleGray}
          noFilter
          noSort
        />
        <TitleWrapper>
          <Title>Assign Primary</Title>
          <CheckboxContainer>
            <RoundedCheckBox
              label="For All Games"
              value={allGamesCheck}
              onChange={() => {
                if (!allGamesCheck && !isEmpty(ids)) {
                  handlePrimaryAssignmentBulk({ ids });
                }
                setAllGamesCheck(!allGamesCheck);
              }}
            />
          </CheckboxContainer>
        </TitleWrapper>
        <StyledAssignor header>
          <AssignorText header>Name</AssignorText>
          <AssignorText header />
          <AssignorText header>Location</AssignorText>
        </StyledAssignor>
        <AssignorList>
          {eventAssignors.map(assignor => (
            <Assignor
              key={`assignor${assignor.user_id}`}
              assignor={assignor}
              handlePrimaryAssignment={handlePrimaryAssignment}
              handlePrimaryAssignmentBulk={handlePrimaryAssignmentBulk}
              current={current}
              allGames={games}
              allGamesChecked={allGamesCheck}
              requiredPaymentProfile={requiredPaymentProfile}
              setAssignmentWarnings={setAssignmentWarnings}
            />
          ))}
        </AssignorList>
        <NoAssignor
          onClick={
            !allGamesCheck
              ? () =>
                  current &&
                  handlePrimaryAssignment({ current, selected: { id: current.event_role_id } })
              : () => !isEmpty(ids) && handlePrimaryAssignmentBulk({ ids })
          }
        >
          <AssignorText>No Assignor</AssignorText>
        </NoAssignor>
      </AssignorWrapper>
    </OutsideClickHandler>
  );
};

AssignorPanel.propTypes = {
  eventAssignors: PropTypes.arrayOf(Object),
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  sort: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  allGamesCheck: PropTypes.bool.isRequired,
  setAllGamesCheck: PropTypes.func.isRequired,
  selectedGame: PropTypes.objectOf(Object).isRequired,
  games: PropTypes.arrayOf(Object).isRequired,
  handlePrimaryAssignment: PropTypes.func.isRequired,
  handlePrimaryAssignmentBulk: PropTypes.func.isRequired,
  onOutsideClick: PropTypes.func.isRequired
};

AssignorPanel.defaultProps = {
  eventAssignors: []
};

export default Enhancer(AssignorPanel);
