import styled from 'styled-components';

import Icon from '../Icon';
import { pxToRem } from '../../../utils/helpers';
import { COLORS } from '../../../utils/styles';

export const Header = styled.div`
  display: flex;
  flex: 1;
  background-color: ${COLORS.pale_grey_two};
  align-items: center;
  height: ${pxToRem('32px')};
  padding-left: 0.5rem;
  margin: 0 0 0.75rem;
`;

export const Label = styled.span`
  font-family: 'RobotoBold';
  font-size: ${pxToRem('15px')};
  color: ${COLORS.sapphire};
  text-transform: uppercase;
`;

export const Chevron = styled(Icon)`
  margin-right: 0.75rem;
  margin-left: auto;
  cursor: pointer;

  ${({ showContent }) => (showContent ? `position: relative; left: ${pxToRem('-3px')};` : '')};
  ${({ theme }) => theme || ''};
`;
