import styled from 'styled-components';
import COLORS from '../../../utils/styles';

const IconWrapper = styled.div`
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50px;
  box-shadow: 1px 0px 5px 0px #2d2d2d;
  background: ${COLORS.denimBlue};
`;

export default IconWrapper;
