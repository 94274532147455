import React from 'react';
import PropTypes from 'prop-types';
import Sorter from '../../Sorter';
import config from './config';

const GameSorter = ({ onSort, value, sortingOptions }) => (
  <Sorter
    options={sortingOptions || config.options}
    onSort={onSort}
    value={value || 'date_location_time'}
    minWidth={130}
  />
);

GameSorter.propTypes = {
  onSort: PropTypes.func.isRequired,
  value: PropTypes.string,
  sortingOptions: PropTypes.arrayOf(Object)
};

GameSorter.defaultProps = {
  value: '',
  sortingOptions: null
};
export default React.memo(GameSorter);
