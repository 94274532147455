import { compose, withHandlers, lifecycle, withProps, withState } from 'recompose';

import MODAL_NAMES, {
  FROM_REPORTS,
  FROM_GAMES,
  FROM_NOTIFICATIONS
} from '../../../core/Modals/constants';
import { convertToTimezone } from '../../../utils/helpers';

export default compose(
  withProps(({ game }) => ({ reports: game && game.incidentReports })),
  withState('reportId', 'setReportId', null),
  withState('creatingReport', 'setCreatingReport', false),
  withState('inEdit', 'setInEdit', false),
  withState('deleteReportId', 'setDeleteReportId', null),
  withHandlers({
    onSubmit: ({
      createIncidentReport,
      setCreatingReport,
      game: { id, display_id, game_level, start_date, start_time, timezone },
      reportSettings,
      isGamesEventOfficial
    }) => values => {
      createIncidentReport({
        id,
        data:
          isGamesEventOfficial &&
          reportSettings &&
          reportSettings.allow_officials_to_submit &&
          reportSettings.auto_approve_incident
            ? {
                ...values,
                game_level_id: game_level.id,
                external_event_id: display_id,
                external_game_date: convertToTimezone(
                  `${start_date} ${start_time}`,
                  timezone,
                  'YYYY-MM-DD HH:mm:ss'
                ),
                timezone,
                approved: true
              }
            : {
                ...values,
                game_level_id: game_level.id,
                external_event_id: display_id,
                external_game_date: convertToTimezone(
                  `${start_date} ${start_time}`,
                  timezone,
                  'YYYY-MM-DD HH:mm:ss'
                ),
                timezone
              }
      });
      setCreatingReport(false);
    },
    onEdit: ({ editIncidentReport: editGamesReport, editReport, setReportId, setInEdit }) => ({
      values,
      from,
      report: { id }
    }) => {
      if (from === FROM_REPORTS) {
        editReport({ id, data: values });
      } else if (from === FROM_GAMES || from === FROM_NOTIFICATIONS) {
        editGamesReport({ id, data: values });
      }
      setReportId(null);
      setInEdit(false);
    },
    onDelete: ({ deleteIncidentReport, setDeleteReportId }) => id => {
      deleteIncidentReport({ id });
      setDeleteReportId(null);
    },
    onClose: ({ setShowModalFor }) => from =>
      setShowModalFor({ name: MODAL_NAMES.incidentReportsModal, from }),
    onBack: ({ setReportId, setCreatingReport, setDeleteReportId }) => () => {
      setReportId(null);
      setCreatingReport(false);
      setDeleteReportId(null);
    },
    onApprove: ({ editIncidentReport: editGamesReport, editReport }) => (
      { id, approved },
      from
    ) => {
      if (from === FROM_REPORTS) {
        editReport({
          id,
          data: { approved: !approved },
          message: `Successfully ${approved ? 'un' : ''}approved incident report`
        });
      } else if (from === FROM_GAMES) {
        editGamesReport({
          id,
          data: { approved: !approved },
          message: `Successfully ${approved ? 'un' : ''}approved incident report`
        });
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.game && this.props.game.id) {
        const filters = {};
        if (
          !(
            this.props.isReportsEventGroupAdmin ||
            this.props.isGamesEventAdminOrAssignor ||
            this.props.isReportsEventAdmin ||
            this.props.isReportsEventAssignor ||
            (this.props.reportSettings &&
              this.props.reportSettings.allow_officials_to_submit &&
              this.props.reportSettings.official_to_official_viewability)
          )
        )
          filters.user_id = this.props.currentUserId;
        this.props.fetchCurrentIncidentReports({ filters });
      }
    },
    componentDidUpdate(prevProps) {
      if (!prevProps.game.id && this.props.game) {
        const filters = {};
        if (
          !(
            this.props.isReportsEventGroupAdmin ||
            this.props.isGamesEventAdminOrAssignor ||
            this.props.isReportsEventAdmin ||
            this.props.isReportsEventAssignor ||
            (this.props.reportSettings &&
              this.props.reportSettings.official_to_official_viewability)
          )
        )
          filters.user_id = this.props.currentUserId;
        this.props.fetchCurrentIncidentReports({ filters });
      }
    }
  })
);
