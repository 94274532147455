import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { normalizePrice } from '../../../utils/parsers';

import { FieldInput, FieldError } from './styled-components';

const PriceField = ({
  name,
  max,
  hasHint,
  validate,
  hasLongHint,
  paddingLeft,
  alignLeft,
  ...rest
}) => (
  <Field
    name={name}
    validate={validate}
    parse={value => normalizePrice(value, max, false, false, true, false)}
    {...rest}
  >
    {({ input, meta, ...inputRest }) => (
      <>
        <FieldInput {...input} {...inputRest} />
        {meta.error && (
          <FieldError
            className={hasHint ? 'push-down' : hasLongHint && 'push-down-more'}
            paddingLeft={paddingLeft}
            alignLeft={alignLeft}
          >
            {meta.error}
          </FieldError>
        )}
      </>
    )}
  </Field>
);

PriceField.propTypes = {
  name: PropTypes.string.isRequired,
  max: PropTypes.number,
  validate: PropTypes.func,
  hasHint: PropTypes.bool,
  hasLongHint: PropTypes.bool,
  alignLeft: PropTypes.bool,
  paddingLeft: PropTypes.string
};

PriceField.defaultProps = {
  hasHint: false,
  hasLongHint: false,
  max: 2,
  validate: () => null,
  alignLeft: false,
  paddingLeft: '0px'
};

export default PriceField;
