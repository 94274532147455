import React from 'react';
import PropTypes from 'prop-types';

import LoaderWrapper from '../../../LoaderWrapper';

const GameLocationCount = ({ counts, isFetching, theme }) => (
  <LoaderWrapper isFetching={isFetching} theme={theme}>
    {counts}
  </LoaderWrapper>
);

GameLocationCount.propTypes = {
  counts: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    wrapper: PropTypes.string,
    animation: PropTypes.string
  })
};

GameLocationCount.defaultProps = {
  theme: {
    animation: `
      margin: 0 0.60rem;
    `
  }
};

export default GameLocationCount;
