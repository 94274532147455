import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import GamesList from './GamesList';
import LoaderWrapper from '../../LoaderWrapper';
import AdjustmentPanel from './AdjustmentPanel';
import PaginationBar from '../../PaginationBar';
import Subheader from '../../Subheader';
import AssignorPanel from './AssignorList';
import Enhancer from '../../../containers/Payments/BatchEdit';
import { PaymentsContainer, Wrapper } from '../styled-components';
import { EmptyMessage } from '../../StyledGames/styled-components';
import { hasClicked } from '../../../../utils/helpers';
import SummaryBar from '../SummaryBar';
import PreviewBatch from '../PreviewBatch';
import { POSITION_LABELS } from '../../../../constants';

const SUBHEADER_THEME = {
  LabelsHeader: 'width: 100%; justify-content: flex-end;',
  NavItem: 'width: 22.75rem;',
  ColumnHeader: `
    &:first-child {
      border-left: none;
    }
  `
};

// protection incase the event is raised but the handler provided is undefined
const onDefaultPaginationClick = setSelectedAllGames => {
  if (setSelectedAllGames) {
    setSelectedAllGames(false);
  }
};

const BatchEdit = ({
  games,
  handleCheckboxClick,
  filter,
  pagination,
  sort,
  handleSelectAll,
  crewLabels,
  isFetching,
  selectedAssignment,
  handleSetSelectedAssignment,
  handleAdjustmentSubmit,
  isAssignmentFetching,
  setSelectedAssignment,
  selectedGame,
  setSelectedGame,
  setAllGamesCheck,
  allGamesCheck,
  activeBatch,
  handleBackToDateSelection,
  paymentsSummary,
  batchModalOpen,
  setBatchModalOpen,
  onLoadPageArgs: { reviewMode },
  reviewBatchInfo,
  downloadBatchPayReport,
  downloadFundingPayReport,
  isEventAdmin,
  isPaymentsEventGroupAdmin,
  isSuperAdmin,
  location,
  paymentEvent,
  setAssignmentWarnings,
  deleteActiveBatch,
  categories,
  teams,
  complexes,
  selectedAllGames,
  setSelectedAllGames
}) => {
  const { required_payment_profile: requiredPaymentProfile } = reviewBatchInfo.event ||
    paymentEvent || {
      required_payment_profile: true
    };
  const {
    id: reviewBatchId,
    description: reviewBatchDescription,
    event: { name: reviewBatchEventName } = {},
    payment_initiated
  } = reviewBatchInfo;
  const report_settings = activeBatch && activeBatch.event && activeBatch.event.report_settings;
  const { event: { name: activeBatchName = '' } = {} } = activeBatch;
  const start_date = reviewMode ? reviewBatchInfo.start_date : activeBatch.start_date;
  const end_date = reviewMode ? reviewBatchInfo.end_date : activeBatch.end_date;
  const eventName = reviewMode ? reviewBatchEventName : activeBatchName;
  const game_count = reviewMode ? reviewBatchInfo.game_count : paymentsSummary.game_count;
  const payment_total = reviewMode ? reviewBatchInfo.pay_batched : paymentsSummary.payment_total;
  const minTransfer =
    paymentsSummary &&
    paymentsSummary.transaction_constant &&
    paymentsSummary.transaction_constant.min_amount;
  const showSelectAllCheckbox = true;
  // eslint-disable-next-line no-param-reassign
  pagination.onDefaultClick = () => onDefaultPaginationClick(setSelectedAllGames);

  return (
    <Wrapper>
      <PaymentsContainer>
        <Header
          gamelevels={crewLabels}
          eventName={eventName}
          categories={categories}
          filter={filter}
          setSelectedGame={setSelectedGame}
          setAllGamesCheck={setAllGamesCheck}
          batchRange={{ start_date, end_date }}
          batchReviewDescription={`Batch #${reviewBatchId} ${reviewBatchDescription || ''}`}
          reviewMode={reviewMode}
          downloadBatchPayReport={downloadBatchPayReport}
          downloadFundingPayReport={downloadFundingPayReport}
          isEventAdmin={isEventAdmin}
          isPaymentsEventGroupAdmin={isPaymentsEventGroupAdmin}
          isSuperAdmin={isSuperAdmin}
          location={location}
          teams={teams}
          complexes={complexes}
        />
        <LoaderWrapper isFetching={isFetching}>
          <Subheader
            sort={sort}
            labels={[...POSITION_LABELS, { label: 'Assignor' }]}
            checkbox={showSelectAllCheckbox}
            handleSelectAll={handleSelectAll}
            theme={SUBHEADER_THEME}
            checked={selectedAllGames}
          />
          {!games.length && <EmptyMessage>No games available</EmptyMessage>}
          {games && !!games.length && (
            <>
              <GamesList
                games={games}
                handleCheckboxClick={handleCheckboxClick}
                pagination={pagination}
                crewLabels={[...crewLabels]}
                togglePanel={handleSetSelectedAssignment}
                selectedAssignment={selectedAssignment}
                selectedGame={selectedGame}
                setSelectedGame={setSelectedGame}
                setSelectedAssignment={setSelectedAssignment}
                allGamesCheck={allGamesCheck}
                setAllGamesCheck={setAllGamesCheck}
                reviewMode={reviewMode}
                requiredPaymentProfile={requiredPaymentProfile}
                minTransferAmount={minTransfer}
                report_settings={report_settings}
              />
              <SummaryBar
                handleBackToDateSelection={handleBackToDateSelection}
                totalGames={game_count}
                totalPay={payment_total}
                setBatchModalOpen={setBatchModalOpen}
                reviewMode={reviewMode}
                deleteActiveBatch={deleteActiveBatch}
                reviewBatchId={reviewBatchId}
                activeBatch={activeBatch}
                payment_initiated={payment_initiated}
                setAssignmentWarnings={setAssignmentWarnings}
                minTransferAmount={minTransfer}
              />
              <PaginationBar {...pagination} />
            </>
          )}
        </LoaderWrapper>
      </PaymentsContainer>
      {(selectedAssignment || isAssignmentFetching) && (
        <AdjustmentPanel
          assignment={selectedAssignment || undefined}
          game={
            selectedAssignment
              ? games.find(g => g.id === selectedAssignment.external_game_id)
              : undefined
          }
          onSubmit={handleAdjustmentSubmit}
          isAssignmentFetching={isAssignmentFetching}
          onOutsideClick={e => !hasClicked(e, 'games-list') && setSelectedAssignment(null)}
          setAssignmentWarnings={setAssignmentWarnings}
          report_settings={report_settings}
        />
      )}
      {selectedGame && (
        <AssignorPanel
          selectedGame={selectedGame}
          games={games}
          allGamesCheck={allGamesCheck}
          setAllGamesCheck={setAllGamesCheck}
          reload={pagination.onLoadPage}
          onOutsideClick={e => {
            if (!hasClicked(e, 'games-list')) {
              setSelectedGame(null);
              setAllGamesCheck(false);
            }
          }}
          setAssignmentWarnings={setAssignmentWarnings}
        />
      )}
      {batchModalOpen && (
        <PreviewBatch
          totalGames={game_count}
          totalPayments={payment_total}
          setBatchModalOpen={setBatchModalOpen}
          minTransferAmount={minTransfer}
        />
      )}
    </Wrapper>
  );
};

BatchEdit.propTypes = {
  clearParams: PropTypes.func,
  games: PropTypes.arrayOf(Object),
  handleCheckboxClick: PropTypes.func,
  gamelevels: PropTypes.arrayOf(Object),
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number,
    limit: PropTypes.number,
    pageLinkBuilder: PropTypes.func,
    onLoadPage: PropTypes.func
  }).isRequired,
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  handleSelectAll: PropTypes.func,
  crewLabels: PropTypes.arrayOf(Object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedAssignment: PropTypes.objectOf(Object),
  handleSetSelectedAssignment: PropTypes.func,
  handleAdjustmentSubmit: PropTypes.func,
  isAssignmentFetching: PropTypes.bool.isRequired,
  selectedGame: PropTypes.objectOf(Object),
  setSelectedGame: PropTypes.func,
  setSelectedAssignment: PropTypes.func,
  setAllGamesCheck: PropTypes.func,
  allGamesCheck: PropTypes.bool.isRequired,
  activeBatch: PropTypes.oneOfType([PropTypes.objectOf(Object), PropTypes.bool]).isRequired,
  handleBackToDateSelection: PropTypes.func,
  paymentsSummary: PropTypes.shape({
    game_count: PropTypes.number.isRequired,
    payment_total: PropTypes.number.isRequired
  }).isRequired,
  batchModalOpen: PropTypes.bool.isRequired,
  setBatchModalOpen: PropTypes.func,
  onLoadPageArgs: PropTypes.shape({
    reviewMode: PropTypes.bool.isRequired
  }).isRequired,
  reviewBatchInfo: PropTypes.objectOf(Object).isRequired
};

BatchEdit.defaultProps = {
  games: [],
  gamelevels: [],
  selectedAssignment: null,
  selectedGame: null,
  setBatchModalOpen: () => null,
  handleBackToDateSelection: () => null,
  setAllGamesCheck: () => null,
  setSelectedAssignment: () => null,
  setSelectedGame: () => null,
  handleAdjustmentSubmit: () => null,
  handleSetSelectedAssignment: () => null,
  handleSelectAll: () => null,
  handleCheckboxClick: () => null,
  clearParams: () => null
};

export default Enhancer(BatchEdit);
