import React from 'react';
import { IconButton } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CarretButton from '../../../../CarretButton';
import ExpandInfoPanel from '../ExpandInfoPanel';
import { PayoutText, RowsWrapper, SpanBlue } from '../styled-components';
import LoaderWrapper from '../../../../LoaderWrapper';
import ConfirmationPopup from '../../../../popupModals/ComfirmationPopup';

export default function CardInfoRow({
  Info,
  sourceName,
  deletePaymentSource,
  isFetching,
  writeAccess
}) {
  const [showInfo, setShowInfo] = React.useState(false);
  const [deleteConfimation, setDeleteConfimation] = React.useState(false);
  return (
    <LoaderWrapper isFetching={isFetching}>
      <div>
        <RowsWrapper>
          <Col xs={1}>
            {' '}
            <CarretButton
              opened={showInfo}
              onClick={() => setShowInfo(!showInfo)}
              nobg={showInfo}
            />
          </Col>
          <Col xs={10}>
            <Row middle="xs">
              <Col xs={1}>
                <SpanBlue>
                  <CreditCardIcon />
                </SpanBlue>
              </Col>
              <Col xs={10}>
                <Row>
                  <Col xs={12}>
                    Payment Method: <SpanBlue> Card</SpanBlue>
                  </Col>

                  <Col xs={12}>
                    {' '}
                    {`XXXX-XXXX-XXXX-`}
                    <SpanBlue>{Info.details.last4}</SpanBlue>
                  </Col>
                  <Col xs={12} style={{ float: 'right' }}>
                    <>
                      <PayoutText>
                        Exp:{' '}
                        <SpanBlue>
                          {' '}
                          {Info.details.exp_month}/{Info.details.exp_year}
                        </SpanBlue>
                      </PayoutText>
                    </>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {writeAccess && (
            <Col xs={1}>
              <Row middle="xs" around="xs">
                <Col>
                  <IconButton>
                    <DeleteIcon onClick={() => setDeleteConfimation(true)} />
                  </IconButton>
                </Col>
              </Row>
            </Col>
          )}
        </RowsWrapper>
        {showInfo && (
          <ExpandInfoPanel
            expanded={showInfo}
            onExpand={showInfo}
            ViewName={sourceName}
            lastDigits={Info.details.last4}
            expireMonth={Info.details.exp_month}
            expireYear={Info.details.exp_year}
            brand={Info.details.brand}
          />
        )}
        {deleteConfimation && (
          <ConfirmationPopup
            title="Delete Payment Method"
            description="Are you sure you want to delete this Payment Method?"
            SubmitBtnText="YES"
            confirmationPopupAction={deleteConfimation}
            setConfirmationPopupAction={setDeleteConfimation}
            primaryActionFun={deletePaymentSource}
            sourceId={Info.id}
            type="card"
          />
        )}
        <br />
      </div>
    </LoaderWrapper>
  );
}
