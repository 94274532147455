import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import NotificationCollapsible from './NotificationCollapsible';
import AcceptDeclineCollapsible from './AcceptDeclineCollapsible';
import OfficalsSelfAssignCollapsible from './OfficalsSelfAssignCollapsible';
import Button from '../../Button';
import { Container, Header, Title, Content, ButtonContainer } from './styled-components';
import { ActualContentContainer } from '../styled-components';

const AssignorSettings = ({ onSubmit, getEventInfo, getUser }) => {
  const restrict_access = !(
    getUser &&
    getEventInfo &&
    getUser.admin_events.indexOf(parseInt(getEventInfo.id, 10)) > -1
  );
  const initialValues = {
    officials_setting: { ...getEventInfo.officials_setting },
    assessors_setting: { ...getEventInfo.assessors_setting },
    assignors_setting: { ...getEventInfo.assignors_setting },
    admins_setting: { ...getEventInfo.admins_setting },
    require_games_accept_setting: { ...getEventInfo.require_games_accept_setting },
    disable_games_decline_setting: { ...getEventInfo.disable_games_decline_setting },
    official_self_assign_setting: { ...getEventInfo.official_self_assign_setting }
  };

  return (
    <ActualContentContainer>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <Header>
                <Title>Assignor Settings</Title>
              </Header>
              <Content>
                <NotificationCollapsible disabled={restrict_access} />
                <AcceptDeclineCollapsible disabled={restrict_access} />
                <OfficalsSelfAssignCollapsible disabled={restrict_access} />
              </Content>
              <ButtonContainer>
                <Button
                  label="Update"
                  primary
                  type="submit"
                  disabled={restrict_access || pristine || submitting || invalid}
                />
                {/* <Button type="button" label="Cancel" onClick={reset} name="label" /> */}
              </ButtonContainer>
            </Container>
          </form>
        )}
      />
    </ActualContentContainer>
  );
};

AssignorSettings.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

AssignorSettings.defaultProps = {};

export default AssignorSettings;
