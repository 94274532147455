import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';
import { pxToRem } from '../../../../utils/helpers';
import { COLORS, mediaQueries } from '../../../../utils/styles';
import Icon from '../../Icon';

export const AssignmentInfoRow = styled(Row)``;

export const InfoRow = styled(Row)`
  padding: ${({ padding }) => padding || '20px 10% 0px 10%'};
  .pd {
    padding: 0px 0px 0px 6px;
    width: 29px;
  }

  ${mediaQueries.lg(` {
  padding:20px 10% 20px 9.5%;
}`)}

  ${mediaQueries.md(` {
  padding:20px 25px 20px 60px;
  .pd {
    padding:0px 0px 0px 0px;
    width:25px;
  }
}`)}
`;

export const PositionRow = styled(Row)`
  padding: ${({ padding }) => padding || '20px 10% 0px 10%'};
  .pd {
    padding: 0px 0px 0px 6px;
    width: 29px;
  }

  ${mediaQueries.lg(` {
  padding:0px 1% 0px 0%;
}`)}
`;

export const AssignmentInfoCol = styled(Col)`
  width: 33%;
  border-right: 1px solid #e5ebf2;
  display: flex;
  justify-content: start;
  flex-direction: column;
  &:nth-child(3) {
    border-right: 0px;
  }
  padding-bottom: 15px;
  ${mediaQueries.lg(` {
    width: 100%;
    border-right:0px;
    border-bottom:1px solid #E5EBF2;
    padding-bottom:20px;
    &:nth-child(3) {border-bottom:0px;}
  }`)}
`;

export const AssignmentRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-grow: 0;
  flex-direction:column;
  width: 100%;
  border-bottom: 1px solid ${COLORS.blueGray};
  background:#F3F6F9;
  &:last-child {
    border-bottom: 2px solid ${COLORS.denimBlue};
  }
  .desktophr{
    width: 90%;
    border-bottom-style: dashed;
    border-block-color: #ccc;
    color: #fff;
    border-top: none;
    border-width: 3px;
    overflow: hidden;
    box-sizing: border-box;
    margin: 20px auto;
  }

.actionButton {
  align-self: center;
  display:flex;
}
.tabhr , .mobilehr{display:none;}
${mediaQueries.md(`
padding: 0px;
.tabhr{ width: calc(100vw - 16px - 9px);
display:block;
  border-block-color: #ccc;
  color: #fff;
  border-top: none;
  border-width: 2px;
  box-sizing: border-box;
  margin:15px auto;
}
`)}
${mediaQueries.sm(` {
.actionButton {
    justify-content:start;
}
}`)};
${mediaQueries.xs(` {
  .tabhr{display:none;}
.mobilehr, .desktophr{width: calc(100vw - 16px - 9px);
  display:block;
    border-block-color: #ccc;
    color: #fff;
    border-top: none;
    border-width: 2px;
    box-sizing: border-box;
    margin:20px auto;
    border-bottom-style: solid;
}
}`)};

}
`;

export const Time = styled.span`
  margin-bottom: 2px;
  display: flex;
  width: 100%;
  font-family: 'Barlow Semi Condensed';
  color: #3d4956;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  ${mediaQueries.sm(` {
    font-size: 16px;
    letter-spacing: 1px;
    }`)};
`;

export const GameStatus = styled.span`
  background: ${COLORS.mustardYellow};
  color: ${COLORS.white};
  font-family: 'Barlow';
  font-weight: 600;
  font-size: ${pxToRem('12px')};
  margin: 0;
  padding: 0px 6px 3px 6px;
  margin: 0px 5px;
  text-transform: capitalize;
  height: 15px;
`;

export const GameName = styled.p`
  margin: 0 0 ${pxToRem('2px')};
  color: ${({ greyColor }) => (greyColor ? `#989898` : `COLORS.black`)};
  font-family: 'Barlow';
  font-weight: ${({ fontBold }) => (fontBold ? `bold` : `400`)};
  text-transform: capitalize;
  text-align: left;
  word-break: break-word;
`;

export const GamePosition = styled(GameName)`
  display: flex;
  align-item: center;
  background-color: ${({ background }) =>
    background ? `rgba(255, 187, 56, 0.15)` : `transparent`};
  padding: 0px 8px 2px 3px;
  word-break: normal;
  width: ${({ mincontent }) => (mincontent ? `fit-content` : `100%`)};
`;

export const GameId = styled.span`
  color: #989898;
  word-break: break-word;

  ${props =>
    mediaQueries.sm(`
    font-size:${props.fontSize ? `14px` : `16px`};
`)}
`;

export const GamePositionStatus = styled(GameId)`
  display: flex;
  align-items: baseline !important;
  margin-right: ${pxToRem('7px')};
  padding-left: 2px !important;
  ${mediaQueries.md(`{
  margin-right:${pxToRem('0px')};
}`)};
`;

export const Info = styled.div`
  font-family: 'Barlow';
  text-align: left;
  width: 100%;
  .flexNoWrap {
    flex-wrap: nowrap;
    display: flex;
    align-items: baseline;
    width: 100%;
    min-height: 40px;
  }
  .assignor {
    padding-top: 6px;
    align-items: start;
  }
  .paddingZero {
    padding-left: 7px !important;
  }
  .mobilePaddingZero {
    padding: 0px;
  }

  ${mediaQueries.xs(`{
  .assigner{
    padding-top:3px;
  }

}`)}
`;

export const FieldInfo = styled.p`
  color: ${COLORS.newDarkBlue};
  font-family: 'Barlow Semi Condensed';
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  text-transform: capitalize;
  margin: 0px;
  & a {
    padding: ${pxToRem('5px')} ${pxToRem('10px')};
  }
  .whiteSpace {
    white-space: nowrap;
  }
  ${mediaQueries.sm(` {
    font-size: 16px;
    letter-spacing: 1px;
    }`)};
`;

export const StatusIcon = styled(Icon)`
  margin: 0;
  position: relative;
  top: ${pxToRem('-3px')};
  right: ${pxToRem('0px')};
`;

export const StatusIconContainer = styled.div`
  margin-top: ${pxToRem('3px')};
  margin-bottom: ${pxToRem('4px')};
  width: ${pxToRem('17px')};
  height: ${pxToRem('16px')};
  overflow: visible;
  border-radius: 50%;
  padding-right: ${pxToRem('10px')};
`;

export const GamePositionStatusIcon = styled(StatusIconContainer)`
  margin: 1px 0px 0px 0px;
  padding-right: ${pxToRem('10px')};
`;

export const StyledButton = styled.button`
  margin: ${({ margin }) => margin || '0px 10px 0px 50px'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || `140px`};
  height: 40px;
  background: ${COLORS.white};
  box-shadow: 1px 2px 1px #00000029;
  border: 1px solid #C3CCDA;
  font-weight: 600;
  font-family:'Barlow';
  border-radius: 6px
  color: ${({ label }) => (label === 'Accept' ? COLORS.successGreen : `#BF2827`)};
  font-size:${pxToRem('14px')};
  & span {
    padding: 0px 5px;
  }

  &:hover {
    border: ${({ label }) => (label === 'Accept' ? `1px solid #4E9F31` : `1px solid #BF2827`)};
  }
  &:disabled {
    opacity: 0.4;
    border: 0px;
    pointer-events: none;
  }
  &:active {
    border: ${({ label }) => (label === 'Accept' ? `1px solid #4E9F31` : `1px solid #BF2827`)};
    background: ${({ label }) =>
      label === 'Accept' ? `rgba(78,159,49,0.3)` : `rgba(191,40,39,0.3)`};
  }

  ${props =>
    mediaQueries.lg(`
    margin:${props.margin ? props.margin : `0px 10px 0px 0px`};
`)}

`;

export const ReportLink = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${COLORS.lightBlue};
  margin-right: 10px;
  font-size: 14px;
  font-family: 'Barlow Semi Condensed';
`;

export const SubInfo = styled.span`
  color: ${({ color }) => (color ? `${color}` : `${COLORS.greyish}`)};
  font-weight: ${({ fontWeight }) => fontWeight || `500`};
  white-space: ${({ whiteSpace }) => (whiteSpace ? 'nowrap' : 'none')};
  padding: ${({ padding }) => padding || '0px'}
  font-size: 14px;
  font-family: 'Barlow';
  .link {
    color: ${COLORS.lightBlue};
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
  }
  ${mediaQueries.md(`
  font-size:16px;
`)}
  ${props =>
    mediaQueries.xs(`
    margin: ${props.marginLeft ? props.marginLeft : '0px'} ;
    text-align:${props.textAlign ? props.textAlign : ''};
    font-size:16px;
`)}
`;

export const Uppercase = styled.span`
  text-transform: uppercase !important;
  word-break: ${({ wordbreak }) => (wordbreak ? `break-word` : `normal`)};
  display: inline-block;
  padding: 0px !important;
`;

export const ReportTab = styled.div`
  display: flex;
`;

export const CrewName = styled.div`
  padding: 0px 10px;
  margin-left: -10px;
  border-radius: 4px;
  display: flex;
  background: ${({ userActive }) =>
    userActive ? `rgba(255, 187, 56, 0.15)` : `linear-gradient(#FFFFFF, #F2F5F7)`};
  padding-left: 97px;
  align-items: center;
  height: 40px;
  width: 100%;
  ${mediaQueries.md(`
  padding: 0px 0px 0px 70px;
  `)};
`;

export const UserName = styled.small`
  color: ${({ userActive }) => (userActive ? `${COLORS.darkGrey}` : `${COLORS.lightBlue}`)};
  font-size: 14px !important;
  text-transform: capitalize;
  word-break: break-word;
  padding-left: 2px;
  font-weight: 500;
  font-family: 'Barlow';
  ${mediaQueries.sm(`
  font-size: 16px !important;
  `)};
`;

export const OpenPositions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0px 0px 0px 60px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Barlow';
  & .lightBlue {
    color: ${COLORS.lightBlue};
  }
  ${mediaQueries.md(`
  padding: 0px 0px 0px 35px;
  `)};
  ${mediaQueries.sm(`
  font-size: 16px;
  `)};
`;

export const Block = styled(Col)`
  position: relative;
  margin: ${pxToRem('15px')} auto 0;
  padding: 0 ${pxToRem('20px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.shuttleGray};
  line-height: 1.31;

  &.last {
    margin-bottom: ${pxToRem('15px')};
  }
`;

export const Content = styled.div`
  ${({ light }) => (light ? 'font-family: RobotoLight' : '')};
  ${({ first }) => (first ? `margin-bottom: ${pxToRem('15px')}` : '')};
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`;
