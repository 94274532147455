import styled from 'styled-components';

import { pxToRem } from '../../../utils/helpers';
import { COLORS } from '../../../utils/styles';

export const Content = styled.div`
  background-color: ${COLORS.lightCyanGray};
  padding: ${pxToRem('20px')} 6%;
  width: 100%;
  box-sizing: border-box;
`;

export const Paper = styled.div`
  padding: ${pxToRem('40px')} 4% ${pxToRem('30px')};
  background-color: ${COLORS.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
`;

export const Wrapper = styled.div`
  width: 100%;
`;
