import { compose, pure, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { path, filter } from 'ramda';

import { getEventCategories } from '../../../../core/Events/selectors';
import {
  deleteCategory as deleteCategoryAction,
  updateCategories as updateCategoriesAction,
  fetchEventInfoCategories as fetchCategoriesAction
} from '../../../../core/Events';
import { fetchEventUniqueTeams as fetchUniqueTeamsAction } from '../../../../core/Teams';
import { getTeams } from '../../../../core/Teams/selectors';
import { fetchEventComplexs as fetchComplexsAction } from '../../../../core/Locations';
import { getLocations } from '../../../../core/Locations/selectors';

const mapStateToProps = state => ({
  categories: getEventCategories(state),
  isFetching: state.events.eventPageFetching,
  teams: getTeams(state),
  locations: getLocations(state)
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch(fetchCategoriesAction(payload.eventId)) &&
    dispatch(fetchUniqueTeamsAction(payload)) &&
    dispatch(fetchComplexsAction(payload)),
  deleteCategory: payload => dispatch(deleteCategoryAction(payload)),
  updateCategories: payload => dispatch(updateCategoriesAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('showModal', 'setModalVisibility', false),
  withState('currentItem', 'setItem', {}),
  withHandlers({
    onDelete: ({
      currentItem: { index, remove },
      setItem,
      categories,
      setModalVisibility,
      deleteCategory
    }) => () => {
      const id = path(['id'], categories[index]);
      setItem({});
      setModalVisibility(false);
      remove(index);
      if (id) {
        deleteCategory({ id });
      }
    },
    onRemove: ({ setModalVisibility, setItem }) => ({ id, index, remove }) => () => {
      setItem({ id, index, remove });
      setModalVisibility(true);
    },
    onSubmit: ({ updateCategories }) => ({ categories }) => {
      const newCategories = filter(({ id }) => !id)(categories);
      const oldCategories = filter(({ id }) => id)(categories);
      updateCategories({ newCategories, oldCategories });
    }
  }),
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { eventId }
        },
        onLoad
      } = this.props;
      onLoad({ eventId });
    }
  }),
  pure
);
