import { compose, pure, lifecycle, withState, branch, renderComponent, nest } from 'recompose';
import { connect } from 'react-redux';
import { path, pipe, ifElse, isEmpty } from 'ramda';
import 'url-search-params-polyfill';

import { login as loginAction } from '../../../core/Auth';
import Circle from '../../components/Circle';
import { Wrapper } from '../../components/Circle/styled-components';

const Animation = nest(Wrapper, Circle);

const getURLSearchParams = pipe(
  path(['search']),
  ifElse(
    isEmpty,
    () => false,
    pipe(
      search => new URLSearchParams(search),
      search => ({
        accessToken: search.get('access_token'),
        idToken: search.get('id_token'),
        refreshToken: search.get('refresh_token')
      })
    )
  )
);

export default compose(
  connect(() => ({}), { login: loginAction }),
  withState('submitting', 'setSubmit', false),
  lifecycle({
    componentDidMount() {
      const { location, login } = this.props;
      const payload = getURLSearchParams(location);
      if (payload) {
        this.props.setSubmit(true); // TODO remove flag if possible

        login(payload);
      }
    }
  }),
  branch(({ submitting }) => submitting, renderComponent(Animation)),
  pure
);
