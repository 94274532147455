import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import CertificationUpdateEnhancer from '../../containers/CertificationDetailUpdate';
import CertificationForm from './Certification';
import PersonalForm from './Personal';
import Availability from './Availability';
import SideMenu, { routesToMenuItems } from '../SideMenu';
import PersonalEnhancer from '../../containers/ProfileInformation';
import {
  MainContainer,
  ProfileTitleWrapper,
  ProfileName,
  ColumnedContent,
  ActualContentContainer,
  Name
} from './styled-components';
import { userIdxGetter } from '../../../utils/auth';
import Enhancer from '../../containers/Profile';
import DropdownMenu from '../DropdownMenu';
import Button from '../Button';
import { COLORS, FONTS } from '../../../utils/styles';
import { capitalize } from '../../../utils/helpers';

export const CertificationPage = CertificationUpdateEnhancer(CertificationForm);
export const PersonalPage = PersonalEnhancer(PersonalForm);
export const AvailabilityPage = Availability;

export const Profile = ({
  AuthorizedRoutes,
  routes,
  currentUserId,
  userId,
  paramId,
  redirectPath,
  firstName,
  lastName
}) => (
  <MainContainer>
    <SideMenu
      newMenu
      currentUserId={currentUserId}
      userId={userId}
      items={routesToMenuItems({
        routes: AuthorizedRoutes,
        userIdx: userIdxGetter(),
        userId: paramId
      })}
      displayView="desktop"
    />
    {routes.map(({ path, component: Component, title, noTitle }) => (
      <Route
        key={path}
        path={path}
        render={props => (
          <ColumnedContent>
            {!noTitle && (
              <ProfileTitleWrapper title={title}>
                {currentUserId && userId && currentUserId !== userId ? (
                  <ProfileName>
                    {title}{' '}
                    <Name>
                      {capitalize(firstName)} {capitalize(lastName)}
                    </Name>
                  </ProfileName>
                ) : (
                  <ProfileName>{title}</ProfileName>
                )}
                {currentUserId && userId && currentUserId !== userId && (
                  <Link to={redirectPath || ''} className="exitViewButton">
                    <Button
                      width="150px"
                      customStyle={{
                        color: COLORS.black,
                        backgroundColor: COLORS.lightOrange,
                        fontFamily: FONTS.bold
                      }}
                      label="Exit this view"
                    />
                  </Link>
                )}
                <ProfileName>
                  {' '}
                  <DropdownMenu
                    newMenu
                    items={routesToMenuItems({
                      routes: AuthorizedRoutes,
                      userIdx: userIdxGetter(),
                      userId: paramId
                    })}
                    subHeadingView="desktop"
                  />{' '}
                </ProfileName>
              </ProfileTitleWrapper>
            )}
            <ActualContentContainer>
              <Component {...props} />
            </ActualContentContainer>
          </ColumnedContent>
        )}
      />
    ))}
  </MainContainer>
);

Profile.propTypes = {
  AuthorizedRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      pathname: PropTypes.string,
      exact: PropTypes.bool,
      title: PropTypes.string
    })
  ),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      pathname: PropTypes.string,
      exact: PropTypes.bool,
      title: PropTypes.string
    })
  )
};

Profile.defaultProps = {
  AuthorizedRoutes: [],
  routes: []
};

export default Enhancer(Profile);
