import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalContainer,
  SuccessRow,
  BatchRow,
  BatchAmount,
  IconSuccess
} from '../styled-components';
import { Dialog } from '../../../../global-material-components';
import { numToCurrency } from '../../../../../utils/parsers';

const FundingResultDialog = ({
  DialogOpen,
  setDialogOpen,
  setCurrentBatch,
  currentBatch,
  title,
  batchPaymentFee,
  textColor,
  icon,
  description
}) => (
  <Dialog
    title={title}
    onClose={() => {
      setDialogOpen(false);
      setCurrentBatch(null);
    }}
    open={DialogOpen}
    cancelAction={() => setDialogOpen(false)}
    primaryAction={() => setDialogOpen(false)}
    noCancel
    noPrimary
    noDivider
  >
    <ModalContainer>
      {currentBatch ? (
        <SuccessRow>
          <IconSuccess width={54} height={54} icon={icon} color={textColor} />
          <BatchRow>{`Batch #${currentBatch.id} ${description}`}</BatchRow>
          <BatchAmount>{`${numToCurrency(
            currentBatch.pay_batched + (batchPaymentFee && batchPaymentFee.totalFees)
          )}`}</BatchAmount>
        </SuccessRow>
      ) : null}
    </ModalContainer>
  </Dialog>
);

FundingResultDialog.propTypes = {
  setDialogOpen: PropTypes.func.isRequired,
  setCurrentBatch: PropTypes.func.isRequired,
  currentBatch: PropTypes.object.isRequired,
  DialogOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default FundingResultDialog;
