import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Icon from '../../Icon';
import { COLORS, ICONS } from '../../../../utils/styles';
import {
  ALL_EVENTS,
  CLOSED_EVENTS,
  OPEN_EVENTS,
  OWN_EVENTS
} from '../../../../core/Events/eventConstants';
import {
  EventNavigationBarInnerContainer,
  EventNavigationItemContainer,
  EventNavigationItem,
  EventActionItemContainer,
  EventActionItem,
  OpenEventIndicator,
  ClosedEventIndicator
} from './styled-components';
import { generateRefreshQuery } from '../../../../utils/helpers';

const navigationItems = [
  { label: 'My Events', value: OWN_EVENTS },
  { label: 'All Open Events', value: OPEN_EVENTS },
  { label: 'All Closed Events', value: CLOSED_EVENTS },
  { label: 'All Events', value: ALL_EVENTS }
];

const buildNavigationBar = (selectedNavigationItem, onClick) =>
  navigationItems.map(navigationItem => (
    <EventNavigationItem
      key={navigationItem.value}
      className={navigationItem.value === selectedNavigationItem ? 'active' : null}
      onClick={() => onClick(navigationItem.value)}
    >
      {navigationItem.label === 'All Open Events' && <OpenEventIndicator />}
      {navigationItem.label === 'All Closed Events' && <ClosedEventIndicator />}
      {navigationItem.label}
    </EventNavigationItem>
  ));

const EventNavigationBar = ({
  selectedNavigationItem,
  onClick,
  toggleNewEventModal,
  isGroupAdmin,
  isSuperAdmin,
  requestEventListCSV,
  location
}) => (
  <EventNavigationBarInnerContainer>
    <EventNavigationItemContainer>
      {buildNavigationBar(selectedNavigationItem, onClick)}
    </EventNavigationItemContainer>

    <EventActionItemContainer>
      {(isGroupAdmin || isSuperAdmin) && (
        <EventActionItem onClick={toggleNewEventModal}>
          <Icon height={20} width={20} icon={ICONS.NEW} color={COLORS.white} />
          New Event
        </EventActionItem>
      )}
      <EventActionItem>
        <NavLink to={`${location.pathname}${generateRefreshQuery(location.search)}`}>
          <Icon height={18} width={18} icon={ICONS.REFRESH} color={COLORS.white} />
        </NavLink>
      </EventActionItem>
      <EventActionItem onClick={() => requestEventListCSV()}>
        <Icon height={20} width={20} icon={ICONS.DOWNLOAD} color={COLORS.white} />
      </EventActionItem>
    </EventActionItemContainer>
  </EventNavigationBarInnerContainer>
);

EventNavigationBar.propTypes = {
  selectedNavigationItem: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isGroupAdmin: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  toggleNewEventModal: PropTypes.func.isRequired,
  requestEventListCSV: PropTypes.func.isRequired
};

EventNavigationBar.defaultProps = {
  selectedNavigationItem: ''
};

export default EventNavigationBar;
