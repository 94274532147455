import moment from 'moment';
import options from '../constants';
import { generateUUID } from '../../../../../../utils/helpers';

const TEAM_ROSTER = {
  byPlayer: {
    name: '',
    uniform: '',
    roster: '',
    present: false,
    suspended: false,
    total_goals: 0,
    is_caution: false,
    caution: '',
    caution_minute: null,
    is_subbed_in: false,
    subbed_in_at_minute: null,
    is_subbed_out: false,
    subbed_out_at_minute: null,
    is_send_off: false,
    send_off: '',
    send_off_minute: null
  },
  byTeam: {
    name: '',
    uniform: '',
    roster: '',
    present: false,
    suspended: false
  }
};

const TEAM_STAFF = {
  soccer: {
    byTeam: {
      name: '',
      present: false
    },
    byPlayer: {
      name: '',
      present: false,
      coach_dismissed: false,
      is_coach_dismissed_at_minute: null
    }
  },
  nonSoccer: {
    byPlayer: {
      name: '',
      present: false
    },
    byTeam: {
      name: '',
      present: false
    }
  }
};

const PLAYER_GOAL = {
  type: options.goalDropdownOptions[0],
  minute: ''
};

export const fetchIsLocked = (gameEndDate, gameEndTime, lockDays) =>
  lockDays &&
  moment.utc().toDate() >
    moment.utc(`${gameEndDate} ${gameEndTime}`).add(lockDays, 'days').toDate();

export const mutatorGoals = (args, state, utils) => {
  const diff = args[0];
  const roster = args[1];
  const index = args[2];

  utils.changeValue(state, `${roster}[${index}].goals`, goals => {
    if (diff < 0) {
      goals.splice(goals.length - Math.abs(diff), Math.abs(diff));
      return goals;
    }
    return goals.concat(new Array(diff).fill({ ...PLAYER_GOAL, uid: generateUUID() }));
  });
};

export const getTeamStaffTemplate = (byPlayer, isSoccer) => {
  const templates = isSoccer ? TEAM_STAFF.soccer : TEAM_STAFF.nonSoccer;
  const template = byPlayer ? templates.byPlayer : templates.byTeam;
  return { ...template };
};

export const getTeamRosterTemplate = byPlayer => {
  const template = byPlayer ? TEAM_ROSTER.byPlayer : TEAM_ROSTER.byTeam;
  return { ...template };
};
