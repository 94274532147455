export default {
  options: [
    {
      name: 'roles',
      label: 'Role, Name, Age'
    },
    {
      name: 'names',
      label: 'Name, City, Zip'
    }
  ]
};
