import { GAMES_STATUSES } from '../../../../constants';
import { ASSIGNMENT_STATUS } from '../../../../core/Games/constants';

export const getGameById = (gameLevels, games) =>
  gameLevels.find(d => d.id === games.game_level.id);

export const categoryFilter = (categories, games, index) => {
  if (games[index]) {
    const matchTeamHome = `${games[index].team_home && games[index].team_home.id}`;
    const matchTeamVisitor = `${games[index].team_visitor && games[index].team_visitor.id}`;

    return categories.filter(category => {
      const categoryArr = [];
      if (category && category.states) {
        categoryArr.push(
          category.states.includes(
            games[index].location &&
              games[index].location.state &&
              games[index].location.state.toUpperCase()
          )
        );
      }
      if (category && category.postal_codes) {
        categoryArr.push(
          category.postal_codes.includes(
            games[index].location &&
              games[index].location.postal_code &&
              games[index].location.postal_code.toUpperCase()
          )
        );
      }
      if (category && category.locations) {
        categoryArr.push(
          category.locations.includes(games[index].location && games[index].location.id)
        );
      }
      if (category && category.teams) {
        categoryArr.push(
          category.teams.includes(matchTeamHome) || category.teams.includes(matchTeamVisitor)
        );
      }

      if (!categoryArr.length) {
        return false;
      }
      if (categoryArr.every(e => e)) {
        return true;
      }
      return false;
    });
  }
  return [];
};

export const getAssignedUsers = (users, game) =>
  users
    .filter(
      u =>
        game.assignments_game &&
        game.assignments_game.length &&
        game.assignments_game.map(a => a.event_role && a.event_role.user_id).includes(Number(u.id))
    )
    .map(u => {
      const allAssignments =
        u.event_roles &&
        u.event_roles.length &&
        u.event_roles.reduce((assignments, role) => {
          if (role.game_assignments && role.game_assignments.length) {
            const filteredAssignments = role.game_assignments
              .filter(
                ga =>
                  [
                    ASSIGNMENT_STATUS.accepted,
                    ASSIGNMENT_STATUS.pending,
                    ASSIGNMENT_STATUS.unpublished
                  ].includes(ga.status) &&
                  ![GAMES_STATUSES.POSTPONED, GAMES_STATUSES.CANCELLED].includes(
                    ga.game && ga.game.status
                  )
              )
              .map(ga => ({ ...ga, event_id: role.event_id }));

            assignments.push(...filteredAssignments);
          }

          return assignments;
        }, []);

      return {
        id: u.id,
        first_name: u.first_name,
        last_name: u.last_name,
        email: u.email,
        assignments: allAssignments
      };
    });
