/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { FormHelperText, makeStyles } from '@material-ui/core';
import COLORS from '../../../utils/styles';

const useStyles = makeStyles(() => ({
  popupIndicator: {
    background: `rgb(0,0,0,0) !important`,
    color: COLORS.denimBlue,
    '&:hover': {
      background: `rgb(0,0,0,0)`
    }
  },
  inputRoot: {
    marginTop: '0px',
    '& .MuiInput-underline.Mui-focused:not(.Mui-error):after': {
      borderBottom: `1px solid ${COLORS.denimBlue}`
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #8b9198'
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottom: '1px solid #c61e1e'
    },
    '& .MuiInputBase-input': {
      fontSize: 12,
      color: COLORS.denimBlue,
      paddingLeft: '8px !important'
    }
  },
  errorRoot: {
    paddingLeft: '10px',
    fontSize: '10px',
    color: '#e74c3c'
  },
  popper: {
    zIndex: 9999
  },
  listbox: {
    maxHeight: '120px',
    minWidth: 'auto !important',
    maxWidth: '800px'
  },
  option: {
    fontWeight: 200,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: '12px',
    borderBottom: '1px solid #ccc',
    '&:hover': {
      background: COLORS.regalBlue,
      color: COLORS.white
    },
    '&[data-focus="true"]': {
      background: COLORS.regalBlue,
      color: COLORS.white
    }
  }
}));

const MaterialUiTypehead = ({ placeholder, input, options, meta: { error } }) => {
  const inputValue = options && options.filter(val => val.value === (input && input.value))[0];
  const classes = useStyles();
  const defaultProps = {
    options,
    getOptionLabel: option => option.name || '',
    defaultValue: inputValue || '',
    name: input.name
  };
  return (
    <div>
      <Autocomplete
        {...defaultProps}
        popupIcon={<ExpandMoreRoundedIcon />}
        classes={{
          popupIndicator: classes.popupIndicator,
          popper: classes.popper,
          listbox: classes.listbox,
          option: classes.option
        }}
        error={error}
        disableClearable
        onChange={(event, option) => input.onChange(option)}
        renderInput={params => (
          <TextField
            {...params}
            label={placeholder}
            classes={{ root: classes.inputRoot }}
            error={error}
            InputLabelProps={{ style: { fontSize: 12, paddingLeft: 10, color: COLORS.black } }}
          />
        )}
      />
      {error && <FormHelperText classes={{ root: classes.errorRoot }}>{error}</FormHelperText>}
    </div>
  );
};

export default MaterialUiTypehead;
