import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '../../Button';
import Paper from '../../../layouts/Paper';
import {
  gameReportsConfig,
  incidentReportsConfig,
  crewVerificationConfig,
  combinedMutators
} from './config';
import { FormContainer, TitleBar, SectionContainer } from '../InformationForm/styled-components';
import { ButtonContainer } from './styled-components';
import { ActualContentContainer } from '../styled-components';
import Enhancer from '../../../containers/Event/Reports';
import TwoColumnContainer from '../../../layouts/FieldSpreadLayoutContainer';
import LoaderWrapper from '../../LoaderWrapper';

const DEFAULT_REPORT_SETTINGS = {
  game_upload_type: null,
  link_position_1: null,
  link_position_2: null,
  link_position_3: null,
  link_position_4: null,
  link_position_5: null,
  link_position_6: null,
  link_position_7: null,
  link_position_8: null,
  checkin_position_1: null,
  checkin_position_2: null,
  checkin_position_3: null,
  checkin_position_4: null,
  checkin_position_5: null,
  checkin_position_6: null,
  checkin_position_7: null,
  checkin_position_8: null,
  lock_after: null,
  is_score_entry_option: null,
  score_entry_option: 'Team Score Only',
  team_staff_section: null,
  roster_section: null,
  image_attachments: null,
  game_summary_notes: null,
  mark_forfeit: null,
  default_score_non_forfeit: '0',
  default_score_forfeit: '0',
  score_entry_stats: 'hidden',
  auto_approve_incident: null,
  official_to_official_viewability: null
};

const updateDisabled = ({ pristine, invalid, submitErrors }) =>
  pristine || (invalid && !submitErrors);

const GameReportsSection = gameUploadType => (
  <SectionContainer>
    <TitleBar>Game Reports</TitleBar>
    <TwoColumnContainer
      columns={2}
      fields={gameReportsConfig.map(({ Component, ...props }) => (
        <Component {...props} {...gameUploadType} />
      ))}
    />
  </SectionContainer>
);

const IncidentReportsSection = () => (
  <SectionContainer>
    <TitleBar>Incident Reports</TitleBar>
    <TwoColumnContainer
      columns={2}
      fields={incidentReportsConfig.map(({ Component, ...props }) => (
        <Component {...props} />
      ))}
    />
  </SectionContainer>
);

const CrewVerificationSection = () => (
  <SectionContainer>
    <TitleBar>Crew Check-In</TitleBar>
    <TwoColumnContainer
      columns={2}
      fields={crewVerificationConfig.map(({ Component, ...props }) => (
        <Component {...props} />
      ))}
    />
  </SectionContainer>
);

const Reports = ({ report_settings, onSubmit, isFetching }) => (
  <LoaderWrapper isFetching={isFetching}>
    <ActualContentContainer>
      <Form
        onSubmit={onSubmit}
        initialValues={{ ...DEFAULT_REPORT_SETTINGS, ...(report_settings || {}) }}
        mutators={combinedMutators}
        render={({ form: { mutators }, handleSubmit, reset, ...rest }) => (
          <form onSubmit={handleSubmit}>
            <Paper>
              <FormContainer>
                <CrewVerificationSection />
                <GameReportsSection
                  gameUploadType={report_settings.game_upload_type}
                  mutators={mutators}
                />
                <IncidentReportsSection />
              </FormContainer>
              <ButtonContainer>
                <TwoColumnContainer
                  fields={[
                    <Button label="Update" primary type="submit" disabled={updateDisabled(rest)} />,
                    <Button type="button" label="Cancel" onClick={reset} name="label" />
                  ]}
                  columns={2}
                />
              </ButtonContainer>
            </Paper>
          </form>
        )}
      />
    </ActualContentContainer>
  </LoaderWrapper>
);

Reports.propTypes = {
  report_settings: PropTypes.shape({
    game_upload_type: PropTypes.number,
    link_position_1: PropTypes.bool,
    link_position_2: PropTypes.bool,
    link_position_3: PropTypes.bool,
    link_position_4: PropTypes.bool,
    link_position_5: PropTypes.bool,
    link_position_6: PropTypes.bool,
    link_position_7: PropTypes.bool,
    link_position_8: PropTypes.bool,
    lock_after: PropTypes.number,
    is_score_entry_option: PropTypes.bool,
    score_entry_option: PropTypes.string,
    team_staff_section: PropTypes.bool,
    roster_section: PropTypes.bool,
    image_attachments: PropTypes.bool,
    game_summary_notes: PropTypes.bool,
    mark_forfeit: PropTypes.bool,
    default_score_non_forfeit: PropTypes.number,
    default_score_forfeit: PropTypes.number,
    score_entry_stats: PropTypes.string,
    auto_approve_incident: PropTypes.bool,
    official_to_official_viewability: PropTypes.bool
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Enhancer(Reports);
