import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import IntegerInput from '../../../../global-material-components/Inputs/integerInput';
import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  RankBlock,
  Text,
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  InputContainer
} from './styled-components';
import Enhancer from '../../../../containers/ImportModal/AddRankModal';
import { InfoIconBlue } from '../../../Event/CrewLabels/styled-components';
import MouseOverPopover from '../../../../global-material-components/Popover';

const ImportModal = ({
  onClose,
  selected,
  onSubmit,
  rank,
  setRank,
  peopleTab,
  pagination,
  onLoadPageArgs,
  allSelect,
  config,
  role_ids
}) => (
  <Modal title="Add/Edit Rank" onClose={onClose} rightPanel background userList>
    <Row>
      <Block xs={10} xsOffset={1}>
        {allSelect ? (
          <Content>ASSIGNING RANK TO ALL SELECTED {`${config.label.toUpperCase()}S`}</Content>
        ) : (
          <Content>
            ASSIGNING RANK TO {selected.length}{' '}
            {peopleTab ? 'USER' : selected[0].role.toUpperCase()}
            {selected.length > 1 ? 'S' : ''}
          </Content>
        )}
      </Block>
      <RankBlock>
        <Text>Rank</Text>
        <InputContainer>
          <IntegerInput
            error={(rank >= 1 && rank <= 99) || rank === '' ? '' : 'Rank must be between 1 to 99'}
            min={1}
            max={99}
            value={rank}
            onChange={event => setRank(event)}
          />
        </InputContainer>

        <MouseOverPopover
          messageTitle="Official & Assessor Ranks"
          messageBody="Rank represents skill level, 1 being the <br> most skilled and 99 being the least."
          customStyle={{ zIndex: '10000' }}
        >
          <InfoIconBlue />
        </MouseOverPopover>
      </RankBlock>
      <Block xs={12}>
        <ButtonsContainer>
          <ButtonContainer>
            <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
          </ButtonContainer>
          <ButtonContainer>
            <Button
              label="Save"
              onClick={() =>
                onSubmit({ selected, rank, allSelect, role_ids }) && peopleTab
                  ? pagination.onLoadPage(onLoadPageArgs)
                  : ''
              }
              width="84px"
              height="32px"
              primary
              disabled={
                !(rank === '' || (rank >= 1 && rank <= 99)) || !(allSelect || selected.length)
              }
            />
          </ButtonContainer>
        </ButtonsContainer>
      </Block>
    </Row>
  </Modal>
);

ImportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired
};

ImportModal.defaultProps = {};

export default Enhancer(ImportModal);
