import React from 'react';
import PropTypes from 'prop-types';
import Sorter from '../../Sorter';
import config from './config';

const EventSorter = ({ onSort, value }) => (
  <Sorter options={config.options} onSort={onSort} value={value} minWidth={170} />
);

EventSorter.propTypes = {
  onSort: PropTypes.func.isRequired,
  value: PropTypes.string
};

EventSorter.defaultProps = {
  value: ''
};
export default EventSorter;
