import React from 'react';
import PropTypes from 'prop-types';

import Filters from '../../Filters';
import generateFields from './config';

const EventsFilter = ({ sports, governingBodies, ...filtersProps }) => (
  <Filters config={generateFields({ sports, governingBodies })} {...filtersProps} />
);

EventsFilter.propTypes = {
  sports: PropTypes.arrayOf(Object),
  governingBodies: PropTypes.arrayOf(Object)
};

EventsFilter.defaultProps = {
  sports: [],
  governingBodies: []
};

export default EventsFilter;
