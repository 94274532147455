import styled from 'styled-components';
import { COLORS, HEIGHTS } from '../../../../../utils/styles';
import Flex from '../../../Flex';
import { pxToRem } from '../../../../../utils/helpers';

export const EventWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: ${pxToRem('278px')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${COLORS.white};
  background: ${COLORS.denimBlue};
  min-height: ${HEIGHTS.eventNavigationBar};
`;

export const actionTheme = {
  icon: {
    color: COLORS.white,
    marginTop: '0px',
    marginRight: '0px'
  },
  dropdown: {
    topOffset: '34px',
    rightOffset: '12px',
    width: '140px'
  },
  option: {
    background: COLORS.denimBlue,
    color: COLORS.white,
    hoverBackground: COLORS.darkCerulean,
    hoverColor: COLORS.white
  },
  optionDisabled: {
    background: COLORS.denimBlue,
    color: COLORS.strongCyan,
    hoverBackground: COLORS.denimBlue,
    hoverColor: COLORS.strongCyan
  }
};

export const EventHeader = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  padding-left: ${pxToRem('34px')};
  width: 100%;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.white};
  background: ${COLORS.denimBlue};
  height: ${HEIGHTS.eventNavigationBar};
`;

export const EventLabel = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
`;

export default {
  dropdownContainer: `border-right: 1px solid ${COLORS.denimBlue}`,
  dateRangeWrapper: `
    padding: 0 1.5rem;
    color: ${COLORS.white};
  `
};

export const BatchActionItemContainer = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
`;

export const BatchActionItem = styled.div`
  display: flex;
  width: auto;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: ${pxToRem('20px')};
  }
`;
