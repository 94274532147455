import React from 'react';
import PropTypes from 'prop-types';

import { Rectangle, Input, Span, Label, LineContainer } from './styled-components';

const Checkbox = ({ name, value, required, label, onChange, locked, indeterminate }) => (
  <>
    <LineContainer>
      <Rectangle>
        <Input
          name={name}
          type="checkbox"
          required={required}
          checked={value}
          onChange={onChange}
          disabled={locked}
        />
        <Span indeterminate={indeterminate} />
      </Rectangle>
      <Label title={label}>{label}</Label>
    </LineContainer>
  </>
);

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  locked: PropTypes.bool,
  indeterminate: PropTypes.bool
};

Checkbox.defaultProps = {
  name: '',
  value: false,
  required: false,
  label: '',
  locked: false,
  indeterminate: false
};

export default Checkbox;
