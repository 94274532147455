import styled from 'styled-components';

import { COLORS } from '../../../utils/styles';

export const Select = styled.select`
  color: ${({ color }) => (color ? `${color}` : COLORS.slateGray)};
  height: ${({ height }) => (height ? `${height}` : '2rem')};
  overflow: hidden;
  border: ${({ borderColor }) =>
    borderColor ? `0.08rem solid ${borderColor}` : `0.08rem solid ${COLORS.iron}`};
  padding-left: 0.3rem;
  width: ${({ width }) => (width ? `${width}` : '100%')};
  font-family: 'FiraSans';
  font-size: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  outline: ${({ outline }) => outline};
`;

export const LabelSecondary = styled.label`
  color: ${COLORS.slateGray};
  font-size: 0.7rem;
  text-transform: uppercase;
`;
