import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

import {
  Wrapper,
  Heading,
  Content,
  OptionLabel,
  Options,
  Option,
  StyledNavLink
} from './styled-components';
import { userIdxGetter } from '../../../utils/auth';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import Enhancer from '../../containers/NavDropDown';
import { ROUTESLABEL } from '../../../constants';

const NavDropDown = ({ open, setOpen, theme, options, title, selected, setIsActive }) => {
  React.useEffect(() => {
    if (window.location.href.includes(ROUTESLABEL.dashboard.toLowerCase()) && !open) {
      setIsActive(true);
    }
  }, [open, setIsActive]);

  return (
    <OutsideClickHandler onOutsideClick={() => open && setOpen(!open)}>
      <Wrapper theme={theme.wrapper}>
        <Heading onClick={() => setOpen(!open)} theme={theme.heading} active>
          {title}
        </Heading>
        {open && (
          <Content theme={theme.dropDown}>
            <Options>
              {options
                .filter(item => item.value !== selected)
                .map(({ label, onClick, to, value }) => (
                  <StyledNavLink
                    to={routeBuilder({ userIdx: userIdxGetter() }, to)}
                    theme={theme.navLink}
                  >
                    <Option
                      key={label}
                      theme={theme.option}
                      onClick={() => {
                        onClick(value);
                        setOpen(false);
                        setIsActive(true);
                      }}
                    >
                      <OptionLabel>{label}</OptionLabel>
                    </Option>
                  </StyledNavLink>
                ))}
            </Options>
          </Content>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

NavDropDown.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    wrapper: PropTypes.string,
    heading: PropTypes.string,
    dropDown: PropTypes.string,
    option: PropTypes.string,
    navLink: PropTypes.string
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      to: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.string,
  setIsActive: PropTypes.func
};

NavDropDown.defaultProps = {
  theme: {
    wrapper: '',
    heading: '',
    dropDown: '',
    option: '',
    navLink: ''
  },
  selected: '',
  setIsActive: () => null
};

export default Enhancer(NavDropDown);
