import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { lifecycle, compose, withState } from 'recompose';
import { getEventRoleById } from '../../../core/Users/selectors';
import { fetchCertifications as fetchCertificationsAction } from '../../../core/Certifications';
import { AVAILABILITY_CALENDAR_TZ } from '../../../constants';

const mapStateToProps = (state, { role, item }) => ({
  item: getEventRoleById(state, role, item.eventRoleId),
  isFetching: state && state.certs && state.certs.isFetching
});

const mapDispatchToProps = dispatch => ({
  fetchCertifications: payload => dispatch(fetchCertificationsAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('dropDownTimezoneList', 'setDropdownTimezoneList', [
    { value: moment.tz.guess(), label: 'Current time zone' }
  ]),
  lifecycle({
    componentDidMount() {
      const tzlist = AVAILABILITY_CALENDAR_TZ.map(({ code, name }) => {
        return {
          value: code,
          label: `${moment().tz(code).format('z')} - ${name}`,
          abbr: moment().tz(code).format('z')
        };
      });
      this.props.setDropdownTimezoneList([...this.props.dropDownTimezoneList, ...tzlist]);
    }
  })
);
