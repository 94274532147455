import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';

import { COLORS } from '../../../utils/styles';

export const Title = styled.h3`
  color: ${COLORS.regalBlue};
  font-size: 1rem;
  text-transform: capitalize;
  margin-left: 1rem;
`;

export const ColWrapper = styled(Row)`
  padding: 1rem 2rem 0;
`;
