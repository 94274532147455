import { getTimezoneAbbr } from '../../../../../utils/helpers';
import { formatDate } from '../../../../../utils/parsers';
import COLORS from '../../../../../utils/styles';
import { MODAL_STATUSES } from '../constants';

const generateGameInfo = ({ game, display_id }) => [
  {
    label: 'Game ID',
    value: display_id
  },

  {
    label: 'Date',
    value: `${formatDate(game.start_date, 'dddd, MMMM Do YYYY')} -  ${
      game.start_time
    } (${getTimezoneAbbr(game.start_date, game.timezone)})`
  },
  {
    label: 'Level & League',
    value: `${game.game_level.game_level} - ${game.game_level.game_type} (${game.schedule_owner})`
  },
  {
    label: 'Location',
    value: `${game.location.name} (${game.location.city})`
  },
  {
    label: 'Home Team',
    value: game.team_home.name
  },
  {
    label: 'Away Team',
    value: game.team_visitor.name
  },
  {
    label: 'Officials',
    value:
      (game.crew &&
        game.crew
          .filter(({ role_id }) => role_id === 5)
          .map(({ first_name, last_name }) => `${first_name} ${last_name}`)
          .join(', ')) ||
      (game.assignments_game &&
        game.assignments_game
          .filter(({ event_role: { role_id } }) => role_id === 5)
          .map(({ event_role: { user } }) => `${user.first_name} ${user.last_name}`)
          .join(', '))
  }
];

export const getGRIconColors = ({ status }) => {
  switch (status) {
    case MODAL_STATUSES.PARTIAL:
      return {
        icon: COLORS.white,
        iconBG: COLORS.yellow
      };

    case MODAL_STATUSES.COMPLETE:
      return {
        icon: COLORS.white,
        iconBG: COLORS.forestGreen
      };
    default:
      return {
        icon: COLORS.cobaltGrey,
        iconBG: COLORS.blueGray
      };
  }
};

export default generateGameInfo;
