import moment from 'moment';
import { GAMES_STATUSES, GAME_ASSIGNMENT_STATUSES } from '../../../../../constants';
import { convertToUtc } from '../../../../../utils/helpers';

export const DEFAULT_SELECTED_ASSIGNMENTS = {
  position_0: false,
  position_1: false,
  position_2: false,
  position_3: false,
  position_4: false,
  position_5: false,
  position_6: false,
  position_7: false
};

export const getSelectedAssignments = (
  values = { assignments: DEFAULT_SELECTED_ASSIGNMENTS },
  isSingleGame,
  assignments = []
) => {
  const keys = Object.keys(values.assignments).filter(key => values.assignments[key]);

  if (isSingleGame && keys.length) {
    const positions = [];
    keys.forEach(key => {
      positions.push(Number(key.split('_')[1]));
    });

    return assignments.filter(assignment => positions.includes(assignment.official_label_col));
  }

  return assignments;
};

const generateErrors = (name, errorTypes) =>
  errorTypes.reduce(
    (errors, { message, includeName, gameData, ignoreRule, display_id }) =>
      message
        ? [
            ...errors,
            {
              message,
              name: includeName ? name : null,
              gameData: gameData || null,
              ignoreRule: ignoreRule || false,
              display_id
            }
          ]
        : errors,
    []
  );

export const checkAssignmentConflict = ({
  userAssignments,
  start_date,
  start_time,
  timezone,
  id,
  display_id,
  gamesEventId,
  end_date,
  end_time,
  first_name,
  last_name,
  status
}) => {
  const isGameCancelledOrPostponed = ['cancelled', 'postponed'];
  const game = {
    id,
    display_id,
    start: moment.utc(convertToUtc(`${start_date} ${start_time}`, timezone)),
    end: moment.utc(convertToUtc(`${end_date} ${end_time}`, timezone)),
    status
  };

  const otherEventConflicts = [];
  const sameTimeConflicts = [];

  if (userAssignments.length) {
    userAssignments.forEach(otherAssignment => {
      // Allow assignments in cancelled and postponed games. Since they are not playable games then they don't need to
      // enforce rules where officials are not available.
      if (game.status === GAMES_STATUSES.CANCELLED || game.status === GAMES_STATUSES.POSTPONED)
        return;

      const otherAssignmentGameStatus = otherAssignment.game && otherAssignment.game.status;
      if (
        otherAssignmentGameStatus === GAMES_STATUSES.CANCELLED ||
        otherAssignmentGameStatus === GAMES_STATUSES.POSTPONED
      )
        return;

      if (game.id === (otherAssignment && otherAssignment.external_game_id)) return;
      const start =
        otherAssignment &&
        otherAssignment.game &&
        otherAssignment.game.start_at &&
        moment.utc(otherAssignment.game.start_at);
      const end =
        otherAssignment &&
        otherAssignment.game &&
        otherAssignment.game.end_at &&
        moment.utc(otherAssignment.game.end_at);
      const gameStatus = otherAssignment && otherAssignment.game && otherAssignment.game.status;
      if (Number(gamesEventId) !== otherAssignment.event_id) {
        if (
          otherAssignment.status === GAME_ASSIGNMENT_STATUSES.accepted &&
          !isGameCancelledOrPostponed.includes(gameStatus) &&
          !isGameCancelledOrPostponed.includes(game.status) &&
          (start.isBetween(game.start, game.end, null, '[)') ||
            end.isBetween(game.start, game.end, null, '(]') ||
            game.start.isBetween(start, end, null, '[)') ||
            game.end.isBetween(start, end, null, '(]'))
        ) {
          otherEventConflicts.push(otherAssignment);
        }
      } else if (
        otherAssignment.status === GAME_ASSIGNMENT_STATUSES.accepted &&
        !isGameCancelledOrPostponed.includes(gameStatus) &&
        !isGameCancelledOrPostponed.includes(game.status) &&
        (start.isBetween(game.start, game.end, null, '[)') ||
          end.isBetween(game.start, game.end, null, '(]') ||
          game.start.isBetween(start, end, null, '[)') ||
          game.end.isBetween(start, end, null, '(]'))
      ) {
        sameTimeConflicts.push(otherAssignment);
      }
    });
  }
  const sameTimeConflictsLabel = sameTimeConflicts.length !== 1 ? 'Games' : 'Game';
  const name = `${first_name} ${last_name} `;
  const errors = generateErrors(name, [
    {
      message:
        otherEventConflicts.length &&
        `has another game for another event on the time and date of Game ${game.display_id}.`,
      includeName: true,
      display_id: otherEventConflicts
        .map(({ game: gameInfo }) => gameInfo.external_event_id)
        .join(', ')
    },
    {
      message:
        sameTimeConflicts.length &&
        `is assigned to ${
          sameTimeConflicts.length
        } ${sameTimeConflictsLabel.toLowerCase()} with the same date and time: ${sameTimeConflictsLabel} ${sameTimeConflicts
          .map(({ game: gameInfo }) => gameInfo.external_event_id)
          .join(', ')}.`,
      includeName: true,
      display_id: sameTimeConflicts
        .map(({ game: gameInfo }) => gameInfo.external_event_id)
        .join(', ')
    }
  ]);

  return {
    userGameErrors: errors
  };
};
