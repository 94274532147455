import styled from 'styled-components';

import { COLORS, HEIGHTS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Wrapper = styled.div`
  width: 100%;
  height: ${HEIGHTS.appHeader};
  background-image: radial-gradient(
    circle at 52% -36%,
    #064b84,
    #011947
  ); /* TODO fix hex, revisit styles */
  display: flex;
  align-items: center;
  padding: 0 ${pxToRem('40px')};
`;

export const Logo = styled.img`
  max-width: ${pxToRem('328px')};
  width: 40%;
  color: ${COLORS.white};
`;
