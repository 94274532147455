import moment from 'moment';
import {
  groupBy,
  propEq,
  identity,
  is,
  cond,
  map,
  always,
  isNil,
  isEmpty,
  merge,
  replace
} from 'ramda';
import { formatMoney, unformat } from 'accounting-js';
import { format, parse, isValid } from 'date-fns';
import Decimal from 'decimal.js';
import { notNilNotEmpty } from './helpers';

export const relate = ({ relationships = {}, groupedIncluded = {} }, dataParser = identity) =>
  isEmpty(relationships)
    ? {}
    : Object.keys(relationships)
        .map(k => ({
          [k]: cond([
            [
              is(Array),
              data =>
                data.map(({ type, id }) => dataParser(groupedIncluded[type].find(propEq('id', id))))
            ],
            [
              is(Object),
              data =>
                dataParser(groupedIncluded[data.type].find(propEq('id', relationships[k].data.id)))
            ],
            [isNil, always(null)]
          ])(relationships[k].data)
        }))
        .reduce((ac, c) => ({ ...ac, ...c }), {});

export const mergeData = ({ data = [], included = {} }) => {
  const groupedIncluded = isEmpty(included) ? {} : groupBy(({ type }) => type)(included); // TODO Missing test for empty included
  const parseData = ({ id, attributes, relationships = {} }) => ({
    ...attributes,
    id,
    ...relate({ relationships, groupedIncluded }, parseData)
  });
  return cond([
    [isNil, always({})],
    [is(Array), map(parseData)],
    [is(Object), parseData]
  ])(data); // TODO Tests for the different data types
};

export const dataWithPagination = ({ meta, ...rest }) => merge({ meta }, { data: mergeData(rest) });

export const formatDate = (date, dateFormat = 'MM/DD/YYYY') => format(date, dateFormat);

/**
 * Takes a date and an optional format string and returns formatted date.
 * Extra steps are necessary to handle timezone offset and off-by-one errors
 * @param {Date} date to format
 * @param {String} dateFormat *optional* string to format date with
 *
 * @return {String} formatted date string
 */
export const roundedFormatDate = (date, dateFormat = 'MM/DD/YYYY') => {
  if (!date) return '';

  const [year, month, day] = date.toString().slice(0, 10).split('-');
  return format(new Date(year, month - 1, day), dateFormat);
};

export const parseDate = dateString => {
  if (isValid(parse(dateString))) {
    return parse(dateString);
  }
  return dateString;
};

export const parseDateOnly = dateString =>
  moment(dateString).isValid() ? moment(dateString).format('YYYY-MM-DD') : dateString;

/**
 * String::String
 * takes a String and formats it as a phone ignoring every char that is not related
 * to form format.
 * Return a formatted phone String
 */
export const normalizePhone = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  let formattedPhone = '';
  if (onlyNums.length <= 3) {
    formattedPhone = onlyNums;
  } else if (onlyNums.length <= 6) {
    formattedPhone = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  } else {
    formattedPhone = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
  }
  return formattedPhone;
};

/**
 * String::String
 * takes a String and formats it as a phone ignoring every char that is not related
 * to form format.
 * Return a formatted phone String
 */
export const normalizePhoneWithExtension = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  let formattedPhone = '';
  if (onlyNums.length <= 10) {
    formattedPhone = normalizePhone(value);
  } else {
    formattedPhone = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10
    )} x${onlyNums.slice(10, 16)}`;
  }
  return formattedPhone;
};

/**
 * String::String
 * takes a String and formats it as a price in the format of $xx.xx
 * Return a formatted price String
 */
export const normalizePrice = (
  rawValue,
  max = 4,
  withComma = false,
  defaultWithZeros = false,
  input = false,
  allowNegativePrice = true
) => {
  let value = rawValue;
  const CENT_PLACES = 2;
  const DIGITS_BETWEEN_COMMAS = 3;
  const MIN_DIGITS_TO_NEED_COMMA = 6;

  if (!input) {
    if (value === '0' || value === 'NaN') return defaultWithZeros ? '$0.00' : '';
    if (!value.match(/\./)) {
      value += '.00';
    } else if (value.match(/\d+\.\d$/)) {
      value += '0';
    }
  }

  const removedLeadingZeros = value.replace(/^0+/g, '');
  const prefix = value.includes('-') ? (allowNegativePrice ? '-' : '') : '';
  const onlyNums = removedLeadingZeros.replace(/[^\d]/g, '').slice(0, max + CENT_PLACES);

  if (!onlyNums) return '';
  const centIdx = onlyNums.length - CENT_PLACES;
  const commaIdx = centIdx - DIGITS_BETWEEN_COMMAS;
  if (onlyNums.length <= CENT_PLACES) return `$.${onlyNums}`;
  return withComma && onlyNums.length >= MIN_DIGITS_TO_NEED_COMMA
    ? `$${prefix}${onlyNums.slice(0, commaIdx)},${onlyNums.slice(
        commaIdx,
        centIdx
      )}.${onlyNums.slice(centIdx)}`
    : `$${prefix}${onlyNums.slice(0, centIdx)}.${onlyNums.slice(centIdx)}`;
};

export const emptyStringToNull = value => (value === '' ? null : value);
export const emptyStringToZero = value => (value === '' ? '0' : value);

export const truncateWithEllipses = (text, max) =>
  text && text.substr(0, max - 1) + (text.length > max ? '...' : '');

export const printValue = value => (notNilNotEmpty(value) ? 'None' : value);

export const printDate = dValue =>
  notNilNotEmpty(dValue) ? 'None' : formatDate(parseDate(dValue), 'dddd, MMM D YYYY');

export const printDateDifferenceInMinutes = ({ start_time, start_date, end_time, end_date }) =>
  start_time && start_date && end_time && end_date
    ? moment(`${end_date} ${end_time}`, 'MM/DD/YYYY hh:mm A').diff(
        moment(`${start_date} ${start_time}`, 'MM/DD/YYYY hh:mm A'),
        'minutes'
      )
    : '0';

export const fromMomentTo12Hours = value => (value ? value.format('h:mm A') : value);

export const from12HoursToMoment = value => (value ? moment(value, 'h:mm A') : value);

export const parseDateMMDDYYYY = value => moment(value).format('MM/DD/YYYY');

export const returnOnlyNumbers = value => replace(/\D/g, '', value);

export const falsyToNull = value => (!value ? null : value);

/**
 * @param {Number} val value to format
 * @param {Bool} noInitialZero removes initial zero on empty currency
 * Takes a number and returns a string formatted as currency.
 */
export const numToCurrency = (val, noInitialZero = false) => {
  if (typeof val === 'string') return val;
  if (val === 0 && noInitialZero) return '$.00';
  return formatMoney(val) === '$NaN.undefined' ? undefined : formatMoney(val);
};

/**
 * @param {String} val to format
 * Takes a string formatted as currency an returns a number.
 * will return the string passed in some edge cases caused by the user being halfway through typing a valid currency
 */
export const currencyToNum = val => {
  if (val === '$-0.00' || val === '-$0.00' || val === '$-.00' || val === '-$.00') return val;
  return parseFloat(unformat(val).toFixed(2));
};

/**
 *
 * @param {Date} Date
 * Receives a date and returns the same date at the end of that day (23:59:59.999)
 */
export const getEndOfDay = date => moment(date).endOf('day').toDate();

export const fixedDecimal = (amount, digits) =>
  new Decimal(new Decimal(amount).toFixed(digits || 2)).toNumber();

export const normalizeTaxIdNo = (value, max) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  let formattedPhone = '';
  formattedPhone = `${onlyNums.slice(0, max)}`;
  return formattedPhone;
};

// reimplementation of groupBy because our current version of node is old
export const simpleGroupBy = (array, matcher, output = {}) =>
  array.reduce((a, c) => {
    const match = matcher(c);
    // eslint-disable-next-line no-param-reassign
    a[match] = a[match] ? [...a[match], c] : [c];
    return a;
  }, output);
