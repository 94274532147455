import styled from 'styled-components';

import { COLORS, PAPER } from '../../../utils/styles';

export const Container = styled.div`
  top: 20px;
  right: 20px;
  width: 300px;
  position: fixed;
  z-index: 9999999;
`;

export const Wrapper = styled.div`
  position: relative;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
`;

export const Notification = styled.div`
  background-color: ${COLORS.white};
  border-left: 2px solid
    ${({ type }) => (type === 'error' ? COLORS.carminePink : COLORS.mountainMeadow)};
  color: ${COLORS.slateGray};
  cursor: pointer;
  ${PAPER};
`;

export const NotificationMeta = styled.div`
  padding: 10px 20px;
`;

export const Title = styled.h4`
  margin: 0 0 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.428571429;
`;

export const Message = styled.p`
  margin: 0;
  font-size: 14px;
  overflow-wrap: break-word;
`;
