import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers } from 'recompose';
import { always } from 'ramda';
import MaterialDropdown from '../../components/MaterialDropDown';
import TypeaheadWrapper from './styled-components';

const MaterialTypeahead = ({ updateOptionsArray, ...rest }) => (
  <TypeaheadWrapper>
    <MaterialDropdown
      updateFilter={value => {
        updateOptionsArray(value);
      }}
      typeahead
      {...rest}
    />
  </TypeaheadWrapper>
);

MaterialTypeahead.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
    })
  ),
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  required: PropTypes.bool,
  updateOptionsArray: PropTypes.func.isRequired
};

MaterialTypeahead.defaultProps = {
  options: [],
  placeholder: '',
  input: {
    value: null,
    onChange: always(undefined)
  },
  required: false
};

export default compose(
  withStateHandlers(({ options }) => ({ options, allOptions: options }), {
    updateOptionsArray: ({ allOptions }) => filterValue => {
      const newOptions = allOptions.filter(
        elem => elem.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
      );
      return { options: newOptions };
    }
  }),
  pure
)(MaterialTypeahead);
