import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import Button from '../../../Button';
import Modal from '../../../Modal';
import {
  Block,
  Content,
  ContentWrapper,
  ButtonsContainer,
  ButtonContainer
} from '../../../Event/DeleteEventModal/styled-components';

const GameLevelChangeModal = ({ onClose, onSubmit }) => (
  <Modal title="Change Game Level and Type" onClose={onClose} background>
    <Row>
      <ContentWrapper>
        <Block xs={10} xsOffset={1} className="content">
          <Content>
            Are you sure you want to change Level and Type?
            <br />
            If the Game Crew size for the new Level and Type is smaller,
            <br />
            then game assignments may be removed upon update.
          </Content>
        </Block>
      </ContentWrapper>
      <Block xs={12}>
        <ButtonsContainer>
          <ButtonContainer>
            <Button
              label="Update Level and Type"
              onClick={onSubmit}
              width="151px"
              height="32px"
              primary
            />
          </ButtonContainer>
          <ButtonContainer>
            <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
          </ButtonContainer>
        </ButtonsContainer>
      </Block>
    </Row>
  </Modal>
);

GameLevelChangeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default GameLevelChangeModal;
