import { MODAL_TYPES } from './constants';
import { getCVRIconColors } from './CrewVerificationReportModal/config';
import { getGRIconColors } from './GameReportModal/config';

const colorsConfig = {
  [MODAL_TYPES.CVR]: getCVRIconColors,
  [MODAL_TYPES.GR]: getGRIconColors,
  [MODAL_TYPES.IR]: getGRIconColors,
  [MODAL_TYPES.AR]: getGRIconColors
};

const config = {
  getIconColors: ({ status, modal }) => colorsConfig[modal] && colorsConfig[modal]({ status })
};

export default config;
