import { compose, pure, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  fetchEvent,
  switchEventStatus,
  deleteEventAndRedirect as deleteEventAndRedirectAction
} from '../../../core/Events';
import { getEventInfo } from '../../../core/Events/selectors';
import { getUserGroups, isSuperAdmin as isSuperAdminSelector } from '../../../core/Auth/selectors';
import { fetchBillingCustomers as fetchBillingCustomerAction } from '../../../core/BillingCustomers';

const mapStateToProps = state => ({
  eventInfo: getEventInfo(state),
  userGroups: getUserGroups(state),
  isSuperAdmin: isSuperAdminSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onLoad: (eventId, include_billing_customer_data) =>
    dispatch(fetchEvent(eventId, include_billing_customer_data)),
  onToggleEventStatus: status => dispatch(switchEventStatus(status)),
  deleteEventAndRedirect: payload => dispatch(deleteEventAndRedirectAction(payload)),
  fetchBillingCustomer: payload => dispatch(fetchBillingCustomerAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('showModal', 'toggleShowModal', false),
  withRouter,
  withHandlers({
    onDelete: ({ deleteEventAndRedirect }) => eventId =>
      new Promise(resolve => deleteEventAndRedirect({ eventId, resolve }))
  }),
  lifecycle({
    componentWillMount() {
      const {
        match: {
          params: { eventId }
        },
        onLoad
      } = this.props;
      const include_billing_customer_data = true;
      onLoad(eventId, include_billing_customer_data);
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.eventInfo.id !== (this.props.eventInfo && this.props.eventInfo.id) ||
        prevProps.eventInfo.id === (this.props.eventInfo && this.props.eventInfo.id)
      ) {
        const {
          eventInfo: { platform_id },
          fetchBillingCustomer
        } = this.props;
        if (platform_id) {
          fetchBillingCustomer({ platformId: platform_id });
        }
      }
    }
  }),
  pure
);
