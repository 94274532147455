import React, { useEffect, useState } from 'react';
import { Table as PopUpTable, CircularProgress, TableCell, TableRow } from '@material-ui/core';

import Enhancer from '../../../containers/AvailableBalance';
import { WhiteContentContainer } from '../styled-components';
import FlexRow, { MinMaxWarning, EmptyLine } from './styled-components';
import {
  H4,
  PrimaryButton,
  Table,
  H6,
  Spaced,
  SpacedLeft
} from '../../../global-material-components';
import { COLORS } from '../../../../utils/styles';
import ConfirmationPopup from '../../popupModals/ComfirmationPopup';

const headings = [
  {
    name: 'Batch Id',
    id: 'batchId',
    sortable: true,
    sortFunction: () => (a, b) => Number(a.batchId) - Number(b.batchId)
  },
  {
    name: 'Date',
    id: 'date',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Status',
    id: 'status',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Event Name',
    id: 'eventName',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Payment Period',
    id: 'paymentPeriod',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Paying Group',
    id: 'billingName',
    sortable: false,
    sortFunction: null
  },
  {
    name: 'Amount',
    id: 'amount',
    sortable: false,
    sortFunction: null,
    parser: val => `$${Number(val).toFixed(2)}`
  },
  {
    name: 'Total',
    id: 'total',
    sortable: false,
    sortFunction: null,
    parser: val => `$${Number(val).toFixed(2)}`
  }
];

const PayoutDescription = ({ available, fees, availableToPay }) => (
  <PopUpTable style={{ marginLeft: '0px' }}>
    <TableRow>
      <TableCell>Available Balance</TableCell>
      <TableCell>{`$${Number(available).toFixed(2)}`}</TableCell>
    </TableRow>
    {fees ? (
      <TableRow>
        <TableCell>Fees</TableCell>
        <TableCell>{`$${Number(fees).toFixed(2)}`}</TableCell>
      </TableRow>
    ) : (
      ''
    )}
    <TableRow>
      <TableCell>Available To Payout</TableCell>
      <TableCell>{`$${Number(availableToPay).toFixed(2)}`}</TableCell>
    </TableRow>
  </PopUpTable>
);

const AvailableBalance = function ({
  isFetching,
  userId,
  payee,
  availableBalances,
  requestPayoutAction,
  batchDetails,
  payoutFee,
  fetchPayoutFee,
  minAndMaxAmount,
  crossEditPayeeCheck,
  lastPayoutFailed
}) {
  const [isFundRequest, setIsFundRequest] = useState(false);
  const [isLastPayoutFailed, setIsLastPayoutFailed] = useState(false);
  const [availableToPay, setAvailableToPay] = useState(0);
  const [minMaxError, setMinMaxError] = useState('');

  const totalAmount =
    batchDetails &&
    batchDetails.reduce((acc, batch) => (batch.status !== 'Pending' ? acc + batch.total : acc), 0);
  const availableAmount = availableBalances.available || 0;
  const pendingAmount = availableBalances.pending || 0;
  const payeeId = payee && payee.id;

  const payoutHandler = () => {
    requestPayoutAction({
      userId,
      payeeId,
      available: Number(availableAmount).toFixed(2)
    });
    setIsFundRequest(false);
    setIsLastPayoutFailed(false);
  };
  const payoutActionClick = () => {
    setIsLastPayoutFailed(lastPayoutFailed);
    if (lastPayoutFailed) {
      setIsFundRequest(false);
    } else {
      payoutHandler();
    }
  };
  useEffect(() => {
    setAvailableToPay(availableAmount - payoutFee);
  }, [availableAmount, payoutFee]);

  useEffect(() => {
    if (availableToPay < minAndMaxAmount.min_amount)
      setMinMaxError(`The least amount required for payout is $${minAndMaxAmount.min_amount}.`);
    else if (availableToPay > minAndMaxAmount.max_amount)
      setMinMaxError(`The maximum amount required for payout is $${minAndMaxAmount.max_amount}.`);
    else if (payoutFee && payoutFee < minAndMaxAmount.min_fee)
      setMinMaxError(`The least amount required for fee is $${minAndMaxAmount.min_fee}.`);
    else if (payoutFee > minAndMaxAmount.max_fee)
      setMinMaxError(`The maximum amount required for fee is $${minAndMaxAmount.max_fee}.`);
    else setMinMaxError('');
  }, [minAndMaxAmount, payoutFee, availableToPay]);

  return (
    <WhiteContentContainer padding fetching={isFetching}>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <FlexRow style={{ marginBottom: '40px' }}>
            <H4 margin="0">Available Balance:</H4>
            <H4 margin="0 0 0 10px" color={COLORS.denimBlue}>
              {`$${Number(availableAmount).toFixed(2)}`}
            </H4>
            <PrimaryButton
              onClick={() => {
                setIsFundRequest(true);
                if (payeeId) fetchPayoutFee({ payeeId, amount: availableAmount });
              }}
              disabled={!availableAmount}
              style={{ marginLeft: '20px' }}
            >
              Request Funds
            </PrimaryButton>
            {isLastPayoutFailed && (
              <ConfirmationPopup
                title="Payout Confirmation"
                description="Are you sure you want to continue?"
                SubmitBtnText="YES"
                cancelButtonText="NO"
                confirmationPopupAction={isLastPayoutFailed}
                setConfirmationPopupAction={setIsLastPayoutFailed}
                primaryActionFun={payoutHandler}
              />
            )}
            {isFundRequest && (
              <ConfirmationPopup
                title="Confirm Request Fund  "
                description={
                  <>
                    <PayoutDescription
                      available={availableAmount}
                      fees={payoutFee}
                      availableToPay={availableToPay}
                    />
                    {minMaxError && (
                      <>
                        <EmptyLine />
                        <center>
                          <MinMaxWarning>{minMaxError}</MinMaxWarning>
                        </center>
                      </>
                    )}
                  </>
                }
                SubmitBtnText="Payout"
                confirmationPopupAction={isFundRequest}
                setConfirmationPopupAction={setIsFundRequest}
                userId={userId}
                primaryActionFun={payoutActionClick}
                primaryButtonDisabled={
                  availableToPay < minAndMaxAmount.min_amount ||
                  availableToPay > minAndMaxAmount.max_amount ||
                  (payoutFee && payoutFee < minAndMaxAmount.min_fee) ||
                  payoutFee > minAndMaxAmount.max_fee
                }
              />
            )}
            {!crossEditPayeeCheck && (
              <H6 margin="0 0 0 10px" color={COLORS.errorRed}>
                *Please complete your payment Profile
              </H6>
            )}
          </FlexRow>
          <FlexRow style={{ marginBottom: '40px' }}>
            <SpacedLeft>
              <H6 margin="0">Total Balance:</H6>
              <H6 margin="0 0 0 10px" color={COLORS.denimBlue}>
                {`$${Number(availableAmount + pendingAmount).toFixed(2)}`}
              </H6>
            </SpacedLeft>
            <Spaced>
              <H6 margin="0">Amount Pending:</H6>
              <H6 margin="0 0 0 10px" color={COLORS.denimBlue}>
                {`$${Number(pendingAmount).toFixed(2)}`}
              </H6>
            </Spaced>
          </FlexRow>
          <Table
            total={`$${Number(totalAmount).toFixed(2)}`}
            dataKey="batchId"
            data={batchDetails || []}
            headings={headings}
          />
        </>
      )}
    </WhiteContentContainer>
  );
};

export default Enhancer(AvailableBalance);
