import { compose, withProps, withState } from 'recompose';
import { connect } from 'react-redux';

import unconnected from './unconnected';
import { paginationAware, filterConfigToNames, updateOnPageChange } from '../PaginationAware';
import {
  fetchEvents as fetchEventsAction,
  fetchFilteredEvents as fetchFilteredEventsAction
} from '../../../core/Events';
import { REPORT_TYPES } from '../../../constants';
import {
  fetchAssessmentReports,
  fetchIncidentReports,
  fetchGameReports,
  storeReportsEvent as storeReportsEventAction,
  fetchReportsEventCrewLabels as fetchReportsEventCrewLabelsAction,
  bulkUpdateReports,
  bulkUpdateAssessmentReports,
  bulkUpdateGameReports,
  setCurrentReport as setCurrentReportAction
} from '../../../core/Reports';
import {
  getIncidentReports,
  getReportMeta,
  getReportsEvent,
  getIsFetching,
  getAssessmentReports,
  getReportsEventCrewLabels,
  getSortingValue,
  getGameReports,
  getIsFetchingGameReports,
  isReportsEventGroupAdmin
} from '../../../core/Reports/selectors';
import {
  isReportsEventAdmin,
  selectUserId,
  isReportEventAssignor,
  isReportEventAssessor
} from '../../../core/Auth/selectors';
import {
  getEventAssessmentReportSettings,
  getEvents,
  getFilteredEvents,
  getEventReportSettings,
  getEventSportsId
} from '../../../core/Events/selectors';
import BulkApproveModal from '../../components/Reports/Modals/BulkApproveModal';
import BulkUnapproveModal from '../../components/Reports/Modals/BulkUnapproveModal';
import filterConfig from '../../components/Reports/Header/ReportFilter/config';
import { fetchCurrentGame as fetchCurrentGameAction } from '../../../core/Games';
import {
  setShowModalFor as setShowModalForAction,
  unsetShowModalFor as unsetShowModalForAction
} from '../../../core/Modals';
import { formatReportFilters } from '../../../utils/helpers';

const mapStateToProps = state => ({
  incidentReports: getIncidentReports(state),
  assessmentReports: getAssessmentReports(state),
  gameReports: getGameReports(state),
  reportsEvent: getReportsEvent()(state),
  reportMeta: getReportMeta(state),
  events: getEvents(state),
  isFetching: getIsFetching(state),
  crewLabels: getReportsEventCrewLabels(state),
  sortingValue: getSortingValue(state),
  isReportsEventAdmin: isReportsEventAdmin(state),
  isReportsEventAssignor: isReportEventAssignor(state),
  isReportsEventAssessor: isReportEventAssessor(state),
  getReportsEventSport: getEventSportsId(state),
  reportSettings: getEventReportSettings(state),
  currentUserId: selectUserId(state),
  filteredEvents: getFilteredEvents(state),
  isFetchingGameReports: getIsFetchingGameReports(state),
  eventsAssessmentSettings: getEventAssessmentReportSettings(state),
  isReportsEventGroupAdmin: !!isReportsEventGroupAdmin()(state)
});

const mapDispatchToProps = dispatch => ({
  storeReportsEvent: payload => dispatch(storeReportsEventAction(payload)),
  fetchEvents: payload => dispatch(fetchEventsAction(payload)),
  bulkApproveReports: payload => dispatch(bulkUpdateReports(payload)),
  bulkApproveAssessmentReports: payload => dispatch(bulkUpdateAssessmentReports(payload)),
  bulkApproveGameReports: payload => dispatch(bulkUpdateGameReports(payload)),
  fetchReportsEventCrewLabels: () => dispatch(fetchReportsEventCrewLabelsAction()),
  fetchReports: payload => {
    const payloadData = { ...payload, filters: formatReportFilters(payload.filters) };

    if (!payload.type || payload.type === REPORT_TYPES.incident) {
      dispatch(fetchIncidentReports(payloadData));
    } else if (payload.type === REPORT_TYPES.game) {
      dispatch(fetchGameReports(payloadData));
    } else {
      dispatch(fetchAssessmentReports(payloadData));
    }
  },
  fetchCurrentGame: payload => dispatch(fetchCurrentGameAction(payload)),
  setCurrentReport: payload => dispatch(setCurrentReportAction(payload)),
  setShowModalFor: payload => dispatch(setShowModalForAction(payload)),
  unsetShowModalFor: payload => dispatch(unsetShowModalForAction(payload)),
  fetchFilteredEvents: payload => dispatch(fetchFilteredEventsAction(payload))
});

export const PAGINATION_AWARE_PREFIX = 'rpt';

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState(
    'reportType',
    'setReportType',
    isReportsEventGroupAdmin || isReportsEventAdmin || isReportEventAssignor
      ? REPORT_TYPES.incident
      : REPORT_TYPES.assessment
  ),
  withProps({
    BulkApproveModal,
    BulkUnapproveModal
  }),
  paginationAware(
    {
      fetcherQuote: 'fetchReports',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'reportMeta',
      searchFilter: ['external_event_id'],
      filterNames: filterConfigToNames([
        ...filterConfig.assessment,
        ...filterConfig.incident,
        ...filterConfig.game,
        ...filterConfig.gameSoccerOrFutsal
      ]),
      pageFilter: ['type']
    },
    /* needs a check for updateOnPageChange */
    updateOnPageChange && updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  unconnected
);
