import { compose, withProps, withState } from 'recompose';
import { connect } from 'react-redux';

import unconnected from './unconnected';
import { paginationAware, filterConfigToNames, updateOnPageChange } from '../PaginationAware';
import {
  fetchEvents as fetchEventsAction,
  fetchFilteredEvents as fetchFilteredEventsAction
} from '../../../core/Events';
import { setCurrentReport as setCurrentReportAction } from '../../../core/Reports';
import { getEvents, getFilteredEvents, getEventCategories } from '../../../core/Events/selectors';
import { fetchCurrentGame as fetchCurrentGameAction } from '../../../core/Games';
import { setShowModalFor as setShowModalForAction } from '../../../core/Modals';
import { storePeopleEvent, fetchActiveUserInfo } from '../../../core/User';
import { getPeopleEvent } from '../../../core/User/selectors';
import {
  fetchEventPeople,
  sendBulkEmail,
  addRankEvent,
  setSelectedUser as setSelectedUserAction,
  assignCategoriesToUsers as assignCategoriesToUsersAction
} from '../../../core/Users';
import { getUsersByType, getMetaByType, getIsFetching } from '../../../core/Users/selectors';
import config from '../../components/People/Header/PeopleFilter/config';
import EmailUsersModal from '../../components/SideList/Modals/EmailUsersModal';
import UserCategoryAssignorModal from '../../components/SideList/Modals/UserCategoryAssignorModal';
import AddRankModal from '../../components/SideList/Modals/AddRankModal';

const mapStateToProps = (state, props) => ({
  peopleEvent: getPeopleEvent()(state),
  events: getEvents(state),
  isFetching: getIsFetching(state),
  filteredEvents: getFilteredEvents(state),
  userList: getUsersByType(state, 'users'),
  metaData: getMetaByType('users')(state),
  onLoadPageArgs: {
    roles: { alias: 'users', list: [] },
    eventId: getPeopleEvent()(state) && getPeopleEvent()(state).id,
    peopleTab: true
  },
  categories: getEventCategories(state),
  pathUrl: props.location && props.location.pathname,
  searchParams: props.location && props.location.search
});

const mapDispatchToProps = dispatch => ({
  storePeopleEvent: payload => dispatch(storePeopleEvent(payload)),
  fetchEvents: payload => dispatch(fetchEventsAction(payload)),
  fetchCurrentGame: payload => dispatch(fetchCurrentGameAction(payload)),
  setCurrentReport: payload => dispatch(setCurrentReportAction(payload)),
  setShowModalFor: payload => dispatch(setShowModalForAction(payload)),
  fetchFilteredEvents: payload => dispatch(fetchFilteredEventsAction(payload)),
  onLoad: payload => dispatch(fetchEventPeople(payload)),
  sendEmail: payload => dispatch(sendBulkEmail(payload)),
  addRank: payload => dispatch(addRankEvent(payload)),
  setSelectedUser: payload => dispatch(setSelectedUserAction(payload)),
  assignCategoriesToUsers: payload => dispatch(assignCategoriesToUsersAction(payload)),
  fetchActiveUserInfo: payload => dispatch(fetchActiveUserInfo(payload))
});

const PAGINATION_AWARE_PREFIX = 'usrs';

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('inEdit', 'setInEdit', false),
  withProps({
    EmailUsersModal,
    UserCategoryAssignorModal,
    AddRankModal
  }),
  paginationAware(
    {
      fetcherQuote: 'onLoad',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'metaData',
      searchFilter: ['search'],
      filterNames: filterConfigToNames([...config({}).filters, ...config({}).allEventsFilter])
    },
    /* needs a check for updateOnPageChange */
    updateOnPageChange && updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  unconnected
);
