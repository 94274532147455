import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, LinksContainer, UserMenuLink } from './styled-components';
import Enhancer from '../../../containers/Logout';
import { sInterpolator as routeBuilder } from '../../../../utils/helpers';
import { userIdxGetter } from '../../../../utils/auth';
import { PROFILE_INFORMATION } from '../../../../core/paths';
import { URLS } from '../../../../constants';

const UserMenu = ({ onLogout, onLinkClick, user }) => (
  <Wrapper>
    <LinksContainer>
      <UserMenuLink
        to={routeBuilder({ userIdx: userIdxGetter(), userId: user.id }, PROFILE_INFORMATION)}
        onClick={onLinkClick}
      >
        Profile
      </UserMenuLink>
      <UserMenuLink to={{ pathname: URLS.HELP_DESK }} target="_blank">
        Help
      </UserMenuLink>
      <UserMenuLink to="#" onClick={onLogout}>
        Sign Out
      </UserMenuLink>
    </LinksContainer>
  </Wrapper>
);

UserMenu.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired
};

export default Enhancer(UserMenu);
