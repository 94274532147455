import React, { Fragment } from 'react';
import { TableContainer } from '@material-ui/core';
import moment from 'moment';
import LoaderWrapper from '../../../LoaderWrapper';
import { ModalContainer, HeaderRow, Batch } from '../styled-components';
import { Dialog } from '../../../../global-material-components';
import { HeaderRowGame } from '../../../Event/GameLevels/styled-components';
import { ColHeaderGame } from '../../../SimpleTable';
import { numToCurrency } from '../../../../../utils/parsers';
import {
  TableScroll,
  TableWrap,
  StaticValue,
  NavItem,
  Container as ValueColContainer,
  NoData
} from './styled-components';

const FailedSummaryDialog = ({
  batchFailedHistory,
  currentBatch,
  isFetching,
  failedSummaryOpen,
  setFailedSummaryOpen
}) => {
  const headers = ['Creator', 'Funded On', 'Failed Batch Reason'];
  return (
    <Dialog
      title="Failed Summary"
      onClose={() => {
        setFailedSummaryOpen(false);
      }}
      open={failedSummaryOpen}
      noCancel
      noPrimary
      noDivider
    >
      <ModalContainer>
        <LoaderWrapper isFetching={isFetching}>
          <div>
            {currentBatch && failedSummaryOpen ? (
              <>
                <HeaderRow>
                  <Batch>{`Batch ID: ${currentBatch.id}`}</Batch>
                  <Batch>{`Batch Total Amount: ${numToCurrency(
                    currentBatch.batch_payment.amount
                  )}`}</Batch>
                  <Batch>{`Batch Fee: ${numToCurrency(
                    currentBatch.batch_payment.application_fee
                  )}`}</Batch>
                </HeaderRow>
                <>
                  <TableContainer style={{ overflow: 'hidden' }}>
                    <HeaderRowGame between="xs">
                      {headers.map(name => (
                        <ColHeaderGame
                          textAlign="left"
                          xs={name === 'Failed Batch Reason' ? 6 : 3}
                          key={name}
                        >
                          <NavItem>{name} </NavItem>
                        </ColHeaderGame>
                      ))}
                    </HeaderRowGame>
                    {batchFailedHistory ? (
                      batchFailedHistory.map((e, i) => (
                        <Fragment key={`frangment-${i}`}>
                          <TableScroll between="xs" key={`key-${i}`}>
                            <ValueColContainer textAlign="left" xs={3}>
                              <StaticValue lineHeight={1.2} fontSize="0.8rem">
                                {e.creator}
                              </StaticValue>
                            </ValueColContainer>
                            <ValueColContainer xs={3} textAlign="left">
                              <StaticValue lineHeight={1.2} fontSize="0.8rem">
                                {moment.utc(e.created_at).local().format('YYYY-MM-DD')}
                              </StaticValue>
                            </ValueColContainer>
                            <ValueColContainer
                              textAlign="left"
                              xs={6}
                              paddingLeft="10px"
                              paddingRight="10px"
                            >
                              <TableWrap>
                                <StaticValue textAlign="left" lineHeight={1.2} fontSize="0.8rem">
                                  {e.failedBatchReason}
                                </StaticValue>
                              </TableWrap>
                            </ValueColContainer>
                          </TableScroll>
                        </Fragment>
                      ))
                    ) : (
                      <NoData> No data found</NoData>
                    )}
                  </TableContainer>
                </>
              </>
            ) : null}
          </div>
        </LoaderWrapper>
      </ModalContainer>
    </Dialog>
  );
};

export default FailedSummaryDialog;
