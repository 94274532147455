import React from 'react';

import logoConnect from '../../../assets/images/new-official-connect-logo-white-red.png';
import { Wrapper, Logo } from './styled-components';

const SimpleNav = () => (
  <Wrapper>
    <Logo src={logoConnect} alt="logoConnect" />
  </Wrapper>
);

export default SimpleNav;
