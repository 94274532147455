import { takeEvery, put, call, select } from 'redux-saga/effects';
import { ifElse, both, is, pipe, identity, isEmpty, not, map } from 'ramda';
import shortid from 'shortid';

import Api from '../../utils/api-wrapper';
import Logger from '../../utils/logger';
import {
  ADD_NOTIFICATIONS,
  REMOVE_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  UNSET_NOTIFICATIONS,
  FETCH_NOTIFICATION,
  FETCH_MENU_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  ALLOW_IGNORE_RULES,
  ALLOW_IGNORE_RULES_SUCCESS,
  setIsFetching,
  setNotification,
  setMenuNotifications,
  setBannerNotifications,
  removeMenuNotification,
  FETCH_BANNER_NOTIFICATIONS,
  DELETE_NOTIFICATION
} from './index';
import { selectUserId } from '../Auth/selectors';
import { errorProgressDecorate } from '../Progress/helper';
import { mergeData } from '../../utils/parsers';
import { NOTIFICATION_STATUS } from '../../constants';

const setDefault = pipe(
  ifElse(both(is(Array), pipe(isEmpty, not)), identity, () => [
    { type: 'error', message: 'Something went wrong' }
  ]),
  map(notification => ({ ...notification, id: shortid.generate() }))
);

export function* addNotification({ payload }) {
  try {
    yield put({ type: SET_NOTIFICATIONS, payload: setDefault(payload) });
    // yield delay(5000);
    // yield put({ type: REMOVE_NOTIFICATIONS });
  } catch (e) {
    Logger.error(e);
  }
}

export function* setIgnoreRules(input) {
  try {
    yield put({ type: ALLOW_IGNORE_RULES_SUCCESS, payload: input });
  } catch (e) {
    Logger.error(e);
  }
}

export function* removeNotification() {
  try {
    yield put({ type: UNSET_NOTIFICATIONS });
  } catch (e) {
    Logger.error(e);
  }
}

export function* processFetchNotification({ payload: { id } }) {
  const response = yield call(Api.fetchNotification, { id });
  if (response) {
    yield put(setNotification(mergeData(response)));
  }
}

export function* fetchNotification(params) {
  yield call(errorProgressDecorate, processFetchNotification, params, setIsFetching);
}

export function* processFetchNotifications({ payload: { filters } }) {
  const userId = yield select(selectUserId);
  const response = yield call(Api.fetchNotifications, {
    filters: { ...filters, user_id: userId }
  });

  if (response) {
    yield put(setMenuNotifications(mergeData(response)));
  }
}

export function* fetchNotifications(params) {
  yield call(errorProgressDecorate, processFetchNotifications, params, setIsFetching);
}

export function* processFetchBannerNotifications({ payload: { filters } }) {
  const userId = yield select(selectUserId);
  const response = yield call(Api.fetchNotifications, {
    filters: { ...filters, user_id: userId }
  });

  if (response) {
    yield put(setBannerNotifications(mergeData(response)));
  }
}

export function* fetchBannerNotifications(params) {
  yield call(errorProgressDecorate, processFetchBannerNotifications, params, setIsFetching);
}

export function* processUpdateNotification({ payload: { id, data } }) {
  const response = yield call(Api.updateNotification, { id, data });
  if (response.data && response.data.length) {
    for (let i = 0; i < response.data.length; i += 1) {
      const { alert_type: alertType, status } = response.data[i].attributes;
      if (status === NOTIFICATION_STATUS.action_taken) {
        yield put(removeMenuNotification({ id, alertType }));
      }
    }
  }
}

export function* updateNotification(params) {
  yield call(errorProgressDecorate, processUpdateNotification, params, setIsFetching);
}

export function* processDeleteNotification({ payload: { id, data, alert_type: alertType } }) {
  yield call(Api.deleteNotification, { id, data });
  if (alertType) yield put(removeMenuNotification({ id, alertType }));
}

export function* deleteNotification(params) {
  yield call(errorProgressDecorate, processDeleteNotification, params, setIsFetching);
}

export const notificationsSagas = [
  takeEvery(ADD_NOTIFICATIONS, addNotification),
  takeEvery(REMOVE_NOTIFICATIONS, removeNotification),
  takeEvery(FETCH_NOTIFICATION, fetchNotification),
  takeEvery(FETCH_MENU_NOTIFICATIONS, fetchNotifications),
  takeEvery(FETCH_BANNER_NOTIFICATIONS, fetchBannerNotifications),
  takeEvery(UPDATE_NOTIFICATION, updateNotification),
  takeEvery(ALLOW_IGNORE_RULES, setIgnoreRules),
  takeEvery(DELETE_NOTIFICATION, deleteNotification)
];

export default notificationsSagas;
