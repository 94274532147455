import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import {
  isSuperAdmin as isSuperAdminSelector,
  getGroups as groupsSelector
} from '../../../core/Auth/selectors';
import CreateAccount from '../CreateAccount';

export default compose(
  connect(
    state => ({
      isSuperAdmin: isSuperAdminSelector(state),
      isFetching: state.auth.isFetching,
      groups: groupsSelector(state)
    }),
    null
  ),
  CreateAccount,
  pure
);
