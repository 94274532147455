import styled from 'styled-components';

import Icon from '../../Icon';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${COLORS.denimBlue};
  background: ${COLORS.white};
  min-height: ${props => (props.hasHeight ? 'none' : '2rem')};
  width: 100%;

  ${({ theme }) => theme || ''};
  ${props =>
    props.isUserAddAction &&
    `
   color: ${COLORS.black};
  `}
`;

export const Header = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${COLORS.denimBlue};
  background: ${COLORS.white};
  font-family: 'RobotoLight';
  font-size: ${pxToRem('14px')};
  height: ${props => (props.hasHeight ? 'none' : '2rem')};
  box-shadow: 0 1px 0 0
    ${({ hasError }) =>
      hasError ? `${COLORS.carminePink} !important` : 'rgba(137, 143, 149, 0.3)'};
  ${({ theme }) => theme || ''};
  ${props =>
    props.isUserAddAction &&
    `
   color: ${COLORS.black};
  `}
`;

export const Label = styled.span`
  ${({ theme }) => theme || ''};
  font-size: ${pxToRem('14px')};
`;

export const Chevron = styled(Icon)`
  margin-right: 0.75rem;
  margin-left: auto;

  ${({ theme }) => theme || ''};
`;

export const Option = styled.div`
  z-index: 100000;
  box-sizing: border-box;
  padding: 0 0.5rem;
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('14px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.denimBlue};
  background: ${COLORS.white};
  min-height: ${props => (props.hasHeight ? 'none' : '2rem')};
  width: 100%;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  text-align: left;

  &:hover {
    background: ${COLORS.aliceBlueVariant};
  }

  ${({ theme }) => theme || ''};
  ${props =>
    props.isUserAddAction &&
    `
   color:  ${COLORS.black};
  `}
`;

export const Options = styled.div.attrs(() => ({ className: 'options' }))`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: ${({ setHeight }) => (setHeight ? 'auto' : '10rem')};
  width: 100%;
  max-width: ${pxToRem('260px')};
  overflow: auto;
  top: 2rem;
  z-index: 100000;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);

  &::-webkit-scrollbar {
    width: ${pxToRem('7px')};
    height: ${pxToRem('5px')};
    background-color: ${COLORS.white};
  }

  &::-webkit-scrollbar-thumb {
    height: ${pxToRem('50px')};
    background: ${COLORS.lightCyanGray};
  }

  ${({ theme }) => theme || ''};
  ${props =>
    props.isUserAddAction &&
    `
   max-width: none
  `}
`;

export const CheckIcon = styled(Icon)`
  margin-left: 0;
  margin-right: 0.5rem;

  ${({ theme }) => theme || ''};
`;

export const MinorLabel = styled.label`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('10px')};
  color: rgba(255, 255, 255, 0.5);
  margin: ${pxToRem('10px')} 0 0;
  display: inline-block;
  text-transform: capitalize;
  ${({ isHidden }) => (isHidden ? 'visibility: hidden' : '')};
  position: absolute;
  left: 0.5rem;
  top: -1.25rem;

  ${({ theme }) => theme || ''};
`;
export const ErrorMessage = styled.span`
  color: ${COLORS.red};
`;

export const ErrorLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const ErrorLabel = styled.p`
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  text-align: left;
  font-family: 'RobotoCondensedLight';
  padding-left: 0.5rem;
  margin: 0px;

  ${({ theme }) => theme || ''};
`;
