import { createSelector } from 'reselect';
import { groupBy } from 'ramda';

export const getCerts = ({ certs }) => certs.certData;

export const selectCertsAtributesGroupingBy = (state, attribute) =>
  createSelector(
    getCerts,
    groupBy(cert => cert[attribute])
  )(state);

export const getUssfData = ({ certs }) => certs.ussf;
