import React from 'react';
import LoaderWrapper from '../../../LoaderWrapper';
import { ModalContainer } from '../styled-components';
import DateRangePicker from '../../DateRangePicker';
import Modal from '../../../Modal';

const DownloadBatchListDialog = ({ setDownloadModalOpen, downloadFinancialReportSummary }) => {
  return (
    <Modal
      title="Download Financial Report"
      onClose={() => {
        setDownloadModalOpen(false);
      }}
      size={{ lg: 4 }}
      overflow
    >
      <ModalContainer>
        <LoaderWrapper>
          <DateRangePicker
            onSubmit={downloadFinancialReportSummary}
            buttonLabel="DOWNLOAD"
            fieldDirection="column"
            fieldWidth="100%"
            fieldMargin="20px 0px"
            primary="true"
          />
        </LoaderWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default DownloadBatchListDialog;
