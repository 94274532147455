import { compose, pure, lifecycle } from 'recompose';

export default compose(
  lifecycle({
    componentDidMount() {
      const { fetchActiveUserInfo, paramId } = this.props;
      if (paramId) {
        fetchActiveUserInfo({ userId: paramId });
      }
    }
  }),
  pure
);
