import { compose, pure, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { pipe, toPairs, filter, fromPairs, keys, length, contains } from 'ramda';

import { getReportSettings, getGameUploadType } from '../../../../core/Events/selectors';
import {
  updateReportSettings as updateReportSettingsAction,
  fetchEventInfoReportSettings
} from '../../../../core/Events';
import { addNotifications as addNotificationsAction } from '../../../../core/Notifications';

const countPosition = pipe(
  toPairs,
  filter(([key]) => key.includes('link_position')),
  fromPairs,
  filter(v => v),
  keys,
  length
);

const mapDispatchToProps = dispatch => ({
  onLoad: eventId => dispatch(fetchEventInfoReportSettings(eventId)),
  updateReportSettings: payload => dispatch(updateReportSettingsAction(payload)),
  addNotifications: payload => dispatch(addNotificationsAction(payload))
});

export default compose(
  connect(
    state => ({
      report_settings: {
        score_entry_stats: 'hidden',
        ...getReportSettings(state),
        game_upload_type: getGameUploadType(state)
      },
      isFetching: state.events.eventPageFetching
    }),
    mapDispatchToProps
  ),
  withHandlers({
    onSubmit: ({ updateReportSettings, addNotifications }) => values => {
      const positions = countPosition(values);
      if (contains(values.score_entry_stats, ['optional', 'required']) && positions === 0) {
        addNotifications([
          {
            type: 'error',
            message: 'Required to choose at least one option in "Game Report Link..."'
          }
        ]);
        return { link_position: 'Required to choose at least one option' };
      }
      updateReportSettings(values);
      return undefined;
    }
  }),
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { eventId }
        },
        onLoad
      } = this.props;
      onLoad(eventId);
    }
  }),
  pure
);
