import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';
import { pxToRem } from '../../../../utils/helpers';
import { COLORS, mediaQueries } from '../../../../utils/styles';

export const Content = styled.div`
  display: flex;
  padding: 0 ${pxToRem('15px')};
`;

export const ContainerPadding = styled.div`
  padding: 0rem 1rem;
`;

export const AccountText = styled.span`
font-size: 1rem;
font-family: 'Roboto', 'Helvetica', 'Arial', sans - serif;
font-weight: 400;
line-height: 1.5;
letter-spacing: 0.00938em;
color: ${({ color }) => color || `${COLORS.navyBlue}`}
margin-left: ${({ marginL }) => marginL || 0}
margin-top: ${({ marginT }) => marginT || 0}
display: ${({ display }) => display || null}
`;

export const Label = styled.span`
  font: 400 ${pxToRem('15px')} 'RobotoMedium', sans-serif;
  padding-top: 10px;
  display: block;
`;
export const Description = styled.p`
  margin: ${pxToRem('10px')} 0;
  font: 400 ${pxToRem('13px')} 'RobotoCondensed', sans-serif;
  font-stretch: condensed;
  color: ${COLORS.shuttleGray};
`;
export const FormStyles = styled.div`
  width: 100%;
  margin-top: 30px;
  & Field {
    width: 100%;
  }
  & span {
    color: #484848;
  }
  & select {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
    background: #fff;
    padding: 0px 10px;
    outline: 0px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
      no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 97%;
  }
  .fullNameInput {
    color: #525252 !important;
  }
`;

export const RowWrapper = styled(Row)`
margin - right: 10px!important;
margin - left: 10px!important;
`;

export const ButtonsContainer = styled(Col)`
  display: flex;
  justify-content: end;
  width: 100%;
  ${({ isOnBoarding }) => isOnBoarding && 'justify-content: space-between;'};
`;

export const TextInput = styled(Col)`
  display: flex;
`;

export const TaxSpan = styled.p`
  font-size: 20px;
  padding-top: 5px;
  ${mediaQueries.md`
  font-size: 13px;
  padding-top: 0px;
  `}
  ${`@media screen and (max-width: 768px) {
  padding-top: 8px;
  `}
`;

export const FullNameLabel = styled.span`
  position: absolute;
  z-index: 99;
  margin-top: 8px;
  color: #ccc;
  font-size: 13px;
`;

export const AddressRequiredLabel = styled.span`
  color: ${COLORS.carminePink} !important;
  font-size: 13px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const CollapseSection = styled.div`
  height: ${({ isOnBoarding }) => isOnBoarding && '730px'};
  ${mediaQueries.md`
 height:auto;
 `}
`;
