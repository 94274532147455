import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles, { InputWrapper } from './styles';
import InputError from '../../components/InputError';

const IntegerInput = ({
  classes,
  value,
  onChange,
  style = {
    width: '100%',
    marginRight: '10px'
  },
  required = false,
  disabled = false,
  error,
  helperText = false,
  label = false,
  max = 100000,
  min = 0
}) => (
  <InputWrapper>
    <TextField
      style={style}
      value={value || ''}
      type="number"
      onChange={e =>
        onChange(Math.round(Number(e.target.value.toString().replace(/([^0-9]+)/gi, ''))))
      }
      variant="outlined"
      required={required}
      disabled={disabled}
      error={!!error}
      helperText={helperText}
      label={label && label}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          shrink: classes.slimLabelShrink,
          focused: classes.cssFocused
        }
      }}
      InputProps={{
        inputProps: { min, max },
        notched: false,
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline
        }
      }}
    />
    <InputError>{error || ''}</InputError>
  </InputWrapper>
);

export default withStyles(styles)(IntegerInput);
