import React, { useState } from 'react';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener, FormControlLabel, Radio } from '@material-ui/core';
import { tzfilter } from './config';
import MaterialDatePicker from '../../controls/MaterialDatePicker';
import CarretButton from '../CarretButton';
import Button from '../Button';
import {
  MenuDropdown,
  MenuTitle,
  MenuItem,
  FieldWrapper,
  FilterWrapper
} from './styled-components';
import {
  composeValidators,
  isSameOrAfter,
  isSameOrBefore,
  required
} from '../../../utils/validations';
import { DATE_FORMATS } from '../../../constants';
import { formatGameFilterEndDate, formatGameFilterStartDate } from '../../../utils/helpers';

const theme = {
  noAutoTouch: false,
  wrapper: '',
  label: ' margin-top: 0px !important;',
  input: 'background: #f2f5f7;'
};

const useStyles = makeStyles({
  label: {
    flex: 1,
    width: '100%',
    fontSize: '12px',
    'margin-left': '1px'
  }
});

const Dropdown = ({ expanded, setExpanded, dropDownClickHandler, initialValues }) => {
  const classes = useStyles();
  return (
    <MenuDropdown>
      <Form
        onSubmit={val => {
          const [dateRange] = tzfilter.filter(v => v.value === val.filter);
          const startDate = dateRange.start_date
            ? formatGameFilterStartDate(dateRange.start_date)
            : formatGameFilterStartDate(val.start_date);
          const endDate = dateRange.end_date
            ? formatGameFilterEndDate(dateRange.end_date)
            : formatGameFilterEndDate(val.end_date);

          dropDownClickHandler({
            start_date: startDate,
            end_date: endDate,
            selectedTitle: dateRange.label
          });
          setExpanded(!expanded);
        }}
        initialValues={initialValues}
        render={({ handleSubmit, pristine, invalid, submitting, values }) => (
          <form
            onSubmit={val => {
              handleSubmit(val);
            }}
          >
            {tzfilter.map(({ value, label }, index) => (
              <Field name="filter" type="radio" value={value} key={index}>
                {({ input }) => (
                  <MenuItem>
                    <FormControlLabel
                      value={value}
                      control={<Radio color="primary" {...input} />}
                      label={label}
                      classes={{ label: classes.label }}
                    />
                  </MenuItem>
                )}
              </Field>
            ))}

            {values.filter === tzfilter[3].value && (
              <>
                <FieldWrapper>
                  <Field
                    component={MaterialDatePicker}
                    name="start_date"
                    validate={composeValidators(required, (val, { end_date: endTime }) =>
                      isSameOrBefore(val, endTime, DATE_FORMATS.YYYY_MM_DD)
                    )}
                    placeholder="Start Date"
                    theme={theme}
                    disabledDays={{ before: new Date() }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={MaterialDatePicker}
                    name="end_date"
                    validate={composeValidators(required, (val, { start_date }) =>
                      isSameOrAfter(val, start_date, DATE_FORMATS.YYYY_MM_DD)
                    )}
                    placeholder="End Date"
                    theme={theme}
                    disabledDays={{ before: new Date() }}
                  />
                </FieldWrapper>
              </>
            )}
            <FieldWrapper>
              <Button
                type="submit"
                label="Apply"
                disabled={pristine || invalid || submitting}
                primary
              />
            </FieldWrapper>
          </form>
        )}
      />
    </MenuDropdown>
  );
};

const FilterMenu = ({ fetchGames, platformId, user, filter, setFilter }) => {
  const [expanded, setExpanded] = useState(false);
  const onDropdownExpand = () => {
    setExpanded(!expanded);
  };
  const dropDownClickHandler = ({ start_date, end_date, selectedTitle }) => {
    fetchGames({ start_date, end_date, platformId, userId: user.id });
    setFilter({
      ...filter,
      filter: selectedTitle,
      start_date:
        selectedTitle === 'Custom Date Range'
          ? moment(start_date).format(DATE_FORMATS.YYYY_MM_DD)
          : '',
      end_date:
        selectedTitle === 'Custom Date Range'
          ? moment(end_date).subtract(1, 'day').format(DATE_FORMATS.YYYY_MM_DD)
          : ''
    });
  };

  return (
    <ClickAwayListener
      onClickAway={() => expanded && setExpanded(false)}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <FilterWrapper>
        <MenuTitle onClick={onDropdownExpand}>
          <span>{filter.filter}</span>
          <CarretButton opened={expanded} selected width={1.8} nobg />
        </MenuTitle>
        {expanded && (
          <Dropdown
            expanded={expanded}
            setExpanded={setExpanded}
            dropDownClickHandler={dropDownClickHandler}
            initialValues={filter}
          />
        )}
      </FilterWrapper>
    </ClickAwayListener>
  );
};

export default FilterMenu;
