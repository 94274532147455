import React from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';

import LoaderWrapper from '../../LoaderWrapper';
import AddAvailabilityModal from './AddAvailabilityModal';
import './react-big-calendar.css';
import {
  Wrapper,
  HeaderWrapper,
  ToolbarWrapper,
  ToolbarHeader,
  DayName,
  DateNumber,
  LastTimeslot,
  DateRange,
  HelperText,
  BoldHelperText,
  Chevron,
  Calendar,
  ToolbarAdditional,
  AddIcon,
  DayPickerContainer
} from './styled-components';
import { COLORS, ICONS } from '../../../../utils/styles';
import FREQUENCIES, { AVAILABILITY_TYPES } from './availabilityConstants';

import Enhancer from '../../../containers/Availability';
import generateToolbarEnhancer from '../../../containers/Availability/Toolbar/unconnected';
import { convertUtcToTimezone, writePermissionCheck } from '../../../../utils/helpers';
import { DATE_FORMATS } from '../../../../constants';

require('moment-recur');

moment.updateLocale('en', {
  week: {
    dow: 0
  }
});

BigCalendar.momentLocalizer(moment);
const minTime = moment().hour(7).minute(0).toDate();
const maxTime = moment().hour(23).minute(0).toDate();

const formatDateRange = ({ start, end }, culture, localizer) => {
  const startString = localizer.format(
    start,
    moment(start).isSame(end, 'year') ? 'MMMM D' : 'MMMM D, YYYY'
  );
  const endString = localizer.format(
    end,
    moment(end).isSame(start, 'month') ? 'D, YYYY' : 'MMMM D, YYYY'
  );
  return `${startString} - ${endString}`;
};

/**
 * Takes two moment dates and returns a moment that combines the date and time accordingly
 * @param {Object} time Moment object with desired time
 * @param {Object} day  Moment object with desired date
 * @return {Object} Moment object with combined properties
 */
const dateFromTimeAndDay = (time, day) =>
  moment({
    year: day.year(),
    month: day.month(),
    day: day.date(),
    hour: time.hour(),
    minute: time.minute(),
    second: time.second()
  }).toDate();

/**
 * Takes an availability object and returns an array of original event and all future events
 * based on recurring, frequency, interval, and count properties
 * @param {Object} availabilityObj
 * @return {Array} Array of Moment objects for availability and all future occurrences
 * formatted as calendar events
 */
const parseRecur = availabilityObj => {
  if (!availabilityObj) return [];

  const availability = {
    ...availabilityObj,
    time_from: convertUtcToTimezone(
      availabilityObj.time_from,
      availabilityObj.timezone,
      DATE_FORMATS.DATE_TIME_IOS
    ),
    time_to: convertUtcToTimezone(
      availabilityObj.time_to,
      availabilityObj.timezone,
      DATE_FORMATS.DATE_TIME_IOS
    )
  };

  const initialEvent = {
    id: availability.id,
    title: availability.availability_type,
    allDay: false,
    start: new Date(availability.time_from),
    end: new Date(availability.time_to)
  };
  const events = [initialEvent];

  if (!availability.recurring) return events;

  let futureEvents;

  if (availability.frequency === FREQUENCIES.daily) {
    futureEvents = moment(availability.time_from).recur().every(1).days().next(availability.count);
  } else if (availability.frequency === FREQUENCIES.weekly) {
    futureEvents = moment(availability.time_from)
      .recur()
      .every(availability.interval.map(day => (Number(day) + 1) % 7))
      .daysOfWeek()
      .next(availability.count);
  } else {
    futureEvents = moment(availability.time_from)
      .recur()
      .every(1)
      .months()
      .next(availability.count);
  }

  return [
    ...events,
    ...futureEvents.map(event => ({
      id: availability.id,
      title: availability.availability_type,
      allDay: false,
      start: dateFromTimeAndDay(moment(availability.time_from), moment(event)),
      end: dateFromTimeAndDay(moment(availability.time_to), moment(event))
    }))
  ];
};

const Toolbar = ({
  onNavigate,
  date,
  label,
  showDatePicker,
  setShowDatePicker,
  setShowAddAvailabilityModal,
  setUserAvailabilityNote,
  writeAccess
}) => (
  <ToolbarWrapper>
    <ToolbarHeader>
      <Chevron
        icon={ICONS.CHEVRON_RIGHT}
        color={COLORS.hawkesBlue}
        width={18}
        height={11}
        onClick={() => onNavigate('PREV', moment(date))}
        reverse
      />
      <DateRange>{label}</DateRange>
      <Chevron
        icon={ICONS.CHEVRON_RIGHT}
        color={COLORS.hawkesBlue}
        width={18}
        height={11}
        onClick={() => onNavigate('NEXT', moment(date))}
      />
      <OutsideClickHandler onOutsideClick={() => setShowDatePicker(false)}>
        <Calendar
          icon={ICONS.CALENDAR}
          width={18}
          height={18}
          color={COLORS.denimBlue}
          onClick={() => setShowDatePicker(s => !s)}
        />
        {showDatePicker && (
          <DayPickerContainer>
            <DayPicker
              selectedDays={date}
              onDayClick={day => setShowDatePicker(s => !s) || onNavigate('DATE', day)}
              onDayTouchEnd={day => setShowDatePicker(s => !s) || onNavigate('DATE', day)}
              firstDayOfWeek={0}
            />
          </DayPickerContainer>
        )}
      </OutsideClickHandler>
    </ToolbarHeader>

    <ToolbarAdditional>
      <HelperText>
        Set your <BoldHelperText>Availability</BoldHelperText> by clicking the plus icon and
        selecting the times you will be available or busy.
      </HelperText>
      {writeAccess && (
        <AddIcon
          onClick={() => {
            setUserAvailabilityNote();
            setShowAddAvailabilityModal(s => !s);
          }}
          icon={ICONS.NEW}
          color={COLORS.denimBlue}
          width={26}
          height={26}
        />
      )}
    </ToolbarAdditional>
  </ToolbarWrapper>
);

Toolbar.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  label: PropTypes.string.isRequired,
  showDatePicker: PropTypes.bool,
  setShowDatePicker: PropTypes.func.isRequired,
  setShowAddAvailabilityModal: PropTypes.func.isRequired
};

Toolbar.defaultProps = {
  showDatePicker: false
};

const Header = ({ date, label }) => {
  const isToday = moment(date).isSame(moment(), 'day');
  return (
    <HeaderWrapper>
      <DayName className="desktop">{label.split(' ')[0]}</DayName>
      <DayName className="mobile">{label.split(' ')[0].slice(0, 3)}</DayName>
      <DateNumber isToday={isToday}>{label.split(' ')[1]}</DateNumber>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

const Availability = ({
  showAddAvailabilityModal,
  setShowAddAvailabilityModal,
  setStartTime,
  startTime,
  setEndTime,
  endTime,
  onUpdate,
  onCreate,
  availabilities,
  setEditAvailabilityId,
  editAvailabilityId,
  isFetching,
  deleteUserAvailability,
  currentUserId,
  userId,
  isFetchingPayee,
  localTimezone,
  fetchUserAvailabilityNote,
  availabilityNotefetching,
  availabilityNote,
  setUserAvailabilityNote,
  isSuperAdmin,
  allowAdminUpdates,
  availabilityAccessPermissions
}) => {
  const [defaultDate, setDefaultDate] = React.useState();
  const inCrossEditMode = (userId && currentUserId && currentUserId !== userId) || false;
  const crossEditWriteAllowed = writePermissionCheck(
    userId,
    currentUserId,
    isSuperAdmin,
    availabilityAccessPermissions,
    allowAdminUpdates
  );
  const writeAccess = !inCrossEditMode || (inCrossEditMode && crossEditWriteAllowed);
  return (
    <LoaderWrapper isFetching={isFetching || isFetchingPayee}>
      <Wrapper>
        {showAddAvailabilityModal && (
          <AddAvailabilityModal
            onSubmit={editAvailabilityId ? onUpdate : onCreate}
            onClose={() => {
              setShowAddAvailabilityModal(s => !s);
              setStartTime(null);
              setEndTime(null);
              setEditAvailabilityId(null);
            }}
            startTime={startTime}
            endTime={endTime}
            availability={
              editAvailabilityId &&
              availabilities.find(availability => editAvailabilityId === availability.id)
            }
            deleteUserAvailability={deleteUserAvailability}
            localTimezone={localTimezone}
            availabilityNotefetching={availabilityNotefetching}
            availabilityNote={availabilityNote}
            paramId={userId}
            inCrossEditMode={inCrossEditMode}
          />
        )}
        <BigCalendar
          events={availabilities.reduce(
            (allEvents, event) => [...allEvents, ...parseRecur(event)],
            []
          )}
          startAccessor="start"
          endAccessor="end"
          view="week"
          onView={() => true}
          views={['week']}
          defaultDate={moment(defaultDate).format()}
          onNavigate={newDate => setDefaultDate(newDate)}
          eventPropGetter={event => ({
            style:
              event.title === AVAILABILITY_TYPES.AVAILABLE
                ? { backgroundColor: 'rgb(128 229 102)' }
                : ''
          })}
          components={{
            toolbar: generateToolbarEnhancer({
              setShowAddAvailabilityModal,
              setUserAvailabilityNote,
              writeAccess
            })(Toolbar),
            week: { header: Header }
          }}
          drilldownView={null}
          step={60}
          timeslots={1}
          formats={{
            dayFormat: 'dddd D',
            timeGutterFormat: 'h A',
            dayRangeHeaderFormat: (range, culture, localizer) =>
              formatDateRange(range, culture, localizer),
            eventTimeRangeFormat: ({ start, end }) => {
              if (moment(end).diff(moment(start), 'minutes') <= 30) return null;
              return `${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`;
            },
            eventTimeRangeEndFormat: ({ start, end }) => {
              if (moment(end).diff(moment(start), 'minutes') <= 30) return null;
              return `${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`;
            }
          }}
          min={minTime}
          max={maxTime}
          selectable="ignoreEvents"
          onSelectSlot={({ start, end }) => {
            if (writeAccess) {
              setShowAddAvailabilityModal(true);
              setUserAvailabilityNote();
              setStartTime(start);
              setEndTime(end);
            }
          }}
          onSelectEvent={({ id }) => {
            if (writeAccess) {
              setShowAddAvailabilityModal(true);
              setUserAvailabilityNote();
              fetchUserAvailabilityNote({ id, target: id });
              setEditAvailabilityId(id);
            }
          }}
        />
        <LastTimeslot className="rbc-label">11 PM</LastTimeslot>
      </Wrapper>
    </LoaderWrapper>
  );
};

Availability.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  showAddAvailabilityModal: PropTypes.bool,
  setShowAddAvailabilityModal: PropTypes.func.isRequired,
  setStartTime: PropTypes.func.isRequired,
  startTime: PropTypes.instanceOf(Date),
  setEndTime: PropTypes.func.isRequired,
  endTime: PropTypes.instanceOf(Date),
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  availabilities: PropTypes.arrayOf(Object),
  setEditAvailabilityId: PropTypes.func.isRequired,
  editAvailabilityId: PropTypes.string,
  deleteUserAvailability: PropTypes.func.isRequired
};

Availability.defaultProps = {
  showAddAvailabilityModal: false,
  startTime: null,
  endTime: null,
  availabilities: [],
  editAvailabilityId: null
};

export default Enhancer(Availability);
