import styled from 'styled-components';
import { pxToRem } from '../../../../../../utils/helpers';
import COLORS from '../../../../../../utils/styles';

export const Wrapper = styled.div``;

export const Label = styled.p`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: ${COLORS.errorRed};
  margin: ${pxToRem('10px')} 0;
  text-align: left;
  padding-left: 1.4rem;
`;

export const FieldItem = styled.span`
  text-align: left;
  align-items: start !important;
  display: flex;
`;

export const Message = styled.span`
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoCondensedLight';
  color: ${COLORS.black};
  font-weight: 600;
`;

export const MessageWrapper = styled(Wrapper)`
  text-align: left;
  padding-left: ${pxToRem('18px')};
`;

export const NoteText = styled.span`
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoCondensedLight';
  color: ${COLORS.black};
`;

export const theme = {
  textArea: `
    &::placeholder {
      color: ${COLORS.black};
      font-family: 'RobotoCondensedLight';
      font-weight: 600;
    }
  `
};
