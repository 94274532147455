import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

const ItemContainer = styled.div`
  padding-left: ${pxToRem('8px')};
  text-align: left;
  color: ${COLORS.shuttleGrey};
  font-size: ${pxToRem('13px')};
  font-weight: 100;
  line-height: 2rem;
  border-bottom: 1px solid ${COLORS.altoGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${COLORS.regalBlue};
    color: ${COLORS.white};
    cursor: pointer;
  }

  ${({ theme }) => theme};
`;

export const ItemsContainer = styled.div`
  overflow-y: auto;
  max-height: ${pxToRem('98px')};

  ${({ theme }) => theme};
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme};
`;

export const FieldError = styled.p`
  width: 100%;
  position: absolute;
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: ${COLORS.venetianRed};
  margin: ${pxToRem('6px')} 0 0;
  text-align: center;
`;

export const LeftDropDownError = styled.span`
  color: ${COLORS.carminePink};
  font-size: 0.7rem;
  padding-left: 0.3rem;
`;

export default ItemContainer;
