import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';

import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const HeaderImg = styled.img`
  height: auto;
`;

export const LogoConnect = HeaderImg.extend`
  height: auto;
  width: 95%;
  margin-bottom: ${pxToRem('20px')};
  image-rendering: -webkit-optimize-contrast;
`;

export const Description = styled.p`
  font-family: 'RobotoCondensedLight';
  font-size: 11px;
  color: ${COLORS.white};
  margin: ${pxToRem('20px')} 0 0;
`;

export const ButtonsWrappers = styled(Row)`
  margin: 20px 0px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: ${pxToRem('25px')} 0 ${pxToRem('5px')};
`;

export const LinksWrapper = styled(Row)`
  margin: 20px 0px;
`;

export const Left = styled(Col)`
  text-align: left;
  padding-left: 0px;
`;

export const Right = styled(Col)`
  text-align: right;
`;

export const Note = styled.p`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.white};
  margin-top: ${pxToRem('45px')};
`;

export const Title = styled.h3`
  font-family: 'Roboto';
  font-size: ${pxToRem('19px')};
  color: ${COLORS.white};
  margin: 0;
`;

export const Message = styled.p`
  font-family: 'RobotoLight';
  color: ${COLORS.white};
  font-size: ${pxToRem('15px')};
  text-align: center;
`;

export const StyledInput = styled.input`
  display: block;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoLight';
  background: transparent;
  border: none;
  padding: 0.5rem;
  box-shadow: inset 0 -1px 0 0 ${COLORS.white}55;
  margin: 0;
  width: 100%;
  outline-width: 0;

  &::placeholder {
    color: ${COLORS.white};
    text-transform: capitalize;
  }

  &:focus {
    box-shadow: inset 0 -2px 0 0 ${COLORS.denimBlue};
    ${({ hasError }) =>
      hasError
        ? `box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed}`
        : `box-shadow: inset 0 -2px 0 0 ${COLORS.denimBlue}`};
  }

  ${({ hasValue }) => (hasValue ? `box-shadow: inset 0 -2px 0 0 ${COLORS.white}` : '')};
  ${({ hasError }) => (hasError ? `box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed}` : '')};
`;

export const StyledLabel = styled.label`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: rgba(255, 255, 255, 0.5);
  margin: ${pxToRem('10px')} 0 0;
  display: inline-block;
  text-transform: capitalize;
  ${({ isHidden }) => (isHidden ? 'visibility: hidden' : '')};
  position: relative;
  left: 0.5rem;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -1.25rem;
  left: 0.5rem;
  font-size: ${pxToRem('13px')};
  color: ${COLORS.white};
  font-family: 'RobotoCondensedLight';
`;

export const FooterLink = styled.span`
  cursor: pointer;
  color: ${COLORS.white};
  text-decoration: underline;
`;
