import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';

export const Wrapper = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Message = styled.span`
  color: ${COLORS.denimBlue};
`;

export const ButtonsWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 25rem;
  justify-content: space-between;
  & > * {
    width: 45%;
  }
`;
