import { connect } from 'react-redux';
import { compose, withState } from 'recompose';

import { getSelectedRole } from '../../../core/Auth/selectors';

export default compose(
  connect(
    state => ({
      selected: getSelectedRole(state)
    }),
    null
  ),
  withState('open', 'setOpen', false)
);
