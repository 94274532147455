import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import shortid from 'shortid';

import {
  Container,
  Header,
  Image,
  Title,
  Content,
  EmptyMessageContainer,
  EmptyMessage,
  HelpLinkContainer,
  HelpLink
} from './styled-components';

const HelpBox = ({ helpLinks, imageHeight, imageSrc, title }) => (
  <Container>
    <Header>
      <Image src={imageSrc} imageHeight={imageHeight} />
      <Title>{title}</Title>
    </Header>
    <Content>
      {isEmpty(helpLinks) ? (
        <EmptyMessageContainer>
          <EmptyMessage>There is no content.</EmptyMessage>
        </EmptyMessageContainer>
      ) : (
        helpLinks.map(({ text, link }) => (
          <HelpLinkContainer key={shortid.generate()}>
            <HelpLink href={link} download={`${text}.pdf`} target="blank">
              {text}
            </HelpLink>
          </HelpLinkContainer>
        ))
      )}
    </Content>
  </Container>
);

HelpBox.propTypes = {
  helpLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ),
  imageHeight: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

HelpBox.defaultProps = {
  helpLinks: [],
  imageHeight: '48px'
};

export default HelpBox;
