import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';

import { getAvailableBalance, requestPayout, fetchPayoutFee } from '../../../core/Payments';
import {
  getPayoutFee,
  getMinAndMaxAmount,
  getIsLastPayoutFailed
} from '../../../core/Payments/selectors';
import { crossEditPayeeCheck, getActiveUser } from '../../../core/Auth/selectors';

const mapStateToProps = state => ({
  userId: getActiveUser(state).id,
  payee: crossEditPayeeCheck()(state),
  crossEditPayeeCheck: crossEditPayeeCheck()(state).status,
  batchDetails: state.payments.batchDetails,
  availableBalances: state.payments.availableBalance,
  isFetching: state.payments.isFetching,
  payoutFee: getPayoutFee({ payments: state.payments }),
  minAndMaxAmount: getMinAndMaxAmount({ payments: state.payments }),
  lastPayoutFailed: getIsLastPayoutFailed({ payments: state.payments })
});
const mapDispatchToProps = dispatch => ({
  getAvailableBalanceAction: info => dispatch(getAvailableBalance(info)),
  requestPayoutAction: info => dispatch(requestPayout(info)),
  fetchPayoutFee: info => dispatch(fetchPayoutFee(info))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { payee, userId, getAvailableBalanceAction } = this.props;
      if (payee && userId) {
        getAvailableBalanceAction({ payeeId: payee.id, userId });
      }
    }
  })
);
