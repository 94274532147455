import React, { useEffect } from 'react';
import TaxIdentification from './TaxIdentification';

import { Container, Content, ActualContentContainer } from './styled-components';
import Enhancer from '../../containers/TaxInfo';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import * as paths from '../../../core/paths';

const TaxInfo = ({
  onSubmit,
  setShowEdit,
  isFetching,
  setAgreementPayload,
  userIdx,
  path,
  userId,
  isFundingAdmin,
  currentUserId,
  addPayeeAccountAction,
  editPayeeAccountAction,
  bankAccounts,
  fetchActiveUserInfo,
  pathUrl,
  isSuperAdmin
}) => {
  const isOnBoardingUrl =
    routeBuilder({ userIdx, userId }, paths.START_PROFILE_PAYMENT_METHOD) === path;
  useEffect(
    () => {
      fetchActiveUserInfo({ userId, pathUrl });
    },
    // eslint-disable-next-line
    []
  );
  const previousBtnPath = routeBuilder({ userIdx, userId }, paths.START_PROFILE_PAYMENT_METHOD);
  const skipButtonPath = routeBuilder(
    { userIdx },
    isFundingAdmin ? `${paths.EVENTS}?ev_status=2` : paths.DASHBOARD
  );
  return (
    <>
      <ActualContentContainer isOnBoardingUrl={isOnBoardingUrl}>
        <Container isOnBoardingUrl={isOnBoardingUrl}>
          <Content>
            <TaxIdentification
              onSubmit={onSubmit}
              account={bankAccounts}
              userId={userId}
              currentUserId={currentUserId}
              setShowEdit={setShowEdit}
              isFetching={isFetching}
              isSuperAdmin={isSuperAdmin}
              setAgreementPayload={setAgreementPayload}
              isOnBoardingUrl={isOnBoardingUrl}
              previousBtnPath={previousBtnPath}
              skipButtonPath={skipButtonPath}
              addPayeeAccountAction={addPayeeAccountAction}
              editPayeeAccountAction={editPayeeAccountAction}
            />
          </Content>
        </Container>
      </ActualContentContainer>
    </>
  );
};

export default Enhancer(TaxInfo);
