import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';

import {
  getPaymentMethodMandate,
  getPaymentMethodSecret
} from '../../../../core/Payments/selectors';
import {
  confirmPaymentMethod as confirmPaymentMethodAction,
  fetchAgreement as fetchAgreementAction,
  fetchPaymentMethodSecret as fetchPaymentMethodSecretAction
} from '../../../../core/Payments';
import { getUser } from '../../../../core/Auth/selectors';
import { PAYMENT_METHODS } from '../../../../constants';
import Stripe from '../../../../utils/stripe';
import { AGREEMENT_LOCATIONS } from '../../../../core/Payments/constants';

const mapStateToProps = state => ({
  secret: getPaymentMethodSecret(state),
  user: getUser(state),
  mandate: getPaymentMethodMandate(state)
});

const mapDispatchToProps = dispatch => ({
  fetchSecret: payload => dispatch(fetchPaymentMethodSecretAction(payload)),
  confirmPaymentMethod: payload => dispatch(confirmPaymentMethodAction(payload)),
  fetchAgreement: payload => dispatch(fetchAgreementAction(payload))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('billingDetails', 'setBillingDetails', {}),
  withState('paymentMethodDetails', 'setPaymentMethodDetails', {}),
  withState('creatingAccount', 'setCreatingAccount', false),
  withState('isSubmitting', 'toggleSubmitting', false),
  withHandlers({
    onSubmit: ({
      user,
      confirmPaymentMethod,
      paymentMethodDetails,
      mandate,
      toggleSubmitting,
      setPaymentMethodDetails,
      closeModal
    }) => (billingDetails, formApi) => {
      toggleSubmitting(true);

      if (billingDetails.agreed) {
        new Promise(resolve =>
          confirmPaymentMethod({
            ...paymentMethodDetails,
            agreement: {
              agreed_by_user: user.id,
              doc_id: mandate.data.id,
              agreement_id: mandate.data.doc_agreement.id
            },
            billingDetails,
            resolve
          })
        ).then(() => {
          toggleSubmitting(false);
          setPaymentMethodDetails({});

          formApi.restart();
          closeModal();
        });
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      const { fetchAgreement } = this.props;

      fetchAgreement({ locId: AGREEMENT_LOCATIONS.US_BANK_ACCOUNT_MANDATE });
    },
    componentDidUpdate({ secret: prevSecret }) {
      const {
        secret,
        billingDetails,
        user,
        setPaymentMethodDetails,
        setCreatingAccount,
        creatingAccount,
        addNotifications,
        setDialogDisplay
      } = this.props;
      if (creatingAccount && prevSecret.token !== secret.token) {
        setDialogDisplay('none');
        Stripe.setUpBankAccount({
          details: billingDetails,
          secret: secret.token
        })
          .then(request => {
            setPaymentMethodDetails({
              method: PAYMENT_METHODS.BANK_ACCOUNT,
              payerId: user.payer && user.payer.id,
              requestId: request.id,
              paymentMethodId: request.payment_method && request.payment_method.id,
              accNumber:
                request.payment_method &&
                request.payment_method.us_bank_account &&
                request.payment_method.us_bank_account.last4
            });
          })
          .catch(e => {
            if (e && e.message) {
              addNotifications([
                {
                  type: 'error',
                  message: e.message
                }
              ]);
            }
          })
          .finally(() => {
            setDialogDisplay('block');
            setCreatingAccount(false);
          });
      }
    }
  })
);
