import FileSaver from 'file-saver';
import { compose, lifecycle, pure, withHandlers } from 'recompose';

import { formatDate } from '../../../../utils/parsers';

export default compose(
  withHandlers({
    downloadFinancialReport: ({ fetchFundingBatches }) => ({ start_date, end_date }) => {
      const startDate = formatDate(start_date, 'YYYY-MM-DD HH:mm');
      const endDate = formatDate(end_date, 'YYYY-MM-DD HH:mm');
      new Promise(resolve => {
        fetchFundingBatches({ toCSV: true, resolve, startDate, endDate });
      }).then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' });
        FileSaver.saveAs(blob, `financial_report_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.pagination.onLoadPage();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.paymentsEvent.id !== this.props.paymentsEvent.id) {
        this.props.pagination.onLoadPage();
      }
    }
  }),
  pure
);
