import { compose, withProps, pure, lifecycle } from 'recompose';

export default compose(
  withProps(({ routes, ...props }) => ({
    AuthorizedRoutes:
      routes && routes.filter(route => route.showRoute.every(condition => props[condition]))
  })),
  lifecycle({
    componentDidMount() {
      const { fetchActiveUserInfo, paramId } = this.props;
      if (paramId) {
        fetchActiveUserInfo({ userId: paramId });
      }
    }
  }),
  pure
);
