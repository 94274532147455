import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const EventListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  padding-bottom: 35px;
`;

export const EventItemContainer = styled.div`
  display: flex;
  min-height: 5rem;
  background: ${COLORS.white};
  background: -moz-linear-gradient(top, ${COLORS.white} 0%, ${COLORS.blackSqueeze} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${COLORS.white}), color-stop(100%, ${COLORS.blackSqueeze}));
  background: -webkit-linear-gradient(top, ${COLORS.white} 0%, ${COLORS.blackSqueeze} 100%);
  background: -o-linear-gradient(top, ${COLORS.white} 0%, ${COLORS.blackSqueeze} 100%);
  background: -ms-linear-gradient(top, ${COLORS.white} 0%, ${COLORS.blackSqueeze} 100%);
  background: linear-gradient(to bottom, ${COLORS.white} 0%, ${COLORS.blackSqueeze} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${COLORS.white}', endColorstr='${COLORS.blackSqueeze}', GradientType=0);

  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.pattensBlue};
  }
`;

export const EventInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.8125rem 0.625rem 0.8125rem 2.125rem;
`;

export const EventHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EventStatusIndicator = styled.span`
  min-width: ${pxToRem('12px')};
  min-height: ${pxToRem('12px')};
  max-width: ${pxToRem('12px')};
  max-height: ${pxToRem('12px')};
  border-radius: 50%;
`;

export const ClosedEventIndicator = EventStatusIndicator.extend`
  background: ${COLORS.wildWatermelon};
  background: -moz-linear-gradient(top, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${COLORS.wildWatermelon}), color-stop(100%, ${COLORS.carmine}));
  background: -webkit-linear-gradient(top, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  background: -o-linear-gradient(top, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  background: -ms-linear-gradient(top, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  background: linear-gradient(to bottom, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${COLORS.wildWatermelon}', endColorstr='${COLORS.carmine}', GradientType=0);
`;

export const OpenEventIndicator = EventStatusIndicator.extend`
  background: ${COLORS.kellyGreen};
  background: -moz-linear-gradient(top, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${COLORS.kellyGreen}), color-stop(100%, ${COLORS.forestGreen}));
  background: -webkit-linear-gradient(top, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  background: -o-linear-gradient(top, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  background: -ms-linear-gradient(top, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  background: linear-gradient(to bottom, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${COLORS.kellyGreen}', endColorstr='${COLORS.forestGreen}', GradientType=0);
`;

export const EventHeader = styled.h4`
  margin: 0 0 0 ${pxToRem('8px')};
  color: ${COLORS.denimBlue};
  font-size: ${pxToRem('15px')};
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  max-width: ${pxToRem('310px')};
`;

export const EventDataContainer = styled.div`
  font-size: ${pxToRem('15px')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  max-width: ${pxToRem('120px')};
`;

export const EventHeaderData = styled.span`
  margin-left: ${pxToRem('4px')};
  font-size: ${pxToRem('13px')};
  color: ${COLORS.nightRider};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${pxToRem('100px')};
`;

export const EventHeaderInnerContainer = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EventContentContainer = styled.div`
  margin-top: 0.3rem;
  margin-left: 1.2rem;
`;

export const EventData = styled.span`
  color: ${props => (/darker/.test(props.className) ? COLORS.nightRider : COLORS.darkBlue)};
  font-size: ${pxToRem('11px')};
  font-stretch: condensed;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${pxToRem('30px')};
  font-weight: bold;
`;

export const EventHead = styled.span`
  color: ${props => (/darker/.test(props.className) ? COLORS.nightRider : COLORS.shuttleGrey)};
  font-size: ${pxToRem('11px')};
  font-stretch: condensed;
`;

export const EventSummaryContainer = styled.div`
  display: flex;
  min-width: 44%;
`;
export const EventSummaryData = styled(NavLink)`
  flex: 1;
  min-height:  ${pxToRem('64px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.aliceBlue};
  background: -moz-linear-gradient(top, ${COLORS.aliceBlue} 0%, ${COLORS.solitude} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${
    COLORS.aliceBlue
  }), color-stop(100%, ${COLORS.solitude}));
  background: -webkit-linear-gradient(top, ${COLORS.aliceBlue} 0%, ${COLORS.solitude} 100%);
  background: -o-linear-gradient(top, ${COLORS.aliceBlue} 0%, ${COLORS.solitude} 100%);
  background: -ms-linear-gradient(top, ${COLORS.aliceBlue} 0%, ${COLORS.solitude} 100%);
  background: linear-gradient(to bottom, ${COLORS.aliceBlue} 0%, ${COLORS.solitude} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${
    COLORS.aliceBlue
  }', endColorstr='${COLORS.solitude}', GradientType=0);
  color: ${COLORS.sapphire};
  font-size: ${pxToRem('25px')};
  border-left: 1px solid ${COLORS.pattensBlue};
  position: relative;

  &.active {
    color: ${COLORS.white};
  background: ${COLORS.denimBlue};
  background: -moz-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, ${COLORS.denimBlue}),
    color-stop(100%, ${COLORS.bahamaBlue})
  );
  background: -webkit-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -o-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -ms-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: linear-gradient(to bottom, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  text-shadow: 2px 2px 2px rgba(2, 32, 80, 0.28);
  z-index: 1;
  }
`;

export const EventSummaryDataSelected = EventSummaryData.extend`
  color: ${COLORS.white};
  background: ${COLORS.denimBlue};
  background: -moz-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, ${COLORS.denimBlue}),
    color-stop(100%, ${COLORS.bahamaBlue})
  );
  background: -webkit-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -o-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -ms-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: linear-gradient(to bottom, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  text-shadow: 2px 2px 2px rgba(2, 32, 80, 0.28);
  z-index: 1;
`;

export const GameLink = styled.span`
  color: ${({ disabled }) => (disabled ? COLORS.greyChateau : COLORS.denimBlue)};
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    ${({ disabled }) => (!disabled ? 'text-decoration: underline' : '')};
  }
`;

export const HeaderRest = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;
