import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';

import { pxToRem } from '../../../utils/helpers';
import COLORS from '../../../utils/styles';

export const TableWrapper = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0px;
`;

export const Wrapper = styled.div`
  display: inline;
  flex: 1;
  margin-bottom: 50px;
`;

export const Container = styled(Row)`
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ padding }) => padding && `padding: ${padding};`}
`;

export const UserListWrapper = styled(Container)`
  ${`@media (max-width: 1530px) { padding: 0px 200px 0 150px;}`}
  ${`@media (max-width: 1680px) { padding: 0px 300px 0 200px;}`}
  ${`@media (max-width: 1820px) { padding: 0px 300px 0 250px;}`}
`;

export const AddAdminSpan = styled.span.attrs({
  'data-intercom-target': 'AddGroupAdmin'
})``;

export const GroupInfoContainer = styled(Container)`
${`@media (max-width: 1530px) { padding: 20px 200px 0 150px;}`}
${`@media (max-width: 1680px) { padding: 20px 300px 0 200px;}`}
${`@media (max-width: 1820px) { padding: 20px 300px 0 250px;}`}
`;

export const GroupTitleContainer = styled(Container)`
${`@media (max-width: 1530px) { padding: 20px 200px 0 150px;}`}
${`@media (max-width: 1680px) { padding: 20px 300px 0 200px;}`}
${`@media (max-width: 1820px) { padding: 20px 300px 0 250px; justify-content: space-between;}`}
`;

export const NoteFieldContainer = styled(Container)`
${`@media (max-width: 1530px) { padding: 20px 300px 0 170px;}`}
${`@media (max-width: 1680px) { padding: 20px 400px 0 220px;}`}
${`@media (max-width: 1820px) { padding: 20px 450px 0 270px;}`}
`;

export const GroupTileFieldContainer = styled(Container)`
${`@media (max-width: 1530px) { padding: 10px 0 10px 20px;}`}
${`@media (max-width: 1680px) { padding: 10px 0 10px 20px;}`}
${`@media (max-width: 1820px) { padding: 10px 0 10px 30px;}`}
`;

export const InnerContainer = styled(Col)`
  width: 400;
`;

export const Heading = styled.h3`
  font-family: 'Barlow';
  font-size: ${pxToRem('40px')};
  color: ${COLORS.newDenimBlue};
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-top: 0rem;
`;

export const CreatedOn = styled(Heading)`
  font-size: ${pxToRem('13px')};
  color: ${COLORS.black};
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
`;

export const FormContainerElement = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  background: #fff;
`;

export const NoteWrapper = styled(Col)`
  width: 500;
`;

export const NoteContainer = styled(Container)`
  background-color: rgb(251 239 211);
  border-radius: 3px;
`;

export const NoteLabel = styled(Col)`
  width: 70;
  padding: 8px 0 0 3px;
  font-family: 'Barlow';
  ${({ isHeading }) => (isHeading ? 'font-weight: bold;' : '')}
  font-size: ${pxToRem('13px')};
`;

export const Label = styled(Col)`
  text-transform: capitalize;
  font-family: 'Barlow';
  font-size: ${pxToRem('14px')};
  ${({ isHeading }) => (isHeading ? 'font-weight: bold;' : '')}
`;

export const LabelWrapper = styled(Row)``;

export const Input = styled.input``;

export const TableHeading = styled.h2`
  font-family: barlow;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  margin-top: 10px;
  padding-bottom: 19px;
`;
