import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';

export const ButtonWrapper = styled(Row)`
  margin: 20px 0px;
`;

export const ButtonContainer = styled(Col)``;

export const DateFieldContainer = styled.div``;

export const DateContainer = styled.div`
  display: flex;
  div svg {
      padding-top: 10px;
    }
  }
`;

export const DateFieldLabel = styled.div`
  margin-top: 22px;
  font-size: 11px;
  color: #798993;
`;
