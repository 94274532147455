import { compose, withHandlers, lifecycle, withState } from 'recompose';
import { allEventsId, eventFilterLimit } from '../../../constants';

const searchEventParms = {
  LIMIT: eventFilterLimit,
  SORTBY: '-created_at'
};
export default compose(
  withState('showOptions', 'toggleShowOptions', false),
  withState('clickedOutside', 'setClickedOutside', false),
  withState('searchValue', 'setValue', ''),
  withHandlers({
    onSearchEvent: ({ fetchFilteredEvents, setValue, filters, isUserAddAction }) => searchValue => {
      setValue(searchValue);
      fetchFilteredEvents({
        filters: { ...filters, search: searchValue },
        sort: searchEventParms.SORTBY,
        noLimit: true,
        isFetchingList: true,
        isUserAddAction
      });
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.clickedOutside !== prevProps.clickedOutside) {
        this.props.setValue(this.props.name);
      }
      const eventId = this.props.selectedEvent && this.props.selectedEvent.id;
      const prevEventId = prevProps.selectedEvent && prevProps.selectedEvent.id;
      if (eventId && (!prevEventId || prevEventId !== eventId) && eventId !== allEventsId) {
        this.props.setValue('');
        this.props.fetchEvent(eventId);
      }
    }
  })
);
