import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';

const combineClassNames = require('classnames');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: '0 0 20px 10px',
    width: '420px'
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  formControlLabel: {
    borderLeft: '1px solid #d2d9df',
    borderTop: '1px solid #d2d9df',
    borderRight: '1px solid #d2d9df',
    marginRight: '0',
    '&:first-child': {
      borderRadius: '3px 3px 0 0'
    },
    '&:last-child': {
      borderBottom: '1px solid #d2d9df',
      borderRadius: '0 0 3px 3px'
    }
  },
  label: {
    fontSize: '13px'
  }
}));

export default function RadioButton({
  id,
  ariaLabel,
  label,
  classNames,
  labelClasses,
  dataItems,
  ...rest
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          id={id}
          aria-label={ariaLabel}
          className={combineClassNames(classNames, classes.textField)}
          {...rest}
        >
          {dataItems.map((val, index) => (
            <FormControlLabel
              key={index}
              value={val.value}
              control={<Radio color="primary" />}
              label={<span className={classes.label}>{val.label}</span>}
              className={classes.formControlLabel}
              classes={{ ...labelClasses }}
              disabled={val.disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

RadioButton.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  classNames: PropTypes.string,
  dataItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  labelClasses: PropTypes.object
};

RadioButton.defaultProps = {
  id: '',
  ariaLabel: '',
  label: '',
  classNames: '',
  dataItems: [],
  labelClasses: {}
};
