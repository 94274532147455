export const ASSIGNMENT_STATUS = {
  unpublished: 'unpublished',
  pending: 'pending',
  declined: 'declined',
  accepted: 'accepted',
  unassigned: 'unassigned',
  completed: 'completed',
  unassignedOff: 'unassignedOff',
  unassignedAss: 'unassignedAss'
};

export const UNASSIGNED_ASSIGNMENT_STATUS = ['unassignedOff', 'unassignedAss', 'unpublished'];

export default {
  ASSIGNMENT_STATUS,
  UNASSIGNED_ASSIGNMENT_STATUS
};
