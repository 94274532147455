import React from 'react';
import { Col } from 'react-flexbox-grid';
import { Switch } from 'react-router-dom';
import { nest } from 'recompose';
import Title from '../Title';
import Button from '../Button';
import Icon from '../Icon';
import { ICONS } from '../../../utils/styles';
import { Description, ButtonsWrappers } from '../../layouts/AuthLayout/styled-components';
import { getLoginURL } from '../../../utils/helpers';
import Frosted from '../Auth/styled-components';
import FullBackgroundImage from '../../layouts/FullBackgroundImage';
import AuthLayout from '../../layouts/AuthLayout';

const Wrapper = nest(
  FullBackgroundImage,
  ({ children }) => <Frosted authFlow>{children}</Frosted>,
  AuthLayout,
  Switch
);

const SuccessMessage = () => (
  <Wrapper>
    <>
      <Title>
        <Icon height={34} width={32} icon={ICONS.CHECK_CIRCLE} />
        Email Sent
      </Title>
      <Description>Check your email to activate your account and sign in.</Description>
      <ButtonsWrappers center="xs">
        <Col xs={8}>
          <a href={getLoginURL()}>
            <Button name="login" label="Sign In" width="150px" primary />
          </a>
        </Col>
      </ButtonsWrappers>
    </>
  </Wrapper>
);

export default SuccessMessage;
