import React from 'react';
import { Field, Form } from 'react-final-form';

import Enhancer from '../../containers/PaymentMethod/AddBank';
import { Checkbox, GhostButton, Input } from '../../global-material-components';
import {
  Error,
  FieldWrapper,
  FormStyles,
  GhostContainer,
  GhostStyle,
  TermsContainer
} from './style-components';
import { PAYMENT_METHODS } from '../../../constants';
import config from './config';
import Button from '../Button';
import MandateModal from './Modals/mandate';

const AddBank = ({
  user,
  secret,
  fetchSecret,
  mandate,
  paymentMethodDetails,
  setCreatingAccount,
  onSubmit,
  setBillingDetails,
  isSubmitting
}) => {
  const [showMandate, toggleMandate] = React.useState(false);

  React.useEffect(() => {
    window.setAccount();
  }, [paymentMethodDetails]);

  return (
    <>
      <FormStyles>
        <Form
          onSubmit={(data, formApi) => onSubmit(data, formApi)}
          initialValues={{ ...config.initialValues({ user }) }}
          mutators={{
            setAccount: (args, state, utils) => {
              utils.changeValue(
                state,
                'accNumber',
                () =>
                  paymentMethodDetails &&
                  paymentMethodDetails.accNumber &&
                  `****${paymentMethodDetails.accNumber}`
              );
            }
          }}
          render={({ handleSubmit, form: { mutators }, submitting, pristine, invalid, values }) => {
            window.setAccount = mutators.setAccount;

            return (
              <>
                <form onSubmit={handleSubmit}>
                  {config.fields.map(({ component: Component, name, ...field }) => (
                    <Field key={name} {...field} name={name}>
                      {({ input, meta: { error } }) => (
                        <Component
                          {...input}
                          error={error}
                          noEndAdornmentStyle={{ width: '10%' }}
                        />
                      )}
                    </Field>
                  ))}
                  <GhostContainer>
                    <Field name="accNumber">
                      {({ input, meta: { error } }) => (
                        <FieldWrapper>
                          <Input
                            {...input}
                            type="text"
                            label="Click Link to add your bank account"
                            placeholder="Click Link to add your bank account"
                            disabled
                            endAdornment={
                              <Button
                                type="button"
                                disabled={
                                  pristine ||
                                  submitting ||
                                  invalid ||
                                  !!values.accNumber ||
                                  isSubmitting
                                }
                                primary
                                submitting={secret.fetching}
                                label="Link"
                                onClick={() => {
                                  setBillingDetails({ name: values.name, email: values.email });
                                  setCreatingAccount(true);
                                  fetchSecret({
                                    method: PAYMENT_METHODS.BANK_ACCOUNT,
                                    payerId: user.payer && user.payer.id
                                  });
                                }}
                              />
                            }
                            endAdornmentStyle={{ width: '50%' }}
                          />

                          {error && <Error>{error}</Error>}
                        </FieldWrapper>
                      )}
                    </Field>
                  </GhostContainer>
                  <TermsContainer>
                    <Field name="agreed" type="checkbox">
                      {({ input }) => <Checkbox {...input} disabled={!values.accNumber} />}
                    </Field>
                    I agree to the
                    <GhostButton style={GhostStyle} onClick={() => toggleMandate(true)}>
                      terms and conditions
                    </GhostButton>
                  </TermsContainer>
                  <Button
                    type="submit"
                    disabled={!values.agreed}
                    primary
                    label="Add Account"
                    submitting={isSubmitting}
                  />
                  <MandateModal open={showMandate} toggle={toggleMandate} mandate={mandate} />
                </form>
              </>
            );
          }}
        />
      </FormStyles>
    </>
  );
};

AddBank.propTypes = {};

export default Enhancer(AddBank);
