import styled from 'styled-components';

const styles = () => ({
  cssLabel: {
    top: '-7px',
    '&$cssFocused': {
      color: '#167cd4'
    }
  },
  cssFocused: {},
  cssOutlinedInput: {
    height: '40px',
    backgroundColor: '#ffff',
    '&$cssFocused $notchedOutline': {
      borderColor: '#167cd4'
    }
  },
  notchedOutline: {},
  slimLabelShrink: {
    transform: 'translate(0px, -9px) scale(0.75)!important'
  }
});

export const InputWrapper = styled.div`
  width: 100%;
  margin-right: 10px;
`;

export default styles;
