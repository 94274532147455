import {
  assoc,
  prop,
  isNil,
  always,
  nth,
  compose,
  cond,
  match,
  test,
  T,
  update,
  isEmpty
} from 'ramda';

const TOKEN = 'idToken';
const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const REFERRER = 'redirect-to-referrer';
const USER = 'user';
const GAMES_EVENT = 'games-event';
const REPORTS_EVENT = 'reports-event';
const PAYMENTS_EVENT = 'payments-event';
const PEOPLE_EVENT = 'people-event';
const PEOPLE_EVENT_PATH = 'people-tab-path';
const GROUP_PAGE_REDIRECT_PATH = 'group-redirect-path';
const GROUP_PAGE_REDIRECT_LABEL = 'group-redirect-label';
const RETURN_URL = 'return url';
const SELECTED_ROLE = 'selected-role';
const PLATEFORM_ID = 'platform-id';

export const INDEX_IS_NULL = 'Index is null, could not deduce.';
/**
 *  will parse location for user id.
 * if user id is present will return id. Else will return undefined
 */
export const userIdxGetter = () =>
  cond([
    [test(/^\/u\/(\d+)/), compose(parseInt, nth(1), match(/^\/u\/(\d+)/))],
    [T, always(undefined)]
  ])(window.location.pathname);

export const STORAGE_NAME = 'info';

/**
 *
 * Will return an array containing all users
 */
export const getCurrentStorage = () => JSON.parse(localStorage.getItem(STORAGE_NAME)) || [];

/**
 * Takes a user index and returns his local storage
 * @param {Number} usrIdx user index
 * returns void
 */
export const getUserStorage = usrIdx => {
  const currentStorage = JSON.parse(localStorage.getItem(STORAGE_NAME)) || [];
  if (isNil(currentStorage) || isEmpty(currentStorage)) {
    return {};
  }
  return nth(usrIdx, currentStorage) || {};
};

/**
 * Takes the user index and a user storage. will persist userStorage in
 * localStorage
 * This function should not be used outside of this file.
 * returns void
 * @param {Number} usrIdx
 * @param {Object} updatedUserStorage
 */
const updateUserEntry = (usrIdx, updatedUserStorage) => {
  if (isNil(usrIdx)) {
    throw new Error(INDEX_IS_NULL);
  }
  const currentStorage = JSON.parse(localStorage.getItem(STORAGE_NAME)) || [];

  const newStorage = update(usrIdx, updatedUserStorage, currentStorage);
  localStorage.setItem(STORAGE_NAME, JSON.stringify(newStorage));
};

/**
 * Takes an object with initialValues and adds it to the "session" Array
 * returns new index for the session just created.
 * @param {Object} initialValues
 */
export const createUserStorage = initialValues => {
  const currentStorage = JSON.parse(localStorage.getItem(STORAGE_NAME)) || [];

  if (isEmpty(currentStorage)) {
    localStorage.setItem(STORAGE_NAME, JSON.stringify([initialValues]));
  } else {
    localStorage.setItem(STORAGE_NAME, JSON.stringify(currentStorage.concat(initialValues)));
  }

  return currentStorage.length;
};

/**
 * Takes a key value pair and an optional user index.
 * Will update or set given key value pair in user session.
 * If userIndex is not provided, will parse the location in look for current index
 * returns void
 * @param {String} itemKey
 * @param {String | Number} value
 * @param {Number} usrIdx
 */
export const setItem = (itemKey, value, usrIdx = userIdxGetter()) => {
  if (isNil(usrIdx)) {
    throw new Error(INDEX_IS_NULL);
  }
  const currentStorage = getUserStorage(usrIdx);
  const newStorage = assoc(itemKey, value)(currentStorage);

  updateUserEntry(usrIdx, newStorage);
};

/**
 * Takes an item and an optional index
 * returns the value at index
 * @param {String} item
 * @param {Number} usrIdx
 */
export const getItem = (item, currentIdx = userIdxGetter()) => {
  if (isNil(currentIdx)) {
    return null;
  }
  const currentStorage = getUserStorage(currentIdx);
  return prop(item, currentStorage);
};

/**
 * Takes an object and persists it into the store.
 * This function is equivelent to calling setItem multiple times.
 * Only more performant for this type of operation
 * returns void
 * @param {Object} obj
 * @param {Number} usrIdx
 */
export const setMultiItem = (obj, usrIdx = userIdxGetter()) => {
  const currentStorage = getUserStorage(usrIdx);
  const newStorage = { ...currentStorage, ...obj };
  updateUserEntry(usrIdx, newStorage);
};

/**
 * Clears user storage
 */
export const clearEverything = () => {
  localStorage.removeItem(STORAGE_NAME);
};

export const setTokens = ({ tokens, usrIdx }) => setMultiItem(tokens, usrIdx);

export const setReferrer = referer => localStorage.setItem(REFERRER, referer);
export const setPeopleTabPath = ({ pathUrl }) => localStorage.setItem(PEOPLE_EVENT_PATH, pathUrl);
export const setGroupPageRedirectPath = ({ path }) =>
  localStorage.setItem(GROUP_PAGE_REDIRECT_PATH, path);
export const setGroupPageRedirectLabel = ({ label }) =>
  localStorage.setItem(GROUP_PAGE_REDIRECT_LABEL, label);
export const setReturnUrl = ({ path }) => localStorage.setItem(RETURN_URL, path);
export const getReturnUrl = () => localStorage.getItem(RETURN_URL);

export const getGroupPageRedirectPath = () => localStorage.getItem(GROUP_PAGE_REDIRECT_PATH);
export const getGroupPageRedirectLabel = () => localStorage.getItem(GROUP_PAGE_REDIRECT_LABEL);
export const getPeopleTabPath = () => localStorage.getItem(PEOPLE_EVENT_PATH);
export const getReferrer = () => localStorage.getItem(REFERRER);

export const removeReferrer = () => localStorage.removeItem(REFERRER);

export const setUser = ({ user, usrIdx }) => setItem(USER, user, usrIdx);
export const getGamesEvent = ({ usrIdx }) => getItem(GAMES_EVENT, usrIdx);
export const getReportsEvent = ({ usrIdx }) => getItem(REPORTS_EVENT, usrIdx);
export const getPaymentsEvent = ({ usrIdx }) => getItem(PAYMENTS_EVENT, usrIdx);
export const getRefreshToken = ({ usrIdx }) => getItem(REFRESH_TOKEN, usrIdx) || '';
export const getAccessToken = ({ usrIdx }) => getItem(ACCESS_TOKEN, usrIdx) || '';
export const getPeopleEvent = ({ usrIdx }) => getItem(PEOPLE_EVENT, usrIdx) || '';
export const getSelectedRole = ({ usrIdx }) => getItem(SELECTED_ROLE, usrIdx) || '';

export const setPeopleEvent = ({ id, usrIdx }) => setItem(PEOPLE_EVENT, id, usrIdx);
export const getIdToken = () => getItem(TOKEN) || '';

export const persistGamesEvent = ({ event, usrIdx }) => setItem(GAMES_EVENT, event, usrIdx);
export const persistReportsEvent = ({ event, usrIdx }) => setItem(REPORTS_EVENT, event, usrIdx);
export const persistPaymentsEvent = ({ event, usrIdx }) => setItem(PAYMENTS_EVENT, event, usrIdx);
export const persistSelectedRole = ({ role, usrIdx }) => setItem(SELECTED_ROLE, role, usrIdx);

export const persistGroupName = ({ id, usrIdx }) => setItem(PLATEFORM_ID, id, usrIdx);
export const getGroupName = ({ usrIdx }) => getItem(PLATEFORM_ID, usrIdx) || null;
