import React from 'react';
import config from './config';
import HelpIcon from '../HelpIcon';
import COLORS, { ICONS } from '../../../utils/styles';
import DasboardWrapper from './styled-components';
import { URLS } from '../../../constants';
import Enhancer from '../../containers/Dashboards';

const UserDashboard = props => {
  const Dashboard = config(props);

  return (
    <DasboardWrapper>
      <Dashboard {...props} />
      <HelpIcon
        width={25}
        height={25}
        icon={ICONS.QUESTION_MARK_HOLLOW}
        color={COLORS.white}
        title="Help"
        link={URLS.HELP_DESK}
        linkTraget="_blank"
        addClassName="mobile"
        clickable
      />
    </DasboardWrapper>
  );
};

export default Enhancer(UserDashboard);
