import styled from 'styled-components';
import COLORS from '../../../utils/styles';

export const RowsWrapper = styled.div`
  margin-left:${({ defaultAlignment }) => (defaultAlignment ? '12px' : '0px')};
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  border:${({ wideView }) => (wideView ? `1px solid ${COLORS.veryLightGray}` : `none`)}
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 0px;
  font-family: 'BarlowSemiBold';
  gap:${({ wideView }) => (wideView ? '' : '15px')}
`;

export const PrimaryContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  z-index: ${({ opened }) => opened && 1};
  border-bottom: 1px solid ${COLORS.lightCyanGray};
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.31);
  padding: 20px;
`;

export const TotalBalance = styled.p`
  font-size: 15px;
  margin: 0;
`;

export const VerticalLine = styled.div`
  border-left: 1px solid ${COLORS.veryLightGray};
  border-radius: 5px;
  height: 48px;
`;

export const BoldSpan = styled.span`
  padding: 3px 0px 3px 5px;
  font-size: 15px;
  display: inline-block;
  width: ${({ width }) => width || 'auto'};
`;

export const SpanBlue = styled.span`
  color: ${COLORS.denimBlue};
  padding-top: 3px;
  font-size: ${({ fontSize }) => fontSize || '15px'};
`;

export const AddFundBtn = styled.button`
  font-size: 13px;
  padding: 7px;
  border: 1px solid #ccc;
  background: ${COLORS.navyBlue};
  color: #fff;
  outline: 0;
  cursor: pointer;
  &:disabled {
    background: ${COLORS.veryLightGray};
    color: ${COLORS.lightGrey};
  }
  font-weight: bold;
`;

export const SpanContainer = styled.div`
  text-align: ${({ textCenter }) => (textCenter ? 'center' : '')};
`;
