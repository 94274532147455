import styled from 'styled-components';

import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

export const IconWrapper = styled.div`
  padding: 0.5rem;
  cursor: pointer;
`;

export const Label = styled.span`
  font-family: 'RobotoCondensed';
  font-size: ${pxToRem('10px')};
  font-stretch: condensed;
  text-align: left;
  color: ${COLORS.shuttleGrey};
`;

export const Resend = Label.extend`
  color: ${COLORS.denimBlue};
  cursor: pointer;
  text-decoration: underline;
`;
