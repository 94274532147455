import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Icon from '../../Icon';

export const StyledInput = styled.input`
  display: flex;
  flex: 1;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoLight';
  background: transparent;
  border: none;
  padding: ${pxToRem('10px')} 0.5rem 0.5rem;
  margin: 0;
  outline-width: 0;
  ${({ addable }) => (!addable ? 'width: 100%' : '')};

  &::placeholder {
    color: ${COLORS.white};
    text-transform: capitalize;
  }

  ${({ theme }) => theme || ''};
`;

export const StyledLabel = styled.label`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('13px')};
  color: rgba(255, 255, 255, 0.5);
  margin: ${pxToRem('10px')} 0 0;
  display: inline-block;
  text-transform: capitalize;
  ${({ isHidden }) => (isHidden ? 'visibility: hidden' : '')};
  position: absolute;
  left: 0.5rem;
  top: -1.25rem;

  ${({ theme }) => theme || ''};
`;

export const Wrapper = styled.div`
  position: relative;
  box-shadow: inset 0 -1px 0 0 ${COLORS.white}88;

  &.hasValue {
    box-shadow: inset 0 -2px 0 0 ${COLORS.white};
  }

  ${({ hasValue }) => (hasValue ? `box-shadow: inset 0 -2px 0 0 ${COLORS.white}` : '')};
  ${({ hasError }) => (hasError ? `box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed}` : '')};

  ${({ theme }) => theme || ''};
`;

export const Error = styled.span`
  position: absolute;
  bottom: -1.25rem;
  left: 0.5rem;
  font-size: ${pxToRem('13px')};
  color: ${COLORS.white};
  font-family: 'RobotoCondensedLight';
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  ${({ theme }) => theme || ''};
`;

export const Count = styled.span`
  font-size: ${pxToRem('12px')};
  width: 1rem;
  height: 1rem;
  background: ${COLORS.denimBlue};
  display: inline-block;
  border-radius: 50%;
  color: ${COLORS.white};
  text-align: center;
  padding: ${pxToRem('2px')} ${pxToRem('1px')} 0 0;
  margin-right: 0.5rem;
  ${({ theme }) => theme || ''};
`;
