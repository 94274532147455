import { takeLatest, put } from 'redux-saga/effects';

import { SHOW, HIDE, TOGGLE } from '.';
import Logger from '../../utils/logger';

function* toggleProgressVisibility({ payload }) {
  try {
    yield put({ type: TOGGLE, payload });
  } catch (e) {
    Logger.error(e.message);
  }
}

export const progressSagas = [
  takeLatest(SHOW, toggleProgressVisibility),
  takeLatest(HIDE, toggleProgressVisibility)
];

export default progressSagas;
