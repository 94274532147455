import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, StyledButton, FlexDiv, BoldBlue, Subheader } from './styled-components';
import { numToCurrency } from '../../../../utils/parsers';

const SummaryBar = ({
  totalGames,
  totalPay,
  handleBackToDateSelection,
  deleteActiveBatch,
  setBatchModalOpen,
  reviewMode,
  reviewBatchId,
  payment_initiated,
  setAssignmentWarnings,
  minTransferAmount
}) => {
  return (
    <Wrapper>
      <FlexDiv>
        <StyledButton onClick={handleBackToDateSelection}>
          {reviewMode ? 'Back To Batch List' : 'Back To Date Selection'}
        </StyledButton>
      </FlexDiv>
      <FlexDiv>
        {!reviewMode && (
          <>
            <BoldBlue>{totalGames}</BoldBlue>
            <Subheader>Games</Subheader>
          </>
        )}

        <BoldBlue>{numToCurrency(totalPay)}</BoldBlue>
        <Subheader>to Pay</Subheader>
        {!reviewMode && (
          <StyledButton
            filled
            disabled={!totalGames}
            onClick={() => {
              if (!reviewMode && totalPay < minTransferAmount) {
                setAssignmentWarnings({
                  assignmentWarnings: [
                    {
                      message: `Pay Amount must be greater than or equal to $${
                        minTransferAmount && minTransferAmount.toFixed(2)
                      }`,
                      error: true,
                      header: 'PAYMENT ERROR'
                    }
                  ]
                });
              }
              setBatchModalOpen(true);
            }}
          >
            Review Batch
          </StyledButton>
        )}
        {!payment_initiated && (
          <div style={{ marginLeft: '5px' }}>
            {' '}
            <StyledButton
              filled
              onClick={() => {
                deleteActiveBatch(reviewBatchId, reviewMode);
              }}
            >
              Delete Batch
            </StyledButton>
          </div>
        )}
      </FlexDiv>
    </Wrapper>
  );
};

SummaryBar.propTypes = {
  totalGames: PropTypes.number,
  totalPay: PropTypes.number,
  handleBackToDateSelection: PropTypes.func.isRequired,
  setBatchModalOpen: PropTypes.func.isRequired,
  reviewMode: PropTypes.bool.isRequired
};

SummaryBar.defaultProps = {
  totalGames: 1010,
  totalPay: 25050
};

export default SummaryBar;
