import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
  StyledLabel,
  StyledInput,
  Message,
  ErrorLabel,
  ErrorMessage,
  ErrorLabelWrapper
} from './styled-components';

/**
 * Built to integrate with React-Final-Form, simple input
 * @param {Object} input contains onChange and value attributes
 * @param {String} error error to display under input and underline input in red
 * @param {Boolean} invalid wether the field is valid or not
 * @param {String} placeholder  Label to display on dropdown
 * @param {Object} theme *optional* for customizing styles
 * @param {Object} message *optional* message to display under input
 * @param {Boolean} showError *optional* wether to show the error message if error is defined
 */
const Input = ({
  input: { value, onChange },
  meta: { error },
  placeholder: label,
  theme,
  maxLength,
  message,
  showError,
  disabled
}) => (
  <>
    <Wrapper theme={theme.wrapper}>
      <StyledLabel isHidden={!value} theme={theme.label}>
        {label}
      </StyledLabel>
      <StyledInput
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={label}
        hasValue={value}
        autoComplete="off"
        theme={theme.input}
        maxLength={maxLength}
        hasError={!!(showError && error)}
        disabled={disabled}
      />
    </Wrapper>
    {message && <Message>{message}</Message>}
    <ErrorLabelWrapper>
      <ErrorLabel>{!!(showError && error) && <ErrorMessage>{error}</ErrorMessage>}</ErrorLabel>
    </ErrorLabelWrapper>
  </>
);

Input.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired,
  placeholder: PropTypes.string,
  theme: PropTypes.shape({
    wrapper: PropTypes.string
  }),
  maxLength: PropTypes.number,
  message: PropTypes.string
};

Input.defaultProps = {
  placeholder: '',
  theme: {},
  maxLength: null,
  message: null
};

export default Input;
