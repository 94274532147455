import { composeValidators, mustBeEmail, required } from '../../../../../utils/validations';

export default {
  modal: {
    title: 'Add New Group Admin',
    titleStyle: {
      textAlign: 'left',
      fontSize: '16px',
      marginRight: '50px'
    },
    size: { xs: 2, sm: 2, md: 3, lg: 3 },
    wrapperStyle: 'background-image: none;'
  },

  fields: [
    {
      name: 'firstName',
      validate: required,
      type: 'text',
      label: 'First Name',
      placeholder: 'First Name'
    },
    {
      name: 'lastName',
      validate: required,
      type: 'text',
      label: 'Last Name',
      placeholder: 'Last Name'
    },
    {
      name: 'email',
      validate: composeValidators(required, mustBeEmail),
      type: 'email',
      label: 'Email',
      placeholder: 'Email'
    }
  ],

  inputProps: ({ classes }) => ({
    InputProps: { style: { height: '35px' } },
    InputLabelProps: {
      classes: {
        root: classes.root,
        shrink: classes.slimLabelShrink
      }
    }
  }),

  button: {
    type: 'submit',
    label: 'Add',
    customStyle: { textTransform: 'capitalize' },
    primary: true
  }
};
