import { takeEvery, call, put, all, takeLatest } from 'redux-saga/effects';

import { mergeData } from '../../utils/parsers';
import Api from '../../utils/api-wrapper';
import {
  setFetchingInvoice,
  setProductInvoices,
  setPlatformSubscriptions,
  setPlatform
} from './actions';
import { FETCH_PRODUCT_INVOICES, FETCH_PLATFORM_SUBSCRIPTIONS, SET_GROUP } from './constants';
import { errorDecorate } from '../Progress/helper';
import { persistGroupName, userIdxGetter } from '../../utils/auth';

export function* fetchInvoices({ prop, query }) {
  const invoices = yield call(Api.fetchProductInvoices, query);
  yield put(setProductInvoices({ data: mergeData(invoices), prop }));
}

export function* processFetchProductInvoices({ platformId, product, config }) {
  yield put(setFetchingInvoice(product, true));

  yield all(
    Object.keys(config).map(prop =>
      call(fetchInvoices, {
        prop,
        query: {
          ...config[prop](),
          platformId,
          product
        }
      })
    )
  );
  yield put(setFetchingInvoice(product, false));
}

export function* fetchProductInvoices({ payload }) {
  yield errorDecorate(processFetchProductInvoices, payload);
}

export function* processFetchSubscriptions({ platformId }) {
  yield put(setPlatformSubscriptions(null));
  const subscriptions = yield call(Api.fetchPlatformSubscriptions, {
    platformId
  });
  yield put(setPlatformSubscriptions(mergeData(subscriptions)));
}

export function* fetchSubscriptions({ payload }) {
  yield errorDecorate(processFetchSubscriptions, payload);
}

export function* setGroupName({ payload: { id, usrIdx = userIdxGetter() } }) {
  yield put(setPlatform(id));
  yield call(persistGroupName, { id, usrIdx });
}

export const platformSagas = [
  takeEvery(FETCH_PRODUCT_INVOICES, fetchProductInvoices),
  takeEvery(FETCH_PLATFORM_SUBSCRIPTIONS, fetchSubscriptions),
  takeLatest(SET_GROUP, setGroupName)
];

export default platformSagas;
