import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import EventIcon from '@material-ui/icons/Event';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import HelpIcon from '@material-ui/icons/Help';

import * as PATHS from '../../../core/paths';
import { REPORT_TYPES, URLS } from '../../../constants';
import { OWN_EVENTS } from '../../../core/Events/eventConstants';
import { PAGINATION_AWARE_PREFIX as eventPrefix } from '../../containers/Events';
import { PAGINATION_AWARE_PREFIX as reportPrefix } from '../../containers/Reports';
import { history } from '../../../core/store';

export const SELECTABLE_ROLES = {
  ADMIN_OR_ASSIGNOR: 'admin_assignor',
  OFFICIAL_OR_ASSESSOR: 'official_assessor'
};

export const getDashboardsList = ({ userRoles: roles = {}, persistRole }) => {
  let list = [];

  if (
    (roles.assignor || roles.admin || roles.groupAdmin || roles.superAdmin) &&
    roles.officialAssessor
  )
    list = [
      {
        label: 'Admin / Assignor',
        onClick: role => persistRole({ role }),
        to: PATHS.DASHBOARD,
        value: SELECTABLE_ROLES.ADMIN_OR_ASSIGNOR
      },
      {
        label: 'Official / Assessor',
        onClick: role => persistRole({ role }),
        to: PATHS.DASHBOARD,
        value: SELECTABLE_ROLES.OFFICIAL_OR_ASSESSOR
      }
    ];

  return list;
};

export const getNavRoutes = props => [
  {
    label: 'DASHBOARD',
    to: PATHS.DASHBOARD,
    requireAuth: 'showDashboard',
    icon: <DashboardIcon />,
    list: getDashboardsList(props)
  },
  {
    label: 'EVENTS',
    to: `${PATHS.EVENTS}?${eventPrefix}_status=${OWN_EVENTS}`,
    isActive: () => /\/u\/\d+\/events/.test(history.location.pathname),
    icon: <EventIcon />
  },
  {
    label: 'GAMES',
    to: PATHS.GAMES,
    requireAuth: 'isNotFundingAdmin',
    icon: <SportsBasketballIcon />
  },
  {
    label: 'PEOPLE',
    to: PATHS.PEOPLE,
    requireAuth: 'isSuperAdmin',
    icon: <PeopleAltIcon />
  },
  {
    label: 'REPORTS',
    to: `${PATHS.REPORTS}?${reportPrefix}_type=${REPORT_TYPES.assessment}`,
    isActive: () => /\/u\/\d+\/reports/.test(history.location.pathname),
    requireAuth: 'canViewReports',
    icon: <DescriptionIcon />
  },
  {
    label: 'PAYMENTS',
    to: PATHS.PAYMENTS,
    requireAuth: 'canViewPayments',
    icon: <AttachMoneyIcon />
  },
  {
    label: 'PROFILE',
    to: PATHS.PROFILE_INFORMATION,
    icon: <AccountCircleIcon />,
    class: 'mobile'
  },
  {
    label: 'HELP',
    to: URLS.HELP_DESK,
    icon: <HelpIcon />,
    target: '_blank',
    class: 'mobile',
    isExternalLink: true
  }
];
