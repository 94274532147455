import { compose } from 'recompose';
import { connect } from 'react-redux';

import { getAllUserAdminEvents } from '../../../../../core/Auth/selectors';
import { getMenuNotifications } from '../../../../../core/Notifications/selectors';
import {
  fetchAllUserEvents,
  linkGamesToEvent as linkGamesToEventAction
} from '../../../../../core/Events';
import unconnected from './unconnected';

const mapStateToProps = state => ({
  events: getAllUserAdminEvents(state),
  notifications: getMenuNotifications(state)
});

const mapDispatchToProps = dispatch => ({
  fetchAllUserEvents: payload => dispatch(fetchAllUserEvents(payload)),
  linkGamesToEvent: payload => dispatch(linkGamesToEventAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
