import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import { pxToRem } from '../../../utils/helpers';
import { HEIGHTS } from '../../../utils/styles';

export default styled(Row)`
  min-height: 100%;
`;

export const StyledCol = styled(Col)`
  width: 100%;
  position: absolute;
  top: ${pxToRem(HEIGHTS.fullEventBar)};
`;
