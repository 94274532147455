import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

const LoaderWrapper = ({ isFetching, children, simple, theme }) => (
  <>{isFetching ? <Spinner theme={theme} simple={simple} /> : children}</>
);

LoaderWrapper.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  simple: PropTypes.bool,
  theme: PropTypes.shape({
    wrapper: PropTypes.string,
    animation: PropTypes.string
  })
};

LoaderWrapper.defaultProps = {
  simple: true,
  theme: {
    wrapper: '',
    animation: ''
  }
};

export default LoaderWrapper;
