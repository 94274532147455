import React from 'react';

import ConfirmationPopup from '../../../popupModals/ComfirmationPopup';
import config from './config';
import Enhancer from '../../../../containers/GroupInfo/Modals/RemoveGroupAdmin';

const RemoveGroupAdmin = ({ showModal, setShowModal, onConfirm }) => (
  <ConfirmationPopup
    {...config.popup}
    confirmationPopupAction={showModal}
    setConfirmationPopupAction={setShowModal}
    primaryActionFun={() => {
      onConfirm();
      setShowModal(false);
    }}
  />
);

export default Enhancer(RemoveGroupAdmin);
