import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Container = styled.div`
  width: 100%;
`;

export const HelpText = styled.label`
  color: ${COLORS.shuttleGrey};
  font-size: 0.8rem;
  display: inline-block;
  margin-bottom: 0.8rem;
`;

export const MTextArea = styled.textarea`
  border-color: ${COLORS.altoGray};
  font-family: 'RobotoCondensed';
  color: ${COLORS.denimBlue};
  width: 100%;
  box-sizing: border-box;
  padding: ${pxToRem('3px')} 0 0 ${pxToRem('8px')};

  &::placeholder {
    color: ${COLORS.altoGray};
  }

  & {
    resize: ${props => props.resize};
  }

  ${({ customStyle }) => customStyle}
`;
export default Container;
