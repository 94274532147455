import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Field } from 'react-final-form';

import Dropdown from '../Dropdown';
import Input from '../Input';
import { Label } from './styled-component';

const LocationRange = ({ distance, location, options, color, theme }) => (
  <Row>
    <Col xs={2} md={4}>
      <Label color={color}>{distance.label}</Label>
    </Col>
    <Col xs={2} md={1}>
      <Field
        component={Input}
        name={distance.name}
        theme={theme.input}
        placeholder={distance.placeholder}
      />
    </Col>
    <Col xs={2} md={2}>
      <Label color={color}>{location.label}</Label>
    </Col>
    <Col xs={6} md={5}>
      <Field
        component={Dropdown}
        name={location.name}
        options={options}
        theme={theme.dropdown}
        placeholder={location.placeholder}
        displayValue
      />
    </Col>
  </Row>
);

export default LocationRange;
