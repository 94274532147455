import styled from 'styled-components';
import { COLORS, HEIGHTS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const ModalContent = styled.div`
  box-sizing: border-box;
  padding: ${pxToRem('20px')};
`;

export const WarningDiv = styled.div`
  border: ${pxToRem('1px')} solid ${COLORS.veryLightGray};
  border-left: ${pxToRem('3px')} solid ${COLORS.warningYellow};
  width: ${pxToRem('355px')};
  padding: ${pxToRem('10px')};
  font-size: ${pxToRem('14px')};
  color: ${COLORS.offBlack};
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const TableRow = styled.div`
  background: ${({ header }) => (header ? COLORS.headerBlue : COLORS.white)};
  color: ${({ header }) => (header ? COLORS.darkBlue : COLORS.offBlack)};
  ${({ header }) => header && `text-transform: uppercase`};
  font-size: ${pxToRem('13px')};
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  padding: ${pxToRem('10px')};
  ${({ header }) => !header && `border-bottom: 1px solid ${COLORS.headerBlue}`};
  ${({ header }) => !header && `margin-right: ${pxToRem('-17px')}`};
  ${({ isMargin }) => isMargin && `margin-top: 20px;`};

  &:last-child {
    border-bottom: none;
  }
`;

export const TableContentContainer = styled.div`
  max-height: 25vh;
  border: ${pxToRem('2px')} solid ${COLORS.headerBlue};
  overflow-x: hidden;
  margin-bottom: ${pxToRem('20px')};

  &::-webkit-scrollbar {
    border-left: ${pxToRem('1px')} solid ${COLORS.headerBlue};
    background: ${COLORS.white};
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border: ${pxToRem('3px')} solid transparent;
    border-radius: ${pxToRem('9px')};
    background-clip: content-box;
  }
`;

export const TableText = styled.p`
  padding: 0;
  margin: 0;
  display: ${({ value }) => value && 'flex'};
`;

export const Wrapper = styled.div``;
export const Label = styled.span`
  font-size: ${pxToRem('14px')};
  color: ${COLORS.offBlack};
  float: left;
  width: 40%;
`;
export default {
  datePicker: {
    wrapper: ``,
    label: `
      color: ${COLORS.offBlack};
    `,
    input: `
      background: transparent;
    `,
    noAutoTouch: true
  },
  dropdown: {
    wrapper: ``,
    dropableContainer: {
      clickable: ``,
      caption: `
        color: ${COLORS.offBlack};
        border: 1px solid ${COLORS.altoGray};
      `,
      placeholder: `
        text-align: left;
        font-family: 'Roboto';
        font-size: ${pxToRem('14px')};
        color: ${COLORS.offBlack};
      `,
      icon: `
        position: relative;
        bottom: ${pxToRem('1px')};
        right: ${pxToRem('6px')};
      `,
      content: ``,
      label: `
        font-size: ${pxToRem('14px')};
        color: ${COLORS.offBlack};
      `
    },
    itemsContainer: `
      border: 1px solid ${COLORS.offBlack};
      ::-webkit-scrollbar {
        width: ${pxToRem('6px')};
        position: absolute;
        background-color: ${COLORS.white};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${COLORS.azureGray};
      }
    `,
    dropdownItem: `
      color: ${COLORS.offBlack};
      line-height: 2;
      font-family: 'Roboto';
      font-size: ${pxToRem('14px')};
      background-color: ${COLORS.white};
      height: ${pxToRem('24px')};
      border: none;

      &:hover {
        background-color: ${COLORS.denimBlue};
        color: ${COLORS.offBlack};
      }
      `
  }
};
export const PaymentMouseOverPopoverWrapper = styled.span`
  cursor: pointer;
  color: #f5a623;
  display: inline-block;
  padding-left: 0.6em;
  & > div {
    font-size: 0.4px;
    & > svg {
      width: 0.8em;
      height: 0.8em;
      font-size: 1.5rem;
    }
  }
`;

export const HeaderRow = styled.div`
  background: ${COLORS.dopeGray};
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${pxToRem('10px')} ${pxToRem('30px')};
  box-sizing: border-box;
  font-family: 'Roboto';
  min-height: ${HEIGHTS.summaryBar};
`;
