import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import Error from '../InputError';
import { Select, LabelSecondary } from './styled-components';

// Based on https://codepen.io/rkchauhan/pen/ONJEYL
const Dropdown = ({
  name,
  options,
  value,
  onChange,
  required,
  error,
  label,
  placeholder,
  dropdownWidth,
  columnSize,
  borderRadius,
  backgroundColor,
  borderColor,
  outline,
  dropdownHeight,
  onFocus,
  color
}) => (
  <>
    <Row middle="xs">
      {label && (
        <Col xs={columnSize || 6}>
          <LabelSecondary htmlFor={name}>{label}</LabelSecondary>
        </Col>
      )}
      <Col xs={label ? 5 : 12} xsOffset={label ? 1 : 0}>
        <Select
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          width={dropdownWidth}
          height={dropdownHeight}
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          outline={outline}
          onFocus={onFocus}
          color={color}
        >
          {placeholder && <option defaultValue>{placeholder}</option>}
          {options.map(({ value: optionValue, label: optionLabel }, i) => (
            <option value={optionValue} key={`${value + i}`}>
              {optionLabel}
            </option>
          ))}
        </Select>
        {error && <Error>{error}</Error>}
      </Col>
    </Row>
  </>
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  dropdownWidth: PropTypes.string
};

Dropdown.defaultProps = {
  value: '',
  label: '',
  required: false,
  placeholder: '',
  error: '',
  dropdownWidth: null
};

export default Dropdown;
