import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import ROLE_IDS from '../../../core/Roles/roleConstants';
import buildConfig from './config';
import SideList from '../SideList';
import generateUserMain from '../SideList/Generic/UserMain';
import generateUserExpanded from '../SideList/Generic/Expanded';
import { Wrapper, Option, CheckIcon } from './styled-components';
import { ICONS, COLORS } from '../../../utils/styles';
// import Popover from '../../global-material-components/Popover';
import Enhancer from '../../containers/Lists/AssignmentsList';
// import Icon from '../Icon';

const generateHeader = ({
  type,
  setRoles,
  setSelectedUser,
  setSelectedRoleId,
  clearParams
  // allowSelfAssignment
}) =>
  pure(
    () => (
      <Wrapper>
        <Option
          selected={type === 'official'}
          onClick={() => {
            if (type !== 'official') {
              setSelectedUser(null);
              clearParams();
            }
            setSelectedRoleId(ROLE_IDS.OFFICIAL);
            setRoles({ alias: 'official', list: ['Official'] });
          }}
        >
          <CheckIcon
            width={20}
            height={20}
            icon={type === 'official' ? ICONS.CHECK_CIRCLE : ICONS.CIRCLE}
            color={type === 'official' ? COLORS.denimBlue : COLORS.hawkesBlue}
          />
          OFFICIALS
        </Option>
        <Option
          selected={type === 'assessor'}
          onClick={() => {
            if (type !== 'assessor') {
              setSelectedUser(null);
              clearParams();
            }
            setSelectedRoleId(ROLE_IDS.ASSESSOR);
            setRoles({ alias: 'assessor', list: ['Assessor'] });
          }}
        >
          <CheckIcon
            width={20}
            height={20}
            icon={type === 'assessor' ? ICONS.CHECK_CIRCLE : ICONS.CIRCLE}
            color={type === 'assessor' ? COLORS.denimBlue : COLORS.hawkesBlue}
          />{' '}
          ASSESSORS
        </Option>
      </Wrapper>
    )
    // )
  );

const summaryInfo = [
  { label: 'Age', name: 'age', width: '6ch' },
  { label: 'Rank', name: 'rank', width: '6ch' },
  { label: 'Cert.', name: 'cert', width: '11ch' },
  { label: 'State', name: 'state', width: '15ch' },
  { label: 'Assigned', name: user => user.assignments_count, width: '7ch' }
];

export const AssignmentsList = Enhancer(({ handleUpdateCategories, ...props }) => {
  return (
    <SideList
      onLoad={props.onLoad}
      onFetchItemDetail={props.onFetchItemDetail}
      fetchNextOfficials={props.onFetchNextOfficials}
      items={props.users}
      type={props.roles.alias}
      role={props.roles.alias}
      selectedProp="eventRoleId"
      mainComponent={generateUserMain({ summaryInfo })}
      expandedComponent={generateUserExpanded({
        onUpdateCategories: handleUpdateCategories,
        canEdit: props.isGamesEventGroupAdmin || props.isGamesEventAdmin,
        allowSelfAssignment: props.allowSelfAssignment
      })}
      headerComponent={generateHeader({
        type: props.roles.alias,
        setRoles: props.setRoles,
        setSelectedUser: props.setSelectedUser,
        setSelectedRoleId: props.setSelectedRoleId,
        clearParams: props.clearParams,
        allowSelfAssignment: props.allowSelfAssignment
      })}
      config={buildConfig(props)}
      setSelectedUser={props.setSelectedUser}
      noCollapse
      singleSelect
      addClassName
      infinity
      fetchEventUsersCalendarData={props.fetchEventUsersCalendarData}
      eventUsersList={props.eventUsersList}
      setEventUsersList={props.setEventUsersList}
      usersDemographicsList={props.usersDemographicsList}
      setUsersDemographicsList={props.setUsersDemographicsList}
      {...props}
    />
  );
});

AssignmentsList.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onFetchItemDetail: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(Object).isRequired,
  roles: PropTypes.shape({
    alias: PropTypes.string
  }).isRequired,
  setRoles: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  setSelectedRoleId: PropTypes.func.isRequired,
  handleUpdateCategories: PropTypes.func.isRequired,
  isGamesEventAdmin: PropTypes.bool.isRequired,
  allowSelfAssignment: PropTypes.bool.isRequired,
  clearParams: PropTypes.func.isRequired
};
AssignmentsList.defaultProps = {
  onLoad: () => {},
  onFetchItemDetail: () => {},
  users: [],
  roles: {},
  setRoles: () => {},
  setSelectedUser: () => {},
  setSelectedRoleId: () => {},
  handleUpdateCategories: () => {},
  isGamesEventAdmin: false,
  allowSelfAssignment: false,
  clearParams: () => {}
};

export default React.memo(AssignmentsList);
