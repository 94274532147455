import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';

// TODO: Early optimization. We should detect common titles across the whole app the put them here.
export default styled.h3`
  color: ${COLORS.slateGray};
  font-weight: 100;
  font-size: 19px;
  font-family: 'FiraSansUltraLight';
  text-transform: capitalize;
  margin-bottom: 0;
`;
