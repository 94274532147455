import React from 'react';
import PropTypes from 'prop-types';

import Enhancer from '../../containers/Modals';

const Modals = ({ modals, showModals }) => (
  <>
    {modals.map(({ name, component: Component }) => showModals[name] && <Component key={name} />)}
  </>
);

Modals.propTypes = {
  modals: PropTypes.arrayOf(Object),
  showModals: PropTypes.shape({})
};

Modals.defaultProps = {
  modals: [],
  showModals: {}
};

export default Enhancer(Modals);
