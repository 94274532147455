import { compose, withState, withHandlers, lifecycle } from 'recompose';
import moment from 'moment';

import FREQUENCIES from '../../components/Profile/Availability/availabilityConstants';
import { DATE_FORMATS } from '../../../constants';

const parseIntoDate = (date, time) =>
  moment(date)
    .hour(moment(time, 'h:mm A').hour())
    .minute(moment(time, 'h:mm A').minute())
    .seconds(0)
    .toDate();

const formatFormData = ({
  date,
  from,
  to,
  frequency,
  interval,
  count,
  availability_type,
  timezone,
  note,
  paramId
}) => ({
  time_from: moment(parseIntoDate(date, from)).format(DATE_FORMATS.FULL_DATE_TIME),
  time_to: moment(parseIntoDate(date, to)).format(DATE_FORMATS.FULL_DATE_TIME),
  recurring: frequency !== FREQUENCIES.never,
  frequency,
  interval: interval ? interval.map((day, i) => (day ? i : null)).filter(day => day !== null) : [],
  count: Number(count) || null,
  availability_type,
  timezone,
  note: note || null,
  paramId
});

export default compose(
  withState('showAddAvailabilityModal', 'setShowAddAvailabilityModal', false),
  withState('startTime', 'setStartTime', null),
  withState('endTime', 'setEndTime', null),
  withState('editAvailabilityId', 'setEditAvailabilityId', null),
  withHandlers({
    onCreate: ({ onCreateUserAvailability }) => availability =>
      onCreateUserAvailability(formatFormData(availability)),
    onUpdate: ({ onUpdateUserAvailability, editAvailabilityId }) => availability =>
      onUpdateUserAvailability({
        id: editAvailabilityId,
        data: formatFormData(availability)
      })
  }),
  lifecycle({
    componentDidMount() {
      const { paramId, pathUrl, fetchActiveUserInfo, fetchAvailabilities } = this.props;
      fetchActiveUserInfo({ userId: paramId, pathUrl });
      fetchAvailabilities({ paramId });
    },
    componentWillUnmount() {
      if (this.props.calendarIcon) {
        this.props.setShowCalendarAction({ show: false, calendarActive: false });
      }
    }
  })
);
