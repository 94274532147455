import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
  width: 40%;
  margin: 20px 0px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1.4rem;
`;

export const TitleBar = styled.div`
  background-color: ${COLORS.blackSqueeze};
  font-family: 'RobotoCondensed';
  font-size: 1rem;
  text-transform: uppercase;
  height: 3rem;
  line-height: 3rem;
  font-weight: bold;
  padding-left: 1rem;
  color: ${COLORS.greenVogue};
`;

export const HalfContent = styled.div`
  width: 50%;
`;
