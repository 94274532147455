import React from 'react';
import { Divider } from '@material-ui/core';

import { SectionContainer } from './styled-components';
import { Select, H3 } from '../../global-material-components';

const options = [
  {
    value: 'test',
    label: 'Test'
  },
  {
    value: 'test2',
    label: 'Test2'
  },
  {
    value: 'test3',
    label: 'Test3'
  },
  {
    value: 'test4',
    label: 'Test4'
  }
];

export default function Buttons() {
  const [selected, setSelected] = React.useState(null);

  return (
    <>
      <H3>Selects</H3>
      <Divider />

      <SectionContainer>
        <Select
          options={options}
          label="Test Label"
          style={{ width: '200px', marginTop: '30px' }}
          value={selected}
          onChange={setSelected}
        />
      </SectionContainer>
    </>
  );
}
