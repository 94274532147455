import moment from 'moment';
import MultiSelectDropdown from '../Reusable/MultiSelectDropdown';
import Dropdown from '../Reusable/Dropdown';
import MultipleInput from '../Reusable/MultipleInput';
import EmailUsersModal from '../SideList/Modals/EmailUsersModal';
import MinMax from '../Reusable/MinMax';
import MaterialDatePicker from '../../controls/MaterialDatePicker';
import MaterialTimePicker from '../MaterialTimePicker';
import {
  allAvailableProvinces,
  OFFICIAL_MIN_GRADE,
  ASSESSOR_MIN_GRADE,
  LICENSE_STATUSES,
  PAYMENT_PROFILE_FILTER
} from '../../../constants';
import theme from '../SideList/styled-components';
import { fromMomentTo12Hours, from12HoursToMoment } from '../../../utils/parsers';
import { composeValidators, isSameOrBefore } from '../../../utils/validations';
import { capitalize, sortListByKey, pxToRem } from '../../../utils/helpers';
import LocationRange from '../Reusable/LocationRange';
import COLORS from '../../../utils/styles';
import roleConstants from '../../../core/Roles/roleConstants';

export default ({
  showEmailSelectedModal,
  filter,
  toggleShowEmailSelectedModal,
  sendEmail,
  downloadCSV,
  categories = [],
  readOnly,
  disabled,
  selectedRoleId,
  eventInfo,
  complexes = []
}) => {
  const roleGade = selectedRoleId === 5 ? OFFICIAL_MIN_GRADE : ASSESSOR_MIN_GRADE;
  const Filters = [
    {
      component: MinMax,
      name: ['age_min', 'age_max'],
      placeholder: 'Age',
      theme: theme.multipleInput,
      hasCustomFields: true,
      convert: true,
      maxLength: 2
    },
    {
      component: MinMax,
      name: ['certyr_min', 'certyr_max'],
      placeholder: 'Cert Yr.',
      theme: theme.multipleInput,
      hasCustomFields: true,
      convert: true,
      maxLength: 4,
      minLength: 4
    },
    {
      component: Dropdown,
      name: 'cert_level',
      placeholder: 'Cert Level',
      options: roleGade.slice(1).map(grade => ({
        name: grade.label,
        value: grade.label
      })),
      theme: theme.multiSelectDropdown
    },
    {
      component: Dropdown,
      name: 'cert_status',
      placeholder: 'Cert. Status',
      options: Object.keys(LICENSE_STATUSES).map(key => ({
        name: capitalize(LICENSE_STATUSES[key]),
        value: LICENSE_STATUSES[key]
      })),
      theme: theme.multiSelectDropdown
    },
    {
      component: MultiSelectDropdown,
      name: 'user.address_primary.state',
      placeholder: 'State/Province',
      options: allAvailableProvinces.map(({ name, value }) => ({
        name,
        value
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MultipleInput,
      name: 'user.address_primary.postal_code',
      placeholder: 'Zip Code',
      maxLength: 7,
      theme: theme.multipleInput
    },
    {
      component: Dropdown,
      name: 'user.gender',
      placeholder: 'Gender',
      options: [
        {
          name: 'Male',
          value: 'male'
        },
        {
          name: 'Female',
          value: 'female'
        }
      ],
      theme: theme.multiSelectDropdown
    },
    {
      component: MultiSelectDropdown,
      name: 'categories_filtered.category_id',
      placeholder: 'Category',
      options: categories.map(category => ({
        value: category.id,
        name: category.name
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      placeholder: 'Date',
      parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
      format: value => (value ? moment(value, 'YYYY-MM-DD').toDate() : null),
      readOnly,
      component: MaterialDatePicker,
      name: 'filter_date'
    },
    {
      placeholder: 'Start Time',
      format: from12HoursToMoment,
      parse: fromMomentTo12Hours,
      component: MaterialTimePicker,
      name: 'start_time',
      disabled,
      validate: composeValidators(
        (val, { end_time: endTime, filter_date: filterDate }) =>
          filterDate &&
          isSameOrBefore(val, endTime, undefined, '"Start Time" cannot be greater than "End Time"')
      ),
      alwaysShowError: true
    },
    {
      placeholder: 'End Time',
      format: from12HoursToMoment,
      parse: fromMomentTo12Hours,
      readOnly,
      disabled,
      component: MaterialTimePicker,
      name: 'end_time'
    },
    {
      component: LocationRange,
      name: ['distance', 'location'],
      location: {
        label: 'miles to',
        placeholder: 'Complex',
        name: 'location'
      },
      distance: {
        label: 'Distance To Field',
        placeholder: '',
        name: 'distance'
      },
      options: sortListByKey(
        [...new Map(complexes.map(location => [location.name, location])).values()].map(
          location => ({
            value: `${location.name},${location.address},${location.city},${location.state},${location.postal_code}`,
            name: location.name
          })
        )
      ),
      theme: {
        dropdown: {
          ...theme.multiSelectDropdown,
          value: `font-size: ${pxToRem('12px')} !important`
        },
        input: {
          input: `color: ${COLORS.black};`
        }
      },
      hasCustomFields: true,
      isDistanceFilter: true,
      getCount: value => (value[0] && value[1] ? 1 : 0)
    },
    {
      component: Dropdown,
      name: 'payment_profile',
      placeholder: 'Payment Profile',
      options: Object.keys(PAYMENT_PROFILE_FILTER).map(key => ({
        name: capitalize(PAYMENT_PROFILE_FILTER[key]),
        value: PAYMENT_PROFILE_FILTER[key]
      })),
      theme: theme.multiSelectDropdown
    }
  ];

  const utils = {};

  utils.filter = {
    getCount: values => {
      let count = 0;
      Filters.forEach(fltr => {
        const value = Array.isArray(fltr.name)
          ? fltr.name.map(name => values[name]).filter(val => val)
          : values[fltr.name];
        if (value) {
          if (fltr.getCount) {
            count += fltr.getCount(value);
          } else {
            count += Array.isArray(value) ? value.length : !!value;
          }
        }
      });
      return count;
    }
  };

  return {
    sort: {
      options: [
        {
          name: 'name',
          label: 'Name'
        },
        {
          name: 'age',
          label: 'Age'
        },
        {
          name: 'rank',
          label: 'Rank'
        },
        {
          name: 'city',
          label: 'City'
        },
        {
          name: 'state',
          label: 'State'
        },
        {
          name: 'postal_code',
          label: 'Zip'
        },
        {
          name: 'distance',
          label: 'Distance',
          disabled: !(filter && filter.filterData && filter.filterData.distance)
        }
      ]
    },
    filters:
      !eventInfo || (eventInfo && eventInfo.required_payment_profile)
        ? Filters
        : Filters.slice(0, -1),
    actions: {
      options: [
        {
          component: EmailUsersModal,
          label: 'Email Selected',
          showOption: ['hasSelected'],
          showModal: showEmailSelectedModal,
          onClick: toggleShowEmailSelectedModal,
          onSubmit: sendEmail,
          eventInfo,
          role_ids: [roleConstants.ADMIN, roleConstants.ASSIGNOR, roleConstants.FUNDING_ADMIN]
        },
        {
          label: 'Download List',
          showOption: [],
          showModal: false,
          onClick: downloadCSV
        }
      ]
    },
    utils
  };
};
