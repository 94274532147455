import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import { COLORS } from '../../../utils/styles';
import Icon from '../Icon';

export const TileText = styled.span`
  color: ${COLORS.offBlack};
`;

export const FormStyles = styled.div`
  width: 100%;
  & div {
    width: 100%;
  }
  & Field {
    width: 100%;
  }
  & input {
    width: 100%;
  }
  & span {
    margin-left: 10px;
    color: red;
  }
  & select {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
    background: #fff;
    padding: 0px 10px;
    outline: 0px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
      no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 97%;
  }
`;

export const RowElementCenter = styled(Row)`
  justify-content: center;
  align-item: center;
  margin-left: 0px !important;
  margin-right: 0px !important;
`;

export const AddBtn = styled.button`
  font-size: 16px;
  padding: 5px 20px;
  border: 1px solid #ccc;
  background: ${COLORS.navyBlue};
  color: #fff;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background: ${COLORS.veryLightGray};
    color: ${COLORS.lightGrey};
  }
`;

export const ConfirmationIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
`;

export const GhostContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;
