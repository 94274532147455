import { compose } from 'recompose';
import { connect } from 'react-redux';
import unconnected from './unconnected';
import {
  fetchUpcomingAssignments as fetchUpcomingAssignmentsAction,
  fetchUpcomingGames as fetchUpcomingGamesAction
} from '../../../core/User';
import { getLoggedInUserIndex, getUser } from '../../../core/Auth/selectors';
import { storeGamesEventAndRedirect } from '../../../core/Games';
import { setEvents as setEventsAction } from '../../../core/Events';

const mapStateToProps = state => ({
  userIdx: getLoggedInUserIndex(state),
  user: getUser(state),
  fetching: state.user.fetching,
  upcomingGames: (state.user && state.user.upcomingGames) || [],
  upcomingAssignments: (state.user && state.user.upcomingAssignments) || []
});

const mapDispatchToProps = dispatch => ({
  fetchUpcomingGames: payload => dispatch(fetchUpcomingGamesAction(payload)),
  fetchUpcomingAssignments: payload => dispatch(fetchUpcomingAssignmentsAction(payload)),
  storeGamesEventAndRedirect: payload => dispatch(storeGamesEventAndRedirect(payload)),
  setEvents: payload => dispatch(setEventsAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
