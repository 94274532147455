import React from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';

import Tags from '../../Tags';
import { Wrapper, StyledLabel, StyledInput, StyledIcon, Count } from './styled-components';
import { COLORS, ICONS } from '../../../../utils/styles';

/**
 * Built to integrate with React-Final-Form, multi-input that stores each values as an array
 * @param {Object} input contains onChange and value attributes
 * @param {String} error error to display under input and underline input in red
 * @param {String} placeholder  Label to display on dropdown
 * @param {Object} theme *optional* for customizing styles
 * @param {Boolean} withTags *optional* adds removable tags underneath input, defaults to true
 */
const MultipleInput = ({
  input: { value, onChange },
  error,
  placeholder: label,
  current,
  setCurrent,
  theme,
  withTags,
  maxLength,
  isCount
}) => {
  let values = value || [];
  values = Array.isArray(values) ? values : [values];
  const addable = !!current.trim() && values && !values.find(val => val === current);

  return (
    <>
      <Wrapper className={values.length ? 'hasValue' : ''} theme={theme.wrapper}>
        {isCount && <Count theme={theme.count}>{values.length}</Count>}
        <StyledLabel isHidden={!current} theme={theme.label}>
          {label}
        </StyledLabel>
        <StyledInput
          type="text"
          value={current}
          onChange={e => setCurrent(e.target.value)}
          placeholder={label}
          hasValue={current}
          hasError={error}
          autoComplete="off"
          addable={addable}
          theme={theme.input}
          maxLength={maxLength}
        />
        {addable && (
          <StyledIcon
            width={16}
            height={16}
            icon={ICONS.NEW}
            color={theme.plusIconColor || COLORS.white}
            onClick={() => {
              onChange([...values, current]);
              setCurrent('');
            }}
            noMargin
            theme={theme.icon}
          />
        )}
      </Wrapper>
      {values && !!values.length && withTags && (
        <Tags
          tags={values.map(val => ({
            name: val,
            onRemove: () => onChange(values.filter(singleVal => singleVal !== val)),
            canEdit: true,
            color: COLORS.denimBlue,
            backgroundColor: theme.tagBackgroundColor
          }))}
        />
      )}
    </>
  );
};

MultipleInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func.isRequired
  }).isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  current: PropTypes.string,
  setCurrent: PropTypes.func.isRequired,
  withTags: PropTypes.bool,
  theme: PropTypes.shape({
    wrapper: PropTypes.string
  }),
  maxLength: PropTypes.number
};

MultipleInput.defaultProps = {
  withTags: true,
  error: null,
  placeholder: '',
  current: '',
  theme: {},
  maxLength: null
};

export default withState('current', 'setCurrent', '')(MultipleInput);
