import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import '../../toggle.css';

import LoaderWrapper from '../../../../LoaderWrapper';
import CollapseableHeader from '../../../../CollapseableHeader';
import Button from '../../../../Button';
import InfoField from '../../../../Reusable/InfoField';
import { Header, Body, FieldWrapper } from '../../styled-components';

import config, { generateGameInfo, generateCrewLabels, generateAttendance } from '../config';
import { FROM_GAMES } from '../../../../../../core/Modals/constants';
import { FieldItem, Message, MessageWrapper, NoteText, theme } from './styled-components';
import ConfirmationPopup from '../../../../popupModals/ComfirmationPopup';
import TextArea from '../../../../Reusable/TextArea';
import { DATE_FORMATS } from '../../../../../../constants';
import { addCountToDisplayId } from '../../../../../../utils/helpers';

const DEFAULT_ATTENDANCE = {
  crew_checkin_position_0: false,
  crew_checkin_position_1: false,
  crew_checkin_position_2: false,
  crew_checkin_position_3: false,
  crew_checkin_position_4: false,
  crew_checkin_position_5: false,
  crew_checkin_position_6: false,
  crew_checkin_position_7: false
};

const CrewVerificationReport = ({
  onSubmit,
  onClose,
  game,
  report,
  isGamesEventAdminOrAssignor,
  isGamesEventGroupAdmin,
  from,
  crewLabels,
  reportSettings,
  isFetching,
  currentUserId,
  notes = [],
  isGamesEventOfficialOrAssessor
}) => {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [position, setPosition] = React.useState(null);
  const [fieldValue, setFieldValue] = React.useState(false);

  const attendance =
    report &&
    report.attendance &&
    report.attendance.length &&
    generateAttendance(report.attendance);

  const handleOnChange = ({ value, values, onChange, name }) => {
    if (attendance && attendance[name] && values[name]) {
      setShowPopUp(!showPopUp);
      setPosition(name);
      setFieldValue(value);
    } else onChange(value);
  };

  const display_id = addCountToDisplayId(game);

  const crewNotes = notes.length
    ? notes.sort((a, b) => (moment(a.submitted_at).isSameOrBefore(moment(b.submitted_at)) ? 1 : -1))
    : [];
  return (
    <Body>
      <CollapseableHeader label="Game Details">
        {game && (
          <LoaderWrapper isFetching={game.isFetching || isFetching} simple>
            <Row style={{ marginBottom: '0.75rem' }}>
              {generateGameInfo({ game, display_id }).map(info => (
                <Col key={info.label} xs={12} md={info.width || 6}>
                  <FieldWrapper verticalMargins="0.5rem">
                    <InfoField {...info} />
                  </FieldWrapper>
                </Col>
              ))}
            </Row>
          </LoaderWrapper>
        )}
      </CollapseableHeader>
      <Header>Crew Check-In</Header>
      <MessageWrapper>
        <Message>Please select all crew members that attended the game</Message>
        <Message style={{ paddingLeft: '30px', fontSize: '12px' }}>
          Note: crew must be checked off to get paid
        </Message>
      </MessageWrapper>
      <Form
        onSubmit={onSubmit}
        initialValues={attendance || DEFAULT_ATTENDANCE}
        mutators={{
          onConfirm: (args, state, utils) => {
            utils.changeValue(state, `${position}`, () => args[0]);
            setShowPopUp(!showPopUp);
          }
        }}
        render={({ handleSubmit, invalid, pristine, values, change, form: { mutators } }) => {
          let all_active_crew = false;
          const { labels, all_active } = generateCrewLabels({
            game,
            crewLabels,
            currentUserId,
            reportSettings
          });

          if (!all_active) {
            if (isGamesEventGroupAdmin || isGamesEventAdminOrAssignor) {
              all_active_crew = true;
            } else {
              all_active_crew = all_active;
            }
          } else {
            all_active_crew = all_active;
          }
          const { fields } = config({
            game,
            change,
            labels,
            crewLabels,
            all_active_crew,
            in_batch: report && report.in_batch,
            batch_pay: report && report.attendance.map(a => a.batch_pay),
            attendance
          });
          return (
            <>
              <Row>
                {fields.map(
                  ({
                    isRequired = () => false,
                    isDisabled = () => false,
                    component: Component,
                    name,
                    key,
                    placeholder,
                    ...field
                  }) => (
                    <Col key={field.name} xs={12} md={field.width || 4}>
                      {!isDisabled(values) && (
                        <FieldWrapper>
                          <FieldItem>
                            <Field name={name} key={key}>
                              {({ input: { onChange, ...input }, meta }) => (
                                <div>
                                  <Component
                                    {...field}
                                    placeholder={
                                      !!placeholder && placeholder + (isRequired(values) ? '*' : '')
                                    }
                                    input={{
                                      ...input,
                                      onChange: value =>
                                        handleOnChange({ value, values, onChange, name })
                                    }}
                                  />
                                  {meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                            </Field>
                          </FieldItem>
                        </FieldWrapper>
                      )}
                    </Col>
                  )
                )}
              </Row>
              {/* {report && report.in_batch ? (
                <Row center="xs">
                  <Label>
                    <Icon width={16} height={16} icon={ICONS.LOCK} color={COLORS.errorRed} />
                    Unfortunately, crew check-in is now closed and not able to be edited.
                  </Label>
                </Row>
              ) : (
                ''
              )} */}
              {showPopUp && (
                <Row>
                  <ConfirmationPopup
                    title="Remove Confirmation"
                    description="This person has already been checked-in, are you sure you want to remove
                        this crew member?"
                    SubmitBtnText="CONFIRM"
                    cancelButtonText="CANCEL"
                    confirmationPopupAction={showPopUp}
                    setConfirmationPopupAction={setShowPopUp}
                    primaryActionFun={() => mutators.onConfirm(fieldValue)}
                    customStyle={{ zIndex: '10000' }}
                  />
                </Row>
              )}
              {isGamesEventOfficialOrAssessor && (
                <Row style={{ padding: '0 0.5rem 1rem 1.5rem' }}>
                  <Field
                    name="note"
                    component={TextArea}
                    innerPlaceholder="Note - add any additional info about the crew here. Ex) no show/late to game, or replacement crew member"
                    rows={6}
                    width={12}
                    resize="vertical"
                    theme={theme}
                  />
                </Row>
              )}
              <Row center="xs" style={{ paddingBottom: '1rem' }}>
                <Button
                  width="84px"
                  height="32px"
                  margin="0 12px 0"
                  label="Cancel"
                  onClick={() => onClose(from)}
                />
                <Button
                  type="submit"
                  width="84px"
                  height="32px"
                  margin="0 12px 0"
                  label="Submit"
                  disabled={invalid || pristine}
                  onClick={handleSubmit}
                  primary
                />
              </Row>
            </>
          );
        }}
      />
      {(isGamesEventGroupAdmin || isGamesEventAdminOrAssignor) && !!notes.length && (
        <>
          <Header>Notes</Header>
          {crewNotes.map(n => (
            <>
              <Row center="xs">
                <NoteText>
                  {`${n.submitted_by.first_name} ${n.submitted_by.last_name} - `}
                  <Message>{n.note} </Message>
                  {`- ${moment(n.submitted_at).local().format(DATE_FORMATS.MM_DD_YYYY)}`}
                </NoteText>
              </Row>
              <hr />
            </>
          ))}
        </>
      )}
    </Body>
  );
};

CrewVerificationReport.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  game: PropTypes.shape({
    id: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isGamesEventAdminOrAssignor: PropTypes.bool,
  isReportsEventAdmin: PropTypes.bool,
  isReportsEventAssignor: PropTypes.bool,

  from: PropTypes.string
};

CrewVerificationReport.defaultProps = {
  game: null,
  isGamesEventAdminOrAssignor: false,
  isReportsEventAdmin: false,
  isReportsEventAssignor: false,
  isFetching: false,
  from: FROM_GAMES
};

export default CrewVerificationReport;
