import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { always } from 'ramda';
import Wrapper from './styled-components';
import LoaderWrapper from '../../LoaderWrapper';
import { isEventGoverningBodyUSSF } from '../../../../utils/helpers';

export const Item = ({
  item,
  role,
  mainComponent: Main,
  expandedComponent: Expanded,
  onSelected,
  isFirst,
  filter,
  selected,
  expanded,
  isFetchingDetails,
  canEdit,
  onExpand,
  expandSideList,
  setExpandSideList,
  availibiltyTab,
  setAvailibiltyTab,
  calendarActiveMonth,
  setCalendarActiveMonth,
  eventInfo,
  eventsPageActive,
  style,
  handleResize,
  index,
  setActiveTab,
  activeTab,
  setCalendarTimezone,
  calendarTimezone,
  isFetchingEventUsers
}) => {
  const isGoverningBodyUSSF = isEventGoverningBodyUSSF(eventInfo);

  return (
    <Wrapper selected={expanded} expandSideList={expandSideList} style={style}>
      <Main
        item={item}
        onSelected={onSelected}
        filter={filter}
        isFirst={isFirst}
        selected={selected}
        expanded={expanded}
        eventInfo={eventInfo}
        expandSideList={expandSideList}
        onClickExpand={e => {
          onExpand(e, expanded);
          setActiveTab(eventsPageActive ? 1 : 0);
          setCalendarTimezone(moment.tz.guess());
          e.stopPropagation();
        }}
        canEdit={canEdit}
        setActiveTab={setActiveTab}
      />
      {expanded && (
        <LoaderWrapper isFetching={isFetchingDetails || isFetchingEventUsers} style={style}>
          <Expanded
            item={item}
            id={item.id}
            onSelected={onSelected}
            filter={filter}
            isFirst={isFirst}
            selected={selected}
            expanded={expanded}
            role={role}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            expandSideList={expandSideList}
            setExpandSideList={setExpandSideList}
            availibiltyTab={availibiltyTab}
            setAvailibiltyTab={setAvailibiltyTab}
            calendarActiveMonth={calendarActiveMonth}
            setCalendarActiveMonth={setCalendarActiveMonth}
            calendarTimezone={calendarTimezone}
            setCalendarTimezone={setCalendarTimezone}
            isGoverningBodyUSSF={isGoverningBodyUSSF}
            eventsPageActive={eventsPageActive}
            handleResize={handleResize}
            index={index}
          />
        </LoaderWrapper>
      )}
    </Wrapper>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  expanded: PropTypes.bool,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
  isFirst: PropTypes.bool,
  mainComponent: PropTypes.func.isRequired,
  expandedComponent: PropTypes.func.isRequired,
  role: PropTypes.string,
  isFetchingDetails: PropTypes.bool.isRequired,
  isFetchingEventUsers: PropTypes.bool.isRequired
};

Item.defaultProps = {
  expanded: false,
  selected: false,
  onSelected: always(undefined),
  isFirst: false,
  role: null
};

export default React.memo(Item);
