import { PAYMENTS_VIEW, allEventsId } from '../../constants';
import setters from './setters';

// Actions
export const CREATE_BATCH = 'PAYMENTS/CREATE_BATCH';
export const DELETE_BATCH = 'PAYMENTS/DELETE_BATCH';
export const STORE_PAYMENTS_EVENT = 'PAYMENTS/STORE_PAYMENTS_EVENT';
export const SET_PAYMENTS_EVENT_IN_BATCH = 'PAYMENTS/SET_PAYMENTS_EVENT_IN_BATCH';
export const SET_BATCH_GAMES = 'PAYMENTS/SET_BATCH_GAMES';
export const SET_BATCH_PAY_GAMES_INFO = 'PAYMENTS/SET_BATCH_PAY_GAMES_INFO';
export const SET_SELECTED_PAYMENTS_EVENT = 'PAYMENTS/SET_SELECTED_PAYMENTS_EVENT';
export const GET_BATCH_GAMES = 'PAYMENTS/GET_BATCH_GAMES';
export const FETCH_GAME_ASSIGNMENT = 'PAYMENTS/FETCH_GAME_ASSIGNMENT';
export const GAME_ASSIGNMENT_IS_FETCHING = 'PAYMENTS/GAME_ASSIGNMENT_IS_FETCHING';
export const SET_GAME_ASSIGNMENT = 'PAYMENTS/SET_GAME_ASSIGNMEN';
export const SET_ASSIGNMENT_BASE_PAY = 'PAYMENTS/SET_ASSIGNMENT_BASE_PAY;';
export const SET_ASSIGNMENT_ASSIGNOR_PAY = 'PAYMENTS/SET_ASSIGNMENT_ASSIGNOR_PAY;';
export const FETCH_PAYMENTS_EVENT_CREW_LABELS = 'PAYMENTS/FETCH_PAYMENTS_EVENT_CREW_LABELS';
export const SET_PAYMENTS_EVENT_CREW_LABELS = 'PAYMENTS/SET_PAYMENTS_EVENT_CREW_LABELS';
export const PROCESS_ASSIGNMENT = 'PAYMENTS/PROCESS_ASSIGNMENT';
export const SET_BATCH_GAMES_FETCHING = 'PAYMENTS/SET_BATCH_GAMES_FETCHING';
export const CREATE_PRIMARY_ASSIGNOR_ON_GAME = 'PAYMENTS/CREATE_PRIMARY_ASSIGNOR_ON_GAME';
export const DELETE_PRIMARY_ASSIGNOR_ON_GAME = 'PAYMENTS/DELETE_PRIMARY_ASSIGNOR_ON_GAME';
export const UPDATE_PRIMARY_ASSIGNOR_ON_GAME = 'PAYMENTS/UPDATE_PRIMARY_ASSIGNOR_ON_GAME';
export const UPDATE_ASSIGNOR_IN_STATE = 'PAYMENTS/UPDATE_ASSIGNOR_IN_STATE';
export const REMOVE_ASSIGNOR_IN_STATE = 'PAYMENTS/REMOVE_ASSIGNOR_IN_STATE';
export const DELETE_ALL_PRIMARY_ASSIGNORS = 'PAYMENTS/DELETE_ALL_PRIMARY_ASSIGNORS';
export const CREATE_ALL_PRIMARY_ASSIGNORS = 'PAYMENTS/CREATE_ALL_PRIMARY_ASSIGNORS';
export const UPDATE_ALL_PRIMARY_ASSIGNORS = 'PAYMENTS/UPDATE_ALL_PRIMARY_ASSIGNORS';
export const UPDATE_ALL_PRIMARY_ASSIGNORS_ON_STATE =
  'PAYMENTS/UPDATE_ALL_PRIMARY_ASSIGNORS_ON_STATE';
export const ASSIGNOR_IS_FETCHING = 'PAYMENTS/ASSIGNOR_IS_FETCHING';
export const STORE_BATCH_STAGING_SUMMARY = 'PAYMENTS/STORE_BATCH_STAGING_SUMMARY';
export const UPDATE_FULL_GAME_ASSIGNMENTS = 'PAYMENTS/UPDATE_FULL_GAME_ASSIGNMENTS';
export const SET_FULL_GAME_ASSIGNMENTS = 'PAYMENTS/SET_FULL_GAME_ASSIGNMENTS';
export const FETCH_BATCH_MODAL_INFO = 'PAYMENTS/FETCH_BATCH_MODAL_INFO';
export const STORE_BATCH_MODAL_INFO = 'PAYMENTS/STORE_BATCH_MODAL_INFO';
export const CONFIRM_BATCH = 'PAYMENTS/CONFIRM_BATCH';
export const FETCH_MONTH_SUMMARY_DATA = 'PAYMENTS/FETCH_MONTH_SUMMARY_DATA';
export const STORE_MONTH_TILES_DATA = 'PAYMENTS/STORE_MONTH_TILES_DATA';
export const SET_MONTH_TILES_DATA_FETCHING = 'PAYMENTS/SET_MONTH_TILES_DATA_FETCHING';
export const SET_EVENT_BATCHES = 'PAYMENTS/SET_EVENT_BATCHES';
export const SET_PAYMENTS_FETCHING = 'PAYMENTS/SET_PAYMENTS_FETCHING';
export const FETCH_FUNDING_BATCHES = 'PAYMENTS/FETCH_FUNDING_BATCHES';
export const GET_REVIEW_BATCH_INFO = 'PAYMENTS/GET_REVIEW_BATCH_INFO';
export const SET_REVIEW_BATCH_INFO = 'PAYMENTS/SET_REVIEW_BATCH_INFO';
export const GET_AVAILABLE_BALANCE = 'PAYMENTS/GET_AVAILABLE_BALANCE';
export const SET_AVAILABLE_BALANCE = 'PAYMENTS/SET_AVAILABLE_BALANCE';
export const SET_BATCH_DETAILS = 'PAYMENTS/SET_BATCH_DETAILS';
export const FUND_BATCH_PAY = 'PAYMENTS/FUND_BATCH_PAY';
export const REQUEST_PAYOUT = 'PAYMENTS/REQUEST_PAYOUT';
export const GET_PAYMENT_HISTORY = 'PAYMENTS/GET_PAYMENT_HISTORY';
export const SET_PAYMENT_HISTORY = 'PAYMENTS/SET_PAYMENT_HISTORY';
export const GET_PAYMENT_BATCH_DETAILS = 'PAYMENTS/GET_PAYMENT_BATCH_DETAILS';
export const SET_PAYMENT_BATCH_DETAILS = 'PAYMENTS/SET_PAYMENT_BATCH_DETAILS';
export const SET_PAYMENT_VIEW = 'PAYMENTS/SET_PAYMENT_VIEW';
export const GET_BATCH_PAY_GAMES_INFO = 'PAYMENTS/GET_BATCH_PAY_GAMES_INFO';
export const ADD_PAYMENT_SOURCE = 'PAYMENTS/ADD_PAYMENT_SOURCE';
export const PROCESS_BATCH_FUND = 'PAYMENTS/PROCESS_BATCH_FUND';
export const FETCH_PAYMENT_TOKEN = 'PAYMENT/FETCH_PAYMENT_TOKEN';
export const FETCH_FUND_SUMMARY = 'PAYMENT/FETCH_FUND_SUMMARY';
export const FETCH_BATCH_FAILED_HISTORY = 'PAYMENT/FETCH_BATCH_FAILED_HISTORY';
export const SET_CLIENT_SECRET = 'PAYMENT/SET_CLIENT_SECRET';
export const SET_FUND_SUMMARY = 'PAYMENT/SET_FUND_SUMMARY';
export const SET_BATCH_FAILED_HISTORY = 'PAYMENT/SET_BATCH_FAILED_HISTORY';
export const SET_IS_PROCESSING_PAYMENT = 'PAYMENT/SET_IS_PROCESSING_PAYMENT';
export const SET_BATCH_PAYMENT_SUCCESS = 'PAYMENT/SET_BATCH_PAYMENT_SUCCESS';
export const SET_BATCH_PAYMENT_ERROR = 'PAYMENT/SET_BATCH_PAYMENT_ERROR';
export const SET_ERROR = 'PAYMENT/SET_ERROR';
export const DELETE_PAYMENT_SOURCE = 'PAYMENT/DELETE_PAYMENT_SOURCE';
export const FETCH_BATCH_PAYMENT_FEE = 'PAYMENT/FETCH_BATCH_PAYMENT_FEE';
export const SET_BATCH_PAYMENT_FEE = 'PAYMENT/SET_BATCH_PAYMENT_FEE';
export const FETCH_PAYOUT_FEE = 'PAYMENT/FETCH_PAYOUT_FEE';
export const SET_PAYOUT_FEE = 'PAYMENT/SET_PAYOUT_FEE';
export const REMOVE_BATCH_PAYMENT_ERROR = 'PAYMENT/REMOVE_BATCH_PAYMENT_ERROR';
export const SET_PAYMENT_SUPPORTED = 'PAYMENT/SET_PAYMENT_SUPPORTED';
export const DELETE_BATCH_PAY = 'PAYMENTS/DELETE_BATCH_PAY';
export const FETCH_AGREEMENT = 'PAYMENTS/FETCH_AGREEMENT';
export const SET_AGREEMENT = 'PAYMENTS/SET_AGREEMENT';
export const FETCH_PAYMENT_METHODS = 'PAYMENTS/FETCH_PAYMENT_METHODS';
export const SET_PAYMENT_METHODS = 'PAYMENTS/SET_PAYMENT_METHODS';
export const SET_PAYMENT_METHOD = 'PAYMENTS/SET_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD = 'PAYMENTS/UPDATE_PAYMENT_METHOD';
export const REMOVE_PAYMENT_METHOD = 'PAYMENTS/REMOVE_PAYMENT_METHOD';
export const SET_PAYMENTS_EVENT_CATEGORIES = 'PAYMENTS/SET_PAYMENTS_EVENT_CATEGORIES';
export const GET_INVOICED_FEES_REPORT = 'PAYMENTS/GET_INVOICED_FEES_REPORT';
export const GET_NON_INVOICED_FEES_REPORT = 'PAYMENTS/GET_NON_INVOICED_FEES_REPORT';
export const SET_PAYMENT_METHOD_TOKEN = 'PAYMENTS/SET_PAYMENT_METHOD_TOKEN';
export const SET_PAYMENT_METHOD_SECRET_FETCHING = 'PAYMENTS/SET_PAYMENT_METHOD_SECRET_FETCHING';
export const SET_PAYMENT_METHOD_SECRET = 'PAYMENTS/SET_PAYMENT_METHOD_SECRET';
export const FETCH_PAYMENT_METHOD_SECRET = 'PAYMENTS/FETCH_PAYMENT_METHOD_SECRET';
export const CONFIRMING_PAYMENT_METHOD = 'PAYMENTS/CONFIRMING_PAYMENT_METHOD';
export const CONFIRM_PAYMENT_METHOD = 'PAYMENTS/CONFIRM_PAYMENT_METHOD';
export const FETCH_MANDATE = 'PAYMENTS/FETCH_MANDATE';
export const SET_AGREEMENT_FETCHING = 'PAYMENTS/SET_AGREEMENT_FETCHING';
export const SET_ALL_EVENTS_SELECTED = 'PAYMENTS/SET_ALL_EVENTS_SELECTED';
export const SET_WALLET_IS_FETCHING = 'PAYMENTS/SET_WALLET_IS_FETCHING';
export const FETCH_WALLET = 'PAYMENTS/FETCH_WALLET';
export const SET_WALLET = 'PAYMENTS/SET_WALLET';
export const FUND_WALLET = 'PAYMENTS/FUND_WALLET';
export const SET_TRANSACTION_HISTORY_FETCHING = 'PAYMENTS/SET_TRANSACTION_HISTORY_FETCHING';
export const FETCH_TRANSACTION_HISTORY = 'PAYMENTS/FETCH_TRANSACTION_HISTORY';
export const SET_TRANSACTION_HISTORY = 'PAYMENTS/SET_TRANSACTION_HISTORY';
export const GET_PLATFORM_ITEMIZED_TRANSACTION_DETAILS =
  'PAYMENTS/GET_PLATFORM_ITEMIZED_TRANSACTION_DETAILS';
export const BULK_UPDATE_FULL_GAME_ASSIGNMENTS = 'PAYMENTS/BULK_UPDATE_FULL_GAME_ASSIGNMENTS';

// Reducer
export const initialState = {
  paymentsViewSelected: PAYMENTS_VIEW.GAME_PAY,
  selectedPaymentsEvent: {
    id: null
  },
  paymentsEventCrewLabels: [],
  paymentsEventCategories: [],
  paymentsEventHasActiveBatch: {},
  gamesInBatch: {
    meta: { total_count: 0, limit: 100 },
    games: [],
    isFetching: false,
    isAssignorFetching: false
  },
  selectedAssignment: {
    isFetching: false,
    assignment: null
  },
  summary: {
    assignments_batch: [],
    game_count: 0,
    payment_total: 0
  },
  assignmentPayments: [],
  monthTilesData: {
    isFetching: false,
    dataArray: []
  },
  eventBatches: {
    list: [],
    meta: { total_count: 0, limit: 20 }
  },
  reviewBatchInfo: {},
  isFetching: false,
  availableBalance: [],
  paymentHistory: [],
  secrets: {},
  isProcessingPayment: false,
  errors: {
    paymentSource: {
      add: null,
      verify: null
    }
  },
  payoutFee: {
    fixed: 0,
    percentage: 0
  },
  isPaymentSupported: false,
  agreement: {},
  paymentMethods: [],
  paymentBatchDetails: [],
  paymentMethod: {
    secret: {
      fetching: false,
      token: null
    },
    mandate: {
      fetching: true,
      data: ''
    },
    confirming: false
  },
  allEvents: {
    isSelected: false,
    selectedBatchEventId: null
  },
  wallet: {
    fetching: false,
    available: '',
    pending: '',
    transactions: {
      fetching: false,
      data: [],
      meta: {
        limit: 6,
        total_count: 0
      }
    }
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PAYMENT_VIEW:
      return {
        ...state,
        paymentsViewSelected: action.payload
      };
    case SET_SELECTED_PAYMENTS_EVENT:
      return {
        ...state,
        selectedPaymentsEvent: action.payload,
        allEvents: {
          isSelected: !!(action.payload.id === allEventsId),
          selectedBatchEventId: action.payload.id
        }
      };

    case SET_PAYMENTS_EVENT_IN_BATCH:
      return {
        ...state,
        paymentsEventHasActiveBatch: action.payload
      };
    case SET_BATCH_GAMES:
      return {
        ...state,
        gamesInBatch: {
          ...state.gamesInBatch,
          games: action.payload.data,
          meta: { ...state.gamesInBatch.meta, ...action.payload.meta }
        }
      };
    case SET_BATCH_PAY_GAMES_INFO:
      return {
        ...state,
        list: action.payload.data,
        meta: { ...state.meta, ...action.payload.meta }
      };
    case SET_FULL_GAME_ASSIGNMENTS: {
      const { id: gameId, assignments } = action.payload;
      return {
        ...state,
        gamesInBatch: {
          ...state.gamesInBatch,
          games: state.gamesInBatch.games.map(game => {
            if (game.id === gameId) {
              return {
                ...game,
                assignments_game: game.assignments_game.map(a => ({
                  ...a,
                  batch_pay_staging: {
                    ...a.batch_pay_staging,
                    in_batch: assignments.find(x => x.game_assignment_id.toString() === a.id)
                      .in_batch
                  }
                }))
              };
            }
            return game;
          })
        }
      };
    }
    case UPDATE_ASSIGNOR_IN_STATE: {
      const gameIdx = state.gamesInBatch.games.findIndex(
        game => game.id === action.payload.external_game_id
      );
      return {
        ...state,
        gamesInBatch: {
          ...state.gamesInBatch,
          games: [
            ...state.gamesInBatch.games.slice(0, gameIdx),
            { ...state.gamesInBatch.games[gameIdx], assignment_primary_game: action.payload },
            ...state.gamesInBatch.games.slice(gameIdx + 1)
          ]
        }
      };
    }
    case REMOVE_ASSIGNOR_IN_STATE: {
      const gameIdx = state.gamesInBatch.games.findIndex(
        game => game.id === action.payload.external_game_id
      );
      return {
        ...state,
        gamesInBatch: {
          ...state.gamesInBatch,
          games: [
            ...state.gamesInBatch.games.slice(0, gameIdx),
            { ...state.gamesInBatch.games[gameIdx], assignment_primary_game: null },
            ...state.gamesInBatch.games.slice(gameIdx + 1)
          ]
        }
      };
    }
    case DELETE_ALL_PRIMARY_ASSIGNORS: {
      return {
        ...state,
        gamesInBatch: {
          ...state.gamesInBatch,
          games: state.gamesInBatch.games.map(game => ({
            ...game,
            assignment_primary_game: null
          }))
        }
      };
    }
    case UPDATE_ALL_PRIMARY_ASSIGNORS_ON_STATE: {
      return {
        ...state,
        gamesInBatch: {
          ...state.gamesInBatch,
          games: state.gamesInBatch.games.map(game => ({
            ...game,
            assignment_primary_game: action.payload.find(a => a.external_game_id === game.id)
          }))
        }
      };
    }
    case SET_BATCH_GAMES_FETCHING:
      return {
        ...state,
        gamesInBatch: {
          ...state.gamesInBatch,
          isFetching: action.payload
        }
      };
    case GAME_ASSIGNMENT_IS_FETCHING:
      return {
        ...state,
        selectedAssignment: {
          ...state.selectedAssignment,
          isFetching: action.payload
        }
      };
    case ASSIGNOR_IS_FETCHING: {
      return {
        ...state,
        selectedAssignment: {
          ...state.selectedAssignment,
          isAssignorFetching: action.payload
        }
      };
    }
    case SET_GAME_ASSIGNMENT:
      return {
        ...state,
        selectedAssignment: {
          ...state.selectedAssignment,
          assignment: action.payload
        }
      };
    case SET_ASSIGNMENT_BASE_PAY:
      return {
        ...state,
        selectedAssignment: {
          ...state.selectedAssignment,
          assignment: {
            ...state.selectedAssignment.assignment,
            base_pay: action.payload
          }
        }
      };
    case SET_ASSIGNMENT_ASSIGNOR_PAY:
      return {
        ...state,
        selectedAssignment: {
          ...state.selectedAssignment,
          assignment: {
            ...state.selectedAssignment.assignment,
            assignor_pay: action.payload
          }
        }
      };
    case STORE_BATCH_STAGING_SUMMARY:
      return {
        ...state,
        summary: {
          ...action.payload
        }
      };
    case STORE_BATCH_MODAL_INFO:
      return {
        ...state,
        assignmentPayments: action.payload
      };
    case SET_PAYMENTS_EVENT_CREW_LABELS:
      return {
        ...state,
        paymentsEventCrewLabels: action.payload
      };
    case SET_MONTH_TILES_DATA_FETCHING:
      return {
        ...state,
        monthTilesData: {
          ...state.monthTilesData,
          isFetching: action.payload
        }
      };
    case STORE_MONTH_TILES_DATA:
      return {
        ...state,
        monthTilesData: {
          ...state.monthTilesData,
          dataArray: action.payload
        }
      };
    case SET_EVENT_BATCHES:
      return {
        ...state,
        eventBatches: {
          list: action.payload.data,
          meta: { ...state.eventBatches.meta, ...action.payload.meta }
        }
      };
    case SET_PAYMENTS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_REVIEW_BATCH_INFO:
      return {
        ...state,
        reviewBatchInfo: action.payload
      };
    case GET_AVAILABLE_BALANCE:
      return {
        ...state,
        isFetching: true
      };
    case GET_PAYMENT_HISTORY:
      return {
        ...state,
        isFetching: true
      };
    case GET_PAYMENT_BATCH_DETAILS:
      return {
        ...state,
        isFetching: true
      };
    case SET_BATCH_DETAILS:
      return {
        ...state,
        batchDetails: action.payload
      };
    case SET_AVAILABLE_BALANCE:
      return {
        ...state,
        availableBalance: action.payload
      };
    case SET_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: action.payload
      };
    case SET_PAYMENT_BATCH_DETAILS:
      return {
        ...state,
        paymentBatchDetails: action.payload
      };
    case FUND_BATCH_PAY:
      return {
        ...state,
        isFetching: true
      };
    case SET_CLIENT_SECRET:
      return {
        ...state,
        secrets: {
          ...state.secrets,
          [action.payload.batchId]: action.payload.clientSecret
        }
      };
    case SET_FUND_SUMMARY:
      return {
        ...state,
        fundSummaryDetails: action.payload,
        batchPaymentFee: {}
      };
    case SET_BATCH_FAILED_HISTORY:
      return {
        ...state,
        batchFailedHistory: action.payload
      };
    case SET_BATCH_PAYMENT_FEE:
      return {
        ...state,
        batchPaymentFee: action.payload
      };
    case SET_PAYOUT_FEE:
      return {
        ...state,
        payoutFee: { ...action.payload }
      };
    case SET_IS_PROCESSING_PAYMENT:
      return {
        ...state,
        isProcessingPayment: true
      };

    case SET_BATCH_PAYMENT_SUCCESS: {
      return {
        ...state,
        isProcessingPayment: false,
        eventBatches: {
          list: state.eventBatches.list.map(batch => {
            const newBatch = { ...batch };
            if (batch.id === action.payload.id) newBatch.processed = true;
            return newBatch;
          }),
          meta: { ...state.eventBatches.meta }
        }
      };
    }

    case SET_BATCH_PAYMENT_ERROR: {
      return {
        ...state,
        isProcessingPayment: false,
        eventBatches: {
          list: state.eventBatches.list.map(batch => {
            const newBatch = { ...batch };
            if (batch.id === action.payload.id)
              newBatch.error = {
                code: action.payload.err.status,
                message: Array.isArray(action.payload.err.message)
                  ? action.payload.err.message[0].message
                  : action.payload.err.message.message
              };
            return newBatch;
          }),
          meta: { ...state.eventBatches.meta }
        }
      };
    }

    case REMOVE_BATCH_PAYMENT_ERROR: {
      return {
        ...state,
        eventBatches: {
          list: state.eventBatches.list.map(batch => {
            const newBatch = { ...batch };
            if (batch.id === action.payload.id) newBatch.error = false;
            return { ...newBatch };
          }),
          meta: { ...state.eventBatches.meta }
        }
      };
    }

    case DELETE_PAYMENT_SOURCE: {
      const { payload } = action;
      return {
        ...state,
        payload
      };
    }

    case SET_ERROR: {
      const { type, op } = action.payload;
      return {
        ...state,
        [type]: {
          ...state.type,
          [op]: true
        }
      };
    }

    case SET_PAYMENT_SUPPORTED: {
      return {
        ...state,
        isPaymentSupported: action.payload
      };
    }

    case SET_AGREEMENT: {
      return setters.agreement(state, action);
    }

    case SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: action.payload
      };
    }

    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: state.paymentMethods.concat([action.payload])
      };

    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: state.paymentMethods.map(method => {
          if (method.id === action.id) {
            return {
              ...method,
              ...action.payload
            };
          }
          return method;
        })
      };

    case REMOVE_PAYMENT_METHOD: {
      const { payload } = action;
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(method => method.id !== payload.id)
      };
    }

    case SET_PAYMENTS_EVENT_CATEGORIES:
      return {
        ...state,
        paymentsEventCategories: action.payload
      };

    case SET_PAYMENT_METHOD_TOKEN:
      return {
        ...state,
        paymentMethodToken: action.token
      };

    case SET_PAYMENT_METHOD_SECRET_FETCHING:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          secret: {
            ...state.paymentMethod.secret,
            fetching: action.value
          }
        }
      };
    case SET_PAYMENT_METHOD_SECRET:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          secret: {
            ...state.paymentMethod.secret,
            token: action.token
          }
        }
      };

    case CONFIRMING_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          confirming: action.value
        }
      };

    case SET_AGREEMENT_FETCHING:
      return setters.agreementFetch(state, action);

    case SET_ALL_EVENTS_SELECTED:
      return {
        ...state,
        allEvents: {
          ...state.allEvents,
          selectedBatchEventId: action.payload.eventId.toString()
        }
      };
    case SET_WALLET_IS_FETCHING:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          fetching: action.payload
        }
      };
    case SET_WALLET:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          available: action.payload.available,
          pending: action.payload.pending
        }
      };
    case SET_TRANSACTION_HISTORY_FETCHING:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          transactions: {
            ...state.wallet.transactions,
            fetching: action.payload
          }
        }
      };
    case SET_TRANSACTION_HISTORY:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          transactions: {
            ...state.wallet.transactions,
            data: action.payload.data,
            meta: {
              ...state.wallet.transactions.meta,
              total_count: action.payload.meta.total_count
            }
          }
        }
      };

    default:
      return state;
  }
}

// Action Creators
export const setPaymentsView = payload => ({
  type: SET_PAYMENT_VIEW,
  payload
});

export const getPaymentHistory = payload => ({
  type: GET_PAYMENT_HISTORY,
  payload
});

export const setPaymentHistory = payload => ({
  type: SET_PAYMENT_HISTORY,
  payload
});

export const getPaymentBatchDetails = payload => ({
  type: GET_PAYMENT_BATCH_DETAILS,
  payload
});

export const setPaymentBatchDetails = payload => ({
  type: SET_PAYMENT_BATCH_DETAILS,
  payload
});

export const getAvailableBalance = payload => ({
  type: GET_AVAILABLE_BALANCE,
  payload
});

export const setAvailableBalance = payload => ({
  type: SET_AVAILABLE_BALANCE,
  payload
});
export const setBatchDetails = payload => ({
  type: SET_BATCH_DETAILS,
  payload
});

export const requestPayout = payload => ({
  type: REQUEST_PAYOUT,
  payload
});

export const processAssignment = payload => ({
  type: PROCESS_ASSIGNMENT,
  payload
});

export const createBatch = payload => ({
  type: CREATE_BATCH,
  payload
});

export const storeBatchStagingSummary = payload => ({
  type: STORE_BATCH_STAGING_SUMMARY,
  payload
});

export const storePaymentsEvent = payload => ({
  type: STORE_PAYMENTS_EVENT,
  payload
});

export const fetchFundingBatches = payload => ({
  type: FETCH_FUNDING_BATCHES,
  payload
});

export const setSelectedPaymentsEvent = payload => ({
  type: SET_SELECTED_PAYMENTS_EVENT,
  payload
});

export const setPaymentsEventHasBatch = payload => ({
  type: SET_PAYMENTS_EVENT_IN_BATCH,
  payload
});

export const deleteBatch = payload => ({
  type: DELETE_BATCH,
  payload
});

export const deleteBatchPay = payload => ({
  type: DELETE_BATCH_PAY,
  payload
});

export const setBatchGames = payload => ({
  type: SET_BATCH_GAMES,
  payload
});

export const setBatchPayGamesInfo = payload => ({
  type: SET_BATCH_PAY_GAMES_INFO,
  payload
});

export const getBatchGames = payload => ({
  type: GET_BATCH_GAMES,
  payload
});

export const setBatchGamesIsFetching = payload => ({
  type: SET_BATCH_GAMES_FETCHING,
  payload
});

export const fetchGameAssignment = payload => ({
  type: FETCH_GAME_ASSIGNMENT,
  payload
});

export const setGameAssignmentIsFetching = payload => ({
  type: GAME_ASSIGNMENT_IS_FETCHING,
  payload
});

export const setGameAssignment = payload => ({
  type: SET_GAME_ASSIGNMENT,
  payload
});

export const setAssignmentBasePay = payload => ({
  type: SET_ASSIGNMENT_BASE_PAY,
  payload
});

export const setAssignmentAssignorPay = payload => ({
  type: SET_ASSIGNMENT_ASSIGNOR_PAY,
  payload
});

export const fetchPaymentsEventCrewLabels = payload => ({
  type: FETCH_PAYMENTS_EVENT_CREW_LABELS,
  payload
});

export const setPaymentsEventCrewLabels = payload => ({
  type: SET_PAYMENTS_EVENT_CREW_LABELS,
  payload
});

export const createPrimaryAssignorOnGame = payload => ({
  type: CREATE_PRIMARY_ASSIGNOR_ON_GAME,
  payload
});

export const deletePrimaryAssignorOnGame = payload => ({
  type: DELETE_PRIMARY_ASSIGNOR_ON_GAME,
  payload
});

export const updatePrimaryAssignorOnGame = payload => ({
  type: UPDATE_PRIMARY_ASSIGNOR_ON_GAME,
  payload
});

export const deleteAllPrimaryAssignors = payload => ({
  type: DELETE_ALL_PRIMARY_ASSIGNORS,
  payload
});

export const createAllPrimaryAssignors = payload => ({
  type: CREATE_ALL_PRIMARY_ASSIGNORS,
  payload
});

export const updateAllPrimaryAssignors = payload => ({
  type: UPDATE_ALL_PRIMARY_ASSIGNORS,
  payload
});

export const updateAllPrimaryAssignorsInState = payload => ({
  type: UPDATE_ALL_PRIMARY_ASSIGNORS_ON_STATE,
  payload
});

export const updateAssignorInState = payload => ({
  type: UPDATE_ASSIGNOR_IN_STATE,
  payload
});

export const removeAssignorInState = payload => ({
  type: REMOVE_ASSIGNOR_IN_STATE,
  payload
});

export const setAssignorFetching = payload => ({
  type: ASSIGNOR_IS_FETCHING,
  payload
});

export const updateFulLGameAssignments = payload => ({
  type: UPDATE_FULL_GAME_ASSIGNMENTS,
  payload
});

export const bulkUpdateFullGameAssignmentsSaga = payload => ({
  type: BULK_UPDATE_FULL_GAME_ASSIGNMENTS,
  payload
});

export const setFullGameAssignments = payload => ({
  type: SET_FULL_GAME_ASSIGNMENTS,
  payload
});

export const fetchBatchModalInfo = payload => ({
  type: FETCH_BATCH_MODAL_INFO,
  payload
});

export const storeBatchModalInfo = payload => ({
  type: STORE_BATCH_MODAL_INFO,
  payload
});

export const confirmBatch = payload => ({
  type: CONFIRM_BATCH,
  payload
});

export const fetchMonthTilesData = payload => ({
  type: FETCH_MONTH_SUMMARY_DATA,
  payload
});

export const storeMonthTilesData = payload => ({
  type: STORE_MONTH_TILES_DATA,
  payload
});

export const setMonthTilesDataFetching = payload => ({
  type: SET_MONTH_TILES_DATA_FETCHING,
  payload
});

export const setEventBatches = payload => ({
  type: SET_EVENT_BATCHES,
  payload
});

export const setPaymentsFetching = payload => ({
  type: SET_PAYMENTS_FETCHING,
  payload
});

export const getBatchInfo = payload => ({
  type: GET_REVIEW_BATCH_INFO,
  payload
});

export const setBatchInfo = payload => ({
  type: SET_REVIEW_BATCH_INFO,
  payload
});

export const fetchBatchPayGamesInfo = payload => ({
  type: GET_BATCH_PAY_GAMES_INFO,
  payload
});

export const addPaymentSource = payload => ({
  type: ADD_PAYMENT_SOURCE,
  payload
});

export const processBatchFund = payload => ({
  type: PROCESS_BATCH_FUND,
  payload
});

export const fetchPaymentToken = payload => ({
  type: FETCH_PAYMENT_TOKEN,
  payload
});

export const fetchFundSummaryDetails = payload => ({
  type: FETCH_FUND_SUMMARY,
  payload
});

export const fetchBatchFailedHistory = payload => ({
  type: FETCH_BATCH_FAILED_HISTORY,
  payload
});

export const fetchBatchPaymentFee = payload => ({
  type: FETCH_BATCH_PAYMENT_FEE,
  payload
});

export const fetchPayoutFee = payload => ({
  type: FETCH_PAYOUT_FEE,
  payload
});

export const setClientSecret = payload => ({
  type: SET_CLIENT_SECRET,
  payload
});

export const setProcessingPayment = () => ({
  type: SET_IS_PROCESSING_PAYMENT
});

export const setBatchPaymentSuccess = payload => ({
  type: SET_BATCH_PAYMENT_SUCCESS,
  payload
});

export const setBatchPaymentError = payload => ({
  type: SET_BATCH_PAYMENT_ERROR,
  payload
});

export const deletePaymentSource = payload => ({
  type: DELETE_PAYMENT_SOURCE,
  payload
});
export const setFundSummary = payload => ({
  type: SET_FUND_SUMMARY,
  payload
});

export const setBatchFailedHistory = payload => ({
  type: SET_BATCH_FAILED_HISTORY,
  payload
});

export const setBatchPaymentFee = payload => ({
  type: SET_BATCH_PAYMENT_FEE,
  payload
});

export const setPayoutFee = payload => ({
  type: SET_PAYOUT_FEE,
  payload
});

export const setError = (type, op) => ({
  type: SET_ERROR,
  payload: {
    type,
    op
  }
});

export const removeBatchPaymentError = payload => ({
  type: REMOVE_BATCH_PAYMENT_ERROR,
  payload
});

export const setPaymentSupported = payload => ({
  type: SET_PAYMENT_SUPPORTED,
  payload
});

export const fetchAgreement = payload => ({
  type: FETCH_AGREEMENT,
  payload
});

export const setAgreement = payload => ({
  type: SET_AGREEMENT,
  payload
});

export const fetchPaymentMethods = payload => ({
  type: FETCH_PAYMENT_METHODS,
  payload
});

export const setPaymentMethods = payload => ({
  type: SET_PAYMENT_METHODS,
  payload
});

export const setPaymentMethod = payload => ({
  type: SET_PAYMENT_METHOD,
  payload
});

export const updatePaymentMethod = (id, payload) => ({
  type: UPDATE_PAYMENT_METHOD,
  id,
  payload
});

export const removePaymentMethod = payload => ({
  type: REMOVE_PAYMENT_METHOD,
  payload
});

export const setPaymentsEventCategories = payload => ({
  type: SET_PAYMENTS_EVENT_CATEGORIES,
  payload
});

export const fetchFeeReport = (invoiced, payload) => ({
  type: invoiced ? GET_INVOICED_FEES_REPORT : GET_NON_INVOICED_FEES_REPORT,
  payload
});

export const fetchPlatformTransactionDetails = payload => ({
  type: GET_PLATFORM_ITEMIZED_TRANSACTION_DETAILS,
  payload
});

export const fetchPaymentMethodSecret = payload => ({
  type: FETCH_PAYMENT_METHOD_SECRET,
  payload
});

export const setPaymentMethodSecretFetching = value => ({
  type: SET_PAYMENT_METHOD_SECRET_FETCHING,
  value
});

export const setPaymentMethodSecret = token => ({
  type: SET_PAYMENT_METHOD_SECRET,
  token
});

export const setPaymentMethodConfirming = value => ({
  type: CONFIRMING_PAYMENT_METHOD,
  value
});

export const confirmPaymentMethod = payload => ({
  type: CONFIRM_PAYMENT_METHOD,
  payload
});

export const setAgreementFetching = (payload, value) => ({
  type: SET_AGREEMENT_FETCHING,
  payload,
  value
});

export const setAllEventIsSelected = payload => ({
  type: SET_ALL_EVENTS_SELECTED,
  payload
});

export const setWalletIsFetching = payload => ({
  type: SET_WALLET_IS_FETCHING,
  payload
});

export const fetchWallet = payload => ({
  type: FETCH_WALLET,
  payload
});

export const setWallet = payload => ({
  type: SET_WALLET,
  payload
});

export const addWalletFund = payload => ({
  type: FUND_WALLET,
  payload
});

export const setTransactionHistoryFetching = payload => ({
  type: SET_TRANSACTION_HISTORY_FETCHING,
  payload
});

export const setTransactionHistory = payload => ({
  type: SET_TRANSACTION_HISTORY,
  payload
});

export const fetchTransactionHistory = payload => ({
  type: FETCH_TRANSACTION_HISTORY,
  payload
});
