import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-flexbox-grid';
import {
  HeaderText,
  GhostContainer,
  AddIcon,
  RadioStyle,
  GhostStyle,
  CardElementWrapper,
  CardTextWrap,
  CardExpireWrap,
  CardNumber,
  CardExpireText,
  MainWrapper,
  ListWrapper
} from '../styled-components';
import { COLORS, ICONS } from '../../../../../utils/styles';
import { RadioGroup, GhostButton } from '../../../../global-material-components';
import AddCard from '../../../AddCard';

const useStyles = makeStyles({
  label: {
    flex: 1,
    width: '100%',
    'margin-left': '1px'
  }
});

const Card = ({ cards, setCard, card, addCard, billingInfo, setBillingInfo, addNotifications }) => {
  const classes = useStyles();
  const [addNewCard, setAddNewCard] = React.useState(null);

  return addNewCard ? (
    <MainWrapper>
      <CardElementWrapper>
        <Row middle="xs">
          <Col xs={8}>
            <HeaderText>Add New Card</HeaderText>
          </Col>
          <Col xs={4}>
            {' '}
            <GhostContainer>
              <GhostButton
                style={GhostStyle}
                onClick={() => {
                  setAddNewCard(!addNewCard);
                  setCard(null);
                }}
              >
                {addNewCard ? 'Go Back' : 'Use Another Payment Method'}
                <AddIcon
                  width={20}
                  height={20}
                  icon={addNewCard ? ICONS.BACK_ARROW : ICONS.PLUS_CIRCLE}
                  color={COLORS.denimBlue}
                  noMargin
                />
                <br />
              </GhostButton>
            </GhostContainer>
          </Col>
          <Col xs={12}>
            <AddCard
              billingInfo={billingInfo}
              setBillingInfo={setBillingInfo}
              addNotifications={addNotifications}
              addCard={addCard}
            />
          </Col>
        </Row>
      </CardElementWrapper>
    </MainWrapper>
  ) : (
    <>
      {cards && cards.length ? (
        <HeaderText>
          Select card to use{' '}
          {!card && (
            <span
              style={{
                color: COLORS.errorRed,
                fontSize: 12
              }}
            >
              {` (*Required)`}
            </span>
          )}{' '}
        </HeaderText>
      ) : (
        ''
      )}

      <ListWrapper>
        <RadioGroup
          id="bank-account-info"
          aria-label="Bank Account Info"
          label="Bank Account Info"
          name="bankAccountInfo"
          onChange={evt => setCard(evt.target.value)}
          classes={{ root: classes.label }}
          dataItems={
            cards
              ? cards.map(cardInfo => ({
                  value: `${cardInfo.id}`,
                  label: (
                    <CardTextWrap>
                      <CardNumber>{`XXXX-XXXX-XXXX-${cardInfo.details.last4}`}</CardNumber>
                      <CardExpireWrap>
                        {`Expires: `}
                        <CardExpireText>
                          {cardInfo.details.exp_month}/{cardInfo.details.exp_year}
                        </CardExpireText>
                      </CardExpireWrap>
                    </CardTextWrap>
                  )
                }))
              : []
          }
          value={card}
          style={RadioStyle}
          labelClasses={classes}
        />
        <GhostContainer>
          <GhostButton
            style={GhostStyle}
            onClick={() => {
              setAddNewCard(!addNewCard);
              setCard(null);
            }}
          >
            {addNewCard ? 'Go Back' : 'Use Another Payment Method'}
            <AddIcon
              width={20}
              height={20}
              icon={addNewCard ? ICONS.BACK_ARROW : ICONS.PLUS_CIRCLE}
              color={COLORS.denimBlue}
              noMargin
            />
          </GhostButton>
        </GhostContainer>
        <br />{' '}
      </ListWrapper>
    </>
  );
};

export default Card;
