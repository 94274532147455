import styled from 'styled-components';

import { COLORS, mediaQueries } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

import Icon from '../../Icon';

export const Wrapper = styled.div`
  margin: 2rem;
  padding: ${pxToRem('30px')};
  align-self: center;
  background-color: ${COLORS.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  ${mediaQueries.sm(`
  margin: 0rem;
  padding: ${pxToRem('10px')};
  `)};
`;

export const HeaderWrapper = styled.div``;

export const DayName = styled.p`
  margin: ${pxToRem('21px')} 0 ${pxToRem('15px')};
  color: ${COLORS.denimBlue};
  font-size: ${pxToRem('13px')};
  font-family: 'Roboto';
  margin-bottom: ${pxToRem('15px')};
`;

export const DateNumber = styled.p`
  box-sizing: border-box;
  border-left: 1px solid ${COLORS.lightCyanGray};
  border-right: 1px solid ${COLORS.lightCyanGray};
  border-top: 2px solid ${COLORS.lightCyanGray};
  border-bottom: 2px solid ${COLORS.lightCyanGray};
  margin: 0;
  padding: ${pxToRem('5px')} 0 ${pxToRem('7px')};
  background-image: linear-gradient(to bottom, ${COLORS.white}, ${COLORS.solitude});
  color: ${COLORS.shuttleGray};
  font-family: 'Roboto';
  font-size: ${pxToRem('24px')};

  ${({ isToday }) => (isToday ? `border: 2px solid ${COLORS.denimBlue}` : '')};
`;

export const LastTimeslot = styled.span`
  position: relative;
  bottom: ${pxToRem('10px')};
  padding: 0 ${pxToRem('14px')};
  color: ${COLORS.shuttleGray};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoCondensedLight';
`;

export const ToolbarWrapper = styled.div`
  box-shadow: inset 0 -1px 0 0 ${COLORS.hawkesBlue}80;
`;

export const ToolbarHeader = styled.div`
  width: 100%;
  background: ${COLORS.blackSqueeze};
  padding: ${pxToRem('15px')} ${pxToRem('17px')};
  display: flex;
  position: relative;
  align-items: center;
`;

export const DateRange = styled.span`
  font-family: 'Roboto';
  font-size: ${pxToRem('15px')};
  color: ${COLORS.denimBlue};
  padding: 0 ${pxToRem('10px')};
`;

export const HelperText = styled.p`
  margin-top: ${pxToRem('17px')};
  margin-bottom: ${pxToRem('20px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.sapphire};
`;

export const BoldHelperText = styled.span`
  font-family: 'RobotoBold';
`;

export const Chevron = styled(Icon)`
  user-select: none;
  cursor: pointer;
  margin: ${pxToRem('2px')} 0 0;

  ${({ reverse }) => (reverse ? 'transform: rotate(180deg)' : '')};
`;

export const Calendar = styled(Icon)`
  user-select: none;
  cursor: pointer;
`;

export const AddIcon = styled(Icon)`
  user-select: none;
  cursor: pointer;
  margin: ${pxToRem('14px')} ${pxToRem('14px')} 0;
`;

export const ToolbarAdditional = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DayPickerContainer = styled.div`
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background: ${COLORS.white};
  display: inline-block;
  position: absolute;
  z-index: 100;
  left: 0;
  top: ${pxToRem('50px')};
`;
