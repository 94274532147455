import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { head, prop, compose } from 'ramda';
import hasher from 'object-hash';
import { partition_all } from '../../../utils/helpers';
import { RowContainer, ContentDivider, UnPaddedRowContainer } from './styled-components';

export { RowContainer, ContentDivider, UnPaddedRowContainer };

export const FieldSpreadLayoutContainer = ({ fields, columns, deviation, useAsKey, paddingTop }) =>
  partition_all(fields, columns).map(innerColumns => (
    <Fragment key={`${compose(hasher, prop('props'), head)(innerColumns)}`}>
      <RowContainer paddingTop={paddingTop}>
        {innerColumns.map(inf => (
          <ContentDivider calcWidth={100 / columns - deviation} key={prop(useAsKey, inf.props)}>
            {inf}
          </ContentDivider>
        ))}
      </RowContainer>
    </Fragment>
  ));

FieldSpreadLayoutContainer.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.element),
  columns: PropTypes.number.isRequired,
  deviation: PropTypes.number
};

FieldSpreadLayoutContainer.defaultProps = {
  deviation: 5,
  useAsKey: 'name'
};

export default FieldSpreadLayoutContainer;
