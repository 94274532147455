import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Icon from '../../Icon';

export const FormStyles = styled.div`
  width: 100%;
  & div {
    width: 100%;
  }
  & Field {
    width: 100%;
  }
  & input {
    width: 100%;
  }
  & span {
    margin-left: 10px;
    color: red;
  }
  & select {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
    background: #fff;
    padding: 0px 10px;
    outline: 0px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
      no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 97%;
  }
  & option:first-child {
    color: ${COLORS.gray} !important;
  }
`;
export const BatchTitle = styled.h3`
  color: ${COLORS.darkBlue};
  font-size: ${pxToRem('32px')};
  font-weight: normal;
  padding: 0;
  margin: 0;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const BatchNumber = styled.div`
  background: ${COLORS.veryLightGray};
  padding: ${pxToRem('3px')} ${pxToRem('10px')};
  border-radius: ${pxToRem('13px')};
  margin: ${pxToRem('10px')} 0px;
  font-size: ${pxToRem('13px')};
  color: ${COLORS.offBlack};
`;

export const TileTextWrapper = styled.div`
  font-size: ${({ size }) => size || pxToRem('11px')};
  letter-spacing: ${pxToRem('0.3px')};
  color: ${({ color }) => color || COLORS.greyChateau};
  margin: ${({ margin }) => margin || 0};
  text-decoration: ${({ textDecoration }) => textDecoration || 'normal'};
  font-style: ${({ fontStyle }) => fontStyle || 'initial'};
`;

export const TileTextSpan = styled.span`
  font-size: ${({ size }) => size || pxToRem('11px')};
  letter-spacing: ${pxToRem('0.3px')};
  display: inline !important;
  color: ${({ color }) => color || COLORS.greyChateau};
  margin: ${({ margin }) => margin || 0};
  text-decoration: ${({ textDecoration }) => textDecoration || 'normal'};
  font-style: ${({ fontStyle }) => fontStyle || 'initial'};
  cursor: pointer;
`;

export const TileText = styled.span`
  color: ${COLORS.offBlack};
`;

export const BatchTotal = styled.h3`
  color: ${COLORS.offBlack};
  font-size: ${({ size }) => size || pxToRem('24px')};
  padding: 0;
  margin: ${({ margin }) => margin || 0};
`;

export const BarDiv = styled.div`
  border-left: ${({ green, red }) =>
    red
      ? `${pxToRem('7px')} solid ${COLORS.red}`
      : green
      ? `${pxToRem('7px')} solid ${COLORS.forestGreen}`
      : `${pxToRem('7px')} solid ${COLORS.lightCyanGray}`};
  margin: ${({ margin }) => margin || 0};
  padding-left: ${pxToRem('10px')};
`;

export const NoBatchSubHeader = styled.p`
  margin: ${({ margin }) => margin || 0};
  padding: 0;
`;
export const InputWrapper = styled.div`
  padding-bottom: 4px;
`;

export const ModalContainer = styled.div`
  font-size: 13px;
  padding: 20px;
  ${({ newAccount }) => (newAccount ? `background-color: ${COLORS.greyout};` : '')}
`;

export const CardElementWrapper = styled.div`
  margin-left: 20px;
  width: 400px;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const SuccessRow = styled.div`
  text-align: center;
`;

export const BatchRow = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0;
  width: 430px;
`;

export const BatchAmount = styled.div`
  font-size: 24px;
  color: ${COLORS.denimBlue};
`;

export const Batch = styled.span`
  background-color: ${COLORS.athensGrayDarker};
  border-radius: 13px;
  padding: 3px 10px;
`;

export const FundTotal = styled.span`
  color: ${COLORS.denimBlue};
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
`;

export const FundTotalText = styled.span`
  color: ${COLORS.cobaltGrey};
  padding-left: 10px;
  position: relative;
  top: 3px;
`;

export const HeaderText = styled.h2`
  color: ${COLORS.textColor};
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
`;

export const GhostContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AddIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 9px;
`;

export const BackIcon = styled(Icon)`
  position: 'relative';
`;

export const ConfirmationIcon = styled(Icon)`
  position: relative;
  top: -1px;
  right: -10px;
`;

export const IconSuccess = styled(Icon)`
  margin: 20px 0 12px;
`;

export const FundStyle = {
  margin: '12px 0'
};

export const InputStyle = {
  fontSize: '13px',
  width: '100%',
  marginTop: '0',
  marginBottom: '0',
  zIndex: 1
};

export const RadioStyle = {
  fontSize: '13px'
};

export const GhostStyle = {
  fontSize: '14px',
  fontWeight: '500',
  paddingRight: '40px'
};

export const Overlay = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${props => (props.show ? 'block' : 'none')};

  &:before {
    content: '';
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

export const SpinnerMessage = styled.div`
  color: white;
`;

export const VerifyText = styled.div`
  color: black;
  padding-bottom: 1rem;
`;

export const StyleBackButton = styled.div`
  color: black;
  margin-right: 1rem;
`;

export const CardTextWrap = styled.div`
  display: flex;
`;

export const CardNumber = styled.div`
  color: ${COLORS.denimBlue};
  font-weight: 500;
`;

export const BankName = styled.span`
  color: ${COLORS.denimBlue};
  font-weight: 500;
  line-height: 25px;
`;

export const CardExpireWrap = styled.div`
  text-align: right;
  flex: 1;
  padding-right: 10px;
`;

export const CardExpireText = styled.span`
  color: ${COLORS.denimBlue};
  font-weight: 500;
`;

export const CashPayment = styled.div`
  font-weight: 500;
  text-align: center;
  padding: 10px;
`;

export const MainWrapper = styled.div`
  margin-bottom: 2.5em;
`;

export const ListWrapper = styled.div`
  height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background: ${COLORS.white};
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border: ${pxToRem('2px')} solid transparent;
    border-radius: ${pxToRem('9px')};
    background-clip: content-box;
  }
  & < div {
    width: 100%;
    margin-left: 1px;
  }
`;

export const AddBtn = styled.button`
  font-size: 16px;
  padding: 5px 20px;
  border: 1px solid #ccc;
  background: ${COLORS.navyBlue};
  color: #fff;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background: ${COLORS.veryLightGray};
    color: ${COLORS.lightGrey};
  }
`;

export const TabsContainer = styled.div`
width: 100 %;
margin: 0;
padding: 0;
border - bottom: 1px solid ${COLORS.lightGray};
`;

export const FundingActionItem = styled.div`
  display: flex;
  width: auto;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  justify-content: flex-end;
  cursor: pointer;
  & svg {
    margin: 0px !important;
    color: #fff;
  }
  &:not(:last-child) {
    margin-right: ${pxToRem('20px')};
  }
`;

export const MandateContent = styled.div`
  padding: 10px 20px;
  font-size: ${pxToRem('15px')};
`;

export const StandaloneRadioBtnContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.defaultAlignment ? 'start' : 'space-around')};
  border: 1px solid #d2d9df;
  width: 440px;
  padding-left: ${props => (props.defaultAlignment ? '10px' : '0px')};
`;

export const RedirectText = styled.span`
  margin-top: 10px;
`;

export const RedirectLink = styled(NavLink)`
  color: ${COLORS.denimBlue};
`;

export const BatchDetails = styled.div`
  & p {
    position: relative;
    padding-top: 0;
  }
`;
