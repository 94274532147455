import styled from 'styled-components';
import { pxToRem } from '../../../utils/helpers';
import { mediaQueries } from '../../../utils/styles';

export const GameContainer = styled.div`
  display: ${({ expand }) => (expand ? 'none' : 'flex')};
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  .pz {
    padding: 0px 0px;
  }
  .w-100 {
    width: 100%;
    overflow: hidden;
    margin-left: 0px;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: flex;
  }

  .mobileView {
    display: none;
  }

  .collapseMobile {
    position: relative;
    bottom: 0px;
    width: 100%;
    z-index: 99;
    height: ${({ height }) => height && height};
    display: none;
  }
  ${mediaQueries.md(` {
    width:100%;
    flex-direction:column;
    .desktop{display:none;}
    .collapseMobile{display: block;}
   }
   .mobileView{display: block; color:#ccc;}
  }`)}
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${props => (props.show ? 'block' : 'none')};
  background-color: rgba(0, 0, 0, 0.04);
  -webkit-backdrop-filter: blur(${pxToRem('2px')});
  backdrop-filter: blur(${pxToRem('2px')});
  &:before {
    content: '';
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
