export const FROM_GAMES = 'games';
export const FROM_REPORTS = 'reports';
export const FROM_NOTIFICATIONS = 'notifications';
export const FROM_CALENDAR = 'calendar';
export default {
  incidentReportsModal: 'incidentReportsModal',
  gameReportsModal: 'gameReportsModal',
  crewVerificationReportsModal: 'crewVerificationReportsModal',
  assessmentReportsModal: 'assessmentReportsModal'
};
