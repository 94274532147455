import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';

import { pxToRem } from '../../../utils/helpers';
import COLORS from '../../../utils/styles';

export const Wrapper = styled.div`
  display: inline;
  flex: 1;
  width: 100%;
`;

export const Container = styled(Row)`
  padding: 1rem 8rem;
`;
export const InnerWrapper = styled(Col)``;

export const InfoInnerWrapper = styled(Col).attrs({
  'data-intercom-target': 'ViewInfo'
})``;

export const ListInnerWrapper = styled(Col).attrs({
  'data-intercom-target': 'GroupList'
})``;

export const Heading = styled.h3`
  font-family: 'Barlow';
  font-size: ${pxToRem('25px')};
  color: ${COLORS.newDenimBlue};
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-top: 0px;
  font-weight: 600;
  & small {
    font-weight: 800;
    font-size: ${pxToRem('30px')};
  }
`;
