import styled from 'styled-components';
import { pxToRem } from '../../../utils/helpers';
import { COLORS, mediaQueries } from '../../../utils/styles';

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const EmptyMessage = styled.p`
  margin: ${pxToRem('23px')} 0 0 ${pxToRem('34px')};
  font-family: 'RobotoLight';
  font-size: 1rem;
  line-height: 1.06;
  color: ${COLORS.shuttleGray};
  font-weight: 600;
  ${`@media (max-width: 1315px) { max-width: 100%;}`}
  ${mediaQueries.sm(`max-width:80%;`)}
  ${mediaQueries.xs(`max-width:60%;`)};
  ${`@media (max-width: 375px) { max-width:40%;}`}
`;

export const PaginationWrapper = styled.div`
  z-index: 9999;
`;
