import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Modal from '../../../../Modal';
import Button from '../../../../Button';
import { Block } from './styled-components';

const CancelConfirmModal = ({ onClose, onSubmit, tableName }) => (
  <Modal title="Unsaved Pay table Changes" onClose={onClose} background>
    <Row>
      <Block xs={10} xsOffset={1}>
        {`You have unsaved changes to table ${tableName}. Unsaved changes will be lost.`}
      </Block>
      <Block xs={12}>
        <Row center="xs">
          <Col xs={4}>
            <Button label="Continue Editing" onClick={onSubmit} primary />
          </Col>
          <Col xs={4}>
            <Button label="Discard Changes" onClick={onClose} />
          </Col>
        </Row>
      </Block>
    </Row>
  </Modal>
);

CancelConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired
};

CancelConfirmModal.defaultProps = {};

export default CancelConfirmModal;
