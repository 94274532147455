import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pxToRem } from '../../../../utils/helpers';
import { COLORS } from '../../../../utils/styles';

export const Wrapper = styled.div`
  background: ${COLORS.white};
  width: ${pxToRem('241px')};
  position: absolute;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.45);
  top: 100%;
  right: 0;
  z-index: 1000;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserMenuLink = styled(Link)`
  flex: 1;
  display: flex;
  align-items: center;
  min-height: ${pxToRem('42px')};
  padding: 0 ${pxToRem('22px')};
  font-family: 'Roboto', sans-serif;
  font-size: ${pxToRem('13px')};
  color: ${COLORS.denimBlue};
  text-decoration: none;

  &:hover {
    background-color: ${COLORS.aliceBlueVariant};
  }
`;
