import { path, prop } from 'ramda';
import { parseDateMMDDYYYY } from '../../../utils/parsers';

export const getCheckedGames = (checkedGames, games) =>
  checkedGames.map(id => games.find(game => game.id === id));

export const parseFormData = d => ({
  display_id: d.display_id,
  date: parseDateMMDDYYYY(d.start_date),
  start_date: parseDateMMDDYYYY(d.start_date),
  end_date: parseDateMMDDYYYY(d.start_date),
  start_at: prop('start_time', d),
  timezone: d.timezone,
  end_at: prop('end_time', d),
  status: d.status,
  source: {
    event_id: prop('display_id', d),
    schedule_id: path(['game_level', 'schedule_id'], d)
  },
  game_level: {
    game_level: path(['game_level', 'game_level'], d),
    game_type: path(['game_level', 'game_type'], d)
  },
  location: {
    ...d.location
  },
  team_home: {
    ...d.team_home
  },
  team_visitor: {
    ...d.team_visitor
  },
  subscriptionId: d.subscriptions && d.subscriptions.length && d.subscriptions[0]
});

export const getGameData = games =>
  games.map(game => ({
    id: game.id,
    display_id: game.display_id,
    location: game.field_name,
    complex: game.location && game.location.name,
    home_team: game.team_home && game.team_home.name,
    away_team: game.team_visitor && game.team_visitor.name,
    date: game.start_date,
    time: game.start_time
  }));

export const openExternalGameReportURL = (url, token) => {
  setTimeout(() => {
    window.open(`${url}&id_token=${token.id_token}`, 'gameReportTab');
  });
};
