import { compose, withState, lifecycle } from 'recompose';
import { EVENT_STATUS, GAME_UPLOAD_TYPES } from '../../../../../constants';

export default compose(
  withState('expandedId', 'setExpandedId', null),
  withState('selectedEventId', 'setSelectedEventId', null),
  lifecycle({
    componentDidMount() {
      if (!this.props.events.length) {
        this.props.fetchAllUserEvents({
          userOnly: true,
          filters: {
            game_upload_type: GAME_UPLOAD_TYPES.connect_game_sync,
            status: EVENT_STATUS.OPEN
          }
        });
      }
    }
  })
);
