import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Field } from 'react-final-form';
import RoundedCheckBox from '../../../RoundedCheckBox';
import MaterialInput from '../../../MaterialInput';
import FieldError from '../../../FieldError';
import { Block, HelpText } from '../../Categories/styled-components';
import { Inline, FillHeight, Spaced, LabelText } from '../styled-components';
import { emptyStringToNull, emptyStringToZero, falsyToNull } from '../../../../../utils/parsers';
import MaterialDropDown from '../../../MaterialDropDown';
import { required } from '../../../../../utils/validations';
import ConditionalField from '../../../ConditionalField';
import { fields, displaySettings } from './config';

const MaterialDropDownTheme = {
  wrapper: {
    marginBottom: '1.5rem'
  }
};

const ScoreEntryTheme = {
  wrapper: {
    marginBottom: '0.5rem'
  },
  disabled: {
    color: '#167CD4'
  }
};

const ReportAvailability = props => (
  <Row middle="xs">
    <Col xs={12}>
      <Field
        component={MaterialDropDown}
        name="score_entry_stats"
        validate={required}
        options={fields.score_entry_stats.options}
        onChange={(...args) => props.mutators.scoreEntryStats(...args)}
        theme={MaterialDropDownTheme}
        helpText="Score Entry & Player Stats"
        showError
      />
      <HelpText>Game Report Link will be available to:</HelpText>
      <FillHeight>
        {[1, 2, 3, 4, 5, 6, 7, 8].map(position => (
          <Col xs={3} key={position}>
            <Field name={`link_position_${position}`} parse={falsyToNull}>
              {({ input }) => <RoundedCheckBox {...input} />}
            </Field>
            <HelpText>Position {position}</HelpText>
          </Col>
        ))}
      </FillHeight>
      <FieldError name="link_position" />
    </Col>
    <Block xs={12} padding={0}>
      <HelpText>
        Lock the game report after
        <Inline>
          <Field
            component={MaterialInput}
            name="lock_after"
            type="number"
            min={1}
            max={99}
            hideArrows
            parse={emptyStringToNull}
          />
        </Inline>
        days
      </HelpText>
    </Block>
    {props.gameUploadType === 2 && (
      <Col xs={12}>
        <HelpText>Display the following sections for the game report: </HelpText>
        <FillHeight>
          {Object.keys(displaySettings).map(key => (
            <Col xs={12} key={key}>
              <Row>
                <Field name={`${key}`} parse={falsyToNull}>
                  {({ input }) => <RoundedCheckBox {...input} />}
                </Field>
                <LabelText>{displaySettings[key].label}</LabelText>
                {key === 'is_score_entry_option' && (
                  <ConditionalField when="is_score_entry_option" predicate={type => !type}>
                    {score_entry_option => (
                      <Col xs={6}>
                        <Field
                          component={MaterialDropDown}
                          disabled={true || score_entry_option}
                          disabledBlue={true || score_entry_option}
                          name="score_entry_option"
                          validate={required}
                          options={[
                            { name: 'Team Score Only', value: 'Team Score Only' },
                            { name: 'Score by Player', value: 'Score by Player' }
                          ]}
                          theme={ScoreEntryTheme}
                          helpText=""
                          showError
                        />
                      </Col>
                    )}
                  </ConditionalField>
                )}
              </Row>
              {key === 'mark_forfeit' && (
                <ConditionalField when="mark_forfeit" predicate={type => !type}>
                  {officialsAllowed => (
                    <div>
                      <Col xs={12}>
                        <Spaced>
                          <Field
                            component={MaterialInput}
                            disabled={officialsAllowed}
                            name="default_score_non_forfeit"
                            type="number"
                            min={0}
                            max={999}
                            parse={emptyStringToZero}
                            hideArrows
                          />
                        </Spaced>
                        <HelpText>Default Score for Non-Forfeit Team</HelpText>
                      </Col>
                      <Col xs={12}>
                        <Spaced>
                          <Field
                            component={MaterialInput}
                            disabled={officialsAllowed}
                            name="default_score_forfeit"
                            type="number"
                            min={0}
                            max={999}
                            parse={emptyStringToZero}
                            hideArrows
                          />
                        </Spaced>
                        <HelpText>Default Score For Forfeit Team</HelpText>
                      </Col>
                    </div>
                  )}
                </ConditionalField>
              )}
            </Col>
          ))}
        </FillHeight>
      </Col>
    )}
  </Row>
);

export default ReportAvailability;
