import { compose, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import {
  createBillingCustomer as createBillingCustomerAction,
  fetchBillingCustomers as fetchBillingCustomerAction,
  updateBillingCustomer as updateBillingCustomerAction
} from '../../../../../core/BillingCustomers';
import {
  billingCustomerIsFetching,
  getBillingCustomerList
} from '../../../../../core/BillingCustomers/selector';
import { getEventInfo } from '../../../../../core/Events/selectors';

const mapStateToProps = state => ({
  eventInfo: getEventInfo(state),
  billingCustomersList: getBillingCustomerList(state) || '',
  billingCustomerIsFetching: billingCustomerIsFetching(state)
});

const mapDispatchToProps = dispatch => ({
  createBillingCustomer: payload => dispatch(createBillingCustomerAction(payload)),
  fetchBillingCustomer: () => dispatch(fetchBillingCustomerAction()),
  updateBillingCustomer: payload => dispatch(updateBillingCustomerAction(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onAddBillingCustomer: ({ createBillingCustomer, eventInfo }) => values => {
      const platformId = eventInfo && eventInfo.platform_id;
      /*
      Dev notes: a better way to do this is to not mutate the param and instead set the correct value
      when the object is composed. this didn't work because of the way we send field values to the framework
      and it is the framework that decides how to compose the resulting object. Maybe someone smarter than 
      me can figure this one out
     */
      /* eslint-disable no-param-reassign */
      values.state = (values.state && values.state.value) || values.state;
      createBillingCustomer({
        data: { ...values },
        platformId
      });
    }
  }),
  pure
);
