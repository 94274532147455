import React from 'react';
import PropTypes from 'prop-types';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Wrapper,
  EmptyCellLabel,
  NonPaymentIcon,
  PaymentMouseOverPopoverWrapper
} from './styled-components';
import { Name, Ammount } from '../PositionCell/styled-components';
import { numToCurrency } from '../../../../../../utils/parsers';
import MouseOverPopover from '../../../../../global-material-components/Popover';

const AssignorCell = ({
  assignment,
  setSelectedAssignment,
  setSelectedGame,
  selectedGame,
  currentId,
  grayOut,
  uniqueGameId,
  totalAssignorFee,
  allGamesCheck,
  setAllGamesCheck,
  reviewMode,
  requiredPaymentProfile,
  minTransferAmount
}) => {
  const selected = selectedGame && selectedGame.currentId === currentId;
  const onClickFn = () => {
    if (!reviewMode) {
      setSelectedAssignment(null);
      setAllGamesCheck(false);
      if (selected) {
        setSelectedGame(null);
      } else {
        setSelectedGame({ currentId, uniqueGameId });
      }
    }
  };

  if (assignment) {
    const {
      event_role: {
        user: { first_name, last_name, payee }
      }
    } = assignment;
    const isPayoutNotEnabled =
      requiredPaymentProfile && (!payee || (payee && !payee.transfers_enabled));
    const shouldGrayOut = isPayoutNotEnabled ? true : grayOut;

    //  const { batch_pay, event_role: { user: { first_name, last_name } } } = assignment;
    // if (batch_pay) {
    //   totalPayment = numToCurrency(batch_pay.total_payment);
    // } else {
    const totalPayment = totalAssignorFee ? numToCurrency(totalAssignorFee) : '';
    //  }
    const isTransferEligible = totalAssignorFee >= minTransferAmount;
    if (assignment.batched && !reviewMode) {
      return (
        <Wrapper reviewMode>
          <Ammount grayOut={shouldGrayOut}>{totalPayment}</Ammount>
          <Name grayOut={shouldGrayOut}>{`${first_name}`}</Name>
          <Name grayOut={shouldGrayOut}>{`${last_name}`}</Name>
        </Wrapper>
      );
    }

    return (
      <Wrapper onClick={onClickFn} selected={selected || allGamesCheck} reviewMode>
        <Ammount grayOut={shouldGrayOut} selected={selected || allGamesCheck}>
          {totalPayment}
        </Ammount>
        <Name grayOut={shouldGrayOut} selected={selected || allGamesCheck}>{`${first_name}`}</Name>
        <Name grayOut={shouldGrayOut} selected={selected || allGamesCheck}>{`${last_name}`}</Name>
        {isPayoutNotEnabled && (
          <NonPaymentIcon>
            <MonetizationOnOutlinedIcon />
          </NonPaymentIcon>
        )}
        {!reviewMode && !isTransferEligible && (
          <PaymentMouseOverPopoverWrapper>
            <MouseOverPopover
              transformOriginHorizontal="left"
              messageTitle="Invalid Amount"
              messageBody={`Pay amount must be greater than or equal to $${
                minTransferAmount && minTransferAmount.toFixed(2)
              }`}
            >
              <InfoOutlinedIcon />{' '}
            </MouseOverPopover>
          </PaymentMouseOverPopoverWrapper>
        )}
      </Wrapper>
    );
  }
  return (
    <Wrapper onClick={onClickFn} selected={selected || allGamesCheck} reviewMode>
      <EmptyCellLabel grayOut={grayOut} selected={selected || allGamesCheck}>
        Assign Primary
      </EmptyCellLabel>
    </Wrapper>
  );
};

AssignorCell.propTypes = {
  assignment: PropTypes.objectOf(Object),
  selectedGame: PropTypes.objectOf(Object),
  setSelectedGame: PropTypes.func,
  setSelectedAssignment: PropTypes.func,
  currentId: PropTypes.string.isRequired,
  uniqueGameId: PropTypes.string.isRequired,
  totalAssignorFee: PropTypes.number,
  allGamesCheck: PropTypes.bool.isRequired,
  setAllGamesCheck: PropTypes.func.isRequired,
  grayOut: PropTypes.bool.isRequired,
  reviewMode: PropTypes.bool.isRequired
};

AssignorCell.defaultProps = {
  assignment: null,
  selectedGame: null,
  setSelectedGame: () => null,
  setSelectedAssignment: () => null,
  totalAssignorFee: null
};

export default AssignorCell;
