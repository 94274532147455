import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchActiveUserInfo } from '../../../core/User';
import { logout, storeSelectedRole } from '../../../core/Auth';
import {
  getUserHeaderProfile,
  getUserIndex,
  canViewReports,
  isAnyEventAdmin,
  isAnyEventAdminOrFundingAdmin,
  isNotFundingAdmin,
  isSuperAdmin,
  canViewPayments,
  canViewDashboard,
  getSelectedRole
} from '../../../core/Auth/selectors';

export default compose(
  withRouter,
  connect(
    state => ({
      canViewReports: canViewReports()(state),
      canViewPayments: canViewPayments()(state),
      isEventAdmin: isAnyEventAdmin()(state),
      isEventAdminOrFundingAdmin: isSuperAdmin(state) || isAnyEventAdminOrFundingAdmin()(state),
      isNotFundingAdmin: isNotFundingAdmin()(state),
      userProfile: getUserHeaderProfile(state),
      userIndex: getUserIndex(state),
      isSuperAdmin: isSuperAdmin(state),
      userId: state.auth && state.user.activeUser && state.user.activeUser.id,
      currentUserId: state.auth && state.auth.user && state.auth.user.id,
      showDashboard: canViewDashboard()(state),
      selected: getSelectedRole(state)
    }),
    {
      onLogout: logout,
      fetchUserInfo: fetchActiveUserInfo,
      persistRole: storeSelectedRole
    }
  )
);
