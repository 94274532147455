import styled from 'styled-components';
import Icon from '../Icon';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';
import { CollapsedContent } from '../CollapsableContent/styled-component';
import { Border, RawInput } from '../Input/styled-components';
import { RawButton } from '../Button/styled-components';

export const EmailSenderContainer = styled.div`
  min-height: ${props => pxToRem(`${props.height}px`)};
  background: ${COLORS.white};
  transition: all 0.3s;
  display: flex;
  flex-direction: column;

  & ${CollapsedContent} {
    padding: ${pxToRem('10px')} 0;
    border-bottom: 1px solid ${COLORS.hawkesBlue}50;
  }
`;

export const EmailSenderInnerContainer = styled.div`
  flex: 1;
`;

export const SuccessMessageContainer = styled.div`
  min-width: ${pxToRem('368px')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const SuccessMessage = styled.span`
  color: ${COLORS.forestGreen};
  font-size: ${pxToRem('13px')};
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledIcon = styled(Icon)`
  margin: 0;
`;

export const SuccessIcon = StyledIcon.extend`
  margin-right: ${pxToRem('5px')};
`;

export const Content = styled.div`
  padding: ${pxToRem('10px')};

  & ${Border} {
    display: flex;
    padding-left: 0;
  }

  & ${RawInput} {
    &::placeholder {
      font-weight: bold;
    }
  }
`;

export const RecipientList = styled.div`
  padding-right: ${'10px'};
  text-align: left;
`;

export const AlertMessageContainer = styled.div`
  margin: ${pxToRem('10px')} 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlertMessage = styled.span`
  font-size: ${pxToRem('12px')};
  font-weight: bold;
  color: ${COLORS.denimBlue};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & ${RawButton}:not(:last-child) {
    margin-right: ${pxToRem('16px')};
  }
`;

export default EmailSenderContainer;
