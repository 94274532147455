import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';
import { REPORT_TYPES } from '../../../../../constants';

const BulkUnapproveModal = ({ onClose, selected, onSubmit, reportType }) => {
  const reportTypeCapitalized = reportType.charAt(0).toUpperCase() + reportType.slice(1);
  const selectedByType = REPORT_TYPES.assessment === reportType ? [].concat(...selected) : selected;
  const unapproveable = selectedByType.filter(report => report.approved);
  return (
    <Modal
      title="Unapprove Selected"
      onClose={onClose}
      size={{
        xs: 8,
        sm: 8,
        md: 8,
        lg: 6
      }}
    >
      <>
        <Row>
          <HighlightedBlock xs={12}>
            <Content>
              <Summary>
                <Count color={COLORS.denimBlue}>{unapproveable.length}</Count>{' '}
                {reportTypeCapitalized} Reports selected to be unapproved.
              </Summary>
            </Content>
          </HighlightedBlock>
        </Row>
        <Row>
          <Block xs={12} className="last">
            <ButtonsContainer>
              <ButtonContainer>
                <Button
                  label="Unapprove"
                  onClick={() => {
                    onClose();
                    onSubmit(unapproveable);
                  }}
                  disabled={!unapproveable.length}
                  width="84px"
                  height="32px"
                  primary
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
              </ButtonContainer>
            </ButtonsContainer>
          </Block>
        </Row>
      </>
    </Modal>
  );
};

BulkUnapproveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired
};

BulkUnapproveModal.defaultProps = {};

export default BulkUnapproveModal;
