import React from 'react';
import { Form, Field } from 'react-final-form';
import { Dialog } from '../../global-material-components';
import { FormWrapper, FieldWrapper, ButtonContainer } from './styled-component';
import config from './config';
import Button from '../Button';
import Enhancer from '../../containers/AddWalletFundsModal';
import ConfirmationPopUp from './ConfirmationPopup';

const AddWalletFundsModal = ({
  userAccounts,
  setAddFund,
  open,
  options,
  bankName,
  closeConfirmationModal,
  openConfirmationModal,
  setAchModal,
  onSubmit,
  formData,
  confirmButtonHandler,
  totalBalance,
  businessDays
}) => {
  return (
    <Dialog
      title="Add funds to your wallet"
      onClose={() => {
        setAddFund(false);
      }}
      open={open}
      noCancel
      noPrimary
    >
      <FormWrapper>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            amount: 0
          }}
          mutators={{
            setACHAccountValue: (args, state, utils) => {
              utils.changeValue(state, 'account', () => {
                return args[0];
              });
            }
          }}
          render={({ handleSubmit, form, pristine, invalid, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  {config.fields.map(({ field }) => {
                    const { component: Component } = field;
                    return (
                      <Field {...field} name={field.name}>
                        {({ input, meta: { error } }) => (
                          <FieldWrapper
                            className={field.name === 'wallet_balance' ? 'walletBalance' : ''}
                          >
                            <Component
                              {...input}
                              error={error}
                              mutators={form.mutators}
                              setOpenACHModal={setAchModal}
                              options={options}
                              setAddFund={setAddFund}
                              totalBalance={totalBalance}
                            />
                          </FieldWrapper>
                        )}
                      </Field>
                    );
                  })}
                  <ButtonContainer>
                    <Button
                      label="Add Funds"
                      type="submit"
                      height="45px"
                      primary
                      disabled={submitting || pristine || invalid}
                    />
                  </ButtonContainer>
                  {openConfirmationModal && (
                    <ConfirmationPopUp
                      openModel={openConfirmationModal}
                      closeConfirmationHandler={closeConfirmationModal}
                      data={formData}
                      confirmButtonHandler={confirmButtonHandler}
                      setAddFund={setAddFund}
                      userAccounts={userAccounts}
                      bankName={bankName}
                      businessDays={businessDays}
                    />
                  )}
                </div>
              </form>
            );
          }}
        />
      </FormWrapper>
    </Dialog>
  );
};

export default Enhancer(AddWalletFundsModal);
