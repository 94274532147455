export default {
  official: {
    roles: {
      users: {
        officials: {
          viewOfficialProfile: true,
          viewOfficialCertification: true
        },
        assesors: {
          viewOfficialProfile: true,
          viewOfficialCertification: true
        }
      },
      games: {
        viewAssignmentHistory: true,
        viewEvents: true
      },
      reporting: {
        myEvents: true
      }
    }
  },
  assessor: {
    roles: {
      users: {
        officials: {
          viewAll: true,
          viewOfficialProfile: true,
          viewOfficialCertification: true
        },
        assesors: {
          viewOfficialProfile: true,
          viewOfficialCertification: true
        }
      },
      games: {
        viewAssignmentHistory: true,
        viewEvents: true
      },
      assessments: {
        view: true,
        submitNew: true
      },
      reporting: {
        gameAssignmentReport: true,
        gameStatsReport: true,
        myEvents: true
      }
    }
  },
  assignor: {
    roles: {
      users: {
        officials: {
          viewAll: true,
          viewOfficialProfile: true,
          viewOfficialCertification: true
        },
        assesors: {
          viewAll: true,
          viewOfficialProfile: true,
          viewOfficialCertification: true
        },
        users: {
          viewAll: true,
          viewOfficialCertification: true,
          viewOfficialProfile: true
        }
      },
      games: {
        viewAssignmentHistory: true,
        assignAssessors: true,
        assignOfficials: true,
        viewEvents: true
      },
      assessments: {
        view: true,
        editPast: true,
        submitNew: true
      },
      reporting: {
        gameAssignmentReport: true,
        gameStatsReport: true,
        assessorReport: true,
        officialsReport: true,
        myEvents: true,
        gameAssignmentHistoryReport: true,
        assessmentReport: true
      }
    }
  },
  admin: {
    roles: {
      users: {
        officials: {
          viewAll: true,
          addOfficials: true,
          removeOfficials: true,
          viewOfficialProfile: true,
          editOfficialProfile: true,
          viewOfficialCertification: true,
          editOfficialCertification: true
        },
        assesors: {
          viewAll: true,
          addOfficials: true,
          removeOfficials: true,
          viewOfficialProfile: true,
          editOfficialProfile: true,
          viewOfficialCertification: true,
          editOfficialCertification: true
        },
        users: {
          viewAll: true,
          editOfficialCertification: true,
          viewOfficialCertification: true,
          editOfficialProfile: true,
          viewOfficialProfile: true,
          removeOfficials: true,
          addOfficials: true
        }
      },
      events: {
        manageOfficialSettings: true,
        manageGameSettings: true,
        manageCrewPay: true,
        manageMerchantAccounts: true,
        manageAgeGroups: true,
        manageAssessorSettings: true,
        manageAssignorSettings: true,
        deleteEvents: true,
        copyEvents: true,
        addEvents: true
      },
      games: {
        addImportEvents: true,
        editDeleteEvents: true,
        viewEvents: true,
        assignOfficials: true,
        assignAssessors: true,
        viewAssignmentHistory: true
      },
      assessments: {
        view: true,
        editPast: true,
        submitNew: true
      },
      financials: {
        postPayments: true,
        addAdjustments: true,
        submitRefunds: true,
        manageMerchantAccounts: true,
        viewPaymentHistory: true
      },
      reporting: {
        gameAssignmentReport: true,
        gameStatsReport: true,
        adminReport: true,
        assignorReport: true,
        assessorReport: true,
        officialsReport: true,
        myEvents: true,
        gameAssignmentHistoryReport: true,
        assessmentReport: true,
        gamePayReport: true,
        ccTransaccionReport: true,
        financialAdjustmentReport: true,
        financialSummaryReport: true
      }
    }
  },
  funding: {
    roles: {
      financials: {
        postPayments: true
      }
    }
  }
};
