import React from 'react';
import { Row } from 'react-flexbox-grid';
import { isNil } from 'ramda';
import Dropdown from '../Dropdown';
import { ButtonAsLink } from '../Button';
import COLORS from '../../../utils/styles';
import {
  Wrapper,
  Container,
  Heading,
  InnerWrapper,
  InfoInnerWrapper,
  ListInnerWrapper
} from './styled-components';

export const ViewGroupInfo = ({ onViewGroupInfo, platformId }) => (
  <InfoInnerWrapper xs={12} xsOffset={0}>
    <ButtonAsLink
      to={onViewGroupInfo(platformId)}
      label="View Group Info"
      customStyle={{
        borderRadius: '5px',
        color: COLORS.white,
        backgroundColor: COLORS.newDarkBlue,
        height: '2.4rem',
        width: '9rem',
        fontFamily: 'FiraSans'
      }}
      type="button"
      margin="0rem"
      noShadow
      disabled={isNil(platformId) || Number.isNaN(Number(platformId))}
    />
  </InfoInnerWrapper>
);

const myEvents = 'My Events';
const GroupDetails = ({
  platformId,
  groups,
  setGroup,
  getStatements,
  onViewGroupInfo,
  defaultDropdownTitle,
  removeStatements
}) => {
  const userGroups = defaultDropdownTitle
    ? groups.concat([{ name: 'My Events', id: myEvents }])
    : groups;
  const groupName = userGroups.find(grp => platformId === grp.id);
  const groupDefaultValue = groupName ? groupName.name : 'My Groups';
  return (
    <Wrapper>
      <Container>
        <InnerWrapper xs={12} sm={12} md={12} lg={12} style={{ margin: '0 auto' }}>
          <Heading>
            {defaultDropdownTitle ? (
              groupName && groupName.id !== myEvents ? (
                <span>
                  All Events in <small>{groupName.name} </small>
                </span>
              ) : (
                defaultDropdownTitle
              )
            ) : (
              groupName && groupName.name
            )}
          </Heading>
          <Row>
            <ListInnerWrapper>
              <Dropdown
                onChange={({ target }) => {
                  const { value } = target;
                  if (value !== groupDefaultValue) {
                    setGroup({ id: value });
                    if (value === myEvents) {
                      removeStatements();
                    } else {
                      getStatements(value);
                    }
                  }
                }}
                dropdownWidth="9rem"
                options={userGroups.map(({ id: value, name }) => ({
                  value,
                  label: `${name}  ${value !== myEvents ? `- ${value}` : ''}`
                }))}
                borderRadius="5px"
                backgroundColor={COLORS.newDarkBlue}
                color={COLORS.white}
                outline="none"
                value={groupDefaultValue}
                dropdownHeight="2.5rem"
                placeholder={groupDefaultValue}
              />
            </ListInnerWrapper>
            <InnerWrapper style={{ paddingLeft: '20px' }}>
              <ViewGroupInfo onViewGroupInfo={onViewGroupInfo} platformId={platformId} />
            </InnerWrapper>
          </Row>
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

export default GroupDetails;
