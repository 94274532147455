import styled from 'styled-components';

import { COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

import Icon from '../Icon';

export const Wrapper = styled.div`
  align-self: center;
  background-color: ${COLORS.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  ${mediaQueries.sm(`
  margin: 0rem;
  padding: ${pxToRem('10px')};
  `)};
`;

export const HeaderWrapper = styled.div``;

export const DayName = styled.p`
  margin: 0px;
  color: #6c757d;
  font-size: ${pxToRem('13px')};
  font-family: 'Barlow';
  font-weight: 400;
`;

export const DateNumber = styled.p`
  box-sizing: border-box;
  margin: 0;
  color: #6c757d;
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
`;

export const ToolbarWrapper = styled.div`
  box-shadow: inset 0 -1px 0 0 ${COLORS.hawkesBlue}80;
`;

export const ToolbarHeader = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.blueGray};
  border-bottom: 1px solid ${COLORS.blueGray};
  padding: ${pxToRem('8px')} ${pxToRem('17px')};
  display: flex;
  position: relative;
  align-items: center;
`;

export const DateRange = styled.span`
  font-family: 'Barlow';
  font-weight: 500;
  font-size: ${pxToRem('16px')};
  color: ${COLORS.newDenimBlue};
  padding: 0 0 0 ${pxToRem('10px')};
`;

export const Chevron = styled(Icon)`
  user-select: none;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? '0px 0px' : `1px 2px 0 2px`)};
  ${({ reverse }) => (reverse ? 'transform: rotate(180deg)' : '')};
`;

export const EventTime = styled.div`
  text-transform: uppercase;
  font-family: 'BarlowSemiBold';
  color: #3d4956;
  overflow: hidden;
  font-size: ${props => props.fontSize && props.fontSize};
`;

export const EventTitle = styled(EventTime)`
  font-weight: 400;
  padding-left: 3px;
  font-family: 'BarlowSemiCondensed';
`;

export const EventDetails = styled.div`
  display: -webkit-inline-box;
  width: 100%;
  background: ${props => props.background && props.background};
  &:hover {
    background: ${props => props.hover && props.hover};
  }
  z-index: 99999;
`;

export const EventIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvailabilityWrapper = styled.div`
  font-size: 14px;
  font-family: 'Barlow';
  padding: 5px 5px;
  text-align: left;
  line-height: 1.5;
`;

export const AvailabilityTitle = styled.div`
  text-transform: capitalize;
  font-family: 'BarlowBold';
`;

export const AssignmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 15px;
  min-height: 200px;
`;

export const AssignmentTitle = styled.div`
  text-transform: capitalize;
  background: #d2e9ff;
  padding: 8px 6px;
  margin: 0;
  color: rgb(0, 64, 133);
  fontfamily: Barlow;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
`;

export const GreyTitle = styled.div`
  color: #6c757d;
  font-size: 13px;
  font-family: 'Barlow';
  font-weight: '400';
  margin-top: 10px;
  padding: 0 6px;
`;
export const Info = styled.div`
  color: ${COLORS.newDenimBlue};
  font-size: 13px;
  font-family: 'Barlow';
  font-weight: '400';
  padding: 0 6px;
  text-transform: capitalize;
`;

export const GameStatus = styled.div`
  & > div {
    background: ${COLORS.reddish};
    border-radius: 4px;
    display: inline-table;
    font-family: 'Barlow';
    font-weight: 700;
    padding: 2px 10px;
    margin: 10px 0px 0px 8px;
    color: #fff;
    text-transform: capitalize;
  }
`;

export const TzDropDown = styled.select`
  width: 8rem;
  border: 1px solid #ccc;
  color: ${COLORS.black};
  height: 25px;
  background: #fff;
  outline: none;
  margin-top: 10px;
`;

export const AvailableIconSpan = styled.span`
  font-size: 15px;
`;
