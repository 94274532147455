import React, { useState } from 'react';

import { Dialog } from '../../global-material-components';
import ACHAccount from '../Profile/PaymentMethod/Payer/paymentMethods/ach-account';
import MainWrapper from './styled-component';

export default function ACHModal({ open, closeHandler }) {
  const [dialogDisplay, setDialogDisplay] = useState('block');
  return (
    <Dialog
      id="addACHDialog"
      title="Add your Payment Method"
      onClose={() => {
        closeHandler();
      }}
      open={open}
      noCancel
      noPrimary
      style={{ display: dialogDisplay }}
    >
      <MainWrapper id="achModal">
        <ACHAccount setDialogDisplay={setDialogDisplay} closeModal={closeHandler} />
      </MainWrapper>
    </Dialog>
  );
}
