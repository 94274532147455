import { compose, pure, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getUserProfile, isActive as isActiveSelector } from '../../../core/Auth/selectors';
import {
  changePassword as changePasswordAction,
  changeEmail as changeEmailAction,
  resendEmail as resendEmailActon
} from '../../../core/Auth';

export default compose(
  connect(
    state => ({
      username: getUserProfile(state).email,
      isActive: isActiveSelector(state),
      password: 'password',
      currentUserId: state.auth && state.auth.user && state.auth.user.id,
      userId: state.auth && state.user.activeUser && state.user.activeUser.id
    }),
    {
      changePassword: changePasswordAction,
      changeEmail: changeEmailAction,
      resendEmail: resendEmailActon
    }
  ),
  withState('modalVisibility', 'setVisibility', { username: false, password: false }),
  withHandlers({
    toggleModal: ({ setVisibility }) => modal => setVisibility(v => ({ ...v, [modal]: !v[modal] }))
  }),
  withHandlers({
    onChangePassword: ({ toggleModal, changePassword }) => ({ old_password, new_password }) => {
      new Promise(resolve => changePassword({ old_password, new_password, resolve })).then(() => {
        toggleModal('password');
      });
    },
    onChangeEmail: ({ toggleModal, changeEmail, isActive }) => ({ email, password }) => {
      new Promise(resolve => changeEmail({ email, password, isActive, resolve })).then(() => {
        toggleModal('username');
      });
    }
  }),
  pure
);
