import MultipleInput from '../Reusable/MultipleInput';
import MultiSelectDropdown from '../Reusable/MultiSelectDropdown';
import { allAvailableProvinces } from '../../../constants';
import theme from '../SideList/styled-components';
import { sortListByKey } from '../../../utils/helpers';

export default ({ allLocations: locations = [] }) => ({
  sort: {
    options: [
      {
        name: 'name',
        label: 'Complex Name'
      },
      {
        name: 'state,city',
        label: 'City/State'
      },
      {
        name: 'postal_code',
        label: 'Zip Code'
      }
    ]
  },
  filters: [
    {
      component: MultipleInput,
      name: 'location.city',
      placeholder: 'City',
      theme: theme.multipleInput
    },
    {
      component: MultiSelectDropdown,
      name: 'location.state',
      placeholder: 'State/Province',
      options: allAvailableProvinces.map(({ name, value }) => ({
        name,
        value
      })),
      noCount: true,
      theme: theme.multiSelectDropdown
    },
    {
      component: MultipleInput,
      name: 'location.postal_code',
      placeholder: 'Zip Code',
      maxLength: 7,
      theme: theme.multipleInput
    },
    {
      component: MultiSelectDropdown,
      name: 'location.name',
      placeholder: 'Complex Name',
      options: sortListByKey(
        [...new Set(locations.map(({ name }) => name))].map(name => ({
          name,
          value: name
        }))
      ),
      noCount: true,
      theme: theme.multiSelectDropdown
    }
  ],
  actions: {
    options: []
  }
});
