import styled from 'styled-components';
import { mediaQueries } from '../../../utils/styles';

const DasboardWrapper = styled.div`
  display: contents;
  .mobile {
    display: none;
  }
  ${mediaQueries.md(`.mobile{display:block;}`)};
`;

export default DasboardWrapper;
