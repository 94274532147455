import React from 'react';
import PropTypes from 'prop-types';
import { compose, map, prop, find, propEq, toString } from 'ramda';
import { Form, Field } from 'react-final-form';
import OutsideClickHandler from 'react-outside-click-handler';
import Checkbox from '../../../Checkbox';
import Button from '../../../Button';
import LoaderWrapper from '../../../LoaderWrapper';
import { calcAssignorFee, calcOfficialTotal } from '../helpers';
import {
  Wrapper,
  TitleWrapper,
  Title,
  ContentWrapper,
  Name,
  Info,
  InfoWrapper,
  Divider,
  RateText,
  RateWrapper,
  RateTextBlue,
  ButtonWrapper,
  CheckboxContainer,
  DisabledSpan
} from './styled-components';
import {
  numToCurrency,
  currencyToNum,
  emptyStringToNull,
  normalizePrice
} from '../../../../../utils/parsers';

// import PayHistoryRow from './PayHistoryRow';

import TextArea from '../../../Reusable/TextArea';
import { CellInput } from '../../../Event/CrewPay/styled-components';
import RoundCheckbox from '../../../RoundedCheckBox';

const getGreatestYear = compose(toString, x => Math.max(...x), map(prop('year')));
const getLastGrade = roles =>
  compose(prop('grade'), year => find(propEq('year', year), roles), getGreatestYear)(roles);

const getTotal = (assignor, assignor_pay, base_pay, travel_pay, adjustment) =>
  calcOfficialTotal({
    assignor,
    base_pay,
    adjustment,
    assignor_pay,
    travel_pay
  });

const AdjustmentPanel = ({
  assignment: {
    event_role: {
      role_id,
      user: { first_name, last_name, roles: userRoles, addresses }
    },
    adjustments,
    id: gameAssignmentId,
    travel_pay,
    base_pay = 0,
    assignor_pay,
    batch_pay_staging: { in_batch, has_payment_profile, crew_verification_report: has_attendance }
  },
  game: { assignment_primary_game: assignor },
  onSubmit,
  isAssignmentFetching,
  onOutsideClick,
  setAssignmentWarnings,
  report_settings
}) => {
  const { amount = 0, description = null, id: adjustmentId = null } = adjustments[0] || {};
  const travel_pay_amount = travel_pay ? travel_pay.amount : 0;
  const roles = userRoles.filter(r => r.role_id === role_id);
  const eligibleToPay = Boolean(
    has_payment_profile &&
      (report_settings && report_settings.allow_officials_to_checkin ? has_attendance : true)
  );

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <Wrapper>
        <LoaderWrapper isFetching={isAssignmentFetching}>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              shouldInBatch: in_batch && eligibleToPay,
              amount,
              description,
              isNew: !adjustments.length,
              adjustmentId: adjustmentId || gameAssignmentId,
              gameAssignmentId,
              base_pay,
              assignor_pay
            }}
            validate={({ amount: formAmount }) => {
              const adjustmentError = {
                adjustmentError:
                  'Adjustment is greater than sum of Base and Travel Fees. Please update the adjustment amount.'
              };
              const total = getTotal(
                assignor,
                assignor_pay,
                base_pay,
                travel_pay_amount,
                formAmount
              );

              if (Number.isNaN(total)) return undefined; // field 'amount' already validates if number is valid
              if (total < 0 && formAmount < 0) return adjustmentError;
              return total >= 0 ? undefined : adjustmentError;
            }}
            render={({
              handleSubmit,
              values: { amount: formAmount },
              pristine,
              invalid,
              submitting,
              errors: { adjustmentError }
            }) => (
              <form onSubmit={handleSubmit}>
                <TitleWrapper>
                  <Title>Adjust Pay</Title>
                  <CheckboxContainer
                    onClick={() => {
                      if (!has_payment_profile) {
                        setAssignmentWarnings({
                          assignmentWarnings: [
                            {
                              message: `Payment Profile is required to be included in a batch.`,
                              error: true,
                              header: 'PAYMENT ERROR'
                            }
                          ]
                        });
                      }
                    }}
                  >
                    <Field name="shouldInBatch">
                      {({ input }) => <Checkbox locked={!eligibleToPay} {...input} />}
                    </Field>
                  </CheckboxContainer>
                  <DisabledSpan locked={!eligibleToPay}>In Batch</DisabledSpan>
                </TitleWrapper>
                <ContentWrapper theme={{ minHeight: '21rem' }}>
                  <Name>{`${first_name} ${last_name} `}</Name>
                  <InfoWrapper>
                    {roles.length ? <Info>Grade Level: {getLastGrade(roles)}</Info> : null}
                    {addresses.map(({ address_1, city, postal_code }) => (
                      <Info key={address_1}>{`${address_1}, ${city} (${postal_code})`}</Info>
                    ))}
                  </InfoWrapper>
                  {report_settings && report_settings.allow_officials_to_checkin ? (
                    has_attendance ? (
                      <>
                        <RoundCheckbox
                          name="checkedIn"
                          height="0.2rem"
                          width="0.4rem"
                          widthLabel="0.7rem"
                          heightLabel="0.7rem"
                          inlineLabel="Checked In"
                          customRound
                          value
                        />
                      </>
                    ) : (
                      <RoundCheckbox
                        name="checkedIn"
                        height="0.2rem"
                        width="0.4rem"
                        widthLabel="0.7rem"
                        heightLabel="0.7rem"
                        inlineLabel="Checked In"
                        customRound
                        disabled
                      />
                    )
                  ) : (
                    ''
                  )}

                  <RateWrapper>
                    <RateText>Base Pay</RateText>
                    <RateText>{numToCurrency(base_pay)}</RateText>
                  </RateWrapper>
                  <RateWrapper>
                    <RateText>Travel Pay</RateText>
                    <RateText>{numToCurrency(travel_pay_amount)}</RateText>
                  </RateWrapper>
                  <RateWrapper>
                    <RateText>Assignor Pay</RateText>
                    <RateText>
                      -
                      {numToCurrency(
                        calcAssignorFee({
                          assignor,
                          assignor_pay,
                          base_pay,
                          travel_pay: travel_pay_amount,
                          adjustment: formAmount
                        })
                      )}
                    </RateText>
                  </RateWrapper>
                  <RateWrapper>
                    <RateText>Adjustment</RateText>
                    <Field
                      parse={value => currencyToNum(normalizePrice(value, 4, false, false, true))}
                      format={value => normalizePrice(numToCurrency(value), 4, false, false, true)}
                      name="amount"
                      placeholder="$0.00"
                    >
                      {({ input, meta, ...rest }) => <CellInput {...input} {...rest} />}
                    </Field>
                  </RateWrapper>
                  <Divider />
                  <RateWrapper>
                    <RateTextBlue>Total</RateTextBlue>
                    <RateTextBlue>
                      {numToCurrency(
                        getTotal(
                          assignor,
                          assignor_pay,
                          base_pay,
                          travel_pay_amount,
                          currencyToNum(formAmount)
                        )
                      ) || '$~'}
                    </RateTextBlue>
                  </RateWrapper>
                  <Field
                    component={TextArea}
                    name="description"
                    showError
                    innerPlaceholder="Notes"
                    parse={emptyStringToNull}
                  />
                  <ButtonWrapper>
                    <Button
                      type="submit"
                      label="Save"
                      primary
                      disabled={pristine || invalid || submitting || adjustmentError}
                    />
                  </ButtonWrapper>
                  {adjustmentError && (
                    <InfoWrapper>
                      <Info red>{adjustmentError}</Info>
                    </InfoWrapper>
                  )}
                </ContentWrapper>
                <TitleWrapper>
                  <Title>Pay History</Title>
                </TitleWrapper>
                {/*       -----  Use this component and styles to populate Pay History
          <ContentWrapper theme={{ overflow: 'auto' }}>
            <PayHistoryRow date="5/28" id="80008008" amount="$50" />
          </ContentWrapper> */}
              </form>
            )}
          />
        </LoaderWrapper>
      </Wrapper>
    </OutsideClickHandler>
  );
};

AdjustmentPanel.propTypes = {
  assignment: PropTypes.objectOf(Object),
  game: PropTypes.objectOf(Object),
  onSubmit: PropTypes.func.isRequired,
  isAssignmentFetching: PropTypes.bool.isRequired,
  onOutsideClick: PropTypes.func.isRequired
};

AdjustmentPanel.defaultProps = {
  assignment: {
    event_role: {
      role_id: 0,
      user: {
        first_name: '',
        last_name: '',
        roles: [],
        addresses: []
      }
    },
    adjustments: [],
    id: '',
    travel_pay: null,
    base_pay: 0,
    assignor_pay: null,
    batch_pay_staging: { in_batch: true }
  },
  game: {}
};

export default AdjustmentPanel;
