import moment from 'moment';

import MultiSelectDropdown from '../../Reusable/MultiSelectDropdown';
import MaterialDatePicker from '../../../controls/MaterialDatePicker';
import MultipleInput from '../../Reusable/MultipleInput';
import { allAvailableProvinces } from '../../../../constants';
import theme from './styled-components';

export const generateFields = ({ sports = [], governingBodies = [] }) => [
  {
    component: MultipleInput,
    name: 'group_name',
    placeholder: 'Group Name'
  },
  {
    component: MaterialDatePicker,
    name: 'start_date',
    placeholder: 'Start Date',
    parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
    format: value => (value ? moment(value, 'YYYY-MM-DD').toDate() : null),
    theme: theme.materialDatePicker
  },
  {
    component: MultiSelectDropdown,
    name: 'governing_body',
    placeholder: 'Governing Body',
    options: governingBodies.map(governingBody => ({
      name: governingBody.label,
      value: governingBody.value
    })),
    noCount: true,
    theme: theme.multiSelectDropdown
  },
  {
    component: MaterialDatePicker,
    name: 'end_date',
    placeholder: 'End Date',
    parse: value => (value ? moment(value).format('YYYY-MM-DD') : null),
    format: value => (value ? moment(value, 'YYYY-MM-DD').toDate() : null),
    theme: theme.materialDatePicker,
    validate: (value, values) =>
      !value || !values.start_date || moment(value).isSameOrAfter(moment(values.start_date))
        ? undefined
        : 'cannot be before Start Date',
    inlineError: true
  },
  {
    component: MultiSelectDropdown,
    name: 'area_state',
    placeholder: 'State/Province',
    options: allAvailableProvinces.map(({ name, value }) => ({
      name,
      value
    })),
    noCount: true,
    theme: theme.multiSelectDropdown
  },
  {
    component: MultiSelectDropdown,
    name: 'sport_id',
    placeholder: 'Sports',
    options: sports.map(sport => ({
      name: sport.name,
      value: sport.id
    })),
    noCount: true,
    theme: theme.multiSelectDropdown
  }
];

export default generateFields;
