import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';

// import { COLORS } from '../../../../utils/styles';

export const Inline = styled.div`
  width: 2rem;
  overflow: hidden;
  padding-right: 0.5rem;
  display: inline-block;
  margin: 0 0.5rem;
`;

export const Spaced = Inline.extend`
  margin: 0 0.5rem 0 2rem;
`;

export const FillHeight = styled(Row)`
  margin-top: 1rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 8rem;
`;

export const ButtonContainer = styled.div`
  float: right;
  margin: 1em 2em;
  width: 40%;
`;
