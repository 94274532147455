import React from 'react';
import PropTypes from 'prop-types';
import { always, not, isEmpty } from 'ramda';
import {
  Input,
  GroupContainer,
  BarContainer,
  HeighlightContainer,
  Label
} from './styled-components';
import ErrorContainer from '../MaterialErrorContainer';
import { requiredValidationError } from '../../../utils/helpers';

const MaterialInput = ({
  placeholder,
  input,
  onFocus,
  onBlur,
  onClick,
  meta: { touched, error, invalid },
  readOnly,
  type,
  maxLength,
  disabled,
  min,
  max,
  inputRef,
  theme,
  autoComplete,
  inlineError,
  hideArrows,
  alwaysShowError,
  style,
  marginTop,
  onChange
}) => {
  const hasError =
    (requiredValidationError(input.value) && error) ||
    ((touched || alwaysShowError) && invalid && error);
  return (
    <GroupContainer theme={theme.wrapper} style={style} renderType>
      <Input
        {...input}
        hasError={hasError}
        onFocus={onFocus}
        onClick={onClick}
        onBlur={onBlur}
        readOnly={readOnly}
        type={type}
        maxLength={`${maxLength}`}
        disabled={disabled}
        innerRef={inputRef}
        {...(type === 'number' && { min, max })}
        theme={theme.input}
        autoComplete={autoComplete}
        className={`${input.value ? 'hasValue' : ''} ${hasError ? 'hasError' : ''}`}
        hideArrows={hideArrows}
        onInput={onChange}
      />
      <HeighlightContainer />
      <BarContainer hasError={hasError} />
      {hasError && !inlineError && <ErrorContainer>{error}</ErrorContainer>}
      <Label
        hasError={hasError}
        hasValue={not(isEmpty(input.value))}
        theme={theme.label}
        marginTop={marginTop}
      >
        {placeholder}
        {inlineError && error && ` - ${error}`}
      </Label>
    </GroupContainer>
  );
};

MaterialInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string
  }),
  alwaysShowError: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func
  }),
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  inputRef: PropTypes.func,
  theme: PropTypes.shape({
    wrapper: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired
  }),
  autoComplete: PropTypes.string,
  inlineError: PropTypes.bool,
  hideArrows: PropTypes.bool
};

MaterialInput.defaultProps = {
  placeholder: '',
  onChange: always(undefined),
  onFocus: always(undefined),
  onClick: always(undefined),
  inlineError: false,
  meta: {
    touched: false,
    error: ''
  },
  alwaysShowError: false,
  input: {
    value: '',
    onChange: always(undefined)
  },
  readOnly: false,
  type: 'text',
  maxLength: '',
  disabled: false,
  min: 0,
  max: 0,
  inputRef: undefined,
  theme: {
    wrapper: ``,
    label: '',
    input: ''
  },
  autoComplete: 'off',
  hideArrows: false
};

export default MaterialInput;
