import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const KillLoaderWrapperStyleIssue = styled.div``;

export const ContentWrapper = styled.div`
  padding: ${pxToRem('40px')};
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns ? `repeat(${columns}, 1fr)` : `repeat(3, 1fr)`};
  grid-column-gap: ${({ gutter }) => (gutter ? pxToRem(gutter) : 0)};
  grid-row-gap: ${({ gutter }) => (gutter ? pxToRem(gutter) : 0)};
  width: 100%;
  max-width: 1280px;
  margin: ${({ margin }) => margin || 0};
`;

export const TilesTitle = styled.h3`
  color: ${COLORS.darkBlue};
  font-size: ${pxToRem('24px')};
  padding: 0;
  margin: 0;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: ${({ justification }) => justification || 'center'};
  align-items: ${({ alignment }) => alignment || 'center'};
  flex-direction: ${({ direction }) => direction || 'row'};
  margin: ${({ margin }) => margin || 'center'};
  padding: ${({ padding }) => padding || 'center'};
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px ${pxToRem('20px')};
  margin: 0px 0px ${pxToRem('10px')};
  border-right: ${({ border }) => (border ? `2px solid ${COLORS.lightCyanGray}` : 'none')};
  border-left: ${({ border }) => (border ? `2px solid ${COLORS.lightCyanGray}` : 'none')};
`;

export const Circle = styled.div`
  height: ${pxToRem('24px')};
  width: ${pxToRem('24px')};
  border-radius: 50%;
  background: ${({ blue }) => (blue ? COLORS.denimBlue : COLORS.shuttleGray)};
`;

export const ViewButton = styled.button`
  color: ${COLORS.denimBlue};
  border-radius: ${pxToRem('3px')};
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: ${pxToRem('0.4px')};
  font-stretch: normal;
  font-weight: 500;
  outline: none;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
  display: block;
  transition: ease-in-out 0.1s all;

  &:hover {
    background: ${COLORS.denimBlue};
    color: ${COLORS.white};
  }

  &:active {
    background: #1263aa;
  }
`;

export const Tile = styled.div`
  background: ${({ disabled }) => (disabled ? '#eaf0f6' : COLORS.white)};
  border: 2px solid ${COLORS.borderBlueGray};
  border-radius: ${pxToRem('3px')};
  box-sizing: border-box;
  cursor: ${({ noPointer }) => (noPointer ? 'default' : 'pointer')};
  padding: ${({ padding }) => padding || 0};
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
  transition: ease-in-out 0.1s transform;
  width: 100%;

  &:hover ${ViewButton} {
    border: 1px solid ${COLORS.denimBlue};
    height: ${pxToRem('25px')};
    padding: 0px ${pxToRem('10px')};
  }

  &:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    border: none;
  }
`;

export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => padding || `${pxToRem('0px')} ${pxToRem('10px')}`};
`;

export const TitleText = styled.p`
  margin: ${({ margin }) => margin || 0};
  padding: 0;
  font-size: ${({ size }) => size || pxToRem('16px')};
  color: ${({ color }) => color || COLORS.offBlack};
`;
