import moment from 'moment';
import { isEmpty, isNil } from 'ramda';
import { CORE_ROLES, GAMES_STATUSES, GAME_ASSIGNMENT_STATUSES } from '../../../constants';
import { groupArrayByKey } from '../../../utils/helpers';

const sortBy = {
  ASSIGNMENT: 'ASSIGNMENT',
  GAMES: 'GAMES'
};

const assignmentFormatConfig = {
  eventName: '',
  officials: 0,
  assessors: 0
};

export const gameTotalConfig = {
  eventName: '',
  new: 0,
  none: 0,
  updated: 0,
  postponed: 0,
  cancelled: 0
};

const getAssignmentCountByStatus = ({ games, status }) => {
  if (games && games.length) {
    return games.reduce((acc, game) => {
      const { game_assignments } = game;
      const pendingAssignments = game_assignments.length
        ? game_assignments.filter(val => val.status === status)
        : [];
      return acc + pendingAssignments.length;
    }, 0);
  }
  return 0;
};

// filter role_ids open for assignments
const rolesOpenforAssignments = (game_level, game_assignments) =>
  game_level &&
  game_level.role_ids.length &&
  game_level.role_ids.filter((value, index) => {
    const columnAssign = game_assignments.find(val => val.official_label_col === index);
    return isEmpty(columnAssign) || isNil(columnAssign);
  });

// filter role_ids as per assignments
const roles = (game_level, game_assignments, status) =>
  game_level &&
  game_level.role_ids.length &&
  game_level.role_ids.filter((value, index) => {
    return game_assignments.find(val => val.official_label_col === index && val.status === status);
  });

const official = rolesIds =>
  rolesIds && rolesIds.length
    ? rolesIds.reduce(
        (acc, val) => (Number(val) === Number(CORE_ROLES.official) ? acc + 1 : acc + 0),
        0
      )
    : 0;
const assessor = rolesIds =>
  rolesIds && rolesIds.length
    ? rolesIds.reduce(
        (acc, val) => (Number(val) === Number(CORE_ROLES.assessor) ? acc + 1 : acc + 0),
        0
      )
    : 0;

const upcomingEvents = ({ games, status, sort, formatConfig }) => {
  const gropuByEvent = groupArrayByKey(games, 'eventId');
  let upcomingGamesEvent = [];
  Object.keys(gropuByEvent).forEach(key => {
    const events = gropuByEvent[key].reduce((acc, game) => {
      const { game_level, game_assignments, eventId } = game;
      if (sort === sortBy.ASSIGNMENT) {
        const filterRoleArry = isNil(status)
          ? rolesOpenforAssignments(game_level, game_assignments)
          : roles(game_level, game_assignments, status);
        const officialCount = official(filterRoleArry);
        const assessorCount = assessor(filterRoleArry);
        return {
          eventName: game_level && game_level.event && game_level.event.name,
          eventId,
          officials: officialCount + acc.officials,
          assessors: assessorCount + acc.assessors
        };
      }
      const obj = {
        eventId,
        new:
          game.status === GAMES_STATUSES.SCHEDULED || game.status === GAMES_STATUSES.NEW
            ? acc.new + 1
            : acc.new,
        none: game.status === GAMES_STATUSES.NONE ? acc.none + 1 : acc.none,
        updated: game.status === GAMES_STATUSES.UPDATED ? acc.updated + 1 : acc.updated,
        postponed: game.status === GAMES_STATUSES.POSTPONED ? acc.postponed + 1 : acc.postponed,
        cancelled: game.status === GAMES_STATUSES.CANCELLED ? acc.cancelled + 1 : acc.cancelled
      };
      return {
        eventName: game_level && game_level.event && game_level.event.name,
        ...obj
      };
    }, formatConfig);
    upcomingGamesEvent = upcomingGamesEvent.concat(events);
  });
  const sortGames = upcomingGamesEvent.sort((a, b) => b.eventId - a.eventId);
  return sort === sortBy.ASSIGNMENT
    ? sortGames.filter(val => val.officials || val.assessors)
    : sortGames;
};

export const assignmentConfig = [
  {
    title: 'Open Positions',
    getCount: ({ games }) => {
      if (games && games.length) {
        return games.reduce((acc, game) => {
          const { game_level, game_assignments } = game;
          const crewSize = game_level && game_level.crew_size;
          return acc + (Number(crewSize) - Number(game_assignments && game_assignments.length));
        }, 0);
      }
      return 0;
    },
    onClickHandler: ({ setIsActive, title, setEventInfo, games }) => {
      setIsActive(title);
      setEventInfo(
        upcomingEvents({ games, sort: sortBy.ASSIGNMENT, formatConfig: assignmentFormatConfig })
      );
    }
  },
  {
    title: 'Unpublished Assignments',
    getCount: ({ games }) =>
      getAssignmentCountByStatus({ games, status: GAME_ASSIGNMENT_STATUSES.unpublished }),
    onClickHandler: ({ setIsActive, title, setEventInfo, games }) => {
      setIsActive(title);
      setEventInfo(
        upcomingEvents({
          games,
          sort: sortBy.ASSIGNMENT,
          formatConfig: assignmentFormatConfig,
          status: GAME_ASSIGNMENT_STATUSES.unpublished
        })
      );
    }
  },
  {
    title: 'Pending Assignments',
    getCount: ({ games }) =>
      getAssignmentCountByStatus({ games, status: GAME_ASSIGNMENT_STATUSES.pending }),
    onClickHandler: ({ setIsActive, title, setEventInfo, games }) => {
      setIsActive(title);
      setEventInfo(
        upcomingEvents({
          games,
          sort: sortBy.ASSIGNMENT,
          formatConfig: assignmentFormatConfig,
          status: GAME_ASSIGNMENT_STATUSES.pending
        })
      );
    }
  },
  {
    title: 'Declined Assignments',
    getCount: ({ games }) =>
      getAssignmentCountByStatus({ games, status: GAME_ASSIGNMENT_STATUSES.declined }),
    onClickHandler: ({ setIsActive, title, setEventInfo, games }) => {
      setIsActive(title);
      setEventInfo(
        upcomingEvents({
          games,
          sort: sortBy.ASSIGNMENT,
          formatConfig: assignmentFormatConfig,
          status: GAME_ASSIGNMENT_STATUSES.declined
        })
      );
    }
  }
];

export const gamesConfig = [
  {
    title: 'Total Games',
    getCount: ({ games }) => {
      return games && games.length ? games.length : 0;
    },
    onClickHandler: ({ setIsActive, title, games, setEventInfo }) => {
      setIsActive(title);
      setEventInfo(upcomingEvents({ games, sort: sortBy.GAMES, formatConfig: gameTotalConfig }));
    }
  },
  {
    title: 'Updated Games',
    getCount: ({ games }) => {
      if (games && games.length) {
        return games.filter(value => value.status === GAMES_STATUSES.UPDATED).length;
      }
      return 0;
    },
    onClickHandler: ({ setIsActive, title, games, setEventInfo }) => {
      setIsActive(title);
      setEventInfo(
        upcomingEvents({ games, sort: sortBy.GAMES, formatConfig: gameTotalConfig })
          .filter(val => val.updated)
          .map(game => {
            return { eventName: game.eventName, updated: game.updated };
          })
      );
    }
  },
  {
    title: 'Postponed & Cancelled Games',
    getCount: ({ games }) => {
      if (games && games.length) {
        return games.filter(
          value =>
            value.status === GAMES_STATUSES.POSTPONED || value.status === GAMES_STATUSES.CANCELLED
        ).length;
      }
      return 0;
    },
    onClickHandler: ({ setIsActive, title, games, setEventInfo }) => {
      setIsActive(title);
      setEventInfo(
        upcomingEvents({ games, sort: sortBy.GAMES, formatConfig: gameTotalConfig })
          .filter(val => val.postponed || val.cancelled)
          .map(game => {
            return {
              eventName: game.eventName,
              postponed: game.postponed,
              cancelled: game.cancelled
            };
          })
      );
    }
  }
];

export const tzfilter = [
  {
    label: 'Next 6 Months',
    start_date: moment(),
    end_date: moment().add(6, 'month'),
    value: 'Next 6 Months'
  },
  {
    label: 'Today',
    start_date: moment(),
    end_date: moment().endOf('day'),
    value: 'Today'
  },
  {
    label: 'Tomorrow',
    start_date: moment().add(1, 'days'),
    end_date: moment().add(1, 'days').endOf('day'),
    value: 'Tomorrow'
  },
  {
    label: 'Custom Date Range',
    value: 'Custom Date Range'
  }
];
