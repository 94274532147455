import React from 'react';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import Icon from '../Icon';
import { CarretButtonContainer } from './styled-components';
import { ICONS, COLORS } from '../../../utils/styles';

const CarretButton = ({
  opened,
  onClick,
  selected,
  width,
  nobg,
  customStyle,
  expandClosedIcon,
  expandOpendIcon
}) => {
  return (
    <CarretButtonContainer
      onClick={onClick}
      width={width}
      selected={selected}
      nobg={nobg}
      style={customStyle}
    >
      <Icon
        height={12}
        width={12}
        icon={opened ? expandOpendIcon : expandClosedIcon}
        color={selected ? COLORS.white : COLORS.shuttleGray}
      />
    </CarretButtonContainer>
  );
};

CarretButton.propTypes = {
  onClick: PropTypes.func,
  opened: PropTypes.bool,
  selected: PropTypes.bool,
  width: PropTypes.number,
  nobg: PropTypes.bool,
  expandClosedIcon: PropTypes.string,
  expandOpendIcon: PropTypes.string
};

CarretButton.defaultProps = {
  onClick: always(undefined),
  opened: false,
  selected: false,
  width: null,
  nobg: false,
  expandClosedIcon: ICONS.CHEVRON_RIGHT,
  expandOpendIcon: ICONS.CHEVRON_DOWN
};
export default CarretButton;
