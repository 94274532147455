import React from 'react';
import PropTypes from 'prop-types';
import hasher from 'object-hash';
import { identity } from 'ramda';
import { Col, Row } from 'react-flexbox-grid';
import {
  ColHeader,
  ColHeaderGame,
  Input,
  HeaderRow,
  Container,
  ValueColContainerCenter
} from './styled-components';

export { ColHeader, ColHeaderGame, HeaderRow, Container, ValueColContainerCenter };

export const TableRow = ({ rowValues, renderCol }) => (
  <Row between="xs">
    {rowValues.map(val => (
      <Container xs key={hasher(val)}>
        {renderCol(val)}
      </Container>
    ))}
  </Row>
);

TableRow.propTypes = {
  rowValues: PropTypes.arrayOf(String),
  renderCol: PropTypes.func
};

TableRow.defaultProps = {
  rowValues: [],
  renderCol: identity
};
/**
 * Takes an array of column names
 * an array of values and a renderCol functions and renders cols uniformly
 * @param {Object} props
 * @param {Array<String>} props.rows
 * @param {Array<String>} props.columnNames
 * @param {Function} props.func
 */
export const Table = ({ columnNames, rows, renderCol }) => (
  <Row>
    <Col xs>
      <HeaderRow between="xs">
        {columnNames.map(name => (
          <ColHeader xs key={name}>
            {name}
          </ColHeader>
        ))}
      </HeaderRow>
      {rows.map(row => (
        <TableRow key={hasher(row)} rowValues={row} renderCol={renderCol} />
      ))}
    </Col>
  </Row>
);

Table.propTypes = {
  columnNames: PropTypes.arrayOf(String),
  rows: PropTypes.arrayOf(Array),
  renderCol: PropTypes.func
};

Table.defaultProps = {
  columnNames: [],
  rows: [],
  renderCol: val => <Input name="" value={val} disabled />
};

export default Table;
