import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Icon from '../../Icon';

export const StyledInput = styled.input`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoLight';
  background: transparent;
  border: none;
  padding: ${pxToRem('10px')} ${pxToRem('6px')} ${pxToRem('6px')};
  margin: 0;
  outline-width: 0;
  text-align: center;
  ${({ addable }) => (!addable ? 'width: 100%' : 'width: 60%')};

  &::placeholder {
    color: ${COLORS.white};
    text-transform: capitalize;
  }

  ${({ theme }) => theme || ''};
`;

export const Label = styled.label`
  display: flex;
  margin-top: ${pxToRem('7px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  margin-left: 0.5rem;
  ${({ color }) => (color ? `color: ${COLORS.white}` : `color: ${COLORS.nightRider}`)};
  ${({ theme }) => theme || ''};
`;

export const Wrapper = styled.div`
  position: relative;
  box-shadow: inset 0 -1px 0 0 ${COLORS.white}88;

  &.hasValue {
    box-shadow: inset 0 -2px 0 0 ${COLORS.white};
  }

  ${({ hasValue }) => (hasValue ? `box-shadow: inset 0 -2px 0 0 ${COLORS.white}` : '')};
  ${({ hasError }) => (hasError ? `box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed}` : '')};

  ${({ theme }) => theme || ''};
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

export const Error = styled.p`
  position: absolute;
  width: 100%;
  text-align: center;
  top: -0.5rem;
  margin: 0;
  color: ${COLORS.carminePink};
  font-size: ${pxToRem('10px')};
  font-family: 'RobotoCondensedLight';
`;
