import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import React from 'react';
import LoaderWrapper from '../../LoaderWrapper';
import config from './configs';
import { PrimaryContainer, ViewMore } from './styled-components';
import Enhancer from '../../../containers/Wallet/TransactionHistory';
import COLORS from '../../../../utils/styles';
import { WALLET, MAP_TRANSACTION_STATUSES } from '../../../../constants';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: props => (props.wideView ? '600px' : '220px')
  },
  tableCell: {
    borderBottom: 'none',
    padding: 8,
    fontSize: props => (props.wideView ? '' : '12px')
  },
  colored: {
    color: COLORS.greenBlue
  }
});

const TransactionTableHeader = ({ wideView }) => {
  const classes = useStyles({ wideView });
  return (
    <>
      <TableHead>
        <TableRow>
          {config.transactionHistoryTable({}).map(field => {
            return (
              <TableCell
                colspan={field.colSpan}
                className={classes.tableCell}
                key={field.label}
                align="center"
              >
                {field.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </>
  );
};

const TransactionTableBody = ({ transactions, wideView }) => {
  const classes = useStyles({ wideView });
  return (
    <>
      <TableBody>
        {transactions.map((t, i) => {
          const isPending = t.status === WALLET.TRANSACTION.STATUSES.PENDING;
          const status = MAP_TRANSACTION_STATUSES[t.transactionType][t.status];
          // eslint-disable-next-line no-param-reassign
          t.status = status;
          return (
            <TableRow key={`${t.prefund_id}-${i}`}>
              {config.transactionHistoryTable({ ...t }).map(field => {
                return (
                  <>
                    <TableCell
                      className={
                        isPending ? `${classes.colored} ${classes.tableCell}` : classes.tableCell
                      }
                      colSpan={field.colSpan}
                      align="center"
                    >
                      {field.value}
                      <br />
                      {field.subValue}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

export const TransactionTable = ({
  transactions = [],
  wideView,
  isFetching,
  showMoreTransactionHistory,
  transactionsMeta
}) => {
  const classes = useStyles({ wideView });
  const showMoreTransactions =
    transactionsMeta &&
    transactionsMeta.total_count > transactionsMeta.limit &&
    transactionsMeta.total_count !== transactions.length;
  return (
    <>
      <PrimaryContainer wideView={wideView}>
        <LoaderWrapper isFetching={isFetching}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TransactionTableHeader wideView={!!wideView} />
              <TransactionTableBody wideView={!!wideView} transactions={transactions} />
            </Table>
          </TableContainer>

          {showMoreTransactions && (
            <ViewMore>
              <Button
                className="ViewMoreButton"
                onClick={() => showMoreTransactionHistory({ limit: transactionsMeta.limit })}
              >
                Show More
              </Button>
            </ViewMore>
          )}
        </LoaderWrapper>
      </PrimaryContainer>
    </>
  );
};

export default Enhancer(React.memo(TransactionTable));
