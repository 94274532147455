import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import EventItemEnhancer from '../../../containers/Events/EventItem';
import EventInformationModal from '../EventInfoModal';
import { OPEN_EVENTS } from '../../../../core/Events/eventConstants';
import { formatDate } from '../../../../utils/parsers';
import {
  EventListContainer,
  EventItemContainer,
  EventInfoContainer,
  EventHeaderContainer,
  EventHeaderInnerContainer,
  OpenEventIndicator,
  ClosedEventIndicator,
  EventHeader,
  EventHeaderData,
  EventContentContainer,
  EventDataContainer,
  EventHead,
  EventData,
  EventSummaryContainer,
  EventSummaryData,
  GameLink,
  HeaderRest
} from './styled-components';
import {
  sInterpolator as routeBuilder,
  removeByPrefix,
  getDelay,
  addPrefixToKeys,
  getRemainingGamesFilter
} from '../../../../utils/helpers';
import { DATE_FORMATS, PARAM_PREFIXES } from '../../../../constants';
import { userIdxGetter } from '../../../../utils/auth';
import {
  EVENTS,
  EVENTS_LOCATION,
  EVENTS_OFFICIALS,
  EVENTS_ASSESSOR,
  EVENTS_USERS,
  GAMES
} from '../../../../core/paths';
import GameLocationCount from './GameLocationCount';

const buildEventList = ({
  eventList,
  sports,
  adminEvents,
  assignorEvents,
  storeGamesEventAndRedirect,
  isSuperAdmin,
  userGroups
}) =>
  eventList.map((eventItem, i) => (
    <EventItem
      key={eventItem.id}
      id={eventItem.id}
      name={eventItem.name}
      status={eventItem.status}
      groupName={eventItem.group_name}
      group={eventItem.group}
      game_upload_type={eventItem.game_upload_type}
      sport={sports.find(sport => Number(sport.id) === eventItem.sport_id)}
      state={eventItem.area_state}
      country={eventItem.area_country}
      userRoles={eventItem.user_roles}
      createdAt={formatDate(eventItem.created_at)}
      summary={eventItem.summary}
      isEventAdmin={!!adminEvents.includes(Number(eventItem.id))}
      isGroupAdmin={!!userGroups.includes(Number(eventItem.group))}
      isEventAssignor={!!assignorEvents.includes(Number(eventItem.id))}
      storeGamesEventAndRedirect={storeGamesEventAndRedirect}
      isSuperAdmin={isSuperAdmin}
      fetchCountDelay={getDelay(i, 1)}
    />
  ));

const EventList = ({
  eventList,
  sports,
  adminEvents,
  assignorEvents,
  storeGamesEventAndRedirect,
  isSuperAdmin,
  userGroups
}) => (
  <EventListContainer>
    {buildEventList({
      eventList,
      sports,
      adminEvents,
      assignorEvents,
      storeGamesEventAndRedirect,
      isSuperAdmin,
      userGroups
    })}
  </EventListContainer>
);

const EventInfoCell = ({ head, data }) => (
  <EventDataContainer>
    <EventHead>{head}: </EventHead>
    <EventData>{data}</EventData>
  </EventDataContainer>
);

const EventItem = EventItemEnhancer(
  ({
    id,
    name,
    status,
    state,
    country,
    userRoles,
    groupName,
    group,
    game_upload_type,
    sport,
    createdAt,
    summary,
    setShowModalInfo,
    showModalInfo,
    findSportById,
    isEventAdmin,
    isEventAssignor,
    findEventById,
    location: { search },
    storeGamesEventAndRedirect,
    isSuperAdmin,
    isGroupAdmin,
    isNotFundingAdmin
  }) => {
    const userIdx = userIdxGetter();
    const gameFilters = addPrefixToKeys(
      getRemainingGamesFilter({ format: DATE_FORMATS.YYYY_MM_DD }),
      `${PARAM_PREFIXES.GAMES}_`
    );
    const gamesQuery = [];

    Object.keys(gameFilters).forEach(key => {
      gamesQuery.push(`${key}=${gameFilters[key]}`);
    });

    const eventsUrl = routeBuilder({ userIdx }, EVENTS);
    const locationUrl = routeBuilder({ eventId: id, userIdx }, EVENTS_LOCATION);
    const officialsUrl = routeBuilder({ eventId: id, userIdx }, EVENTS_OFFICIALS);
    const assesorUrl = routeBuilder({ eventId: id, userIdx }, EVENTS_ASSESSOR);
    const usersUrl = routeBuilder({ eventId: id, userIdx }, EVENTS_USERS);
    const gamesUrl = routeBuilder({ userIdx }, `${GAMES}?${gamesQuery.join('&')}`);

    return (
      <EventItemContainer>
        <EventInfoContainer>
          <EventHeaderContainer>
            <EventHeaderInnerContainer>
              {status && String(status.value) === OPEN_EVENTS ? (
                <OpenEventIndicator />
              ) : (
                <ClosedEventIndicator />
              )}
              <EventHeader title={name} onClick={() => setShowModalInfo(true)}>
                {name}
              </EventHeader>
              {id && <EventHeaderData title={id}>{`- ${id}`}</EventHeaderData>}
            </EventHeaderInnerContainer>
            <HeaderRest>
              {((userRoles && !!userRoles.length) || isSuperAdmin || isGroupAdmin) && (
                <GameLink
                  onClick={() =>
                    summary &&
                    !!summary.game_levels &&
                    storeGamesEventAndRedirect({ id, game_upload_type, platform_id: group })
                  }
                  disabled={summary && !summary.game_levels}
                >
                  View Games
                </GameLink>
              )}
            </HeaderRest>
          </EventHeaderContainer>

          <EventContentContainer>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs>
                    <EventInfoCell head="Group" data={groupName} />
                  </Col>
                  <Col xs>
                    <EventInfoCell head="Sport" data={sport && sport.name} />
                  </Col>
                  <Col xs>
                    <EventInfoCell head="Location" data={`${state}, ${country}`} />
                  </Col>
                  <Col xs>
                    <EventInfoCell head="Created At" data={`${createdAt}`} />
                  </Col>
                  <Col xs>
                    <EventInfoCell
                      head="Roles"
                      data={(userRoles && userRoles.map(({ label }) => label).join(',')) || 'None'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </EventContentContainer>
          {showModalInfo && (
            <EventInformationModal
              onClose={() => setShowModalInfo(false)}
              eventInfo={findEventById(id)}
              findSportById={findSportById}
              isEventAdmin={isEventAdmin}
              isSuperAdmin={isSuperAdmin}
              isGroupAdmin={isGroupAdmin}
            />
          )}
        </EventInfoContainer>
        {/* TODO: Use a loop to build the data! */}
        <EventSummaryContainer>
          <EventSummaryData
            to={
              isNotFundingAdmin
                ? gamesUrl
                : `${eventsUrl}${removeByPrefix(search, PARAM_PREFIXES.GAMES)}`
            }
            onClick={() =>
              isNotFundingAdmin &&
              storeGamesEventAndRedirect({ id, game_upload_type, platform_id: group })
            }
          >
            {summary && (
              <GameLocationCount
                counts={summary.gameCount}
                isFetching={typeof summary.gameCount === 'undefined'}
              />
            )}
          </EventSummaryData>
          <EventSummaryData
            isActive={(match, location) => location.pathname === locationUrl}
            to={`${locationUrl}${removeByPrefix(search, PARAM_PREFIXES.LOCATIONS)}`}
          >
            {summary && (
              <GameLocationCount
                counts={summary.locationCount}
                isFetching={typeof summary.locationCount === 'undefined'}
              />
            )}
          </EventSummaryData>
          <EventSummaryData
            isActive={(match, location) => location.pathname === officialsUrl}
            to={
              isEventAdmin || isEventAssignor || isSuperAdmin || isGroupAdmin
                ? `${officialsUrl}${removeByPrefix(search, PARAM_PREFIXES.USERS)}`
                : `${eventsUrl}${removeByPrefix(search, PARAM_PREFIXES.USERS)}`
            }
            disabled={!isEventAdmin && !isEventAssignor && !isSuperAdmin && !isGroupAdmin}
          >
            {summary && summary.officials}
          </EventSummaryData>
          <EventSummaryData
            isActive={(match, location) => location.pathname === assesorUrl}
            to={
              isEventAdmin || isEventAssignor || isSuperAdmin || isGroupAdmin
                ? `${assesorUrl}${removeByPrefix(search, PARAM_PREFIXES.USERS)}`
                : `${eventsUrl}${removeByPrefix(search, PARAM_PREFIXES.USERS)}`
            }
            disabled={!isEventAdmin && !isEventAssignor && !isSuperAdmin && !isGroupAdmin}
          >
            {summary && summary.assessors}
          </EventSummaryData>
          <EventSummaryData
            isActive={(match, location) => location.pathname === usersUrl}
            to={
              isEventAdmin || isEventAssignor || isSuperAdmin || isGroupAdmin
                ? `${usersUrl}${removeByPrefix(search, PARAM_PREFIXES.USERS)}`
                : `${eventsUrl}${removeByPrefix(search, PARAM_PREFIXES.USERS)}`
            }
            disabled={!isEventAdmin && !isEventAssignor && !isSuperAdmin && !isGroupAdmin}
          >
            {summary && summary.users}
          </EventSummaryData>
        </EventSummaryContainer>
      </EventItemContainer>
    );
  }
);

EventList.propTypes = {
  adminEvents: PropTypes.arrayOf(PropTypes.number),
  assignorEvents: PropTypes.arrayOf(PropTypes.number),
  eventList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      group_name: PropTypes.string.isRequired,
      sport_id: PropTypes.number.isRequired,
      status: PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired
      }).isRequired,
      area_state: PropTypes.string,
      area_country: PropTypes.string.isRequired,
      user_roles: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          role_id: PropTypes.number
        })
      ),
      summary: PropTypes.shape({
        assessors: PropTypes.number.isRequired,
        locations: PropTypes.number.isRequired,
        officials: PropTypes.number.isRequired,
        teams: PropTypes.number.isRequired,
        users: PropTypes.number.isRequired,
        game_levels: PropTypes.number.isRequired
      }).isRequired
    })
  ).isRequired,
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  storeGamesEventAndRedirect: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool,
  userGroups: PropTypes.arrayOf(PropTypes.number)
};

EventList.defaultProps = {
  adminEvents: [],
  assignorEvents: [],
  isSuperAdmin: false,
  userGroups: []
};

export default EventList;
