import { compose } from 'recompose';
import { connect } from 'react-redux';

import { clearAssignmentWarnings as clearAssignmentWarningsAction } from '../../../core/Notifications';
import {
  setShowCalendar as setShowCalendarAction,
  clearAssignments as clearAssignmentsAction,
  fetchActiveUserInfo
} from '../../../core/User';
import {
  getAssignmentWarnings,
  getNotificationCounts
} from '../../../core/Notifications/selectors';
import unconnected from './unconnected';
import { getUserAssignments, getCurrentDayAssignmentCount } from '../../../core/User/selectors';

import { getShowModals } from '../../../core/Modals/selectors';

const mapStateToProps = state => ({
  notificationCounts: getNotificationCounts(state),
  assignmentWarnings: getAssignmentWarnings(state),
  games: getUserAssignments(state),
  currentDayAssignmentCount: getCurrentDayAssignmentCount(state),
  isFetching: state.user.isFetching,
  modals: getShowModals(state),
  showCalendarIcon: state.user.showCalendar,
  userIdx: state.auth.userIdx,
  calendarIcon: state.user.calendarIcon,
  userId: state.auth && state.auth.user && state.auth.user.id
});

const mapDispatchToProps = dispatch => ({
  clearAssignmentWarnings: () => dispatch(clearAssignmentWarningsAction()),
  clearAssignments: payload => dispatch(clearAssignmentsAction(payload)),
  setShowCalendarAction: payload => dispatch(setShowCalendarAction(payload)),
  fetchActiveUserInfo: payload => dispatch(fetchActiveUserInfo(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
