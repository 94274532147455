import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import AuthRoute from '../../containers/AuthHOC';

/**
 * Custom implementation of Routes. Should be used in cases when Routes require certain privileges.
 * Use instead of Route
 * @param {Object} props common route props
 */
const RouteWithSubRoutes = ({
  path,
  component: Component,
  routes,
  location,
  isPrivate,
  defaultChecked
}) => {
  if (location.pathname !== undefined) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
  return (
    <AuthRoute
      path={path}
      location={location}
      isPrivate={isPrivate}
      render={props => <Component {...props} routes={routes} defaultChecked={defaultChecked} />}
    />
  );
};

RouteWithSubRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  isPrivate: PropTypes.bool
};

RouteWithSubRoutes.defaultProps = {
  routes: [],
  location: {},
  isPrivate: false
};

export default RouteWithSubRoutes;
