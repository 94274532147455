import styled from 'styled-components';

import { HEIGHTS, COLORS, mediaQueries } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 2rem;
`;

export const InnerContainer = styled.div`
  flex-grow: 1;
  height: ${pxToRem(HEIGHTS.eventFilterBar)};
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  ${({ backgroundColor }) =>
    backgroundColor
      ? `background-color:${backgroundColor}`
      : `background: ${COLORS.greenVogue};
  background: -moz-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${COLORS.greenVogue}), color-stop(100%, ${COLORS.bahamaBlue}));
  background: -webkit-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -o-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -ms-linear-gradient(top, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  background: linear-gradient(to bottom, ${COLORS.greenVogue} 0%, ${COLORS.bahamaBlue} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${COLORS.greenVogue}', endColorstr='${COLORS.bahamaBlue}', GradientType=0);`};
`;

export const NavItem = styled.span`
  display: flex;
  align-items: center;
  padding: 0 0 0 ${pxToRem('8px')};
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  cursor: pointer;

  ${({ divider }) => (divider ? `border-right: 1px solid ${COLORS.white}` : '')};
  ${({ theme }) => theme};
`;

export const Checkbox = styled.input`
  margin-left: 0;
  margin-right: 0;
  margin-top: ${pxToRem('10px')};
`;
export const SelectAllCheckbox = styled.input`
  margin-left: 0;
  margin-right: 0;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: ${pxToRem('180px')};
  padding-top: 0.25rem;
  margin-right: 1rem;
`;

export const LabelsHeader = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  ${({ theme }) => theme};
  ${`@media (max-width: 1315px) {
    width: 51%;
  }`}
  ${mediaQueries.md(`
  display:none;
  `)};
`;

export const ColumnHeader = styled.div`
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  color: ${COLORS.white};
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('12px')};
  text-align: center;

  &:first-child {
    box-sizing: border-box;
    border-left: 1px solid ${COLORS.white};
  }

  ${({ theme }) => theme};
`;

export const Options = styled.div`
  position: relative;
  flex-grow: 1;
  height: ${pxToRem(HEIGHTS.eventFilterBar)};
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const FilterContainer = styled.div`
  margin-left: ${pxToRem('10px')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GameFilterCounterWrapper = styled.div`
  min-height: ${pxToRem('12px')};
  min-width: ${pxToRem('12px')};
  padding: ${pxToRem('2px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${COLORS.denimBlue};
  margin-right: ${pxToRem('8px')};
`;

export const GameFilterCounter = styled.span`
  color: ${COLORS.white};
  font-size: ${pxToRem('9px')};
  font-family: sans-serif;
`;

export const LeftContent = styled.div`
  display: flex;
  padding: 0 0 0 ${pxToRem('34px')};
  ${mediaQueries.md(` {
    padding: 0 0 0 ${pxToRem('20px')};
    }`)};
`;

export const CheckboxPlaceholder = styled.div`
  width: ${pxToRem('13px')};
`;
