import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';

export const FieldWrapper = styled(Col)`
  margin: 1rem auto;
`;

export const FormWrapper = styled(Row)`
  padding: 1rem 3rem 2rem 3rem;
`;

export const ButtonsWrapper = styled(Row)`
  padding: 1rem 0;
`;
