import React from 'react';
import PropTypes from 'prop-types';
import { defaultTo, prop, flip, compose, propEq, ifElse, isNil } from 'ramda';

import { roundedFormatDate } from '../../../utils/parsers';
import { addressFormatter } from '../SideList/Formatters';
import { notNilNotEmpty } from '../../../utils/helpers';
import buildConfig from './config';
import SideList from '../SideList';
import generateUserMain from '../SideList/Generic/UserMain';
import generateUserExpanded from '../SideList/Generic/Expanded';
import Enhancer from '../../containers/Lists/UsersList';

const PRIMARY_CONTACT_TYPE_ID = 3;
const defaultToEmpty = defaultTo('');
const PRIMARY_ADDRESS = 1;
const TRAVEL_ADDRESS = 2;

const defaultFormatExpandedInfo = ({ profile, age }) => {
  const profileValues = flip(prop)(profile);
  const mainAddress =
    profileValues('addresses') &&
    profileValues('addresses').filter(propEq('type_id', PRIMARY_ADDRESS));
  let travelAddress =
    profileValues('addresses') &&
    profileValues('addresses').filter(propEq('type_id', TRAVEL_ADDRESS));
  if (!isNil(travelAddress)) {
    travelAddress = mainAddress;
  }
  return [
    {
      title: 'Parent / Guardian Info',
      columns: [
        [
          {
            label: 'Email',
            info: profileValues('email') && defaultToEmpty(profileValues('email'))
          },
          {
            label: age && age > 18 ? 'Phone' : 'Prim. Phone',
            info: (profileValues('contacts') || [])
              .filter(propEq('type_id', PRIMARY_CONTACT_TYPE_ID))
              .map(compose(defaultToEmpty, prop('primary_phone_number')))
              .join('')
          },
          {
            label: 'Gender-DOB',
            info:
              profileValues('gender') &&
              `${defaultToEmpty(profileValues('gender').slice(0, 1).toUpperCase())} - ${ifElse(
                notNilNotEmpty,
                defaultToEmpty,
                roundedFormatDate
              )(profileValues('dob'), 'MMMM DD, YYYY')}`
          },
          {
            label: 'Travel Address',
            info: addressFormatter(travelAddress)
          }
        ]
      ]
    }
  ];
};

const summaryInfo = [
  { label: 'Role', name: 'role', width: '10ch' },
  { label: 'Age', name: 'age', width: '6ch' },
  { label: 'Cert.', name: 'cert', width: '11ch' },
  { label: 'State', name: 'state', width: '15ch' }
];

export const UsersList = ({ onUpdateCategories, ...props }) => (
  <SideList
    onLoad={props.onLoad}
    onFetchItemDetail={props.onFetchItemDetail}
    fetchNextOfficials={props.onFetchNextOfficials}
    items={props.users}
    roles={{ alias: 'user', list: ['Assignor', 'Admin', 'Funding Admin'] }}
    selectedProp="eventRoleId"
    type="admin"
    mainComponent={generateUserMain({ summaryInfo })}
    expandedComponent={generateUserExpanded({
      defaultFormatExpandedInfo,
      onUpdateCategories,
      canEdit: props.isGamesEventGroupAdmin || props.isEventAdmin
    })}
    config={buildConfig(props)}
    eventId={props.match.params.eventId}
    setSelectedUser={props.setSelectedUser}
    infinity
    eventName={props.eventInfo && props.eventInfo.name}
    hasNextPage={props.hasNextPage}
    isFetchingEventRoles={props.isFetchingEventRoles}
    {...props}
  />
);

UsersList.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onFetchItemDetail: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(Object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  }).isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  onUpdateCategories: PropTypes.func.isRequired,
  isGamesEventGroupAdmin: PropTypes.bool.isRequired
};

export default Enhancer(UsersList);
