import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../ProfileInformation/Avatar';
import ProfileInformationForm from '../../ProfileInformation/ProfileInformationForm';
import LoaderWrapper from '../../LoaderWrapper';
import { Wrapper } from './styled-components';
import { Paper } from '../styled-components';

export const Personal = ({
  onSubmit,
  initialValues,
  isFetching,
  userIdx,
  allowAdminUpdates,
  isSuperAdmin,
  currentUserId,
  personalInfoAccessPermissions,
  userId,
  path
}) => (
  <LoaderWrapper isFetching={isFetching}>
    <Wrapper>
      <Paper>
        <Avatar />
        <ProfileInformationForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          path={path}
          allowAdminUpdates={allowAdminUpdates}
          buttonLabel="Update Information"
          userIdx={userIdx}
          isSuperAdmin={isSuperAdmin}
          currentUserId={currentUserId}
          userId={userId}
          personalInfoAccessPermissions={personalInfoAccessPermissions}
        />
      </Paper>
    </Wrapper>
  </LoaderWrapper>
);

Personal.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    profile: PropTypes.object.isRequired,
    emergencyInfo: PropTypes.object.isRequired,
    primaryAddress: PropTypes.object.isRequired,
    travelAddress: PropTypes.object.isRequired,
    primaryContact: PropTypes.object.isRequired,
    parentGuardianContact: PropTypes.object.isRequired
  }).isRequired
};

export default Personal;
