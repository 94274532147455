import styled from 'styled-components';
import { COLORS } from '../../../../../../utils/styles';
import { pxToRem } from '../../../../../../utils/helpers';

export const Wrapper = styled.div`
  margin-right: 2.5rem;
`;

export const StyledLabel = styled.span`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: ${COLORS.nightRider};
  text-align: left;
  display: inline-block;
  padding-left: ${pxToRem('6px')};
`;
export const StyledField = styled.span`

padding-left: ${pxToRem('6px')}
margin-left:${pxToRem('6px')}
margin-top:0;

`;

export const AtMinute = styled.span`
  font-family: 'RobotoCondensedLight';
  font-size: ${pxToRem('11px')};
  color: ${COLORS.nightRider};
  text-align: left;
  display: inline-block;
  padding-left:${pxToRem('30px')}
  padding-right:${pxToRem('10px')}
`;
export const NumberValue = styled.div`
  padding-right: 5px;
  padding-top: 5px;
  color: ${COLORS.denimBlue};
`;

export const Value = styled.p`
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.denimBlue};
  margin: ${pxToRem('6px')} 0 0;
  text-align: left;
  padding-left: 0.5rem;
  ${({ empty }) => (empty ? `padding: ${pxToRem('9px')} 0 ${pxToRem('9px')} 0.5rem` : '')};
  display: inline-block;
`;

export default {
  dropdownField: {
    error: true,
    wrapper: `
      box-sizing: border-box;
      cursor: pointer;
      background-color: white;
      height: 1.5rem;
      border-radius: 0;
      border: none;
      box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);
      font-family: 'Roboto';
      color: ${COLORS.denimBlue};
      font-size: ${pxToRem('13px')};
      display: inline-block;
      width: ${pxToRem('125px')};
    `,
    dropableContainer: {
      noDefaultError: true,
      clickable: `
        padding-top: 0;
        height: 100%:
      `,
      caption: `
        box-shadow: none;
        height: 1.5rem;
      `,
      placeholder: `
        line-height: 1.5rem;
        font-size: ${pxToRem('13px')};
        padding-left: 0.5rem;
        font-family: 'Roboto';
        vertical-align: middle;
      `,
      icon: `
        position: relative;
        bottom: ${pxToRem('3px')};
        right: ${pxToRem('6px')};
      `,
      content: `
        box-shadow: 0 ${pxToRem('2px')} 0.25rem 0 rgba(0, 0, 0, 0.5);
      `,
      label: `
      `
    },
    itemsContainer: `
    `,
    dropdownItem: `
      color: ${COLORS.shuttleGray}75;
      line-height: 1.5rem;
      font-family: 'RobotoCondensed';
      background-color: ${COLORS.white};
      height: ${pxToRem('24px')};
      border: none;

      &:hover {
        background-color: ${COLORS.aliceBlueVariant};
        color: ${COLORS.shuttleGray};
      }
      `
  },
  dropdownHeader: {
    error: true,
    wrapper: `
      box-sizing: border-box;
      cursor: pointer;
      background-color: white;
      height: ${pxToRem('20px')};
      border-radius: 0;
      border: none;
      box-shadow: 1px 1px 2px 0 rgba(137, 143, 149, 0.3);
      font-family: 'Roboto';
      color: ${COLORS.denimBlue};
      font-size: ${pxToRem('13px')};
      display: inline-block;
      width: ${pxToRem('176px')};
    `,
    dropableContainer: {
      noDefaultError: true,
      clickable: `
        padding-top: 0;
        height: 100%:
      `,
      caption: `
        box-shadow: none;
        height: ${pxToRem('20px')};
      `,
      placeholder: `
        color: ${COLORS.shuttleGray};
        line-height: 1.4rem;
        font-size: ${pxToRem('13px')};
        padding-left: 0.5rem;
        font-family: 'RobotoCondensedLight';
        vertical-align: middle;
      `,
      icon: `
        position: relative;
        bottom: ${pxToRem('5px')};
        right: ${pxToRem('6px')};
      `,
      content: `
        box-shadow: 0 ${pxToRem('2px')} 0.25rem 0 rgba(0, 0, 0, 0.5);
      `,
      label: 'hide'
    },
    itemsContainer: `
    `,
    dropdownItem: `
      color: ${COLORS.shuttleGray}75;
      line-height: 1.4rem;
      font-family: 'RobotoCondensed';
      background-color: ${COLORS.white};
      height: ${pxToRem('20px')};
      border: none;

      &:hover {
        background-color: ${COLORS.aliceBlueVariant};
        color: ${COLORS.shuttleGray};
      }
      `
  },
  dropdownContainer: `border-bottom: 1px solid ${COLORS.black};
      width:200px;
      margin: auto;`
};
