import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

export const Block = styled(Col)`
  margin: 2.5rem auto;
  color: #5e6267;

  &:first-child {
    margin-bottom: 0;
  }
`;

export default Block;
