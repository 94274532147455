import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Route } from 'react-router-dom';
import EventNavigationBar from './EventNavigationBar';
import EventFilterBar from './EventFilterBar';
import EventList from './EventList';
import CreateNewEventModal from '../CreateNewEventModal';
import Enhancer from '../../containers/Events';
import { EventContainer, EmptyMessage, Wrapper, PaginationWrapper } from './styled-components';
import PaginationBar from '../PaginationBar';
import OL from '../OfficialsList';
import UL from '../UsersList';
import AL from '../AssessorsList';
import LL from '../LocationsList';
import TL from '../TeamsList';
import SideListEnhancer from '../../containers/SideList';
import LoaderWrapper from '../LoaderWrapper';

// TODO fix singular
export const OfficialList = SideListEnhancer(OL);
export const UsersList = SideListEnhancer(UL);
export const AssessorList = SideListEnhancer(AL);
export const LocationList = SideListEnhancer(LL);
export const TeamList = SideListEnhancer(TL);

export const Events = ({
  eventList,
  requestEventListCSV,
  showNewEventModal,
  setModalVisibility: toggleNewEventModal,
  onCreateNewEvent,
  onCreateEventAndRedirect,
  sports,
  governingBodies,
  isSuperAdmin,
  adminEvents,
  assignorEvents,
  fetchEvents,
  roles,
  routes,
  pagination,
  search,
  filter,
  sort,
  storeGamesEventAndRedirect,
  status,
  isFetching,
  location,
  groups,
  userGroups,
  canEdit,
  showProgress,
  hasNoRole
}) => (
  <Wrapper>
    <EventContainer>
      <EventNavigationBar
        selectedNavigationItem={status.value}
        onClick={status.onStatusChange}
        isGroupAdmin={!!userGroups.length}
        isSuperAdmin={isSuperAdmin}
        toggleNewEventModal={toggleNewEventModal}
        requestEventListCSV={requestEventListCSV}
        location={location}
      />
      <EventFilterBar
        filter={filter}
        sports={sports}
        governingBodies={governingBodies}
        roles={roles}
        sort={sort}
        search={search}
        onFilter={fetchEvents}
      />
      <LoaderWrapper isFetching={isFetching}>
        {eventList && !isEmpty(eventList) ? (
          <EventList
            eventList={eventList}
            sports={sports}
            adminEvents={adminEvents}
            assignorEvents={assignorEvents}
            storeGamesEventAndRedirect={storeGamesEventAndRedirect}
            isSuperAdmin={isSuperAdmin}
            userGroups={userGroups}
          />
        ) : hasNoRole ? (
          <EmptyMessage>
            You are not currently affiliated with any events. Please contact your assignor or
            administrator to be added to an event.
          </EmptyMessage>
        ) : (
          <EmptyMessage>No events available</EmptyMessage>
        )}
      </LoaderWrapper>
      {!!pagination.totalCount && (
        <PaginationWrapper>
          <PaginationBar {...pagination} />
        </PaginationWrapper>
      )}
      {showNewEventModal && (
        <CreateNewEventModal
          onClose={toggleNewEventModal}
          onSubmit={onCreateNewEvent}
          onSubmitAndRedirect={onCreateEventAndRedirect}
          sports={sports}
          governingBodies={governingBodies}
          groups={groups}
          isFetching={showProgress}
        />
      )}
    </EventContainer>
    {routes.map(({ path, component: Component, role }) => (
      <Route
        key={path}
        path={path}
        render={(...props) => <Component role={role} canEdit={canEdit} {...props} />}
      />
    ))}
  </Wrapper>
);

Events.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  adminEvents: PropTypes.arrayOf(PropTypes.number),
  assignorEvents: PropTypes.arrayOf(PropTypes.number),
  isSuperAdmin: PropTypes.bool,
  eventList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired
      }).isRequired,
      governing_body: PropTypes.string.isRequired,
      area_city: PropTypes.string,
      area_state: PropTypes.string,
      area_country: PropTypes.string.isRequired,
      summary: PropTypes.shape({
        assessors: PropTypes.number.isRequired,
        locations: PropTypes.number.isRequired,
        officials: PropTypes.number.isRequired,
        teams: PropTypes.number.isRequired,
        users: PropTypes.number.isRequired
      }).isRequired
    })
  ).isRequired,
  requestEventListCSV: PropTypes.func.isRequired,
  showNewEventModal: PropTypes.bool,
  setModalVisibility: PropTypes.func.isRequired,
  onCreateNewEvent: PropTypes.func.isRequired,
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  governingBodies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }) // I think we dont need this
  ).isRequired,
  fetchEvents: PropTypes.func.isRequired,
  onCreateEventAndRedirect: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
      exact: PropTypes.bool,
      path: PropTypes.string
    })
  ),
  pagination: PropTypes.objectOf(Object).isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  sort: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  storeGamesEventAndRedirect: PropTypes.func.isRequired,
  status: PropTypes.shape({
    onStatusChange: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  fetchGroups: PropTypes.func.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.number)
};

Events.defaultProps = {
  adminEvents: [],
  assignorEvents: [],
  showNewEventModal: false,
  isSuperAdmin: false,
  routes: [],
  userGroups: []
};

export default Enhancer(Events);
