import { connect } from 'react-redux';
import { pure } from 'recompose';
import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import { getUser, canEditEvent, taxStatus } from '../../../core/Auth/selectors';
import {
  getEventCategories,
  getIsFetching,
  getIsFetchingEventUsers
} from '../../../core/Events/selectors';
import { isGamesEventGroupAdmin } from '../../../core/Games/selectors';
import { assignCategoriesToUsers as assignCategoriesToUsersAction } from '../../../core/Users';

const mapStateToProps = (state, { match }) => ({
  isGamesEventGroupAdmin: isGamesEventGroupAdmin()(state),
  isEventAdmin: canEditEvent(match)(state),
  user: getUser(state),
  categories: getEventCategories(state),
  isFetching: getIsFetching(state, match),
  isFetchingDetails: state.users.isFetchingDetails,
  taxStatus: taxStatus(state),
  isFetchingEventUsers: getIsFetchingEventUsers(state)
});

const mapDispatchToProps = dispatch => ({
  assignCategoriesToUsers: payload => dispatch(assignCategoriesToUsersAction(payload))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), pure);
