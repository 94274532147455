import React from 'react';
import PropTypes from 'prop-types';
import { defaultTo } from 'ramda';

import buildConfig from './config';
import SideList from '../SideList';
/* import CarretButton from '../CarretButton'; */
import generateUserExpanded from '../SideList/Generic/Expanded';
import {
  PrimaryContainer,
  Summary,
  Name,
  InfoRow,
  Detail,
  Field,
  PrimaryLabel,
  PrimaryValue
} from './styled-components';

import ExpandedEnhancer from '../../containers/Lists/LocationsList/expanded';
import Enhancer from '../../containers/Lists/LocationsList';

const defaultToEmpty = defaultTo('');

const formatExpandedInfo = field => [
  {
    title: 'DETAILS',
    columns: [
      [
        {
          label: 'Phone',
          info: defaultToEmpty(field.location.contact_phone)
        },
        {
          label: 'Website',
          info: defaultToEmpty(field.location.url)
        }
      ],
      [
        {
          label: 'Lit',
          info: (field.is_lit && 'Yes') || (field.is_lit === false && 'No') || ''
        },
        {
          label: 'Indoor/Outdoor',
          info: (field.is_indoor && 'Indoor') || (field.is_indoor === false && 'Outdoor') || ''
        }
      ]
    ],
    fullWidth: [
      {
        label: 'Notes',
        info: defaultToEmpty(field.notes)
      }
    ]
  }
];

const formatAddress = ({ address, address_two, city, state, postal_code }) =>
  `${defaultToEmpty(address)} ${defaultToEmpty(address_two)}, ${defaultToEmpty(
    city
  )}, ${defaultToEmpty(state)} ${defaultToEmpty(postal_code)}`;

const Main = ({ item: field, selected, expanded, isFirst, onSelected }) => (
  <PrimaryContainer opened={expanded} isFirst={isFirst} selected={selected} onClick={onSelected}>
    <Summary selected={selected}>
      <InfoRow first>
        <Name selected={selected}>{field && field.location && field.location.name}</Name>{' '}
        <Field selected={selected} padding>
          {field.name} {field.surface_name && `, ${field.surface_name}`}
        </Field>
      </InfoRow>
      <InfoRow last>
        <Detail>
          <PrimaryLabel selected={selected}>ADDRESS</PrimaryLabel>
          <PrimaryValue selected={selected}>{formatAddress(field)}</PrimaryValue>
        </Detail>
      </InfoRow>
    </Summary>
    {/* <CarretButton opened={expanded} onClick={onExpand} selected={selected} width={2} /> */}
  </PrimaryContainer>
);

Main.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  selected: PropTypes.bool,
  expanded: PropTypes.bool,
  isFirst: PropTypes.bool
  // onExpand: PropTypes.func.isRequired
};

Main.defaultProps = {
  selected: false,
  expanded: false,
  isFirst: false
};

export const LocationsList = props => (
  <SideList
    onLoad={props.onLoad}
    onFetchItemDetail={props.onFetchItemDetail}
    items={props.locations}
    searchLabel="field"
    type="location"
    selectedProp="id"
    mainComponent={Main}
    expandedComponent={generateUserExpanded({
      formatExpandedInfo,
      Enhancer: ExpandedEnhancer
    })}
    config={buildConfig(props)}
    eventId={props.match.params.eventId}
    {...props}
  />
);

LocationsList.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onFetchItemDetail: PropTypes.func,
  locations: PropTypes.arrayOf(Object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  }).isRequired
};

LocationsList.defaultProps = {
  onFetchItemDetail: () => true
};

export default Enhancer(LocationsList);
