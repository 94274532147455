import { compose, withHandlers, withPropsOnChange, pure, lifecycle } from 'recompose';
import { equals } from 'ramda';
import { notNilNotEmpty } from '../../../utils/helpers';

const keysToUpdate = [
  'profile',
  'emergencyInfo',
  'primaryAddress',
  'primaryContact',
  'travelAddress',
  'parentGuardianContact'
];

export default compose(
  withPropsOnChange(
    (props, nextProps) => !keysToUpdate.every(key => equals(props[key], nextProps[key])),
    ({
      profile,
      emergencyInfo,
      primaryAddress,
      primaryContact,
      travelAddress,
      parentGuardianContact,
      defaultChecked
    }) => {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      if (
        primaryAddress.address_1 === travelAddress.address_1 &&
        primaryAddress.city === travelAddress.city &&
        primaryAddress.postal_code === travelAddress.postal_code
      ) {
        primaryAddress.makeTravelAddress = true;
      } else if (
        notNilNotEmpty(primaryAddress.address_1) &&
        notNilNotEmpty(travelAddress.address_1)
      ) {
        primaryAddress.makeTravelAddress = true;
      }

      if (defaultChecked) {
        primaryContact.show_phone = defaultChecked;
        primaryContact.show_email = defaultChecked;
      }

      return {
        initialValues: {
          profile,
          emergencyInfo,
          primaryAddress,
          primaryContact,
          travelAddress,
          parentGuardianContact
        }
      };
    }
  ),
  withHandlers({
    onSubmit: ({ submitForm, location: { pathname } }) => (values = {}) => {
      const { primaryAddress, travelAddress } = values;
      const { state: primaryState } = primaryAddress;
      const { state: travelState } = travelAddress;
      const prmyState =
        typeof primaryState === 'object' ? primaryAddress.state.value : primaryAddress.state;
      const trvlState =
        typeof travelState === 'object' ? travelAddress.state.value : travelAddress.state;

      submitForm({
        values: {
          ...values,
          primaryAddress: { ...primaryAddress, state: prmyState },
          travelAddress: { ...travelAddress, state: trvlState }
        },
        pathname
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      const { fetchActiveUserInfo, paramId } = this.props;
      if (paramId) {
        fetchActiveUserInfo({ userId: paramId });
      }
    }
  }),
  pure
);
