import React from 'react';
import moment from 'moment';
import { Col, Row } from 'react-flexbox-grid';
import { makeStyles } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Tooltip from '@material-ui/core/Tooltip';
import { ICONS } from '../../../../utils/styles';
import CarretButton from '../../CarretButton';
import { getTimezoneAbbr, addCountToDisplayId } from '../../../../utils/helpers';
import {
  GameName,
  StatusIcon,
  GameId,
  RowsWrapper,
  GameTile,
  CarrentIconCol,
  GameStatus,
  GamePosition,
  GamePositionStatus,
  GamePositionStatusIcon,
  GamePaymentStatus,
  IconStyle,
  DarkGray,
  SkyBlueSpan,
  SelfAssignSpan,
  SelfAssignPositionWrapper
} from '../styled-components';
import CalenderExpandInfo from '../CalendarExpandedInfo';

import config from '../config';
import { DATE_FORMATS } from '../../../../constants';

const useStyles = makeStyles({
  cursorTooltip: {
    textTransform: 'none'
  }
});

const CalendarGameRow = ({
  game,
  updateGameAssignments,
  userSelfAssignGames,
  selectedUser,
  setCurrentGame,
  setShowModalFor,
  onAssessmentReportClick,
  onGamesReportClick,
  date,
  fetchAssignments,
  startAndEndDates,
  setCalendarGame,
  user,
  createGameAssignment,
  userSchedule,
  setPulledDates
}) => {
  const [showInfo, setShowInfo] = React.useState(false);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [showAcceptErrorAssignmentsModal, setShowAcceptErrorAssignmentsModal] = React.useState(
    false
  );
  const [hasConflictError, setHasConflictError] = React.useState([]);
  const classes = useStyles();
  const assignment = game && game.assignments_game.length && game.assignments_game[0];
  let paymentStatus = null;
  if (assignment && assignment.payment) {
    const { amount, date: createdAt, status } = assignment.payment;
    if (config.paymentStatus[status]) {
      paymentStatus = config.paymentStatus[status]({ date: new Date(createdAt), amount });
    }
  }
  const statusConfig = config.gameStatus[game.status.toLowerCase()];
  const expandButtonConfig =
    game.assignments_game &&
    game.assignments_game.length &&
    config.expandAssignmentStatus[game.assignments_game[0].status.toLowerCase()];
  const timezoneAbbr = getTimezoneAbbr(
    moment(game.start_at).format(DATE_FORMATS.MM_DD_YYYY_hh_mm_A),
    game.timezone,
    DATE_FORMATS.MM_DD_YYYY
  );

  return (
    <>
      <RowsWrapper>
        <CarrentIconCol {...expandButtonConfig}>
          <CarretButton
            opened={showInfo}
            onClick={() => setShowInfo(!showInfo)}
            nobg={showInfo}
            expandClosedIcon={ICONS.PLUS_ICON}
            expandOpendIcon={ICONS.MINUS}
            customStyle={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              background: 'none'
            }}
          />
        </CarrentIconCol>
        <GameTile>
          <Row middle="xs">
            <Col xs={12}>
              <GameName>
                <span>
                  <b>
                    {game.game_level && game.game_level.event.name}
                    {` `}
                  </b>
                  <GameId fontFamily="Barlow" fontSize="16px" fontWeight="500">
                    {`Game #`}
                    {addCountToDisplayId(game)}
                  </GameId>
                </span>
                {statusConfig && <GameStatus {...statusConfig}> {game.status} </GameStatus>}
              </GameName>
            </Col>
            <Col xs={12} className="crewPosition">
              <GamePosition mincontent>
                <GamePositionStatus>
                  <GamePositionStatusIcon>
                    <StatusIcon
                      {...((assignment.status &&
                        config.assignmentStatus[assignment.status.toLowerCase()]) ||
                        config.assignmentStatus.default)}
                    />
                  </GamePositionStatusIcon>

                  {!assignment.status ? (
                    <>
                      <SelfAssignPositionWrapper>
                        <DarkGray>Open Positions:</DarkGray>
                        <SkyBlueSpan>
                          {game && game.selfAssignPositions.filter(v => v !== null).join(', ')}
                        </SkyBlueSpan>
                      </SelfAssignPositionWrapper>
                      <SelfAssignSpan>Self-assign</SelfAssignSpan>
                    </>
                  ) : (
                    <>
                      <DarkGray>
                        {' '}
                        {game &&
                          game.game_level &&
                          game.game_level.labels &&
                          game.game_level.labels[assignment.official_label_col]}
                      </DarkGray>
                    </>
                  )}
                </GamePositionStatus>
              </GamePosition>
            </Col>
            <Col xs={12}>
              <GamePosition>
                <GamePositionStatus>
                  <GamePositionStatusIcon>
                    <IconStyle className="material-icons">event</IconStyle>
                  </GamePositionStatusIcon>
                  <GameId>
                    {' '}
                    {game.start_date} @ {game.start_time} {`(${timezoneAbbr})`}
                  </GameId>
                </GamePositionStatus>
                {paymentStatus && (
                  <Tooltip
                    PopperProps={{
                      disablePortal: true
                    }}
                    open={openTooltip}
                    title={paymentStatus.message}
                    classes={{ popper: classes.cursorTooltip }}
                  >
                    {paymentStatus.paid ? (
                      <GamePaymentStatus
                        onMouseEnter={() => setOpenTooltip(true)}
                        onMouseLeave={() => setOpenTooltip(false)}
                        {...paymentStatus.styles}
                      >
                        paid
                      </GamePaymentStatus>
                    ) : (
                      <GamePaymentStatus
                        onMouseEnter={() => setOpenTooltip(true)}
                        onMouseLeave={() => setOpenTooltip(false)}
                        {...paymentStatus.styles}
                      >
                        <MonetizationOnIcon fontSize="small" />
                        pending
                      </GamePaymentStatus>
                    )}
                  </Tooltip>
                )}
              </GamePosition>
            </Col>
            <Col xs={12}>
              <GamePosition>
                <GamePositionStatus>
                  <GamePositionStatusIcon>
                    <IconStyle className="material-icons">location_on</IconStyle>
                  </GamePositionStatusIcon>
                  <GameId>
                    {' '}
                    {game && game.location.name}
                    {game && game.field_name && `, ${game.field_name}`}
                    {game &&
                      game.location &&
                      ` (${game.location.city && `${game.location.city},`} ${
                        game.location.state && game.location.state
                      })`}
                  </GameId>
                </GamePositionStatus>{' '}
              </GamePosition>
            </Col>
            <Col xs={12}>
              <GamePosition>
                <GamePositionStatus>
                  <GamePositionStatusIcon>
                    <IconStyle className="material-icons">sports</IconStyle>
                  </GamePositionStatusIcon>
                  <GameId>
                    Game Level:{` `}
                    {game && game.game_level && game.game_level.game_level}
                  </GameId>
                </GamePositionStatus>
              </GamePosition>
            </Col>
          </Row>
        </GameTile>
      </RowsWrapper>

      {showInfo && (
        <CalenderExpandInfo
          key={game.id}
          game={game}
          updateGameAssignments={updateGameAssignments}
          userSelfAssignGames={userSelfAssignGames}
          selectedUser={selectedUser}
          setCurrentGame={setCurrentGame}
          setShowModalFor={setShowModalFor}
          onAssessmentReportClick={onAssessmentReportClick}
          onGamesReportClick={onGamesReportClick}
          date={date}
          fetchAssignments={fetchAssignments}
          startAndEndDates={startAndEndDates}
          setCalendarGame={setCalendarGame}
          timezoneAbbr={timezoneAbbr}
          createGameAssignment={createGameAssignment}
          setPulledDates={setPulledDates}
          user={user}
          userSchedule={userSchedule}
          setShowAcceptErrorAssignmentsModal={setShowAcceptErrorAssignmentsModal}
          showAcceptErrorAssignmentsModal={showAcceptErrorAssignmentsModal}
          hasConflictError={hasConflictError}
          setHasConflictError={setHasConflictError}
        />
      )}
    </>
  );
};

export default CalendarGameRow;
