import styled from 'styled-components';

import { COLORS, hexToRgba } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';
import Circle from '../Circle';

export const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  ${({ simple }) =>
    simple
      ? ''
      : `
    -webkit-backdrop-filter: blur(${pxToRem('3px')});
  backdrop-filter: blur(${pxToRem('3px')});
  background-image: linear-gradient(
    122deg,
    ${hexToRgba(COLORS.congressBlue, 0.9)},
    ${hexToRgba(COLORS.greenVogue, 0.9)}
  );
  `};
  ${({ theme }) => theme || ''};
  flex-direction: column;
`;

export const Animation = styled(Circle)`
  margin: 2rem;
  ${({ customTheme }) => customTheme || ''};
`;

export const MessageWrapper = styled.div``;
