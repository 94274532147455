export default {
  options: [
    {
      name: 'schedule_name',
      label: 'Schedule Name'
    },
    {
      name: 'game_level',
      label: 'Game Level'
    },
    {
      name: 'game_type',
      label: 'Game Type'
    }
  ]
};
