import {
  range,
  head,
  contains,
  isNil,
  isEmpty,
  either,
  take,
  drop,
  assoc,
  assocPath,
  curryN,
  zipObj,
  map,
  values,
  equals,
  or,
  last,
  juxt,
  mergeWith,
  path,
  fromPairs,
  zipWith,
  is,
  findIndex,
  propEq,
  ifElse,
  identity,
  always
} from 'ramda';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import momentRecur from 'moment-recur';

import {
  DATE_FORMATS,
  EVENT_GOVERNING_BODY,
  GAME_UPLOAD_TYPES,
  REPORT_DISPLAY_STATUS,
  URL_ENCODINGS
} from '../constants';
import * as paths from '../core/paths';
import AVAILABILITY_FREQUENCIES, {
  AVAILABILITY_TYPES
} from '../views/components/Profile/Availability/availabilityConstants';
import { getIdToken, getRefreshToken, setTokens } from './auth';
import Api from './api-wrapper';
import { mergeData } from './parsers';

// TODO: This change must be reverted once devops figures out how to set environment variables as we should not be hardcoding and this will only work in prod.
export const getStoredResourcesURL = () => {
  const url = process.env.REACT_APP_S3_CLOUD_FRONT_ASSET_URL
    ? `https://${process.env.REACT_APP_S3_CLOUD_FRONT_ASSET_URL}`
    : 'https://d2c5dn6af63jm9.cloudfront.net';

  return url;
};

// TODO: This change must be reverted once devops figures out how to set environment variables as we should not be hardcoding and this will only work in prod.
export const getAssetsURL = () => {
  // not including https so that it matches the value needed on the API side.
  const url = process.env.REACT_APP_S3_CLOUD_FRONT_ASSET_URL
    ? `https://${process.env.REACT_APP_S3_CLOUD_FRONT_ASSET_URL}/assets/`
    : 'https://d2c5dn6af63jm9.cloudfront.net/assets/';
  return url;
};

export const isDevelopment = () => process.env.NODE_ENV === 'development';
export const isProd = () => process.env.NODE_ENV === 'production';
export const getLoginURL = () =>
  `${process.env.REACT_APP_BSS_LOGIN_URL}/login?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_URL}${paths.SSO_REDIRECT}`;

export const notNilNotEmpty = either(isNil, isEmpty);

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * `wait` milliseconds
 * @param {Function} func function to call after period of time
 * @param {Number} wait ms to wait
 * @param {Boolean} immediate trigger the function, instead of waiting
 */
export function debounce(func, wait = 0, immediate) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    }, wait);
    if (immediate && !timeout) func.apply(this, [...args]);
  };
}

/**
 * Int::Promise
 * Delay effect!.  Used to create a delay efect in sagas
 * Takes an in int in milliseconds
 * returns a promise that resolve the promise in n millyseconds
 */
export function delay(ms) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms));
}

export function pxToRem(px) {
  return `${parseInt(head(px.split('px')), 10) / 16}rem`;
}

/**
 * Takes a String in the form of a query string and converts it to json
 * if key is repeated, it will parse to array
 * @param {String} queryString
 */
export function queryStringToJson(queryString) {
  return notNilNotEmpty(queryString)
    ? {}
    : queryString
        .replace('?', '')
        .split('&')
        .reduce(
          (acc, v) =>
            mergeWith(
              (a, b) => [].concat(a).concat(b),
              acc,
              fromPairs([juxt([head, last])(decodeURIComponent(v).split('='))])
            ),
          {}
        );
}

export function jsonToQueryString(jsonObject) {
  const joinner = (k, v) => (is(Array, v) ? v.map(iv => `${k}=${iv}`).join('&') : `${k}=${v}`);
  const keys = Object.keys(jsonObject);
  const jValues = values(jsonObject);
  const zipped = zipWith(joinner, keys, jValues);
  return `?${zipped.join('&')}`;
}

const mapNumber = number => ({ value: `${number}`, label: `${number}` });
const addZero = number => (number < 10 ? `0${number}` : `${number}`);
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
].map((month, i) => ({ value: addZero(i + 1), label: month }));
export const days = range(1, 32).map(addZero).map(mapNumber);
export const years = range(1930, new Date().getFullYear() + 1)
  .sort((a, b) => b - a)
  .map(mapNumber);

/**
 * takes an object and changes any empty string values to null
 */
export const emptyToNull = obj =>
  Object.keys(obj).reduce(
    (newObj, key) => ({ ...newObj, [key]: obj[key] === '' ? null : obj[key] }),
    {}
  );

/**
 * takes an object and changes any empty string values to zero
 */
export const emptyToZero = obj =>
  Object.keys(obj).reduce(
    (newObj, key) => ({ ...newObj, [key]: obj[key] === '' || obj[key] === null ? 0 : obj[key] }),
    {}
  );

/**
 * takes an object and changes any undefined values to null
 */
export const undefinedToNull = obj =>
  Object.keys(obj).reduce(
    (newObj, key) => ({ ...newObj, [key]: obj[key] === undefined ? null : obj[key] }),
    {}
  );

/**
 * takes an object and strips out any keys with null values
 */
export const removeNullProperties = obj =>
  Object.keys(obj).reduce(
    (newObj, key) => (obj[key] !== null ? { ...newObj, [key]: obj[key] } : newObj),
    {}
  );

export const areArraysDifferent = (ary1, ary2, property) => {
  if (ary1.length !== ary2.length) {
    return true;
  }

  for (let i = 0; i < ary1.length; i += 1) {
    if (ary2[i][property] !== ary1[i][property]) {
      return true;
    }
  }

  return false;
};

export const flattenOnlyKeys = obj =>
  Object.keys(obj).reduce((newObj, key) => {
    if (!obj[key] || typeof obj[key] !== 'object' || Array.isArray(obj[key]))
      return { ...newObj, [key]: obj[key] };

    const subObj = flattenOnlyKeys(obj[key]);
    const flattenedObj = Object.keys(subObj).reduce(
      (newSubObj, subKey) => ({
        ...newSubObj,
        [`${key}.${subKey}`]: subObj[subKey]
      }),
      {}
    );

    return {
      ...newObj,
      ...flattenedObj
    };
  }, {});

/**
 * object for working around final-form's automatic name conversion
 */
export const formConverter = {
  convert: value =>
    typeof value === 'string'
      ? value.replace(/\./g, '=>')
      : Object.keys(value).reduce(
          (newValues, key) => ({
            ...newValues,
            [formConverter.convert(key)]: value[key]
          }),
          {}
        ),
  revert: fields =>
    Object.keys(fields).reduce(
      (newFields, key) => ({
        ...newFields,
        [key.replace(/=>/g, '.')]: fields[key]
      }),
      {}
    )
};

/**
 * takes and object with string values or array of strings and
 * converts values to floats
 */
export const convertPrices = obj => {
  const newObj = {};
  Object.keys(obj).forEach(k => {
    try {
      if (Array.isArray(obj[k])) {
        newObj[k] = obj[k].map(el => {
          if (el.match(/^\$/)) {
            return parseFloat(el.slice(1));
          }
          if (el.match(/^\d+$/)) {
            return parseFloat(el);
          }
          if (el === '') {
            return 0;
          }
          return el;
        });
      } else if (obj[k].match(/^\$/)) {
        newObj[k] = parseFloat(obj[k].slice(1));
      } else if (obj[k].match(/^\d+$/)) {
        newObj[k] = parseFloat(obj[k]);
      } else if (obj[k] === '') {
        newObj[k] = 0;
      } else {
        newObj[k] = obj[k];
      }
    } catch (err) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
};

/**
 * partition-all function takes an array of values and
 * return an array of array of values of the given size
 */
export function partition_all(valuesArray, size, accum = []) {
  const items = take(size, valuesArray);
  const remaining = drop(size, valuesArray);
  if (items.length < size) {
    return accum;
  }
  return partition_all(remaining, size, accum.concat([items]));
}

/**
 * Takes an object and a prefix, adds a prefix to every object key
 * @param {Object} target
 * @param {String} prefix
 */
export const addPrefixToKeys = (target, prefix) =>
  Object.keys(target).reduce((accum, tK) => assoc(`${prefix}${tK}`, target[tK])(accum), {});

/**
 * takes a target object prefixed with a namespace.
 * returns an object grouped by prefix
 * @param {Object} target
 * @param {String} prefixDelimiter
 */
export const groupByPrefix = curryN(2, (prefixDelimiter, target) =>
  Object.keys(target).reduce((accum, key) => {
    const idxDelimiter = key.indexOf(prefixDelimiter);
    const prefix = key.substring(0, idxDelimiter);
    const actualKey = key.substring(idxDelimiter + 1, key.length);
    return assocPath([prefix, actualKey], target[key], accum);
  }, {})
);
/**
 *
 * groupByPrefix^(-1)
 * takes a prefix delimeter and an object and.
 * Returns an object with nested values according to prefix
 */
export const deGroupByPrefix = curryN(2, (prefixDelimiter, target) =>
  Object.keys(target).reduce(
    (accum, key) =>
      Object.keys(target[key]).reduce(
        (iaccum, ikey) =>
          assoc(`${key}${prefixDelimiter}${ikey}`, path([key, ikey], target), iaccum),
        accum
      ),
    {}
  )
);

/**
 * Takes a search/query string and returns a new string with prefixed params removed
 * @param {String} search current search/query string
 * @param {String} prefix specifies which param prefix to remove params for
 */
export const removeByPrefix = (search, prefix) =>
  `?${search
    .slice(1)
    .split('&')
    .filter(param => param.indexOf(`${prefix}_`) === -1)
    .join('&')}`;

export const sameValueSameNameOptionFactory = (sameValueSameName = []) =>
  sameValueSameName.map(n => ({ name: n, value: n }));

/**
 *  <Function,Object>:: Object
 *  Takes a function to be applied to object values. values for object must be arrays
 *  Returns Object With function applied to values.
 *
 */
export const mapObjectValues = curryN(2, (fn, obj) =>
  zipObj(Object.keys(obj), map(fn, values(obj)))
);

/** Takes two objects with the same structure
 * returns a new object with only the keys that changed and the values from the first
 */
export const preferFirst = curryN(2, (first, source) =>
  Object.keys(first).reduce(
    (acc, k) => (equals(source[k], first[k]) ? acc : assoc(k, first[k], acc)),
    {}
  )
);

export const sInterpolator = (object, source, beginChar = ':') => {
  const regularExpression = new RegExp(`:\\w*`, 'g');
  const matches = source.match(regularExpression);
  return or(isNil(matches), isNil(object))
    ? source
    : matches.reduce(
        (acc, word) =>
          acc.replace(
            word,
            ifElse(isNil, always(word), identity)(object[word.replace(beginChar, '')])
          ),
        source
      );
};

export const matchUrl = (pathUrl, actualPath) => {
  const paramRegex = new RegExp(`:\\w*`, 'g');
  const params = pathUrl.match(paramRegex);
  let pathRegex = `^${pathUrl}$`;
  params.forEach(param => {
    pathRegex = pathRegex.replace(new RegExp(`/${param}/`, 'g'), '/[a-zA-Z0-9_.-]*/');
  });
  pathRegex = new RegExp(pathRegex, '');
  return pathRegex.test(actualPath);
};

export const generateRefreshQuery = query => {
  const queryObj = queryStringToJson(query);
  if (!queryObj.mn_reload) {
    queryObj.mn_reload = true;
  } else {
    delete queryObj.mn_reload;
  }
  return jsonToQueryString(queryObj);
};

export const billingMethodOptions = [
  { name: 'No', value: 0 },
  { name: 'Yes', value: 1 }
];

export const allowAdminUpdatesOptions = [
  { name: 'No', value: false },
  { name: 'Yes', value: true }
];

const PERMISSION_WRITE = 'write';

export const writePermissionCheck = (
  userId,
  currentUserId,
  isSuperAdmin,
  accessPermissions,
  allowAdminUpdates
) => {
  return (
    !userId ||
    !currentUserId ||
    userId === currentUserId ||
    isSuperAdmin ||
    (accessPermissions === PERMISSION_WRITE && allowAdminUpdates)
  );
};

export const elevatedWritePermissionCheck = (userId, currentUserId, accessPermissions) => {
  return (
    !userId || !currentUserId || userId === currentUserId || accessPermissions === PERMISSION_WRITE
  );
};

export const precisionRound = (number, precision) => {
  const shift = (numberShift, precisionShift, reverseShift) => {
    let precisionResult = precisionShift;
    if (reverseShift) {
      precisionResult = -precisionResult;
    }
    const numArray = `${numberShift}`.split('e');
    return +`${numArray[0]}e${numArray[1] ? +numArray[1] + precisionResult : precisionResult}`;
  };
  return shift(Math.round(shift(number, precision, false)), precision, true);
};

export const findIndexFromList = (key, id, list) => findIndex(propEq(key, id))(list);

export const capitalize = word => {
  if (!word || !(typeof word === 'string' || word instanceof String)) {
    return word;
  }

  const firstLetter = word.charAt(0);
  const restOfWord = word.slice(1, word.length);

  return `${firstLetter.toUpperCase()}${restOfWord}`;
};

export const parseIntoDateMoment = (date, time) =>
  moment(date, 'MM/DD/YYYY')
    .hour(moment(time, 'h:mm A').hour())
    .minute(moment(time, 'h:mm A').minute())
    .seconds(0);

export const parseIntoDate = (date, time) => parseIntoDateMoment(date, time).toDate();

export const getHigherDate = (startDate, endDate) =>
  moment(endDate).isBefore(startDate) ? startDate : endDate;

/**
 * takes an object and checks for any values at provided keys
 * @param {Object} obj form state split up into subsections
 * @param {Array} keys array of form names to check against within subSection
 */
export const checkForValues = (obj, keys) => keys.some(key => !!obj[key]);

export const travelFields = ['address_1', 'address_2', 'city', 'postal_code', 'state'];

/**
 * converts the provided array into an object where keys are the corresponding value
 * of the key arg on each item, and the values are arrays of all items that match
 * @param {Array} arr items with a key that corresponds to the key arg
 * @param {String} key items are grouped based on their values at this key
 */
export const groupArrayByKey = (arr, key) =>
  arr && arr.length
    ? arr.reduce(
        (resultObj, item) => ({
          ...resultObj,
          [item[key]]: resultObj[item[key]] ? [...resultObj[item[key]], item] : [item]
        }),
        {}
      )
    : {};

export const onlyDigits = value => value.replace(/[^\d]/g, '');

/**
 *
 * @param {Browser Event} e
 * Polyfill for Event.path/Event.composedPath for multi-browser support
 */
export const crossBrowserComposedPath = e => {
  let element = e.target || null;
  const pathArr = [element];

  if (!element || !element.parentElement) {
    return [];
  }

  while (element.parentElement) {
    element = element.parentElement;
    pathArr.unshift(element);
  }

  return pathArr;
};

export const hasClicked = (e, classClicked) => {
  const eventPath = e && crossBrowserComposedPath(e);
  return eventPath
    ? !!eventPath.filter(({ className }) => (className ? contains(classClicked, className) : false))
        .length
    : false;
};

export const conditionallySetDefaultFilter = (
  history,
  filters,
  applyWhenPath,
  filterToAdd,
  valueToGiveFilter
) => {
  const callingPathMatches = history.location.pathname.indexOf(applyWhenPath) !== -1;
  if (callingPathMatches && !filters[filterToAdd]) {
    return { ...filters, [filterToAdd]: valueToGiveFilter };
  }
  return filters;
};

export const round = (value, decimals) =>
  Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);

export const cleanFilterData = filterData => {
  const data = { ...filterData };
  if (data.start_time) {
    const stillUtc_start = moment.utc(data.start_time, 'hh:mm a');
    data.start_time = moment(stillUtc_start).local().format('hh:mm a');
  }
  if (data.end_time) {
    const stillUtc_end = moment.utc(data.end_time, 'hh:mm a');
    data.end_time = moment(stillUtc_end).local().format('hh:mm a');
  }
  return data;
};

export const getGameStatus = status => {
  const lowerCaseStatus = status.toLowerCase();
  if (lowerCaseStatus === 'scheduled') return 'New';
  if (lowerCaseStatus === 'none') return '';
  return status;
};

export const isManualUpload = event => {
  return event && event.game_upload_type === GAME_UPLOAD_TYPES.manual_upload;
};

export const generateUUID = () =>
  /* eslint-disable no-bitwise */
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line no-mixed-operators
    return v.toString(16);
  });

export const generateRefreshToken = async (userIdx, user) => {
  const refreshToken = getRefreshToken(userIdx);

  const idToken = getIdToken();
  const data = await Api.refreshTokens({
    data: { email: user && user.profile.email, token: refreshToken },
    token: idToken
  });
  const token = mergeData(data);
  setTokens({
    tokens: { accessToken: token.access_token, idToken: token.id_token, refreshToken },
    usrIdx: userIdx
  });
  return token;
};

/**
 * Takes an index and returns delay on basis of preferred iterator
 * @param {Number} index for which delay need to calculate
 * @param {Number} iterator specifies the iteration cycle
 * @returns {Number} calculated delay
 */
export const getDelay = (index, iterator = 5) => parseInt(index / iterator, 0) * 500;
export const sortByTime = (a, b, isDesc) =>
  isDesc
    ? new Date(`1970/01/01 ${b}`) - new Date(`1970/01/01 ${a}`)
    : new Date(`1970/01/01 ${a}`) - new Date(`1970/01/01 ${b}`);

export const sortByString = (a, b, isDesc) => {
  const lowerCaseA = a ? a.toLowerCase() : '';
  const lowerCaseB = b ? b.toLowerCase() : '';
  if (lowerCaseA < lowerCaseB) {
    return isDesc ? 1 : -1;
  }
  if (lowerCaseA > lowerCaseB) {
    return isDesc ? -1 : 1;
  }
  return 0;
};
export const sortByDate = (a, b, isDesc) =>
  isDesc ? new Date(b) - new Date(a) : new Date(a) - new Date(b);

export const getDisplayGameObject = gameInfo => {
  const game = { ...gameInfo };
  const startDate = moment.utc(`${game.start_date} ${game.start_time}`).format('MM/DD/YYYY h:mm A');
  game.start_time = moment(new Date(startDate)).format('h:mm A');
  game.start_date = moment(new Date(startDate)).format('MM/DD/YYYY');
  const endDate = moment.utc(`${game.end_date} ${game.end_time}`).format('MM/DD/YYYY h:mm A');
  game.end_time = moment(new Date(endDate)).format('h:mm A');
  game.end_date = moment(new Date(endDate)).format('MM/DD/YYYY');
  return game;
};

export const getGameObject = gameInfo => {
  const game = { ...gameInfo };
  const date = moment(game.start_date).format('MM/DD/YYYY');
  const startDate = moment(`${date} ${game.start_time}`).format('MM/DD/YYYY h:mm A');
  game.start_time = moment.utc(new Date(startDate)).format('h:mm A');
  game.start_date = moment.utc(new Date(startDate)).format('MM/DD/YYYY');
  const endDate = moment(`${date} ${game.end_time}`).format('MM/DD/YYYY h:mm A');
  game.end_time = moment.utc(new Date(endDate)).format('h:mm A');
  game.end_date = moment.utc(new Date(endDate)).format('MM/DD/YYYY');
  return game;
};

export const requiredValidationError = value =>
  !value || (value && (is(String, value) ? isEmpty(value.trim()) : isEmpty(value)));

/**
 * Takes a string value and returns the formatted string as USSF-ID
 * @param {String} value to format
 * @returns {String} The formatted USSF-ID
 */
export const formatUssfId = value => {
  if (!value) return value;
  let formattedUssfId = '';

  const ussfId = value.replace(/[^\d]/g, '');
  const ussfIdLength = ussfId.length;

  if (ussfIdLength <= 4) formattedUssfId = ussfId;
  else if (ussfIdLength <= 8) formattedUssfId = `${ussfId.slice(0, 4)}-${ussfId.slice(4)}`;
  else if (ussfIdLength <= 12)
    formattedUssfId = `${ussfId.slice(0, 4)}-${ussfId.slice(4, 8)}-${ussfId.slice(8)}`;
  else
    formattedUssfId = `${ussfId.slice(0, 4)}-${ussfId.slice(4, 8)}-${ussfId.slice(
      8,
      12
    )}-${ussfId.slice(12, 16)}`;

  return formattedUssfId;
};

export const licensesShortForm = value => {
  const licenseName = value && value.replace(/[//()-/[\]\\]/g, ' ');
  return (
    licenseName &&
    licenseName
      .split(' ')
      .map(name => name.slice(0, 1))
      .join('')
      .toUpperCase()
  );
};

export const getParamId = ({ match }) => match && match.params && match.params.userId;

/**
 * Takes timezones list and timezone code and
 * returns the matched timezone data from given list
 * @param {Array} tzList contains timezones list
 * @param {String} tzCode is the timezone code
 * @returns {Object} Timezone data object
 */
export const getTimezoneByCode = (tzList = [], tzCode) => {
  const tzData = tzList.find(tz => tz.code === tzCode);

  return !tzData ? {} : tzData;
};

/**
 * Takes date and timezone and returns date after converting to provided timezone
 * @param {Date} date which will be converted
 * @param {String} tzCode in which date will be converted
 * @param {String} format to format the converted date
 * @param {String} dateFormat format of given date
 * @returns {Date} Converted date in given timezone
 */
export const convertToTimezone = (date, tzCode, format, dateFormat = 'MM/DD/YYYY hh:mm A') => {
  if (tzCode && tzCode !== 'UTC') {
    return momentTimezone.tz(date, dateFormat, tzCode).format(format);
  }
  return moment.utc(date).format(format);
};

/**
 * Takes date and timezone and returns date
 * after converting to UTC from provided timezone
 * @param {Date} date will be converted to UTC
 * @param {String} tzCode tells the timezone of given date
 * @param {String} format to format the UTC date
 * @returns {Date} UTC date
 */
export const convertToUtc = (date, tzCode, format) =>
  moment.utc(convertToTimezone(date, tzCode)).format(format);

/**
 * Takes date and timezone and returns timezone abbreviation
 * @param {Date} date to get timezone abbr
 * @param {String} tzCode timezone of given date
 * @param {String} dateFormat format of given date
 * @returns {String} Timezone abbreviation
 */
export const getTimezoneAbbr = (date, tzCode = 'UTC', dateFormat = 'MM/DD/YYYY hh:mm A') =>
  momentTimezone.tz(date, dateFormat, tzCode).format('z');

/**
 * Takes UTC date and timezone and returns
 * date after converting to provided timezone
 * @param {Date} date to convert
 * @param {String} tzCode in which date will be converted
 * @param {String} format to format converted date
 * @returns {Date} Converted date in given timezone
 */
export const convertUtcToTimezone = (date, tzCode, format = 'MM/DD/YYYY hh:mm A') =>
  tzCode ? momentTimezone.utc(date).tz(tzCode).format(format) : moment.utc(date).format(format);

export const formatGameFilterStartDate = startDate => {
  if (startDate) {
    return moment
      .utc(startDate)
      .startOf('day')
      .add(10, 'hours')
      .format(DATE_FORMATS.FULL_DATE_TIME);
  }
  return moment.utc().startOf('day').add(10, 'hours').format(DATE_FORMATS.FULL_DATE_TIME);
};

export const formatGameFilterEndDate = endDate => {
  if (endDate) {
    return moment
      .utc(endDate)
      .add(1, 'day')
      .startOf('day')
      .add(10, 'hours')
      .format(DATE_FORMATS.FULL_DATE_TIME);
  }
  return moment
    .utc()
    .add(1, 'day')
    .startOf('day')
    .add(10, 'hours')
    .format(DATE_FORMATS.FULL_DATE_TIME);
};

/**
 * Format the provided reports filter object
 * @param {Object} filterData Object containing filters
 * @returns {filters} Formatted filters object
 */
export const formatReportFilters = (filterData = {}) => {
  const { start_date: start, end_date: end } = filterData;
  const filters = { ...filterData };

  if (start) filters.start_date = formatGameFilterStartDate(start);
  if (end) filters.end_date = formatGameFilterEndDate(end);

  return filters;
};

/**
 * Takes a list of objects and returns sorted list on basis of provided sortKey
 * @param {Array} list which need to be sorted
 * @param {String} sortKey used for sorting
 * @param {Boolean} isDesc to check if sort in descending order
 * @returns {Array} The sorted list
 */
export const sortListByKey = (list, sortKey = 'name', isDesc = false) =>
  list.sort((first, second) => {
    const A = first[sortKey] && first[sortKey].toLowerCase();
    const B = second[sortKey] && second[sortKey].toLowerCase();
    return A > B ? (isDesc ? -1 : 1) : B > A ? (isDesc ? 1 : -1) : 0;
  });

// TODO: refactor this helper when subscriptions are available
export const formatGroupInfo = attrs => {
  const groupInfo = { ...attrs };
  let users;
  let subscriptions = [];

  if (attrs.user_roles) {
    users =
      (attrs.user_roles.length &&
        attrs.user_roles.map(
          role =>
            role.user && {
              roleId: role.role_id,
              name: `${role.user.first_name} ${role.user.last_name}`,
              username: role.user.email,
              last_login: role.user.last_login
                ? `${moment
                    .utc(role.user.last_login)
                    .utcOffset(moment().utcOffset())
                    .format('M/D/YY @ h:mm a')}`
                : 'NA',
              id: role.user.id
            }
        )) ||
      [];

    delete groupInfo.user_roles;
  }

  if (groupInfo.subscriptions && groupInfo.subscriptions.length) {
    subscriptions = groupInfo.subscriptions.map(sub => {
      const product = sub.order_product || {};

      // eslint-disable-next-line no-param-reassign
      delete sub.order_product;
      return {
        ...product,
        cycle: sub.cycle_frequency,
        invoiceRunDay: Number(sub.invoice_generation_day),
        billingStartDate: moment.utc(sub.billing_start_date).format('YYYY-MM-DD'),
        active: sub.active
      };
    });

    delete groupInfo.subscriptions;
  }

  return {
    users,
    groupInfo,
    subscriptions
  };
};

/**
 * Encodes provided string as per URL encoding
 *
 * @param {String} str string to encode
 *
 * @returns Encoded query string
 */
export const encodeQueryString = str =>
  str
    .split('%')
    .join(URL_ENCODINGS['%'])
    .split('"')
    .join(URL_ENCODINGS['"'])
    .split('<')
    .join(URL_ENCODINGS['<'])
    .split('>')
    .join(URL_ENCODINGS['>'])
    .split('#')
    .join(URL_ENCODINGS['#'])
    .split('|')
    .join(URL_ENCODINGS['|'])
    .split('&')
    .join(URL_ENCODINGS['&'])
    .split('+')
    .join(URL_ENCODINGS['+'])
    .split(' ')
    .join(URL_ENCODINGS.SPACE);

export const encodeJSONObject = jsonObject => {
  const encodedObject = {};

  Object.keys(jsonObject).forEach(key => {
    const value = jsonObject[key];

    if (typeof value === 'string') {
      encodedObject[key] = encodeURIComponent(value);
    } else {
      encodedObject[key] = value;
    }
  });

  return encodedObject;
};

/**
 * check for USSOCCER EVENT
 * @param {Object} event Object of event info
 * @returns {Boolean} values
 */
export const isEventGoverningBodyUSSF = event =>
  event && event.governing_body === EVENT_GOVERNING_BODY.USSF;

/**
 * Gets filters for remaining games
 *
 * @property {String} startDate
 * @property {String} format for formatting dates
 *
 * @returns Filters object
 */
export const getRemainingGamesFilter = ({
  startDate = null,
  format = DATE_FORMATS.FULL_DATE_TIME
}) => ({
  'game.start_date': ifElse(
    () => !isNil(startDate),
    () => moment.utc(startDate).format(format),
    () => moment.utc().format(format)
  )()
});

export const getFormatObjectByKey = (dataArray, keyName) => {
  return dataArray.reduce((acc, obj) => {
    const key = obj[keyName];
    if (!acc[key]) {
      acc[key] = {};
    }
    acc[key] = obj;
    return acc;
  }, {});
};

/**
 * Calculates all time slots for Availability|Unavailability
 * on the basis availability frequency and recursion
 *
 * @property {String} type is availability frequency
 * @property {Date} start is default start date
 * @property {Date} end is default end date
 * @property {Number} recursion is the recurring count
 * @property {Array} weekDays contains days of week
 *
 * @returns {Array} List of all time slots
 */
export const getAllSlots = ({ type, start, end, recursion, weekDays = [] }) => {
  switch (type) {
    case AVAILABILITY_FREQUENCIES.weekly: {
      const allStartDates = [
        start,
        ...momentRecur(start)
          .recur()
          .every(weekDays.map(day => (Number(day) + 1) % 7))
          .daysOfWeek()
          .next(recursion)
          .map(d =>
            moment(
              `${d.format(DATE_FORMATS.YYYY_MM_DD)} ${moment(start).format(DATE_FORMATS.H_MM_A)}`
            ).format(DATE_FORMATS.FULL_DATE_TIME)
          )
      ];
      const allEndDates = [
        end,
        ...momentRecur(end)
          .recur()
          .every(weekDays.map(day => (Number(day) + 1) % 7))
          .daysOfWeek()
          .next(recursion)
          .map(d =>
            moment(
              `${d.format(DATE_FORMATS.YYYY_MM_DD)} ${moment(end).format(DATE_FORMATS.H_MM_A)}`
            ).format(DATE_FORMATS.FULL_DATE_TIME)
          )
      ];

      return allStartDates.map((d, i) => ({
        start: d,
        end: allEndDates[i]
      }));
    }

    case AVAILABILITY_FREQUENCIES.daily: {
      const allStartDates = [
        start,
        ...momentRecur(start)
          .recur()
          .every(1)
          .days()
          .next(recursion)
          .map(d =>
            moment(
              `${d.format(DATE_FORMATS.YYYY_MM_DD)} ${moment(start).format(DATE_FORMATS.H_MM_A)}`
            ).format(DATE_FORMATS.FULL_DATE_TIME)
          )
      ];
      const allEndDates = [
        end,
        ...momentRecur(end)
          .recur()
          .every(1)
          .days()
          .next(recursion)
          .map(d =>
            moment(
              `${d.format(DATE_FORMATS.YYYY_MM_DD)} ${moment(end).format(DATE_FORMATS.H_MM_A)}`
            ).format(DATE_FORMATS.FULL_DATE_TIME)
          )
      ];

      return allStartDates.map((d, i) => ({
        start: d,
        end: allEndDates[i]
      }));
    }

    case AVAILABILITY_FREQUENCIES.monthly: {
      const allStartDates = [
        start,
        ...momentRecur(start)
          .recur()
          .every(1)
          .months()
          .next(recursion)
          .map(d =>
            moment(
              `${d.format(DATE_FORMATS.YYYY_MM_DD)} ${moment(start).format(DATE_FORMATS.H_MM_A)}`
            ).format(DATE_FORMATS.FULL_DATE_TIME)
          )
      ];
      const allEndDates = [
        end,
        ...momentRecur(end)
          .recur()
          .every(1)
          .months()
          .next(recursion)
          .map(d =>
            moment(
              `${d.format(DATE_FORMATS.YYYY_MM_DD)} ${moment(end).format(DATE_FORMATS.H_MM_A)}`
            ).format(DATE_FORMATS.FULL_DATE_TIME)
          )
      ];

      return allStartDates.map((d, i) => ({
        start: d,
        end: allEndDates[i]
      }));
    }

    default:
      return [{ start, end }];
  }
};

/**
 * Check if user is unavailable during the provided game's date and time
 *
 * @property {Array} availabilities
 * @property {Object} game
 *
 * @returns {Boolean} True|False
 */
export const isUnavailable = ({ availabilities: allAvailabilities = [], game = {} }) => {
  const unavailabilities = allAvailabilities.filter(
    a => a.availability_type === AVAILABILITY_TYPES.UNAVAILABLE
  );

  const availabilities = unavailabilities.filter(availability => {
    const start = moment.parseZone(availability.time_from).format(DATE_FORMATS.FULL_DATE_TIME);
    const end = moment.parseZone(availability.time_to).format(DATE_FORMATS.FULL_DATE_TIME);
    const gameStart = moment(moment.parseZone(game.start).format(DATE_FORMATS.FULL_DATE_TIME));
    const gameEnd = moment(moment.parseZone(game.end).format(DATE_FORMATS.FULL_DATE_TIME));
    const allSlots = getAllSlots({
      start,
      end,
      type: availability.frequency,
      recursion: availability.count,
      weekDays: availability.interval
    });

    let i = 0;
    while (i < allSlots.length) {
      const { start: newStart, end: newEnd } = allSlots[i];

      if (
        moment(newStart).isBetween(gameStart, gameEnd, null, '[)') ||
        moment(newEnd).isBetween(gameStart, gameEnd, null, '(]') ||
        gameStart.isBetween(moment(newStart), moment(newEnd), null, '[)') ||
        gameEnd.isBetween(moment(newStart), moment(newEnd), null, '(]')
      ) {
        return true;
      }
      i += 1;
    }

    return false;
  });

  return !!availabilities.length;
};

/**
 * Gets games filter
 * @returns Filters object
 */
export const upcomingGamesFilter = ({
  startDate = null,
  endDate = null,
  assignmentFilter = null,
  status = null
}) => ({
  'game.start_date': ifElse(
    () => !isNil(startDate),
    () => moment(startDate).format(DATE_FORMATS.YYYY_MM_DD),
    () => moment().format(DATE_FORMATS.YYYY_MM_DD)
  )(),
  ...(!isNil(endDate) && {
    'game.end_date': moment(endDate).format(DATE_FORMATS.YYYY_MM_DD)
  }),
  ...(!isNil(assignmentFilter) && { assignmentFilter }),
  ...(!isNil(status) && status.length > 0 && { 'game.status': status })
});

export const formatTaxId = value => {
  if (!value) return value;
  const formattedTaxId = '*****';
  const result = value.slice(value.length - 4);
  return formattedTaxId.concat(result);
};

export const flattenObj = ob => {
  const result = {};

  Object.keys(ob).forEach(i => {
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      Object.keys(temp).forEach(j => {
        result[`${i}.${j}`] = temp[j];
      });
    } else {
      result[i] = ob[i];
    }
  });

  return result;
};

export const sortTransactionByDate = transactions => {
  const arr = transactions.sort((a, b) => {
    return moment(b.date).valueOf() - moment(a.date).valueOf();
  });
  return arr;
};

export const addBusinessDays = date => {
  let addDays = 6;
  let newDate;
  if (moment(date).day() === 0 || moment(date).day() === 1) {
    addDays = 4;
    newDate = moment(date).add(addDays, 'days');
  } else if (moment(date).day() === 6) {
    addDays = 5;
    newDate = moment(date).add(addDays, 'days');
  } else {
    newDate = moment(date).add(addDays, 'days');
  }
  return moment(newDate).format(DATE_FORMATS.M_D_YY);
};

// /**
//  * Adds count when not null to display_id to show which games are copies
//  * @returns updated id
// */
// export const addCountToDisplayId = game => {
//   if (!game || (!game.id && !game.display_id)) {
//     return 'N/A';
//   }
//   if (game.count && game.display_id) {
//     return `${game.display_id}_${game.count}`;
//   }
//   if (game.count && game.id) {
//     return `${game.id}_${game.count}`;
//   }
//   return game.display_id || game.id;
// };
export const addCountToDisplayId = game => {
  if (!game || !game.display_id) {
    return 'N/A';
  }
  let id = '';
  if (game.count) {
    id = `${game.display_id}_${game.count}`;
  } else {
    id = game.display_id;
  }
  return id;
};

export const getReportDisplayIdWithCount = report => {
  if (!report || !report.game || !report.game.external_event_id) {
    return 'N/A';
  }
  let id = '';
  if (report.game.count) {
    id = `${report.game.external_event_id}_${report.game.count}`;
  } else {
    id = report.game.external_event_id;
  }
  return id;
};

export const updateDisplayIdWithCount = (display_id, count) => {
  let id = '';
  if (count) {
    id = `${display_id}_${count}`;
  } else {
    id = display_id;
  }
  return id;
};

// Must remain as a pure function as it is used in a reducer. Do not modify the input param
export const genericReportDisplayStatus = reports => {
  if (!reports || reports.length === 0) return REPORT_DISPLAY_STATUS.NONE;

  const approvedIncidentReports = reports && reports.filter(r => r.approved === true);
  if (reports.length === approvedIncidentReports.length) {
    return REPORT_DISPLAY_STATUS.COMPLETE;
  }

  return REPORT_DISPLAY_STATUS.PARTIAL;
};

// Must remain as a pure function as it is used in a reducer. Do not modify the input param
export const buildGameReducerState = (state, updatedStateCurrentProp, reports, gameIdx) => {
  const incident_report_display = genericReportDisplayStatus(reports);
  const gameList =
    gameIdx !== -1
      ? [
          ...state.list.slice(0, gameIdx),
          { ...state.list[gameIdx], incident_report_display },
          ...state.list.slice(gameIdx + 1)
        ]
      : [...state.list];

  return {
    ...state,
    current: updatedStateCurrentProp,
    list: gameList
  };
};

export const isFilteredByDistance = filter =>
  !!(filter && filter.filterData && filter.filterData.distance);

export const isFilteredByAvailability = filter =>
  !!(filter && filter.filterData && filter.filterData.filter_date);

export const filterEmptyRoles = certificates => {
  return Object.keys(certificates).reduce((acc, role) => {
    if (certificates[role] && certificates[role].length > 0) {
      acc[role] = certificates[role];
    }
    return acc;
  }, {});
};

// Function that determines the highest role for user accessing the payments tab. Could have simplified it
// and made it much shorter but this provides details into the "why"
export const isFundingAdminHighestRole = (event, fundingAdminEvents, adminEvents) => {
  // Handle case where admin is only a funding admin and has no event admin role permissions
  if (
    Array.isArray(fundingAdminEvents) &&
    fundingAdminEvents.length > 0 &&
    Array.isArray(adminEvents) &&
    adminEvents.length === 0
  ) {
    return true;
  }

  const currentEventId = event && parseInt(event && event.id, 10);

  // When no event is selected we cannot determine if they are only funding admin. Returning false
  // as long as there is one admin event on their list.
  if (Number.isNaN(currentEventId) && Array.isArray(adminEvents) && adminEvents.length > 0) {
    return false;
  }

  // This check goes before funding admin because if the user is an admin for the current event that is their
  // highest role
  if (Array.isArray(adminEvents) && adminEvents.includes(currentEventId)) {
    return false;
  }

  // Made it to this point without user being an admin in current event
  if (Array.isArray(fundingAdminEvents) && fundingAdminEvents.includes(currentEventId)) {
    return true;
  }

  return false;
};

export const hasAnyAdminEvents = adminEvents =>
  Array.isArray(adminEvents) && adminEvents.length > 0;

// event id data can either found from the selected event params in the uri
// or the retrieved event data from an api
export const getEventId = (match, eventInfo) => {
  return (match && match.params && match.params.eventId) || (eventInfo && eventInfo.id);
};
