import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';

const PublishAssignmentsModal = ({ onClose, selected, onSubmit }) => {
  const gamesWithAssignments = selected.filter(
    game => game.assignments_game.filter(assignment => assignment.status === 'unpublished').length
  );

  const newAssignments = gamesWithAssignments
    .flatMap(game => game.assignments_game)
    .filter(assignment => assignment.status === 'unpublished');

  return (
    <Modal title="Publish Assignments" onClose={onClose} leftPanel>
      <>
        <Row>
          <HighlightedBlock xs={12}>
            <Content>
              <Summary>
                <Count color={COLORS.denimBlue}>{selected.length}</Count> Games Selected
              </Summary>
              <Summary>
                <Count color={COLORS.cobaltGrey}>{newAssignments.length}</Count>
                New Assignments
              </Summary>
            </Content>
          </HighlightedBlock>
        </Row>
        <Row>
          <Block xs={10} xsOffset={1}>
            <Content light first>
              Publishing assignments will set all statuses to pending.
            </Content>
            <Content>
              {newAssignments.length
                ? 'Do you wish to publish the selected game assignments?'
                : 'There are no new assignments to be published'}
            </Content>
          </Block>
        </Row>
        <Row>
          <Block xs={12} className="last">
            <ButtonsContainer>
              <ButtonContainer>
                <Button
                  label="Publish"
                  onClick={() => {
                    onClose();
                    onSubmit(newAssignments);
                  }}
                  disabled={!newAssignments.length}
                  width="72px"
                  height="32px"
                  primary
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
              </ButtonContainer>
            </ButtonsContainer>
          </Block>
        </Row>
      </>
    </Modal>
  );
};

PublishAssignmentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired
};

PublishAssignmentsModal.defaultProps = {};

export default PublishAssignmentsModal;
