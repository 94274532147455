import React from 'react';
import { CardElementWrapper } from '../styled-components';
import AddCard from '../../../../AddCard';

const Card = ({ addCard, addNotifications }) => {
  const [billingInfo, setBillingInfo] = React.useState({
    name: null,
    address: { line1: null, line2: null, city: null, state: null, country: 'US' }
  });

  return (
    <>
      <CardElementWrapper paddingT="0px">
        <AddCard
          billingInfo={billingInfo}
          setBillingInfo={setBillingInfo}
          addNotifications={addNotifications}
          addCard={addCard}
        />
      </CardElementWrapper>
      <br />
    </>
  );
};

export default Card;
