import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';

import './index.css';
import { Subheading } from '..';
import { HeaderRow, ColHeader } from '../../components/SimpleTable';
import CollapsableTableRowContent from '../../components/CollapsableTableRowContent';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  TableWrapper: {
    minWidth: 700
  },
  Innerheader: {
    background: '#ddd',
    padding: '10px 6px'
  },
  RowPadding: {
    padding: '0px 8px',
    background: '#f4f4f4',
    boxShadow: '2px 3px 5px 0px #979797',
    borderBottom: '1px solid #bbbaba'
  },
  InnerRowPadding: {
    padding: '10px 6px',
    border: '1px solid #e6e6e6'
  },
  ColWidth: {
    width: '19% !important',
    maxWidth: '19.2% !important'
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#167CD4',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const sortData = (data, direction, sortFunction) => {
  if (sortFunction) {
    if (direction === 'desc') {
      return data.sort(sortFunction).reverse();
    }
    return data.sort(sortFunction);
  }
  return data;
};

export default function TableWrapper({
  headings,
  subTableHeadings,
  data,
  paymentBatchDetails,
  dataKey,
  total,
  ...rest
}) {
  const classes = useStyles();
  const [sortDirection, setSortDirection] = React.useState('desc');
  const [sortKey, setSortKey] = React.useState(null);
  const [sortFunction, setSortFunction] = React.useState(null);

  const sortedData = sortData(data, sortDirection, sortFunction);
  const sortedSubTabledata = sortData(paymentBatchDetails, sortDirection, sortFunction);
  const numberOfColumns = headings.length;

  return (
    <Paper className={classes.root}>
      <Table {...rest} className={classes.TableWrapper}>
        <TableHead>
          <TableRow>
            {headings.map((heading, key) => (
              <StyledTableCell key={key} className={classes.ColWidth}>
                {heading.sortable ? (
                  <TableSortLabel
                    style={{
                      color: '#fff'
                    }}
                    active={sortKey === heading.id}
                    direction={sortDirection}
                    onClick={() => {
                      if (sortKey !== heading.id) {
                        setSortKey(heading.id);
                        setSortDirection('desc');
                        setSortFunction(heading.sortFunction);
                      } else {
                        setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                      }
                    }}
                  >
                    {heading.name}
                  </TableSortLabel>
                ) : (
                  heading.name
                )}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.length ? (
            sortedData.map((row, i) => (
              // eslint-disable-next-line
              <CollapsableTableRowContent
                row={row}
                dataKey={dataKey}
                headings={headings}
                i={i}
                pt={1}
              >
                <TableCell colSpan="6" className={classes.RowPadding}>
                  {sortedSubTabledata[i] ? (
                    <HeaderRow className={classes.Innerheader}>
                      {subTableHeadings.map((subHeading, key) => (
                        <React.Fragment key={key}>
                          {subHeading.sortable ? (
                            <TableSortLabel
                              style={{
                                color: '#fff'
                              }}
                              active={sortKey === subHeading.id}
                              direction={sortDirection}
                              onClick={() => {
                                if (sortKey !== subHeading.id) {
                                  setSortKey(subHeading.id);
                                  setSortDirection('desc');
                                  setSortFunction(subHeading.sortFunction);
                                } else {
                                  setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                                }
                              }}
                            >
                              <ColHeader xs key={subHeading.name} className={classes.ColWidth}>
                                {subHeading.name}
                              </ColHeader>
                            </TableSortLabel>
                          ) : (
                            <ColHeader xs key={subHeading.name} className={classes.ColWidth}>
                              {subHeading.name}
                            </ColHeader>
                          )}
                        </React.Fragment>
                      ))}
                    </HeaderRow>
                  ) : (
                    <Col xs>No data</Col>
                  )}

                  {sortedSubTabledata.length ? (
                    sortedSubTabledata[i] &&
                    sortedSubTabledata[i].map(subTableData => (
                      <Row key={`row[${dataKey}]${i}`} className={classes.InnerRowPadding}>
                        {subTableData &&
                          Object.keys(subTableData).map((item, index) => (
                            <Col key={`row[${i}]${index}`} xs className={classes.ColWidth}>
                              {item === 'batchTotal'
                                ? `$${Number(subTableData[item]).toFixed(2)}`
                                : subTableData[item]}
                            </Col>
                          ))}
                      </Row>
                    ))
                  ) : (
                    <Col colSpan={numberOfColumns}>There is no data to display.</Col>
                  )}
                </TableCell>
              </CollapsableTableRowContent>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={numberOfColumns}>There is no data to display.</TableCell>
            </TableRow>
          )}

          {total ? (
            <TableRow>
              <TableCell colSpan={numberOfColumns - 2} />
              <TableCell>
                <Subheading>Total</Subheading>
              </TableCell>
              <TableCell>
                <Subheading>{total}</Subheading>
              </TableCell>
            </TableRow>
          ) : null}
          {/* I realize that && should produce the same output as above, but it throws a warning when I use it. */}
        </TableBody>
      </Table>
    </Paper>
  );
}

TableWrapper.propTypes = {
  headings: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired
};
