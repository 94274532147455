import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { Wrapper, Message, ButtonsWrapper } from './styled-components';

const ActiveBatchMessage = ({ batch, deleteActiveBatch, editActiveBatch }) => (
  <Wrapper>
    <Message>
      {`You have an active batch for the period starting
              ${moment(batch.start_date).format('MMM Do')} until 
              ${moment(batch.end_date).format('MMM Do')}.`}
    </Message>
    <ButtonsWrapper>
      <Button type="submit" label="Edit Batch" primary onClick={editActiveBatch} />
      <Button type="submit" label="Delete Batch" onClick={deleteActiveBatch} />
    </ButtonsWrapper>
  </Wrapper>
);

ActiveBatchMessage.propTypes = {
  batch: PropTypes.objectOf(Object),
  deleteActiveBatch: PropTypes.func.isRequired,
  editActiveBatch: PropTypes.func.isRequired
};

ActiveBatchMessage.defaultProps = {
  batch: {}
};

export default ActiveBatchMessage;
