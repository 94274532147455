import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Modal from '../../Modal';
import TextArea from '../../Reusable/TextArea';
import Button from '../../Button';
import theme, {
  ModalContent,
  WarningDiv,
  TableRow,
  TableText,
  TableContentContainer,
  Wrapper,
  Label,
  PaymentMouseOverPopoverWrapper,
  HeaderRow
} from './styled-components';
import { BoldBlue, Subheader, FlexDiv, ButtonWrapper } from '../SummaryBar/styled-components';
import Icon from '../../Icon';
import { ICONS, COLORS } from '../../../../utils/styles';
import enhancer from '../../../containers/Payments/BatchEdit/BatchModal';
import { numToCurrency } from '../../../../utils/parsers';
import { maxLength, composeValidators, required } from '../../../../utils/validations';
import MaterialDropDown from '../../MaterialDropDown';
import MouseOverPopover from '../../../global-material-components/Popover';
import LoaderWrapper from '../../LoaderWrapper';

const PreviewBatch = ({
  totalGames,
  totalPayments,
  setBatchModalOpen,
  assignmentPayments,
  handleConfirmBatch,
  fundingAdmins,
  minTransferAmount,
  isFetching
}) => {
  const adminList = fundingAdmins.slice(0); // copy
  adminList.splice(0, 0, {
    attributes: { first_name: 'All Event Administrators', last_name: '' },
    id: 0
  });
  const isNotTransferEligible =
    assignmentPayments &&
    assignmentPayments.find(official => official.total_pay < minTransferAmount);
  return (
    <Modal
      title="Batch Review"
      size={{
        xs: 10
      }}
      onClose={() => setBatchModalOpen(false)}
    >
      <LoaderWrapper isFetching={isFetching}>
        <Wrapper>
          <HeaderRow>
            <FlexDiv>
              <BoldBlue>{totalGames}</BoldBlue>
              <Subheader noLeftMargin>Games</Subheader>
            </FlexDiv>

            <FlexDiv>
              <BoldBlue>{assignmentPayments.length}</BoldBlue>
              <Subheader noLeftMargin>Game Crew</Subheader>
            </FlexDiv>

            <FlexDiv>
              <BoldBlue>{numToCurrency(totalPayments)}</BoldBlue>
              <Subheader noLeftMargin>Payments</Subheader>
            </FlexDiv>
          </HeaderRow>
          <Form
            onSubmit={handleConfirmBatch}
            initialValues={{
              description: '',
              assigneeId: null
            }}
            render={({ handleSubmit, pristine, invalid, submitting }) => (
              <ModalContent>
                <Field
                  name="description"
                  component={TextArea}
                  innerPlaceholder="Batch Description* (96 character max)"
                  resize="none"
                  theme={{ textArea: { minHeight: '3rem', height: '3rem', marginBottom: '1rem' } }}
                  validate={composeValidators(required, maxLength(96))}
                  showError
                  nonDirty
                />
                <div
                  style={{
                    display: 'inline-block',
                    width: '565px',
                    position: 'relative',
                    right: '35px'
                  }}
                >
                  <Label noLeftMargin>Assign to Funding Admin:</Label>
                  <div style={{ float: 'left', width: '50%' }}>
                    <Field
                      component={MaterialDropDown}
                      name="assigneeId"
                      options={adminList.map(user => ({
                        name: `${user.attributes.first_name} ${user.attributes.last_name}`,
                        value: user.id
                      }))}
                      theme={theme.dropdown}
                      validate={required}
                      showError
                    />
                  </div>
                </div>
                {!!assignmentPayments.length && (
                  <>
                    <TableRow header isMargin>
                      <TableText>Game Crew</TableText>
                      <TableText>Owed</TableText>
                    </TableRow>

                    <TableContentContainer>
                      {assignmentPayments.map(official => (
                        <TableRow key={`officialRow${official.first}${official.last}`}>
                          <TableText value>
                            <span>{`${official.first} ${official.last}`}</span>{' '}
                            {official.total_pay < minTransferAmount && (
                              <PaymentMouseOverPopoverWrapper>
                                <MouseOverPopover
                                  transformOriginHorizontal="left"
                                  messageTitle="Invalid Amount"
                                  messageBody={`Pay amount must be greater than or equal to $${
                                    minTransferAmount && minTransferAmount.toFixed(2)
                                  }`}
                                >
                                  <InfoOutlinedIcon />{' '}
                                </MouseOverPopover>
                              </PaymentMouseOverPopoverWrapper>
                            )}
                          </TableText>
                          <TableText>{numToCurrency(official.total_pay)}</TableText>
                        </TableRow>
                      ))}
                    </TableContentContainer>
                  </>
                )}
                <WarningDiv>
                  <Icon
                    width={25}
                    height={25}
                    icon={ICONS.EXCLAMATION_CIRCLE}
                    color={COLORS.warningYellow}
                  />
                  Once users have been added to a Game Pay Batch, crew assignments cannot be
                  changed.
                </WarningDiv>
                <ButtonWrapper>
                  <Button
                    onClick={() => setBatchModalOpen(false)}
                    secondary
                    label="Cancel"
                    width="90"
                  />
                  <Button
                    onClick={handleSubmit}
                    disabled={pristine || invalid || submitting || isNotTransferEligible}
                    label="Approve Batch"
                    primary
                    width="130"
                  />
                </ButtonWrapper>
              </ModalContent>
            )}
          />
        </Wrapper>
      </LoaderWrapper>
    </Modal>
  );
};

PreviewBatch.propTypes = {
  totalGames: PropTypes.number,
  totalPayments: PropTypes.number,
  assignmentPayments: PropTypes.arrayOf(Object),
  setBatchModalOpen: PropTypes.func.isRequired,
  handleConfirmBatch: PropTypes.func.isRequired,
  fundingAdmins: PropTypes.arrayOf(Object)
};

PreviewBatch.defaultProps = {
  totalGames: 0,
  totalPayments: 0,
  assignmentPayments: [],
  fundingAdmins: []
};

export default enhancer(PreviewBatch);
