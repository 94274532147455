import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { always } from 'ramda';
import { compose, pure, withStateHandlers } from 'recompose';
import {
  Container,
  Header,
  TitleContainer,
  Title,
  CollapsedContent,
  ChevronIcon
} from './styled-component';
import { ICONS, COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

const CollapsableContent = ({
  children,
  isOpen,
  setOpen,
  title,
  theme,
  fetch,
  iconStyle,
  onClickEvent
}) => (
  <ThemeProvider theme={theme}>
    <Container>
      <Header
        onClick={() => {
          fetch();
          setOpen();
          if (onClickEvent) {
            onClickEvent();
          }
        }}
      >
        <TitleContainer>
          <Title color={isOpen ? theme.titleColorWhenOpen : theme.titleColorWhenClosed}>
            {title}
          </Title>
        </TitleContainer>
        <ChevronIcon
          width={theme.iconWidth}
          height={theme.iconHeight}
          icon={isOpen ? ICONS.BOLD_CHEVRON_DOWN : ICONS.BOLD_CHEVRON_RIGHT}
          color={isOpen ? theme.iconColorWhenOpen : theme.iconColorWhenClosed}
          style={iconStyle}
        />
      </Header>
      {isOpen && <CollapsedContent>{children}</CollapsedContent>}
    </Container>
  </ThemeProvider>
);

CollapsableContent.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fetch: PropTypes.func,
  title: PropTypes.string,
  theme: PropTypes.shape({
    headerBg: PropTypes.string,
    headerMinHeight: PropTypes.string,
    headerMinPaddingTop: PropTypes.string,
    iconColorWhenClosed: PropTypes.string,
    iconColorWhenOpen: PropTypes.string,
    iconHeight: PropTypes.number,
    iconWidth: PropTypes.number,
    titleColorWhenClosed: PropTypes.string,
    titleColorWhenOpen: PropTypes.string,
    titleFont: PropTypes.string,
    titleLetterSpacing: PropTypes.string,
    titleSize: PropTypes.string,
    justifyContent: PropTypes.string,
    titleTransform: PropTypes.oneOf([
      'capitalize',
      'inherit',
      'initial',
      'lowercase',
      'none',
      'uppercase'
    ]),
    titleWeight: PropTypes.oneOf([
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'bold',
      'bolder',
      'inherit',
      'initial',
      'lighter',
      'normal'
    ])
  })
};

CollapsableContent.defaultProps = {
  title: '',
  fetch: always(undefined),
  theme: {
    headerBg: 'transparent',
    headerMinHeight: `${pxToRem('32px')}`,
    headerMinPaddingTop: `${pxToRem('30px')}`,
    iconColorWhenClosed: COLORS.shuttleGrey,
    iconColorWhenOpen: COLORS.denimBlue,
    iconHeight: 13,
    iconWidth: 13,
    titleColorWhenClosed: COLORS.shuttleGray,
    titleColorWhenOpen: COLORS.denimBlue,
    titleFont: 'Roboto',
    titleLetterSpacing: '0.5px',
    titleSize: `${pxToRem('15px')}`,
    titleTransform: 'none',
    titleWeight: 'bold'
  }
};

export default compose(
  withStateHandlers(
    ({ externalIsOpen = false }) => ({
      isOpen: externalIsOpen
    }),
    {
      setOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen
      })
    }
  ),
  pure
)(React.memo(CollapsableContent));
