export default {
  options: [
    {
      name: 'date_location_time',
      label: 'Date, Location, Time',
      resultSorter: [
        { key: 'start_date', comparator: 'date' },
        { key: 'location.name', comparator: 'string' },
        { key: 'field_name', comparator: 'string' },
        { key: 'start_time', comparator: 'time' }
      ]
    },
    {
      name: 'date_time_location_name',
      label: 'Date, Time, Location'
    },
    {
      name: 'location_name_date_time',
      label: 'Location, Date, Time'
    }
  ]
};
