import { compose, withStateHandlers } from 'recompose';

export default compose(
  withStateHandlers(
    ({ categories = [] }) => ({
      checkBoxData: categories.map(({ id, name }) => ({
        name,
        value: id,
        label: name,
        checked: false
      }))
    }),
    {
      setCheckBoxData: () => checkBoxData => ({ checkBoxData })
    }
  )
);
