import React from 'react';
import PropTypes from 'prop-types';

import Filters from '../../../Filters';
import generateFields from './config';

const GamesFilter = ({
  gamelevels,
  categories,
  showOffNameFilter,
  complexes,
  teams,
  ...filtersProps
}) => (
  <Filters
    config={generateFields({ gamelevels, categories, showOffNameFilter, complexes, teams })}
    {...filtersProps}
  />
);

GamesFilter.propTypes = {
  gamelevels: PropTypes.arrayOf(Object),
  categories: PropTypes.arrayOf(Object)
};

GamesFilter.defaultProps = {
  gamelevels: [],
  categories: []
};

export default React.memo(GamesFilter);
