import { compose, withHandlers, withState } from 'recompose';

export default compose(
  withState('subject', 'setSubject', ''),
  withState('body', 'setBody', ''),
  withState('inProgress', 'setInProgress', false),
  withState('sendSuccess', 'setSendSuccess', false),
  withHandlers({
    resolve: ({ setInProgress, setSendSuccess }) => result => {
      setInProgress(false);
      setSendSuccess(!!result);
    }
  }),
  withHandlers({
    removeRecipient: ({ recipientList, setRecipientList }) => recipientId =>
      setRecipientList(recipientList.filter(recipient => recipient.id !== recipientId)),
    handleOnSubmit: ({ resolve, setInProgress, recipientList, subject, body, onSubmit }) => () => {
      setInProgress(true);
      const userIds = recipientList.map(recipient => recipient.id);
      onSubmit({
        userIds,
        subject: subject.trim(),
        body: body.trim(),
        resolve
      });
    }
  })
);
