import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  getActiveUser,
  getUser,
  isAnyEventAssignorOrAssessorOrOfficial
} from '../../../core/Auth/selectors';
import {
  payeeStatus,
  hasPaymentProfileRequired,
  hasProfileComplete
} from '../../../core/User/selectors';

const mapStateToProps = state => ({
  officialAssessorAssignor: isAnyEventAssignorOrAssessorOrOfficial(state),
  isProfileCompleted: hasProfileComplete(state),
  isPaymentSetUpComplete: payeeStatus(state),
  userId: getUser(state).id || getActiveUser(state).id,
  isPaymentProfileRequired: hasPaymentProfileRequired(state),
  isPaidThroughSO: state.user.profileMeta && state.user.profileMeta.is_paid_through_so
});

const mapDispatchToProps = {};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
