import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';

export default styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  min-height: 4rem;
  flex: 0 0 auto;
  cursor: pointer;
  border: ${({ selected }) => (selected ? `2px solid #0565D9` : `2px solid #F4F8FB`)};
  border-bottom: ${({ selected }) =>
    selected ? `2px solid #0565D9` : `1px solid ${COLORS.lightCyanGray}`};
  z-index: ${({ opened }) => opened && 1};
  border-bottom: ${({ opened }) => opened && `1px solid ${COLORS.lightCyanGray}`};
  -webkit-box-shadow: ${({ opened }) => opened && '-1px 4px 5px 0px rgba(0,0,0,0.31)'};
  -moz-box-shadow: ${({ opened }) => opened && '-1px 4px 5px 0px rgba(0,0,0,0.31)'};
  box-shadow: ${({ opened }) => opened && '-1px 4px 5px 0px rgba(0,0,0,0.31)'};
  ${props =>
    props.selected && props.expandSideList
      ? ` position: absolute;
  width: 100%;
  height: 100%;
  top:0px;
  background: #fff; z-index:999; border:0px;`
      : ``}
`;
