// Actions
export const FETCH_EVENT_TEAMS = 'EVENT_TEAMS/FETCH_EVENT_TEAMS';
export const SET_EVENT_TEAMS = 'EVENT_TEAMS/SET_EVENT_TEAMS';
export const FETCH_EVENT_UNIQUE_TEAMS = 'EVENT_TEAMS/FETCH_EVENT_UNIQUE_TEAMS';
export const SET_EVENT_UNIQUE_TEAMS = 'EVENT_TEAMS/SET_EVENT_UNIQUE_TEAMS';
export const SET_IS_FETCHING = 'EVENT_TEAMS/SET_IS_FETCHING';

// Reducer
const initialState = {
  list: [],
  meta: { total_count: 0, limit: 50000 },
  isFetching: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_EVENT_TEAMS:
      return {
        ...state,
        meta: action.payload.meta,
        list: action.payload.data
      };
    case SET_EVENT_UNIQUE_TEAMS:
      return {
        ...state,
        meta: action.payload.meta,
        list: action.payload.data
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    default:
      return state;
  }
}

// Action Creators
export const fetchEventTeams = payload => ({
  type: FETCH_EVENT_TEAMS,
  payload
});

export const setEventTeams = payload => ({
  type: SET_EVENT_TEAMS,
  payload
});

export const fetchEventUniqueTeams = payload => ({
  type: FETCH_EVENT_UNIQUE_TEAMS,
  payload
});

export const setEventUniqueTeams = payload => ({
  type: SET_EVENT_UNIQUE_TEAMS,
  payload
});
export const setIsFetching = payload => ({
  type: SET_IS_FETCHING,
  payload
});
