import React from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';

import { Chevron, Header, Label } from './styled-components';
import { COLORS, ICONS } from '../../../utils/styles';

/**
 * Header with content toggle, just pass content as children
 * @param {String} label header title
 * @param {Object} theme *optional* theme object
 */
const CollapseableHeader = ({ children, label, showContent, setShowContent, theme }) => (
  <>
    <Header>
      <Label>{label}</Label>
      <Chevron
        width={10}
        height={16}
        showContent={showContent}
        color={
          (theme.chevron && theme.chevron.color) || showContent ? COLORS.denimBlue : COLORS.sapphire
        }
        icon={showContent ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_RIGHT}
        theme={theme.chevron}
        onClick={() => setShowContent(s => !s)}
      />
    </Header>
    {showContent && children}
  </>
);

CollapseableHeader.propTypes = {
  children: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  showContent: PropTypes.bool,
  setShowContent: PropTypes.func.isRequired,
  theme: PropTypes.shape({})
};

CollapseableHeader.defaultProps = {
  showContent: true,
  theme: {}
};

export default withState('showContent', 'setShowContent', true)(CollapseableHeader);
