import React from 'react';
import PropTypes from 'prop-types';
import { ModalContainer, Rows, Description } from './styled-components';
import { Dialog } from '../../global-material-components';

export default function ConfirmationPopup({
  userId,
  sourceId,
  type,
  title,
  description,
  SubmitBtnText,
  confirmationPopupAction,
  setConfirmationPopupAction,
  primaryActionFun,
  primaryButtonDisabled,
  cancelButtonText,
  customStyle
}) {
  return (
    <Dialog
      title={title}
      onClose={() => {
        setConfirmationPopupAction(false);
      }}
      open={confirmationPopupAction}
      cancelAction={() => setConfirmationPopupAction(false)}
      primaryAction={() => {
        primaryActionFun(sourceId !== '' && { id: sourceId, userId, type });
      }}
      primaryButtonText={SubmitBtnText}
      Cancel
      cancelButtonText={cancelButtonText}
      primaryButtonDisabled={primaryButtonDisabled}
      style={customStyle}
    >
      <ModalContainer>
        <Rows>
          <Description>{description}</Description>
        </Rows>
      </ModalContainer>
    </Dialog>
  );
}

ConfirmationPopup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  SubmitBtnText: PropTypes.string.isRequired,
  confirmationPopupAction: PropTypes.bool.isRequired,
  setConfirmationPopupAction: PropTypes.func.isRequired,
  primaryActionFun: PropTypes.func.isRequired
};
