import React from 'react';
import PropTypes from 'prop-types';
import { always, isNil } from 'ramda';
import SideListSorter from './SideListSorter';
import { ICONS, COLORS } from '../../../../utils/styles';
import Enhancer, { onKeyDown, onClick } from '../../../containers/Search';
import Filters from '../../Filters';
import {
  MainContainer,
  SortByContainer,
  FilterByContainer,
  SearchField,
  SearchIcon
} from './styled-components';

export const SearchBox = ({ name, placeholder, onChange, searchValue, onSearch }) => (
  <MainContainer>
    <SearchIcon
      height={13}
      width={13}
      icon={ICONS.SEARCH}
      color={COLORS.denimBlue}
      onClick={() => onClick(searchValue, onSearch)}
      noMargin
    />
    <SearchField
      type="text"
      name={name}
      placeholder={placeholder}
      onKeyDown={event => onKeyDown(event, searchValue, onSearch)}
      onChange={onChange}
      value={isNil(searchValue) ? '' : searchValue}
    />
  </MainContainer>
);

SearchBox.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  searchValue: PropTypes.string,
  onSearch: PropTypes.func
};

SearchBox.defaultProps = {
  name: '',
  placeholder: '',
  onChange: always(undefined),
  searchValue: '',
  onSearch: always(undefined)
};
const EnhancedSearchBox = Enhancer(React.memo(SearchBox));

/**
 * Filter Sort Search Component for User List.
 */
export const FilterSortSearch = ({
  placeholder,
  filter,
  sort,
  search,
  config,
  noFilter,
  noSort
}) => (
  <MainContainer>
    {!noSort && <SideListSorter config={config.sort} {...sort} />}
    {!noFilter && (
      <FilterByContainer split width={10}>
        <Filters
          {...filter}
          config={config.filters}
          filterUtils={config.utils && config.utils.filter ? config.utils.filter : {}}
          sideList
          {...sort}
        />
      </FilterByContainer>
    )}
    <SortByContainer split={noFilter}>
      <EnhancedSearchBox placeholder={placeholder} {...search} />
    </SortByContainer>
  </MainContainer>
);

FilterSortSearch.propTypes = {
  placeholder: PropTypes.string,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  sort: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  config: PropTypes.shape({
    sort: PropTypes.object,
    filters: PropTypes.arrayOf(Object),
    actions: PropTypes.object
  }).isRequired,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  noFilter: PropTypes.bool,
  noSort: PropTypes.bool
};

FilterSortSearch.defaultProps = {
  placeholder: '',
  noFilter: false,
  noSort: false
};

export default React.memo(FilterSortSearch);
