import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import Error from '../InputError';

const FieldError = ({ name, message }) => (
  <Field
    name={name}
    subscription={{ value: true, error: true, touched: true, invalid: true, submitError: true }}
    render={({ meta: { invalid, touched, error, submitError } }) =>
      (submitError || (invalid && touched)) && (
        <Error>{message || error || submitError || 'Required'}</Error>
      )
    }
  />
);

FieldError.propTypes = {
  name: PropTypes.string.isRequired,
  message: PropTypes.string
};

FieldError.defaultProps = {
  message: ''
};

export default FieldError;
