import { DASHBOARD, EVENTS } from '../../../core/paths';

export const config = userRoles => {
  if (
    userRoles.superAdmin ||
    userRoles.groupAdmin ||
    userRoles.admin ||
    userRoles.assignor ||
    userRoles.officialAssessor
  ) {
    return DASHBOARD;
  }

  return `${EVENTS}?ev_status=2`;
};

export const isonBoardingComplete = (
  isProfileCompleted,
  officialAssessorAssignor,
  isPaymentProfileRequired,
  isPaymentSetUpComplete,
  isPaidThroughSO
) => {
  const isSOPaidAccount = isPaidThroughSO && officialAssessorAssignor && isPaymentProfileRequired;
  const isComplete = isProfileCompleted && (!isSOPaidAccount || isPaymentSetUpComplete);
  return isComplete;
};
