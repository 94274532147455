import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'ramda';
import { Form, Field } from 'react-final-form';
import Paper from '../../../layouts/Paper';
import { OFFICIAL_MIN_GRADE, ASSESSOR_MIN_GRADE, POSITION_LABELS } from '../../../../constants';

import {
  FormContainer,
  TableRow,
  ButtonContainer,
  LeftButtonContainer,
  RightButtonContainer,
  CopyFrom,
  DropDown,
  GameLevelLabel,
  GameLevelHeaderRow,
  LineSeparator,
  TableContainer,
  InfoIconBlue,
  MouseOverPopoverWrapper,
  ConfirmationIcon
} from './styled-components';
import { ActualContentContainerCrew, H4, InstructionContainer, Span } from '../styled-components';
import TwoColumnContainer from '../../../layouts/FieldSpreadLayoutContainer';
import Button from '../../Button';
import { composeValidators, maxLength, valueInRange } from '../../../../utils/validations';
import LoaderWrapper from '../../LoaderWrapper';
import TextInput from '../../../global-material-components/Inputs/textfield';
import IntegerInput from '../../../global-material-components/Inputs/integerInput';
import MaterialSelect from '../../../global-material-components/Selects/select';
import MaterialSwitch from '../../../global-material-components/Switch/switch';
import { COLORS, ICONS } from '../../../../utils/styles';
import MouseOverPopover from '../../../global-material-components/Popover';

const MAX_CREW = 8;
const ROLE_OPTIONS = [
  { value: 0, label: 'None' },
  { value: 4, label: 'Assessor' },
  { value: 5, label: 'Official' }
];

export const CrewLabels = prop => {
  const {
    onSubmit,
    isFetching,
    game_levels,
    currentIndex,
    setIndex,
    setId,
    locked,
    isUSSoccer,
    setSelectOpen,
    selectOpen,
    batchedOrStaged
  } = prop;
  const { crew_labels } = prop;
  crew_labels.labels = (crew_labels.labels.length > 0 && crew_labels.labels) || [
    'Pos. 1',
    'Pos. 2',
    'Pos. 3',
    'Pos. 4',
    'Pos. 5',
    'Pos. 6',
    'Pos. 7',
    'Pos. 8'
  ];
  crew_labels.role_ids = (crew_labels.role_ids.length > 0 && crew_labels.role_ids) || [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0
  ];
  crew_labels.set_crew_pay = crew_labels.set_crew_pay || false;
  const gameLevelStyle = { marginTop: '0px', width: '100%', marginRight: '10px' };
  return (
    <LoaderWrapper isFetching={isFetching}>
      <ActualContentContainerCrew>
        <InstructionContainer>
          <H4>Set the CREW SIZE for this Game Level</H4>
          <Span>1. Select Role of Official or Role of Assessor for each position needed</Span>
          <br />
          <Span>
            2. Enter a Crew Label that describes the position (ex.Referee, AR1, Umpire, Home Plate,
            Judge, Mentor)
          </Span>
          <br />
          <Span>Copy Info from existing setup when applicable.</Span>
        </InstructionContainer>
        <Form
          onSubmit={onSubmit}
          initialValues={crew_labels}
          render={({ handleSubmit, pristine, submitting, invalid, values }) => (
            <form onSubmit={handleSubmit}>
              <Paper crewLabel>
                <FormContainer>
                  <CopyFrom>
                    <GameLevelHeaderRow>Copy Info From</GameLevelHeaderRow>
                    <DropDown>
                      <MaterialSelect
                        options={game_levels}
                        value={currentIndex === 0 ? 0 : currentIndex || -1}
                        onChange={setIndex}
                        disabled={locked}
                        style={gameLevelStyle}
                      />
                    </DropDown>
                  </CopyFrom>
                  <LineSeparator />
                  <TableContainer>
                    <TableRow between="xs" key="role_ids">
                      <GameLevelLabel>Official or Assessor?</GameLevelLabel>
                      {range(0, MAX_CREW).map((i, index) => (
                        <Field name={`role_ids[${i}]`} key={`role_ids[${i}]`} disabled={locked}>
                          {({ input, meta, ...rest }) => (
                            <MaterialSelect
                              isRoleField
                              setSelectOpen={setSelectOpen}
                              position={index + 1}
                              options={ROLE_OPTIONS}
                              {...input}
                              {...rest}
                              onChange={value => {
                                /* eslint-disable no-param-reassign */
                                values.labels[i] = POSITION_LABELS[i].label;
                                input.onChange(value);
                                /* eslint-enable no-param-reassign */
                              }}
                              style={gameLevelStyle}
                            />
                          )}
                        </Field>
                      ))}
                    </TableRow>
                    <TableRow between="xs" key="labels">
                      <GameLevelLabel>Crew label per position</GameLevelLabel>
                      {range(0, MAX_CREW).map(i => (
                        <Field
                          name={`labels[${i}]`}
                          key={`labels[${i}]`}
                          validate={composeValidators(maxLength(10))}
                          disabled={batchedOrStaged}
                        >
                          {({ input, meta: { error }, ...rest }) => {
                            if (values.role_ids[i] === 0) {
                              /* eslint-disable no-param-reassign */
                              input.value = '';
                              values.labels[i] = '';
                              /* eslint-enable no-param-reassign */
                            }
                            return (
                              <TextInput
                                error={error}
                                {...input}
                                {...rest}
                                style={gameLevelStyle}
                              />
                            );
                          }}
                        </Field>
                      ))}
                    </TableRow>
                    {isUSSoccer && (
                      <TableRow between="xs" key="official_min_grades">
                        <MouseOverPopoverWrapper>
                          <MouseOverPopover
                            messageTitle="Minimum Grade Level"
                            messageBody="Select a U.S. Soccer grade level to restrict which officials can be assigned to this crew position. Select “No Grade Required” if no grade restrictions apply. "
                          >
                            <GameLevelLabel>
                              Min Grade
                              <InfoIconBlue />
                            </GameLevelLabel>
                          </MouseOverPopover>
                        </MouseOverPopoverWrapper>
                        {range(0, MAX_CREW).map((i, index) => (
                          <Field
                            name={`assessor_min_grades[${i}]`}
                            key={`assessor_min_grades[${i}]`}
                            disabled={values.role_ids[i] === 0}
                          >
                            {({ input, meta, ...rest }) => {
                              if (selectOpen === `reset-${index + 1}`) {
                                /* eslint-disable no-param-reassign */
                                values.assessor_min_grades[index] = null;
                                input.value = '';
                                /* eslint-enable no-param-reassign */
                              }
                              return (
                                <MaterialSelect
                                  isMinGrade
                                  fieldIndex={index + 1}
                                  setSelectOpen={setSelectOpen}
                                  selectOpen={selectOpen}
                                  options={
                                    values.role_ids[i] === 4
                                      ? ASSESSOR_MIN_GRADE
                                      : OFFICIAL_MIN_GRADE
                                  }
                                  {...input}
                                  {...rest}
                                  style={gameLevelStyle}
                                />
                              );
                            }}
                          </Field>
                        ))}
                      </TableRow>
                    )}
                    {
                      <TableRow between="xs" key="min_ranks">
                        <MouseOverPopoverWrapper>
                          <MouseOverPopover
                            messageTitle="Minimum Rank"
                            messageBody={`Select a rank to restrict which officials can be assigned to this crew position. Leave empty if no rank restrictions apply.
                            <br/> <br/>Assign users ranks using the User List action menu on the Events page.`}
                          >
                            <GameLevelLabel>
                              Min Rank
                              <InfoIconBlue />
                            </GameLevelLabel>
                          </MouseOverPopover>
                        </MouseOverPopoverWrapper>

                        {range(0, MAX_CREW).map(i => (
                          <Field
                            name={`min_ranks[${i}]`}
                            key={`min_ranks[${i}]`}
                            disabled={values.role_ids[i] === 0}
                            validate={valueInRange({ min: 0, max: 99 })}
                          >
                            {({ input, meta: { error }, ...rest }) => (
                              <IntegerInput error={error} min={0} max={99} {...input} {...rest} />
                            )}
                          </Field>
                        ))}
                      </TableRow>
                    }
                  </TableContainer>
                  <LineSeparator />
                  <ButtonContainer>
                    <LeftButtonContainer>
                      <GameLevelLabel>Period length</GameLevelLabel>
                      <Field
                        name="length_period"
                        disabled={batchedOrStaged}
                        validate={valueInRange({ min: 1, max: 60 })}
                      >
                        {({ input, meta: { error }, ...rest }) => (
                          <IntegerInput error={error} min={1} max={60} {...input} {...rest} />
                        )}
                      </Field>
                      <GameLevelLabel>Minutes</GameLevelLabel>
                      <Field name="set_crew_pay" disabled={batchedOrStaged}>
                        {({ input, meta, ...rest }) => (
                          <MaterialSwitch checked={input.value} {...input} {...rest}>
                            <ConfirmationIcon
                              width={20}
                              height={20}
                              icon={ICONS.CHECK_CIRCLE}
                              color={COLORS.denimBlue}
                              noMargin
                            />
                          </MaterialSwitch>
                        )}
                      </Field>
                      <GameLevelLabel>Enable Crew Pay</GameLevelLabel>
                    </LeftButtonContainer>
                    <RightButtonContainer>
                      <TwoColumnContainer
                        fields={[
                          <Button
                            type="button"
                            label="Cancel"
                            onClick={() => setId({})}
                            name="cancelBtn"
                            noShadow
                            customStyle={{
                              borderRadius: '3px',
                              border: 'solid 2px #989fa8',
                              color: '#6a7079'
                            }}
                          />,
                          <Button
                            label="Save"
                            primary
                            type="submit"
                            disabled={
                              (pristine || submitting || invalid) &&
                              !(currentIndex || currentIndex === 0)
                            }
                            name="saveBtn"
                            noShadow
                            customStyle={{
                              borderRadius: '3px',
                              border: 'solid 2px #989fa8',
                              backgroundColor: '#167cd4'
                            }}
                          />
                        ]}
                        columns={2}
                      />
                    </RightButtonContainer>
                  </ButtonContainer>
                </FormContainer>
              </Paper>
            </form>
          )}
        />
      </ActualContentContainerCrew>
    </LoaderWrapper>
  );
};
CrewLabels.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  crew_labels: PropTypes.shape({
    labels: PropTypes.array,
    role_ids: PropTypes.array,
    official_min_grades: PropTypes.array,
    assessor_min_grades: PropTypes.array,
    min_ranks: PropTypes.array
  })
};
CrewLabels.defaultProps = {
  crew_labels: {
    labels: [],
    role_ids: [],
    official_min_grades: [],
    assessor_min_grades: [],
    min_ranks: []
  }
};
export default CrewLabels;
