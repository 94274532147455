import React from 'react';
import Radio from '@material-ui/core/Radio';
import COLORS from '../../../../../utils/styles';
import Wallet from '../../../Wallet';
import {
  HeaderText,
  RedirectLink,
  RedirectText,
  StandaloneRadioBtnContainer
} from '../styled-components';
import { PAYMENT_METHODS } from '../../../../../constants';
import { sInterpolator as routeBuilder } from '../../../../../utils/helpers';
import * as paths from '../../../../../core/paths';
import { userIdxGetter } from '../../../../../utils/auth';

const SOBalance = ({ setWalletSelected, selectWallet, user, userWallet }) => {
  const hasBalance = userWallet.available;
  const hasPayerId = user.payer && user.payer.id;
  const userIndex = userIdxGetter();
  return (
    <div>
      <HeaderText>
        {' '}
        Select Wallet to Use{' '}
        {!selectWallet && (
          <span
            style={{
              color: COLORS.errorRed,
              fontSize: 12
            }}
          >
            {` (*Required)`}
          </span>
        )}
      </HeaderText>

      <StandaloneRadioBtnContainer defaultAlignment>
        <Radio
          color="primary"
          checked={selectWallet === PAYMENT_METHODS.WALLET}
          onChange={evt => setWalletSelected(evt.target.value)}
          value="wallet"
          name="bankAccountInfo"
        />
        <Wallet defaultAlignment />
      </StandaloneRadioBtnContainer>
      {!hasPayerId || hasBalance === 0 || hasBalance === '' ? (
        <RedirectText>
          Add additional funds via{' '}
          <RedirectLink
            to={routeBuilder({ userIdx: userIndex, userId: user.id }, paths.PROFILE_PAYMENT_METHOD)}
          >
            Payment Profile
          </RedirectLink>
          .
        </RedirectText>
      ) : (
        ''
      )}
    </div>
  );
};
export default SOBalance;
