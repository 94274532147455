import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { makeStyles } from '@material-ui/core/styles';

import LoaderWrapper from '../../../LoaderWrapper';
import {
  GhostContainer,
  AddIcon,
  RadioStyle,
  GhostStyle,
  HeaderText,
  BankName,
  ListWrapper,
  MainWrapper,
  ModalContainer,
  BackIcon
} from '../styled-components';
import { RadioGroup, GhostButton } from '../../../../global-material-components';
import { COLORS, ICONS } from '../../../../../utils/styles';

import AddBank from '../../../AddBank';

const useStyles = makeStyles({
  label: {
    flex: 1,
    width: '100%',
    'margin-left': '1px'
  }
});

const ACHAccount = ({
  accounts,
  isFetching,
  selectBank,
  setSelectBank,
  addNotifications,
  newAccount,
  toggleNewAccount,
  isAddNew,
  setDialogDisplay
}) => {
  const classes = useStyles();

  return accounts && accounts.length && !newAccount ? (
    <div>
      <HeaderText>
        {' '}
        Select Account to Use{' '}
        {!selectBank && (
          <span
            style={{
              color: COLORS.errorRed,
              fontSize: 12
            }}
          >
            {` (*Required)`}
          </span>
        )}{' '}
      </HeaderText>
      <ListWrapper>
        <LoaderWrapper isFetching={isFetching}>
          <RadioGroup
            id="bank-account-info"
            aria-label="Bank Account Info"
            label="Bank Account Info"
            name="bankAccountInfo"
            onChange={evt => {
              setSelectBank(evt.target.value);
            }}
            classes={{ root: classes.label }}
            dataItems={
              accounts
                ? accounts.map(acc => ({
                    value: `${acc.id}`,
                    label: (
                      <div style={{ marginRight: '10px' }}>
                        <BankName>
                          {acc.details.bank} - {acc.details.nick_name}
                        </BankName>
                      </div>
                    ),
                    disabled: !acc.verified
                  }))
                : []
            }
            value={selectBank}
            style={RadioStyle}
            labelClasses={{
              label: classes.label
            }}
          />
        </LoaderWrapper>
        <GhostContainer>
          <GhostButton
            style={GhostStyle}
            onClick={() => {
              toggleNewAccount(true);
              setSelectBank(null);
              isAddNew(true);
            }}
          >
            Add Bank Account
            <AddIcon
              width={20}
              height={20}
              icon={ICONS.PLUS_CIRCLE}
              color={COLORS.denimBlue}
              noMargin
            />
          </GhostButton>
        </GhostContainer>
        <br />
      </ListWrapper>
    </div>
  ) : (
    <MainWrapper>
      <ModalContainer>
        <Row middle="xs">
          <Col xs={8}>
            <HeaderText>New Bank Account</HeaderText>
          </Col>
          <Col>
            {accounts && accounts.length ? (
              <GhostButton
                onClick={() => {
                  toggleNewAccount(false);
                  isAddNew(false);
                }}
              >
                Go Back{' '}
                <BackIcon width={20} height={20} icon={ICONS.BACK_ARROW} color={COLORS.denimBlue} />
              </GhostButton>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <AddBank
          closeModal={() => {
            toggleNewAccount(false);
            isAddNew(false);
          }}
          addNotifications={addNotifications}
          setDialogDisplay={setDialogDisplay}
        />
      </ModalContainer>
    </MainWrapper>
  );
};

export default ACHAccount;
