import { SPORT_TYPES } from '../../../../constants';

const soccer = {
  fields: [
    'Home Score',
    'Home Cautions',
    'Home SendOffs',
    'Away Score',
    'Away Cautions',
    'Away SendOffs'
  ],
  getValues: byPlayer => report => {
    const values = new Array(6).fill(0);
    if (byPlayer) {
      if (!report || !report.home_team || !report.away_team) {
        return values;
      }

      report.home_team.team_rosters.forEach(roster => {
        values[0] += roster.total_goals || 0;
        values[1] += roster.is_caution ? 1 : 0;
        values[2] += roster.is_send_off ? 1 : 0;
      });

      report.away_team.team_rosters.forEach(roster => {
        values[3] += roster.total_goals || 0;
        values[4] += roster.is_caution ? 1 : 0;
        values[5] += roster.is_send_off ? 1 : 0;
      });

      return values;
    }
    values[0] = (report.home_team && report.home_team.score) || 0;
    values[1] = (report.home_team && report.home_team.cautions) || 0;
    values[2] = (report.home_team && report.home_team.send_off) || 0;
    values[3] = (report.away_team && report.away_team.score) || 0;
    values[4] = (report.away_team && report.away_team.cautions) || 0;
    values[5] = (report.away_team && report.away_team.send_off) || 0;

    return values;
  }
};

const nonSoccer = {
  fields: ['Home Score', 'Away Score'],
  getValues: byPlayer => report => {
    const values = new Array(2).fill(0);

    if (byPlayer) {
      if (report.home_team) {
        report.home_team.team_rosters.forEach(roster => {
          values[0] += roster.total_goals || 0;
        });
      }
      if (report.away_team) {
        report.away_team.team_rosters.forEach(roster => {
          values[1] += roster.total_goals || 0;
        });
      }
      return values;
    }

    values[0] = (report.home_team && report.home_team.score) || 0;
    values[1] = (report.away_team && report.away_team.score) || 0;

    return values;
  }
};

export default {
  getReportInfo: (report, settings, getReportsEventSport) => {
    return (report &&
    (getReportsEventSport === SPORT_TYPES.futsal || getReportsEventSport === SPORT_TYPES.soccer)
      ? soccer
      : nonSoccer
    ).getValues(settings && settings.score_entry_option === 'Score by Player')(report);
  },
  getReportFields: (report, setting, getReportsEventSport) => {
    return (report &&
    (getReportsEventSport === SPORT_TYPES.futsal || getReportsEventSport === SPORT_TYPES.soccer)
      ? soccer
      : nonSoccer
    ).fields;
  }
};
