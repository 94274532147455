import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';

import Enhancer from '../../containers/Notifications';
import {
  Container,
  Wrapper,
  Notification,
  NotificationMeta,
  Title,
  Message
} from './styled-components';

// TODO: Add nice animations when the notifications pop up
const Notifications = ({ notifications }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setShow(true);
    setTimeout(() => setShow(false), 5000);
  }, [notifications]);

  return (
    show && (
      <Container>
        {notifications.map(({ type, message, id }) => (
          <Wrapper key={id}>
            <Notification type={type}>
              <NotificationMeta>
                <Title>{type === 'error' ? 'Error' : 'Success'}</Title>
                <Message>{message}</Message>
              </NotificationMeta>
            </Notification>
          </Wrapper>
        ))}
      </Container>
    )
  );
};

Notifications.propTypes = {
  notifications: Proptypes.arrayOf(
    Proptypes.shape({
      type: Proptypes.string,
      message: Proptypes.oneOfType([Proptypes.array, Proptypes.string]),
      id: Proptypes.string
    })
  )
};

Notifications.defaultProps = {
  notifications: []
};

export default Enhancer(Notifications);
