import React from 'react';
import PropTypes from 'prop-types';

import { TableRow, TableText, TableContentContainer, TableFooter } from './styled-components';
import { numToCurrency } from '../../../../utils/parsers';

const PreviewFundBatch = ({ fundSummaryDetails, batchFee = 0, contentBackground }) =>
  fundSummaryDetails && (
    <>
      <TableRow header>
        <TableText>{`Game Crew (${fundSummaryDetails.length})`}</TableText>
        <TableText>Owed</TableText>
      </TableRow>
      <TableContentContainer height="195px">
        {fundSummaryDetails.map(official => (
          <TableRow
            key={`officialRow${official.first_name}${official.last_name}`}
            background={contentBackground}
          >
            <TableText>{`${official.first_name} ${official.last_name}`}</TableText>
            <TableText paddingL={fundSummaryDetails.length <= 1 ? '0px' : '5px'}>
              {numToCurrency(official.total_payment)}
            </TableText>
          </TableRow>
        ))}
      </TableContentContainer>
      <TableFooter footer>
        <TableText>Fee</TableText>
        <TableText>{numToCurrency(batchFee)}</TableText>
      </TableFooter>
      <br />
    </>
  );

PreviewFundBatch.propTypes = {
  fundSummaryDetails: PropTypes.arrayOf(Object),
  contentBackground: PropTypes.string
};

PreviewFundBatch.defaultProps = {
  fundSummaryDetails: [],
  contentBackground: ''
};

export default PreviewFundBatch;
