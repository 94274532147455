import styled from 'styled-components';
import { HEIGHTS, COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const EventNavigationBarInnerContainer = styled.div`
  min-height: ${pxToRem(HEIGHTS.eventNavigationBar)};
  display: flex;
  justify-content: space-between;
  background: ${COLORS.denimBlue};
  background: -moz-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${
    COLORS.denimBlue
  }), color-stop(100%, ${COLORS.bahamaBlue}));
  background: -webkit-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -o-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: -ms-linear-gradient(top, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  background: linear-gradient(to bottom, ${COLORS.denimBlue} 0%, ${COLORS.bahamaBlue} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${
    COLORS.denimBlue
  }', endColorstr='${COLORS.bahamaBlue}', GradientType=0);
`;

export const EventNavigationItemContainer = styled.div`
  display: flex;
`;

export const EventNavigationItem = styled.span`
  display: flex;
  align-items: center;
  padding: 0 ${pxToRem('32px')};
  border-right: 1px solid ${COLORS.navyBlue};
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  cursor: pointer;

  &.active {
    margin-top: ${pxToRem('2px')};
    padding-bottom: ${pxToRem('2px')};
    background: ${COLORS.bahamaBlue};
    background: -moz-linear-gradient(top, ${COLORS.bahamaBlue} 0%, ${COLORS.greenVogue} 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, ${
      COLORS.bahamaBlue
    }), color-stop(100%, ${COLORS.greenVogue}));
    background: -webkit-linear-gradient(top, ${COLORS.bahamaBlue} 0%, ${COLORS.greenVogue} 100%);
    background: -o-linear-gradient(top, ${COLORS.bahamaBlue} 0%, ${COLORS.greenVogue} 100%);
    background: -ms-linear-gradient(top, ${COLORS.bahamaBlue} 0%, ${COLORS.greenVogue} 100%);
    background: linear-gradient(to bottom, ${COLORS.bahamaBlue} 0%, ${COLORS.greenVogue} 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${
      COLORS.bahamaBlue
    }', endColorstr='${COLORS.greenVogue}', GradientType=0);
    border-right-color: transparent;
  }
`;

export const EventActionItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EventActionItem = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: ${pxToRem('20px')};
  }
`;

const EventStatusIndicator = styled.span`
  margin-right: 5px;
  min-width: ${pxToRem('12px')};
  min-height: ${pxToRem('12px')};
  max-width: ${pxToRem('12px')};
  max-height: ${pxToRem('12px')};
  border-radius: 50%;
`;

export const ClosedEventIndicator = EventStatusIndicator.extend`
  background: ${COLORS.wildWatermelon};
  background: -moz-linear-gradient(top, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${COLORS.wildWatermelon}), color-stop(100%, ${COLORS.carmine}));
  background: -webkit-linear-gradient(top, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  background: -o-linear-gradient(top, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  background: -ms-linear-gradient(top, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  background: linear-gradient(to bottom, ${COLORS.wildWatermelon} 0%, ${COLORS.carmine} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${COLORS.wildWatermelon}', endColorstr='${COLORS.carmine}', GradientType=0);
`;

export const OpenEventIndicator = EventStatusIndicator.extend`
  background: ${COLORS.kellyGreen};
  background: -moz-linear-gradient(top, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, ${COLORS.kellyGreen}), color-stop(100%, ${COLORS.forestGreen}));
  background: -webkit-linear-gradient(top, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  background: -o-linear-gradient(top, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  background: -ms-linear-gradient(top, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  background: linear-gradient(to bottom, ${COLORS.kellyGreen} 0%, ${COLORS.forestGreen} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${COLORS.kellyGreen}', endColorstr='${COLORS.forestGreen}', GradientType=0);
`;
