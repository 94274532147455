import moment from 'moment';
import { PRODUCTS, INVOICE_STATUS } from '../../../constants';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export default {
  [PRODUCTS.PER_GAME_FEE]: subscription => ({
    due: () => ({
      endDate: moment()
        .set('date', moment(subscription.billing_start_date).date())
        .format(dateFormat),
      status: INVOICE_STATUS[0]
    })
  })
  // [PRODUCTS.PLATFORM_FEE]: subscription => ({
  //   due: () => ({
  //     endDate: moment()
  //       .set('date', moment(subscription.cycle_start_date).date())
  //       .format(dateFormat),
  //     status: INVOICE_STATUS[0]
  //   })
  // })
};
