import React, { Children } from 'react';
import PropTypes from 'prop-types';

import { Background, ChildContainer } from './styled-components';

const Paper = ({ children, authFlow, overflow, crewLabel = false, customStyle = '', border }) => (
  <Background authFlow={authFlow} crewLabel={crewLabel} customStyle={customStyle} border={border}>
    {Children.map(children, (child, i) => (
      <ChildContainer key={i} showOverflow={overflow}>
        {child}
      </ChildContainer>
    ))}
  </Background>
);

Paper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  authFlow: PropTypes.bool,
  overflow: PropTypes.bool
};

Paper.defaultProps = {
  authFlow: false,
  overflow: false
};

export default Paper;
