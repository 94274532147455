import React from 'react';
import PropTypes from 'prop-types';

import MaterialInput from '../../components/MaterialInput';

// Stateless function components cannot be given refs
class InputWithFocus extends React.Component {
  constructor(props) {
    super(props);
    this.inputElement = null;
  }
  // eslint-disable-next-line
  focus() {
    return true;
  }

  render() {
    const { input, onChange, value, theme, meta, ...rest } = this.props;
    return (
      <MaterialInput
        inputRef={element => {
          this.inputElement = element;
        }}
        input={{ name: input.name, onChange, value }}
        theme={theme}
        meta={{ ...meta, touched: theme.noAutoTouch ? !!value : true }}
        {...rest}
      />
    );
  }
}

InputWithFocus.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]).isRequired,
  theme: PropTypes.shape({
    wrapper: PropTypes.string.isRequired
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

InputWithFocus.defaultProps = {
  input: {
    name: '',
    onBlur: () => {},
    onFocus: () => {}
  },
  theme: {
    wrapper: '',
    label: '',
    input: ''
  },
  meta: {
    touched: false,
    error: undefined
  }
};

export default InputWithFocus;
