import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${({ paddingTop }) => (paddingTop ? '1.3rem' : '1rem')};
`;

export const ContentDivider = styled.div`
  width: ${({ calcWidth }) => `${calcWidth}%`};
`;

export const UnPaddedRowContainer = RowContainer.extend`
  padding-top: 0;
`;
