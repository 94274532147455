import React from 'react';
import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function checkbox({ onChange, value, checked, ...rest }) {
  return <Checkbox color="primary" onChange={onChange} checked={checked} value={value} {...rest} />;
}

checkbox.propTypes = {
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};
