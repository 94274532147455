import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import {
  Container,
  Label,
  SortIconContainer,
  ArrowUp,
  ArrowDown
} from '../../Sorter/styled-components';
import Header from './styled-components';

const ColumnSorter = ({
  label,
  minWidth,
  onSort,
  theme,
  value,
  sortColumnName,
  defaultSortUp,
  showDefaultSort,
  justifyContent,
  padding
}) => {
  const icons = {
    up: { active: defaultSortUp && showDefaultSort, sortValue: sortColumnName },
    down: { active: !defaultSortUp && showDefaultSort, sortValue: `-${sortColumnName}` }
  };
  if (value !== null) {
    if (value.indexOf(sortColumnName) > -1) {
      if (value.indexOf('-') === -1) {
        icons.up.active = true;
        icons.down.active = false;
      } else {
        icons.up.active = false;
        icons.down.active = true;
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container tabIndex={-1} minWidth={minWidth}>
        <Header justifyContent={justifyContent}>
          <Label padding={padding}>{label}</Label>
          <SortIconContainer>
            <ArrowUp isActive={icons.up.active} onClick={() => onSort(icons.up.sortValue)} />
            <ArrowDown isActive={icons.down.active} onClick={() => onSort(icons.down.sortValue)} />
          </SortIconContainer>
        </Header>
      </Container>
    </ThemeProvider>
  );
};

ColumnSorter.propTypes = {
  label: PropTypes.string.isRequired,
  minWidth: PropTypes.number,
  onSort: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    sorterHeaderBg: PropTypes.string,
    sorterLabelColor: PropTypes.string,
    chevronDownColor: PropTypes.string,
    optionBg: PropTypes.string,
    optionColor: PropTypes.string,
    optionHoverBg: PropTypes.string,
    optionHoverColor: PropTypes.string,
    clearButtonColor: PropTypes.string
  }),
  value: PropTypes.string,
  sortColumnName: PropTypes.string,
  defaultSortUp: PropTypes.bool,
  showDefaultSort: PropTypes.bool
};

ColumnSorter.defaultProps = {
  minWidth: 117,
  theme: {
    sorterHeaderBg: '',
    sorterLabelColor: COLORS.white,
    chevronDownColor: COLORS.white,
    optionBg: COLORS.darkCerulean,
    optionColor: COLORS.white,
    optionHoverBg: COLORS.sapphire,
    optionHoverColor: COLORS.denimBlue,
    clearButtonColor: COLORS.greyChateau
  },
  value: '',
  sortColumnName: '',
  defaultSortUp: true,
  showDefaultSort: true
};

export default ColumnSorter;
