// Actions
export const FETCH_USER_CERTIFICATIONS = 'CERTS/FETCH_USER_CERTIFICATIONS';
export const SET_USER_CERTIFICATIONS = 'CERTS/SET_USER_CERTIFICATIONS';
export const ADD_USER_CERTIFICATIONS = 'CERTS/ADD_USER_CERTIFICATIONS';
export const UPDATE_USER_CERTIFICATIONS = 'CERTS/UPDATE_USER_CERTIFICATIONS';
export const SET_IS_FETCHING = 'CERTS/SET_IS_FETCHING';
export const SET_USER_USSF = 'CERTS/SET_USER_USSF';
export const DELETE_USER_CERTIFICATIONS = 'CERTS/DELETE_USER_CERTIFICATIONS';

// Reducer
const initialState = {
  certData: {},
  isFetching: false,
  ussf: {
    id: '',
    isValid: false,
    first_name: '',
    last_name: ''
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_CERTIFICATIONS:
      return {
        ...state,
        certData: { ...action.payload }
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_USER_USSF:
      return {
        ...state,
        ussf: {
          ...state.ussf,
          ...action.payload
        }
      };
    default:
      return state;
  }
}

// Action Creators

export const fetchCertifications = payload => ({
  payload,
  type: FETCH_USER_CERTIFICATIONS
});

export const setCertificates = payload => ({
  payload,
  type: SET_USER_CERTIFICATIONS
});

export const addCertifications = payload => ({
  payload,
  type: ADD_USER_CERTIFICATIONS
});

export const updateCertifications = payload => ({
  payload,
  type: UPDATE_USER_CERTIFICATIONS
});

export const setIsFetching = payload => ({
  payload,
  type: SET_IS_FETCHING
});

export const setUssf = payload => ({
  payload,
  type: SET_USER_USSF
});

export const deleteCertifications = payload => ({
  payload,
  type: DELETE_USER_CERTIFICATIONS
});
