import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Flex from '../../Flex';
import ActionDropdown from '../../ActionDropdown';
import Dropdown from '../../Reusable/Dropdown';
import EventFilter from '../../EventFilter';
import ReportFilter from './ReportFilter';
import Search from '../../Search';
import theme from '../styled-components';
import { ReportsActionItemContainer, ReportsActionItem } from './styled-components';
import { COLORS, HEIGHTS, ICONS } from '../../../../utils/styles';
import Icon from '../../Icon';
import { REPORT_TYPES, SPORT_TYPES, CORE_ROLES } from '../../../../constants';
import { generateRefreshQuery } from '../../../../utils/helpers';

const REPORT_TYPE_OPTS_ADMIN = [
  { name: 'Incident', value: 'incident' },
  { name: 'Game Report', value: 'game' },
  { name: 'Assessment', value: 'assessment' }
];

const REPORT_TYPE_OPTS_ADMIN_ASSIGNOR_WITHOUT_INCIDENT_SETTING = [
  { name: 'Game Report', value: 'game' },
  { name: 'Assessment', value: 'assessment' }
];

const REPORT_TYPE_OPTS_ADMIN_ASSIGNOR_WITHOUT_ASSESSMENT_SETTING = [
  { name: 'Incident', value: 'incident' },
  { name: 'Game Report', value: 'game' }
];

const REPORT_TYPE_OPTS_ASSESSOR = [{ name: 'Assessment', value: 'assessment' }];

const GAME_REPORT_TYPE = [{ name: 'Game Report', value: 'game' }];

const Header = ({
  storeReportsEvent,
  reportsEvent,
  config,
  conditions,
  filter,
  setReportType,
  reportType,
  search: { onSearch, value },
  pageFilter,
  isReportsEventGroupAdmin,
  isReportsEventAdmin,
  location,
  clearParams,
  getReportsEventSport,
  isReportsEventAssignor,
  reportSettings,
  eventsAssessmentSettings
}) => {
  let options;
  if (
    (isReportsEventAdmin || isReportsEventGroupAdmin || isReportsEventAssignor) &&
    reportSettings &&
    reportSettings.allow_officials_to_submit &&
    eventsAssessmentSettings &&
    eventsAssessmentSettings.allow_assessors_to_submit
  ) {
    options = REPORT_TYPE_OPTS_ADMIN;
  } else if (
    (isReportsEventAdmin || isReportsEventGroupAdmin || isReportsEventAssignor) &&
    !(reportSettings && reportSettings.allow_officials_to_submit) &&
    eventsAssessmentSettings &&
    eventsAssessmentSettings.allow_assessors_to_submit
  ) {
    options = REPORT_TYPE_OPTS_ADMIN_ASSIGNOR_WITHOUT_INCIDENT_SETTING;
  } else if (
    (isReportsEventAdmin || isReportsEventGroupAdmin || isReportsEventAssignor) &&
    reportSettings &&
    reportSettings.allow_officials_to_submit &&
    !(eventsAssessmentSettings && eventsAssessmentSettings.allow_assessors_to_submit)
  ) {
    options = REPORT_TYPE_OPTS_ADMIN_ASSIGNOR_WITHOUT_ASSESSMENT_SETTING;
  } else if (eventsAssessmentSettings && eventsAssessmentSettings.allow_assessors_to_submit) {
    options = REPORT_TYPE_OPTS_ASSESSOR;
  } else {
    options =
      isReportsEventAdmin || isReportsEventGroupAdmin || isReportsEventAssignor
        ? GAME_REPORT_TYPE
        : [];
  }
  let filteredReportType = reportType;
  if (
    reportType === REPORT_TYPES.game &&
    (getReportsEventSport === SPORT_TYPES.futsal || getReportsEventSport === SPORT_TYPES.soccer)
  ) {
    filteredReportType = 'gameSoccerOrFutsal';
  }

  return (
    <Flex
      bgImage="linear-gradient(to top, #05579d, #167cd4)"
      height={HEIGHTS.eventNavigationBar}
      justifyContent="space-between"
      padding="0 1.5rem 0 0"
      fullWidth
      noShrink
    >
      <Flex>
        <EventFilter
          onSelectEvent={storeReportsEvent}
          clearParams={clearParams}
          selectedEvent={reportsEvent}
          filters={{ role_id: [CORE_ROLES.admin, CORE_ROLES.assignor, CORE_ROLES.assessor] }}
        />
        <Flex theme={theme.dropdownContainer} height={HEIGHTS.eventNavigationBar}>
          <Dropdown
            displayValue
            theme={theme.dropdown}
            options={options}
            input={{
              value: reportType,
              onChange: val => {
                if (
                  val === REPORT_TYPES.game &&
                  (getReportsEventSport === SPORT_TYPES.futsal ||
                    getReportsEventSport === SPORT_TYPES.soccer) &&
                  reportSettings &&
                  reportSettings.score_entry_option === 'Score by Player'
                ) {
                  pageFilter.onPageFilterChange(val, true);
                } else {
                  pageFilter.onPageFilterChange(val);
                }

                return setReportType(val);
              }
            }}
          />
        </Flex>
        <Flex theme={theme.dropdownContainer} height={HEIGHTS.eventNavigationBar}>
          <ReportFilter reportType={filteredReportType} {...filter} />
        </Flex>
      </Flex>
      <ReportsActionItemContainer justifyContent="space-between">
        <ReportsActionItem full maxWidth="120px">
          <Search
            iconHeight={14}
            iconWidth={14}
            iconColor={COLORS.white}
            fieldColor={COLORS.white}
            onSearch={onSearch}
            value={value}
            placeholder="Search Game ID"
          />
        </ReportsActionItem>
        <ReportsActionItem>
          <NavLink to={`${location.pathname}${generateRefreshQuery(location.search)}`}>
            <Icon height={18} width={18} icon={ICONS.REFRESH} color={COLORS.white} />
          </NavLink>
        </ReportsActionItem>
        {(isReportsEventAssignor || isReportsEventAdmin || isReportsEventGroupAdmin) && (
          <ReportsActionItem>
            <ActionDropdown theme={theme.action} config={config.action} conditions={conditions} />
          </ReportsActionItem>
        )}
      </ReportsActionItemContainer>
    </Flex>
  );
};

Header.propTypes = {
  events: PropTypes.arrayOf(Object),
  reportsEvent: PropTypes.shape({
    id: PropTypes.string
  }),
  storeReportsEvent: PropTypes.func.isRequired,
  config: PropTypes.shape({
    action: PropTypes.shape({
      options: PropTypes.arrayOf(Object)
    })
  }).isRequired,
  conditions: PropTypes.shape({
    reportsSelected: PropTypes.bool
  }),
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  reportType: PropTypes.string.isRequired,
  setReportType: PropTypes.func.isRequired,
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    searchValue: PropTypes.string
  }).isRequired,
  pageFilter: PropTypes.shape({
    onPageFilterChange: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  isReportsEventAdmin: PropTypes.bool
};

Header.defaultProps = {
  events: [],
  reportsEvent: null,
  conditions: [],
  isReportsEventAdmin: false
};

export default Header;
