import React from 'react';

import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Form } from 'react-final-form';
import Toggle from 'react-toggle';
import '../../toggle.css';
import arrayMutators from 'final-form-arrays';

import Field from '../../../../../../utils/hocs/final-form-field-adapter';
import LoaderWrapper from '../../../../LoaderWrapper';
import Flex from '../../../../Flex';
import Icon from '../../../../Icon';
import CollapseableHeader from '../../../../CollapseableHeader';
import Button from '../../../../Button';
import InfoField from '../../../../Reusable/InfoField';
import TextArea from '../../../../Reusable/TextArea';
import './styles.css';
import theme, { Header, Body, FieldWrapper, Label, SubHeader } from '../../styled-components';
import { COLORS, ICONS } from '../../../../../../utils/styles';
import generateGameInfo from '../config';
import { FROM_GAMES } from '../../../../../../core/Modals/constants';
import { mutatorGoals, getTeamStaffTemplate, getTeamRosterTemplate } from './utils';
import Summary from '../Summary';
import MaterialInput from '../../../../MaterialInput';

import Team from './TeamSection';
import Attachments from './Attachments';
import ImagePreview from './Attachments/ImagePreview';
import { addCountToDisplayId, generateUUID } from '../../../../../../utils/helpers';

const GameReport = ({
  onSubmit,
  onEdit,
  onBack,
  onClose,
  game,
  report,
  inEdit,
  setInEdit,
  onApprove,
  isGamesEventAdmin,
  isReportsEventGroupAdmin,
  isGamesEventAssignor,
  isReportsEventAdmin,
  isFetching,
  reportSettings,
  from,
  eventSportId,
  gamesEventId,
  isDisabled,
  setIsdisabled,
  gameReport,
  isGamesEventOfficial,
  isLocked
}) => {
  const editableReport = gameReport
    ? {
        home_score: gameReport.home_team.score,
        home_cautions: gameReport.home_team.cautions,
        home_send_off: gameReport.home_team.send_off,
        away_score: gameReport.away_team.score,
        away_cautions: gameReport.away_team.cautions,
        away_send_off: gameReport.away_team.send_off,
        game_summary_notes: gameReport.game_summary_notes,
        home_team: gameReport.home_team.team_staffs.map(team => ({
          ...team,
          uid: generateUUID()
        })),
        home_roster: gameReport.home_team.team_rosters.map(roster => ({
          ...roster,
          uid: generateUUID()
        })),
        away_team: gameReport.away_team.team_staffs.map(team => ({
          ...team,
          uid: generateUUID()
        })),
        away_roster: gameReport.away_team.team_rosters.map(roster => ({
          ...roster,
          uid: generateUUID()
        })),
        id: gameReport.id,
        approved: gameReport.approved,
        home_forfeit: gameReport.home_team.is_forfeit || false,
        away_forfeit: gameReport.away_team.is_forfeit || false,
        attachments: gameReport.attachments
      }
    : {};
  const disabled = isDisabled;

  const byPlayer = reportSettings && reportSettings.score_entry_option === 'Score by Player';
  const isSoccer = eventSportId === 5 || eventSportId === 9;

  const showEditIcon =
    isReportsEventGroupAdmin ||
    isGamesEventAdmin ||
    isGamesEventAssignor ||
    (isGamesEventOfficial && !isLocked && report && !report.approved);
  const showLockIcon = isGamesEventOfficial && isLocked && report && !report.approved;

  const display_id = addCountToDisplayId(game);
  return (
    <LoaderWrapper isFetching={game.isFetching} simple>
      <Body>
        {report && (
          <Flex justifyContent="space-between" padding="0 0 0.5rem">
            <Flex>
              {isReportsEventGroupAdmin ||
              isGamesEventAdmin ||
              isGamesEventAssignor ||
              isReportsEventAdmin ? (
                <>
                  <Label color={report.approved ? COLORS.forestGreen : COLORS.hawkesBlue}>
                    Approved
                  </Label>
                  <Toggle
                    checked={report.approved}
                    icons={false}
                    onChange={() => !isFetching && onApprove(report, from)}
                  />
                </>
              ) : (
                report.approved && <Label color={COLORS.forestGreen}>Approved</Label>
              )}
            </Flex>
            {showEditIcon && (
              <Icon
                width={22}
                height={18}
                icon={ICONS.EDIT}
                color={COLORS.denimBlue}
                clickable
                onClick={() => {
                  setInEdit(s => !s);
                  setIsdisabled(s => !s);
                }}
              />
            )}
            {showLockIcon && (
              <Icon width={22} height={18} icon={ICONS.LOCK} color={COLORS.hawkesBlue} />
            )}
          </Flex>
        )}
        <CollapseableHeader label="Game Details">
          {game && (
            <Row style={{ marginBottom: '0.75rem' }}>
              {generateGameInfo({ game, display_id }).map(info => (
                <Col key={info.label} xs={12} md={info.width || 6}>
                  <FieldWrapper verticalMargins="0.5rem">
                    <InfoField {...info} />
                  </FieldWrapper>
                </Col>
              ))}
            </Row>
          )}
        </CollapseableHeader>
        <LoaderWrapper isFetching={game.isFetching} simple>
          <Form
            onSubmit={inEdit ? values => onEdit({ values, editableReport, from }) : onSubmit}
            initialValues={
              inEdit || isDisabled
                ? {
                    ...editableReport,
                    flag: false,
                    previewSrc: null
                  }
                : {
                    event_id: gamesEventId,
                    external_event_id: game.display_id,
                    home_team: [
                      { ...getTeamStaffTemplate(byPlayer, isSoccer), index: 0, uid: generateUUID() }
                    ],
                    away_team: [
                      { ...getTeamStaffTemplate(byPlayer, isSoccer), index: 0, uid: generateUUID() }
                    ],
                    home_roster: [
                      {
                        ...getTeamRosterTemplate(byPlayer),
                        index: 0,
                        uid: generateUUID(),
                        goals: []
                      }
                    ],
                    away_roster: [
                      {
                        ...getTeamRosterTemplate(byPlayer),
                        index: 0,
                        uid: generateUUID(),
                        goals: []
                      }
                    ],
                    home_forfeit: false,
                    away_forfeit: false,
                    previewSrc: null
                  }
            }
            mutators={{
              ...arrayMutators,
              mutateGoals: mutatorGoals
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push, update, mutateGoals }
              },
              invalid,
              values,
              pristine
            }) => {
              const showSummary =
                reportSettings &&
                (!byPlayer || reportSettings.team_staff_section || reportSettings.roster_section);
              return (
                <>
                  {showSummary && (
                    <>
                      <div className="margin-bottom">
                        <Header>Summary</Header>
                        <div className="summaryWrapper">
                          <Row>
                            <Summary
                              teamName={game.team_home.name}
                              label="home"
                              scoreOption={reportSettings.score_entry_option}
                              eventSportId={eventSportId}
                              disabled={disabled}
                              values={values}
                              teamRoster={values.home_roster}
                              heading="HOME"
                            />
                            <Summary
                              teamName={game.team_visitor.name}
                              label="away"
                              scoreOption={reportSettings.score_entry_option}
                              eventSportId={eventSportId}
                              disabled={disabled}
                              values={values}
                              teamRoster={values.away_roster}
                              heading="AWAY"
                            />
                          </Row>
                        </div>
                      </div>
                    </>
                  )}
                  <Team
                    type="home"
                    reportSettings={reportSettings}
                    theme={theme}
                    push={push}
                    update={update}
                    disabled={disabled}
                    values={values}
                    game={game}
                    byPlayer={byPlayer}
                    isSoccer={isSoccer}
                    eventSportId={eventSportId}
                    mutateGoals={mutateGoals}
                  />
                  <Team
                    type="away"
                    reportSettings={reportSettings}
                    theme={theme}
                    push={push}
                    update={update}
                    values={values}
                    byPlayer={byPlayer}
                    isSoccer={isSoccer}
                    game={game}
                    disabled={disabled}
                    eventSportId={eventSportId}
                    mutateGoals={mutateGoals}
                  />
                  {reportSettings &&
                    (reportSettings.game_summary_notes || reportSettings.image_attachments) && (
                      <CollapseableHeader label="GAME NOTES & ATTACHMENTS">
                        <>
                          {reportSettings.game_summary_notes && (
                            <>
                              <SubHeader color={COLORS.sapphire}>GAME NOTES</SubHeader>
                              <div style={{ paddingLeft: '2rem' }}>
                                <Field
                                  name="game_summary_notes"
                                  component={TextArea}
                                  placeholder="Game Summary Notes"
                                  rows={6}
                                  width={12}
                                  disabled={disabled}
                                  resize="vertical"
                                  theme={theme.textArea}
                                />
                              </div>
                            </>
                          )}
                          {reportSettings.image_attachments && (
                            <Attachments values={values} disabled={disabled} />
                          )}

                          {values.previewSrc && <ImagePreview values={values} />}

                          {/** hidden form field */}
                          <div className="visible-none">
                            <Field name="dummy" component={MaterialInput} />
                          </div>
                        </>
                      </CollapseableHeader>
                    )}
                  <Row center="xs">
                    <Button
                      width="84px"
                      height="32px"
                      margin="0 8px 0"
                      label="Cancel"
                      onClick={
                        from === FROM_GAMES
                          ? onBack
                          : () => {
                              onClose(from);
                            }
                      }
                    />
                    {reportSettings &&
                      (!byPlayer ||
                        reportSettings.team_staff_section ||
                        reportSettings.roster_section) && (
                        <Button
                          type="submit"
                          width="84px"
                          height="32px"
                          margin="0 8px 0"
                          label={inEdit ? 'Save' : 'Submit'}
                          disabled={invalid || pristine}
                          onClick={handleSubmit}
                          primary
                        />
                      )}
                  </Row>
                </>
              );
            }}
          />
        </LoaderWrapper>
      </Body>
    </LoaderWrapper>
  );
};
GameReport.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  game: PropTypes.shape({
    id: PropTypes.string
  }),
  report: PropTypes.shape({
    id: PropTypes.string
  }),
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  inEdit: PropTypes.bool,
  setInEdit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isGamesEventAdmin: PropTypes.bool,
  isReportsEventAdmin: PropTypes.bool,
  isGamesEventOfficial: PropTypes.bool,
  isGamesEventAssignor: PropTypes.bool,
  isFetching: PropTypes.bool,
  from: PropTypes.string
};

GameReport.defaultProps = {
  game: null,
  report: null,
  inEdit: false,
  isGamesEventAdmin: false,
  isReportsEventAdmin: false,
  isGamesEventOfficial: false,
  isGamesEventAssignor: false,
  isFetching: false,
  from: FROM_GAMES
};

export default GameReport;
