import { compose, withHandlers, lifecycle } from 'recompose';

import MODAL_NAMES from '../../../core/Modals/constants';

export default compose(
  withHandlers({
    onSubmit: ({
      createCrewVerificationReport,
      setShowModalFor,
      game: { id, display_id, game_level }
    }) => values => {
      createCrewVerificationReport({
        id,
        data: {
          values,
          event_id: game_level.event_id,
          external_event_id: display_id,
          game_level_id: game_level.id
        }
      });
      setShowModalFor({ name: MODAL_NAMES.crewVerificationReportsModal, from: 'games' });
    },

    onClose: ({ setShowModalFor }) => from =>
      setShowModalFor({ name: MODAL_NAMES.crewVerificationReportsModal, from })
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.game && this.props.game.id) {
        const filters = {};
        if (
          !(
            this.props.isGamesEventGroupAdmin ||
            this.props.isGamesEventAdminOrAssignor ||
            this.props.isReportsEventAdmin ||
            this.props.isReportsEventAssignor ||
            (this.props.reportSettings &&
              this.props.reportSettings.allow_officials_to_checkin &&
              this.props.reportSettings.official_to_official_viewability)
          )
        )
          filters.user_id = this.props.currentUserId;
        this.props.fetchCurrentCrewVerificationReports({ filters });
      }
    },
    componentDidUpdate(prevProps) {
      if (!prevProps.game.id && this.props.game) {
        const filters = {};
        if (
          !(
            this.props.isGamesEventGroupAdmin ||
            this.props.isGamesEventAdminOrAssignor ||
            this.props.isReportsEventAdmin ||
            this.props.isReportsEventAssignor ||
            (this.props.reportSettings &&
              this.props.reportSettings.official_to_official_viewability)
          )
        )
          filters.user_id = this.props.currentUserId;
        this.props.fetchCurrentCrewVerificationReports({ filters });
      }
    }
  })
);
