import styled from 'styled-components';

import Icon from '../Icon';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ColumnedContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ActualContentContainer = styled.div`
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
`;

export const ActualContentContainerCrew = styled.div`
  align-self: center;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  border: 1px solid #d8d8d8;
`;

export const EventTitleWrapper = styled.div`
  min-height: 3rem;
  max-height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.white};
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.47rem;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
`;

export const EventInfo = styled.div``;

export const ActionButtons = styled.div`
  display: flex;
  margin-right: ${pxToRem('35px')};
`;

export const IconsContainer = styled.div`
  margin-left: ${pxToRem('16px')};
`;

export const ActionIcon = styled(Icon)`
  margin-top: ${pxToRem('2px')};
  cursor: pointer;

  &.hidden {
    visibility: hidden;
  }

  &.disabled {
    cursor: auto;
  }
`;

export const EventName = styled.span`
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.denimBlue};
  height: 100%;
  line-height: 3rem;
  padding-right: 1rem;
`;

export const EventId = styled.span`
  height: 100%;
  font-family: 'RobotoCondensed';
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.mineShaft};
  padding-right: 1rem;
`;

export const EventDate = styled.span`
  font-family: 'RobotoCondensed';
  font-size: 0.6rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 3rem;
  letter-spacing: -0.3px;
  text-align: left;
  color: ${COLORS.shuttleGray};
`;

export const InstructionContainer = styled.div`
  align-self: center;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  box-sizing: border-box;
  margin-bottom: -2rem;
`;

export const H4 = styled.h4``;

export const Span = styled.span``;
