import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Flex from '../../Flex';
import Icon from '../../Icon';

export default {
  action: {
    icon: {
      color: COLORS.white,
      marginTop: '0px',
      marginRight: '0px'
    },
    dropdown: {
      topOffset: '34px',
      rightOffset: '-9px',
      width: '140px'
    },
    option: {
      background: COLORS.denimBlue,
      color: COLORS.white,
      hoverBackground: COLORS.darkCerulean,
      hoverColor: COLORS.white
    },
    optionDisabled: {
      background: COLORS.denimBlue,
      color: COLORS.strongCyan,
      hoverBackground: COLORS.denimBlue,
      hoverColor: COLORS.strongCyan
    }
  },
  dropdown: {
    wrapper: `
      width: 170px;
      height: 100%;
      background: transparent;
    `,
    header: `
      background: transparent;
      color: ${COLORS.white};
      box-shadow: none;
      font-size: ${pxToRem('13px')};

      &.hasValue {
        box-shadow: none;
      }
    `,
    chevron: {
      color: COLORS.white
    },
    option: `
      background-color: ${COLORS.bahamaBlue};
      color: ${COLORS.white};

      &:hover {
        background-color: ${COLORS.sapphire};
      }
    `,
    options: `
      top: ${pxToRem('40px')};

      &::-webkit-scrollbar {
        background-color: ${COLORS.bahamaBlue};
      }

      &::-webkit-scrollbar-thumb {
        background: ${COLORS.sapphire};
      }
    `,
    value: 'padding-left: 0.5rem;',
    minorLabel: 'top: -2.25rem'
  },
  dropdownContainer: `border-right: 1px solid ${COLORS.denimBlue}`
};

export const GamesActionItemContainer = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
`;

export const GamesActionItem = styled.div`
  display: flex;
  width: auto;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: ${pxToRem('20px')};
    ${`@media (max-width: 350px) {
      margin-right: ${pxToRem('0px')};
    }`}
  }
`;

export const RefreshButton = styled.button`
  outline: none;
  border: 0px;
  background: transparent;
  cursor: pointer;
`;

export const StatusIconContainer = styled.div`
  width: ${pxToRem('16px')};
  height: ${({ height }) => height || pxToRem('16px')};
  overflow: visible;
  border-radius: 50%;
  padding-right: ${({ paddingRight }) => paddingRight || pxToRem('10px')};
  margin: 1px 0px 0px 0px;
`;

export const StatusIcon = styled(Icon)`
  margin: 0;
  position: relative;
  top: ${pxToRem('-3px')};
  right: ${pxToRem('0px')};
  color: ${COLORS.white};
`;
