import React from 'react';
import { Field } from 'react-final-form';
import CollapsibleContent from '../../../CollapsableContent';
import RoundedCheckBox from '../../../RoundedCheckBox';
import { pxToRem } from '../../../../../utils/helpers';
import { COLORS } from '../../../../../utils/styles';
import {
  Content,
  OptionContainer,
  Option,
  AcceptGameOption,
  InnerWrapper,
  InputOuterWrapper,
  InputWrapper,
  Description
} from './styled-components';
import baseCollapsibleTheme from '../config';
import { OptionLabel } from '../NotificationCollapsible/styled-components';
import { NumericField } from '../NotificationCollapsible';
import { valueInRange } from '../../../../../utils/validations';

const acceptDeclineCollapsibleTheme = {
  ...baseCollapsibleTheme,
  headerMinPaddingTop: `${pxToRem('32px')}`
};

const AcceptDeclineCollapsible = ({ disabled }) => (
  <CollapsibleContent
    title="ACCEPT/DECLINE GAMES"
    theme={acceptDeclineCollapsibleTheme}
    externalIsOpen
  >
    <Content>
      <OptionContainer>
        <AcceptGameOption textColor={COLORS.shuttleGray}>
          <InnerWrapper>
            <Option>
              <Field name="require_games_accept_setting.checked" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>Require user to accept within:</OptionLabel>
            </Option>
            <InputOuterWrapper>
              <InputWrapper>
                <NumericField
                  validate={valueInRange({ min: 0, max: 30 })}
                  min={0}
                  max={30}
                  name="require_games_accept_setting.days_after_assignment"
                  disabled={disabled}
                  label="days after assignment or they will be auto-declined"
                />
              </InputWrapper>
              <InputWrapper>
                <NumericField
                  validate={valueInRange({ min: 0, max: 30 })}
                  min={0}
                  max={30}
                  name="require_games_accept_setting.days_before_game"
                  disabled={disabled}
                  label="days before game date or they will be auto-declined"
                />
              </InputWrapper>
              <Description>
                Rules will not apply if user is assigned less than the day value listed above
              </Description>
            </InputOuterWrapper>
          </InnerWrapper>
        </AcceptGameOption>
        <Option textColor={COLORS.shuttleGray}>
          <Field name="disable_games_decline_setting.checked" disabled={disabled}>
            {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
          </Field>
          <OptionLabel color={COLORS.shuttleGray}>Cannot decline after accepting </OptionLabel>
          <NumericField
            validate={valueInRange({ min: 0, max: 30 })}
            min={0}
            max={30}
            name="disable_games_decline_setting.days_before_game"
            disabled={disabled}
            label="days before game date"
          />
        </Option>
      </OptionContainer>
    </Content>
  </CollapsibleContent>
);

export default AcceptDeclineCollapsible;
