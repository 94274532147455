import styled from 'styled-components';
import { COLORS } from '../../../utils/styles';

export const Round = styled.div`
  position: relative;
  display: inline-block;
  justify-content: center;
  color: ${COLORS.greenVogue};
  margin: 0.5rem;
`;

export const CustomRound = styled.div`
  position: relative;
  display: inline-block;
  justify-content: start;
  color: ${COLORS.cobaltGrey}
  display: flex;
  align-items: center;
  font-size:0.7rem;
`;

export const Label = styled.label`
background-color:${({ disabled, selected }) =>
  disabled ? COLORS.disabled : selected ? COLORS.cerulean : COLORS.white};
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: ${({ locked, disabled }) => (locked && disabled ? 'default' : 'pointer')};
  height: ${({ heightLabel }) => heightLabel || '1.02rem '};
  position: absolute;
  width:  ${({ widthLabel }) => widthLabel || '1rem'};
  left: 0;
  &:after {
    border: ${({ selected }) =>
      selected ? `2px solid ${COLORS.denimBlue}` : `2px solid ${COLORS.white}`};
    border-top: none;
    border-right: none;
    content: '';
    height: ${({ height }) => height || '0.375rem '}
    left:  ${({ left }) => left || '0.125rem '}
        opacity: 0;
    position: absolute;
    top: 0.093rem;
    transform: rotate(-50deg);
    width: ${({ width }) => width || '0.75rem'}
  }
`;

export const InlineLabel = styled.label`
  color: ${COLORS.shuttleGrey};
`;

export const CheckBox = styled.input`
  visibility: hidden;
  &:checked + ${Label} {
    background-color: ${({ disabled, selected }) =>
      disabled ? COLORS.disabled : selected ? COLORS.white : COLORS.cerulean};
    border-color: ${({ disabled, selected }) =>
      disabled ? COLORS.disabled : selected ? COLORS.white : COLORS.cerulean};
  }

  &:checked + ${Label}:after {
    opacity: 1;
  }
`;
