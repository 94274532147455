import moment from 'moment';

export const fetchCategory = (
  categories,
  gameState,
  gamePostalCode,
  gameTeamHome,
  gameTeamVisitor,
  gameLocation
) =>
  categories.some(
    category =>
      (category.states && category.states.includes(gameState.toUpperCase())) ||
      (category.postal_codes && category.postal_codes.includes(gamePostalCode.toUpperCase())) ||
      (category.teams && category.teams.includes(gameTeamHome)) ||
      (category.teams && category.teams.includes(gameTeamVisitor)) ||
      (category.locations && category.locations.includes(gameLocation))
  );

export const isMaxGamePerDayExceeded = (maxGamePerDay, startDate, gameAssignments) => {
  const gameDate = moment.utc(startDate).format('YYYY-MM-DD');
  const gameDateAssignmentCount = gameAssignments.reduce((acc, gameAssignment) => {
    const assignmentGameDate =
      gameAssignment.game &&
      gameAssignment.game.start_at &&
      moment.utc(gameAssignment.game.start_at).format('YYYY-MM-DD');
    if (assignmentGameDate === gameDate) {
      return acc + 1;
    }
    return acc;
  }, 0);
  return gameDateAssignmentCount >= maxGamePerDay;
};

export const isMaxGamePer24hExceeded = (maxGamePer24h, startDate, startTime, gameAssignments) => {
  const time24hBeforeGame = moment.utc(`${startDate} ${startTime}`).subtract(1, 'days');
  const time24hAfterGame = moment.utc(`${startDate} ${startTime}`).add(1, 'days');
  const timeDiffs = gameAssignments.reduce(
    (acc, assignment) => {
      const assignmentGameDate =
        assignment.game && assignment.game.start_at && moment(assignment.game.start_at);
      if (assignmentGameDate >= time24hBeforeGame && assignmentGameDate <= time24hAfterGame) {
        const duration = moment.duration(assignmentGameDate.diff(time24hBeforeGame));
        if (duration !== 24) acc.push(duration.asHours());
      }
      return acc;
    },
    [24]
  );

  timeDiffs.sort();

  if (timeDiffs.length <= maxGamePer24h) return false;

  let timeWindowStart = 0;
  let timeWindowEnd = 0;

  while (timeWindowEnd < timeDiffs.length) {
    if (timeWindowStart === timeWindowEnd) {
      timeWindowEnd += 1;
    }
    if (timeDiffs[timeWindowEnd] - timeDiffs[timeWindowStart] > 24) {
      timeWindowStart += 1;
    } else {
      if (timeWindowEnd - timeWindowStart + 1 > maxGamePer24h) return true;
      timeWindowEnd += 1;
    }
  }
  return false;
};

/**
 * Takes user (containing all assignments), game and assignment statuses.
 * And returns True if user's assignment have same date & time conflicts with the provided game
 * @property {Object} user
 * @property {Object} game current game details
 * @property {Array} assignmentStatuses to be included
 * @property {Array} gameStatuses the game statuses which should not be included
 *
 * @returns {Boolean} True|False
 */
export const hasSameTimeConflict = ({
  user: { assignments = [] },
  game: { start, end, id, status },
  assignmentStatuses = [],
  gameStatuses = []
}) => {
  const conflictedAssignments = assignments.filter(ga => {
    let filter = false;

    if (id === (ga && ga.external_game_id)) return filter;

    const startDate = ga && ga.game && ga.game.start_at && moment.utc(ga.game.start_at);
    const endDate = ga && ga.game && ga.game.end_at && moment.utc(ga.game.end_at);

    if (
      !gameStatuses.includes(status) &&
      assignmentStatuses.includes(ga.status) &&
      (startDate.isBetween(start, end, null, '[)') ||
        endDate.isBetween(start, end, null, '(]') ||
        start.isBetween(startDate, endDate, null, '[)') ||
        end.isBetween(startDate, endDate, null, '(]'))
    ) {
      filter = true;
    }

    return filter;
  });

  return !!conflictedAssignments.length;
};

/**
 * Generates assignment errors for provided error types with optional name configuration
 * @param {String} name selected user's name
 * @param {Array} errorTypes array of error type objects with message if error exists and includeName option
 */
export const generateErrors = (name, errorTypes) =>
  errorTypes.reduce(
    (errors, { message, includeName, gameData, ignoreRule }) =>
      message
        ? [
            ...errors,
            {
              message,
              name: includeName ? name : null,
              gameData: gameData || null,
              ignoreRule: ignoreRule || false
            }
          ]
        : errors,
    []
  );
