import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import {
  Block,
  Content,
  ButtonsContainer,
  ButtonContainer,
  HighlightedBlock,
  Summary,
  Count
} from '../styled-components';
import { COLORS } from '../../../../../utils/styles';
import theme from './styled-components';
import Dropdown from '../../../Reusable/Dropdown';
import Flex from '../../../Flex';
import STATUSES from '../../constants';

const GameStatusChangeModal = ({ onClose, selected, onSubmit, gameStatus, handleGameStatus }) => {
  return (
    <Modal title="Change Game Status" onClose={onClose} leftPanel>
      <>
        <Row>
          <HighlightedBlock xs={12}>
            <Content>
              <Summary>
                <Count color={COLORS.denimBlue}>{selected.length}</Count> Games Selected
              </Summary>
            </Content>
          </HighlightedBlock>
        </Row>
        <Row>
          <Block xs={10} xsOffset={1}>
            <Content first light>
              Select a game status from the dropdown below to apply to all selected games
            </Content>
            <Content>
              <div style={{ height: '140px' }}>
                <Flex theme={theme.dropdownContainer} height="48px">
                  <Dropdown
                    options={STATUSES.filter(statusObj => {
                      return statusObj.value !== 'paid';
                    })}
                    input={{ value: gameStatus, onChange: handleGameStatus }}
                    theme={theme.dropdown}
                    placeholder="Status"
                    displayValue
                  />
                </Flex>
              </div>
              <Block xs={12} className="last">
                <ButtonsContainer
                  style={{
                    position: 'relative',
                    top: '-70px'
                  }}
                >
                  <ButtonContainer>
                    <Button
                      label="Update"
                      onClick={() => {
                        onClose();
                        onSubmit(selected);
                      }}
                      width="72px"
                      height="32px"
                      primary
                      disabled={!gameStatus}
                    />
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
                  </ButtonContainer>
                </ButtonsContainer>
              </Block>
            </Content>
          </Block>
        </Row>
      </>
    </Modal>
  );
};

GameStatusChangeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  gameStatus: PropTypes.string.isRequired,
  handleGameStatus: PropTypes.func.isRequired
};

GameStatusChangeModal.defaultProps = {};

export default GameStatusChangeModal;
