import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';

export const FieldWrapper = styled.div`
  margin: 3rem auto;
`;

export const FormWrapper = styled(Row)`
  padding: 1rem 3rem 0rem 3rem;
`;

export const ButtonsWrapper = styled(Row)`
  padding: 1rem 0;
`;
