import styled from 'styled-components';
import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';

export const TableRow = styled.div`
  background: ${({ header, background }) =>
    background || (header ? COLORS.headerBlue : COLORS.white)};
  color: ${({ header }) => (header ? COLORS.darkBlue : COLORS.offBlack)};
  ${({ header }) => header && `text-transform: uppercase`};
  font-size: ${pxToRem('13px')};
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  padding: ${pxToRem('10px')};
  ${({ header }) => !header && `border-bottom: 1px solid ${COLORS.headerBlue}`};
  &:last-child {
    border-bottom: none;
  }
`;

export const TableFooter = styled.div`
  background: ${({ footer }) => (footer ? COLORS.lightGray : COLORS.white)};
  color: ${({ header }) => (header ? COLORS.darkBlue : COLORS.offBlack)};
  ${({ header }) => header && `text-transform: uppercase`};
  font-size: ${pxToRem('13px')};
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  padding: ${pxToRem('10px')};
  ${({ header }) => !header && `border-bottom: 1px solid ${COLORS.headerBlue}`};
  ${({ header }) => !header && `margin-right: ${pxToRem('-17px')}`};
  margin-right: 0;
  &:last-child {
    border-bottom: none;
  }
`;

export const TableContentContainer = styled.div`
  max-height: ${({ height }) => height || '25vh'};
  border: ${pxToRem('2px')} solid ${COLORS.headerBlue};
  overflow-x: hidden;
  &::-webkit-scrollbar {
    border-left: ${pxToRem('1px')} solid ${COLORS.headerBlue};
    background: ${COLORS.white};
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border: ${pxToRem('2px')} solid transparent;
    border-radius: ${pxToRem('9px')};
    background-clip: content-box;
  }
`;

export const TableText = styled.p`
  padding-left: ${({ paddingL }) => paddingL || '0'};
  margin: 0;
`;
