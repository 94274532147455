import React from 'react';
import PropTypes from 'prop-types';
import Sorter from '../../../Sorter';
import config from './config';

const PeopleSorter = ({ onSort, value }) => (
  <Sorter options={config.options} onSort={onSort} value={value || 'roles'} minWidth={160} />
);

PeopleSorter.propTypes = {
  onSort: PropTypes.func.isRequired,
  value: PropTypes.string
};

PeopleSorter.defaultProps = {
  value: ''
};
export default PeopleSorter;
