import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import pluralize from 'pluralize';
import { Box, Container } from '@material-ui/core';
import CollapsableContent from '../CollapsableContent';

import Button from '../Button';
import { COLORS } from '../../../utils/styles';
import { pxToRem } from '../../../utils/helpers';
import { ButtonContainer, StyledDiv, UserList } from './styled-components';
import Enhancer from '../../containers/CopyUsersToEvent';

import EventFilter from '../EventFilter';
import Dropdown from '../Reusable/Dropdown';
import UserItem from './UserItem';

const buildCollapsableTitle = userQuantity => `${userQuantity} ${pluralize('User', userQuantity)}`;

const buildCopyList = (userList, removeUser) =>
  userList.map(recipient => (
    <UserItem
      key={recipient.id}
      id={recipient.id}
      email={recipient.email}
      firstName={recipient.first_name}
      lastName={recipient.last_name}
      handleCloseIconClick={removeUser}
    />
  ));

const roles = [
  { name: 'Official', value: 5 },
  { name: 'Assessor', value: 4 }
];

const UserToEventCopier = ({
  userList,
  removeUser,
  inProgress,
  handleOnSubmit,
  onClose,
  allSelected,
  removeAllUsers,
  config,
  clearParams,
  onSelectEvent,
  selectedEvent,
  role,
  setSelectedRole,
  isSuperAdmin,
  events,
  sourceEventId,
  sourceRoleIds
}) => {
  const isUserAddAction = true;
  const finishSubmit = (userRole, eventId) => {
    handleOnSubmit({
      selectedRole: userRole,
      eventId,
      events,
      allSelected,
      sourceEventId,
      sourceRoleIds
    });
    onClose();
  };

  return (
    <Container>
      <Box display="grid" gridGap="10px">
        <CollapsableContent
          title={allSelected ? `All ${config.label}s` : buildCollapsableTitle(userList.length)}
          theme={{
            headerBg: COLORS.blackSqueeze,
            headerMinHeight: `${pxToRem('30px')}`,
            headerMinPaddingTop: `${pxToRem('0px')}`,
            iconColorWhenClosed: COLORS.shuttleGrey,
            iconColorWhenOpen: COLORS.denimBlue,
            iconHeight: 12,
            iconWidth: 12,
            titleColorWhenClosed: COLORS.denimBlue,
            titleColorWhenOpen: COLORS.denimBlue,
            titleFont: 'Roboto',
            titleLetterSpacing: 'normal',
            titleSize: `${pxToRem('13px')}`,
            titleTransform: 'none',
            titleWeight: 'bold'
          }}
        >
          {allSelected ? (
            <UserList>
              <userItem
                key={1}
                id={1}
                email={`All ${config.label}s`}
                firstName={`All ${config.label}s`}
                lastName=""
                handleCloseIconClick={removeAllUsers}
              />
            </UserList>
          ) : (
            <UserList>{buildCopyList(userList, removeUser)}</UserList>
          )}
        </CollapsableContent>

        <StyledDiv>
          <EventFilter
            placeholder={selectedEvent || 'Event'}
            isUserAddAction={isUserAddAction}
            onSelectEvent={onSelectEvent}
            clearParams={clearParams}
            selectedEvent={selectedEvent}
            isSuperAdmin={isSuperAdmin}
          />
        </StyledDiv>
        <StyledDiv>
          <Dropdown
            isUserAddAction={isUserAddAction}
            placeholder={roles.find(eventRole => eventRole.value === role)?.name || 'Role'}
            options={roles.map(eventRole => ({ value: eventRole.value, name: eventRole.name }))}
            input={{
              value: role,
              onChange: value => {
                setSelectedRole(value);
              }
            }}
          />
        </StyledDiv>
        <ButtonContainer>
          <Button
            label="Copy Users"
            onClick={() => {
              finishSubmit(events, role, selectedEvent ? selectedEvent.id : null);
            }}
            width="84px"
            height="32px"
            disabled={(!allSelected && isEmpty(userList)) || inProgress || !selectedEvent || !role}
            primary
          />
          <Button label="Cancel" width="84px" height="32px" onClick={onClose} />
        </ButtonContainer>
      </Box>
    </Container>
  );
};
UserToEventCopier.propTypes = {
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      state: PropTypes.string
    })
  ).isRequired,
  inProgress: PropTypes.bool.isRequired,
  removeUser: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

UserToEventCopier.defaultProps = {};

export default Enhancer(UserToEventCopier);
