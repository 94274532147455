import React from 'react';
import PropTypes from 'prop-types';
import { BatchTitle, NoBatchSubHeader } from './styled-components';
import { Flex } from '../MonthTiles/styled-components';
import Enhancer from '../../../containers/Payments/Funding';
import FundingResultDialog from './FundingDialog/FundingResultDialog';
import FailedSummaryDialog from './FailedSummaryDialog';
import FundingBatchList from './FundingBatchList';
import FundBatchDialog from './FundingDialog/FundBatchDialog';
import LoaderWrapper from '../../LoaderWrapper';
import { COLORS, ICONS } from '../../../../utils/styles';
import PaginationBar from '../../PaginationBar';

const Funding = ({
  isFetching,
  eventBatches,
  viewBatch,
  paymentMethods,
  addPaymentSource,
  processBatchFund,
  user,
  fetchPaymentToken,
  isProcessingPayment,
  fetchFundSummaryDetails,
  fetchBatchFailedHistory,
  fundSummaryDetails,
  batchFailedHistory,
  fetchAgreement,
  fetchBatchPaymentFee,
  batchPaymentFee,
  addNotifications,
  removeBatchPaymentError,
  isPaymentSupported,
  agreement,
  fetchPaymentMethods,
  downloadFinancialReport,
  pagination,
  paymentsEvent,
  userWallet
}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [fundDialogOpen, setFundDialogOpen] = React.useState(false);
  const [failedSummaryOpen, setFailedSummaryOpen] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [failedDialogOpen, setFailedDialogOpen] = React.useState(false);
  const [incompleteDialogOpen, setIncompleteDialogOpen] = React.useState(false);
  const [unprocessableDialogOpen, setUnprocessableDialogOpen] = React.useState(false);
  const [fundingError, setFundingError] = React.useState(null);
  const [currentBatch, setCurrentBatch] = React.useState(null);
  const [currentPaymentSource, setCurrentPaymentSource] = React.useState(null);

  const [currentPaymentSourceMeta, setCurrentPaymentSourceMeta] = React.useState({
    name: null,
    address: { line1: null, line2: null, city: null, state: null, country: 'US' }
  });
  const [values, setValues] = React.useState({
    type: 'bank_account',
    description: '',
    accountName: '',
    bankAccountNumber: '',
    confirmBankAccountNumber: '',
    routingNumber: '',
    confirmRoutingNumber: '',
    accountType: '',
    cardComplete: null,
    batchTotalError: '',
    nickName: ''
  });
  const [dialogDisplay, setDialogDisplay] = React.useState('');

  React.useEffect(() => {
    if (currentBatch) {
      const batch = eventBatches.find(bch => bch.id === currentBatch.id);
      if (batch && batch.processed) {
        setFundDialogOpen(false);
        setSuccessDialogOpen(true);
      }
      if (batch && batch.error) {
        setFundDialogOpen(false);
        if (batch.error.code === '400') {
          setUnprocessableDialogOpen(true);
          setFundingError(batch.error.message);
        } else if (batch.batch_payment && batch.batch_payment.status === 4) {
          setIncompleteDialogOpen(true);
        } else {
          setFailedDialogOpen(true);
        }

        removeBatchPaymentError({ id: batch.id });
      }
    }
    // eslint-disable-next-line
  }, [isProcessingPayment, currentBatch, eventBatches]);

  const paymentSources = paymentMethods
    ? paymentMethods.reduce((acc, method) => {
        if (!acc[method.method]) acc[method.method] = [];
        acc[method.method].push(method);
        return acc;
      }, {})
    : {};

  const setBatch = id => {
    const batch = eventBatches.find(bch => bch.id === id);
    setCurrentBatch(batch);
  };

  const handlePaymentSource = source => {
    setCurrentPaymentSource(source);
    if (source && currentBatch && currentBatch.id)
      fetchBatchPaymentFee({ batchId: currentBatch.id, paymentSourceId: source });
  };

  return (
    <LoaderWrapper isFetching={isFetching}>
      {' '}
      {eventBatches.length > 0 ? (
        <>
          <FundBatchDialog
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            values={values}
            setValues={setValues}
            isFetching={isFetching}
            addPaymentSource={addPaymentSource}
            processBatchFund={processBatchFund}
            user={user}
            fundSummaryDetails={fundSummaryDetails}
            batchPaymentFee={batchPaymentFee}
            addNotifications={addNotifications}
            setFundDialogOpen={setFundDialogOpen}
            fundDialogOpen={fundDialogOpen}
            currentBatch={currentBatch}
            paymentSources={paymentSources}
            currentPaymentSourceMeta={currentPaymentSourceMeta}
            setCurrentPaymentSourceMeta={setCurrentPaymentSourceMeta}
            handlePaymentSource={handlePaymentSource}
            currentPaymentSource={currentPaymentSource}
            isPaymentSupported={isPaymentSupported}
            agreement={agreement}
            setDialogDisplay={setDialogDisplay}
            dialogDisplay={dialogDisplay}
            userWallet={userWallet}
          />
          <FailedSummaryDialog
            currentBatch={currentBatch}
            failedSummaryOpen={failedSummaryOpen}
            setFailedSummaryOpen={setFailedSummaryOpen}
            batchFailedHistory={batchFailedHistory}
          />
          <FundingBatchList
            handlePaymentSource={handlePaymentSource}
            fetchBatchPaymentFee={fetchBatchPaymentFee}
            currentBatch={currentBatch}
            isProcessingPayment={isProcessingPayment}
            eventBatches={eventBatches}
            setFundDialogOpen={setFundDialogOpen}
            fundDialogOpen={fundDialogOpen}
            setBatch={setBatch}
            fetchFundSummaryDetails={fetchFundSummaryDetails}
            fetchBatchFailedHistory={fetchBatchFailedHistory}
            fetchAgreement={fetchAgreement}
            fetchPaymentToken={fetchPaymentToken}
            setCurrentPaymentSourceMeta={setCurrentPaymentSourceMeta}
            failedSummaryOpen={failedSummaryOpen}
            setFailedSummaryOpen={setFailedSummaryOpen}
            setValues={setValues}
            viewBatch={viewBatch}
            fetchPaymentMethods={fetchPaymentMethods}
            payer={user.payer}
            downloadFinancialReportSummary={downloadFinancialReport}
            paymentsEvent={paymentsEvent}
            setDialogDisplay={setDialogDisplay}
          />
          <FundingResultDialog
            DialogOpen={failedDialogOpen}
            setDialogOpen={setFailedDialogOpen}
            setCurrentBatch={setCurrentBatch}
            currentBatch={currentBatch}
            batchPaymentFee={batchPaymentFee}
            title="FAILED"
            textColor={COLORS.errorRed}
            icon={ICONS.CLOSE}
            description="Fund Request Failed"
          />
          <FundingResultDialog
            DialogOpen={successDialogOpen}
            setDialogOpen={setSuccessDialogOpen}
            setCurrentBatch={setCurrentBatch}
            currentBatch={currentBatch}
            batchPaymentFee={batchPaymentFee}
            title="SUCCESS"
            textColor={COLORS.forestGreen}
            icon={ICONS.CHECK_CIRCLE_HOLLOW}
            description="Fund Request Succeeded"
          />
          <FundingResultDialog
            DialogOpen={incompleteDialogOpen}
            setDialogOpen={setIncompleteDialogOpen}
            setCurrentBatch={setCurrentBatch}
            currentBatch={currentBatch}
            batchPaymentFee={batchPaymentFee}
            title="PENDING"
            textColor={COLORS.denimBlue}
            icon={ICONS.PENDING}
            description="Fund Request In Process"
          />
          <FundingResultDialog
            DialogOpen={unprocessableDialogOpen}
            setDialogOpen={setUnprocessableDialogOpen}
            setCurrentBatch={setCurrentBatch}
            currentBatch={currentBatch}
            batchPaymentFee={batchPaymentFee}
            title="PAYMENT CANNOT BE INITIATED"
            textColor={COLORS.errorRed}
            icon={ICONS.CLOSE}
            description={fundingError || ''}
          />
        </>
      ) : (
        <Flex margin="40px" direction="column">
          <BatchTitle>No Batches Found</BatchTitle>
          <NoBatchSubHeader margin="20px 0px 0px">
            It looks like no batches have been created yet.
          </NoBatchSubHeader>
          <NoBatchSubHeader>Please wait until a batch is created before funding.</NoBatchSubHeader>
        </Flex>
      )}
      <>{eventBatches && !!eventBatches.length && <PaginationBar {...pagination} />}</>
    </LoaderWrapper>
  );
};

Funding.propTypes = {
  eventBatches: PropTypes.arrayOf(Object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  viewBatch: PropTypes.func.isRequired
};

export default Enhancer(Funding);
