import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Icon from '../../Icon';

export const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 0.5rem;
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  position: relative;
`;

export const Block = styled(Col)`
  position: relative;
  margin: ${pxToRem('15px')} auto 0;
  padding: 0 ${pxToRem('20px')};
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  color: ${COLORS.shuttleGray};
  line-height: 1.31;

  &.last {
    margin-bottom: ${pxToRem('15px')};
  }
`;
export const InnerFieldContainer = styled.div`
  padding-left: ${pxToRem('7px')};
`;

export const TotalGoalScored = styled.span`
position: relative;
top ${pxToRem('10px')};
margin-left:${pxToRem('-31px')};;
`;

export const Content = styled.div`
  ${({ light }) => (light ? 'font-family: RobotoLight' : '')};
  ${({ first }) => (first ? `margin-bottom: ${pxToRem('15px')}` : '')};
`;

export const ButtonsContainer = styled.div`
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`;

export const TextContainer = styled.div`
  display: inline-block;
  padding: 0 ${pxToRem('2px')};
`;

export const InputLink = styled.a`
  cursor: pointer;
  color: ${COLORS.denimBlue};
  text-decoration: underline;
`;

export const InputSelect = styled.span`
  cursor: pointer;
  color: ${COLORS.denimBlue};
  text-decoration: underline;
`;

export const HighlightedBlock = styled(Block)`
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-around;
  padding: ${pxToRem('25px')} 0 ${pxToRem('20px')};
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
`;

export const ModalRow = styled.div`
  display: flex;
  flex: 1;
  ${({ highlighted }) =>
    highlighted
      ? `background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white})`
      : ''};
`;

export const Summary = styled.span`
  font-size: ${pxToRem('13px')};
  color: ${COLORS.cobaltGrey};
  margin: 0 ${({ noMargin }) => (noMargin ? 0 : pxToRem('12px'))};
`;

export const Count = styled.span`
  display: inline-block;
  font-size: ${pxToRem('24px')};
  font-family: 'RobotoBold';
  color: ${({ color }) => color};
  padding-right: ${pxToRem('5px')};
`;

export const ClickableLink = styled.span`
  font-size: ${pxToRem('13px')};
  font-family: 'Roboto';
  color: ${COLORS.denimBlue};
  text-decoration: underline;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  background-color: ${COLORS.pale_grey_two};
  align-items: center;
  height: ${pxToRem('32px')};
  padding-left: 0.5rem;
  margin: 0 0 0.75rem;
  text-transform: uppercase;
  color: ${COLORS.sapphire};
  font-size: ${pxToRem('15px')};
  font-family: 'RobotoBold';
`;

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  height: ${pxToRem('32px')};
  margin: 0 0 0.75rem;
  color: ${COLORS.sapphire};
  font-size: ${pxToRem('15px')};
  font-family: 'RobotoBold';
`;

export const SubHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 2.1rem;
  height: ${pxToRem('32px')};

  color: ${COLORS.sapphire};
  font-size: ${pxToRem('15px')};
  font-family: 'RobotoBold';
`;

export const StyledIcon = styled(Icon)`
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoLight';
  background: transparent;
  border: none;
  position: relative;
  outline-width: 0;
  &::placeholder {
    color: ${COLORS.white};
    text-transform: capitalize;
  }

  ${({ theme }) => theme || ''};
`;

export const ButtonLabel = styled.span`
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  text-align: right;
  color: ${({ color }) => color};
  padding-left: 0 ${pxToRem('5px')};
`;

export const Body = styled.div`
  padding: ${pxToRem('30px')};
`;

export const FieldWrapper = styled.div`
  margin: ${({ verticalMargins }) => verticalMargins || '1rem'} 1rem;
`;

export const Label = styled.span`
  font-family: 'Roboto';
  font-size: ${pxToRem('13px')};
  text-align: right;
  color: ${({ color }) => color};
  padding: 0 ${pxToRem('10px')};
`;

export default {
  dropdown: {
    wrapper: `
      background: transparent;
    `,
    header: `
      background: transparent;
      color: ${COLORS.nightRider};
      box-shadow: inset 0 -1px 0 0 ${COLORS.shadow}88;
    `,
    chevron: {
      color: COLORS.nightRider
    },
    option: `
      background-color: ${COLORS.white};
      color: ${COLORS.nightRider};
      font-size: ${pxToRem('13px')};

      &:hover {
        color: ${COLORS.denimBlue};
        background-color: ${COLORS.aliceBlueVariant};
      }
    `,
    options: `
      max-width: 100%;
      &::-webkit-scrollbar {
        background-color: ${COLORS.aliceBlueVariant};
      }

      &::-webkit-scrollbar-thumb {
        background: ${COLORS.shadow};
      }
    `,
    label: `
      color: ${COLORS.nightRider};
      font-size: ${pxToRem('13px')};
    `,
    minorLabel: `
      color: ${COLORS.nightRider};
      font-size: ${pxToRem('11px')};
    `,
    value: `
      color: ${COLORS.denimBlue};
      font-family: 'Roboto';
      font-size: ${pxToRem('13px')};
    `
  },
  input: {
    wrapper: `
      box-shadow: inset 0 -1px 0 0 ${COLORS.shadow}88;

      &.hasError {
        box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed};
      }
    `,
    input: `
      color: ${COLORS.denimBlue};
      font-family: 'Roboto';
      font-size: ${pxToRem('13px')};

      &::placeholder {
        font-family: 'RobotoLight';
        color: ${COLORS.nightRider};
      }
    `,
    label: `
      font-size: ${pxToRem('11px')};
      color: ${COLORS.nightRider};
    `
  },
  textArea: {}
};

export const DeclineNote = styled.textarea`
  width: 100%;
  font-size: 13px;
  height: 52px;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.gray};
  resize: none;
`;

export const NoteValidationError = styled.div`
  color: red;
  font-size: 10px;
`;

export const LineBreak = styled.br``;

export const NotificationContentWrapper = styled.div`
  text-align: initial;
  margin-left: 14px;
`;
