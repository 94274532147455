import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { PRODUCTS } from '../../../constants';

export default compose(
  withProps(({ groupSubscriptions, groupInfo }) => ({
    groupData: {
      ...groupInfo,
      gameSubscription: groupSubscriptions.find(sub => sub.product === PRODUCTS.PER_GAME_FEE),
      groupSubscription: groupSubscriptions.find(sub => sub.product === PRODUCTS.PLATFORM_FEE)
    }
  })),
  withHandlers({
    onSubmit: ({ updateGroupInfo, groupId, isSuperAdmin }) => ({
      note = '',
      gameFee,
      groupFee,
      subscriptionStatus,
      custId,
      netsuiteId,
      billingStartDate,
      invoiceRunDay,
      ...values
    }) => {
      const fee = {
        [PRODUCTS.PER_GAME_FEE]: Number(gameFee),
        [PRODUCTS.PLATFORM_FEE]: Number(groupFee)
      };
      const product = PRODUCTS.PER_GAME_FEE;

      updateGroupInfo({
        ...values,
        groupId,
        isSuperAdmin,
        note,
        fee,
        product,
        subscriptionStatus,
        custId,
        netsuiteId,
        billingStartDate,
        invoiceRunDay
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      const { groupId, fetchGroupInfo, fetchGroupBillingCustomers } = this.props;
      if (groupId) {
        fetchGroupInfo({ groupId });
        fetchGroupBillingCustomers({ platformId: groupId });
      }
    }
  })
);
