import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';

import { COLORS } from '../../../../utils/styles';
import { pxToRem } from '../../../../utils/helpers';
import Icon from '../../Icon';

export const Wrapper = styled(Row)`
  padding: 1.5rem;
  font-family: 'RobotoCondensed';
`;

export const FieldRow = styled(Row)`
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
`;

export const Block = styled(Col)`
  ${({ padding = 1 }) => !!padding && 'padding-left: 1rem;'};
  margin: 1.5rem 0;
`;

export const HelpText = styled.label`
  color: ${COLORS.shuttleGrey};
  font-size: 0.8rem;
  padding-left: 2px;
`;

export const Counter = HelpText.extend`
  margin-left: 0.25rem;
  color: ${COLORS.denimBlue};
  font-weight: bold;
`;

export const Label = styled.label`
  color: ${COLORS.denimBlue};
  font-size: 0.8rem;
  font-family: 'RobotoCondensed';
  cursor: pointer;
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`;

export const X = styled(Icon)`
  margin: 0;
`;

export const New = styled(Icon)`
  margin-right: 0.5rem;
`;

export const Criteria = styled.p`
  font-size: ${pxToRem('12px')};
  padding-left: 1rem;
`;

export const MultipleInputTheme = {
  wrapper: `
  width: 13%;
  margin-left: ${pxToRem('10px')};
  box-shadow: inset 0 -1px 0 0 #4d4d4d;
  &.hasValue {
      box-shadow: inset 0 -1px 0 0 #4d4d4d;;
    }
  &.hasError {
      box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed};
    }
  `,
  label: `
  color: ${COLORS.denimBlue};
  font-family: 'RobotoLight';
  visibility: initial;
  position: initial;
  top: 0;
  left: 0;
  margin:0;
  `,
  input: `color:#000;`,
  plusIconColor: ` ${COLORS.denimBlue}`,
  icon: `margin-top: 1.5rem;`
};
