import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Enhancer from '../../containers/Main';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import Header from '../Header';
import MainLayout from '../../layouts/MainLayout';
import Spinner from '../Spinner';
import { Wrapper, WaitingDiv } from './styled-components';
import { sInterpolator as routeBuilder, matchUrl } from '../../../utils/helpers';
import { userIdxGetter } from '../../../utils/auth';
import { config, isonBoardingComplete } from './config';
import { ON_BOARDING } from '../../../core/paths';

// Regex for path /u/:userId or /u/:userId/
const mainPagePathRegex = /\/u\/(\d+)((\/?$))/;

const Main = ({
  routes,
  isFetching,
  match,
  isProfileCompleted,
  officialAssessorAssignor,
  isPaymentSetUpComplete,
  isPaymentProfileRequired,
  isFetchingProfileMeta,
  isPaidThroughSO,
  ...props
}) => {
  if (isFetchingProfileMeta.profileMeta) {
    return <Spinner />;
  }

  if (mainPagePathRegex.test(window.location.pathname)) {
    if (
      !isonBoardingComplete(
        isProfileCompleted,
        officialAssessorAssignor,
        isPaymentProfileRequired,
        isPaymentSetUpComplete,
        isPaidThroughSO
      )
    ) {
      if (!matchUrl(ON_BOARDING, window.location.pathname)) {
        return <Redirect to={routeBuilder({ userIdx: userIdxGetter() }, ON_BOARDING)} />;
      }
    }
    const defaultPage = config(props);
    return (
      <Redirect
        to={routeBuilder(
          { userIdx: userIdxGetter(), userId: match && match.params.userId },
          defaultPage
        )}
      />
    );
  }
  const gamePage = window.location.href.includes('games');
  const batchEditPage = window.location.href.includes('payments/');
  return (
    <MainLayout>
      {/* dont add any div or component here */}
      <Wrapper style={gamePage ? { overflowY: 'hidden' } : {}}>
        {!window.location.pathname.includes('on-boarding') && <Header userRoles={props} />}
        {isFetching && <Spinner />}
        <WaitingDiv isFetching={isFetching} batchEditPage={batchEditPage}>
          {routes.map(({ path, ...route }) => (
            <RouteWithSubRoutes key={path} path={path} {...route} />
          ))}
        </WaitingDiv>
      </Wrapper>
    </MainLayout>
  );
};

Main.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default Enhancer(Main);
