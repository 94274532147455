import { put } from 'redux-saga/effects';
import { addNotifications } from '../Notifications';
import Logger from '../../utils/logger';

/**
 * Takes a Generator Function and wraps it in an inProgress clause.
 * On Error will add Notification
 * @param {Generator Function} iterable
 */
export const errorProgressDecorate = function* (iterable, params, setProgress) {
  try {
    if (setProgress) {
      yield put(setProgress(true));
    }
    yield* iterable(params);
  } catch (error) {
    let err = error;
    if (error.message) {
      err = error.message;
    }
    yield put(addNotifications(err));
  } finally {
    if (setProgress) {
      yield put(setProgress(false));
    }
  }
};

/**
 * Generator wrapper that catches errors and creates a log and notification
 * @param {Generator Function} iterable
 */
export const errorDecorate = function* (iterable, params) {
  try {
    yield* iterable(params);
  } catch (error) {
    let err = error;
    if (error.message) {
      err = error.message;
    }
    yield put(addNotifications(err));
    Logger.error(error.message);
  }
};

export default errorProgressDecorate;
