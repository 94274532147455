import { compose, withHandlers } from 'recompose';

export default compose(
  withHandlers({
    resolve: ({ fetchGroupInfo, groupId }) => () => {
      if (groupId) fetchGroupInfo({ groupId });
    }
  }),
  withHandlers({
    onSubmit: ({ createAccount, resolve, isGroupAdmin }) => payload => {
      createAccount({ values: { ...payload, isGroupAdmin }, resolve });
    }
  })
);
