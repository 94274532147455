import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/helpers';
import COLORS from '../../../../../utils/styles';

export const PositionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 0;
  justify-content: space-around;
`;

export const GameWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  position: relative;
  background-image: linear-gradient(to top, ${COLORS.blackSqueeze}, ${COLORS.white});
  border-bottom: 1px solid #e5ebf2;
  min-height: ${pxToRem('110px')};
  max-height: ${pxToRem('110px')};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const GamesListContainer = styled.div`
  font-size: ${pxToRem('13px')};
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: ${pxToRem('100px')};
`;
export default PositionsWrapper;
