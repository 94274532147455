import moment from 'moment';

import COLORS from '../../../utils/styles';

export default {
  billing: ({
    pastEventBillingStatements,
    dueDateEventBillingStatements,
    recentEventBillingStatements,
    isFetchingPastStatement,
    isFetchingDueDateStatement,
    isFetchingRecentStatement
  }) => [
    {
      invoiceInfo: pastEventBillingStatements,
      title: 'Past Due',
      isFetching: isFetchingPastStatement,
      iconName: 'error_icon',
      color: COLORS.lightRed
    },
    {
      invoiceInfo: dueDateEventBillingStatements,
      title: `Due by end of ${moment().format('MMMM YYYY')}`,
      isFetching: isFetchingDueDateStatement,
      iconName: 'watch_later',
      color: COLORS.grayishBlue
    },
    {
      invoiceInfo: recentEventBillingStatements,
      title: 'Recent Payments',
      isFetching: isFetchingRecentStatement,
      iconName: 'check_circle',
      color: COLORS.lightGreen
    }
  ],

  subscriptionStatus: {
    INACTIVE: {
      color: COLORS.greyish,
      bgColor: COLORS.blueGray,
      borderColor: COLORS.altoGray,
      iconBg: COLORS.greyish
    }
  }
};
