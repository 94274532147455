import styled from 'styled-components';
import { COLORS } from '../../../../../utils/styles';

export const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 auto;
  padding-bottom: 12px;
  padding-top: 14px;
`;

export const UserForm = styled.form`
  width: 90%;
  margin: 20px auto;
  & div {
    width: 100%;
    margin-bottom: 0px;
    text-transform: capitalize;
  }
  & Field {
    width: 100%;
  }
  & input {
    width: 100%;
    font-size: 13px;
  }
  & option {
    text-transform: capitalize;
  }
  & select {
    text-transform: capitalize;
    margin: 14px 0px;
  }
  & span {
    color: ${COLORS.errorRed};
    font-size: 13px;
    text-align: left;
    width: 100%;
    display: block;
  }
  & select {
    width: 100%;
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
    background: #fff;
    padding: 0px 10px;
    outline: 0px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
      no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 97%;
    background-size: 11px;
  }
`;

export const InputWrapper = styled.div``;
