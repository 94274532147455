import { compose, withHandlers, withState } from 'recompose';

export default compose(
  withState('userList', 'setUserList', ({ userList }) => userList),
  withState('subject', 'setSubject', ''),
  withState('body', 'setBody', ''),
  withState('inProgress', 'setInProgress', false),
  withState('sendSuccess', 'setSendSuccess', false),
  withHandlers({
    resolve: ({ setInProgress, setSendSuccess }) => result => {
      setInProgress(false);
      setSendSuccess(!!result);
    }
  }),
  withHandlers({
    removeRecipient: ({ userList, setUserList }) => recipientId =>
      setUserList(userList.filter(recipient => recipient.id !== recipientId)),
    removeAllRecipients: ({ setUserList, toggleSelectAll }) => () => {
      setUserList([]);
      toggleSelectAll();
    },
    handleOnSubmit: payload => () => {
      const { resolve, setInProgress, userList, subject, body, onSubmit, allSelected } = payload;

      setInProgress(true);

      const options =
        payload && payload.config && payload.config.actions && payload.config.actions.options;
      const emailOption = options && options.find(o => o.label === 'Email Selected');

      const message = {
        subject,
        body,
        resolve
      };

      if (allSelected && emailOption) {
        message.event_id = emailOption.event_id;
        message.role_ids = emailOption.role_ids;
      }

      message.userIds = userList.map(recipient => recipient.id);

      onSubmit(message);
    }
  })
);
