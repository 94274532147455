import React from 'react';
import PropTypes from 'prop-types';

import AssessmentReport from './AssessmentReport';
import Subheader from '../Subheader';
import LoaderWrapper from '../../LoaderWrapper';
import Flex from '../../Flex';
import theme, { EmptyMessage } from '../styled-components';

const AssessmentReports = ({
  reports,
  handleCheckboxClick,
  checkedReports,
  handleSelectAll,
  isFetching,
  crewLabels,
  sort,
  onAssessmentReportClick,
  isReportsEventGroupAdmin,
  isReportsEventAdmin,
  isReportsEventAssignor
}) => (
  <>
    <LoaderWrapper isFetching={isFetching}>
      <Subheader
        handleSelectAll={handleSelectAll}
        checked={!!reports.length && reports.length === checkedReports.length}
        crewLabels={crewLabels}
        sort={sort}
        isReportsEventAdmin={isReportsEventAdmin}
        isReportsEventAssignor={isReportsEventAssignor}
        isReportsEventGroupAdmin={isReportsEventGroupAdmin}
      />
      {reports.length ? (
        <Flex direction="column" full fullWidth theme={theme.row.wrapper}>
          {reports.map(report => (
            <AssessmentReport
              key={report[0].game_assignment.external_game_id}
              gameReports={report}
              handleCheckboxClick={handleCheckboxClick}
              checked={checkedReports.includes(report[0].game_assignment.external_game_id)}
              crewLabels={crewLabels}
              onAssessmentReportClick={onAssessmentReportClick}
              isReportsEventGroupAdmin={isReportsEventGroupAdmin}
              isReportsEventAdmin={isReportsEventAdmin}
              isReportsEventAssignor={isReportsEventAssignor}
            />
          ))}
        </Flex>
      ) : (
        <Flex fullWidth>
          <EmptyMessage>No reports available</EmptyMessage>
        </Flex>
      )}
    </LoaderWrapper>
  </>
);

AssessmentReports.propTypes = {
  reports: PropTypes.arrayOf(Object),
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedReports: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  crewLabels: PropTypes.arrayOf(Object).isRequired,
  onAssessmentReportClick: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  isReportsEventAdmin: PropTypes.bool,
  isReportsEventGroupAdmin: PropTypes.bool
};

AssessmentReports.defaultProps = {
  reports: [],
  isReportsEventAdmin: false,
  isReportsEventGroupAdmin: false
};

export default AssessmentReports;
