import { compose } from 'recompose';
import { connect } from 'react-redux';
import unconnected from './unconnected';
import { getPaymentMethods, getUserWalletTotal } from '../../../core/Payments/selectors';
import { addWalletFund as addWalletFundAction } from '../../../core/Payments';

const mapStateToProps = state => ({
  userAccounts: getPaymentMethods(state),
  totalBalance: getUserWalletTotal(state)
});

const mapDispatchToProps = dispatch => ({
  addWalletFund: payload => dispatch(addWalletFundAction(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), unconnected);
