import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import Modal from '../../../Modal';
import Button from '../../../Button';
import { Block } from './styled-components';

const Warning = ({ onClose, onSubmit }) => (
  <Modal title="Delete Category" onClose={onClose} background>
    <Row>
      <Block xs={12}>
        Are you sure you want to delete this category? It will be removed from all games and users
      </Block>
      <Block xs={12}>
        <Row center="xs">
          <Col xs={4}>
            <Button label="Delete" onClick={onSubmit} primary />
          </Col>
          <Col xs={4}>
            <Button label="Cancel" onClick={onClose} />
          </Col>
        </Row>
      </Block>
    </Row>
  </Modal>
);

Warning.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

Warning.defaultProps = {};

export default Warning;
