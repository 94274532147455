import InfoField from '../../../Reusable/InfoField';
import Dropdown from '../../../Reusable/Dropdown';
import Input from '../../../Reusable/Input';
import TextArea from '../../../Reusable/TextArea';
import theme from '../styled-components';
import options from './constants';
import CheckField from '../../../CheckField';
import { getTimezoneAbbr, onlyDigits } from '../../../../../utils/helpers';
import { required } from '../../../../../utils/validations';
import { formatDate } from '../../../../../utils/parsers';

const isRequired = () => true;
const playerSelected = values => values.report_on === 'Player';
const playerOrCoachSelected = values =>
  values.report_on === 'Player' || values.report_on === 'Coach';
const determineTeam = ({ report, game }) => {
  const selectedTeam = [game.team_home, game.team_visitor].find(
    team => team.id === report.external_team_id
  );
  return selectedTeam ? selectedTeam.name : 'N/A';
};

export const generateGameInfo = ({ game, display_id }) => [
  {
    label: 'Game ID',
    value: display_id
  },
  {
    label: 'Date',
    value: `${formatDate(game.start_date, 'dddd, MMMM Do YYYY')} -  ${
      game.start_time
    } (${getTimezoneAbbr(game.start_date, game.timezone)})`
  },
  {
    label: 'Level & League',
    value: `${game.game_level.game_level} - ${game.game_level.game_type} (${game.schedule_owner})`
  },
  {
    label: 'Location',
    value: `${game.location.name} (${game.location.city})`
  },
  {
    label: 'Home Team',
    value: game.team_home.name
  },
  {
    label: 'Away Team',
    value: game.team_visitor.name
  },
  {
    label: 'Officials',
    value:
      (game.crew &&
        game.crew
          .filter(({ role_id }) => role_id === 5)
          .map(({ first_name, last_name }) => `${first_name} ${last_name}`)
          .join(', ')) ||
      (game.assignments_game &&
        game.assignments_game
          .filter(({ event_role: { role_id } }) => role_id === 5)
          .map(({ event_role: { user } }) => `${user.first_name} ${user.last_name}`)
          .join(', '))
  }
];

export const generateReportInfo = ({ report, game }) => [
  {
    component: InfoField,
    label: 'Report Type',
    value: report.report_type
  },
  {
    component: InfoField,
    label: 'Time of Incident',
    value: report.report_time
  },
  {
    component: InfoField,
    label: 'Reason',
    value: report.reason
  },
  {
    component: InfoField,
    label: 'Reporting On',
    value: report.report_on
  },
  {
    component: InfoField,
    label: 'Name',
    value: report.name
  },
  {
    component: InfoField,
    label: 'Team',
    value: determineTeam({ report, game }),
    isDisabled: report.report_type === 'Field Conditions'
  },
  {
    component: InfoField,
    label: 'Uniform/Jersey #',
    value: report.uniform_number,
    isDisabled: !playerSelected(report)
  },
  {
    component: InfoField,
    label: 'Roster/Pass #',
    value: report.roster_number,
    isDisabled: !playerOrCoachSelected(report)
  },
  {
    component: CheckField,
    label: 'Offensive Language',
    placeholder: 'Offensive Language',
    input: { value: report.offensive_language, onChange: () => true },
    width: 12,
    disabled: true,
    padding: '0 0 0 0.5rem'
  },
  {
    component: TextArea,
    name: 'description',
    label: 'Detailed Description',
    placeholder: 'Detailed Description',
    input: { value: report.description, onChange: () => true },
    theme: theme.textArea,
    rows: 6,
    width: 12,
    resize: 'none',
    readOnly: true
  }
];

export default ({ game, change }) => ({
  fields: [
    {
      component: Dropdown,
      name: 'report_type',
      placeholder: 'Report Type',
      options: options.reportType.map(name => ({ name, value: name })),
      theme: theme.dropdown,
      displayValue: true,
      validate: required,
      isRequired,
      error: 'Required'
    },
    {
      component: Dropdown,
      name: 'report_time',
      placeholder: 'Time of Incident',
      options: options.timeOfIncident.map(name => ({ name, value: name })),
      theme: theme.dropdown,
      displayValue: true,
      validate: required,
      isRequired,
      error: 'Required'
    },
    {
      component: Dropdown,
      name: 'reason',
      placeholder: 'Reason',
      options: options.reason.map(name => ({ name, value: name })),
      theme: theme.dropdown,
      displayValue: true,
      validate: required,
      isRequired,
      error: 'Required'
    },
    {
      component: Dropdown,
      name: 'report_on',
      placeholder: 'Reporting On',
      options: options.reportingOn.map(name => ({ name, value: name })),
      theme: theme.dropdown,
      displayValue: true,
      validate: required,
      isRequired,
      error: 'Required'
    },
    {
      component: Input,
      name: 'name',
      placeholder: 'Name',
      theme: theme.input
    },
    {
      component: Dropdown,
      name: 'external_team_id',
      placeholder: 'Team',
      options: [
        { name: game.team_home.name, value: game.team_home.id },
        { name: game.team_visitor.name, value: game.team_visitor.id },
        { name: 'N/A', value: 'N/A' }
      ],
      theme: theme.dropdown,
      displayValue: true,
      isDisabled: values => values.report_type === 'Field Conditions'
    },
    {
      component: Input,
      name: 'uniform_number',
      placeholder: 'Uniform/Jersey #',
      theme: theme.input,
      message: "(Player's Uniform/Jersey Number)",
      parse: onlyDigits,
      validate: (value, allValues) => (playerSelected(allValues) ? required(value) : undefined),
      isRequired: playerSelected,
      isDisabled: values => !playerSelected(values),
      showError: true
    },
    {
      component: Input,
      name: 'roster_number',
      placeholder: 'Roster/Pass #',
      theme: theme.input,
      message: "(Player's Roster/Pass Number if required)",
      parse: onlyDigits,
      validate: (value, allValues) =>
        playerOrCoachSelected(allValues) ? required(value) : undefined,
      isRequired: playerOrCoachSelected,
      isDisabled: values => !playerOrCoachSelected(values),
      showError: true
    },
    {
      component: CheckField,
      name: 'offensive_language',
      placeholder: 'Offensive Language',
      message: '(If Report contains language that some might consider innappropriate or offensive)',
      width: 12,
      padding: '0 0 0 0.5rem'
    },
    {
      component: TextArea,
      name: 'description',
      placeholder: 'Detailed Description',
      theme: theme.textArea,
      rows: 6,
      width: 12,
      resize: 'vertical',
      validate: required,
      isRequired,
      error: 'Required',
      showError: true,
      nonDirty: true
    }
  ],
  onChanges: [
    {
      name: 'report_type',
      onChangeFn: (value, prev) => {
        if (value !== prev && value === 'Field Conditions') change('external_team_id', null);
      }
    },
    {
      name: 'report_on',
      onChangeFn: (value, prev) => {
        if (value !== prev && !playerSelected({ value })) change('uniform_number', null);
        if (value !== prev && !playerOrCoachSelected({ value })) change('roster_number', null);
      }
    }
  ]
});
