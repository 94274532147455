import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Headline, BodyText } from '../Typography';

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none'
  },
  popoverContent: {
    pointerEvents: 'auto',
    width: '260px',
    border: '1px solid rgb(0, 123, 255)',
    fontFamily: 'Roboto',
    position: 'absolute',
    borderRadius: '2px',
    overflow: 'visible'
  },
  Button: {
    border: '0px solid transparent',
    outline: 0,
    color: '#007BFF',
    background: 'transparent',
    fontSize: '16px',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent'
  },
  Headline: {
    textTransform: 'capitalize',
    background: '#D2E9FF',
    padding: '8px 10px',
    margin: 0,
    color: 'RGB(0, 64, 133)',
    fontFamily: 'Barlow',
    fontWeight: '400'
  },
  Text: {
    color: 'RGB(61, 73, 86)',
    fontFamily: 'Barlow',
    fontWeight: '400',
    fontSize: '14px'
  },
  BodyText: {
    padding: '8px 10px',
    boxSizing: 'border-box'
  },
  TooltipWrapper: {
    width: '100%'
  }
}));

const ToolTip = ({ children, messageTitle, messageBody, buttonText, color }) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const handleScroll = () => {
    setOpenedPopover(false);
    window.removeEventListener('scroll', handleScroll, true);
  };

  useEffect(() => {
    if (openedPopover) {
      window.addEventListener('scroll', handleScroll, true);
    }
  });

  const copy = () => {
    const element = document.getElementById('innerText');
    navigator.clipboard.writeText(element.innerText.replace(/[^0-9]/g, ''));
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };
  const classes = useStyles();

  return (
    <span className={classes.TooltipWrapper}>
      <span
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        onTouchStartCapture={popoverEnter}
        style={{
          cursor: 'pointer',
          WebkitTapHighlightColor: 'transparent',
          textTransform: 'capitalize',
          display: 'inline-flex',
          alignItems: 'center',
          color
        }}
      >
        {children}
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave
        }}
      >
        <Typography variantMapping={{ body1: 'div' }}>
          <Headline className={classes.Headline}>{messageTitle}</Headline>
          <BodyText className={classes.BodyText}>
            <span
              className={classes.Text}
              id="innerText"
              dangerouslySetInnerHTML={{ __html: messageBody }}
            />
            <Tooltip
              PopperProps={{
                disablePortal: true
              }}
              open={openTooltip}
              onClose={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied!"
            >
              <button type="button" className={classes.Button} onClick={() => copy()}>
                {buttonText}
              </button>
            </Tooltip>
          </BodyText>
        </Typography>
      </Popover>
    </span>
  );
};

export default ToolTip;
