import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import { COLORS } from '../../../../../utils/styles';
import { pxToRem } from '../../../../../utils/helpers';

export const TableScroll = styled(Row)`
  background-color: ${COLORS.white};
  position: relative;
  margin: auto;
  padding: ${pxToRem('10px')} 0px;
`;

export const TableWrap = styled(Col)`
  width: 100%;
  overflow: auto;
  maxheight: 10px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

export const StaticValue = styled.span`
  font-family: 'RobotoCondensed';
  font-size: ${({ fontSize }) => fontSize || '0.9rem'};
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: ${({ lineHeight }) => lineHeight || '2.31'};
  letter-spacing: normal;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  color: ${COLORS.nightRider};
`;

export const Container = styled(Col)`
  max-width: 90%;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  padding-right: ${({ paddingRight }) =>
    paddingRight ? `${pxToRem(paddingRight)} !important` : `${pxToRem('8px')} !important`};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? `${pxToRem(paddingLeft)} !important` : `${pxToRem('16px')} !important`};
`;

export const NavItem = styled.span`
  display: flex;
  align-items: left;
  justify-content: left;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  cursor: pointer;

  ${({ divider }) => (divider ? `border-right: 1px solid ${COLORS.white}` : '')};
  ${({ theme }) => theme};
`;

export const NoData = styled.span`
  margin-left: ${pxToRem('5px')};
`;
