import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

/**
 * Create the store with the neccessary middlewares
 *
 * @param  {object} state - Initial state
 * @return {object} store - Returns redux's store
 */

export default function configureStore(state = {}) {
  // Create the store with the middlewares
  //  1. sagaMiddleware: Makes redux-sagas work
  const middlewares = [sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(createReducer, state, composeWithDevTools(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.runSaga(rootSaga);

  return store;
}

// Create a history of your choosing (we're using a browser history in this case)
const historyOptions = {};
export const history = createBrowserHistory(historyOptions);

// Create redux store with history
export const store = configureStore({});
