import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { Content, Wrapper } from './styled-components';
import SimpleNav from '../SimpleNav';
import onBoardingConfig from './config';
import Enhancer from '../../containers/OnBoarding';
import { userIdxGetter } from '../../../utils/auth';
import { sInterpolator as routeBuilder, matchUrl } from '../../../utils/helpers';
import { ON_BOARDING } from '../../../core/paths';

const OnBoarding = ({
  routes,
  isProfileCompleted,
  officialAssessorAssignor,
  isPaymentProfileRequired,
  isPaymentSetUpComplete,
  userId,
  isPaidThroughSO
}) => {
  if (matchUrl(ON_BOARDING, window.location.pathname)) {
    const onBoardingPage = onBoardingConfig(
      isProfileCompleted,
      officialAssessorAssignor,
      isPaymentProfileRequired,
      isPaymentSetUpComplete,
      isPaidThroughSO
    );
    if (onBoardingPage && !matchUrl(onBoardingPage, window.location.pathname)) {
      return <Redirect to={routeBuilder({ userIdx: userIdxGetter(), userId }, onBoardingPage)} />;
    }
  }
  return (
    <Wrapper>
      <SimpleNav />
      <Content>
        {routes.map(({ path, component: Component }) => (
          <Route key={path} path={path} render={props => <Component {...props} />} />
        ))}
      </Content>
    </Wrapper>
  );
};

OnBoarding.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Enhancer(OnBoarding);
