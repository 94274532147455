import styled from 'styled-components';
import { COLORS } from '../../../../../../utils/styles';
import { pxToRem } from '../../../../../../utils/helpers';
import Icon from '../../../../Icon';

export const StyledInput = styled.input`
  display: flex;
  flex: 4;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoLight';
  background: transparent;
  border: none;
  padding: ${pxToRem('6px')} 0.5rem 0.5rem;
  margin: 0;
  outline-width: 0;
  ${({ addable }) => (!addable ? 'width: 100%' : '')};

  &::placeholder {
    color: ${COLORS.white};
    text-transform: capitalize;
  }

  ${({ theme }) => theme || ''};
`;

export const StyledBody = styled.div`
  padding: ${pxToRem('6px')} 0.5rem 0.5rem;
`;

export const Container = styled.div`
  display: inline-block;
  margin-left: ${pxToRem('5px')};
`;
export const StyledTrashIcon = styled(Icon)`
  display: flex;
  flex: 1;
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoLight';
  background: transparent;
  border: none;
  position: relative;
  float: right;
  bottom: ${pxToRem('23px')};
  padding: 0 0 1rem;
  margin: ${pxToRem('-12px')};
  outline-width: 0;
  &::placeholder {
    color: ${COLORS.white};
    text-transform: capitalize;
  }

  ${({ theme }) => theme || ''};
`;
export const StyledRosterTrashIcon = styled(Icon)`
  color: ${COLORS.white};
  font-size: ${pxToRem('13px')};
  font-family: 'RobotoLight';
  background: transparent;
  border: none;
  position: relative;
  float: right;
  outline-width: 0;
  &::placeholder {
    color: ${COLORS.white};
    text-transform: capitalize;
  }

  ${({ theme }) => theme || ''};
`;

export const StyledLabel = styled.label`
  font-family: 'RobotoCondensedLight';
  font-size: 0.7rem !important;
  color: rgba(255, 255, 255, 0.5);
  margin: ${pxToRem('10px')} 0 0;
  display: inline-block;
  text-transform: capitalize;
  ${({ isHidden }) => (isHidden ? 'visibility: hidden' : '')};
  position: absolute;
  left: 0.5rem;

  top: -1.25rem;

  ${({ theme }) => theme || ''};
`;

export const Wrapper = styled.div`
  position: relative;
  box-shadow: inset 0 -1px 0 0 ${COLORS.white}88;
  width: 92%;
  margin-left: 1.2rem;
  &.hasValue {
    box-shadow: inset 0 -2px 0 0 ${COLORS.white};
  }

  ${({ hasError }) => (hasError ? `box-shadow: inset 0 -2px 0 0 ${COLORS.venetianRed}` : '')};

  ${({ theme }) => theme || ''};
`;

export const Message = styled.p`
  color: ${COLORS.hawkesBlue};
  font-size: ${pxToRem('13px')};
  text-align: left;
  font-family: 'RobotoCondensedLight';
  padding-left: 0.5rem;
  margin: ${pxToRem('4px')} 0 0;
`;

export const Error = styled.span`
  position: absolute;
  left: 0.5rem;
  font-size: ${pxToRem('10px')};
  color: ${COLORS.carminePink};
  font-family: 'RobotoCondensedLight';
`;
