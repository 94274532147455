import React from 'react';
import { Field } from 'react-final-form';

import { FiberManualRecord as Status } from '@material-ui/icons';
import moment from 'moment';

import { required } from '../../../utils/validations';
import { SUBSCRIPTION_CYCLE, SUBSCRIPTION_STATUSES } from '../../../constants';
import { numToCurrency } from '../../../utils/parsers';
import MaterialInput from '../MaterialInput';
import Paper from '../../layouts/Paper';
import {
  GroupTileFieldContainer,
  InnerContainer,
  Label,
  LabelWrapper,
  TableWrapper
} from './styled-components';
import COLORS from '../../../utils/styles';
import config from './config';
import Switch from '../Switch';
import MaterialDatePicker from '../../controls/MaterialDatePicker';
import Dropdown from '../Dropdown';

// TODO: make config to show group info
const GroupInfo = ({ info, isEditing, isSuperAdmin }) => (
  <TableWrapper>
    <Paper {...config.groupInfoTile}>
      <GroupTileFieldContainer justifyContent="space-between" padding="20px 0 0 40px">
        <InnerContainer>
          <LabelWrapper>
            {' '}
            <Label style={{ width: 100 }} isHeading>
              Group Name:
            </Label>
            <Label style={{ width: 300 }}>
              {isEditing ? (
                <Field
                  component={MaterialInput}
                  name="name"
                  validate={required}
                  style={{ width: '60%', padding: '0 0 15px 0' }}
                  theme={{ input: `color: ${COLORS.black}; font-size: 14px; padding: 0;` }}
                />
              ) : (
                info.name || 'NA'
              )}
            </Label>
          </LabelWrapper>
        </InnerContainer>
        <InnerContainer>
          <LabelWrapper>
            {' '}
            <Label style={{ width: 120 }} isHeading>
              Group Number:
            </Label>
            <Label style={{ width: 280 }}>{info.id || 'NA'}</Label>
          </LabelWrapper>
        </InnerContainer>
      </GroupTileFieldContainer>
      <GroupTileFieldContainer justifyContent="space-between" padding="20px 0 0 40px">
        <InnerContainer>
          <LabelWrapper>
            {' '}
            <Label style={{ width: 150 }} isHeading>
              Subscription Status:
            </Label>
            {isEditing && isSuperAdmin ? (
              <Label>
                <Field name="subscriptionStatus">
                  {({ input: { value, onChange } }) => (
                    <Switch
                      label={null}
                      onLabel={SUBSCRIPTION_STATUSES.ACTIVE}
                      offLabel={SUBSCRIPTION_STATUSES.INACTIVE}
                      switchState={value}
                      onClick={() => onChange(!value)}
                      theme={{
                        offLabel: {
                          onState: '',
                          offState: ''
                        },
                        onLabel: {
                          onState: '',
                          offState: ''
                        }
                      }}
                    />
                  )}
                </Field>
              </Label>
            ) : (
              <>
                <Label>
                  {info.gameSubscription && (
                    <Status
                      style={{
                        fontSize: '20px',
                        color: info.gameSubscription.active ? COLORS.forestGreen : COLORS.errorRed
                      }}
                    />
                  )}
                </Label>
                <Label style={{ width: 240 }}>
                  {(info.gameSubscription &&
                    (info.gameSubscription.active
                      ? SUBSCRIPTION_STATUSES.ACTIVE
                      : SUBSCRIPTION_STATUSES.INACTIVE)) ||
                    'NA'}
                </Label>
              </>
            )}
          </LabelWrapper>
        </InnerContainer>
        <InnerContainer>
          <LabelWrapper>
            {' '}
            <Label style={{ width: 150 }} isHeading>
              Group Subscription:
            </Label>
            <Label style={{ width: 250 }}>
              {isEditing && isSuperAdmin ? (
                <Field
                  component={MaterialInput}
                  name="groupFee"
                  type="number"
                  validate={required}
                  min={0}
                  max={10000}
                  style={{ width: '30%', padding: '0 0 15px 0' }}
                  theme={{ input: `color: ${COLORS.black}; font-size: 14px; padding: 0;` }}
                />
              ) : info.groupSubscription ? (
                `${numToCurrency(info.groupSubscription.price)}/${
                  SUBSCRIPTION_CYCLE[info.groupSubscription.cycle]
                }`
              ) : (
                'NA'
              )}
            </Label>
          </LabelWrapper>
        </InnerContainer>
      </GroupTileFieldContainer>
      <GroupTileFieldContainer justifyContent="space-between" padding="20px 0 10px 40px">
        <InnerContainer>
          <LabelWrapper>
            {' '}
            <Label style={{ width: 140 }} isHeading>
              Event Game Fee:
            </Label>
            <Label style={{ width: 260 }}>
              {isEditing && isSuperAdmin ? (
                <Field
                  component={MaterialInput}
                  name="gameFee"
                  type="number"
                  validate={required}
                  min={0}
                  max={1000}
                  style={{ width: '30%', padding: '0 0 15px 0' }}
                  theme={{ input: `color: ${COLORS.black}; font-size: 14px; padding: 0;` }}
                />
              ) : info.gameSubscription ? (
                `${numToCurrency(info.gameSubscription.price)}/game`
              ) : (
                'NA'
              )}
            </Label>
          </LabelWrapper>
        </InnerContainer>
        {isSuperAdmin && (
          <InnerContainer>
            <LabelWrapper>
              {' '}
              <Label style={{ width: 140 }} isHeading>
                Billing Start Date:
              </Label>
              <Label style={{ width: 260 }}>
                {isEditing ? (
                  <Field
                    component={MaterialDatePicker}
                    name="billingStartDate"
                    validate={required}
                    parse={value => (value ? moment(value).format('YYYY-MM-DD') : null)}
                    format={value => (value ? moment(value, 'YYYY-MM-DD').toDate() : null)}
                    style={{ width: '80%', padding: '10px 0px 15px' }}
                    theme={{ input: `color: ${COLORS.black}; font-size: 14px; padding: 0;` }}
                    containerWidth
                  />
                ) : info.gameSubscription ? (
                  info.gameSubscription.billingStartDate
                ) : (
                  'NA'
                )}
              </Label>
            </LabelWrapper>
          </InnerContainer>
        )}
      </GroupTileFieldContainer>
      {isSuperAdmin && (
        <GroupTileFieldContainer justifyContent="space-between" padding="20px 0 10px 40px">
          <InnerContainer>
            <LabelWrapper>
              {' '}
              <Label style={{ width: 140 }} isHeading>
                Salesforce ID:
              </Label>
              <Label style={{ width: 260 }}>
                {isEditing ? (
                  <Field
                    component={MaterialInput}
                    name="custId"
                    type="text"
                    validate={required}
                    style={{ width: '80%', padding: '0 0 15px 0' }}
                    theme={{ input: `color: ${COLORS.black}; font-size: 14px; padding: 0;` }}
                  />
                ) : (
                  info.salesforce_id || 'NA'
                )}
              </Label>
            </LabelWrapper>
          </InnerContainer>
          <InnerContainer>
            <LabelWrapper>
              {' '}
              <Label style={{ width: 140 }} isHeading>
                Invoice Day of the Month:
              </Label>
              <Label style={{ width: 260 }}>
                {isEditing ? (
                  <Field
                    component={MaterialInput}
                    type="number"
                    name="invoiceRunDay"
                    validate={required}
                    min={1}
                    max={31}
                    style={{ width: '30%', padding: '0 0 15px 0' }}
                    theme={{ input: `color: ${COLORS.black}; font-size: 14px; padding: 0;` }}
                  />
                ) : info.gameSubscription ? (
                  info.gameSubscription.invoiceRunDay
                ) : (
                  'NA'
                )}
              </Label>
            </LabelWrapper>
          </InnerContainer>
          <InnerContainer>
            <LabelWrapper>
              {' '}
              <Label style={{ width: 140 }} isHeading>
                Netsuite ID:
              </Label>
              <Label style={{ width: 260 }}>
                {isEditing ? (
                  <Field
                    component={MaterialInput}
                    name="netsuiteId"
                    type="text"
                    validate={required}
                    style={{ width: '80%', padding: '0 0 15px 0' }}
                    theme={{ input: `color: ${COLORS.black}; font-size: 14px; padding: 0;` }}
                  />
                ) : (
                  info.netsuite_id || 'NA'
                )}
              </Label>
            </LabelWrapper>
          </InnerContainer>
          <InnerContainer>
            <LabelWrapper>
              {' '}
              <Label style={{ width: 140 }} isHeading>
                Group Status:
              </Label>
              <Label style={{ width: 260 }}>
                {isEditing ? (
                  <Field name="is_test">
                    {({ input: { name, value, onChange }, meta: { active } }) => (
                      <Dropdown
                        dropdownWidth="9rem"
                        options={[
                          { label: '- Select -' },
                          { label: 'Billable', value: 0 },
                          { label: 'Test', value: 1 }
                        ]}
                        onChange={({ target: { value: is_test } }) => {
                          onChange(is_test);
                        }}
                        active={active}
                        columnSize={2}
                        name={name}
                        value={value}
                        borderRadius="5px"
                        backgroundColor={COLORS.white}
                        borderColor={COLORS.slateGray}
                        outline="none"
                        dropdownHeight="1.5rem"
                      />
                    )}
                  </Field>
                ) : info.is_test ? (
                  'Test'
                ) : (
                  'Billable'
                )}
              </Label>
            </LabelWrapper>
          </InnerContainer>
        </GroupTileFieldContainer>
      )}
    </Paper>
  </TableWrapper>
);

export default GroupInfo;
