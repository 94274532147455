import styled from 'styled-components';

export const AddressLine = styled.p`
  margin: 0;
`;

export const AddressContainer = styled.div``;

export const SingleCertificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CertificationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CertificationValue = styled.div`
  padding-left: 1px;
`;
