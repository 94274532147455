import React, { useState } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { InputsContainer, TitleBar, HalfContent, SectionContainer } from '../styled-components';
import config from './config';
import BillingModal from '../Modal';
import LoaderWrapper from '../../../LoaderWrapper';
import Enhancer from '../../../../containers/Event/EventInformation/Modal';

const BillingSection = ({
  billingCustomersList,
  updateBillingCustomer,
  onAddBillingCustomer,
  billingCustomerIsFetching,
  mutators,
  isGamesEventGroupAdmin,
  formValues,
  hasInvoice,
  initialValue,
  isFetchingBillingCustomerList
}) => {
  const [eventBillingModal, setEventBillingModal] = useState(false);
  const [updateEventBillingModal, setUpdateEventBillingModal] = useState(false);
  const [updatedBillingCustomerList = billingCustomersList, setNewList] = useState();
  const { billing } = formValues;
  const [updatedBillingCustomer, setUpdatedBillingCustomer] = useState(null);
  const modalCloseUpdatePayer = () => {
    setUpdateEventBillingModal(false);
    setUpdatedBillingCustomer(null);
    mutators.setBillingDropdown(initialValue);
  };

  const onUpdateBillingCustomer = user => {
    /*
      Dev notes: a better way to do this is to not mutate the param and instead set the correct value
      when the object is composed. this didn't work because of the way we send field values to the framework
      and it is the framework that decides how to compose the resulting object. Maybe someone smarter than 
      me can figure this one out
     */
    /* eslint-disable no-param-reassign */
    user.state = (user.state && user.state.value) || user.state;
    updateBillingCustomer({
      data: { ...user },
      platformId: user.groupBillingCustomer[0].platform_id,
      customerId: user.id
    });
  };

  const modalCloseBilling = () => {
    setEventBillingModal(false);
  };

  const handleUpdateBillingCustomer = updatedInfo => {
    setUpdatedBillingCustomer(updatedInfo);
    onUpdateBillingCustomer(updatedInfo);
    setUpdateEventBillingModal(false);
    setNewList(
      updatedBillingCustomerList.map(user => (user.id === updatedInfo.id ? updatedInfo : user))
    );
  };

  return (
    <>
      <SectionContainer>
        <TitleBar>Game Fee Billing</TitleBar>
        <LoaderWrapper isFetching={billingCustomerIsFetching || isFetchingBillingCustomerList}>
          {config
            .getBillingFields({
              billingCustomers: updatedBillingCustomerList,
              setEventBillingModal,
              setUpdateEventBillingModal,
              selectedBillingCustomer: updatedBillingCustomer,
              setSelectedBillingCustomer: setUpdatedBillingCustomer,
              mutators,
              isGamesEventGroupAdmin,
              billing,
              hasInvoice,
              initialValue
            })
            .map(({ field }) => (
              <InputsContainer key={field.name}>
                <HalfContent>
                  <Field {...field} />
                </HalfContent>
              </InputsContainer>
            ))}
        </LoaderWrapper>
      </SectionContainer>
      {eventBillingModal && (
        <BillingModal
          title="Add New Billing Contact"
          onClose={modalCloseBilling}
          onSubmitManually={onAddBillingCustomer}
          fields={config.billingModalFields}
          openModal={eventBillingModal}
        />
      )}
      {updateEventBillingModal && (
        <BillingModal
          title="Event Billing Contact"
          onClose={modalCloseUpdatePayer}
          onSubmitManually={values => handleUpdateBillingCustomer(values)}
          fields={config.billingModalFields}
          openModal={updateEventBillingModal}
          updateBillingCustomer={updateBillingCustomer}
          payerInfo={updatedBillingCustomer}
        />
      )}
    </>
  );
};

BillingSection.propTypes = {
  isFetchingBillingCustomerList: PropTypes.bool.isRequired,
  taxInfo: PropTypes.objectOf(Object)
};

BillingSection.defaultProps = {
  taxInfo: {}
};

export default Enhancer(BillingSection);
