import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '../../Button';
import Paper from '../../../layouts/Paper';
import AssesmentReportOptions from './AssessmentReportOptions';
import { FormContainer, TitleBar, SectionContainer } from '../InformationForm/styled-components';
import { ButtonContainer } from './styled-components';
import { ActualContentContainer } from '../styled-components';
import Enhancer from '../../../containers/Event/Assessments';
import TwoColumnContainer from '../../../layouts/FieldSpreadLayoutContainer';
import LoaderWrapper from '../../LoaderWrapper';

const DEFAULT_ASSESSMENT_SETTINGS = {
  auto_approve_assessment: null,
  official_auto_release_assessment: null
};

const updateDisabled = ({ pristine, invalid, submitErrors }) =>
  pristine || (invalid && !submitErrors);

const AssesmentReportSection = () => (
  <SectionContainer>
    <TitleBar>Assessment Reports</TitleBar>
    <AssesmentReportOptions />
  </SectionContainer>
);

const Assessments = ({ assessment_settings, isFetching, onSubmit }) => (
  <LoaderWrapper isFetching={isFetching}>
    <ActualContentContainer>
      <Form
        onSubmit={onSubmit}
        initialValues={{ ...DEFAULT_ASSESSMENT_SETTINGS, ...(assessment_settings || {}) }}
        render={({ handleSubmit, reset, ...rest }) => (
          <form onSubmit={handleSubmit}>
            <Paper>
              <FormContainer>
                <AssesmentReportSection />
              </FormContainer>
              <ButtonContainer>
                <TwoColumnContainer
                  fields={[
                    <Button label="Update" primary type="submit" disabled={updateDisabled(rest)} />,
                    <Button type="button" label="Cancel" onClick={reset} name="label" />
                  ]}
                  columns={2}
                />
              </ButtonContainer>
            </Paper>
          </form>
        )}
      />
    </ActualContentContainer>
  </LoaderWrapper>
);

Assessments.propTypes = {
  assessment_settings: PropTypes.shape({
    auto_approve_assessment: PropTypes.bool,
    official_auto_release_assessment: PropTypes.bool
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Enhancer(Assessments);
